import React from "react";
import { useState, useEffect, Component } from "react";
import { useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
//import axios from '../../axios.js';
import moment from "moment/moment";

//import "./../Resources/homebtcss.css";
import "../../Resources/home2css.css";
import "../../Resources/serviceshomecss.css";

import "../../Resources/homebtcss.css";
//import Sidebar from "./sidebar";
import '../../Resources/responsiveness.css';

//import Chart from "chart.js/auto";
//import { Line, Bar } from "react-chartjs-2";
import Footer3 from "../../Supportingfiles/footer3";
//import { Navbar } from "flowbite-react";
import Navbar3 from "../../Supportingfiles/navbar3";
import Whystartupconnect from "../whystartupconnect";


function Contentwriting1() {
  return (
    <>
      <html lang="en">
        <head>
          {/*} Required meta tags */}
          <meta charset="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <title>STARTUP CONNECT | SERVICES | CONTENT WRITING</title>
          {/*} plugins:css */}
          <link
            rel="stylesheet"
            href="panel/assets/vendors/mdi/css/materialdesignicons.min.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/css/vendor.bundle.base.css"
          />
          {/*} endinject */}
          {/*} Plugin css for this page */}
          <link
            rel="stylesheet"
            href="panel/assets/vendors/jquery-bar-rating/css-stars.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/font-awesome/css/font-awesome.min.css"
          />
          {/*} End plugin css for this page */}
          {/*} inject:css */}
          {/*} endinject */}
          {/*} Layout styles */}
          <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
          {/*} End layout styles */}
          <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
        </head>
        <body>

        

  {/* Whatsapp Chat Icon Start */}
  <div class="wtp-chat-container" /*style={{position:"fixed",marginTop:"92vh", marginLeft:"94vw"}}*/>
    <a aria-label="Chat on WhatsApp" href="https://wa.me/918480002413?text=I%20want%20to%20know%20more%20about%20service%20Content%20Writing%20by%20the%20Startup%20Connect">   
    <img class="wtp-chat-icon" src="https://cdn-icons-png.flaticon.com/128/3938/3938058.png" /*style={{height:"3rem", width:"3rem"}}*/></img>
  </a>
  </div>
  {/* Whatsapp Chat Icon End */}


          <div class="home2-container">
            <Navbar3 />

            {/* HEADER SECTION START */}
            <section /*class="sv-header-section" */>
              <div class="sv-header-container1">
                <div class="sv-header-left home-header-pd">
                  <h1 style={{ color: "#004463" }}>
                    CONTENT WRITING
                  </h1>
                  <p>CONTENT WRITING is the process of creating written material, such as articles, blog posts, social media posts, and website copy, that is engaging, informative, and relevant to a specific audience. We can provide written material for various purposes, such as marketing, education, entertainment, or communication.To create effective content, our writers conduct research, identify target audience, and tailor their writing style and tone to appeal to that audience. They must also consider factors such as search engine optimization (SEO), readability, and visual elements such as images or videos.</p>

                  <div class="home2-services-features-list">
                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img loading = "lazy"

                          src="https://cdn-icons-png.flaticon.com/128/1087/1087840.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>500+</h3>
                        <h5 style={{ fontWeight: "bold" }}>Projects</h5>
                      </div>
                    </div>

                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img loading = "lazy"

                          src="https://cdn-icons-png.flaticon.com/128/12735/12735475.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>25+</h3>
                        <h5 style={{ fontWeight: "bold" }}>Cities</h5>
                      </div>
                    </div>

                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img loading = "lazy"

                          src="https://cdn-icons-png.flaticon.com/128/2991/2991235.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>
                          4{" "}
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/7656/7656139.png"
                            style={{ height: "2rem", width: "2rem" }}
                          />
                          +
                        </h3>
                        <h5 style={{ fontWeight: "bold" }}>Google Ratings</h5>
                      </div>
                    </div>
                  </div>

                  <div  class="serviceshome-btn-container">
             <div>
             <a href="https://calendly.com/thestartupconnect/30min"><button class="btn-lft-margin" id="home2-modules-section-btn" style={{marginLeft:"0"}}>Book Free Consultancy <i class="mdi mdi-arrow-right"></i></button></a>
             </div>
             <div style={{marginLeft:"0"}}>
             <a href="/home1/marketing_services_brochure.pdf" download="Startup Connect Services Brochure"><button class="btn-lft-margin" id="home2-modules-section-btn" style={{backgroundImage: "linear-gradient(to right, #208d17, #00ad11)", marginLeft:"0"}}>Download Brochure <i class="mdi mdi-download"></i></button></a>
             </div>
             </div>
             
                </div>
                <div
                  class="sv-header-right" /* style={{backgroundColor:"#96dfff",padding:"2rem",borderRadius:"15px",boxShadow:"2px 3px 3px 2px #cbcecf"}} */
                >
                  <img loading = "lazy"

                    src="https://img.freepik.com/free-vector/editing-body-text-concept-illustration_114360-5791.jpg"
                    style={{ width: "90%", height: "100%" }}
                  />
                </div>
              </div>
            </section>
            {/* HEADER SECTION END */}
            <hr />

            {/* SIDEBAR AND MAIN PAGE START */}
            <div className="container-scroller">
              {/*<Sidebar /> */}
              <div className="container-fluid page-body-wrapper">
                {/*} partial */}
                <div className="main-panel">
                 

              

               
                  <h2 id="ip-h2" style={{fontWeight:"bold"}}>
                    WHY CHOOSE  <span style={{ color: "#9f23c2" }}>
                            CONTENT WRITING
                          </span>
                  </h2>
                  {/* BENEFITS SECTION START */}
                  <div class="row" style={{ padding: "2rem" }}>
                    <div
                      class="col-sm-2 stretch-card grid-margin"  id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/419/419814.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Audience Targetting</h4>
                        <p>
                        We create Content keeping specific audiences in mind, based on demographics, interests, and behaviors.</p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin"  id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/755/755150.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Relevance</h4>
                        <p>
                        Our Contents are relevant to the audience and address their pain points or interests. </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin"  id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/5579/5579746.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Originality</h4>
                        <p>
                        Our Contents are original and not copied from other sources. Plagiarism is not acceptable. </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin"  id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/3089/3089220.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">SEO Optimization</h4>
                        <p>
                        We optimize the content for search engines using relevant keywords, meta tags, and other on-page optimization techniques.</p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin"  id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/3822/3822895.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Engagement</h4>
                        <p>
                        Our Contents are engaging and capture the reader's attention, using storytelling, humor, or other techniques. </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin"  id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/13161/13161578.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Clarity and Readibility</h4>
                        <p>
                        Our Content is easy to read and understand, using simple language, short sentences, and clear headings and subheadings.</p>
                      </div>
                    </div>

                   
                  
                   
                  </div>
                  {/* BENEFITS SECTION END */}

                  <hr />
                  {/* REQUIREMENTS SECTION START */}
                  <h2 id="ip-h2" style={{fontWeight:"bold"}}>BENEFITS OF <span style={{color:"#140d83"}}>CONTENT WRITING</span></h2>
                  <p style={{fontSize:"1rem", padding:"0.5rem"}}>Unlock the potential of your brand, connect with your audience, and establish authority in your industry through our professional Content Writing services. Opting for content writing isn't just about producing words—it's a strategic decision to convey your message with clarity, engage your audience authentically, and drive meaningful connections. Here's why choosing content writing is paramount for your organization:</p>

                  <div class="row" style={{ padding: "2rem" }}>
                    <div class="col-sm-4 stretch-card grid-margin">
                      <img loading = "lazy"

                        src="https://img.freepik.com/free-vector/blogging-illustration-concept_114360-788.jpg"
                        style={{ width: "90%", height: "100%" }}
                      />
                    </div>

                    <div
                      class="col-sm-8 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div>
                        <h4 style={{ fontWeight: "bold" }}>
                        <i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> Brand Consistency:
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                          {" "}
                          Maintain a consistent and recognizable brand voice. Content writing ensures that your messaging is uniform across different platforms, creating a cohesive brand identity that resonates with your audience.</p>
                        <br />
                        <h4 style={{ fontWeight: "bold" }}>
                        <i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> Adaptability to Trends and Changes:
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                        Stay relevant in a dynamic digital landscape. Content writing allows for quick adaptation to industry trends, news, and changes, ensuring that your content remains fresh, timely, and aligned with current market demands.</p>
                        <br />
                     
                        </div>
                        </div>
                        </div>
                         
                        <div class="row" style={{ padding: "2rem" }}>
                  

                    <div
                      class="col-sm-8 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div>
                        <h4 style={{ fontWeight: "bold" }}>
                        <i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> Audience Engagement and Retention:
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                        Build a loyal audience through meaningful interactions. Content writing creates engaging and informative pieces that captivate your audience, encouraging them to stay longer on your platforms and fostering a sense of community.</p>
                        <br />
                        <h4 style={{ fontWeight: "bold" }}>
                        <i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> Compelling Brand Storytelling:
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                        Craft narratives that resonate and leave a lasting impact. Content writing brings your brand story to life, connecting with your audience emotionally and fostering a deeper understanding of your mission and values.</p>
                      </div>
                    </div>
                    <div class="col-sm-4 stretch-card grid-margin">
                      <img loading = "lazy"

                        src="https://img.freepik.com/free-vector/blogging-isometric-icon-with-character-writing-post-3d_1284-63083.jpg"
                        style={{ width: "90%", height: "100%" }}
                      />
                    </div>
                  </div>

                  {/* REQUIREMENTS SECTION END */}
                  <hr />
               

                  {/* Why StartUp Connect Starts */}
                  <Whystartupconnect/>
                  {/* Why StartUp Connect Ends */}

                  <hr />

                  {/* PRICING SECTION START */}
                  <h2 id="ip-h2" style={{fontWeight:'bold'}}>PRICING FOR <span style={{color:"#006345"}}>CONTENT WRITING</span></h2>
                  <div class="row md-12" style={{marginTop:"5rem"}}>

                  {/* PRICING CARD 1 START */}
                  <div
                      class="col-sm-12 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                    <div class="card">
                    <div style={{backgroundColor:"#207a78",color:"#ffffff",fontWeight:"bold",textAlign:"center",borderRadius:"12px",padding:"1rem"}}>
                    <h3 class="pricing-ul-text"><u>GET CUSTOMIZED QUOTATIONS FOR CONTENT WRITING IN YOUR BUDGET</u></h3>
                    <div style={{textAlign:"center"}}><h2 style={{fontWeight:"bold"}}> Starting at <span style={{color:"#ffffff"}}>₹ 499*</span> only</h2></div>
                    </div>
                    <div style={{padding:"1rem",margingTop:"1rem"}}>
                       <div class="d-flex justify-content-center">
                       <button class="btn btn-lg btn-success" style={{backgroundColor:"#207a78", fontSize:"1rem"}} onClick={()=>{
                        //Setting Values to redirect to Contact Sales Page
                        sessionStorage.setItem("Smodule00","Services");sessionStorage.setItem("Sservice00","Content Writing"); window.location.replace("/businesstools-contactsales");
                       }}>Contact Sales</button>
                       </div>
                    </div>
                    </div>
                    </div>
                    {/* PRICING CARD 1 END */}

                  </div>
                  <hr/>
                  {/* PRICING SECTION END */}
              

             
                  <h2 id="ip-h2">Frequently Asked Questions (FAQs)</h2>
                  {/* FAQ SECTION START */}
                  <div
                    id="accordion"
                    style={{ textAlign: "left", marginTop: "3rem" }}
                  >
                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#003e73" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                        What type of content can a content writer create ?
                        </h5>
                        <div>
                          <button
                            id="ques-1-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-1").style.display =
                                "block";
                              document.getElementById(
                                "ques-1-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-1-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-1-dicon"
                            style={{ display: "none" }}
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-1").style.display =
                                "none";
                              document.getElementById(
                                "ques-1-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-1-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-1">
                        <div class="card-body">
                        Content writers can create a wide range of written materials, including blog posts, articles, website copy, social media updates, product descriptions, email, newsletters, and more.
                        </div>
                        </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                          How will you go about creating a content strategy for a client/brand?
                        </h5>
                        <div>
                          <button
                            id="ques-2-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-2").style.display =
                                "block";
                              document.getElementById(
                                "ques-2-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-2-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-2-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-2").style.display =
                                "none";
                              document.getElementById(
                                "ques-2-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-2-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-2" style={{ display: "none" }}>
                        <div class="card-body">
                        To create a content strategy for a client/brand, We conduct research on the brand and its target audience, identify content goals and objectives, determine content types and formats, establish a content calendar and distribution plan, and measure performance and adjust strategy as needed.  </div>
                        </div>
                    </div>

                   {/*} <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                       What Is Premium GST Billing Software?
                        </h5>
                        <div>
                          <button
                            id="ques-3-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-3").style.display =
                                "block";
                              document.getElementById(
                                "ques-3-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-3-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-3-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-3").style.display =
                                "none";
                              document.getElementById(
                                "ques-3-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-3-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-3" style={{ display: "none" }}>
                        <div class="card-body">
                        Premium GST Billing Software is a type of billing software that is designed to comply with Goods and Services Tax (GST) regulations in India, and includes features for invoicing, tax calculation, GST return filing, inventory management, and financial reporting.
                        </div>
                        </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                       How can I benefit me as a customer from a billing software?
                        </h5>
                        <div>
                          <button
                            id="ques-4-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-4").style.display =
                                "block";
                              document.getElementById(
                                "ques-4-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-4-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-4-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-4").style.display =
                                "none";
                              document.getElementById(
                                "ques-4-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-4-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-4" style={{ display: "none" }}>
                        <div class="card-body">
                        Billing software is a computer program that helps businesses manage their billing processes. It can benefit you as a customer by ensuring accurate and timely billing, providing transparency into your billing history, and enabling you to make payments online.  </div>
                      </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                     How does billing software help businesses manage their billing processes?

                        </h5>
                        <div>
                          <button
                            id="ques-5-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-5").style.display =
                                "block";
                              document.getElementById(
                                "ques-5-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-5-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-5-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-5").style.display =
                                "none";
                              document.getElementById(
                                "ques-5-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-5-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-5" style={{ display: "none" }}>
                        <div class="card-body">
                        Billing software helps businesses manage their billing processes by automating routine tasks such as invoicing, payment reminders, and late fees. It also provides real-time visibility into billing data, allowing businesses to make informed decisions about their billing practices.
                        </div>
                         </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                       How can billing software help businesses provide transparency into billing history?
                        </h5>
                        <div>
                          <button
                            id="ques-6-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-6").style.display =
                                "block";
                              document.getElementById(
                                "ques-6-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-6-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-6-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-6").style.display =
                                "none";
                              document.getElementById(
                                "ques-6-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-6-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-6" style={{ display: "none" }}>
                        <div class="card-body">
                        Billing software can help businesses provide transparency into billing history by allowing customers to view their billing history online. This makes it easy for customers to track their payments and resolve any billing disputes.</div>
                      </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                         How can I ensure the security of my billing information when using billing software?
                        </h5>
                        <div>
                          <button
                            id="ques-7-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-7").style.display =
                                "block";
                              document.getElementById(
                                "ques-7-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-7-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-7-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-7").style.display =
                                "none";
                              document.getElementById(
                                "ques-7-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-7-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-7" style={{ display: "none" }}>
                        <div class="card-body">
                        To ensure the security of your billing information when using billing software, you should choose a reputable and reliable software provider that uses encryption and other security measures to protect your</div>
                      </div>
                    </div>

                  {/*} <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                         What kind of analytics can I expect from a web app?
                        </h5>
                        <div>
                          <button
                            id="ques-8-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-8").style.display =
                                "block";
                              document.getElementById(
                                "ques-8-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-8-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-8-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-8").style.display =
                                "none";
                              document.getElementById(
                                "ques-8-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-8-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-8" style={{ display: "none" }}>
                        <div class="card-body">
                        Web apps can provide advanced analytics to track user behavior and identify areas for improvement.{" "}
                        </div>
                      </div>
                    </div> */}
                  </div>
                  {/* FAQ SECTION END */}

                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
            {/* SIDEBAR AND MAIN PAGE END */}

            <Footer3 />
          </div>
          {/*} container-scroller */}
          {/*} plugins:js */}
          <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
          {/*} endinject */}
          {/*} Plugin js for this page */}
          <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
          <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
          <script
            src="panel/assets/js/jquery.cookie.js"
            type="text/javascript"
          ></script>
          {/*} End plugin js for this page */}
          {/*} inject:js */}
          <script src="panel/assets/js/off-canvas.js"></script>
          <script src="panel/assets/js/hoverable-collapse.js"></script>
          <script src="panel/assets/js/misc.js"></script>
          <script src="panel/assets/js/settings.js"></script>
          <script src="panel/assets/js/todolist.js"></script>
          {/*} endinject */}
          {/*} Custom js for this page */}
          <script src="panel/assets/js/dashboard.js"></script>
          {/*} End custom js for this page */}
        </body>
      </html>
    </>
  );
}

export default Contentwriting1;
