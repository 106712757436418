import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './Resources/homebtcss.css';
import './Resources/home2css.css';
import './Resources/serviceshomecss.css';

import Navbar3 from './Supportingfiles/navbar3';
import Footer3 from './Supportingfiles/footer3';

//import './test123.css';


function Serviceschoice(){

    //CATEGORY CHANGE VARIABLES 
    const[choice_mostpopular,setchoice_mostpopular] = useState(1);//choice_mostpopular
    const[choice_businesssetup,setchoice_businesssetup] = useState(0);//choice_businesssetup
    const[choice_websitedev,setchoice_websitedev] = useState(0);//choice_mostpopular
    const[choice_softwaredev,setchoice_softwaredev] = useState(0);//choice_mostpopular
    const[choice_compliances,setchoice_compliances] = useState(0);//choice_compliances
    const[choice_consultancy,setchoice_consultancy] = useState(0);//choice_consultancy
    const[choice_fundraising,setchoice_fundraising] = useState(0);//choice_fundraising
    const[choice_govtschemes,setchoice_govtschemes] = useState(0);//choice_govtschemes
    const[choice_drafting,setchoice_drafting] = useState(0);//choice_drafting
    const[choice_marketing,setchoice_marketing] = useState(0);//choice_marketing

//SEARCHBAR
//searchbarvalue
//const[searchbarvalue,setsearchbarvalue]=useState();const updatesearchbarvalue = (event) => { setsearchbarvalue(event.target.value); }; const[searchbarvalue_er,setsearchbarvalue_er]=useState();



      return(
      <>

        {/* SEARCH SECTION START */}
        <div class="row d-flex justify-content-center mt-3">
        <div class="col-sm-6 stretch-card grid-margin">
        <div class="sv-searchbar">
        <select id="searchbar_input" placeholder="search your service">
          <option value="/">Select Service</option>
          <option value="/privatelimitedresgistration">Private Limited Registration</option>
          <option value="/llpregistration">LLP Registration</option>
          <option value="/startupindiaregistration">Startup India Registration</option>
          <option value="/gstregistration">GST Registration</option>
          <option value="/msmeregistration">MSME Registration</option>
          <option value="/partnershipregistration">Partnership Registration</option>
          <option value="/soleproprietorshipregistration">Sole Proprietorship Registration</option>
          <option value="/trademarkandipr">Trademark and IPR Registration</option>
          <option value="/staticdisplaywebsite">Static Display Website</option>
          <option value="/webapp">Web App</option>
          <option value="/ecommercewebsite">E-Commerce Website</option>
          <option value="/institutionwebsite">Institution Website</option>
          <option value="/enterpriseresourceplanner">Enterprise Resource Planner (ERP)</option>
          <option value="/billingsoftware">Billing Software</option>
          <option value="/pointofsale">Point of Sale (PoS)</option>
          <option value="/inventoryandwarehouse">Inventory and Warehouse Management</option>
          <option value="/membersmanagement">Members Management for Gyms and Clubs</option>
          <option value="/saas">Software as a Service (SaaS)</option>
          <option value="/paas">Platform as a Service(PaaS)</option>
          <option value="/gstfiling">GST Filing</option>
          <option value="/itrfiling">ITR Filing</option>
          <option value="/cmadata">CMA Data</option>
          <option value="/pitchdeckpreparation">Pitch Deck Preparation</option>
          <option value="/businessplancreation">Business Plan Creation</option>
          <option value="/startupconsultancy">Startup Consultancy</option>
          <option value="/startupindiaseedfundscheme">Startup India Seed Fund Scheme</option>
          <option value="/projectreportpreparationn">Project Report Preparation</option>
          <option value="/valuation">Valuations</option>
          <option value="/termsheetdrafting">Term Sheet Drafting</option>
          <option value="/termsandconditionsdrafting">Terms and Conditions Drafting</option>
          <option value="/privacypolicydrafting">Privacy Policy Drafting</option>
          <option value="/moudrafting">MOU Agreement Drafting</option>
          <option value="/socialmediamanagement">Social Media Management</option>
          <option value="/digitalmarketing">Digital Marketing</option>
          <option value="/copywriting">Copywriting</option>
          <option value="/contentwriting">Content Writing</option>
          <option value="/graphicsdesigning">Graphics Designing</option>
          <option value="/seo">Search Engine Optimization (SEO)</option>
          <option value="/ecommercestoresetup">E-Commerce Store Setup</option>
          <option value="/marketingcampaigns">Emailing and Messaging Campaigns</option>
        </select>

        <button onClick={()=>{
           const searchbar_val = document.getElementById("searchbar_input").value;
           window.location.replace(searchbar_val);
        }}>Search</button>
        </div>
        </div>
        </div>
     {/*}   <div class="row d-flex justify-content-center mt-0">
        <div class="col-md-6 stretch-card grid-margin">
        <div class="col-sm-4 stretch-card grid-margin">
        <button class="btn btn-link">Private Limited Company Registration</button>
        </div>
        <div class="col-sm-4 stretch-card grid-margin">
        <button class="btn btn-link">Startup India Registration</button>
        </div>
        <div class="col-sm-4 stretch-card grid-margin">
        <button class="btn btn-link">Apply for Seed Fund India Scheme</button>
        </div>

       </div>
       </div>*/}
        
        {/* SEARCH SECTION END */}

{/* CHOICE SECTION START */}

<div class="justify-content-between">
     {/*}   <h4 style={{color:"#2091a8",fontWeight:"bold"}}>Service Categories :</h4> */}
        <button class="btn btn-primary" style={{marginTop:"1rem"}} onClick={()=>{
            setchoice_mostpopular(1); setchoice_businesssetup(0); setchoice_websitedev(0); setchoice_softwaredev(0); setchoice_compliances(0); setchoice_consultancy(0); setchoice_fundraising(0); setchoice_govtschemes(0); setchoice_drafting(0); setchoice_marketing(0);  }}>Most Popular</button>
        <button class="btn btn-warning" style={{marginLeft:"1rem", marginTop:"1rem"}} onClick={()=>{
            setchoice_businesssetup(1); setchoice_mostpopular(0); setchoice_websitedev(0); setchoice_softwaredev(0); setchoice_compliances(0); setchoice_consultancy(0); setchoice_fundraising(0); setchoice_govtschemes(0); setchoice_drafting(0); setchoice_marketing(0);  }}>Business Setup</button>
        <button class="btn btn-success" style={{marginLeft:"1rem", marginTop:"1rem"}} onClick={()=>{
            setchoice_websitedev(1); setchoice_businesssetup(0); setchoice_mostpopular(0); setchoice_softwaredev(0); setchoice_compliances(0); setchoice_consultancy(0); setchoice_fundraising(0); setchoice_govtschemes(0); setchoice_drafting(0); setchoice_marketing(0); }}>Website Development</button>
        <button class="btn btn-danger" style={{marginLeft:"1rem", marginTop:"1rem"}} onClick={()=>{
            setchoice_softwaredev(1); setchoice_businesssetup(0); setchoice_websitedev(0); setchoice_mostpopular(0); setchoice_compliances(0); setchoice_consultancy(0); setchoice_fundraising(0); setchoice_govtschemes(0); setchoice_drafting(0); setchoice_marketing(0); }}>Software Solutions</button>
        <button class="btn btn-primary" style={{marginLeft:"1rem", marginTop:"1rem",backgroundColor:"#6f19b4",border:"#6f19b4"}} onClick={()=>{
            setchoice_compliances(1); setchoice_businesssetup(0); setchoice_websitedev(0); setchoice_softwaredev(0); setchoice_mostpopular(0); setchoice_consultancy(0); setchoice_fundraising(0); setchoice_govtschemes(0); setchoice_drafting(0); setchoice_marketing(0);  }}>Compliances</button>
        <button class="btn btn-info" style={{marginLeft:"1rem", marginTop:"1rem",backgroundColor:"#049681",border:"#049681"}} onClick={()=>{
            setchoice_consultancy(1); setchoice_businesssetup(0); setchoice_websitedev(0); setchoice_softwaredev(0); setchoice_compliances(0); setchoice_mostpopular(0); setchoice_fundraising(0); setchoice_govtschemes(0); setchoice_drafting(0); setchoice_marketing(0); }}>Consultancy</button>
        <button class="btn btn-info" style={{marginLeft:"1rem", marginTop:"1rem",backgroundColor:"#c61a99",border:"#c61a99"}} onClick={()=>{
            setchoice_fundraising(1); setchoice_businesssetup(0); setchoice_websitedev(0); setchoice_softwaredev(0); setchoice_compliances(0); setchoice_consultancy(0); setchoice_mostpopular(0); setchoice_govtschemes(0); setchoice_drafting(0); setchoice_marketing(0); }}>Fund Raising</button>
     {/*}   <button class="btn btn-info" style={{marginLeft:"1rem", marginTop:"1rem",backgroundColor:"#c75c1a",border:"#c75c1a"}} onClick={()=>{
            setchoice_govtschemes(1); setchoice_businesssetup(0); setchoice_websitedev(0); setchoice_softwaredev(0); setchoice_compliances(0); setchoice_consultancy(0); setchoice_fundraising(0); setchoice_mostpopular(0); setchoice_drafting(0); setchoice_marketing(0);  }}>Government Schemes</button>
      */}  <button class="btn btn-info" style={{marginLeft:"1rem", marginTop:"1rem",backgroundColor:"#057fb5",border:"#057fb5"}} onClick={()=>{
            setchoice_drafting(1); setchoice_businesssetup(0); setchoice_websitedev(0); setchoice_softwaredev(0); setchoice_compliances(0); setchoice_consultancy(0); setchoice_fundraising(0); setchoice_govtschemes(0); setchoice_mostpopular(0); setchoice_marketing(0); }}>Drafting</button>
        <button class="btn btn-info" style={{marginLeft:"1rem", marginTop:"1rem",backgroundColor:"#d0a10c",border:"#d0a10c"}} onClick={()=>{
            setchoice_marketing(1); setchoice_businesssetup(0); setchoice_websitedev(0); setchoice_softwaredev(0); setchoice_compliances(0); setchoice_consultancy(0); setchoice_fundraising(0); setchoice_govtschemes(0); setchoice_drafting(0); setchoice_mostpopular(0);  }}>Marketing</button>
        </div>


       {/* MOST POPULAR SECTION START */}
        <div class="row" id="choice-mostpopular" hidden={choice_mostpopular==0}>
             {/* SERVICE CARD 1 START */}
              <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/company-concept-illustration_114360-2581.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> Private Limited Registration </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Register your Private Limited Company seamlessly with our expert guidance and support.</p>
                      <p class="mt-4 text-primary" style={{fontSize:"1rem"}}><b>Starts at ₹ 7999* only</b></p>
                      </div>
                    </div>
                    <a href="/privatelimitedregistration"><button class="btn btn-primary mt-3">View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 1 END */}

              {/* SERVICE CARD 2 START */}
              <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/customer-relationship-management-concept-illustration_114360-7652.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> Limited Liability Partnership </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Set up your Limited Liability Partnership quickly and efficiently with support of our expert guidance.</p>
                      <p class="mt-4 text-primary" style={{fontSize:"1rem"}}><b>Starts at ₹ 7999* only</b></p>
                      </div>
                    </div>
                    <a href="/llpregistration"><button class="btn btn-primary mt-3">View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 2 END */}

              {/* SERVICE CARD 3 START */}
              <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/startup-flat-icon_1262-18782.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> Startup India Registration </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Get recognized as a Startup by the Government of India with Startup India registration. Get started now!</p>
                      <p class="mt-4 text-primary" style={{fontSize:"1rem"}}><b>Starts at ₹ 4999* only</b></p>
                      </div>
                    </div>
                    <a href="/startupindiaregistration"><button class="btn btn-primary mt-3">View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 3 END */}

              {/* SERVICE CARD 4 START */}
              <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/tax-form-concept-illustration_114360-7718.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> GST Registration </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Make your GST(Goods and Service Tax) Registration process hassle free and convenient with Startup Connect.</p>
                      <p class="mt-4 text-primary" style={{fontSize:"1rem"}}><b>Starts at ₹ 7999* only</b></p>
                      </div>
                    </div>
                    <a href="/gstregistration"><button class="btn btn-primary mt-3">View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 4 END */}

              {/* SERVICE CARD 5 START */}
              <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/digital-presentation-concept-illustration_114360-8415.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> Pitch Deck Preparation </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Pitch deck preparation services help businesses create persuasive presentations to secure investments or partnerships.</p>
                      <p class="mt-4 text-primary" style={{fontSize:"1rem"}}><b>Starts at ₹ 4999* only</b></p>
                      </div>
                    </div>
                    <a href="/pitchdeckpreparation"><button class="btn btn-primary mt-3">View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 5 END */}

              {/* SERVICE CARD 6 START */}
              <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/investing-concept-illustration_114360-2963.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> Valuations </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Redefine the essence of your business through professional valuation services provided by experts.</p>
                      <p class="mt-4 text-primary" style={{fontSize:"1rem"}}><b>Starts at ₹ 7999* only</b></p>
                      </div>
                    </div>
                    <a href="/valuation"><button class="btn btn-primary mt-3">View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 6 END */}

              {/* SERVICE CARD 7 START */}
              <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/app-development-concept-illustration_114360-5164.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> Web App </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Transform your business processes with our custom web application development services in your budget.</p>
                      <p class="mt-4 text-primary" style={{fontSize:"1rem"}}><b>Starts at ₹ 9999* only</b></p>
                      </div>
                    </div>
                    <a href="/webapp"><button class="btn btn-primary mt-3">View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 7 END */}

              {/* SERVICE CARD 8 START */}
              <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/isometric-laptop-with-shopping-cart-keypad_1262-16544.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> E-Commerce Website </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Get an online store that drives conversions and boosts revenue with our E-commerce website development.</p>
                      <p class="mt-4 text-primary" style={{fontSize:"1rem"}}><b>Starts at ₹ 14999* only</b></p>
                      </div>
                    </div>
                    <a href="/ecommercewebsite"><button class="btn btn-primary mt-3">View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 8 END */}

        </div>
       {/* MOST POPULAR SECTION END */}

       {/* BUSINESS SETUP SECTION START */}
        <div class="row" id="choice-businesssetup" hidden={choice_businesssetup==0}>
             {/* SERVICE CARD 1 START */}
              <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/company-concept-illustration_114360-2581.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> Private Limited Registration </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Register your Private Limited Company seamlessly with our expert guidance and support.</p>
                      <p class="mt-4 text-primary" style={{fontSize:"1rem"}}><b>Starts at ₹ 7999* only</b></p>
                      </div>
                    </div>
                    <a href="/privatelimitedregistration"><button class="btn btn-primary mt-3">View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 1 END */}
                {/* SERVICE CARD 2 START */}
                <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/customer-relationship-management-concept-illustration_114360-7652.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> Limited Liability Partnership </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Set up your Limited Liability Partnership quickly and efficiently with support of our expert guidance.</p>
                      <p class="mt-4 text-primary" style={{fontSize:"1rem"}}><b>Starts at ₹ 7999* only</b></p>
                      </div>
                    </div>
                    <a href="/llpregistration"><button class="btn btn-primary mt-3">View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 2 END */}
              {/* SERVICE CARD 3 START */}
              <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/startup-flat-icon_1262-18782.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> Startup India Registration </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Get recognized as a Startup by the Government of India with Startup India registration. Get started now!</p>
                      <p class="mt-4 text-primary" style={{fontSize:"1rem"}}><b>Starts at ₹ 4999* only</b></p>
                      </div>
                    </div>
                    <a href="/startupindiaregistration"><button class="btn btn-primary mt-3">View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 3 END */}
               {/* SERVICE CARD 4 START */}
               <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/tax-form-concept-illustration_114360-7718.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> GST Registration </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Make your GST(Goods and Service Tax) Registration process hassle free and convenient with Startup Connect.</p>
                      <p class="mt-4 text-primary" style={{fontSize:"1rem"}}><b>Starts at ₹ 7999* only</b></p>
                      </div>
                    </div>
                    <a href="/gstregistration"><button class="btn btn-primary mt-3">View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 4 END */}
                {/* SERVICE CARD 5 START */}
                <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/supermarket-food-shelves-eggplant-cabbage-carrot-peppers-onions-corn-bread-potatoes-shopping-fresh-vector-illustration_1284-46271.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> MSME Registration </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Register as an MSME(Micro, Small and Medium Enterprise) and unlock opportunities for growth and development.</p>
                      <p class="mt-4 text-warning" style={{fontSize:"1rem"}}><b>Starts at ₹ 7999* only</b></p>
                      </div>
                    </div>
                    <a href="/msmeregistration"><button class="btn btn-warning mt-3">View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 5 END */}
                {/* SERVICE CARD 6 START */}
                <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/business-partners-handshake_74855-5222.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> Partnership Registration </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Get started on a business journey with your partners by getting your parnership registered through our partnership registration services.</p>
                      <p class="mt-4 text-warning" style={{fontSize:"1rem"}}><b>Starts at ₹ 4999* only</b></p>
                      </div>
                    </div>
                    <a href="/partnershipregistration"><button class="btn btn-warning mt-3">View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 6 END */}
                {/* SERVICE CARD 7 START */}
                <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/creative-business-strategy-idea-illustration-concept_1344-194.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> Sole Proprietorship Registration </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Launch your dream business as a Sole Proprietor with our hassle-free sole proprietorship registration services.</p>
                      <p class="mt-4 text-warning" style={{fontSize:"1rem"}}><b>Starts at ₹ 3499* only</b></p>
                      </div>
                    </div>
                    <a href="/soleproprietorshipregistration"><button class="btn btn-warning mt-3">View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 7 END */}
                {/* SERVICE CARD 8 START */}
                <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/iso-certification-concept_23-2148696815.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> Public Limited Registration </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Register as a public limited company with help of public limited company registration services by Startup Connect.</p>
                      <p class="mt-4 text-warning" style={{fontSize:"1rem"}}><b>Starts at ₹ 29999* only</b></p>
                      </div>
                    </div>
                    <a href="/publiclimitedregistration"><button class="btn btn-warning mt-3">View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 8 END */}

             </div>
        {/* BUSINESS SETUP SECTION END */}

            {/* WEBSITE DEVELOPMENT SECTION START */}
            <div class="row" id="choice-websitedev" hidden={choice_websitedev==0}>
             {/* SERVICE CARD 1 START */}
              <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/dashboard-concept-illustration_114360-1447.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> Static Display Website </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Make an impactful online presence with our visually stunning and user-friendly static websites.</p>
                      <p class="mt-4 text-success" style={{fontSize:"1rem"}}><b>Starts at ₹ 999* only</b></p>
                      </div>
                    </div>
                    <a href="/staticdisplaywebsite"><button class="btn btn-success mt-3">View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 1 END */}
              {/* SERVICE CARD 2 START */}
              <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/app-development-concept-illustration_114360-5164.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> Web App </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Transform your business processes with our custom web application development services in your budget.</p>
                      <p class="mt-4 text-primary" style={{fontSize:"1rem"}}><b>Starts at ₹ 9999* only</b></p>
                      </div>
                    </div>
                    <a href="/webapp"><button class="btn btn-primary mt-3">View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 2 END */}
               {/* SERVICE CARD 3 START */}
               <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/isometric-laptop-with-shopping-cart-keypad_1262-16544.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> E-Commerce Website </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Get an online store that drives conversions and boosts revenue with our E-commerce website development.</p>
                      <p class="mt-4 text-primary" style={{fontSize:"1rem"}}><b>Starts at ₹ 14999* only</b></p>
                      </div>
                    </div>
                    <a href="/ecommercewebsite"><button class="btn btn-primary mt-3">View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 3 END */}
               {/* SERVICE CARD 4 START */}
               <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/college-class-concept-illustration_114360-10544.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> Institution Website </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Strengthen your online presence with our institution website design that showcases your values and offerings.</p>
                      <p class="mt-4 text-success" style={{fontSize:"1rem"}}><b>Starts at ₹ 9999* only</b></p>
                      </div>
                    </div>
                    <a href="/institutionwebsite"><button class="btn btn-success mt-3">View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 4 END */}
             </div>
             {/* WEBSITE DEVELOPMENT SECTION END */}

            {/* SOFTWARE DEVELOPMENT SECTION START */}
            <div class="row" id="choice-softwaredev" hidden={choice_softwaredev==0}>
             {/* SERVICE CARD 1 START */}
              <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/hand-drawn-flat-design-erp-illustration_23-2149365029.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> Enterprise Resource Planner (ERP) </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Streamline your business processes and boost productivity with our custom ERP solutions.</p>
                      <p class="mt-4 text-danger" style={{fontSize:"1rem"}}><b>Starts at ₹ 99999* only</b></p>
                      </div>
                    </div>
                    <a href="/enterpriseresourceplanner"><button class="btn btn-danger mt-3">View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 1 END */}
                {/* SERVICE CARD 2 START */}
                <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/gradient-crm-illustration_23-2149370936.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> Customer Relationship Manager(CRM) </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Build stronger customer relationships and drive growth with our tailored CRM solutions.</p>
                      <p class="mt-4 text-danger" style={{fontSize:"1rem"}}><b>Starts at ₹ 39999* only</b></p>
                      </div>
                    </div>
                    <a href="/customerrelationmanager"><button class="btn btn-danger mt-3">View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 2 END */}
              {/* SERVICE CARD 3 START */}
              <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/paying-bills-concept-illustration_114360-19357.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> Billing Software </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Efficiently manage your billing and invoicing with our custom software solutions.</p>
                      <p class="mt-4 text-danger" style={{fontSize:"1rem"}}><b>Starts at ₹ 9999* only</b></p>
                      </div>
                    </div>
                    <a href="/billingsoftware"><button class="btn btn-danger mt-3">View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 3 END */}
              {/* SERVICE CARD 4 START */}
              <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/self-checkout-concept-illustration_114360-2138.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> Point of Sale (PoS) </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Streamline your sales process with our intuitive and user-friendly PoS solutions.</p>
                      <p class="mt-4 text-danger" style={{fontSize:"1rem"}}><b>Starts at ₹ 14999* only</b></p>
                      </div>
                    </div>
                    <a href="/pointofsale"><button class="btn btn-danger mt-3">View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 4 END */}
              {/* SERVICE CARD 5 START */}
              <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/warehouse-interior-concept-illustration_114360-17989.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> Inventory Management Software </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Optimize your inventory levels and improve efficiency with our customized management solutions.</p>
                      <p class="mt-4 text-danger" style={{fontSize:"1rem"}}><b>Starts at ₹ 79999* only</b></p>
                      </div>
                    </div>
                    <a href="/inventoryandwarehouse"><button class="btn btn-danger mt-3">View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 5 END */}
                {/* SERVICE CARD 6 START */}
                <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/onboarding-concept-illustration_114360-1000.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> Members Management for Gyms and Clubs </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Effortlessly manage your members and their information with our customized software solutions.</p>
                      <p class="mt-4 text-danger" style={{fontSize:"1rem"}}><b>Starts at ₹ 39999* only</b></p>
                      </div>
                    </div>
                    <a href="/membersmanagement"><button class="btn btn-danger mt-3">View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 6 END */}
              {/* SERVICE CARD 7 START */}
              <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/static-asset-illustration-concept_114360-866.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> Software as a Service (SaaS) </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Bring your SaaS idea to life with our end-to-end product development services.</p>
                      <p class="mt-4 text-danger" style={{fontSize:"1rem"}}><b>Starts at ₹ 29999* only</b></p>
                      </div>
                    </div>
                    <a href="/saas"><button class="btn btn-danger mt-3">View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 7 END */}
              {/* SERVICE CARD 8 START */}
              <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/server-status-concept-illustration_114360-1106.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> Platform as a Service (PaaS) </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Build and deploy scalable cloud applications with our expert PaaS development services.</p>
                      <p class="mt-4 text-danger" style={{fontSize:"1rem"}}><b>Starts at ₹ 99999* only</b></p>
                      </div>
                    </div>
                    <a href="/paas"><button class="btn btn-danger mt-3">View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 8 END */}
             </div>
            {/* SOFTWARE DEVELOPMENT SECTION END */}

            {/* COMPLIANCES SECTION START */}
            <div class="row" id="choice-compliances" hidden={choice_compliances==0}>
            {/* SERVICE CARD 1 START */}
              <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/tax-preparation-concept-illustration_114360-15617.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> GST Filings </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      GST filing services help businesses comply with tax regulations and file GST returns accurately and timely.</p>
                      <p class="mt-4" style={{fontSize:"1rem", color:"#6f19b4"}}><b>Starts at ₹ 7999* only</b></p>
                      </div>
                    </div>
                    <a href="/gstfiling"><button class="btn btn-info mt-3" style={{backgroundColor:"#6f19b4",border:"#6f19b4"}}>View Service</button></a>
                  </div>
                </div>
              </div>
            {/*}  SERVICE CARD 1 END */}
            {/* SERVICE CARD 2 START */}
               <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/tax-form-concept-illustration_114360-7618.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> ITR Filing </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      ITR filing services help individuals and businesses comply with tax regulations and file income tax returns accurately and timely.</p>
                      <p class="mt-4" style={{fontSize:"1rem", color:"#6f19b4"}}><b>Starts at ₹ 7999* only</b></p>
                      </div>
                    </div>
                    <a href="/itrfiling"><button class="btn btn-info mt-3" style={{backgroundColor:"#6f19b4",border:"#6f19b4"}}>View Service</button></a>
                  </div>
                </div>
              </div>
            {/*}  SERVICE CARD 2 END */}
            {/* SERVICE CARD 3 START */}
               <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/accountant-concept-illustration_114360-5968.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> CMA </h5>
                    <div class="d-flex justify-content-between">
                     <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Empower your financial strategy with Startup Connect's CMA service. Gain comprehensive insights for informed business decisions and growth.</p>
                      <p class="mt-4" style={{fontSize:"1rem", color:"#6f19b4"}}><b>Starts at ₹ 3999* only</b></p>
                      </div>
                    </div>
                    <a href="/cmadata"><button class="btn btn-info mt-3" style={{backgroundColor:"#6f19b4",border:"#6f19b4"}}>View Service</button></a>
                  </div>
                </div>
              </div>
            {/*}  SERVICE CARD 3 END */}
             {/* SERVICE CARD 4 START */}
             <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/editorial-commission-concept-illustration_114360-8037.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> Trademark and IPR Registration </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Enhance your brand's security with streamlined Trademark and IPR registration services by Startup Connect.</p>
                      <p class="mt-4" style={{fontSize:"1rem", color:"#6f19b4"}}><b>Starts at ₹ 7999* only</b></p>
                      </div>
                    </div>
                    <a href="/trademarkandipr"><button class="btn btn-warning mt-3" style={{backgroundColor:"#6f19b4",border:"#6f19b4"}}>View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 4 END */}
            </div>

            {/* COMPLIANCES SECTION END */}

               {/* CONSULTANCY SECTION START */}
               <div class="row" id="choice-consultancy" hidden={choice_consultancy==0}>
            {/* SERVICE CARD 1 START */}
              <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/digital-presentation-concept-illustration_114360-8415.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> Pitch Deck Preparation </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Pitch deck preparation services help businesses create persuasive presentations to secure investments or partnerships.</p>
                      <p class="mt-4 text-primary" style={{fontSize:"1rem"}}><b>Starts at ₹ 4999* only</b></p>
                      </div>
                    </div>
                    <a href="/pitchdeckpreparation"><button class="btn btn-primary mt-3" /* style={{backgroundColor:"#049681",border:"#049681"}}*/ >View Service</button></a>
                  </div>
                </div>
              </div>
            {/*}  SERVICE CARD 1 END */}
             {/* SERVICE CARD 2 START */}
             <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/business-plan-concept-illustration_114360-1450.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> Business Plan Creation </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Forge your path to success with Startup Connect's expert business plan creation service. Strategic blueprints tailored for your entrepreneurial journey.</p>
                      <p class="mt-4" style={{fontSize:"1rem", color:"#049681"}}><b>Starts at ₹ 9999* only</b></p>
                      </div>
                    </div>
                    <a href="/businessplancreation"><button class="btn btn-info mt-3" style={{backgroundColor:"#049681",border:"#049681"}}>View Service</button></a>
                  </div>
                </div>
              </div>
            {/*}  SERVICE CARD 2 END */}
             {/* SERVICE CARD 3 START */}
             <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/hand-drawn-illustration-people-with-ideas_23-2149164331.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> Startup Consultancy </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Unlock your startup's potential with Startup Connect's consultancy. Strategic insights for success in every phase of your entrepreneurial journey</p>
                      <p class="mt-4" style={{fontSize:"1rem", color:"#049681"}}><b>Starts at ₹ 2999* only</b></p>
                      </div>
                    </div>
                    <a href="/startupconsultancy"><button class="btn btn-info mt-3" style={{backgroundColor:"#049681",border:"#049681"}}>View Service</button></a>
                  </div>
                </div>
              </div>
            {/*}  SERVICE CARD 3 END */}
             {/* SERVICE CARD 4 START */}
             <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/flat-design-investments-concept-with-plants_23-2149162430.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> Startup India Seed Fund Scheme </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Apply for funding support from government under Startup India Seed Fund Scheme and avail Grant upto Rs. 20 Lakhs and Debt upto Rs. 50 Lakhs.*</p>
                      <p class="mt-4" style={{fontSize:"1rem", color:"#049681"}}><b>Starts at ₹ 9999* only</b></p>
                      </div>
                    </div>
                    <a href="/startupindiaseedfundscheme"><button class="btn btn-info mt-3" style={{backgroundColor:"#049681",border:"#049681"}}>View Service</button></a>
                  </div>
                </div>
              </div>
            {/*}  SERVICE CARD 4 END */}

            </div>
            {/* CONSULTANCY SECTION END */}


            {/* DRAFTING SECTION START */}
            <div class="row" id="choice-drafting" hidden={choice_drafting==0}>
            {/* SERVICE CARD 1 START */}
              <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/diplomacy-diplomat-colored-isometric-concept-handshake-flag-country-seals-documents-signature-vector-illustration_1284-78091.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> Term Sheet Drafting </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Forge strong partnerships with Startup Connect's precise term sheet drafting service. Define terms clearly for successful and secure collaborations.</p>
                      <p class="mt-4" style={{fontSize:"1rem", color:"#057fb5"}}><b>Starts at ₹ 7999* only</b></p>
                      </div>
                    </div>
                    <a href="/termsheetdrafting"><button class="btn btn-info mt-3" style={{backgroundColor:"#057fb5",border:"#057fb5"}}>View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 1 END */}
               {/* SERVICE CARD 2 START */}
               <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/team-checklist-concept-illustration_114360-13202.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> Terms and Conditions Drafting </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Define success on your terms with meticulously drafted terms and conditions, ensuring clarity, protection, and seamless business operations.</p>
                      <p class="mt-4" style={{fontSize:"1rem", color:"#057fb5"}}><b>Starts at ₹ 7999* only</b></p>
                      </div>
                    </div>
                    <a href="/termsandconditionsdrafting"><button class="btn btn-info mt-3" style={{backgroundColor:"#057fb5",border:"#057fb5"}}>View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 2 END */}
               {/* SERVICE CARD 3 START */}
               <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/consent-concept-illustration_114360-9164.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> Privacy Policy Drafting </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Ensure trust and compliance with our Privacy Policy Drafting service. Tailored policies for safeguarding data and building credibility.</p>
                      <p class="mt-4" style={{fontSize:"1rem", color:"#057fb5"}}><b>Starts at ₹ 7999* only</b></p>
                      </div>
                    </div>
                    <a href="/privacypolicydrafting"><button class="btn btn-info mt-3" style={{backgroundColor:"#057fb5",border:"#057fb5"}}>View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 3 END */}
               {/* SERVICE CARD 4 START */}
               <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/memorandum-understanding-concept-illustration_114360-19867.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> MOU Agreement Drafting </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Facilitate seamless partnerships with businesses with our MOU service. Clear, concise, and customized terms for successful collaborations and growth</p>
                      <p class="mt-4" style={{fontSize:"1rem", color:"#057fb5"}}><b>Starts at ₹ 7999* only</b></p>
                      </div>
                    </div>
                    <a href="/moudrafting"><button class="btn btn-info mt-3" style={{backgroundColor:"#057fb5",border:"#057fb5"}}>View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 4 END */}
             </div>
             {/* DRAFTING SECTION END */}

            {/* MARKETING SECTION START */}
            <div class="row" id="choice-marketing" hidden={choice_marketing==0}>
            {/* SERVICE CARD 1 START */}
              <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/marketing-concept-illustration_114360-7324.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> Social Media Management </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Unlock the true potential of your brand on social media with our expert management services.</p>
                      <p class="mt-4" style={{fontSize:"1rem", color:"#d0a10c"}}><b>Starts at ₹ 999* only</b></p>
                      </div>
                    </div>
                    <a href="/socialmediamanagement"><button class="btn btn-info mt-3" style={{backgroundColor:"#d0a10c",border:"#d0a10c"}}>View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 1 END */}
                {/* SERVICE CARD 2 START */}
                <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/female-multitasking-work_23-2148390868.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> Digital Marketing </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Elevate your brand's online presence with our comprehensive digital marketing services.</p>
                      <p class="mt-4" style={{fontSize:"1rem", color:"#d0a10c"}}><b>Starts at ₹ 999* only</b></p>
                      </div>
                    </div>
                    <a href="/digitalmarketing"><button class="btn btn-info mt-3" style={{backgroundColor:"#d0a10c",border:"#d0a10c"}}>View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 2 END */}
             {/* SERVICE CARD 3 START */}
                <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/online-article-concept-illustration_114360-8360.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> Copywriting </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Words matter. Let us craft powerful and persuasive copy that connects with your audience.</p>
                      <p class="mt-4" style={{fontSize:"1rem", color:"#d0a10c"}}><b>Starts at ₹ 499* only</b></p>
                      </div>
                    </div>
                    <a href="/copywriting"><button class="btn btn-info mt-3" style={{backgroundColor:"#d0a10c",border:"#d0a10c"}}>View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 3 END */}
              {/* SERVICE CARD 4 START */}
            <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/typing-concept-illustration_114360-4121.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> Content Writing </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Create compelling content that engages your audience and drives results. Let us help.</p>
                      <p class="mt-4" style={{fontSize:"1rem", color:"#d0a10c"}}><b>Starts at ₹ 499* only</b></p>
                      </div>
                    </div>
                    <a href="/contentwriting"><button class="btn btn-info mt-3" style={{backgroundColor:"#d0a10c",border:"#d0a10c"}}>View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 4 END */}
            {/* SERVICE CARD 5 START */}
            <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/design-team-concept-illustration_114360-6661.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> Graphics Designing </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Captivate your audience with visually stunning graphics and design that brings your brand to life.</p>
                      <p class="mt-4" style={{fontSize:"1rem", color:"#d0a10c"}}><b>Starts at ₹ 499* only</b></p>
                      </div>
                    </div>
                    <a href="/graphicsdesigning"><button class="btn btn-info mt-3" style={{backgroundColor:"#d0a10c",border:"#d0a10c"}}>View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 5 END */}
               {/* SERVICE CARD 6 START */}
            <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/seo-analytics-team-concept-illustration_114360-9255.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> Search Engine Optimization </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Get found online. Boost your search engine rankings with our expert SEO services.</p>
                      <p class="mt-4" style={{fontSize:"1rem", color:"#d0a10c"}}><b>Starts at ₹ 999* only</b></p>
                      </div>
                    </div>
                    <a href="/seo"><button class="btn btn-info mt-3" style={{backgroundColor:"#d0a10c",border:"#d0a10c"}}>View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 6 END */}
                {/* SERVICE CARD 7 START */}
            <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/ecommerce-web-page-concept-illustration_114360-8224.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> E-Commerce Store Setup </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Open your online store in no time. Let us set up your E-commerce store.</p>
                      <p class="mt-4" style={{fontSize:"1rem", color:"#d0a10c"}}><b>Starts at ₹ 2999* only</b></p>
                      </div>
                    </div>
                    <a href="/ecommercestoresetup"><button class="btn btn-info mt-3" style={{backgroundColor:"#d0a10c",border:"#d0a10c"}}>View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 7 END */}
              {/* SERVICE CARD 8 START */}
            <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/newsletter-concept-illustration_114360-4698.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> Emailing and Messaging Campaigns </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Stay connected with your audience through strategic email and messaging campaigns.</p>
                      <p class="mt-4" style={{fontSize:"1rem", color:"#d0a10c"}}><b>Starts at ₹ 999* only</b></p>
                      </div>
                    </div>
                    <a href="/marketingcampaigns"><button class="btn btn-info mt-3" style={{backgroundColor:"#d0a10c",border:"#d0a10c"}}>View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 8 END */}
             </div>
             {/* MARKETING SECTION END */}

                 {/* FUNDRAISING SECTION START */}
            <div class="row" id="choice-fundraising" hidden={choice_fundraising==0}>
            {/* SERVICE CARD 1 START */}
              <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/flat-design-business-planning-with-device_23-2149151728.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> Project Report Preparation </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Transform your vision into reality with Startup Connect's expert project report preparation service.</p>
                      <p class="mt-4" style={{fontSize:"1rem", color:"#c61a99"}}><b>Starts at ₹ 9999* only</b></p>
                      </div>
                    </div>
                    <a href="/projectreportpreparation"><button class="btn btn-info mt-3" style={{backgroundColor:"#c61a99",border:"#c61a99"}}>View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 1 END */}
               {/* SERVICE CARD 2 START */}
               <div class="col-sm-3 stretch-card grid-margin mt-4"  id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/investing-concept-illustration_114360-2963.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-bold"> Valuations </h5>
                    <div class="d-flex justify-content-between">
                    <div>
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Redefine the essence of your business through professional valuation services provided by experts.</p>
                      <p class="mt-4 text-primary" style={{fontSize:"1rem"}}><b>Starts at ₹ 7999* only</b></p>
                      </div>
                    </div>
                    <a href="/valuation"><button class="btn btn-primary mt-3" /* style={{backgroundColor:"#c61a99",border:"#c61a99"}}*/ >View Service</button></a>
                  </div>
                </div>
              </div>
             {/*}  SERVICE CARD 2 END */}
             </div>
             {/* FUNDRAISING SECTION END */}

            <div class="d-flex justify-content-center">

       <button id="view-all-btn" class="btn btn-dark" onClick={()=>{ 
        setchoice_mostpopular(0);setchoice_businesssetup(1);setchoice_compliances(1);setchoice_consultancy(1);setchoice_drafting(1);setchoice_fundraising(1);setchoice_softwaredev(1);setchoice_websitedev(1); 
        document.getElementById("view-all-btn").style.display="none";document.getElementById("hide-all-btn").style.display="block";
        }}>View All</button>

       <button id="hide-all-btn" style={{display:"none"}} class="btn btn-dark" onClick={()=>{ 
        setchoice_mostpopular(1);setchoice_businesssetup(0);setchoice_compliances(0);setchoice_consultancy(0);setchoice_drafting(0);setchoice_fundraising(0);setchoice_softwaredev(0);setchoice_websitedev(0); 
        document.getElementById("view-all-btn").style.display="block";document.getElementById("hide-all-btn").style.display="none";
        }}>Hide All</button>

          </div>


     {/*}   <div class="container-fluid py-2">


   <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/iso-certification-concept_23-2148696815.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-semibold"> BUSINESS REGISTRATIONS </h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0">
                      Want to setup new business and want to get started. Business Setup Services are here to assist you.</p>
                      
                    </div>
                    <button class="btn btn-primary mt-3" onClick={()=>{ window.location.replace("/client-dashboard-quotations"); }}>View Service</button>
                  </div>
                </div>
              </div>


        <div class="row flex-row flex-nowrap overflow-auto mt-5" style={{scrollbar:"hidden"}}> */}
        {/* SERVICE CARD 1 START 
              <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/iso-certification-concept_23-2148696815.jpg" alt="" style={{height:"32vh",width:"100%"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-semibold"> BUSINESS REGISTRATIONS </h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0">
                      Want to setup new business and want to get started. Business Setup Services are here to assist you.</p>
                      
                    </div>
                    <button class="btn btn-primary mt-3" onClick={()=>{ window.location.replace("/client-dashboard-quotations"); }}>View Service</button>
                  </div>
                </div>
              </div>
               SERVICE CARD 1 END */}

               {/* SERVICE CARD 2 START 
               <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/startup-construction-development-3d-thin-line-art-style-design-concept-isometric-illustration_1284-61110.jpg" alt="" style={{height:"32vh"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-semibold"> STARTUP SERVICES</h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0">
                      manage and view your project quotations received from Startup Connect Associates.</p>
                      
                    </div>
                    <button class="btn btn-warning mt-3" onClick={()=>{ window.location.replace("/client-dashboard-quotations"); }}>View Service</button>
                  </div>
                </div>
              </div>
              {/* SERVICE CARD 2 END */}

              {/* SERVICE CARD 3 START 
              <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/banking-industry-concept-illustration_114360-13934.jpg" alt="" style={{height:"32vh"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-semibold"> DOCUMENTATAION AND FUNDRAISING</h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0">
                      manage and view your project quotations received from Startup Connect Associates.</p>
                      
                    </div>
                    <button class="btn btn-danger mt-3" onClick={()=>{ window.location.replace("/client-dashboard-quotations"); }}>View Service</button>
                  </div>
                </div>
              </div>
              {/* SERVICE CARD 3 END */}
              {/* SERVICE CARD 4 START 
              <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/law-firm-concept-illustration_114360-8606.jpg" alt="" style={{height:"32vh"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-semibold">LEGAL SERVICES </h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0">
                      manage and view your project quotations received from Startup Connect Associates.</p>
                      
                    </div>
                    <button class="btn btn-success mt-3" onClick={()=>{ window.location.replace("/client-dashboard-quotations"); }}>View Service</button>
                  </div>
                </div>
              </div>
              {/* SERVICE CARD 4 END */}
               {/* SERVICE CARD 5 START 
               <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/female-multitasking-work_23-2148390868.jpg" alt="" style={{height:"32vh"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-semibold"> MARKETING SERVICES </h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0">
                      manage and view your project quotations received from Startup Connect Associates.</p>
                      
                    </div>
                    <button class="btn btn-primary mt-3" onClick={()=>{ window.location.replace("/client-dashboard-quotations"); }}>View Service</button>
                  </div>
                </div>
              </div>
              {/* SERVICE CARD 5 END */}
               {/* SERVICE CARD 6 START 
               <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/hand-drawn-flat-design-api-illustration_23-2149365021.jpg" alt="" style={{height:"32vh"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-semibold"> WEBSITE DEVELOPMENT </h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0">
                      manage and view your project quotations received from Startup Connect Associates.</p>
                      
                    </div>
                    <button class="btn btn-warning mt-3" onClick={()=>{ window.location.replace("/client-dashboard-quotations"); }}>View Service</button>
                  </div>
                </div>
              </div>
              {/* SERVICE CARD 6 END */}
               {/* SERVICE CARD 7 START
               <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/ui-ux-designers-isometric-composition-with-small-people-creating-custom-design-web-site-3d-vector-illustration_1284-68939.jpg" alt="" style={{height:"32vh"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-semibold"> SOFTWARE DEVELOPMENT </h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0">
                      Get quality softwares developed in effective time.</p>
                      
                    </div>
                    <button class="btn btn-danger mt-3" onClick={()=>{ window.location.replace("/client-dashboard-quotations"); }}>View Service</button>
                  </div>
                </div>
              </div>
              {/* SERVICE CARD 7 END */}
               {/* SERVICE CARD 8 START 
                <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/managers-looking-chart-monitor_1262-19283.jpg" alt="" style={{height:"32vh"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-semibold"> COMPLIANCES </h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0">
                      manage all your regulatory and compliances are being reviewed adequately.</p>
                      
                    </div>
                    <button class="btn btn-primary mt-3" onClick={()=>{ window.location.replace("/client-dashboard-quotations"); }}>View Service</button>
                  </div>
                </div>
              </div>
              {/* SERVICE CARD 8 END */}

            {/*}  </div> 
              </div> */}

              {/* CHOICE SECTION END */}

</>
      );
}


export default Serviceschoice;