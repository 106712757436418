import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';
import moment from 'moment/moment';

import '../Resources/dashboard.css';





function Add_productgroup(){

  
  
 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const busyid=sessionStorage.getItem("BusyId00");
 const counterid=sessionStorage.getItem("CounterId00");


 
//saving default values with usestate
const state = useState();

//group_name
const[group_name,setgroup_name]=useState();const updategroup_name = (event) => { setgroup_name(event.target.value);}; const[group_name_er,setgroup_name_er]=useState();
//group_type
const[group_type,setgroup_type]=useState();const updategroup_type = (event) => { setgroup_type(event.target.value);}; const[group_type_er,setgroup_type_er]=useState();
//group_industry
const[group_industry,setgroup_industry]=useState();const updategroup_industry = (event) => { setgroup_industry(event.target.value);}; const[group_industry_er,setgroup_industry_er]=useState();
//group_category
const[group_category,setgroup_category]=useState();const updategroup_category = (event) => { setgroup_category(event.target.value);}; const[group_category_er,setgroup_category_er]=useState();
//group_sector
const[group_sector,setgroup_sector]=useState();const updategroup_sector = (event) => { setgroup_sector(event.target.value);}; const[group_sector_er,setgroup_sector_er]=useState();
//group_status
const[group_status,setgroup_status]=useState();const updategroup_status = (event) => { setgroup_status(event.target.value);}; const[group_status_er,setgroup_status_er]=useState();



//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();




  
//******************* ADD PRODUCT GROUP FUNCTION START ********************//
function createproductgroup(){

  //Setting Error Variables
setgroup_name_er(" ");setgroup_type_er(" ");setgroup_industry_er(" ");setgroup_sector_er(" ");setgroup_category_er();setgroup_status_er();seterror_msg(" ");



  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))){
 
 
  axios.post("bt-create-productgroup", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Group_name :group_name,
   Type:group_type,
   Industry : group_industry,
   Sector:group_sector,
   Category:group_category,
   Status:group_status


  })
  .then((response) => {


  //Product Group Limit Reached
  if(response.data.error==111){     seterror_msg(response.data.error_msg); return; }

  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
  }

   //Validation Errors
 if(response.data.validatorerror){
  setgroup_name_er(response.data.validatorerror.Group_name);
  setgroup_type_er(response.data.validatorerror.Type);
  setgroup_industry_er(response.data.validatorerror.Industry);
  setgroup_sector_er(response.data.validatorerror.Sector);
  setgroup_category_er(response.data.validatorerror.Category);
  setgroup_status_er(response.data.validatorerror.Status);

}
//Invalid Entries
if(response.data.error==102){ 
  
  setgroup_name_er(response.data.group_name_er);
  setgroup_type_er(response.data.type_er);
  setgroup_industry_er(response.data.industry_er);
  setgroup_sector_er(response.data.sector_er);
  setgroup_category_er(response.data.category_er);
  setgroup_status_er(response.data.status_er);
 
  seterror_msg("Cannot Create Group!");  }


  //Invalid Entries
  if(response.data.error==103){     seterror_msg(response.data.error_msg);  }

   //Transaction Entered Successfully
   if(response.data.successvar==1){        document.getElementById("success-card1").style.display="block";  }
   //Cannot Add Transaction
   else{  seterror_msg("Cannot Create Group !"); }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* ADD PRODUCT GROUP FUNCTION END ********************//






return(
    <>
                <div class="card">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("create-form1").style.display="none"; }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                  <div class="card-body">
                    <h4 class="card-title">Add Product Group</h4>
                    <div class="form-sample">
                      <p class="card-description">add your product group details here</p>
                      <code>{error_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Group Name<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Red T-Shirt" id="group_name" value={group_name} onChange={updategroup_name} />
                            <code>{group_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="Type Regular" id="group_type" value={group_type} onChange={updategroup_type} />
                            <code>{group_type_er}</code>
                            </div>
                          </div>
                        </div>   
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Industry</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Clothing" id="group_industry" value={group_industry} onChange={updategroup_industry} />
                            <code>{group_industry_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Sector</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Adidas" id="group_sector" value={group_sector} onChange={updategroup_sector} />
                            <code>{group_sector_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                      <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Category</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="On Demand" id="group_category" value={group_category} onChange={updategroup_category} />
                            <code>{group_category_er}</code>
                            </div>
                          </div>
                        </div> 
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Status</label>
                            <div class="col-sm-9">
                            <select class="form-control" id="group_status" style={{padding:"1rem"}} value={group_status} onChange={updategroup_status} >
                              <option value="active">active</option>
                              <option value="inactive">inactive</option>
                              </select>
                              <code>{group_status_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                    
                    

                        
                      
                        <button class="btn btn-primary" style={{fontWeight:"bold"}} onClick={createproductgroup}>Create Group</button>
                      
                       
    
                     
                   
                    </div>
                  </div>
                </div>
    </>

);

}

export default Add_productgroup;