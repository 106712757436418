import React from "react";
import { useState, useEffect, Component } from "react";
import { useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
//import axios from '../../axios.js';
import moment from "moment/moment";

/*
import "../../Resources/homebtcss.css";
import "../../Resources/home2css.css";
import "../../Resources/serviceshomecss.css";
import "../ankitcss.css";
*/

function Whystartupconnect() {
  return (
  <>
                    {/* Why StartUp Connect Starts */}

                    <h2 id="ip-h2"> WHY STARTUP CONNECT ?</h2>
                  <div class="row" style={{ padding: "2rem",  }}>
                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left"}}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn1.iconfinder.com/data/icons/programming-blue-1/64/Process-512.png"
                            style={{ height: "6rem", width: "6rem", borderRadius: "9999px" }}
                          />
                        </div>

                        <h4 class="mt-3">Complete Digital Process</h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn4.iconfinder.com/data/icons/business-blue-series-set-4/64/a-26-512.png"
                            style={{ height: "6rem", width: "6rem", borderRadius: "9999px" }}
                          />
                        </div>
                        <h4 class="mt-3">
                          Expert Assistance
                        </h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn0.iconfinder.com/data/icons/offer-and-discount-labels-gradient/64/BEST_PRICE-sale-offer-shopping-discount-512.png"
                            style={{ height: "6rem", width: "6rem", borderRadius:"9999px"}}
                          />
                        </div>
                        <h4 class="mt-3">Budget Prices</h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn0.iconfinder.com/data/icons/leto-survey-rating/64/__rate_rating_stars_evaluation-256.png"
                            style={{ height: "6rem", width: "6rem", borderRadius:"9999px" }}
                          />
                        </div>
                        <h4 class="mt-3">
                          Customer Support{" "}
                  
                        </h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn0.iconfinder.com/data/icons/ecommerce-594/24/refund_money_back_guarantee_cashback_money_back_return-64.png"
                            style={{ height: "6rem", width: "6rem", borderRadius:"9999px" }}
                          />
                        </div>
                        <h4 class="mt-3">
                          Efficiency{" "}

                        </h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn1.iconfinder.com/data/icons/fitness-and-sport-4/256/Cycling-256.png"
                            style={{ height: "6rem", width: "6rem", borderRadius:"9999px" }}
                          />
                        </div>
                        <h4 class="mt-3">
                          Simple and fast Process{" "}
                        </h4>
                      </div>
                    </div>
                  </div>
                  {/* Why StartUp Connect Ends */}
  </>

  );

}

  export default Whystartupconnect;
