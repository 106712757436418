import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';




/*import Amanbg from './amanbg';*/


function Educationdetails(){

  

    return(
<>

{/* EDUCATIONAL DETAILS SECTION START */}
<div class="content-wrapper">

<div class="col-12">


                <div class="card">
                  <div class="card-body">
                    <h2 >Educational Details</h2>
                    <div class="form-sample" style={{textAlign:"left"}}>
                      <p class="card-description"> Educational information Form </p>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Course Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="firstname" style={{border:"0.1rem solid black"}}  />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Course Type</label>
                            <div class="col-sm-9">
                            <select class="form-control" style={{padding:"1rem", border:"0.1rem solid black"}}>
                                <option>PHD.</option>
                                <option>Master's Degree</option>
                                <option>Bachelor's Degree</option>
                                <option>Certification Course</option>
                                <option>Diploma</option>
                                <option>10+2</option>
                                <option>10</option>
                                <option>Others</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Accredation</label>
                            <div class="col-sm-9">
                            <select class="form-control" style={{padding:"1rem", border:"0.1rem solid black"}}>
                                <option>Percentage</option>
                                <option>Marks</option>
                                <option>C.G.P.A.</option>
            
                              </select>                            
                              </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type Value</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" id="firstname" style={{border:"0.1rem solid black"}}  />
                      </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Institution Name</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" id="firstname" style={{border:"0.1rem solid black"}}  />               
                              </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">City</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" id="firstname" style={{border:"0.1rem solid black"}}  />
                      </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">State</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" id="firstname" style={{border:"0.1rem solid black"}}  />               
                              </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Country</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" id="firstname" style={{border:"0.1rem solid black"}}  />
                      </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Course Start Date</label>
                            <div class="col-sm-9">
                            <input type="date" class="form-control" id="firstname" style={{border:"0.1rem solid black"}}  />               
                              </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Course End Date</label>
                            <div class="col-sm-9">
                            <input type="date" class="form-control" id="firstname" style={{border:"0.1rem solid black"}}  />
                      </div>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex justify-content-center">
                    <button type="button" class="btn btn-gradient-primary btn-rounded btn-icon" >
                            <i class="mdi mdi-plus-circle-outline" style={{fontSize:"2.8rem"}} ></i>
                          </button>
                          </div>
                          <br/>
                          <br/>
                          <br/>

                          <button type="button" class="btn btn-outline-danger btn-icon-text" style={{}}  onClick={()=>{        
                        //Back Button                  
                          document.getElementById("section-2").style.display="block";//Showing previous section
                          document.getElementById("section-3").style.display="none";//Hiding next section
                          sessionStorage.setItem("pval","20%");// Updating Progress Bar
                      }} > <i class="mdi mdi-chevron-double-left"></i>Back </button>
                     <button type="button" class="btn btn-outline-success btn-icon-text" style={{marginLeft:"3rem"}}  onClick={()=>{        
                        //Next Button                  
                          document.getElementById("section-4").style.display="block";//Showing next section
                          document.getElementById("section-3").style.display="none";//Hiding previous section
                          sessionStorage.setItem("pval","40%");// Updating Progress Bar
                      }} > Next <i class="mdi mdi-chevron-double-right"></i></button>      
                      <button type="button" class="btn btn-gradient-dark float-end">Save</button>
                    </div>
                  </div>
                </div>
              </div>

              </div>
              {/* EDUCATIONAL DETAILS SECTION END */}

              </>
);
    }

export default Educationdetails;