import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from 'axios';


function CandidateprofileAchievements(){

  

    return(
<>

                 {/* ACHIEVEMENTS SECTION START */}
                 <div class="hire-form-panel" style={{backgroundColor:"#ffffff",display:"none"}} id="achievements-section">
            <h3>Achievements</h3>
            <p style={{color:"#acafb0"}}>Add your achievements that will be displayed in your profile and detailed resume.</p>


            <div class="hire-form-list">
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Achievement Title</div>
           <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Employee of the Month" /></div>
           </div>
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Details</div>
           <div class="hire-form-group-right"><textarea class="hire-form-group-input" placeholder="For e.g. : completed 500+ bug fixes in a month and incraesed website performance." /> 
           </div>
           </div>
           </div>

           <div class="hire-form-list">
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Date Received</div>
           <div class="hire-form-group-right"><input type="date" class="hire-form-group-input" /></div>
           </div>
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Link (if any)</div>
           <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : https://www.achievmentlink/" /> 
           </div>
           </div>
           </div>

            <div class="hire-form-btn">
           <button style={{backgroundColor:"#ffffff",border:"none", marginTop:"2rem"}}><img src="https://cdn-icons-png.flaticon.com/128/10337/10337579.png" class="hire-card-icon" style={{height:"4rem",width:"4rem",}} /></button>
           </div>
          
            <div class="hire-form-btn">
            <button class="hire-btn-default" style={{backgroundColor:"#12d571",marginRight:"2rem",marginTop:"2rem",marginBottom:"2rem"}}
            onClick={()=>{ document.getElementById("achievements-section").style.display="none";
            document.getElementById("other-links-section").style.display="block";
             }}
            >Save and Next</button>
            </div>

            </div>
            {/* ACHIEVEMENTS SECTION END */}
          
              
</>
);
    }

export default CandidateprofileAchievements;