import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';
import moment from 'moment/moment';

import '../Resources/dashboard.css';


function Create_contra(){


  
   //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const busyid=sessionStorage.getItem("BusyId00");
 const counterid=sessionStorage.getItem("CounterId00");


 //Checking if Sessions are Set

// alert(logstatus,loguser,logadminid);
 //Sessions Not Set
 {/* if(((logstatus==null)||(logstatus==""))||((logid==null)||(logid==""))||((token==null)||(token==" "))||((busyid==null)||(busyid==" "))||((counterid==null)||(counterid==" "))){
  
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");
   
 
    window.location.replace("/businesstools-login");
   }
  
   //Sessions Set
   else{
   */}


    
      
//Variables
const[Tbanks,setTbanks] = useState([]);//SetData Variable

//Error Variable
const[errorvar,seterrorvar] = useState();

//date
const[date,setdate]=useState();const updatedate = (event) => { setdate(event.target.value);}; const[date_er,setdate_er]=useState();
//from
const[from,setfrom]=useState();const updatefrom = (event) => { setfrom(event.target.value);}; const[from_er,setfrom_er]=useState();
//to
const[to,setto]=useState();const updateto = (event) => { setto(event.target.value);}; const[to_er,setto_er]=useState();
//particular
const[particular,setparticular]=useState();const updateparticular = (event) => { setparticular(event.target.value);}; const[particular_er,setparticular_er]=useState();
//amount
const[amount,setamount]=useState();const updateamount = (event) => { setamount(event.target.value);}; const[amount_er,setamount_er]=useState();
//from_bank_id
const[from_bank_id,setfrom_bank_id]=useState();const updatefrom_bank_id = (event) => { setfrom_bank_id(event.target.value);}; const[from_bank_id_er,setfrom_bank_id_er]=useState();
//to_bank_id
const[to_bank_id,setto_bank_id]=useState();const updateto_bank_id = (event) => { setto_bank_id(event.target.value);}; const[to_bank_id_er,setto_bank_id_er]=useState();



//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();




useEffect(()=>{
   
  getData();
  
  },[])
 

  
    
  
//******************* GET BANKS DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))){
 
 
 axios.post("bt-get-banks", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Sn_start :0,
   Sn_end :0,
   Sn_total :0,
   Sn_limit :0
 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setTbanks(response.data.data);
  
  }
  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* GET BANKS DATA FUNCTION END  ********************//


 
  
//******************* CREATE CONTRABOOK FUNCTION START ********************//
function createcontra(){

  //Setting Error Variables
setdate_er(" ");setfrom_er(" ");setto_er();setfrom_bank_id_er();setto_bank_id_er();setparticular_er(" ");setamount_er(" ");seterror_msg(" ");



  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))){
 
 
  axios.post("bt-create-contra", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Date : moment(date).format('YYYY-MM-DD'),
   From:from,
   To:to,
   From_bank_id:from_bank_id,
   To_bank_id:to_bank_id,
   Particular : particular,
   Amount : amount,
 })
 .then((response) => {

  if(response.data.error==111){     seterror_msg(response.data.error_msg); return; }

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
  }

   //Validation Errors
 if(response.data.validatorerror){
  setdate_er(response.data.validatorerror.Date);
  setfrom_er(response.data.validatorerror.From);
  setto_er(response.data.validatorerror.To);
  setfrom_bank_id_er(response.data.validatorerror.From_bank_id);
  setto_bank_id_er(response.data.validatorerror.To_bank_id);
  setparticular_er(response.data.validatorerror.Particular);
  setamount_er(response.data.validatorerror.Amount);
  return;

}
//Invalid Entries
if(response.data.error==102){ 
  
  setdate_er(response.data.date_er);
  setfrom_er(response.data.from_er);
  setto_er(response.data.to_er);
  setfrom_bank_id_er(response.data.from_bank_id_er);
  setto_bank_id_er(response.data.to_bank_id_er);
  setparticular_er(response.data.particular_er);
  setamount_er(response.data.amount_er);
 
  seterror_msg("Cannot Add Transaction!"); 
  return; }


  //Invalid Entries
  if(response.data.error==103){     seterror_msg(response.data.error_msg); return; }

   //Transaction Entered Successfully
   if(response.data.successvar==1){        document.getElementById("success-card").style.display="block";  }
   //Cannot Add Transaction
   else{  seterror_msg("Cannot Add Contra Entry !"); return; }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* CREATE BANKBOOK FUNCTION END ********************//


return(
    <>
                <div class="card">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("create-form").style.display="none"; }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                  <div class="card-body">
                    <h4 class="card-title">Contra Transaction</h4>
                    <div class="form-sample">
                      <p class="card-description">enter your contra entry transactions here</p>
                      <code>{error_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control"  id="date" value={date} onChange={updatedate}  />
                              <code>{date_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Amount<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="5000.00" id="amount" value={amount} onChange={updateamount}  />
                              <code>{amount_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                      <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">From<code>*</code></label>
                            <div class="col-sm-9">
                            <select class="form-control" placeholder="Select From" style={{padding:"1rem"}} id="from" value={from} onChange={updatefrom}>
                              <option value="">Select From</option>
                              <option value="cash">Cash</option>
                              <option value="bank">Bank</option>
                              </select>
                              <code>{from_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Select Bank</label>
                            <div class="col-sm-9">
                              <select class="form-control" placeholder="Select Bank" style={{padding:"1rem"}} id="from_bank_id" value={from_bank_id} onChange={updatefrom_bank_id}>
                              <option value="">Select Bank</option>
                              {
                              Tbanks.map((curElm2,ind)=>{

                              return(
                              <>
                              <option value={curElm2.Bank_id}>{curElm2.Bank_name}</option>
                              </>
                              )
                              }
	 				   
	                         		)
                              }
                              </select>
                              <code>{from_bank_id_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                      <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">To<code>*</code></label>
                            <div class="col-sm-9">
                              <select class="form-control" placeholder="Select From" style={{padding:"1rem"}} id="to" value={to} onChange={updateto}>
                              <option value="">Select To</option>
                              <option value="cash">Cash</option>
                              <option value="bank">Bank</option>
                              </select>
                              <code>{to_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Select Bank</label>
                            <div class="col-sm-9">
                              <select class="form-control" placeholder="Select Bank" style={{padding:"1rem"}} id="to_bank_id" value={to_bank_id} onChange={updateto_bank_id}>
                              <option value="">Select Bank</option>
                              {
                              Tbanks.map((curElm2,ind)=>{

                              return(
                              <>
                              <option value={curElm2.Bank_id}>{curElm2.Bank_name}</option>
                              </>
                              )
                              }
	 				   
	                         		)
                              }
                              </select>
                              <code>{to_bank_id_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="exampleTextarea1">Particular</label>
                        <textarea class="form-control" rows="2" id="particular" value={particular} onChange={updateparticular} ></textarea>
                      </div>
                  
                      
                        <button class="btn btn-primary" style={{fontWeight:"bold"}} onClick={createcontra}>Submit</button>
                      
                        
                      
                        
                      
                       
    
                     
                   
                    </div>
                  </div>
                </div>
    </>

);

}

//}

export default Create_contra;