import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios';
//import Navbar from '../../navbar.js';
import Navbar from './navbar.js';
import Sidebar from './sidebar.js';
//import Topanalytics from './topanalytics.js';
//import Topchart from './topchart.js';
//import Createteam from './createteam.js';
//import Editteam from './editteam';
//import Addteammember from './addteammember.js';



/*import Amanbg from './amanbg';*/


function Admin_quotation_page(){


  
  
   // Setting Values fom Session Storage
 const logstatus=sessionStorage.getItem("loginstatus00");//loginstatus
 const loguser=sessionStorage.getItem("username00");//username
 const logtype=sessionStorage.getItem("logintype00");//logintype
 const logname=sessionStorage.getItem("loginname00");//loginname
 const logadminid=sessionStorage.getItem("loginadminid00");//loginadminid
 //const logteamid=sessionStorage.getItem("loginteamid00");//loginteamid

 
 //Variables
 const[data,setData] = useState([]);//SetData Variable
 const[data1,setData1] = useState([]);//SetData Variable
 //Error Variable
 const[errorvar,seterrorvar] = useState();
 //Error Variable
 const[errorvar1,seterrorvar1] = useState();


 
 //Filter Variables
 const[load_val,setload_val] = useState(0);
 const[quotation_no_filter,setquotation_no_filter] = useState();
 const[client_id_filter,setclient_id_filter] = useState(0);
 const[client_name_filter,setclient_name_filter] = useState(0);
 const[contact_filter,setcontact_filter] = useState(0);
 const[email_filter,setemail_filter] = useState(0);
 const[created_by_filter,setcreated_by_filter] = useState(0);
 const[member_id_filter,setmember_id_filter] = useState(0);
 const[start_date_val,setstart_date_val] = useState(0);
 const[end_date_val,setend_date_val] = useState(0);      

    //Sessions Not Set
  {/*} if(((logstatus=="false")||(logstatus==null))&&(loguser==null)){
 
      sessionStorage.removeItem("username00");
      sessionStorage.removeItem("loginstatus00");
      sessionStorage.removeItem("logintype00");
      sessionStorage.removeItem("loginname00");
      sessionStorage.removeItem("loginadminid00");
     
      alert("Session Expired! Login Again.");
      window.location.replace("/");
    
    }
    //Sessions Set
  else{
  */}
     
      

  //Use Effect, Data Load
  useEffect(()=>{

    getData();

},[])



//Fetching Team Members
function getData(){
  

    //Checking if user loggedin
  if(((logstatus=="true")||(logstatus!=null))&&(loguser!=null)&&(logadminid!=null)){
  
  
   
   axios.post("services-get-quotationlist", {
      //Basic Details
      User:loguser,
      Logtype:logtype,
      Log_id:logadminid,
      Logstatus:"true",
      User_id:logadminid,
      Account_type:logtype,
      Member_id:logadminid,
      Load_val:load_val,
      Operation:"getdata",
  
      //Filter Val
      Quotation_no_filter:quotation_no_filter,
      Client_id_filter:client_id_filter, 
      Client_name_filter:client_name_filter,
      Contact_filter:contact_filter,
      Email_filter:email_filter,
      Created_by_filter:created_by_filter,
      Member_id_filter:member_id_filter
   })
   .then((response) => {
    //Success Data Fetched
    if(response.data.successvar==1){
     //Setting Data
     setData(response.data.quotation_list);
    // console.log(response.data);
    //setdatacount(response.data.datacount);
     //setloadcount(response.data.loadcount);
     //setloadcount(1); 
    }
    //Cannot Fetch Data
    else{
     seterrorvar1("Cannot Fetch Data !");
    }
   
  
   });
  
   }
  //User not Logedin
  else{
  
   sessionStorage.removeItem("username00");
   sessionStorage.removeItem("loginstatus00");
   sessionStorage.removeItem("logintype00");
   sessionStorage.removeItem("loginname00");
   sessionStorage.removeItem("loginadminid00");
  
   alert("Session Expired! Login Again.");
   window.location.replace("/");
  
    }
  
  }
  
  



    return(
<>
{/*<!DOCTYPE html> */}
<html lang="en">
  <head>
    {/* Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>theStartupConnect | Portal</title>
    {/* plugins:css */}
    <link rel="stylesheet" href="./assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="./assets/vendors/css/vendor.bundle.base.css" />
    {/* endinject */}
    {/* Plugin css for this page */}
    {/* End plugin css for this page */}
    {/* inject:css */}
    {/* endinject */}
    {/* Layout styles */}
    <link rel="stylesheet" href="./assets/css/style.css" />
    {/* End layout styles */}
   {/*} <link rel="shortcut icon" href="./assets/images/favicon.ico" /> */}
  </head>
  <body>

  


<Navbar/>

{/* MAIN PAGE COMPONENT START */}
  <div class="container-fluid page-body-wrapper">

 <Sidebar/>


{/* MAIN PANEL START */}
<div class="main-panel" style={{marginTop:"5vh"}}>
<div class="content-wrapper">


{/* ADD NEW TEAM MEMBER SECTION START */}
<div class="addteammember-box" id="addteammember-box"  style={{position:"fixed",marginLeft:"2%",marginTop:"-2%",width:"70%",backgroundColor:"#fdffff",display:"none",zIndex:"3",boxShadow:"0.1rem 0.1rem 0.1rem 0.1rem #c7d0ce"}}>
{/*<Addteammember/>*/}

</div>





<h2 class="text-center mb-2">Quotations</h2>
       {/*}  <Topanalytics/> */}
       
       {/*  MANAGE TEAM SECTION START */}


         {/* Team Members Table Start */}
         <div class="row">
              <div class="col-12 grid-margin">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Quotations</h4>
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th>Quotation_no</th>
                            <th> Date</th>
                            <th>Member Id</th>
                            <th> Client Id </th>
                            <th> Client Name </th>
                            <th> Contact </th>
                            <th> Email </th>
                            <th> Amount </th>
                            <th>Created By</th>
                            <th>Status</th>
                            <th>Created at</th>
                            <th> View </th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                       data.map((curElm,ind)=>{

return(
  <>
                          <tr key={curElm.Id}>
                            <td>{curElm.Quotation_no}</td>
                            <td> {curElm.Date} </td>
                            <td> {curElm.Member_id} </td>
                            <td> {curElm.Client_id} </td>
                            <td>{curElm.Client_name}</td>
                            <td>{curElm.Client_contact}</td>
                            <td>{curElm.Client_email}</td>
                            <td>{curElm.Amount}</td>
                            <td>{curElm.Created_by}</td>
                            <td><label class="badge badge-gradient-success">{curElm.Status}</label></td>
                            <td style={{fontSize:"0.6rem"}}><label >{curElm.Date_created} at {(curElm.Time_created)}</label></td>
                            <td><button type="button" class="btn btn-gradient-dark"onClick={()=>{
                              sessionStorage.setItem("loadVal010",curElm.Id);
                              window.location.replace("/view-admin-quotation");
                            }} >View</button></td>
                          </tr>
                         
                          
                          </>
      )
      }
					   
			)}
                 
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Team Members Table End */}

         
      {/*}   <Topchart/> */}
{/*<Pageheader/>
<Topanalytics/>*/}
    
</div>
</div>
{/* MAIN PANEL END */}

</div>
{/* MAIN PAGE COMPONENT END */}

    {/* container-scroller */}
    {/* plugins:js */}
    <script src="./assets/vendors/js/vendor.bundle.base.js"></script>
    {/* endinject */}
    {/* Plugin js for this page */}
    {/* End plugin js for this page */}
    {/* inject:js */}
    <script src="./assets/js/off-canvas.js"></script>
    <script src="./assets/js/hoverable-collapse.js"></script>
    <script src="./assets/js/misc.js"></script>

    {/*} plugins:js */}
    <script src="assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="assets/vendors/chart.js/Chart.min.js"></script>
    <script src="assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="assets/js/off-canvas.js"></script>
    <script src="assets/js/hoverable-collapse.js"></script>
    <script src="assets/js/misc.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="assets/js/dashboard.js"></script>
    <script src="assets/js/todolist.js"></script>
    {/*} End custom js for this page */}
    {/* endinject */}
  </body>
</html>
</>
    );
}
//}

export default Admin_quotation_page;