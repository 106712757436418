import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import '../Resources/dashboard.css';
import Navbar from '../navbar';
import axios from '../../axios.js';

function Businesstools_adminregister(){


//saving default values with usestate
const state = useState();

//name
const[name,setname]=useState();const updatename = (event) => { setname(event.target.value);}; const[name_er,setname_er]=useState();
//username
const[username,setusername]=useState();const updateusername = (event) => { setusername(event.target.value);}; const[username_er,setusername_er]=useState();
//contact
const[contact,setcontact]=useState();const updatecontact = (event) => { setcontact(event.target.value);}; const[contact_er,setcontact_er]=useState();
//email
const[email,setemail]=useState();const updateemail = (event) => { setemail(event.target.value);};  const[email_er,setemail_er]=useState();
//password
const[password,setpassword]=useState();const updatepassword = (event) => { setpassword(event.target.value);};  const[password_er,setpassword_er]=useState();
//cpassword
const[cpassword,setcpassword]=useState();const updatecpassword = (event) => { setcpassword(event.target.value);};  const[cpassword_er,setcpassword_er]=useState();
//city
const[city,setcity]=useState();const updatecity = (event) => { setcity(event.target.value);};  const[city_er,setcity_er]=useState();
//cstate
const[cstate,setcstate]=useState();const updatecstate = (event) => { setcstate(event.target.value);};  const[cstate_er,setcstate_er]=useState();


//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();


/***************** REGISTER FUNCTION START  ******************/
function register(){


//Setting Error Variables
setname_er(" "); setusername_er(" ");setemail_er(" ");setcontact_er(" ");setpassword_er(" ");setcpassword_er(" ");setcity_er(" ");setcstate_er(" ");
seterror_msg(" ");


//Fetching Form Data
  let noerror = 0;
  const $name = document.getElementById("name").value; if($name==""){ setname_er("Name is required !");noerror = noerror+1; }//Fetching Name Value
  const $username = document.getElementById("username").value; if($username==""){ setusername_er("Username is required !");noerror = noerror+1; }//Fetching Username Value
  const $email = document.getElementById("email").value; if($email==""){ setemail_er("Email is required !");noerror = noerror+1; }//Fetching Email Value
  const $contact = document.getElementById("contact").value; if($contact==""){ setcontact_er("Contact is required !");noerror = noerror+1; }//Fetching contact Value
  const $password = document.getElementById("password").value; if($password==""){ setpassword_er("Password is required !");noerror = noerror+1; }//Fetching Password Value
  const $cpassword = document.getElementById("cpassword").value; if($cpassword==""){ setcpassword_er("Confirm Password is required !");noerror = noerror+1; }//Fetching Confirm Password Value
  const $city = document.getElementById("city").value; if($city==""){ setcity_er("City is required !");noerror = noerror+1; }//Fetching City Value
  const $state = document.getElementById("cstate").value; if($state==""){ setcstate_er("State is required !");noerror = noerror+1; }//Fetching state Value
  //Fetching User Consent Acceptance
  const iagree = document.getElementById('iagree');
  //User Agrees to Terms and Conditions and privacy policy continue to registration
if (iagree.checked) {


//No primary errors
if(noerror==0){
  //Sending Data through axios
  axios.post("bt-admin-register", { 
    Name:$name,
   Username:$username,
   Email:$email,
   Contact:$contact,
   Password:$password,
   Confirm_password:$cpassword,
   City:$city,
   State:$state,
   Country:"India"
 
})

 .then((response) => {
   

   //Validation Errors
 if(response.data.validatorerror){
   setname_er(response.data.validatorerror.Name);
   setusername_er(response.data.validatorerror.Username);
   setcontact_er(response.data.validatorerror.Contact);
   setemail_er(response.data.validatorerror.Email);
   setpassword_er(response.data.validatorerror.Password);
   setcpassword_er(response.data.validatorerror.Confirm_password);
   setcity_er(response.data.validatorerror.City);
   setcstate_er(response.data.validatorerror.State);
 
 }
 //Invalid Entries
 if(response.data.error==102){ 
    
    setname_er(response.data.name_er); 
    setusername_er(response.data.username_er); 
    setemail_er(response.data.email_er); 
    setcontact_er(response.data.contact_er); 
    setpassword_er(response.data.password_er); 
    setcity_er(response.data.city_er); 
    setcstate_er(response.data.state_er); 
    //setcountry_er(response.data.country_er); 


}

//Password and Confirm Password don't match
 if(response.data.error==103){ seterror_msg(response.data.password_er); }

 
//Username Already Exists
if(response.data.error==104){ seterror_msg(response.data.error_msg); }


//Name Already Exists
if(response.data.error==105){ seterror_msg(response.data.error_msg); }

 //Success
 if(response.data.successvar==1){
  
  //Setting Sessions for OTP verification
  sessionStorage.setItem("otp_sn", response.data.admin_sn);
  sessionStorage.setItem("otp_id", response.data.admin_id);
 
 //window.location.replace("/businesstools-startworking");
 document.getElementById("success-card").style.display="block";
 
 }
 //failure
 if(response.data.successvar==2){
 seterror_msg("Account Not Created!");
 }
}    );

}
//Return Errors
else{
  return;
  }

}
//User Not Select the CheckBox
else{
  //Show error
  seterror_msg("You need to agree to our Terms and Conditions and Privacy Policy to Continue!");
}




}
/***************** REGSITER FUNCTION END  ******************/


return(
    <>

<html lang="en">
  <head>
    {/* Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Business Tools|BookKeeping Solution</title>
    {/* plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/* endinject */}
    {/* Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/* End plugin css for this page */}
    {/* inject:css */}
    {/* endinject */}
    {/* Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/* End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
    </head>
    <body>
    {/* container-scroller */}
    <div class="container-scroller">

   {/* partial */}
   <div class="container-fluid page-body-wrapper">

        {/*}  <Navbar/>  */}

          <div class="main-panel">
          <div class="d-flex justify-content-center">

              {/* SUCCESS CARD START */}
              <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Account Created Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                    {/*}  <a href="/businesstools-login"><button class="mt-3 btn btn-outline-success">Continue</button></a> */}
                      {/*<a href="/businesstools-otpverify"><button class="mt-3 btn btn-outline-success">Continue</button></a> */}
                      <a href="/login"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              {/* SUCCESS CARD END */}

          <div class="col-12 grid-margin">
          <div class="content-wrapper pb-0">
                <div class="card" style={{textAlign:"left"}}>
                  <div class="card-body">
                    <h4 class="card-title">User Registration</h4>
                    <div class="form-sample">
                      <p class="card-description">register now and become part of the community.</p>
                      <p><code>{error_msg}</code></p>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Enter Name" id="name" value={name} onChange={updatename} />
                              <code>{name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Username</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Enter Username" id="username" value={username} onChange={updateusername} />
                              <code>{username_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Password</label>
                            <div class="col-sm-9">
                              <input type="password" class="form-control" placeholder="Password" id="password" value={password} onChange={updatepassword} />
                              <code>{password_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Confirm Password</label>
                            <div class="col-sm-9">
                              <input type="password" class="form-control" placeholder="Confirm Password" id="cpassword" value={cpassword} onChange={updatecpassword} />
                              <code>{cpassword_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Email</label>
                            <div class="col-sm-9">
                              <input type="email" class="form-control" placeholder="Enter Email" id="email" value={email} onChange={updateemail} />
                            <code>{email_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Contact</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Enter Contact" id="contact" value={contact} onChange={updatecontact} />
                              <code>{contact_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">City</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Enter City" id="city" value={city} onChange={updatecity} />
                              <code>{city_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">State</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Enter State" id="cstate" value={cstate} onChange={updatecstate} />
                              <code>{cstate_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row" style={{textAlign:"left"}}>
                      <div class="form-group row">
                      <div class="form-check mx-sm-2">
                        <label class="form-check-label">
                          <input type="checkbox" id="iagree" class="form-check-input"/></label><p style={{marginLeft:"2rem"}}>I hereby confirm that I am above 18 years of age and I agree to the <a href="/termsandconditions">terms and conditions</a> and <a href="/privacy-policy">privacy policy</a> of theStartupConnect Business tools. </p>
                      </div>
                      </div>
                      </div>
                      <button class="btn-lg btn-success" onClick={register}>Register</button>
                   
                    
                    </div>
                  
                  </div>
                </div>
                </div>
              </div>

            
             </div>
             </div>
             </div>
             </div>


    {/* plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/* endinject */}
    {/* Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/* End plugin js for this page */}
    {/* inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/* endinject */}
    {/* Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/* End custom js for this page */}
  </body>
  </html>    
    </>
);

}

export default Businesstools_adminregister;