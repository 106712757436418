import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import moment from 'moment/moment';


import '../Resources/dashboard.css';
import axios from '../../axios.js';
import Navbar from '../navbar';
import Sidebar from '../Sidebar';
import Create_cash from './create_cash';
import LineChart from '../components/Linechart.js';
import BarChart from '../components/Barchart.js';


import Chart from "chart.js/auto";
import { Line, Bar } from "react-chartjs-2";

function Userfinalaccounts(){


  
 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const busyid=sessionStorage.getItem("BusyId00");
 const counterid=sessionStorage.getItem("CounterId00");

 /* PAGE VARIABLES */


 

 //Checking if Sessions are Set

// alert(logstatus,loguser,logadminid);
 //Sessions Not Set
 {/*if(((logstatus==null)||(logstatus==""))||((logid==null)||(logid==""))||((token==null)||(token==" "))||((busyid==null)||(busyid==" "))||((counterid==null)||(counterid==" "))){
  
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");
   
 
    window.location.replace("/businesstools-login");
   }
  
   //Sessions Set
   else{

   */}
  

 
 
//Variables
const[data,setData] = useState([]);//SetData Variable

//Error Variable
const[errorvar,seterrorvar] = useState();
    
 

//FILTER VARIABLES
const[filter_val,setfilter_val] = useState(0);
const[start_date,setstart_date] = useState('1970-01-01'); const updatestart_date = (event) => { setstart_date(event.target.value);}; const[start_date_er,setstart_date_er]=useState();
const[end_date,setend_date] = useState('2100-12-12'); const updateend_date = (event) => { setend_date(event.target.value);}; const[end_date_er,setfilter_end_date_er]=useState();
const[error_msg,seterror_msg] = useState();



 //SETTING DEFAULT STATE VARIABLES  */

 /* Trading - Profit and Loss Account Variables */

const[opening_stock,setopening_stock] = useState();
const[closing_stock,setclosing_stock] = useState();
const[purchases,setpurchases] = useState();
const[sales,setsales] = useState();
const[purchase_returns,setpurchase_returns] = useState();
const[sales_return,setsales_return] = useState();
const[netpurchase,setnetpurchase] = useState();
const[netsales,setnetsales] = useState();
const[expenses,setexpenses] = useState();
const[incomes,setincomes] = useState();
const[other_expenses_paid,setother_expenses_paid] = useState();
const[other_expenses_received,setother_expenses_received] = useState();
const[shipping_carriages_paid,setshipping_carriages_paid] = useState();
const[shipping_carriages_received,setshipping_carriages_received] = useState();
const[discount_allowed,setdiscount_allowed] = useState();
const[discount_received,setdiscount_received] = useState();
const[other_deductions_allowed,setother_deductions_allowed] = useState();
const[other_deductions_received,setother_deductions_received] = useState();
const[interest_paid,setinterest_paid] = useState();
const[interest_received,setinterest_received] = useState();
const[power,setpower] = useState();
const[labour,setlabour] = useState();
const[manufacturing_other_exp,setmanufacturing_other_exp] = useState();
const[advance_salary,setadvance_salary] = useState();
const[salary_paid,setsalary_paid] = useState();
const[due_salary,setdue_salary] = useState();
const[netprofit,setnetprofit] = useState();
const[netloss,setnetloss] = useState();
const[tpltotal,settpltotal] = useState();

/* BALANCE SHEET Variables */
const[capital,setcapital] = useState();
const[netcapital,setnetcapital] = useState();
const[stock,setstock] = useState();
const[cash,setcash] = useState();
const[bank,setbank] = useState();
const[banks,setbanks] = useState();
const[provident_fund,setprovident_fund] = useState();
const[creditors,setcreditors] = useState();
const[debtors,setdebtors] = useState();
const[outstanding_expenses,setoutstanding_expenses] = useState();
const[outstanding_incomes,setoutstanding_incomes] = useState();
const[input_sgst,setinput_sgst] = useState();
const[output_sgst,setoutput_sgst] = useState();
const[input_cgst,setinput_cgst] = useState();
const[output_cgst,setoutput_cgst] = useState();
const[input_igst,setinput_igst] = useState();
const[output_igst,setoutput_igst] = useState();
const[tax_collected,settax_collected] = useState();
const[tax_paid,settax_paid] = useState();
const[assets,setassets] = useState();
const[bstotal,setbstotal] = useState();

//******************* GET DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))){
 
    //Fetching Filter Variables
    const $start_date = start_date;const $end_date = end_date;

 axios.post("bt-get-financial-analysis", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   //Filters
   Start_date : moment($start_date).format('YYYY-MM-DD'),
   End_date : moment($end_date).format('YYYY-MM-DD'),
  

  
//Code Edits for pagination
//'page' : pageNumber




 })
 .then((response) => {




  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
     return;
  }
  //Success Data Fetched
  if(response.data.successvar==1){

    //Setting Data

   //TRADING - PROFIT AND LOSS VARIABLES
   setopening_stock(response.data.opening_stock);
   setclosing_stock(response.data.closing_stock);
   setpurchases(response.data.purchases);
   setsales(response.data.sales);
   setpurchase_returns(response.data.purchase_returns);
   setsales_return(response.data.sales_return);
   setnetpurchase(response.data.netpurchase);
   setnetsales(response.data.netsales);
   setexpenses(response.data.expenses);
   setincomes(response.data.incomes);
   setother_expenses_paid(response.data.other_expenses_paid);
   setother_expenses_received(response.data.other_expenses_received);
   setshipping_carriages_received(response.data.shipping_carriages_received);
   setshipping_carriages_paid(response.data.shipping_carriages_paid);
   setdiscount_allowed(response.data.discount_allowed);
   setdiscount_received(response.data.discount_received);
   setother_deductions_allowed(response.data.other_deductions_allowed);
   setother_deductions_received(response.data.other_deductions_received);
   setinterest_paid(response.data.interest_paid);
   setinterest_received(response.data.interest_received);
   setsalary_paid(response.data.salary_paid);
   setdue_salary(response.data.due_salary);
   setadvance_salary(response.data.advance_salary);
   setpower(response.data.power);
   setlabour(response.data.labour);
   setmanufacturing_other_exp(response.data.manufacturing_other_exp);
   setnetprofit(response.data.netprofit);
   setnetloss(response.data.netloss);
   settpltotal(response.data.tpltotal);

//BALANCE SHEET VARIABLES
setcapital(response.data.capital);
setnetcapital(response.data.netcapital);
setstock(response.data.stock);
setcash(response.data.cash);
setbank(response.data.bank);
setbanks(response.data.banks);
setcreditors(response.data.creditors);
setdebtors(response.data.debtors);
setoutstanding_expenses(response.data.outstanding_expenses);
setoutstanding_incomes(response.data.outstanding_incomes);
setprovident_fund(response.data.provident_fund);
setassets(response.data.assets);
setinput_sgst(response.data.sgst_out);
setoutput_sgst(response.data.sgst_in);
setinput_cgst(response.data.cgst_out);
setoutput_cgst(response.data.cgst_in);
setinput_igst(response.data.igst_out);
setoutput_igst(response.data.igst_in);
settax_collected(response.data.tax_in);
settax_paid(response.data.tax_out);
setbstotal(response.data.bstotal);

  }
  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  


return(
    <>
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Business Tools|BookKeeping Solution</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">

      <Sidebar />
    <div className="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div className="main-panel">

          <div className="content-wrapper pb-0">

          <div className="row">
              <div className="col-12 grid-margin create-form" id="create-form">
              <Create_cash /> 
              </div>
          </div>


            <div className="page-header flex-wrap">
            <div className="header-left"></div>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <a href="#">
                    <p className="m-0 pe-3">FINAL ACCOUNTS</p>
                  </a>
                  <a className="ps-3 me-4" href="#">
                   {/*} <p className="m-0">ADE-00234</p> */}
                  </a>
                </div>
         {/*}       <button type="button" className="btn btn-primary mt-2 mt-sm-0 btn-icon-text"  onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
                  <i className="mdi mdi-plus-circle"></i> New Transaction </button> */}
              </div>
            </div>

            {/*} chart row starts here */}
           
            {/* DATE SECTION STARTS */}
            <div className="row">
              <div className="col-12 grid-margin create-form" id="edit-form">
              <div className="card">
              <div className="card-body" style={{textAlign:"left"}}>
                    <h4 className="card-title">FINANCIAL ANALYSIS</h4>
                    <div className="form-sample">
                      <p className="card-description">get financial analysis of your business</p>
                      <code>{error_msg}</code>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Start Date<code>*</code></label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control"  id="date" value={start_date} onChange={updatestart_date} />
                              <code>{start_date_er}</code>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">End Date<code>*</code></label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control"  id="date" value={end_date} onChange={updateend_date} />
                              <code>{end_date_er}</code>
                            </div>
                          </div>
                        </div>

                      </div>
                      <button class="btn btn-primary btn-lg" onClick={getData}>Analyse</button>
                      </div>
                      </div>
              </div>
              </div>
              </div>
              {/* DATE SECTION ENDS */}


           

              {/* TRADING AND PROFIT AND LOSS ACCOUNT TABLE SECTION START */}
                  
             <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Trading - Profit and Loss Account</h4>
                    <p className="card-description">showing summary of profit and loss as on <b>{end_date}</b>
                    </p>



                    <div className="table-responsive">
                      <table className="table table-hover table-bordered">
                        <thead>
                          <tr >
                            <th style={{fontWeight:"bold"}} >Dr. Particular</th>
                            <th  style={{fontWeight:"bold"}} >Amount</th>
                            <th  style={{fontWeight:"bold"}} >Cr. Particular</th>
                            <th  style={{fontWeight:"bold"}} >Amount</th>
                         
                          </tr>
                        </thead>
                        <tbody style={{textAlign:"left"}}>
                      
                        <tr >
                           <td>Opening Stock</td>
                           <td style={{textAlign:"right"}}>{opening_stock}</td>
                           <td>Closing Stock</td>
                           <td style={{textAlign:"right"}}>{closing_stock}</td>
                        </tr>
                        <tr >
                           <td>Purhases: {purchases}</td>
                           <td style={{textAlign:"right"}}></td>
                           <td>Sales: {sales}</td>
                           <td style={{textAlign:"right"}}></td>
                        </tr>
                        <tr >
                           <td>(-) Purhase Returns: ({purchase_returns})</td>
                           <td style={{textAlign:"right"}}></td>
                           <td>(-) Sales Return: ({sales_return})</td>
                           <td style={{textAlign:"right"}}></td>
                        </tr>
                        <tr >
                           <td>Net Purhases</td>
                           <td style={{textAlign:"right"}}>{netpurchase}</td>
                           <td>Net Sales</td>
                           <td style={{textAlign:"right"}}>{netsales}</td>
                        </tr>
                        <tr >
                           <td>Expenses</td>
                           <td style={{textAlign:"right"}}>{expenses}</td>
                           <td>Incomes</td>
                           <td style={{textAlign:"right"}}>{incomes}</td>
                        </tr>
                        <tr >
                           <td>Other Expenses Paid</td>
                           <td style={{textAlign:"right"}}>{other_expenses_paid}</td>
                           <td>Other Expenses Received</td>
                           <td style={{textAlign:"right"}}>{other_expenses_received}</td>
                        </tr>
                        <tr >
                           <td>Shipping and Carriages Paid</td>
                           <td style={{textAlign:"right"}}>{shipping_carriages_paid}</td>
                           <td>Shipping and Carriages Received</td>
                           <td style={{textAlign:"right"}}>{shipping_carriages_received}</td>
                        </tr>
                        <tr >
                           <td>Discount Allowed</td>
                           <td style={{textAlign:"right"}}>{discount_allowed}</td>
                           <td>Discount Received</td>
                           <td style={{textAlign:"right"}}>{discount_received}</td>
                        </tr>
                        <tr >
                           <td>Other Deductions Allowed</td>
                           <td style={{textAlign:"right"}}>{other_deductions_allowed}</td>
                           <td>Other Deductions Received</td>
                           <td style={{textAlign:"right"}}>{other_deductions_received}</td>
                        </tr>
                        <tr >
                           <td>Interest Paid</td>
                           <td style={{textAlign:"right"}}>{interest_paid}</td>
                           <td>Interest Received</td>
                           <td style={{textAlign:"right"}}>{interest_received}</td>
                        </tr>
                        <tr >
                           <td>Salary Due</td>
                           <td style={{textAlign:"right"}}>{due_salary}</td>
                           <td>Advance Salary</td>
                           <td style={{textAlign:"right"}}>{advance_salary}</td>
                        </tr>
                        <tr >
                           <td>Salary Paid</td>
                           <td style={{textAlign:"right"}}>{salary_paid}</td>
                           <td></td>
                           <td style={{textAlign:"right"}}></td>
                        </tr>
                        <tr >
                           <td>Power</td>
                           <td style={{textAlign:"right"}}>{power}</td>
                           <td></td>
                           <td style={{textAlign:"right"}}></td>
                        </tr>
                        <tr >
                           <td>Labour</td>
                           <td style={{textAlign:"right"}}>{labour}</td>
                           <td></td>
                           <td style={{textAlign:"right"}}></td>
                        </tr>
                        <tr >
                           <td>Other Expenses (manufacturing)</td>
                           <td style={{textAlign:"right"}}>{manufacturing_other_exp}</td>
                           <td></td>
                           <td style={{textAlign:"right"}}></td>
                        </tr>
                        <tr >
                           <td>Net Profit</td>
                           <td class="text-success" style={{textAlign:"right"}}>{netprofit}</td>
                           <td>Net Loss</td>
                           <td class="text-danger" style={{textAlign:"right"}}>{netloss}</td>
                        </tr>
                        <tr >
                           <td><b>TOTAL</b></td>
                           <td style={{textAlign:"right"}}><b>{tpltotal}</b></td>
                           <td><b>TOTAL</b></td>
                           <td style={{textAlign:"right"}}><b>{tpltotal}</b></td>
                        </tr>
                      
                          
                         
                        </tbody>
                      </table>
                    </div>
                    
               
                  </div>
                </div>
              </div>

             {/* TRADING AND PROFIT AND LOSS ACCOUNT TABLE SECTION END */}



            {/* BALANCE SHEET TABLE SECTION START */}
                  
            <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">BALANCE SHEET</h4>
                    <p className="card-description">mini statement of your assets and liablities as on <b>{end_date}</b>
                    </p>



                    <div className="table-responsive">
                      <table className="table table-hover table-bordered">
                        <thead>
                          <tr >
                            <th style={{fontWeight:"bold"}} >LIABILITIES</th>
                            <th  style={{fontWeight:"bold"}} >AMOUNT</th>
                            <th  style={{fontWeight:"bold"}} >ASSETS</th>
                            <th  style={{fontWeight:"bold"}} >AMOUNT</th>
                         
                          </tr>
                        </thead>
                        <tbody style={{textAlign:"left"}}>
                      
                        <tr >
                           <td>Capital: {capital}</td>
                           <td style={{textAlign:"right"}}></td>
                           <td>Stock</td>
                           <td style={{textAlign:"right"}}>{stock}</td>
                        </tr>
                        <tr >
                           <td>(+) Profit: <span class="text-success">{netprofit}</span></td>
                           <td style={{textAlign:"right"}}></td>
                           <td>Cash</td>
                           <td style={{textAlign:"right"}}>{cash}</td>
                        </tr>
                        <tr >
                           <td>(-) Loss: <span class="text-danger">{netloss}</span></td>
                           <td style={{textAlign:"right"}}></td>
                           <td>Bank</td>
                           <td style={{textAlign:"right"}}>{bank}</td>
                        </tr>
                        <tr >
                           <td>Net Capital</td>
                           <td style={{textAlign:"right"}}>{netcapital}</td>
                           <td></td>
                           <td style={{textAlign:"right"}}></td>
                        </tr>
                        <tr >
                           <td>Creditors</td>
                           <td style={{textAlign:"right"}}>{creditors}</td>
                           <td>Debtors</td>
                           <td style={{textAlign:"right"}}>{debtors}</td>
                        </tr>
                        <tr >
                           <td>Outstanding Expenses</td>
                           <td style={{textAlign:"right"}}>{outstanding_expenses}</td>
                           <td>Outstanding Incomes</td>
                           <td style={{textAlign:"right"}}>{outstanding_incomes}</td>
                        </tr>
                        <tr >
                           <td>Salary Due</td>
                           <td style={{textAlign:"right"}}>{due_salary}</td>
                           <td>Advance Salary</td>
                           <td style={{textAlign:"right"}}>{advance_salary}</td>
                        </tr>
                        <tr >
                           <td>Provident Fund</td>
                           <td style={{textAlign:"right"}}>{provident_fund}</td>
                           <td>Assets</td>
                           <td style={{textAlign:"right"}}>{assets}</td>
                        </tr>
                        <tr >
                           <td>Output SGST</td>
                           <td style={{textAlign:"right"}}>{output_sgst}</td>
                           <td>Input SGST</td>
                           <td style={{textAlign:"right"}}>{input_sgst}</td>
                        </tr>
                        <tr >
                           <td>Output CGST</td>
                           <td style={{textAlign:"right"}}>{output_cgst}</td>
                           <td>Input CGST</td>
                           <td style={{textAlign:"right"}}>{input_cgst}</td>
                        </tr>
                        <tr >
                           <td>Output IGST</td>
                           <td style={{textAlign:"right"}}>{output_igst}</td>
                           <td>Input IGST</td>
                           <td style={{textAlign:"right"}}>{input_igst}</td>
                        </tr>
                        <tr >
                           <td>Other Tax Collected</td>
                           <td style={{textAlign:"right"}}>{tax_collected}</td>
                           <td>Other Tax Paid</td>
                           <td style={{textAlign:"right"}}>{tax_paid}</td>
                        </tr>
                        <tr >
                           <td><b>TOTAL</b></td>
                           <td style={{textAlign:"right"}}><b>{bstotal}</b></td>
                           <td><b>TOTAL</b></td>
                           <td style={{textAlign:"right"}}><b>{bstotal}</b></td>
                        </tr>
                      
                          
                         
                        </tbody>
                      </table>
                    </div>
                    
               
                  </div>
                </div>
              </div>

             {/* BALANCE SHEET TABLE SECTION END */}

            
          </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
</>
);

//}
}
export default Userfinalaccounts;