import React from 'react';
import {useState,useEffect,Component} from 'react';
//import axios from './axios';


function Process(){

return(
<>

    {/* PROCESS SECTION START */}
    <div class="process-section" id="service-process">
  <h2 style={{textAlign: "center",marginBottom: "2rem",color:"rgb(33, 48, 60)"}}>Get your Service Delivered in <span style={{color:"rgb(223, 21, 21)",fontWeight: "bold"}}>6</span> Simple Steps</h2>
  <div class="process-list">
    <div class="process-list-card-a" style={{marginLeft: "2%"}}>
   <div class="process-list-item"><img src="https://cdn-icons-png.flaticon.com/512/3565/3565956.png" id="process-item" /></div>
    <div><h4 id="process-list-heading">Select Requirements</h4></div>
  </div>
  <div class="process-list-card-b"><img src="https://cdn-icons-png.flaticon.com/512/1294/1294758.png" id="process-flow" /></div>
  <div class="process-list-card-a">
    <div class="process-list-item"><img src="https://cdn-icons-png.flaticon.com/512/4301/4301577.png" id="process-item" /></div> 
    <div><h4 id="process-list-heading">Upload Documents</h4></div>
   </div>
   <div class="process-list-card-b"><img src="https://cdn-icons-png.flaticon.com/512/1294/1294758.png" id="process-flow" /></div>
   <div class="process-list-card-a">
    <div class="process-list-item"><img src="https://cdn-icons-png.flaticon.com/512/6712/6712857.png" id="process-item" /></div> 
    <div><h4 id="process-list-heading">Consult with Expert</h4></div>
   </div>
   <div class="process-list-card-b"><img src="https://cdn-icons-png.flaticon.com/512/1294/1294758.png" id="process-flow" /></div>

    <div class="process-list-card-a">
   <div class="process-list-item"><img src="https://cdn-icons-png.flaticon.com/512/1087/1087080.png" id="process-item" /></div>
    <div><h4 id="process-list-heading">Pay Service Fee</h4></div>
  </div>
  <div class="process-list-card-b"><img src="https://cdn-icons-png.flaticon.com/512/1294/1294758.png" id="process-flow" /></div>
  <div class="process-list-card-a">
    <div class="process-list-item"><img src="https://cdn-icons-png.flaticon.com/512/2997/2997954.png" id="process-item" /></div> 
    <div><h4 id="process-list-heading">Manage Progress</h4></div>
   </div>
   <div class="process-list-card-b"><img src="https://cdn-icons-png.flaticon.com/512/1294/1294758.png" id="process-flow" /></div>
   <div class="process-list-card-a">
    <div class="process-list-item"><img src="https://cdn-icons-png.flaticon.com/512/9198/9198208.png" id="process-item" /></div> 
    <div><h4 id="process-list-heading">Service Delivered</h4></div>
   </div>

  </div>
  </div>
{/*</div>*/}
    {/* PROCESS SECTION END */}

</>
);
}

export default Process;