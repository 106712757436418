import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
//import axios from '../../axios.js';
import moment from 'moment/moment';

import './Resources/homebtcss.css';
import './Resources/home2css.css';
import './Resources/serviceshomecss.css';
import './Resources/responsiveness.css';


import Chart from "chart.js/auto";
import { Line, Bar } from "react-chartjs-2";
import Footer3 from './Supportingfiles/footer3';
import Navbar3 from './Supportingfiles/navbar3';

function Bthome(){

   

    return(
     <>

<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Business Tools Home</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>

  
  {/* Whatsapp Chat Icon Start */}
  <div class="wtp-chat-container" /*style={{position:"fixed",marginTop:"92vh", marginLeft:"94vw"}}*/>
    <a aria-label="Chat on WhatsApp" href="https://wa.me/919399395256?text=I%20want%20to%20know%20more%20about%20Book%20Keeping%20by%20the%20Startup%20Connect">   
    <img class="wtp-chat-icon" src="https://cdn-icons-png.flaticon.com/128/3938/3938058.png" /*style={{height:"3rem", width:"3rem"}}*/></img>
  </a>
  </div>
  {/* Whatsapp Chat Icon End */}

   
    <div class="home2-container">
    
    <Navbar3/>


    {/* HEADER SECTION START */}
    <section /*class="sv-header-section" */>
    <div class="sv-header-container1">
    <div class="sv-header-left home-header-pd">
        <h1 style={{color:"#004463"}}>Start Your Book Keeping Journey <span id="change-txt-color" style={{color:"#e34802"}}></span></h1>
        <p>Rich in features with multiple businesses and inbuilt PoS and invoice managemenet. Want to get started with digitally managing your accounts. But, can't beacuse of hard to use and costly solutions.</p>
        <p>We have got you covered.</p>
        <div class="d-flex justify-content-left">
        <a href="/businesstools-login"><button id="home2-modules-section-btn" style={{marginLeft:"0"}}>Get Started for Free <i class="mdi mdi-arrow-right"></i></button></a>
        </div>
        <p class="text-muted" style={{fontSize:"1rem",marginTop:"0.2rem"}}>No Credit Card Required*</p>
       
    </div>
    <div class="sv-header-right" /* style={{backgroundColor:"#96dfff",padding:"2rem",borderRadius:"15px",boxShadow:"2px 3px 3px 2px #cbcecf"}} */>
    <img src="/images/Btools/bt-view9.png" style={{width:"90%",height:"100%",borderRadius:"15px",boxShadow:"2px 3px 3px 2px #cbcecf"}} />
    </div>
    </div>
    </section>
    {/* HEADER SECTION END */}
    <hr />
    {/* FEATURES SECTION START */}
    <div class="bth-features-section"  style={{backgroundColor:"#e5ecf3"}}>
      <div class="bth-features-left">
      <p style={{color:"#ce30c1",fontWeight:"bold"}}>What's Special?</p>
      <h2>Embark on a financial journey like never before</h2>
      <p>Our user-friendly interface makes managing your accounts a breeze whether you're a financial pro or just starting. Enjoy the freedom of accessing your data from anywhere with our cloud-based solution. No more tedious manual entry – our automation takes care of regular transaction's ledger entries, ensuring accuracy and saving you time. Get real-time insights into your business, streamline invoicing, and effortlessly track incomes, expenses, inventory and lot more. Also manage cash flows and taxes with ease. <br /><br/><span style={{color:"#bb279f", fontWeight:"bold"}}>Startup Connect's Book-Keeping Solution</span> is your shortcut to efficient and exciting financial management. Elevate your accounting game today!
     </p>
      </div>
      <div class="bth-features-right">
      <div class="bth-features-elm-section">
        <div class="bth-features-elm-section-2">
        <div class="bth-features-elm-card" style={{backgroundColor:"#ffffff"}}>
          <h3>User-Friendly Interface</h3>
          <p style={{fontSize:"1rem"}}>Our accounting software boasts an intuitive and user-friendly interface, ensuring that both seasoned accountants and beginners can navigate and utilize its powerful features effortlessly.</p>
        </div>

        <div class="bth-features-elm-card" style={{marginTop:"2rem",boxShadow:"2px 3px 3px 2px #ffba19", backgroundColor:"#ffffff"}}>
          <h3>Automated Bookkeeping</h3>
          <p style={{fontSize:"1rem"}}>Say goodbye to manual data entry! Our software automates the bookkeeping process, saving you time and reducing the risk of errors. Enjoy accurate financial records with minimal effort.</p>
        </div>
        </div>
        <div class="bth-features-elm-section-2">
          
        <div class="bth-features-elm-card" style={{marginTop:"2rem",boxShadow:"2px 3px 3px 2px #d10bd1", backgroundColor:"#ffffff"}}>
          <h3>Real-Time Financial Reporting</h3>
          <p style={{fontSize:"1rem"}}>Stay informed with real-time financial reporting. Monitor your business's performance with up-to-the-minute data, empowering you to make informed decisions promptly.</p>
        </div>
        <div class="bth-features-elm-card" style={{marginTop:"2rem",boxShadow:"2px 3px 3px 2px #049cb5", backgroundColor:"#ffffff"}}>
          <h3>Invoicing and Billing</h3>
          <p style={{fontSize:"1rem"}}>Streamline your invoicing and billing processes with our comprehensive tools. Create professional invoices, track payments, and manage your cash flow effortlessly.</p>
        </div>
        </div>
      </div>

      </div>
    </div>
    {/* FEATURES SECTION END */}

    <hr />
    {/* PRODUCT IMAGES SECTION START */}
    <div class="bth-images-section" >
    <div class="bth-images-section-top">
    <p style={{color:"#29ba3d",fontWeight:"bold"}}>Explore Features</p>
    <h2>Explore Features of Our Book Keeping Software</h2>
    </div>
    <div class="container-fluid py-2">
    <div class="row flex-row flex-nowrap overflow-auto display-cards-custom" style={{padding:"1rem"}}>

             {/* SERVICE CARD 1 START */}
             <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0" style={{borderBottom:"0.2rem solid #296fba" }}>
                    <img class="img-fluid w-100" src="/images/Btools/bt-view1.png" alt="" />
                  </div>
                  <div class="card-body px-3 text-dark" >
                 
                    <h5 class="font-weight-bold"> MANAGE MULTIPLE BUSINESSES </h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Startup Connect's accounting software revolutionizes business management by allowing users to effortlessly navigate and manage multiple businesses from a centralized platform. No more juggling between different systems or struggling with complex integrations – our intuitive interface ensures a smooth experience for business owners overseeing diverse ventures.</p>
                      
                    </div>
                    </div>
                </div>
              </div>
              {/* SERVICE CARD 1 END */}

             {/* SERVICE CARD 2 START */}
             <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0" style={{borderBottom:"0.2rem solid #296fba"}}>
                    <img class="img-fluid w-100" src="/images/Btools/bt-view2.png" alt="" />
                  </div>
                  <div class="card-body px-3 text-dark" >
                 
                    <h5 class="font-weight-bold"> CREATE & MANAGE COUNTERS </h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Efficiency is key in today's fast-paced business environment. With Startup Connect, businesses can manage transactions seamlessly from multiple counters within their establishments. Whether it's a retail setting, a restaurant with various service points, or any other multi-counter setup, our software ensures that every transaction is handled with precision and ease.
                     </p>
                      
                    </div>
                    </div>
                </div>
              </div>
              {/* SERVICE CARD 2 END */}

             {/* SERVICE CARD 3 START */}
             <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0" style={{borderBottom:"0.2rem solid #296fba"}}>
                    <img class="img-fluid w-100" src="/images/Btools/bt-view3.png" alt="" />
                  </div>
                  <div class="card-body px-3 text-dark" >
                 
                    <h5 class="font-weight-bold"> TEAM ACCOUNTS MANAGEMENT </h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Collaboration is at the heart of success. Our accounting software includes robust team management features, enabling businesses to assign roles and permissions, fostering collaboration while maintaining security. Team members can work together seamlessly, ensuring a streamlined workflow and optimal productivity.
                     </p>
                      
                    </div>
                    </div>
                </div>
              </div>
              {/* SERVICE CARD 3 END */}

              {/* SERVICE CARD 4 START */}
             <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0" style={{borderBottom:"0.2rem solid #296fba"}}>
                    <img class="img-fluid w-100" src="/images/Btools/bt-view4.png" alt="" />
                  </div>
                  <div class="card-body px-3 text-dark" >
                 
                    <h5 class="font-weight-bold"> TRANSACTION & UPDATES HISTORY </h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Transparency and accountability are paramount in financial management. Startup Connect provides a comprehensive transaction history, allowing users to trace the evolution of financial data over time. Detailed updation histories provide insights into changes, facilitating accurate record-keeping and audit trails.                     </p>
                      
                    </div>
                    </div>
                </div>
              </div>
              {/* SERVICE CARD 4 END */}

              {/* SERVICE CARD 5 START */}
             <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0" style={{borderBottom:"0.2rem solid #296fba"}}>
                    <img class="img-fluid w-100" src="/images/Btools/bt-view5.png" alt="" />
                  </div>
                  <div class="card-body px-3 text-dark" >
                 
                    <h5 class="font-weight-bold"> GENERATE AND VIEW CUSTOM REPORTS </h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Understanding your business requires insightful analysis. Our software empowers users to generate custom reports tailored to their specific needs. Whether it's financial performance, sales trends, or expense breakdowns, the ability to customize reports ensures that you get the information you need, presented in a way that makes sense for your business.
                     </p>
                      
                    </div>
                    </div>
                </div>
              </div>
              {/* SERVICE CARD 5 END */}

              {/* SERVICE CARD 6 START */}
              <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0" style={{borderBottom:"0.2rem solid #296fba"}}>
                    <img class="img-fluid w-100" src="/images/Btools/bt-view6.png" alt="" />
                  </div>
                  <div class="card-body px-3 text-dark" >
                 
                    <h5 class="font-weight-bold"> PROACTIVE FILTERS </h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Time is money. Our proactive filters enable users to quickly and precisely retrieve the data they need, enhancing efficiency and accuracy in decision-making. With the ability to filter information on the fly, users can focus on what matters most without being bogged down by irrelevant details.                     </p>
                      
                    </div>
                    </div>
                </div>
              </div>
              {/* SERVICE CARD 6 END */}

                  {/* SERVICE CARD 7 START */}
                  <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0" style={{borderBottom:"0.2rem solid #296fba"}}>
                    <img class="img-fluid w-100" src="/images/Btools/bt-view7.png" alt="" />
                  </div>
                  <div class="card-body px-3 text-dark" >
                 
                    <h5 class="font-weight-bold"> CUSTOMERS AND SUPPLIERS MANAGEMENT </h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Building strong customer and supplier relationships is a cornerstone of business success. Startup Connect's accounting software simplifies customer and supplier management, allowing businesses to create and maintain a comprehensive database. 
                       </p>
                      
                    </div>
                    </div>
                </div>
              </div>
              {/* SERVICE CARD 7 END */}

                  {/* SERVICE CARD 8 START */}
                  <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0" style={{borderBottom:"0.2rem solid #296fba"}}>
                    <img class="img-fluid w-100" src="/images/Btools/bt-view9.png" alt="" />
                  </div>
                  <div class="card-body px-3 text-dark" >
                 
                    <h5 class="font-weight-bold"> CASH MANAGEMENT </h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Maintaining a clear picture of your financial liquidity is essential. Startup Connect's cash management feature enables businesses to track cash inflows and outflows in real-time. This invaluable insight allows for better financial planning and the ability to make informed decisions to keep your business financially healthy.                       </p>
                      
                    </div>
                    </div>
                </div>
              </div>
              {/* SERVICE CARD 8 END */}

                {/* SERVICE CARD 9 START */}
                <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0" style={{borderBottom:"0.2rem solid #296fba"}}>
                    <img class="img-fluid w-100" src="/images/Btools/bt-view10.png" alt="" />
                  </div>
                  <div class="card-body px-3 text-dark" >
                 
                    <h5 class="font-weight-bold"> BANK MANAGEMENT </h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Managing multiple bank accounts can be complex. Our software simplifies bank management by allowing users to integrate and oversee multiple bank accounts from a single platform. Whether it's monitoring balances, reconciling transactions, or initiating transfers, businesses can handle their banking needs with ease.
                       </p>
                      
                    </div>
                    </div>
                </div>
              </div>
              {/* SERVICE CARD 9 END */}

                {/* SERVICE CARD 10 START */}
                <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0" style={{borderBottom:"0.2rem solid #296fba"}}>
                    <img class="img-fluid w-100" src="/images/Btools/bt-view11.png" alt="" />
                  </div>
                  <div class="card-body px-3 text-dark" >
                 
                    <h5 class="font-weight-bold"> CONTRA ENTRIES </h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Effortlessly handle fund transfers between account ledgers with our streamlined contra entries feature. Whether it's moving funds between different bank accounts or reconciling cash transactions, Startup Connect ensures that contra entries are managed efficiently, minimizing the risk of errors in financial transactions.          </p>
                      
                    </div>
                    </div>
                </div>
              </div>
              {/* SERVICE CARD 10 END */}

                {/* SERVICE CARD 11 START */}
                <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0" style={{borderBottom:"0.2rem solid #296fba"}}>
                    <img class="img-fluid w-100" src="/images/Btools/bt-view12.png" alt="" />
                  </div>
                  <div class="card-body px-3 text-dark" >
                 
                    <h5 class="font-weight-bold"> PRODUCT LIST CATALOG </h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      For businesses dealing with a variety of products, maintaining a well-organized product list is essential. Startup Connect's accounting software enables businesses to create and manage a detailed product list, streamlining the invoicing process and ensuring accurate financial records.
                      </p>
                      
                    </div>
                    </div>
                </div>
              </div>
              {/* SERVICE CARD 11 END */}

              
                {/* SERVICE CARD 12 START */}
                <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0" style={{borderBottom:"0.2rem solid #296fba"}}>
                    <img class="img-fluid w-100" src="/images/Btools/bt-view13.png" alt="" />
                  </div>
                  <div class="card-body px-3 text-dark" >
                 
                    <h5 class="font-weight-bold"> INVENTORY MANAGEMENT </h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Optimizing inventory levels is critical for businesses in various industries. Our software includes a robust inventory management system, allowing businesses to track stock levels, manage reorder points, and streamline their supply chain for maximum efficiency.
                     </p>
                      
                    </div>
                    </div>
                </div>
              </div>
              {/* SERVICE CARD 12 END */}

                {/* SERVICE CARD 12 START */}
                <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0" style={{borderBottom:"0.2rem solid #296fba"}}>
                    <img class="img-fluid w-100" src="/images/Btools/bt-view15.png" alt="" />
                  </div>
                  <div class="card-body px-3 text-dark" >
                 
                    <h5 class="font-weight-bold"> INVOICING </h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      First impressions matter. Our software helps businesses create professional and polished invoices that reflect the professionalism of your brand. With customizable templates and easy-to-use features, invoicing becomes a breeze, allowing businesses to get paid faster and maintain a positive cash flow.                     </p>      
                    </div>
                    </div>
                </div>
              </div>
              {/* SERVICE CARD 12 END */}

                   {/* SERVICE CARD 13 START */}
                   <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0" style={{borderBottom:"0.2rem solid #296fba"}}>
                    <img class="img-fluid w-100" src="/images/Btools/bt-view14.png" alt="" />
                  </div>
                  <div class="card-body px-3 text-dark" >
                 
                    <h5 class="font-weight-bold"> SALES, PURCHASES AND RETURNS </h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Accurate recording and management of sales, purchases, and returns are crucial for financial accuracy. Startup Connect's accounting software provides a comprehensive solution, ensuring that businesses can track all transactions with precision and easily manage returns or exchanges.
                     </p>      
                    </div>
                    </div>
                </div>
              </div>
              {/* SERVICE CARD 13 END */}

               {/* SERVICE CARD 14 START */}
               <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0" style={{borderBottom:"0.2rem solid #296fba"}}>
                    <img class="img-fluid w-100" src="/images/Btools/bt-view16.png" alt="" />
                  </div>
                  <div class="card-body px-3 text-dark" >
                 
                    <h5 class="font-weight-bold"> INCOMES & EXPENSES MANAGEMENT </h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Diversified income and expense streams require meticulous tracking. Our software allows businesses to monitor and categorize various incomes and expenses, providing a comprehensive overview of financial inflows and outflows. This insight is essential for strategic decision-making and long-term financial planning.
                     </p>      
                    </div>
                    </div>
                </div>
              </div>
              {/* SERVICE CARD 14 END */}

                {/* SERVICE CARD 15 START */}
                <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0" style={{borderBottom:"0.2rem solid #296fba"}}>
                    <img class="img-fluid w-100" src="/images/Btools/bt-view17.png" alt="" />
                  </div>
                  <div class="card-body px-3 text-dark" >
                 
                    <h5 class="font-weight-bold"> OUTSTANDING MANAGEMENT </h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Effective management of outstanding payments is critical for maintaining healthy cash flow. Startup Connect's accounting software provides tools to monitor and manage outstanding invoices, reducing the risk of overdue payments and improving overall financial stability.                     </p>      
                    </div>
                    </div>
                </div>
              </div>
              {/* SERVICE CARD 15 END */}

                {/* SERVICE CARD 16 START */}
                <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0" style={{borderBottom:"0.2rem solid #296fba"}}>
                    <img class="img-fluid w-100" src="/images/Btools/bt-view18.png" alt="" />
                  </div>
                  <div class="card-body px-3 text-dark" >
                 
                    <h5 class="font-weight-bold">MANAGE DEBTORS AND CREDITORS </h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Streamline cash flow with Startup Connect's integrated debtors and creditors management. Keep tabs on receivables, outstanding debts, and efficiently handle supplier payments. This approach ensures timely transactions, reduces financial risks, and cultivates strong relationships. Stay in control, mitigate overdue payments, and maintain a positive financial reputation. </p>                    </div>
                    </div>
                </div>
              </div>
              {/* SERVICE CARD 16 END */}

                 {/* SERVICE CARD 17 START */}
                 <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0" style={{borderBottom:"0.2rem solid #296fba"}}>
                    <img class="img-fluid w-100" src="/images/Btools/bt-view19.png" alt="" />
                  </div>
                  <div class="card-body px-3 text-dark" >
                  <h5 class="font-weight-bold">GST AND TAX MANAGEMENT</h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Navigate the complexities of tax compliance effortlessly with Startup Connect's GST and tax management features. Stay up-to-date with changing tax regulations, generate accurate tax reports, and ensure that your business remains compliant, minimizing the risk of penalties or fines. </p>                    </div>
                    </div>
                </div>
              </div>
              {/* SERVICE CARD 17 END */}

                {/* SERVICE CARD 18 START */}
                <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0" style={{borderBottom:"0.2rem solid #296fba"}}>
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/coming-soon-background-memphis-style_1017-39370.jpg" alt="" />
                  </div>
                  <div class="card-body px-3 text-dark" >
                  <h5 class="font-weight-bold">COMING SOON...</h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      At Startup Connect, we are committed to continuous improvement. Anticipate more cutting-edge features and enhancements in the near future, ensuring that our accounting software evolves alongside your business needs. Stay ahead with a platform that grows with you. </p>                    </div>
                    </div>
                </div>
              </div>
              {/* SERVICE CARD 18 END */}
            
    </div>

    <div>
      <div class="d-flex justify-content-center">
        <div style={{backgroundColor:"#03558b", borderRadius:"50%", height:"1.3rem", width:"1.3rem", boxShadow:"1px 1px 1px 1px #e5e8ea"}}></div>
        <div style={{backgroundColor:"#c60e1d", borderRadius:"50%", height:"1.3rem", width:"1.3rem", boxShadow:"1px 1px 1px 1px #e5e8ea", marginLeft:"1rem"}}></div>
        <div style={{backgroundColor:"#03558b", borderRadius:"50%", height:"1.3rem", width:"1.3rem", boxShadow:"1px 1px 1px 1px #e5e8ea", marginLeft:"1rem"}}></div>
      </div>
    </div>
    </div>

 

    </div>
    {/* PRODUCT IMAGES SECTION END */}

    <hr />
    {/* PRICING SECTION START */}
     

    <div class="bth-images-section-top" style={{backgroundColor:"#e5ecf3"}}>
    <p style={{color:"#ba5129",fontWeight:"bold"}}>Choose a Plan</p>
    <h2>Plans That Suite Businesses Of All Sizes</h2>
    </div>
   {/*} <div className="row"> */}
 
     {/* INDIVIDUAL PLAN 28 DAYS CARDS START */}
    {/*<div class="row"> */}
                       <div className="col-sm-12 stretch-card grid-margin" style={{ backgroundColor:"#e5ecf3"}}>
                    
                       <div className="card-body">
                       
                       <div class="row col-12 grid-margin justify-content-between" >

     {/* INDIVIDUAL 28 DAYS FREE PLAN CARD START */}
     <div class="col-md-3" style={{marginTop:"3rem", boxShadow:"2px 1px 1px 2px #c6cbcd", border:"0.2rem solid #c6cbcd",borderRadius:"15px",padding:"1rem",backgroundColor:"#ffffff"}}>
     <div style={{backgroundColor:"#0078b5", color:"#ffffff", borderRadius:"15px",padding:"0.5rem"}}>
     <h4><b>INDIVIDUAL PLAN</b></h4>
     <p>suited for small business</p>
     </div><div><div></div>
     <ul style={{textAlign:"left",fontSize:"105%", listStyleType:"none"}}>                    
     <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#ffeb00", fontSize:"110%",marginRight:"1rem"}}></i>1000 Transactions per month</li>
     <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#28c878", fontSize:"110%",marginRight:"1rem"}}></i>1 Active Business</li>
     <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#28c878", fontSize:"110%",marginRight:"1rem"}}></i>1 Active Counter</li> 
     <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#ffeb00", fontSize:"110%",marginRight:"1rem"}}></i>50 Customers in List Access</li> 
     <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#ffeb00", fontSize:"110%",marginRight:"1rem"}}></i>50 Suppliers in List Access</li> 
     <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#ffeb00", fontSize:"110%",marginRight:"1rem"}}></i>100 Products in List Access</li> 
     <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#ffeb00", fontSize:"110%",marginRight:"1rem"}}></i>2 Team Accounts</li> 
     </ul>
     <hr />
     <h4 style={{color:"#18728b"}}><b>₹ 0/- for 30 days</b></h4>
     <label style={{textDecoration:"line-through"}}>₹ 199/- for 30 days</label>
     <br/>
     <p style={{textAlign:"right",color:"#0091b9"}}><b>100% off</b></p>
     <hr/>
     <button class="btn btn-lg btn-info" style={{backgroundColor:"#0078b5",border:"none", fontSize:"1rem"}} onClick={()=>{
     
     // Assign value to pricingplan
     sessionStorage.setItem("pricingplan00", "IFREE0001"); sessionStorage.setItem("planname00", "INDIVIDUAL FREE PLAN");
     window.location.replace("/bt-freetrial");
    }}><b>Get Started for Free</b></button>
     </div>
     </div>
       {/* INDIVIDUAL 28 DAYS FREE PLAN CARD END */}


     {/* INDIVIDUAL 28 DAYS BASIC PLAN CARD START */}
     <div class="col-md-3" style={{marginTop:"3rem", boxShadow:"2px 1px 1px 2px #c6cbcd", border:"0.2rem solid #c6cbcd", borderRadius:"15px",padding:"1rem", backgroundColor:"#ffffff"}}>
     <div style={{backgroundColor:"#26c78c", color:"#ffffff", borderRadius:"15px",padding:"0.5rem"}}>
     <h4><b>COUNTERS PLAN</b></h4>
     <p>suitable for medium businesses with counters</p>
     </div><div><div></div>
     <ul style={{textAlign:"left",fontSize:"105%", listStyleType:"none"}}>                    
     <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#ffeb00", fontSize:"110%",marginRight:"1rem"}}></i>1000 Transactions per month</li>
     <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#28c878", fontSize:"110%",marginRight:"1rem"}}></i>1 Active Business</li>
     <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#28c878", fontSize:"110%",marginRight:"1rem"}}></i>2 Active Counter</li> 
     <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#ffeb00", fontSize:"110%",marginRight:"1rem"}}></i>50 Customers in List Access</li> 
     <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#ffeb00", fontSize:"110%",marginRight:"1rem"}}></i>50 Suppliers in List Access</li> 
     <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#ffeb00", fontSize:"110%",marginRight:"1rem"}}></i>100 Products in List Access</li> 
     <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#ffeb00", fontSize:"110%",marginRight:"1rem"}}></i>2 Team Accounts</li> 
     </ul>
     <hr />
     <h4 style={{color:"#18728b"}}><b>₹ 0/- for 30 days</b></h4>
     <label style={{textDecoration:"line-through"}}>₹ 249/- for 30 days</label>
     <br/>
     <p style={{textAlign:"right",color:"#0091b9"}}><b>100% off</b></p>
     <hr/>
     <button class="btn btn-lg btn-info" style={{backgroundColor:"#26c78c",border:"none", fontSize:"1rem"}} onClick={()=>{
     
     // Assign value to pricingplan
     sessionStorage.setItem("pricingplan00", "CFREE0001");sessionStorage.setItem("planname00", "COUNTERS FREE PLAN");
     window.location.replace("/bt-freetrial");
    }}><b>Get Started for Free</b></button>
     </div>
     </div>
       {/* INDIVIDUAL 28 DAYS BASIC PLAN CARD END */}


     {/* INDIVIDUAL 28 DAYS STANDARD PLAN CARD START */}
     <div id="i-standard-28" className="col-md-3" style={{marginTop:"3rem", border:"0.2rem solid #c6cbcd",boxShadow:"2px 1px 1px 2px #c6cbcd",borderRadius:"15px",padding:"1rem", backgroundColor:"#ffffff"}}>
     <div style={{backgroundColor:"#f49938", color:"#ffffff", borderRadius:"15px",padding:"0.5rem"}}>
     <h4><b>BUSINESS PLAN</b></h4>
     <p>suitable for owners having more than one business</p>
     </div><div><div></div>
     <ul style={{textAlign:"left",fontSize:"105%", listStyleType:"none"}}>                    
     <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#ffeb00", fontSize:"110%",marginRight:"1rem"}}></i>1000 Transactions per month</li>
     <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#28c878", fontSize:"110%",marginRight:"1rem"}}></i>2 Active Business</li>
     <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#28c878", fontSize:"110%",marginRight:"1rem"}}></i>2 Active Counter</li> 
     <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#ffeb00", fontSize:"110%",marginRight:"1rem"}}></i>50 Customers in List Access</li> 
     <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#ffeb00", fontSize:"110%",marginRight:"1rem"}}></i>50 Suppliers in List Access</li> 
     <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#ffeb00", fontSize:"110%",marginRight:"1rem"}}></i>100 Products in List Access</li> 
     <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#ffeb00", fontSize:"110%",marginRight:"1rem"}}></i>2 Team Accounts</li> 
     </ul>
     <hr />
     <h4 style={{color:"#18728b"}}><b>₹ 0/- for 30 days</b></h4>
     <label style={{textDecoration:"line-through"}}>₹ 499/- for 30 days</label>
     <br/>
     <p style={{textAlign:"right",color:"#0091b9"}}><b>100% off</b></p>
     <hr/>
     <button class="btn btn-lg btn-info" style={{backgroundColor:"#f49938",border:"none", fontSize:"1rem"}} onClick={()=>{
     
     // Assign value to pricingplan
     sessionStorage.setItem("pricingplan00", "BFREE0001");sessionStorage.setItem("planname00", "BUSINESS FREE PLAN");
     window.location.replace("/bt-freetrial");
    }}><b>Get Started for Free</b></button>
     </div>
     </div>
     {/* INDIVIDUAL 28 DAYS STANDARD PLAN CARD END */}
      
          {/* INDIVIDUAL 28 DAYS FREE PLAN CARD START */}
          <div id="i-basic-28" className="col-md-3"  style={{marginTop:"3rem", border:"0.2rem solid #c6cbcd", boxShadow:"2px 1px 1px 2px #c6cbcd",borderRadius:"15px",padding:"1rem", backgroundColor:"#ffffff"}}>
          <div style={{backgroundColor:"#f53838", color:"#ffffff", borderRadius:"15px",padding:"0.5rem"}}>
          <h4><b>CONTACT SALES</b></h4>
          <p>get best customized plans suited to your bussiness needs</p>
          </div><div><div></div>
          <img src="https://cdn-icons-png.flaticon.com/128/870/870124.png" style={{height:"60%",width:"80%", marginTop:"1rem"}}></img>
          
          <hr/>
          <button class="btn btn-lg btn-info" style={{backgroundColor:"#f53838",border:"none", fontSize:"1rem"}} onClick={()=>{
                        //Setting Values to redirect to Contact Sales Page
                        sessionStorage.setItem("Smodule00","Book Keeping");sessionStorage.setItem("Sservice00","NA"); window.location.replace("/businesstools-contactsales");
                       }}><b>Contact Sales</b></button>
          </div>
          </div>
          {/* INDIVIDUAL 28 DAYS FREE PLAN CARD END */}
  

</div>
</div>

</div>

<a href="/businesstools-user-pricing"> <button class="btn btn-lg btn-dark mt-2 mb-3">View Paid Plans</button></a>
   {/*}  </div> */}
     {/* INDIVIDUAL PLAN 28 DAYS CARDS END */}

  {/*}   <p>Currently Showing for {ip_days} Days Plan (all prices excluding GST)</p> */}

 
    
    



{/*</div>*/}
{/* PRICING SECTION END */}

{/* FOOTER SECTION START */}
<Footer3/>
{/* FOOTER SECTION END */}

    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
     </>
    );

}

export default Bthome;