import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import Navbar from './navbar';



//import '../hireportalcss.css';
//import '../Hireportalcss/hireportalcss.css';

//Importing Page css with screen sizes
import '../Hireportalcss/sdefault.css';
import '../Hireportalcss/s1200.css';
import '../Hireportalcss/s920.css';
import '../Hireportalcss/s768.css';
import '../Hireportalcss/s600.css';
import '../Hireportalcss/sanimation.css';



function Employercreateapplicationquestions(){

    return(

    <>
        <div class="default-view">
        <Navbar/>
        {/* MAIN SECTION START */}
        <div class="hire-main-section">

        {/* FORM CONTAINER START */}
        <div class="hire-form-container">
        
        {/* CREATE JOB FORM START */}
            <div class="hire-form-panel" style={{backgroundColor:"#ffffff"}}>
            <h3>Add Application Questions</h3>

            <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Question Number</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : 1" /></div>
            </div>
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Answer Type</div>
            <div class="hire-form-group-right"><select class="hire-form-group-input" placeholder="Select Answer Type">
            <option value="">Select Answer Type</option>
            <option value="">Multiple Choice Answer</option>
            <option value="">Short Format Answer</option>
            <option value="">Long Format Answer</option>
            </select>    
            </div>
            </div>
            </div>


            <div class="hire-form-list">
            <div class="hire-form-group-large">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Enter Question here</div>
            <div class="hire-form-group-right"><textarea size="3" class="hire-form-group-input" placeholder="For e.g. : Why are you the best fit for this role?."></textarea></div>
            </div>
            </div>

            <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Required</div>
            <div class="hire-form-group-right"><select class="hire-form-group-input"  placeholder="Select Job Type">
            <option value="Yes">Yes</option>
            <option value="No">No</option>
            </select>
            </div>
            </div>
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Add Options</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : 6" /></div>
            </div>
            </div>
            <div class="hire-form-btn">
            <button class="hire-btn-default" style={{backgroundColor:"#060c5f",marginRight:"2rem",marginTop:"2rem",marginBottom:"2rem"}}>+</button>

            </div>

            <div id="line-1"></div>
            <h3>Attach Assignment</h3>

            <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Select Assignment</div>
            <div class="hire-form-group-right"><select class="hire-form-group-input" placeholder="Select Answer Type">
            <option value="">Select Assignment</option>
            <option value="">Assignment 1</option>
            <option value="">Assignment 2</option>
            <option value="">Assignment 3</option>
            <option value="">Assignment 4</option>
            </select>    
            </div>
            </div>
            <div class="hire-form-group-small">
            <button class="hire-btn-default" style={{backgroundColor:"#12d571",marginRight:"2rem",marginTop:"2rem",marginBottom:"2rem"}}>Create New</button>
            </div>
            </div>

            <div class="hire-form-btn">
            <button class="hire-btn-default" style={{backgroundColor:"#d48939",marginRight:"2rem",marginTop:"2rem",marginBottom:"2rem"}}>Submit Job for Review</button>

            </div>

        </div>
        {/* CREATE JOB FORM END */}


        </div>
        {/* FORM CONTAINER END */}

        </div>
        {/* MAIN SECTION END */}

        </div>

        </>
    );




}


export default Employercreateapplicationquestions;