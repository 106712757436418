import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
//import axios from '../../axios.js';
import moment from 'moment/moment';

//import './Resources/homebtcss.css';
//import './Resources/home2css.css';
//import './Resources/serviceshomecss.css';


function New_refundandcancellation(){

    return(
     <>
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Startup Connect | Privacy Policy</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>

   <div class="container" style={{textAlign:"left"}}>

   <p style={{marginTop:"3rem"}}>Last Updated : 2024-01-24</p>
   <h1>REFUND AND CANCELLATION POLICY</h1>

		<p>
			<br/><br/>
<h2><u>FOR SERVICES</u></h2>
			<br/>
            <br/>
<h3>Performance Policy </h3>
			<br/>
Every Client has the right to raise a <b>‘Grievance Request’</b> with <b>thestartupconnect.com</b> by sending an email to <b>help.startupconnect@gmail.com</b> specifying their grievances along with details of the service utilized and order details. On verification of the request, if there is any genuine delay or grievance to the client, then <b>thestartupconnect.com</b> shall immediately take it up and correct the situation. Such delay or grievance should be attributable to the operations of <b>thestartupconnect.com</b> and should be towards an element that is within the operational scope of <b>thestartupconnect.com</b>.
			<br/>
Further, <b>thestartupconnect.com</b> shall not be responsible if any delay is found from client side in submission of the required documents or information. Such claims shall not be covered under the policy.
			<br/>
<b>thestartupconnect.com</b> does not guarantee any results or outcome of a process where governmental or quasi-judicial or any regulatory authority is involved. 
			<br/>
			<br/>
<h3>Refund Policy </h3>
			<br/>
We put our sincere efforts to make our clients happy but if even after our hardest efforts you are not satisfied with our service process then you may claim a refund as per the following terms.
			<br/>
			<br/>
<li>The refund can only be claimed in case of visibly clear deficiency/error with the service/s provided by <b>thestartupconnect.com</b>
</li>	<br/>
<li>No refund shall be paid for change in mind or mood or situation of the client and <b>thestartupconnect.com</b> shall not be liable for the same.
</li>		<br/>
<li>If the request of refund has been raised after the completion of services or completion of fulfillment of order then you shall not be eligible for refund and such refund request shall be considered invalid.
</li>		<br/>
<li>You shall not be eligible for refund if any delay is found from your side in submission of the required documents or information.
</li>		<br/>
<li>You shall not be liable for refund after 60 days of making payment to <b>thestartupconnect.com</b> for any services even if such orders have not been started or initiated. <b>thestartupconnect.com</b> shall not hold any liability for such abandoned orders. The amount against such orders shall be utilized and considered as the cost incurred by the company on following up and consultancy served to you during these days.
</li>		<br/>
<li>If you request to exchange your original service order with another service which is of lesser value, then the differential amount shall not be refunded, rather the same shall be kept in your store credit account which can be utilized by you for availing future services with us.*
</li>		<br/>
<li>Such store credit shall be utilized within 365 days from the date of request for store credit. After such a period, store credit shall lapse.*
</li>		<br/>
<li>After approval of refund from <b>thestartupconnect.com</b>, such refund shall be granted within <b>15-30 working days</b> from the settlement of the claim. The refund amount shall be restricted towards the professional fees and shall exclude all government fees, taxes, charges, challan payments, third party payments, payment gateways charges and vendor payments etc. which are generally non-refundable in nature.
</li>		<br/>
Refund will not be processed by Startup Connect under following circumstances as <b>thestartupconnect.com</b> dedicates its manpower, time, resources and technology to cater to the client’s requirements
			<br/>
			<br/>
- Towards any component/s of the bundled/customized package availed
			<br/>
- In case of any untimely events such as earthquake, fire, flood, pandemic
			<br/>
- Change of business plans by the client and not opting for the services chosen.
			<br/>
- Payments made by you to any third parties directly for e.g. affiliates, vendors, employees
			<br/>
			<br/>
			<br/>
			<br/>
<h2><u>FOR BUSINESS TOOLS AND OTHER SUBSCRIPTION SERVICES</u></h2>
			<br/>
			<br/>
<h3>Refund Policy</h3>
			
			<br/>
Your subscription may get renewed automatically on their due renewal date according to date of purchase i.e every month / quarter / year until you cancel auto-pay services.
			<br/>
You can upgrade or downgrade level of our services at any time of your usage, however we does not refund any fees in that case
			<br/>
If you stop using our services in between the term, we will not refund you the fees paid by you for the remaining term
			<br/>
			<br/>
Startup connect charges and collects in advance for use of the SaaS service. All services rendered are non-refundable. Once a customer selects a subscription plan and provides billing information, Startup connect will calculate a pro-rated amount with the first payment due on signing.
			<br/>
			<br/>
		   <br/>
<h3>7 Day No Obligation Cancellation : New Customers </h3>
<br/>
			
Startup connect offers SaaS service to each new Customer under a 7-day no obligation cancellation policy. The no obligation cancellation begins immediately upon account activation. The 7-day no obligation cancellation period only applies to new customers and does not apply to existing customers that have a paid subscription plan.
			<br/>
At any time during the 7-day period, subscriber can cancel their account and will be refunded in full with a minor service charge & development cost.The refund will be processed within 15-30 working days and returned to your original mode of payment.-
			<br/>
In order to continue using SaaS after the 7-day no obligation cancellation period, you will be automatically be put to continue to your selected payment plan which you have selected initially at time of buying from range of selections of monthly or receiving a discount by paying for half yearly or full year of service.
			<br/>
			<br/>
Customers will receive an email from Startup connect confirming that their subscription to the SaaS service has been cancelled.
			<br/>
			<br/>
			<br/>
<h2><u>ORDER CANCELLATION AND REFUND PROCESS</u></h2>
<ol>
			<br/>
<li>For availing refund the client has to write to us at <b>help.startupconnect@gmail.com</b> the name, phone number, email, order details, date of order, transaction or payment information along with any other important information to be conveyed.
</li>	<br/>
<li>The Feedback Manager will then be in touch with you to understand and verify the details provided.
</li>		<br/>
<li>Feedback Manager will then decide if the request is applicable for refund and cancellation or not. If your request is fair then our company will initiate the process for your refund as discussed and conveyed during your interaction with the Feedback Manager.
</li>		<br/>
<li>The refund will be made to the party within 15-30 Days, post successful verification and completion of Order Cancellation or Refund Request from our Feedback Manager once received by the client.
</li>		<br/>
<b>Note:-</b> Any dispute arising from or in connection with the Refund and Cancellation Policy or Claim shall be settled through friendly negotiation. In case no settlement can be reached, the dispute shall be then submitted as per Dispute Resolution Clause mentioned under this agreement.*
			<br/>
			<br/>
			</ol>
<h3>Dispute Resolution/Jurisdiction</h3>
			<br/>
Please note that this policy shall be governed by the laws of India and shall be construed in accordance with the laws thereof. The Parties agree that any and all disputes between the Parties under or relating to the terms and conditions of this Agreement shall be fully and completely adjudicated in the courts of <b>Jabalpur, Madhya Pradesh, India</b>.
			<br/>
			<br/>
			<h3>Contact Information</h3>
			<br/>
If you have any questions or comments about our Refund and Cancellation Policy as outlined above, you can contact us at:
<br></br>
			<br/>
<b>Technomate Startup Services Private Limited
			<br/>
LIG C-5, 90 Quarter, Sanjeevani Nagar, Jabalpur (M.P) - 482001
			<br/>
Contact : +91-8480002413
			<br/>
Email : help.startupconnect@gmail.com
</b>
			<br/>
			<br/>
		</p>


   </div>

  
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>

</>
);

}

export default New_refundandcancellation;