import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';
import moment from 'moment/moment';


import '../Resources/dashboard.css';


function Add_product(){

  
 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const busyid=sessionStorage.getItem("BusyId00");
 const counterid=sessionStorage.getItem("CounterId00");


 
//saving default values with usestate
const state = useState();

//product_name
const[product_name,setproduct_name]=useState();const updateproduct_name = (event) => { setproduct_name(event.target.value);}; const[product_name_er,setproduct_name_er]=useState();
//product_code
const[product_code,setproduct_code]=useState();const updateproduct_code = (event) => { setproduct_code(event.target.value);}; const[product_code_er,setproduct_code_er]=useState();
//product_hsn
const[product_hsn,setproduct_hsn]=useState();const updateproduct_hsn = (event) => { setproduct_hsn(event.target.value);}; const[product_hsn_er,setproduct_hsn_er]=useState();
//product_type
const[product_type,setproduct_type]=useState();const updateproduct_type = (event) => { setproduct_type(event.target.value);}; const[product_type_er,setproduct_type_er]=useState();
//product_category
const[product_category,setproduct_category]=useState();const updateproduct_category = (event) => { setproduct_category(event.target.value);}; const[product_category_er,setproduct_category_er]=useState();
//product_brand
const[product_brand,setproduct_brand]=useState();const updateproduct_brand = (event) => { setproduct_brand(event.target.value);}; const[product_brand_er,setproduct_brand_er]=useState();
//product_status
const[product_status,setproduct_status]=useState();const updateproduct_status = (event) => { setproduct_status(event.target.value);}; const[product_status_er,setproduct_status_er]=useState();
//description
const[description,setdescription]=useState();const updatedescription = (event) => { setdescription(event.target.value);}; const[description_er,setdescription_er]=useState();
//group_id
const[group_id,setgroup_id]=useState();const updategroup_id = (event) => { setgroup_id(event.target.value);}; const[group_id_er,setgroup_id_er]=useState();
//sales_rate
const[sales_rate,setsales_rate]=useState();const updatesales_rate = (event) => { setsales_rate(event.target.value);}; const[sales_rate_er,setsales_rate_er]=useState();
//purchases_rate
const[purchases_rate,setpurchases_rate]=useState();const updatepurchases_rate = (event) => { setpurchases_rate(event.target.value);}; const[purchases_rate_er,setpurchases_rate_er]=useState();
//units
const[units,setunits]=useState();const updateunits = (event) => { setunits(event.target.value);}; const[units_er,setunits_er]=useState();
//discount_per
const[discount_per,setdiscount_per]=useState();const updatediscount_per = (event) => { setdiscount_per(event.target.value);}; const[discount_per_er,setdiscount_per_er]=useState();
//sgst_per
const[sgst_per,setsgst_per]=useState();const updatesgst_per = (event) => { setsgst_per(event.target.value);}; const[sgst_per_er,setsgst_per_er]=useState();
//cgst_per
const[cgst_per,setcgst_per]=useState();const updatecgst_per = (event) => { setcgst_per(event.target.value);}; const[cgst_per_er,setcgst_per_er]=useState();
//igst_per
const[igst_per,setigst_per]=useState();const updateigst_per = (event) => { setigst_per(event.target.value);}; const[igst_per_er,setigst_per_er]=useState();
//tax_per
const[tax_per,settax_per]=useState();const updatetax_per = (event) => { settax_per(event.target.value);}; const[tax_per_er,settax_per_er]=useState();

//Variables
const[data,setdata] = useState([]);//SetData Variable

//Error Variable
const[errorvar,seterrorvar] = useState();



//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();




useEffect(()=>{
   
  getData();
  
  },[])
 
    
  
//******************* GET GROUPS DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))){
 
 
 axios.post("bt-get-productgroups", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Sn_start :0,
   Sn_end :0,
   Sn_total :0,
   Sn_limit :0
 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setdata(response.data.data);
  
  }
  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* GET GROUPS DATA FUNCTION END  ********************//

 
  




  
//******************* ADD PRODUCT ITEM FUNCTION START ********************//
function addproduct(){

  //Setting Error Variables
setproduct_name_er(" ");setproduct_code_er(" ");setproduct_hsn_er(" ");setproduct_type_er(" ");setproduct_brand_er(" ");setproduct_category_er(" ");setproduct_status_er();setdescription_er();seterror_msg(" ");
setsales_rate_er(" ");setpurchases_rate_er(" ");setunits_er(" ");setdiscount_per_er(" ");setsgst_per_er(" ");setcgst_per_er(" ");setigst_per_er(" ");settax_per_er(" ");setgroup_id_er(" ");



  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))){
 
 
  axios.post("bt-add-productitem", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Product_name :product_name,
   Product_code :product_code,
   Product_hsn :product_hsn,
   Type:product_type,
   Units:units,
   Category : product_category,
   Brand:product_brand,
   Status:product_status,
   Description:description,
   Group_id:group_id,
   Sales_rate:sales_rate,
   Purchases_rate:purchases_rate,
   Discount_per:discount_per,
   Sgst_per:sgst_per,
   Cgst_per:cgst_per,
   Igst_per:igst_per,
   Tax_per:tax_per


  })
  .then((response) => {

        //Product Item Limit Reached
        if(response.data.error==111){     seterror_msg(response.data.error_msg); return; }
        

  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
  }

   //Validation Errors
 if(response.data.validatorerror){
  setproduct_name_er(response.data.validatorerror.Product_name);
  setproduct_code_er(response.data.validatorerror.Product_code);
  setproduct_hsn_er(response.data.validatorerror.Product_hsn);
  setproduct_type_er(response.data.validatorerror.Type);
  setproduct_category_er(response.data.validatorerror.Category);
  setproduct_brand_er(response.data.validatorerror.Brand);
  setproduct_status_er(response.data.validatorerror.Status);
  setdescription_er(response.data.validatorerror.Description);
  setgroup_id_er(response.data.validatorerror.Group_id);
  setsales_rate_er(response.data.validatorerror.Sales_rate);
  setpurchases_rate_er(response.data.validatorerror.Purchases_rate);
  setunits_er(response.data.validatorerror.Units);
  setdiscount_per_er(response.data.validatorerror.Discount_per);
  setsgst_per_er(response.data.validatorerror.Sgst_per);
  setcgst_per_er(response.data.validatorerror.Cgst_per);
  setigst_per_er(response.data.validatorerror.Igst_per);
  settax_per_er(response.data.validatorerror.Tax_per);

}
//Invalid Entries
if(response.data.error==102){ 
  
  setproduct_name_er(response.data.product_name_er);
  setproduct_code_er(response.data.product_code_er);
  setproduct_hsn_er(response.data.product_hsn_er);
  setproduct_type_er(response.data.type_er);
  setproduct_category_er(response.data.category_er);
  setproduct_brand_er(response.data.brand_er);
  setproduct_status_er(response.data.status_er);
  setdescription_er(response.data.description_er);
  setgroup_id_er(response.data.group_id_er);
  setsales_rate_er(response.data.sales_rate_er);
  setpurchases_rate_er(response.data.purchases_rate_er);
  setunits_er(response.data.units_er);
  setdiscount_per_er(response.data.discount_per_er);
  setsgst_per_er(response.data.sgst_per_er);
  setcgst_per_er(response.data.cgst_per_er);
  setigst_per_er(response.data.igst_per_er);
  settax_per_er(response.data.tax_per_er);
 
  seterror_msg("Cannot Add Product Item!");  }


  //Invalid Entries
  if(response.data.error==103){     seterror_msg(response.data.error_msg);  }

   //Transaction Entered Successfully
   if(response.data.successvar==1){        document.getElementById("success-card").style.display="block";  }
   //Cannot Add Transaction
   else{  seterror_msg("Cannot Add Product Item !"); }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* ADD PRODUCT ITEM FUNCTION END ********************//


 




return(
    <>
                <div class="card">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("create-form").style.display="none"; }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                  <div class="card-body">
                    <h4 class="card-title">Add Product</h4>
                    <div class="form-sample">
                      <p class="card-description">add your product details here</p>
                      <code>{error_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product Name<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Red T-Shirt" id="product_name" value={product_name} onChange={updateproduct_name} />
                            <code>{product_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="Type Regular" id="product_type" value={product_type} onChange={updateproduct_type}/>
                            <code>{product_type_er}</code>
                            </div>
                          </div>
                        </div>   
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product Code<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="PC0001" id="product_code" value={product_code} onChange={updateproduct_code} />
                            <code>{product_code_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product HSN</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="478514" id="product_hsn" value={product_hsn} onChange={updateproduct_hsn}/>
                            <code>{product_hsn_er}</code>
                            </div>
                          </div>
                        </div>   
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Category</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Northern Style" id="product_category" value={product_category} onChange={updateproduct_category} />
                            <code>{product_category_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Brand</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Adidas" id="product_brand" value={product_brand} onChange={updateproduct_brand} />
                            <code>{product_brand_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Group</label>
                            <div class="col-sm-9">
                              <select class="form-control" id="group_id" style={{padding:"1rem"}} value={group_id} onChange={updategroup_id} >
                              <option>Select Group</option>
                              {
                              data.map((curElm5,ind)=>{

                              return(
                              <>
                              <option value={curElm5.Group_id}>{curElm5.Group_name}</option>
                              </>
                              )
                              }
	 				   
	                         		)
                              }
                              </select>
                              <code>{group_id_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Status</label>
                            <div class="col-sm-9">
                            <select class="form-control" id="product_status" style={{padding:"1rem"}} value={product_status} onChange={updateproduct_status} >
                              <option value="active">active</option>
                              <option value="inactive">inactive</option>
                              </select>
                              <code>{product_status_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                    
                      <div class="form-group">
                        <label for="exampleTextarea1">Description</label>
                        <textarea class="form-control" rows="2" id="description" value={description} onChange={updatedescription} ></textarea>
                       <code>{description_er}</code>
                      </div>
                 
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Sales Rate</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="150.00" id="sales_rate" value={sales_rate} onChange={updatesales_rate}/>
                              <code>{sales_rate_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Purchases Rate</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="220.00" id="purchases_rate" value={purchases_rate} onChange={updatepurchases_rate} />
                            <code>{purchases_rate_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Units</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="pieces" id="units" value={units} onChange={updateunits} />
                              <code>{units_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Discount %</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="10.00" id="discount_per" value={discount_per} onChange={updatediscount_per} />
                              <code>{discount_per_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">SGST %</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="06.00" id="sgst_per" value={sgst_per} onChange={updatesgst_per} />
                              <code>{sgst_per_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">CGST %</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="06.00"  id="cgst_per" value={cgst_per} onChange={updatecgst_per}/>
                              <code>{cgst_per_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">IGST %</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="06.00" id="igst_per" value={igst_per} onChange={updateigst_per}/>
                              <code>{igst_per_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Tax %</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="06.00" id="tax_per" value={tax_per} onChange={updatetax_per} />
                              <code>{tax_per_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>

                        
                      
                        <button class="btn btn-primary" style={{fontWeight:"bold"}} onClick={addproduct}>Add Product</button>
                      
                       
    
                     
                   
                    </div>
                  </div>
                </div>
    </>

);

}

export default Add_product;