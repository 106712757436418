import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';
import moment from 'moment/moment';


import '../Resources/dashboard.css';
import Insideteam_navbar from './insideteam_navbar.js';
import Insideteam_sidebar from './insideteam_sidebar.js';
//import Create_customer from '../create_customer';

import {useRef as useref} from 'react';
import {useReactToPrint} from 'react-to-print';

//EXCEL DOWNLOAD IMPORT
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';


function Insideteam_salescouponhistory(){


  
    //Report Print Function
    const componentRef = useref();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });

    
 // Function to download the data as an Excel file
 const downloadExcel = () => {
  // Convert JSON data to Excel worksheet
  const worksheet = XLSX.utils.json_to_sheet(data);
  
  // Create a new workbook and add the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  
  // Convert the workbook to a binary array
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  
  // Create a Blob from the binary data and trigger a download
  const excel_data = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(excel_data, "data.xlsx");
};



  //console.log
  
 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");



{/* CUSTOMERS TABLE COLUMNS MANAGEMENT VARIABLES START */}
const[cd_col1_val,setcd_col1_val] = useState(0);//column1
const[cd_col2_val,setcd_col2_val] = useState(0);//column2
const[cd_col3_val,setcd_col3_val] = useState(0);//column3
const[cd_col4_val,setcd_col4_val] = useState(0);//column4
const[cd_col5_val,setcd_col5_val] = useState(0);//column5
const[cd_col6_val,setcd_col6_val] = useState(0);//column6
const[cd_col7_val,setcd_col7_val] = useState(0);//column7
const[cd_col8_val,setcd_col8_val] = useState(0);//column8
const[cd_col9_val,setcd_col9_val] = useState(0);//column9
const[cd_col10_val,setcd_col10_val] = useState(0);//column10
const[cd_col11_val,setcd_col11_val] = useState(0);//column11
const[cd_col12_val,setcd_col12_val] = useState(0);//column12
const[cd_col13_val,setcd_col13_val] = useState(0);//column13
const[cd_col14_val,setcd_col14_val] = useState(0);//column14
const[cd_col15_val,setcd_col15_val] = useState(0);//column15
const[cd_col16_val,setcd_col16_val] = useState(0);//column16
const[cd_col17_val,setcd_col17_val] = useState(0);//column17

//Variables
const[data,setData] = useState([]);//SetData Variable

//Error Variable
const[errorvar,seterrorvar] = useState();


//FILTER VARIABLES
const[filter_val,setfilter_val] = useState(0);
const[filter_coupon_code,setfilter_coupon_code] = useState(); const updatefilter_coupon_code = (event) => { setfilter_coupon_code(event.target.value);}; const[filter_coupon_code_er,setfilter_coupon_code_er]=useState();
const[filter_steam_id,setfilter_steam_id] = useState(); const updatefilter_steam_id = (event) => { setfilter_steam_id(event.target.value);}; const[filter_steam_id_er,setfilter_steam_id_er]=useState();
const[filter_team_id,setfilter_team_id] = useState(); const updatefilter_team_id = (event) => { setfilter_team_id(event.target.value);}; const[filter_team_id_er,setfilter_team_id_er]=useState();
const[filter_username,setfilter_username] = useState(); const updatefilter_username = (event) => { setfilter_username(event.target.value);}; const[filter_username_er,setfilter_username_er]=useState();
const[filter_status,setfilter_status] = useState(); const updatefilter_status = (event) => { setfilter_status(event.target.value);}; const[filter_status_er,setfilter_status_er]=useState();
const[filter_order_id,setfilter_order_id] = useState(); const updatefilter_order_id = (event) => { setfilter_order_id(event.target.value);}; const[filter_order_id_er,setfilter_order_id_er]=useState();
const[filter_payment_id,setfilter_payment_id] = useState(); const updatefilter_payment_id = (event) => { setfilter_payment_id(event.target.value);}; const[filter_payment_id_er,setfilter_payment_id_er]=useState();
const[filter_plan_code,setfilter_plan_code] = useState(); const updatefilter_plan_code = (event) => { setfilter_plan_code(event.target.value);}; const[filter_plan_code_er,setfilter_plan_code_er]=useState();
const[filter_date,setfilter_date] = useState(); const updatefilter_date = (event) => { setfilter_date(event.target.value);}; const[filter_date_er,setfilter_date_er]=useState();

//Variables for pagination
const [currentPage, setCurrentPage] = useState(1);
const [pageNumber, setpageNumber] = useState(1);

useEffect(()=>{
   
  getData();
  
  },[])
 
    
  
//******************* GET SALES TEAM DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))){
 

   const $filter_coupon_code = filter_coupon_code;const $filter_steam_id = filter_steam_id; const $filter_team_id = filter_team_id; const $filter_username = filter_username; const $filter_status = filter_status; 
   const $filter_order_id = filter_order_id; const $filter_payment_id = filter_payment_id; const $filter_plan_code = filter_plan_code; const $filter_date = filter_date;

 axios.post("bt-get-inside-salesteam-couponcodes-history", {
   Log_id:logid,
   Token:token,
   Sn_start :0,
   Sn_end :0,
   Sn_total :0,
   Sn_limit :0,
   Filter_val : filter_val,
   Filter_coupon_code : $filter_coupon_code,
  // Filter_steam_id : $filter_steam_id,
   Filter_team_id : $filter_team_id,
 //  Filter_username : $filter_username,
   Filter_status : $filter_status,
   Filter_order_id : $filter_order_id,
   Filter_payment_id : $filter_payment_id,
   Filter_plan_code : $filter_plan_code,
  // Filter_status : $filter_coupon_status,
   Filter_date :  moment($filter_date).format('YYYY-MM-DD'),
   
   //Code Edits for pagination
   'page' : pageNumber


   })
 .then((response) => {


  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");


     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setData(response.data.data.data);
  
  }

  //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){
    setfilter_coupon_code_er(response.data.validatorerror.Filter_coupon_code);
    setfilter_steam_id_er(response.data.validatorerror.Filter_steam_id);
    setfilter_team_id_er(response.data.validatorerror.Filter_team_id);
    setfilter_username_er(response.data.validatorerror.Filter_username);
    setfilter_status_er(response.data.validatorerror.Filter_status);
    setfilter_order_id_er(response.data.validatorerror.Filter_order_id);
    setfilter_payment_id_er(response.data.validatorerror.Filter_payment_id);
    setfilter_plan_code_er(response.data.validatorerror.Filter_plan_code);
    setfilter_date_er(response.data.dateatorerror.Filter_date);


  }

  //Data Violation
  if(response.data.error==102){ 
  
    setfilter_coupon_code_er(response.data.filter_coupon_code_er);
    setfilter_steam_id_er(response.data.filter_steam_id_er);
    setfilter_team_id_er(response.data.filter_team_id_er);
    setfilter_username_er(response.data.filter_username_er);
    setfilter_status_er(response.data.filter_status_er);
    setfilter_order_id_er(response.data.filter_order_id_er);
    setfilter_payment_id_er(response.data.filter_payment_id_er);
    setfilter_plan_code_er(response.data.filter_plan_code_er);
    setfilter_date_er(response.data.filter_date_er);

  }


  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* GET CUSTOMERS DATA FUNCTION END  ********************//





return(
    <>
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Business Tools|BookKeeping Solution</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>


    <div class="container-scroller">

      <Insideteam_sidebar />
    <div class="container-fluid page-body-wrapper">




       <Insideteam_navbar />
        {/*} partial */}
        <div class="main-panel">

             
          

          <div class="content-wrapper pb-0">

      
          
            <div class="page-header flex-wrap">
            <div class="header-left"></div>
              <div class="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div class="d-flex align-items-center">
                  <a href="#">
                    <p class="m-0 pe-3">SALES COUPON USAGE HISTORY</p>
                  </a>
                  <a class="ps-3 me-4" href="#">
                    <p class="m-0"></p>
                  </a>
                </div>
         
              </div>
            </div>

         

            {/* COUPON HISTORY TABLE START */}
                  
             <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Sales Coupon Usage History</h4>
                    <p class="card-description">sales coupons usage history
                    </p>


                            {/* FILTER SECTION START */}
                            <hr/>
                            <div id="filter-top-card" class="row col-12" style={{textAlign:"left",display:"none"}}>
                            <div class="d-flex justify-content-between">
                            <div>
                            <h4 style={{padding:'1rem'}}>Filters</h4>
                           </div>
                           <div>
                    
                           <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("filter-top-card").style.display="none"; }}>
                           <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                           </button>
                           </div>
                           </div>
        
                        <div class="row">
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Coupon Code</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="COUPON30" id="filter_coupon_code" value={filter_coupon_code} onChange={updatefilter_coupon_code}  />
                              <code>{filter_coupon_code_er}</code>
                            </div>
                          </div>
                        </div>
            {/*}            <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Steam Id</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="121" id="filter_steam_id" value={filter_steam_id} onChange={updatefilter_steam_id}  />
                              <code>{filter_steam_id_er}</code>
                            </div>
                          </div> 
                        </div> */}
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Team Id</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="121" id="filter_team_id" value={filter_team_id} onChange={updatefilter_team_id}  />
                              <code>{filter_team_id_er}</code>
                            </div>
                          </div>
                        </div>
                     {/*}   <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Username</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="ROHAN121" id="filter_username" value={filter_username} onChange={updatefilter_username}  />
                              <code>{filter_username_er}</code>
                            </div>
                          </div>
                        </div> */}
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Order Id</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="order9999121" id="filter_order_id" value={filter_order_id} onChange={updatefilter_order_id}  />
                              <code>{filter_order_id_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Payment</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="45555121" id="filter_payment_id" value={filter_payment_id} onChange={updatefilter_payment_id}  />
                              <code>{filter_payment_id_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Plan Code</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="PREK899" id="filter_plan_code" value={filter_plan_code} onChange={updatefilter_plan_code}  />
                              <code>{filter_plan_code_er}</code>
                            </div>
                          </div>
                        </div>
                       {/*} <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Status</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="121" id="filter_coupon_status" value={filter_coupon_status} onChange={updatefilter_coupon_status}  />
                              <code>{filter_coupon_status_er}</code>
                            </div>
                          </div>
                        </div>
                    {/*}    <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Valid</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" placeholder="Rakesh Sharma" value={filter_valid} onChange={updatefilter_valid}  />
                              <code>{filter_valid_er}</code>
                            </div>
                          </div>
                        </div> */}
                   
                        <div class="col-md-4">
                         <button class="btn btn-primary" onClick={()=>{ setfilter_val(1); getData(); }}>Apply Filters</button>
                         <button class="btn btn-danger" style={{marginLeft:"1rem"}} onClick={()=>{ setfilter_val(0); getData(); }}>Remove Filters</button>
                        </div>
                        <br/>
                        <br/>
                      </div>
                            <hr/>                      
                            </div>
                            
                    {/* FILTER SECTION END */}


                  <div class="col-12" style={{marginBottom:"2rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                        {/* COLUMNS MANAGEMENT DROPDOWN START */}
                       
                          <div class="dropdown">
                            <button class="btn btn-warning" onClick={()=>{ document.getElementById("filter-top-card").style.display="block"; }}><i class="mdi mdi-filter-outline"></i></button>

                            <button class="btn btn-primary dropdown-toggle" style={{marginLeft:"1rem"}} type="button" id="dropdownMenuIconButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="mdi mdi-table-large"></i>
                            </button>     

                            <div class="dropdown-menu" aria-labelledby="dropdownMenuIconButton1">
                              <h6 class="dropdown-header">Columns</h6>
                              <hr/>
                              
                              {/* Column 1 */}
                              <li><div class="d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Sn</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c1_yes" onClick={()=>{setcd_col1_val(1); document.getElementById("cd_c1_yes").style.display="none"; document.getElementById("cd_c1_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c1_no" style={{ display:"none"}} onClick={()=>{ setcd_col1_val(0); document.getElementById("cd_c1_yes").style.display="block"; document.getElementById("cd_c1_no").style.display="none"; }}></i>
                              </div></div></li> 
                               {/* Column 2 
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Steam Id</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c2_yes"  onClick={()=>{setcd_col2_val(1); document.getElementById("cd_c2_yes").style.display="none"; document.getElementById("cd_c2_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c2_no" style={{ display:"none"}} onClick={()=>{ setcd_col2_val(0); document.getElementById("cd_c2_yes").style.display="block"; document.getElementById("cd_c2_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 3 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Team Id</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c3_yes"  onClick={()=>{setcd_col3_val(1); document.getElementById("cd_c3_yes").style.display="none"; document.getElementById("cd_c3_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c3_no" style={{ display:"none"}} onClick={()=>{ setcd_col3_val(0); document.getElementById("cd_c3_yes").style.display="block"; document.getElementById("cd_c3_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 4 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Coupon Code</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c4_yes"  onClick={()=>{setcd_col4_val(1); document.getElementById("cd_c4_yes").style.display="none"; document.getElementById("cd_c4_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c4_no" style={{ display:"none"}} onClick={()=>{ setcd_col4_val(0); document.getElementById("cd_c4_yes").style.display="block"; document.getElementById("cd_c4_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 5 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Order Id</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c5_yes"  onClick={()=>{setcd_col5_val(1); document.getElementById("cd_c5_yes").style.display="none"; document.getElementById("cd_c5_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c5_no" style={{ display:"none"}} onClick={()=>{ setcd_col5_val(0); document.getElementById("cd_c5_yes").style.display="block"; document.getElementById("cd_c5_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 6 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Payment Id</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c6_yes"  onClick={()=>{setcd_col6_val(1); document.getElementById("cd_c6_yes").style.display="none"; document.getElementById("cd_c6_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c6_no" style={{ display:"none"}} onClick={()=>{ setcd_col6_val(0); document.getElementById("cd_c6_yes").style.display="block"; document.getElementById("cd_c6_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 7 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Plan Code</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c7_yes" onClick={()=>{setcd_col7_val(1); document.getElementById("cd_c7_yes").style.display="none"; document.getElementById("cd_c7_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c7_no" style={{ display:"none"}} onClick={()=>{ setcd_col7_val(0); document.getElementById("cd_c7_yes").style.display="block"; document.getElementById("cd_c7_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 8 */}
                           {/*}   <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Username</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c8_yes" onClick={()=>{setcd_col8_val(1); document.getElementById("cd_c8_yes").style.display="none"; document.getElementById("cd_c8_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c8_no" style={{ display:"none"}} onClick={()=>{ setcd_col8_val(0); document.getElementById("cd_c8_yes").style.display="block"; document.getElementById("cd_c8_no").style.display="none"; }}></i>
                              </div></div></li>  */}
                              {/* Column 9 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Date Updated</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c9_yes" onClick={()=>{setcd_col9_val(1); document.getElementById("cd_c9_yes").style.display="none"; document.getElementById("cd_c9_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c9_no" style={{ display:"none"}} onClick={()=>{ setcd_col9_val(0); document.getElementById("cd_c9_yes").style.display="block"; document.getElementById("cd_c9_no").style.display="none"; }}></i>
                              </div></div></li> 
                             
                        
                            
                            {/*}  <div class="dropdown-divider"></div>
                              <button class="btn btn-danger">Close</button>*/}
                            </div> 
                          </div>
                          {/* COLUMNS MANAGEMENT DROPDOWN END */}

                          </div>
                          </div>


                    <div class="table-responsive"  id="reports" ref={componentRef}>
                      <table class="table table-hover">
                        <thead>
                          <tr >
                            <th  style={{fontWeight:"bold"}} id="cd_c1" hidden={cd_col1_val==1}>Sn</th>
                        {/*}    <th  style={{fontWeight:"bold"}} id="cd_c2" hidden={cd_col2_val==1}>Steam Id</th> */}
                            <th  style={{fontWeight:"bold"}} id="cd_c3" hidden={cd_col3_val==1}>Team Id</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c4" hidden={cd_col4_val==1}>Coupon Code</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c5" hidden={cd_col5_val==1}>Order Id</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c6" hidden={cd_col6_val==1}>Payment_id</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c7" hidden={cd_col7_val==1}>Plan_code</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c8" hidden={cd_col8_val==1}>Username</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c9" hidden={cd_col9_val==1}>Date Updated</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                       data.map((curElm,ind)=>{

                         return(
                         <>
                       
                        <tr key={curElm.Sn}>
                            <td id="cd_co1"  hidden={cd_col1_val==1}>{curElm.Sn}</td>
                           {/*} <td id="cd_co2"  hidden={cd_col2_val==1}>{curElm.Steam_id}</td> */}
                            <td id="cd_co3"  hidden={cd_col3_val==1}>{curElm.Team_id}</td>
                            <td id="cd_co4"  hidden={cd_col4_val==1}>{curElm.Coupon_code}</td>
                            <td id="cd_co5"  hidden={cd_col5_val==1}>{curElm.Order_id}</td>
                            <td id="cd_co6"  hidden={cd_col6_val==1}>{curElm.Payment_id}</td>
                            <td id="cd_co7"  hidden={cd_col7_val==1}>{curElm.Plan_code}</td>
                            <td id="cd_co8"  hidden={cd_col8_val==1}>{curElm.Username}</td>
                            <td id="cd_co9"  hidden={cd_col9_val==1}>{curElm.Date_updated} ({curElm.Time_updated})</td>
                           </tr>
                          </>
                         )
                         }
					   
	                  		 )
                         }
        

                        </tbody>
                      </table>
                    </div>
                        {/* PAGINATION SECTION START */}
                        <div>
                       <div class="col-12" style={{marginBottom:"2rem",marginTop:"1rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                       
                          <div class="dropdown">
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber-1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-left-drop-circle text-success"></i></button>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber+1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-right-drop-circle text-success"></i></button>
                          </div>
                          </div>
                          </div>
                    </div>
                    {/* PAGINATION SECTION END */}

                    <button class="btn btn-light" onClick={handlePrint}><i class="mdi mdi-cloud-print text-dark"></i></button>
                    <button class="btn btn-light" onClick={downloadExcel}><i class="mdi mdi-file-excel text-dark"></i></button>

                   
                   
                  </div>
                </div>
              </div>
              {/* CUSTOMER DETAILS TABLE END */}

           {/*}   <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Customer Summary</h4>
                    <p class="card-description">customer summary
                    </p>
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr >
                            <th  style={{fontWeight:"bold"}}>Sn</th>
                            <th  style={{fontWeight:"bold"}}>Customer Id</th>
                            <th  style={{fontWeight:"bold"}}>Customer Name</th>
                            <th  style={{fontWeight:"bold"}}>Sales</th>
                            <th  style={{fontWeight:"bold"}}>This month</th>
                            <th  style={{fontWeight:"bold"}}>Last Transaction</th>
                          </tr>
                        </thead>
                        <tbody>
                       
                        <tr>
                            <td>24</td>
                            <td>CA98777</td>
                            <td>Sumit Mukherjee</td>
                            <td>330000.00</td>
                            <td class="text-success">21.00 % <i class="mdi mdi-arrow-up"></i></td>
                            <td>21-08-2023</td>
                          </tr>
                          <tr>
                            <td>24</td>
                            <td>CA98777</td>
                            <td>Sumit Mukherjee</td>
                            <td>330000.00</td>
                            <td class="text-success">21.00 % <i class="mdi mdi-arrow-up"></i></td>
                            <td>21-08-2023</td>
                          </tr>
                          <tr>
                            <td>24</td>
                            <td>CA98777</td>
                            <td>Sumit Mukherjee</td>
                            <td>330000.00</td>
                            <td class="text-success">21.00 % <i class="mdi mdi-arrow-up"></i></td>
                            <td>21-08-2023</td>
                          </tr>
                           <tr>
                            <td>24</td>
                            <td>CA98777</td>
                            <td>Sumit Mukherjee</td>
                            <td>330000.00</td>
                            <td class="text-success">21.00 % <i class="mdi mdi-arrow-up"></i></td>
                            <td>21-08-2023</td>
                          </tr>
                           <tr>
                            <td>24</td>
                            <td>CA98777</td>
                            <td>Sumit Mukherjee</td>
                            <td>330000.00</td>
                            <td class="text-success">21.00 % <i class="mdi mdi-arrow-up"></i></td>
                            <td>21-08-2023</td>
                          </tr>
                           <tr>
                            <td>24</td>
                            <td>CA98777</td>
                            <td>Sumit Mukherjee</td>
                            <td>330000.00</td>
                            <td class="text-success">21.00 % <i class="mdi mdi-arrow-up"></i></td>
                            <td>21-08-2023</td>
                          </tr>
                           <tr>
                            <td>24</td>
                            <td>CA98777</td>
                            <td>Sumit Mukherjee</td>
                            <td>330000.00</td>
                            <td class="text-success">21.00 % <i class="mdi mdi-arrow-up"></i></td>
                            <td>21-08-2023</td>
                          </tr>
                           <tr>
                            <td>24</td>
                            <td>CA98777</td>
                            <td>Sumit Mukherjee</td>
                            <td>330000.00</td>
                            <td class="text-success">21.00 % <i class="mdi mdi-arrow-up"></i></td>
                            <td>21-08-2023</td>
                          </tr>
                          
                         
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>*/}

            
          </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
         {/*} <footer class="footer">
            <div class="d-sm-flex justify-content-center justify-content-sm-between">
              <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
</>
);


//}
}
export default Insideteam_salescouponhistory;