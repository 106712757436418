import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import Navbar from './navbar';



//import '../hireportalcss.css';
//import '../Hireportalcss/hireportalcss.css';

//Importing Page css with screen sizes
import '../Hireportalcss/sdefault.css';
import '../Hireportalcss/s1200.css';
import '../Hireportalcss/s920.css';
import '../Hireportalcss/s768.css';
import '../Hireportalcss/s600.css';
import '../Hireportalcss/sanimation.css';



function Employerviewapplication(){

    return(

    <>
        <div class="default-view">
        <Navbar/>
        {/* MAIN SECTION START */}
        <div class="hire-main-section">

        {/* FORM CONTAINER START */}
        <div class="hire-form-container">
        
        {/* RESUME CARD START */}
            <div class="hire-form-panel" style={{backgroundColor:"#ffffff"}}>

            <h2>Application By Suresh Verma for <span style={{color:"#154f9d"}}>Full Stack Developer Role</span></h2>
            <div class="hire-resume-h-list" style={{marginLeft:"1rem"}}>

            <div class="hire-resume-dsize-3">
            <div class="hire-resume-ict-box">
            <div class="hire-resume-ict-icon"><img src="https://cdn-icons-png.flaticon.com/128/6806/6806987.png" class="hire-resume-ict-icon" /></div>
            <div class="hire-resume-ict-text">sureshverma893@gmail.com</div>
            </div>
            </div>

            <div class="hire-resume-dsize-3">
            <div class="hire-resume-ict-box">
            <div class="hire-resume-ict-icon"><img src="https://cdn-icons-png.flaticon.com/128/10261/10261328.png" class="hire-resume-ict-icon" /></div>
            <div class="hire-resume-ict-text">+91 87878 98787</div>
            </div>
            </div>

            <div class="hire-resume-dsize-3">
            <div class="hire-resume-ict-box">
            <div class="hire-resume-ict-icon"><img src="https://cdn-icons-png.flaticon.com/128/4494/4494495.png" class="hire-resume-ict-icon" /></div>
            <div class="hire-resume-ict-text">sureshverma893@gmail.com</div>
            </div>
            </div>

            </div>
            <div id="line-1"></div>
            <p style={{textAlign:"right",marginRight:"1rem",fontSize:"80%",color:"#939598"}}><b>Status :</b> <span style={{color:"#29cc5f"}}>online </span><b>Last Active :</b> 23-03-2023 <b>Date Applied :</b> 23-03-2023</p>
            {/* RESUME SECTION START */}
            <div class="hire-resume-section">

            <h3 style={{textAlign:"left"}}>ASSIGNMENT</h3>
            <div><p style={{textAlign:"left"}}><b>Assignment Score : <span style={{color:"#2c887f"}}>45/200</span> (<span style={{color:"#1cce45"}}>Pass</span>) </b></p>
            </div>


            <div style={{display:"flex",flexDirection:"row",justifyContent:"end"}}>
            <div>
            <button class="hire-btn-default" style={{backgroundColor:"#12d571",marginRight:"2rem"}}
            onClick={()=>{ window.location.replace("/employer-view-candidate-assignment"); }}>View Assignment</button>
            </div>
            <div>
            <select class="hire-btn-default" style={{backgroundColor:"#ffffff",color:"black",border:"0.1rem solid #0a5492"}}>
            <option>Process</option>
            <option>Shortlist</option>
            <option>Reject</option>
            <option>Hire</option>
            </select>
            </div>
            </div>


            <h3 style={{textAlign:"left"}}>ANSWERS OF APPLICATION QUESTIONS</h3>

            {/* Question 1 Start */}
            <div class="hire-resume-card" style={{marginTop:"0.2rem"}}>
           <h3>Q.1 Why are you the right fit for this role?</h3>
           
            <p><b>Ans: </b> I am a highly motivated and skilled full-stack developer with a passion for building innovative and user-friendly web applications. I have a strong understanding of both front-end and back-end development, and I am proficient in a variety of programming languages and technologies, including JavaScript, HTML, CSS, Python, Django, and MySQL.

            I am also an effective communicator and team player. I am able to clearly articulate my ideas to both technical and non-technical audiences, and I am always willing to collaborate with others to achieve common goals.

            I believe that my skills and experience make me a strong candidate for the full-stack developer role at your company. I am confident that I can contribute to your team's success by developing high-quality, scalable, and secure web applications that meet the needs of your users.

            In addition to my technical skills, I am also a quick learner and I am always eager to take on new challenges. I am confident that I can quickly adapt to your company's specific needs and contribute to your team's success from day one.

            I am excited about the opportunity to work at your company and help you build the next generation of web applications.</p>
            </div>
            {/* Question 1 End */}

               {/* Question 2 Start */}
               <div class="hire-resume-card" style={{marginTop:"0.2rem"}}>
           <h3>Q.2 Why are you the right fit for this role?</h3>
           
            <p><b>Ans: </b> I am a highly motivated and skilled full-stack developer with a passion for building innovative and user-friendly web applications. I have a strong understanding of both front-end and back-end development, and I am proficient in a variety of programming languages and technologies, including JavaScript, HTML, CSS, Python, Django, and MySQL.

            I am also an effective communicator and team player. I am able to clearly articulate my ideas to both technical and non-technical audiences, and I am always willing to collaborate with others to achieve common goals.

            I believe that my skills and experience make me a strong candidate for the full-stack developer role at your company. I am confident that I can contribute to your team's success by developing high-quality, scalable, and secure web applications that meet the needs of your users.

            In addition to my technical skills, I am also a quick learner and I am always eager to take on new challenges. I am confident that I can quickly adapt to your company's specific needs and contribute to your team's success from day one.

            I am excited about the opportunity to work at your company and help you build the next generation of web applications.</p>
            </div>
            {/* Question 2 End */}

               {/* Question 3 Start */}
               <div class="hire-resume-card" style={{marginTop:"0.2rem"}}>
           <h3>Q.3 Why are you the right fit for this role?</h3>
           
            <p><b>Ans: </b> I am a highly motivated and skilled full-stack developer with a passion for building innovative and user-friendly web applications. I have a strong understanding of both front-end and back-end development, and I am proficient in a variety of programming languages and technologies, including JavaScript, HTML, CSS, Python, Django, and MySQL.

            I am also an effective communicator and team player. I am able to clearly articulate my ideas to both technical and non-technical audiences, and I am always willing to collaborate with others to achieve common goals.

            I believe that my skills and experience make me a strong candidate for the full-stack developer role at your company. I am confident that I can contribute to your team's success by developing high-quality, scalable, and secure web applications that meet the needs of your users.

            In addition to my technical skills, I am also a quick learner and I am always eager to take on new challenges. I am confident that I can quickly adapt to your company's specific needs and contribute to your team's success from day one.

            I am excited about the opportunity to work at your company and help you build the next generation of web applications.</p>
            </div>
            {/* Question 3 End */}




            </div>
            {/* RESUME SECTION END */}
    
        </div>
        {/* RESUME CARD END */}


        </div>
        {/* FORM CONTAINER END */}

        </div>
        {/* MAIN SECTION END */}

        </div>

        </>
    );




}


export default Employerviewapplication;