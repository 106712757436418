import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';


   // Setting Values fom Session Storage
   const userlogstatus=sessionStorage.getItem("userloginstatus00");//loginstatus
   //const loguser=sessionStorage.getItem("userusername00");//username
   const userlogtype=sessionStorage.getItem("userlogintype00");//logintype
   const userlogname=sessionStorage.getItem("userloginname00");//loginname
   const userlogadminid=sessionStorage.getItem("userloginadminid00");//loginadminid



function Personalinfo(){


  
      //firstname
      const[firstname,setfirstname]=useState();const updatefirstname = (event) => { setfirstname(event.target.value);};
      //lastname
      const[lastname,setlastname]=useState();const updatelastname = (event) => { setlastname(event.target.value);};
      //email
      const[email,setemail]=useState();const updateemail = (event) => { setemail(event.target.value);};
      //contact
      const[contact,setcontact]=useState();const updatecontact = (event) => { setcontact(event.target.value);};
      //whatsapp
      const[whatsapp,setwhatsapp]=useState();const updatewhatsapp = (event) => { setwhatsapp(event.target.value);};
      //nameofmother
      const[nameofmother,setnameofmother]=useState();const updatenameofmother = (event) => { setnameofmother(event.target.value);};
      //nameoffather
      const[nameoffather,setnameoffather]=useState();const updatenameoffather = (event) => { setnameoffather(event.target.value);};
      //gender
      const[gender,setgender]=useState();const updategender = (event) => { setgender(event.target.value);};
      //dateofbirth
      const[dateofbirth,setdateofbirth]=useState();const updatedateofbirth = (event) => { setdateofbirth(event.target.value);};
      //siblingstatus
      const[siblingstatus,setsiblingstatus]=useState();const updatesiblingstatus = (event) => { setsiblingstatus(event.target.value);};  
      //siblings
      const[siblings,setsiblings]=useState();const updatesiblings = (event) => { setsiblings(event.target.value);};        

      
        //ERROR VARIABLES 
        const[error1,seterror1]=useState();
        const[error2,seterror2]=useState();
        const[error3,seterror3]=useState();
        const[error4,seterror4]=useState();
        const[error5,seterror5]=useState();
        const[error6,seterror6]=useState();
        const[error7,seterror7]=useState();


  
    return(
<>


{/* PERSONAL DETAILS SECTION START */}

<div class="content-wrapper">

<div class="col-12">


                <div class="card">
                  <div class="card-body">
                    <h2 >Personal Details</h2>
                    <div class="form-sample" style={{textAlign:"left"}}></div>

                      <p class="card-description"> Personal information Form </p>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">First Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="firstname" style={{border:"0.1rem solid black"}} required />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Last Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="lastname" style={{border:"0.1rem solid black"}} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Contact Number </label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="contact" style={{border:"0.1rem solid black"}} />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Email</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="email" style={{border:"0.1rem solid black"}} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Whatsapp Number </label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" id="whatsapp" style={{border:"0.1rem solid black"}} />
                            </div>
                          </div>
                          </div>
                          
                        <div class="col-md-6">
                          <div class="form-group row">
                          <div class="form-check">
                              <label class="form-check-label">
                                <input type="checkbox" class="form-check-input" checked /> Same as Personal Contact </label>
                            </div>
                        </div>
                      </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Mother's Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="nameofmother" style={{border:"0.1rem solid black"}} />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Father's Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="nameoffather" style={{border:"0.1rem solid black"}} />
                            </div>
                          </div>
                        </div>
                      </div>
                  
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Gender</label>
                            <div class="col-sm-9">
                              <select class="form-control" id="gender" style={{border:"0.1rem solid black",padding:"1rem"}}>
                                <option>Male</option>
                                <option>Female</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date of Birth</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" id="dateofbirth" placeholder="dd/mm/yyyy" style={{border:"0.1rem solid black"}} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Age</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" id="age" placeholder="43" style={{border:"0.1rem solid black"}} />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Any Sibling</label>
                            <div class="col-sm-4">
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input type="radio" class="form-check-input" name="membershipRadios" /*id="membershipRadios1"*/ id="sibling-yes" value="" checked /> Yes </label>
                              </div>
                            </div>
                            <div class="col-sm-5">
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input type="radio" class="form-check-input" name="membershipRadios" /*id="membershipRadios2"*/ id="sibling-no" value="option2" /> No </label>
                              </div>
                            </div>
                          </div>
                   
                        </div>
                 
                      </div>
                         {/*<button type="button" class="btn btn-outline-danger btn-icon-text" style={{}}> <i class="mdi mdi-chevron-double-left"></i>Back </button> */}
                         <button type="button" class="btn btn-outline-success btn-icon-text" style={{marginLeft:"3rem"}} onClick={()=>{        
                        //Next Button                  
                          document.getElementById("section-2").style.display="block";//Showing next section
                          document.getElementById("section-1").style.display="none";//Hiding previous section
                          sessionStorage.setItem("pval","20%");// Updating Progress Bar
                      }} > Next <i class="mdi mdi-chevron-double-right"></i></button> 
                     <button type="button" class="btn btn-gradient-dark float-end">Save</button>

                   
                    </div>
                  </div>
                </div>
              </div>

              
              {/* PERSONAL DETAILS SECTION END */}
</>
);
    }

export default Personalinfo;