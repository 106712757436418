import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios';
import Navbar from '../navbar.js';
import Sidebar from '../sidebar.js';
import Topanalytics from './topanalytics.js';
import Topchart from './topchart.js';
import Editadmin from './editadmin';



/*import Amanbg from './amanbg';*/


function Adminpaneldashboard(){

  
   // Setting Values fom Session Storage
 const logstatus=sessionStorage.getItem("loginstatus00");//loginstatus
 const loguser=sessionStorage.getItem("username00");//username
 const logtype=sessionStorage.getItem("logintype00");//logintype
 const logname=sessionStorage.getItem("loginname00");//loginname
 const logadminid=sessionStorage.getItem("loginadminid00");//loginadminid


   //name
   const[cname,setcname]=useState();const updatecname = (event) => { setcname(event.target.value);};
   //email
   const[email,setemail]=useState();const updateemail = (event) => { setemail(event.target.value);};
   //contact
   const[contact,setcontact]=useState();const updatecontact = (event) => { setcontact(event.target.value);};
   //username
   const[username,setusername]=useState();const updateusername = (event) => { setusername(event.target.value);};
   //status
   const[status,setstatus]=useState();const updatestatus = (event) => { setstatus(event.target.value);};
   //password
   const[password,setpassword]=useState();const updatepassword = (event) => { setpassword(event.target.value);};
   //cpassword
   const[cpassword,setcpassword]=useState();const updatecpassword = (event) => { setcpassword(event.target.value);};
 
     //ERROR VARIABLES 
     const[error1,seterror1]=useState();
     const[error2,seterror2]=useState();
     const[error3,seterror3]=useState();
     const[error4,seterror4]=useState();
     const[error5,seterror5]=useState();
     const[error6,seterror6]=useState();
     const[error7,seterror7]=useState();
     const[error8,seterror8]=useState();

     /*     TEAM LIST       */
     
   const[data,setData] = useState([]);//SetData Variable
    //Loading count
    const[loadcount,setloadcount] = useState(0);
    //Button Press
    const[buttonpress,setbuttonpress] = useState("No");
    //Error Variable
    const[errorvar,seterrorvar] = useState();
    //Data Count
    const[datacount,setdatacount] = useState();
    //User Count
    const[usercount,setusercount] = useState();
    //Filter Column
    const[filter,setfilter] = useState("All");const updatefilter = (event) => { setfilter(event.target.value);};
    //Filter Value
    const[filterval,setfilterval] = useState();const updatefilterval = (event) => { setfilterval(event.target.value);};

   //Sessions Not Set
 {/* if(((logstatus=="false")||(logstatus==null))&&(loguser==null)&&(logadminid==null)){
 
  sessionStorage.removeItem("username00");
  sessionStorage.removeItem("loginstatus00");
  sessionStorage.removeItem("logintype00");
  sessionStorage.removeItem("loginname00");
  sessionStorage.removeItem("loginadminid00");
 
  alert("Session Expired! Login Again.");
  window.location.replace("/");

}
//Sessions Set
else{
*/}

  //Use Effect, Data Load
  useEffect(()=>{

    getData();

},[])

function getData(){
  

   //Checking if user loggedin
 if(((logstatus=="true")||(logstatus!=null))&&(loguser!=null)&&(logadminid!=null)){


  
  axios.post("adminlist", {
    Loadcount:loadcount,
    User: loguser,
    Logtype:logtype,
    Logstatus:logstatus,
    Log_id: logadminid,
    Button_val: buttonpress,
    filter: filter,
   filterVal: filterval
  })
  .then((response) => {
   //Success Data Fetched
   if(response.data.successvar==1){
    //Setting Data
    setData(response.data.data);
    setdatacount(response.data.datacount);
    setloadcount(response.data.loadcount);
    setloadcount(1);
   }
   //Cannot Fetch Data
   else{
    seterrorvar("Cannot Fetch Data !");
   }
  

  });

  }
//User not Logedin
else{

  sessionStorage.removeItem("username00");
  sessionStorage.removeItem("loginstatus00");
  sessionStorage.removeItem("logintype00");
  sessionStorage.removeItem("loginname00");
  sessionStorage.removeItem("loginadminid00");
 
  alert("Session Expired! Login Again.");
  window.location.replace("/");

   }

}

//Filter Function
function getFunction(){
  getData();
}


//Default Load, Getting Data from Admin Id
function defaultLoad(){
  //Checking if user loggedin and Admin Id Set for Edit
if(((logstatus=="true")||(logstatus!=null))&&(loguser!=null)&&(logadminid!=null)){

const Fetchadmin = sessionStorage.getItem("editId00");

//Admin Id Set
if((Fetchadmin!=null)&&(Fetchadmin>0)){

//Sending Data through axios
axios.post("admindetails", { 
User: loguser,
Logtype:logtype,
Logstatus:logstatus,
Log_id: logadminid,
AdminId:Fetchadmin

})

.then((response) => {

if((response.data.successvar)==1){

setcname(response.data.name);
setemail(response.data.email);
setcontact(response.data.contact);
setstatus(response.data.status);
setusername(response.data.username);
}



})

}else{

//Removing Edit Variables
document.getElementById("editadmin-box").style.display="none"; document.getElementById("change-pwd").style.display="none";sessionStorage.removeItem("editId00");}
}
//User not Logedin
else{

sessionStorage.removeItem("username00");
sessionStorage.removeItem("loginstatus00");
sessionStorage.removeItem("logintype00");
sessionStorage.removeItem("loginname00");
sessionStorage.removeItem("loginadminid00");

alert("Session Expired! Login Again.");
window.location.replace("/");



}
}





//Edit Function
function editadmindetails(){
  //Checking if user loggedin and Admin Id Set for Edit
if(((logstatus=="true")||(logstatus!=null))&&(loguser!=null)&&(logadminid!=null)){

const Fetchadmin = sessionStorage.getItem("editId00");

//Admin Id Set
if((Fetchadmin!=null)&&(Fetchadmin>0)){

  let noerror = 0;

  const $name = document.getElementById("edit-name").value; if($name==""){ seterror1("Name is required !");noerror = noerror+1; }
  const $username = document.getElementById("edit-username").value; if($username==""){ seterror2("Username is required !");noerror = noerror+1; }
  const $email = document.getElementById("edit-email").value; if($email==""){ seterror3("Email is required !");noerror = noerror+1; }
  const $contact = document.getElementById("edit-contact").value; if($contact==""){ seterror4("Contact is required !");noerror = noerror+1; }
  const $status = document.getElementById("edit-status").value; if($status==""){ seterror5("Status is required !");noerror = noerror+1; }

  //Checking if there are errors
  if(noerror==0){ 

//Sending Data through axios
axios.post("editadmindetails", { 
User: loguser,
Logtype:logtype,
Logstatus:logstatus,
Log_id: logadminid,
AdminId:Fetchadmin,
Name:$name,
Username:$username,
Email:$email,
Contact:$contact,
Status:$status,
Password:document.getElementById("edit-password").value,
ConfirmPassword:document.getElementById("edit-cpassword").value
})

.then((response) => {

  
seterror1(" ");seterror2(" ");seterror3(" ");seterror4(" ");seterror5(" ");seterror6(" ");seterror7(" ");seterror8("");
  
  //Validation Errors
if(response.data.validatorerror){
  seterror1(response.data.validatorerror.Name);
  seterror2(response.data.validatorerror.Username);
  seterror3(response.data.validatorerror.Email);
  seterror4(response.data.validatorerror.Contact);
  seterror5(response.data.validatorerror.Status);
  seterror6(response.data.validatorerror.Password);
  seterror7(response.data.validatorerror.Cpassword);

  if(response.data.error=='101'){ seterror8("Data Not Updated!"); }
 
   }
//Invalid Entries
if(response.data.successvar==2){
  seterror2(response.data.name_er);
  seterror2(response.data.Username_er);
  seterror3(response.data.email_er);
  seterror4(response.data.contact_er);
  seterror5(response.data.status_er);
  seterror6(response.data.password_er);
  seterror7(response.data.cpassword_er);
  seterror8("Data Not Updated!");

 
       }
if((response.data.successvar)==1){

  alert("Data Updated Successfully!"+"<br/>"+response.data.pass_update_msg);
}
else{
  alert("Data Not Updated!");
}



})
  }

}else{

//Removing Edit Variables
document.getElementById("editadmin-box").style.display="none"; document.getElementById("change-pwd").style.display="none";sessionStorage.removeItem("editId00");}
}

//User not Logedin
else{

sessionStorage.removeItem("username00");
sessionStorage.removeItem("loginstatus00");
sessionStorage.removeItem("logintype00");
sessionStorage.removeItem("loginname00");
sessionStorage.removeItem("loginadminid00");

alert("Session Expired! Login Again.");
window.location.replace("/");



}
}





//Delete Function
function deleteadmindetails(){
  //Checking if user loggedin and Admin Id Set for Edit
if(((logstatus=="true")||(logstatus!=null))&&(loguser!=null)&&(logadminid!=null)){

const Fetchadmin = sessionStorage.getItem("editId00");

//Admin Id Set
if((Fetchadmin!=null)&&(Fetchadmin>0)){

//Sending Data through axios
axios.post("deleteadmindetails", { 
User: loguser,
Logtype:logtype,
Logstatus:logstatus,
Log_id: logadminid,
AdminId:Fetchadmin,
})

.then((response) => {

  
  //Validation Errors
if(response.data.validatorerror){

  if(response.data.error=='101'){ alert("Data Not Deleted!"); }
 
   }
//Invalid Entries
if(response.data.successvar==2){

  alert("Data Not Updated!");

 
       }
if((response.data.successvar)==1){

  alert("Data Deleted Successfully!");
}
else{
  alert("Data Not Deleted!");
}



})

}else{

//Removing Edit Variables
document.getElementById("editadmin-box").style.display="none"; document.getElementById("change-pwd").style.display="none";sessionStorage.removeItem("editId00");}
}
//User not Logedin
else{

sessionStorage.removeItem("username00");
sessionStorage.removeItem("loginstatus00");
sessionStorage.removeItem("logintype00");
sessionStorage.removeItem("loginname00");
sessionStorage.removeItem("loginadminid00");

alert("Session Expired! Login Again.");
window.location.replace("/");



}
document.getElementById("deleteadmin-box").style.display="none";
}

    return(
<>
{/*<!DOCTYPE html> */}
<html lang="en">
  <head>
    {/* Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>theStartupConnect | Portal</title>
    {/* plugins:css */}
    <link rel="stylesheet" href="./assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="./assets/vendors/css/vendor.bundle.base.css" />
    {/* endinject */}
    {/* Plugin css for this page */}
    {/* End plugin css for this page */}
    {/* inject:css */}
    {/* endinject */}
    {/* Layout styles */}
    <link rel="stylesheet" href="./assets/css/style.css" />
    {/* End layout styles */}
   {/*} <link rel="shortcut icon" href="./assets/images/favicon.ico" /> */}
  </head>
  <body>

<Navbar/>

{/* MAIN PAGE COMPONENT START */}
  <div class="container-fluid page-body-wrapper">

 <Sidebar/>


{/* MAIN PANEL START */}
<div class="main-panel" style={{marginTop:"5vh"}}>
<div class="content-wrapper">

{/* DELETE ADMIN ALERT BOX SECTION START */}
<div class="deleteadmin-box" id="deleteadmin-box"  style={{position:"fixed",marginLeft:"2%",marginTop:"-2%",width:"70%",backgroundColor:"#fdffff",display:"none",zIndex:"3",boxShadow:"0.1rem 0.1rem 0.1rem 0.1rem #c7d0ce"}}>
<h3 style={{marginTop:"3rem"}}> Are you sure you want to Delete this account?</h3>
<button class="btn btn-block btn-lg btn-success mt-4 mb-4" onClick={deleteadmindetails}>Yes</button>
<button class="btn btn-block btn-lg btn-danger mt-4 mb-4 " style={{marginLeft:"2rem"}} onClick={()=>{ document.getElementById("deleteadmin-box").style.display="none"; }}>No</button>

</div>
{/* DELETE ADMIN ALERT BOX SECTION END */}


{/* EDIT ADMIN SECTION START */}
<div class="editadmin-box" id="editadmin-box"  style={{position:"fixed",marginLeft:"2%",marginTop:"-2%",width:"70%",backgroundColor:"#fdffff",display:"none",zIndex:"3",boxShadow:"0.1rem 0.1rem 0.1rem 0.1rem #c7d0ce"}}>
{/*<Editadmin/>*/}
{/* Edit Team Section Start */}

<div>
       <div class="col-12" >     
                <div class="card">
                <div><button class="float-end" style={{padding:"1rem",backgroundColor:"transparent",border:"none"}} onClick={()=>{ sessionStorage.removeItem("editId00"); document.getElementById("editadmin-box").style.display="none";document.getElementById("change-pwd").style.display="none";}}><i class="mdi mdi-close-box" style={{color:"#f04f60",fontSize:"2rem"}}></i></button></div>
                  <div class="card-body">
                    <h4 class="card-title">Edit Admin Details</h4>
                    <div class="form-sample">
                      <p class="card-description"> </p>
                      <small style={{fontSize:".8rem",color:"#f16083"}}>{error8}</small>  
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Admin Id</label>
                            <div class="col-sm-9">
                              <label class="col-form-label">{sessionStorage.getItem("editId00")}</label>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="edit-name" value={cname} onChange={updatecname} />
                              <small style={{fontSize:".8rem",color:"#f16083"}}>{error1}</small>  
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Username</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="edit-username" value={username} onChange={updateusername} />
                              <small style={{fontSize:".8rem",color:"#f16083"}}>{error2}</small>  
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Status</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="edit-status" value={status} onChange={updatestatus} />
                              <small style={{fontSize:".8rem",color:"#f16083"}}>{error5}</small>  
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Contact</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" id="edit-contact" value={contact} onChange={updatecontact} />
                            <small style={{fontSize:".8rem",color:"#f16083"}}>{error4}</small>  
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Email</label>
                            <div class="col-sm-9">
                              <input class="form-control" id="edit-email" value={email} onChange={updateemail} />
                              <small style={{fontSize:".8rem",color:"#f16083"}}>{error3}</small>  
                            </div>
                          </div>
                        </div>
                        <div class="d-flex justify-content-end"><button class="btn btn-outline-info" onClick={()=>{ document.getElementById("change-pwd").style.display="block";}}>Change Password</button></div>
                      </div>
                      <div class="row mt-4" id="change-pwd" style={{display:"none"}}>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Password</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" id="edit-password" placeholder="Password" value={password} onChange={updatepassword}/>
                            <small style={{fontSize:".8rem",color:"#f16083"}}>{error6}</small>  
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Confirm Password</label>
                            <div class="col-sm-9">
                              <input class="form-control" id="edit-cpassword" placeholder="Confirm Password" value={cpassword} onChange={updatecpassword} />
                              <small style={{fontSize:".8rem",color:"#f16083"}}>{error7}</small>  
                            </div>
                          </div>
                        </div>
                      </div>
                      <button class="btn btn-block btn-lg btn-primary mt-4 mb-4" onClick={editadmindetails}>Edit Details</button>
                  
                    
                    </div>
                  </div>
                </div>
              </div>

       
       </div>
            
{/* Edit Team Section End */}
</div>
{/* EDIT ADMIN SECTION END */}


<h2 class="text-center mb-2">Admin Panel</h2>
         <Topanalytics/>
       
       {/*  MANAGE ADMIN SECTION START */}
         <button class="btn btn-block btn-lg btn-gradient-primary mt-4 mb-4" onClick={()=>{
          window.location.replace("/adminstartupconnect-register");
         }}>+ Add New Admin</button>
         <div class="row">
         {/* FILTER SECTION START */}
        <div class="d-flex justify-content-end mb-4"><button style={{backgroundColor:"#1ea6ae",border:"none",boxShadow:"1px 1px 1px 1px #bec3c3",borderRadius:"5%"}}><i class="mdi mdi-filter-outline mb-3" style={{color:"#ffffff",fontSize:"1.6rem"}}
        onClick={()=>{ document.getElementById("filter-form").style.display="block"}}><span style={{fontSize:"1.2rem"}}>Filters</span></i></button></div>
        <div id="filter-form" style={{display:"none"}}>
        <div class="col-12">
                <div class="card">
                <div class="card-body">
                <div><button class="float-end" style={{padding:"1rem",backgroundColor:"transparent",border:"none"}} onClick={()=>{ document.getElementById("filter-form").style.display="none";}}><i class="mdi mdi-close-box" style={{color:"#f04f60",fontSize:"2rem"}}></i></button></div>
                    <h4 class="card-title"></h4>
                    <div class="form-sample">
                      <p class="card-description">Apply Filters</p>
                      <div class="row">
                      <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Select Column</label>
                            <div class="col-sm-9">
                              <select class="form-control" style={{border:"0.1rem solid #6d7576"}} onChange={updatefilter}>
                              <option value={filter}>{filter}</option>
                                <option value="AdminId">Admin Id</option>
                                <option value="Name">Name</option>
                                <option value="Username">Username</option>
                                <option value="Email">Email</option>
                                <option value="Contact">Contact</option>
                                <option value="Status">Status</option>
                                <option value="All">All</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Enter Value" style={{border:"0.1rem solid #6d7576"}} onChange={updatefilterval} value={filterval} />
                            </div>
                            <div class="col-sm-3">
                            <button type="submit" class="btn btn-gradient-info mb-2" onClick={getFunction}>Apply</button>
                            </div>
                          </div>
                        </div>     
                        <hr style={{color:"#7b8889"}}/>              
                      </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>
        {/* FILTER SECTION END */}
              <div class="col-12 grid-margin">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">List of Admins</h4>
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th>Image</th>
                            <th> Admin Id </th>
                            <th> Name </th>
                            <th> Username </th>
                            <th> Email </th>
                            <th> Contact </th>
                            <th> Status </th>
                            <th> Edit </th>
                            <th> Delete </th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                       data.map((curElm,ind)=>{

return(
  <>
                          <tr key={curElm.Admin_id}>
                            <td>
                              <img src="assets/images/faces/face1.jpg" class="me-2" alt="image" />
                            </td>
                            <td>{curElm.Admin_id}</td>
                            <td>{curElm.Name}</td>
                            <td>{curElm.Username}</td>
                            <td>{curElm.Email}</td>
                            <td>{curElm.Contact}</td>
                            <td>
                              <label class="badge badge-gradient-success">{curElm.Status}</label>
                            </td>
                            <td> <button style={{backgroundColor:"transparent",border:"none"}} onClick={()=>{
                              //Redirecting to Edit
                             
                             sessionStorage.setItem("editId00",curElm.Admin_id);
                             defaultLoad();
                             document.getElementById("editadmin-box").style.display="block";

                            }}><i class="mdi mdi-pencil-box" style={{color:"#dbc821",fontSize:"2rem"}}></i></button></td>
                            <td> <button style={{backgroundColor:"transparent",border:"none"}}  onClick={()=>{
                              //Redirecting to Edit
                             
                             sessionStorage.setItem("editId00",curElm.Admin_id);
                           
                           //  defaultLoad();
                             document.getElementById("deleteadmin-box").style.display="block";

                            }}><i class="mdi mdi-minus-box" style={{color:"#e1435d",fontSize:"2rem"}}></i></button></td>
                          </tr>
</>
      )
      }
					   
			)}
                          
                       
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      {/* MANAGE ADIN SECTION END */}
         
         <Topchart/>
{/*<Pageheader/>
<Topanalytics/>*/}
    
</div>
</div>
{/* MAIN PANEL END */}

</div>
{/* MAIN PAGE COMPONENT END */}

    {/* container-scroller */}
    {/* plugins:js */}
    <script src="./assets/vendors/js/vendor.bundle.base.js"></script>
    {/* endinject */}
    {/* Plugin js for this page */}
    {/* End plugin js for this page */}
    {/* inject:js */}
    <script src="./assets/js/off-canvas.js"></script>
    <script src="./assets/js/hoverable-collapse.js"></script>
    <script src="./assets/js/misc.js"></script>

    {/*} plugins:js */}
    <script src="assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="assets/vendors/chart.js/Chart.min.js"></script>
    <script src="assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="assets/js/off-canvas.js"></script>
    <script src="assets/js/hoverable-collapse.js"></script>
    <script src="assets/js/misc.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="assets/js/dashboard.js"></script>
    <script src="assets/js/todolist.js"></script>
    {/*} End custom js for this page */}
    {/* endinject */}
  </body>
</html>
</>
    );
}
//}

export default Adminpaneldashboard;