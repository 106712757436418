import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from './../axios.js';




/*import Amanbg from './amanbg';*/


function Login(){

       //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("loginstatus00");

 // Access value associated with the Username
//const User=sessionStorage.getItem("username00");

  //saving default values with usestate
const state = useState();

  //email
  const[cemail,setcemail]=useState();const updatecemail = (event) => { setcemail(event.target.value);};
  //password
  const[password,setpassword]=useState();const updatepassword = (event) => { setpassword(event.target.value);};


      //ERROR VARIABLES 
      const[error1,seterror1]=useState();
      const[error2,seterror2]=useState();
      const[error3,seterror3]=useState();


      //Redirecting to Services Portal if LoggedIn
  useEffect(() => {

         //Checking if sessions set
    if(logstatus=="true"){       window.location.replace("/dashboard");   }

});


  function loginme(){




      seterror1("");
      seterror2("");
      seterror3("");
     

      let noerror = 0;

      const $email = document.getElementById("email").value; if($email==""){ seterror2("Email is required !");noerror = noerror+1; }
      const $password = document.getElementById("password").value; if($password==""){ seterror3("Password is required !");noerror = noerror+1; }
     
    //No primary errors
if(noerror==0){
      //Sending Data through axios
      axios.post("login", { 
      
       Email:$email,
       Password:$password,
     
 })
 
     .then((response) => {
 
       seterror1(" ");seterror2(" ");seterror3(" ");
 
       //Validation Errors
     if(response.data.validatorerror){
       seterror2(response.data.validatorerror.Email);
       seterror3(response.data.validatorerror.Password);
     
     }
     //Invalid Entries
     if(response.data.error==3){
       seterror1("Invalid Email or Password");
      
     }
     //Email Doesn't exists
     if(response.data.error==1){
       seterror2("Email Doesn't Exists!");
     }
     //Incorrect Password
     if(response.data.error==2){
       seterror3("Incorrect Password!");
     }
     //success
     if(response.data.successvar==1){

      //Setting Sessions
      const log = response.data.loginstatus;
      //Setting Sessions
     
     // sessionStorage.setItem("username00", $username); // Assign value to username
     
      sessionStorage.setItem("userlogintype00", "client"); //Assign value to logintype
      
      sessionStorage.setItem("userloginname00", response.data.adminname); //Assign value to loginname
     
      sessionStorage.setItem("userloginadminid00", response.data.adminid); //Assign value to loginadminid
 
      sessionStorage.setItem("userloginstatus00", response.data.loginstatus); // Assign value to status

      sessionStorage.setItem("userusername00", $email); // Assign value to user


     alert("User Logged In Successfully!");
   //  window.location.replace("/candidatedetails");
   window.location.replace("/dashboard");
     
     }
     //failure
     if(response.data.successvar==0){
     seterror1("Cannot Login, Try Again!");
     }
   }    );

 }
 //Return Errors
 else{
   return;
 }




   
  }
    return(
<>
{/*<!DOCTYPE html> */}
<html lang="en">
  <head>
    {/* Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>theStartupConnect | Services Portal</title>
    {/* plugins:css */}
    <link rel="stylesheet" href="./assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="./assets/vendors/css/vendor.bundle.base.css" />
    {/* endinject */}
    {/* Plugin css for this page */}
    {/* End plugin css for this page */}
    {/* inject:css */}
    {/* endinject */}
    {/* Layout styles */}
    <link rel="stylesheet" href="./assets/css/style.css" />
    {/* End layout styles */}
   {/*} <link rel="shortcut icon" href="./assets/images/favicon.ico" /> */}
  </head>
  <body>
    <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-center auth">
          <div class="row flex-grow">
            <div class="col-lg-4 mx-auto">
              <div class="auth-form-light text-left p-5">
                <div class="brand-logo">
                  <img src="./assets/images/logo-a.jpeg" />
                </div>
                <h4>Hello! let's get started</h4>
                <h6 class="font-weight-light">Sign in to continue.</h6>
                <small style={{fontSize:".8rem",color:"#f16083"}}>{error1}</small>
                <div class="pt-3">
                  <div class="form-group">
                    <input type="email" class="form-control form-control-lg" id="email" value={cemail} onChange={updatecemail} placeholder="Email" />
                    <small style={{fontSize:".8rem",color:"#f16083"}}>{error2}</small>
                  </div>
                  <div class="form-group">
                    <input type="password" class="form-control form-control-lg" id="password"  value={password} onChange={updatepassword} placeholder="Password" />
                    <small style={{fontSize:".8rem",color:"#f16083"}}>{error3}</small>
                  </div>
                  <div class="mt-3">
                    <a class="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" onClick={loginme}>SIGN IN</a>
                  </div>
                  <div class="my-2 d-flex justify-content-between align-items-center">
                 {/*}   <div class="form-check">
                      <label class="form-check-label text-muted">
                        <input type="checkbox" class="form-check-input" /> Keep me signed in </label>
                    </div> */}
                    <a href="#" class="auth-link text-black">Forgot password?</a>
                  </div>
                  <div class="mb-2">
                   {/*} <button type="button" class="btn btn-block btn-facebook auth-form-btn">
                      <i class="mdi mdi-facebook me-2"></i>Connect using facebook </button> */}
                  </div>
                  <div class="text-center mt-4 font-weight-light"> Don't have an account? <a href="/register" class="text-primary">Create</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* content-wrapper ends */}
      </div>
      <p>The site is currently under development. Some functionalities may not work.</p>
      <p>Sorry for inconvenience!</p>
      {/* page-body-wrapper ends */}
    </div>
    {/* container-scroller */}
    {/* plugins:js */}
    <script src="./assets/vendors/js/vendor.bundle.base.js"></script>
    {/* endinject */}
    {/* Plugin js for this page */}
    {/* End plugin js for this page */}
    {/* inject:js */}
    <script src="./assets/js/off-canvas.js"></script>
    <script src="./assets/js/hoverable-collapse.js"></script>
    <script src="./assets/js/misc.js"></script>
    {/* endinject */}
  </body>
</html>
</>
    );
}

export default Login;