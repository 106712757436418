import React from "react";
import { useState, useEffect, Component } from "react";
import { useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
//import axios from '../../axios.js';
import moment from "moment/moment";

import "../../Resources/homebtcss.css";
import "../../Resources/home2css.css";
import "../../Resources/serviceshomecss.css";
import "../ankitcss.css";
import '../../Resources/responsiveness.css';

//import Sidebar from "./sidebar";

//import Chart from "chart.js/auto";
//import { Line, Bar } from "react-chartjs-2";
import Footer3 from "../../Supportingfiles/footer3";
//import { Navbar } from "flowbite-react";
import Navbar3 from "../../Supportingfiles/navbar3";
import Whystartupconnect from "../whystartupconnect";


function Startupindiaregistration1() {

  

  /* Cashfree Button Style */

  const button_container = {
    border: "1px solid black",
    borderRadius: "5px",
    display: "flex",
    padding: "10px", 
    width: "fit-content",
    cursor: "pointer",
    background: "#0b6083",
    border:"none"
};

const text_container = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginLeft: "10px",
  justifyContent: "center",
  marginRight: "10px",
  fontWeight:"bold"
};
const logo_container = {
  width: "40px",
  height: "40px"
};
const secondary_logo_container = {
width: "16px",
height: "16px",
verticalAlign: "middle"
};

  return (
    <>
     <html lang="en">
        <head>
          {/*} Required meta tags */}
          <meta charset="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <title>STARTUP CONNECT | SERVICES | STARTUP INDIA REGISTRATION</title>
          {/*} plugins:css */}
          <link
            rel="stylesheet"
            href="panel/assets/vendors/mdi/css/materialdesignicons.min.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/css/vendor.bundle.base.css"
          />
          {/*} endinject */}
          {/*} Plugin css for this page */}
          <link
            rel="stylesheet"
            href="panel/assets/vendors/jquery-bar-rating/css-stars.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/font-awesome/css/font-awesome.min.css"
          />
          {/*} End plugin css for this page */}
          {/*} inject:css */}
          {/*} endinject */}
          {/*} Layout styles */}
          <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
          {/*} End layout styles */}
          <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
        </head>
        <body>

        
  {/* Whatsapp Chat Icon Start */}
  <div class="wtp-chat-container" /*style={{position:"fixed",marginTop:"92vh", marginLeft:"94vw"}}*/>
    <a aria-label="Chat on WhatsApp" href="https://wa.me/918480002413?text=I%20want%20to%20know%20more%20about%20service%20Startup%20India%20Registration%20by%20the%20Startup%20Connect">   
    <img class="wtp-chat-icon" src="https://cdn-icons-png.flaticon.com/128/3938/3938058.png" /*style={{height:"3rem", width:"3rem"}}*/></img>
  </a>
  </div>
  {/* Whatsapp Chat Icon End */}

          <div class="home2-container">
            <Navbar3 />

            {/* HEADER SECTION START */}
            <section /*class="sv-header-section" */>
              <div class="sv-header-container1">
                <div class="sv-header-left home-header-pd">
                  <h1 style={{ color: "#004463" }}>
                    STARTUP INDIA (DPIIT) REGISTRATION
                  </h1>
                  <p>
                  <div style={{ marginBottom: "2px" }}>
                      <span style={{ paddingRight: "5px" }}><i class="mdi mdi-checkbox-marked" style={{fontSize:"2rem", color :"#0b5748"}}></i></span>
                      <span>Fast customer support </span>
                    </div>
                    <div style={{ marginBottom: "2px" }}>
                      <span style={{ paddingRight: "5px" }}><i class="mdi mdi-checkbox-marked" style={{fontSize:"2rem", color :"#0b5748"}}></i></span>
                      <span>Complete online process.</span>
                    </div>
                    <div style={{ marginBottom: "2px" }}>
                      <span style={{ paddingRight: "5px" }}><i class="mdi mdi-checkbox-marked" style={{fontSize:"2rem", color :"#0b5748"}}></i></span>
                      <span>Instant WhatsApp/email updates </span>
                    </div>
                  </p>

                  <div class="home2-services-features-list">
                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img
                          loading="lazy"
                          src="https://cdn-icons-png.flaticon.com/128/1087/1087840.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>500+</h3>
                        <h5 style={{ fontWeight: "bold" }}>Projects</h5>
                      </div>
                    </div>

                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img
                          loading="lazy"
                          src="https://cdn-icons-png.flaticon.com/128/12735/12735475.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>25+</h3>
                        <h5 style={{ fontWeight: "bold" }}>Cities</h5>
                      </div>
                    </div>

                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img
                          loading="lazy"
                          src="https://cdn-icons-png.flaticon.com/128/2991/2991235.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>
                          4{" "}
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/7656/7656139.png"
                            style={{ height: "2rem", width: "2rem" }}
                          />
                          +
                        </h3>
                        <h5 style={{ fontWeight: "bold" }}>Google Ratings</h5>
                      </div>
                    </div>
                  </div>

                  <div  class="serviceshome-btn-container">
             <div>
             <a href="https://calendly.com/thestartupconnect/30min"><button class="btn-lft-margin" id="home2-modules-section-btn" style={{marginLeft:"0"}}>Book Free Consultancy <i class="mdi mdi-arrow-right"></i></button></a>
             </div>
             <div style={{marginLeft:"0"}}>
             <a href="/home1/startup_india_registration.pdf" download="Startup Connect Services Brochure"><button class="btn-lft-margin" id="home2-modules-section-btn" style={{backgroundImage: "linear-gradient(to right, #208d17, #00ad11)", marginLeft:"0"}}>Download Brochure <i class="mdi mdi-download"></i></button></a>
             </div>
             </div>
             
                </div>
                <div
                  class="sv-header-right" /* style={{backgroundColor:"#96dfff",padding:"2rem",borderRadius:"15px",boxShadow:"2px 3px 3px 2px #cbcecf"}} */
                >
                  <img
                    loading="lazy"
                    src="https://img.freepik.com/free-vector/group-people-starting-business-project_52683-57206.jpg"
                    style={{ width: "90%", height: "100%" }}
                  />
                </div>
              </div>
            </section>
            {/* HEADER SECTION END */}
            <hr />

            {/* SIDEBAR AND MAIN PAGE START */}
            <div className="container-scroller">
              {/*<Sidebar /> */}
              <div className="container-fluid page-body-wrapper">
                {/*} partial */}
                <div className="main-panel">

                  {/* MORE INFORMATION SECTION START */}
                  <div class="row" style={{ padding: "2rem" }}>
                    <div
                      class="col-sm-9 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div>
                        <h2 id="ip-h2" style={{ fontWeight: "bold" }}>
                          WHAT IS{" "}
                          <span style={{ color: "#9f23c2" }}>
                           STARTUP INDIA (DPIIT) REGISTRATION
                          </span>
                        </h2>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                          {" "}
                          The government has rolled out a Startup India scheme
                          for the growth of businesses by promoting innovations.
                          The DIPP registration comes with many benefits that
                          startups can leverage by registering a business entity
                          under the StartUp India scheme. This scheme is
                          envisioned at transforming India into a job-provider
                          rather than a job seeker. Only an entity which is
                          registered as a Private Limited Company or a Limited
                          Liability Partnership (LLP) or a Registered
                          Partnership Firm can get recognition under Startup
                          India Scheme. Further, such startup must be involved
                          in the activities towards innovation or improvement in
                          existing products or services, and must be capable of
                          generating employment or wealth creation.
                        </p>
                        
                        <p style={{ fontSize: "1rem" }}>
                          Only an eligible entity as per the criteria provided
                          by the government can take registration under the said
                          scheme by applying online via Mobile Application or
                          through portal set up by DIPP.{" "}
                        </p>
                      </div>
                    </div>
                    <div class="col-sm-3 stretch-card grid-margin">
                      <img
                        loading="lazy"
                        src="https://img.freepik.com/free-vector/pitch-meeting-concept-illustration_114360-6000.jpg"
                        style={{ width: "90%", height: "100%" }}
                      />
                    </div>
                  </div>
                  {/* MORE INFORMATION SECTION END */}

                  <hr />
                  <h2 id="ip-h2">BENEFITS OF STARTUP INDIA REGISTRATION
</h2>
                  {/* BENEFITS SECTION START */}
                  <div class="row d-flex justify-content-center" style={{ padding: "2rem" }}>
                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/2590/2590440.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Tax Exemption under 80-IAC</h4>
                        <p>
                        Post getting recognition a Startup may apply for Tax exemption under section 80 IAC of the Income Tax Act. Post getting clearance for Tax exemption, the Startup can avail tax holiday for 3 consecutive financial years out of its first ten years since incorporation.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/2758/2758527.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Section 56 Exemption</h4>
                        <p>
                          The Entity registered under the Startup Inida Scheme can apply for an exemption of income tax under section 80 IAC of the income TAX Act. investments into eligible startups by listed companies with a net worth of more than INR 100 Crore or turnover more than INR 250 Crore shall be exempt under Section 56 (2) VIIB of Income Tax Act.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/11443/11443795.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Interaction platform for Startups</h4>
                        <p>
                          The Startup India recognized entity can avail tax exemption benefit from the funds received as angel investment. The exemption is only granted if the total amount of paid-up share capital and share premium does not exceed Rs. 25 Crore after the proposed issue of share. To avail this exemption the entity need to fulfil some conditions for investing in certain assets.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/3529/3529127.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Interaction platform for Startups</h4>
                        <p>
                        Networking is an essential key for any entrepreneur to
                          get recognition in the market and among other industry
                          peers. After registering under the DIPP scheme, the
                          startups get a platform to meet other startups,
                          well-known speakers, investors etc. at various fests
                          and summits organized by Government at a huge scale.
                          Startups recognised under DIPP can register on
                          Government e-Marketplace as a seller and sell their
                          products and services directly to Goverment Entities{" "}
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/5347/5347858.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Rebate in Trademark and Patent Application fees</h4>
                        <p>
                          All the startups registered under this scheme can claim a rebate on the government fees for filing a trademark applicatoin. And up to 80% rebate in Patent applications and an avail the benefits of fast track patent applicaton. Entire fees of FAcilitators for any number of Patents, Trademarks or Designs a Startup may file, shall be borne by the Government. Hence Startup will have to bear the cost of Statutory fees only.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/13163/13163215.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Self Certification</h4>
                        <p>
                          Startups can self certify the compliance under 6 Labour Laws and 3 Environmental Laws for 5 years from the date of incorporation.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/4435/4435123.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Government Tenders </h4>
                        <p>
                          Registering an entity under the DIPP recognition scheme enable Startups to apply for Government tenders. One of the requirements of having minimum prior experience/turnover for filing the government tender by the companies is not application to the companies registered under the StartUp India Scheme. Startups recognised uner DIPP have been exempted from submitting EArnest Money Deposit (){" "}
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/855/855288.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Fund of Funds for Startups  </h4>
                        <p>
                        The Startup can raise funds and capital through the
                            fund of funds initiative by Startup India. DIPP has
                            proposed to release Rs. 10,000 crore for Startups
                            through this scheme. Small Industries Development
                            Bank of India shall provide fund sto Startups
                            approved by implementing Agencies{" "}
                        </p>
                      </div>
                    </div>

                 {/*}   <div
                      class="col-sm-3 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/3205/3205470.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Faster Exit  </h4>
                        <p>
                        The Startup entities registered under Minisry of Corporate AFfairs (MCA) i.e Private Limite Comapny and an LLP will be wound up on a fast track basis. Under the insolvency and Bankrupcy Code, 2016, Startups with simple debt structure or those meeting certain income specified criteria can be wound up withing 90 days of filing an application for insolvency.{" "}
                        </p>
                      </div>
                    </div> */}
                  </div>
                  {/* BENEFITS SECTION END */}

                  <hr />
                  {/* PRICING SECTION START */}
                  <h2 id="ip-h2" style={{fontWeight:'bold', padding:"0.5rem"}}>PRICING FOR <span style={{color:"#006345"}}>STARTUP INDIA REGISTRATION</span></h2>
                  <div class="row md-12" style={{marginTop:"5rem", padding:"1rem"}}>

                  {/* PRICING CARD 1 START */}
                  <div
                      class="col-sm-12 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                    <div class="card">
                    <div style={{backgroundColor:"#207a78",color:"#ffffff",fontWeight:"bold",textAlign:"center",borderRadius:"12px",padding:"1rem"}}>
                    <h3 class="pricing-ul-text"><u>GET CUSTOMIZED QUOTATIONS FOR STARTUP INDIA REGISTRATION IN YOUR BUDGET</u></h3>
                    <div style={{textAlign:"center"}}><h2 style={{fontWeight:"bold"}}> Starting at <span style={{color:"#ffffff"}}>₹ 4999*</span> only</h2></div>
                    </div>
                    <div style={{padding:"1rem",margingTop:"1rem"}}>
                       <div class="d-flex justify-content-center">
                       <button class="btn btn-lg btn-success" style={{backgroundColor:"#207a78", fontSize:"1rem"}} onClick={()=>{
                        //Setting Values to redirect to Contact Sales Page
                        sessionStorage.setItem("Smodule00","Services");sessionStorage.setItem("Sservice00","Startup India Registration"); window.location.replace("/businesstools-contactsales");
                       }}>Contact Sales</button>
                        {/* CASHFREE CHECKOUT BUTTON START FOR PRICING CARD */}
                        <div>
                        <a style={{textDecoration:"none"}} href="https://payments.cashfree.com/forms/startupconnect-startupindiaregistration-payment" target="_parent">
                        <div class="button-container" style={button_container}>
                        <div>
                        {/*}    <img src="https://cashfree-checkoutcartimages-prod.cashfree.com/startupconnectlogo1Y6cg7dgopcs0_prod.png" alt="logo" style={logo_container} class="logo-container" />
                        */} </div>
                        <div class="text-container" style={text_container}>
                        <div style={{fontFamily: "Arial", color: "#fff", marginBottom: "5px", fontSize: "14px"}}>
                        Check Out
                        </div>
                        <div style={{fontFamily: "Arial", color: "#fff", fontSize: "10px"}}>
                        <span>Powered By Cashfree</span>
                        <img src="https://cashfreelogo.cashfree.com/cashfreepayments/logosvgs/Group_4355.svg" alt="logo" style={secondary_logo_container} class="seconday-logo-container" />
                        </div>
                        </div>
                        </div>
                        </a>
                        </div>
                       {/* CASHFREE CHECKOUT BUTTON START FOR PRICING CARD */}
                       </div>
                    </div>
                    </div>
                    </div>
                    {/* PRICING CARD 1 END */}

                  </div>
                  <hr/>
                  {/* PRICING SECTION END */}
                  {/* REQUIREMENTS SECTION START */}
                  <h2 id="ip-h2">ELIGIBILITY CRITERIA FOR STARTUP INDIA(DPIIT) REGISTRATION</h2>

                  <div class="row" style={{ padding: "2rem" }}>
                    <div class="col-sm-4 stretch-card grid-margin">
                      <img
                        loading="lazy"
                        src="https://img.freepik.com/free-vector/business-deal-concept-illustration_114360-1103.jpg"
                        style={{ width: "90%", height: "100%" }}
                      />
                    </div>

                    <div
                      class="col-sm-8 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div>
                        <h4 style={{ fontWeight: "bold" }}>
                          1. Incorporation Period:
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                          {" "}
                          Period of existence and operations should not be
                          exceeding 10 years from the date of Incorporation.
                        </p>
                        <br />
                        <h4 style={{ fontWeight: "bold" }}>
                          2. Type of Business Enitity:
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                          It should be Incorporated as a Private Limited
                          Company, a Registered Partnership Firm or a Limited
                          Liability Partnership.
                        </p>
                        <br />
                        <h4 style={{ fontWeight: "bold" }}>
                          3. Annual Turnover
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                          Shold have an annual turnover not exceeding Rs. 100
                          crore for any of the financial years since its
                          Incorporation.
                        </p>
                        <br />
                        <h4 style={{ fontWeight: "bold" }}>
                          4. Original Entity
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                          Entity should not have been formed by splitting up or
                          reconstructing an already existing business
                        </p>
                        <h4 style={{ fontWeight: "bold" }}>
                          5. Innovative & Scalable
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                          Should work towards development or improvement of a
                          product, process or service and/or have scalable
                          business model with high potential for creation of
                          wealth & employment.
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* REQUIREMENTS SECTION END */}
                  <hr />
                  <h2 id="ip-h2">STARTUP INDIA (DPIIT) REGISTRATION PROCESS</h2>
                  <h5>The startup India registration process with professional utilities is simple and hassle free. The are the following details you need to provide to get your business registered under the startup India certificate through DPIIT registration.</h5>
                
                  {/* PROCESS SECTION START */}
                  <div class="row  mt-5 d-flex justify-content-center" style={{padding:"1rem"}}>

                       <div class="col-sm-4 stretch-card grid-margin" id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/free-vector/text-files-concept-illustration_114360-4402.jpg?size=626&ext=jpg&ga=GA1.1.1328445396.1702628882&semt=ais"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold"> Details & Documentation</h4>
                          <div class="d-flex justify-content-between">
                            <p class="mb-0" style={{ fontSize: "1rem" }}>
                            Entities are required to provide all asked details to get their startup registered under the Startup India initiative on the startup portal.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-4 stretch-card grid-margin" id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/free-vector/upload-concept-illustration_114360-1205.jpg?size=626&ext=jpg&ga=GA1.1.1328445396.1702628882&semt=ais"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold"> Drafting & Uploading Documents</h4>
                          <div class="d-flex justify-content-between">
                            <p class="mb-0" style={{ fontSize: "1rem" }}>
                            Once all the documents and details are collected. The drafting process for the startup India portal registration will be initiated by our experts.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-4 stretch-card grid-margin" id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/free-vector/mobile-testing-concept-illustration_114360-1564.jpg?size=626&ext=jpg&ga=GA1.1.1328445396.1702628882&semt=sph"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold"> Application for startup India registration </h4>
                          <div class="d-flex justify-content-between">
                            <p class="mb-0" style={{ fontSize: "1rem" }}>
                            Once the drafting and creation of entity profile is completed on the startup India registration portal. The application for DPIIT recognition will be raised from the startup india portal dashboard.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-4 stretch-card grid-margin" id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/free-vector/product-quality-concept-illustration_114360-7301.jpg?w=740&t=st=1706780098~exp=1706780698~hmac=4ed66236fdef104f05dab6b8d7176409171e304e19a3758c389bc28837bbe13b"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold"> Verification by DPIIT </h4>
                          <div class="d-flex justify-content-between">
                            <p class="mb-0" style={{ fontSize: "1rem" }}>
                            The application for consideration of acknowledging the entity as a legitimate startup will be initiated by the authorities. Depending upon the criteria of startup India registration the application will be acknowledged by the DPIIT.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-4 stretch-card grid-margin" id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/free-vector/building-permit-concept-illustration_114360-7299.jpg?size=626&ext=jpg&ga=GA1.1.1328445396.1702628882&semt=sph"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold">Approval & rejection of application </h4>
                          <div class="d-flex justify-content-between">
                            <p class="mb-0" style={{ fontSize: "1rem" }}>
                            Once the application is submitted to The Department for Promotion of Industry and Internal Trade(DPIIT). It would take around 20-25 days to get startup India registration approval from the authorities. The application might also be rejected if it does not fulfill the criteria for registration set by the DPIIT.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* PROCESS SECTION END */}
                  <hr/>
                  {/* Why StartUp Connect Starts */}
                  <Whystartupconnect/>
                  {/* Why StartUp Connect Ends */}

                  <hr />

                  {/* Documents SECTION Starts */}
                  <h2 id="ip-h2">DOCUMENTS REQUIRED FOR STARTUP INDIA (DPIIT) CERTIFICATE</h2>
                  <div class="row" style={{ padding: "2rem" }}>
                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/1999/1999225.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>

                        <h4 class="mt-3">
                          Certificate of Incorporation and PAN number of entity{" "}
                        </h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/1726/1726620.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">
                          All Directors/partner PAN Card and Aadhaar Card
                        </h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/9068/9068642.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">
                          All Directors/Partner Email id and Mobile Number
                        </h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/3135/3135715.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3" style={{ fontSize: "15px" }}>
                          Passport Photograph of all Directors/Partners
                        </h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/7910/7910629.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">
                          - Website/Pitch deck (if available)
                        </h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/857/857034.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Orginisation Digital Signature </h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/1087/1087840.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">
                          Introduction of Business{" "}
                        </h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/2168/2168771.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">
                        GST/MSME/Trademark/Copyright 
{" "}
                        </h4>
                      </div>
                    </div>
                  </div>
                  {/* Documents SECTION Ends */}
                  {/* COMPLIANCES SECTION START */}
                 
                  {/* COMPLIANCES SECTION END */}
                
                  {/* COMPARISON TABLE START */}
                  

                  {/* COMPARISON TABLE END */}

                 

                  {/* FAQ SECTION END */}

                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
            {/* SIDEBAR AND MAIN PAGE END */}

            <Footer3 />
          </div>
          {/*} container-scroller */}
          {/*} plugins:js */}
          <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
          {/*} endinject */}
          {/*} Plugin js for this page */}
          <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
          <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
          <script
            src="panel/assets/js/jquery.cookie.js"
            type="text/javascript"
          ></script>
          {/*} End plugin js for this page */}
          {/*} inject:js */}
          <script src="panel/assets/js/off-canvas.js"></script>
          <script src="panel/assets/js/hoverable-collapse.js"></script>
          <script src="panel/assets/js/misc.js"></script>
          <script src="panel/assets/js/settings.js"></script>
          <script src="panel/assets/js/todolist.js"></script>
          {/*} endinject */}
          {/*} Custom js for this page */}
          <script src="panel/assets/js/dashboard.js"></script>
          {/*} End custom js for this page */}
        </body>
      </html>
    </>
  );
}

export default Startupindiaregistration1;
