import React from "react";

function Team_create_quoatation(){
  return (
    <>
    <html lang="en">
  <head>
    {/* Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Startup Connect | Services Portal</title>
    {/* plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/* endinject */}
    {/* Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/* End plugin css for this page */}
    {/* inject:css */}
    {/* endinject */}
    {/* Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/* End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
    </head>
    <body>
    {/* container-scroller */}
    <div class="container-scroller">
    
      <div className="newquotation main-panel overflow-scroll">
        <div className="content-wrapper d-flex flex-column">
          <div className="d-flex User align-center justify-content-center">
            <h1>New Quotation</h1>
          </div>
          <div className="row">
            <div className="col-12 grid-margin">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title d-flex flex-column align-items-center">
                    Quotation Details
                  </h4>
                  <form className="form-sample">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Quotation_no
                          </label>
                          <div className="col-sm-9">
                            <input type="text" className="form-control" placeholder="QNO123"/>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Date
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              placeholder="dd/mm/yyyy"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="card">
                <div className="card-body">
                  <h4 className="card-title d-flex flex-column align-items-center">
                    Client Details
                  </h4>
                  <form className="form-sample">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Client ID
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Client ID"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Client Name
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Client Name"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Contact
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="+91XXXXXXXXXX"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Email
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="xyz@gmail.com"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            City
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="City"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            State
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="State"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            GSTIN
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="GSTIN"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Address
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="address"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="card">
                <div className="card-body">
                  <h4 className="card-title d-flex flex-column align-items-center">
                    Order Items
                  </h4>
                  <form className="form-sample">
                    <div className="row">
                      <div class="col-12 grid-margin stretch-card">
                        <div class="card">
                          <div class="card-body">
                            <div class="form-group row">
                              <div class="col">
                                <label>Heading</label>
                                <div id="the-basics">
                                  <input
                                    class="typeahead"
                                    type="text"
                                    placeholder="Service 1"
                                  />
                                </div>
                              </div>
                              <div class="col">
                                <label>Quantity</label>
                                <div id="the-basics">
                                  <input
                                    class="typeahead"
                                    type="text"
                                    placeholder="1"
                                  />
                                </div>
                              </div>
                              <div class="col">
                                <label>Details</label>
                                <div id="the-basics">
                                  <input
                                    class="typeahead"
                                    type="text"
                                    placeholder="Piece"
                                  />
                                </div>
                              </div>
                              <div class="col">
                                <label>Rate</label>
                                <div id="the-basics">
                                  <input
                                    class="typeahead"
                                    type="text"
                                    placeholder="100.00"
                                  />
                                </div>
                              </div>
                              <div class="col">
                                <label>Amount</label>
                                <div id="the-basics">
                                  <input
                                    class="typeahead"
                                    type="text"
                                    placeholder="100.00"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row form-group"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="card">
                <div className="card-body">
                  <h4 className="card-title d-flex flex-column align-items-center">
                    Order Summary
                  </h4>
                  <form className="form-sample">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Sub Total
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="----,----"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Discount
                          </label>
                          <div className="col-sm-9 d-flex ">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="00.00%"
                            />
                            <input
                              type="text"
                              className="form-control"
                              placeholder="000.00"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">GST</label>
                          <div className="col-sm-9 d-flex">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="00.00%"
                            />
                            <input
                              type="text"
                              className="form-control"
                              placeholder="000.00"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Other Expenses
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="000.00"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Other Deductions
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="000.00"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Net Total(Payable)
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="000.00"
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="card">
                <div className="card-body ">
                  <form className="form-sample">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Total Amount in Words
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="label"
                              className="form-control"
                              placeholder="One Hundred twenty three only"
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="Summary d-flex align-center justify-content-center ">
            <button className="btn-lg btn-primary mx-auto">
              Calculate
          </button>
          <button className="btn-lg btn-primary mx-auto bg-warning text-black">
              + Create Quotation
          </button>
          </div>
          
        </div>
      </div>

      </div>

{/* plugins:js */}
<script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
{/* endinject */}
{/* Plugin js for this page */}
<script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
<script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
<script src="panel/assets/vendors/flot/jquery.flot.js"></script>
<script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
<script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
<script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
<script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
<script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
{/* End plugin js for this page */}
{/* inject:js */}
<script src="panel/assets/js/off-canvas.js"></script>
<script src="panel/assets/js/hoverable-collapse.js"></script>
<script src="panel/assets/js/misc.js"></script>
<script src="panel/assets/js/settings.js"></script>
<script src="panel/assets/js/todolist.js"></script>
{/* endinject */}
{/* Custom js for this page */}
<script src="panel/assets/js/dashboard.js"></script>
{/* End custom js for this page */}
</body>
</html>    
    </>
  );
};

export default Team_create_quoatation;
