import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

//import '../Businesstools/Resources/dashboard.css';

//import '../Resources/dashboard.css';

import Sidebar1 from './sidebar1';
import Navbar from './navbar';

const logname=sessionStorage.getItem("logName00");

function Mainadminredirectdashboard(){

    
    


return(
    <>

<html lang="en">
  <head>
    {/* Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Startup Connect | Login</title>
    {/* plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/* endinject */}
    {/* Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/* End plugin css for this page */}
    {/* inject:css */}
    {/* endinject */}
    {/* Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/* End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
    </head>
    <body>
    {/* container-scroller */}
    <div class="container-scroller">

    <Sidebar1 />  
   {/* partial */}
   <div class="container-fluid page-body-wrapper">
   <Navbar />


       {/*}   <Navbar/> */}

          <div class="main-panel">
      

          <div class="d-flex justify-content-center">
          {/* SUCCESS CARD START */}
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Login Successful!!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <a href="businesstools-businesses"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              {/* SUCCESS CARD END */}

              </div>
          <div class="content-wrapper pb-0">

         {/* CHARTS START */}
            <div class="row">
              <div class="col-sm-6 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      <div class="card-title"> Registrations <small class="d-block text-muted">August 01 - August 31</small>
                      </div>
                      <div class="d-flex text-muted font-20">
                        <i class="mdi mdi-printer mouse-pointer"></i>
                        <i class="mdi mdi-help-circle-outline ms-2 mouse-pointer"></i>
                      </div>
                    </div>
                    <h3 class="font-weight-bold mb-0"> 2,409 <span class="text-success h5">4,5%<i class="mdi mdi-arrow-up"></i></span>
                    </h3>
                    <span class="text-muted font-13">Avg customers/Day</span>
                    <div class="line-chart-wrapper">
                      <canvas id="linechart" height="80"></canvas>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      <div class="card-title"> Logins <small class="d-block text-muted">August 01 - August 31</small>
                      </div>
                      <div class="d-flex text-muted font-20">
                        <i class="mdi mdi-printer mouse-pointer"></i>
                        <i class="mdi mdi-help-circle-outline ms-2 mouse-pointer"></i>
                      </div>
                    </div>
                    <h3 class="font-weight-bold mb-0"> 0.40% <span class="text-success h5">0.20%<i class="mdi mdi-arrow-up"></i></span>
                    </h3>
                    <span class="text-muted font-13">Avg customers/Day</span>
                    <div class="bar-chart-wrapper">
                      <canvas id="barchart" height="80"></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
     
           {/* CHARTS END */}

          <h1>Hi <span class="text-primary">{logname}!</span> Welcome to Startup Connect's Services Tracking Platform.</h1>
             <div class="row mt-5">
              <div class="col-sm-4 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/inbox-cleanup-illustration-concept_114360-963.jpg" alt="" />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-semibold"> SERVICES PORTAL ADMIN </h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0">
                      manage and view your project quotations received from Startup Connect Associates.</p>
                      
                    </div>
                    <button class="btn btn-warning mt-3" onClick={()=>{ window.location.replace("/mainadmin-servicesportal-dashboard"); }}>Continue to Quotations Dashboard</button>
                  </div>
                </div>
              </div>
              <div class="col-sm-4 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/taking-orders-by-phone-store-contact-center-customers-support-easy-order-fast-delivery-trade-service-call-center-operator-cartoon-character_335657-2564.jpg" alt="" />
                  </div>
                  <div class="card-body px-3 text-dark">
                  
                    <h5 class="font-weight-semibold"> BUSINESS TOOLS ADMIN </h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0">
                      manage and track your orders with Startup Connect Services from order managemenet dashboard.
                      </p>
                      
                    </div>
                    <button class="btn btn-primary mt-3" onClick={()=>{ window.location.replace("/client-dashboard-orders"); }}>Continue to Orders Dashboard</button>

                  </div>
                </div>
              </div>
              <div class="col-sm-4 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/organizing-projects-concept-illustration_114360-632.jpg" alt="" />
                  </div>
                  <div class="card-body px-3 text-dark">
                
                    <h5 class="font-weight-semibold">NETWORKING ADMIN</h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0">
                       manage all your projects with Startup Connect Services from orders dashboard
                      </p> 
                      
                    </div>
                    <button class="btn btn-danger mt-3" onClick={()=>{ window.location.replace("/client-dashboard-projects"); }}>Continue to Projects Dashboard</button>
                  </div>
                </div>
              </div>
         
             </div>
             </div>
             </div>
             </div>
             </div>
           
        


    {/* plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/* endinject */}
    {/* Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/* End plugin js for this page */}
    {/* inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/* endinject */}
    {/* Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/* End custom js for this page */}
  </body>
  </html>    
    </>
);

}

export default Mainadminredirectdashboard;