import React from "react";
import { useState, useEffect, Component } from "react";
import { useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
//import axios from '../../axios.js';
import moment from "moment/moment";


import "../../Resources/homebtcss.css";
import "../../Resources/home2css.css";
import "../../Resources/serviceshomecss.css";
import "../ankitcss.css";
import '../../Resources/responsiveness.css';



function Comparisontable() {
  return (
    <>
   
                  {/* COMPARISON TABLE START */}
                  <h2 id="ip-h2" style={{ marginBottom: "40px" }}>
                    {" "}
                    COMPARISON OF VARIOUS BUSINESS ENTITIES
                  </h2>
                  <div className="container" style={{ overflow: "scroll" }}>
                    <div classame="table-responsive">
                      <div className="row">
                        <div className="col">
                          <table className="table table-striped table-bordered table-hover">
                            <thead className="thead-dark">
                              <tr>
                                <th scope="col">Features</th>
                                <th scope="col">Proprietorship</th>
                                <th scope="col">Partnership</th>
                                <th scope="col">LLP</th>
                                <th scope="col">company</th>
                                <th scope="col">One person Company</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row">Definition</th>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Unregistered type of business entity managed
                                  by one single person
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  A formal agreement between two or more parties
                                  to manage and operate a business
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  A Limited Liability Partnership is a hybrid
                                  combination having features similar to a
                                  partnership firm and liabilities similar to a
                                  company.
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Registered type of entity with limited
                                  liability to the owners and shareholders
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Combination of Sole-Proprietorship and
                                  Corporate form of business
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Applicable Law</th>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  No specified Act
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Indian Partnership Act, 1932
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Limited Liability Partnership Act, 2008
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Companies Act, 2013
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Companies Act, 2013
                                </td>
                              </tr>
                              <tr>
                                <th scope="row" style={{ whiteSpace: "wrap" }}>
                                  Ownership/No of owners
                                </th>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Sole Ownership
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Min 2 Partners Max 50 Partners
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Min 2 Partners Max Unlimited
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Min 2 Shareholders- Max 200 Shareholder
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Only 1
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Management</th>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Managed by proprietor
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Managed by Partners
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Managed by Partners
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Min 2 Directors Max 15 Directors
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  1 Director 1 Nominee Director
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Promoter Liability</th>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Unlimited Liability
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Unlimited Liability
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Limited Liability
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Limited Liability
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Limited Liability
                                </td>
                              </tr>
                              <tr>
                                <th scope="row" style={{ whiteSpace: "wrap" }}>
                                  Separate Legal Entity
                                </th>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  No
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  No
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Yes
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Yes
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Yes
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Documentation</th>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  MSME, GST Registration
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Partnership Deed
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  LLP Deed, Incorporation Certificate
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  MOA, AOA, Incorporation Certificate
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  MOA, AOA, Incorporation Certificate
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Transferability</th>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Non Transferable
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Transferable if registered under ROF
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Transferable
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Transferable
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Transferable
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Statutory Audit</th>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Not Mandatory
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Not Mandatory
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Applicable if Limit exceeds
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Mandatory
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Mandatory
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Taxation Liability</th>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Low
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  High
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  High
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Moderate
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Moderate
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Foreign Ownership</th>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Not allowed
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Allowed
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Allowed
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Allowed
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Not Allowed
                                </td>
                              </tr>
                              <tr>
                                <th scope="row" style={{ whiteSpace: "wrap" }}>
                                  Compliance Requirement
                                </th>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Low
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Low
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Moderate
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  High
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "wrap",
                                    padding: "20px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  High
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* COMPARISON TABLE END */}

                  <hr />
                 
                  {/* FAQ SECTION END */}

                  <br />
                  <br />
                  <br />
                  <br />
             
    </>
  );
}

export default Comparisontable;
