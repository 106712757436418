import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';

import '../Resources/dashboard.css';
import Navbar from '../navbar';
import Sidebar from '../Sidebar';
import Create_supplier from './create_supplier';

import {useRef as useref} from 'react';
import {useReactToPrint} from 'react-to-print';

//EXCEL DOWNLOAD IMPORT
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

function Usersuppliers(){


    //Report Print Function
    const componentRef = useref();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });



 // Function to download the data as an Excel file
 const downloadExcel = () => {
  // Convert JSON data to Excel worksheet
  const worksheet = XLSX.utils.json_to_sheet(data);
  
  // Create a new workbook and add the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  
  // Convert the workbook to a binary array
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  
  // Create a Blob from the binary data and trigger a download
  const excel_data = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(excel_data, "suppliers_data.xlsx");
};

  
 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const busyid=sessionStorage.getItem("BusyId00");
 const counterid=sessionStorage.getItem("CounterId00");


 
{/* SUPPLIERS TABLE COLUMNS MANAGEMENT VARIABLES START */}
const[cd_col1_val,setcd_col1_val] = useState(0);//column1
const[cd_col2_val,setcd_col2_val] = useState(0);//column2
const[cd_col3_val,setcd_col3_val] = useState(0);//column3
const[cd_col4_val,setcd_col4_val] = useState(0);//column4
const[cd_col5_val,setcd_col5_val] = useState(0);//column5
const[cd_col6_val,setcd_col6_val] = useState(0);//column6
const[cd_col7_val,setcd_col7_val] = useState(0);//column7
const[cd_col8_val,setcd_col8_val] = useState(0);//column8
const[cd_col9_val,setcd_col9_val] = useState(0);//column9
const[cd_col10_val,setcd_col10_val] = useState(0);//column10
const[cd_col11_val,setcd_col11_val] = useState(0);//column11
const[cd_col12_val,setcd_col12_val] = useState(0);//column12
const[cd_col13_val,setcd_col13_val] = useState(0);//column13
const[cd_col14_val,setcd_col14_val] = useState(0);//column14

{/* SUPPLIERS TABLE COLUMNS MANAGEMENT VARIABLES END */}


 //Checking if Sessions are Set

// alert(logstatus,loguser,logadminid);
 //Sessions Not Set
 {/*if(((logstatus==null)||(logstatus==""))||((logid==null)||(logid==""))||((token==null)||(token==" "))||((busyid==null)||(busyid==" "))||((counterid==null)||(counterid==" "))){
  
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");
   
 
    window.location.replace("/businesstools-login");
   }
  
   //Sessions Set
   else{
   */}

    
      
//Variables
const[banks,setbanks] = useState([]);//SetData Variable
const[data,setData] = useState([]);//SetData Variable

//Error Variable
const[errorvar,seterrorvar] = useState();



//FILTER VARIABLES
const[filter_val,setfilter_val] = useState(0);
const[filter_supplier_id,setfilter_supplier_id] = useState(); const updatefilter_supplier_id = (event) => { setfilter_supplier_id(event.target.value);}; const[filter_supplier_id_er,setfilter_supplier_id_er]=useState();
const[filter_supplier_name,setfilter_supplier_name] = useState(); const updatefilter_supplier_name = (event) => { setfilter_supplier_name(event.target.value);}; const[filter_supplier_name_er,setfilter_supplier_name_er]=useState();
const[filter_email,setfilter_email] = useState(); const updatefilter_email = (event) => { setfilter_email(event.target.value);}; const[filter_email_er,setfilter_email_er]=useState();
const[filter_contact,setfilter_contact] = useState(); const updatefilter_contact = (event) => { setfilter_contact(event.target.value);}; const[filter_contact_er,setfilter_contact_er]=useState();
const[filter_whatsapp,setfilter_whatsapp] = useState(); const updatefilter_whatsapp = (event) => { setfilter_whatsapp(event.target.value);}; const[filter_whatsapp_er,setfilter_whatsapp_er]=useState();
const[filter_address,setfilter_address] = useState(); const updatefilter_address = (event) => { setfilter_address(event.target.value);}; const[filter_address_er,setfilter_address_er]=useState();
const[filter_city,setfilter_city] = useState(); const updatefilter_city = (event) => { setfilter_city(event.target.value);}; const[filter_city_er,setfilter_city_er]=useState();
const[filter_state,setfilter_state] = useState(); const updatefilter_state = (event) => { setfilter_state(event.target.value);}; const[filter_state_er,setfilter_state_er]=useState();
const[filter_country,setfilter_country] = useState(); const updatefilter_country = (event) => { setfilter_country(event.target.value);}; const[filter_country_er,setfilter_country_er]=useState();
const[filter_type,setfilter_type] = useState(); const updatefilter_type = (event) => { setfilter_type(event.target.value);}; const[filter_type_er,setfilter_type_er]=useState();
const[filter_category,setfilter_category] = useState(); const updatefilter_category = (event) => { setfilter_category(event.target.value);}; const[filter_category_er,setfilter_category_er]=useState();


//Variables for pagination
const [currentPage, setCurrentPage] = useState(1);
const [pageNumber, setpageNumber] = useState(1);

useEffect(()=>{
   
  getData();
  
  },[])
 
    
  
//******************* GET SUPPLIERS DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))){
    

   const $filter_supplier_id = filter_supplier_id; const $filter_supplier_name = filter_supplier_name; const $filter_email = filter_email; const $filter_contact = filter_contact; const $filter_whatsapp = filter_whatsapp;
   const $filter_address = filter_address; const $filter_city = filter_city; const $filter_state = filter_state; const $filter_country = filter_country; const $filter_type = filter_type; const $filter_category = filter_category;


 
 axios.post("bt-get-suppliers", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Sn_start :0,
   Sn_end :0,
   Sn_total :0,
   Sn_limit :0,
   Filter_val : filter_val,
   Filter_supplier_id : $filter_supplier_id,
   Filter_supplier_name : $filter_supplier_name,
   Filter_email : $filter_email,
   Filter_contact : $filter_contact,
   Filter_whatsapp : $filter_whatsapp,
   Filter_address : $filter_address,
   Filter_city : $filter_city,
   Filter_state : $filter_state,
   Filter_country : $filter_country,
   Filter_type : $filter_type,
   Filter_category : $filter_category,
      
   //Code Edits for pagination
   'page' : pageNumber
 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setbanks(response.data.banks);
   setData(response.data.data.data);
  
  }
  
  //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){
    setfilter_supplier_id_er(response.data.validatorerror.Filter_supplier_id);
    setfilter_supplier_name_er(response.data.validatorerror.Filter_supplier_name);
    setfilter_email_er(response.data.validatorerror.Filter_email);
    setfilter_contact_er(response.data.validatorerror.Filter_contact);
    setfilter_whatsapp_er(response.data.validatorerror.Filter_whatsapp);
    setfilter_address_er(response.data.validatorerror.Filter_address);
    setfilter_city_er(response.data.validatorerror.Filter_city);
    setfilter_state_er(response.data.validatorerror.Filter_state);
    setfilter_country_er(response.data.validatorerror.Filter_country);
    setfilter_type_er(response.data.validatorerror.Filter_type);
    setfilter_category_er(response.data.validatorerror.Filter_category);

  }

  //Data Violation
  if(response.data.error==102){ 
  
    setfilter_supplier_id_er(response.data.filter_supplier_id_er);
    setfilter_supplier_name_er(response.data.filter_supplier_name_er);
    setfilter_email_er(response.data.filter_email_er);
    setfilter_contact_er(response.data.filter_contact_er);
    setfilter_whatsapp_er(response.data.filter_whatsapp_er);
    setfilter_address_er(response.data.filter_address_er);
    setfilter_city_er(response.data.filter_city_er);
    setfilter_state_er(response.data.filter_state_er);
    setfilter_country_er(response.data.filter_country_er);
    setfilter_type_er(response.data.filter_type_er);
    setfilter_category_er(response.data.filter_category_er);


  }

  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* GET SUPPLIERS DATA FUNCTION END  ********************//


 
 
//******************* GET SUPPLIERS DATA BY ID FUNCTION START  ********************//



//ename
const[ename,setename]=useState();const updateename = (event) => { setename(event.target.value);}; const[ename_er,setename_er]=useState();
//eemail
const[eemail,seteemail]=useState();const updateeemail = (event) => { seteemail(event.target.value);}; const[eemail_er,seteemail_er]=useState();
//econtact
const[econtact,setecontact]=useState();const updateecontact = (event) => { setecontact(event.target.value);}; const[econtact_er,setecontact_er]=useState();
//ewhatsapp
const[ewhatsapp,setewhatsapp]=useState();const updateewhatsapp = (event) => { setewhatsapp(event.target.value);}; const[ewhatsapp_er,setewhatsapp_er]=useState();
//eaddress
const[eaddress,seteaddress]=useState();const updateeaddress = (event) => { seteaddress(event.target.value);}; const[eaddress_er,seteaddress_er]=useState();
//ecity
const[ecity,setecity]=useState();const updateecity = (event) => { setecity(event.target.value);}; const[ecity_er,setecity_er]=useState();
//ecstate
const[ecstate,setecstate]=useState();const updateecstate = (event) => { setecstate(event.target.value);}; const[ecstate_er,setecstate_er]=useState();
//ecountry
const[ecountry,setecountry]=useState();const updateecountry = (event) => { setecountry(event.target.value);}; const[ecountry_er,setecountry_er]=useState();
//epincode
const[epincode,setepincode]=useState();const updateepincode = (event) => { setepincode(event.target.value);}; const[epincode_er,setepincode_er]=useState();
//etype
const[etype,setetype]=useState();const updateetype = (event) => { setetype(event.target.value);}; const[etype_er,setetype_er]=useState();
//ecategory
const[ecategory,setecategory]=useState();const updateecategory = (event) => { setecategory(event.target.value);}; const[ecategory_er,setecategory_er]=useState();
//egstin
const[egstin,setegstin]=useState();const updateegstin = (event) => { setegstin(event.target.value);}; const[egstin_er,setegstin_er]=useState();
//elicense_no
const[elicense_no,setelicense_no]=useState();const updateelicense_no = (event) => { setelicense_no(event.target.value);}; const[elicense_no_er,setelicense_no_er]=useState();



//ERROR VARIABLES 
const[eerror_msg,seteerror_msg]=useState();




//Edit Function
function getsupplierdatabyId(){

  const supplierId=sessionStorage.getItem("supplierId00");

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&(busyid>0)&&(counterid>0)&&(supplierId>0)){
 
 
 axios.post("bt-supplier-details-byid", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Supplier_id:supplierId
 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");
     sessionStorage.removeItem("supplierId00");


     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){

   //Setting Data
   setename(response.data.name);
   seteemail(response.data.email);
   setecontact(response.data.contact);
   setewhatsapp(response.data.whatsapp);
   seteaddress(response.data.address);
   setecity(response.data.city);
   setecstate(response.data.state);
   setecountry(response.data.country);
   setepincode(response.data.pincode);
   setetype(response.data.type);
   setecategory(response.data.category);
   setegstin(response.data.gstin);
   setelicense_no(response.data.license_no);

  
  }
  //Cannot Fetch Data
  else{


  document.getElementById("edit-form").style.display="none";

  //Setting Variables to empty 
   
  //Setting Error Variables
  setename_er(" ");seteemail_er(" ");setecontact_er(" ");setewhatsapp_er(" ");seteaddress_er();setecity_er();setecstate_er();setecountry_er();setepincode_er();setetype_er();setecategory_er();setegstin_er();setelicense_no_er();seteerror_msg(" ");
  setename(" ");seteemail(" ");setecontact(" ");setewhatsapp(" ");seteaddress();setecity();setecstate();setecountry();setepincode();setetype();setecategory();setegstin();setelicense_no();

  document.getElementById("edit-error-card").style.display="block"; 
  sessionStorage.removeItem("supplierId00");


  }
 
 
 });
 
 }
 //User not Logedin
 else{

     //Checking if user loggedin
     if(((logstatus==null)||(logstatus==""))||((logid==null)||(logid==""))||((token==null)||(token==" "))){
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
     }
 
  }
 
 }
  
 //******************* GET SUPPLIER DATA BY ID FUNCTION END  ********************//

 
//******************* EDIT SUPPLIER FUNCTION START ********************//
function editsupplier(){

  const supplierId=sessionStorage.getItem("supplierId00");
  //Setting Error Variables
  setename_er(" ");seteemail_er(" ");setecontact_er(" ");setewhatsapp_er(" ");seteaddress_er();setecity_er();setecstate_er();setecountry_er();setepincode_er();setetype_er();setecategory_er();setegstin_er();setelicense_no_er();seteerror_msg(" ");



  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))&&((supplierId!=null)||(supplierId!=" "))){
 
 
  axios.post("bt-supplier-edit", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Name : ename,
   Email:eemail,
   Contact : econtact,
   Whatsapp:ewhatsapp,
   Address:eaddress,
   City:ecity,
   State:ecstate,
   Country:ecountry,
   Pincode:epincode,
   Type:etype,
   Category:ecategory,
   Gstin:egstin,
   License_no:elicense_no,
   Supplier_id:supplierId


  })
  .then((response) => {

    //console.log(response);

  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");
     sessionStorage.removeItem("supplierId00");

     window.location.replace("businesstools-login");
  }

   //Validation Errors
 else if(response.data.validatorerror){
  setename_er(response.data.validatorerror.Name);
  seteemail_er(response.data.validatorerror.Email);
  setecontact_er(response.data.validatorerror.Contact);
  setewhatsapp_er(response.data.validatorerror.Whatsapp);
  seteaddress_er(response.data.validatorerror.Address);
  setecity_er(response.data.validatorerror.City);
  setecstate_er(response.data.validatorerror.State);  
  setecountry_er(response.data.validatorerror.Country);
  setepincode_er(response.data.validatorerror.Pincode);  
  setetype_er(response.data.validatorerror.Type);
  setecategory_er(response.data.validatorerror.Category);
  setegstin_er(response.data.validatorerror.Gstin);
  setelicense_no_er(response.data.validatorerror.License_no);

}
//Invalid Entries
else if(response.data.error==102){ 
  
  setename_er(response.data.name_er);
  seteemail_er(response.data.email_er);
  setecontact_er(response.data.contact_er);
  setewhatsapp_er(response.data.whatsapp_er);
  seteaddress_er(response.data.address_er);
  setecity_er(response.data.city_er);
  setecstate_er(response.data.state_er);
  setecountry_er(response.data.country_er);
  setepincode_er(response.data.pincode_er);
  setetype_er(response.data.type_er);
  setecategory_er(response.data.category_er);
  setegstin_er(response.data.gstin_er);
  setelicense_no_er(response.data.license_no_er);
 
  seteerror_msg("Cannot Edit Supplier!");  }


  //Invalid Entries
  else if(response.data.error==103){     seteerror_msg(response.data.error_msg);  }

   //Bank Updated Successfully
   else if(response.data.successvar==1){        

    document.getElementById("edit-form").style.display="none";  
    setename_er(" ");seteemail_er(" ");setecontact_er(" ");setewhatsapp_er(" ");seteaddress_er();setecity_er();setecstate_er();setecountry_er();setepincode_er();setetype_er();setecategory_er();setegstin_er();setelicense_no_er();seteerror_msg(" ");
    setename(" ");seteemail(" ");setecontact(" ");setewhatsapp(" ");seteaddress();setecity();setecstate();setecountry();setepincode();setetype();setecategory();setegstin();setelicense_no();

    
    document.getElementById("edit-success-card").style.display="block";  }
   //Cannot AUpdate Bank
   else{  seteerror_msg("Cannot Edit Supplier !"); }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* EDIT SUPPLIER FUNCTION END ********************//


 
//******************* DELETE SUPPLIER FUNCTION START ********************//
function deletesupplier(){

  const supId=sessionStorage.getItem("DsupplierId00");

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))&&((supId!=null)||(supId!=" "))){
 
 axios.post("bt-supplier-delete", {
   Busy_id:busyid,
   Counter_id:counterid,
   Log_id:logid,
   Token:token,
   Supplier_id:supId,
 })
 .then((response) => {

  //console.log(response);

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");
     sessionStorage.removeItem("DsupplierId00");

     window.location.replace("businesstools-login");
  }

  //Counter Deleted Successfully
   if(response.data.successvar==1){        

      document.getElementById("delete-card").style.display="none";
      document.getElementById("delete-success-card").style.display="block";  
      sessionStorage.removeItem("DsupplierId");

  }
  
      //Cannot Delete Counter
    else{  
    document.getElementById("delete-card").style.display="none";
    document.getElementById("delete-error-card").style.display="block";   }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");
   sessionStorage.removeItem("DsupplierId00");


   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* DELETE SUPPLIER FUNCTION END ********************//




return(
    <>
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Business Tools|BookKeeping Solution</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>


    <div class="container-scroller">

      <Sidebar />
    <div class="container-fluid page-body-wrapper">




       <Navbar />
        {/*} partial */}
        <div class="main-panel">

          {/* ADD supplier SUCCESS CARD START */}
          <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">New Supplier Added Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <a href="businesstools-user-suppliers"><button class="mt-3 btn btn-outline-danger">Close</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* ADD supplier SUCCESS CARD END */}

                  {/* ADD supplier EDIT SUCCESS CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="edit-success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Supplier Details Updated Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("edit-success-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* ADD supplier EDIT SUCCESS CARD END */}
               {/* ADD supplier EDIT ERROR CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="edit-error-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-danger">Cannot Fetch Data !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("edit-error-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* ADD supplier EDIT ERROR CARD END */}

                  {/* DELETE CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-danger">Are you sure you want to delete this transaction?</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> This action cannot be reversed. You will lose all your data related to this transaction if you wish to continue this action.</p>
                      <button class="mt-3 btn btn-outline-success" onClick={deletesupplier}>Yes, I Know! Delete Transaction.</button>
                      <button class="mt-3 btn btn-outline-danger" style={{marginLeft:"1rem"}} onClick={()=>{  
                      //Cancel Delete and remove supplier_id
                      sessionStorage.removeItem("DsupplierId00");    document.getElementById('delete-card').style.display = 'none'; }}>No, Don't Delete Transaction.</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* DELETE CARD END */}
              {/* DELETE SUCCESS CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Supplier Deleted Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("delete-success-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* DELETE SUCCESS CARD END */}
               {/*  DELETE ERROR CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-error-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-danger">Cannot Delete Supplier !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("delete-error-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* DELETE ERROR CARD END */}


          <div class="content-wrapper pb-0">

         {/* CREATE supplier FORM START */}
          <div class="row">
              <div class="col-12 grid-margin create-form" id="create-form">
              <Create_supplier /> 
              </div>
          </div>
          {/* CREATE supplier FORM END */}
          
            <div class="page-header flex-wrap">
            <div class="header-left"></div>
              <div class="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div class="d-flex align-items-center">
                  <a href="#">
                    <p class="m-0 pe-3">Suppliers</p>
                  </a>
                  <a class="ps-3 me-4" href="#">
                   {/*} <p class="m-0">ADE-00234</p> */}
                  </a>
                </div>
                <button type="button" class="btn btn-primary mt-2 mt-sm-0 btn-icon-text" onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
                  <i class="mdi mdi-plus-circle"></i> Add New Supplier </button>
              </div>
            </div>

            {/*} chart row starts here */}
          {/*}  <div class="row">
              <div class="col-sm-6 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      <div class="card-title">
                      </div>
                      <div class="d-flex text-muted font-20">
                        <i class="mdi mdi-printer mouse-pointer"></i>
                        <i class="mdi mdi-help-circle-outline ms-2 mouse-pointer"></i>
                      </div>
                    </div>
                    <h3 class="font-weight-bold mb-0"> 2,409 <span class="text-success h5">4,5%<i class="mdi mdi-arrow-up"></i></span>
                    </h3>
                    <span class="text-muted font-13">Avg Suppliers/Day</span>
                    <div class="line-chart-wrapper">
                      <canvas id="linechart" height="80"></canvas>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      <div class="card-title"> Conversions
                      </div>
                      <div class="d-flex text-muted font-20">
                        <i class="mdi mdi-printer mouse-pointer"></i>
                        <i class="mdi mdi-help-circle-outline ms-2 mouse-pointer"></i>
                      </div>
                    </div>
                    <h3 class="font-weight-bold mb-0"> 0.40% <span class="text-success h5">0.20%<i class="mdi mdi-arrow-up"></i></span>
                    </h3>
                    <span class="text-muted font-13">Avg Suppliers/Day</span>
                    <div class="bar-chart-wrapper">
                      <canvas id="barchart" height="80"></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

             {/* EDIT supplier FORM START */}
          <div class="row">
              <div class="col-12 grid-margin create-form" id="edit-form" style={{display:"none"}}>
              <div class="card" style={{textAlign:"left"}}>
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ 
                        //Removing Sessions and Closing Add Bank Edit
                        setename_er(" ");seteemail_er(" ");setecontact_er(" ");setewhatsapp_er(" ");seteaddress_er();setecity_er();setecstate_er();setecountry_er();setepincode_er();setetype_er();setecategory_er();setegstin_er();setelicense_no_er();seteerror_msg(" ");
                        setename(" ");seteemail(" ");setecontact(" ");setewhatsapp(" ");seteaddress();setecity();setecstate();setecountry();setepincode();setetype();setecategory();setegstin();setelicense_no();
                        document.getElementById("edit-form").style.display="none";
                        sessionStorage.removeItem("supplierId00");
                        
                         }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                  <div class="card-body">
                    <h4 class="card-title">Edit Supplier</h4>
                    <div class="form-sample">
                      <p class="card-description">edit new Supplier</p>
                      <code>{eerror_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Supplier Name<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Suman Kumar" id="name" value={ename} onChange={updateename} />
                              <code>{ename_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Email</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="sumankumar123@gmail.com" id="eemail" value={eemail} onChange={updateeemail} />
                              <code>{eemail_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Contact</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="91 84754 00000" id="contact" value={econtact} onChange={updateecontact} />
                              <code>{econtact_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">WhatsApp</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="91 84754 00000" id="ewhatsapp" value={ewhatsapp} onChange={updateewhatsapp} />
                              <code>{ewhatsapp_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="exampleTextarea1">Address</label>
                        <textarea class="form-control" rows="2"  id="eaddress" value={eaddress} onChange={updateeaddress} ></textarea>
                        <code>{eaddress_er}</code>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">City</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Lucknow" id="ecity" value={ecity} onChange={updateecity} />
                              <code>{ecity_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">State</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Uttar Pradesh" id="ecstate" value={ecstate} onChange={updateecstate} />
                              <code>{ecstate_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Country</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="India"  id="ecountry" value={ecountry} onChange={updateecountry}/>
                              <code>{ecountry_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Pincode</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" pincode="420110" id="epincode" value={epincode} onChange={updateepincode} />
                              <code>{epincode_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Regular" id="etype" value={etype} onChange={updateetype} />
                              <code>{etype_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Category</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Offline" id="ecategory" value={ecategory} onChange={updateecategory} />
                              <code>{ecategory_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">GSTIN</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="HUGVSTS55AAA" id="egstin" value={egstin} onChange={updateegstin} />
                              <code>{egstin_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">License No.</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="MGFS87666" id="elicense_no" value={elicense_no} onChange={updateelicense_no} />
                              <code>{elicense_no_er}</code>
                            </div>
                          </div>
                        </div>
                       
                      </div>
  
                        <button class="btn btn-primary" style={{fontWeight:"bold"}} onClick={editsupplier}>Edit Supplier</button>
                      
                   
                    </div>
                  </div>
                </div>
              </div>
          </div>
          {/* EDIT supplier FORM END */}

            {/* supplier DETAILS TABLE START */}
                  
             <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Suppliers</h4>
                    <p class="card-description">Supplier details
                    </p>

                            {/* FILTER SECTION START */}
                            <hr/>
                            <div id="filter-top-card" class="row col-12" style={{textAlign:"left",display:"none"}}>
                            <div class="d-flex justify-content-between">
                            <div>
                            <h4 style={{padding:'1rem'}}>Filters</h4>
                           </div>
                           <div>
                    
                           <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("filter-top-card").style.display="none"; }}>
                           <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                           </button>
                           </div>
                           </div>
        
                        <div class="row">
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Supplier Id</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="121" id="filter_supplier_id" value={filter_supplier_id} onChange={updatefilter_supplier_id}  />
                              <code>{filter_supplier_id_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Supplier Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Rakesh Sharma" value={filter_supplier_name} onChange={updatefilter_supplier_name}  />
                              <code>{filter_supplier_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Email</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="rakeshsharma123@gmail.com"  value={filter_email} onChange={updatefilter_email}  />
                              <code>{filter_email_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Contact</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="9414444410"value={filter_contact} onChange={updatefilter_contact}  />
                              <code>{filter_contact_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">WhatsApp</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="9414444100"  value={filter_whatsapp} onChange={updatefilter_whatsapp}  />
                              <code>{filter_whatsapp_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Address</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="24 A North Road" value={filter_address} onChange={updatefilter_address}  />
                              <code>{filter_address_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">City</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Ahmedabad" value={filter_city} onChange={updatefilter_city}  />
                              <code>{filter_city_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">State</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Gujarat"  value={filter_state} onChange={updatefilter_state}  />
                              <code>{filter_state_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Country</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="India" value={filter_country} onChange={updatefilter_country}  />
                              <code>{filter_country_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Regular" value={filter_type} onChange={updatefilter_type}  />
                              <code>{filter_type_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Category</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Offline"  value={filter_category} onChange={updatefilter_category}  />
                              <code>{filter_category_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                         <button class="btn btn-primary" onClick={()=>{ setfilter_val(1); getData(); }}>Apply Filters</button>
                         <button class="btn btn-danger" style={{marginLeft:"1rem"}} onClick={()=>{ setfilter_val(0); getData(); }}>Remove Filters</button>
                        </div>
                      </div>
                            <hr/>                      
                            </div>
                            
                            {/* FILTER SECTION END */}
                    
                  <div class="col-12" style={{marginBottom:"2rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                        {/* COLUMNS MANAGEMENT DROPDOWN START */}
                          <div class="dropdown">
                          <button class="btn btn-warning" onClick={()=>{ document.getElementById("filter-top-card").style.display="block"; }}><i class="mdi mdi-filter-outline"></i></button>

                            <button class="btn btn-primary dropdown-toggle" style={{marginLeft:"1rem"}} type="button" id="dropdownMenuIconButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="mdi mdi-table-large"></i>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuIconButton1">
                              <h6 class="dropdown-header">Columns</h6>
                              <hr/>
                              
                              {/* Column 1 */}
                              <li><div class="d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Sn</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c1_yes" onClick={()=>{setcd_col1_val(1); document.getElementById("cd_c1_yes").style.display="none"; document.getElementById("cd_c1_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c1_no" style={{ display:"none"}} onClick={()=>{ setcd_col1_val(0); document.getElementById("cd_c1_yes").style.display="block"; document.getElementById("cd_c1_no").style.display="none"; }}></i>
                              </div></div></li> 
                               {/* Column 2 */}
                               <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Supplier Id</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c2_yes"  onClick={()=>{setcd_col2_val(1); document.getElementById("cd_c2_yes").style.display="none"; document.getElementById("cd_c2_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c2_no" style={{ display:"none"}} onClick={()=>{ setcd_col2_val(0); document.getElementById("cd_c2_yes").style.display="block"; document.getElementById("cd_c2_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 3 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Name</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c3_yes"  onClick={()=>{setcd_col3_val(1); document.getElementById("cd_c3_yes").style.display="none"; document.getElementById("cd_c3_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c3_no" style={{ display:"none"}} onClick={()=>{ setcd_col3_val(0); document.getElementById("cd_c3_yes").style.display="block"; document.getElementById("cd_c3_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 4 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Email</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c4_yes"  onClick={()=>{setcd_col4_val(1); document.getElementById("cd_c4_yes").style.display="none"; document.getElementById("cd_c4_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c4_no" style={{ display:"none"}} onClick={()=>{ setcd_col4_val(0); document.getElementById("cd_c4_yes").style.display="block"; document.getElementById("cd_c4_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 5 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Contact</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c5_yes"  onClick={()=>{setcd_col5_val(1); document.getElementById("cd_c5_yes").style.display="none"; document.getElementById("cd_c5_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c5_no" style={{ display:"none"}} onClick={()=>{ setcd_col5_val(0); document.getElementById("cd_c5_yes").style.display="block"; document.getElementById("cd_c5_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 6 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">WhatsApp</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c6_yes"  onClick={()=>{setcd_col6_val(1); document.getElementById("cd_c6_yes").style.display="none"; document.getElementById("cd_c6_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c6_no" style={{ display:"none"}} onClick={()=>{ setcd_col6_val(0); document.getElementById("cd_c6_yes").style.display="block"; document.getElementById("cd_c6_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 7 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Address</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c7_yes" onClick={()=>{setcd_col7_val(1); document.getElementById("cd_c7_yes").style.display="none"; document.getElementById("cd_c7_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c7_no" style={{ display:"none"}} onClick={()=>{ setcd_col7_val(0); document.getElementById("cd_c7_yes").style.display="block"; document.getElementById("cd_c7_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 8 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">City</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c8_yes" onClick={()=>{setcd_col8_val(1); document.getElementById("cd_c8_yes").style.display="none"; document.getElementById("cd_c8_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c8_no" style={{ display:"none"}} onClick={()=>{ setcd_col8_val(0); document.getElementById("cd_c8_yes").style.display="block"; document.getElementById("cd_c8_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 9 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">State</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c9_yes" onClick={()=>{setcd_col9_val(1); document.getElementById("cd_c9_yes").style.display="none"; document.getElementById("cd_c9_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c9_no" style={{ display:"none"}} onClick={()=>{ setcd_col9_val(0); document.getElementById("cd_c9_yes").style.display="block"; document.getElementById("cd_c9_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 10 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Country</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c10_yes" onClick={()=>{setcd_col10_val(1); document.getElementById("cd_c10_yes").style.display="none"; document.getElementById("cd_c10_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c10_no" style={{ display:"none"}} onClick={()=>{ setcd_col10_val(0); document.getElementById("cd_c10_yes").style.display="block"; document.getElementById("cd_c10_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 11 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Type</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c11_yes" onClick={()=>{setcd_col11_val(1); document.getElementById("cd_c11_yes").style.display="none"; document.getElementById("cd_c11_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c11_no" style={{ display:"none"}} onClick={()=>{ setcd_col11_val(0); document.getElementById("cd_c11_yes").style.display="block"; document.getElementById("cd_c11_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 12 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Category</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c12_yes" onClick={()=>{setcd_col12_val(1); document.getElementById("cd_c12_yes").style.display="none"; document.getElementById("cd_c12_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c12_no" style={{ display:"none"}} onClick={()=>{ setcd_col12_val(0); document.getElementById("cd_c12_yes").style.display="block"; document.getElementById("cd_c12_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 13 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10 text-warning">Edit</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c13_yes" onClick={()=>{setcd_col13_val(1); document.getElementById("cd_c13_yes").style.display="none"; document.getElementById("cd_c13_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c13_no" style={{ display:"none"}} onClick={()=>{ setcd_col13_val(0); document.getElementById("cd_c13_yes").style.display="block"; document.getElementById("cd_c13_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 14 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10 text-danger">Delete
                              </div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c14_yes" onClick={()=>{setcd_col14_val(1); document.getElementById("cd_c14_yes").style.display="none"; document.getElementById("cd_c14_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c14_no" style={{ display:"none"}} onClick={()=>{ setcd_col14_val(0); document.getElementById("cd_c14_yes").style.display="block"; document.getElementById("cd_c14_no").style.display="none"; }}></i>
                              </div></div></li> 
                            {/*}  <div class="dropdown-divider"></div>
                              <button class="btn btn-danger">Close</button>*/}
                            </div> 
                          </div>
                          {/* COLUMNS MANAGEMENT DROPDOWN END */}

                          </div>
                          </div>

                    <div class="table-responsive" id="reports" ref={componentRef}>
                      <table class="table table-hover">
                        <thead>
                          <tr >
                            <th  style={{fontWeight:"bold"}} hidden={cd_col1_val==1}>Sn</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col2_val==1}>Supplier Id</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col3_val==1}>Name</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col4_val==1}>Email</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col5_val==1}>Contact</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col6_val==1}>WhatsApp</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col7_val==1}>Address</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col8_val==1}>City</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col9_val==1}>State</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col10_val==1}>Country</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col11_val==1}>Type</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col12_val==1}>Category</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col13_val==1}></th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col14_val==1}></th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                       data.map((curElm,ind)=>{

                         return(
                         <>
                       
                        <tr key={curElm.Supplier_id}>
                            <td  hidden={cd_col1_val==1} >{curElm.Sn}</td>
                            <td  hidden={cd_col2_val==1} >{curElm.Supplier_id}</td>
                            <td  hidden={cd_col3_val==1} >{curElm.Supplier_name}</td>
                            <td  hidden={cd_col4_val==1} >{curElm.Email}</td>
                            <td  hidden={cd_col5_val==1} >{curElm.Contact}</td>
                            <td  hidden={cd_col6_val==1} >{curElm.Whatsapp}</td>
                            <td  hidden={cd_col7_val==1} >{curElm.Address}</td>
                            <td  hidden={cd_col8_val==1} >{curElm.City}</td>
                            <td  hidden={cd_col9_val==1} >{curElm.State}</td>
                            <td  hidden={cd_col10_val==1} >{curElm.Country}</td>
                            <td  hidden={cd_col11_val==1} >{curElm.Type}</td>
                            <td  hidden={cd_col12_val==1} >{curElm.Category}</td>
                            <td  hidden={cd_col13_val==1} ><i class="mdi mdi-pencil-box text-warning" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                            //Setting supplier Id and Redirect to Edit
                            sessionStorage.setItem("supplierId00",curElm.Supplier_id);getsupplierdatabyId();document.getElementById('edit-form').style.display = 'block'; }}></i></td>
                            <td  hidden={cd_col14_val==1} ><i class="mdi mdi-delete-forever text-danger" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                            //Setting supplier Id for Delete
                            sessionStorage.setItem("DsupplierId00",curElm.Supplier_id);  document.getElementById('delete-card').style.display = 'block'; }}></i></td>
                          </tr>
                          </>
                         )
                         }
					   
	                  		 )
                         }
                       
                         
                        </tbody>
                      </table>
                    </div>
                         {/* PAGINATION SECTION START */}
                         <div>
                       <div class="col-12" style={{marginBottom:"2rem",marginTop:"1rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                       
                          <div class="dropdown">
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber-1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-left-drop-circle text-success"></i></button>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber+1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-right-drop-circle text-success"></i></button>
                          </div>
                          </div>
                          </div>
                    </div>
                    {/* PAGINATION SECTION END */}

                    <button class="btn btn-light" onClick={handlePrint}><i class="mdi mdi-cloud-print text-dark"></i></button>
                    <button class="btn btn-light" onClick={downloadExcel}><i class="mdi mdi-file-excel text-dark"></i></button>

                  </div>
                </div>
              </div>
              {/* supplier DETAILS TABLE END */}

          {/*}    <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">supplier Summary</h4>
                    <p class="card-description">supplier summary
                    </p>
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr >
                            <th  style={{fontWeight:"bold"}}>Sn</th>
                            <th  style={{fontWeight:"bold"}}>supplier Id</th>
                            <th  style={{fontWeight:"bold"}}>supplier Name</th>
                            <th  style={{fontWeight:"bold"}}>Sales</th>
                            <th  style={{fontWeight:"bold"}}>This month</th>
                            <th  style={{fontWeight:"bold"}}>Last Transaction</th>
                          </tr>
                        </thead>
                        <tbody>
                       
                        <tr>
                            <td>24</td>
                            <td>CA98777</td>
                            <td>Sumit Mukherjee</td>
                            <td>330000.00</td>
                            <td class="text-success">21.00 % <i class="mdi mdi-arrow-up"></i></td>
                            <td>21-08-2023</td>
                          </tr>
                          <tr>
                            <td>24</td>
                            <td>CA98777</td>
                            <td>Sumit Mukherjee</td>
                            <td>330000.00</td>
                            <td class="text-success">21.00 % <i class="mdi mdi-arrow-up"></i></td>
                            <td>21-08-2023</td>
                          </tr>
                          <tr>
                            <td>24</td>
                            <td>CA98777</td>
                            <td>Sumit Mukherjee</td>
                            <td>330000.00</td>
                            <td class="text-success">21.00 % <i class="mdi mdi-arrow-up"></i></td>
                            <td>21-08-2023</td>
                          </tr>
                           <tr>
                            <td>24</td>
                            <td>CA98777</td>
                            <td>Sumit Mukherjee</td>
                            <td>330000.00</td>
                            <td class="text-success">21.00 % <i class="mdi mdi-arrow-up"></i></td>
                            <td>21-08-2023</td>
                          </tr>
                           <tr>
                            <td>24</td>
                            <td>CA98777</td>
                            <td>Sumit Mukherjee</td>
                            <td>330000.00</td>
                            <td class="text-success">21.00 % <i class="mdi mdi-arrow-up"></i></td>
                            <td>21-08-2023</td>
                          </tr>
                           <tr>
                            <td>24</td>
                            <td>CA98777</td>
                            <td>Sumit Mukherjee</td>
                            <td>330000.00</td>
                            <td class="text-success">21.00 % <i class="mdi mdi-arrow-up"></i></td>
                            <td>21-08-2023</td>
                          </tr>
                           <tr>
                            <td>24</td>
                            <td>CA98777</td>
                            <td>Sumit Mukherjee</td>
                            <td>330000.00</td>
                            <td class="text-success">21.00 % <i class="mdi mdi-arrow-up"></i></td>
                            <td>21-08-2023</td>
                          </tr>
                           <tr>
                            <td>24</td>
                            <td>CA98777</td>
                            <td>Sumit Mukherjee</td>
                            <td>330000.00</td>
                            <td class="text-success">21.00 % <i class="mdi mdi-arrow-up"></i></td>
                            <td>21-08-2023</td>
                          </tr>
                          
                         
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>*/}

            
          </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
        {/*}  <footer class="footer">
            <div class="d-sm-flex justify-content-center justify-content-sm-between">
              <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
</>
);


//}
}
export default Usersuppliers;