import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';
import moment from 'moment/moment';

import '../Resources/dashboard.css';
import Navbar from '../navbar';
import Sidebar from '../Sidebar';
import Create_counter from './create_counter.js';
import Edit_counter from './edit_counter.js';

import Chart from "chart.js/auto";
import { Line, Bar } from "react-chartjs-2";


function Poshome(){

  
 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const busyid=sessionStorage.getItem("BusyId00");

 //Checking if Sessions are Set

// alert(logstatus,loguser,logadminid);
 //Sessions Not Set
{/*} if(((logstatus==null)||(logstatus==""))||((logid==null)||(logid==""))||((token==null)||(token==" "))||((busyid==null)||(busyid==" "))){
  
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   
 
    window.location.replace("/businesstools-login");
   }
  
   //Sessions Set
   else{
   */}
//Variables
const[data,setData] = useState([]);//SetData Variable
const[transaction_history,settransaction_history] = useState([]);//Settransaction_history Variable

//Error Variable
const[errorvar,seterrorvar] = useState();


 //SETTING DEFAULT STATE VARIABLES FOR CHART */
 
 //LINECHART VARIABLES
 const[lc_summary,setlc_summary] = useState([],);
 const[lc_data,setlc_data] = useState([],);

 //Line Chart
 const[lc_date,setlc_date] = useState();
 const[lc_default,setlc_default] = useState(0);
 const[lc_start_date,setlc_start_date] = useState();const updatelc_start_date = (event) => { setlc_start_date(event.target.value); }; 
 const[lc_end_date,setlc_end_date] = useState();const updatelc_end_date = (event) => { setlc_end_date(event.target.value); }; 
 const[lc_date_error,setlc_date_error] = useState();
    

 //Bar Chart
 const[bc_default,setbc_default] = useState(0);
 const[bc_start_date,setbc_start_date] = useState();const updatebc_start_date = (event) => { setbc_start_date(event.target.value); }; 
 const[bc_end_date,setbc_end_date] = useState();const updatebc_end_date = (event) => { setbc_end_date(event.target.value); }; 
 const[bc_counter,setbc_counter] = useState();
 const[bc_data,setbc_data] = useState();

 
//Variables for pagination
const [currentPage, setCurrentPage] = useState(1);
const [pageNumber, setpageNumber] = useState(1);


//FILTER VARIABLES
const[filter_val,setfilter_val] = useState(0);
const[filter_start_date,setfilter_start_date] = useState('1970-01-01'); const updatefilter_start_date = (event) => { setfilter_start_date(event.target.value);}; const[filter_start_date_er,setfilter_start_date_er]=useState();
const[filter_end_date,setfilter_end_date] = useState('2100-12-12'); const updatefilter_end_date = (event) => { setfilter_end_date(event.target.value);}; const[filter_end_date_er,setfilter_end_date_er]=useState();
const[filter_trno,setfilter_trno] = useState(); const updatefilter_trno = (event) => { setfilter_trno(event.target.value);}; const[filter_trno_er,setfilter_trno_er]=useState();
const[filter_busy_id,setfilter_busy_id] = useState(); const updatefilter_busy_id = (event) => { setfilter_busy_id(event.target.value);}; const[filter_busy_id_er,setfilter_busy_id_er]=useState();
const[filter_amount,setfilter_amount] = useState(); const updatefilter_amount = (event) => { setfilter_amount(event.target.value);}; const[filter_amount_er,setfilter_amount_er]=useState();
const[filter_updated_by,setfilter_updated_by] = useState(); const updatefilter_updated_by = (event) => { setfilter_updated_by(event.target.value);}; const[filter_updated_by_er,setfilter_updated_by_er]=useState();



   useEffect(()=>{
   
    getData();
    
    },[])
   

  
//******************* GET COUNTER DATA FUNCTION START  ********************//
function getData(){
 

    //Checking if user loggedin
    if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))){
   
    //Fetching Filter Variables
    const $filter_start_date = filter_start_date;const $filter_end_date = filter_end_date;const $filter_trno = filter_trno;const $filter_busy_id = filter_busy_id;const $filter_amount = filter_amount;const $filter_updated_by = filter_updated_by;


   
   axios.post("bt-get-counters-default", {
     Log_id:logid,
     Token:token,
     Busy_id:busyid,
     Sn_start :0,
     Sn_end :0,
     Sn_total :0,
     Sn_limit :0,
    //Line Chart
    lc_default:lc_default,
    lc_start_date:moment(lc_start_date).format('YYYY-MM-DD'),
    lc_end_date:moment(lc_end_date).format('YYYY-MM-DD'),
    //Bar Chart
    bc_default:bc_default,
    bc_start_date:moment(bc_start_date).format('YYYY-MM-DD'),
    bc_end_date:moment(bc_end_date).format('YYYY-MM-DD'),
     
    //Code Edits for pagination
    'page' : pageNumber,
     //Filters
     Filter_val : filter_val,
     Filter_start_date : moment($filter_start_date).format('YYYY-MM-DD'),
     Filter_end_date : moment($filter_end_date).format('YYYY-MM-DD'),
     Filter_trno : $filter_trno,
     Filter_busy_id : $filter_busy_id,
     Filter_amount : $filter_amount,
     Filter_updated_by : $filter_updated_by,

   })
   .then((response) => {


    //Invalid Session
    if((response.data.error)==100){ 
        //Remove Session Variables and redirect to login
       sessionStorage.removeItem("logStatus00");
       sessionStorage.removeItem("logId00");
       sessionStorage.removeItem("logToken00");
       sessionStorage.removeItem("BusyId00");
       sessionStorage.removeItem("BusyName00");

       window.location.replace("businesstools-login");
    }
    //Success Data Fetched
    if(response.data.successvar==1){
     //Setting Data
     setData(response.data.data);
     settransaction_history(response.data.transactions_history.data);
     const lc_summary = response.data.lc_summary;
     // Extract data for line chart
     setlc_date(lc_summary.map((entry) => entry.Date_created));
     setlc_data(lc_summary.map((entry) => entry.Updation_counts));

     const bc_summary = response.data.bc_summary;
     // Extract data for line chart
     setbc_counter(bc_summary.map((entry) => entry.Counter_name));
     setbc_data(bc_summary.map((entry) => entry.Updation_counts));

    }
    //Cannot Fetch Data
    else{
     seterrorvar("Cannot Fetch Data !");
    }
   
   
   });
   
   }
   //User not Logedin
   else{
   
   //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");

     window.location.replace("businesstools-login");
   
    }
   
   }
    
   //******************* GET COUNTER DATA FUNCTION END  ********************//

   
 /* LINE CHART START */
 const data1 = {
  labels: lc_date,
  datasets: [
    {
      label: "Transactions",
      backgroundColor: ["rgb(54, 160, 255)" ],
      borderColor: "rgb(54, 160, 255)",
      data: lc_data,
    },
   
  ],
};
/* LINE CHART END */ 


 /* BAR CHART VARIABLES START */
 const data2 = {
   labels: bc_counter,
   datasets: [
     {
       label: bc_counter,
       backgroundColor: ["#25d494", "#d42641", "#26cdd4", "#d45e26", "#010a9a", "#d943b9", "#d0d943", "#d98843", "#5c108f", "#828f10", "#86efff", "#ffe585" ],
       borderColor: "rgb(255, 99, 132)",
       data: bc_data,
     },
    
   ],
 };
 /* BAR CHART VARIABLES END */

   //******************* GET COUNTER DATA BY ID FUNCTION START  ********************//

//Setting Edit Variables

//ecounter_name
const[ecounter_name,setecounter_name]=useState();const updateecounter_name = (event) => { setecounter_name(event.target.value);}; const[ecounter_name_er,setecounter_name_er]=useState();
//ecounter_type
const[ecounter_type,setecounter_type]=useState();const updateecounter_type = (event) => { setecounter_type(event.target.value);}; const[ecounter_type_er,setecounter_type_er]=useState();
//ecounter_category
const[ecounter_category,setecounter_category]=useState();const updateecounter_category = (event) => { setecounter_category(event.target.value);}; const[ecounter_category_er,setecounter_category_er]=useState();
//ecounter_details
const[ecounter_details,setecounter_details]=useState();const updateecounter_details = (event) => { setecounter_details(event.target.value);}; const[ecounter_details_er,setecounter_details_er]=useState();
//ecounter_limit
const[ecounter_limit,setecounter_limit]=useState();const updateecounter_limit = (event) => { setecounter_limit(event.target.value);}; const[ecounter_limit_er,setecounter_limit_er]=useState();
//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();


//Edit Function
function getcounterdatabyId(){
 

  const counterid=sessionStorage.getItem("CounterId00");

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&(busyid>0)&&(counterid>0)){
 
 
 axios.post("bt-counter-details-byid", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid
 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){

   //Setting Data
   setecounter_name(response.data.counter_name);
   setecounter_type(response.data.type);
   setecounter_category(response.data.category);
   setecounter_details(response.data.counter_details);
   setecounter_limit(response.data.counter_limit);
  
  }
  //Cannot Fetch Data
  else{


      document.getElementById("edit-form").style.display="none";

     //Setting Variables to empty 
     setecounter_name_er(" ");setecounter_type_er(" ");setecounter_category_er(" ");setecounter_details_er(" ");setecounter_limit_er(" ");seterror_msg(" ");
     setecounter_name(" ");setecounter_type(" ");setecounter_category(" ");setecounter_details(" ");setecounter_limit(" ");


      document.getElementById("edit-error-card").style.display="block"; 
      sessionStorage.removeItem("CounterId00");
  }
 
 
 });
 
 }
 //User not Logedin
 else{

     //Checking if user loggedin
     if(((logstatus==null)||(logstatus==""))||((logid==null)||(logid==""))||((token==null)||(token==" "))){
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
     }
 
  }
 
 }
  
 //******************* GET COUNTER DATA BY ID FUNCTION END  ********************//

 
  
//******************* EDIT COUNTER FUNCTION START ********************//
function editcounter(){

  //Setting Error Variables
setecounter_name_er(" ");setecounter_type_er(" ");setecounter_category_er(" ");setecounter_details_er(" ");setecounter_limit_er(" ");seterror_msg(" ");

const counterid=sessionStorage.getItem("CounterId00");

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))){
 
 
  axios.post("bt-counter-edit", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Counter_name : ecounter_name,
   Details : ecounter_details,
   Type : ecounter_type,
   Category : ecounter_category,
   Counter_limit : ecounter_limit
 })
 .then((response) => {


  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");

     window.location.replace("businesstools-login");
  }

   //Validation Errors
 if(response.data.validatorerror){
  setecounter_name_er(response.data.validatorerror.Counter_name);
  setecounter_type_er(response.data.validatorerror.Type);
  setecounter_category_er(response.data.validatorerror.Category);
  setecounter_details_er(response.data.validatorerror.Details);
  setecounter_limit_er(response.data.validatorerror.Counter_limit);

}
//Invalid Entries
if(response.data.error==102){ 
  
  setecounter_name_er(response.data.counter_name_er);
  setecounter_type_er(response.data.type_er);
  setecounter_category_er(response.data.category_er);
  setecounter_details_er(response.data.details_er);
  setecounter_limit_er(response.data.counter_limit_er);

  seterror_msg("Cannot Update Counter Information!");  }


   //Edit Counter
   if(response.data.successvar==1){        
      
      document.getElementById("edit-form").style.display="none";
      document.getElementById("edit-success-card").style.display="block";  
      //Setting Variables to empty
      setecounter_name_er(" ");setecounter_type_er(" ");setecounter_category_er(" ");setecounter_details_er(" ");setecounter_limit_er(" ");seterror_msg(" ");
      setecounter_name(" ");setecounter_type(" ");setecounter_category(" ");setecounter_details(" ");setecounter_limit(" ");
      sessionStorage.removeItem("CounterId00");
  
  }
   //Cannot Edit Counter
   else{  seterror_msg("Cannot Update Counter Information !"); }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* EDIT COUNTER FUNCTION END ********************//



   
//******************* DELETE COUNTER FUNCTION START ********************//
function deletecounter(){

  const counterId=sessionStorage.getItem("DCounterId00")
  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterId!=null)||(counterId!=" "))){
 
 axios.post("bt-counter-delete", {
   Busy_id:busyid,
   Counter_id:counterId,
   Log_id:logid,
   Token:token,
 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("DCounterId00");

     window.location.replace("businesstools-login");
  }

  //Counter Deleted Successfully
   if(response.data.successvar==1){        

      document.getElementById("delete-card").style.display="none";
      document.getElementById("delete-success-card").style.display="block";  
      sessionStorage.removeItem("DCounterId");

  }
  
      //Cannot Delete Counter
    else{  
    document.getElementById("delete-card").style.display="none";
    document.getElementById("delete-error-card").style.display="block";   }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* DELETE BUSINESS FUNCTION END ********************//




return(
    <>
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Business Tools|BookKeeping Solution</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div class="container-scroller">

    <div class="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div class="main-panel">

         {/* SUCCESS CARD START */}
         <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">New Counter Created Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <a href="businesstools-pos"><button class="mt-3 btn btn-outline-danger">Close</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* SUCCESS CARD END */}
               {/* EDIT SUCCESS CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="edit-success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Counter Information Updated Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("edit-success-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* EDIT SUCCESS CARD END */}
               {/* EDIT ERROR CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="edit-error-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-danger">Cannot Fetch Data !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("edit-error-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* EDIT ERROR CARD END */}
               {/* DELETE CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-danger">Are you sure you want to delete this counter?</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> This action cannot be reversed. You will lose all your data related to this counter if you wish to continue this action.</p>
                      <button class="mt-3 btn btn-outline-success" onClick={deletecounter}>Yes, I Know! Delete Counter.</button>
                      <button class="mt-3 btn btn-outline-danger" style={{marginLeft:"1rem"}} onClick={()=>{  
                      //Cancel Delete and remove Counter_id
                      sessionStorage.removeItem("DCounterId00");document.getElementById('delete-card').style.display = 'none'; }}>No, Don't Delete Counter.</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* DELETE CARD END */}
              {/* DELETE SUCCESS CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Counter Deleted Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("delete-success-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* DELETE SUCCESS CARD END */}
               {/* DELETE ERROR CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-error-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-danger">Cannot Delete Counter !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("delete-error-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* DELETE ERROR CARD END */}

          <div class="content-wrapper pb-0">

             {/* COUNTER CREATE FORM START */}
             <div class="row">
              <div class="col-12 grid-margin create-form" id="create-form">
              <Create_counter /> 
              </div>
          </div>
          {/* COUNTER CREATE FORM START */}


            <div class="page-header flex-wrap">
            <div class="header-left"></div>
              <div class="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div class="d-flex align-items-center">
                  <a href="#">
                    <p class="m-0 pe-3">Dashboard</p>
                  </a>
                  <a class="ps-3 me-4" href="#">
                  {/*}  <p class="m-0">ADE-00234</p> */}
                  </a>
                </div>
                <button type="button" hidden={logtype=="user"}  class="btn btn-primary mt-2 mt-sm-0 btn-icon-text" onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
                  <i class="mdi mdi-plus-circle"></i> Add Counter </button>
              </div>
            </div>
            {/*} first row starts here */}
        {/*}    <div class="row">
              <div class="col-xl-9 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-between flex-wrap">
                      <div>
                        <div class="card-title mb-0">Sales Revenue</div>
                        <h3 class="font-weight-bold mb-0">$32,409</h3>
                      </div>
                      <div>
                        <div class="d-flex flex-wrap pt-2 justify-content-between sales-header-right">
                          <div class="d-flex me-5">
                            <button type="button" class="btn btn-social-icon btn-outline-sales">
                              <i class="mdi mdi-inbox-arrow-down"></i>
                            </button>
                            <div class="ps-2">
                              <h4 class="mb-0 font-weight-semibold head-count"> $8,217 </h4>
                              <span class="font-10 font-weight-semibold text-muted">TOTAL SALES</span>
                            </div>
                          </div>
                          <div class="d-flex me-3 mt-2 mt-sm-0">
                            <button type="button" class="btn btn-social-icon btn-outline-sales profit">
                              <i class="mdi mdi-cash text-info"></i>
                            </button>
                            <div class="ps-2">
                              <h4 class="mb-0 font-weight-semibold head-count"> 2,804 </h4>
                              <span class="font-10 font-weight-semibold text-muted">TOTAL PROFIT</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p class="text-muted font-13 mt-2 mt-sm-0"> Your sales monitoring dashboard template. <a class="text-muted font-13" href="#"><u>Learn more</u></a>
                    </p>
                    <div class="flot-chart-wrapper">
                      <div id="flotChart" class="flot-chart">
                        <canvas class="flot-base"></canvas>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 stretch-card grid-margin">
                <div class="card card-img">
                  <div class="card-body d-flex align-items-center">
                    <div class="text-white">
                      <h1 class="font-20 font-weight-semibold mb-0"> Get premium </h1>
                      <h1 class="font-20 font-weight-semibold">account!</h1>
                      <p>to optimize your selling prodcut</p>
                      <p class="font-10 font-weight-semibold"> Enjoy the advantage of premium. </p>
                      <button class="btn bg-white font-12">Get Premium</button>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/*} chart row starts here */}
            <div class="row">
                 {/* LINE CHART START */}
                 <div className="col-sm-6 stretch-card grid-margin">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                    <div className="card-title"> Transactions
                      </div>
                   
                      <div className="d-flex text-muted font-20">
                      {/*  <i className="mdi mdi-printer mouse-pointer"></i>
                        <i className="mdi mdi-help-circle-outline ms-2 mouse-pointer">  </i> */}
                        <i className="mdi mdi-arrow-down-drop-circle text-primary" onClick={()=>{ document.getElementById("lc-date-search-box").style.display="block"; }}></i>
                        <i className="mdi mdi-close-box text-danger"  onClick={()=>{ document.getElementById("lc-date-search-box").style.display="none"; }}></i>
                      
                      </div>
                    </div>
                    {/* LINE CHART DATE FILTER START */}
                    <div id="lc-date-search-box" style={{display:"none"}}>
                    <hr />
                    <p style={{marginTop:"2rem"}}>Make sure start date and end date are not more than 10 days apart for best results</p>
                    <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label"> Start Date</label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control-sm"  id="lc_start_date" value={lc_start_date} onChange={updatelc_start_date} />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">End Date</label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control-sm" id="lc_end_date" value={lc_end_date} onChange={updatelc_end_date} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <button class="btn btn-primary mb-4" onClick={()=>{ setlc_default(1); getData(); }}>Update</button>
                      <hr/>
                      </div>
                      {/* LINE CHART DATE FILTER END */}
                      <div className="line-chart-wrapper">
                     <Line data={data1} /> 
                     {/* LINE CHART END */}      
                    </div>
                  </div>
                </div>
              </div>
               {/* LINECHART END */}
                {/* BARCHART START */}
              <div className="col-sm-6 stretch-card grid-margin">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <div className="card-title"> Counters Summary
                      </div>
                      <div className="d-flex text-muted font-20">
                      {/*}  <i className="mdi mdi-printer mouse-pointer"></i>
                        <i className="mdi mdi-help-circle-outline ms-2 mouse-pointer"></i> */}
                        <i className="mdi mdi-arrow-down-drop-circle text-primary" onClick={()=>{ document.getElementById("bc-date-search-box").style.display="block"; }}></i>
                        <i className="mdi mdi-close-box text-danger"  onClick={()=>{ document.getElementById("bc-date-search-box").style.display="none"; }}></i>
                      
                      </div>
                    </div>
                        {/*BAR CHART DATE FILTER START */}
                        <div id="bc-date-search-box" style={{display:"none"}}>
                    <hr />
                    <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label"> Start Date</label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control-sm"  id="bc_start_date" value={bc_start_date} onChange={updatebc_start_date} />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">End Date</label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control-sm" id="bc_end_date" value={bc_end_date} onChange={updatebc_end_date} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <button class="btn btn-primary mb-4" onClick={()=>{ setbc_default(1); getData(); }}>Update</button>
                      <hr/>
                      </div>
                      {/* BAR CHART DATE FILTER END */}
                      <div className="bar-chart-wrapper">
                      {/* SUMMARY BARCHART START */}
                      <Bar data={data2} />
                      {/* SUMMARY BARCHART END */}
                    </div>
                  </div>
                </div>
              </div>
              {/* BARCHART END */}
          
            </div>

              {/* COUNTER EDIT FORM START */}
              <div class="row">
              <div class="col-12 grid-margin create-form" id="edit-form" style={{display:"none"}}>
              <div class="card">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ 
                        //close edit form button
                        document.getElementById("edit-form").style.display="none";
                        //Setting Variables to empty
                        setecounter_name_er(" ");setecounter_type_er(" ");setecounter_category_er(" ");setecounter_details_er(" ");setecounter_limit_er(" ");seterror_msg(" ");
                        setecounter_name(" ");setecounter_type(" ");setecounter_category(" ");setecounter_details(" ");setecounter_limit(" ");
                        sessionStorage.removeItem("CounterId00");
                            }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                  <div class="card-body">
                    <h4 class="card-title">Edit Counter</h4>
                    <div class="form-sample">
                      <p class="card-description">enter your counter details here</p>
                      <p><code>{error_msg}</code></p>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Counter Name<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Enter Counter Name" id="ecounter_name" value={ecounter_name} onChange={updateecounter_name} />
                            <code>{ecounter_name_er}</code>
                            </div>
                          </div>
                        </div>
                      {/*}  <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Counter Limit<code>*</code></label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="5000" id="ecounter_limit" value={ecounter_limit} onChange={updateecounter_limit} />
                              <code>{ecounter_limit_er}</code>
                            </div>
                          </div>
                        </div> */}
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type</label>
                            <div class="col-sm-9">
                              <input type="email" class="form-control" placeholder="Enter Counter Type"  id="ecounter_type" value={ecounter_type} onChange={updateecounter_type} />
                            {ecounter_type_er}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Category</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Enter Counter Category"  id="ecounter_category" value={ecounter_category} onChange={updateecounter_category} />
                            <code>{ecounter_category_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                   
                      <div class="form-group">
                        <label for="exampleTextarea1">Details</label>
                        <textarea  class="form-control" rows="2"  id="ecounter_details" value={ecounter_details} onChange={updateecounter_details} ></textarea>
                       <code>{ecounter_details_er}</code>
                      </div>
                   
                    
                      <button class="btn-lg btn-warning" style={{fontWeight:"bold"}} onClick={editcounter} >Edit Counter</button>
                        
                       
    
                     
                   
                    </div>
                  </div>
                </div>
              </div>
          </div>
          {/* COUNTER EDIT FORM END */}
  
          {/* COUNTER CARDS SECTION START */}

            {/*} image card row starts here */}
            <div class="row">
            {
                       data.map((curElm,ind)=>{

return(
  <>
            {/* CARD 1 */}
              <div class="col-sm-4 stretch-card grid-margin" key={curElm.Counter_id}>
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://images.pexels.com/photos/12935048/pexels-photo-12935048.jpeg?auto=compress&cs=tinysrgb&w=600" alt="" />
                  </div>
                  <div class="card-body px-3 text-dark">
                    <div class="d-flex justify-content-between">
                      <h6 class="text-muted  mb-0">#{curElm.Counter_id}</h6>
                      <i class="mdi mdi-heart-outline"></i>
                    </div>
                    <h4 class="font-weight-semibold">{curElm.Counter_name}</h4>
                    <hr/>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0"><b>Type : </b>{curElm.Type}</p>
                        <p class="mb-0"><b>Category : </b>{curElm.Category}</p>
                    </div>
                  {/*}  <div class="d-flex justify-content-between">
                      <p class="mb-0"><b>Counter Limit : </b>{curElm.Counter_limit}</p>
                        <p class="mb-0"><b>Counter Orders : </b>{curElm.Counter_orders}</p>
                    </div> */}
                    <p class="text-muted  mt-3 mb-3"> {curElm.Counter_details}</p>
                    <button class="btn btn-success" onClick={()=>{ 
                      //Setting Counter and redirecting to startworking page
                      sessionStorage.setItem("CounterId00",curElm.Counter_id); window.location.replace("/businesstools-startworking")
                    }}>Start Working</button>
                     
                    <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                      <button type="button" class="btn btn-social-icon" hidden={logtype=="user"} onClick={()=>{ 
                        //Setting Counter Id and Redirect to Edit
                      sessionStorage.setItem("CounterId00",curElm.Counter_id);getcounterdatabyId();document.getElementById('edit-form').style.display = 'block'; }}>
                      <i class="mdi mdi-pencil-box text-warning" style={{fontSize:"2rem"}}></i>
                      </button>
                      <button type="button" class="btn btn-social-icon" hidden={logtype=="user"} onClick={()=>{ 
                        //Setting Counter Id for Delete
                      sessionStorage.setItem("DCounterId00",curElm.Counter_id);document.getElementById('delete-card').style.display = 'block'; }}>
                      <i class="mdi mdi-delete-forever text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    </>
      )
      }
					   
			)}

             


             </div>
        
                  {/* COUNTER CARDS SECTION END */}




                 {/* FILTER SECTION START */}
                 <hr/>
                            <div id="filter-top-card" class="row col-12" style={{textAlign:"left",display:"none"}}>
                            <div class="d-flex justify-content-between">
                            <div>
                            <h4 style={{padding:'1rem'}}>Filters</h4>
                           </div>
                           <div>
                    
                           <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("filter-top-card").style.display="none"; }}>
                           <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                           </button>
                           </div>
                           </div>
        
                        <div class="row">
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Start Date</label>
                            <div class="col-sm-8">
                              <input type="date" class="form-control" id="filter_start_date" value={filter_start_date} onChange={updatefilter_start_date}  />
                              <code>{filter_start_date_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">End Date</label>
                            <div class="col-sm-8">
                              <input type="date" class="form-control"  id="filter_end_date" value={filter_end_date} onChange={updatefilter_end_date}  />
                              <code>{filter_end_date_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Tr No.</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" placeholder="12345"   id="filter_trno" value={filter_trno} onChange={updatefilter_trno}  />
                              <code>{filter_trno_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Counter Id</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" placeholder="12"   id="filter_busy_id" value={filter_busy_id} onChange={updatefilter_busy_id}  />
                              <code>{filter_busy_id_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Amount</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" placeholder="12000"   id="filter_amount" value={filter_amount} onChange={updatefilter_amount}  />
                              <code>{filter_amount_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Updated By</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" placeholder="jOHN DOE"   id="filter_updated_by" value={filter_updated_by} onChange={updatefilter_updated_by}  />
                              <code>{filter_updated_by_er}</code>
                            </div>
                          </div>
                        </div>
                   
                        <div class="col-md-4">
                         <button class="btn btn-primary" onClick={()=>{ setfilter_val(1); getData(); }}>Apply Filters</button>
                         <button class="btn btn-danger" style={{marginLeft:"1rem"}} onClick={()=>{ setfilter_val(0); getData(); }}>Remove Filters</button>
                        </div>
                        </div>
                            <hr/>                      
                            </div>
                            
                        {/* FILTER SECTION END */}

                        <div class="col-12" style={{marginBottom:"2rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                        {/* COLUMNS MANAGEMENT DROPDOWN START */}
                          <div class="dropdown">
                          <button class="btn btn-warning" onClick={()=>{ document.getElementById("filter-top-card").style.display="block"; }}><i class="mdi mdi-filter-outline"></i></button>
                         
                          </div>
                          {/* COLUMNS MANAGEMENT DROPDOWN END */}

                          </div>
                          </div>

                {/* COUNTER TRANSACTIONS HISTORY SECTION START */}
             <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Transactions and Updations History</h4>
                    <p class="card-description">your transactions today
                    </p>
                    <div class="table-responsive">
                      <table class="table table-hover">
                        <thead>
                          <tr >
                            <th  style={{fontWeight:"bold"}}>Sn</th>
                            <th  style={{fontWeight:"bold"}}>Tr_no</th>
                            <th  style={{fontWeight:"bold"}}>Date</th>
                            <th  style={{fontWeight:"bold"}}>Time</th>
                            <th  style={{fontWeight:"bold"}}>Counter Id</th>
                            <th  style={{fontWeight:"bold"}}>Transaction</th>
                            <th  style={{fontWeight:"bold"}}>Amount</th>
                            <th  style={{fontWeight:"bold"}}>Updated By</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                      transaction_history.map((curElm1,ind)=>{

return(
  <>
                        <tr key={curElm1.Transaction_id}>
                         
                            <td>{curElm1.Sn}</td> 
                            <td>{curElm1.Tr_no}</td>
                            <td>{curElm1.Date_created}</td>
                            <td>{curElm1.Time_created}</td>                           
                            <td>{curElm1.Counter_id}</td>
                            <td>{curElm1.Particular}</td>
                            <td>{curElm1.Amount}</td>          
                            <td>{curElm1.Updated_by}</td>                   
                          </tr>
                          </>
      )
      }
					   
			)}
                       
                
                         
                        </tbody>
                      </table>
                    </div>
                       {/* PAGINATION SECTION START */}
                        <div>
                       <div class="col-12" style={{marginBottom:"2rem",marginTop:"1rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                       
                          <div class="dropdown">
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber-1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-left-drop-circle text-success"></i></button>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber+1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-right-drop-circle text-success"></i></button>
                          </div>
                          </div>
                          </div>
                    </div>
                    {/* PAGINATION SECTION END */}
                  </div>
                </div>
              </div>
              {/* COUNTER TRANSACTIONS HISTORY SECTION END */}
            
          </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
        {/*}  <footer class="footer">
            <div class="d-sm-flex justify-content-center justify-content-sm-between">
              <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
</>
);

   }

//}
export default Poshome;