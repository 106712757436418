import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import Navbar from './navbar';



//import '../hireportalcss.css';
//import '../Hireportalcss/hireportalcss.css';

//Importing Page css with screen sizes
import '../Hireportalcss/sdefault.css';
import '../Hireportalcss/s1200.css';
import '../Hireportalcss/s920.css';
import '../Hireportalcss/s768.css';
import '../Hireportalcss/s600.css';
import '../Hireportalcss/sanimation.css';



function Employerviewresume(){

    return(

    <>
        <div class="default-view">
        <Navbar/>
        {/* MAIN SECTION START */}
        <div class="hire-main-section">

        {/* FORM CONTAINER START */}
        <div class="hire-form-container">
        
        {/* RESUME CARD START */}
            <div class="hire-form-panel" style={{backgroundColor:"#ffffff"}}>


            <h2>Detailed Resume of <span style={{color:"#154f9d"}}>SURESH VERMA</span></h2>
            
            <div id="line-1"></div>
            <p style={{textAlign:"right",marginRight:"1rem",fontSize:"80%",color:"#939598"}}><b>Status :</b> <span style={{color:"#29cc5f"}}>online </span><b>Last Active :</b> 23-03-2023</p>
            {/* RESUME SECTION START */}
            <div class="hire-resume-section">


            <h3 style={{textAlign:"left"}}>A.) BASIC DETAILS</h3>

            {/* BASIC DETAILS SECTION START */}
            <div class="hire-resume-card">
           <h3>Full Name : SURESH VERMA</h3>

            <div class="hire-resume-h-list">

            <div class="hire-resume-dsize-3">
            <div class="hire-resume-ict-box">
            <div class="hire-resume-ict-icon"><img src="https://cdn-icons-png.flaticon.com/128/6806/6806987.png" class="hire-resume-ict-icon" /></div>
            <div class="hire-resume-ict-text">sureshverma893@gmail.com</div>
            </div>
            </div>

            <div class="hire-resume-dsize-3">
            <div class="hire-resume-ict-box">
            <div class="hire-resume-ict-icon"><img src="https://cdn-icons-png.flaticon.com/128/10261/10261328.png" class="hire-resume-ict-icon" /></div>
            <div class="hire-resume-ict-text">+91 87878 98787</div>
            </div>
            </div>

            <div class="hire-resume-dsize-3">
            <div class="hire-resume-ict-box">
            <div class="hire-resume-ict-icon"><img src="https://cdn-icons-png.flaticon.com/128/4494/4494495.png" class="hire-resume-ict-icon" /></div>
            <div class="hire-resume-ict-text">sureshverma893@gmail.com</div>
            </div>
            </div>
          
           </div>

           <div class="hire-resume-h-list">

           <div class="hire-resume-dsize-3">
           <div>
           <b>Father's Name : </b>Aravalli Kumar Verma
           </div>
           </div>

           <div class="hire-resume-dsize-3">
           <div>
           <b>Mother's Name : </b>Sumaila Kumar Verma
           </div>
           </div>

           <div class="hire-resume-dsize-3">
           <div class="hire-resume-ict-box">
           <div class="hire-resume-ict-icon"><img src="https://cdn-icons-png.flaticon.com/128/2517/2517326.png" class="hire-resume-ict-icon" /></div>
           <div class="hire-resume-ict-text">Male</div>
           </div>
           </div>

           </div>


           <div class="hire-resume-h-list">

           <div class="hire-resume-dsize-3">
           <div class="hire-resume-ict-box">
           <div class="hire-resume-ict-icon"><img src="https://cdn-icons-png.flaticon.com/128/3991/3991771.png" class="hire-resume-ict-icon" /></div>
           <div class="hire-resume-ict-text">23-07-2000</div>
           </div>
           </div>

           <div class="hire-resume-dsize-3">
           <div>
           <b>Age : </b>23 years
           </div>
           </div>

           <div class="hire-resume-dsize-3">
           <div>
           <b>Sibling : </b><span style={{color:"#1aa367"}}>Yes</span>
           </div>
           </div>

        

          </div>

          <div id="line-3"></div> 

          {/* Permanent Address Start */}
          <div>
          <h3>Permanent Address</h3>

           
          <p style={{marginLeft:"1rem"}}><b>Address : </b>56/A North Bloack, Second Floor South GB Road near Adarsh Market</p>


          <div class="hire-resume-h-list">

          <div class="hire-resume-dsize-3">
          <div>
          <b>City : </b>Jabalpur
          </div>
          </div>

          <div class="hire-resume-dsize-3">
          <div>
          <b>State : </b>Madhya Pradesh
          </div>
          </div>

          <div class="hire-resume-dsize-3">
          <div>
          <b>Country : </b>India
          </div>
          </div>





          </div>
          </div>
          {/* Permanent Address End */}


          <div id="line-3"></div> 

{/* Current Address Start */}
<div>
<h3>Current Address</h3>

 
<p style={{marginLeft:"1rem"}}><b>Address : </b>56/A North Bloack, Second Floor South GB Road near Adarsh Market</p>


<div class="hire-resume-h-list">

<div class="hire-resume-dsize-3">
<div>
<b>City : </b>Jabalpur
</div>
</div>

<div class="hire-resume-dsize-3">
<div>
<b>State : </b>Madhya Pradesh
</div>
</div>

<div class="hire-resume-dsize-3">
<div>
<b>Country : </b>India
</div>
</div>





</div>
</div>
{/* Current Address End */}


             
            </div>
            {/* BASIC DETAILS SECTION END */}




            <h3 style={{textAlign:"left"}}>B.) EDUCATIONAL DETAILS</h3>

{/* EDUCATIONAL DETAILS SECTION START */}

{/* Education Card 1 Start */}
<div class="hire-resume-card" style={{borderColor:"#bc4e01"}}>
<p style={{color:"#a3aaab"}}>#1</p>

<h3>MBA in Marketing</h3>
<p style={{marginLeft:"1rem"}}><b>Type : </b>Full Time</p>


           <div style={{marginLeft:"1rem"}}>
           <div class="hire-resume-ict-box">
           <div class="hire-resume-ict-icon"><img src="https://cdn-icons-png.flaticon.com/128/11068/11068480.png" class="hire-resume-ict-icon" /></div>
           <div class="hire-resume-ict-text">Simhogs Institution for Advance Technology Learning and Artificial Intelligence, North Road, Jabalpur, Madhya Pradesh(India) PinCode - 482002</div>
           </div>
           </div>

<div class="hire-resume-h-list">

<div class="hire-resume-dsize-3">
<div>
<b>Marks/C.G.P.A./Percentage : </b>85
</div>
</div>

<div class="hire-resume-dsize-3">
<div>
<b>Start Date : </b>24-07-2021
</div>
</div>


<div class="hire-resume-dsize-3">
<div>
<b>End Date : </b>23-06-2023
</div>
</div>



</div>

 
</div>

{/* Education Card 1 End */}


{/* Education Card 2 Start */}
<div class="hire-resume-card" style={{borderColor:"#bc4e01",marginTop:"0.2rem"}}>
<p style={{color:"#a3aaab"}}>#2</p>

<h3>MBA in Marketing</h3>
<p style={{marginLeft:"1rem"}}><b>Type : </b>Full Time</p>


           <div style={{marginLeft:"1rem"}}>
           <div class="hire-resume-ict-box">
           <div class="hire-resume-ict-icon"><img src="https://cdn-icons-png.flaticon.com/128/11068/11068480.png" class="hire-resume-ict-icon" /></div>
           <div class="hire-resume-ict-text">Simhogs Institution for Advance Technology Learning and Artificial Intelligence, North Road, Jabalpur, Madhya Pradesh(India) PinCode - 482002</div>
           </div>
           </div>

<div class="hire-resume-h-list">

<div class="hire-resume-dsize-3">
<div>
<b>Marks/C.G.P.A./Percentage : </b>85
</div>
</div>

<div class="hire-resume-dsize-3">
<div>
<b>Start Date : </b>24-07-2021
</div>
</div>


<div class="hire-resume-dsize-3">
<div>
<b>End Date : </b>23-06-2023
</div>
</div>



</div>

 
</div>

{/* Education Card 2 End */}


{/* Education Card 3 Start */}
<div class="hire-resume-card" style={{borderColor:"#bc4e01",marginTop:"0.2rem"}}>
<p style={{color:"#a3aaab"}}>#3</p>

<h3>MBA in Marketing</h3>
<p style={{marginLeft:"1rem"}}><b>Type : </b>Full Time</p>


           <div style={{marginLeft:"1rem"}}>
           <div class="hire-resume-ict-box">
           <div class="hire-resume-ict-icon"><img src="https://cdn-icons-png.flaticon.com/128/11068/11068480.png" class="hire-resume-ict-icon" /></div>
           <div class="hire-resume-ict-text">Simhogs Institution for Advance Technology Learning and Artificial Intelligence, North Road, Jabalpur, Madhya Pradesh(India) PinCode - 482002</div>
           </div>
           </div>

<div class="hire-resume-h-list">

<div class="hire-resume-dsize-3">
<div>
<b>Marks/C.G.P.A./Percentage : </b>85
</div>
</div>

<div class="hire-resume-dsize-3">
<div>
<b>Start Date : </b>24-07-2021
</div>
</div>


<div class="hire-resume-dsize-3">
<div>
<b>End Date : </b>23-06-2023
</div>
</div>



</div>

 
</div>

{/* Education Card 3 End */}


{/* EDUCATIONAL DETAILS SECTION END */}



{/* SKILLS SECTION START*/}

<h3 style={{textAlign:"left"}}>C.) SKILLS</h3>

<div class="hire-resume-card" style={{borderColor:"#106941"}}>
        <div class="hire-list-card-skillbar">
        <div class="hire-card-skill-badge" style={{backgroundColor:"#d4b717",color:"black"}}>
        <span>MySql</span>
        </div>  
        <div class="hire-card-skill-badge" style={{backgroundColor:"#d4b717",color:"black"}}>
        <span>Web Development</span>
        </div>  
        <div class="hire-card-skill-badge" style={{backgroundColor:"#d4b717",color:"black"}}>
        <span>React Js</span>
        </div>  
        <div class="hire-card-skill-badge" style={{backgroundColor:"#d4b717",color:"black"}}>
        <span>Laravel</span>
        </div>  
        <div class="hire-card-skill-badge" style={{backgroundColor:"#d4b717",color:"black"}}>
        <span>HTML/CSS</span>
        </div>  
        <div class="hire-card-skill-badge" style={{backgroundColor:"#d4b717",color:"black"}}>
        <span>React Js</span>
        </div>  
        <div class="hire-card-skill-badge" style={{backgroundColor:"#d4b717",color:"black"}}>
        <span>Laravel</span>
        </div>  
        <div class="hire-card-skill-badge" style={{backgroundColor:"#d4b717",color:"black"}}>
        <span>HTML/CSS</span>
        </div>  
        <div class="hire-card-skill-badge" style={{backgroundColor:"#d4b717",color:"black"}}>
        <span>MySql</span>
        </div>  

       
        </div>
 
</div>

{/* SKILLS SECTION END */}




<h3 style={{textAlign:"left"}}>D.) COURSES AND CERTIFICATIONS</h3>

{/* COURSES AND CERTIFICATIONS SECTION START */}

{/* Courses and Certifications Card 1 Start */}
<div class="hire-resume-card" style={{borderColor:"#0b5899"}}>
<p style={{color:"#a3aaab"}}>#1</p>

<h3>Course/ Certification : Advanced Concepts of Digital Marketing</h3>
<p style={{marginLeft:"1rem"}}><b>Type : </b>Full Time</p>

<div class="hire-resume-h-list">


<div  class="hire-resume-dsize-3" style={{marginLeft:"1rem",marginTop:"0.2rem"}}>
<div class="hire-resume-ict-box">
<div class="hire-resume-ict-icon"><img src="https://cdn-icons-png.flaticon.com/128/11068/11068480.png" class="hire-resume-ict-icon" /></div>
<div class="hire-resume-ict-text">Google Tech Institute, North Road, Jabalpur, Madhya Pradesh(India) PinCode - 482002</div>
</div>
</div>

<div class="hire-resume-dsize-3">
<div>
<b>Certificate ID : </b>AAGAGAG888888
</div>
</div>


<div class="hire-resume-dsize-3">
<div>
<b>Marks/ C.G.P.A./Percentage : </b>84
</div>
</div>



</div>

<div class="hire-resume-h-list">

<div class="hire-resume-dsize-3">
<div>
<b>Start Date : </b>24-07-2021
</div>
</div>


<div class="hire-resume-dsize-3">
<div>
<b>End Date : </b>23-06-2023
</div>
</div>

<div class="hire-resume-dsize-3">
<div>
<b>Valid Till : </b>24-07-2025
</div>
</div>



</div>

<div style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Link : </b>https://www.google.com/digitalmarketing/certificate/JJJJAJAAHHA999.pkm
</div>
</div>
 
 <div style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Details : </b>
</div>
</div>

</div>

{/* Courses and Certifications Card 1 End */}



{/* Courses and Certifications Card 2 Start */}
<div class="hire-resume-card" style={{borderColor:"#0b5899",marginTop:"0.2rem"}}>
<p style={{color:"#a3aaab"}}>#2</p>

<h3>Course/ Certification : Advanced Concepts of Digital Marketing</h3>
<p style={{marginLeft:"1rem"}}><b>Type : </b>Full Time</p>

<div class="hire-resume-h-list">


<div  class="hire-resume-dsize-3" style={{marginLeft:"1rem"}}>
<div class="hire-resume-ict-box">
<div class="hire-resume-ict-icon"><img src="https://cdn-icons-png.flaticon.com/128/11068/11068480.png" class="hire-resume-ict-icon" /></div>
<div class="hire-resume-ict-text">Google Tech Institute, North Road, Jabalpur, Madhya Pradesh(India) PinCode - 482002</div>
</div>
</div>

<div class="hire-resume-dsize-3">
<div>
<b>Certificate ID : </b>AAGAGAG888888
</div>
</div>


<div class="hire-resume-dsize-3">
<div>
<b>Marks/ C.G.P.A./Percentage : </b>84
</div>
</div>



</div>

<div class="hire-resume-h-list">

<div class="hire-resume-dsize-3">
<div>
<b>Start Date : </b>24-07-2021
</div>
</div>


<div class="hire-resume-dsize-3">
<div>
<b>End Date : </b>23-06-2023
</div>
</div>

<div class="hire-resume-dsize-3">
<div>
<b>Valid Till : </b>24-07-2025
</div>
</div>



</div>

<div style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Link : </b>https://www.google.com/digitalmarketing/certificate/JJJJAJAAHHA999.pkm
</div>
</div>
 
 <div style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Details : </b>
</div>
</div>

</div>

{/* Courses and Certifications Card 2 End */}



{/* Courses and Certifications Card 3 Start */}
<div class="hire-resume-card" style={{borderColor:"#0b5899",marginTop:"0.2rem"}}>
<p style={{color:"#a3aaab"}}>#3</p>

<h3>Course/ Certification : Advanced Concepts of Digital Marketing</h3>
<p style={{marginLeft:"1rem"}}><b>Type : </b>Full Time</p>

<div class="hire-resume-h-list">


<div  class="hire-resume-dsize-3" style={{marginLeft:"1rem"}}>
<div class="hire-resume-ict-box">
<div class="hire-resume-ict-icon"><img src="https://cdn-icons-png.flaticon.com/128/11068/11068480.png" class="hire-resume-ict-icon" /></div>
<div class="hire-resume-ict-text">Google Tech Institute, North Road, Jabalpur, Madhya Pradesh(India) PinCode - 482002</div>
</div>
</div>

<div class="hire-resume-dsize-3">
<div>
<b>Certificate ID : </b>AAGAGAG888888
</div>
</div>


<div class="hire-resume-dsize-3">
<div>
<b>Marks/ C.G.P.A./Percentage : </b>84
</div>
</div>



</div>

<div class="hire-resume-h-list">

<div class="hire-resume-dsize-3">
<div>
<b>Start Date : </b>24-07-2021
</div>
</div>


<div class="hire-resume-dsize-3">
<div>
<b>End Date : </b>23-06-2023
</div>
</div>

<div class="hire-resume-dsize-3">
<div>
<b>Valid Till : </b>24-07-2025
</div>
</div>



</div>

<div style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Link : </b>https://www.google.com/digitalmarketing/certificate/JJJJAJAAHHA999.pkm
</div>
</div>
 
 <div style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Details : </b>
</div>
</div>

</div>

{/* Courses and Certifications Card 3 End */}



{/* COURSES AND CERTIFICATIONS SECTION END */}


<h3 style={{textAlign:"left"}}>E.) WORK EXPERIENCE</h3>

{/* WORK EXPERIENCE SECTION START */}

{/* Work Experience Card 1 Start */}
<div class="hire-resume-card" style={{borderColor:"#0a487d",marginTop:"0.2rem"}}>
<p style={{color:"#a3aaab"}}>#1</p>

<h3>Full Stack Developer</h3>
<p style={{marginLeft:"1rem"}}><b>Type : </b>Full Time</p>

<div class="hire-resume-h-list">

<div class="hire-resume-dsize-3">
<div>
<b>Start Date : </b>24-07-2021
</div>
</div>


<div class="hire-resume-dsize-3">
<div>
<b>End Date : </b>23-06-2023
</div>
</div>

<div class="hire-resume-dsize-3">
<div>
<b>Currently Working : </b><span style={{color:"#cc2b49"}}>No</span>
</div>
</div>



</div>

<div style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Work and Responsibilities : </b>Full stack developers build and maintain web applications from the ground up, mastering front-end and back-end technologies. 
</div>
</div>
 
 <div class="hire-resume-h-list">

<div  class="hire-resume-dsize-2" style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Link : </b>https://www.google.com/digitalmarketing/certificate/JJJJAJAAHHA999.pkm
</div>
</div>

<div  class="hire-resume-dsize-2" style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Reference : </b><span style={{color:"#2bcc66"}}>Yes</span>
</div>
</div>

</div>


<div style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Skills Utilized : </b>
</div>
</div>






<div class="hire-list-card-skillbar">
        <div class="hire-card-skill-badge" style={{backgroundColor:"#2bcc66",color:"black"}}>
        <span>MySql</span>
        </div>  
        <div class="hire-card-skill-badge" style={{backgroundColor:"#2bcc66",color:"black"}}>
        <span>Web Development</span>
        </div>  
        <div class="hire-card-skill-badge" style={{backgroundColor:"#2bcc66",color:"black"}}>
        <span>React Js</span>
        </div>  
        <div class="hire-card-skill-badge" style={{backgroundColor:"#2bcc66",color:"black"}}>
        <span>Laravel</span>
        </div>  
        <div class="hire-card-skill-badge" style={{backgroundColor:"#2bcc66",color:"black"}}>
        <span>HTML/CSS</span>
        </div>  
        <div class="hire-card-skill-badge" style={{backgroundColor:"#2bcc66",color:"black"}}>
        <span>React Js</span>
        </div>  

       
        </div>

        <div id="line-3"></div>
        <h3 style={{marginLeft:"1rem"}}>Reference Details</h3>

<div class="hire-resume-h-list">

<div  class="hire-resume-dsize-2" style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Name : </b>Shobbhit Roy Dutta
</div>
</div>

<div  class="hire-resume-dsize-2" style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Company : </b>St. Proviate Limited for Regular Supplies, North Block, Sector-7 New Delhi, Delhi (India) PinCode- 360001
</div>
</div>

</div>


<div class="hire-resume-h-list">

<div class="hire-resume-dsize-3">
<div class="hire-resume-ict-box">
<div class="hire-resume-ict-icon"><img src="https://cdn-icons-png.flaticon.com/128/6806/6806987.png" class="hire-resume-ict-icon" /></div>
<div class="hire-resume-ict-text">sshobbhitroy893@gmail.com</div>
</div>
</div>

<div class="hire-resume-dsize-3">
<div class="hire-resume-ict-box">
<div class="hire-resume-ict-icon"><img src="https://cdn-icons-png.flaticon.com/128/10261/10261328.png" class="hire-resume-ict-icon" /></div>
<div class="hire-resume-ict-text">+91 87451 98787</div>
</div>
</div>

<div class="hire-resume-dsize-3">
<div class="hire-resume-ict-box">
<div class="hire-resume-ict-icon"><img src="https://cdn-icons-png.flaticon.com/128/9750/9750948.png" class="hire-resume-ict-icon" /></div>
<div class="hire-resume-ict-text">Team Manager</div>
</div>
</div>

</div>

</div>
{/* Work Experience Card 1 End */}


{/* Work Experience Card 2 Start */}
<div class="hire-resume-card" style={{borderColor:"#0a487d",marginTop:"0.2rem"}}>
<p style={{color:"#a3aaab"}}>#2</p>

<h3>Full Stack Developer</h3>
<p style={{marginLeft:"1rem"}}><b>Type : </b>Full Time</p>

<div class="hire-resume-h-list">

<div class="hire-resume-dsize-3">
<div>
<b>Start Date : </b>24-07-2021
</div>
</div>


<div class="hire-resume-dsize-3">
<div>
<b>End Date : </b>23-06-2023
</div>
</div>

<div class="hire-resume-dsize-3">
<div>
<b>Currently Working : </b><span style={{color:"#cc2b49"}}>No</span>
</div>
</div>



</div>

<div style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Work and Responsibilities : </b>Full stack developers build and maintain web applications from the ground up, mastering front-end and back-end technologies. 
</div>
</div>
 
 <div class="hire-resume-h-list">

<div  class="hire-resume-dsize-2" style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Link : </b>https://www.google.com/digitalmarketing/certificate/JJJJAJAAHHA999.pkm
</div>
</div>

<div  class="hire-resume-dsize-2" style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Reference : </b><span style={{color:"#2bcc66"}}>Yes</span>
</div>
</div>

</div>


<div style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Skills Utilized : </b>
</div>
</div>






<div class="hire-list-card-skillbar">
        <div class="hire-card-skill-badge" style={{backgroundColor:"#2bcc66",color:"black"}}>
        <span>MySql</span>
        </div>  
        <div class="hire-card-skill-badge" style={{backgroundColor:"#2bcc66",color:"black"}}>
        <span>Web Development</span>
        </div>  
        <div class="hire-card-skill-badge" style={{backgroundColor:"#2bcc66",color:"black"}}>
        <span>React Js</span>
        </div>  
        <div class="hire-card-skill-badge" style={{backgroundColor:"#2bcc66",color:"black"}}>
        <span>Laravel</span>
        </div>  
        <div class="hire-card-skill-badge" style={{backgroundColor:"#2bcc66",color:"black"}}>
        <span>HTML/CSS</span>
        </div>  
        <div class="hire-card-skill-badge" style={{backgroundColor:"#2bcc66",color:"black"}}>
        <span>React Js</span>
        </div>  

       
        </div>

        <div id="line-3"></div>
        <h3 style={{marginLeft:"1rem"}}>Reference Details</h3>

<div class="hire-resume-h-list">

<div  class="hire-resume-dsize-2" style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Name : </b>Shobbhit Roy Dutta
</div>
</div>

<div  class="hire-resume-dsize-2" style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Company : </b>St. Proviate Limited for Regular Supplies, North Block, Sector-7 New Delhi, Delhi (India) PinCode- 360001
</div>
</div>

</div>


<div class="hire-resume-h-list">

<div class="hire-resume-dsize-3">
<div class="hire-resume-ict-box">
<div class="hire-resume-ict-icon"><img src="https://cdn-icons-png.flaticon.com/128/6806/6806987.png" class="hire-resume-ict-icon" /></div>
<div class="hire-resume-ict-text">sshobbhitroy893@gmail.com</div>
</div>
</div>

<div class="hire-resume-dsize-3">
<div class="hire-resume-ict-box">
<div class="hire-resume-ict-icon"><img src="https://cdn-icons-png.flaticon.com/128/10261/10261328.png" class="hire-resume-ict-icon" /></div>
<div class="hire-resume-ict-text">+91 87451 98787</div>
</div>
</div>

<div class="hire-resume-dsize-3">
<div class="hire-resume-ict-box">
<div class="hire-resume-ict-icon"><img src="https://cdn-icons-png.flaticon.com/128/9750/9750948.png" class="hire-resume-ict-icon" /></div>
<div class="hire-resume-ict-text">Team Manager</div>
</div>
</div>

</div>

</div>
{/* Work Experience Card 2 End */}

{/* Work Experience Card 3 Start */}
<div class="hire-resume-card" style={{borderColor:"#0a487d",marginTop:"0.2rem"}}>
<p style={{color:"#a3aaab"}}>#3</p>

<h3>Full Stack Developer</h3>
<p style={{marginLeft:"1rem"}}><b>Type : </b>Full Time</p>

<div class="hire-resume-h-list">

<div class="hire-resume-dsize-3">
<div>
<b>Start Date : </b>24-07-2021
</div>
</div>


<div class="hire-resume-dsize-3">
<div>
<b>End Date : </b>23-06-2023
</div>
</div>

<div class="hire-resume-dsize-3">
<div>
<b>Currently Working : </b><span style={{color:"#cc2b49"}}>No</span>
</div>
</div>



</div>

<div style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Work and Responsibilities : </b>Full stack developers build and maintain web applications from the ground up, mastering front-end and back-end technologies. 
</div>
</div>
 
 <div class="hire-resume-h-list">

<div  class="hire-resume-dsize-2" style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Link : </b>https://www.google.com/digitalmarketing/certificate/JJJJAJAAHHA999.pkm
</div>
</div>

<div  class="hire-resume-dsize-2" style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Reference : </b><span style={{color:"#2bcc66"}}>Yes</span>
</div>
</div>

</div>


<div style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Skills Utilized : </b>
</div>
</div>






<div class="hire-list-card-skillbar">
        <div class="hire-card-skill-badge" style={{backgroundColor:"#2bcc66",color:"black"}}>
        <span>MySql</span>
        </div>  
        <div class="hire-card-skill-badge" style={{backgroundColor:"#2bcc66",color:"black"}}>
        <span>Web Development</span>
        </div>  
        <div class="hire-card-skill-badge" style={{backgroundColor:"#2bcc66",color:"black"}}>
        <span>React Js</span>
        </div>  
        <div class="hire-card-skill-badge" style={{backgroundColor:"#2bcc66",color:"black"}}>
        <span>Laravel</span>
        </div>  
        <div class="hire-card-skill-badge" style={{backgroundColor:"#2bcc66",color:"black"}}>
        <span>HTML/CSS</span>
        </div>  
        <div class="hire-card-skill-badge" style={{backgroundColor:"#2bcc66",color:"black"}}>
        <span>React Js</span>
        </div>  

       
        </div>

        <div id="line-3"></div>
        <h3 style={{marginLeft:"1rem"}}>Reference Details</h3>

<div class="hire-resume-h-list">

<div  class="hire-resume-dsize-2" style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Name : </b>Shobbhit Roy Dutta
</div>
</div>

<div  class="hire-resume-dsize-2" style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Company : </b>St. Proviate Limited for Regular Supplies, North Block, Sector-7 New Delhi, Delhi (India) PinCode- 360001
</div>
</div>

</div>


<div class="hire-resume-h-list">

<div class="hire-resume-dsize-3">
<div class="hire-resume-ict-box">
<div class="hire-resume-ict-icon"><img src="https://cdn-icons-png.flaticon.com/128/6806/6806987.png" class="hire-resume-ict-icon" /></div>
<div class="hire-resume-ict-text">sshobbhitroy893@gmail.com</div>
</div>
</div>

<div class="hire-resume-dsize-3">
<div class="hire-resume-ict-box">
<div class="hire-resume-ict-icon"><img src="https://cdn-icons-png.flaticon.com/128/10261/10261328.png" class="hire-resume-ict-icon" /></div>
<div class="hire-resume-ict-text">+91 87451 98787</div>
</div>
</div>

<div class="hire-resume-dsize-3">
<div class="hire-resume-ict-box">
<div class="hire-resume-ict-icon"><img src="https://cdn-icons-png.flaticon.com/128/9750/9750948.png" class="hire-resume-ict-icon" /></div>
<div class="hire-resume-ict-text">Team Manager</div>
</div>
</div>

</div>

</div>
{/* Work Experience Card 3 End */}


{/* WORK EXPERIENCE SECTION END */}






<h3 style={{textAlign:"left"}}>F.) PROJECTS</h3>

{/* PROJECTS SECTION START */}

{/* Project Card 1 Start */}
<div class="hire-resume-card" style={{borderColor:"#0a487d",marginTop:"0.2rem"}}>
<p style={{color:"#a3aaab"}}>#1</p>

<h3>Title : MARKET RESEARCH ON SERVICES OFFERING BY OLA</h3>
<p style={{marginLeft:"1rem"}}><b>Type : </b>Full Time</p>

<div class="hire-resume-h-list">

<div class="hire-resume-dsize-3">
<div>
<b>Start Date : </b>24-07-2021
</div>
</div>


<div class="hire-resume-dsize-3">
<div>
<b>End Date : </b>23-06-2023
</div>
</div>

<div class="hire-resume-dsize-3">
<div>
<b>Currently Working : </b><span style={{color:"#cc2b49"}}>No</span>
</div>
</div>



</div>


<div class="hire-resume-h-list">

<div class="hire-resume-dsize-3">
<div>
<b>Industry : </b>Marketing
</div>
</div>


<div class="hire-resume-dsize-3">
<div>
<b>Sector : </b>Mobility
</div>
</div>

<div class="hire-resume-dsize-3">
<div>
<b>Category : </b>Consumer
</div>
</div>


</div>


<div class="hire-resume-h-list">

<div class="hire-resume-dsize-2">
<div>
<b>Organization Name : </b>ABC Institute, North Canta
</div>
</div>


<div class="hire-resume-dsize-2">
<div>
<b>Role : </b>Researcher's Assistant
</div>
</div>


</div>


<div style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Work and Responsibilities : </b>Full stack developers build and maintain web applications from the ground up, mastering front-end and back-end technologies. 
</div>
</div>
 
 <div class="hire-resume-h-list">

<div  class="hire-resume-dsize-2" style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Link : </b>https://www.google.com/digitalmarketing/certificate/JJJJAJAAHHA999.pkm
</div>
</div>

<div  class="hire-resume-dsize-2" style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Reference : </b><span style={{color:"#2bcc66"}}>Yes</span>
</div>
</div>

</div>


<div style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Skills Utilized : </b>
</div>
</div>






<div class="hire-list-card-skillbar">
        <div class="hire-card-skill-badge" style={{backgroundColor:"#2bcc66",color:"black"}}>
        <span>MySql</span>
        </div>  
        <div class="hire-card-skill-badge" style={{backgroundColor:"#2bcc66",color:"black"}}>
        <span>Web Development</span>
        </div>  
        <div class="hire-card-skill-badge" style={{backgroundColor:"#2bcc66",color:"black"}}>
        <span>React Js</span>
        </div>  
        <div class="hire-card-skill-badge" style={{backgroundColor:"#2bcc66",color:"black"}}>
        <span>Laravel</span>
        </div>  
        <div class="hire-card-skill-badge" style={{backgroundColor:"#2bcc66",color:"black"}}>
        <span>HTML/CSS</span>
        </div>  
        <div class="hire-card-skill-badge" style={{backgroundColor:"#2bcc66",color:"black"}}>
        <span>React Js</span>
        </div>  

       
        </div>

        <div id="line-3"></div>
        <h3 style={{marginLeft:"1rem"}}>Reference Details</h3>

<div class="hire-resume-h-list">

<div  class="hire-resume-dsize-2" style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Name : </b>Shobbhit Roy Dutta
</div>
</div>

<div  class="hire-resume-dsize-2" style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Company : </b>St. Proviate Limited for Regular Supplies, North Block, Sector-7 New Delhi, Delhi (India) PinCode- 360001
</div>
</div>

</div>


<div class="hire-resume-h-list">

<div class="hire-resume-dsize-3">
<div class="hire-resume-ict-box">
<div class="hire-resume-ict-icon"><img src="https://cdn-icons-png.flaticon.com/128/6806/6806987.png" class="hire-resume-ict-icon" /></div>
<div class="hire-resume-ict-text">sshobbhitroy893@gmail.com</div>
</div>
</div>

<div class="hire-resume-dsize-3">
<div class="hire-resume-ict-box">
<div class="hire-resume-ict-icon"><img src="https://cdn-icons-png.flaticon.com/128/10261/10261328.png" class="hire-resume-ict-icon" /></div>
<div class="hire-resume-ict-text">+91 87451 98787</div>
</div>
</div>

<div class="hire-resume-dsize-3">
<div class="hire-resume-ict-box">
<div class="hire-resume-ict-icon"><img src="https://cdn-icons-png.flaticon.com/128/9750/9750948.png" class="hire-resume-ict-icon" /></div>
<div class="hire-resume-ict-text">Team Manager</div>
</div>
</div>

</div>

</div>
{/* Projects Card 1 End */}




{/* Project Card 2 Start */}
<div class="hire-resume-card" style={{borderColor:"#0a487d",marginTop:"0.2rem"}}>
<p style={{color:"#a3aaab"}}>#2</p>

<h3>Title : MARKET RESEARCH ON SERVICES OFFERING BY OLA</h3>
<p style={{marginLeft:"1rem"}}><b>Type : </b>Full Time</p>

<div class="hire-resume-h-list">

<div class="hire-resume-dsize-3">
<div>
<b>Start Date : </b>24-07-2021
</div>
</div>


<div class="hire-resume-dsize-3">
<div>
<b>End Date : </b>23-06-2023
</div>
</div>

<div class="hire-resume-dsize-3">
<div>
<b>Currently Working : </b><span style={{color:"#cc2b49"}}>No</span>
</div>
</div>



</div>


<div class="hire-resume-h-list">

<div class="hire-resume-dsize-3">
<div>
<b>Industry : </b>Marketing
</div>
</div>


<div class="hire-resume-dsize-3">
<div>
<b>Sector : </b>Mobility
</div>
</div>

<div class="hire-resume-dsize-3">
<div>
<b>Category : </b>Consumer
</div>
</div>


</div>


<div class="hire-resume-h-list">

<div class="hire-resume-dsize-2">
<div>
<b>Organization Name : </b>ABC Institute, North Canta
</div>
</div>


<div class="hire-resume-dsize-2">
<div>
<b>Role : </b>Researcher's Assistant
</div>
</div>


</div>


<div style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Work and Responsibilities : </b>Full stack developers build and maintain web applications from the ground up, mastering front-end and back-end technologies. 
</div>
</div>
 
 <div class="hire-resume-h-list">

<div  class="hire-resume-dsize-2" style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Link : </b>https://www.google.com/digitalmarketing/certificate/JJJJAJAAHHA999.pkm
</div>
</div>

<div  class="hire-resume-dsize-2" style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Reference : </b><span style={{color:"#2bcc66"}}>Yes</span>
</div>
</div>

</div>


<div style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Skills Utilized : </b>
</div>
</div>






<div class="hire-list-card-skillbar">
        <div class="hire-card-skill-badge" style={{backgroundColor:"#2bcc66",color:"black"}}>
        <span>MySql</span>
        </div>  
        <div class="hire-card-skill-badge" style={{backgroundColor:"#2bcc66",color:"black"}}>
        <span>Web Development</span>
        </div>  
        <div class="hire-card-skill-badge" style={{backgroundColor:"#2bcc66",color:"black"}}>
        <span>React Js</span>
        </div>  
        <div class="hire-card-skill-badge" style={{backgroundColor:"#2bcc66",color:"black"}}>
        <span>Laravel</span>
        </div>  
        <div class="hire-card-skill-badge" style={{backgroundColor:"#2bcc66",color:"black"}}>
        <span>HTML/CSS</span>
        </div>  
        <div class="hire-card-skill-badge" style={{backgroundColor:"#2bcc66",color:"black"}}>
        <span>React Js</span>
        </div>  

       
        </div>

        <div id="line-3"></div>
        <h3 style={{marginLeft:"1rem"}}>Reference Details</h3>

<div class="hire-resume-h-list">

<div  class="hire-resume-dsize-2" style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Name : </b>Shobbhit Roy Dutta
</div>
</div>

<div  class="hire-resume-dsize-2" style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Company : </b>St. Proviate Limited for Regular Supplies, North Block, Sector-7 New Delhi, Delhi (India) PinCode- 360001
</div>
</div>

</div>


<div class="hire-resume-h-list">

<div class="hire-resume-dsize-3">
<div class="hire-resume-ict-box">
<div class="hire-resume-ict-icon"><img src="https://cdn-icons-png.flaticon.com/128/6806/6806987.png" class="hire-resume-ict-icon" /></div>
<div class="hire-resume-ict-text">sshobbhitroy893@gmail.com</div>
</div>
</div>

<div class="hire-resume-dsize-3">
<div class="hire-resume-ict-box">
<div class="hire-resume-ict-icon"><img src="https://cdn-icons-png.flaticon.com/128/10261/10261328.png" class="hire-resume-ict-icon" /></div>
<div class="hire-resume-ict-text">+91 87451 98787</div>
</div>
</div>

<div class="hire-resume-dsize-3">
<div class="hire-resume-ict-box">
<div class="hire-resume-ict-icon"><img src="https://cdn-icons-png.flaticon.com/128/9750/9750948.png" class="hire-resume-ict-icon" /></div>
<div class="hire-resume-ict-text">Team Manager</div>
</div>
</div>

</div>

</div>
{/* Projects Card 2 End */}




{/* Project Card 3 Start */}
<div class="hire-resume-card" style={{borderColor:"#0a487d",marginTop:"0.2rem"}}>
<p style={{color:"#a3aaab"}}>#3</p>

<h3>Title : MARKET RESEARCH ON SERVICES OFFERING BY OLA</h3>
<p style={{marginLeft:"1rem"}}><b>Type : </b>Full Time</p>

<div class="hire-resume-h-list">

<div class="hire-resume-dsize-3">
<div>
<b>Start Date : </b>24-07-2021
</div>
</div>


<div class="hire-resume-dsize-3">
<div>
<b>End Date : </b>23-06-2023
</div>
</div>

<div class="hire-resume-dsize-3">
<div>
<b>Currently Working : </b><span style={{color:"#cc2b49"}}>No</span>
</div>
</div>



</div>


<div class="hire-resume-h-list">

<div class="hire-resume-dsize-3">
<div>
<b>Industry : </b>Marketing
</div>
</div>


<div class="hire-resume-dsize-3">
<div>
<b>Sector : </b>Mobility
</div>
</div>

<div class="hire-resume-dsize-3">
<div>
<b>Category : </b>Consumer
</div>
</div>


</div>


<div class="hire-resume-h-list">

<div class="hire-resume-dsize-2">
<div>
<b>Organization Name : </b>ABC Institute, North Canta
</div>
</div>


<div class="hire-resume-dsize-2">
<div>
<b>Role : </b>Researcher's Assistant
</div>
</div>


</div>


<div style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Work and Responsibilities : </b>Full stack developers build and maintain web applications from the ground up, mastering front-end and back-end technologies. 
</div>
</div>
 
 <div class="hire-resume-h-list">

<div  class="hire-resume-dsize-2" style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Link : </b>https://www.google.com/digitalmarketing/certificate/JJJJAJAAHHA999.pkm
</div>
</div>

<div  class="hire-resume-dsize-2" style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Reference : </b><span style={{color:"#2bcc66"}}>Yes</span>
</div>
</div>

</div>


<div style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Skills Utilized : </b>
</div>
</div>






<div class="hire-list-card-skillbar">
        <div class="hire-card-skill-badge" style={{backgroundColor:"#2bcc66",color:"black"}}>
        <span>MySql</span>
        </div>  
        <div class="hire-card-skill-badge" style={{backgroundColor:"#2bcc66",color:"black"}}>
        <span>Web Development</span>
        </div>  
        <div class="hire-card-skill-badge" style={{backgroundColor:"#2bcc66",color:"black"}}>
        <span>React Js</span>
        </div>  
        <div class="hire-card-skill-badge" style={{backgroundColor:"#2bcc66",color:"black"}}>
        <span>Laravel</span>
        </div>  
        <div class="hire-card-skill-badge" style={{backgroundColor:"#2bcc66",color:"black"}}>
        <span>HTML/CSS</span>
        </div>  
        <div class="hire-card-skill-badge" style={{backgroundColor:"#2bcc66",color:"black"}}>
        <span>React Js</span>
        </div>  

       
        </div>

        <div id="line-3"></div>
        <h3 style={{marginLeft:"1rem"}}>Reference Details</h3>

<div class="hire-resume-h-list">

<div  class="hire-resume-dsize-2" style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Name : </b>Shobbhit Roy Dutta
</div>
</div>

<div  class="hire-resume-dsize-2" style={{marginLeft:"1rem",marginTop:"1rem"}}>
<div>
<b>Company : </b>St. Proviate Limited for Regular Supplies, North Block, Sector-7 New Delhi, Delhi (India) PinCode- 360001
</div>
</div>

</div>


<div class="hire-resume-h-list">

<div class="hire-resume-dsize-3">
<div class="hire-resume-ict-box">
<div class="hire-resume-ict-icon"><img src="https://cdn-icons-png.flaticon.com/128/6806/6806987.png" class="hire-resume-ict-icon" /></div>
<div class="hire-resume-ict-text">sshobbhitroy893@gmail.com</div>
</div>
</div>

<div class="hire-resume-dsize-3">
<div class="hire-resume-ict-box">
<div class="hire-resume-ict-icon"><img src="https://cdn-icons-png.flaticon.com/128/10261/10261328.png" class="hire-resume-ict-icon" /></div>
<div class="hire-resume-ict-text">+91 87451 98787</div>
</div>
</div>

<div class="hire-resume-dsize-3">
<div class="hire-resume-ict-box">
<div class="hire-resume-ict-icon"><img src="https://cdn-icons-png.flaticon.com/128/9750/9750948.png" class="hire-resume-ict-icon" /></div>
<div class="hire-resume-ict-text">Team Manager</div>
</div>
</div>

</div>

</div>
{/* Projects Card 3 End */}


{/* PROJECTS SECTION END */}





<h3 style={{textAlign:"left"}}>G.) OTHER LINKS</h3>

{/* OTHER LINKS SECTION START */}
<div class="hire-resume-card" style={{borderColor:"#cc552b"}}>

<div class="hire-resume-h-list">

<div class="hire-resume-dsize-3">
<div>
<b>LinkedIn : </b>https:/linkedIn.com/kksksksskk
</div>
</div>


<div class="hire-resume-dsize-3">
<div>
<b>GitHub : </b>https:/github.com/kksksksskk
</div>
</div>

<div class="hire-resume-dsize-3">
<div>
<b>Instagram : </b>https:/Instagram.com/kksksksskk
</div>
</div>



</div>


</div>
{/* OTHER LINKS SECTION END */}


<h3 style={{textAlign:"left"}}>H.) ACHIEVEMENTS</h3>
{/* ACHIEVEMENTS SECTION START */}


{/* Achievement Card 1 Start */}
<div class="hire-resume-card" style={{borderColor:"#098b65",marginTop:"0.2rem"}}>
<p style={{color:"#a3aaab"}}>#1</p>

<h3>Title :  BEST PERFORMER OF THE MONTH</h3>
<p style={{marginLeft:"1rem"}}><b>Received : </b>23-07-2022</p>

<div class="hire-resume-dsize-3">
<div>
<b>Details : </b>https:/github.com/kksksksskk
</div>
</div>

<div class="hire-resume-dsize-3">
<div>
<b>Link : </b>https:/www..thisislink.com/kksksksskk/achievement
</div>
</div>



</div>
{/* Achievement Card 1 End */}


{/* Achievement Card 2 Start */}
<div class="hire-resume-card" style={{borderColor:"#098b65",marginTop:"0.2rem"}}>
<p style={{color:"#a3aaab"}}>#2</p>

<h3>Title :  BEST PERFORMER OF THE MONTH</h3>
<p style={{marginLeft:"1rem"}}><b>Received : </b>23-07-2022</p>

<div class="hire-resume-dsize-3">
<div>
<b>Details : </b>https:/github.com/kksksksskk
</div>
</div>

<div class="hire-resume-dsize-3">
<div>
<b>Link : </b>https:/www..thisislink.com/kksksksskk/achievement
</div>
</div>



</div>
{/* Achievement Card 2 End */}



{/* Achievement Card 3 Start */}
<div class="hire-resume-card" style={{borderColor:"#098b65",marginTop:"0.2rem"}}>
<p style={{color:"#a3aaab"}}>#3</p>

<h3>Title :  BEST PERFORMER OF THE MONTH</h3>
<p style={{marginLeft:"1rem"}}><b>Received : </b>23-07-2022</p>

<div class="hire-resume-dsize-3">
<div>
<b>Details : </b>https:/github.com/kksksksskk
</div>
</div>

<div class="hire-resume-dsize-3">
<div>
<b>Link : </b>https:/www..thisislink.com/kksksksskk/achievement
</div>
</div>



</div>
{/* Achievement Card 3 End */}

{/* ACHIEVEMENTS SECTION END */}


            </div>
            {/* RESUME SECTION END */}
    
        </div>
        {/* RESUME CARD END */}


        </div>
        {/* FORM CONTAINER END */}

        </div>
        {/* MAIN SECTION END */}

        </div>

        </>
    );




}


export default Employerviewresume;