import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios';
//import Navbar from '../../navbar.js';
import Navbar from '../Extracomponents/navbar.js';
import Sidebar from '../Extracomponents/sidebar.js';
import moment from 'moment/moment';
import { hasUnreliableEmptyValue } from '@testing-library/user-event/dist/utils';



/*import Amanbg from './amanbg';*/


function Create_quotation(){


  
  
   // Setting Values fom Session Storage
 const logstatus=sessionStorage.getItem("userloginstatus00");//loginstatus
 const loguser=sessionStorage.getItem("userusername00");//username
 const logtype=sessionStorage.getItem("userlogintype00");//logintype
 const logname=sessionStorage.getItem("userloginname00");//loginname
 const loguserid=sessionStorage.getItem("userloginadminid00");//loginadminid
 const logteamid=sessionStorage.getItem("userteamid00");//loginteamid

 
 //Variables
 const[data,setData] = useState([]);//SetData Variable
 const[data1,setData1] = useState([]);//SetData Variable
 //Error Variable
 const[errorvar,seterrorvar] = useState();
 //Error Variable
 const[errorvar1,seterrorvar1] = useState();


 
      

    //Sessions Not Set
  {/*} if(((logstatus=="false")||(logstatus==null))&&(loguser==null)&&(logteamid==null)){
 
      sessionStorage.removeItem("userusername00");
      sessionStorage.removeItem("userloginstatus00");
      sessionStorage.removeItem("userlogintype00");
      sessionStorage.removeItem("userloginname00");
      sessionStorage.removeItem("userloginadminid00");
      sessionStorage.removeItem("userloginteamid00");
      alert("Session Expired! Login Again.");
      window.location.replace("/");
    
    }
    //Sessions Set
  else{
  */}
     
      

  //Use Effect, Data Load
  useEffect(()=>{

    getData();

},[])


function getData(){

}


/*  CREATE NEW QUOTATION  */

/* Quotation Details */
  //quotation_no
  const[quotation_no,setquotation_no]=useState();const updatequotation_no = (event) => { setquotation_no(event.target.value);};
  //date
  const[date,setdate]=useState();const updatedate = (event) => { setdate(event.target.value);};

  /* Client Details */
  //client_id
  const[client_id,setclient_id]=useState();const updateclient_id = (event) => { setclient_id(event.target.value);};
  //client_name
  const[client_name,setclient_name]=useState();const updateclient_name = (event) => { setclient_name(event.target.value);};
  //client_contact
  const[client_contact,setclient_contact]=useState();const updateclient_contact = (event) => { setclient_contact(event.target.value);};
  //client_email
  const[client_email,setclient_email]=useState();const updateclient_email = (event) => { setclient_email(event.target.value);};
  //client_city
  const[client_city,setclient_city]=useState();const updateclient_city = (event) => { setclient_city(event.target.value);};
  //client_state
  const[client_state,setclient_state]=useState();const updateclient_state = (event) => { setclient_state(event.target.value);};
  //client_gstin
  const[client_gstin,setclient_gstin]=useState();const updateclient_gstin = (event) => { setclient_gstin(event.target.value);};
  //client_address
  const[client_address,setclient_address]=useState();const updateclient_address = (event) => { setclient_address(event.target.value);};

 /* Payment Details */
  //payment_mode
  const[payment_mode,setpayment_mode]=useState();const updatepayment_mode = (event) => { setpayment_mode(event.target.value);};
  //payment_payee
  const[payment_payee,setpayment_payee]=useState();const updatepayment_payee = (event) => { setpayment_payee(event.target.value);};
  //payment_trno
  const[payment_trno,setpayment_trno]=useState();const updatepayment_trno = (event) => { setpayment_trno(event.target.value);};
  //payment_accountno
  const[payment_accountno,setpayment_accountno]=useState();const updatepayment_accountno = (event) => { setpayment_accountno(event.target.value);};
  //payment_date
  const[payment_date,setpayment_date]=useState();const updatepayment_date = (event) => { setpayment_date(event.target.value);};
  //payment_time
  const[payment_time,setpayment_time]=useState();const updatepayment_time = (event) => { setpayment_time(event.target.value);};
  //payment_remarks
  const[payment_remarks,setpayment_remarks]=useState();const updatepayment_remarks = (event) => { setpayment_remarks(event.target.value);};


/* Order Items */
/* Item 1 */
//heading1
const[heading1,setheading1]=useState();const updateheading1 = (event) => { setheading1(event.target.value);};
//quantity1
const[quantity1,setquantity1]=useState();const updatequantity1 = (event) => { setquantity1(event.target.value); calculate();};
//details1
const[details1,setdetails1]=useState();const updatedetails1 = (event) => { setdetails1(event.target.value);};
//rate1
const[rate1,setrate1]=useState();const updaterate1 = (event) => { setrate1(event.target.value); calculate();};
//amount1
const[amount1,setamount1]=useState();const updateamount1 = (event) => { setamount1(event.target.value); calculate();} ;

/* Item 2 */
//heading2
const[heading2,setheading2]=useState();const updateheading2 = (event) => { setheading2(event.target.value);};
//quantity2
const[quantity2,setquantity2]=useState();const updatequantity2 = (event) => { setquantity2(event.target.value); calculate();};
//details2
const[details2,setdetails2]=useState();const updatedetails2 = (event) => { setdetails2(event.target.value);};
//rate2
const[rate2,setrate2]=useState();const updaterate2 = (event) => { setrate2(event.target.value); calculate();};
//amount2
const[amount2,setamount2]=useState();const updateamount2 = (event) => { setamount2(event.target.value); calculate();};

/* Item 3 */
//heading3
const[heading3,setheading3]=useState();const updateheading3 = (event) => { setheading3(event.target.value);};
//quantity3
const[quantity3,setquantity3]=useState();const updatequantity3 = (event) => { setquantity3(event.target.value); calculate();};
//details3
const[details3,setdetails3]=useState();const updatedetails3 = (event) => { setdetails3(event.target.value);};
//rate3
const[rate3,setrate3]=useState();const updaterate3 = (event) => { setrate3(event.target.value); calculate();};
//amount3
const[amount3,setamount3]=useState();const updateamount3 = (event) => { setamount3(event.target.value); calculate();};

/* Item 4 */
//heading4
const[heading4,setheading4]=useState();const updateheading4 = (event) => { setheading4(event.target.value);};
//quantity4
const[quantity4,setquantity4]=useState();const updatequantity4 = (event) => { setquantity4(event.target.value); calculate();};
//details4
const[details4,setdetails4]=useState();const updatedetails4 = (event) => { setdetails4(event.target.value);};
//rate4
const[rate4,setrate4]=useState();const updaterate4 = (event) => { setrate4(event.target.value); calculate();};
//amount4
const[amount4,setamount4]=useState();const updateamount4 = (event) => { setamount4(event.target.value); calculate();};

/* Item 5 */
//heading5
const[heading5,setheading5]=useState();const updateheading5 = (event) => { setheading5(event.target.value);};
//quantity5
const[quantity5,setquantity5]=useState();const updatequantity5 = (event) => { setquantity5(event.target.value); calculate();};
//details5
const[details5,setdetails5]=useState();const updatedetails5 = (event) => { setdetails5(event.target.value);};
//rate5
const[rate5,setrate5]=useState();const updaterate5 = (event) => { setrate5(event.target.value); calculate();};
//amount5
const[amount5,setamount5]=useState();const updateamount5 = (event) => { setamount5(event.target.value); calculate();};

/* Item 6 */
//heading6
const[heading6,setheading6]=useState();const updateheading6 = (event) => { setheading6(event.target.value);};
//quantity6
const[quantity6,setquantity6]=useState();const updatequantity6 = (event) => { setquantity6(event.target.value); calculate();};
//details6
const[details6,setdetails6]=useState();const updatedetails6 = (event) => { setdetails6(event.target.value);};
//rate6
const[rate6,setrate6]=useState();const updaterate6 = (event) => { setrate6(event.target.value); calculate();};
//amount6
const[amount6,setamount6]=useState();const updateamount6 = (event) => { setamount6(event.target.value); calculate();};

/* Item 7 */
//heading7
const[heading7,setheading7]=useState();const updateheading7 = (event) => { setheading7(event.target.value); };
//quantity7
const[quantity7,setquantity7]=useState();const updatequantity7 = (event) => { setquantity7(event.target.value); calculate();};
//details7
const[details7,setdetails7]=useState();const updatedetails7 = (event) => { setdetails7(event.target.value); calculate();};
//rate7
const[rate7,setrate7]=useState();const updaterate7 = (event) => { setrate7(event.target.value); calculate();};
//amount7
const[amount7,setamount7]=useState();const updateamount7 = (event) => { setamount7(event.target.value); calculate();};


/* Total */
//total_quantity
const[total_quantity,settotal_quantity]=useState();const updatetotal_quantity = (event) => { settotal_quantity(event.target.value); calculate();};
//total
const[total,settotal]=useState();const updatetotal = (event) => { settotal(event.target.value); calculate();};
//subtotal
const[subtotal,setsubtotal]=useState();const updatesubtotal = (event) => { setsubtotal(event.target.value); calculate();};
//discount_per
const[discount_per,setdiscount_per]=useState();const updatediscount_per = (event) => { setdiscount_per(event.target.value); calculate();};
//discount_amt
const[discount_amt,setdiscount_amt]=useState();const updatediscount_amt = (event) => { setdiscount_amt(event.target.value); calculate();};
//gst_per
const[gst_per,setgst_per]=useState();const updategst_per = (event) => { setgst_per(event.target.value); calculate();};
//gst_amt
const[gst_amt,setgst_amt]=useState();const updategst_amt = (event) => { setgst_amt(event.target.value); calculate();};
//other_exp
const[other_exp,setother_exp]=useState();const updateother_exp = (event) => { setother_exp(event.target.value); calculate();};
//other_ded
const[other_ded,setother_ded]=useState();const updateother_ded = (event) => { setother_ded(event.target.value); calculate();};
//nettotal
const[nettotal,setnettotal]=useState();const updatenettotal = (event) => { setnettotal(event.target.value); calculate();};
//amt_word
const[amt_word,setamt_word]=useState();const updateamt_word = (event) => { setamt_word(event.target.value);};


//ERROR VARIABLES
const[error1,seterror1]=useState();const[error2,seterror2]=useState();const[error3,seterror3]=useState();const[error4,seterror4]=useState();const[error5,seterror5]=useState();
const[error6,seterror6]=useState();const[error7,seterror7]=useState();const[error8,seterror8]=useState();const[error9,seterror9]=useState();const[error10,seterror10]=useState();
const[error11,seterror11]=useState();const[error12,seterror12]=useState();const[error13,seterror13]=useState();const[error14,seterror14]=useState();const[error15,seterror15]=useState();
const[error16,seterror16]=useState();const[error17,seterror17]=useState();const[error18,seterror18]=useState();const[error19,seterror19]=useState();const[error20,seterror20]=useState();
const[error21,seterror21]=useState();const[error22,seterror22]=useState();const[error23,seterror23]=useState();const[error24,seterror24]=useState();const[error25,seterror25]=useState();
const[error26,seterror26]=useState();const[error27,seterror27]=useState();const[error28,seterror28]=useState();const[error29,seterror29]=useState();const[error30,seterror30]=useState();
const[error31,seterror31]=useState();const[error32,seterror32]=useState();const[error33,seterror33]=useState();const[error34,seterror34]=useState();const[error35,seterror35]=useState();
const[error36,seterror36]=useState();const[error37,seterror37]=useState();const[error38,seterror38]=useState();const[error39,seterror39]=useState();const[error40,seterror40]=useState();
const[error41,seterror41]=useState();const[error42,seterror42]=useState();const[error43,seterror43]=useState();const[error44,seterror44]=useState();const[error45,seterror45]=useState();
const[error46,seterror46]=useState();const[error47,seterror47]=useState();const[error48,seterror48]=useState();const[error49,seterror49]=useState();const[error50,seterror50]=useState();
const[error51,seterror51]=useState();const[error52,seterror52]=useState();const[error53,seterror53]=useState();const[error54,seterror54]=useState();const[error55,seterror55]=useState();
const[error56,seterror56]=useState();const[error57,seterror57]=useState();const[error58,seterror58]=useState();const[error59,seterror59]=useState();const[error60,seterror60]=useState();
const[error61,seterror61]=useState();const[error62,seterror62]=useState();const[error63,seterror63]=useState();const[error64,seterror64]=useState();const[error65,seterror65]=useState();
const[error66,seterror66]=useState();const[error67,seterror67]=useState();const[error68,seterror68]=useState();const[error69,seterror69]=useState();const[error70,seterror70]=useState();



/***********  CALCULATE FUNCTION START ***********/
function calculate(){

  //fetching values for calculation
let amount1=document.getElementById("amount1").value; if((amount1=="")||(amount1==null)){ amount1=0; }; amount1=parseFloat(amount1); let rate1=document.getElementById("rate1").value; if((rate1=="")||(rate1==null)){ rate1=0; }; rate1=parseFloat(rate1); let quantity1=document.getElementById("quantity1").value; if((quantity1=="")||(quantity1==null)){ quantity1=0; };quantity1=parseFloat(quantity1);
let amount2=document.getElementById("amount2").value; if((amount2=="")||(amount2==null)){ amount2=0; }; amount2=parseFloat(amount2); let rate2=document.getElementById("rate2").value; if((rate2=="")||(rate2==null)){ rate2=0; }; rate2=parseFloat(rate2); let quantity2=document.getElementById("quantity2").value; if((quantity2=="")||(quantity2==null)){ quantity2=0; };quantity2=parseFloat(quantity2);
let amount3=document.getElementById("amount3").value; if((amount3=="")||(amount3==null)){ amount3=0; }; amount3=parseFloat(amount3); let rate3=document.getElementById("rate3").value; if((rate3=="")||(rate3==null)){ rate3=0; }; rate3=parseFloat(rate3); let quantity3=document.getElementById("quantity3").value; if((quantity3=="")||(quantity3==null)){ quantity3=0; };quantity3=parseFloat(quantity3);
let amount4=document.getElementById("amount4").value; if((amount4=="")||(amount4==null)){ amount4=0; }; amount4=parseFloat(amount4); let rate4=document.getElementById("rate4").value; if((rate4=="")||(rate4==null)){ rate4=0; }; rate4=parseFloat(rate4); let quantity4=document.getElementById("quantity4").value; if((quantity4=="")||(quantity4==null)){ quantity4=0; };quantity4=parseFloat(quantity4);
let amount5=document.getElementById("amount5").value; if((amount5=="")||(amount5==null)){ amount5=0; }; amount5=parseFloat(amount5); let rate5=document.getElementById("rate5").value; if((rate5=="")||(rate5==null)){ rate5=0; }; rate5=parseFloat(rate5); let quantity5=document.getElementById("quantity5").value; if((quantity5=="")||(quantity5==null)){ quantity5=0; };quantity5=parseFloat(quantity5);
let amount6=document.getElementById("amount6").value; if((amount6=="")||(amount6==null)){ amount6=0; }; amount6=parseFloat(amount6); let rate6=document.getElementById("rate6").value; if((rate6=="")||(rate6==null)){ rate6=0; }; rate6=parseFloat(rate6); let quantity6=document.getElementById("quantity6").value; if((quantity6=="")||(quantity6==null)){ quantity6=0; };quantity6=parseFloat(quantity6);
let amount7=document.getElementById("amount7").value; if((amount7=="")||(amount7==null)){ amount7=0; }; amount7=parseFloat(amount7); let rate7=document.getElementById("rate7").value; if((rate7=="")||(rate7==null)){ rate7=0; }; rate7=parseFloat(rate7); let quantity7=document.getElementById("quantity7").value; if((quantity7=="")||(quantity7==null)){ quantity7=0; };quantity7=parseFloat(quantity7);
let total=document.getElementById("total").value; if((total=="")||(total==null)){ total=0; }; total=parseFloat(total); let total_quantity=document.getElementById("total_quantity").value; if((total_quantity=="")||(total_quantity==null)){ total_quantity=0; }; total_quantity=parseFloat(total_quantity); let subtotal=document.getElementById("subtotal").value; if((subtotal=="")||(subtotal==null)){ subtotal=0; }; subtotal=parseFloat(subtotal);
let discount_per=document.getElementById("discount_per").value; if((discount_per=="")||(discount_per==null)){ discount_per=0; }; discount_per=parseFloat(discount_per); let discount_amt=document.getElementById("discount_amt").value; if((discount_amt=="")||(discount_amt==null)){ discount_amt=0; }; discount_amt=parseFloat(discount_amt);
let gst_per=document.getElementById("gst_per").value; if((gst_per=="")||(gst_per==null)){ gst_per=0; }; gst_per=parseFloat(gst_per); let gst_amt=document.getElementById("gst_amt").value; if((gst_amt=="")||(gst_amt==null)){ gst_amt=0; }; gst_amt=parseFloat(gst_amt);
let nettotal=document.getElementById("nettotal").value; if((nettotal=="")||(nettotal==null)){ nettotal=0; }; nettotal=parseFloat(nettotal);
let other_ded=document.getElementById("other_ded").value; if((other_ded=="")||(other_ded==null)){ other_ded=0; }; other_ded=parseFloat(other_ded);
let other_exp=document.getElementById("other_exp").value; if((other_exp=="")||(other_exp==null)){ other_exp=0; }; other_exp=parseFloat(other_exp);

// Individual Items Total 
  amount1 = parseFloat(quantity1*rate1); setamount1(amount1.toFixed(2));
  amount2 = parseFloat(quantity2*rate2); setamount2(amount2.toFixed(2));
  amount3 = parseFloat(quantity3*rate3); setamount3(amount3.toFixed(2));
  amount4 = parseFloat(quantity4*rate4); setamount4(amount4.toFixed(2));
  amount5 = parseFloat(quantity5*rate5); setamount5(amount5.toFixed(2));
  amount6 = parseFloat(quantity6*rate6); setamount6(amount6.toFixed(2));
  amount7 = parseFloat(quantity7*rate7); setamount7(amount7.toFixed(2));

  // Setting Total Values 
total_quantity=parseFloat(quantity1+quantity2+quantity3+quantity4+quantity5+quantity6+quantity7);settotal_quantity(total_quantity.toFixed(2));
total=parseFloat(amount1+amount2+amount3+amount4+amount5+amount6+amount7);settotal(total.toFixed(2));
subtotal=parseFloat(total);setsubtotal(subtotal.toFixed(2));
if(discount_per>0){discount_amt = parseFloat(total*(discount_per/100));}setdiscount_amt(discount_amt.toFixed(2));
const bgst = parseFloat(total-discount_amt);
if(gst_per>0){gst_amt = parseFloat(bgst*(gst_per/100));setgst_amt(gst_amt.toFixed(2));}
nettotal=bgst+gst_amt+other_exp-other_ded; nettotal=parseFloat(nettotal); setnettotal(nettotal.toFixed(2));

}

/***********  CALCULATE FUNCTION END ***********/

/************ CREATE QUOTATION FUNCTION  ************/
function create_quotation(){


//Checking if user loggedin
if((logstatus=="true")&&(loguser!=null)&&(logteamid!=null)&&(loguserid>0)){

// Basic Information
const quotation_no = document.getElementById("quotation_no").value;
const date = document.getElementById("date").value;
// Client Details
const client_id = document.getElementById("client_id").value;
const client_name = document.getElementById("client_name").value;
const client_contact = document.getElementById("client_contact").value;
const client_email = document.getElementById("client_email").value;
const client_city = document.getElementById("client_city").value;
const client_state = document.getElementById("client_state").value;
const client_address = document.getElementById("client_address").value;
const client_gstin = document.getElementById("client_gstin").value;
//Payment Details
/*const payment_payee = document.getElementById("payment_payee").value;
const payment_trno = document.getElementById("payment_trno").value;
const payment_date = document.getElementById("payment_date").value;
const payment_time = document.getElementById("payment_time").value;
const payment_mode = document.getElementById("payment_mode").value;
const payment_remarks = document.getElementById("payment_remarks").value;
const payment_account = document.getElementById("payment_account").value;*/
//Order Items
 //fetching values for calculation
 let amount1=document.getElementById("amount1").value; if((amount1=="")||(amount1==null)){ amount1=0; }; amount1=parseFloat(amount1); let rate1=document.getElementById("rate1").value; if((rate1=="")||(rate1==null)){ rate1=0; }; rate1=parseFloat(rate1); let quantity1=document.getElementById("quantity1").value; if((quantity1=="")||(quantity1==null)){ quantity1=0; };quantity1=parseFloat(quantity1);
 let amount2=document.getElementById("amount2").value; if((amount2=="")||(amount2==null)){ amount2=0; }; amount2=parseFloat(amount2); let rate2=document.getElementById("rate2").value; if((rate2=="")||(rate2==null)){ rate2=0; }; rate2=parseFloat(rate2); let quantity2=document.getElementById("quantity2").value; if((quantity2=="")||(quantity2==null)){ quantity2=0; };quantity2=parseFloat(quantity2);
 let amount3=document.getElementById("amount3").value; if((amount3=="")||(amount3==null)){ amount3=0; }; amount3=parseFloat(amount3); let rate3=document.getElementById("rate3").value; if((rate3=="")||(rate3==null)){ rate3=0; }; rate3=parseFloat(rate3); let quantity3=document.getElementById("quantity3").value; if((quantity3=="")||(quantity3==null)){ quantity3=0; };quantity3=parseFloat(quantity3);
 let amount4=document.getElementById("amount4").value; if((amount4=="")||(amount4==null)){ amount4=0; }; amount4=parseFloat(amount4); let rate4=document.getElementById("rate4").value; if((rate4=="")||(rate4==null)){ rate4=0; }; rate4=parseFloat(rate4); let quantity4=document.getElementById("quantity4").value; if((quantity4=="")||(quantity4==null)){ quantity4=0; };quantity4=parseFloat(quantity4);
 let amount5=document.getElementById("amount5").value; if((amount5=="")||(amount5==null)){ amount5=0; }; amount5=parseFloat(amount5); let rate5=document.getElementById("rate5").value; if((rate5=="")||(rate5==null)){ rate5=0; }; rate5=parseFloat(rate5); let quantity5=document.getElementById("quantity5").value; if((quantity5=="")||(quantity5==null)){ quantity5=0; };quantity5=parseFloat(quantity5);
 let amount6=document.getElementById("amount6").value; if((amount6=="")||(amount6==null)){ amount6=0; }; amount6=parseFloat(amount6); let rate6=document.getElementById("rate6").value; if((rate6=="")||(rate6==null)){ rate6=0; }; rate6=parseFloat(rate6); let quantity6=document.getElementById("quantity6").value; if((quantity6=="")||(quantity6==null)){ quantity6=0; };quantity6=parseFloat(quantity6);
 let amount7=document.getElementById("amount7").value; if((amount7=="")||(amount7==null)){ amount7=0; }; amount7=parseFloat(amount7); let rate7=document.getElementById("rate7").value; if((rate7=="")||(rate7==null)){ rate7=0; }; rate7=parseFloat(rate7); let quantity7=document.getElementById("quantity7").value; if((quantity7=="")||(quantity7==null)){ quantity7=0; };quantity7=parseFloat(quantity7);
 let total=document.getElementById("total").value; if((total=="")||(total==null)){ total=0; }; total=parseFloat(total); let total_quantity=document.getElementById("total_quantity").value; if((total_quantity=="")||(total_quantity==null)){ total_quantity=0; }; total_quantity=parseFloat(total_quantity); let subtotal=document.getElementById("subtotal").value; if((subtotal=="")||(subtotal==null)){ subtotal=0; }; subtotal=parseFloat(subtotal);
 let discount_per=document.getElementById("discount_per").value; if((discount_per=="")||(discount_per==null)){ discount_per=0; }; discount_per=parseFloat(discount_per); let discount_amt=document.getElementById("discount_amt").value; if((discount_amt=="")||(discount_amt==null)){ discount_amt=0; }; discount_amt=parseFloat(discount_amt);
 let gst_per=document.getElementById("gst_per").value; if((gst_per=="")||(gst_per==null)){ gst_per=0; }; gst_per=parseFloat(gst_per); let gst_amt=document.getElementById("gst_amt").value; if((gst_amt=="")||(gst_amt==null)){ gst_amt=0; }; gst_amt=parseFloat(gst_amt);
 let nettotal=document.getElementById("nettotal").value; if((nettotal=="")||(nettotal==null)){ nettotal=0; }; nettotal=parseFloat(nettotal);
 let other_ded=document.getElementById("other_ded").value; if((other_ded=="")||(other_ded==null)){ other_ded=0; }; other_ded=parseFloat(other_ded);
 let other_exp=document.getElementById("other_exp").value; if((other_exp=="")||(other_exp==null)){ other_exp=0; }; other_exp=parseFloat(other_exp);
 
 // Individual Items Total 
   amount1 = parseFloat(quantity1*rate1); setamount1(amount1.toFixed(2));
   amount2 = parseFloat(quantity2*rate2); setamount2(amount2.toFixed(2));
   amount3 = parseFloat(quantity3*rate3); setamount3(amount3.toFixed(2));
   amount4 = parseFloat(quantity4*rate4); setamount4(amount4.toFixed(2));
   amount5 = parseFloat(quantity5*rate5); setamount5(amount5.toFixed(2));
   amount6 = parseFloat(quantity6*rate6); setamount6(amount6.toFixed(2));
   amount7 = parseFloat(quantity7*rate7); setamount7(amount7.toFixed(2));
 
   // Setting Total Values 
 total_quantity=parseFloat(quantity1+quantity2+quantity3+quantity4+quantity5+quantity6+quantity7);settotal_quantity(total_quantity.toFixed(2));
 total=parseFloat(amount1+amount2+amount3+amount4+amount5+amount6+amount7);settotal(total.toFixed(2));
 subtotal=parseFloat(total);setsubtotal(subtotal.toFixed(2));
 if(discount_per>0){discount_amt = parseFloat(total*(discount_per/100));}setdiscount_amt(discount_amt.toFixed(2));
 const bgst = parseFloat(total-discount_amt);
 if(gst_per>0){gst_amt = parseFloat(bgst*(gst_per/100));setgst_amt(gst_amt.toFixed(2));}
 nettotal=bgst+gst_amt+other_exp-other_ded; nettotal=parseFloat(nettotal); setnettotal(nettotal.toFixed(2));



 //Returning Errors if empty values
 if((quotation_no=="")||(quotation_no=="null")){ seterror1("Quotation_no is Required ! ");return;}else{ seterror1(""); }//quotation_no
 if((date=="")||(date=="null")){ seterror2("Date is Required ! ");return;}else{ seterror2(""); }//date
 if((client_id=="")||(client_id=="null")){ seterror3("Client Id is Required ! ");return;}else{ seterror3(""); }//client_id
 if((client_name=="")||(client_name=="null")){ seterror4("Client Name is Required ! ");return;}else{ seterror4(""); }//client_name
 if((client_contact=="")||(client_contact=="null")){ seterror5("Client Contact is Required ! ");return;}else{ seterror5(""); }//client_contact
 if((client_email=="")||(client_email=="null")){ seterror6("Client Email is Required ! ");return;}else{ seterror6(""); }//client_email
//List Items
//Row 1
if((heading1=="")||(heading1=="null")){ seterror16("Heading is Required ! ");return;}else{ seterror16(""); }//heading1

//alert(date);
//const old_date=moment(date).format('DD-MM-YYYY')
 //SENDING DATA THROUGH API and FETCHING RESULTS
   //Sending Data through axios
   axios.post("services-create-quotation", { 
    //Basic Details
    User:loguser,
    Logtype:logtype,
    Log_id:loguserid,
    Logstatus:"true",
    User_id:loguserid,
    Account_type:logtype,
    Member_id:loguserid,
    Team_id:logteamid,
    Operation:"create",
    UpdateVal:null,
    Date:moment(date).format('DD-MM-YYYY'),
    Quotation_no:quotation_no,
    //Client Details
    Client_id:client_id,
    Client_name:client_name,
    Client_contact:client_contact,
    Client_email:client_email,
    Client_address:client_address,
    Client_city:client_city,
    Client_state:client_state,
    Client_gstin:client_gstin,
    //Payment Details
   /* Payment_date:moment(payment_date).format('MM-DD-YYYY'),
    Payment_time:payment_time,
    Payment_mode:payment_mode,
    Payee_name:payment_payee,
    Transaction_number:payment_trno,
    Details:payment_account,
    Remarks:payment_remarks, */
    //List Items
    Sn1:"1",heading1:document.getElementById("heading1").value,quantity1:quantity1,details1:document.getElementById("details1").value,rate1:rate1,
    Sn2:"2",heading2:document.getElementById("heading2").value,quantity2:quantity2,details2:document.getElementById("details2").value,rate2:rate2,
    Sn3:"3",heading3:document.getElementById("heading3").value,quantity3:quantity3,details3:document.getElementById("details3").value,rate3:rate3,
    Sn4:"4",heading4:document.getElementById("heading4").value,quantity4:quantity4,details4:document.getElementById("details4").value,rate4:rate4,
    Sn5:"5",heading5:document.getElementById("heading5").value,quantity5:quantity5,details5:document.getElementById("details5").value,rate5:rate5,
    Sn6:"6",heading6:document.getElementById("heading6").value,quantity6:quantity6,details6:document.getElementById("details6").value,rate6:rate6,
    Sn7:"7",heading7:document.getElementById("heading7").value,quantity7:quantity7,details7:document.getElementById("details7").value,rate7:rate7,
    //Extras
    discount_per:discount_per,discount_amt:discount_amt,
    gst_per:gst_per,gst_amt:gst_amt,
    other_ded:other_ded,other_exp:other_exp,
    Amount_words:amt_word

   // ConfirmPassword:$cpassword
})

  .then((response) => {

    seterror1(" ");seterror2(" ");seterror3(" ");seterror4(" ");seterror5(" ");seterror6(" ");seterror7(" ");seterror8(" ");seterror9(" ");seterror10(" ");
    seterror11(" ");seterror12(" ");seterror13(" ");seterror14(" ");seterror15(" ");seterror16(" ");seterror17(" ");seterror18(" ");seterror19(" ");seterror20(" ");
    seterror21(" ");seterror22(" ");seterror23(" ");seterror24(" ");seterror25(" ");seterror26(" ");seterror27(" ");seterror28(" ");seterror29(" ");seterror30(" ");
    seterror31(" ");seterror32(" ");seterror33(" ");seterror34(" ");seterror35(" ");seterror36(" ");seterror37(" ");seterror38(" ");seterror39(" ");seterror40(" ");
    seterror41(" ");seterror42(" ");seterror43(" ");seterror44(" ");seterror45(" ");seterror46(" ");seterror47(" ");seterror48(" ");seterror49(" ");seterror50(" ");
    seterror51(" ");seterror52(" ");seterror53(" ");seterror54(" ");seterror55(" ");seterror56(" ");seterror57(" ");seterror58(" ");seterror59(" ");seterror60(" ");
    seterror61(" ");seterror62(" ");seterror63(" ");seterror64(" ");seterror65(" ");seterror66(" ");seterror67(" ");seterror68(" ");seterror69(" ");seterror70(" ");

    //Validation Errors
  if(response.data.validatorerror){
    //General Errors
    seterror1(response.data.validatorerror.Quotation_no);
    seterror2(response.data.validatorerror.Date);
    //Client Details Errors
    seterror3(response.data.validatorerror.Client_id);
    seterror4(response.data.validatorerror.Client_name);
    seterror5(response.data.validatorerror.Client_contact);
    seterror6(response.data.validatorerror.Client_email);
    seterror7(response.data.validatorerror.Client_address);
    seterror8(response.data.validatorerror.Client_city);
    seterror9(response.data.validatorerror.Client_state);
    seterror10(response.data.validatorerror.Client_gstin);
    //Payment Details Errors
    seterror11(response.data.validatorerror.Payment_mode);
    seterror12(response.data.validatorerror.Payee_name);
    seterror13(response.data.validatorerror.Transaction_number);
    seterror14(response.data.validatorerror.Details);
    seterror15(response.data.validatorerror.Remarks);

    //List Items First Row
    seterror16(response.data.validatorerror.heading1);
    seterror17(response.data.validatorerror.quantity1);
    seterror19(response.data.validatorerror.rate1);

    //Extras
    seterror50(response.data.validatorerror.Amount_words);

    //Default Error 
    seterror51("Cannot  Create Quotation !");
    seterror52(response.data.error);

   /* if(response.data.error=='101'){ seterror6("Cannot Create New Project!"); }*/
    
     }
  //Invalid Entries
  if(response.data.successvar==2){

    //General Errors
  seterror1(response.data.quotation_no_er);
  seterror2(response.data.date_er);
  //Client Details Errors
  seterror3(response.data.client_id_er);
  seterror4(response.data.client_name_er);
  seterror5(response.data.client_contact_er);
  seterror6(response.data.client_email_er);
  seterror7(response.data.client_address_er);
  seterror8(response.data.client_city_er);
  seterror9(response.data.client_state_er);
  seterror10(response.data.client_gstin_er);
    //Payment Details Errors
  seterror11(response.data.payment_mode_er);
  seterror12(response.data.payee_name_er);
  seterror13(response.data.transaction_number_er);
  seterror14(response.data.payment_details_er);
  seterror15(response.data.payment_remarks_er);
    //Item Errors
  seterror16(response.data.heading1_er);
  seterror17(response.data.quantity1_er);
  seterror18(response.data.details1_er);
  seterror19(response.data.rate1_er);
  seterror20(response.data.heading2_er);
  seterror21(response.data.quantity2_er);
  seterror22(response.data.details2_er);
  seterror23(response.data.rate2_er);
  seterror24(response.data.heading3_er);
  seterror25(response.data.quantity3_er);
  seterror26(response.data.details3_er);
  seterror27(response.data.rate3_er);
  seterror28(response.data.heading4_er);
  seterror29(response.data.quantity4_er);
  seterror30(response.data.details4_er);
  seterror31(response.data.rate4_er);
  seterror32(response.data.heading5_er);
  seterror33(response.data.quantity5_er);
  seterror34(response.data.details5_er);
  seterror35(response.data.rate5_er);
  seterror36(response.data.heading6_er);
  seterror37(response.data.quantity6_er);
  seterror38(response.data.details6_er);
  seterror39(response.data.rate6_er);
  seterror40(response.data.heading7_er);
  seterror41(response.data.quantity7_er);
  seterror42(response.data.details7_er);
  seterror43(response.data.rate7_er);
  //Extras
  seterror44(response.data.discount_per_er);
  seterror45(response.data.discount_amt_er);
  seterror46(response.data.gst_per_er);
  seterror47(response.data.gst_per_er);
  seterror48(response.data.other_exp_er);
  seterror49(response.data.other_ded_er);
  seterror50(response.data.amount_words_er);


    //Default Error 
    seterror51("Cannot  Create Quotation !");
    seterror52(response.data.error);

    
         }

  //success
  if(response.data.successvar==1){
  alert("Quotation Created Successfully !");
  //alert("New Date"+response.data.date+", Old Date"+old_date+"Old Back Date"+response.data.newdate);
  sessionStorage.setItem("loadVal010",response.data.quotation_id);
  window.location.replace("/view-quotation");
 // window.location.replace("view-quotation");
  }
  else{
  if(response.data.successvar==0){
    //Default Error 
    seterror51("Cannot  Create Quotation !");
    seterror52(response.data.error);
  }
   //Default Error 
   seterror51("Cannot  Create Quotation !");
   seterror52(response.data.error);
}
}    );



}
//Sessions Not Set
else{
  sessionStorage.removeItem("userusername00");
  sessionStorage.removeItem("userloginstatus00");
  sessionStorage.removeItem("userlogintype00");
  sessionStorage.removeItem("userloginname00");
  sessionStorage.removeItem("userloginadminid00");
  sessionStorage.removeItem("userloginteamid00");
  alert("Session Expired! Login Again.");
  window.location.replace("/");
}
}

//Fetching Team Members
/*function getData(){
  

  //Checking if user loggedin
if(((logstatus=="true")||(logstatus!=null))&&(loguser!=null)&&(logadminid!=null)){


 
 axios.post("teammember-details", {
   User: loguser,
   Logtype:logtype,
   Logstatus:logstatus,
   Log_id: logadminid,
 })
 .then((response) => {
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setData1(response.data.teammembers);
  // console.log(response.data);
  /* setdatacount(response.data.datacount);
   setloadcount(response.data.loadcount);
   setloadcount(1); */
  /*}
  //Cannot Fetch Data
  else{
   seterrorvar1("Cannot Fetch Data !");
  }
 

 });

 }
//User not Logedin
else{

 sessionStorage.removeItem("username00");
 sessionStorage.removeItem("loginstatus00");
 sessionStorage.removeItem("logintype00");
 sessionStorage.removeItem("loginname00");
 sessionStorage.removeItem("loginadminid00");

 alert("Session Expired! Login Again.");
 window.location.replace("/");

  }

}
*/




    return(
<>
{/*<!DOCTYPE html> */}
<html lang="en">
  <head>
    {/* Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>theStartupConnect | Portal</title>
    {/* plugins:css */}
    <link rel="stylesheet" href="./assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="./assets/vendors/css/vendor.bundle.base.css" />
    {/* endinject */}
    {/* Plugin css for this page */}
    {/* End plugin css for this page */}
    {/* inject:css */}
    {/* endinject */}
    {/* Layout styles */}
    <link rel="stylesheet" href="./assets/css/style.css" />
    {/* End layout styles */}
   {/*} <link rel="shortcut icon" href="./assets/images/favicon.ico" /> */}
  </head>
  <body>

  


<Navbar/>

{/* MAIN PAGE COMPONENT START */}
  <div class="container-fluid page-body-wrapper">

 <Sidebar/>


{/* MAIN PANEL START */}
<div class="main-panel" style={{marginTop:"5vh"}}>


{/*  QUOTATION DETAILS SECTION START  */}
<div class="content-wrapper">







<h2 class="text-center mb-2">Create New Quotation</h2>

<div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title"> Quotation Details </h4>
                    <div class="form-sample">
                      <p class="card-description" style={{fontSize:".8rem",color:"#f16083"}}>{error52}</p>
                      <small style={{fontSize:".8rem",color:"#f16083"}}>{error51}</small>           
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Quotation_no</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="quotation_no" style={{border:"0.1rem solid #115a63"}}  placeholder="Quotation_no" value={quotation_no} onChange={updatequotation_no} required />
                             <small style={{fontSize:".8rem",color:"#f16083"}}>{error1}</small>                       
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" id="date" style={{border:"0.1rem solid #115a63"}} value={date} onChange={updatedate} required />
                              <small style={{fontSize:".8rem",color:"#f16083"}}>{error2}</small> 
                            </div>
                          </div>
                        </div>
                      </div>

                    {/*}  <button type="button" style={{backgroundColor:"#196fb0",marginLeft:"2rem"}} class="btn btn-secondary btn-icon-text"> Next <i class="mdi mdi-arrow-right-bold"></i>
                          </button>
                       */}
                      </div>
                      </div>
                      </div>
                      </div>
                      </div>
{/*  QUOTATION DETAILS SECTION END  */}

{/*  CLIENT DETAILS SECTION START  */}
                      <div class="content-wrapper">
                      <div class="col-12">
                      <div class="card">
                      <div class="card-body">
                      <h4 class="card-title"> Client Details </h4>
                      <div class="form-sample">
                      <p class="card-description"></p>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Client Id</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" id="client_id" style={{border:"0.1rem solid #115a63"}} placeholder="Client Id" value={client_id} onChange={updateclient_id} required />
                            <small style={{fontSize:".8rem",color:"#f16083"}}>{error3}</small> 
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Client Name</label>
                            <div class="col-sm-9">
                              <input class="form-control" type="text" id="client_name"  style={{border:"0.1rem solid #115a63"}} placeholder="Client Name" value={client_name} onChange={updateclient_name} required  />
                              <small style={{fontSize:".8rem",color:"#f16083"}}>{error4}</small>                         
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Contact</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" id="client_contact" style={{border:"0.1rem solid #115a63"}} placeholder="Contact" value={client_contact} onChange={updateclient_contact} required />
                           <small style={{fontSize:".8rem",color:"#f16083"}}>{error5}</small> 
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Email</label>
                            <div class="col-sm-9">
                              <input class="form-control" type="text" id="client_email"  style={{border:"0.1rem solid #115a63"}} placeholder="Email" value={client_email} onChange={updateclient_email} required />
                              <small style={{fontSize:".8rem",color:"#f16083"}}>{error6}</small>                         
                            </div>
                          </div>
                        </div>
                      </div>
                 
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">City</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" id="client_city" style={{border:"0.1rem solid #115a63"}} placeholder="City" value={client_city} onChange={updateclient_city} />
                           <small style={{fontSize:".8rem",color:"#f16083"}}>{error8}</small> 
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">State</label>
                            <div class="col-sm-9">
                              <input class="form-control" type="text" id="client_state"  style={{border:"0.1rem solid #115a63"}} placeholder="State" value={client_state} onChange={updateclient_state} />
                              <small style={{fontSize:".8rem",color:"#f16083"}}>{error9}</small>                         
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">GSTIN</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" id="client_gstin"  style={{border:"0.1rem solid #115a63"}} placeholder="GSTIN" value={client_gstin} onChange={updateclient_gstin} />
                            <small style={{fontSize:".8rem",color:"#f16083"}}>{error10}</small>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label  class="col-sm-3 col-form-label">Address</label>
                            <div class="col-sm-9">
                              <textarea class="form-control" id="client_address" style={{border:"0.1rem solid #115a63"}} size="4"  placeholder="Client Address Over here." value={client_address} onChange={updateclient_address}></textarea>
                              <small style={{fontSize:".8rem",color:"#f16083"}}>{error7}</small>                        
                            </div>
                          </div>
                        </div>
                      </div>
                            
                            
                 {/*}     <button type="button" style={{backgroundColor:"#c62589"}} class="btn btn-secondary btn-icon-text"><i class="mdi mdi-arrow-left-bold"></i> Previous 
                          </button>
                      <button type="button" style={{backgroundColor:"#196fb0",marginLeft:"2rem"}} class="btn btn-secondary btn-icon-text"> Next <i class="mdi mdi-arrow-right-bold"></i>
                          </button>
                  */}
                       
             
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
{/*  CLIENT DETAILS SECTION END  */}

{/*  PAYMENT DETAILS SECTION START  */}
               {/*}   <div class="content-wrapper">
                      <div class="col-12">
                      <div class="card">
                      <div class="card-body">
                      <h4 class="card-title"> Payment Details </h4>
                      <div class="form-sample">
                      <p class="card-description"></p>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Payment Mode</label>
                            <div class="col-sm-9">
                            <select type="text" class="form-control" id="payment_mode" style={{border:"0.1rem solid #115a63",padding:"1rem"}} placeholder="Client Id" value={payment_mode} onChange={updatepayment_mode} required >
                            <option>Cash</option>
                            <option>NEFT</option>
                            <option>UPI</option>
                            <option>Debit Card</option>
                            <option>Credit Card</option>
                            <option>IMPS</option>
                            <option>RTGS</option>
                            </select>
                           <small style={{fontSize:".8rem",color:"#f16083"}}>{error11}</small> 
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Payee Name</label>
                            <div class="col-sm-9">
                              <input class="form-control" type="text" id="payment_payee"  style={{border:"0.1rem solid #115a63"}} placeholder="Payee Name" value={payment_payee} onChange={updatepayment_payee} required />
                              <small style={{fontSize:".8rem",color:"#f16083"}}>{error12}</small>                         
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Transaction Number</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" id="payment_trno"  style={{border:"0.1rem solid #115a63"}} placeholder="BHVV77776TFFF55" value={payment_trno} onChange={updatepayment_trno} required />
                            <small style={{fontSize:".8rem",color:"#f16083"}}>{error13}</small> 
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Details</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" id="payment_account"  style={{border:"0.1rem solid #115a63"}} placeholder="1212 XXXX XXXX XXXX" value={payment_accountno} onChange={updatepayment_accountno} />
                            <small style={{fontSize:".8rem",color:"#f16083"}}>{error14}</small> 
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date</label>
                            <div class="col-sm-9">
                            <input type="date" class="form-control" id="payment_date"  style={{border:"0.1rem solid #115a63"}} value={payment_date} onChange={updatepayment_date} required />
                          {/*}  <small style={{fontSize:".8rem",color:"#f16083"}}>{error3}</small>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Time</label>
                            <div class="col-sm-9">
                            <input type="time" class="form-control" id="payment_time"  style={{border:"0.1rem solid #115a63"}}value={payment_time} onChange={updatepayment_time}  />
                          {/*}  <small style={{fontSize:".8rem",color:"#f16083"}}>{error3}</small> 
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Remarks</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" id="payment_remarks"  style={{border:"0.1rem solid #115a63"}} value={payment_remarks} onChange={updatepayment_remarks} />
                            <small style={{fontSize:".8rem",color:"#f16083"}}>{error15}</small> 
                            </div>
                          </div>
                        </div>
                      </div>
                      */}
                   
                            
                      {/*<button type="button" style={{backgroundColor:"#c62589"}} class="btn btn-secondary btn-icon-text"><i class="mdi mdi-arrow-left-bold"></i> Previous 
                          </button>
                      <button type="button" style={{backgroundColor:"#196fb0",marginLeft:"2rem"}} class="btn btn-secondary btn-icon-text"> Next <i class="mdi mdi-arrow-right-bold"></i>
                          </button>
                       */}  
                       
                    {/*}
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>*/}

{/*  PAYMENT DETAILS SECTION END  */}

{/* ORDER ITEMS SECTION START  */}
<div class="content-wrapper">
                      <div class="col-12">
                      <div class="card">
                      <div class="card-body">
                      <h4 class="card-title"> Order Items </h4>
                      <div class="form-sample">
                      <p class="card-description"></p>
                     
                     <table>
                     <thead>
                      <th>Sn</th>
                      <th>Heading</th>
                      <th>Quantity</th>
                      <th>Details</th>
                      <th>Rate</th>
                      <th>Amount</th>
                     </thead>
                      <tbody>
                      <tr style={{marginTop:"2rem"}}>
                        <td>#1</td>
                        <td> <input type="text" class="form-control" id="heading1"  style={{border:"0.1rem solid #115a63"}} placeholder="Service 1" value={heading1} onChange={updateheading1} /></td>
                        <td> <input type="text" class="form-control" id="quantity1"  style={{border:"0.1rem solid #115a63"}} placeholder="1" value={quantity1} onChange={updatequantity1} /></td>
                        <td> <input type="text" class="form-control" id="details1"  style={{border:"0.1rem solid #115a63"}} placeholder="Piece" value={details1} onChange={updatedetails1} /></td>
                        <td> <input type="text" class="form-control" id="rate1"  style={{border:"0.1rem solid #115a63"}} placeholder="100.00" value={rate1} onChange={updaterate1} /></td>
                        <td> <input type="text" class="form-control" id="amount1"  style={{border:"0.1rem solid #115a63"}} placeholder="100.00" value={amount1} onChange={updateamount1} /></td>
                        </tr>
                        <tr style={{fontSize:".8rem",color:"#f16083"}}><td></td><td>{error16}</td><td>{error17}</td><td>{error18}</td><td>{error19}</td><td></td></tr>
                        <tr style={{marginTop:"2rem"}}>
                        <td>#2</td>
                        <td><input type="text" class="form-control" id="heading2"  style={{border:"0.1rem solid #115a63"}} placeholder="Service 2" value={heading2} onChange={updateheading2} /></td>
                        <td> <input type="text" class="form-control" id="quantity2"  style={{border:"0.1rem solid #115a63"}} placeholder="1" value={quantity2} onChange={updatequantity2} /></td>
                        <td> <input type="text" class="form-control" id="details2"  style={{border:"0.1rem solid #115a63"}} placeholder="Piece" value={details2} onChange={updatedetails2} /></td>
                        <td> <input type="text" class="form-control" id="rate2"  style={{border:"0.1rem solid #115a63"}} placeholder="200.00" value={rate2} onChange={updaterate2} /></td>
                        <td> <input type="text" class="form-control" id="amount2"  style={{border:"0.1rem solid #115a63"}} placeholder="200.00" value={amount2} onChange={updateamount2} /></td>
                     </tr>
                     <tr style={{fontSize:".8rem",color:"#f16083"}}><td></td><td>{error20}</td><td>{error21}</td><td>{error22}</td><td>{error23}</td><td></td></tr>
                        <tr style={{marginTop:"2rem"}}>
                        <td>#3</td>
                        <td><input type="text" class="form-control" id="heading3"  style={{border:"0.1rem solid #115a63"}} placeholder="Service 3" value={heading3} onChange={updateheading3} /></td>
                        <td> <input type="text" class="form-control" id="quantity3"  style={{border:"0.1rem solid #115a63"}} placeholder="1" value={quantity3} onChange={updatequantity3} /></td>
                        <td> <input type="text" class="form-control" id="details3"  style={{border:"0.1rem solid #115a63"}} placeholder="Piece" value={details3} onChange={updatedetails3} /></td>
                        <td> <input type="text" class="form-control" id="rate3"  style={{border:"0.1rem solid #115a63"}} placeholder="300.00" value={rate3} onChange={updaterate3} /></td>
                        <td> <input type="text" class="form-control" id="amount3"  style={{border:"0.1rem solid #115a63"}} placeholder="300.00" value={amount3} onChange={updateamount3} /></td>
                        </tr>
                        <tr style={{fontSize:".8rem",color:"#f16083"}}><td></td><td>{error24}</td><td>{error25}</td><td>{error26}</td><td>{error27}</td><td></td></tr>
                        <tr style={{marginTop:"2rem"}}>
                        <td>#4</td>
                        <td><input type="text" class="form-control" id="heading4"  style={{border:"0.1rem solid #115a63"}} placeholder="Service 4" value={heading4} onChange={updateheading4} /></td>
                        <td> <input type="text" class="form-control" id="quantity4"  style={{border:"0.1rem solid #115a63"}} placeholder="1" value={quantity4} onChange={updatequantity4} /></td>
                        <td> <input type="text" class="form-control" id="details4"  style={{border:"0.1rem solid #115a63"}} placeholder="Piece" value={details4} onChange={updatedetails4} /></td>
                        <td> <input type="text" class="form-control" id="rate4"  style={{border:"0.1rem solid #115a63"}} placeholder="400.00" value={rate4} onChange={updaterate4} /></td>
                        <td> <input type="text" class="form-control" id="amount4"  style={{border:"0.1rem solid #115a63"}} placeholder="400.00" value={amount4} onChange={updateamount4} /></td>
                        </tr>
                        <tr style={{fontSize:".8rem",color:"#f16083"}}><td></td><td>{error28}</td><td>{error29}</td><td>{error30}</td><td>{error31}</td><td></td></tr>
                        <tr style={{marginTop:"2rem"}}>
                        <td>#5</td>
                        <td><input type="text" class="form-control" id="heading5"  style={{border:"0.1rem solid #115a63"}} placeholder="Service 5" value={heading5} onChange={updateheading5} /></td>
                        <td> <input type="text" class="form-control" id="quantity5"  style={{border:"0.1rem solid #115a63"}} placeholder="1" value={quantity5} onChange={updatequantity5} /></td>
                        <td> <input type="text" class="form-control" id="details5"  style={{border:"0.1rem solid #115a63"}} placeholder="Piece" value={details5} onChange={updatedetails5} /></td>
                        <td> <input type="text" class="form-control" id="rate5"  style={{border:"0.1rem solid #115a63"}} placeholder="500.00" value={rate5} onChange={updaterate5} /></td>
                        <td> <input type="text" class="form-control" id="amount5"  style={{border:"0.1rem solid #115a63"}} placeholder="500.00" value={amount5} onChange={updateamount5} /></td>
                        </tr>
                        <tr style={{fontSize:".8rem",color:"#f16083"}}><td></td><td>{error32}</td><td>{error33}</td><td>{error34}</td><td>{error35}</td><td></td></tr>
                        <tr style={{marginTop:"2rem"}}>
                        <td>#6</td>
                        <td><input type="text" class="form-control" id="heading6"  style={{border:"0.1rem solid #115a63"}} placeholder="Service 6" value={heading6} onChange={updateheading6} /></td>
                        <td> <input type="text" class="form-control" id="quantity6"  style={{border:"0.1rem solid #115a63"}} placeholder="1" value={quantity6} onChange={updatequantity6} /></td>
                        <td> <input type="text" class="form-control" id="details6"  style={{border:"0.1rem solid #115a63"}} placeholder="Piece" value={details6} onChange={updatedetails6} /></td>
                        <td> <input type="text" class="form-control" id="rate6"  style={{border:"0.1rem solid #115a63"}} placeholder="600.00" value={rate6} onChange={updaterate6} /></td>
                        <td> <input type="text" class="form-control" id="amount6"  style={{border:"0.1rem solid #115a63"}} placeholder="600.00" value={amount6} onChange={updateamount6} /></td>
                        </tr>
                        <tr style={{fontSize:".8rem",color:"#f16083"}}><td></td><td>{error36}</td><td>{error37}</td><td>{error38}</td><td>{error39}</td><td></td></tr>
                        <tr style={{marginTop:"2rem"}}>
                        <td>#7</td>
                        <td><input type="text" class="form-control" id="heading7"  style={{border:"0.1rem solid #115a63"}} placeholder="Service 7" value={heading7} onChange={updateheading7} /></td>
                        <td> <input type="text" class="form-control" id="quantity7"  style={{border:"0.1rem solid #115a63"}} placeholder="1" value={quantity7} onChange={updatequantity7} /></td>
                        <td> <input type="text" class="form-control" id="details7"  style={{border:"0.1rem solid #115a63"}} placeholder="Piece" value={details7} onChange={updatedetails7} /></td>
                        <td> <input type="text" class="form-control" id="rate7"  style={{border:"0.1rem solid #115a63"}} placeholder="700.00" value={rate7} onChange={updaterate7} /></td>
                        <td> <input type="text" class="form-control" id="amount7"  style={{border:"0.1rem solid #115a63"}} placeholder="700.00" value={amount7} onChange={updateamount7} /></td>
                        </tr>
                        <tr style={{fontSize:".8rem",color:"#f16083"}}><td></td><td>{error40}</td><td>{error41}</td><td>{error42}</td><td>{error43}</td><td></td></tr>
                        <tr style={{marginTop:"2rem"}}>
                        <td><b>#</b></td>
                        <td><b>Total</b></td>
                        <td> <input type="text" class="form-control" id="total_quantity"  style={{border:"0.1rem solid #115a63",fontWeight:"bold"}} placeholder="-" value={total_quantity} onChange={updatetotal_quantity} /></td>
                        <td></td>
                        <td></td>
                        <td> <input type="text" class="form-control" id="total"  style={{border:"0.1rem solid #115a63",fontWeight:"bold"}} placeholder="__.__" value={total} onChange={updatetotal} /></td>
                        </tr>
                      </tbody>
                     </table>
                            <div style={{marginTop:"4rem",marginBottom:"4rem",padding:"0.1rem",backgroundColor:"#115a63"}}></div>
                            <table>
                     <thead>
                     </thead>
                      <tbody>
                      <tr style={{marginTop:"2rem"}}>
                        <td style={{textAlign:"left"}}>Sub Total</td>
                        <td></td>
                        <td> <input type="text" class="form-control" id="subtotal"  style={{border:"0.1rem solid #115a63"}} placeholder="___.__" value={subtotal} onChange={updatesubtotal} /></td>
                        </tr>
                      <tr style={{marginTop:"2rem"}}>
                        <td style={{textAlign:"left"}}>Discount</td>
                        <td> <input type="text" class="form-control" id="discount_per"  style={{border:"0.1rem solid #115a63"}} placeholder="00.00%" value={discount_per} onChange={updatediscount_per} /></td>
                        <td> <input type="text" class="form-control" id="discount_amt"  style={{border:"0.1rem solid #115a63"}} placeholder="000.00" value={discount_amt} onChange={updatediscount_amt} /></td>
                        </tr>
                        <tr><td></td><td>{error44}</td><td>{error45}</td></tr>
                        <tr style={{marginTop:"2rem"}}>
                        <td style={{textAlign:"left"}}>GST</td>
                        <td> <input type="text" class="form-control" id="gst_per"  style={{border:"0.1rem solid #115a63"}} placeholder="00.00%" value={gst_per} onChange={updategst_per}/></td>
                        <td> <input type="text" class="form-control" id="gst_amt"  style={{border:"0.1rem solid #115a63"}} placeholder="000.00" value={gst_amt} onChange={updategst_amt} /></td>
                        </tr>
                        <tr><td><td></td>{error46}</td><td>{error47}</td></tr>
                        <tr style={{marginTop:"2rem"}}>
                        <td style={{textAlign:"left"}}>Other Expenses</td>
                        <td></td>
                        <td> <input type="text" class="form-control" id="other_exp"  style={{border:"0.1rem solid #115a63"}} placeholder="000.00" value={other_exp} onChange={updateother_exp} /></td>
                        </tr>
                        <tr>{error48}</tr>
                        <tr style={{marginTop:"2rem"}}>
                        <td style={{textAlign:"left"}}>Other Deductions</td>
                        <td></td>
                        <td> <input type="text" class="form-control" id="other_ded"  style={{border:"0.1rem solid #115a63"}} placeholder="000.00" value={other_ded} onChange={updateother_ded} /></td>
                        </tr>
                        <tr>{error49}</tr>
                        <tr style={{marginTop:"2rem"}}>
                        <td style={{textAlign:"left"}}><b>Net Total(Payable)</b></td>
                        <td></td>
                        <td> <input type="text" class="form-control" id="nettotal"  style={{border:"0.1rem solid #115a63"}} placeholder="000.00" value={nettotal} onChange={updatenettotal} /></td>
                        </tr>
                      
                      </tbody>
                     </table>
                     <div style={{marginTop:"4rem",marginBottom:"4rem"}}></div>
                     

                     <div class="row">
                        <div class="col-md-12">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Amount in words</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" id="amt_word"  style={{border:"0.1rem solid #115a63"}} placeholder="One Hundred Twenty One Only" value={amt_word} onChange={updateamt_word} />
                            <small style={{fontSize:".8rem",color:"#f16083"}}>{error50}</small> 
                            </div>
                          </div>
                        </div>
                    
                      </div>


                      {/*<button type="button" style={{backgroundColor:"#c62589"}} class="btn btn-secondary btn-icon-text"><i class="mdi mdi-arrow-left-bold"></i> Previous 
                          </button>*/}
                          <button type="button" style={{backgroundColor:"#e6631b",marginLeft:"2rem"}} class="btn btn-secondary btn-icon-text" onClick={calculate}><i class="mdi mdi-relative-scale"></i> Calculate
                          </button>
                      <button type="button" style={{backgroundColor:"#811bc4",marginLeft:"2rem"}} class="btn btn-secondary btn-icon-text" onClick={create_quotation}> + Create Quotation </button>

                       
             
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>

</div>
{/* MAIN PANEL END */}

</div>
{/* MAIN PAGE COMPONENT END */}

    {/* container-scroller */}
    {/* plugins:js */}
    <script src="./assets/vendors/js/vendor.bundle.base.js"></script>
    {/* endinject */}
    {/* Plugin js for this page */}
    {/* End plugin js for this page */}
    {/* inject:js */}
    <script src="./assets/js/off-canvas.js"></script>
    <script src="./assets/js/hoverable-collapse.js"></script>
    <script src="./assets/js/misc.js"></script>

    {/*} plugins:js */}
    <script src="assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="assets/vendors/chart.js/Chart.min.js"></script>
    <script src="assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="assets/js/off-canvas.js"></script>
    <script src="assets/js/hoverable-collapse.js"></script>
    <script src="assets/js/misc.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="assets/js/dashboard.js"></script>
    <script src="assets/js/todolist.js"></script>
    {/*} End custom js for this page */}
    {/* endinject */}
  </body>
</html>
</>
    );
}
//}

export default Create_quotation;