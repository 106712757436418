import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import Navbar from './navbar';



//import '../hireportalcss.css';
//import '../Hireportalcss/hireportalcss.css';

//Importing Page css with screen sizes
import '../Hireportalcss/sdefault.css';
import '../Hireportalcss/s1200.css';
import '../Hireportalcss/s920.css';
import '../Hireportalcss/s768.css';
import '../Hireportalcss/s600.css';
import '../Hireportalcss/sanimation.css';



function Employerviewassignment(){

    return(

    <>
        <div class="default-view">
        <Navbar/>
        {/* MAIN SECTION START */}
        <div class="hire-main-section">

        {/* FORM CONTAINER START */}
        <div class="hire-form-container">
        
        {/* RESUME CARD START */}
            <div class="hire-form-panel" style={{backgroundColor:"#ffffff"}}>

            <h2>Assignment Name : <span style={{color:"#154f9d"}}>Full Stack Developer Role Test</span></h2>
       
            <div id="line-1"></div>
                   {/* RESUME SECTION START */}
            <div class="hire-resume-section">
            <div>
               <p style={{textAlign:"left"}}><b>Total Marks : 200 </b></p>

            </div>

            <h3 style={{textAlign:"left"}}>ASSIGNMENT Questions</h3>

            {/* Question 1 Start */}
            <div class="hire-resume-card" style={{marginTop:"0.2rem"}}>
            <h3>Q.1 Why are you the right fit for this role?</h3>
                <p><b>Answer Type: </b> Short Answer Text </p>
                <p><b>Correct Answer: </b> Must Include "ABC is a Good"  Full Stack Developer </p>
                <div>
                <button class="hire-btn-default" style={{backgroundColor:"#0a918a"}}>Edit</button>
                <button class="hire-btn-default">View as Candidate</button>
                </div>
                </div>
            {/* Question 1 End */}

          


            </div>
            {/* RESUME SECTION END */}
    
        </div>
        {/* RESUME CARD END */}


        </div>
        {/* FORM CONTAINER END */}

        </div>
        {/* MAIN SECTION END */}

        </div>

        </>
    );




}


export default Employerviewassignment;