import React from 'react'
import { useState } from 'react';
import Cards from './cards/Cards';
import './AllFeatures.css'

{/* import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap' */}

const AllFeatures = (props) => {

  let All = [
    
    {
      id:'1',
      title:'Digital Marketing',
      category: '(Marketing)',
      description:'Elevate your brand\'s online presence with our comprehensive digital marketing services.',
      price : 'Rs.999/-',
      link:'/digitalmarketing',
      src:'https://img.freepik.com/free-vector/hand-drawn-illustration-people-with-smartphone-marketing_52683-66659.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=sph',
      plans:''
    },

    {
      id:'2',
      title:'Enterprise Resource Planner(ERPs)',
      category: '(IT and Software Solutions)',
      description:'Streamline your business processes and boost productivity with our custom ERP solutions.',
      price : 'Rs.99999/-',
      link:'/erpsoftware',
      src:'https://img.freepik.com/free-vector/gradient-erp-illustration_23-2149373210.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=sph',
      plans:''
    },

    {
      id:'3',
        title:'Software as a Service(SaaS)',
        category: '(IT and Software Solutions)',
        description:'Bring your SaaS idea to life with our end-to-end product development services.',
        price : 'Rs.29999/-',
        link:'/saas',
        src:'https://img.freepik.com/free-vector/mobile-app-development-isometric-composition-with-human-characters-robotic-arm-smartphone-platforms-with-rocket-vector-illustration_1284-77296.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=sph',
        plans:''
    },
    {
      id:'4',
      title:'Static Display Website',
      category: '(Website Development)',
      description:'Make an impactful online presence with our visually stunning and user-friendly static websites.',
      price : 'Rs.999/-',
      link:'/staticdisplaywebsite',
      src:'https://img.freepik.com/free-psd/american-food-website-app-template_23-2148477329.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=sph',
      plans:''
    },
    {
      id:'5',
      title:'E-Commerce Website',
      category: '(Website Development)',
      description:'Get an online store that drives conversions and boosts revenue with our E-commerce website development.',
      price : 'Rs.9999/-',
      link:'/ecommercewebsite',
      src:'https://img.freepik.com/free-vector/online-shopping-concept-web-landing-page-digital-marketing-website-mobile-application_25819-695.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=ais',
      plans:''
    },

    {
      id:'6',
      title:'Private Limited Registration',
      category: '(Business Registration)',
      description:'Register your Private Limited Company seamlessly with our expert guidance and support.',
      price : 'Rs.6999/-',
      link:'/privatelimitedcompany',
      src:'https://img.freepik.com/free-vector/modern-environmentally-friendly-plant-composition_1284-73036.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=sph',
      plans:''
      },
    
      {
        id:'7',
        title:'Limited Liability Partnership',
        category: '(Business Registration)',
        description:'Set up your Limited Liability Partnership quickly and efficiently with our expert guidance.',
        price : 'Rs.4999/-',
        link:'llpregistration',
        src:'https://img.freepik.com/free-vector/flat-working-day-scene-with-different-business-people_23-2148946792.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=sph',
        plans:''
      },
    
      {
        id:'8',
        title:'Startup India Registration',
        category: '(Business Registration)',
        description:'Get recognized as a Startup by the Govt. of India with Startup India registration.',
        price : 'Rs.2999/-',
        link:'/startupindiaregistration',
        src:'https://img.freepik.com/free-vector/colleagues-starting-business-project_23-2148856899.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=ais',
        plans:''
      },
      
      {
        id:'9',
        title:'GST Registrations',
        category: '(Business Registration)',
        description:'Services agency must register for GST if annual turnover exceeds 20 lakh benefits include input tax credit and compliance.',
        price : 'Rs.999/-',
        link:'/gstregistration',
        src:'https://img.freepik.com/free-vector/courier-sales-agent-meeting-consumer_1262-19234.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=ais',
        plans:''
      },
      

  ]



  let Market = [
    {
      id:'1',
      title:'Social Media Management',
      category: '(Marketing)',
      description:'Unlock the true potential of your brand on social media with our expert management services.',
      price : 'Rs.999/- per month',
      link:'/socialmediamanagement',
      src:'https://img.freepik.com/free-vector/infographic-social-media-icons_1045-609.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=ais',
      plans:''
    },
    {
      id:'2',
      title:'Digital Marketing',
      category: '(Marketing)',
      description:'Elevate your brand\'s online presence with our comprehensive digital marketing services.',
      price : 'Rs.999/-',
      link:'digitalmarketing',
      src:'https://img.freepik.com/free-vector/hand-drawn-illustration-people-with-smartphone-marketing_52683-66659.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=sph',
      plans:''
    },
    {
      id:'3',
      title:'Copywriting',
      category: '(Marketing)',
      description:'Words matter. Let us craft powerful and persuasive copy that connects with your audience.',
      price : 'Rs.199/- for 100 words',
      link:'copywriting',
      src:'https://img.freepik.com/free-vector/copywriting-typographic-header-writing-designing-texts-creativity-promotion-idea-finding-information-making-valuable-content-vector-flat-illustration_613284-256.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=sph',
      plans:''
    },
    {
      id:'4',
      title:'Graphics Designing',
      category: '(Marketing)',
      description:'Captivate your audience with visually stunning graphics and design that brings your brand to life.',
      price : 'Rs.99/-',
      link:'/graphicsdesigning',
      src:'https://img.freepik.com/free-vector/graphic-designer-workspace-concept_1284-18695.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=ais',
      plans:''
    },
    {
      id:'5',
      title:'Content Writing',
      category: '(Marketing)',
      description:'Create compelling content that engages your audience and drives results. Let us help.',
      price : 'Rs.999/- for 100 words',
      link:'contentwriting',
      src:'https://img.freepik.com/free-vector/content-writing-typographic-header-professional-speaker-journalist-write-speech-public-announcement-copywriter-creating-text-media-flat-vector-illustration_613284-2212.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=ais',
      plans:''
    },

    {
      id:'6',
      title:'Search Engine Optimization',
      category: '(Marketing)',
      description:'Get found online. Boost your search engine rankings with our expert SEO services.',
      price : 'Rs.499/- month',
      link:'/seo',
      src:'https://img.freepik.com/free-vector/illustration-online-marketing-concept-isometric-graphic_1375-296.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=sph',
      plans:''
      },
    
      {
        id:'7',
        title:'E-commerce Store Setup',
        category: '(Marketing)',
        description:'Open your online store in no time. Let us set up your E-commerce store.',
        price : 'Rs.2999/-',
        link:'e-commercestoresetup',
        src:'https://img.freepik.com/free-vector/online-shop-page-tablet_74855-5906.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=ais',
        plans:''
      },
    
      {
        id:'8',
        title:'Product Promotion',
        category: '(Marketing)',
        description:'Launch your product to success with our targeted promotion and marketing strategies.',
        price : 'Rs.999/-',
        link:'productpromotion',
        src:'https://img.freepik.com/free-vector/marketing-typographic-header-business-promotion-educational-school-course-customer-communication-product-advertising-through-social-networks-flat-vector-illustration_613284-2136.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=sph',
        plans:''
      },
      
      {
        id:'9',
        title:'Emailing and Messaging Campaigns',
        category: '(Marketing)',
        description:'Stay connected with your audience through strategic email and messaging campaigns.',
        price : 'Rs.999/-',
        link:'emailingmessaging',
        src:'https://img.freepik.com/free-vector/people-sending-receiving-messages_74855-5914.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=ais',
        plans:''
      },

      

  ]



  let IT = [
    {
      id:'1',
      title:'Enterprise Resource Planner(ERPs)',
      category: '(IT and Software Solutions)',
      description:'Streamline your business processes and boost productivity with our custom ERP solutions.',
      price : 'Rs.99999/-',
      link:'erpsoftware',
      src:'https://img.freepik.com/free-vector/gradient-erp-illustration_23-2149373210.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=sph',
      plans:''
    },
    {
      id:'2',
      title:'Customer Relationship Manager(CRM)',
      category: '(IT and Software Solutions)',
      description:'Build stronger customer relationships and drive growth with our tailored CRM solutions.',
      price : 'Rs.39999/-',
      link:'crmsoftware',
      src:'https://img.freepik.com/free-vector/flat-design-crm-illustration_23-2149380371.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=sph',
      plans:''
    },
    {
      id:'3',
      title:'Accounting Software',
      category: '(IT and Software Solutions)',
      description:'Simplify your financial management with our customized accounting software solutions.',
      price : 'Rs.14999/-',
      link:'accountingsoftware',
      src:'https://img.freepik.com/free-vector/mental-health-wellness-composition-workplace-icons-with-human-character-worker-with-reactions-chat-bubbles-vector-illustration_1284-80416.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=ais',
      plans:''
    },
    {
      id:'4',
      title:'Billing Software',
      category: '(IT and Software Solutions)',
      description:'Efficiently manage your billing and invoicing with our custom software solutions.',
      price : 'Rs.9999/-',
      link:'billingsoftware',
      src:'https://img.freepik.com/free-vector/tax-concept-illustration_114360-6114.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=ais',
      plans:''
    },
    {
      id:'5',
      title:'Point of Sale(POS)',
      category: '(IT and Software Solutions)',
      description:'Streamline your sales process with our intuitive and user-friendly PoS solutions.',
      price : 'Rs.14999/-',
      link:'possoftware',
      src:'https://img.freepik.com/free-vector/set-buyers-paying-purchases-supermarket-checkout_74855-3734.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=sph',
      plans:''
    },

    {
      id:'6',
      title:'Inventory Management Software',
      category: '(IT and Software Solutions)',
      description:'Optimize your inventory levels and improve efficiency with our customized management solutions.',
      price : 'Rs.79999/-',
      link:'inventorymanagementsoftware',
      src:'https://img.freepik.com/free-vector/warehouse-workers-using-laptop-carrying-boxes-wheelbarrow_74855-14202.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=ais',
      plans:''
      },
    
      {
        id:'7',
        title:'Members Management for Gyms ans Clubs',
        category: '(IT and Software Solutions)',
        description:'Effortlessly manage your members and their information with our customized software solutions.',
        price : 'Rs.39999/-',
        link:'membersmanagement',
        src:'https://img.freepik.com/free-vector/tiny-hr-specialist-looking-recruits-job-flat-illustration_74855-15541.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=ais',
        plans:''
      },
    
      {
        id:'8',
        title:'Software as a Service(SaaS)',
        category: '(IT and Software Solutions)',
        description:'Bring your SaaS idea to life with our end-to-end product development services.',
        price : 'Rs.29999/-',
        link:'/saas',
        src:'https://img.freepik.com/free-vector/mobile-app-development-isometric-composition-with-human-characters-robotic-arm-smartphone-platforms-with-rocket-vector-illustration_1284-77296.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=sph',
        plans:''
      },
      
      {
        id:'9',
        title:'Platform as a Service(PaaS)',
        category: '(IT and Software Solutions)',
        description:'Build and deploy scalable cloud applications with our expert PaaS development services.',
        price : 'Rs.99999/-',
        link:'/paas',
        src:'https://img.freepik.com/free-vector/web-development-isometric-concept-infographics-composition-with-platforms-text-captions-people-characters-icons-screens-illustration_1284-60242.jpg?w=900&t=st=1689536356~exp=1689536956~hmac=ef5c5ad51e2b6e75facd0d735fe6b1bf8b9fab9ee56493a49e937317cd5a119e',
        plans:''
      },

      {
        id:'10',
        title:'Customized Solutions',
        category: '(IT and Software Solutions)',
        description:'Get tailor-made software solutions that solve your unique business challenges. Let\'s talk.',
        price : 'Rs.19999/-',
        link:'/customizedsolutions',
        src:'https://img.freepik.com/free-vector/hand-drawn-illustration-people-with-smartphone-marketing_52683-66659.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=sph',
        plans:''
      },

  ]



  let Web = [
    {
      id:'1',
      title:'Static Display Website',
      category: '(Website Development)',
      description:'Make an impactful online presence with our visually stunning and user-friendly static websites.',
      price : 'Rs.999/-',
      link:'/staticdisplaywebsite',
      src:'https://img.freepik.com/free-psd/american-food-website-app-template_23-2148477329.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=sph',
      plans:''
    },
    {
      id:'2',
      title:'Web App',
      category: '(Website Development)',
      description:'Transform your business processes with our custom web application development services.',
      price : 'Rs.9999/-',
      link:'/webapp',
      src:'https://img.freepik.com/free-vector/website-setup-illustration-concept_114360-784.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=ais',
      plans:''
    },
    {
      id:'3',
      title:'E-Commerce Website',
      category: '(Website Development)',
      description:'Get an online store that drives conversions and boosts revenue with our E-commerce website development.',
      price : 'Rs.9999/-',
      link:'/ecommercewebsite',
      src:'https://img.freepik.com/free-vector/online-shopping-concept-web-landing-page-digital-marketing-website-mobile-application_25819-695.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=ais',
      plans:''
    },
    {
      id:'4',
      title:'Institution Website',
      category: '(Website Development)',
      description:'Strengthen your online presence with our institution website design that showcases your values and offerings.',
      price : 'Rs.9999/-',
      link:'/institutionwebsite',
      src:'https://img.freepik.com/free-vector/student-studying-using-laptop-bench-campus-park_74855-3905.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=ais',
      plans:''
    },

  ]


  let BR = [
    {
      id:'1',
      title:'Private Limited Registration',
      category: '(Business Registration)',
      description:'Register your Private Limited Company seamlessly with our expert guidance and support.',
      price : 'Rs.6999/-',
      link:'/privatelimitedcompany',
      src:'https://img.freepik.com/free-vector/modern-environmentally-friendly-plant-composition_1284-73036.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=sph',
      plans:''
    },
    {
      id:'2',
      title:'Sole Proprietorship Registration',
      category: '(Business Registration)',
      description:'Launch your dream business as a Sole Proprietor with our hassle-free registration services.',
      price : 'Rs.999/-',
      link:'/soleproprietorshipregistration',
      src:'https://img.freepik.com/free-vector/smart-guy-character-set_1284-15853.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=sph',
      plans:''
    },
    {
      id:'3',
      title:'Partnership Registration',
      category: '(Business Registration)',
      description:'Start your business journey as partners with our partnership registration services.',
      price : 'Rs.2999/-',
      link:'/partnershipregistration',
      src:'https://img.freepik.com/free-vector/two-business-partners-handshaking_74855-6685.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=sph',
      plans:''
    },
    {
      id:'4',
      title:'Limited Liability Partnership',
      category: '(Business Registration)',
      description:'Set up your Limited Liability Partnership quickly and efficiently with our expert guidance.',
      price : 'Rs.4999/-',
      link:'/llpregistration',
      src:'https://img.freepik.com/free-vector/flat-working-day-scene-with-different-business-people_23-2148946792.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=sph',
      plans:''
    },
    {
      id:'5',
      title:'One Person Company',
      category: '(Business Registration)',
      description:'Register your One Person Company smoothly and start your entrepreneurial journey today.',
      price : 'Rs.5999/-',
      link:'/opcregistration',
      src:'https://img.freepik.com/free-vector/businessman-set-with-different-postures_52683-207.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=sph',
      plans:''
    },

    {
      id:'6',
      title:'Section 8 Company',
      category: '(Business Registration)',
      description:'Form a non-profit Section-8 Company effortlessly and achieve your social objectives.',
      price : 'Rs.9999/-',
      link:'/section8registration',
      src:'https://img.freepik.com/free-vector/humanitarian-help-people-donating-sanitary-protection-equipment-concept-illustration_114360-1756.jpg?w=996&t=st=1689538614~exp=1689539214~hmac=674851c51e5d0b63c8f80e9424260aa5e962801fb2af67cb5a2ea22eba2dff88',
      plans:''
      },
    
      {
        id:'7',
        title:'MSME Registration',
        category: '(Business Registration)',
        description:'Register as an MSME and unlock opportunities for growth and development.',
        price : 'Rs.999/-',
        link:'/msmeregistration',
        src:'https://img.freepik.com/free-vector/illustration-startup-business_53876-27172.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=ais',
        plans:''
      },
    
      {
        id:'8',
        title:'Startup India Registration',
        category: '(Business Registration)',
        description:'Get recognized as a Startup by the Govt. of India with Startup India registration.',
        price : 'Rs.2999/-',
        link:'/startupindiaregistration',
        src:'https://img.freepik.com/free-vector/colleagues-starting-business-project_23-2148856899.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=ais',
        plans:''
      },
      
      {
        id:'9',
        title:'GST Registrations',
        category: '(Business Registration)',
        description:'Services agency must register for GST if annual turnover exceeds 20 lakh benefits include input tax credit and compliance.',
        price : 'Rs.999/-',
        link:'/gstregistration',
        src:'https://img.freepik.com/free-vector/courier-sales-agent-meeting-consumer_1262-19234.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=ais',
        plans:''
      },

  ]


  let consultancy = [
    {
      id:'1',
      title:'Management Consultancy',
      category: '(Consultancy)',
      description:'Management consultancy offers expert advice on business strategy, operations, and management to improve performance.',
      price : 'Rs.499/- per hour',
      link:'/managementconsultancy',
      src:'https://img.freepik.com/free-vector/flat-tiny-people-communication-concept-partner-discussion-business-projects_513217-77.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=sph',
      plans:''
    },
    {
      id:'2',
      title:'Strategy Consultancy',
      category: '(Consultancy)',
      description:'Strategy consultancy helps businesses develop long-term plans to achieve goals and sustain growth.',
      price : 'Rs.499/- per hour',
      link:'/strategyconsultancy',
      src:'https://img.freepik.com/free-vector/hand-drawn-business-strategy_23-2149164272.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=sph',
      plans:''
    },
    {
      id:'3',
      title:'Marketing Consultancy',
      category: '(Consultancy)',
      description:'Marketing consultancy offers strategies to help businesses promote products or services, increase sales, and brand awareness.',
      price : 'Rs.499/- per hour',
      link:'/marketingconsultancy',
      src:'https://img.freepik.com/free-vector/female-multitasking-work_23-2148390868.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=sph',
      plans:''
    },
    {
      id:'4',
      title:'IT/Tech Consultancy',
      category: '(Consultancy)',
      description:'IT/tech consultancy helps businesses leverage technology to improve processes, security and innovation.',
      price : 'Rs.499/- per hour',
      link:'/ittechconsultancy',
      src:'https://img.freepik.com/free-vector/business-training-concept-vector-illustration-website-landing-page-isometric-businesswoman_1150-57083.jpg?w=740&t=st=1689540380~exp=1689540980~hmac=4f0fe379ed2f5aa9e253c82e1954ed3adeb00ff83636680c04f47dc0101140f9',
      plans:''
    },
    {
      id:'5',
      title:'HR Consultancy',
      category: '(Consultancy)',
      description:'HR consultancy helps businesses optimize their human resources through recruitment, retention, training and development.',
      price : 'Rs.499/- per hour',
      link:'/hrconsultancy',
      src:'https://img.freepik.com/free-vector/human-resources-hr-typographic-header-idea-recruitment-job-management-hr-manager-interviewing-job-candidate-flat-vector-illustration_613284-1240.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=ais',
      plans:''
    },

    {
      id:'6',
      title:'Sales Consultancy',
      category: '(Consultancy)',
      description:'Sales consultancy helps businesses optimize their sales processes, increase revenue and improve customer satisfaction.',
      price : 'Rs.499/- per hour',
      link:'/salesconsultancy',
      src:'https://img.freepik.com/free-vector/pitch-meeting-concept-illustration_114360-6300.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=ais',
      plans:''
      },
    
      {
        id:'7',
        title:'Data Consultancy',
        category: '(Consultancy)',
        description:'Data consultancy helps businesses analyze and leverage data to inform decision-making and improve performance.',
        price : 'Rs.499/- per hour',
        link:'/dataconsultancy',
        src:'https://img.freepik.com/free-vector/data-centers-are-data-centers-serve-applications-supporting-business-information-cloud-computing-technology-business-analysis-analytics-research-strategy-statistic-planning-marketing_1150-55220.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=ais',
        plans:''
      },
    
      {
        id:'8',
        title:'Design/ Branding Consultancy',
        category: '(Consultancy)',
        description:'Design/branding consultancy offers creative solutions to enhance brand identity, product design and customer experience.',
        price : 'Rs.499/- per hour',
        link:'/designbrandingconsultancy',
        src:'https://img.freepik.com/free-vector/new-company-trade-mark-registration-business-start-up-form-design-naming-brand-identity-building-process-company-formation-procedure-flat-vector-illustration_613284-1472.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=ais',
        plans:''
      },
      
      {
        id:'9',
        title:'E-Commerce Consultancy',
        category: '(Consultancy)',
        description:'E-commerce consultancy helps businesses optimize their online presence, improve customer experience and increase sales.',
        price : 'Rs.499/- per hour',
        link:'/ecommerceconsultancy',
        src:'https://img.freepik.com/free-vector/electricity-3d-composition_1284-24128.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=ais',
        plans:''
      },

  ]


  let Tax = [
    {
      id:'1',
      title:'GST Filing',
      category: '(Taxation)',
      description:'GST filing services help businesses comply with tax regulations and file GST returns accurately and timely.',
      price : 'Rs.1999/-',
      link:'/gstfiling',
      src:'https://img.freepik.com/free-vector/accountant-concept-professional-bookkeeper-tax-calculating-financial-analysis-business-character-making-financial-operation-vector-illustration_613284-2257.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=ais',
      plans:''
    },

    {
      id:'2',
      title:'Income Tax Filing',
      category: '(Taxation)',
      description:'ITR filing services help individuals and businesses comply with tax regulations and file income tax returns accurately and timely.',
      price : 'Rs.499/-',
      link:'/itrfiling',
      src:'https://img.freepik.com/free-vector/men-with-business-service-tax-tablet_24877-53418.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=ais',
      plans:''
    },
    

  ]


  let Legal = [
    {
      id:'1',
      title:'Rent Agreement Drafting',
      category: '(Legal)',
      description:'Rent agreement drafting services help landlords and tenants create legally binding agreements for rental properties.',
      price : 'Rs.999/-',
      link:'rentagreementdrafting',
      src:'https://img.freepik.com/free-vector/signing-contract-concept-illustration_114360-4879.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=ais',
      plans:''
    },

  ]

  let Others = [
    {
      id:'1',
      title:'Resume Building',
      category: '(Others)',
      description:'Resume building services help job seekers create professional resumes to improve their chances of getting hired.',
      price : 'Rs.599/-',
      link:'/resumebuilding',
      src:'https://img.freepik.com/free-vector/recruitment-concept-with-laptop_23-2148621834.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=ais',
      plans:''
    },

    {
      id:'2',
      title:'Pitch Deck Preparation',
      category: '(Others)',
      description:'Pitch deck preparation services help businesses create persuasive presentations to secure investments or partnerships.',
      price : 'Rs.1999/-',
      link:'pitchdeckcreation',
      src:'https://img.freepik.com/free-vector/seminar-concept-illustration_114360-7480.jpg?size=626&ext=jpg&ga=GA1.2.485285661.1639651736&semt=sph',
      plans:''
    },
    

  ]

  const [active, setActive] = useState("All");
  

  return (
    <>
    <div>
    <h2 style={{textAlign:"center",marginTop:"3rem"}}>OUR SERVICE OFFERINGS</h2>
        <div class="btn-toolbar d-flex justify-content-center mt-5 services-list-heading" role="toolbar" aria-label="Toolbar with button groups">
          <div class="col- btn-group mt-2 bg-light" id='button' aria-label="Third group">
            <button type="button" id='text' onClick={() => setActive("All")} class="btn text-dark">All</button>
          </div>

          <div class="col- btn-group mt-2 bg-light" id='button' aria-label="Third group">
            <button type="button" id='text' onClick={() => setActive("Marketing")} class="btn text-dark">Marketing</button>
          </div>
          <div class="col- btn-group mt-2 bg-light" id='button' aria-label="Third group">
            <button type="button" id='text' onClick={() => setActive("SoftwareSolutions")} class="btn text-dark">Software Solutions</button>
          </div>
          <div class="col- btn-group mt-2 bg-light" id='button' aria-label="Third group">
            <button type="button" id='text' onClick={() => setActive("WebsiteDevelopment")} class="btn text-dark">Website Development</button>
          </div>

          <div class="col- btn-group mt-2 bg-light" id='button' aria-label="Third group">
            <button type="button" id='text' onClick={() => setActive("Consultancy")} class="btn text-dark">Consultancy</button>
          </div>
          <div class="col- btn-group mt-2 bg-light" id='button' aria-label="Third group">
            <button type="button" id='text'  onClick={() => setActive("BusinessRegistrations")} class="btn text-dark">Business Registrations</button>
          </div>
          <div class="col- btn-group mt-2 bg-light" id='button' aria-label="Third group">
            <button type="button" id='text' onClick={() => setActive("Taxation")} class="btn text-dark">Taxation</button>
          </div>
          <div class="col- btn-group mt-2 bg-light" id='button' aria-label="Third group">
            <button type="button" id='text' onClick={() => setActive("Legal")} class="btn text-dark">Legal</button>
          </div>
          
          <div class="col- btn-group mt-2 bg-light" id='button' aria-label="Third group">
            <button type="button" id='text' onClick={() => setActive("Others")} class="btn text-dark">Others</button>
          </div>
          
        </div>
      {/* <button onClick={() => setActive("Firstcard")} type="button" class="btn btn-secondary bg-dark">1</button>
      <button onClick={() => setActive("Secondcard")} type="button" class="btn btn-secondary bg-dark">2</button>
      <button onClick={() => setActive("Thirdcard")} type="button" class="btn btn-secondary bg-dark">3</button> */}


    </div>

{/*Start of All Section */}
    
{active === "All" &&
    <div className="container services-list" id='marketing'>
        
          <div class="row row-cols-md-3 mt-5 g-4" > 
            <div class="col mt-3">
              <Cards title={All[0].title}  description={All[0].description} price={All[0].price} link={All[0].link} plans={All[0].plans} src={All[0].src} />
            </div>
            
            <div class="col mt-3">
              <Cards title={All[1].title}  description={All[1].description} price={All[1].price} link={All[1].link} plans={All[1].plans} src={All[1].src} />
            </div>
            
            <div class="col mt-3">
              <Cards title={All[2].title}  description={All[2].description} price={All[2].price} link={All[2].link} plans={All[2].plans} src={All[2].src} />
            </div>
            
            <div class="col mt-3">
              <Cards title={All[3].title}  description={All[3].description} price={All[3].price} link={All[3].link} plans={All[3].plans} src={All[3].src} />
            </div>
            
            <div class="col mt-3">
              <Cards title={All[4].title}  description={All[4].description} price={All[4].price} link={All[4].link} plans={All[4].plans} src={All[4].src} />
            </div>
            
            <div class="col mt-3">
              <Cards title={All[5].title}  description={All[5].description} price={All[5].price} link={All[5].link} plans={All[5].plans} src={All[5].src} />
            </div>
            
            <div class="col mt-3">
              <Cards title={All[6].title}  description={All[6].description} price={All[6].price} link={All[6].link} plans={All[6].plans} src={All[6].src} />
            </div>
            
            <div class="col mt-3">
              <Cards title={All[7].title}  description={All[7].description} price={All[7].price} link={All[7].link} plans={All[7].plans} src={All[7].src} />
            </div>
            
            <div class="col mt-3">
              <Cards title={All[8].title}  description={All[8].description} price={All[8].price} link={All[8].link} plans={All[8].plans} src={All[8].src} />
            </div>
          </div>
        
    </div>
      }
{/*End of All Section */}



{/*Start of Market Section */}
    
      {active === "Marketing" &&
    <div className="container" id='marketing'>
        
          <div class="row row-cols-md-3 mt-5 g-4"> 
            <div class="col mt-3">
              <Cards title={Market[0].title}  description={Market[0].description} price={Market[0].price} link={Market[0].link} plans={Market[0].plans} src={Market[0].src} />
            </div>
            
            <div class="col mt-3">
              <Cards title={Market[1].title}  description={Market[1].description} price={Market[1].price} link={Market[1].link} plans={Market[1].plans} src={Market[1].src} />
            </div>
            
            <div class="col mt-3">
              <Cards title={Market[2].title}  description={Market[2].description} price={Market[2].price} link={Market[2].link} plans={Market[2].plans} src={Market[2].src} />
            </div>
            
            <div class="col mt-3">
              <Cards title={Market[3].title}  description={Market[3].description} price={Market[3].price} link={Market[3].link} plans={Market[3].plans} src={Market[3].src} />
            </div>
            
            <div class="col mt-3">
              <Cards title={Market[4].title}  description={Market[4].description} price={Market[4].price} link={Market[4].link} plans={Market[4].plans} src={Market[4].src} />
            </div>
            
            <div class="col mt-3">
              <Cards title={Market[5].title}  description={Market[5].description} price={Market[5].price} link={Market[5].link} plans={Market[5].plans} src={Market[5].src} />
            </div>
            
            <div class="col mt-3">
              <Cards title={Market[6].title}  description={Market[6].description} price={Market[6].price} link={Market[6].link} plans={Market[6].plans} src={Market[6].src} />
            </div>
            
            <div class="col mt-3">
              <Cards title={Market[7].title}  description={Market[7].description} price={Market[7].price} link={Market[7].link} plans={Market[7].plans} src={Market[7].src} />
            </div>
            
            <div class="col mt-3">
              <Cards title={Market[8].title}  description={Market[8].description} price={Market[8].price} link={Market[8].link} plans={Market[8].plans} src={Market[8].src} />
            </div>
          </div>
        
    </div>
      }
{/*End of Market Section */}


{/*Start of IT & Software */}
      { active === "SoftwareSolutions" && 
      <div className="container">
        
        <div class="row row-cols-md-3 mt-5 g-4"> 
          <div class="col mt-3">
            <Cards title={IT[0].title} description={IT[0].description} price={IT[0].price} link={IT[0].link} plans={IT[0].plans} src={IT[0].src} />
          </div>
          
          <div class="col mt-3">
            <Cards title={IT[1].title} description={IT[1].description} price={IT[1].price} link={IT[1].link} plans={IT[1].plans} src={IT[1].src} />
          </div>
          
          <div class="col mt-3">
            <Cards title={IT[2].title} description={IT[2].description} price={IT[2].price} link={IT[2].link} plans={IT[2].plans} src={IT[2].src} />
          </div>
          
          <div class="col mt-3">
            <Cards title={IT[3].title} description={IT[3].description} price={IT[3].price} link={IT[3].link} plans={IT[3].plans} src={IT[3].src} />
          </div>
          
          <div class="col mt-3">
            <Cards title={IT[4].title} description={IT[4].description} price={IT[4].price} link={IT[4].link} plans={IT[4].plans} src={IT[4].src} />
          </div>
          
          <div class="col mt-3">
            <Cards title={IT[5].title} description={IT[5].description} price={IT[5].price} link={IT[5].link} plans={IT[5].plans} src={IT[5].src} />
          </div>
          
          <div class="col mt-3">
            <Cards title={IT[6].title} description={IT[6].description} price={IT[6].price} link={IT[6].link} plans={IT[6].plans} src={IT[6].src} />
          </div>
          
          <div class="col mt-3">
            <Cards title={IT[7].title} description={IT[7].description} price={IT[7].price} link={IT[7].link} plans={IT[7].plans} src={IT[7].src} />
          </div>
          
          <div class="col mt-3">
            <Cards title={IT[8].title} description={IT[8].description} price={IT[8].price} link={IT[8].link} plans={IT[8].plans} src={IT[8].src} />
          </div>
        </div>
    
      </div>
      
      
      }


{/*End of IT & Software */}


{/*Start of Website Development */}
      {active === "WebsiteDevelopment" && 
      
      <div className="container">
        
        <div class="row row-cols-md-3 mt-5 g-4"> 
          <div class="col mt-3">
            <Cards title={Web[0].title}  description={Web[0].description} price={Web[0].price} link={Web[0].link} plans={Web[0].plans} src={Web[0].src}  />
          </div>
          
          <div class="col mt-3">
            <Cards title={Web[1].title}  description={Web[1].description} price={Web[1].price} link={Web[1].link} plans={Web[1].plans} src={Web[1].src} />
          </div>
          
          <div class="col mt-3">
            <Cards title={Web[2].title}  description={Web[2].description} price={Web[2].price} link={Web[2].link} plans={Web[2].plans} src={Web[2].src} />
          </div>
          
          <div class="col mt-3">
            <Cards title={Web[3].title}  description={Web[3].description} price={Web[3].price} link={Web[3].link} plans={Web[3].plans} src={Web[3].src} />
          </div>
          
         
        </div>
    
      </div>
      
      
      }


{/*End of Website Development*/}

{/*Start of Consultancy */}

{active === "Consultancy" && 
      
      <div className="container">
        
        <div class="row row-cols-md-3 mt-5 g-4"> 
          <div class="col mt-3">
            <Cards title={consultancy[0].title} description={consultancy[0].description} price={consultancy[0].price} link={consultancy[0].link} plans={consultancy[0].plans} src={consultancy[0].src} />
          </div>
          
          <div class="col mt-3">
            <Cards title={consultancy[1].title} description={consultancy[1].description} price={consultancy[1].price} link={consultancy[1].link} plans={consultancy[1].plans} src={consultancy[1].src} />
          </div>
          
          <div class="col mt-3">
            <Cards title={consultancy[2].title} description={consultancy[2].description} price={consultancy[2].price} link={consultancy[2].link} plans={consultancy[2].plans} src={consultancy[2].src} />
          </div>
          
          <div class="col mt-3">
            <Cards title={consultancy[3].title} description={consultancy[3].description} price={consultancy[3].price} link={consultancy[3].link} plans={consultancy[3].plans} src={consultancy[3].src} />
          </div>

          <div class="col mt-3">
            <Cards title={consultancy[4].title} description={consultancy[4].description} price={consultancy[4].price} link={consultancy[4].link} plans={consultancy[4].plans} src={consultancy[4].src} />
          </div>

          <div class="col mt-3">
            <Cards title={consultancy[5].title} description={consultancy[5].description} price={consultancy[5].price} link={consultancy[5].link} plans={consultancy[5].plans} src={consultancy[5].src} />
          </div>

          <div class="col mt-3">
            <Cards title={consultancy[6].title} description={consultancy[6].description} price={consultancy[6].price} link={consultancy[6].link} plans={consultancy[6].plans} src={consultancy[6].src} />
          </div>

          <div class="col mt-3">
            <Cards title={consultancy[7].title} description={consultancy[7].description} price={consultancy[7].price} link={consultancy[7].link} plans={consultancy[7].plans} src={consultancy[7].src} />
          </div>

          <div class="col mt-3">
            <Cards title={consultancy[8].title} description={consultancy[8].description} price={consultancy[8].price} link={consultancy[8].link} plans={consultancy[8].plans} src={consultancy[8].src} />
          </div>
          
         
        </div>
    
      </div>
      
      
      }


{/*End of Consultancy*/}

{/*Start of Business Registration */}

{active === "BusinessRegistrations" && 
      
      <div className="container">
        
        <div class="row row-cols-md-3 mt-5 g-4"> 
          <div class="col mt-3">
            <Cards title={BR[0].title} description={BR[0].description} price={BR[0].price} link={BR[0].link} plans={BR[0].plans} src={BR[0].src} />
          </div>
          
          <div class="col mt-3">
            <Cards title={BR[1].title} description={BR[1].description} price={BR[1].price} link={BR[1].link} plans={BR[1].plans} src={BR[1].src} />
          </div>
          
          <div class="col mt-3">
            <Cards title={BR[2].title} description={BR[2].description} price={BR[2].price} link={BR[2].link} plans={BR[2].plans} src={BR[2].src} />
          </div>
          
          <div class="col mt-3">
            <Cards title={BR[3].title} description={BR[3].description} price={BR[3].price} link={BR[3].link} plans={BR[3].plans} src={BR[3].src} />
          </div>

          <div class="col mt-3">
            <Cards title={BR[4].title} description={BR[4].description} price={BR[4].price} link={BR[4].link} plans={BR[4].plans} src={BR[4].src} />
          </div>

          <div class="col mt-3">
            <Cards title={BR[5].title} description={BR[5].description} price={BR[5].price} link={BR[5].link} plans={BR[5].plans} src={BR[5].src} />
          </div>

          <div class="col mt-3">
            <Cards title={BR[6].title} description={BR[6].description} price={BR[6].price} link={BR[6].link} plans={BR[6].plans} src={BR[6].src} />
          </div>

          <div class="col mt-3">
            <Cards title={BR[7].title} description={BR[7].description} price={BR[7].price} link={BR[7].link} plans={BR[7].plans} src={BR[7].src} />
          </div>

          <div class="col mt-3">
            <Cards title={BR[8].title} description={BR[8].description} price={BR[8].price} link={BR[8].link} plans={BR[8].plans} src={BR[8].src} />
          </div>
          
         
        </div>
    
      </div>
      
      
      }


{/*End of Business Registration*/}


{/*Start of Taxation */}
{active === "Taxation" && 
      
      <div className="container">
        
        <div class="row row-cols-md-3 mt-5 g-4"> 
          <div class="col mt-3">
            <Cards title={Tax[0].title}  description={Tax[0].description} price={Tax[0].price} link={Tax[0].link} plans={Tax[0].plans} src={Tax[0].src} />
          </div>

          <div class="col mt-3">
            <Cards title={Tax[1].title}  description={Tax[1].description} price={Tax[1].price} link={Tax[1].link} plans={Tax[1].plans} src={Tax[1].src} />
          </div>
        </div>
    
      </div>
      
      
      }


{/* End of Taxation */}


{/*Start of Legal */}
{active === "Legal" && 
      
      <div className="container">
        
        <div class="row row-cols-md-3 mt-5 g-4"> 
          <div class="col mt-3">
            <Cards title={Legal[0].title} description={Legal[0].description} price={Legal[0].price} link={Legal[0].link} plans={Legal[0].plans} src={Legal[0].src} />
          </div>

        </div>
    
      </div>
      
      
      }


{/* End of Legal */}


{/*Start of Others */}
{active === "Others" && 
      
      <div className="container">
        
        <div class="row row-cols-md-3 mt-5 g-4"> 
          <div class="col mt-3">
            <Cards title={Others[0].title} description={Others[0].description} price={Others[0].price} link={Others[0].link} plans={Others[0].plans} src={Others[0].src} />
          </div>

          <div class="col mt-3">
            <Cards title={Others[1].title} description={Others[1].description} price={Others[1].price} link={Others[1].link} plans={Others[1].plans} src={Others[1].src}/>
          </div>
        </div>
    
      </div>
      
      
      }


{/* End of Others */}


    </>
  )
}

export default AllFeatures
