import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';
import moment from 'moment/moment';

import '../Resources/dashboard.css';
import Navbar from '../navbar';
import Sidebar from '../Sidebar';
import Create_bank from './create_bank';
import Add_bank from './add_bank';


import Chart from "chart.js/auto";
import { Line, Bar } from "react-chartjs-2";

import {useRef as useref} from 'react';
import {useReactToPrint} from 'react-to-print';

//EXCEL DOWNLOAD IMPORT
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

function Userbank(){

 //Report Print Function
 const componentRef = useref();
 const handlePrint = useReactToPrint({
   content: () => componentRef.current,
 });

 //Report Print Function 1
 const componentRef1 = useref();
 const handlePrint1 = useReactToPrint({
   content: () => componentRef1.current,
 });


 
 // Function to download the data as an Excel file
 const downloadExcel = () => {
  // Convert JSON data to Excel worksheet
  const worksheet = XLSX.utils.json_to_sheet(data);
  
  // Create a new workbook and add the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  
  // Convert the workbook to a binary array
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  
  // Create a Blob from the binary data and trigger a download
  const excel_data = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(excel_data, "bank_data.xlsx");
};




 // Function to download the bank_summary as an Excel file
 const downloadExcel1 = () => {
  // Convert JSON data to Excel worksheet
  const worksheet1 = XLSX.utils.json_to_sheet(bank_summary);
  
  // Create a new workbook and add the worksheet
  const workbook1 = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook1, worksheet1, "Sheet1");
  
  // Convert the workbook to a binary array
  const excelBuffer1 = XLSX.write(workbook1, { bookType: "xlsx", type: "array" });
  
  // Create a Blob from the binary data and trigger a download
  const excel_data1 = new Blob([excelBuffer1], { type: "application/octet-stream" });
  saveAs(excel_data1, "bank_summary.xlsx");
};

  
 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const busyid=sessionStorage.getItem("BusyId00");
 const counterid=sessionStorage.getItem("CounterId00");


 
 
{/* TRANSACTIONS TABLE COLUMNS MANAGEMENT VARIABLES START */}
const[cd_col1_val,setcd_col1_val] = useState(0);//column1
const[cd_col2_val,setcd_col2_val] = useState(0);//column2
const[cd_col3_val,setcd_col3_val] = useState(0);//column3
const[cd_col4_val,setcd_col4_val] = useState(0);//column4
const[cd_col5_val,setcd_col5_val] = useState(0);//column5
const[cd_col6_val,setcd_col6_val] = useState(0);//column6
const[cd_col7_val,setcd_col7_val] = useState(0);//column7
const[cd_col8_val,setcd_col8_val] = useState(0);//column8
const[cd_col9_val,setcd_col9_val] = useState(0);//column9
const[cd_col10_val,setcd_col10_val] = useState(0);//column10
const[cd_col11_val,setcd_col11_val] = useState(0);//column11
const[cd_col12_val,setcd_col12_val] = useState(0);//column12
{/*const[cd_col13_val,setcd_col13_val] = useState(0);//column13
const[cd_col14_val,setcd_col14_val] = useState(0);//column14 */}

{/* TRANSACTIONS TABLE COLUMNS MANAGEMENT VARIABLES END */}


 //Checking if Sessions are Set

// alert(logstatus,loguser,logadminid);
 //Sessions Not Set
 /*if(((logstatus==null)||(logstatus==""))||((logid==null)||(logid==""))||((token==null)||(token==" "))||((busyid==null)||(busyid==" "))||((counterid==null)||(counterid==" "))){
  
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");
   
 
    window.location.replace("/businesstools-login");
   }
  
   //Sessions Set
  else{ */



    
 //SETTING DEFAULT STATE VARIABLES FOR CHART */
 
 //LINECHART VARIABLES
 const[lc_summary,setlc_summary] = useState([],);
 const[lc_inflow,setlc_inflow] = useState([],);
 const[lc_outflow,setlc_outflow] = useState([],);
 const[lc_netcashflow,setlc_netcashflow] = useState([],);

 //Line Chart
 const[lc_date,setlc_date] = useState();
 const[lc_default,setlc_default] = useState(0);
 const[lc_start_date,setlc_start_date] = useState(0);const updatelc_start_date = (event) => { setlc_start_date(event.target.value); }; 
 const[lc_end_date,setlc_end_date] = useState(0);const updatelc_end_date = (event) => { setlc_end_date(event.target.value); }; 
 const[lc_date_error,setlc_date_error] = useState();

 //Bar Chart
 const[bc_default,setbc_default] = useState(0);
 const[bc_start_date,setbc_start_date] = useState(0);const updatebc_start_date = (event) => { setbc_start_date(event.target.value); }; 
 const[bc_end_date,setbc_end_date] = useState(0);const updatebc_end_date = (event) => { setbc_end_date(event.target.value); }; 
 const[bc_date_error,setbc_date_error] = useState();
 const[bc_entry,setbc_entry] = useState();
 const[bc_drawings,setbc_drawings] = useState();
 const[bc_incomes,setbc_incomes] = useState();
 const[bc_expenses,setbc_expenses] = useState();
 const[bc_sales,setbc_sales] = useState();
 const[bc_purchases,setbc_purchases] = useState();
 const[bc_salesreturn,setbc_salesreturn] = useState();
 const[bc_purchasereturns,setbc_purchasereturns] = useState();
 const[bc_creditorpayment,setbc_creditorpayment] = useState();
 const[bc_debtorpayment,setbc_debtorpayment] = useState();
 const[bc_osexpenses,setbc_osexpenses] = useState();
 const[bc_osincomes,setbc_osincomes] = useState();

      
//Variables
const[banks,setbanks] = useState([]);//SetData Variable
const[data,setData] = useState([]);//SetData Variable
const[bank_summary,setbank_summary] = useState([]);//Setbank_summary Variable

//Error Variable
const[errorvar,seterrorvar] = useState();



//FILTER VARIABLES
const[filter_val,setfilter_val] = useState(0);
const[filter_start_date,setfilter_start_date] = useState('1970-01-01'); const updatefilter_start_date = (event) => { setfilter_start_date(event.target.value);}; const[filter_start_date_er,setfilter_start_date_er]=useState();
const[filter_end_date,setfilter_end_date] = useState('2100-12-12'); const updatefilter_end_date = (event) => { setfilter_end_date(event.target.value);}; const[filter_end_date_er,setfilter_end_date_er]=useState();
const[filter_trno,setfilter_trno] = useState(); const updatefilter_trno = (event) => { setfilter_trno(event.target.value);}; const[filter_trno_er,setfilter_trno_er]=useState();
const[filter_transaction,setfilter_transaction] = useState(); const updatefilter_transaction = (event) => { setfilter_transaction(event.target.value);}; const[filter_transaction_er,setfilter_transaction_er]=useState();
const[filter_amount_in_sign,setfilter_amount_in_sign] = useState(); const updatefilter_amount_in_sign = (event) => { setfilter_amount_in_sign(event.target.value);}; const[filter_amount_in_sign_er,setfilter_amount_in_sign_er]=useState();
const[filter_amount_in,setfilter_amount_in] = useState(); const updatefilter_amount_in = (event) => { setfilter_amount_in(event.target.value);}; const[filter_amount_in_er,setfilter_amount_in_er]=useState();
const[filter_amount_out_sign,setfilter_amount_out_sign] = useState(); const updatefilter_amount_out_sign = (event) => { setfilter_amount_out_sign(event.target.value);}; const[filter_amount_out_sign_er,setfilter_amount_out_sign_er]=useState();
const[filter_amount_out,setfilter_amount_out] = useState(); const updatefilter_amount_out = (event) => { setfilter_amount_out(event.target.value);}; const[filter_amount_out_er,setfilter_amount_out_er]=useState();
const[filter_bank,setfilter_bank] = useState(); const updatefilter_bank = (event) => { setfilter_bank(event.target.value);}; const[filter_bank_er,setfilter_bank_er]=useState();
//PART 2 FILTER VARIABLES
const[filter_val1,setfilter_val1] = useState(0);
const[filter_start_date1,setfilter_start_date1] = useState('1970-01-01'); const updatefilter_start_date1 = (event) => { setfilter_start_date1(event.target.value);}; const[filter_start_date1_er,setfilter_start_date1_er]=useState();
const[filter_end_date1,setfilter_end_date1] = useState('2100-12-12'); const updatefilter_end_date1 = (event) => { setfilter_end_date1(event.target.value);}; const[filter_end_date1_er,setfilter_end_date1_er]=useState();
const[filter_bank_name,setfilter_bank_name] = useState(); const updatefilter_bank_name = (event) => { setfilter_bank_name(event.target.value);}; const[filter_bank_name_er,setfilter_bank_name_er]=useState();



//Variables for pagination
const [currentPage, setCurrentPage] = useState(1);
const [pageNumber, setpageNumber] = useState(1);


useEffect(()=>{
   
  getData();
  
  },[])
 
    
  
//******************* GET BANKBOOK DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))){
 
      //Fetching Filter Variables
      const $filter_start_date = filter_start_date;const $filter_end_date = filter_end_date;const $filter_trno = filter_trno;const $filter_transaction = filter_transaction;
      const $filter_amount_in_sign = filter_amount_in_sign;const $filter_amount_in = filter_amount_in;const $filter_amount_out_sign = filter_amount_out_sign;const $filter_amount_out = filter_amount_out;
      const $filter_bank = filter_bank; const $filter_bank_name = filter_bank_name; const $filter_start_date1 = filter_start_date1;const $filter_end_date1 = filter_end_date1
      
 
 axios.post("bt-get-bankbook-default", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Sn_start :0,
   Sn_end :0,
   Sn_total :0,
   Sn_limit :0,
   //Line Chart
   lc_default:lc_default,
   lc_start_date:moment(lc_start_date).format('YYYY-MM-DD'),
   lc_end_date:moment(lc_end_date).format('YYYY-MM-DD'),
   //Bar Chart
   bc_default:bc_default,
   bc_start_date:moment(bc_start_date).format('YYYY-MM-DD'),
   bc_end_date:moment(bc_end_date).format('YYYY-MM-DD'),
   //Filters
   Filter_val : filter_val,
   Filter_start_date : moment($filter_start_date).format('YYYY-MM-DD'),
   Filter_end_date : moment($filter_end_date).format('YYYY-MM-DD'),
   Filter_trno : $filter_trno,
   Filter_transaction : $filter_transaction,
   Filter_amount_in_sign : $filter_amount_in_sign,
   Filter_amount_in : $filter_amount_in,
   Filter_amount_out_sign : $filter_amount_out_sign,
   Filter_amount_out : $filter_amount_out,
   'Filter_bank' : $filter_bank,
   'Filter_val1' : filter_val1,
   'Filter_start_date1' : moment($filter_start_date1).format('YYYY-MM-DD'),
   'Filter_end_date1' : moment($filter_end_date1).format('YYYY-MM-DD'),
   'Filter_bank_name' : $filter_bank_name,
   //Code Edits for pagination
  'page' : pageNumber

 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setbanks(response.data.banks);
   setData(response.data.data.data);
   setbank_summary(response.data.bank_summary.data);

   const lc_summary = response.data.lc_summary;
   // Extract data for line chart
   setlc_date(lc_summary.map((entry) => entry.Date));
   setlc_inflow(lc_summary.map((entry) => entry.Inflow));
   setlc_outflow(lc_summary.map((entry) => entry.Outflow)); 
   //extract data for bar chart
   setbc_entry(response.data.bc_entry);
   setbc_drawings(response.data.bc_drawings);
   setbc_incomes(response.data.bc_incomes);
   setbc_expenses(response.data.bc_expenses);
   setbc_sales(response.data.bc_sales);
   setbc_purchases(response.data.bc_purchases);
   setbc_salesreturn(response.data.bc_salesreturn);
   setbc_purchasereturns(response.data.bc_purchasereturns);
   setbc_osincomes(response.data.bc_osincomes);
   setbc_osexpenses(response.data.bc_osexpenses);
   setbc_creditorpayment(response.data.bc_creditorpayment);
   setbc_debtorpayment(response.data.bc_debtorpayment);
  
  }
   //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){
    setfilter_start_date_er(response.data.validatorerror.Filter_start_date);
    setfilter_end_date_er(response.data.validatorerror.Filter_end_date);
    setfilter_trno_er(response.data.validatorerror.Filter_trno);
    setfilter_transaction_er(response.data.validatorerror.Filter_transaction);
    setfilter_amount_in_sign_er(response.data.validatorerror.Filter_amount_in_sign);
    setfilter_amount_in_er(response.data.validatorerror.Filter_amount_in);
    setfilter_amount_out_sign_er(response.data.validatorerror.Filter_amount_out_sign);
    setfilter_amount_out_er(response.data.validatorerror.Filter_amount_out);
    setfilter_bank_er(response.data.validatorerror.Filter_bank);
    setfilter_bank_name_er(response.data.validatorerror.Filter_bank_name);
    setfilter_start_date1_er(response.data.validatorerror.Filter_start_date1);
    setfilter_end_date1_er(response.data.validatorerror.Filter_end_date1);
  }

  //Data Violation
  if(response.data.error==102){ 
  
    
    setfilter_start_date_er(response.data.filter_start_date_er);
    setfilter_end_date_er(response.data.filter_end_date_er);
    setfilter_trno_er(response.data.filter_trno_er);
    setfilter_transaction_er(response.data.filter_transaction_er);
    setfilter_amount_in_sign_er(response.data.filter_amount_in_sign_er);
    setfilter_amount_in_er(response.data.filter_amount_in_er);
    setfilter_amount_out_er(response.data.filter_amount_out_er);
    setfilter_amount_out_sign_er(response.data.filter_amount_out_sign_er);
    setfilter_bank_er(response.data.filter_bank_er);
    setfilter_bank_name_er(response.data.filter_bank_name_er);
    setfilter_start_date1_er(response.data.filter_start_date1_er);
    setfilter_end_date1_er(response.data.filter_end_date1_er);
    

  }
  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* GET BANKBOOK DATA FUNCTION END  ********************//

 

 /* LINE CHART START */
 const data1 = {
  labels: lc_date,
  datasets: [
    {
      label: "Cash Outflow",
      backgroundColor: ["rgb(255, 99, 132)" ],
      borderColor: "rgb(255, 99, 132)",
      data: lc_outflow,
    },
    {
      label: "Cash Inflow",
      backgroundColor: ["rgb(54, 160, 255)"],
      borderColor: "rgb(54, 160, 255)",
      data: lc_inflow,
    },
  ],
};
/* LINE CHART END */ 

 /* BAR CHART VARIABLES START */
  const labels2 = ["Entry", "Drawings", "Sales", "Purchases", "Incomes", "Expenses","Salesreturn", "Purchasereturns", "Outstanding Expenses Payment", "Outstanding Income Payment", "Credtor Payment", "Debtor Payment"];

  const data2 = {
    labels: labels2,
    datasets: [
      {
        label: [],
        backgroundColor: ["#25d494", "#d42641", "#26cdd4", "#d45e26", "#010a9a", "#d943b9", "#d0d943", "#d98843", "#5c108f", "#828f10", "#86efff", "#ffe585" ],
        borderColor: "rgb(255, 99, 132)",
        data: [bc_entry, bc_drawings, bc_sales, bc_purchases, bc_incomes, bc_expenses, bc_salesreturn, bc_purchasereturns, bc_osexpenses, bc_osincomes, bc_creditorpayment, bc_debtorpayment],
      },
     
    ],
  };
  /* BAR CHART VARIABLES END */


 
//******************* GET BANK DATA BY ID FUNCTION START  ********************//



//ebank_name
const[ebank_name,setebank_name]=useState();const updateebank_name = (event) => { setebank_name(event.target.value);}; const[ebank_name_er,setebank_name_er]=useState();
//eaccount_type
const[eaccount_type,seteaccount_type]=useState();const updateeaccount_type = (event) => { seteaccount_type(event.target.value);}; const[eaccount_type_er,seteaccount_type_er]=useState();
//eaccount_no
const[eaccount_no,seteaccount_no]=useState();const updateeaccount_no = (event) => { seteaccount_no(event.target.value);}; const[eaccount_no_er,seteaccount_no_er]=useState();
//eaccount_ifsc
const[eaccount_ifsc,seteaccount_ifsc]=useState();const updateeaccount_ifsc = (event) => { seteaccount_ifsc(event.target.value);}; const[eaccount_ifsc_er,seteaccount_ifsc_er]=useState();
//eaccount_remarks
const[eaccount_remarks,seteaccount_remarks]=useState();const updateeaccount_remarks = (event) => { seteaccount_remarks(event.target.value);}; const[eaccount_remarks_er,seteaccount_remarks_er]=useState();
//ebranch
const[ebranch,setebranch]=useState();const updateebranch = (event) => { setebranch(event.target.value);}; const[ebranch_er,setebranch_er]=useState();



//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();




//Edit Function
function getbankdatabyId(){
 

  const bankId=sessionStorage.getItem("BankId00");

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&(busyid>0)&&(counterid>0)&&(bankId>0)){
 
 
 axios.post("bt-bank-details-byid", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Bank_id:bankId
 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");
     sessionStorage.removeItem("BankId00");


     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){

   //Setting Data
   setebank_name(response.data.bank_name);
   seteaccount_type(response.data.bank_type);
   seteaccount_ifsc(response.data.bank_ifsc);
   seteaccount_no(response.data.account_number);
   seteaccount_remarks(response.data.bank_remarks);
   setebranch(response.data.branch);

  
  }
  //Cannot Fetch Data
  else{


      document.getElementById("edit-form1").style.display="none";

     //Setting Variables to empty 
     setebank_name_er(" ");seteaccount_type_er(" ");seteaccount_no_er(" ");seteaccount_ifsc_er(" ");seteaccount_remarks_er();setebranch_er();seterror_msg(" ");
     setebank_name(" ");seteaccount_type(" ");seteaccount_no(" ");seteaccount_ifsc(" ");seteaccount_remarks();setebranch();


      document.getElementById("edit-error-card1").style.display="block"; 
      sessionStorage.removeItem("BankId00");
  }
 
 
 });
 
 }
 //User not Logedin
 else{

     //Checking if user loggedin
     if(((logstatus==null)||(logstatus==""))||((logid==null)||(logid==""))||((token==null)||(token==" "))){
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
     }
 
  }
 
 }
  
 //******************* GET BANK DATA BY ID FUNCTION END  ********************//

   
//******************* EDIT ADD BANK FUNCTION START ********************//
function editbank(){

  const bankId=sessionStorage.getItem("BankId00");
  //Setting Error Variables
setebank_name_er(" ");seteaccount_type_er(" ");seteaccount_no_er(" ");seteaccount_ifsc_er(" ");seteaccount_remarks_er();setebranch_er();seterror_msg(" ");



  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))&&((bankId!=null)||(bankId!=" "))){
 
 
  axios.post("bt-bank-edit", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Bank_name : ebank_name,
   Bank_type:eaccount_type,
   Bank_ifsc : eaccount_ifsc,
   Account_number:eaccount_no,
   Bank_remarks:eaccount_remarks,
   Branch:ebranch,
   Bank_id:bankId


  })
  .then((response) => {

  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");
     sessionStorage.removeItem("BankId00");

     window.location.replace("businesstools-login");
  }

   //Validation Errors
 if(response.data.validatorerror){
  setebank_name_er(response.data.validatorerror.Bank_name);
  seteaccount_type_er(response.data.validatorerror.Bank_type);
  seteaccount_ifsc_er(response.data.validatorerror.Bank_ifsc);
  seteaccount_no_er(response.data.validatorerror.Account_number);
  seteaccount_remarks_er(response.data.validatorerror.Bank_remarks);
  setebranch_er(response.data.validatorerror.Branch);

}
//Invalid Entries
if(response.data.error==102){ 
  
  setebank_name_er(response.data.bank_name_er);
  seteaccount_type_er(response.data.bank_type_er);
  seteaccount_ifsc_er(response.data.bank_ifsc_er);
  seteaccount_no_er(response.data.account_number_er);
  seteaccount_remarks_er(response.data.bank_remarks_er);
  setebranch_er(response.data.branch_er);
 
  seterror_msg("Cannot Edit Bank!");  }


  //Invalid Entries
  if(response.data.error==103){     seterror_msg(response.data.error_msg);  }

   //Bank Updated Successfully
   if(response.data.successvar==1){        

    document.getElementById("edit-form1").style.display="none";  
    setebank_name_er(" ");seteaccount_type_er(" ");seteaccount_no_er(" ");seteaccount_ifsc_er(" ");seteaccount_remarks_er();setebranch_er();seterror_msg(" ");
    setebank_name(" ");seteaccount_type(" ");seteaccount_no(" ");seteaccount_ifsc(" ");seteaccount_remarks();setebranch();

    
    document.getElementById("edit-success-card1").style.display="block";  }
   //Cannot AUpdate Bank
   else{  seterror_msg("Cannot Edit Bank !"); }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* EDIT ADD BANK FUNCTION END ********************//

 //******************* GET BANKBOOK DATA BY ID FUNCTION START  ********************//


//edate
const[edate,setedate]=useState();const updateedate = (event) => { setedate(event.target.value);}; const[edate_er,setedate_er]=useState();
//etype
const[etype,setetype]=useState();const updateetype = (event) => { setetype(event.target.value);}; const[etype_er,setetype_er]=useState();
//eparticular
const[eparticular,seteparticular]=useState();const updateeparticular = (event) => { seteparticular(event.target.value);}; const[eparticular_er,seteparticular_er]=useState();
//eamount
const[eamount,seteamount]=useState();const updateeamount = (event) => { seteamount(event.target.value);}; const[eamount_er,seteamount_er]=useState();
//ebank_id
const[ebank_id,setebank_id]=useState();const updateebank_id = (event) => { setebank_id(event.target.value);}; const[ebank_id_er,setebank_id_er]=useState();
//ebanknm_id
const[ebanknm_id,setebanknm_id]=useState();const updateebanknm_id = (event) => { setebanknm_id(event.target.value);}; const[ebanknm_id_er,setebanknm_id_er]=useState();



//Edit Function
function getcounterdatabyId(){
 

  const bankid=sessionStorage.getItem("Bankid00");

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&(busyid>0)&&(counterid>0)&&(bankid>0)){
 
 
 axios.post("bt-bankbook-details-byid", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Bank_id:bankid,

 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");
     sessionStorage.removeItem("Bankid00");


     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){

   //Setting Data
   setedate(response.data.date);
   setetype(response.data.type);
   seteparticular(response.data.particular);
   seteamount((response.data.amount_in)+(response.data.amount_out));
   setebank_id(response.data.bank_id);
   setebanknm_id(response.data.bank_name);
  
  }
  //Cannot Fetch Data
  else{


      document.getElementById("edit-form").style.display="none";

     //Setting Variables to empty 
     setedate_er(" ");setetype_er(" ");seteparticular_er(" ");seteamount_er(" ");setebank_id_er();seterror_msg(" ");   
     setedate(" ");setetype(" ");seteparticular(" ");seteamount(" ");setebank_id();setebanknm_id();

      document.getElementById("edit-error-card").style.display="block"; 
      sessionStorage.removeItem("Bankid00");
  }
 
 
 });
 
 }
 //User not Logedin
 else{

     //Checking if user loggedin
     if(((logstatus==null)||(logstatus==""))||((logid==null)||(logid==""))||((token==null)||(token==" "))){
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
     }
 
  }
 
 }
  
 //******************* GET BANKBOOK DATA BY ID FUNCTION END  ********************//

 

 //******************* EDIT BANKBOOK FUNCTION START ********************//
function editbankbook(){

  //Setting Error Variables
setedate_er(" ");setetype_er(" ");seteparticular_er(" ");seteamount_er(" ");seterror_msg(" ");setebank_id_er();

  const bankid = sessionStorage.getItem("Bankid00");
  const trno = sessionStorage.getItem("Trno00");

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))&&((bankid!=null)||(bankid!=" "))&&((trno!=null)||(trno!=" "))){
 
 
  axios.post("bt-bankbook-edit", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Cash_id:bankid,
   Tr_no:trno,
   Date : moment(edate).format('YYYY-MM-DD'),
   Type:etype,
   Bank_id:ebank_id,
   Particular : eparticular,
   Amount : eamount,
 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");
     sessionStorage.removeItem("Trno00");
     sessionStorage.removeItem("Bankid00");

     window.location.replace("businesstools-login");
  }

   //Validation Errors
 if(response.data.validatorerror){
  setedate_er(response.data.validatorerror.Date);
  setetype_er(response.data.validatorerror.Type);
  seteparticular_er(response.data.validatorerror.Particular);
  seteamount_er(response.data.validatorerror.Amount);
  setebank_id_er(response.data.validatorerror.Bank_id);


}
//Invalid Entries
if(response.data.error==102){ 
  
  setedate_er(response.data.date_er);
  setetype_er(response.data.type_er);
  seteparticular_er(response.data.particular_er);
  seteamount_er(response.data.amount_er);
  setebank_id_er(response.data.bank_id_er);
 
  seterror_msg("Cannot Update Transaction!");  }


  //Invalid Entries
  if(response.data.error==103){     seterror_msg(response.data.error_msg);  }

   //Transaction Updated Successfully
   if(response.data.successvar==1){        
     //Setting Variables to empty 
     setedate_er(" ");setetype_er(" ");seteparticular_er(" ");seteamount_er(" ");setebank_id_er();seterror_msg(" ");   
     setedate(" ");setetype(" ");seteparticular(" ");seteamount(" ");setebank_id();
     document.getElementById("edit-form").style.display="none"; 
    document.getElementById("edit-success-card").style.display="block";  }

   //Cannot Update Transaction
   else{  seterror_msg("Cannot Update Transaction !"); }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");
   sessionStorage.removeItem("Trno00");
   sessionStorage.removeItem("Bankid00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* EDIT BANKBOOK FUNCTION END ********************//

 
   
//******************* DELETE BANKBOOK FUNCTION START ********************//
function deletebankbook(){

  const cashId=sessionStorage.getItem("DCashId00");
  const dtrno=sessionStorage.getItem("DTrno00");

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))&&((cashId!=null)||(cashId!=" "))&&((dtrno!=null)||(dtrno!=" "))){
 
 axios.post("bt-bankbook-delete", {
   Busy_id:busyid,
   Counter_id:counterid,
   Log_id:logid,
   Token:token,
   Bank_id:cashId,
   Tr_no:dtrno
 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");
     sessionStorage.removeItem("DCashId00");
     sessionStorage.removeItem("DTrno00");

     window.location.replace("businesstools-login");
  }

  //Counter Deleted Successfully
   if(response.data.successvar==1){        

      document.getElementById("delete-card").style.display="none";
      document.getElementById("delete-success-card").style.display="block";  
      sessionStorage.removeItem("DCashId");
      sessionStorage.removeItem("DTrno00");

  }
  
      //Cannot Delete Counter
    else{  
    document.getElementById("delete-card").style.display="none";
    document.getElementById("delete-error-card").style.display="block";   }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");
   sessionStorage.removeItem("DCashId00");
   sessionStorage.removeItem("DTrno00");


   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* DELETE BANKBOOK FUNCTION END ********************//




return(
    <>
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Business Tools|BookKeeping Solution</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div class="container-scroller">

      <Sidebar />
    <div class="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div class="main-panel">

           {/* ADD BANK SUCCESS CARD START */}
           <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card1">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">New Bank Added Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <a href="businesstools-user-bank"><button class="mt-3 btn btn-outline-danger">Close</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* ADD BANK SUCCESS CARD END */}
                   {/* ADD BANK EDIT SUCCESS CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="edit-success-card1">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Bank Updated Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("edit-success-card1").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* ADD BANK EDIT SUCCESS CARD END */}
               {/* ADD BANK EDIT ERROR CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="edit-error-card1">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-danger">Cannot Fetch Data !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("edit-error-card1").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* ADD BANK EDIT ERROR CARD END */}





             {/* SUCCESS CARD START */}
          <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">New Transaction Added Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <a href="businesstools-user-bank"><button class="mt-3 btn btn-outline-danger">Close</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* SUCCESS CARD END */}
              {/* EDIT SUCCESS CARD START */}
             <div class="d-flex justify-content-center">
             <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="edit-success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Transaction Updated Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("edit-success-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* EDIT SUCCESS CARD END */}
               {/* EDIT ERROR CARD START */}
            <div class="d-flex justify-content-center">
            <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="edit-error-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-danger">Cannot Fetch Data !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("edit-error-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* EDIT ERROR CARD END */}

                  {/* DELETE CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-danger">Are you sure you want to delete this transaction?</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> This action cannot be reversed. You will lose all your data related to this transaction if you wish to continue this action.</p>
                      <button class="mt-3 btn btn-outline-success" onClick={deletebankbook}>Yes, I Know! Delete Transaction.</button>
                      <button class="mt-3 btn btn-outline-danger" style={{marginLeft:"1rem"}} onClick={()=>{  
                      //Cancel Delete and remove Counter_id
                      sessionStorage.removeItem("DCashId00");     sessionStorage.removeItem("DTrno00");  document.getElementById('delete-card').style.display = 'none'; }}>No, Don't Delete Transaction.</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* DELETE CARD END */}
              {/* DELETE SUCCESS CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Transaction Deleted Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("delete-success-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* DELETE SUCCESS CARD END */}
               {/*  DELETE ERROR CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-error-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-danger">Cannot Delete Transaction !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("delete-error-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* DELETE ERROR CARD END */}


          <div class="content-wrapper pb-0">
          
          {/* CREATE BANKBOOK TRANSACTION SECTION START */}
          <div class="row">
              <div class="col-12 grid-margin create-form" id="create-form">
              <Create_bank /> 
              </div>
              {/* CREATE BANKBOOK TRANSACTION SECTION END */}
              {/* ADD BANK SECTION START */}
              <div class="col-12 grid-margin create-form" id="create-form1">
              <Add_bank /> 
              </div>   
              {/* ADD BANK SECTION END */}
          </div>

            <div class="page-header flex-wrap">
            <div class="header-left"></div>
              <div class="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div class="d-flex align-items-center">
                  <a href="#">
                    <p class="m-0 pe-3">Bank</p>
                  </a>
                  <a class="ps-3 me-4" href="#">
                  {/*}  <p class="m-0">ADE-00234</p> */}
                  </a>
                </div>
                <button type="button" style={{backgroundColor:"#006b6b", marginRight:"1rem"}} class="btn btn-primary mt-2 mt-sm-0 btn-icon-text"  onClick={()=>{ 
               const createForm = document.getElementById('create-form1');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
               <i class="mdi mdi-plus-circle" style={{backgroundColor:"#014f4e"}}></i> Add New Bank </button>
                <button type="button" class="btn btn-primary mt-2 mt-sm-0 btn-icon-text" onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
                  <i class="mdi mdi-plus-circle"></i> New Transaction </button>

              </div>
            </div>

            {/*} chart row starts here */}
            <div class="row">
              {/* LINECHART START */}
              <div className="col-sm-6 stretch-card grid-margin">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                    <div className="card-title"> Counter Bank Cash Flow
                      </div>
                      <div className="d-flex text-muted font-20">
                        <i className="mdi mdi-arrow-down-drop-circle text-primary" onClick={()=>{ document.getElementById("lc-date-search-box").style.display="block"; }}></i>
                        <i className="mdi mdi-close-box text-danger"  onClick={()=>{ document.getElementById("lc-date-search-box").style.display="none"; }}></i>
                      
                      </div>
                    </div>
                    {/* LINE CHART DATE FILTER START */}
                    <div id="lc-date-search-box" style={{display:"none"}}>
                    <hr />
                    <p style={{marginTop:"2rem"}}>Make sure start date and end date are not more than 10 days apart for best results</p>
                    <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label"> Start Date</label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control-sm"  id="lc_start_date" value={lc_start_date} onChange={updatelc_start_date} />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">End Date</label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control-sm" id="lc_end_date" value={lc_end_date} onChange={updatelc_end_date} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <button class="btn btn-primary mb-4" onClick={()=>{ setlc_default(1); getData(); }}>Update</button>
                      <hr/>
                      </div>
                      {/* LINE CHART DATE FILTER END */}
              
                    <div className="line-chart-wrapper">
                  {/* CASH INFLOW OUTFLOW LINE CHART START */}
                  <Line data={data1} /> 
                  {/* CASH INFLOW OUTFLOW LINE CHART END */}
                    </div>
                  </div>
                </div>
              </div>
               {/* LINECHART END */}
              {/* BARCHART START */}
              <div className="col-sm-6 stretch-card grid-margin">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                    <div className="card-title"> Business Transactions Summary
                      </div>
                      <div className="d-flex text-muted font-20">
                      {/*}  <i className="mdi mdi-printer mouse-pointer"></i>
                        <i className="mdi mdi-help-circle-outline ms-2 mouse-pointer"></i> */}
                        <i className="mdi mdi-arrow-down-drop-circle text-primary" onClick={()=>{ document.getElementById("bc-date-search-box").style.display="block"; }}></i>
                        <i className="mdi mdi-close-box text-danger"  onClick={()=>{ document.getElementById("bc-date-search-box").style.display="none"; }}></i>
                      
                      </div>
                    </div>
                        {/*BAR CHART DATE FILTER START */}
                        <div id="bc-date-search-box" style={{display:"none"}}>
                    <hr />
                    <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label"> Start Date</label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control-sm"  id="bc_start_date" value={bc_start_date} onChange={updatebc_start_date} />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">End Date</label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control-sm" id="bc_end_date" value={bc_end_date} onChange={updatebc_end_date} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <button class="btn btn-primary mb-4" onClick={()=>{ setbc_default(1); getData(); }}>Update</button>
                      <hr/>
                      </div>
                      {/* BAR CHART DATE FILTER END */}
                    <div className="bar-chart-wrapper">
                      {/* SUMMARY BARCHART START */}
                      <Bar data={data2} />
                      {/* SUMMARY BARCHART END */}
                    </div>
                  </div>
                </div>
              </div>
              {/* BARCHART END */}
            </div>


             {/* BANKBOOK EDIT FORM START */}
             <div class="row">
              <div class="col-12 grid-margin create-form" id="edit-form" style={{display:"none"}}>
              <div class="card">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ 
                         //close edit form button
                         document.getElementById("edit-form").style.display="none";
                        //Setting Variables to empty
                        //Setting Variables to empty 
                        setedate_er(" ");setetype_er(" ");seteparticular_er(" ");seteamount_er(" ");seterror_msg(" ");   
                        setedate(" ");setetype(" ");seteparticular(" ");seteamount(" ");                     
                        sessionStorage.removeItem("Bankid00");
                        sessionStorage.removeItem("Trno00"); }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                  <div class="card-body">
                    <h4 class="card-title">Edit Bank Transaction</h4>
                    <div class="form-sample">
                      <p class="card-description">edit your bank transactions here</p>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" id="date" value={edate} onChange={updateedate} />
                              <code>{edate_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Transaction<code>*</code></label>
                            <div class="col-sm-9">
                              <select class="form-control" style={{padding:"1rem"}} placeholder="Select Transaction" id="etype" value={etype} onChange={updateetype}>
                              <option value={etype}>{etype}</option>
                              <option value="">Select Transaction</option>
                              <option value="entry">Entry</option>
                              <option value="drawings">Drawings</option>
                              </select>
                              <code>{etype_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="exampleTextarea1">Particular<code>*</code></label>
                        <textarea class="form-control" rows="2" id="eparticular" value={eparticular} onChange={updateeparticular} ></textarea>
                        <code>{eparticular_er}</code>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Amount<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="5000.00" id="eamount" value={eamount} onChange={updateeamount} />
                              <code>{eamount_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Select Bank<code>*</code></label>
                            <div class="col-sm-9">
                              <select class="form-control" placeholder="Select Bank" style={{padding:"1rem"}} id="ebank_id" value={ebank_id} onChange={updateebank_id}>
                              <option value={ebank_id}>{ebanknm_id}</option>
                              {
                              banks.map((curElm1,ind)=>{

                              return(
                              <>
                              <option value={curElm1.Bank_id}>{curElm1.Bank_name}</option>
                              </>
                              )
                              }
	 				   
	                         		)
                              }
                              </select>
                              <code>{ebank_id_er}</code>
                            </div>
                          </div>
                        </div>                      
                      </div>
                        
                      
                        <button class="btn btn-primary" style={{fontWeight:"bold"}} onClick={editbankbook}>Submit</button>
                      
                       
    
                     
                   
                    </div>
                  </div>
                </div>
              </div>
              </div>
              {/*BANKBOOK EDIT FORM END */}


              {/* BANKBOOK LIST SECTION START */}
             <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Bank Transactions</h4>
                    <p class="card-description">bank transaction details
                    </p>

    {/* FILTER SECTION START */}
    <hr/>
                            <div id="filter-top-card" class="row col-12" style={{textAlign:"left",display:"none"}}>
                            <div class="d-flex justify-content-between">
                            <div>
                            <h4 style={{padding:'1rem'}}>Filters</h4>
                           </div>
                           <div>
                    
                           <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("filter-top-card").style.display="none"; }}>
                           <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                           </button>
                           </div>
                           </div>
        
                        <div class="row">
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Start Date</label>
                            <div class="col-sm-8">
                              <input type="date" class="form-control" id="filter_start_date" value={filter_start_date} onChange={updatefilter_start_date}  />
                              <code>{filter_start_date_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">End Date</label>
                            <div class="col-sm-8">
                              <input type="date" class="form-control"  id="filter_end_date" value={filter_end_date} onChange={updatefilter_end_date}  />
                              <code>{filter_end_date_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Tr No.</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" placeholder="12345"   id="filter_trno" value={filter_trno} onChange={updatefilter_trno}  />
                              <code>{filter_trno_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-8">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Amount In</label>
                            <div class="col-sm-4">
                              <select class="form-control" style={{padding:"1rem"}} id="filter_amount_in_sign" value={filter_amount_in_sign} onChange={updatefilter_amount_in_sign}  >
                              <option className="dropdown-item" value=" ">All</option>
                              <option className="dropdown-item" value="equals">equals</option>
                              <option className="dropdown-item" value="more than" >more than</option>
                              <option className="dropdown-item" value="less than">less than</option>
                              <option className="dropdown-item" value="more than or equals" >more than or equals</option>
                              <option className="dropdown-item" value="less than or equals" >less than or equals</option>
                              </select>
                              <code>{filter_amount_in_sign_er}</code>
                            </div>
                            <div class="col-sm-4">
                              <input type="text" class="form-control" placeholder="5000.00" id="filter_amount_in" value={filter_amount_in} onChange={updatefilter_amount_in}   />
                              <code>{filter_amount_in_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Transaction</label>
                            <div class="col-sm-8">
                             <select class="form-control" style={{padding:"1rem"}} id="filter_transaction" value={filter_transaction} onChange={updatefilter_transaction} >
                             <option value=" ">Select Transaction</option>
                             <option value="entry">entry</option>
                             <option value="drawings">drawings</option>
                             <option value="income">income</option>
                             <option value="expense">expense</option>
                             <option value="sales">sales</option>
                             <option value="purchases">purchases</option>
                             <option value="salesreturn">salesreturn</option>
                             <option value="purchasereturns">purchasereturns</option>
                             <option value="debtor payment">debtor payment</option>
                             <option value="creditor payment">creditor payment</option>
                             <option value="outstanding income payment">outstanding income payment</option>
                             <option value="outstanding expense payment">outstanding expense payment</option>

                             </select>                              
                             <code>{filter_transaction_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                    
                      <div class="row">
                      <div class="col-md-8">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Amount Out</label>
                            <div class="col-sm-4">
                              <select class="form-control" style={{padding:"1rem"}} id="filter_amount_out_sign" value={filter_amount_out_sign} onChange={updatefilter_amount_out_sign} >
                              <option className="dropdown-item" value=" ">All</option>
                              <option className="dropdown-item" value="equals">equals</option>
                              <option className="dropdown-item" value="more than" >more than</option>
                              <option className="dropdown-item" value="less than">less than</option>
                              <option className="dropdown-item" value="more than or equals" >more than or equals</option>
                              <option className="dropdown-item" value="less than or equals" >less than or equals</option>
                              </select>
                              <code>{filter_amount_out_sign_er}</code>
                            </div>
                            <div class="col-sm-4">
                              <input type="text" class="form-control" placeholder="5000.00" id="filter_amount_out" value={filter_amount_out} onChange={updatefilter_amount_out}   />
                              <code>{filter_amount_out_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Select Bank</label>
                            <div class="col-sm-9">
                              <select class="form-control" style={{padding:"1rem"}} placeholder="Select Bank" id="filter_bank" value={filter_bank} onChange={updatefilter_bank}>
                              <option value=" ">Select Bank</option>
                              {
                              banks.map((curElm11,ind)=>{

                              return(
                              <>
                              <option value={curElm11.Bank_id}>{curElm11.Bank_name}</option>
                              </>
                              )
                              }
	 				   
	                         		)
                              }
                              </select>
                              <code>{filter_bank_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                      <button class="btn btn-primary" onClick={()=>{ setfilter_val(1); getData(); }}>Apply Filters</button>
                      <button class="btn btn-danger" style={{marginLeft:"1rem"}} onClick={()=>{ setfilter_val(0); getData(); }}>Remove Filters</button>
                      </div>

                            <hr/>                      
                            </div>
                            
                            {/* FILTER SECTION END */}

                    <div class="col-12" style={{marginBottom:"2rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                        {/* COLUMNS MANAGEMENT DROPDOWN START */}
                          <div class="dropdown">
                          <button class="btn btn-warning" onClick={()=>{ document.getElementById("filter-top-card").style.display="block"; }}><i class="mdi mdi-filter-outline"></i></button>
                            <button class="btn btn-primary dropdown-toggle" style={{marginLeft:"1rem"}} type="button" id="dropdownMenuIconButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="mdi mdi-table-large"></i>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuIconButton1">
                              <h6 class="dropdown-header">Columns</h6>
                              <hr/>
                              
                              {/* Column 1 */}
                              <li><div class="d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Sn</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c1_yes" onClick={()=>{setcd_col1_val(1); document.getElementById("cd_c1_yes").style.display="none"; document.getElementById("cd_c1_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c1_no" style={{ display:"none"}} onClick={()=>{ setcd_col1_val(0); document.getElementById("cd_c1_yes").style.display="block"; document.getElementById("cd_c1_no").style.display="none"; }}></i>
                              </div></div></li> 
                               {/* Column 2 */}
                               <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Tr No.</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c2_yes"  onClick={()=>{setcd_col2_val(1); document.getElementById("cd_c2_yes").style.display="none"; document.getElementById("cd_c2_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c2_no" style={{ display:"none"}} onClick={()=>{ setcd_col2_val(0); document.getElementById("cd_c2_yes").style.display="block"; document.getElementById("cd_c2_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 3 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Date</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c3_yes"  onClick={()=>{setcd_col3_val(1); document.getElementById("cd_c3_yes").style.display="none"; document.getElementById("cd_c3_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c3_no" style={{ display:"none"}} onClick={()=>{ setcd_col3_val(0); document.getElementById("cd_c3_yes").style.display="block"; document.getElementById("cd_c3_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 4 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Transaction</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c4_yes"  onClick={()=>{setcd_col4_val(1); document.getElementById("cd_c4_yes").style.display="none"; document.getElementById("cd_c4_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c4_no" style={{ display:"none"}} onClick={()=>{ setcd_col4_val(0); document.getElementById("cd_c4_yes").style.display="block"; document.getElementById("cd_c4_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 5 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Bank</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c5_yes"  onClick={()=>{setcd_col5_val(1); document.getElementById("cd_c5_yes").style.display="none"; document.getElementById("cd_c5_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c5_no" style={{ display:"none"}} onClick={()=>{ setcd_col5_val(0); document.getElementById("cd_c5_yes").style.display="block"; document.getElementById("cd_c5_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 6 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Particular</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c6_yes"  onClick={()=>{setcd_col6_val(1); document.getElementById("cd_c6_yes").style.display="none"; document.getElementById("cd_c6_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c6_no" style={{ display:"none"}} onClick={()=>{ setcd_col6_val(0); document.getElementById("cd_c6_yes").style.display="block"; document.getElementById("cd_c6_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 7 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Amount In</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c7_yes" onClick={()=>{setcd_col7_val(1); document.getElementById("cd_c7_yes").style.display="none"; document.getElementById("cd_c7_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c7_no" style={{ display:"none"}} onClick={()=>{ setcd_col7_val(0); document.getElementById("cd_c7_yes").style.display="block"; document.getElementById("cd_c7_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 8 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Amount Out</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c8_yes" onClick={()=>{setcd_col8_val(1); document.getElementById("cd_c8_yes").style.display="none"; document.getElementById("cd_c8_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c8_no" style={{ display:"none"}} onClick={()=>{ setcd_col8_val(0); document.getElementById("cd_c8_yes").style.display="block"; document.getElementById("cd_c8_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 9 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Updated By</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c9_yes" onClick={()=>{setcd_col9_val(1); document.getElementById("cd_c9_yes").style.display="none"; document.getElementById("cd_c9_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c9_no" style={{ display:"none"}} onClick={()=>{ setcd_col9_val(0); document.getElementById("cd_c9_yes").style.display="block"; document.getElementById("cd_c9_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 10 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Updated At</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c10_yes" onClick={()=>{setcd_col10_val(1); document.getElementById("cd_c10_yes").style.display="none"; document.getElementById("cd_c10_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c10_no" style={{ display:"none"}} onClick={()=>{ setcd_col10_val(0); document.getElementById("cd_c10_yes").style.display="block"; document.getElementById("cd_c10_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 11 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10 text-warning">Edit</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c11_yes" onClick={()=>{setcd_col11_val(1); document.getElementById("cd_c11_yes").style.display="none"; document.getElementById("cd_c11_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c11_no" style={{ display:"none"}} onClick={()=>{ setcd_col11_val(0); document.getElementById("cd_c11_yes").style.display="block"; document.getElementById("cd_c11_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 12 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10 text-danger">Delete</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c12_yes" onClick={()=>{setcd_col12_val(1); document.getElementById("cd_c12_yes").style.display="none"; document.getElementById("cd_c12_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c12_no" style={{ display:"none"}} onClick={()=>{ setcd_col12_val(0); document.getElementById("cd_c12_yes").style.display="block"; document.getElementById("cd_c12_no").style.display="none"; }}></i>
                              </div></div></li> 
                            {/*}  <div class="dropdown-divider"></div>
                              <button class="btn btn-danger">Close</button>*/}
                            </div> 
                          </div>
                          {/* COLUMNS MANAGEMENT DROPDOWN END */}

                          </div>
                          </div>

                    <div class="table-responsive" id="reports" ref={componentRef}>
                      <table class="table table-hover">
                        <thead>
                          <tr >
                            <th  style={{fontWeight:"bold"}} hidden={cd_col1_val==1}>Sn</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col2_val==1}>Tr No.</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col3_val==1}>Date</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col4_val==1}>Transaction</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col5_val==1}>Bank</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col6_val==1}>Particular</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col7_val==1}>Amount In</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col8_val==1}>Amount Out</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col9_val==1}>Updated By</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col10_val==1}>Updated At</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col11_val==1}></th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col12_val==1}></th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                       data.map((curElm,ind)=>{

                         return(
                         <>
                       
                        <tr key={curElm.Id}>
                            <td hidden={(logtype == "user")||(cd_col1_val==1)}>{curElm.Admin_sn}</td>
                            <td hidden={(logtype == "admin")||(cd_col1_val==1)}>{curElm.User_sn}</td>
                            <td hidden={cd_col2_val==1}>{curElm.Tr_no}</td>
                            <td hidden={cd_col3_val==1}>{curElm.Date}</td>
                            <td hidden={cd_col4_val==1}>
                            <label className="badge badge-danger" hidden={(curElm.Type == "entry")||(curElm.Type == "contra entry")||(curElm.Type == "income")||(curElm.Type == "expense")||(curElm.Type == "outstanding income payment")||(curElm.Type == "outstanding expense payment")||(curElm.Type == "sales")||(curElm.Type == "salesreturn")||(curElm.Type == "purchases")||(curElm.Type == "purchasereturns")||(curElm.Type == "creditor payment")||(curElm.Type == "debtor payment")||(curElm.Type == "asset sales")||(curElm.Type == "asset purchase")||(curElm.Type == "salary payment")}>{curElm.Type}</label>
                            <label className="badge badge-success" hidden={(curElm.Type == "drawings")||(curElm.Type== "contra entry")||(curElm.Type == "income")||(curElm.Type == "expense")||(curElm.Type == "outstanding income payment")||(curElm.Type == "outstanding expense payment")||(curElm.Type == "sales")||(curElm.Type == "salesreturn")||(curElm.Type == "purchases")||(curElm.Type == "purchasereturns")||(curElm.Type == "creditor payment")||(curElm.Type == "debtor payment")||(curElm.Type == "asset sales")||(curElm.Type == "asset purchase")||(curElm.Type == "salary payment")}>{curElm.Type}</label>
                            <label className="badge badge-info" hidden={(curElm.Type == "drawings")||(curElm.Type== "entry")||(curElm.Type == "income")||(curElm.Type == "expense")||(curElm.Type == "outstanding income payment")||(curElm.Type == "outstanding expense payment")||(curElm.Type == "sales")||(curElm.Type == "salesreturn")||(curElm.Type == "purchases")||(curElm.Type == "purchasereturns")||(curElm.Type == "creditor payment")||(curElm.Type == "debtor payment")||(curElm.Type == "asset sales")||(curElm.Type == "asset purchase")||(curElm.Type == "salary payment")}>{curElm.Type}</label>
                            <label className="badge badge-warning" hidden={(curElm.Type == "drawings")||(curElm.Type== "entry")||(curElm.Type == "contra entry")||(curElm.Type == "income")||(curElm.Type == "outstanding income payment")||(curElm.Type == "outstanding expense payment")||(curElm.Type == "sales")||(curElm.Type == "salesreturn")||(curElm.Type == "purchases")||(curElm.Type == "purchasereturns")||(curElm.Type == "creditor payment")||(curElm.Type == "debtor payment")||(curElm.Type == "asset sales")||(curElm.Type == "asset purchase")||(curElm.Type == "salary payment")}>{curElm.Type}</label>
                            <label className="badge badge-primary" hidden={(curElm.Type == "drawings")||(curElm.Type== "entry")||(curElm.Type == "expense")||(curElm.Type == "contra entry")||(curElm.Type == "outstanding income payment")||(curElm.Type == "outstanding expense payment")||(curElm.Type == "sales")||(curElm.Type == "salesreturn")||(curElm.Type == "purchases")||(curElm.Type == "purchasereturns")||(curElm.Type == "creditor payment")||(curElm.Type == "debtor payment")||(curElm.Type == "asset sales")||(curElm.Type == "asset purchase")||(curElm.Type == "salary payment")}>{curElm.Type}</label>
                            <label className="badge badge-primary" style={{backgroundColor:"#065f69",border:"none"}} hidden={(curElm.Type == "drawings")||(curElm.Type== "entry")||(curElm.Type == "expense")||(curElm.Type == "contra entry")||(curElm.Type == "income")||(curElm.Type == "outstanding expense payment")||(curElm.Type == "sales")||(curElm.Type == "salesreturn")||(curElm.Type == "purchases")||(curElm.Type == "purchasereturns")||(curElm.Type == "creditor payment")||(curElm.Type == "debtor payment")||(curElm.Type == "asset sales")||(curElm.Type == "asset purchase")||(curElm.Type == "salary payment")}>outstanding <br/> income payment</label>
                            <label className="badge badge-primary" style={{backgroundColor:"#c99c20",border:"none"}} hidden={(curElm.Type == "drawings")||(curElm.Type== "entry")||(curElm.Type == "expense")||(curElm.Type == "contra entry")||(curElm.Type == "outstanding income payment")||(curElm.Type == "income")||(curElm.Type == "sales")||(curElm.Type == "salesreturn")||(curElm.Type == "purchases")||(curElm.Type == "purchasereturns")||(curElm.Type == "creditor payment")||(curElm.Type == "debtor payment")||(curElm.Type == "asset sales")||(curElm.Type == "asset purchase")||(curElm.Type == "salary payment")}>    outstanding <br/> expense payment   </label>
                            <label className="badge badge-primary" style={{backgroundColor:"#b4179d",border:"none"}} hidden={(curElm.Type == "drawings")||(curElm.Type== "entry")||(curElm.Type == "expense")||(curElm.Type == "contra entry")||(curElm.Type == "outstanding income payment")||(curElm.Type == "income")||(curElm.Type == "outstanding expense payment")||(curElm.Type == "salesreturn")||(curElm.Type == "purchases")||(curElm.Type == "purchasereturns")||(curElm.Type == "creditor payment")||(curElm.Type == "debtor payment")||(curElm.Type == "asset sales")||(curElm.Type == "asset purchase")||(curElm.Type == "salary payment")}>{curElm.Type}</label>
                            <label className="badge badge-primary" style={{backgroundColor:"#08a1b5",border:"none"}} hidden={(curElm.Type == "drawings")||(curElm.Type== "entry")||(curElm.Type == "expense")||(curElm.Type == "contra entry")||(curElm.Type == "outstanding income payment")||(curElm.Type == "income")||(curElm.Type == "outstanding expense payment")||(curElm.Type == "sales")||(curElm.Type == "purchases")||(curElm.Type == "purchasereturns")||(curElm.Type == "creditor payment")||(curElm.Type == "debtor payment")||(curElm.Type == "asset sales")||(curElm.Type == "asset purchase")||(curElm.Type == "salary payment")}>{curElm.Type}</label>
                            <label className="badge badge-primary" style={{backgroundColor:"#df6150",border:"none"}} hidden={(curElm.Type == "drawings")||(curElm.Type== "entry")||(curElm.Type == "expense")||(curElm.Type == "contra entry")||(curElm.Type == "outstanding income payment")||(curElm.Type == "income")||(curElm.Type == "outstanding expense payment")||(curElm.Type == "salesreturn")||(curElm.Type == "sales")||(curElm.Type == "purchasereturns")||(curElm.Type == "creditor payment")||(curElm.Type == "debtor payment")||(curElm.Type == "asset sales")||(curElm.Type == "asset purchase")||(curElm.Type == "salary payment")}>{curElm.Type}</label>
                            <label className="badge badge-primary" style={{backgroundColor:"#f48ab2",border:"none"}} hidden={(curElm.Type == "drawings")||(curElm.Type== "entry")||(curElm.Type == "expense")||(curElm.Type == "contra entry")||(curElm.Type == "outstanding income payment")||(curElm.Type == "income")||(curElm.Type == "outstanding expense payment")||(curElm.Type == "sales")||(curElm.Type == "purchases")||(curElm.Type == "salesreturn")||(curElm.Type == "creditor payment")||(curElm.Type == "debtor payment")||(curElm.Type == "asset sales")||(curElm.Type == "asset purchase")||(curElm.Type == "salary payment")}>{curElm.Type}</label>
                            <label className="badge badge-primary" style={{backgroundColor:"#f5ea02",border:"none"}} hidden={(curElm.Type == "drawings")||(curElm.Type== "entry")||(curElm.Type == "expense")||(curElm.Type == "contra entry")||(curElm.Type == "outstanding income payment")||(curElm.Type == "income")||(curElm.Type == "outstanding expense payment")||(curElm.Type == "sales")||(curElm.Type == "purchases")||(curElm.Type == "salesreturn")||(curElm.Type == "purchasereturns")||(curElm.Type == "debtor payment")||(curElm.Type == "asset sales")||(curElm.Type == "asset purchase")||(curElm.Type == "salary payment")}>{curElm.Type}</label>
                            <label className="badge badge-primary" style={{backgroundColor:"#3d3482",border:"none"}} hidden={(curElm.Type == "drawings")||(curElm.Type== "entry")||(curElm.Type == "expense")||(curElm.Type == "contra entry")||(curElm.Type == "outstanding income payment")||(curElm.Type == "income")||(curElm.Type == "outstanding expense payment")||(curElm.Type == "sales")||(curElm.Type == "purchases")||(curElm.Type == "salesreturn")||(curElm.Type == "purchasereturns")||(curElm.Type == "creditor payment")||(curElm.Type == "asset sales")||(curElm.Type == "asset purchase")||(curElm.Type == "salary payment")}>{curElm.Type}</label>
                            <label className="badge badge-primary" style={{backgroundColor:"#b4179d",border:"none"}} hidden={(curElm.Type == "drawings")||(curElm.Type== "entry")||(curElm.Type == "expense")||(curElm.Type == "contra entry")||(curElm.Type == "outstanding income payment")||(curElm.Type == "income")||(curElm.Type == "outstanding expense payment")||(curElm.Type == "salesreturn")||(curElm.Type == "purchases")||(curElm.Type == "purchasereturns")||(curElm.Type == "creditor payment")||(curElm.Type == "debtor payment")||(curElm.Type == "sales")||(curElm.Type == "asset purchase")||(curElm.Type == "salary payment")}>{curElm.Type}</label>
                            <label className="badge badge-primary" style={{backgroundColor:"#df6150",border:"none"}} hidden={(curElm.Type == "drawings")||(curElm.Type== "entry")||(curElm.Type == "expense")||(curElm.Type == "contra entry")||(curElm.Type == "outstanding income payment")||(curElm.Type == "income")||(curElm.Type == "outstanding expense payment")||(curElm.Type == "salesreturn")||(curElm.Type == "sales")||(curElm.Type == "purchasereturns")||(curElm.Type == "creditor payment")||(curElm.Type == "debtor payment")||(curElm.Type == "asset sales")||(curElm.Type == "purchases")||(curElm.Type == "salary payment")}>{curElm.Type}</label>
                            <label className="badge badge-primary" style={{backgroundColor:"#2c0069",border:"none"}} hidden={(curElm.Type == "drawings")||(curElm.Type== "entry")||(curElm.Type == "expense")||(curElm.Type == "contra entry")||(curElm.Type == "outstanding income payment")||(curElm.Type == "income")||(curElm.Type == "outstanding expense payment")||(curElm.Type == "salesreturn")||(curElm.Type == "sales")||(curElm.Type == "purchasereturns")||(curElm.Type == "creditor payment")||(curElm.Type == "debtor payment")||(curElm.Type == "asset sales")||(curElm.Type == "purchases")||(curElm.Type == "asset purchase")}>{curElm.Type}</label>
                            </td>
                            <td hidden={cd_col5_val==1}>{curElm.Bank_name}</td>
                            <td hidden={cd_col6_val==1}>{curElm.Particular}</td>
                            <td hidden={cd_col7_val==1}>{curElm.Amount_in}</td>
                            <td hidden={cd_col8_val==1}>{curElm.Amount_out}</td>
                            <td hidden={cd_col9_val==1}>{curElm.Updated_by}</td>
                            <td hidden={cd_col10_val==1}>{curElm.Date_updated} ({curElm.Time_updated})</td>
                            <td hidden={cd_col11_val==1}><i  hidden={(curElm.Type!="entry")&&(curElm.Type!="drawings")} class="mdi mdi-pencil-box text-warning" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                        //Setting Bank Id and Redirect to Edit
                      sessionStorage.setItem("Bankid00",curElm.Id);sessionStorage.setItem("Trno00",curElm.Tr_no);getcounterdatabyId();document.getElementById('edit-form').style.display = 'block'; }}></i></td>
                            <td hidden={cd_col12_val==1}><i  hidden={(curElm.Type!="entry")&&(curElm.Type!="drawings")} class="mdi mdi-delete-forever text-danger" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                        //Setting Bank Id for Delete
                      sessionStorage.setItem("DCashId00",curElm.Id);      sessionStorage.setItem("DTrno00",curElm.Tr_no); document.getElementById('delete-card').style.display = 'block'; }}></i></td>
                          </tr>
                          </>
                         )
                         }
					   
	                  		 )
                         }
                          
                      
                        </tbody>
                      </table>
                    </div>
                      {/* PAGINATION SECTION START */}
                      <div>
                       <div class="col-12" style={{marginBottom:"2rem",marginTop:"1rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                       
                          <div class="dropdown">
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber-1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-left-drop-circle text-success"></i></button>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber+1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-right-drop-circle text-success"></i></button>
                          </div>
                          </div>
                          </div>
                    </div>
                    {/* PAGINATION SECTION END */}

                    <button class="btn btn-light" onClick={handlePrint}><i class="mdi mdi-cloud-print text-dark"></i></button>
                    <button class="btn btn-light" onClick={downloadExcel}><i class="mdi mdi-file-excel text-dark"></i></button>


                  </div>
                </div>
              </div>
              {/* BANKBOOK LIST SECTION END */}


              {/* ADD BANK EDIT FORM START */}
              <div class="row">
              <div class="col-12 grid-margin create-form" id="edit-form1" style={{display:"none"}}>
              <div class="card">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ 
                        //Removing Sessions and Closing Add Bank Edit
                        setebank_name_er(" ");seteaccount_type_er(" ");seteaccount_no_er(" ");seteaccount_ifsc_er(" ");seteaccount_remarks_er();setebranch_er();seterror_msg(" ");
                        setebank_name(" ");seteaccount_type(" ");seteaccount_no(" ");seteaccount_ifsc(" ");seteaccount_remarks();setebranch();
                        document.getElementById("edit-form1").style.display="none";
                        sessionStorage.removeItem("BankId00");
                        
                         }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                  <div class="card-body">
                    <h4 class="card-title">Edit Bank</h4>
                    <div class="form-sample">
                      <p class="card-description">enter your banks here</p>
                      <code>{error_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Bank Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="ABC Bank" id="ebank_name" editable="false" value={ebank_name}  />
                              <p class="text-muted" style={{textAlign:"left", fontSize:"0.7rem"}}>this field cannot be changed</p>
                              <code>{ebank_name_er}</code>
                            
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Account Type</label>
                            <div class="col-sm-9">
                              <select class="form-control" style={{padding:"1rem"}} placeholder="Select Account Type" id="eaccount_type" value={eaccount_type} onChange={updateeaccount_type}>
                              <option value="">Select Account Type</option>
                              <option value={eaccount_type}>{eaccount_type}</option>
                              <option value="Current">Current</option>
                              <option value="Savings">Savings</option>
                              </select>
                              <code>{eaccount_type_er}</code>
                            </div>
                          </div>
                        </div>   
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Account Number</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="5844414444444444" id="eaccount_no" value={eaccount_no} onChange={updateeaccount_no} />
                             <code>{eaccount_no_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">IFSC Code</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="HSBSY56555" id="eaccount_ifsc" value={eaccount_ifsc} onChange={updateeaccount_ifsc} />
                             <code>{eaccount_ifsc_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                    
                      <div class="form-group">
                        <label for="exampleTextarea1">Remarks</label>
                        <textarea  class="form-control"  rows="2" id="eaccount_remarks" value={eaccount_remarks} onChange={updateeaccount_remarks}></textarea>
                      <code>{eaccount_remarks_er}</code>
                      </div>
                 
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Branch</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Enter Branch" id="ebranch" value={ebranch} onChange={updateebranch} />
                            <code>{ebranch_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                        <button class="btn btn-primary" style={{fontWeight:"bold"}} onClick={editbank} >Edit Bank</button>
                        </div>                     
                      </div>
                   
                    </div>
                  </div>
                </div>
              </div>
              </div>
              {/* ADD BANK EDIT FORM END */}
         


              {/* BANKS SUMMARY SECTION START */}
              <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Bank Summary</h4>
                    <p class="card-description">bank summary
                    </p>

                            {/* FILTER SECTION 1 START */}
                            <hr/>
                            <div id="filter-top-card1" class="row col-12" style={{textAlign:"left",display:"none"}}>
                            <div class="d-flex justify-content-between">
                            <div>
                            <h4 style={{padding:'1rem'}}>Filters</h4>
                           </div>
                           <div>
                    
                           <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("filter-top-card1").style.display="none"; }}>
                           <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                           </button>
                           </div>
                           </div>
        
                        <div class="row">
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Start Date</label>
                            <div class="col-sm-8">
                              <input type="date" class="form-control" id="filter_start_date" value={filter_start_date1} onChange={updatefilter_start_date1}  />
                              <code>{filter_start_date1_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">End Date</label>
                            <div class="col-sm-8">
                              <input type="date" class="form-control"  id="filter_end_date" value={filter_end_date1} onChange={updatefilter_end_date1}  />
                              <code>{filter_end_date1_er}</code>
                            </div>
                          </div>
                        </div>
                      
                        <div class="col-md-4">
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Select Bank</label>
                            <div class="col-sm-9">
                              <select class="form-control" placeholder="Select Bank" style={{padding:"1rem"}} id="filter_bank_name" value={filter_bank_name} onChange={updatefilter_bank_name}>
                              <option value=" ">Select Bank</option>
                              {
                              banks.map((curElm12,ind)=>{

                              return(
                              <>
                              <option value={curElm12.Bank_id}>{curElm12.Bank_name}</option>
                              </>
                              )
                              }
	 				   
	                         		)
                              }
                              </select>
                              <code>{filter_bank_name_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                      <button class="btn btn-primary" onClick={()=>{ setfilter_val1(1); getData(); }}>Apply Filters</button>
                      <button class="btn btn-danger" style={{marginLeft:"1rem"}} onClick={()=>{ setfilter_val1(0); getData(); }}>Remove Filters</button>
                      </div>

                            <hr/>                      
                            </div>
                            
                            {/* FILTER SECTION 1 END */}

                    <div class="col-12" style={{marginBottom:"2rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                       
                          <div class="dropdown">
                          <button class="btn btn-warning" onClick={()=>{ document.getElementById("filter-top-card1").style.display="block"; }}><i class="mdi mdi-filter-outline"></i></button>
                          </div>
                          </div>
                          </div>

                    <div class="table-responsive" id="reports" ref={componentRef1}>
                      <table class="table table-striped">
                        <thead>
                          <tr >
                          {/*}  <th  style={{fontWeight:"bold"}}>Sn</th> */}
                            <th  style={{fontWeight:"bold"}}>Bank Id</th>
                            <th  style={{fontWeight:"bold"}}>Bank Name</th>
                           {/*} <th  style={{fontWeight:"bold"}}>IFSC Code</th>
                            <th  style={{fontWeight:"bold"}}>Account Number</th> */}
                            <th  style={{fontWeight:"bold"}}>Inflow</th>
                            <th  style={{fontWeight:"bold"}}>Outflow</th>
                            <th  style={{fontWeight:"bold"}}>Balance</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                       bank_summary.map((curElm1,ind)=>{

                      return(
                       <>
                        <tr key={curElm1.Bank_id}>
                           {/*} <td>{curElm1.Sn}</td> */}
                            <td>{curElm1.Bank_id}</td>
                            <td>{curElm1.Bank_name}</td>
                      {/*}  <td>{curElm1.Ifsc_code}</td>
                            <td>{curElm1.Account_number}</td> */}
                            <td>{parseFloat(curElm1.Amount_in).toFixed(2)}</td>
                            <td>{parseFloat(curElm1.Amount_out).toFixed(2)}</td>
                            <td class="text-success" hidden={(curElm1.Amount_in)<(curElm1.Amount_out)}>{parseFloat((curElm1.Amount_in)-(curElm1.Amount_out)).toFixed(2)}<i class="mdi mdi-arrow-up"></i></td>
                            <td class="text-danger" hidden={(curElm1.Amount_out)<=(curElm1.Amount_in)}>{parseFloat((curElm1.Amount_in)-(curElm1.Amount_out)).toFixed(2)}<i class="mdi mdi-arrow-down"></i></td>
                            <td><i class="mdi mdi-pencil-box text-warning" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                          //Setting Bank Id and Redirect to Edit
                           sessionStorage.setItem("BankId00",curElm1.Bank_id);getbankdatabyId();document.getElementById('edit-form1').style.display = 'block'; }}></i></td>
                          {/*}  <td><i class="mdi mdi-delete-forever text-danger" style={{fontSize:"1.4rem"}}></i></td> */}
                          </tr>
                          </>
                        )
                        }
					   
		                  	)
                        }
                          
                       
                        </tbody>
                      </table>
                    </div>
                      {/* PAGINATION SECTION START */}
                      <div>
                       <div class="col-12" style={{marginBottom:"2rem",marginTop:"1rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                       
                          <div class="dropdown">
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber-1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-left-drop-circle text-success"></i></button>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber+1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-right-drop-circle text-success"></i></button>
                          </div>
                          </div>
                          </div>
                    </div>
                    {/* PAGINATION SECTION END */}

                    <button class="btn btn-light" onClick={handlePrint1}><i class="mdi mdi-cloud-print text-dark"></i></button>
                    <button class="btn btn-light" onClick={downloadExcel1}><i class="mdi mdi-file-excel text-dark"></i></button>

                  </div>
                </div>
              </div>
            {/* BANKS SUMMARY SECTION END */}

            
          </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
        {/*}  <footer class="footer">
            <div class="d-sm-flex justify-content-center justify-content-sm-between">
              <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2023 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
</>
);

//}

}
export default Userbank;