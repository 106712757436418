import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

//import './Resources/serviceshomecss.css';
//import './Resources/home2css.css';
import Footer1 from './Supportingfiles/footer1';

//import './test123.css';

function Home2(){


      return(
      <>


<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />

    <meta name="theme-color" content="#000000" />
    <meta
      name="description"
      content="Get your startup services relating to website development, consultancy, taxation, business registration, software services, intern hiring, social media management, digital marketing and much more.  "></meta>
    
   {/* <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />*/}
        <link rel="icon" href="./images/startupconnect_icon.ico" />
    <link rel="apple-touch-icon" href="./images/startupconnect_icon.png" />

      {/* plugins:css */}
      <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/* endinject */}
    {/* Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/* End plugin css for this page */}
    {/* inject:css */}
    {/* endinject */}
    {/* Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/* End layout styles */}
    <link rel="shortcut icon" href="./images/startupconnect_icon.png" />


    {/*} Bootstrap CSS */}
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous" />
 

    <title>Startup Connect | Business Tools, Services, Networks, Hiring</title>
  </head>
  <body>

     <div class="home2-container">

     {/* NAVBAR START */}
     {/* DEFAULT NAVBAR START */}
     <div class="home2-navbar" id="home2-navbar-default">
     <div style={{display:"flex",flexDirection:"row", justifyContent:"space-between"}}>
     <div>
     <ul style={{display:"flex"}}>
     <li><img src="/home1/weblogo1.png" id="home2-navbar-logo" ></img></li>
     <li style={{ fontFamily: "Open Sans, sans-serif", fontWeight: "bold"}}><span style={{color:"#5d17eb"}}>Startup </span>Connect</li>
     </ul>
     </div>
     <div>
        <ul style={{display:"flex",flexDirection:"row"}}>
        <li><a href="#home2-services-section" style={{textDecoration:"none",color:"#000000"}}>Services</a></li>
            <li><a href="#home2-tools-section" style={{textDecoration:"none",color:"#000000"}}>Tools</a></li>
            <li><a href="#home2-networking-section" style={{textDecoration:"none",color:"#000000"}}>Network</a></li>
            <li><a href="/about-us" style={{textDecoration:"none",color:"#000000"}}>About Us</a></li>
            <li><a href="https://www.startuphire.in" style={{textDecoration:"none",color:"#000000"}}>Hiring</a></li>
            <li><a href="/contest-home" style={{textDecoration:"none",color:"#000000"}}>Contests</a></li>
            <li><a href="/login" style={{textDecoration:"none",color:"#000000"}}><button id="home2-navbar-login">Login</button></a></li>
            <li><a href="/businesstools-admin-register" style={{textDecoration:"none",color:"#000000"}}><button id="home2-navbar-register">Register</button></a></li>
        </ul>
     </div>
     </div>
     </div>
     {/* DEFAULT NAVBAR END */}

    {/* DEFAULT NAVBAR START */}
   <div class="home2-navbar" id="home2-navbar-changed">
   <div style={{display:"flex",flexDirection:"row", justifyContent:"space-between"}}>
     <div>
    
     </div>
     <div>
       <img src="https://cdn-icons-png.flaticon.com/128/9109/9109758.png" style={{width:"3rem",height:"3rem"}} onClick={()=>{   const createForm = document.getElementById('home2-display-menu');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }} />
     </div>
     </div>

     <div id="home2-display-menu">
     <div style={{display:"flex",flexDirection:"row", justifyContent:"space-between"}}>
     <div>
     <ul style={{display:"flex"}}>
     <li><img src="/home1/weblogo1.png" id="home2-navbar-logo" ></img></li>
     <li style={{ fontFamily: "Open Sans, sans-serif", fontWeight: "bold"}}><span style={{color:"#5d17eb"}}>Startup </span>Connect</li>
     </ul>
     </div>
     <div>
        <ul>
        <li><a href="#home2-services-section" style={{textDecoration:"none",color:"#000000"}}>Services</a></li>
            <li><a href="#home2-tools-section" style={{textDecoration:"none",color:"#000000"}}>Tools</a></li>
            <li><a href="#home2-networking-section" style={{textDecoration:"none",color:"#000000"}}>Network</a></li>
            <li><a href="/about-us" style={{textDecoration:"none",color:"#000000"}}>About Us</a></li>
            <li><a href="https://www.startuphire.in" style={{textDecoration:"none",color:"#000000"}}>Hiring</a></li>
            <li><a href="/contest-home" style={{textDecoration:"none",color:"#000000"}}>Contests</a></li>
            <li><a href="/login" style={{textDecoration:"none",color:"#000000"}}><button id="home2-navbar-login">Login</button></a></li>
            <li><a href="/businesstools-admin-register" style={{textDecoration:"none",color:"#000000"}}><button id="home2-navbar-register">Register</button></a></li>

        </ul>
     </div>
     </div>
     </div>
     </div>
     {/* DEFAULT NAVBAR END */}



     {/* NAVBAR END */}

       {/* HEADER SECTION START */}
       <section class="home2-header-section">
          {/* MAIN HEADER SECTION START */}
          <div class="home2-header-main">


                <div class="home2-header-main-left">
                <h1>Shaping the Future of Central India's Startup Ecosystem!</h1>
                <p>Embark on your startup journey with Startup Connect. Whether you're a seasoned entrepreneur or just starting, our platform provides the resources and support you need to turn your vision into reality. Join us in shaping the future of Central India's startup landscape.</p>
                <p> Let's connect, collaborate, and create success together.</p>
                <a href="#home2-services-section"><button id="home2-header-main-btn">Services <i class="mdi mdi-arrow-right"></i></button></a>
                <a href="#home2-networking-section"><button id="home2-header-main-btn" style={{backgroundImage: "linear-gradient(to right, #8d1777, #ad0082)"}}>Network <i class="mdi mdi-arrow-right"></i></button></a>
                <a href="https://www.startuphire.in"><button id="home2-header-main-btn" style={{backgroundImage: "linear-gradient(to right, #d4ba37, #fadc13)"}}>Hiring <i class="mdi mdi-arrow-right"></i></button></a>
                <a href="#home2-tools-section"><button id="home2-header-main-btn" style={{backgroundImage: "linear-gradient(to right, #8d3717, #ad3900)"}}>Tools <i class="mdi mdi-arrow-right"></i></button></a>
                <a href="/contest-home"><button id="home2-header-main-btn" style={{backgroundImage: "linear-gradient(to right, #208d17, #00ad11)"}}>Contests <i class="mdi mdi-arrow-right"></i></button></a>

                </div>
                <div class="home2-header-main-right">
               <button style={{backgroundColor:"#ffffff",border:"none"}} >
               <a aria-label="Chat on WhatsApp" href="https://wa.me/918480002413?text=I%20want%20to%20know%20more%20about%20the%20Startup%20Connect">
                <img src="https://cdn-icons-png.flaticon.com/128/3536/3536445.png" id="home2-wtp-icon"></img></a>
                </button> 
                </div>

            

            </div>
            {/* MAIN HEADER SECTION END */}

         
        </section>
        {/* HEADER SECTION END */}
  
        <hr/> 

           {/* MODULES SECTION START */}
           <div class="home2-modules-section">
        <h1>START YOUR JOURNEY!</h1>
        <p>Explore the myriad ways we can help elevate your startup to new heights.</p>

        {/* SERVICES SECTION START */}
        <div class="home2-services-section" id="home2-services-section">
        <div class="home2-services-section-container">
            <div class="home2-services-section-left">
                <h2>Startup Connect Services</h2>
                <p>Explore the diverse range of services offered by Startup Connect and take your startup to new heights. Whether you're looking to build a robust legal framework, enhance your brand presence, or optimize your operations, we have the services and expertise you need.</p>
                <p>Join Startup Connect Services today and empower your startup for success!</p>
                <div class="home2-services-features-list">
                    <div class="home2-services-fcard">
                       <div class="home2-services-fcard-left">
                        <img src="https://cdn-icons-png.flaticon.com/128/1087/1087840.png" id="home2-services-fcard-icon" />
                       </div>
                       <div class="home2-services-fcard-right">
                       <h3>500+</h3>
                       <h5 style={{fontWeight:"bold"}}>Projects</h5>
                       </div> 
                    </div>
                

                    <div class="home2-services-fcard">
                       <div class="home2-services-fcard-left">
                        <img src="https://cdn-icons-png.flaticon.com/128/12735/12735475.png" id="home2-services-fcard-icon" />
                       </div>
                       <div class="home2-services-fcard-right">
                       <h3>25+</h3>
                       <h5 style={{fontWeight:"bold"}}>Cities</h5>
                       </div> 
                    </div>
             

                    <div class="home2-services-fcard">
                       <div class="home2-services-fcard-left">
                        <img src="https://cdn-icons-png.flaticon.com/128/2991/2991235.png" id="home2-services-fcard-icon" />
                       </div>
                       <div class="home2-services-fcard-right">
                       <h3>4 <img src="https://cdn-icons-png.flaticon.com/128/7656/7656139.png" style={{height:"2rem",width:"2rem"}} />+</h3>
                       <h5 style={{fontWeight:"bold"}}>Google Ratings</h5>
                       </div> 
                    </div>

                </div>
                <a href="/home"><button id="home2-modules-section-btn">Get Started with Services <i class="mdi mdi-arrow-right"></i></button></a>
            </div>
            <div class="home2-services-section-right">
        {/*}    <img src="https://media.istockphoto.com/id/1144645520/photo/busy-call-centre-in-operation.jpg?b=1&s=612x612&w=0&k=20&c=vib4nWV1c_PLKXZa6aqHDEU-fVBg6-WeIGfmik2zj2E=" style={{height:"60%",marginTop:"20%",width:"96%",marginRight:"4%"}} />
            <iframe width="560" height="315" style={{height:"60%",marginTop:"20%",width:"96%",marginRight:"4%"}} src="https://youtu.be/JFsDijcTm3U?si=qG4Bb201EsXa_6EJ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        */}  <iframe style={{height:"60%",marginTop:"20%",width:"96%",marginRight:"4%"}}  src="https://www.youtube.com/embed/JFsDijcTm3U?si=trT6QjXlNjmru7TD" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        </div> 
        </div>
        {/* SERVICES SECTION END */}

        <hr/>

        <hr />
       {/* TOOLS SECTION START */}
       <div class="home2-tools-section" id="home2-tools-section">
       <h2>Startup Connect Business Tools</h2>
       <p style={{fontWeight:"normal"}}>Discover the full range of tools in our Business Tools Module and empower your startup for success. Whether you're a small business or a growing enterprise, find the solutions tailored to your needs.</p>
       <p style={{fontWeight:"normal"}}>Start your journey today!</p>
            <div class="home2-tools-section-container">
            <div class="home2-tools-section-left">
            <img src="/home1/Adds/acc-1.png" style={{height:"50%",marginTop:"15%",width:"96%",marginLeft:"4%",boxShadow:"#b0b0b0 0.2rem 0.2rem 0.3rem 0.2rem",borderRadius:"15px"}} />
            </div>
            <div class="home2-tools-section-right">
            <h3>Accounting Software</h3>
            <p>Managing your finances is a breeze with our powerful accounting software. Stay on top of your financial health and make informed decisions for the future.</p>

            <div class="home2-tools-fcard">
                <div class="home2-tools-fcard-left">
                <img src="https://cdn-icons-png.flaticon.com/128/4954/4954479.png" id="home2-tools-fcard-icon" />
                </div>
                <div class="home2-tools-fcard-right">
                    <h5>Easy Management:</h5>
                    <p>Navigate through your financial data effortlessly with our user-friendly interface. No steep learning curves – just straightforward tools designed to make managing your finances a breeze.</p>
                </div>
            </div>

            <div class="home2-tools-fcard">
                <div class="home2-tools-fcard-left">
                <img src="https://cdn-icons-png.flaticon.com/128/11331/11331591.png" id="home2-tools-fcard-icon" />
                </div>
                <div class="home2-tools-fcard-right">
                    <h5>Cost Efficient:</h5>
                    <p>We believe that every startup should have access to robust accounting tools without breaking the bank. Choose from our affordable pricing plans that scale with your business.</p>
                </div>
            </div>

            <a href="/businesstools-login"><button id="home2-modules-section-btn" style={{backgroundImage: "linear-gradient(to right, #208d17, #00ad11)"}}>Get Started for Free<i class="mdi mdi-arrow-right"></i></button></a>
           <br />
            <small style={{marginTop:'1rem'}}>no credit card and app installation required*</small>

            </div>
           
            </div>
         
       </div>
       {/* TOOLS SECTION END */}
       <hr />


        {/* NETWORK SECTION START */}
        <div class="home2-network-section" id="home2-networking-section">
       <h2>Network with Startup Connect</h2>
       <p style={{fontWeight:"normal"}}>At Startup Connect, we believe in the transformative power of connections. Our platform is more than just a network; it's a dynamic ecosystem designed to connect startups with experienced mentors, foster collaboration, and propel innovation. Whether you're a budding entrepreneur or a seasoned mentor, Startup Connect is your gateway to limitless possibilities.</p>
       

       <div class="home2-network-container">

        <div class="home2-network-fcard">
            <div class="home2-network-fcard-top">
                <div class="home2-networrk-fcard-top-l">
                <img src="https://cdn-icons-png.flaticon.com/128/10010/10010599.png" style={{height:"5rem",width:"5rem", marginRight:"2rem" }} />
                </div>
                <div class="home2-network-fcard-top-r">
                    <h3>Mentor, Advisor and Investor</h3>
                </div>
            </div>
            <div class="home2-network-fcard-content">
                <p style={{fontWeight:"normal"}}>Elevate your impact by joining Startup Connect as a mentor. Your experience is a guiding light for emerging entrepreneurs, and our platform is the ideal stage to share your wisdom. Connect with a dynamic community of mentors, expand your influence, and contribute to the next wave of innovation. Ready to shape the future and leave a lasting legacy? Join us now and inspire the leaders of tomorrow. Your mentorship journey begins with a simple sign-up.</p>

                <button id="home2-modules-section-btn" style={{backgroundImage: "linear-gradient(to right, #8d1777, #ad0082)",float:"right"}} onClick={()=>{ sessionStorage.setItem("NetworkVal00","mentor"); window.location.replace("/networking-login"); }}>I'm a Mentor<i class="mdi mdi-arrow-right"></i></button>

            </div>
           
        </div>


        <div class="home2-network-fcard" style={{marginTop:"1rem"}}>
            <div class="home2-network-fcard-top">
                <div class="home2-networrk-fcard-top-l">
                <img src="https://cdn-icons-png.flaticon.com/128/5064/5064971.png" style={{height:"5rem",width:"5rem", marginRight:"2rem" }} />
                </div>
                <div class="home2-network-fcard-top-r">
                    <h3>Startup or Business</h3>
                </div>
            </div>
            <div class="home2-network-fcard-content">
                <p style={{fontWeight:"normal"}}>Embark on a transformative journey with Startup Connect, where your startup dreams come to life. Ready to showcase your vision to a global audience, connect with experienced mentors, and explore funding opportunities? It's time to turn your ideas into reality. Join us and supercharge your entrepreneurial aspirations. Your breakthrough awaits—create your profile now and let's build the future together!</p>

                <button id="home2-modules-section-btn" style={{backgroundImage: "linear-gradient(to right, #17888d, #004aad)",float:"right"}} onClick={()=>{ sessionStorage.setItem("NetworkVal00","startup"); window.location.replace("/networking-login"); }}>I'm a Startup<i class="mdi mdi-arrow-right"></i></button>

            </div>
           
        </div>



       </div>


       </div>
       {/* NETWORK SECTION END */}

      <hr />
         {/* CONTESTS SECTION START */}
       {/*}  <div class="home2-contest-section">
       <h1>Why Choose Us ?</h1>
        <p>Startup Connect has been regularly working to improve its efficeiency and provide best place to users to find all their resources.</p>
         
       
       <iframe style={{width:"80vw", height:"70vh"}} src="https://www.youtube.com/embed/GLG4-YQuPlw?si=oyLucN3hVsJ0FSp9" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
       

       <button id="home2-header-main-btn" style={{backgroundImage: "linear-gradient(to right, #d4ba37, #fadc13)",width:"12rem,marginTop:0"}}>Get Started<i class="mdi mdi-arrow-right"></i></button>
      </div>
      */}
       {/* CONTESTS SECTION END */}

       <hr />

            {/* HIRING SECTION START */}
        {/*}    <div class="home2-hiring-section">
       <h2>Startuphire.in by StartupConnect</h2>
       <p>your one stop solution for all hiring needs. Get quality talent hired with ease. No need to spend hours searching for right talent.</p>

        <div class="home2-hiring-container">
        <div class="home2-hiring-section-left">
        <img src="https://images.pexels.com/photos/1595385/pexels-photo-1595385.jpeg?auto=compress&cs=tinysrgb&w=600" id="home2-hiring-side-img"  />
        </div>
        <div class="home2-hiring-section-right">
         
         <div class="home2-hiring-card">
            <div class="home2-hiring-card-left">
            <img src="https://cdn-icons-png.flaticon.com/128/5064/5064971.png" id="home2-hiring-card-icon" />
            </div>
            <div class="home2-hiring-card-right">
                <h4>Fast Hiring</h4>
                <p>fast hiring process in the cart. To be continued as it is by the individuals</p>
            </div>
         </div>

         <div class="home2-hiring-card" style={{backgroundImage:"linear-gradient(to right, #cd8802, #d6c919)"}}>
         
            <div class="home2-hiring-card-right">
                <h4  style={{textAlign:"right"}}>Fast Hiring</h4>
                <p  style={{textAlign:"right"}}>fast hiring process in the cart. To be continued as it is by the individuals</p>
            </div>
            <div class="home2-hiring-card-left">
            <img src="https://cdn-icons-png.flaticon.com/128/5064/5064971.png" id="home2-hiring-card-icon" />
            </div>
         </div>

         <div class="home2-hiring-card" style={{backgroundImage:"linear-gradient(to right, #17868d, #00adad)"}}>
            <div class="home2-hiring-card-left">
            <img src="https://cdn-icons-png.flaticon.com/128/5064/5064971.png" id="home2-hiring-card-icon" />
            </div>
            <div class="home2-hiring-card-right">
                <h4>Fast Hiring</h4>
                <p>fast hiring process in the cart. To be continued as it is by the individuals</p>
            </div>
         </div>

       

        </div>

        </div>

        <button id="home1-header-main-btn" style={{backgroundImage: "linear-gradient(to right, #29b442, #18d85f)",width:"19rem"}}>Get Started as Employer<i class="mdi mdi-arrow-right"></i></button>
        <button id="home1-header-main-btn" style={{backgroundImage: "linear-gradient(to right, #8d1777, #ad0082)",width:"19rem"}}>Get Started as Candidate<i class="mdi mdi-arrow-right"></i></button>


       </div> */}
       {/* HIRING SECTION END */}
       <hr />

        </div>
        {/* MODULES SECTION END */}


       
        
        {/* FEATURES SECTION START */}
        <div class="home2-features-section">
        <h1>Why Choose Us ?</h1>
        <p>Startup Connect has been regularly working to improve its efficeiency and provide</p>
        <p>best place to users to find all their resources.</p>
      
      {/* Features Row 1 Start */}
        <div class="home2-features-row">
        <div class="home2-features-card" style={{borderImage: "linear-gradient(to right, #17868d, #00adad) 1" }}>
                <div class="home2-features-card-top">
                <div class="home2-features-card-top-left" style={{backgroundImage: "linear-gradient(to right, #17868d, #00adad)"}}>
               
                <img src="/home1/monitor.png" id="home-feature-card-icon"  />
                </div>
                <div class="home2-features-card-top-right">
                <h3 style={{color:"#1F8989"}}>Support</h3>
                </div>
                </div>
                <hr />
                <div class="home2-features-card-content">
                    <p>Your success is our priority. Count on Startup Connect's dedicated customer support for prompt assistance and guidance. Navigate the platform with confidence, knowing help is just a message away.</p>
                </div>
            </div>

            <div class="home2-features-card" style={{borderImage: "linear-gradient(to right, #8d3717, #ad3900) 1" }}>
                <div class="home2-features-card-top">
                <div class="home2-features-card-top-left" style={{backgroundImage: "linear-gradient(to right, #8d3717, #ad3900)"}}>
               
                <img src="/home1/monitor.png" id="home-feature-card-icon" />
                </div>
                <div class="home2-features-card-top-right">
                <h3 style={{color:" #ad3900"}}>Efficiency</h3>
                </div>
                </div>
                <hr />
                <div class="home2-features-card-content">
                    <p>Boost your team's productivity with Startup Connect's streamlined task management and user-friendly interfaces. Navigate projects effortlessly, minimize manual efforts, and enhance overall work efficiency.</p>
                </div>
            </div>


            <div class="home2-features-card" style={{borderImage: "linear-gradient(to right, #208d17, #00ad11) 1" }}>
                <div class="home2-features-card-top">
                <div class="home2-features-card-top-left" style={{backgroundImage: "linear-gradient(to right, #208d17, #00ad11)"}}>
               
                <img src="/home1/monitor.png" id="home-feature-card-icon" />
                </div>
                <div class="home2-features-card-top-right">
                <h3 style={{color:"#1BEB17"}}>Affordable</h3>
                </div>
                </div>
                <hr />
                <div class="home2-features-card-content">
                    <p>Experience powerful collaboration without breaking the bank. Startup Connect offers cost-effective solutions, ensuring that teams of all sizes can access essential features and benefit from a collaborative platform in their budget.</p>
                </div>
            </div>

        </div>
        {/* Features Row 1 End */}

              {/* Features Row 2 Start */}
              <div class="home2-features-row">
            <div class="home2-features-card">
                <div class="home2-features-card-top">
                <div class="home2-features-card-top-left">
               
                <img src="/home1/calendar.png" id="home-feature-card-icon" />
                </div>
                <div class="home2-features-card-top-right">
                <h3>Scheduling</h3>
                </div>
                </div>
                <hr />
                <div class="home2-features-card-content">
                    <p>Coordinate seamlessly with Startup Connect's integrated scheduling tools. Effortlessly plan team meetings, track project milestones, and set deadlines to  take control of your schedule and keep your team on the path to success.</p>
                </div>
            </div>


              <div class="home2-features-card" style={{borderImage: "linear-gradient(to right, #d4ba37, #fadc13) 1" }}>
                <div class="home2-features-card-top">
                <div class="home2-features-card-top-left" style={{backgroundImage: "linear-gradient(to right, #d4ba37, #fadc13)"}}>
               
                <img src="/home1/monitor.png" id="home-feature-card-icon" />
                </div>
                <div class="home2-features-card-top-right">
                <h3 style={{color:"#fadc13"}}>Monitoring</h3>
                </div>
                </div>
                <hr />
                <div class="home2-features-card-content">
                    <p>Keep projects on track with Startup Connect's robust monitoring tools. Gain real-time insights into project progress, identify deviations, and ensure timely delivery. From task tracking to milestone achievements, stay in control with Startup Connect's monitoring capabilities.</p>
                </div>
            </div>


            <div class="home2-features-card" style={{borderImage: "linear-gradient(to right, #2c3ab4, #0300ad) 1" }}>
                <div class="home2-features-card-top">
                <div class="home2-features-card-top-left" style={{backgroundImage: "linear-gradient(to right, #2c3ab4, #0300ad)"}}>
           
                <img src="/home1/monitor.png" id="home-feature-card-icon" />
                </div>
                <div class="home2-features-card-top-right">
                <h3 style={{color:"#024BA9",marginLeft:"1rem"}}>Management</h3>
                </div>
                </div>
                <hr />
                <div class="home2-features-card-content">
                    <p>Take control of your projects with Startup Connect's robust management capabilities. Efficiently allocate resources, assign tasks, and oversee project timelines empowering team leaders to manage projects effectively.</p>
                </div>
            </div>



     
        </div>
        {/* Features Row 2 End */}

        </div>
        {/* FEATURES SECTION END */}

        <Footer1 />
      
    </div> 

      {/*} Optional JavaScript; choose one of the two! */}

    {/*} Option 1: Bootstrap Bundle with Popper */}
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>

    {/*} Option 2: Separate Popper and Bootstrap JS */}
    {/*}
    <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js" integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p" crossorigin="anonymous"></script>
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.min.js" integrity="sha384-cVKIPhGWiC2Al4u+LWgxfKTRIcfu0JTxR+EQDz/bgldoEyl4H0zUF0QKbrJ0EcQF" crossorigin="anonymous"></script>
    */}

    

    {/* plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/* endinject */}
    {/* Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/* End plugin js for this page */}
    {/* inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/* endinject */}
    {/* Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/* End custom js for this page */}

  </body>
</html>
</>
      );

}

export default Home2;