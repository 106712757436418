import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import moment from 'moment/moment';


import '../Resources/dashboard.css';
import axios from '../../axios.js';
import Navbar from '../navbar';


 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");

 //Other Constatants of Sessions
 const startup_key=sessionStorage.getItem("StartupKey10");
 const startup_id=sessionStorage.getItem("StartupId10");

function Startupedit2(){


  const[data,setData] = useState([]);//SetData Variable
  //Page Number
 const[pageNumber,setpageNumber] = useState();

 
//Error Variable
const[errorvar,seterrorvar] = useState();


useEffect(()=>{
   
  getData();
  
  },[])
  
//******************* GET TEAM DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((startup_id!=null)||(startup_id!=" "))&&((startup_key!=null)||(startup_key!=" "))){
 
 axios.post("network-get-startup-team-default", {
   Log_id:logid,
   Token:token,
   Startup_id:startup_id,
   Startup_key:startup_key
   })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");

     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setData(response.data.data);
  }

  //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){
    seterrorvar("Validation error!");

  }

  //Data Violation
  if(response.data.error==102){ 
  
  seterrorvar("Violation Error");


  }


  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("logName00");
   sessionStorage.removeItem("StartupId00");
   sessionStorage.removeItem("StartupKey00");
   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* GET TEAM DATA FUNCTION END  ********************//




//member_name
const[member_name,setmember_name]=useState();const updatemember_name = (event) => { setmember_name(event.target.value);}; const[member_name_er,setmember_name_er]=useState();
//contact
const[contact,setcontact]=useState();const updatecontact = (event) => { setcontact(event.target.value);}; const[contact_er,setcontact_er]=useState();
//email
const[email,setemail]=useState();const updateemail = (event) => { setemail(event.target.value);};  const[email_er,setemail_er]=useState();
//role
const[role,setrole]=useState();const updaterole = (event) => { setrole(event.target.value);}; const[role_er,setrole_er]=useState();
//experience
const[experience,setexperience]=useState();const updateexperience = (event) => { setexperience(event.target.value);}; const[experience_er,setexperience_er]=useState();
//duration
const[duration,setduration]=useState();const updateduration = (event) => { setduration(event.target.value);};  const[duration_er,setduration_er]=useState();
//linkedin
const[linkedin,setlinkedin]=useState();const updatelinkedin = (event) => { setlinkedin(event.target.value);}; const[linkedin_er,setlinkedin_er]=useState();
//type
const[type,settype]=useState();const updatetype = (event) => { settype(event.target.value);}; const[type_er,settype_er]=useState();
//working
const[working,setworking]=useState();const updateworking = (event) => { setworking(event.target.value);};  const[working_er,setworking_er]=useState();
//gender
const[gender,setgender]=useState();const updategender = (event) => { setgender(event.target.value);}; const[gender_er,setgender_er]=useState();
//introduction
const[introduction,setintroduction]=useState();const updateintroduction = (event) => { setintroduction(event.target.value);}; const[introduction_er,setintroduction_er]=useState();
//date_of_birth
const[date_of_birth,setdate_of_birth]=useState();const updatedate_of_birth = (event) => { setdate_of_birth(event.target.value);};  const[date_of_birth_er,setdate_of_birth_er]=useState();
//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();




/***************** ADD TEAM MEMBER FUNCTION START  ******************/
function register(){

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))&&((startup_id!=null)||(startup_id!=" "))&&((startup_key!=null)||(startup_key!=" "))){


//Setting Error Variables
setmember_name_er(" "); setemail_er(" ");setcontact_er(" ");setrole_er(" ");setexperience_er(" ");setduration_er();setlinkedin_er();settype_er();setworking_er();setgender_er();setdate_of_birth_er();setintroduction_er();
;seterror_msg(" ");


//Fetching Form Data
let noerror = 0;
const $member_name = document.getElementById("member_name").value; if($member_name==""){ setmember_name_er("Member Name is required !");noerror = noerror+1; }//Fetching member_name Value
const $email = document.getElementById("email").value; if($email==""){ setemail_er("Email is required !");noerror = noerror+1; }//Fetching Email Value
const $contact = document.getElementById("contact").value; if($contact==""){ setcontact_er("Contact is required !");noerror = noerror+1; }//Fetching contact Value
const $role = document.getElementById("role").value; if($role==""){ setrole_er("Role is required !");noerror = noerror+1; }//Fetching role Value
const $experience = document.getElementById("experience").value; if($experience==""){ setexperience_er("Experience is required !");noerror = noerror+1; }//Fetching experience Value
const $duration = document.getElementById("duration").value; if($duration==""){ setduration_er("Duration is required !");noerror = noerror+1; }//Fetching Confirm Password Value
const $gender = document.getElementById("gender").value; if($gender==""){ setgender_er("Gender is required !");noerror = noerror+1; }//Fetching gender Value
const $date_of_birth = document.getElementById("date_of_birth").value; if($date_of_birth==""){ setdate_of_birth_er("Date of Birth is required !");noerror = noerror+1; }//Fetching date_of_birth Value
const $introduction = document.getElementById("introduction").value; if($introduction==""){ setintroduction_er("Introduction is required !");noerror = noerror+1; }//Fetching introduction Value
const $linkedin = document.getElementById("linkedin").value; //Fetching linkedin Value
const $type = document.getElementById("type").value; //Fetching type Value
const $working = document.getElementById("working").value; //Fetching working Value

//No primary errors
if(noerror==0){


//Sending Data through axios
axios.post("network-register-startup-team", { 
Log_id:logid,
Token:token,
Startup_id:startup_id,
Startup_key:startup_key,
Member_name:$member_name,
Email:$email,
Contact:$contact,
Role:$role,
Duration:$duration,
Experience:$experience,
Gender:$gender,
Date_of_birth:moment($date_of_birth).format('YYYY-MM-DD'),
Summary:$introduction,
Type:$type,
Working:$working,
Linkedin:$linkedin

})

.then((response) => {

//Validation Errors
if(response.data.validatorerror){
setmember_name_er(response.data.validatorerror.Member_name);
setcontact_er(response.data.validatorerror.Contact);
setemail_er(response.data.validatorerror.Email);
setrole_er(response.data.validatorerror.Role);
setduration_er(response.data.validatorerror.Duration);
setexperience_er(response.data.validatorerror.Experience);
setgender_er(response.data.validatorerror.Gender);
setdate_of_birth_er(response.data.validatorerror.Date_of_birth);
setintroduction_er(response.data.validatorerror.Summary);
settype_er(response.data.validatorerror.Type);
setworking_er(response.data.validatorerror.Working);
setlinkedin_er(response.data.validatorerror.Linkedin);

seterror_msg("Team Member Not Added !");


}
//Invalid Entries
if(response.data.error==102){ 

setmember_name_er(response.data.member_name_er);
setcontact_er(response.data.contact_er);
setemail_er(response.data.email_er);
setrole_er(response.data.role_er);
setduration_er(response.data.duration_er);
setexperience_er(response.data.experience_er);
setgender_er(response.data.gender_er);
setdate_of_birth_er(response.data.date_of_birth_er);
setintroduction_er(response.data.summary_er);
settype_er(response.data.type_er);
setworking_er(response.data.working_er);
setlinkedin_er(response.data.linkedin_er);
seterror_msg("Team Member Not Added !");


}

//Mentor Already Exists
if(response.data.error==104){     seterror_msg(response.data.error_msg); return; }



//Success
if(response.data.successvar==1){


//window.location.replace("/businesstools-startworking");
document.getElementById("success-card").style.display="block";

}
//failure
if(response.data.successvar==2){
seterror_msg("Team Member Not Added !");
}
}    );

}

  }
 //User not Logedin
else{

//Remove Session Variables and redirect to login
sessionStorage.removeItem("logStatus00");
sessionStorage.removeItem("logId00");
sessionStorage.removeItem("logToken00");
sessionStorage.removeItem("logName00");
sessionStorage.removeItem("StartupId00");
sessionStorage.removeItem("StartupKey00");

window.location.replace("/businesstools-login");


}




}
/***************** ADD TEAM MEMBER FUNCTION END  ******************/


/* EDIT VARIABLES */


//emember_name
const[emember_name,setemember_name]=useState();const updateemember_name = (event) => { setemember_name(event.target.value);}; const[emember_name_er,setemember_name_er]=useState();
//econtact
const[econtact,setecontact]=useState();const updateecontact = (event) => { setecontact(event.target.value);}; const[econtact_er,setecontact_er]=useState();
//eemail
const[eemail,seteemail]=useState();const updateeemail = (event) => { seteemail(event.target.value);};  const[eemail_er,seteemail_er]=useState();
//erole
const[erole,seterole]=useState();const updateerole = (event) => { seterole(event.target.value);}; const[erole_er,seterole_er]=useState();
//eexperience
const[eexperience,seteexperience]=useState();const updateeexperience = (event) => { seteexperience(event.target.value);}; const[eexperience_er,seteexperience_er]=useState();
//eduration
const[eduration,seteduration]=useState();const updateeduration = (event) => { seteduration(event.target.value);};  const[eduration_er,seteduration_er]=useState();
//elinkedin
const[elinkedin,setelinkedin]=useState();const updateelinkedin = (event) => { setelinkedin(event.target.value);}; const[elinkedin_er,setelinkedin_er]=useState();
//etype
const[etype,setetype]=useState();const updateetype = (event) => { setetype(event.target.value);}; const[etype_er,setetype_er]=useState();
//eworking
const[eworking,seteworking]=useState();const updateeworking = (event) => { seteworking(event.target.value);};  const[eworking_er,seteworking_er]=useState();
//egender
const[egender,setegender]=useState();const updateegender = (event) => { setegender(event.target.value);}; const[egender_er,setegender_er]=useState();
//eintroduction
const[eintroduction,seteintroduction]=useState();const updateeintroduction = (event) => { seteintroduction(event.target.value);}; const[eintroduction_er,seteintroduction_er]=useState();
//edate_of_birth
const[edate_of_birth,setedate_of_birth]=useState();const updateedate_of_birth = (event) => { setedate_of_birth(event.target.value);};  const[edate_of_birth_er,setedate_of_birth_er]=useState();
//ERROR VARIABLES 
const[eerror_msg,seteerror_msg]=useState();

//Edit Variables
const[steam_id,setsteam_id]=useState();
const[steam_key,setsteam_key]=useState();


/***************** EDIT FUNCTION START  ******************/
function edit(){

  
  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))&&((steam_id!=null)||(steam_id!=" "))&&((steam_key!=null)||(steam_key!=" "))){


//Setting Error Variables
setemember_name_er(" "); seteemail_er(" ");setecontact_er(" ");seterole_er(" ");seteexperience_er(" ");seteduration_er();setelinkedin_er();setetype_er();seteworking_er();setegender_er();setedate_of_birth_er();seteintroduction_er();
;seteerror_msg(" ");


//Fetching Form Data
let noerror = 0;
const $emember_name = document.getElementById("emember_name").value; if($emember_name==""){ setemember_name_er("Member Name is required !");noerror = noerror+1; }//Fetching emember_name Value
const $eemail = document.getElementById("eemail").value; if($eemail==""){ seteemail_er("Email is required !");noerror = noerror+1; }//Fetching Email Value
const $econtact = document.getElementById("econtact").value; if($econtact==""){ setecontact_er("Contact is required !");noerror = noerror+1; }//Fetching contact Value
const $erole = document.getElementById("erole").value; if($erole==""){ seterole_er("Role is required !");noerror = noerror+1; }//Fetching role Value
const $eexperience = document.getElementById("eexperience").value; if($eexperience==""){ seteexperience_er("Experience is required !");noerror = noerror+1; }//Fetching experience Value
const $eduration = document.getElementById("eduration").value; if($eduration==""){ seteduration_er("Duration is required !");noerror = noerror+1; }//Fetching Confirm Password Value
const $egender = document.getElementById("egender").value; if($egender==""){ setegender_er("Gender is required !");noerror = noerror+1; }//Fetching gender Value
const $edate_of_birth = document.getElementById("edate_of_birth").value; if($edate_of_birth==""){ setedate_of_birth_er("Date of Birth is required !");noerror = noerror+1; }//Fetching date_of_birth Value
const $eintroduction = document.getElementById("eintroduction").value; if($eintroduction==""){ seteintroduction_er("Introduction is required !");noerror = noerror+1; }//Fetching introduction Value
const $elinkedin = document.getElementById("elinkedin").value; //Fetching elinkedin Value
const $etype = document.getElementById("etype").value; //Fetching etype Value
const $eworking = document.getElementById("eworking").value; //Fetching eworking Value

 
//Sending Data through axios
axios.post("network-edit-startup-team", { 
  Log_id:logid,
  Token:token,
  Startup_id:startup_id,
  Startup_key:startup_key,
  Member_name:$emember_name,
  Email:$eemail,
  Contact:$econtact,
  Role:$erole,
  Duration:$eduration,
  Experience:$eexperience,
  Gender:$egender,
  Date_of_birth:moment($edate_of_birth).format('YYYY-MM-DD'),
  Summary:$eintroduction,
  Type:$etype,
  Working:$eworking,
  Linkedin:$elinkedin,
  Steam_id:steam_id,
  Steam_key:steam_key
  
  })
    
    .then((response) => {
    
    
   
//Validation Errors
if(response.data.validatorerror){
  setemember_name_er(response.data.validatorerror.Member_name);
  setecontact_er(response.data.validatorerror.Contact);
  seteemail_er(response.data.validatorerror.Email);
  seterole_er(response.data.validatorerror.Role);
  seteduration_er(response.data.validatorerror.Duration);
  seteexperience_er(response.data.validatorerror.Experience);
  setegender_er(response.data.validatorerror.Gender);
  setedate_of_birth_er(response.data.validatorerror.Date_of_birth);
  seteintroduction_er(response.data.validatorerror.Summary);
  setetype_er(response.data.validatorerror.Type);
  seteworking_er(response.data.validatorerror.Working);
  setelinkedin_er(response.data.validatorerror.Linkedin);
  seteerror_msg("Cannot Update Team Member Data !");
    
    
    }
    //Invalid Entries
    if(response.data.error==102){ 
    
      setemember_name_er(response.data.member_name_er);
      setecontact_er(response.data.contact_er);
      seteemail_er(response.data.email_er);
      seterole_er(response.data.role_er);
      seteduration_er(response.data.duration_er);
      seteexperience_er(response.data.experience_er);
      setegender_er(response.data.gender_er);
      setedate_of_birth_er(response.data.date_of_birth_er);
      seteintroduction_er(response.data.summary_er);
      setetype_er(response.data.type_er);
      seteworking_er(response.data.working_er);
      setelinkedin_er(response.data.linkedin_er);
      seteerror_msg("Cannot Update Team Member Data !");
    
    
    }
    
    //Success
    if(response.data.successvar==1){
    
    
    //window.location.replace("/businesstools-startworking");
    document.getElementById("edit-success-card").style.display="block";
    
    }
    //failure
    if(response.data.successvar==2){
    seteerror_msg("Cannot Update Team Member Data !");
    }
    }    );
    


  }
  //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
 sessionStorage.removeItem("logStatus00");
 sessionStorage.removeItem("logId00");
 sessionStorage.removeItem("logToken00");
 sessionStorage.removeItem("logName00");
 sessionStorage.removeItem("StartupId10");
 sessionStorage.removeItem("StartupKey10");
 window.location.replace("/businesstools-login");
 
 
 }    

}
/***************** EDIT FUNCTION END  ******************/


/***************** DELETE FUNCTION START  ******************/
function deleteteam(){

  
  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))&&((steam_id!=null)||(steam_id!=" "))&&((steam_key!=null)||(steam_key!=" "))){

  seteerror_msg(" ");
 
//Sending Data through axios
axios.post("network-delete-startup-team", { 
  Log_id:logid,
  Token:token,
  Startup_id:startup_id,
  Startup_key:startup_key,
  Steam_id:steam_id,
  Steam_key:steam_key
  
  })
    
    .then((response) => {
    

    //Success
    if(response.data.successvar==1){        

      document.getElementById("delete-card").style.display="none";
      document.getElementById("delete-success-card").style.display="block";  
      setsteam_id("");
      setsteam_key("");

  }
  
      //Cannot Delete Counter
    else{  
    document.getElementById("delete-card").style.display="none";
    document.getElementById("delete-error-card").style.display="block"; 
    setsteam_id("");
    setsteam_key("");  }


    }    );
    


  }
  //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
 sessionStorage.removeItem("logStatus00");
 sessionStorage.removeItem("logId00");
 sessionStorage.removeItem("logToken00");
 sessionStorage.removeItem("logName00");
 sessionStorage.removeItem("StartupId10");
 sessionStorage.removeItem("StartupKey10");
 window.location.replace("/businesstools-login");
 
 
 }    

}
/***************** DELETE FUNCTION END  ******************/


return(
    <>
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Networking|Startup Register</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div class="container-scroller">

  {/*}  <Sidebar /> */}
    <div class="container-fluid page-body-wrapper">


       <Navbar /> 
        {/*} partial */}
        <div class="main-panel">
          <div class="content-wrapper pb-0">

                 {/*  SUCCESS CARD START */}
                <div class="d-flex justify-content-center">
                <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Team Member Added Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <button class="mt-3 btn btn-outline-success" onClick={()=>{ document.getElementById("success-card").style.display="none"; }}>Continue</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/*  SUCCESS CARD END */}

      {/* EDIT SUCCESS CARD START */}
      <div className="d-flex justify-content-center">
          <div className="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="edit-success-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title  text-success">Team Member Data Updated Successfully !!!</h4>
                  
                    <blockquote className="blockquote">
                      <p className="mb-0"> Click the following button to close.</p>
                      <button className="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("edit-success-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* EDIT SUCCESS CARD END */}

                 {/* DELETE CARD START */}
        <div className="d-flex justify-content-center">
          <div className="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title  text-danger">Are you sure you want to delete this team member ?</h4>
                  
                    <blockquote className="blockquote">
                      <p className="mb-0"> This action cannot be reversed. You will lose all your data related to your team member.</p>
                      <button className="mt-3 btn btn-outline-success" onClick={deleteteam}>Yes, I Know! Delete Team Member.</button>
                      <button className="mt-3 btn btn-outline-danger" style={{marginLeft:"1rem"}} onClick={()=>{  
                      //Cancel Delete and remove Counter_id
                      setsteam_id("");setsteam_key("");  document.getElementById('delete-card').style.display = 'none'; }}>No, Don't Delete Team Member.</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* DELETE CARD END */}
              {/* DELETE SUCCESS CARD START */}
        <div className="d-flex justify-content-center">
          <div className="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-success-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title  text-success">Team Member Data Deleted Successfully !!!</h4>
                  
                    <blockquote className="blockquote">
                      <p className="mb-0"> Click the following button to close.</p>
                      <button className="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("delete-success-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* DELETE SUCCESS CARD END */}
               {/*  DELETE ERROR CARD START */}
        <div className="d-flex justify-content-center">
          <div className="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-error-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title  text-danger">Cannot Delete Transaction !!!</h4>
                  
                    <blockquote className="blockquote">
                      <p className="mb-0"> Click the following button to close.</p>
                      <button className="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("delete-error-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* DELETE ERROR CARD END */}

          <div className="page-header flex-wrap">
            <div className="header-left"></div>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                
                </div>
                <button type="button" className="btn btn-primary mt-2 mt-sm-0 btn-icon-text"  onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
                  <i className="mdi mdi-plus-circle"></i> Add New Team Member</button>
              </div>
            </div>

        

              <div class="col-12 grid-margin">

              {/* ADD TEAM MEMBER FORM START */}
              <div class="card" id="create-form">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ 
                          setmember_name("");setrole("");setcontact("");setemail("");setgender("");settype("");setworking("");
                          setintroduction("");setdate_of_birth("");setexperience("");setduration("");setlinkedin("");seterror_msg("");

                        document.getElementById("create-form").style.display="none"; }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
              
                  <div class="card-body">
                    <h4 class="card-title">Team Details</h4>
                    <div class="form-sample" style={{textAlign:"left"}}>
                      <p class="card-description">add your team details here to stand out.</p>
                      <code>{error_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control"  id="member_name" onChange={updatemember_name} value={member_name} />
                              <code>{member_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Role</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control"  id="role" onChange={updaterole} value={role} placeholder="Founder" />
                              <code>{role_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Contact</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control"  id="contact" onChange={updatecontact} value={contact} placeholder="91000000000" />
                              <code>{contact_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Email</label>
                            <div class="col-sm-9">
                              <input type="email" class="form-control"  id="email" onChange={updateemail} value={email} placeholder="myemail@gmail.com" />
                            <code>{email_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">LinkedIn</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control"  id="linkedin" onChange={updatelinkedin} value={linkedin} placeholder="https://www.linkedin.com/" />
                            <code>{linkedin_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Gender</label>
                            <div class="col-sm-9">
                              <select style={{padding:"1rem"}} type="text" class="form-control" id="gender" onChange={updategender}>
                              <option>Select Gender</option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                              <option value="other">Other</option>
                              </select>
                            </div>
                            <code>{gender_er}</code>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type</label>
                            <div class="col-sm-9">
                            <select style={{padding:"1rem"}}  class="form-control" id="type" onChange={updatetype}>
                              <option>Select Type</option>
                              <option value="Founder">Founder</option>
                              <option value="Core Team">Core Team</option>
                              <option value="Advisor">Advisor</option>
                              <option value="Investor">Investor</option>
                              <option value="Employee">Employee</option>
                              <option value="Intern">Intern</option>
                              <option value="Partner">Partner</option>
                              <option value="Other">Other</option>
                              </select>
                              <code>{type_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Working</label>
                            <div class="col-sm-9">
                            <select style={{padding:"1rem"}}  class="form-control" id="working" onChange={updateworking}>
                              <option>Select Working</option>
                              <option value="Full Time">Full Time</option>
                              <option value="Part Time">Part Time</option>
                              <option value="Contract">Contract</option>
                              <option value="Voluntary">Voluntary</option>
                              <option value="Other">Other</option>
                              </select>
                              <code>{working_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Total Experience (in years)</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control"  id="experience" onChange={updateexperience} value={experience} placeholder="12" />
                            <code>{experience_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Experience in Startup (in years)</label>
                            <div class="col-sm-9">
                              <input type="email" class="form-control"  id="duration" onChange={updateduration} value={duration} placeholder="05" />
                              <code>{duration_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date of Birth</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control"  id="date_of_birth" onChange={updatedate_of_birth} value={date_of_birth}  />
                              <code>{date_of_birth_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                      <div class="form-group">
                        <label for="exampleTextarea1">Summary/ Introduction</label>
                        <textarea type="text" class="form-control" id="introduction" onChange={updateintroduction} value={introduction} placeholder="I am ...." />
                      <code>{introduction_er}</code>
                      </div> 
                      
                      </div>
                   
                      <button class="btn btn-primary" onClick={register}>Add</button>
                    </div>
                  </div>
                </div>

                  {/* ADD TEAM MEMBER FORM END */}

              {/* EDIT TEAM MEMBER FORM START */}
              <div class="card" id="edit-form" style={{display:"none"}}>
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ 
                          setsteam_id("");setsteam_key(""); 
                          setemember_name("");seterole("");setecontact("");seteemail("");setegender("");setetype("");seteworking("");
                          seteintroduction("");setedate_of_birth("");seteexperience("");seteduration("");setelinkedin("");seteerror_msg("");

                        
                        document.getElementById("edit-form").style.display="none"; }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
              
                  <div class="card-body">
                    <h4 class="card-title">Team Details</h4>
                    <div class="form-sample" style={{textAlign:"left"}}>
                      <p class="card-description">add your team details here to stand out.</p>
                      <code>{eerror_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control"  id="emember_name" onChange={updateemember_name} value={emember_name} />
                              <code>{emember_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Role</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control"  id="erole" onChange={updateerole} value={erole} placeholder="Founder" />
                              <code>{erole_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Contact</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control"  id="econtact" onChange={updateecontact} value={econtact} placeholder="91000000000" />
                              <code>{econtact_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Email</label>
                            <div class="col-sm-9">
                              <input type="email" class="form-control"  id="eemail" onChange={updateeemail} value={eemail} placeholder="myemail@gmail.com" />
                            <code>{eemail_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">LinkedIn</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control"  id="elinkedin" onChange={updateelinkedin} value={elinkedin} placeholder="https://www.linkedin.com/" />
                            <code>{elinkedin_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Gender</label>
                            <div class="col-sm-9">
                              <select style={{padding:"1rem"}} type="text" class="form-control" id="egender" onChange={updateegender}>
                              <option>Select Gender</option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                              <option value="other">Other</option>
                              </select>
                            </div>
                            <code>{egender_er}</code>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type</label>
                            <div class="col-sm-9">
                            <select style={{padding:"1rem"}}  class="form-control" id="etype" onChange={updateetype}>
                              <option>Select Type</option>
                              <option value="Founder">Founder</option>
                              <option value="Core Team">Core Team</option>
                              <option value="Advisor">Advisor</option>
                              <option value="Investor">Investor</option>
                              <option value="Employee">Employee</option>
                              <option value="Intern">Intern</option>
                              <option value="Partner">Partner</option>
                              <option value="Other">Other</option>
                              </select>
                              <code>{etype_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Working</label>
                            <div class="col-sm-9">
                            <select style={{padding:"1rem"}}  class="form-control" id="eworking" onChange={updateeworking}>
                              <option>Select Working</option>
                              <option value="Full Time">Full Time</option>
                              <option value="Part Time">Part Time</option>
                              <option value="Contract">Contract</option>
                              <option value="Voluntary">Voluntary</option>
                              <option value="Other">Other</option>
                              </select>
                              <code>{eworking_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Total Experience (in years)</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control"  id="eexperience" onChange={updateeexperience} value={eexperience} placeholder="12" />
                            <code>{eexperience_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Experience in Startup (in years)</label>
                            <div class="col-sm-9">
                              <input type="email" class="form-control"  id="eduration" onChange={updateeduration} value={eduration} placeholder="05" />
                              <code>{eduration_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date of Birth</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control"  id="edate_of_birth" onChange={updateedate_of_birth} value={edate_of_birth}  />
                              <code>{edate_of_birth_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                      <div class="form-group">
                        <label for="exampleTextarea1">Summary/ Introduction</label>
                        <textarea type="text" class="form-control" id="eintroduction" onChange={updateeintroduction} value={eintroduction} placeholder="I am ...." />
                      <code>{eintroduction_er}</code>
                      </div> 
                      
                      </div>
                   
                      <button class="btn btn-primary" onClick={edit}>Edit</button>
                    </div>
                  </div>
                </div>

                  {/* EDIT TEAM MEMBER FORM END */}


              </div>


              <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">My Team</h4>
                    <p class="card-description"> 
                    </p>
                    <div class="table-responsive">
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th>Id</th>
                            <th>Name</th>
                            <th>Role</th>
                            <th>Contact</th>
                            <th>Email</th>
                            <th>Gender</th>
                            <th>Type</th>
                            <th>Working</th>
                            <th>Summary</th>
                            <th>Date of Birth</th>
                            <th>Expereience</th>
                            <th>Duration</th>
                            <th>LinkedIn</th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                       data.map((curElm,ind)=>{

                         return(
                         <>
                          <tr>
                            <td>{curElm.Steam_id}</td>
                            <td>{curElm.Name}</td>
                            <td>{curElm.Role}</td>
                            <td>{curElm.Contact}</td>
                            <td>{curElm.Email}</td>
                            <td>{curElm.Gender}</td>
                            <td>{curElm.Type}</td>
                            <td>{curElm.Working}</td>
                            <td>{curElm.Summary}</td>
                            <td>{curElm.Date_of_birth}</td>
                            <td>{curElm.Experience}</td>
                            <td>{curElm.Duration}</td>
                            <td>{curElm.Linkedin}</td>
                            <td><i class="mdi mdi-pencil-box text-warning" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                          //Setting Steam_id, other values and Redirect to Edit
                          setsteam_id(curElm.Steam_id);setsteam_key(curElm.Steam_key); 
                          setemember_name(curElm.Name);seterole(curElm.Role);setecontact(curElm.Contact);seteemail(curElm.Email);setegender(curElm.Gender);setetype(curElm.Type);seteworking(curElm.Working);
                          seteintroduction(curElm.Summary);setedate_of_birth(curElm.Date_of_birth);seteexperience(curElm.Experience);seteduration(curElm.Duration);setelinkedin(curElm.Linkedin);
                       
                          document.getElementById('edit-form').style.display = 'block'; }}></i></td>
                            <td><i class="mdi mdi-delete-forever text-danger" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                        //Setting Income Id for Delete
                        setsteam_id(curElm.Steam_id);setsteam_key(curElm.Steam_key);     document.getElementById('delete-card').style.display = 'block'; }}></i></td>

                          </tr>
                          </>
                         )
                         }
					   
	                  		 )
                         }
                       
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

             <button class="btn btn-primary" onClick={()=>{
                sessionStorage.removeItem("StartupId10");
                sessionStorage.removeItem("StartupKey10"); 
                window.location.replace("/networking-startup-dashboard");
              }}>Continue to Startup Dashboard</button>

        </div>
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
</>
);

}
export default Startupedit2;