import React from "react";
import { useState, useEffect, Component } from "react";
import { useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
//import axios from '../../axios.js';
import moment from "moment/moment";


import "../../Resources/homebtcss.css";
import "../../Resources/home2css.css";
import "../../Resources/serviceshomecss.css";
import "../ankitcss.css";
import '../../Resources/responsiveness.css';

//import Sidebar from "./sidebar";

//import Chart from "chart.js/auto";
//import { Line, Bar } from "react-chartjs-2";
import Footer3 from "../../Supportingfiles/footer3";
//import { Navbar } from "flowbite-react";
import Navbar3 from "../../Supportingfiles/navbar3";
import Whystartupconnect from "../whystartupconnect";
import Comparisontable from "./comparisontable";

function LLPRegistration1() {


  

  /* Cashfree Button Style */

  const button_container = {
    border: "1px solid black",
    borderRadius: "5px",
    display: "flex",
    padding: "10px", 
    width: "fit-content",
    cursor: "pointer",
    background: "#0b6083",
    border:"none"
};

const text_container = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginLeft: "10px",
  justifyContent: "center",
  marginRight: "10px",
  fontWeight:"bold"
};
const logo_container = {
  width: "40px",
  height: "40px"
};
const secondary_logo_container = {
width: "16px",
height: "16px",
verticalAlign: "middle"
};


  return (
    <>
      <html lang="en">
        <head>
          {/*} Required meta tags */}
          <meta charset="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <title>STARTUP CONNECT | SERVICES | LIMITED LIABILITY PARTNERSHIP REGISTRATION</title>
          {/*} plugins:css */}
          <link
            rel="stylesheet"
            href="panel/assets/vendors/mdi/css/materialdesignicons.min.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/css/vendor.bundle.base.css"
          />
          {/*} endinject */}
          {/*} Plugin css for this page */}
          <link
            rel="stylesheet"
            href="panel/assets/vendors/jquery-bar-rating/css-stars.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/font-awesome/css/font-awesome.min.css"
          />
          {/*} End plugin css for this page */}
          {/*} inject:css */}
          {/*} endinject */}
          {/*} Layout styles */}
          <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
          {/*} End layout styles */}
          <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
        </head>
        <body>

        
  {/* Whatsapp Chat Icon Start */}
  <div class="wtp-chat-container" /*style={{position:"fixed",marginTop:"92vh", marginLeft:"94vw"}}*/>
    <a aria-label="Chat on WhatsApp" href="https://wa.me/918480002413?text=I%20want%20to%20know%20more%20about%20service%20LLP%20Registration%20by%20the%20Startup%20Connect">   
    <img class="wtp-chat-icon" src="https://cdn-icons-png.flaticon.com/128/3938/3938058.png" /*style={{height:"3rem", width:"3rem"}}*/></img>
  </a>
  </div>
  {/* Whatsapp Chat Icon End */}

          <div class="home2-container">
            <Navbar3 />

            {/* HEADER SECTION START */}
            <section /*class="sv-header-section" */>
              <div class="sv-header-container1">
                <div class="sv-header-left home-header-pd">
                  <h1 style={{ color: "#004463" }}>
                    LIMITED LIABILITY PARTNERSHIP (LLP) REGISTRATION
                  </h1>
                  <p>
                  
                    <div style={{ marginBottom: "2px" }}>
                      <span style={{ paddingRight: "5px" }}><i class="mdi mdi-checkbox-marked" style={{fontSize:"2rem", color :"#0b5748"}}></i></span>
                      <span>Fast customer support </span>
                    </div>
                    <div style={{ marginBottom: "2px" }}>
                      <span style={{ paddingRight: "5px" }}><i class="mdi mdi-checkbox-marked" style={{fontSize:"2rem", color :"#0b5748"}}></i></span>
                      <span>Complete online process.</span>
                    </div>
                    <div style={{ marginBottom: "2px" }}>
                      <span style={{ paddingRight: "5px" }}><i class="mdi mdi-checkbox-marked" style={{fontSize:"2rem", color :"#0b5748"}}></i></span>
                      <span>Instant WhatsApp/email updates </span>
                    </div>
               {/*}     <div style={{ marginBottom: "5px" }}>
                      <span style={{ paddingRight: "10px" }}><i class="mdi mdi-checkbox-marked" style={{fontSize:"2rem", color :"#0b5748"}}></i></span>
                      <span>Transparent pricing with no hidden fees.</span>
                    </div>
                    <div style={{ marginBottom: "5px" }}>
                      <span style={{ paddingRight: "10px" }}><i class="mdi mdi-checkbox-marked" style={{fontSize:"2rem", color :"#0b5748"}}></i></span>
                      <span>User-friendly interface for a smooth experience.</span>
                    </div> */}
                  </p>

                  <div class="home2-services-features-list">
                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img
                          loading="lazy"
                          src="https://cdn-icons-png.flaticon.com/128/1087/1087840.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>500+</h3>
                        <h5 style={{ fontWeight: "bold" }}>Projects</h5>
                      </div>
                    </div>

                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img
                          loading="lazy"
                          src="https://cdn-icons-png.flaticon.com/128/12735/12735475.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>25+</h3>
                        <h5 style={{ fontWeight: "bold" }}>Cities</h5>
                      </div>
                    </div>

                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img
                          loading="lazy"
                          src="https://cdn-icons-png.flaticon.com/128/2991/2991235.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>
                          4{" "}
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/7656/7656139.png"
                            style={{ height: "2rem", width: "2rem" }}
                          />
                          +
                        </h3>
                        <h5 style={{ fontWeight: "bold" }}>Google Ratings</h5>
                      </div>
                    </div>
                  </div>

                  <div  class="serviceshome-btn-container">
             <div>
             <a href="https://calendly.com/thestartupconnect/30min"><button class="btn-lft-margin" id="home2-modules-section-btn" style={{marginLeft:"0"}}>Book Free Consultancy <i class="mdi mdi-arrow-right"></i></button></a>
             </div>
             <div style={{marginLeft:"0"}}>
             <a href="/home1/llp_registration.pdf" download="Startup Connect Services Brochure"><button class="btn-lft-margin" id="home2-modules-section-btn" style={{backgroundImage: "linear-gradient(to right, #208d17, #00ad11)", marginLeft:"0"}}>Download Brochure <i class="mdi mdi-download"></i></button></a>
             </div>
             </div>


                </div>
                <div
                  class="sv-header-right" /* style={{backgroundColor:"#96dfff",padding:"2rem",borderRadius:"15px",boxShadow:"2px 3px 3px 2px #cbcecf"}} */
                >
                  <img
                    loading="lazy"
                    src="https://img.freepik.com/free-vector/sponsor-concept-illustration_114360-6674.jpg"
                    style={{ width: "90%", height: "100%" }}
                  />
                </div>
              </div>
            </section>
            {/* HEADER SECTION END */}
            <hr />

            {/* SIDEBAR AND MAIN PAGE START */}
            <div className="container-scroller">
              {/*<Sidebar /> */}
              <div className="container-fluid page-body-wrapper">
                {/*} partial */}
                <div className="main-panel">

                  {/* MORE INFORMATION SECTION START */}
                  <div class="row" style={{ padding: "2rem" }}>
                    <div
                      class="col-sm-9 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div>
                        <h2 id="ip-h2" style={{ fontWeight: "bold" }}>
                          WHAT IS{" "}
                          <span style={{ color: "#9f23c2" }}>
                           LIMITED LIABILITY PARTNERSHIP (LLP)
                          </span>
                        </h2>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                          {" "}
                          <i class="mdi mdi-assistant text-warning" style={{fontSize:"1.4rem",padding:"1rem"}}></i> LLP stands for Limited Liability partnership.
                        </p>
                        <p style={{ fontSize: "1rem" }}>
                        <i class="mdi mdi-assistant text-warning" style={{fontSize:"1.4rem",padding:"1rem"}}></i>
                          LLP is a mix of a traditional partnership, and a
                          company as some of its features are similar to a
                          traditional partnership and some match with a company
                          like the limited liability of the partners and
                          separate and perpetual legal existence.
                        </p>
                        <p style={{ fontSize: "1rem" }}>
                        <i class="mdi mdi-assistant text-warning" style={{fontSize:"1.4rem",padding:"1rem"}}></i>
                          No partner is liable on account of unauthorized
                          actions of other partners, thus individual partners
                          are shielded from joint liability created by another
                          partner’s misconduct.{" "}
                        </p>
                        <p style={{ fontSize: "1rem" }}>
                        <i class="mdi mdi-assistant text-warning" style={{fontSize:"1.4rem",padding:"1rem"}}></i>
                          LLP is governed under the Limited Liability
                          Partnership Act, 2008.{" "}
                        </p>
                        <p style={{ fontSize: "1rem" }}>
                        <i class="mdi mdi-assistant text-warning" style={{fontSize:"1.4rem",padding:"1rem"}}></i>
                          Every LLP is required to have at least two Designated
                          Partners who shall be individuals and at least one of
                          them shall be a Resident of India. An LLP can be
                          incorporated within 15 working days.{" "}
                        </p>
                      </div>
                    </div>
                    <div class="col-sm-3 stretch-card grid-margin">
                      <img
                        loading="lazy"
                        src="https://img.freepik.com/free-vector/business-deal-concept-illustration_114360-1535.jpg"
                        style={{ width: "90%", height: "100%" }}
                      />
                    </div>
                  </div>
                  {/* MORE INFORMATION SECTION END */}

                  <hr />
                  <h2 id="ip-h2" style={{padding:"0.5rem"}}>
                 BENEFITS OF REGISTERING AS A LIMITED LIABILITY PARTNERSHIP (LLP)
                  </h2>
    {/* BENEFITS SECTION START */}
    <div class="row d-flex justify-content-center" style={{ padding: "2rem" }}>
                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/2590/2590440.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">No Minimum Capital</h4>
                        <p>
                        No minimum capital is required for LLP Formation. A Limited Liability Partnership can be registered even with Rs. 1000 as total capital contribution.

                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/2758/2758527.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Limited Liability</h4>
                        <p>
                        In case of a business loss or insolvency, the liability of the partners is restricted to the capital contribution as per the LLP agreement. Further, one partner is not held responsible for the actions of negligence or misconduct of any other partner.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/11443/11443795.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Separate Legal Entity</h4>
                        <p>
                        business can enter into contract with other entities, take legal action, own assets, and borrow funds in the name of the LLP itself. It provides flexibility to the business to function independently and in perpetuity, irrespective of change or death of partners.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/3529/3529127.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">LLP Tax Benefits 
</h4>
                        <p>
                        It is also exempted from various taxes such as dividend distribution tax and minimum alternative tax. The rate of tax on LLP is less than as compared to the company.{" "}
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/5347/5347858.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Operational Flexibility </h4>
                        <p>
                        LLP Agreement, deed among partners of an LLP, clarifies operating structure including rights and responsibilities of the partners. Typically, LLP has a “Designated Member” who would control day-to-day operations. It can have individuals or existing businesses as members. Further, this structure allows to clearly define the roles and responsibilities of the partners. It could also help in protecting the partner’s interest in case of loss because of an unlawful act of any other partner.

                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/13163/13163215.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Easy Transfer </h4>
                        <p>
                        Interest in Limited Liability Partnership can easily be transferred by introducing new Designated Partner in LLP and it will not affect its existence As it is a separate legal entity.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/4435/4435123.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Lower Compliance Requirement </h4>
                        <p>
                        Compliance requirements for an LLP are lesser as compared to Private Limited Companies. It doesn’t have a mandatory audit requirement until a certain level of turnover or contribution. Unlike companies, compliances related to board meetings, statutory meetings, etc. do not apply to LLPs. Professional services for compliance are typically available at cheaper rates than that for companies, making it a cost-effective formation type to maintain.

                          India{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* BENEFITS SECTION END */}




                  <hr />

                     {/* PRICING SECTION START */}
                     <h2 id="ip-h2" style={{fontWeight:'bold'}}>PRICING FOR <span style={{color:"#006345"}}>LIMITED LIABILITY PARTNERSHIP (LLP) REGISTRATION</span></h2>
                  <div class="row md-12 d-flex justify-content-center" style={{marginTop:"5rem", padding:"1rem"}}>

                  {/* PRICING CARD 1 START */}
                  <div
                      class="col-sm-4 stretch-card grid-margin" id="resp-2"
                      style={{ textAlign: "left" }}
                    >
                    <div class="card">
                    <div style={{backgroundColor:"#207a78",color:"#ffffff",fontWeight:"bold",textAlign:"center",borderRadius:"12px",padding:"1rem"}}>
                    <h3><u>BUSINESS</u></h3>
                    <div style={{textAlign:"center"}}><h2 style={{fontWeight:"bold"}}> Starts <span style={{color:"#ffffff"}}>₹ 7999*</span></h2></div>
                    </div>
                    <div style={{padding:"1rem",margingTop:"1rem"}}>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i> 2 Digital Signature Certificate</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>2 Director Identification Numbers<br/></p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>1 Name Approval Application<br/></p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>LLP Incorporation Certificate<br/></p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>LLP Agreement<br/></p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Permanent Account Number (PAN)<br/></p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Tax Deduction Account Number (TAN)<br/></p>
                        <p style={{fontSize:"1rem", textDecoration:"line-through"}}><i class="mdi mdi-close-circle text-danger" style={{fontSize:"1.6rem",padding:"1rem"}}></i>SSI/ MSME Registration<br/></p>
                        <p style={{fontSize:"1rem", textDecoration:"line-through"}}><i class="mdi mdi-close-circle text-danger" style={{fontSize:"1.6rem",padding:"1rem"}}></i>GST Registration</p>
                        <hr/>
                       <div class="d-flex justify-content-center">
                       <button class="btn btn-lg btn-success" style={{backgroundColor:"#207a78", fontSize:"1rem"}} onClick={()=>{
                        //Setting Values to redirect to Contact Sales Page
                        sessionStorage.setItem("Smodule00","Services");sessionStorage.setItem("Sservice00","LLP Registration"); window.location.replace("/businesstools-contactsales");
                       }}>Contact Sales</button>

                        {/* CASHFREE CHECKOUT BUTTON START FOR PRICING CARD 1 */}
                        <div>
                        <a style={{textDecoration:"none"}} href="https://payments.cashfree.com/forms/startupconnect-llp-payment-business" target="_parent">
                        <div class="button-container" style={button_container}>
                        <div>
                        {/*}    <img src="https://cashfree-checkoutcartimages-prod.cashfree.com/startupconnectlogo1Y6cg7dgopcs0_prod.png" alt="logo" style={logo_container} class="logo-container" />
                        */} </div>
                        <div class="text-container" style={text_container}>
                        <div style={{fontFamily: "Arial", color: "#fff", marginBottom: "5px", fontSize: "14px"}}>
                        Check Out
                        </div>
                        <div style={{fontFamily: "Arial", color: "#fff", fontSize: "10px"}}>
                        <span>Powered By Cashfree</span>
                        <img src="https://cashfreelogo.cashfree.com/cashfreepayments/logosvgs/Group_4355.svg" alt="logo" style={secondary_logo_container} class="seconday-logo-container" />
                        </div>
                        </div>
                        </div>
                        </a>
                        </div>
                       {/* CASHFREE CHECKOUT BUTTON START FOR PRICING CARD 1 */}
                       </div>
                    </div>
                    </div>
                    </div>
                    {/* PRICING CARD 1 END */}

                      {/* PRICING CARD 2 START */}
                  <div
                      class="col-sm-4 stretch-card grid-margin" id="resp-2"
                      style={{ textAlign: "left" }}
                    >
                    <div class="card">
                    <div style={{backgroundColor:"#207a78",color:"#ffffff",fontWeight:"bold",textAlign:"center",borderRadius:"12px",padding:"1rem"}}>
                    <h3><u>PROFESSIONAL</u></h3>
                    <div style={{textAlign:"center"}}><h2 style={{fontWeight:"bold"}}> Starts <span style={{color:"#ffffff"}}>₹ 10999*</span></h2></div>
                    </div>
                    <div style={{padding:"1rem",margingTop:"1rem"}}>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i> 2 Digital Signature Certificate</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>2 Director Identification Numbers</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>1 Name Approval Application</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>LLP Incorporation Certificate</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>LLP Agreement</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Permanent Account Number (PAN)</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Tax Deduction Account Number (TAN)</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>SSI/ MSME Registration</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>GST Registration</p>
                        <hr/>
                       <div class="d-flex justify-content-center">
                       <button class="btn btn-lg btn-success" style={{backgroundColor:"#207a78", fontSize:"1rem"}} onClick={()=>{
                        //Setting Values to redirect to Contact Sales Page
                        sessionStorage.setItem("Smodule00","Services");sessionStorage.setItem("Sservice00","LLP Registration"); window.location.replace("/businesstools-contactsales");
                       }}>Contact Sales</button>
                        {/* CASHFREE CHECKOUT BUTTON START FOR PRICING CARD 2 */}
                        <div>
                        <a style={{textDecoration:"none"}} href="https://payments.cashfree.com/forms/tartupconnect-llp-payment-professional" target="_parent">
                        <div class="button-container" style={button_container}>
                        <div>
                        {/*}    <img src="https://cashfree-checkoutcartimages-prod.cashfree.com/startupconnectlogo1Y6cg7dgopcs0_prod.png" alt="logo" style={logo_container} class="logo-container" />
                        */} </div>
                        <div class="text-container" style={text_container}>
                        <div style={{fontFamily: "Arial", color: "#fff", marginBottom: "5px", fontSize: "14px"}}>
                        Check Out
                        </div>
                        <div style={{fontFamily: "Arial", color: "#fff", fontSize: "10px"}}>
                        <span>Powered By Cashfree</span>
                        <img src="https://cashfreelogo.cashfree.com/cashfreepayments/logosvgs/Group_4355.svg" alt="logo" style={secondary_logo_container} class="seconday-logo-container" />
                        </div>
                        </div>
                        </div>
                        </a>
                        </div>
                       {/* CASHFREE CHECKOUT BUTTON START FOR PRICING CARD 2 */}
                       </div>
                    </div>
                    </div>
                    </div>
                    {/* PRICING CARD 2 END */}


                  </div>
                  <p style={{fontSize:"1rem"}}><b>*Note :</b>The above prices excludes the price of stamp duty to be paid on LLP Agreement.Stamp charges has to be borne by the customer as actuals. Draft LLP Agreement will be provided to client and the stamped agreement shall be provided by client to our team for filing.
</p>
                  <hr/>
                  {/* PRICING SECTION END */}

              
                  {/* REQUIREMENTS SECTION START */}
                  <h2 id="ip-h2">MINIMUM REQUIREMENTS FOR LIMITED LIABILITY PARTNERSHIP (LLP) REGISTARTION</h2>

                  <div class="row" style={{ padding: "2rem" }}>
                    <div class="col-sm-4 stretch-card grid-margin">
                      <img
                        loading="lazy"
                        src="https://img.freepik.com/free-vector/business-deal-concept-illustration_114360-1103.jpg"
                        style={{ width: "90%", height: "100%" }}
                      />
                    </div>

                    <div
                      class="col-sm-8 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div>
                        <h4 style={{ fontWeight: "bold" }}>
                          1. Minimum 2 Designated partners:
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                          {" "}
                          Every LLP shall be required to have atleast two
                          Designated Partners who shall be individuals
                        </p>
                        <br />
                        <h4 style={{ fontWeight: "bold" }}>
                          2. At least one partner should be resident of india:
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                          Among the partners, there should be a minimum of two
                          designated partners who must be natural persons, and
                          at least one of them should be resident in India. The
                          rights and duties of designated partners are governed
                          by the LLP agreement.
                        </p>
                        <br />
                        <h4 style={{ fontWeight: "bold" }}>
                          3. LLP agreement between the partners
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                          An LLP agreement is a written document defining the
                          agreement between the partners of a Limited Liability
                          Partnership. It defines the rights and duties of all
                          the partners towards each other and towards the firm.
                        </p>
                        <br />
                        <h4 style={{ fontWeight: "bold" }}>
                          4. Registered Office Address
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                        A registered office address is required to register for a private limited company, office can either be an owned or rented property.
                        </p>
                        <h4 style={{ fontWeight: "bold" }}>
                          5. Unique business name
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                          The name must be unique and acceptable, as defined by
                          the Companies Act, 2013 or LLP Act, 2008. The name
                          cannot be the same or similar to an existing company
                          or LLP or trademark in the same industry or field
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* REQUIREMENTS SECTION END */}
                  <hr />
                  <h2 id="ip-h2">LLP REGISTRATTION PROCESS</h2>

                  {/* PROCESS SECTION START */}
                  <div class="row d-flex justify-content-center" style={{padding:"1rem"}}>
                    <div class="col-sm-2 stretch-card grid-margin" id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/free-vector/paying-bills-concept-illustration_114360-19357.jpg?w=740&t=st=1706779500~exp=1706780100~hmac=ad8e6bf9dc5da282c80e40c82c023c68f94bea72270aa35612471e5892f8985b"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold"> Step 1 </h4>
                          <div>
                            <p class="mb-0" style={{ fontSize: "1rem", textAlign:"center" }}>
                              Payment and Document Submission
                              <br />
                              Expert review your Documents
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-2 stretch-card grid-margin" id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/free-vector/login-concept-illustration_114360-748.jpg?t=st=1706779615~exp=1706780215~hmac=9651a498d516590febe6779c62640331889c05ff48733ca96f9ca77ddcdec593"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold"> Step 2 </h4>
                          <div class="d-flex justify-content-between">
                            <p class="mb-0" style={{ fontSize: "1rem" }}>
                              Name availability check
                              <br />
                              Trademark Check and Name reservation Application{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-2 stretch-card grid-margin" id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/premium-vector/businessman-shaking-hands-deal-symbol-with-fellow-lawyer-lawyers-discuss-with-clients_923732-776.jpg?w=740"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold"> Step 3 </h4>
                          <div>
                            <p class="mb-0" style={{ fontSize: "1rem", textAlign:"center" }}>
                              Application for Digital Signature and
                              Incorporation application Submission{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-2 stretch-card grid-margin" id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/free-vector/product-quality-concept-illustration_114360-7301.jpg?w=740&t=st=1706780098~exp=1706780698~hmac=4ed66236fdef104f05dab6b8d7176409171e304e19a3758c389bc28837bbe13b"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold"> Step 4 </h4>
                          <div>
                            <p class="mb-0" style={{ fontSize: "1rem", textAlign:"center" }}>
                              Get Certificate for Incorporation
                              <br />
                              PAN, TAN
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-2 stretch-card grid-margin" id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/premium-vector/boy-looking-tax-form-laptop_118167-9401.jpg?w=740"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold"> Step 5 </h4>
                          <div>
                            <p class="mb-0" style={{ fontSize: "1rem" , textAlign:"center"}}>
                              Apply for GST
                              <br />
                              MSME and Trademark
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* PROCESS SECTION END */}
                   <hr/>
                  {/* Why StartUp Connect Starts */}
                   <Whystartupconnect/>
                  {/* Why StartUp Connect Ends */}

                  <hr />

                  {/* Documents SECTION Starts */}
                  <h2 id="ip-h2"> DOCUMENTS REQUIRED FOR LIMITED LIABILITY PARTNERSHIP (LLP) REGISTRATION </h2>
                  <div class="row d-flex justify-content-center" style={{ padding: "2rem" }}>
                    <div
                      class="col-sm-3 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/3135/3135715.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>

                        <h4 class="mt-3">Photograph of all the Partners</h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/1726/1726620.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">
                          PAN Card and aadhar card of all the Partners
                        </h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/9068/9068642.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Email id/ Mobile no of Partners</h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/9875/9875524.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">
                          Bank statement/any other Utility bill for address
                          Proof of Partners
                        </h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/6108/6108273.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">
                          Electricity Bill or any other utility bill for the
                          address proof of the Registered Office
                        </h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/1584/1584961.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">
                          Proof of Registered Office : Consent letter/Rent
                          agreement{" "}
                        </h4>
                      </div>
                    </div>
                  </div>
                  {/* Documents SECTION Ends */}
                  <hr/>
                  {/* COMPLIANCES SECTION START */}
                  <h2 id="ip-h2">COMPLIANCES REQUIRED BY LIMITED LIABILITY PARTNERSHIP (LLP)</h2>
                  <p style={{ fontSize: "1rem" }}>
                  Once a LLP is registered in India, various compliances must be maintained from time to time to avoid penalties and prosecution. The following are some of the compliances a LLP would be required to complete after LLP registration:
                  </p>
                  <div
                    class="row"
                    style={{ textAlign: "left", padding: "2rem" }}
                  >
                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> Director DIN KYC( FORM DIR-3 KYC)</h3>
                    <p style={{ fontSize: "1rem" }}>
                      All partners who hole a Designated Partner Identification Number (DPIN) whose DPIN is allotted on or before 31 March must complete DIN KYC within 30th September every year to validate the phone and email address on record with the Ministry of Corporate Affairs.<br/> There's a penalty of Rs. 5000 in case of failure to file DIN eKYC{" "}
                    </p>
                    <br />
                    <br />

                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> IT Returns</h3>
                    <p style={{ fontSize: "1rem" }}>
                    Every Limited Liability Partnership has to file the Income Tax Returns every year, irrespective of its transactions. It must be filed by LLP on or before 31st July (if not covered under audit) or 30th September (if covered under audit). Income tax returns for Limited Liability Partnership have to be filed under ITR 5.
                    </p>
                    <br />
                    <br />

                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> MCA annual filing</h3>
                    <p style={{ fontSize: "1rem" }}>
                    LLPs are required to file ROC Form 8 before 30th October every year. Form 8 contains details of the Statement of Accounts and solvency. Also, LLPs are required to file ROC Form 11 before 30th May every year. Form 11 contains details of all the Designated Partners like whether there are any changes in the management of the LLP.{" "}
                    </p>
                    <br />
                    <br />

                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> Statutory and tax Audit</h3>
                    <p style={{ fontSize: "1rem" }}>
                    Every Limited Liability Partnership whose turnover exceeds INR 2 Cr. In case of a business or INR 50 Lakh in case of a profession, is required to get its books of accounts tax audited under section 44AB of the Income-tax Act. Such tax audit under section 44AB will have to be completed and filed by 30th September.{" "}
                    </p>
                    <br />
                    <br />

                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> GST Registration</h3>
                    <p style={{ fontSize: "1rem" }}>
                    Every LLP is required to get their GST registration. GST Registration Process is 100% online and there is no requirement of submission of physical documents to GST Department. GST registration must be obtained within 30 days of business incorporation, otherwise, the LLP will not be able to issue proper GST related invoices.
                    </p>
                    <br />
                    <br />

                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> GST Return</h3>
                    <p style={{ fontSize: "1rem" }}>
                    Once a Limited Liability Partnership gets registered under GST It becomes necessary to file GST returns for LLPs. As filing of GST returns is mandatory for all the registered Taxpayers including LLPs. GST returns can be filed monthly, quarterly or yearly depending upon the types of GST returns form you are filing.
                    </p>
                    <br />
                    <br />

                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> IEC Registration</h3>
                    <p style={{ fontSize: "1rem" }}>
                      All the LLP which are engaged in Import and Export of goods require to register the Import Export Code. IE code has lifetime validity. Importers are not allowed to procced without this code and exporters can't benefit of exports from DGFT, customs, Export Promotion Council, if they don't have this code.
                    </p>
                    
                    <br />
                    <br />
                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> Trademark Registration</h3>
                    <p style={{ fontSize: "1rem" }}>
                      Registering a LLP with a name does not provide compolete protection to the name or brand name. Ultimate protection for a business name is secured only by Trademark Registration of same. By trademark registration, you may get the ownership of your Pvt Ltd Co. name or brand name or logo etc.
                    </p>
                  </div>
                  {/* COMPLIANCES SECTION END */}
                  <hr />
                   {/* COMPARISON TABLE START */}
                   <Comparisontable/>
                  {/* COMPARISON TABLE END */}


                 
                  {/* FAQ SECTION END */}

                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
            {/* SIDEBAR AND MAIN PAGE END */}

            <Footer3 />
          </div>
          {/*} container-scroller */}
          {/*} plugins:js */}
          <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
          {/*} endinject */}
          {/*} Plugin js for this page */}
          <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
          <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
          <script
            src="panel/assets/js/jquery.cookie.js"
            type="text/javascript"
          ></script>
          {/*} End plugin js for this page */}
          {/*} inject:js */}
          <script src="panel/assets/js/off-canvas.js"></script>
          <script src="panel/assets/js/hoverable-collapse.js"></script>
          <script src="panel/assets/js/misc.js"></script>
          <script src="panel/assets/js/settings.js"></script>
          <script src="panel/assets/js/todolist.js"></script>
          {/*} endinject */}
          {/*} Custom js for this page */}
          <script src="panel/assets/js/dashboard.js"></script>
          {/*} End custom js for this page */}
        </body>
      </html>
    </>
  );
}

export default LLPRegistration1;
