import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import '../Resources/dashboard.css';
import Navbar from '../navbar';
import axios from '../../axios.js';
import moment from 'moment/moment';


function UserQuotation_addproducts_edit(){

  
   //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const busyid=sessionStorage.getItem("BusyId00");
 const counterid=sessionStorage.getItem("CounterId00");
 const trno=sessionStorage.getItem("Trno00");
 const editid=sessionStorage.getItem("Editid00");


  {/* ADD PRODUCTS TABLE COLUMNS MANAGEMENT VARIABLES START */}
  const[cd_col1_val,setcd_col1_val] = useState(0);//column1
  const[cd_col2_val,setcd_col2_val] = useState(0);//column2
  const[cd_col3_val,setcd_col3_val] = useState(0);//column3
  const[cd_col4_val,setcd_col4_val] = useState(0);//column4
  const[cd_col5_val,setcd_col5_val] = useState(0);//column5
  const[cd_col6_val,setcd_col6_val] = useState(0);//column6
  const[cd_col7_val,setcd_col7_val] = useState(0);//column7
  const[cd_col8_val,setcd_col8_val] = useState(0);//column8
  const[cd_col9_val,setcd_col9_val] = useState(0);//column9
  const[cd_col10_val,setcd_col10_val] = useState(0);//column10
  const[cd_col11_val,setcd_col11_val] = useState(0);//column11
  const[cd_col12_val,setcd_col12_val] = useState(0);//column12
  const[cd_col13_val,setcd_col13_val] = useState(0);//column13
  const[cd_col14_val,setcd_col14_val] = useState(0);//column14
  const[cd_col15_val,setcd_col15_val] = useState(0);//column15
  const[cd_col16_val,setcd_col16_val] = useState(0);//column16
  const[cd_col17_val,setcd_col17_val] = useState(0);//column17
  const[cd_col18_val,setcd_col18_val] = useState(0);//column18
  const[cd_col19_val,setcd_col19_val] = useState(0);//column19
  const[cd_col20_val,setcd_col20_val] = useState(0);//column20
  {/* ADD PRODUCTS TABLE COLUMNS MANAGEMENT VARIABLES END */}


 //Checking if Sessions are Set

// alert(logstatus,loguser,logadminid);
 //Sessions Not Set
 {/* if(((logstatus==null)||(logstatus==""))||((logid==null)||(logid==""))||((token==null)||(token==" "))||((busyid==null)||(busyid==" "))||((counterid==null)||(counterid==" "))||((trno==null)||(trno==" "))){
  
  alert(trno);
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");
   
 
    window.location.replace("/businesstools-login");
   }
  
   //Sessions Set
   else{
   */}



       
      
//Variables
const[inventory,setinventory] = useState([]);//SetData Variable
const[products,setproducts] = useState([],);//SetData Variable
const[cdefault,setcdefault] = useState(0);
const[ecdefault,setecdefault] = useState(0);

//Error Variable
const[errorvar,seterrorvar] = useState();



//EDIT FORM VARIABLES
//ivno
const[ivno,setivno]=useState();const updateivno = (event) => { setivno(event.target.value);geteproductbyname(); }; const[ivno_er,setivno_er]=useState();
//eproduct_name
const[eproduct_name,seteproduct_name]=useState();const updateeproduct_name = (event) => { seteproduct_name(event.target.value);geteproductbyname(); }; const[eproduct_name_er,seteproduct_name_er]=useState();
//eproduct_id
const[eproduct_id,seteproduct_id]=useState();const updateeproduct_id = (event) => { seteproduct_id(event.target.value);}; const[eproduct_id_er,seteproduct_id_er]=useState();
//eproduct_code
const[eproduct_code,seteproduct_code]=useState();const updateeproduct_code = (event) => { seteproduct_code(event.target.value);}; const[eproduct_code_er,seteproduct_code_er]=useState();
//eproduct_hsn
const[eproduct_hsn,seteproduct_hsn]=useState();const updateeproduct_hsn = (event) => { seteproduct_hsn(event.target.value);}; const[eproduct_hsn_er,seteproduct_hsn_er]=useState();
//etype
const[etype,setetype]=useState();const updateetype = (event) => { setetype(event.target.value);}; const[etype_er,setetype_er]=useState();
//erate
const[erate,seterate]=useState();const updateerate = (event) => { seterate(event.target.value); ecalculate(); }; const[erate_er,seterate_er]=useState();
//equantity
const[equantity,setequantity]=useState();const updateequantity = (event) => { setequantity(event.target.value); ecalculate(); }; const[equantity_er,setequantity_er]=useState();
//eunits
const[eunits,seteunits]=useState();const updateeunits = (event) => { seteunits(event.target.value);}; const[eunits_er,seteunits_er]=useState();
//eamount
const[eamount,seteamount]=useState();const updateeamount = (event) => { seteamount(event.target.value); ecalculate(); }; const[eamount_er,seteamount_er]=useState();
//etotal
const[etotal,setetotal]=useState();const updateetotal = (event) => { setetotal(event.target.value); ecalculate(); }; const[etotal_er,setetotal_er]=useState();
//ediscount_per
const[ediscount_per,setediscount_per]=useState();const updateediscount_per = (event) => { setediscount_per(event.target.value); ecalculate(); }; const[ediscount_per_er,setediscount_per_er]=useState();
//ediscount_amt
const[ediscount_amt,setediscount_amt]=useState();const updateediscount_amt = (event) => { setediscount_amt(event.target.value); ecalculate(); }; const[ediscount_amt_er,setediscount_amt_er]=useState();
//esgst_per
const[esgst_per,setesgst_per]=useState();const updateesgst_per = (event) => { setesgst_per(event.target.value); ecalculate(); }; const[esgst_per_er,setesgst_per_er]=useState();
//esgst_amt
const[esgst_amt,setesgst_amt]=useState();const updateesgst_amt = (event) => { setesgst_amt(event.target.value); ecalculate(); }; const[esgst_amt_er,setesgst_amt_er]=useState();
//ecgst_per
const[ecgst_per,setecgst_per]=useState();const updateecgst_per = (event) => { setecgst_per(event.target.value); ecalculate(); }; const[ecgst_per_er,setecgst_per_er]=useState();
//ecgst_amt
const[ecgst_amt,setecgst_amt]=useState();const updateecgst_amt = (event) => { setecgst_amt(event.target.value); ecalculate(); }; const[ecgst_amt_er,setecgst_amt_er]=useState();
//eigst_per
const[eigst_per,seteigst_per]=useState();const updateeigst_per = (event) => { seteigst_per(event.target.value); ecalculate(); }; const[eigst_per_er,seteigst_per_er]=useState();
//eigst_amt
const[eigst_amt,seteigst_amt]=useState();const updateeigst_amt = (event) => { seteigst_amt(event.target.value); ecalculate();}; const[eigst_amt_er,seteigst_amt_er]=useState();
//etax_per
const[etax_per,setetax_per]=useState();const updateetax_per = (event) => { setetax_per(event.target.value); ecalculate();}; const[etax_per_er,setetax_per_er]=useState();
//etax_amt
const[etax_amt,setetax_amt]=useState();const updateetax_amt = (event) => { setetax_amt(event.target.value); ecalculate(); }; const[etax_amt_er,setetax_amt_er]=useState();




//CREATE FORM VARIABLES
//product_name
const[product_name,setproduct_name]=useState();const updateproduct_name = (event) => { setproduct_name(event.target.value);getproductbyname(); }; const[product_name_er,setproduct_name_er]=useState();
//product_id
const[product_id,setproduct_id]=useState();const updateproduct_id = (event) => { setproduct_id(event.target.value);}; const[product_id_er,setproduct_id_er]=useState();
//product_code
const[product_code,setproduct_code]=useState();const updateproduct_code = (event) => { setproduct_code(event.target.value);}; const[product_code_er,setproduct_code_er]=useState();
//product_hsn
const[product_hsn,setproduct_hsn]=useState();const updateproduct_hsn = (event) => { setproduct_hsn(event.target.value);}; const[product_hsn_er,setproduct_hsn_er]=useState();
//type
const[type,settype]=useState();const updatetype = (event) => { settype(event.target.value);}; const[type_er,settype_er]=useState();
//rate
const[rate,setrate]=useState();const updaterate = (event) => { setrate(event.target.value); calculate(); }; const[rate_er,setrate_er]=useState();
//quantity
const[quantity,setquantity]=useState();const updatequantity = (event) => { setquantity(event.target.value); calculate(); }; const[quantity_er,setquantity_er]=useState();
//units
const[units,setunits]=useState();const updateunits = (event) => { setunits(event.target.value);}; const[units_er,setunits_er]=useState();
//amount
const[amount,setamount]=useState();const updateamount = (event) => { setamount(event.target.value); calculate(); }; const[amount_er,setamount_er]=useState();
//total
const[total,settotal]=useState();const updatetotal = (event) => { settotal(event.target.value); calculate(); }; const[total_er,settotal_er]=useState();
//discount_per
const[discount_per,setdiscount_per]=useState();const updatediscount_per = (event) => { setdiscount_per(event.target.value); calculate(); }; const[discount_per_er,setdiscount_per_er]=useState();
//discount_amt
const[discount_amt,setdiscount_amt]=useState();const updatediscount_amt = (event) => { setdiscount_amt(event.target.value); calculate(); }; const[discount_amt_er,setdiscount_amt_er]=useState();
//sgst_per
const[sgst_per,setsgst_per]=useState();const updatesgst_per = (event) => { setsgst_per(event.target.value); calculate(); }; const[sgst_per_er,setsgst_per_er]=useState();
//sgst_amt
const[sgst_amt,setsgst_amt]=useState();const updatesgst_amt = (event) => { setsgst_amt(event.target.value); calculate(); }; const[sgst_amt_er,setsgst_amt_er]=useState();
//cgst_per
const[cgst_per,setcgst_per]=useState();const updatecgst_per = (event) => { setcgst_per(event.target.value); calculate(); }; const[cgst_per_er,setcgst_per_er]=useState();
//cgst_amt
const[cgst_amt,setcgst_amt]=useState();const updatecgst_amt = (event) => { setcgst_amt(event.target.value); calculate(); }; const[cgst_amt_er,setcgst_amt_er]=useState();
//igst_per
const[igst_per,setigst_per]=useState();const updateigst_per = (event) => { setigst_per(event.target.value); calculate(); }; const[igst_per_er,setigst_per_er]=useState();
//igst_amt
const[igst_amt,setigst_amt]=useState();const updateigst_amt = (event) => { setigst_amt(event.target.value); calculate();}; const[igst_amt_er,setigst_amt_er]=useState();
//tax_per
const[tax_per,settax_per]=useState();const updatetax_per = (event) => { settax_per(event.target.value); calculate();}; const[tax_per_er,settax_per_er]=useState();
//tax_amt
const[tax_amt,settax_amt]=useState();const updatetax_amt = (event) => { settax_amt(event.target.value); calculate(); }; const[tax_amt_er,settax_amt_er]=useState();


//Setting Final Variables
//final_amt
const[final_amt,setfinal_amt]=useState();const updatefinal_amt = (event) => { setfinal_amt(event.target.value); }; const[final_amt_er,setfinal_amt_er]=useState();
//other_deductions
const[other_deductions,setother_deductions]=useState();const updateother_deductions = (event) => { setother_deductions(event.target.value); calculatetotal(); }; const[other_deductions_er,setother_deductions_er]=useState();
//other_expenses
const[other_expenses,setother_expenses]=useState();const updateother_expenses = (event) => { setother_expenses(event.target.value); calculatetotal(); }; const[other_expenses_er,setother_expenses_er]=useState();
//shipping_carriages
const[shipping_carriages,setshipping_carriages]=useState();const updateshipping_carriages = (event) => { setshipping_carriages(event.target.value); calculatetotal(); }; const[shipping_carriages_er,setshipping_carriages_er]=useState();
//final_total
const[final_total,setfinal_total]=useState();const updatefinal_total = (event) => { setfinal_total(event.target.value); }; const[final_total_er,setfinal_total_er]=useState();

//particular
const[particular,setparticular]=useState();const updateparticular = (event) => { setparticular(event.target.value); }; const[particular_er,setparticular_er]=useState();


//SUCCESS VARIABLES
const[success_msg,setsuccess_msg]=useState();
const[esuccess_msg,setesuccess_msg]=useState();

//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();
const[eerror_msg,seteerror_msg]=useState();
const[e1error_msg,sete1error_msg]=useState();
const[error_msg1,seterror_msg1]=useState();



useEffect(()=>{
   
  getData();
  
  },[])



  
  
  
//******************* GET PRODUCTS LIST DATA FUNCTION START  ********************//
function getData(){
 


  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((trno!=null)||(trno!=" "))&&((editid!=null)||(editid!=" "))){
 
 
 axios.post("bt-get-quotation-editproducts-page-default", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Tr_no:trno,
   Quotation_id:editid,
   Sn_start :0,
   Sn_end :0,
   Sn_total :0,
   Sn_limit :0
 })
 .then((response) => {
  
  //  console.log(response);

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setinventory(response.data.inventory);
   setfinal_amt((response.data.total).toFixed(2));
   setother_deductions((response.data.other_deductions).toFixed(2));
   setother_expenses((response.data.other_expenses).toFixed(2));
   setshipping_carriages((response.data.shipping_carriages).toFixed(2));
   setfinal_total((response.data.total).toFixed(2));
   setparticular(response.data.particular);

  
  }
  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* GET PRODUCTS LIST DATA FUNCTION END  ********************//


    //******************* GET PRODUCT DATA BY NAME FUNCTION START  ********************//
function getproductbyname(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((trno!=null)||(trno!=" "))){
 
     const cname = document.getElementById("product_name").value;
     const cnlength = cname.length;
     if((cdefault==0)&&(cnlength>=3)){
     
 
 axios.post("bt-get-productlist-spr", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Name:document.getElementById("product_name").value,
   Sn_start :0,
   Sn_end :0,
   Sn_total :0,
   Sn_limit :0
 })
 .then((response) => {

  //console.log(response);

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setproducts(response.data.productlist);
   document.getElementById("products-list-box").style.display="block";
  
  }
  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
}
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }

 }
  
 //******************* GET PRODUCT DATA BY NAME FUNCTION END  ********************//




 
    //******************* GET PRODUCT DATA BY NAME FOR EDIT FUNCTION START  ********************//
function geteproductbyname(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((trno!=null)||(trno!=" "))){
 
     const cname = document.getElementById("eproduct_name").value;
     const cnlength = cname.length;
     if((ecdefault==0)&&(cnlength>=3)){
     
 
 axios.post("bt-get-productlist-spr", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Name:document.getElementById("eproduct_name").value,
   Sn_start :0,
   Sn_end :0,
   Sn_total :0,
   Sn_limit :0
 })
 .then((response) => {

  //console.log(response);

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setproducts(response.data.productlist);
   document.getElementById("eproducts-list-box").style.display="block";
  
  }
  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
}
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }

 }
  
 //******************* GET PRODUCT DATA BY NAME FOR EDIT FUNCTION END  ********************//

 
  
//******************* ADD PRODUCT FUNCTION START ********************//
function addproduct(){

  //Setting Error Variables
setproduct_name_er(" ");settype_er(" ");setproduct_id_er(" ");setproduct_code_er(" ");setproduct_hsn_er(" ");setrate_er(" ");setquantity_er(" ");setunits_er(" ");setamount_er(" ");settotal_er(" ");
setdiscount_per_er(" ");setdiscount_amt_er(" ");setsgst_per_er(" ");setsgst_amt_er(" ");setcgst_per_er(" ");setcgst_amt_er(" ");setigst_per_er(" ");setigst_amt_er(" ");settax_per_er(" ");settax_amt_er(" ");seterror_msg(" ");
seterror_msg(" ");setsuccess_msg(" ");



  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))){
 
 
  axios.post("bt-create-inventory-qpr", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Type:'sales',
   Tr_no:trno,
   Product_id:product_id,
   Product_name:product_name,
   Product_code : product_code,
   Product_hsn : product_hsn,
   Quantity:quantity,
   Rate:rate,
   Units:units,
   Discount_per:discount_per,
   Discount_amt:discount_amt,
   Sgst_per:sgst_per,
   Sgst_amt:sgst_amt,
   Cgst_per:cgst_per,
   Cgst_amt:cgst_amt,
   Igst_per:igst_per,
   Igst_amt:igst_amt,
   Tax_per:tax_per,
   Tax_amt:tax_amt
 })
 .then((response) => {

  if(response.data.error==103){     seterror_msg(response.data.error_msg);  return; }

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");

  }

   //Validation Errors
 if(response.data.validatorerror){
  settype_er(response.data.validatorerror.Type);
  setproduct_name_er(response.data.validatorerror.Product_name);
  setproduct_id_er(response.data.validatorerror.Product_id);
  setproduct_code_er(response.data.validatorerror.Product_code);
  setproduct_hsn_er(response.data.validatorerror.Product_hsn);
  //settrno_er(response.data.validatorerror.Tr_no);
  setquantity_er(response.data.validatorerror.Quantity);
  setrate_er(response.data.validatorerror.Rate);
  setunits_er(response.data.validatorerror.Units);
  setdiscount_per_er(response.data.validatorerror.Discount_per);
  setdiscount_amt_er(response.data.validatorerror.Discount_amt);
  setsgst_per_er(response.data.validatorerror.Sgst_per);
  setsgst_amt_er(response.data.validatorerror.Sgst_amt);
  setcgst_per_er(response.data.validatorerror.Cgst_per);
  setcgst_amt_er(response.data.validatorerror.Cgst_amt);
  setigst_per_er(response.data.validatorerror.Igst_per);
  setigst_amt_er(response.data.validatorerror.Igst_amt);
  settax_per_er(response.data.validatorerror.Tax_per);
  settax_amt_er(response.data.validatorerror.Tax_amt);

  
  return;

}
//Invalid Entries
if(response.data.error==102){ 
  
  settype_er(response.data.type_er);
  setproduct_name_er(response.data.product_name_er);
  setproduct_code_er(response.data.product_code_er);
  setproduct_id_er(response.data.product_id_er);
  setproduct_hsn_er(response.data.product_hsn_er);

  setrate_er(response.data.rate_er);
  setquantity_er(response.data.quantity_er);
  setunits_er(response.data.units_er);
  setdiscount_per_er(response.data.discount_per_er);
  setdiscount_amt_er(response.data.discount_amt_er);
  setsgst_per_er(response.data.sgst_per_er);
  setsgst_amt_er(response.data.sgst_amt_er);
  setcgst_per_er(response.data.cgst_per_er);
  setcgst_amt_er(response.data.cgst_amt_er);
  setigst_per_er(response.data.igst_per_er);
  setigst_amt_er(response.data.igst_amt_er);
  settax_per_er(response.data.tax_per_er);
  settax_amt_er(response.data.tax_amt_er);
 
  seterror_msg("Cannot Add Product!");  return; }

   

   //Product Entered Successfully
   if(response.data.successvar==1){   
   /*  document.getElementById("create-form").style.display="block";*/ setsuccess_msg("Product Added Successfully!")  

setproduct_name(" ");settype(" ");setproduct_id(" ");setproduct_code(" ");setproduct_hsn(" ");setrate(" ");setquantity(" ");setunits(" ");setamount(" ");settotal(" ");
setdiscount_per(" ");setdiscount_amt(" ");setsgst_per(" ");setsgst_amt(" ");setcgst_per(" ");setcgst_amt(" ");setigst_per(" ");setigst_amt(" ");settax_per(" ");settax_amt(" ");
seterror_msg(" ");

   //Calling Default Load Function
   getData();
   }
   //Cannot Add Product
   else{  seterror_msg("Cannot Add Product !"); }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* ADD PRODUCT FUNCTION END ********************//



  
//******************* COMPLETE SALES FUNCTION START ********************//
function completequotation(){

  //Setting Error Variables
setother_deductions_er(" ");setother_expenses_er(" ");setshipping_carriages_er(" ");setparticular_er(" ");seterror_msg1(" ");



  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))&&((trno!=null)||(trno!=" "))){
 
 
  axios.post("bt-complete-quotation", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Tr_no : trno,
   Other_deductions:other_deductions,
   Other_expenses:other_expenses,
   Shipping_carriages:shipping_carriages,
   Particular : particular,
 })
 .then((response) => {

  //console.log(response);

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
  }

   //Validation Errors
 if(response.data.validatorerror){
  setother_expenses_er(response.data.validatorerror.Other_expenses);
  setother_deductions_er(response.data.validatorerror.Other_deductions);
  setshipping_carriages_er(response.data.validatorerror.Shipping_carriages);
  setparticular_er(response.data.validatorerror.Particular);



}
//Invalid Entries
if(response.data.error==102){ 
  
  setother_expenses_er(response.data.other_expenses_er);
  setother_deductions_er(response.data.other_deductions_er);
  setshipping_carriages_er(response.data.shipping_carriages_er);
  setparticular_er(response.data.particular_er);
 
  seterror_msg("Cannot Add Transaction!");  }


  //Invalid Entries
  if(response.data.error==103){     seterror_msg(response.data.error_msg);  }

   //Transaction Entered Successfully
   if(response.data.successvar==1){        document.getElementById("success-card").style.display="block";  }
   //Cannot Add Transaction
   else{  seterror_msg("Cannot Add Transaction !"); }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* COMPLETE SALES FUNCTION END ********************//



 
 
 //******************* EDIT PRODUCT DETAILS FUNCTION START ********************//
function editproducts(){

  //Setting Error Variables
  seteproduct_name(" ");setetype(" ");seteproduct_id(" ");seteproduct_code(" ");seteproduct_hsn(" ");seterate(" ");setequantity(" ");seteunits(" ");seteamount(" ");setetotal(" ");
  setediscount_per(" ");setediscount_amt(" ");setesgst_per(" ");setesgst_amt(" ");setecgst_per(" ");setecgst_amt(" ");seteigst_per(" ");seteigst_amt(" ");setetax_per(" ");setetax_amt(" ");
  seteerror_msg(" ");setesuccess_msg(" ");

 // const expenseid = sessionStorage.getItem("Expenseid00");
  //const trno = sessionStorage.getItem("Trno00");

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))&&((ivno!=null)||(ivno!=" "))&&((trno!=null)||(trno!=" "))){
 
 
  axios.post("bt-inventory-edit-qpr", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Ivno:ivno,
   Type:'sales',
   Tr_no:trno,
   Product_id:eproduct_id,
   Product_name:eproduct_name,
   Product_code : eproduct_code,
   Product_hsn : eproduct_hsn,
   Quantity:equantity,
   Rate:erate,
   Units:eunits,
   Discount_per:ediscount_per,
   Discount_amt:ediscount_amt,
   Sgst_per:esgst_per,
   Sgst_amt:esgst_amt,
   Cgst_per:ecgst_per,
   Cgst_amt:ecgst_amt,
   Igst_per:eigst_per,
   Igst_amt:eigst_amt,
   Tax_per:etax_per,
   Tax_amt:etax_amt
 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");
     sessionStorage.removeItem("Trno00");

     window.location.replace("businesstools-login");
  }

   //Validation Errors
 if(response.data.validatorerror){
  setetype_er(response.data.validatorerror.Type);
  seteproduct_name_er(response.data.validatorerror.Product_name);
  seteproduct_id_er(response.data.validatorerror.Product_id);
  seteproduct_code_er(response.data.validatorerror.Product_code);
  seteproduct_hsn_er(response.data.validatorerror.Product_hsn);
  //settrno_er(response.data.validatorerror.Tr_no);
  setequantity_er(response.data.validatorerror.Quantity);
  seterate_er(response.data.validatorerror.Rate);
  seteunits_er(response.data.validatorerror.Units);
  setediscount_per_er(response.data.validatorerror.Discount_per);
  setediscount_amt_er(response.data.validatorerror.Discount_amt);
  setesgst_per_er(response.data.validatorerror.Sgst_per);
  setesgst_amt_er(response.data.validatorerror.Sgst_amt);
  setecgst_per_er(response.data.validatorerror.Cgst_per);
  setecgst_amt_er(response.data.validatorerror.Cgst_amt);
  seteigst_per_er(response.data.validatorerror.Igst_per);
  seteigst_amt_er(response.data.validatorerror.Igst_amt);
  setetax_per_er(response.data.validatorerror.Tax_per);
  setetax_amt_er(response.data.validatorerror.Tax_amt);


}
//Invalid Entries
if(response.data.error==102){ 
  
  setetype_er(response.data.type_er);
  seteproduct_name_er(response.data.product_name_er);
  seteproduct_code_er(response.data.product_code_er);
  seteproduct_id_er(response.data.product_id_er);
  seteproduct_hsn_er(response.data.product_hsn_er);

  seterate_er(response.data.rate_er);
  setequantity_er(response.data.quantity_er);
  seteunits_er(response.data.units_er);
  setediscount_per_er(response.data.discount_per_er);
  setediscount_amt_er(response.data.discount_amt_er);
  setesgst_per_er(response.data.sgst_per_er);
  setesgst_amt_er(response.data.sgst_amt_er);
  setecgst_per_er(response.data.cgst_per_er);
  setecgst_amt_er(response.data.cgst_amt_er);
  seteigst_per_er(response.data.igst_per_er);
  seteigst_amt_er(response.data.igst_amt_er);
  setetax_per_er(response.data.tax_per_er);
  setetax_amt_er(response.data.tax_amt_er);

 
  seterror_msg("Cannot Update Transaction!");  }



   //Transaction Updated Successfully
   if(response.data.successvar==1){        
     //Setting Variables to empty 
    
seteproduct_name(" ");setetype(" ");seteproduct_id(" ");seteproduct_code(" ");seteproduct_hsn(" ");seterate(" ");setequantity(" ");seteunits(" ");seteamount(" ");setetotal(" ");
setediscount_per(" ");setediscount_amt(" ");setesgst_per(" ");setesgst_amt(" ");setecgst_per(" ");setecgst_amt(" ");seteigst_per(" ");seteigst_amt(" ");setetax_per(" ");setetax_amt(" ");
seteerror_msg(" ");setivno(" ");
setesuccess_msg("Product Updated Successfully !");

   //Calling Default Load Function
   getData();      document.getElementById("edit-form").style.display="none"; 
 }

   //Cannot Update Transaction
   else{  seterror_msg("Cannot Update Product !"); }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");
   sessionStorage.removeItem("Trno00");
   sessionStorage.removeItem("Expenseid00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* EDIT PRODUCT DETAILS FUNCTION END ********************//


 
 
 //******************* DELETE PRODUCTS FUNCTION START ********************//
function deleteproduct(){

  const divno=sessionStorage.getItem("DIvno00");
  const dtrno=sessionStorage.getItem("DTrno00");

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))&&((divno!=null)||(divno!=" "))&&((dtrno!=null)||(dtrno!=" "))){
 
 axios.post("bt-inventory-delete-qpr", {
   Busy_id:busyid,
   Counter_id:counterid,
   Log_id:logid,
   Token:token,
   Ivno:divno,
   Tr_no:dtrno
 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");
     sessionStorage.removeItem("DIvno00");
     sessionStorage.removeItem("DTrno00");

     window.location.replace("businesstools-login");
  }

  //Counter Deleted Successfully
   if(response.data.successvar==1){        

    //  document.getElementById("delete-card").style.display="none";
    //  document.getElementById("delete-success-card").style.display="block";  
      sessionStorage.removeItem("DIvno");
      sessionStorage.removeItem("DTrno00");
      setesuccess_msg("Product Deleted Successfully !");
      getData();

  }
  
      //Cannot Delete Counter
    else{  
      sessionStorage.removeItem("DIvno");
      sessionStorage.removeItem("DTrno00");
      sete1error_msg("Cannot Delete Product");
  
    }

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");
   sessionStorage.removeItem("DIvno00");
   sessionStorage.removeItem("DTrno00");


   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* DELETE ADD PRODUCTS FUNCTION END ********************//


 
  
function calculate(){
  
  //Fetching Values
  let rate = (document.getElementById("rate").value);if(rate==""){ rate=0;}else{ rate=parseFloat(rate);}
  let quantity = (document.getElementById("quantity").value);if(quantity==""){ quantity=0;}else{ quantity=parseFloat(quantity);}
  let amount = parseFloat(rate*quantity);
  let discount_per = (document.getElementById("discount_per").value);if( discount_per==""){ discount_per=0;}else{ discount_per=parseFloat(discount_per);}
  let discount_amt = (document.getElementById("discount_amt").value);if(discount_amt==""){ discount_amt=0;}else{ discount_amt=parseFloat(discount_amt);}
  let sgst_per = (document.getElementById("sgst_per").value);if( sgst_per==""){ sgst_per=0;}else{ sgst_per=parseFloat(sgst_per);}
  let sgst_amt = (document.getElementById("sgst_amt").value);if(sgst_amt==""){ sgst_amt=0;}else{ sgst_amt=parseFloat(sgst_amt);}
  let cgst_per = (document.getElementById("cgst_per").value);if( cgst_per==""){ cgst_per=0;}else{ cgst_per=parseFloat(cgst_per);}
  let cgst_amt = (document.getElementById("cgst_amt").value);if(cgst_amt==""){ cgst_amt=0;}else{ cgst_amt=parseFloat(cgst_amt);}
  let igst_per = (document.getElementById("igst_per").value);if( igst_per==""){ igst_per=0;}else{ igst_per=parseFloat(igst_per);}
  let igst_amt = (document.getElementById("igst_amt").value);if(igst_amt==""){ igst_amt=0;}else{ igst_amt=parseFloat(igst_amt);}
  let tax_per = (document.getElementById("tax_per").value);if( tax_per==""){ tax_per=0;}else{ tax_per=parseFloat(tax_per);}
  let tax_amt = (document.getElementById("tax_amt").value);if(tax_amt==""){ tax_amt=0;}else{ tax_amt=parseFloat(tax_amt);}

  let total=(amount);
  if(amount>0){
    //Discount Calculation
    if(discount_per>0){discount_amt = ((amount)*(discount_per)/100);}
    let after_discount = amount-discount_amt;
   //Gst and Tax Calculation
   if(sgst_per>0){sgst_amt = ((after_discount)*(sgst_per)/100);}
   if(cgst_per>0){cgst_amt = ((after_discount)*(cgst_per)/100);}
   if(igst_per>0){igst_amt = ((after_discount)*(igst_per)/100);}
   if(tax_per>0){tax_amt = ((after_discount)*(tax_per)/100);}
   
   //Setting Final Values to display
    total = (after_discount+sgst_amt+cgst_amt+igst_amt+tax_amt);setdiscount_amt(discount_amt.toFixed(2));setsgst_amt(sgst_amt.toFixed(2));setcgst_amt(cgst_amt.toFixed(2));setigst_amt(igst_amt.toFixed(2));settax_amt(tax_amt.toFixed(2));setamount(amount.toFixed(2));settotal(total.toFixed(2));

}
}



  
function ecalculate(){
  
  //Fetching Values
  let erate = (document.getElementById("erate").value);if(erate==""){ erate=0;}else{ erate=parseFloat(erate);}
  let equantity = (document.getElementById("equantity").value);if(equantity==""){ equantity=0;}else{ equantity=parseFloat(equantity);}
  let eamount = parseFloat(erate*equantity);
  let ediscount_per = (document.getElementById("ediscount_per").value);if( ediscount_per==""){ ediscount_per=0;}else{ ediscount_per=parseFloat(ediscount_per);}
  let ediscount_amt = (document.getElementById("ediscount_amt").value);if(ediscount_amt==""){ ediscount_amt=0;}else{ ediscount_amt=parseFloat(ediscount_amt);}
  let esgst_per = (document.getElementById("esgst_per").value);if( esgst_per==""){ esgst_per=0;}else{ esgst_per=parseFloat(esgst_per);}
  let esgst_amt = (document.getElementById("esgst_amt").value);if(esgst_amt==""){ esgst_amt=0;}else{ esgst_amt=parseFloat(esgst_amt);}
  let ecgst_per = (document.getElementById("ecgst_per").value);if( ecgst_per==""){ ecgst_per=0;}else{ ecgst_per=parseFloat(ecgst_per);}
  let ecgst_amt = (document.getElementById("ecgst_amt").value);if(ecgst_amt==""){ ecgst_amt=0;}else{ ecgst_amt=parseFloat(ecgst_amt);}
  let eigst_per = (document.getElementById("eigst_per").value);if( eigst_per==""){ eigst_per=0;}else{ eigst_per=parseFloat(eigst_per);}
  let eigst_amt = (document.getElementById("eigst_amt").value);if(eigst_amt==""){ eigst_amt=0;}else{ eigst_amt=parseFloat(eigst_amt);}
  let etax_per = (document.getElementById("etax_per").value);if( etax_per==""){ etax_per=0;}else{ etax_per=parseFloat(etax_per);}
  let etax_amt = (document.getElementById("etax_amt").value);if(etax_amt==""){ etax_amt=0;}else{ etax_amt=parseFloat(etax_amt);}

  let etotal=(eamount);
  if(eamount>0){
    //Discount Calculation
    if(ediscount_per>0){ediscount_amt = ((eamount)*(ediscount_per)/100);}
    let eafter_discount = eamount-ediscount_amt;
   //Gst and Tax Calculation
   if(esgst_per>0){esgst_amt = ((eafter_discount)*(esgst_per)/100);}
   if(ecgst_per>0){ecgst_amt = ((eafter_discount)*(ecgst_per)/100);}
   if(eigst_per>0){eigst_amt = ((eafter_discount)*(eigst_per)/100);}
   if(etax_per>0){etax_amt = ((eafter_discount)*(etax_per)/100);}
   
   //Setting Final Values to display
    etotal = (eafter_discount+esgst_amt+ecgst_amt+eigst_amt+etax_amt);setediscount_amt(ediscount_amt.toFixed(2));setesgst_amt(esgst_amt.toFixed(2));setecgst_amt(ecgst_amt.toFixed(2));seteigst_amt(eigst_amt.toFixed(2));setetax_amt(etax_amt.toFixed(2));seteamount(eamount.toFixed(2));setetotal(etotal.toFixed(2));

}
}




  
function calculatetotal(){

  let final_amt = document.getElementById("final_amt").value;if(final_amt==""){ final_amt=0;}else{ final_amt=parseFloat(final_amt);}
  let other_deductions = document.getElementById("other_deductions").value;if(other_deductions==""){ other_deductions=0;}else{ other_deductions=parseFloat(other_deductions);}
  let other_expenses = document.getElementById("other_expenses").value;if(other_expenses==""){ other_expenses=0;}else{ other_expenses=parseFloat(other_expenses);}
  let shipping_carriages = document.getElementById("shipping_carriages").value;if(shipping_carriages==""){ shipping_carriages=0;}else{ shipping_carriages=parseFloat(shipping_carriages);}
  //Fetching and Calculating Values
  let final_total = parseFloat(final_amt-other_deductions+shipping_carriages+other_expenses);
  setfinal_total(final_total.toFixed(2));
}

return(
    <>
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Business Tools|BookKeeping Solution</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div class="container-scroller">

  
    <div class="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div class="main-panel">

           {/* SALES CREATED SUCCESS CARD START */}
           <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">New Quotation Created Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <a href="businesstools-user-quotation-invoice"><button class="mt-3 btn btn-outline-danger">Close</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* SALES CREATED SUCCESS CARD END */}

          <div class="content-wrapper pb-0">

            <div class="page-header flex-wrap">
            <div class="header-left"></div>
              <div class="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div class="d-flex align-items-center">
                  <a href="#">
                    <p class="m-0 pe-3">Quotation</p>
                  </a>
                  <a class="ps-3 me-4" href="#">
                    <p class="m-0">Add Products</p>
                    
                  </a>
                </div>
            
              </div>
            </div>

               {/* EDIT PRODUCT LIST BOX START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-4 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"1rem",display:"none"}} id="eproducts-list-box">
                <div class="card">
                <div class="d-flex justify-content-between">
                    <div>
                    <h4 style={{padding:'1rem'}}>Products</h4>
                    </div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ setecdefault(1); document.getElementById("eproducts-list-box").style.display="none"; }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                    
                    <table class="table table-hover">
                      <thead>
                        <tr>
                        {/*}  <th>Product Id</th> */}
                          <th>Product Name</th>
                        {/*}  <th>Product HSN</th>
                          <th>Product Code</th> */}
                          <th>Sales Rate</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                       products.map((curElm70,ind)=>{

                         return(
                         <>
                        <tr key={curElm70.Product_id}>
                          {/* <td>{curElm70.Product_id}</td> */}
                          <td>{curElm70.Product_name}</td>
                         {/*} <td>{curElm70.Product_hsn}</td>
                          <td>{curElm70.Product_code}</td> */}
                          <td>{curElm70.Sales_rate}</td>
                          <td><i class="mdi mdi-assistant text-warning" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                        //Setting Product Details for 
                       /* setproduct_id(curElm7.Product_name);*/ seteproduct_code(curElm70.Product_code);seteproduct_hsn(curElm70.Product_hsn);seteproduct_name(curElm70.Product_name);seteunits(curElm70.Units);
                        seterate(curElm70.Sales_rate);setediscount_per(curElm70.Discount_per);setesgst_per(curElm70.Sgst_per);setecgst_per(curElm70.Cgst_per);seteigst_per(curElm70.Igst_per);setetax_per(curElm70.Tax_per);
                        document.getElementById("eproducts-list-box").style.display="none";
                              }}></i></td>

                        </tr>
                        </>
                         )
                         }
					   
	                  		 )
                         }
                      </tbody>
                    </table>
              
                </div>
              </div>
              </div>
              {/* EDIT PRODUCT LIST BOX END */}


                     {/* EDIT PRODUCT DETAILS FORM START */}
                     <div class="row">
              <div class="col-12 grid-margin create-form" id="edit-form" style={{ display:"none",textAlign:"left" }}>
              <div class="card">
              <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ 
                        
                        seteproduct_name(" ");setetype(" ");seteproduct_id(" ");seteproduct_code(" ");seteproduct_hsn(" ");seterate(" ");setequantity(" ");seteunits(" ");seteamount(" ");setetotal(" ");
                        setediscount_per(" ");setediscount_amt(" ");setesgst_per(" ");setesgst_amt(" ");setecgst_per(" ");setecgst_amt(" ");seteigst_per(" ");seteigst_amt(" ");setetax_per(" ");setetax_amt(" ");
                        seteerror_msg(" ");setesuccess_msg(" ");setivno(" ");

                        document.getElementById("edit-form").style.display="none"; }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                  <div class="card-body">
                    <h4 class="card-title">Edit Products</h4>
                    <div class="form-sample">
                      <p class="card-description">edit product details</p>
                      <code>{eerror_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product Name<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Levis red T-Shirt " id="eproduct_name" value={eproduct_name} onChange={updateeproduct_name} />
                            <code>{eproduct_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product Code</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="87766" id="eproduct_code" value={eproduct_code} onChange={updateeproduct_code} />
                            <code>{eproduct_code_er}</code>
                            </div>
                          </div>
                        </div>   
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">HSN Code</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="H65S433" id="eproduct_hsn" value={eproduct_hsn} onChange={updateeproduct_hsn} />
                              <code>{eproduct_hsn_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Rate</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="500.00" id="erate" value={erate} onChange={updateerate}/>
                              <code>{erate_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Quantity</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="10.00" id="equantity" value={equantity} onChange={updateequantity} />
                              <code>{equantity_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Units</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="pieces" id="eunits" value={eunits} onChange={updateeunits} />
                              <code>{eunits_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="5000.00" id="eamount" value={eamount} onChange={updateeamount}/>
                              <code>{eamount_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Total</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" style={{fontWeight:"bold"}} placeholder="5000.00" id="etotal" value={etotal} onChange={updateetotal} />
                              <code>{etotal_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Discount %</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="10.00" id="ediscount_per" value={ediscount_per} onChange={updateediscount_per} />
                              <code>{ediscount_per_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Discount Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="500.00" id="ediscount_amt" value={ediscount_amt} onChange={updateediscount_amt}/>
                              <code>{ediscount_amt_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">SGST %</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="05.00" id="esgst_per" value={esgst_per} onChange={updateesgst_per} />
                              <code>{esgst_per_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">SGST Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="50.00" id="esgst_amt" value={esgst_amt} onChange={updateesgst_amt} />
                              <code>{esgst_amt_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">CGST %</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="05.00" id="ecgst_per" value={ecgst_per} onChange={updateecgst_per} />
                              <code>{ecgst_per_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">CGST Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="50.00" id="ecgst_amt" value={ecgst_amt} onChange={updateecgst_amt} />
                              <code>{ecgst_amt_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">IGST %</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="05.00" id="eigst_per" value={eigst_per} onChange={updateeigst_per}/>
                              <code>{eigst_per_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">IGST Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="50.00" id="eigst_amt" value={eigst_amt} onChange={updateeigst_amt} />
                              <code>{eigst_amt_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Tax %</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="05.00" id="etax_per" value={etax_per} onChange={updateetax_per}/>
                              <code>{etax_per_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Tax Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="50.00" id="etax_amt" value={etax_amt} onChange={updateetax_amt} />
                              <code>{etax_amt_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                        
                      
                        <button class="btn btn-primary" style={{fontWeight:"bold"}} onClick={editproducts}>Submit</button>
                      
                       
    
                     
                   
                    </div>
                  </div>
                </div>
              </div>
          </div>
          {/* EDIT PRODUCT DETAILS FORM END */}

          
    {/* COLUMN FILTER FOR ADD PRODUCTS LIST START */}
    <div class="col-12" style={{marginBottom:"2rem"}}>
                    <div class="template-demo d-flex justify-content-between">
                    <div></div>

                     {/* COLUMNS MANAGEMENT DROPDOWN START */}
                     <div class="dropdown">
                     {/*}       <button class="btn btn-warning" onClick={()=>{ document.getElementById("filter-top-card").style.display="block"; }}><i class="mdi mdi-filter-outline"></i></button>
                         */}   <button class="btn btn-primary dropdown-toggle" style={{marginLeft:"1rem"}} type="button" id="dropdownMenuIconButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="mdi mdi-table-large"></i>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuIconButton1">
                              <h6 class="dropdown-header">Columns</h6>
                              <hr/>
                              
                              {/* Column 1 */}
                              <li><div class="d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Sn</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c1_yes" onClick={()=>{setcd_col1_val(1); document.getElementById("cd_c1_yes").style.display="none"; document.getElementById("cd_c1_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c1_no" style={{ display:"none"}} onClick={()=>{ setcd_col1_val(0); document.getElementById("cd_c1_yes").style.display="block"; document.getElementById("cd_c1_no").style.display="none"; }}></i>
                              </div></div></li> 
                               {/* Column 2 */}
                               <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Product Code</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c2_yes"  onClick={()=>{setcd_col2_val(1); document.getElementById("cd_c2_yes").style.display="none"; document.getElementById("cd_c2_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c2_no" style={{ display:"none"}} onClick={()=>{ setcd_col2_val(0); document.getElementById("cd_c2_yes").style.display="block"; document.getElementById("cd_c2_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 3 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">HSN Code</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c3_yes"  onClick={()=>{setcd_col3_val(1); document.getElementById("cd_c3_yes").style.display="none"; document.getElementById("cd_c3_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c3_no" style={{ display:"none"}} onClick={()=>{ setcd_col3_val(0); document.getElementById("cd_c3_yes").style.display="block"; document.getElementById("cd_c3_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 4 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Product Name</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c4_yes"  onClick={()=>{setcd_col4_val(1); document.getElementById("cd_c4_yes").style.display="none"; document.getElementById("cd_c4_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c4_no" style={{ display:"none"}} onClick={()=>{ setcd_col4_val(0); document.getElementById("cd_c4_yes").style.display="block"; document.getElementById("cd_c4_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 5 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Quantity</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c5_yes"  onClick={()=>{setcd_col5_val(1); document.getElementById("cd_c5_yes").style.display="none"; document.getElementById("cd_c5_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c5_no" style={{ display:"none"}} onClick={()=>{ setcd_col5_val(0); document.getElementById("cd_c5_yes").style.display="block"; document.getElementById("cd_c5_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 6 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Rate</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c6_yes"  onClick={()=>{setcd_col6_val(1); document.getElementById("cd_c6_yes").style.display="none"; document.getElementById("cd_c6_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c6_no" style={{ display:"none"}} onClick={()=>{ setcd_col6_val(0); document.getElementById("cd_c6_yes").style.display="block"; document.getElementById("cd_c6_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 7 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Amount</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c7_yes" onClick={()=>{setcd_col7_val(1); document.getElementById("cd_c7_yes").style.display="none"; document.getElementById("cd_c7_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c7_no" style={{ display:"none"}} onClick={()=>{ setcd_col7_val(0); document.getElementById("cd_c7_yes").style.display="block"; document.getElementById("cd_c7_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 8 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Discount %</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c8_yes" onClick={()=>{setcd_col8_val(1); document.getElementById("cd_c8_yes").style.display="none"; document.getElementById("cd_c8_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c8_no" style={{ display:"none"}} onClick={()=>{ setcd_col8_val(0); document.getElementById("cd_c8_yes").style.display="block"; document.getElementById("cd_c8_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 9 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Discount Amount</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c9_yes" onClick={()=>{setcd_col9_val(1); document.getElementById("cd_c9_yes").style.display="none"; document.getElementById("cd_c9_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c9_no" style={{ display:"none"}} onClick={()=>{ setcd_col9_val(0); document.getElementById("cd_c9_yes").style.display="block"; document.getElementById("cd_c9_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 10 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">SGST %</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c10_yes" onClick={()=>{setcd_col10_val(1); document.getElementById("cd_c10_yes").style.display="none"; document.getElementById("cd_c10_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c10_no" style={{ display:"none"}} onClick={()=>{ setcd_col10_val(0); document.getElementById("cd_c10_yes").style.display="block"; document.getElementById("cd_c10_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 11 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">SGST Amount</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c11_yes" onClick={()=>{setcd_col11_val(1); document.getElementById("cd_c11_yes").style.display="none"; document.getElementById("cd_c11_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c11_no" style={{ display:"none"}} onClick={()=>{ setcd_col11_val(0); document.getElementById("cd_c11_yes").style.display="block"; document.getElementById("cd_c11_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 12 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">CGST %</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c12_yes" onClick={()=>{setcd_col12_val(1); document.getElementById("cd_c12_yes").style.display="none"; document.getElementById("cd_c12_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c12_no" style={{ display:"none"}} onClick={()=>{ setcd_col12_val(0); document.getElementById("cd_c12_yes").style.display="block"; document.getElementById("cd_c12_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 13 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">CGST Amount</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c13_yes" onClick={()=>{setcd_col13_val(1); document.getElementById("cd_c13_yes").style.display="none"; document.getElementById("cd_c13_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c13_no" style={{ display:"none"}} onClick={()=>{ setcd_col13_val(0); document.getElementById("cd_c13_yes").style.display="block"; document.getElementById("cd_c13_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 14 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">IGST %</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c14_yes" onClick={()=>{setcd_col14_val(1); document.getElementById("cd_c14_yes").style.display="none"; document.getElementById("cd_c14_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c14_no" style={{ display:"none"}} onClick={()=>{ setcd_col14_val(0); document.getElementById("cd_c14_yes").style.display="block"; document.getElementById("cd_c14_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 15 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">IGST Amount</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c15_yes" onClick={()=>{setcd_col15_val(1); document.getElementById("cd_c15_yes").style.display="none"; document.getElementById("cd_c15_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c15_no" style={{ display:"none"}} onClick={()=>{ setcd_col15_val(0); document.getElementById("cd_c15_yes").style.display="block"; document.getElementById("cd_c15_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 16 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Tax %</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c16_yes" onClick={()=>{setcd_col16_val(1); document.getElementById("cd_c16_yes").style.display="none"; document.getElementById("cd_c16_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c16_no" style={{ display:"none"}} onClick={()=>{ setcd_col16_val(0); document.getElementById("cd_c16_yes").style.display="block"; document.getElementById("cd_c16_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 17 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Tax Amount</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c17_yes" onClick={()=>{setcd_col17_val(1); document.getElementById("cd_c17_yes").style.display="none"; document.getElementById("cd_c17_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c17_no" style={{ display:"none"}} onClick={()=>{ setcd_col17_val(0); document.getElementById("cd_c17_yes").style.display="block"; document.getElementById("cd_c17_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 18 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Total</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c18_yes" onClick={()=>{setcd_col18_val(1); document.getElementById("cd_c18_yes").style.display="none"; document.getElementById("cd_c18_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c18_no" style={{ display:"none"}} onClick={()=>{ setcd_col18_val(0); document.getElementById("cd_c18_yes").style.display="block"; document.getElementById("cd_c18_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 19 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10 text-warning">Edit</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c19_yes" onClick={()=>{setcd_col19_val(1); document.getElementById("cd_c19_yes").style.display="none"; document.getElementById("cd_c19_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c19_no" style={{ display:"none"}} onClick={()=>{ setcd_col19_val(0); document.getElementById("cd_c19_yes").style.display="block"; document.getElementById("cd_c19_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 20 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10 text-danger">Delete</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c20_yes" onClick={()=>{setcd_col20_val(1); document.getElementById("cd_c20_yes").style.display="none"; document.getElementById("cd_c20_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c20_no" style={{ display:"none"}} onClick={()=>{ setcd_col20_val(0); document.getElementById("cd_c20_yes").style.display="block"; document.getElementById("cd_c20_no").style.display="none"; }}></i>
                              </div></div></li> 
                            {/*}  <div class="dropdown-divider"></div>
                              <button class="btn btn-danger">Close</button>*/}
                            </div> 
                          </div>
                          {/* COLUMNS MANAGEMENT DROPDOWN END */}

                          </div>
                          </div>
                          {/* COLUMN FILTER FOR ADD PRODUCTS LIST END */}
  
  


  
          {/* PRODUCT LIST START */}                
          <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Products</h4>
                    <p class="text-success" style={{textAlign:"center"}}>{esuccess_msg}</p>
                    <code>{e1error_msg}</code>
                    <p class="card-description">
                    </p>
                    <div class="table-responsive">
                      <table class="table table-hover">
                        <thead>
                          <tr >
                            <th  style={{fontWeight:"bold"}} hidden={cd_col1_val==1}>Sn</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col2_val==1}>Product Code</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col3_val==1}>HSN Code</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col4_val==1}>Product Name</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col5_val==1}>Quantity</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col6_val==1}>Rate</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col7_val==1}>Amount</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col8_val==1}>Discount %</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col9_val==1}>Discount Amount</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col10_val==1}>SGST %</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col11_val==1}>SGST Amount</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col12_val==1}>CGST % </th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col13_val==1}>CGST Amount</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col14_val==1}>IGST %</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col15_val==1}>IGST Amount</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col16_val==1}>Tax %</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col17_val==1}>Tax Amount</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col18_val==1}>Total</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col19_val==1}></th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col20_val==1}></th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                       inventory.map((curElm1,ind)=>{

                         return(
                         <>
                        <tr key={curElm1.Ivno}>
                            <td hidden={cd_col1_val==1}>{curElm1.Sn}</td>
                            <td hidden={cd_col2_val==1}>{curElm1.Product_code}</td>
                            <td hidden={cd_col3_val==1}>{curElm1.Hsn_code}</td>
                            <td hidden={cd_col4_val==1}>{curElm1.Product_name}</td>
                            <td hidden={cd_col5_val==1}>{curElm1.Quantity}</td>
                            <td hidden={cd_col6_val==1}>{curElm1.Rate}</td>
                            <td hidden={cd_col7_val==1}>{curElm1.Amount}</td>
                            <td hidden={cd_col8_val==1}>{curElm1.Discount_per}</td>
                            <td hidden={cd_col9_val==1}>{curElm1.Discount_amt}</td>
                            <td hidden={cd_col10_val==1}>{curElm1.Sgst_per}</td>
                            <td hidden={cd_col11_val==1}>{curElm1.Sgst_amt}</td>
                            <td hidden={cd_col12_val==1}>{curElm1.Cgst_per}</td>
                            <td hidden={cd_col13_val==1}>{curElm1.Cgst_amt}</td>
                            <td hidden={cd_col14_val==1}>{curElm1.Igst_per}</td>
                            <td hidden={cd_col15_val==1}>{curElm1.Igst_amt}</td>
                            <td hidden={cd_col16_val==1}>{curElm1.Tax_per}</td>
                            <td hidden={cd_col17_val==1}>{curElm1.Tax_amt}</td>
                            <td hidden={cd_col18_val==1}>{curElm1.Total}</td>      
                            <td hidden={cd_col19_val==1}  onClick={()=>{ 
               const createForm = document.getElementById('edit-form');const currentDisplay = createForm.style.display;  createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; 
                  //Setting Product Variables for Edit
                  setivno(curElm1.Ivno);seteproduct_id(curElm1.Product_id);seteproduct_name(curElm1.Product_name);seteproduct_code(curElm1.Product_code);seteproduct_hsn(curElm1.Hsn_code);seterate(curElm1.Rate);setequantity(curElm1.Quantity);seteunits(curElm1.Units);setediscount_per(curElm1.Discount_per);setediscount_amt(curElm1.Discount_amt);
                  setesgst_per(curElm1.Sgst_per);setesgst_amt(curElm1.Sgst_amt);setecgst_per(curElm1.Cgst_per);setecgst_amt(curElm1.Cgst_amt);seteigst_per(curElm1.Igst_per);seteigst_amt(curElm1.Igst_amt);setetax_per(curElm1.Tax_per);setetax_amt(curElm1.Tax_amt);seteamount(curElm1.Amount);setetotal(curElm1.Total);

                       }}><i class="mdi mdi-pencil-box text-warning" style={{fontSize:"1.4rem"}}></i></td>
                            <td hidden={cd_col20_val==1}><i class="mdi mdi-delete-forever text-danger" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                        //Setting expense Id for Delete
                      sessionStorage.setItem("DIvno00",curElm1.Ivno);      sessionStorage.setItem("DTrno00",curElm1.Tr_no); deleteproduct(); }}></i></td>

       
                            </tr>    
                            </>
                         )
                         }
					   
	                  		 )
                         }             
                             
                    
                         
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div><button class="btn btn-primary mb-2 btn-icon-text" onClick={()=>{ document.getElementById("create-form").style.display="block"; }}><i class="mdi mdi-plus-circle"></i> Add Product</button></div>
                </div>              
              </div>
             {/* PRODUCT LIST END */}  


            
        {/* PRODUCT LIST BOX START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-4 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"1rem",display:"none"}} id="products-list-box">
                <div class="card">
                <div class="d-flex justify-content-between">
                    <div>
                    <h4 style={{padding:'1rem'}}>Products</h4>
                    </div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ setcdefault(1); document.getElementById("products-list-box").style.display="none"; }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                    
                    <table class="table table-hover">
                      <thead>
                        <tr>
                        {/*}  <th>Product Id</th> */}
                          <th>Product Name</th>
                        {/*}  <th>Product HSN</th>
                          <th>Product Code</th> */}
                          <th>Sales Rate</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                       products.map((curElm7,ind)=>{

                         return(
                         <>
                        <tr key={curElm7.Product_id}>
                          {/* <td>{curElm7.Product_id}</td> */}
                          <td>{curElm7.Product_name}</td>
                          {/* <td>{curElm7.Product_hsn}</td>
                          <td>{curElm7.Product_code}</td> */}
                          <td>{curElm7.Sales_rate}</td>
                          <td><i class="mdi mdi-assistant text-warning" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                        //Setting Product Details for 
                       /* setproduct_id(curElm7.Product_name);*/ setproduct_code(curElm7.Product_code);setproduct_hsn(curElm7.Product_hsn);setproduct_name(curElm7.Product_name);setunits(curElm7.Units);
                        setrate(curElm7.Sales_rate);setdiscount_per(curElm7.Discount_per);setsgst_per(curElm7.Sgst_per);setcgst_per(curElm7.Cgst_per);setigst_per(curElm7.Igst_per);settax_per(curElm7.Tax_per);
                        document.getElementById("products-list-box").style.display="none";
                              }}></i></td>

                        </tr>
                        </>
                         )
                         }
					   
	                  		 )
                         }
                      </tbody>
                    </table>
              
                </div>
              </div>
              </div>
              {/* PRODUCT LIST BOX END */}

             {/* ADD PRODUCTS FORM START */}  
              <div class="row">
              <div class="col-12 grid-margin create-form" id="create-form" style={{textAlign:"left",display:"none"}}>
              <div class="card">
              <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ 
                        
                        setproduct_name(" ");settype(" ");setproduct_id(" ");setproduct_code(" ");setproduct_hsn(" ");setrate(" ");setquantity(" ");setunits(" ");setamount(" ");settotal(" ");
                        setdiscount_per(" ");setdiscount_amt(" ");setsgst_per(" ");setsgst_amt(" ");setcgst_per(" ");setcgst_amt(" ");setigst_per(" ");setigst_amt(" ");settax_per(" ");settax_amt(" ");
                        seterror_msg(" ");setsuccess_msg(" ");

                        document.getElementById("create-form").style.display="none"; }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                  <div class="card-body">
                    <h4 class="card-title">Add Products</h4>
                    
                    <div class="form-sample">
                      <p class="card-description">add products here</p>
                      <p class="text-success" style={{textAlign:"center"}}>{success_msg}</p>
                      <code>{error_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product Name<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Levis red T-Shirt " id="product_name" value={product_name} onChange={updateproduct_name} />
                            <code>{product_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product Code</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="87766" id="product_code" value={product_code} onChange={updateproduct_code} />
                            <code>{product_code_er}</code>
                            </div>
                          </div>
                        </div>   
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">HSN Code</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="H65S433" id="product_hsn" value={product_hsn} onChange={updateproduct_hsn} />
                              <code>{product_hsn_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Rate</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="500.00" id="rate" value={rate} onChange={updaterate}/>
                              <code>{rate_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Quantity</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="10.00" id="quantity" value={quantity} onChange={updatequantity} />
                              <code>{quantity_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Units</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="pieces" id="units" value={units} onChange={updateunits} />
                              <code>{units_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="5000.00" id="amount" value={amount} onChange={updateamount}/>
                              <code>{amount_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Total</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" style={{fontWeight:"bold"}} placeholder="5000.00" id="total" value={total} onChange={updatetotal} />
                              <code>{total_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Discount %</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="10.00" id="discount_per" value={discount_per} onChange={updatediscount_per} />
                              <code>{discount_per_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Discount Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="500.00" id="discount_amt" value={discount_amt} onChange={updatediscount_amt}/>
                              <code>{discount_amt_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">SGST %</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="05.00" id="sgst_per" value={sgst_per} onChange={updatesgst_per} />
                              <code>{sgst_per_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">SGST Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="50.00" id="sgst_amt" value={sgst_amt} onChange={updatesgst_amt} />
                              <code>{sgst_amt_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">CGST %</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="05.00" id="cgst_per" value={cgst_per} onChange={updatecgst_per} />
                              <code>{cgst_per_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">CGST Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="50.00" id="cgst_amt" value={cgst_amt} onChange={updatecgst_amt} />
                              <code>{cgst_amt_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">IGST %</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="05.00" id="igst_per" value={igst_per} onChange={updateigst_per}/>
                              <code>{igst_per_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">IGST Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="50.00" id="igst_amt" value={igst_amt} onChange={updateigst_amt} />
                              <code>{igst_amt_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Tax %</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="05.00" id="tax_per" value={tax_per} onChange={updatetax_per}/>
                              <code>{tax_per_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Tax Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="50.00" id="tax_amt" value={tax_amt} onChange={updatetax_amt} />
                              <code>{tax_amt_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                        
                      
                        <button class="btn btn-primary" style={{fontWeight:"bold"}} onClick={addproduct}>Add Product</button>
                      
                       
    
                     
                   
                    </div>
                  </div>
                </div>
              </div>
          </div>
          {/* ADD PRODUCTS FORM END */}  

            {/* MAIN FORM SUBMIT START */}
            <div class="card" style={{textAlign:"left"}}>
                  <div class="card-body">
                  {/*}  <h4 class="card-title">Create Sales</h4> */}
                    <div class="form-sample">
                      {/*<p class="card-description">add your sales details here</p>*/}
                      <code style={{textAlign:"center"}}>{error_msg1}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Amount</label>
                            <div class="col-sm-9">
                            <label>{final_amt}</label>
                            <input type="hidden" class="form-control" placeholder="final_amt" id="final_amt" value={final_amt} edittable="false" />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Shipping and Carriages</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="5000.00"  id="shipping_carriages" value={shipping_carriages} onChange={updateshipping_carriages} />
                            <code>{shipping_carriages_er}</code>
                            </div>
                          </div>
                        </div>   
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Other Deductions</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="5000.00"  id="other_deductions" value={other_deductions} onChange={updateother_deductions} />
                            <code>{other_deductions_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Other Expenses</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="5000.00"  id="other_expenses" value={other_expenses} onChange={updateother_expenses} />
                            <code>{other_expenses_er}</code> 
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Total</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="25000.00"  id="final_total" value={final_total} onChange={updatefinal_total} />
                            <code>{final_total_er}</code>
                            </div>
                          </div>
                        </div>
                        </div>
                        <div class="form-group">
                        <label for="exampleTextarea1">Particular<code>*</code></label>
                        <textarea class="form-control" rows="2" id="particular" value={particular} onChange={updateparticular} ></textarea>
                        <code>{particular_er}</code>
                        </div>
                        
                          <button class="btn-lg btn-warning" style={{fontWeight:"bold"}} onClick={completequotation}>Submit</button>
                      
                      
        
                      
                      

           
                        
                      
                       
                       
    
                     
                   
                    </div>
                  </div>
                </div>

                {/* MAIN FORM SUBMIT END */}

    

            
          </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
        {/*}  <footer class="footer">
            <div class="d-sm-flex justify-content-center justify-content-sm-between">
              <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
</>
);

}
//}
export default UserQuotation_addproducts_edit;