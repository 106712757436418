import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import moment from 'moment/moment';



import '../Resources/dashboard.css';
import axios from '../../axios.js';
import Navbar from '../navbar';

 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");

 //Other Constatants of Sessions
 const startup_key=sessionStorage.getItem("StartupKey10");
 const startup_id=sessionStorage.getItem("StartupId10");



function Startupedit1(){

  
//answer1
const[answer1,setanswer1]=useState();const updateanswer1 = (event) => { setanswer1(event.target.value);}; const[answer1_er,setanswer1_er]=useState();
//answer2
const[answer2,setanswer2]=useState();const updateanswer2 = (event) => { setanswer2(event.target.value);}; const[answer2_er,setanswer2_er]=useState();
//answer3
const[answer3,setanswer3]=useState();const updateanswer3 = (event) => { setanswer3(event.target.value);}; const[answer3_er,setanswer3_er]=useState();
//answer4
const[answer4,setanswer4]=useState();const updateanswer4 = (event) => { setanswer4(event.target.value);}; const[answer4_er,setanswer4_er]=useState();
//answer5
const[answer5,setanswer5]=useState();const updateanswer5 = (event) => { setanswer5(event.target.value);}; const[answer5_er,setanswer5_er]=useState();
//answer6
const[answer6,setanswer6]=useState();const updateanswer6 = (event) => { setanswer6(event.target.value);}; const[answer6_er,setanswer6_er]=useState();
//answer7
const[answer7,setanswer7]=useState();const updateanswer7 = (event) => { setanswer7(event.target.value);}; const[answer7_er,setanswer7_er]=useState();
//answer8
const[answer8,setanswer8]=useState();const updateanswer8 = (event) => { setanswer8(event.target.value);}; const[answer8_er,setanswer8_er]=useState();
//answer9
const[answer9,setanswer9]=useState();const updateanswer9 = (event) => { setanswer9(event.target.value);}; const[answer9_er,setanswer9_er]=useState();
//answer10
const[answer10,setanswer10]=useState();const updateanswer10 = (event) => { setanswer10(event.target.value);}; const[answer10_er,setanswer10_er]=useState();
//answer11
const[answer11,setanswer11]=useState();const updateanswer11 = (event) => { setanswer11(event.target.value);}; const[answer11_er,setanswer11_er]=useState();
//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();
const[errorvar,seterrorvar]=useState();


useEffect(()=>{
   
  getData();
  
  },[])
  
//******************* GET STARTUP ANSWERS FUNCTION START  ********************//
function getData(){


  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((startup_id!=null)||(startup_id!=" "))&&((startup_key!=null)||(startup_key!=" "))){
 
 axios.post("network-get-startup-answer-byId", {
   Log_id:logid,
   Token:token,
   Startup_id:startup_id,
   Startup_key:startup_key
   })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("NetworkVal00");
     sessionStorage.removeItem("StartupId10");
     sessionStorage.removeItem("StartupKey10");

     window.location.replace("/networking-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){

   //Setting Data
   
   setanswer1(response.data.answer1);//answer1
   setanswer2(response.data.answer2);//answer2
   setanswer3(response.data.answer3);//answer3
   setanswer4(response.data.answer4);//answer4
   setanswer5(response.data.answer5);//answer5
   setanswer6(response.data.answer6);//answer6
   setanswer7(response.data.answer7);//answer7
   setanswer8(response.data.answer8);//answer8
   setanswer9(response.data.answer9);//answer9
   setanswer10(response.data.answer10);//answer10
   setanswer11(response.data.answer11);//answer11





  }

  //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){
    seterrorvar("Cannot Fetch Data !");

  }

  //Data Violation
  if(response.data.error==102){ 
  
  seterrorvar("Cannot Fetch Data !");


  }


  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("logName00");
   sessionStorage.removeItem("StartupId00");
   sessionStorage.removeItem("StartupKey00");
   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* GET STARTUP ANSWERS DATA FUNCTION END  ********************//


/***************** REGISTER FUNCTION START  ******************/
function submitanswers(){

  
  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))&&((startup_id!=null)||(startup_id!=" "))&&((startup_key!=null)||(startup_key!=" "))){


    //Setting Error Variables
    setanswer1_er(" "); setanswer2_er(" ");setanswer3_er(" ");setanswer4_er(" ");setanswer5_er(" ");setanswer6_er(" ");setanswer7_er();setanswer8_er();setanswer9_er();setanswer10_er();setanswer11_er();
    seterror_msg(" ");

    const $answer1 = document.getElementById("answer1").value; //Fetching answer1 Value
    const $answer2 = document.getElementById("answer2").value; //Fetching answer2 Value
    const $answer3 = document.getElementById("answer3").value; //Fetching answer3 Value
    const $answer4 = document.getElementById("answer4").value; //Fetching answer4 Value
    const $answer5 = document.getElementById("answer5").value; //Fetching answer5 Value
    const $answer6 = document.getElementById("answer6").value; //Fetching answer6 Value
    const $answer7 = document.getElementById("answer7").value; //Fetching answer7 Value
    const $answer8 = document.getElementById("answer8").value; //Fetching answer8 Value
    const $answer9 = document.getElementById("answer9").value; //Fetching answer9 Value
    const $answer10 = document.getElementById("answer10").value; //Fetching answer10 Value
    const $answer11 = document.getElementById("answer11").value; //Fetching answer11 Value

    
  //Sending Data through axios
  axios.post("network-register-startup-answers", { 
    Log_id:logid,
    Token:token,
    Startup_id:startup_id,
    Startup_key:startup_key,
    Answer1:$answer1,
    Answer2:$answer2,
    Answer3:$answer3,
    Answer4:$answer4,
    Answer5:$answer5,
    Answer6:$answer6,
    Answer7:$answer7,
    Answer8:$answer8,
    Answer9:$answer9,
    Answer10:$answer10,
    Answer11:$answer11,
    })
    
    .then((response) => {
    
    
    //Validation Errors
    if(response.data.validatorerror){
    setanswer1_er(response.data.validatorerror.Answer1);
    setanswer2_er(response.data.validatorerror.Answer2);
    setanswer3_er(response.data.validatorerror.Answer3);
    setanswer4_er(response.data.validatorerror.Answer4);
    setanswer5_er(response.data.validatorerror.Answer5);
    setanswer6_er(response.data.validatorerror.Answer6);
    setanswer7_er(response.data.validatorerror.Answer7);
    setanswer8_er(response.data.validatorerror.Answer8);
    setanswer9_er(response.data.validatorerror.Answer9);
    setanswer10_er(response.data.validatorerror.Answer10);
    setanswer11_er(response.data.validatorerror.Answer11);
    seterror_msg("Cannot Add Answers !");
    
    
    }
    //Invalid Entries
    if(response.data.error==102){ 
    
    setanswer1_er(response.data.answer1_er);
    setanswer2_er(response.data.answer2_er);
    setanswer3_er(response.data.answer3_er);
    setanswer4_er(response.data.answer4_er);
    setanswer5_er(response.data.answer5_er);
    setanswer6_er(response.data.answer6_er);
    setanswer7_er(response.data.answer7_er);
    setanswer8_er(response.data.answer8_er);
    setanswer9_er(response.data.answer9_er);
    setanswer10_er(response.data.answer10_er);
    setanswer11_er(response.data.answer11_er);
    seterror_msg("Cannot Add Answers !");
    
    
    }
    
    //Success
    if(response.data.successvar==1){
    
    
    //window.location.replace("/businesstools-startworking");
    document.getElementById("success-card").style.display="block";
    
    }
    //failure
    if(response.data.successvar==2){
    seterror_msg("Cannot Add Answers !");
    }
    }    );
    


  }
  //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
 sessionStorage.removeItem("logStatus00");
 sessionStorage.removeItem("logId00");
 sessionStorage.removeItem("logToken00");
 sessionStorage.removeItem("logName00");
 sessionStorage.removeItem("StartupId10");
 sessionStorage.removeItem("StartupKey10");
 window.location.replace("/businesstools-login");
 
 
 }    

}


return(
    <>
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Networking|Startup Register</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div class="container-scroller">

  {/*}  <Sidebar /> */}
    <div class="container-fluid page-body-wrapper">


       <Navbar /> 
        {/*} partial */}
        <div class="main-panel">
          <div class="content-wrapper pb-0">


    {/*  SUCCESS CARD START */}
    <div class="d-flex justify-content-center">
                <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Answers Updated Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue to Managing Team .</p>
                      <a href="networking-startup-team-edit"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/*  SUCCESS CARD END */}
       

            <div class="col-12 grid-margin stretch-card">
                <div class="card" style={{textAlign:"left"}}>
                  <div class="card-body">
                    <h4 class="card-title">Tell us more about your startup</h4>
                    <p class="card-description">give more information for your startup to help investors ascertain you better. You can change this later</p>
                    <code>{error_msg}</code>
                    <div class="forms-sample">
                    <div class="form-group">
                        <label for="exampleInputName1">1.) Your Startup Pitchline.</label>
                        <textarea type="text" class="form-control" placeholder="My pitchline"  id="answer1" onChange={updateanswer1} value={answer1}  />
                        <code>{answer1_er}</code>
                      </div>
                      <div class="form-group">
                        <label for="exampleInputName1">2.) Describe the problem you are trying to solve, and how are you solving it.</label>
                        <textarea type="text" class="form-control"  id="answer2" onChange={updateanswer2} value={answer2}  placeholder="my startup is solving problem......." />
                      <code>{answer2_er}</code>
                      </div>
                      <div class="form-group">
                        <label for="exampleInputEmail3">3.) How much have you worked on your idea or start-up, and what are your future plans?</label>
                        <textarea type="email" class="form-control" id="answer3" onChange={updateanswer3} value={answer3}  placeholder="we have pretty much worked on idea...." />
                        <code>{answer3_er}</code>
                      </div>
                      <div class="form-group">
                        <label for="exampleInputPassword4">4.) Define your target audience</label>
                        <textarea type="password" class="form-control"  id="answer4" onChange={updateanswer4} value={answer4}  placeholder="our target audience comprises of..." />
                        <code>{answer4_er}</code>
                      </div>
                      <div class="form-group">
                        <label for="exampleSelectGender">5.) Revenue traction or estimates (the revenue you have already generated in past or seek to achieve in future)</label>
                        <textarea type="password" class="form-control" id="answer5" onChange={updateanswer5} value={answer5}  placeholder="we have so far made ......" />
                        <code>{answer5_er}</code>
                      </div>
                      <div class="form-group">
                        <label for="exampleInputCity1">6.) How will you market or promote your business, ways in which you are currently marketing your product?</label>
                        <textarea type="text" class="form-control"  id="answer6" onChange={updateanswer6} value={answer6}  placeholder="we will market our business......" />
                        <code>{answer6_er}</code>
                      </div>
                      <div class="form-group">
                        <label for="exampleTextarea1">7.) Have you taken or applied for funding or received any grants etc. Provide brief introduction for same.</label>
                        <textarea type="text" class="form-control"  id="answer7" onChange={updateanswer7} value={answer7}  placeholder="yes we have received......." />
                        <code>{answer7_er}</code>
                      </div>
                      <div class="form-group">
                        <label for="exampleTextarea1">8.) Are you currently a part of any incubation centre or accelerator program, if yes specify the name and the time you have been with them.</label>
                        <textarea type="text" class="form-control"  id="answer8" onChange={updateanswer8} value={answer8}  placeholder="yes we are part of......." />
                        <code>{answer8_er}</code>
                      </div> 
                      <div class="form-group">
                        <label for="exampleTextarea1">9.) Any more details want to specify.</label>
                        <textarea type="text" class="form-control"  id="answer9" onChange={updateanswer9} value={answer9}  placeholder="other details that we......." />
                        <code>{answer9_er}</code>
                      </div> 
                      <div class="form-group">
                        <label for="exampleTextarea1">10.) Other links you want to attach (including your social media links and others)</label>
                        <textarea type="text" class="form-control"  id="answer10" onChange={updateanswer10} value={answer10}  placeholder="yes we want to....." />               
                        <code>{answer10_er}</code>
                        </div> 
                      <div class="form-group">
                        <label for="exampleTextarea1">11.) Attach Link to your presentation (make sure it is accessible through the link provided and is not private)</label>
                        <textarea type="text" class="form-control"  id="answer11" onChange={updateanswer11} value={answer11}  placeholder="https://mypres......" />
                        <code>{answer11_er}</code>
                      </div> 

                      <button class="btn btn-primary" onClick={submitanswers} >Submit Answers</button>                    
                      </div>
                  </div>
                </div>
              </div>

        </div>
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
</>
);

}
export default Startupedit1;