import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';




/*import Amanbg from './amanbg';*/


function Skills(){

  

    return(
<>


{/* ADD SKILLS SECTION START */}
<div class="content-wrapper">

<div class="col-12">


                <div class="card">
                  <div class="card-body">
                    <h2 >ADD SKILLS</h2>
                    <div class="form-sample" style={{textAlign:"left"}}>
                      <p class="card-description"> Add Skills for you </p>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Select Skills</label>
                            <div class="col-sm-9">
                            <select class="form-control" style={{padding:"1rem", border:"0.1rem solid black"}}>
                                <option>PHD.</option>
                                <option>Master's Degree</option>
                                <option>Bachelor's Degree</option>
                                <option>Certification Course</option>
                                <option>Diploma</option>
                                <option>10+2</option>
                                <option>10</option>
                                <option>Others</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type Value</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" id="firstname" style={{border:"0.1rem solid black"}}  />
                      </div>
                          </div>
                        </div>
                      </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Select Industry</label>
                            <div class="col-sm-9">
                            <select class="form-control" style={{padding:"1rem", border:"0.1rem solid black"}}>
                                <option>PHD.</option>
                                <option>Master's Degree</option>
                                <option>Bachelor's Degree</option>
                                <option>Certification Course</option>
                                <option>Diploma</option>
                                <option>10+2</option>
                                <option>10</option>
                                <option>Others</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Select Category</label>
                            <div class="col-sm-9">
                          <select class="form-control" style={{padding:"1rem", border:"0.1rem solid black"}}>
                                <option>PHD.</option>
                                <option>Master's Degree</option>
                                <option>Bachelor's Degree</option>
                                <option>Certification Course</option>
                                <option>Diploma</option>
                                <option>10+2</option>
                                <option>10</option>
                                <option>Others</option>
                              </select>                      </div>
                          </div>
                        </div>
                      </div>
            
              </div>
                          <br/>
                          <br/>
                          <br/>

                          <button type="button" class="btn btn-outline-danger btn-icon-text" style={{}}  onClick={()=>{        
                        //Back Button                  
                          document.getElementById("section-3").style.display="block";//Showing previous section
                          document.getElementById("section-4").style.display="none";//Hiding next section
                          sessionStorage.setItem("pval","30%");// Updating Progress Bar
                      }} > <i class="mdi mdi-chevron-double-left"></i>Back </button>
                     <button type="button" class="btn btn-outline-success btn-icon-text" style={{marginLeft:"3rem"}}  onClick={()=>{        
                        //Next Button                  
                          document.getElementById("section-5").style.display="block";//Showing next section
                          document.getElementById("section-4").style.display="none";//Hiding previous section
                          sessionStorage.setItem("pval","50%");// Updating Progress Bar
                      }} > Next <i class="mdi mdi-chevron-double-right"></i></button>            
                     <button type="button" class="btn btn-gradient-dark float-end">Save</button>

  
              </div>
                         </div>
                
              </div>
              </div>
              {/* ADD SKILLS SECTION END */}

              </>
);
    }

export default Skills;