import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import moment from 'moment/moment';


import './Resources/dashboard.css';
import axios from '../axios.js';
import Navbar from './navbar';
import Sidebar from './Sidebar';

import Chart from "chart.js/auto";
import { Line, Bar, Pie } from "react-chartjs-2";

function Startworking(){


  
 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logname=sessionStorage.getItem("logName00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const busyid=sessionStorage.getItem("BusyId00");
 const counterid=sessionStorage.getItem("CounterId00");
  

 //SETTING DEFAULT STATE VARIABLES FOR CHART */
 
 //LINECHART VARIABLES

 //Sales Line Chart
 const[lc_sales,setlc_sales] = useState([],);
 const[lc_date,setlc_date] = useState();
 const[lc_data,setlc_data] = useState();
 const[lc_default,setlc_default] = useState(0);
 const[lc_start_date,setlc_start_date] = useState();const updatelc_start_date = (event) => { setlc_start_date(event.target.value); }; 
 const[lc_end_date,setlc_end_date] = useState();const updatelc_end_date = (event) => { setlc_end_date(event.target.value); }; 
 const[lc_date_error,setlc_date_error] = useState();

 
 //Purchase Line Chart
 const[lc1_sales,setlc1_sales] = useState([],);
 const[lc1_date,setlc1_date] = useState();
 const[lc1_data,setlc1_data] = useState();
 const[lc1_default,setlc1_default] = useState(0);
 const[lc1_start_date,setlc1_start_date] = useState();const updatelc1_start_date = (event) => { setlc1_start_date(event.target.value); }; 
 const[lc1_end_date,setlc1_end_date] = useState();const updatelc1_end_date = (event) => { setlc1_end_date(event.target.value); }; 
 const[lc1_date_error,setlc1_date_error] = useState();


 //Pie Chart 1
 const[pc_default,setpc_default] = useState(0);
 const[pc_start_date,setpc_start_date] = useState(0);const updatepc_start_date = (event) => { setpc_start_date(event.target.value); }; 
 const[pc_end_date,setpc_end_date] = useState(0);const updatepc_end_date = (event) => { setpc_end_date(event.target.value); }; 
 const[pc_incomes,setpc_incomes] = useState();
 const[pc_expenses,setpc_expenses] = useState();
 const[pc_iebalance,setpc_iebalance] = useState();

 
 //Pie Chart 2
 const[pc1_default,setpc1_default] = useState(0);
 const[pc1_start_date,setpc1_start_date] = useState(0);const updatepc1_start_date = (event) => { setpc1_start_date(event.target.value); }; 
 const[pc1_end_date,setpc1_end_date] = useState(0);const updatepc1_end_date = (event) => { setpc1_end_date(event.target.value); }; 
 const[pc_cash_inflow,setpc_cash_inflow] = useState();
 const[pc_cash_outflow,setpc_cash_outflow] = useState();
 const[pc_cash_balance,setpc_cash_balance] = useState();
 
 
 //Pie Chart 3
 const[pc2_default,setpc2_default] = useState(0);
 const[pc2_start_date,setpc2_start_date] = useState(0);const updatepc2_start_date = (event) => { setpc2_start_date(event.target.value); }; 
 const[pc2_end_date,setpc2_end_date] = useState(0);const updatepc2_end_date = (event) => { setpc2_end_date(event.target.value); }; 
 const[pc_bank_inflow,setpc_bank_inflow] = useState();
 const[pc_bank_outflow,setpc_bank_outflow] = useState();
 const[pc_bank_balance,setpc_bank_balance] = useState();

 
 //Gst and Tax Line Chart
 const[lc2_sales,setlc2_sales] = useState([],);
 const[lc2_date,setlc2_date] = useState();
 const[lc2_sgst_in,setlc2_sgst_in] = useState();
 const[lc2_sgst_out,setlc2_sgst_out] = useState();
 const[lc2_cgst_in,setlc2_cgst_in] = useState();
 const[lc2_cgst_out,setlc2_cgst_out] = useState();
 const[lc2_igst_in,setlc2_igst_in] = useState();
 const[lc2_igst_out,setlc2_igst_out] = useState();
 const[lc2_tax_in,setlc2_tax_in] = useState();
 const[lc2_tax_out,setlc2_tax_out] = useState();
 const[lc2_default,setlc2_default] = useState(0);
 const[lc2_start_date,setlc2_start_date] = useState();const updatelc2_start_date = (event) => { setlc2_start_date(event.target.value); }; 
 const[lc2_end_date,setlc2_end_date] = useState();const updatelc2_end_date = (event) => { setlc2_end_date(event.target.value); }; 
 const[lc2_date_error,setlc2_date_error] = useState();


//Other Data
const[debtors_balance,setdebtors_balance] = useState();
const[creditors_balance,setcreditors_balance] = useState();
const[inventory_balance,setinventory_balance] = useState();

 //Error Variables
 const[errorvar,seterrorvar] = useState();

 
useEffect(()=>{
   
  getData();
  
  },[])


//******************* GET DASHBOARD DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))){
 

 axios.post("bt-get-dashboard-default", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Sn_start :0,
   Sn_end :0,
   Sn_total :0,
   Sn_limit :0,
   //Line Chart
   lc_default:lc_default,
   lc_start_date:moment(lc_start_date).format('YYYY-MM-DD'),
   lc_end_date:moment(lc_end_date).format('YYYY-MM-DD'),
   //Line Chart 1
   lc1_default:lc1_default,
   lc1_start_date:moment(lc1_start_date).format('YYYY-MM-DD'),
   lc1_end_date:moment(lc1_end_date).format('YYYY-MM-DD'),
   //Pie Chart
   pc_default:pc_default,
   pc_start_date:moment(pc_start_date).format('YYYY-MM-DD'),
   pc_end_date:moment(pc_end_date).format('YYYY-MM-DD'),
   //Pie Chart 1
   pc1_default:pc1_default,
   pc1_start_date:moment(pc1_start_date).format('YYYY-MM-DD'),
   pc1_end_date:moment(pc1_end_date).format('YYYY-MM-DD'),
   //Pie Chart 2
   pc2_default:pc2_default,
   pc2_start_date:moment(pc2_start_date).format('YYYY-MM-DD'),
   pc2_end_date:moment(pc2_end_date).format('YYYY-MM-DD'),
   //Line Chart 2
   lc2_default:lc2_default,
   lc2_start_date:moment(lc2_start_date).format('YYYY-MM-DD'),
   lc2_end_date:moment(lc2_end_date).format('YYYY-MM-DD'),

 })
 .then((response) => {

  //console.log(response);


  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
     return;
  }
  //Success Data Fetched
  if(response.data.successvar==1){



   //Setting Data
  //Sales Line Chart
  const lc_sales = response.data.lc_sales;
  // Extract data for line chart
  setlc_date(lc_sales.map((entry) => entry.Date));
  setlc_data(lc_sales.map((entry) => entry.Amount));

  //Purchase Line Chart
  const lc_purchase = response.data.lc_purchase;
  // Extract data for line chart
  setlc1_date(lc_purchase.map((entry1) => entry1.Date));
  setlc1_data(lc_purchase.map((entry1) => entry1.Amount));

   //Income Expense Pie Chart
    setpc_incomes(response.data.pc_incomes);
    setpc_expenses(response.data.pc_expenses);
    setpc_iebalance(response.data.pc_iebalance);

    //Cash Summary Pie Chart
    setpc_cash_inflow(response.data.pc_cash_inflow);
    setpc_cash_outflow(response.data.pc_cash_outflow);
    setpc_cash_balance(response.data.pc_cash_balance);

    //Bank Summary Pie Chart
    setpc_bank_inflow(response.data.pc_bank_inflow);
    setpc_bank_outflow(response.data.pc_bank_outflow);
    setpc_bank_balance(response.data.pc_bank_balance);

    
  //Gst and Tax Line Chart
  const lc_gstandtax = response.data.lc_gstandtax;
  // Extract data for line chart
  setlc2_date(lc_gstandtax.map((entry2) => entry2.Date));
  setlc2_sgst_in(lc_gstandtax.map((entry2) => entry2.Sgst_in));
  setlc2_sgst_out(lc_gstandtax.map((entry2) => entry2.Sgst_out));
  setlc2_cgst_in(lc_gstandtax.map((entry2) => entry2.Cgst_in));
  setlc2_cgst_out(lc_gstandtax.map((entry2) => entry2.Cgst_out));
  setlc2_igst_in(lc_gstandtax.map((entry2) => entry2.Igst_in));
  setlc2_igst_out(lc_gstandtax.map((entry2) => entry2.Igst_out));
  setlc2_tax_in(lc_gstandtax.map((entry2) => entry2.Tax_in));
  setlc2_tax_out(lc_gstandtax.map((entry2) => entry2.Tax_out));

  //Other Data
  setdebtors_balance((response.data.debtors_balance).toFixed(2));
  setcreditors_balance((response.data.creditors_balance).toFixed(2));
  setinventory_balance((response.data.inventory_balance).toFixed(2));

  }
  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* GET DASHBOARD DATA FUNCTION END  ********************//


 /* SALES LINE CHART START */
 const data1 = {
  labels: lc_date,
  datasets: [
    {
      label: "Sales",
      backgroundColor: ["#1862d6" ],
      borderColor: "#1862d6",
      data: lc_data,
    }
  ],
};
/* SALES LINE CHART END */ 


 /* PURCHASE LINE CHART START */
 const data2 = {
  labels: lc1_date,
  datasets: [
    {
      label: "Purchase",
      backgroundColor: ["rgb(255, 99, 132)" ],
      borderColor: "rgb(255, 99, 132)",
      data: lc1_data,
    }
  ],
};
/* PURCHASE LINE CHART END */ 


  /* INCOME EXPENSE PIE CHART VARIABLES START */
  const data3 = {
    labels: ["income", "expense", "balance"],
    datasets: [
      {
        label: "# incomes/expenses",
        data: [pc_incomes, pc_expenses, pc_iebalance],
        backgroundColor: [ "#8c4faa", "#50a2ab", "#5057ab"],
        borderColor: [ "#ffffff", "#ffffff", "#ffffff"],
        borderWidth: 1,
      },
    ],
  };
    /* INCOME EXPENSE PIE CHART VARIABLES END */

    
  /* CASH SUMMARY PIE CHART VARIABLES START */
  const data4 = {
    labels: ["inflow", "outflow", "balance"],
    datasets: [
      {
        label: "# cash summary",
        data: [pc_cash_inflow, pc_cash_outflow, pc_cash_balance],
        backgroundColor: [ "#69d19a", "#de62af", "#de9962"],
        borderColor: [ "#ffffff", "#ffffff", "#ffffff"],
        borderWidth: 1,
      },
    ],
  };
    /* CASH SUMMARY PIE CHART VARIABLES END */

     /* BANK SUMMARY PIE CHART VARIABLES START */
  const data5 = {
    labels: ["inflow", "outflow", "balance"],
    datasets: [
      {
        label: "# bank summary",
        data: [pc_bank_inflow, pc_bank_outflow, pc_bank_balance],
        backgroundColor: [ "#62ddde", "#0b3190", "#d76721"],
        borderColor: [ "#ffffff", "#ffffff", "#ffffff"],
        borderWidth: 1,
      },
    ],
  };
    /* BANK SUMMARY PIE CHART VARIABLES END */

    
   /* LINE CHART START */
   const data6 = {
   labels: lc2_date,
   datasets: [
    {
      label: "Sgst In",
      backgroundColor: ["#4394ce" ],
      borderColor: "#4394ce",
      data: lc2_sgst_in,
    },
    {
      label: "Sgst Out",
      backgroundColor: ["#a8d2f0"],
      borderColor: "#a8d2f0",
      data: lc2_sgst_out,
    },
    {
      label: "Cgst In",
      backgroundColor: ["#522f99" ],
      borderColor: "#522f99",
      data: lc2_cgst_in,
    },
    {
      label: "Cgst Out",
      backgroundColor: ["#9e80db"],
      borderColor: "#9e80db",
      data: lc2_cgst_out,
    },
    {
      label: "Igst In",
      backgroundColor: ["#18a276" ],
      borderColor: "#18a276",
      data: lc2_igst_in,
    },
    {
      label: "Igst Out",
      backgroundColor: ["#6cb9a0"],
      borderColor: "#6cb9a0",
      data: lc2_igst_out,
    },
    {
      label: "Tax In",
      backgroundColor: ["#a34724" ],
      borderColor: "#a34724",
      data: lc2_tax_in,
    },
    {
      label: "Tax Out",
      backgroundColor: ["#c5836a"],
      borderColor: "#c5836a",
      data: lc2_tax_out,
    },
  ],
  };
  /* LINE CHART END */ 

return(
    <>
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Business Tools|BookKeeping Solution</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div class="container-scroller">

    <Sidebar />
    <div class="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div class="main-panel">
          <div class="content-wrapper pb-0">
            <div class="page-header flex-wrap">
            <div class="header-left"></div>
              <div class="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div class="d-flex align-items-center">
                  <a href="#">
                    <p class="m-0 pe-3">Dashboard</p>
                  </a>
                  <a class="ps-3 me-4" href="#">
                   {/*} <p class="m-0">ADE-00234</p> */}
                  </a>
                </div>
              
              </div>
            </div>
            {/*} first row starts here */}
         {/*}   <div class="row">
              <div class="col-xl-9 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-between flex-wrap">
                      <div>
                        <div class="card-title mb-0">Sales Revenue</div>
                        <h3 class="font-weight-bold mb-0">$32,409</h3>
                      </div>
                      <div>
                        <div class="d-flex flex-wrap pt-2 justify-content-between sales-header-right">
                          <div class="d-flex me-5">
                            <button type="button" class="btn btn-social-icon btn-outline-sales">
                              <i class="mdi mdi-inbox-arrow-down"></i>
                            </button>
                            <div class="ps-2">
                              <h4 class="mb-0 font-weight-semibold head-count"> $8,217 </h4>
                              <span class="font-10 font-weight-semibold text-muted">TOTAL SALES</span>
                            </div>
                          </div>
                          <div class="d-flex me-3 mt-2 mt-sm-0">
                            <button type="button" class="btn btn-social-icon btn-outline-sales profit">
                              <i class="mdi mdi-cash text-info"></i>
                            </button>
                            <div class="ps-2">
                              <h4 class="mb-0 font-weight-semibold head-count"> 2,804 </h4>
                              <span class="font-10 font-weight-semibold text-muted">TOTAL PROFIT</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p class="text-muted font-13 mt-2 mt-sm-0"> Your sales monitoring dashboard template. <a class="text-muted font-13" href="#"><u>Learn more</u></a>
                    </p>
                    <div class="flot-chart-wrapper">
                      <div id="flotChart" class="flot-chart">
                        <canvas class="flot-base"></canvas>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 stretch-card grid-margin">
                <div class="card card-img" style={{backgroundImage:"url(https://images.pexels.com/photos/1024248/pexels-photo-1024248.jpeg?auto=compress&cs=tinysrgb&w=600)"}}>
                  <div class="card-body d-flex align-items-center">
                    <div class="text-white"  style={{color:"#07e8d6"}}>
                      <h1 class="font-20 font-weight-semibold mb-0" style={{color:"#0c1403"}}> Get premium </h1>
                      <h1 class="font-20 font-weight-semibold">account!</h1>
                      <p>to optimize your selling prodcut</p>
                      <p class="font-10 font-weight-semibold" > Enjoy the advantage of premium. </p>
                      <button class="btn bg-white font-12">Get Premium</button>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/*} chart row starts here */}
            <div class="row">
              <div class="col-sm-6 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      <div class="card-title"> Sales
                      </div>
                      <div class="d-flex text-muted font-20">
                      <i className="mdi mdi-arrow-down-drop-circle text-primary" onClick={()=>{ document.getElementById("lc-date-search-box").style.display="block"; }}></i>
                      <i className="mdi mdi-close-box text-danger"  onClick={()=>{ document.getElementById("lc-date-search-box").style.display="none"; }}></i>
                      
                      </div>
                    </div>
                       {/* LINE CHART 1 DATE FILTER START */}
                       <div id="lc-date-search-box" style={{display:"none"}}>
                    <hr />
                    <p style={{marginTop:"2rem"}}>Make sure start date and end date are not more than 10 days apart for best results</p>
                    <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label"> Start Date</label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control-sm"  id="lc_start_date" value={lc_start_date} onChange={updatelc_start_date} />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">End Date</label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control-sm" id="lc_end_date" value={lc_end_date} onChange={updatelc_end_date} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <button class="btn btn-primary mb-4" onClick={()=>{ setlc_default(1); getData(); }}>Update</button>
                      <hr/>
                      </div>
                      {/* LINE CHART 1 DATE FILTER END */}

                  
                    {/*<span class="text-muted font-13">Avg customers/Day</span>*/}
                    <div class="line-chart-wrapper">
                     {/*} <canvas id="linechart" height="80"></canvas> */}
                       {/* SALES LINE CHART START */}
                      <Line data={data1} /> 
                  {/* SALES LINE CHART END */}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 stretch-card grid-margin">
              <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      <div class="card-title"> Purchase
                      </div>
                      <div class="d-flex text-muted font-20">
                      <i className="mdi mdi-arrow-down-drop-circle text-primary" onClick={()=>{ document.getElementById("lc1-date-search-box").style.display="block"; }}></i>
                      <i className="mdi mdi-close-box text-danger"  onClick={()=>{ document.getElementById("lc1-date-search-box").style.display="none"; }}></i>
                      
                      </div>
                    </div>
                       {/* LINE CHART 1 DATE FILTER START */}
                       <div id="lc1-date-search-box" style={{display:"none"}}>
                    <hr />
                    <p style={{marginTop:"2rem"}}>Make sure start date and end date are not more than 10 days apart for best results</p>
                    <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label"> Start Date</label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control-sm"  id="lc1_start_date" value={lc1_start_date} onChange={updatelc1_start_date} />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">End Date</label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control-sm" id="lc1_end_date" value={lc1_end_date} onChange={updatelc1_end_date} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <button class="btn btn-primary mb-4" onClick={()=>{ setlc1_default(1); getData(); }}>Update</button>
                      <hr/>
                      </div>
                      {/* LINE CHART 1 DATE FILTER END */}

                     
                    
                    {/*<span class="text-muted font-13">Avg customers/Day</span>*/}
                    <div class="line-chart-wrapper">
                     {/*} <canvas id="linechart" height="80"></canvas> */}
                       {/* PURCHASE LINE CHART START */}
                      <Line data={data2} /> 
                  {/* PURCHASE LINE CHART END */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
                         {/*} table row starts here */}
            <div class="row">
              <div class="col-xl-4 grid-margin">
                <div class="card card-stat stretch-card mb-3">
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      <div class="text-white" style={{textAlign:"left"}}>
                        <h4 class="font-weight-bold mb-0">Hello {logname}</h4>
                        <h6 class="mt-3"><i>Happy Book-Keeping ahead...</i></h6>
                        <a href="/businesstools-feedback"><div class="badge badge-danger">Write us your suggestions</div></a>
                      </div>
                      <div class="flot-bar-wrapper">
                        <div id="column-chart" class="flot-chart"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card stretch-card mb-3">
                  <div class="card-body d-flex flex-wrap justify-content-between">
                    <div>
                      <h4 class="font-weight-semibold mb-1 text-black"> Debtors</h4>
                     {/*} <h6 class="text-muted">Average Weekly Profit</h6> */}
                    </div>
                    <h3 class="text-success font-weight-bold" hidden={debtors_balance<0}>{debtors_balance}</h3>
                     <h3 class="text-danger font-weight-bold" hidden={debtors_balance>=0}>{debtors_balance}</h3>
                  </div>
                </div>
                <div class="card stretch-card mb-3">
                  <div class="card-body d-flex flex-wrap justify-content-between">
                    <div>
                      <h4 class="font-weight-semibold mb-1 text-black"> Creditors </h4>
                     {/*} <h6 class="text-muted">Weekly Customer Orders</h6> */}
                    </div>
                    <h3 class="text-success font-weight-bold" hidden={creditors_balance>=0}>{creditors_balance}</h3>
                     <h3 class="text-danger font-weight-bold" hidden={creditors_balance<0}>{creditors_balance}</h3>
                  </div>
                </div>
                <div class="card mt-3">
                  <div class="card-body d-flex flex-wrap justify-content-between">
                    <div>
                      <h4 class="font-weight-semibold mb-1 text-black"> Inventory </h4>
                    {/*}  <h6 class="text-muted">System bugs and issues</h6> */}
                    </div>
                    <h3 class="text-success font-weight-bold" hidden={inventory_balance<0}>{inventory_balance}</h3>
                    <h3 class="text-danger font-weight-bold" hidden={inventory_balance>=0}>{inventory_balance}</h3>
                  </div>
                </div>
              </div>
              {/* GST AND TAX SUMMARY CHART START */}
              <div class="col-xl-8 stretch-card grid-margin">
              <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      <div class="card-title"> Gst and Tax
                      </div>
                      <div class="d-flex text-muted font-20">
                      <i className="mdi mdi-arrow-down-drop-circle text-primary" onClick={()=>{ document.getElementById("lc2-date-search-box").style.display="block"; }}></i>
                      <i className="mdi mdi-close-box text-danger"  onClick={()=>{ document.getElementById("lc2-date-search-box").style.display="none"; }}></i>
                      
                      </div>
                    </div>
                       {/* LINE CHART 1 DATE FILTER START */}
                       <div id="lc2-date-search-box" style={{display:"none"}}>
                    <hr />
                    <p style={{marginTop:"2rem"}}>Make sure start date and end date are not more than 10 days apart for best results</p>
                    <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label"> Start Date</label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control-sm"  id="lc2_start_date" value={lc2_start_date} onChange={updatelc2_start_date} />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">End Date</label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control-sm" id="lc2_end_date" value={lc2_end_date} onChange={updatelc2_end_date} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <button class="btn btn-primary mb-4" onClick={()=>{ setlc2_default(1); getData(); }}>Update</button>
                      <hr/>
                      </div>
                      {/* LINE CHART 1 DATE FILTER END */}

                     
                    
                    {/*<span class="text-muted font-13">Avg customers/Day</span>*/}
                    <div class="line-chart-wrapper">
                     {/*} <canvas id="linechart" height="80"></canvas> */}
                       {/* GST AND TAX LINE CHART START */}
                      <Line data={data6} /> 
                  {/* GST AND TAX LINE CHART END */}
                    </div>
                  </div>
                </div>
                </div>
                 {/* GST AND TAX SUMMARY CHART END */}
            </div>
                        {/*} doughnut chart row starts */}
                        <div class="row">
                      
              <div class="col-sm-12 stretch-card grid-margin">

                <div class="card">
                  <div class="row">


                  {/* INCOMES AND EXPENSES PIE CHART START */}
                    <div class="col-md-4">
                      <div class="card border-0">
                        <div class="card-body">
                          
                          <div class="d-flex justify-content-between">
                          <div class="card-title">Incomes and Expenses</div>
                        <div class="d-flex text-muted font-20">
                        <i className="mdi mdi-arrow-down-drop-circle text-primary" onClick={()=>{ document.getElementById("pc-date-search-box").style.display="block"; }}></i>
                        <i className="mdi mdi-close-box text-danger"  onClick={()=>{ document.getElementById("pc-date-search-box").style.display="none"; }}></i>
                     
                      </div>
                      </div>
                    
                        {/*BAR CHART DATE FILTER START */}
                        <div id="pc-date-search-box" style={{display:"none"}}>
                    <hr />
                    <div className="row">
                        <div className="col-md-12">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label"> Start Date</label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control-sm"  id="pc_start_date" value={pc_start_date} onChange={updatepc_start_date} />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">End Date</label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control-sm" id="pc_end_date" value={pc_end_date} onChange={updatepc_end_date} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <button class="btn btn-primary mb-4" onClick={()=>{ setpc_default(1); getData(); }}>Update</button>
                      <hr/>
                      </div>
                      {/* BAR CHART DATE FILTER END */}
                       

                          {/*}  <div class="doughnut-wrapper w-50"> */}
                            <Pie data={data3} style={{width:"600px",height:"400px"}} maintainAspectRatio={false} />
                           {/*} </div> */}
                            <div id="doughnut-chart-legend" class="pl-lg-3 rounded-legend align-self-center flex-grow legend-vertical legend-bottom-left"></div>
                          </div>
                        </div>
                    
                    </div>
                    {/* INCOMES AND EXPENSES PIE CHART END */}


                     {/* CASH SUMMARY START */}
                     <div class="col-md-4">
                      <div class="card border-0">
                        <div class="card-body">
                          
                          <div class="d-flex justify-content-between">
                          <div class="card-title">Cash Summary</div>
                        <div class="d-flex text-muted font-20">
                        <i className="mdi mdi-arrow-down-drop-circle text-primary" onClick={()=>{ document.getElementById("pc1-date-search-box").style.display="block"; }}></i>
                        <i className="mdi mdi-close-box text-danger"  onClick={()=>{ document.getElementById("pc1-date-search-box").style.display="none"; }}></i>
                     
                      </div>
                      </div>
                    {/*BAR CHART DATE FILTER START */}
                        <div id="pc1-date-search-box" style={{display:"none"}}>
                    <hr />
                    <div className="row">
                        <div className="col-md-12">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label"> Start Date</label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control-sm"  id="pc1_start_date" value={pc1_start_date} onChange={updatepc1_start_date} />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">End Date</label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control-sm" id="pc1_end_date" value={pc1_end_date} onChange={updatepc1_end_date} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <button class="btn btn-primary mb-4" onClick={()=>{ setpc1_default(1); getData(); }}>Update</button>
                      <hr/>
                      </div>
                      {/* BAR CHART DATE FILTER END */}
                       

                          {/*}  <div class="doughnut-wrapper w-50"> */}
                            <Pie data={data4} style={{width:"600px",height:"400px"}} maintainAspectRatio={false} />
                           {/*} </div> */}
                            <div id="doughnut-chart-legend" class="pl-lg-3 rounded-legend align-self-center flex-grow legend-vertical legend-bottom-left"></div>
                          </div>
                        </div>
                    
                    </div>
                    {/* CASH SUMMARY END */}


                    {/* BANK SUMMARY START */}
                      <div class="col-md-4">
                      <div class="card border-0">
                        <div class="card-body">
                          
                          <div class="d-flex justify-content-between">
                          <div class="card-title">Bank Summary</div>
                        <div class="d-flex text-muted font-20">
                        <i className="mdi mdi-arrow-down-drop-circle text-primary" onClick={()=>{ document.getElementById("pc2-date-search-box").style.display="block"; }}></i>
                        <i className="mdi mdi-close-box text-danger"  onClick={()=>{ document.getElementById("pc2-date-search-box").style.display="none"; }}></i>
                     
                      </div>
                      </div>
                    {/*BAR CHART DATE FILTER START */}
                        <div id="pc2-date-search-box" style={{display:"none"}}>
                    <hr />
                    <div className="row">
                        <div className="col-md-12">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label"> Start Date</label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control-sm"  id="pc2_start_date" value={pc2_start_date} onChange={updatepc2_start_date} />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">End Date</label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control-sm" id="pc2_end_date" value={pc2_end_date} onChange={updatepc2_end_date} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <button class="btn btn-primary mb-4" onClick={()=>{ setpc2_default(1); getData(); }}>Update</button>
                      <hr/>
                      </div>
                      {/* BAR CHART DATE FILTER END */}
                       

                          {/*}  <div class="doughnut-wrapper w-50"> */}
                            <Pie data={data5} style={{width:"600px",height:"400px"}} maintainAspectRatio={false} />
                           {/*} </div> */}
                            <div id="doughnut-chart-legend" class="pl-lg-3 rounded-legend align-self-center flex-grow legend-vertical legend-bottom-left"></div>
                          </div>
                        </div>
                    
                    </div>
                    {/* BANK SUMMARY END */}
                  </div>
                </div>
              </div>
            </div>

        
          </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
        {/*}  <footer class="footer">
            <div class="d-sm-flex justify-content-center justify-content-sm-between">
              <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
</>
);

}
export default Startworking;