import React from 'react';
import {useState,useEffect,Component} from 'react';
//import axios from './axios';


function Footer3(){

return(
<>

{/* FOOTER SECTION START */}
<div style={{borderTop:"0.2rem solid #01415a"}}> 
<div class="bth-footer" style={{backgroundColor:"#002e33",padding:"2rem"}}>




<div class="row d-flex justify-content-between" style={{textAlign:"left"}}>
<div class="col-sm-3 stretch-card grid-margin">
<div>
<h4 style={{color:"#ffffff"}}>Contact Us</h4>
<br />
<p style={{color:"#ffffff"}}>mail us at : help.startupconnect@gmail.com</p>
<p style={{color:"#ffffff"}}>WhatsApp : +91 93993 95256</p>
{/*<a href="" style={{color:"#ffffff",textDecoration:"none"}}><p >Contact Us</p></a>
*/}
<a className="navbar-brand" href="#"><img style={{width:"70px",height:"70px"}} src="./images/logo-img.png" /><span style={{color:"#ffffff",fontWeight:"bold"}}>Startup Connect</span></a>

</div>
</div>


<div class="col-sm-3 stretch-card grid-margin">
<div>
<h4 style={{color:"#ffffff"}}>Website</h4>
<br/>
<a href="/home" style={{color:"#ffffff",textDecoration:"none"}}><p >Home</p></a>
<a href="/services-home" style={{color:"#ffffff",textDecoration:"none"}}><p >Services</p></a>
<a href="/book-keeping" style={{color:"#ffffff",textDecoration:"none"}}><p >Book-Keeping</p></a>
<a href="/startups-network" style={{color:"#ffffff",textDecoration:"none"}}><p >Startups</p></a>
<a href="https://www.startuphire.in" style={{color:"#ffffff",textDecoration:"none"}}><p >Hiring</p></a>
<a href="/contest-home" style={{color:"#ffffff",textDecoration:"none"}}><p >Contests</p></a>
{/* <a href="" style={{color:"#ffffff",textDecoration:"none"}}><p >About Us</p></a>
<a href="" style={{color:"#ffffff",textDecoration:"none"}}><p >Trainings</p></a> */}
</div>
</div>


<div class="col-sm-3 stretch-card grid-margin">
<div>
<h4 style={{color:"#ffffff"}}>Other Links</h4>
<br/>
<a href="" style={{color:"#ffffff",textDecoration:"none"}}><p >About Us</p></a>
<a href="" style={{color:"#ffffff",textDecoration:"none"}}><p >Trainings</p></a> 
<a href="" style={{color:"#ffffff",textDecoration:"none"}}><p >Careers</p></a> 
</div>
</div>

<div class="col-sm-3 stretch-card grid-margin">
<div>
<h4 style={{color:"#ffffff"}}>Policies and Terms</h4>
<br/>
<a href="/termsandconditions" style={{color:"#ffffff",textDecoration:"none"}}><p >Terms and Conditions</p></a>
<a href="/privacy-policy" style={{color:"#ffffff",textDecoration:"none"}}><p >Privacy Policy</p></a>
<a href="/refundandcancellation" style={{color:"#ffffff",textDecoration:"none"}}><p >Refund and Cancellation</p></a>
<a href="/disclaimer" style={{color:"#ffffff",textDecoration:"none"}}><p >Disclaimer</p></a>
</div>
</div>

</div>


<div style={{borderTop:"0.1rem solid #ffffff", padding:"1rem"}}>
  <p style={{color:"#ffffff",textAlign:"center"}}>Copyright © 2024 | All Rights Reserved by Technomate Startup Services Private Limited.</p>
</div>

<div class="row d-flex justify-content-center">
  <div class="col-sm-3 stretch-card grid-margin">

  <a href="https://www.facebook.com/profile.php?id=100089210907604"><button type="button" class="btn btn-social-icon btn-linkedin btn-rounded" style={{marginLeft:".5rem", backgroundColor:"#131dd8",border:"0.15rem solid #ffffff",color:"#ffffff"}}>
  <i class="mdi mdi-facebook"></i>
  </button></a>
    
  <a href=""><button type="button" class="btn btn-social-icon btn-youtube btn-rounded" style={{marginLeft:".5rem", border:"0.15rem solid #ffffff",color:"#ffffff", backgroundColor:"#c72525"}}>
  <i class="mdi mdi-youtube"></i>
  </button></a>

  <a href="https://www.linkedin.com/company/startupconnect1/"><button type="button" class="btn btn-social-icon btn-linkedin btn-rounded" style={{marginLeft:".5rem", border:"0.15rem solid #ffffff",color:"#ffffff",backgroundColor:"#2468b5"}}>
  <i class="mdi mdi-linkedin"></i>
  </button></a>
  
  <a href="https://www.instagram.com/thestartupconnect/"><button type="button" class="btn btn-social-icon btn-linkedin btn-rounded" style={{marginLeft:".5rem", backgroundColor:"#a43656", border:"0.15rem solid #ffffff",color:"#ffffff"}}>
  <i class="mdi mdi-instagram"></i>
  </button></a>

  <a href=""><button type="button" class="btn btn-social-icon btn-linkedin btn-rounded" style={{marginLeft:".5rem", backgroundColor:"#0e9bb6", border:"0.15rem solid #ffffff",color:"#ffffff"}}>
  <i class="mdi mdi-twitter"></i>
  </button></a>

  <a href=""><button type="button" class="btn btn-social-icon btn-linkedin btn-rounded" style={{marginLeft:".5rem", backgroundColor:"#770969", border:"0.15rem solid #ffffff",color:"#ffffff"}}>
  <i class="mdi mdi-gmail"></i>
  </button></a>

  </div>
</div>

</div>
</div>
{/* FOOTER SECTION END */}
</>
);


}


export default Footer3;