import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import Navbar from './navbar';



//import '../hireportalcss.css';
//import '../Hireportalcss/hireportalcss.css';

//Importing Page css with screen sizes
import '../Hireportalcss/sdefault.css';
import '../Hireportalcss/s1200.css';
import '../Hireportalcss/s920.css';
import '../Hireportalcss/s768.css';
import '../Hireportalcss/s600.css';
import '../Hireportalcss/sanimation.css';



function Candidateapply(){

    return(

    <>
        <div class="default-view">
        <Navbar/>
        {/* MAIN SECTION START */}
        <div class="hire-main-section">

        {/* FORM CONTAINER START */}
        <div class="hire-form-container">
        
        {/* RESUME CARD START */}
            <div class="hire-form-panel" style={{backgroundColor:"#ffffff"}}>

            <h2>Apply for <span style={{color:"#154f9d"}}>Full Stack Developer Role</span> at Senior Gent Companies, AGV Robert</h2>
        
            <div id="line-1"></div>
            {/* APPLICATION SECTION START */}
            <div class="hire-resume-section">

    
           {/* ASSIGNMENT SECTION START */}
            <h3 style={{textAlign:"left"}}>ASSIGNMENT</h3>

            <p>This job profile contains a assignment. You have to complete the following assignment as well to complete your application.</p>

            <div class="hire-resume-card" style={{marginTop:"0.2rem"}}>
            <h3>The Basics of Full Stack Development Role</h3>
            <p><b>Time : </b> 60 minutes <b>Maximum Marks : </b> 100</p>
            <div style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
            <div>
            <button class="hire-btn-default" style={{backgroundColor:"#12d571",marginRight:"2rem"}}
            onClick={()=>{ window.location.replace("/employer-view-candidate-assignment"); }}>View Assignment</button>
            <p><span style={{color:"#de1e5c"}}>Assignment Due</span></p>
            </div>
           
            </div>
            </div>
            {/* ASSIGNMENT SECTION END */}

            {/* APPLICATION QUESTIONS SECTION START */}

            <h3 style={{textAlign:"left"}}>ANSWER APPLICATION QUESTIONS</h3>

            {/* Question 1 Start */}
            <div class="hire-resume-card" style={{marginTop:"0.2rem"}}>
           <h3>Q.1 Why are you the right fit for this role?</h3>
           
            <div class="hire-form-list">
            <div class="hire-form-group-large">
            <textarea size="5" class="hire-form-group-input" placeholder="Type your answer here. "></textarea>
            </div>
            </div>
            
            {/* Question 1 End */}
            {/* Question 2 Start */}
            <div  style={{marginTop:"0.2rem"}}>
           <h3>Q.2 Why are you the right fit for this role?</h3>
           
            <div class="hire-form-list">
            <div class="hire-form-group-large">
            <textarea size="5" class="hire-form-group-input" placeholder="Type your answer here. "></textarea>
            </div>
            </div>
            </div>
            {/* Question 2 End */}
            {/* Question 3 Start */}
            <div  style={{marginTop:"0.2rem"}}>
            <h3>Q.3 Why are you the right fit for this role?</h3>
            <div class="hire-form-list">
            <div class="hire-form-group-large">
            <textarea size="5" class="hire-form-group-input" placeholder="Type your answer here. "></textarea>
            </div>
            </div>
            </div>
            {/* Question 3 End */}
            </div>

              




            </div>
            {/* APPLICATION QUESTIONS SECTION END */}

            <div class="hire-form-btn">
            <button class="hire-btn-default" style={{backgroundColor:"#12d571",marginRight:"2rem",marginTop:"2rem",marginBottom:"2rem"}}
            >Submit Application</button>

            </div>
    
    <p>Your complete profile will also be shared with the job poster.</p>
        </div>
        {/* RESUME CARD END */}


        </div>
        {/* FORM CONTAINER END */}

        </div>
        {/* MAIN SECTION END */}

        </div>

        </>
    );




}


export default Candidateapply;