import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
//import axios from '../../axios.js';
import moment from 'moment/moment';

//import './Resources/homebtcss.css';
//import './Resources/home2css.css';
//import './Resources/serviceshomecss.css';


function New_termsandconditions(){

    return(
     <>
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Startup Connect | Terms and Conditions</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>

   <div class="container" style={{textAlign:"left"}}>

   <p style={{marginTop:"3rem"}}>Last Updated : 2024-01-24</p>
   <h1>Terms and Conditions</h1>
  
		<p>
			<br/>
			<br/>
The Website Owner, including subsidiaries and affiliates (“Website” or “Website Owner” or “we” or “us” or “our”) provides the information contained on the website or any of the pages comprising the website (“website”) to visitors (“visitors”) (cumulatively referred to as “you” or “your” hereinafter) subject to the terms and conditions set out in these website terms and conditions, the privacy policy and any other relevant terms and conditions, policies and notices which may be applicable to a specific section or module of the website.
			
                </p>
            <br/>
			<br/>
<h3>General Information</h3>
			<br/>
			<br/>
            <p>
Welcome to <b>thestartupconnect.com</b>. This website is owned and operated by and it is the Property of  <b>Technomate Startup Services Private Limited (hereafter referred to as ‘Company’)</b> whose registered office is LIG C-5, 90 Quarter, Sanjeevani Nagar, Jabalpur (M.P) - 482001, India. By visiting our website and accessing the information, resources, services, products, and tools we provide, you understand and agree to accept and adhere to the following terms and conditions as stated in this policy (hereafter referred to as ‘User Agreement’), along with the terms and conditions as stated in our <a href="/privacy-policy">Privacy Policy</a> (please refer to the Privacy Policy section below for more information).
			<br/>
We reserve the right to change this User Agreement from time to time without notice. You acknowledge and agree that it is your responsibility to review this User Agreement periodically to familiarize yourself with any modifications. Your continued use of this site after such modifications will constitute acknowledgment and agreement of the modified terms and conditions.
			
                </p>
            <br/>
			<br/>
<h3>Fees and Payment</h3>
			<br/>
            <p>
Some parts of Service are billed on a subscription basis ("Subscription(s)"). You will be billed in advance on a recurring and periodic basis ("Billing Cycle"). Billing cycles will be set depending on the type of subscription plan you select when purchasing a Subscription.
			<br/><br/>
At the end of each Billing Cycle, your Subscription may automatically renew under the exact same conditions unless you cancel it or our company cancels it. You may cancel your Subscription renewal either through your online account management page or by contacting company customer support team.
			<br/>
A valid payment method is required to process the payment for your subscription and for the automatic renewal of the subscription plan. You shall provide company with accurate and complete billing information that may include but not limited to full name, address, state, postal or zip code, telephone number, and a valid payment method information. By submitting such payment information, you automatically authorize company to charge all Subscription fees incurred through your account to any such payment instruments.
			<br/>
Should automatic billing fail to occur for any reason, company reserves the right to terminate your access to the Service with immediate effect.
			<br/>
			<br/>
In the event any tax such as GST, VAT, sales tax or the like is chargeable by company in accordance with any local, state, provincial or foreign laws with respect to your subscription to our Services ("Taxes"), company will invoice you for such Taxes. You agree to pay company such Taxes in addition to the subscription fees. company shall provide you with an invoice in the format prescribed by the applicable local, state, provincial or foreign laws to help you avail the applicable input tax credit for the Taxes so paid.
			</p>
            <br/>
			<br/>
<h3>Fees changes </h3>
			<br/>
            <p>
Company, in its sole discretion and at any time, may modify Subscription fees for the Subscriptions. Any Subscription fee change will become effective at the end of the then-current Billing Cycle.
			<br/>
company will provide you with a reasonable prior notice of any change in Subscription fees to give you an opportunity to terminate your Subscription before such change becomes effective.
			<br/>
Your continued use of Service after Subscription fee change comes into effect constitutes your agreement to pay the modified Subscription fee amount.
			</p>
            <br/>
			<br/>
<h3>Free Trial</h3>
			<br/>
            <p>
Company may, at its sole discretion, offer a Subscription with a free trial for a limited period of time ("Free Trial").
			<br/>
You may be required to enter your billing information in order to sign up for Free Trial.
			<br/>
If you do enter your billing information when signing up for Free Trial, you will not be charged by company until Free Trial has expired. On the last day of Free Trial period, unless you cancelled your Subscription, you will be automatically charged the applicable Subscription fees for the type of Subscription you have selected.
			<br/>
At any time and without notice, company reserves the right to (i) modify Terms of Service of Free Trial offer, or (ii) cancel such Free Trial offer
			<br/>
			<br/>
            <br/>
<h3>Analytics</h3>
			<br/>
We may use third-party Service Providers to monitor and analyze the use of our Service.
			<br/>
            <br/>
            <br/>
<h3>Communications from company</h3>
			<br/>
The Service may include certain communications from company, such as service announcements, administrative messages and newsletters. You understand that these communications shall be considered part of using the Services. As part of our policy to provide you total privacy, we also provide you the option of opting out from receiving newsletters from us. However, you will not be able to opt-out from receiving service announcements and administrative messages.
			<br/>
			<br/>
            <br/>
<h3>Error Reporting and Feedback</h3>
			<br/>
You may provide us either directly at <b>help.startupconnect@gmail.com</b> or via third party sites and tools with information and feedback concerning errors, suggestions for improvements, ideas, problems, complaints, and other matters related to our Service (“Feedback”). You acknowledge and agree that: (i) you shall not retain, acquire or assert any intellectual property right or other right, title or interest in or to the Feedback; (ii) Company may have development ideas similar to the Feedback; (iii) Feedback does not contain confidential information or proprietary information from you or any third party; and (iv) Company is not under any obligation of confidentiality with respect to the Feedback. In the event the transfer of the ownership to the Feedback is not possible due to applicable mandatory laws, you grant Company and its affiliates an exclusive, transferable, irrevocable, free-of-charge, sub-licensable, unlimited and perpetual right to use (including copy, modify, create derivative works, publish, distribute and commercialize) Feedback in any manner and for any purpose.
			<br/>
			<br/>
            <br/>
<h3>Complaint </h3>
			<br/>
If we receive a complaint from any person with respect to your activities as part of use of the Services, we will forward the complaint to the primary email address of your user account. You must respond to the complainant directly within 10 days of receiving the complaint forwarded by us and copy company in the communication. If you do not respond to the complainant within 10 days from the date of our email to you, we may disclose your name and contact information to the complainant for enabling the complainant to take legal action against you. You understand that your failure to respond to the forwarded complaint within the 10 days’ time limit will be construed as your consent to disclosure of your name and contact information by company to the complainant.
			<br/>
            <br/>
            <br/>
<h3>Inactive user account policy</h3>
			<br/>
We reserve the right to terminate unpaid user accounts that are inactive for a continuous period of 120 days. In the event of such termination, all data associated with such user account can be deleted. We will provide you prior notice of such termination and option to back-up your data. The data deletion policy may be implemented with respect to any or all of the Services. Each Service will be considered an independent and separate service for the purpose of calculating the period of inactivity. In other words, activity in one of the Services is not sufficient to keep your user account in another Service active. In case of accounts with more than one user, if at least one of the users is active, the account will not be considered inactive.
			<br/>
			<br/>
            <br/>
<h3>Sample files and Applications</h3>
			<br/>
company may provide sample files and applications for the purpose of demonstrating the possibility of using the Services effectively for specific purposes. The information contained in any such sample files and applications consists of random data. company makes no warranty, either express or implied, as to the accuracy, usefulness, completeness or reliability of the information or the sample files and applications.
			<br/>
			<br/>
            <br/>
<h3>Hosting Location</h3>
			<br/>
The location of the cloud facility from which you are served depends on the mapping of your region/country to the available cloud facilities at the time of your sign-up. We may migrate your account or require you to migrate your account to a different cloud facility in the event of any updates to the region/country to cloud facility mapping at any point of time. You must not mask your internet protocol (IP) address at the time of sign-up since your region/country is determined base on your IP address. If, at any time, your actual region/country is found to be different from the region/country in our records, company may take appropriate action such as migrate your account or require you to migrate your account to the cloud facility corresponding to your region/country, or close your account and deny the Service to you. If you are served from a cloud facility outside your region/country and a company group entity has an office in your region/country, apart from storing the data in the cloud facility assigned to you, we may store a local copy of the data in your region/country.
			<br/>
			<br/>
            <br/>
<h3>Data Ownership</h3>
			<br/>
We respect your right to ownership of content created or stored by you. You own the content created or stored by you. Unless specifically permitted by you, your use of the Services does not grant company the license to use, reproduce, adapt, modify, publish or distribute the content created by you or stored in your user account for company’s commercial, marketing or any similar purpose. But you grant company permission to access, copy, distribute, store, transmit, reformat, publicly display and publicly perform the content of your user account solely as required for the purpose of providing the Services to you.
			<br/>
			<br/>
            <br/>
<h3>Responsible use and conduct</h3>
			<br/>
<li>In order to access our Resources, you may be required to provide certain information about yourself (such as identification, email, phone number, contact details, etc.) as part of the registration process, or as part of your ability to use the Resources. You agree that any information you provide will always be accurate, correct, and up to date.
</li><br/>
<li>You are responsible for maintaining the confidentiality of any login information associated with any account you use to access our Resources. Accordingly, you are responsible for all activities that occur under your account.
</li><br/>
<li>Accessing (or attempting to access) any of our Resources by any means other than through the means we provide, is strictly prohibited. You specifically agree not to access (or attempt to access) any of our Resources through any automated, unethical or unconventional means.
</li><br/>
<li>Engaging in any activity that disrupts or interferes with our Resources, including the servers and/or networks to which our Resources are located or connected, is strictly prohibited.
</li>	<br/>
<li>Attempting to copy, duplicate, reproduce, sell, trade, or resell our resources is strictly prohibited.
</li>		<br/>
<li>Depending on the service offering chosen by any person or entity while checking out on various service pages on the website, thestartupconnect.com may require a specimen signature as a part of the documentation either for fulfilment of the order or not and this may be used for self attestation of documents required to be done so, which may be for fulfilment of the chosen order. thestartupconnect.com holds the right to do so on behalf of its clients and shall not be held guilty of misconduct or forgery in such cases under any circumstances.
</li><br/>
<li>You are solely responsible for any consequences, losses, or damages that we may directly or indirectly incur or suffer due to any unauthorized activities conducted by you, as explained above, and may incur criminal or civil liability.
</li>		<br/>
<li>We may provide various open communication tools on our website, such as blog comments, blog posts, public chat, forums, message boards, newsgroups, product ratings and reviews, various social media services, etc. You understand that generally we do not pre-screen or monitor the content posted by users of these various communication tools, which means that if you choose to use these tools to submit any type of content to our website, then it is your personal responsibility to use these tools in a responsible and ethical manner. By posting information or otherwise using any open communication tools as mentioned, you agree that you will not upload, post, share, or otherwise distribute any content that:
</li>		<br/>
- Is illegal, threatening, defamatory, abusive, harassing, degrading, intimidating, fraudulent, deceptive, invasive, racist, or contains any type of suggestive, inappropriate, or explicit language;
<br/>
- Infringes on any trademark, patent, trade secret, copyright, or other proprietary right of any party.
<br/>
- Contains any type of unauthorized or unsolicited advertising;
<br/>
- Impersonates any person or entity, including any Company employees or representatives.
 <br/>
 <br/>
We have the right at our sole discretion to remove any content that, we feel in our judgment does not comply with this User Agreement, along with any content that we feel is otherwise offensive, harmful, objectionable, inaccurate, or violates any 3rd party copyrights or trademarks. We are not responsible for any delay or failure in removing such content. If you post content that we choose to remove, you hereby consent to such removal, and consent to waive any claim against us.
<br/>
<br/>
We do not assume any liability for any content posted by you or any other 3rd party users of our website. However, any content posted by you using any open communication tools on our website, provided that it doesn’t violate or infringe on any 3rd party copyrights or trademarks, becomes the property of the “company”, and as such, gives us a perpetual, irrevocable, worldwide, royalty-free, exclusive license to reproduce, modify, adapt, translate, publish, publicly display and/or distribute as we see fit. This only refers and applies to content posted via open communication tools as described, and does not refer to information that is provided as part of the registration process, necessary in order to use our Resources. All information provided as part of our registration process is covered by our <a href="/privacy-policy">Privacy Policy</a>.
<br/>
<br/>
You agree to indemnify and hold harmless in a digital property of Technomate Startup Services Private Limited and its parent company and affiliates, and their directors, officers, managers, employees, donors, agents, and licensors, from and against all losses, expenses, damages and costs, including reasonable attorneys’ fees, resulting from any violation of this User Agreement or the failure to fulfill any obligations relating to your account incurred by you or any other person using your account. We reserve the right to take over the exclusive defense of any claim for which we are entitled to indemnification under this User Agreement. In such event, you shall provide us with such cooperation as is reasonably requested by us.
	<br/>
			<br/>
            <br/>
<h3>Privacy</h3>
			<br/>
Your privacy is very important to us, which is why we’ve created a separate <a href="/privacy-policy">Privacy Policy</a> in order to explain in detail how we collect, manage, process, secure, and store your private information. Our Privacy Policy is included under the scope of this User Agreement. To read our Privacy Policy in its entirety, <a href="/privacy-policy">click here</a>.
			<br/>
			<br/>
            <br/>
<h3>Limitation of Warranties</h3>
			<br/>
<b>By using our website, you understand and agree that all Resources we provide are “as is” and “as available”. This means that we do not represent or warrant to you that:
</b>		<br/><br/>
<li>the use of our Resources will meet your needs or requirements.
</li>	<br/>
<li>the use of our Resources will be uninterrupted, timely, secure or free from errors.
</li>		<br/>
<li>the information obtained by using our Resources will be accurate or reliable, and
</li>		<br/>
<li>any defects in the operation or functionality of any Resources we provide will be repaired or corrected.
</li>		<br/>
<b>Furthermore, you understand and agree that:</b>
<br/>
			<br/>
<li>any content downloaded or otherwise obtained through the use of our Resources is done at your own discretion and risk, and that you are solely responsible for any damage to your computer or other devices for any loss of data that may result from the download of such content.
</li>		<br/>
<li>no information or advice, whether expressed, implied, oral or written, obtained by you from <b>https://www.thestartupconnect.com</b> or through any Resources we provide shall create any warranty, guarantee, or conditions of any kind, except for those expressly outlined in this User Agreement.
</li>		<br/>
<br/>
<h3>Limitation of Liability</h3>
			<br/>
			<br/>
	In conjunction with the Limitation of Warranties as explained above, you expressly understand and agree that any claim against us shall be limited to the amount you paid, if any, for use of products and/or services. <b>www.thestartupconnect.com</b> will not be liable for any direct, indirect, incidental, consequential or exemplary loss or damages which may be incurred by you as a result of using our Resources, or as a result of any changes, data loss or corruption, cancellation, loss of access, or downtime to the full extent that applicable limitation of liability laws apply.
			<br/>
            <br/>
            <br/>
<h3>Copyrights / Trademarks</h3>
			<br/>
All content and materials available on <b>www.thestartupconnect.com</b>, including but not limited to text, graphics, website name, code, images and logos are the intellectual property of Technomate Startup Services Private Limited, and are protected by applicable copyright and trademark law. Any inappropriate use, including but not limited to the reproduction, distribution, display or transmission of any content on this site is strictly prohibited, unless specifically authorized by <b>Technomate Startup Services Private Limited</b>.
			<br/>
            <br/>
            <br/>
<h3>Termination of Use</h3>
			<br/>
You agree that we may, at our sole discretion, suspend or terminate your access to all or part of our website and Resources with or without notice and for any reason, including, without limitation, breach of this User Agreement. Any suspected illegal, fraudulent or abusive activity may be grounds for terminating your relationship and may be referred to appropriate law enforcement authorities. Upon suspension or termination, your right to use the Resources we provide will immediately cease, and we reserve the right to remove or delete any information that you may have on file with us, including any account or login information.
			<br/>
            <br/>
            <br/>
<h3>Governing Law</h3>
			<br/>
This website is controlled by <b>Technomate Startup Services Private Limited</b> from our offices located in the state of<b> Madhya Pradesh, India</b>. It can be accessed by most countries around the world. As each country has laws that may differ from those of <b>Madhya Pradesh, India</b> by accessing our website, you agree that the statutes and laws of <b>Madhya Pradesh, India</b> without regard to the conflict of laws and the United Nations Convention on the International Sales of Goods, will apply to all matters relating to the use of this website and the purchase of any products or services through this site.
			<br/>
			<br/>
Furthermore, any action to enforce this User Agreement shall be brought within local jurisdiction of courts located in <b>Jabalpur, Madhya Pradesh, India</b>. You hereby agree to personal jurisdiction by such courts, and waive any jurisdictional, venue, or inconvenient forum objections to such courts.
			<br/>
            <br/>
            <br/>
<h3>Cancellation and Refund</h3>
			<br/>
Cancellation of order is possible even after the payment has been made however, only the balance amount which is unutilised towards processing the order can be refunded.Some order executions are done stepwise and have a committed fee payment for each of the step to the government, vendors or channel partners and hence such portions cannot be refunded. However, in case of non-performance of service by <b>Technomate Startup Services Private Limited</b> you are entitled to refund of the entire professional fee element as per the <a href="refundandcancellation">Refund Policy</a> listed on the website.
			<br/>
            <br/>
For more information you can check out our <a href="/refundandcancellation">refund policy</a>.
			<br/>
            <br/>
            <br/>
<h3>Guarantee</h3>
			<br/>
UNLESS OTHERWISE EXPRESSED, www.thestartupconnect.com EXPRESSLY DISCLAIMS ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
		<br/>
<br/>
<br/>
<h3>Contact Information</h3>
			<br/>
If you have any questions or comments about our Terms of Service as outlined above, you can contact us at:
<br></br>
			<br/>
<b>Technomate Startup Services Private Limited
			<br/>
LIG C-5, 90 Quarter, Sanjeevani Nagar, Jabalpur (M.P) - 482001
			<br/>
Contact : +91-8480002413
			<br/>
Email : help.startupconnect@gmail.com
</b>
			<br/>
			<br/>
			<br/>
			<br/>
			<br/>
			<br/>
			<br/>
			<br/>
		</p>

   </div>

  
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>

</>
);

}

export default New_termsandconditions;