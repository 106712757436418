import React from 'react';
import {useState,useEffect,Component} from 'react';
//import axios from './axios';
import '../homeh.css';

function Navbar(){

return(
<>
   
      {/* NAVBAR START */}


<nav className="navbar navbar-expand-lg">
  <div className="container-fluid navbar-custom">
    <a className="navbar-brand" href="#"><img style={{width:"70px",height:"70px"}} src="./images/logo-img.png" /><span style={{color:"#14C4ED",fontWeight: "bold"}}>the</span><span style={{color:"#7ED957",fontWeight:"bold"}}>Startup</span><span style={{color:"#FFBD1F",fontWeight:"bold"}}>Connect</span></a>
       <button className="navbar-toggler homestyle-nav" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        

      <span className="">
      <button class="float-end" /* onClick={()=>{ if(document.getElementById("ext-nav-content").style.display=="none"){document.getElementById("ext-nav-content").style.display="block"; }else{ document.getElementById("ext-nav-content").style.display="none"; } }}*/ style={{backgroundColor:"transparent",border:"none"}}><img src="https://cdn-icons-png.flaticon.com/128/9679/9679146.png" style={{width:"3rem",height:"3rem"}} id="nav-icon"></img></button>


      </span>
    </button>
  
    <div className="collapse navbar-collapse" id="navbarNavDropdown">
      <ul className="navbar-nav ms-auto">
        <li className="nav-item">
          <a className="nav-link active col-w" style={{color:"#073258"}} aria-current="page" href="#">Home</a>
        </li>
        <li className="nav-item">
          <a className="nav-link col-w" style={{color:"#073258"}} href="#">About</a>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" style={{color:"#073258"}} id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false" >
            Services
          </a>
        {/*  <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <li><a className="dropdown-item" href="/website-development">Website Development</a></li>
            <li><a className="dropdown-item" href="/taxandlegal">Business Registration and Tax Compliances</a></li>
            <li><a className="dropdown-item" href="/digitalmarketing">Digital Marketing</a></li>
            <li><a className="dropdown-item" href="/hiring">Team Building Hiring</a></li>
            <li><a className="dropdown-item" href="/softwaresolutions">Product Development for Software Solutions</a></li>
            <li><a className="dropdown-item" href="/consultancy">Consultancy</a></li>
          </ul> */}
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/hiring-login" style={{color:"#073258"}}>Hiring</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#" style={{color:"#073258"}}>Startups</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#" style={{color:"#073258"}}>Competitions</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#" style={{color:"#073258"}}>Events</a>
        </li>
        <li>
          <button className="btn btn-outline-success me-2 get-started-top" type="button" onClick={()=>{window.location.replace("login");}}>Get Started</button>  
        </li>
      </ul>
    
    </div>
  </div>











</nav>

{/*  COLLAPSIBLE NAVBAR FOR SMALL SIZES  */}

{/*<nav id="ext-navbar" className="container-fluid navbar-custom" style={{position:"fixed",marginTop:"0",zIndex:"2"}}>
<div id="ext-nav-content" style={{display:"none"}}>
<div>
      <ul className="navbar-nav ms-auto">
        <li className="nav-item">
          <a className="nav-link active col-w" style={{color:"#073258"}} aria-current="page" href="#">Home</a>
        </li>
        <li className="nav-item">
          <a className="nav-link col-w" style={{color:"#073258"}} href="#">About</a>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" style={{color:"#073258"}} id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Services
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <li><a className="dropdown-item" href="/website-development">Website Development</a></li>
            <li><a className="dropdown-item" href="/taxandlegal">Business Registration and Tax Compliances</a></li>
            <li><a className="dropdown-item" href="/digitalmarketing">Digital Marketing</a></li>
            <li><a className="dropdown-item" href="/hiring">Team Building Hiring</a></li>
            <li><a className="dropdown-item" href="/softwaresolutions">Product Development for Software Solutions</a></li>
            <li><a className="dropdown-item" href="/consultancy">Consultancy</a></li>
          </ul>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#" style={{color:"#073258"}}>Hiring</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#" style={{color:"#073258"}}>Startups</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#" style={{color:"#073258"}}>Competitions</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#" style={{color:"#073258"}}>Events</a>
        </li>
        <li>
          <button className="btn btn-outline-success me-2 get-started-top" type="button" onClick={()=>{window.location.replace("login");}}>Get Started</button>  
        </li>
      </ul>
    
    </div>
</div>
</nav>*/}

{/* NAVBAR END */}

</>
);


}


export default Navbar;