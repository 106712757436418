import React from "react";
import { useState, useEffect, Component } from "react";
import { useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
//import axios from '../../axios.js';
import moment from "moment/moment";

import "../../Resources/homebtcss.css";
import "../../Resources/home2css.css";
import "../../Resources/serviceshomecss.css";
import "../ankitcss.css";
import '../../Resources/responsiveness.css';

//import Sidebar from "./sidebar";

//import Chart from "chart.js/auto";
//import { Line, Bar } from "react-chartjs-2";
import Footer3 from "../../Supportingfiles/footer3";
//import { Navbar } from "flowbite-react";
import Navbar3 from "../../Supportingfiles/navbar3";
import Whystartupconnect from "../whystartupconnect";


function Businessplancreation1() {
  return (
    <>
      <html lang="en">
        <head>
          {/*} Required meta tags */}
          <meta charset="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <title>STARTUP CONNECT |  SERVICES | BUSINESS PLAN CREATION</title>
          {/*} plugins:css */}
          <link
            rel="stylesheet"
            href="panel/assets/vendors/mdi/css/materialdesignicons.min.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/css/vendor.bundle.base.css"
          />
          {/*} endinject */}
          {/*} Plugin css for this page */}
          <link
            rel="stylesheet"
            href="panel/assets/vendors/jquery-bar-rating/css-stars.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/font-awesome/css/font-awesome.min.css"
          />
          {/*} End plugin css for this page */}
          {/*} inject:css */}
          {/*} endinject */}
          {/*} Layout styles */}
          <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
          {/*} End layout styles */}
          <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
        </head>
        <body>

        

   

  {/* Whatsapp Chat Icon Start */}
  <div class="wtp-chat-container" /*style={{position:"fixed",marginTop:"92vh", marginLeft:"94vw"}}*/>
    <a aria-label="Chat on WhatsApp" href="https://wa.me/918480002413?text=I%20want%20to%20know%20more%20about%20service%20Business%20Plan%20Creation%20by%20the%20Startup%20Connect">   
    <img class="wtp-chat-icon" src="https://cdn-icons-png.flaticon.com/128/3938/3938058.png" /*style={{height:"3rem", width:"3rem"}}*/></img>
  </a>
  </div>
  {/* Whatsapp Chat Icon End */}

          <div class="home2-container">
            <Navbar3 />

            {/* HEADER SECTION START */}
            <section /*class="sv-header-section" */>
              <div class="sv-header-container1">
                <div class="sv-header-left home-header-pd">
                  <h1 style={{ color: "#004463" }}>
                  BUSINESS PLAN CREATION

                  </h1>
                  <p>
                  Ready to turn your business idea into a reality? Contact us today to schedule a consultation and learn more about our business plan creation services. Whether you're a startup looking to launch or an established business aiming to grow, Startup Connect is here to help you achieve your goals. Let's embark on this journey together and unlock the full potential of your business.
                  </p>

                  <div class="home2-services-features-list">
                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img
                          loading="lazy"
                          src="https://cdn-icons-png.flaticon.com/128/1087/1087840.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>500+</h3>
                        <h5 style={{ fontWeight: "bold" }}>Projects</h5>
                      </div>
                    </div>

                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img
                          loading="lazy"
                          src="https://cdn-icons-png.flaticon.com/128/12735/12735475.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>25+</h3>
                        <h5 style={{ fontWeight: "bold" }}>Cities</h5>
                      </div>
                    </div>

                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img
                          loading="lazy"
                          src="https://cdn-icons-png.flaticon.com/128/2991/2991235.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>
                          4{" "}
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/7656/7656139.png"
                            style={{ height: "2rem", width: "2rem" }}
                          />
                          +
                        </h3>
                        <h5 style={{ fontWeight: "bold" }}>Google Ratings</h5>
                      </div>
                    </div>
                  </div>

                  <div  class="serviceshome-btn-container">
             <div>
             <a href="https://calendly.com/thestartupconnect/30min"><button class="btn-lft-margin" id="home2-modules-section-btn" style={{marginLeft:"0"}}>Book Free Consultancy <i class="mdi mdi-arrow-right"></i></button></a>
             </div>
             <div style={{marginLeft:"0"}}>
             <a href="/home1/business_plan_creation.pdf" download="Startup Connect Services Brochure"><button class="btn-lft-margin" id="home2-modules-section-btn" style={{backgroundImage: "linear-gradient(to right, #208d17, #00ad11)", marginLeft:"0"}}>Download Brochure <i class="mdi mdi-download"></i></button></a>
             </div>
             </div>

                </div>
                <div
                  class="sv-header-right" /* style={{backgroundColor:"#96dfff",padding:"2rem",borderRadius:"15px",boxShadow:"2px 3px 3px 2px #cbcecf"}} */
                >
                  <img
                    loading="lazy"
                    src="https://img.freepik.com/free-vector/gradient-credit-assessment-concept_23-2149154183.jpg"
                    style={{ width: "90%", height: "100%" }}
                  />
                </div>
              </div>
            </section>
            {/* HEADER SECTION END */}
            <hr />

            {/* SIDEBAR AND MAIN PAGE START */}
            <div className="container-scroller">
              {/*<Sidebar /> */}
              <div className="container-fluid page-body-wrapper">
                {/*} partial */}
                <div className="main-panel">
                 

                 {/* MORE INFORMATION SECTION START */}
                 <div class="row" style={{ padding: "2rem" }}>
                    <div
                      class="col-sm-9 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div>
                        <h2 id="ip-h2" style={{ fontWeight: "bold" }}>
                         WHY A {" "}
                          <span style={{ color: "#2da95c" }}>
                            BUSINESS PLAN?
                          </span>
                        </h2>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                          {" "}
                          A business plan serves as the foundational cornerstone upon which successful ventures are built, encapsulating the essence of your entrepreneurial vision into a tangible roadmap for success. Its significance lies not only in its function as a comprehensive document but also in its role as a strategic compass, guiding your business through the complexities of the market landscape. By meticulously detailing your objectives, market analysis, competitive positioning, and operational strategies, a business plan provides invaluable clarity and direction, fostering a deeper understanding of your business's trajectory and potential.</p>
                          <p style={{ fontSize: "1rem" }}> Moreover, it acts as a powerful communication tool, effectively conveying your vision and value proposition to stakeholders, whether they be investors, lenders, partners, or internal team members. A well-crafted business plan not only articulates your goals but also instills confidence in your ability to execute them, thereby attracting the necessary resources and support for your venture's growth and sustainability. In essence, a business plan is more than just a document; it is the embodiment of your aspirations, a roadmap to navigate the uncertainties of entrepreneurship, and a blueprint for realizing your business's full potential.
                        </p>
                      </div>
                    </div>
                    <div class="col-sm-3 stretch-card grid-margin">
                      <img
                        loading="lazy"
                        src="https://img.freepik.com/free-vector/business-mission-concept-illustration_114360-7295.jpg"
                        style={{ width: "90%", height: "100%" }}
                      />
                    </div>
                  </div>

                  <div class="row" style={{ padding: "2rem" }}>
                  <div class="col-sm-3 stretch-card grid-margin">
                      <img
                        loading="lazy"
                        src="https://img.freepik.com/free-vector/strategic-consulting-concept-illustration_114360-9336.jpg"
                        style={{ width: "90%", height: "100%" }}
                      />
                    </div>
                    <div
                      class="col-sm-9 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div>
                        <h2 id="ip-h2" style={{ fontWeight: "bold" }}>
                         OUR APPROACH {" "}
                          <span style={{ color: "#9f23c2" }}>
                           BUSINESS PLAN CREATION
                          </span>
                        </h2>
                        <br />
                      
                          <p style={{ fontSize: "1rem" }}>
                            {" "}
                            At Startup Connect, we believe in a collaborative approach to business plan creation. We work closely with you to understand your goals, target market, competitive landscape, and unique value proposition. Our process involves:
                          </p>
                          <br/>
                        <h3><i class="mdi mdi-assistant text-warning" style={{fontSize:"1.4rem",padding:"1rem"}}></i> Initial Consultation:</h3>
                          <p style={{ fontSize: "1rem" }}>
                          We start by getting to know you and your business idea. We'll discuss your objectives, target market, competition, and any other relevant factors.{" "}
                          </p>

                          <h3><i class="mdi mdi-assistant text-warning" style={{fontSize:"1.4rem",padding:"1rem"}}></i> Market Research:</h3>
                          <p style={{ fontSize: "1rem" }}>
                          Our team conducts thorough market research to understand industry trends, customer demographics, and competitive analysis. This helps us identify opportunities and challenges within your market.  </p>

                          <h3><i class="mdi mdi-assistant text-warning" style={{fontSize:"1.4rem",padding:"1rem"}}></i> Strategic Planning:</h3>
                          <p style={{ fontSize: "1rem" }}>
                          Based on our research and discussions, we develop a customized strategy for your business. This includes defining your value proposition, revenue model, marketing plan, and operational strategy. </p>

                      </div>
                    </div>
                   
                  </div>


                  <div class="row" style={{ padding: "2rem" }}>
                
                    <div
                      class="col-sm-6 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div>
                         <h3><i class="mdi mdi-assistant text-warning" style={{fontSize:"1.4rem",padding:"1rem"}}></i> Financial Projections:</h3>
                          <p style={{ fontSize: "1rem" }}>
                          We create detailed financial projections, including revenue forecasts, expense estimates, and cash flow analysis. This helps you understand the financial feasibility of your business and attract potential investors.</p>

                          <h3><i class="mdi mdi-assistant text-warning" style={{fontSize:"1.4rem",padding:"1rem"}}></i> Document Creation:</h3>
                          <p style={{ fontSize: "1rem" }}>
                          Using the information gathered, we craft a comprehensive business plan tailored to your specific needs. Our plans are professionally written, visually appealing, and designed to effectively communicate your vision to stakeholders. </p>

                          <h3><i class="mdi mdi-assistant text-warning" style={{fontSize:"1.4rem",padding:"1rem"}}></i> Review and Revisions:</h3>
                          <p style={{ fontSize: "1rem" }}>
                          We provide you with a draft of the business plan for review and feedback. We're committed to ensuring that the final document accurately reflects your vision and goals.</p>

                          <h3><i class="mdi mdi-assistant text-warning" style={{fontSize:"1.4rem",padding:"1rem"}}></i> Final Delivery:</h3>
                          <p style={{ fontSize: "1rem" }}>
                          Once revisions are complete, we deliver the final business plan in a format of your choice, whether it's a PDF document, PowerPoint presentation, or other preferred format. </p>

                      </div>
                    </div>
                    <div class="col-sm-6 stretch-card grid-margin">
                      <img
                        loading="lazy"
                        src="https://img.freepik.com/free-vector/business-plan-concept-illustration_114360-1487.jpg"
                        style={{ width: "90%", height: "100%" }}
                      />
                    </div>
                  </div>
                  {/* MORE INFORMATION SECTION END */}

                  <hr/>
                  <h2 id="ip-h2" style={{fontWeight:"bold"}}>
                    BENEFITS OF A QUALITY <span style={{ color: "#9f23c2" }}>
                            BUSINESS PLAN
                          </span>
                  </h2>
                  {/* BENEFITS SECTION START */}
                  <div class="row" style={{ padding: "2rem" }}>
                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/1464/1464393.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Clarity of Vision:</h4>
                        <p>
                        A well-crafted business plan provides a clear and concise roadmap for your business. It helps you articulate your vision, mission, and core values, ensuring alignment across your team and stakeholders.
                       </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/7756/7756169.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Strategic Direction:</h4>
                        <p>
                        A quality business plan outlines your business goals, objectives, and strategies for achieving them. It helps you identify opportunities, anticipate challenges, and make informed decisions about resource allocation and growth strategies.</p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/4334/4334580.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Risk Mitigation:</h4>
                        <p>
                        By conducting thorough market research and financial analysis, a business plan helps identify potential risks and challenges early on. This allows you to develop contingency plans and mitigate risks effectively, increasing the likelihood of success.</p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/8991/8991202.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Attracting Investors and Financing:</h4>
                        <p>
                        Investors and lenders often require a comprehensive business plan before providing funding. A quality business plan demonstrates the viability and potential of your business, instilling confidence in investors and increasing your chances of securing financing.</p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/4429/4429903.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Operational Efficiency:</h4>
                        <p>
                        A well-defined business plan outlines your organizational structure, operational processes, and resource allocation strategies. This helps streamline operations, improve efficiency, and maximize productivity across your business.</p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/1070/1070889.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Alignment and Accountability:</h4>
                        <p>
                        By documenting your business goals and strategies, a quality business plan fosters alignment and accountability within your organization. It provides a framework for measuring progress, tracking milestones, and holding team members accountable for results.</p>
                      </div>
                    </div>

                   
                  
                   
                  </div>
                  {/* BENEFITS SECTION END */}

                  <hr />
               
                   {/* PROCESS SECTION START */}
                   <h2 id="ip-h2">COMMON TYPES OF BUSINESS PLANS</h2>
                   <div class="row d-flex justify-content-center" style={{padding:"1rem"}}>
                    <div class="col-sm-4 stretch-card grid-margin" id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/free-vector/pitch-meeting-concept-illustration_114360-6000.jpg"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold"> Startup Business Plan: </h4>
                          <div class="d-flex justify-content-between">
                            <p class="mb-0" style={{ fontSize: "1rem" }}>
                            Tailored for new ventures seeking to launch and grow their businesses.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-4 stretch-card grid-margin" id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/free-vector/team-goals-concept-illustration_114360-5146.jpg"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold"> Expansion/ Growth Plan: </h4>
                          <div>
                            <p class="mb-0" style={{ fontSize: "1rem", textAlign:"center" }}>
                            Designed for established businesses looking to expand into new markets, launch new products, or scale operations.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-4 stretch-card grid-margin" id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/premium-vector/businessman-shaking-hands-deal-symbol-with-fellow-lawyer-lawyers-discuss-with-clients_923732-776.jpg?w=740"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold"> Investor Pitch Deck:  </h4>
                          <div>
                            <p class="mb-0" style={{ fontSize: "1rem", textAlign:"center" }}>
                            A concise, visually compelling presentation for pitching to investors and securing funding.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-4 stretch-card grid-margin" id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/free-vector/process-optimization-concept-illustration_114360-21583.jpg"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold"> Operational Plan: </h4>
                          <div>
                            <p class="mb-0" style={{ fontSize: "1rem",textAlign:"center" }}>
                            Outlines day-to-day operations, processes, and resource allocation strategies.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-4 stretch-card grid-margin" id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/free-vector/product-quality-concept-illustration_114360-7301.jpg?w=740&t=st=1706780098~exp=1706780698~hmac=4ed66236fdef104f05dab6b8d7176409171e304e19a3758c389bc28837bbe13b"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold"> Feasibility Study: </h4>
                          <div>
                            <p class="mb-0" style={{ fontSize: "1rem",textAlign:"center" }}>
                            Assesses the feasibility of a business idea or project, including market analysis, financial projections, and risk assessment.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <hr/>
                  {/* PROCESS SECTION END */}

                  {/* Why StartUp Connect Starts */}
                  <Whystartupconnect/>
                  {/* Why StartUp Connect Ends */}

                  <hr />

                  {/* PRICING SECTION START */}
                  <h2 id="ip-h2" style={{fontWeight:'bold'}}>PRICING FOR <span style={{color:"#006345"}}>BUSINESS PLAN CREATION</span></h2>
                  <div class="row md-12" style={{marginTop:"5rem"}}>

                  {/* PRICING CARD 1 START */}
                  <div
                      class="col-sm-12 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                    <div class="card">
                    <div style={{backgroundColor:"#207a78",color:"#ffffff",fontWeight:"bold",textAlign:"center",borderRadius:"12px",padding:"1rem"}}>
                    <h3 class="pricing-ul-text"><u>GET CUSTOMIZED QUOTATIONS FOR BUSINESS PLAN CREATION IN YOUR BUDGET</u></h3>
                    <div style={{textAlign:"center"}}><h2 style={{fontWeight:"bold"}}> Starting at <span style={{color:"#ffffff"}}>₹ 9999*</span> only</h2></div>
                    </div>
                    <div style={{padding:"1rem",margingTop:"1rem"}}>
                       <div class="d-flex justify-content-center">
                       <button class="btn btn-lg btn-success" style={{backgroundColor:"#207a78", fontSize:"1rem"}} onClick={()=>{
                        //Setting Values to redirect to Contact Sales Page
                        sessionStorage.setItem("Smodule00","Services");sessionStorage.setItem("Sservice00","Business Plan Creation"); window.location.replace("/businesstools-contactsales");
                       }}>Contact Sales</button>
                       </div>
                    </div>
                    </div>
                    </div>
                    {/* PRICING CARD 1 END */}

                  </div>
                  <hr/>
                  {/* PRICING SECTION END */}
              

             
                  <h2 id="ip-h2">Frequently Asked Questions (FAQs)</h2>
                  {/* FAQ SECTION START */}
                  <div
                    id="accordion"
                    style={{ textAlign: "left", marginTop: "3rem" }}
                  >
                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#003e73" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                      What is a business plan, and why is it important?
                        </h5>
                        <div>
                          <button
                            id="ques-1-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-1").style.display =
                                "block";
                              document.getElementById(
                                "ques-1-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-1-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-1-dicon"
                            style={{ display: "none" }}
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-1").style.display =
                                "none";
                              document.getElementById(
                                "ques-1-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-1-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-1">
                        <div class="card-body">
                        A business plan is a comprehensive document that outlines your business goals, strategies, and operational details. It serves as a roadmap for your venture, guiding decision-making, attracting investors, and ensuring long-term success. A quality business plan is essential for clarifying your vision, identifying opportunities, and mitigating risks.
                        </div>
                        </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                         Why should I consider hiring a professional service for business plan creation?
                        </h5>
                        <div>
                          <button
                            id="ques-2-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-2").style.display =
                                "block";
                              document.getElementById(
                                "ques-2-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-2-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-2-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-2").style.display =
                                "none";
                              document.getElementById(
                                "ques-2-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-2-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-2" style={{ display: "none" }}>
                        <div class="card-body">
                        Crafting a high-quality business plan requires expertise in market research, financial analysis, and strategic planning. Professional services bring experience and industry knowledge to the table, ensuring that your business plan is thorough, accurate, and tailored to your specific needs. Additionally, working with experts saves you time and allows you to focus on other aspects of your business.</div>
                        </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                       What types of businesses can benefit from business plan creation services?
                        </h5>
                        <div>
                          <button
                            id="ques-3-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-3").style.display =
                                "block";
                              document.getElementById(
                                "ques-3-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-3-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-3-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-3").style.display =
                                "none";
                              document.getElementById(
                                "ques-3-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-3-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-3" style={{ display: "none" }}>
                        <div class="card-body">
                        Business plan creation services are beneficial for startups, small businesses, and established companies alike. Whether you're launching a new venture, seeking funding, expanding into new markets, or refining your strategy, a well-crafted business plan can provide valuable insights and guidance.</div>
                        </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                    How does the business plan creation process work?
                        </h5>
                        <div>
                          <button
                            id="ques-4-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-4").style.display =
                                "block";
                              document.getElementById(
                                "ques-4-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-4-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-4-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-4").style.display =
                                "none";
                              document.getElementById(
                                "ques-4-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-4-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-4" style={{ display: "none" }}>
                        <div class="card-body">
                        The process typically begins with an initial consultation, where the service provider gathers information about your business, goals, and target market. From there, they conduct market research, develop strategic plans, create financial projections, and craft the business plan itself. Throughout the process, there is collaboration between you and the service provider to ensure that the plan accurately reflects your vision and objectives.</div>
                      </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                    How long does it take to create a business plan?

                        </h5>
                        <div>
                          <button
                            id="ques-5-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-5").style.display =
                                "block";
                              document.getElementById(
                                "ques-5-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-5-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-5-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-5").style.display =
                                "none";
                              document.getElementById(
                                "ques-5-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-5-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-5" style={{ display: "none" }}>
                        <div class="card-body">
                        The timeline for creating a business plan depends on various factors, including the complexity of your business, the level of detail required, and the availability of information. Generally, the process can take several weeks to a few months from initial consultation to final delivery. However, some service providers offer expedited options for clients with tight deadlines.
                        </div>
                         </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                       How can a business plan help me secure funding or investment?
                        </h5>
                        <div>
                          <button
                            id="ques-6-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-6").style.display =
                                "block";
                              document.getElementById(
                                "ques-6-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-6-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-6-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-6").style.display =
                                "none";
                              document.getElementById(
                                "ques-6-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-6-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-6" style={{ display: "none" }}>
                        <div class="card-body">
                        A well-crafted business plan is essential for attracting investors and lenders. It demonstrates your understanding of the market, the viability of your business model, and the potential for growth and profitability. Investors use business plans to evaluate the risks and opportunities associated with your venture and make informed decisions about providing funding or investment.</div>
                      </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                         Can I update or revise my business plan as my business evolves?
                        </h5>
                        <div>
                          <button
                            id="ques-7-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-7").style.display =
                                "block";
                              document.getElementById(
                                "ques-7-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-7-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-7-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-7").style.display =
                                "none";
                              document.getElementById(
                                "ques-7-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-7-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-7" style={{ display: "none" }}>
                        <div class="card-body">
                        Yes, it's important to regularly review and update your business plan to reflect changes in your business, market conditions, or strategic priorities. Many business plan creation services offer ongoing support and assistance with revisions to ensure that your plan remains current and relevant as your business evolves.</div>
                      </div>
                    </div>

                  {/*} <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                         What kind of analytics can I expect from a web app?
                        </h5>
                        <div>
                          <button
                            id="ques-8-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-8").style.display =
                                "block";
                              document.getElementById(
                                "ques-8-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-8-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-8-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-8").style.display =
                                "none";
                              document.getElementById(
                                "ques-8-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-8-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-8" style={{ display: "none" }}>
                        <div class="card-body">
                        Web apps can provide advanced analytics to track user behavior and identify areas for improvement.{" "}
                        </div>
                      </div>
                    </div> */}
                  </div>
                  {/* FAQ SECTION END */}

                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
            {/* SIDEBAR AND MAIN PAGE END */}

            <Footer3 />
          </div>
          {/*} container-scroller */}
          {/*} plugins:js */}
          <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
          {/*} endinject */}
          {/*} Plugin js for this page */}
          <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
          <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
          <script
            src="panel/assets/js/jquery.cookie.js"
            type="text/javascript"
          ></script>
          {/*} End plugin js for this page */}
          {/*} inject:js */}
          <script src="panel/assets/js/off-canvas.js"></script>
          <script src="panel/assets/js/hoverable-collapse.js"></script>
          <script src="panel/assets/js/misc.js"></script>
          <script src="panel/assets/js/settings.js"></script>
          <script src="panel/assets/js/todolist.js"></script>
          {/*} endinject */}
          {/*} Custom js for this page */}
          <script src="panel/assets/js/dashboard.js"></script>
          {/*} End custom js for this page */}
        </body>
      </html>
    </>
  );
}

export default Businessplancreation1;
