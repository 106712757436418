import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';



/*import Amanbg from './amanbg';*/


function Sidebar(){

     
    return(
<>

{/* SIDEBAR START */}

<nav class="sidebar sidebar-offcanvas" id="sidebar" style={{marginTop:"5vh"}}>
          <ul class="nav">
           {/*}  <li class="nav-item nav-profile">
              <a href="#" class="nav-link">
                <div class="nav-profile-image">
                  <img src="assets/images/faces/face1.jpg" alt="profile" />
                  <span class="login-status online"></span>
                  {change to offline or busy as needed}
                </div>
               <div class="nav-profile-text d-flex flex-column">
                  <span class="font-weight-bold mb-2">David Grey. H</span>
                  <span class="text-secondary text-small">Project Manager</span>
                </div> 

                <i class="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
              </a>
            </li> */}
            <li class="nav-item">
              <a class="nav-link" href="/admin-dashboard">
                <span class="menu-title">Summary</span>
                <i class="mdi mdi-access-point menu-icon"></i>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/admin-quotations">
                <span class="menu-title">Quotations</span>
                <i class="mdi mdi-access-point menu-icon"></i>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/admin-orders">
                <span class="menu-title">Orders</span>
                <i class="mdi mdi-access-point menu-icon"></i>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/admin-payments">
                <span class="menu-title">Payments</span>
                <i class="mdi mdi-access-point menu-icon"></i>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/adminpanel-dashboard">
                <span class="menu-title">Manage Admins</span>
                <i class="mdi mdi-account-check menu-icon"></i>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/teampanel-dashboard">
                <span class="menu-title">Manage Team</span>
                <i class="mdi mdi-account-multiple-plus menu-icon"></i>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/projects-list">
                <span class="menu-title">Projects</span>
                <i class="mdi mdi-account-key menu-icon"></i>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/registrations-list">
                <span class="menu-title">Registrations</span>
                <i class="mdi mdi-account-key menu-icon"></i>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/login-history">
                <span class="menu-title">Logins</span>
                <i class="mdi mdi-account-box-outline menu-icon"></i>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/customers-list">
                <span class="menu-title">Customers</span>
                <i class="mdi mdi-account-box-outline menu-icon"></i>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/clients-list">
                <span class="menu-title">Clients</span>
                <i class="mdi mdi-account-box-outline menu-icon"></i>
              </a>
            </li>
     {/*}       <li class="nav-item">
              <a class="nav-link" href="index.html">
                <span class="menu-title">Service Portal Contacts</span>
                <i class="mdi mdi-home menu-icon"></i>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="index.html">
                <span class="menu-title">Tasks</span>
                <i class="mdi mdi-home menu-icon"></i>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-bs-toggle="collapse" href="#ui-basic" aria-expanded="false" aria-controls="ui-basic">
                <span class="menu-title">Portals</span>
                <i class="menu-arrow"></i>
                <i class="mdi mdi-crosshairs-gps menu-icon"></i>
              </a>
              <div class="collapse" id="ui-basic">
                <ul class="nav flex-column sub-menu">
                  <li class="nav-item"> <a class="nav-link" href="pages/ui-features/buttons.html">Services Portal</a></li>
                  <li class="nav-item"> <a class="nav-link" href="pages/ui-features/typography.html">Internship Portal</a></li>
                </ul>
              </div>
            </li>
         */}
       
          
          </ul>
        </nav>

{/* SIDEBAR END */}
</>
    );
}

export default Sidebar;