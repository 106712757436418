import React from "react";
import { useState, useEffect, Component } from "react";
import { useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
//import axios from '../../axios.js';
import moment from "moment/moment";

import "../../Resources/homebtcss.css";
import "../../Resources/home2css.css";
import "../../Resources/serviceshomecss.css";
import "../ankitcss.css";
import '../../Resources/responsiveness.css';

//import Sidebar from "./sidebar";

//import Chart from "chart.js/auto";
//import { Line, Bar } from "react-chartjs-2";
import Footer3 from "../../Supportingfiles/footer3";
//import { Navbar } from "flowbite-react";
import Navbar3 from "../../Supportingfiles/navbar3";
import Whystartupconnect from "../whystartupconnect";
import Comparisontable from "./comparisontable";

function Soleproprietorshipregistration1() {

  

  /* Cashfree Button Style */

  const button_container = {
    border: "1px solid black",
    borderRadius: "5px",
    display: "flex",
    padding: "10px", 
    width: "fit-content",
    cursor: "pointer",
    background: "#0b6083",
    border:"none"
};

const text_container = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginLeft: "10px",
  justifyContent: "center",
  marginRight: "10px",
  fontWeight:"bold"
};
const logo_container = {
  width: "40px",
  height: "40px"
};
const secondary_logo_container = {
width: "16px",
height: "16px",
verticalAlign: "middle"
};

  return (
    <>
        <html lang="en">
        <head>
          {/*} Required meta tags */}
          <meta charset="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <title>STARTUP CONNECT | SERVICES | SOLE PROPRIETORSHIP REGISTRATION</title>
          {/*} plugins:css */}
          <link
            rel="stylesheet"
            href="panel/assets/vendors/mdi/css/materialdesignicons.min.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/css/vendor.bundle.base.css"
          />
          {/*} endinject */}
          {/*} Plugin css for this page */}
          <link
            rel="stylesheet"
            href="panel/assets/vendors/jquery-bar-rating/css-stars.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/font-awesome/css/font-awesome.min.css"
          />
          {/*} End plugin css for this page */}
          {/*} inject:css */}
          {/*} endinject */}
          {/*} Layout styles */}
          <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
          {/*} End layout styles */}
          <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
        </head>
        <body>

        
  {/* Whatsapp Chat Icon Start */}
  <div class="wtp-chat-container" /*style={{position:"fixed",marginTop:"92vh", marginLeft:"94vw"}}*/>
    <a aria-label="Chat on WhatsApp" href="https://wa.me/918480002413?text=I%20want%20to%20know%20more%20about%20service%20Sole%20Proprietorship%20Registration%20by%20the%20Startup%20Connect">   
    <img class="wtp-chat-icon" src="https://cdn-icons-png.flaticon.com/128/3938/3938058.png" /*style={{height:"3rem", width:"3rem"}}*/></img>
  </a>
  </div>
  {/* Whatsapp Chat Icon End */}

          <div class="home2-container">
            <Navbar3 />

            {/* HEADER SECTION START */}
            <section /*class="sv-header-section" */>
              <div class="sv-header-container1">
                <div class="sv-header-left home-header-pd">
                  <h1 style={{ color: "#004463" }}>
                    SOLE PROPRIETORSHIP REGISTRATION
                  </h1>
                  <p>
                  <div style={{ marginBottom: "2px" }}>
                      <span style={{ paddingRight: "5px" }}><i class="mdi mdi-checkbox-marked" style={{fontSize:"2rem", color :"#0b5748"}}></i></span>
                      <span>Fast customer support </span>
                    </div>
                    <div style={{ marginBottom: "2px" }}>
                      <span style={{ paddingRight: "5px" }}><i class="mdi mdi-checkbox-marked" style={{fontSize:"2rem", color :"#0b5748"}}></i></span>
                      <span>Complete online process.</span>
                    </div>
                    <div style={{ marginBottom: "2px" }}>
                      <span style={{ paddingRight: "5px" }}><i class="mdi mdi-checkbox-marked" style={{fontSize:"2rem", color :"#0b5748"}}></i></span>
                      <span>Instant WhatsApp/email updates </span>
                    </div>
                  </p>

                  <div class="home2-services-features-list">
                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img
                          loading="lazy"
                          src="https://cdn-icons-png.flaticon.com/128/1087/1087840.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>500+</h3>
                        <h5 style={{ fontWeight: "bold" }}>Projects</h5>
                      </div>
                    </div>

                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img
                          loading="lazy"
                          src="https://cdn-icons-png.flaticon.com/128/12735/12735475.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>25+</h3>
                        <h5 style={{ fontWeight: "bold" }}>Cities</h5>
                      </div>
                    </div>

                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img
                          loading="lazy"
                          src="https://cdn-icons-png.flaticon.com/128/2991/2991235.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>
                          4{" "}
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/7656/7656139.png"
                            style={{ height: "2rem", width: "2rem" }}
                          />
                          +
                        </h3>
                        <h5 style={{ fontWeight: "bold" }}>Google Ratings</h5>
                      </div>
                    </div>
                  </div>

                  <div  class="serviceshome-btn-container">
             <div>
             <a href="https://calendly.com/thestartupconnect/30min"><button class="btn-lft-margin" id="home2-modules-section-btn" style={{marginLeft:"0"}}>Book Free Consultancy <i class="mdi mdi-arrow-right"></i></button></a>
             </div>
             <div style={{marginLeft:"0"}}>
             <a href="/home1/soleproprietorship_registration.pdf" download="Startup Connect Services Brochure"><button class="btn-lft-margin" id="home2-modules-section-btn" style={{backgroundImage: "linear-gradient(to right, #208d17, #00ad11)", marginLeft:"0"}}>Download Brochure <i class="mdi mdi-download"></i></button></a>
             </div>
             </div>
             
                </div>
                <div
                  class="sv-header-right" /* style={{backgroundColor:"#96dfff",padding:"2rem",borderRadius:"15px",boxShadow:"2px 3px 3px 2px #cbcecf"}} */
                >
                  <img
                    loading="lazy"
                    src="https://img.freepik.com/free-vector/boss-man-concept-illustration_114360-16127.jpg"
                    style={{ width: "90%", height: "100%" }}
                  />
                </div>
              </div>
            </section>
            {/* HEADER SECTION END */}
            <hr />

            {/* SIDEBAR AND MAIN PAGE START */}
            <div className="container-scroller">
              {/*<Sidebar /> */}
              <div className="container-fluid page-body-wrapper">
                {/*} partial */}
                <div className="main-panel">
            

                  {/* MORE INFORMATION SECTION START */}
                  <div class="row" style={{ padding: "2rem" }}>
                    <div
                      class="col-sm-8 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div>
                        <h2 id="ip-h2" style={{ fontWeight: "bold" }}>
                         WHAT IS {" "}
                          <span style={{ color: "#9f23c2" }}>
                            SOLE PROPRIETORSHIP?
                          </span>
                        </h2>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                          {" "}
                          A sole proprietorship firm means a type of business
                          entity that is owned, controlled and managed by a
                          single person. The owner of the Business is called
                          Sole Proprietor of the Firm. As the business is run by
                          a natural person, there is no legal difference between
                          the promoter and the business. The promoter himself
                          receives all the profits. Sole Proprietorship firm
                          does not require any formal registration.
                        </p>
                        <p style={{ fontSize: "1rem" }}>
                          sole proprietorship firm is very easy to start as
                          there are less legal formalities and lesser formation
                          costs involved.
                        </p>
                         <br/>
                        <h2 id="ip-h2">
                    <b>ESSENTIAL LICENSES AND REGISTRATIONS FOR <span style={{color:"#2358a6"}}> SOLE PROPRIETORSHIP</span></b>
                  </h2>
                  <br/>
                  <h4 style={{ fontWeight: "bold" }}>
                          1. MSME Registration
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                          {" "}
                          It recognizes your business as a Micro, Small, or
                          Medium Enterprise (MSME) and offers government
                          benefits.
                        </p>
                        <br />
                        <h4 style={{ fontWeight: "bold" }}>
                          2. GST Registration
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                          If your business exceeds specific thresholds, you must
                          register for Goods and Services Tax (GST) to collect
                          and pay GST.
                        </p>
                        <br />
                        <h4 style={{ fontWeight: "bold" }}>
                          3. Shop and Extablishment Rehistration
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                          Depending on your business location, register under
                          your state's Shops and Establishment Act to follow
                          local labor regulations
                        </p>
                      </div>
                    </div>
                    <div class="col-sm-4 stretch-card grid-margin">
                      <img
                        loading="lazy"
                        src="https://img.freepik.com/free-vector/shop-with-open-sign_23-2148562251.jpg"
                        style={{ width: "90%", height: "100%" }}
                      />
                    </div>
                  </div>
                  {/* MORE INFORMATION SECTION END */}

                  <hr />
                  <h2 id="ip-h2"><b>BENEFITS OF <span style={{color:"#692dc2"}}> SOLE PROPRIETORSHIP REGISTRATION</span></b></h2>
                  {/* BENEFITS SECTION START */}
                  <div class="row d-flex justify-content-center" style={{ padding: "2rem" }}>
                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/2590/2590440.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">No Minimum Capital</h4>
                        <p>
                          There are no restrictions as such with respect to the
                          minimum capital requirement in case of Proprietor
                          Registration.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/4727/4727424.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Easy to Form</h4>
                        <p>
                          Easy and cost-effective to establish, involving fewer
                          formalities compared to other business structures.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/2169/2169997.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Easy to Close</h4>
                        <p>
                          The dissolution or sale of the Proprietorship firm is
                          very easy. As there is no legal distinction between
                          the proprietor and the proprietorship firm, not much
                          regulatory formalities are required for closing of a
                          proprietorship.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/3529/3529127.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Tax benefits</h4>
                        <p>
                          The owner of a sole proprietorship is not required to
                          file a separate business tax report. The business will
                          be taxed at the rates applied to personal income, and
                          not corporate tax rates. This means huge tax
                          savings...{" "}
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/10828/10828751.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Inexpensive</h4>
                        <p>
                          A Sole Proprietorship registration is inexpensive as
                          compared to all other forms of organizations. Also on
                          account of the minimum compliance requirements, it
                          proves to be an advantage as it is inexpensive even in
                          long run also.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/13163/13163215.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Decision Making </h4>
                        <p>
                          Control over all business decisions remains in the
                          hands of the owner. This ensures quicker business
                          decision making and hence, faster execution. Owners
                          have complete and direct control over all decision
                          making.
                        </p>
                      </div>
                    </div>

                    
                  </div>
                  {/* BENEFITS SECTION END */}

                  <hr />

                  
                 {/* PRICING SECTION START */}
                 <h2 id="ip-h2" style={{fontWeight:'bold'}}>PRICING FOR <span style={{color:"#006345"}}>SOLE PROPRIETORSHIP REGISTRATION</span></h2>
                  <div class="row md-12 d-flex justify-content-center" style={{marginTop:"5rem", padding:"1rem"}}>

                  {/* PRICING CARD 1 START */}
                  <div
                      class="col-sm-4 stretch-card grid-margin" id="resp-2"
                      style={{ textAlign: "left" }}
                    >
                    <div class="card">
                    <div style={{backgroundColor:"#207a78",color:"#ffffff",fontWeight:"bold",textAlign:"center",borderRadius:"12px",padding:"1rem"}}>
                    <h3><u>BUSINESS</u></h3>
                    <div style={{textAlign:"center"}}><h2 style={{fontWeight:"bold"}}> Starts <span style={{color:"#ffffff"}}>₹ 3499*</span></h2></div>
                    </div>
                    <div style={{padding:"1rem",margingTop:"1rem"}}>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i> GST Registration</p>
                        <p><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>MSME Registration<br/></p>
                        <p style={{fontSize:"1rem", textDecoration:"line-through"}}><i class="mdi mdi-close-circle text-danger" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Shop Establishment Act</p>
                        <hr/>
                       <div class="d-flex justify-content-center">
                       <button class="btn btn-lg btn-success" style={{backgroundColor:"#207a78", fontSize:"1rem"}} onClick={()=>{
                        //Setting Values to redirect to Contact Sales Page
                        sessionStorage.setItem("Smodule00","Services");sessionStorage.setItem("Sservice00","Sole Proprietorship Registration"); window.location.replace("/businesstools-contactsales");
                       }}>Contact Sales</button>
                        {/* CASHFREE CHECKOUT BUTTON START FOR PRICING CARD 1 */}
                        <div>
                        <a style={{textDecoration:"none"}} href="https://payments.cashfree.com/forms/startupconnect-proprietorship-payment" target="_parent">
                        <div class="button-container" style={button_container}>
                        <div>
                        {/*}    <img src="https://cashfree-checkoutcartimages-prod.cashfree.com/startupconnectlogo1Y6cg7dgopcs0_prod.png" alt="logo" style={logo_container} class="logo-container" />
                        */} </div>
                        <div class="text-container" style={text_container}>
                        <div style={{fontFamily: "Arial", color: "#fff", marginBottom: "5px", fontSize: "14px"}}>
                        Check Out
                        </div>
                        <div style={{fontFamily: "Arial", color: "#fff", fontSize: "10px"}}>
                        <span>Powered By Cashfree</span>
                        <img src="https://cashfreelogo.cashfree.com/cashfreepayments/logosvgs/Group_4355.svg" alt="logo" style={secondary_logo_container} class="seconday-logo-container" />
                        </div>
                        </div>
                        </div>
                        </a>
                        </div>
                       {/* CASHFREE CHECKOUT BUTTON START FOR PRICING CARD 1 */}
                       </div>
                    </div>
                    </div>
                    </div>
                    {/* PRICING CARD 1 END */}

                      {/* PRICING CARD 2 START */}
                  <div
                      class="col-sm-4 stretch-card grid-margin" id="resp-2"
                      style={{ textAlign: "left" }}
                    >
                    <div class="card">
                    <div style={{backgroundColor:"#207a78",color:"#ffffff",fontWeight:"bold",textAlign:"center",borderRadius:"12px",padding:"1rem"}}>
                    <h3><u>PROFESSIONAL</u></h3>
                    <div style={{textAlign:"center"}}><h2 style={{fontWeight:"bold"}}> Starts <span style={{color:"#ffffff"}}>₹ 4999*</span></h2></div>
                    </div>
                    <div style={{padding:"1rem",margingTop:"1rem"}}>
                    <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i> MSME Registration</p>
                    <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>GST Registration</p>
                    <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Shop Establishment Act<br/></p>
                        <hr/>
                       <div class="d-flex justify-content-center">
                       <button class="btn btn-lg btn-success" style={{backgroundColor:"#207a78", fontSize:"1rem"}} onClick={()=>{
                        //Setting Values to redirect to Contact Sales Page
                        sessionStorage.setItem("Smodule00","Services");sessionStorage.setItem("Sservice00","Sole Proprietorship Registration"); window.location.replace("/businesstools-contactsales");
                       }}>Contact Sales</button>
                        {/* CASHFREE CHECKOUT BUTTON START FOR PRICING CARD 2 */}
                        <div>
                        <a style={{textDecoration:"none"}} href="https://payments.cashfree.com/forms/startupconnect-proprietorship-professional-payment" target="_parent">
                        <div class="button-container" style={button_container}>
                        <div>
                        {/*}    <img src="https://cashfree-checkoutcartimages-prod.cashfree.com/startupconnectlogo1Y6cg7dgopcs0_prod.png" alt="logo" style={logo_container} class="logo-container" />
                        */} </div>
                        <div class="text-container" style={text_container}>
                        <div style={{fontFamily: "Arial", color: "#fff", marginBottom: "5px", fontSize: "14px"}}>
                        Check Out
                        </div>
                        <div style={{fontFamily: "Arial", color: "#fff", fontSize: "10px"}}>
                        <span>Powered By Cashfree</span>
                        <img src="https://cashfreelogo.cashfree.com/cashfreepayments/logosvgs/Group_4355.svg" alt="logo" style={secondary_logo_container} class="seconday-logo-container" />
                        </div>
                        </div>
                        </div>
                        </a>
                        </div>
                       {/* CASHFREE CHECKOUT BUTTON START FOR PRICING CARD 2 */}
                       </div>
                    </div>
                    </div>
                    </div>
                    {/* PRICING CARD 2 END */}


                  </div>
                  <hr/>
                  {/* PRICING SECTION END */}
             
                
          
                  <h2 id="ip-h2" style={{padding:"0.5rem"}}>SOLE PROPRIETORSHIP REGISTRATION PROCESS</h2>

                  {/* PROCESS SECTION START */}
                  <div class="row" style={{padding:"1rem"}}>
                    <div class="col-sm-4 stretch-card grid-margin" id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/free-vector/paying-bills-concept-illustration_114360-19357.jpg?w=740&t=st=1706779500~exp=1706780100~hmac=ad8e6bf9dc5da282c80e40c82c023c68f94bea72270aa35612471e5892f8985b"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold"> Step 1 </h4>
                          <div>
                            <p class="mb-0" style={{ fontSize: "1rem", textAlign:"center" }}>
                              GST registration
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-4 stretch-card grid-margin" id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/free-vector/login-concept-illustration_114360-748.jpg?t=st=1706779615~exp=1706780215~hmac=9651a498d516590febe6779c62640331889c05ff48733ca96f9ca77ddcdec593"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold"> Step 2 </h4>
                          <div>
                            <p class="mb-0" style={{ fontSize: "1rem", textAlign:"center" }}>
                              MSME Registration
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-4 stretch-card grid-margin" id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/premium-vector/businessman-shaking-hands-deal-symbol-with-fellow-lawyer-lawyers-discuss-with-clients_923732-776.jpg?w=740"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold"> Step 3 </h4>
                          <div>
                            <p class="mb-0" style={{ fontSize: "1rem",textAlign:"center" }}>
                              Shop and Establishment Registration{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* PROCESS SECTION END */}

                  {/* Why StartUp Connect Starts */}

                  <Whystartupconnect/>
                  {/* Why StartUp Connect Ends */}

                  <hr />

                  {/* Documents SECTION Starts */}
                  <h2 id="ip-h2" style={{padding:"0.5rem"}}> DOCUMENTS REQUIRED FOR SOLE PROPRIETORSHIP REGISTRATION</h2>
                  <div class="row d-flex justify-content-center" style={{ padding: "2rem" }}>
                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/3135/3135715.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>

                        <h4 class="mt-3">Photograph of Owner </h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/1726/1726620.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">
                        PAN Card and Aadhar card of Owner
                        </h4>
                      </div>
                    </div>

                    

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/9875/9875524.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">
                        Address  Proof of the Business 
                        </h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/6108/6108273.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3" style={{ fontSize: "15px" }}>
                        Electricity Bill or any other utility bill for the address proof of the Business
                        </h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/1584/1584961.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">
                           Consent letter/Rent
                          agreement{" "}
                        </h4>
                      </div>
                    </div>
                  </div>
                  {/* Documents SECTION Ends */}
                  <hr/>
                  {/* COMPLIANCES SECTION START */}
                  <h2 id="ip-h2" style={{padding:"0.5rem"}}>COMPLIANCES REQUIRED BY SOLE PROPRIETORSHIP</h2>
                  <p style={{ fontSize: "1rem" }}>
                    Once a LLP is registered in India, various compliances must
                    be maintained from time to time to avoid penalties and
                    prosecution. The following are some of the compliances a LLP
                    would be required to complete after LLP registration:
                  </p>
                  <div
                    class="row"
                    style={{ textAlign: "left", padding: "2rem" }}
                  >
                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i>  GST Registration</h3>
                    <p style={{ fontSize: "1rem" }}>
                      GST registration is one of the ways to get a
                      proprietorship registration done. You can get yourself
                      registered under GST if your annual turnover is more than
                      Rs. 40 lakhs. Also, if you are doing online business
                      (selling through amazon, flipkart etc.), you are required
                      to get a GST number under goods and service tax Act.{" "}
                    </p>
                    <br />
                    <br />

                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> GST Return</h3>
                    <p style={{ fontSize: "1rem" }}>
                      The proprietorship firms registered under GST are required
                      to file the GST return on a monthly, quarterly and annual
                      basis. GST return is a document that contains the details
                      of the income of the taxpayer. As filing of GST returns is
                      mandatory for all the registered Taxpayers including
                      Partnership firms.
                    </p>
                    <br />
                    <br />

                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i>  Accounting</h3>
                    <p style={{ fontSize: "1rem" }}>
                      Accounting The accounting for a sole proprietorship does
                      not require a separate set of accounting records, since
                      the owner is considered to be inseparable from the
                      business. Nonetheless, it is always advisable to maintain
                      records for business activities of a Proprietorship firm,
                      in order to judge whether these operations are generating
                      a profit.{" "}
                    </p>
                    <br />
                    <br />

                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i>  IT Return</h3>
                    <p style={{ fontSize: "1rem" }}>
                      Income tax filing must be filed by all proprietorship
                      having a taxable income over the exemption threshold. In
                      some cases, a tax audit would also be required. Since
                      proprietorship is considered to be one and same as the
                      proprietor, the income tax return filing procedure for a
                      proprietorship is similar to individual income tax return
                      filing.{" "}
                    </p>
                    <br />
                    <br />

                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> GST Registration</h3>
                    <p style={{ fontSize: "1rem" }}>
                      Every LLP is required to get their GST registration. GST
                      Registration Process is 100% online and there is no
                      requirement of submission of physical documents to GST
                      Department. GST registration must be obtained within 30
                      days of business incorporation, otherwise, the LLP will
                      not be able to issue proper GST related invoices.
                    </p>
                    <br />
                    <br />

                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> GST Return</h3>
                    <p style={{ fontSize: "1rem" }}>
                      Once a Limited Liability Partnership gets registered under
                      GST It becomes necessary to file GST returns for LLPs. As
                      filing of GST returns is mandatory for all the registered
                      Taxpayers including LLPs. GST returns can be filed
                      monthly, quarterly or yearly depending upon the types of
                      GST returns form you are filing.
                    </p>
                    <br />
                    <br />

                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i>  Tax Audit</h3>
                    <p style={{ fontSize: "1rem" }}>
                      There is no obligation for a sole proprietor under any law
                      to get the accounts except in case where the turnover of a
                      proprietary business in any financial year exceeds 1
                      Crores Rupees and gross receipt from profession exceeds 50
                      Lakhs Rupees. In both cases, the audit of accounts is
                      compulsory for a proprietor under the Income Tax Act.
                    </p>

                    <br />
                    <br />
                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> TDS Return</h3>
                    <p style={{ fontSize: "1rem" }}>
                      Quarterly TDS returns must be filled by partnership firms
                      that have TAN and are required to deduct tax at source as
                      per TDS rules. A TDS Return is a quarterly statement which
                      has to be submitted to the IT Department of India. It
                      should contain all details of TDS deducted and deposited
                      by you for a particular quarter.
                    </p>
                  </div>
                  {/* COMPLIANCES SECTION END */}
                  <hr />
                 {/* COMPARISON TABLE START */}
                 <Comparisontable/>
                  {/* COMPARISON TABLE END */}
                  {/* FAQ SECTION END */}

                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
            {/* SIDEBAR AND MAIN PAGE END */}

            <Footer3 />
          </div>
          {/*} container-scroller */}
          {/*} plugins:js */}
          <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
          {/*} endinject */}
          {/*} Plugin js for this page */}
          <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
          <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
          <script
            src="panel/assets/js/jquery.cookie.js"
            type="text/javascript"
          ></script>
          {/*} End plugin js for this page */}
          {/*} inject:js */}
          <script src="panel/assets/js/off-canvas.js"></script>
          <script src="panel/assets/js/hoverable-collapse.js"></script>
          <script src="panel/assets/js/misc.js"></script>
          <script src="panel/assets/js/settings.js"></script>
          <script src="panel/assets/js/todolist.js"></script>
          {/*} endinject */}
          {/*} Custom js for this page */}
          <script src="panel/assets/js/dashboard.js"></script>
          {/*} End custom js for this page */}
        </body>
      </html>
    </>
  );
}

export default Soleproprietorshipregistration1;
