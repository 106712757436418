import React from "react";
//import "../assets/css/demo_1/style.css";
//import "../assets/vendors/mdi/css/materialdesignicons.min.css";
//import "../assets/vendors/flag-icon-css/css/flag-icon.min.css";
//import "../assets/vendors/css/vendor.bundle.base.css";
//import Logo from "../images/logo.png";
//import { Link } from "react-router-dom";


const Sidebar = () => {
  return (
    <>
      <nav className="sidebar sidebar-offcanvas float-start" id="sidebar">
        <ul className="nav ">
          <li className="nav-item nav-profile border-bottom">
            <a href="#" className="nav-link flex-column">
              <div className="nav-profile-image">
                <img src="https://cdn-icons-png.flaticon.com/128/9069/9069049.png" alt="profile" />
              </div>
              <div className="nav-profile-text d-flex ms-0 mb-3 flex-column">
                <span className="font-weight-bold mb-1 mt-2 text-center">
              <span className="highlight" style={{color:'#3533cd'}}>Startup</span> Connect
                </span>
              </div>
            </a>
          </li>
          <li className="pt-2 pb-1">
          {/*}  <span className="nav-item-head">Tables</span> */}
          </li>
          <li className="nav-item">
            <a className="nav-link" href='/client-dashboard-quotations'>
              <i className="mdi mdi-table-large menu-icon"></i>
              <span className="menu-title">Quotations</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href='/client-dashboard-orders'>
              <i className="mdi mdi-cart menu-icon"></i>
              <span className="menu-title">Orders</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href='/client-dashboard-projects'>
              <i className="mdi mdi-airplay menu-icon"></i>
              <span className="menu-title">My Projects</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href='/client-dashboard-payments'>
              <i className="mdi mdi-currency-inr menu-icon"></i>
              <span className="menu-title">Payments</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href='client-dashboard-feedbacks'>
              <i className="mdi mdi-account-multiple-plus menu-icon"></i>
              <span className="menu-title">Feedbacks</span>
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Sidebar;
