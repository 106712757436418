import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';

import '../Resources/dashboard.css';
import Insideteam_navbar from './insideteam_navbar.js';
import Insideteam_sidebar from './insideteam_sidebar.js';
import moment from 'moment/moment';
//import Create_customer from '../create_customer';

import {useRef as useref} from 'react';
import {useReactToPrint} from 'react-to-print';

//EXCEL DOWNLOAD IMPORT
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';


function Insideteam_profile(){


  
    //Report Print Function
    const componentRef = useref();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });

    
 // Function to download the data as an Excel file
 const downloadExcel = () => {
  // Convert JSON data to Excel worksheet
  const worksheet = XLSX.utils.json_to_sheet(data);
  
  // Create a new workbook and add the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  
  // Convert the workbook to a binary array
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  
  // Create a Blob from the binary data and trigger a download
  const excel_data = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(excel_data, "customers_data.xlsx");
};



  //
  
 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");




//Variables
const[data,setData] = useState([]);//SetData Variable

//Error Variable
const[errorvar,seterrorvar] = useState();


//FILTER VARIABLES
const[steam_id,setsteam_id] = useState();
const[team_id,setteam_id] = useState();
const[username,setusername] = useState();
const[name,setname] = useState();
const[email,setemail] = useState();
const[contact,setcontact] = useState();
const[city,setcity] = useState();
const[state,setstate] = useState();
const[country,setcountry] = useState();
const[pincode,setpincode] = useState();
const[date_of_birth,setdate_of_birth] = useState();
const[date_of_joining,setdate_of_joining] = useState();
//total summary
const[orders_count,setorders_count] = useState();
const[payments_count,setpayments_count] = useState();
const[orders,setorders] = useState();
const[payments,setpayments] = useState();
const[coupons_count,setcoupons_count] = useState();
const[most_used_coupon,setmost_used_coupon] = useState();
const[incentives,setincentives] = useState();
const[incentives_received,setincentives_received] = useState();
const[incentives_due,setincentives_due] = useState();
//week summary
const[week_orders_count,seweek_torders_count] = useState();
const[week_payments_count,setweek_payments_count] = useState();
const[week_orders,set_week_orders] = useState();
const[week_payments,setweek_payments] = useState();
const[week_coupons_count,setweek_coupons_count] = useState();
const[week_most_used_coupon,setweek_most_used_coupon] = useState();
const[week_incentives,setweek_incentives] = useState();
const[week_incentives_received,setweek_incentives_received] = useState();
const[week_incentives_due,setweek_incentives_due] = useState();

//today summary
const[today_orders_count,setoday_torders_count] = useState();
const[today_payments_count,settoday_payments_count] = useState();
const[today_orders,set_today_orders] = useState();
const[today_payments,settoday_payments] = useState();
const[today_coupons_count,settoday_coupons_count] = useState();
const[today_most_used_coupon,settoday_most_used_coupon] = useState();
const[today_incentives,settoday_incentives] = useState();
const[today_incentives_received,settoday_incentives_received] = useState();
const[today_incentives_due,settoday_incentives_due] = useState();



useEffect(()=>{
   
  getData();
  
  },[])
 
    
  
//******************* GET SALES TEAM DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))){
  axios.post("bt-inside-salesteam-profile", {
   Log_id:logid,
   Token:token,
   })
 .then((response) => {


  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");


     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  else if(response.data.successvar==1){

   //Setting Data
   setsteam_id(response.data.steam_id);
   setteam_id(response.data.team_id);
   setusername(response.data.username);
   setname(response.data.name);
   setemail(response.data.email);
   setcontact(response.data.contact);
   setcity(response.data.city);
   setstate(response.data.state);
   setcountry(response.data.country);
   setpincode(response.data.pincode);
   setdate_of_birth(response.data.date_of_birth);
   setdate_of_joining(response.data.date_of_joining);
   //Total
   setpayments_count(response.data.payments_count);
   setpayments(response.data.payments);
   setincentives(response.data.incentives);
   setincentives_received(response.data.incentives_received);
   setincentives_due(response.data.incentives_due);
   //This Week
   setweek_payments_count(response.data.week_payments_count);
   setweek_payments(response.data.week_payments);
   setweek_incentives(response.data.week_incentives);
   setweek_incentives_received(response.data.week_incentives_received);
   setweek_incentives_due(response.data.week_incentives_due);
   //Total
   settoday_payments_count(response.data.today_payments_count);
   settoday_payments(response.data.today_payments);
   settoday_incentives(response.data.today_incentives);
   settoday_incentives_received(response.data.today_incentives_received);
   settoday_incentives_due(response.data.today_incentives_due);
  
  }

  

  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* GET CUSTOMERS DATA FUNCTION END  ********************//



return(
    <>
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Business Tools|BookKeeping Solution</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>


    <div class="container-scroller">

      <Insideteam_sidebar />
    <div class="container-fluid page-body-wrapper">




       <Insideteam_navbar />
        {/*} partial */}
        <div class="main-panel">

       
          

          <div class="content-wrapper pb-0">

       
            <div class="page-header flex-wrap">
            <div class="header-left"></div>
              <div class="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div class="d-flex align-items-center">
                  <a href="#">
                    <p class="m-0 pe-3">MY PROFILE</p>
                  </a>
                  <a class="ps-3 me-4" href="#">
                    <p class="m-0"></p>
                  </a>
                </div>
              </div>
            </div>

         

            {/* CUSTOMER DETAILS TABLE START */}
                  
             <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h2 class="card-title" style={{textAlign:"left"}}>My summary with Startup Connect</h2>
                  {/*}  <p class="card-description">payments
                    </p> */}
                    <hr/>
                    <h4 class="card-title" style={{textAlign:"left"}}>Basic Information</h4>
                    <br/>

                    <div class="row" style={{textAlign:"left"}}>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <h6 class="col-sm-5">Profile Id: </h6>
                            <div class="col-sm-7">
                            <p>{steam_id}</p>
                            </div>
                          </div>
                        </div>
                       
                       
                        <div class="col-md-4">
                          <div class="form-group row">
                            <h6 class="col-sm-5">Team Id: </h6>
                            <div class="col-sm-7">
                            <p>{team_id}</p>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group row">
                            <h6 class="col-sm-5">Username: </h6>
                            <div class="col-sm-7">
                            <p>{username}</p>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group row">
                            <h6 class="col-sm-5">Name: </h6>
                            <div class="col-sm-7">
                            <p>{name}</p>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group row">
                            <h6 class="col-sm-5">Contact: </h6>
                            <div class="col-sm-7">
                            <p>{contact}</p>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group row">
                            <h6 class="col-sm-5">Email: </h6>
                            <div class="col-sm-7">
                            <p>{email}</p>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group row">
                            <h6 class="col-sm-5">City: </h6>
                            <div class="col-sm-7">
                            <p>{city}</p>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group row">
                            <h6 class="col-sm-5">State: </h6>
                            <div class="col-sm-7">
                            <p>{state}</p>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group row">
                            <h6 class="col-sm-5">Country: </h6>
                            <div class="col-sm-7">
                            <p>{country}</p>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group row">
                            <h6 class="col-sm-5">Pincode: </h6>
                            <div class="col-sm-7">
                            <p>{pincode}</p>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group row">
                            <h6 class="col-sm-5">Date of Birth: </h6>
                            <div class="col-sm-7">
                            <p>{date_of_birth}</p>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group row">
                            <h6 class="col-sm-5">Date of Joining: </h6>
                            <div class="col-sm-7">
                            <p>{date_of_joining}</p>
                            </div>
                          </div>
                        </div>

                        <hr/>

                        <h4 class="card-title" style={{textAlign:"left"}}>Overall Summary</h4>
                        <br/>

                     {/*}   <div class="col-md-4">
                          <div class="form-group row">
                            <h6 class="col-sm-5">Orders: </h6>
                            <div class="col-sm-7">
                            <p>{orders_count}</p>
                            </div>
                          </div>
                        </div> */}

                        <div class="col-md-4">
                          <div class="form-group row">
                            <h6 class="col-sm-5">Payments: </h6>
                            <div class="col-sm-7">
                            <p>{payments_count}</p>
                            </div>
                          </div>
                        </div>

                     {/*}   <div class="col-md-4">
                          <div class="form-group row">
                            <h6 class="col-sm-5">Coupon Codes: </h6>
                            <div class="col-sm-7">
                            <p>{coupons_count}</p>
                            </div>
                          </div>
                        </div>
                        
                        <div class="col-md-4">
                          <div class="form-group row">
                            <h6 class="col-sm-5">Order Value: </h6>
                            <div class="col-sm-7">
                            <p>{orders}</p>
                            </div>
                          </div>
                        </div> */}

                        <div class="col-md-4">
                          <div class="form-group row">
                            <h6 class="col-sm-5">Payments Value: </h6>
                            <div class="col-sm-7">
                            <p>{payments}</p>
                            </div>
                          </div>
                        </div>

                   {/*}     <div class="col-md-4">
                          <div class="form-group row">
                            <h6 class="col-sm-5">Most used Coupon: </h6>
                            <div class="col-sm-7">
                            <p>{most_used_coupon}</p>
                            </div>
                          </div>
                        </div> */}
                        <br/>

                        <div class="col-md-4">
                          <div class="form-group row">
                            <h6 class="col-sm-5">Total Incentives: </h6>
                            <div class="col-sm-7">
                            <p>{incentives}</p>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group row">
                            <h6 class="col-sm-5">Incentives Received: </h6>
                            <div class="col-sm-7">
                            <p>{incentives_received}</p>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group row">
                            <h6 class="col-sm-5">Incentives Due: </h6>
                            <div class="col-sm-7">
                            <p>{incentives_due}</p>
                            </div>
                          </div>
                        </div>


                        <hr/>

<h4 class="card-title" style={{textAlign:"left"}}>This Week</h4>
<br/>

{/* <div class="col-md-4">
  <div class="form-group row">
    <h6 class="col-sm-5">Orders: </h6>
    <div class="col-sm-7">
    <p>{week_orders_count}</p>
    </div>
  </div>
</div> */}

<div class="col-md-4">
  <div class="form-group row">
    <h6 class="col-sm-5">Payments: </h6>
    <div class="col-sm-7">
    <p>{week_payments_count}</p>
    </div>
  </div>
</div>

{/*<div class="col-md-4">
  <div class="form-group row">
    <h6 class="col-sm-5">Coupon Codes: </h6>
    <div class="col-sm-7">
    <p>{week_coupons_count}</p>
    </div>
  </div>
</div>

<div class="col-md-4">
  <div class="form-group row">
    <h6 class="col-sm-5">Order Value: </h6>
    <div class="col-sm-7">
    <p>{week_orders}</p>
    </div>
  </div>
</div> */}

<div class="col-md-4">
  <div class="form-group row">
    <h6 class="col-sm-5">Payments Value: </h6>
    <div class="col-sm-7">
    <p>{week_payments}</p>
    </div>
  </div>
</div>

{/* <div class="col-md-4">
  <div class="form-group row">
    <h6 class="col-sm-5">Most used Coupon: </h6>
    <div class="col-sm-7">
    <p>{week_most_used_coupon}</p>
    </div>
  </div>
</div> */}
<br/>

<div class="col-md-4">
  <div class="form-group row">
    <h6 class="col-sm-5">Total Incentives: </h6>
    <div class="col-sm-7">
    <p>{week_incentives}</p>
    </div>
  </div>
</div>

<div class="col-md-4">
  <div class="form-group row">
    <h6 class="col-sm-5">Incentives Received: </h6>
    <div class="col-sm-7">
    <p>{week_incentives_received}</p>
    </div>
  </div>
</div>

<div class="col-md-4">
  <div class="form-group row">
    <h6 class="col-sm-5">Incentives Due: </h6>
    <div class="col-sm-7">
    <p>{week_incentives_due}</p>
    </div>
  </div>
</div>



<hr/>

<h4 class="card-title" style={{textAlign:"left"}}>Today</h4>
<br/>

{/* <div class="col-md-4">
  <div class="form-group row">
    <h6 class="col-sm-5">Orders: </h6>
    <div class="col-sm-7">
    <p>{today_orders_count}</p>
    </div>
  </div>
</div> */}

<div class="col-md-4">
  <div class="form-group row">
    <h6 class="col-sm-5">Payments: </h6>
    <div class="col-sm-7">
    <p>{today_payments_count}</p>
    </div>
  </div>
</div>

{/* <div class="col-md-4">
  <div class="form-group row">
    <h6 class="col-sm-5">Coupon Codes: </h6>
    <div class="col-sm-7">
    <p>{today_coupons_count}</p>
    </div>
  </div>
</div>

<div class="col-md-4">
  <div class="form-group row">
    <h6 class="col-sm-5">Order Value: </h6>
    <div class="col-sm-7">
    <p>{today_orders}</p>
    </div>
  </div>
</div> */}

<div class="col-md-4">
  <div class="form-group row">
    <h6 class="col-sm-5">Payments Value: </h6>
    <div class="col-sm-7">
    <p>{today_payments}</p>
    </div>
  </div>
</div>

{/*<div class="col-md-4">
  <div class="form-group row">
    <h6 class="col-sm-5">Most used Coupon: </h6>
    <div class="col-sm-7">
    <p>{today_most_used_coupon}</p>
    </div>
  </div>
</div> */}
<br/>

<div class="col-md-4">
  <div class="form-group row">
    <h6 class="col-sm-5">Total Incentives: </h6>
    <div class="col-sm-7">
    <p>{today_incentives}</p>
    </div>
  </div>
</div>

<div class="col-md-4">
  <div class="form-group row">
    <h6 class="col-sm-5">Incentives Received: </h6>
    <div class="col-sm-7">
    <p>{today_incentives_received}</p>
    </div>
  </div>
</div>

<div class="col-md-4">
  <div class="form-group row">
    <h6 class="col-sm-5">Incentives Due: </h6>
    <div class="col-sm-7">
    <p>{today_incentives_due}</p>
    </div>
  </div>
</div>


                        </div>
                        </div>
                        </div>
                        </div>


               
                          
                      
                            
                    {/* FILTER SECTION END */}


             
          </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
         {/*} <footer class="footer">
            <div class="d-sm-flex justify-content-center justify-content-sm-between">
              <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
</>
);


//}
}
export default Insideteam_profile;