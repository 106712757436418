import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';

import '../Resources/dashboard.css';
import Navbar from '../navbar';
import Sidebar from '../Sidebar';
import Create_employee from './create_employee';
import moment from 'moment/moment';


import {useRef as useref} from 'react';
import {useReactToPrint} from 'react-to-print';

//EXCEL DOWNLOAD IMPORT
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';


function Useremployees(){

 

  
    //Report Print Function
    const componentRef = useref();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });

    
 // Function to download the data as an Excel file
 const downloadExcel = () => {
  // Convert JSON data to Excel worksheet
  const worksheet = XLSX.utils.json_to_sheet(data);
  
  // Create a new workbook and add the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  
  // Convert the workbook to a binary array
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  
  // Create a Blob from the binary data and trigger a download
  const excel_data = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(excel_data, "employees_data.xlsx");
};



  //console.log
  
 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const busyid=sessionStorage.getItem("BusyId00");
 const counterid=sessionStorage.getItem("CounterId00");


{/* EMPLOYEES TABLE COLUMNS MANAGEMENT VARIABLES START */}
const[cd_col1_val,setcd_col1_val] = useState(0);//column1
const[cd_col2_val,setcd_col2_val] = useState(0);//column2
const[cd_col3_val,setcd_col3_val] = useState(0);//column3
const[cd_col4_val,setcd_col4_val] = useState(0);//column4
const[cd_col5_val,setcd_col5_val] = useState(0);//column5
const[cd_col6_val,setcd_col6_val] = useState(0);//column6
const[cd_col7_val,setcd_col7_val] = useState(0);//column7
const[cd_col8_val,setcd_col8_val] = useState(0);//column8
const[cd_col9_val,setcd_col9_val] = useState(0);//column9
const[cd_col10_val,setcd_col10_val] = useState(0);//column10
const[cd_col11_val,setcd_col11_val] = useState(0);//column11
const[cd_col12_val,setcd_col12_val] = useState(0);//column12
const[cd_col13_val,setcd_col13_val] = useState(0);//column13
const[cd_col14_val,setcd_col14_val] = useState(0);//column14
const[cd_col15_val,setcd_col15_val] = useState(0);//column15
const[cd_col16_val,setcd_col16_val] = useState(0);//column16
const[cd_col17_val,setcd_col17_val] = useState(0);//column17
const[cd_col18_val,setcd_col18_val] = useState(0);//column18
const[cd_col19_val,setcd_col19_val] = useState(0);//column19
const[cd_col20_val,setcd_col20_val] = useState(0);//column20
const[cd_col21_val,setcd_col21_val] = useState(0);//column21
const[cd_col22_val,setcd_col22_val] = useState(0);//column22
const[cd_col23_val,setcd_col23_val] = useState(0);//column23
const[cd_col24_val,setcd_col24_val] = useState(0);//column24

{/* CUSTOMERS TABLE COLUMNS MANAGEMENT VARIABLES END */}

 //Checking if Sessions are Set

// alert(logstatus,loguser,logadminid);
 //Sessions Not Set
 {/*if(((logstatus==null)||(logstatus==""))||((logid==null)||(logid==""))||((token==null)||(token==" "))||((busyid==null)||(busyid==" "))||((counterid==null)||(counterid==" "))){
  
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");
   
 
    window.location.replace("/businesstools-login");
   }
  
   //Sessions Set
   else{
   */}

    
      
//Variables
const[banks,setbanks] = useState([]);//SetData Variable
const[data,setData] = useState([]);//SetData Variable

//Error Variable
const[errorvar,seterrorvar] = useState();


//FILTER VARIABLES
const[filter_val,setfilter_val] = useState(0);
const[filter_employee_code,setfilter_employee_code] = useState(); const updatefilter_employee_code = (event) => { setfilter_employee_code(event.target.value);}; const[filter_employee_code_er,setfilter_employee_code_er]=useState();
const[filter_first_name,setfilter_first_name] = useState(); const updatefilter_first_name = (event) => { setfilter_first_name(event.target.value);}; const[filter_first_name_er,setfilter_first_name_er]=useState();
const[filter_last_name,setfilter_last_name] = useState(); const updatefilter_last_name = (event) => { setfilter_last_name(event.target.value);}; const[filter_last_name_er,setfilter_last_name_er]=useState();
const[filter_email,setfilter_email] = useState(); const updatefilter_email = (event) => { setfilter_email(event.target.value);}; const[filter_email_er,setfilter_email_er]=useState();
const[filter_contact,setfilter_contact] = useState(); const updatefilter_contact = (event) => { setfilter_contact(event.target.value);}; const[filter_contact_er,setfilter_contact_er]=useState();
const[filter_type,setfilter_type] = useState(); const updatefilter_type = (event) => { setfilter_type(event.target.value);}; const[filter_type_er,setfilter_type_er]=useState();
const[filter_position,setfilter_position] = useState(); const updatefilter_position = (event) => { setfilter_position(event.target.value);}; const[filter_position_er,setfilter_position_er]=useState();
const[filter_city,setfilter_city] = useState(); const updatefilter_city = (event) => { setfilter_city(event.target.value);}; const[filter_city_er,setfilter_city_er]=useState();
const[filter_state,setfilter_state] = useState(); const updatefilter_state = (event) => { setfilter_state(event.target.value);}; const[filter_state_er,setfilter_state_er]=useState();
const[filter_country,setfilter_country] = useState(); const updatefilter_country = (event) => { setfilter_country(event.target.value);}; const[filter_country_er,setfilter_country_er]=useState();
const[filter_pincode,setfilter_pincode] = useState(); const updatefilter_pincode = (event) => { setfilter_pincode(event.target.value);}; const[filter_pincode_er,setfilter_pincode_er]=useState();
const[filter_gender,setfilter_gender] = useState(); const updatefilter_gender = (event) => { setfilter_gender(event.target.value);}; const[filter_gender_er,setfilter_gender_er]=useState();
const[filter_monthly_salary,setfilter_monthly_salary] = useState(); const updatefilter_monthly_salary = (event) => { setfilter_monthly_salary(event.target.value);}; const[filter_monthly_salary_er,setfilter_monthly_salary_er]=useState();
const[filter_yearly_ctc,setfilter_yearly_ctc] = useState(); const updatefilter_yearly_ctc = (event) => { setfilter_yearly_ctc(event.target.value);}; const[filter_yearly_ctc_er,setfilter_yearly_ctc_er]=useState();
const[filter_account_number,setfilter_account_number] = useState(); const updatefilter_account_number = (event) => { setfilter_account_number(event.target.value);}; const[filter_account_number_er,setfilter_account_number_er]=useState();



//Variables for pagination
const [currentPage, setCurrentPage] = useState(1);
const [pageNumber, setpageNumber] = useState(1);

useEffect(()=>{
   
  getData();
  
  },[])
 
    
  
//******************* GET EMPLOYEES DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))){
 

   const $filter_employee_code = filter_employee_code; const $filter_first_name = filter_first_name; const $filter_last_name = filter_last_name; const $filter_email = filter_email; const $filter_contact = filter_contact; const $filter_type = filter_type;
   const $filter_position = filter_position; const $filter_city = filter_city; const $filter_state = filter_state; const $filter_country = filter_country; const $filter_pincode = filter_pincode; const $filter_gender = filter_gender;
   const $filter_monthly_salary = filter_monthly_salary; const $filter_yearly_ctc = filter_yearly_ctc; const $filter_account_number = filter_account_number;

 axios.post("bt-get-employees", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Sn_start :0,
   Sn_end :0,
   Sn_total :0,
   Sn_limit :0,
   Filter_val : filter_val,
   Filter_employee_code : $filter_employee_code,
   Filter_first_name : $filter_first_name,
   Filter_last_name : $filter_last_name,
   Filter_email : $filter_email,
   Filter_contact : $filter_contact,
   Filter_gender : $filter_gender,
   Filter_position : $filter_position,
   Filter_city : $filter_city,
   Filter_state : $filter_state,
   Filter_country : $filter_country,
   Filter_type : $filter_type,
   Filter_monthly_salary : $filter_monthly_salary,
   Filter_yearly_ctc : $filter_yearly_ctc,
   Filter_account_number : $filter_account_number,
   
   //Code Edits for pagination
   'page' : pageNumber


   })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setbanks(response.data.banks);
   setData(response.data.data.data);
  
  }

  //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){
    setfilter_employee_code_er(response.data.validatorerror.Filter_employee_code);
    setfilter_first_name_er(response.data.validatorerror.Filter_first_name);
    setfilter_last_name_er(response.data.validatorerror.Filter_last_name);
    setfilter_email_er(response.data.validatorerror.Filter_email);
    setfilter_contact_er(response.data.validatorerror.Filter_contact);
    setfilter_position_er(response.data.validatorerror.Filter_position);
    setfilter_gender_er(response.data.validatorerror.Filter_gender);
    setfilter_city_er(response.data.validatorerror.Filter_city);
    setfilter_state_er(response.data.validatorerror.Filter_state);
    setfilter_country_er(response.data.validatorerror.Filter_country);
    setfilter_type_er(response.data.validatorerror.Filter_type);
    setfilter_pincode_er(response.data.validatorerror.Filter_pincode);
    setfilter_monthly_salary_er(response.data.validatorerror.Filter_monthly_salary);
    setfilter_yearly_ctc_er(response.data.validatorerror.Filter_yearly_ctc);
    setfilter_account_number_er(response.data.validatorerror.Filter_account_number);

  }

  //Data Violation
  if(response.data.error==102){ 
  
    setfilter_employee_code_er(response.data.filter_employee_code_er);
    setfilter_first_name_er(response.data.filter_first_name_er);
    setfilter_last_name_er(response.data.filter_last_name_er);
    setfilter_email_er(response.data.filter_email_er);
    setfilter_contact_er(response.data.filter_contact_er);
    setfilter_position_er(response.data.filter_position_er);
    setfilter_gender_er(response.data.filter_gender_er);
    setfilter_city_er(response.data.filter_city_er);
    setfilter_state_er(response.data.filter_state_er);
    setfilter_country_er(response.data.filter_country_er);
    setfilter_type_er(response.data.filter_type_er);
    setfilter_pincode_er(response.data.filter_pincode_er);
    setfilter_monthly_salary_er(response.data.filter_monthly_salary_er);
    setfilter_yearly_ctc_er(response.data.filter_yearly_ctc_er);
    setfilter_account_number_er(response.data.filter_account_number_er);


  }


  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* GET EMPLOYEES DATA FUNCTION END  ********************//


 
 
//******************* GET EMPLOYEES DATA BY ID FUNCTION START  ********************//


const[eemployee_code,seteemployee_code]=useState();const updateeemployee_code = (event) => { seteemployee_code(event.target.value);}; const[eemployee_code_er,seteemployee_code_er]=useState();
const[efirst_name,setefirst_name]=useState();const updateefirst_name = (event) => { setefirst_name(event.target.value);}; const[efirst_name_er,setefirst_name_er]=useState();
const[elast_name,setelast_name]=useState();const updateelast_name = (event) => { setelast_name(event.target.value);}; const[elast_name_er,setelast_name_er]=useState();
const[eemail,seteemail]=useState();const updateeemail = (event) => { seteemail(event.target.value);}; const[eemail_er,seteemail_er]=useState();
const[econtact,setecontact]=useState();const updateecontact = (event) => { setecontact(event.target.value);}; const[econtact_er,setecontact_er]=useState();
const[egender,setegender]=useState();const updateegender = (event) => { setegender(event.target.value);}; const[egender_er,setegender_er]=useState();
const[edate_of_birth,setedate_of_birth]=useState('2100-12-12');const updateedate_of_birth = (event) => { setedate_of_birth(event.target.value);}; const[edate_of_birth_er,setedate_of_birth_er]=useState();
const[edate_of_joining,setedate_of_joining]=useState('2100-12-12');const updateedate_of_joining = (event) => { setedate_of_joining(event.target.value);}; const[edate_of_joining_er,setedate_of_joining_er]=useState();
const[eaddress,seteaddress]=useState();const updateeaddress = (event) => { seteaddress(event.target.value);}; const[eaddress_er,seteaddress_er]=useState();
const[ecity,setecity]=useState();const updateecity = (event) => { setecity(event.target.value);}; const[ecity_er,setecity_er]=useState();
const[ecstate,setecstate]=useState();const updateecstate = (event) => { setecstate(event.target.value);}; const[ecstate_er,setecstate_er]=useState();
const[ecountry,setecountry]=useState();const updateecountry = (event) => { setecountry(event.target.value);}; const[ecountry_er,setecountry_er]=useState();
const[epincode,setepincode]=useState();const updateepincode = (event) => { setepincode(event.target.value);}; const[epincode_er,setepincode_er]=useState();
const[eposition,seteposition]=useState();const updateeposition = (event) => { seteposition(event.target.value);}; const[eposition_er,seteposition_er]=useState();
const[ebasic_salary,setebasic_salary]=useState();const updateebasic_salary = (event) => { setebasic_salary(event.target.value);}; const[ebasic_salary_er,setebasic_salary_er]=useState();
const[eincentives,seteincentives]=useState();const updateeincentives = (event) => { seteincentives(event.target.value);}; const[eincentives_er,seteincentives_er]=useState();
const[eallowances,seteallowances]=useState();const updateeallowances = (event) => { seteallowances(event.target.value);}; const[eallowances_er,seteallowances_er]=useState();
const[eprovident_fund,seteprovident_fund]=useState();const updateeprovident_fund = (event) => { seteprovident_fund(event.target.value);}; const[eprovident_fund_er,seteprovident_fund_er]=useState();
const[esalary_deductions,setesalary_deductions]=useState();const updateesalary_deductions = (event) => { setesalary_deductions(event.target.value);}; const[esalary_deductions_er,setesalary_deductions_er]=useState();
const[emonthly_salary,setemonthly_salary]=useState();const updateemonthly_salary = (event) => { setemonthly_salary(event.target.value);}; const[emonthly_salary_er,setemonthly_salary_er]=useState();
const[eyearly_ctc,seteyearly_ctc]=useState();const updateeyearly_ctc = (event) => { seteyearly_ctc(event.target.value);}; const[eyearly_ctc_er,seteyearly_ctc_er]=useState();
const[epayable_salary,setepayable_salary]=useState();const updateepayable_salary = (event) => { setepayable_salary(event.target.value);}; const[epayable_salary_er,setepayable_salary_er]=useState();
const[emode,setemode]=useState();const updateemode = (event) => { setemode(event.target.value);}; const[emode_er,setemode_er]=useState();
const[ebank_name,setebank_name]=useState();const updateebank_name = (event) => { setebank_name(event.target.value);}; const[ebank_name_er,setebank_name_er]=useState();
const[eifsc_code,seteifsc_code]=useState();const updateeifsc_code = (event) => { seteifsc_code(event.target.value);}; const[eifsc_code_er,seteifsc_code_er]=useState();
const[eaccount_number,seteaccount_number]=useState();const updateeaccount_number = (event) => { seteaccount_number(event.target.value);}; const[eaccount_number_er,seteaccount_number_er]=useState();
const[eemployee_remarks,seteemployee_remarks]=useState();const updateeemployee_remarks = (event) => { seteemployee_remarks(event.target.value);}; const[eemployee_remarks_er,seteemployee_remarks_er]=useState();
const[etype,setetype]=useState();const updateetype = (event) => { setetype(event.target.value);}; const[etype_er,setetype_er]=useState();



//ERROR VARIABLES 
const[eerror_msg,seteerror_msg]=useState();


 
//******************* EDIT EMPLOYEE FUNCTION START ********************//
function editemployee(){

  const editId=sessionStorage.getItem("EditId00");
  //Setting Error Variables
  seteemployee_code_er(" ");setefirst_name_er(" ");setelast_name_er(" ");
  seteemail_er(" ");setecontact_er(" ");setedate_of_birth_er(" ");setegender_er(" ");seteaddress_er(" ");setecity_er(" ");setecstate_er(" ");setecountry_er(" ");setepincode_er(" ");
  setedate_of_joining_er(" ");setebasic_salary_er(" ");seteincentives_er(" ");seteallowances_er(" ");seteprovident_fund_er(" ");setesalary_deductions_er(" ");setemonthly_salary_er(" ");seteyearly_ctc_er(" ");
  setemode_er(" ");setebank_name_er(" ");seteifsc_code_er(" ");seteaccount_number_er(" "); seteposition_er(" ");seteemployee_remarks_er(" ");seteerror_msg(" ");setetype_er(" ");



  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))&&((editId!=null)||(editId!=" "))){
 
 
  axios.post("bt-employee-edit", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Employee_code:eemployee_code,
   First_name:efirst_name,
   Last_name:elast_name,
   Email:eemail,
   Contact:econtact,
   Gender:egender,
   Date_of_birth:moment(edate_of_birth).format('YYYY-MM-DD'),
   Date_of_joining:moment(edate_of_joining).format('YYYY-MM-DD'),
   Address:eaddress,
   City:ecity,
   State:ecstate,
   Country:ecountry,
   Pincode:epincode,
   Basic_salary:ebasic_salary,
   Incentives:eincentives,
   Allowances:eallowances,
   Provident_fund:eprovident_fund,
   Salary_deductions:esalary_deductions,
   Monthly_salary:emonthly_salary,
   Yearly_ctc:eyearly_ctc,
   Mode:emode,
   Bank_name:ebank_name,
  // Full_name_for_bank:full_name_for_bank,
   Ifsc_code:eifsc_code,
   Account_number:eaccount_number,
   Position:eposition,
   Employee_remarks:eemployee_remarks,
   Type:etype,
   Edit_id:editId


  })
  .then((response) => {

    //console.log(response);

  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");
     sessionStorage.removeItem("EditId00");

     window.location.replace("businesstools-login");
  }

   //Validation Errors
 else if(response.data.validatorerror){
    seteemployee_code_er(response.data.validatorerror.Employee_code);
    setefirst_name_er(response.data.validatorerror.First_name);
    setelast_name_er(response.data.validatorerror.Last_name);
    setecontact_er(response.data.validatorerror.Contact);
    seteemail_er(response.data.validatorerror.Email);
    setegender_er(response.data.validatorerror.Gender);
    setedate_of_birth_er(response.data.validatorerror.Date_of_birth);
    seteaddress_er(response.data.validatorerror.Address);
    setecity_er(response.data.validatorerror.City);
    setecstate_er(response.data.validatorerror.State);
    setecountry_er(response.data.validatorerror.Country);
    setepincode_er(response.data.validatorerror.Pincode);
    setedate_of_joining_er(response.data.validatorerror.Date_of_joining);
    setebasic_salary_er(response.data.validatorerror.Basic_salary);
    seteincentives_er(response.data.validatorerror.Incentives);
    seteallowances_er(response.data.validatorerror.Allowances);
    seteprovident_fund_er(response.data.validatorerror.Provident_fund);
    setesalary_deductions_er(response.data.validatorerror.Salary_deductions);
    setemonthly_salary_er(response.data.validatorerror.Monthly_salary);
    seteyearly_ctc_er(response.data.validatorerror.Yearly_ctc);
    setemode_er(response.data.validatorerror.Mode);
    setebank_name_er(response.data.validatorerror.Bank_name);
    seteifsc_code_er(response.data.validatorerror.Ifsc_code);
    seteaccount_number_er(response.data.validatorerror.Account_number);
    seteemployee_remarks_er(response.data.validatorerror.Employee_remarks);
    setetype_er(response.data.validatorerror.Type);
    seteerror_msg("Cannot Update Employee Data !");

}
//Invalid Entries
else if(response.data.error==102){ 
  
    seteemployee_code_er(response.data.employee_code_er);
    setefirst_name_er(response.data.first_name_er);
    setelast_name_er(response.data.last_name_er);
    setecontact_er(response.data.contact_er);
    seteemail_er(response.data.email_er);
    setegender_er(response.data.gender_er);
    setedate_of_birth_er(response.data.date_of_birth_er);
    seteaddress_er(response.data.address_er);
    setecity_er(response.data.city_er);
    setecstate_er(response.data.state_er);
    setecountry_er(response.data.country_er);
    setepincode_er(response.data.pincode_er);
    setedate_of_joining_er(response.data.date_of_joining_er);
    setebasic_salary_er(response.data.basic_salary_er);
    seteincentives_er(response.data.incentives_er);
    seteallowances_er(response.data.allowances_er);
    seteprovident_fund_er(response.data.provident_fund_er);
    setemonthly_salary_er(response.data.monthly_salary_er);
    seteyearly_ctc_er(response.data.yearly_ctc_er);
    setemode_er(response.data.mode_er);
    setebank_name_er(response.data.bank_name_er);
    seteifsc_code_er(response.data.ifsc_code_er);
    seteaccount_number_er(response.data.account_number_er);
    seteemployee_remarks_er(response.data.employee_remarks_er);
    setetype_er(response.data.type_er);
    seteerror_msg("Cannot Update Employee Data !");  
}


  //Invalid Entries
  else if(response.data.error==103){     seteerror_msg(response.data.error_msg);  }

   //Bank Updated Successfully
   else if(response.data.successvar==1){        

    document.getElementById("edit-form").style.display="none";  
    seteemployee_code_er(" ");setefirst_name_er(" ");setelast_name_er(" ");
    seteemail_er(" ");setecontact_er(" ");setedate_of_birth_er(" ");setegender_er(" ");seteaddress_er(" ");setecity_er(" ");setecstate_er(" ");setecountry_er(" ");setepincode_er(" ");
    setedate_of_joining_er(" ");setebasic_salary_er(" ");seteincentives_er(" ");seteallowances_er(" ");seteprovident_fund_er(" ");setesalary_deductions_er(" ");setemonthly_salary_er(" ");seteyearly_ctc_er(" ");
    setemode_er(" ");setebank_name_er(" ");seteifsc_code_er(" ");seteaccount_number_er(" "); seteposition_er(" ");seteemployee_remarks_er(" ");seteerror_msg(" ");setetype_er(" ");
    //
    seteemployee_code(" ");setefirst_name(" ");setelast_name(" ");
    seteemail(" ");setecontact(" ");setedate_of_birth(" ");setegender(" ");seteaddress(" ");setecity(" ");setecstate(" ");setecountry(" ");setepincode(" ");
    setedate_of_joining(" ");setebasic_salary(" ");seteincentives(" ");seteallowances(" ");seteprovident_fund(" ");setesalary_deductions(" ");setemonthly_salary(" ");seteyearly_ctc(" ");
    setemode(" ");setebank_name(" ");seteifsc_code(" ");seteaccount_number(" "); seteposition(" ");seteemployee_remarks(" ");seteerror_msg(" ");setetype(" ");
    
    
    document.getElementById("edit-success-card").style.display="block";  }
   //Cannot AUpdate Bank
   else{  seteerror_msg("Cannot Edit Employee !"); }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* EDIT EMPLOYEE FUNCTION END ********************//


 
//******************* DELETE EMPLOYEE FUNCTION START ********************//
function deleteemployee(){

  const editId=sessionStorage.getItem("DEditId00");

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))&&((editId!=null)||(editId!=" "))){
 
 axios.post("bt-employee-delete", {
   Busy_id:busyid,
   Counter_id:counterid,
   Log_id:logid,
   Token:token,
   Edit_id:editId,
 })
 .then((response) => {

  //console.log(response);

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");
     sessionStorage.removeItem("DEditId00");

     window.location.replace("businesstools-login");
  }

  //Counter Deleted Successfully
   if(response.data.successvar==1){        

      document.getElementById("delete-card").style.display="none";
      document.getElementById("delete-success-card").style.display="block";  
      sessionStorage.removeItem("DEditId");

  }
  
      //Cannot Delete Counter
    else{  
    document.getElementById("delete-card").style.display="none";
    document.getElementById("delete-error-card").style.display="block";   }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");
   sessionStorage.removeItem("DEditId00");


   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* DELETE EMPLOYEE FUNCTION END ********************//




return(
    <>
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Business Tools|BookKeeping Solution</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>


    <div class="container-scroller">

      <Sidebar />
    <div class="container-fluid page-body-wrapper">




       <Navbar />
        {/*} partial */}
        <div class="main-panel">

          {/* ADD EMPLOYEE SUCCESS CARD START */}
          <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">New Employee Added Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <a href="businesstools-user-employees"><button class="mt-3 btn btn-outline-danger">Close</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* ADD EMPLOYEE SUCCESS CARD END */}

                  {/* ADD EMPLOYEE EDIT SUCCESS CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="edit-success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Employee Details Updated Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("edit-success-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* ADD EMPLOYEE EDIT SUCCESS CARD END */}
               {/* ADD EMPLOYEE EDIT ERROR CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="edit-error-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-danger">Cannot Fetch Data !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("edit-error-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* ADD EMPLOYEE EDIT ERROR CARD END */}

                  {/* DELETE CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-danger">Are you sure you want to delete this employee, you will loss all employee data ?</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> This action cannot be reversed. You will lose all your data related to this transaction if you wish to continue this action.</p>
                      <button class="mt-3 btn btn-outline-success" onClick={deleteemployee}>Yes, I Know! Delete Employee.</button>
                      <button class="mt-3 btn btn-outline-danger" style={{marginLeft:"1rem"}} onClick={()=>{  
                      //Cancel Delete and remove Edit_id
                      sessionStorage.removeItem("DEditId00");    document.getElementById('delete-card').style.display = 'none'; }}>No, Don't Delete Employee.</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* DELETE CARD END */}
              {/* DELETE SUCCESS CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Employee Deleted Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("delete-success-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* DELETE SUCCESS CARD END */}
               {/*  DELETE ERROR CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-error-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-danger">Cannot Delete Employee !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("delete-error-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* DELETE ERROR CARD END */}


          <div class="content-wrapper pb-0">

         {/* CREATE EMPLOYEE FORM START */}
          <div class="row">
              <div class="col-12 grid-margin create-form" id="create-form">
              <Create_employee /> 
              </div>
          </div>
          {/* CREATE EMPLOYEE FORM END */}
          
            <div class="page-header flex-wrap">
            <div class="header-left"></div>
              <div class="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div class="d-flex align-items-center">
                  <a href="#">
                    <p class="m-0 pe-3">Employees</p>
                  </a>
                  <a class="ps-3 me-4" href="#">
                  {/*}  <p class="m-0">ADE-00234</p> */}
                  </a>
                </div>
                <button type="button" class="btn btn-primary mt-2 mt-sm-0 btn-icon-text" onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
                  <i class="mdi mdi-plus-circle"></i> Add New Employee </button>
              </div>
            </div>

    

             {/* EDIT CUSTOMER FORM START */}
          <div class="row">
              <div class="col-12 grid-margin create-form" id="edit-form" style={{display:"none"}}>
              <div class="card" style={{textAlign:"left"}}>
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ 
                        //Removing Sessions and Closing Add Bank Edit
                        document.getElementById("edit-form").style.display="none";  
                        seteemployee_code_er(" ");setefirst_name_er(" ");setelast_name_er(" ");
                        seteemail_er(" ");setecontact_er(" ");setedate_of_birth_er(" ");setegender_er(" ");seteaddress_er(" ");setecity_er(" ");setecstate_er(" ");setecountry_er(" ");setepincode_er(" ");
                        setedate_of_joining_er(" ");setebasic_salary_er(" ");seteincentives_er(" ");seteallowances_er(" ");seteprovident_fund_er(" ");setesalary_deductions_er(" ");setemonthly_salary_er(" ");seteyearly_ctc_er(" ");
                        setemode_er(" ");setebank_name_er(" ");seteifsc_code_er(" ");seteaccount_number_er(" "); seteposition_er(" ");seteemployee_remarks_er(" ");seteerror_msg(" ");setetype_er(" ");
                        //
                        seteemployee_code(" ");setefirst_name(" ");setelast_name(" ");
                        seteemail(" ");setecontact(" ");setedate_of_birth(" ");setegender(" ");seteaddress(" ");setecity(" ");setecstate(" ");setecountry(" ");setepincode(" ");
                        setedate_of_joining(" ");setebasic_salary(" ");seteincentives(" ");seteallowances(" ");seteprovident_fund(" ");setesalary_deductions(" ");setemonthly_salary(" ");seteyearly_ctc(" ");
                        setemode(" ");setebank_name(" ");seteifsc_code(" ");seteaccount_number(" "); seteposition(" ");seteemployee_remarks(" ");seteerror_msg(" ");setetype(" ");
    
                        document.getElementById("edit-form").style.display="none";
                        sessionStorage.removeItem("EditId00");
                        
                         }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                  <div class="card-body">
                    <h4 class="card-title">Edit Employee</h4>
                    <div class="form-sample">
                      <p class="card-description">edit employee details</p>
                      <code>{eerror_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Employee_code</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="EMP0001" id="eemployee_code" value={eemployee_code} onChange={updateeemployee_code} />
                              <code>{eemployee_code_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="Type" id="etype" value={etype} onChange={updateetype} />
                            <code>{etype_er}</code>
                            </div>
                          </div>
                        </div>   
                        </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">First Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="First Name" id="efirst_name" value={efirst_name} onChange={updateefirst_name} />
                              <code>{efirst_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Last Name</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="Last Name" id="elast_name" value={elast_name} onChange={updateelast_name}  />
                            <code>{elast_name_er}</code>
                            </div>
                          </div>
                        </div>   
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Email</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="myemail@gmail.com"  id="eemail" value={eemail} onChange={updateeemail} />
                              <code>{eemail_er}</code> 
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Contact</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="91 9000000000"  id="econtact" value={econtact} onChange={updateecontact} />
                              <code>{econtact_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Gender</label>
                            <div class="col-sm-9">
                              <select class="form-control" style={{padding:"1rem"}}  id="egender" value={egender} onChange={updateegender}>
                              <option value=" ">select gender</option>
                              <option value="male">male</option>
                              <option value="female">female</option>
                              <option value="undisclosed">undisclosed</option>
                              </select>
                              <code>{egender_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date of Birth</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control"  id="date_of_birth" value={edate_of_birth} onChange={updateedate_of_birth} />
                              <code>{edate_of_birth_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="form-group">
                        <label for="exampleTextarea1">Address</label>
                        <textarea  class="form-control" rows="2" placeholder="My address" id="eaddress" value={eaddress} onChange={updateeaddress} />
                        <code>{eaddress_er}</code>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">City</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Mumbai" id="ecity" value={ecity} onChange={updateecity} />
                              <code>{ecity_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">State</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Maharashtra" id="ecstate" value={ecstate} onChange={updateecstate} />
                              <code>{ecstate_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Country</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="India" id="ecountry" value={ecountry} onChange={updateecountry} />
                              <code>{ecountry_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Pincode</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Madhya Pradesh" id="epincode" value={epincode} onChange={updateepincode} />
                              <code>{epincode_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <hr/>
                      <h4>Salary Details</h4>
                      <div class="row mt-3">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Position</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Branch Head" id="eposition" value={eposition} onChange={updateeposition} />
                              <code>{eposition_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Mode</label>
                            <div class="col-sm-9">
                            <select class="form-control" style={{padding:"1rem"}} id="emode" value={emode} onChange={updateemode} >
                            <option value=" ">select mode</option>
                            <option value="cash">cash</option>
                            <option value="bank">bank</option>
                            </select>
                            <code>{emode_er}</code>
                            </div>
                          </div>
                        </div>                    
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date of Joining</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" id="edate_of_joining" value={edate_of_joining} onChange={updateedate_of_joining} />
                              <code>{edate_of_joining_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Basic Salary</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="10000.00" id="ebasic_salary" value={ebasic_salary} onChange={updateebasic_salary} />
                            <code>{ebasic_salary_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Incentives</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="10000.00" id="eincentives" value={eincentives} onChange={updateeincentives} />
                              <code>{eincentives_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Allowances</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="5000.00" id="eallowances" value={eallowances} onChange={updateeallowances} />
                            <code>{eallowances_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Provident Fund</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="500.00" id="eprovident_fund" value={eprovident_fund} onChange={updateeprovident_fund} />
                              <code>{eprovident_fund_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Salary Deductions</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="500.00" id="esalary_deductions" value={esalary_deductions} onChange={updateesalary_deductions} />
                            <code>{esalary_deductions_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Monthly Salary</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="1000.00" id="emonthly_salary" value={emonthly_salary} onChange={updateemonthly_salary} />
                              <code>{emonthly_salary_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Yearly CTC</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="1000.00" id="eyearly_ctc" value={eyearly_ctc} onChange={updateeyearly_ctc} />
                            <code>{eyearly_ctc_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <hr/>
                      <h4>Banking Details</h4>
                      <div class="row mt-3">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Bank Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="SBI Bank Cherital" id="ebank_name" value={ebank_name} onChange={updateebank_name} />
                              <code>{ebank_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">IFSC code</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="SBI9900000" id="eifsc_code" value={eifsc_code} onChange={updateeifsc_code} />
                            <code>{eifsc_code_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row mt-3">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Account Number</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="1200000000000" id="eaccount_number" value={eaccount_number} onChange={updateeaccount_number} />
                              <code>{eaccount_number_er}</code>
                            </div>
                          </div>
                        </div>
                     </div>

                   
                      <div class="form-group">
                        <label for="exampleTextarea1">Remarks</label>
                        <textarea  class="form-control" rows="2" placeholder="Add your Remarks here" id="employee_remarks" value={eemployee_remarks} onChange={updateeemployee_remarks} />
                        <code>{eemployee_remarks_er}</code>
                      </div>
  
                        <button class="btn btn-primary" style={{fontWeight:"bold"}} onClick={editemployee}>Edit Employee</button>
                      
                   
                    </div>
                  </div>
                </div>
              </div>
          </div>
          {/* EDIT EMPLOYEE FORM END */}

            {/* EMPLOYEE DETAILS TABLE START */}
                  
             <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Employees</h4>
                    <p class="card-description">employee details
                    </p>
                    <code>{eerror_msg}</code>


                            {/* FILTER SECTION START */}
                            <hr/>
                            <div id="filter-top-card" class="row col-12" style={{textAlign:"left",display:"none"}}>
                            <div class="d-flex justify-content-between">
                            <div>
                            <h4 style={{padding:'1rem'}}>Filters</h4>
                           </div>
                           <div>
                    
                           <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("filter-top-card").style.display="none"; }}>
                           <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                           </button>
                           </div>
                           </div>
        
                        <div class="row">
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Employee Code</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="121" id="filter_employee_code" value={filter_employee_code} onChange={updatefilter_employee_code}  />
                              <code>{filter_employee_code_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">First Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Rakesh" value={filter_first_name} onChange={updatefilter_first_name}  />
                              <code>{filter_first_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Last Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Sharma" value={filter_last_name} onChange={updatefilter_last_name}  />
                              <code>{filter_last_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Email</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="rakeshsharma123@gmail.com"  value={filter_email} onChange={updatefilter_email}  />
                              <code>{filter_email_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Contact</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="9414444410"value={filter_contact} onChange={updatefilter_contact}  />
                              <code>{filter_contact_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Gender</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="male"  value={filter_gender} onChange={updatefilter_gender}  />
                              <code>{filter_gender_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Position</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Head of Sales" value={filter_position} onChange={updatefilter_position}  />
                              <code>{filter_position_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">City</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Ahmedabad" value={filter_city} onChange={updatefilter_city}  />
                              <code>{filter_city_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">State</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Gujarat"  value={filter_state} onChange={updatefilter_state}  />
                              <code>{filter_state_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Country</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="India" value={filter_country} onChange={updatefilter_country}  />
                              <code>{filter_country_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Pincode</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="470001" value={filter_pincode} onChange={updatefilter_pincode}  />
                              <code>{filter_pincode_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Offline"  value={filter_type} onChange={updatefilter_type}  />
                              <code>{filter_type_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Monthly Salary</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="25000.00"  value={filter_monthly_salary} onChange={updatefilter_monthly_salary}  />
                              <code>{filter_monthly_salary_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Yearly CTC</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="300000.00"  value={filter_yearly_ctc} onChange={updatefilter_yearly_ctc}  />
                              <code>{filter_yearly_ctc_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Account Number</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="1400000000000000"  value={filter_account_number} onChange={updatefilter_account_number}  />
                              <code>{filter_account_number_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                         <button class="btn btn-primary" onClick={()=>{ setfilter_val(1); getData(); }}>Apply Filters</button>
                         <button class="btn btn-danger" style={{marginLeft:"1rem"}} onClick={()=>{ setfilter_val(0); getData(); }}>Remove Filters</button>
                        </div>
                        <br/><br />
                      </div>
                            <hr/>                      
                            </div>
                            
                            {/* FILTER SECTION END */}


                  <div class="col-12" style={{marginBottom:"2rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                        {/* COLUMNS MANAGEMENT DROPDOWN START */}
                       
                          <div class="dropdown">
                            <button class="btn btn-warning" onClick={()=>{ document.getElementById("filter-top-card").style.display="block"; }}><i class="mdi mdi-filter-outline"></i></button>

                            <button class="btn btn-primary dropdown-toggle" style={{marginLeft:"1rem"}} type="button" id="dropdownMenuIconButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="mdi mdi-table-large"></i>
                            </button>     

                            <div class="dropdown-menu" aria-labelledby="dropdownMenuIconButton1">
                              <h6 class="dropdown-header">Columns</h6>
                              <hr/>
                              
                              {/* Column 1 */}
                              <li><div class="d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Sn</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c1_yes" onClick={()=>{setcd_col1_val(1); document.getElementById("cd_c1_yes").style.display="none"; document.getElementById("cd_c1_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c1_no" style={{ display:"none"}} onClick={()=>{ setcd_col1_val(0); document.getElementById("cd_c1_yes").style.display="block"; document.getElementById("cd_c1_no").style.display="none"; }}></i>
                              </div></div></li> 
                               {/* Column 2 */}
                               <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Employee Code</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c2_yes"  onClick={()=>{setcd_col2_val(1); document.getElementById("cd_c2_yes").style.display="none"; document.getElementById("cd_c2_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c2_no" style={{ display:"none"}} onClick={()=>{ setcd_col2_val(0); document.getElementById("cd_c2_yes").style.display="block"; document.getElementById("cd_c2_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 3 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">First Name</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c3_yes"  onClick={()=>{setcd_col3_val(1); document.getElementById("cd_c3_yes").style.display="none"; document.getElementById("cd_c3_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c3_no" style={{ display:"none"}} onClick={()=>{ setcd_col3_val(0); document.getElementById("cd_c3_yes").style.display="block"; document.getElementById("cd_c3_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 4 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Last Name</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c4_yes"  onClick={()=>{setcd_col4_val(1); document.getElementById("cd_c4_yes").style.display="none"; document.getElementById("cd_c4_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c4_no" style={{ display:"none"}} onClick={()=>{ setcd_col4_val(0); document.getElementById("cd_c4_yes").style.display="block"; document.getElementById("cd_c4_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 5 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Email</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c5_yes"  onClick={()=>{setcd_col5_val(1); document.getElementById("cd_c5_yes").style.display="none"; document.getElementById("cd_c5_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c5_no" style={{ display:"none"}} onClick={()=>{ setcd_col5_val(0); document.getElementById("cd_c5_yes").style.display="block"; document.getElementById("cd_c5_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 6 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Contact</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c6_yes"  onClick={()=>{setcd_col6_val(1); document.getElementById("cd_c6_yes").style.display="none"; document.getElementById("cd_c6_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c6_no" style={{ display:"none"}} onClick={()=>{ setcd_col6_val(0); document.getElementById("cd_c6_yes").style.display="block"; document.getElementById("cd_c6_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 7 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Address</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c7_yes" onClick={()=>{setcd_col7_val(1); document.getElementById("cd_c7_yes").style.display="none"; document.getElementById("cd_c7_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c7_no" style={{ display:"none"}} onClick={()=>{ setcd_col7_val(0); document.getElementById("cd_c7_yes").style.display="block"; document.getElementById("cd_c7_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 8 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">City</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c8_yes" onClick={()=>{setcd_col8_val(1); document.getElementById("cd_c8_yes").style.display="none"; document.getElementById("cd_c8_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c8_no" style={{ display:"none"}} onClick={()=>{ setcd_col8_val(0); document.getElementById("cd_c8_yes").style.display="block"; document.getElementById("cd_c8_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 9 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">State</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c9_yes" onClick={()=>{setcd_col9_val(1); document.getElementById("cd_c9_yes").style.display="none"; document.getElementById("cd_c9_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c9_no" style={{ display:"none"}} onClick={()=>{ setcd_col9_val(0); document.getElementById("cd_c9_yes").style.display="block"; document.getElementById("cd_c9_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 10 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Country</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c10_yes" onClick={()=>{setcd_col10_val(1); document.getElementById("cd_c10_yes").style.display="none"; document.getElementById("cd_c10_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c10_no" style={{ display:"none"}} onClick={()=>{ setcd_col10_val(0); document.getElementById("cd_c10_yes").style.display="block"; document.getElementById("cd_c10_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 11 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Pincode</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c11_yes" onClick={()=>{setcd_col11_val(1); document.getElementById("cd_c11_yes").style.display="none"; document.getElementById("cd_c11_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c11_no" style={{ display:"none"}} onClick={()=>{ setcd_col11_val(0); document.getElementById("cd_c11_yes").style.display="block"; document.getElementById("cd_c11_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 12 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Type</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c12_yes" onClick={()=>{setcd_col12_val(1); document.getElementById("cd_c12_yes").style.display="none"; document.getElementById("cd_c12_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c12_no" style={{ display:"none"}} onClick={()=>{ setcd_col12_val(0); document.getElementById("cd_c12_yes").style.display="block"; document.getElementById("cd_c12_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 13 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Gender</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c13_yes" onClick={()=>{setcd_col13_val(1); document.getElementById("cd_c13_yes").style.display="none"; document.getElementById("cd_c13_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c13_no" style={{ display:"none"}} onClick={()=>{ setcd_col13_val(0); document.getElementById("cd_c13_yes").style.display="block"; document.getElementById("cd_c13_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 14 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Date of Birth
                              </div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c14_yes" onClick={()=>{setcd_col14_val(1); document.getElementById("cd_c14_yes").style.display="none"; document.getElementById("cd_c14_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c14_no" style={{ display:"none"}} onClick={()=>{ setcd_col14_val(0); document.getElementById("cd_c14_yes").style.display="block"; document.getElementById("cd_c14_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 15 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Date of Joining
                              </div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c15_yes" onClick={()=>{setcd_col15_val(1); document.getElementById("cd_c15_yes").style.display="none"; document.getElementById("cd_c15_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c15_no" style={{ display:"none"}} onClick={()=>{ setcd_col15_val(0); document.getElementById("cd_c15_yes").style.display="block"; document.getElementById("cd_c15_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 16 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Position
                              </div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c16_yes" onClick={()=>{setcd_col16_val(1); document.getElementById("cd_c16_yes").style.display="none"; document.getElementById("cd_c16_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c16_no" style={{ display:"none"}} onClick={()=>{ setcd_col16_val(0); document.getElementById("cd_c16_yes").style.display="block"; document.getElementById("cd_c16_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 17 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Monthly Salary
                              </div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c17_yes" onClick={()=>{setcd_col17_val(1); document.getElementById("cd_c17_yes").style.display="none"; document.getElementById("cd_c17_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c17_no" style={{ display:"none"}} onClick={()=>{ setcd_col17_val(0); document.getElementById("cd_c17_yes").style.display="block"; document.getElementById("cd_c17_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 18 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Yearly CTC
                              </div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c18_yes" onClick={()=>{setcd_col18_val(1); document.getElementById("cd_c18_yes").style.display="none"; document.getElementById("cd_c18_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c18_no" style={{ display:"none"}} onClick={()=>{ setcd_col18_val(0); document.getElementById("cd_c18_yes").style.display="block"; document.getElementById("cd_c18_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 19 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Bank Name
                              </div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c19_yes" onClick={()=>{setcd_col19_val(1); document.getElementById("cd_c19_yes").style.display="none"; document.getElementById("cd_c19_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c19_no" style={{ display:"none"}} onClick={()=>{ setcd_col19_val(0); document.getElementById("cd_c19_yes").style.display="block"; document.getElementById("cd_c19_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 20 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Account Number
                              </div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c20_yes" onClick={()=>{setcd_col20_val(1); document.getElementById("cd_c20_yes").style.display="none"; document.getElementById("cd_c20_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c20_no" style={{ display:"none"}} onClick={()=>{ setcd_col20_val(0); document.getElementById("cd_c20_yes").style.display="block"; document.getElementById("cd_c20_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 21 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Updated By
                              </div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c21_yes" onClick={()=>{setcd_col21_val(1); document.getElementById("cd_c21_yes").style.display="none"; document.getElementById("cd_c21_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c21_no" style={{ display:"none"}} onClick={()=>{ setcd_col21_val(0); document.getElementById("cd_c21_yes").style.display="block"; document.getElementById("cd_c21_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 22 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Updated At
                              </div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c22_yes" onClick={()=>{setcd_col22_val(1); document.getElementById("cd_c22_yes").style.display="none"; document.getElementById("cd_c22_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c22_no" style={{ display:"none"}} onClick={()=>{ setcd_col22_val(0); document.getElementById("cd_c22_yes").style.display="block"; document.getElementById("cd_c22_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 23 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10 text-warning">Edit
                              </div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c23_yes" onClick={()=>{setcd_col23_val(1); document.getElementById("cd_c23_yes").style.display="none"; document.getElementById("cd_c23_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c23_no" style={{ display:"none"}} onClick={()=>{ setcd_col23_val(0); document.getElementById("cd_c23_yes").style.display="block"; document.getElementById("cd_c23_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 24 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10 text-danger">Delete
                              </div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c24_yes" onClick={()=>{setcd_col24_val(1); document.getElementById("cd_c24_yes").style.display="none"; document.getElementById("cd_c24_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c24_no" style={{ display:"none"}} onClick={()=>{ setcd_col24_val(0); document.getElementById("cd_c24_yes").style.display="block"; document.getElementById("cd_c24_no").style.display="none"; }}></i>
                              </div></div></li> 


                            {/*}  <div class="dropdown-divider"></div>
                              <button class="btn btn-danger">Close</button>*/}
                            </div> 
                          </div>
                          {/* COLUMNS MANAGEMENT DROPDOWN END */}

                          </div>
                          </div>


                    <div class="table-responsive"  id="reports" ref={componentRef}>
                      <table class="table table-hover">
                        <thead>
                          <tr >
                            <th  style={{fontWeight:"bold"}} id="cd_c1" hidden={cd_col1_val==1}>Sn</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c2" hidden={cd_col2_val==1}>Employee Code</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c3" hidden={cd_col3_val==1}>First Name</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c4" hidden={cd_col4_val==1}>Last Name</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c5" hidden={cd_col5_val==1}>Email</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c6" hidden={cd_col6_val==1}>Contact</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c7" hidden={cd_col7_val==1}>Address</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c8" hidden={cd_col8_val==1}>City</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c9" hidden={cd_col9_val==1}>State</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c10" hidden={cd_col10_val==1}>Country</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c11" hidden={cd_col11_val==1}>Pincode</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c12" hidden={cd_col12_val==1}>Type</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c13" hidden={cd_col13_val==1}>Gender</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c14" hidden={cd_col14_val==1}>Date of Birth</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c15" hidden={cd_col15_val==1}>Date of Joining</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c16" hidden={cd_col16_val==1}>Position</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c17" hidden={cd_col17_val==1}>Monthly Salary</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c18" hidden={cd_col18_val==1}>Yearly CTC</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c19" hidden={cd_col19_val==1}>Bank Name</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c20" hidden={cd_col20_val==1}>Account Number</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c21" hidden={cd_col21_val==1}>Updated By</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c22" hidden={cd_col22_val==1}>Updated At</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c23" hidden={cd_col23_val==1}></th>
                            <th  style={{fontWeight:"bold"}} id="cd_c24" hidden={cd_col24_val==1}></th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                       data.map((curElm,ind)=>{

                         return(
                         <>
                       
                        <tr key={curElm.Sn}>
                            <td id="cd_co1"  hidden={cd_col1_val==1}>{curElm.Sn}</td>
                            <td id="cd_co2"  hidden={cd_col2_val==1}>{curElm.Employee_code}</td>
                            <td id="cd_co3"  hidden={cd_col3_val==1}>{curElm.First_name}</td>
                            <td id="cd_co4"  hidden={cd_col4_val==1}>{curElm.Last_name}</td>
                            <td id="cd_co5"  hidden={cd_col5_val==1}>{curElm.Email}</td>
                            <td id="cd_co6"  hidden={cd_col6_val==1}>{curElm.Contact}</td>
                            <td id="cd_co7"  hidden={cd_col7_val==1}>{curElm.Address}</td>
                            <td id="cd_co8"  hidden={cd_col8_val==1}>{curElm.City}</td>
                            <td id="cd_co9"  hidden={cd_col9_val==1}>{curElm.State}</td>
                            <td id="cd_co10"  hidden={cd_col10_val==1}>{curElm.Country}</td>
                            <td id="cd_co11"  hidden={cd_col11_val==1}>{curElm.Pincode}</td>
                            <td id="cd_co12"  hidden={cd_col12_val==1}>{curElm.Type}</td>
                            <td id="cd_co13"  hidden={cd_col13_val==1}>{curElm.Gender}</td>
                            <td id="cd_co14"  hidden={cd_col14_val==1}>{curElm.Date_of_birth}</td>
                            <td id="cd_co15"  hidden={cd_col15_val==1}>{curElm.Date_of_joining}</td>
                            <td id="cd_co16"  hidden={cd_col16_val==1}>{curElm.Position}</td>
                            <td id="cd_co17"  hidden={cd_col17_val==1}>{curElm.Monthly_salary}</td>
                            <td id="cd_co18"  hidden={cd_col18_val==1}>{curElm.Yearly_ctc}</td>
                            <td id="cd_co19"  hidden={cd_col19_val==1}>{curElm.Bank_name}</td>
                            <td id="cd_co20"  hidden={cd_col20_val==1}>{curElm.Account_number}</td>
                            <td id="cd_co21"  hidden={cd_col21_val==1}>{curElm.Updated_by}</td>
                            <td id="cd_co22"  hidden={cd_col22_val==1}>{curElm.Date_updated} ({curElm.Time_updated})</td>

                            <td id="cd_co23"  hidden={cd_col23_val==1}><i class="mdi mdi-pencil-box text-warning" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                            //Setting Edit Id and Redirect to Edit
                            sessionStorage.setItem("EditId00",curElm.Id);
                            seteemployee_code(curElm.Employee_code);
                            setefirst_name(curElm.First_name);
                            setelast_name(curElm.Last_name);
                            setetype(curElm.Type);
                            setegender(curElm.Gender);
                            seteposition(curElm.Position);
                            setecontact(curElm.Contact);
                            seteemail(curElm.Email);
                            seteaddress(curElm.Address);
                            setecity(curElm.City);
                            setecstate(curElm.State);
                            setecountry(curElm.Country);
                            setepincode(curElm.Pincode);
                            setedate_of_birth(curElm.Date_of_birth);
                            setedate_of_joining(curElm.Date_of_joining);
                            setebasic_salary(curElm.Basic_salary);
                            seteincentives(curElm.Incentives);
                            seteallowances(curElm.Allowances);
                            seteprovident_fund(curElm.Provident_fund);
                            setesalary_deductions(curElm.Salary_deductions);
                            setemonthly_salary(curElm.Monthly_salary);
                            seteyearly_ctc(curElm.Yearly_ctc);
                            setemode(curElm.Mode);
                            setebank_name(curElm.Bank_name);
                            seteifsc_code(curElm.Ifsc_code);
                            seteaccount_number(curElm.Account_number);
                            seteemployee_remarks(curElm.Employee_remarks);

                            document.getElementById('edit-form').style.display = 'block'; }}></i></td>
                            <td id="cd_co24"  hidden={cd_col24_val==1}><i class="mdi mdi-delete-forever text-danger" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                            //Setting Edit Id for Delete
                            sessionStorage.setItem("DEditId00",curElm.Id);  document.getElementById('delete-card').style.display = 'block'; }}></i></td>
                          </tr>
                          </>
                         )
                         }
					   
	                  		 )
                         }
                       
                         
                        </tbody>
                      </table>
                    </div>
                        {/* PAGINATION SECTION START */}
                        <div>
                       <div class="col-12" style={{marginBottom:"2rem",marginTop:"1rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                       
                          <div class="dropdown">
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber-1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-left-drop-circle text-success"></i></button>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber+1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-right-drop-circle text-success"></i></button>
                          </div>
                          </div>
                          </div>
                    </div>
                    {/* PAGINATION SECTION END */}

                    <button class="btn btn-light" onClick={handlePrint}><i class="mdi mdi-cloud-print text-dark"></i></button>
                    <button class="btn btn-light" onClick={downloadExcel}><i class="mdi mdi-file-excel text-dark"></i></button>

                   
                   
                  </div>
                </div>
              </div>
              {/* CUSTOMER DETAILS TABLE END */}

          
          </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
         {/*} <footer class="footer">
            <div class="d-sm-flex justify-content-center justify-content-sm-between">
              <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
</>
);


//}
}
export default Useremployees;