import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './Resources/homebtcss.css';
import './Resources/home2css.css';
import './Resources/serviceshomecss.css';
import './Resources/responsiveness.css';

import Navbar3 from './Supportingfiles/navbar3';
import Footer3 from './Supportingfiles/footer3';
import Serviceschoice from './serviceschoice';


//import './test123.css';

function Serviceshome(){


      return(
      <>


<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />

    <meta name="theme-color" content="#000000" />
    <meta
      name="description"
      content="Get your startup services relating to website development, consultancy, taxation, business registration, software services, intern hiring, social media management, digital marketing and much more.  "></meta>
    
   {/* <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />*/}
        <link rel="icon" href="./images/startupconnect_icon.ico" />
    <link rel="apple-touch-icon" href="./images/startupconnect_icon.png" />

      {/* plugins:css */}
      <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/* endinject */}
    {/* Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/* End plugin css for this page */}
    {/* inject:css */}
    {/* endinject */}
    {/* Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/* End layout styles */}
    <link rel="shortcut icon" href="./images/startupconnect_icon.png" />


    {/*} Bootstrap CSS */}
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous" />
 

    <title>Startup Connect | Business Tools, Services, Networks, Hiring</title>
  </head>
  <body>


  {/* Whatsapp Chat Icon Start */}
  <div class="wtp-chat-container" /*style={{position:"fixed",marginTop:"92vh", marginLeft:"94vw"}}*/>
    <a aria-label="Chat on WhatsApp" href="https://wa.me/918480002413?text=I%20want%20to%20know%20more%20about%20services%20offered%20by%20the%20Startup%20Connect">   
    <img class="wtp-chat-icon" src="https://cdn-icons-png.flaticon.com/128/3938/3938058.png" /*style={{height:"3rem", width:"3rem"}}*/></img>
  </a>
  </div>
  {/* Whatsapp Chat Icon End */}

  <div class="home2-container">
    
  <Navbar3/>
              
   {/* HEADER SECTION START */}
   <section /*class="sv-header-section" */>
    <div class="sv-header-container1">
    <div class="sv-header-left home-header-pd">
        <h1 style={{color:"#004463"}}>YOUR ONE STOP SOLUTION FOR ALL YOUR SERVICES NEEDS</h1>
        <p>best class services delivered by professionals in your budget. From business setup, compliantce to monitoring, marketing, technology, growth, strategy and fundraising. Find all the services you need under one roof.</p>
        <p>Get Started with Services.</p>
        <div class="home2-services-features-list">
                    <div class="home2-services-fcard">
                       <div class="home2-services-fcard-left">
                        <img src="https://cdn-icons-png.flaticon.com/128/1087/1087840.png" id="home2-services-fcard-icon" />
                       </div>
                       <div class="home2-services-fcard-right">
                       <h3>500+</h3>
                       <h5 style={{fontWeight:"bold"}}>Projects</h5>
                       </div> 
                    </div>
                

                    <div class="home2-services-fcard">
                       <div class="home2-services-fcard-left">
                        <img src="https://cdn-icons-png.flaticon.com/128/12735/12735475.png" id="home2-services-fcard-icon" />
                       </div>
                       <div class="home2-services-fcard-right">
                       <h3>25+</h3>
                       <h5 style={{fontWeight:"bold"}}>Cities</h5>
                       </div> 
                    </div>
             

                    <div class="home2-services-fcard">
                       <div class="home2-services-fcard-left">
                        <img src="https://cdn-icons-png.flaticon.com/128/2991/2991235.png" id="home2-services-fcard-icon" />
                       </div>
                       <div class="home2-services-fcard-right">
                       <h3>4 <img src="https://cdn-icons-png.flaticon.com/128/7656/7656139.png" style={{height:"2rem",width:"2rem"}} />+</h3>
                       <h5 style={{fontWeight:"bold"}}>Google Ratings</h5>
                       </div> 
                    </div>

                </div>
              
        
              <div  class="serviceshome-btn-container">
             <div>
             <a href="https://calendly.com/thestartupconnect/30min"><button class="btn-lft-margin" id="home2-modules-section-btn" style={{marginLeft:"0"}}>Book Free Consultancy <i class="mdi mdi-arrow-right"></i></button></a>
             </div>
             <div style={{marginLeft:"0"}}>
             <a href="/home1/startupbrochure1.pdf" download="Startup Connect Services Brochure"><button class="btn-lft-margin" id="home2-modules-section-btn" style={{backgroundImage: "linear-gradient(to right, #208d17, #00ad11)", marginLeft:"0"}}>Download Brochure <i class="mdi mdi-download"></i></button></a>
             </div>
             </div>
       
    </div>
    <div class="sv-header-right" /* style={{backgroundColor:"#96dfff",padding:"2rem",borderRadius:"15px",boxShadow:"2px 3px 3px 2px #cbcecf"}} */>
    <img src="/home1/setupgif1.gif" style={{width:"90%",height:"80%"/*,borderRadius:"15px",boxShadow:"2px 3px 3px 2px #cbcecf"*/}} />
    </div>
    </div>
    </section>
    {/* HEADER SECTION END */}            

    
    {/* SERVICES SECTION START */}
    <div class="sv-services-section" /*style={{backgroundColor:"#efefef"}}*/>
        <h2>Explore the top services offered by Startup Connect.</h2>
  
    

  
        
        <hr/>
     
     <Serviceschoice />


    </div>
    {/* SERVICES SECTION END */}

    <hr />
    {/* FEATURES SECTION START */}
    <div class="bth-features-section"  style={{backgroundColor:"#e5ecf3"}}>
      <div class="bth-features-left">
      <p style={{color:"#ce30c1",fontWeight:"bold"}}>Welcome to Startup Connect Services</p>
      <h2>your gateway to a comprehensive array of tools and resources tailored to fuel the success of your startup venture.</h2>
      <p>  At Startup Connect, we understand the diverse needs and challenges faced by entrepreneurs, which is why we've curated a suite of services designed to address every aspect of your business journey. From essential bookkeeping solutions to cutting-edge networking platforms and innovative fundraising tools, our services are meticulously crafted to provide unparalleled support and guidance. Join us as we embark on this transformative journey together, where your entrepreneurial dreams can flourish with the support of Startup Connect Services.
     </p>
      </div>
      <div class="bth-features-right">
      <div class="bth-features-elm-section">
        <div class="bth-features-elm-section-2">
        <div class="bth-features-elm-card" style={{backgroundColor:"#ffffff"}}>
          <h3>Collaborative Partnership:</h3>
          <p style={{fontSize:"1rem"}}>We believe in building strong, collaborative partnerships with our clients based on trust, integrity, and mutual respect. We see ourselves as an extension of your team, working closely with you to achieve shared goals and objectives. </p>
        </div>

        <div class="bth-features-elm-card" style={{marginTop:"2rem",boxShadow:"2px 3px 3px 2px #ffba19", backgroundColor:"#ffffff"}}>
          <h3>Cost-Effectiveness and Value:</h3>
          <p style={{fontSize:"1rem"}}>We offer competitive pricing structures that are transparent and easy to understand, ensuring that you get the most bang for your buck. With a focus on cost-effectiveness and efficiency, we strive to deliver solutions that not only meet your needs but also provide tangible benefits and returns on investment for your business.</p>
        </div>
        </div>
        <div class="bth-features-elm-section-2">
          
        <div class="bth-features-elm-card" style={{marginTop:"2rem",boxShadow:"2px 3px 3px 2px #d10bd1", backgroundColor:"#ffffff"}}>
          <h3>Expertise and Professionalism:</h3>
          <p style={{fontSize:"1rem"}}>With a team of experienced professionals in their respective fields, we deliver services with a high level of expertise and professionalism. Our experts bring years of industry knowledge and hands-on experience to the table, ensuring that you receive the best possible advice and guidance. </p>
        </div>
        <div class="bth-features-elm-card" style={{marginTop:"2rem",boxShadow:"2px 3px 3px 2px #049cb5", backgroundColor:"#ffffff"}}>
          <h3>Continuous Support and Assistance:</h3>
          <p style={{fontSize:"1rem"}}>We provide ongoing support and assistance to address any follow-up needs or concerns you may have. Whether you need help troubleshooting a problem, implementing a new solution, or simply have questions about our services, we're here to help. </p>
        </div>
        </div>
      </div>

      </div>
    </div>
    {/* FEATURES SECTION END */}
           
               {/* CLIENTS SECTION START */}
               <div class="sv-services-section">
        <h2>OUR CLIENTS</h2>
  
        <div class="card">

             <div class="row mt-5 d-flex justify-content-between" style={{padding:"1rem"}}>
             {/* CLIENTS CARD 1 START */}
              <div class="col-sm-2 stretch-card grid-margin">
                  <div class="card-body p-0">
                    <img  src="/images/clients/client-1.jpeg" alt=""  style={{height:"10rem", width:"10rem"}} />
                </div>
              </div>
              {/* CLIENTS CARD 1 END */}
               {/* CLIENTS CARD 2 START */}
               <div class="col-sm-2 stretch-card grid-margin">
                  <div class="card-body p-0">
                    <img src="/images/clients/client-2.png" alt="" style={{height:"10rem", width:"10rem"}} />
                </div>
              </div>
              {/* CLIENTS CARD 2 END */}
               {/* CLIENTS CARD 3 START */}
               <div class="col-sm-2 stretch-card grid-margin">
                  <div class="card-body p-0">
                    <img  src="/images/clients/client-3.jpeg" alt="" style={{height:"10rem", width:"10rem"}} />
                </div>
              </div>
              {/* CLIENTS CARD 3 END */}
               {/* CLIENTS CARD 4 START */}
               <div class="col-sm-2 stretch-card grid-margin">
                  <div class="card-body p-0">
                    <img  src="/images/clients/client-4.jpg" alt="" style={{height:"10rem", width:"10rem"}}/>
                </div>
              </div>
              {/* CLIENTS CARD 4 END */}
               {/* CLIENTS CARD 5 START */}
               <div class="col-sm-2 stretch-card grid-margin">
                  <div class="card-body p-0">
                    <img src="/images/clients/client-5.jpg" alt="" style={{height:"10rem", width:"10rem"}} />
                </div>
              </div>
              {/* CLIENTS CARD 5 END */}
                {/*  CLIENTS CARD 6 START  */}
                 <div class="col-sm-2 stretch-card grid-margin">
                  <div class="card-body p-0">
                    <img src="/images/clients/client-6.jpeg" alt="" style={{height:"10rem", width:"10rem"}} />
                </div>
              </div>
              {/* CLIENTS CARD 6 END */}
              </div>
              </div>

              </div>
              {/* CLIENTS SECTION END */}

              {/* TESTIMONIALS SECTION START */}
    <div class="sv-testimonials-section">
        <h2>What our customers say about us!</h2>
  
        <div class="row mt-5">
        {/* TESTIMONIAL CARD 1 START */}
              <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-90 " src="https://cdn-icons-png.flaticon.com/128/2202/2202112.png" alt="" style={{height:"11vh"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h4 class="font-weight-bold"> RAHUL RAY </h4>
                    <p class="font-weight-semibold text-muted"><i>Happy Customer</i></p>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0">
                      “ We have used your service to made our company Private limited and yah I 100% satisfied with your service. ”</p>
                      
                    </div>
                    </div>
                </div>
              </div>
              {/* TESTIMONIAL CARD 1 END */}
                 {/* TESTIMONIAL CARD 2 START */}
                 <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-90 " src="https://cdn-icons-png.flaticon.com/128/2202/2202112.png" alt="" style={{height:"11vh"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h4 class="font-weight-bold"> Lucky Sharma </h4>
                    <p class="font-weight-semibold text-muted"><i>Happy Customer</i></p>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0">
                      “ I had a great experience working with Startup Connect. From start to finish, they were professional, knowledgeable, and responsive to my needs. ”</p>
                      
                    </div>
                    </div>
                </div>
              </div>
              {/* TESTIMONIAL CARD 2 END */}
                 {/* TESTIMONIAL CARD 3 START */}
                 <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-90 " src="https://cdn-icons-png.flaticon.com/128/2202/2202112.png" alt="" style={{height:"11vh"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h4 class="font-weight-bold">Ashish Pandey</h4>
                    <p class="font-weight-semibold text-muted"><i>Happy Customer</i></p>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0">
                      “ The overall quality of each service delivered is top class. We will definitely continue to use and recommend Startup Connect team to our friends and family as well. ”</p>
                      
                    </div>
                    </div>
                </div>
              </div>
              {/* TESTIMONIAL CARD 3 END */}
                 {/* TESTIMONIAL CARD 4 START */}
                 <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-90 " src="https://cdn-icons-png.flaticon.com/128/2202/2202112.png" alt="" style={{height:"11vh"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h4 class="font-weight-bold"> Shrikant Shrivastava</h4>
                    <p class="font-weight-semibold text-muted"><i>Happy Customer</i></p>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0">
                      “ I liked the way startup connect team managed the complete software developemt process for my business along with quick responses to my needs. ”</p>
                      
                    </div>
                    </div>
                </div>
              </div>
              {/* TESTIMONIAL CARD 4 END */}

              </div>

              </div>
            {/* TESTIMONIALS SECTION END */}

            <br/><br/><br/><br/><br/>
  <Footer3/>
  </div>

     

    {/* plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/* endinject */}
    {/* Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/* End plugin js for this page */}
    {/* inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/* endinject */}
    {/* Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/* End custom js for this page */}

  </body>
</html>
</>
      );

}

export default Serviceshome;