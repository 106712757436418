import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';

import Navbar from './navbar.js';
import CandidateprofilePersonalinfo from './Candidateprofileformelements/personalinfo.js';
import CandidateprofileAddressinfo from './Candidateprofileformelements/addressinfo.js';
import CandidateprofileEducationaldetails from './Candidateprofileformelements/educationaldetails.js';
import CandidateprofileAddskills from './Candidateprofileformelements/addskills.js';
import CandidateprofileCoursescertification from './Candidateprofileformelements/coursescertification.js';
import CandidateprofileWorkexperience from './Candidateprofileformelements/workexperience.js';
import CandidateprofileProjectsandworks from './Candidateprofileformelements/projectsandworks.js';
import CandidateprofileAchievements from './Candidateprofileformelements/achievements.js';
import CandidateprofileOtherlinks from './Candidateprofileformelements/otherlinks.js';

function Candidatecreateprofile(){

  

    return(
<>




<div class="default-view">
        <Navbar/>
        {/* MAIN SECTION START */}
        <div class="hire-main-section">

        {/* FORM CONTAINER START */}
        <div class="hire-form-container">
        <h2>Candidate Profile</h2>
        <div id="line-3"></div>
        
        {/* CANDIDATE PROFILE FORM START */}
        <CandidateprofilePersonalinfo />
         
        <CandidateprofileAddressinfo />

        <CandidateprofileEducationaldetails />

        <CandidateprofileAddskills />

        <CandidateprofileCoursescertification />

        <CandidateprofileWorkexperience />

        <CandidateprofileProjectsandworks />

        <CandidateprofileAchievements />

        <CandidateprofileOtherlinks />

        {/* CANDIDATE PROFILE FORM END */}
        </div>
        {/* FORM CONTAINER END */}
        </div>
        {/* MAIN SECTION END */}
        </div>



              
              
</>
);
    }

export default Candidatecreateprofile;