import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';




/*import Amanbg from './amanbg';*/


function Topanalytics(){

     
    return(
<>

   
{/* Top Analytics Start */}

<div class="row">
              <div class="col-md-4 stretch-card grid-margin">
                <div class="card bg-gradient-danger card-img-holder text-white">
                  <div class="card-body">
                    <img src="assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
                    <h4 class="font-weight-normal mb-3">Total Registrations <i class="mdi mdi-chart-line mdi-24px float-right"></i>
                    </h4>
                    <h2 >$ 15,0000</h2>
                    <h6 class="card-text mt-0">This week (+60%)</h6>
                    <h2 class="mt-2">$ 15,0000</h2>
                    <h6 class="card-text mt-0">Last 24 Hours (+60%)</h6>
                    <h2 class="mt-2">$ 15,0000</h2>
                    <h6 class="card-text mt-0">Last Hour (+60%)</h6>
                  </div>
                </div>
              </div>
              <div class="col-md-4 stretch-card grid-margin">
                <div class="card bg-gradient-info card-img-holder text-white">
                  <div class="card-body">
                    <img src="assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
                    <h4 class="font-weight-normal mb-3">Total Contacts <i class="mdi mdi-bookmark-outline mdi-24px float-right"></i>
                    </h4>
                    <h2 >$ 15,0000</h2>
                    <h6 class="card-text mt-0">This week (+60%)</h6>
                    <h2 class="mt-2">$ 15,0000</h2>
                    <h6 class="card-text mt-0">Last 24 Hours (+60%)</h6>
                    <h2 class="mt-2">$ 15,0000</h2>
                    <h6 class="card-text mt-0">Last Hour (+60%)</h6>
                  </div>
                </div>
              </div>
              <div class="col-md-4 stretch-card grid-margin">
                <div class="card bg-gradient-success card-img-holder text-white">
                  <div class="card-body">
                    <img src="assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
                    <h4 class="font-weight-normal mb-3">Total Logins <i class="mdi mdi-diamond mdi-24px float-right"></i>
                    </h4>
                    <h2 >$ 15,0000</h2>
                    <h6 class="card-text mt-0">This week (+60%)</h6>
                    <h2 class="mt-2">$ 15,0000</h2>
                    <h6 class="card-text mt-0">Last 24 Hours (+60%)</h6>
                    <h2 class="mt-2">$ 15,0000</h2>
                    <h6 class="card-text mt-0">Last Hour (+60%)</h6>
                  </div>
                </div>
              </div>
            </div>
            
{/* Top Analytics End */}
</>
    );
}

export default Topanalytics;