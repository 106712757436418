import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';
import moment from 'moment/moment';


import '../Resources/dashboard.css';


function Add_inventory(){

  

  
   //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const busyid=sessionStorage.getItem("BusyId00");
 const counterid=sessionStorage.getItem("CounterId00");
 const trno=sessionStorage.getItem("Trno00");


 //Checking if Sessions are Set

// alert(logstatus,loguser,logadminid);
 //Sessions Not Set
 {/*if(((logstatus==null)||(logstatus==""))||((logid==null)||(logid==""))||((token==null)||(token==" "))||((busyid==null)||(busyid==" "))||((counterid==null)||(counterid==" "))){
  
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");
   
 
    window.location.replace("/businesstools-login");
   }
  
   //Sessions Set
   else{
   */}


       
      
//Variables
const[inventory,setinventory] = useState([]);//SetData Variable
const[products,setproducts] = useState([],);//SetData Variable
const[cdefault,setcdefault] = useState(0);


//Error Variable
const[errorvar,seterrorvar] = useState();




//ADD PRODUCT
//date
const[date,setdate]=useState();const updatedate = (event) => { setdate(event.target.value); }; const[date_er,setdate_er]=useState();
//product_name
const[product_name,setproduct_name]=useState();const updateproduct_name = (event) => { setproduct_name(event.target.value);getproductbyname(); }; const[product_name_er,setproduct_name_er]=useState();
//product_id
const[product_id,setproduct_id]=useState();const updateproduct_id = (event) => { setproduct_id(event.target.value);}; const[product_id_er,setproduct_id_er]=useState();
//product_code
const[product_code,setproduct_code]=useState();const updateproduct_code = (event) => { setproduct_code(event.target.value);}; const[product_code_er,setproduct_code_er]=useState();
//product_hsn
const[product_hsn,setproduct_hsn]=useState();const updateproduct_hsn = (event) => { setproduct_hsn(event.target.value);}; const[product_hsn_er,setproduct_hsn_er]=useState();
//type
const[type,settype]=useState();const updatetype = (event) => { settype(event.target.value);}; const[type_er,settype_er]=useState();
//rate
const[rate,setrate]=useState();const updaterate = (event) => { setrate(event.target.value); calculate(); }; const[rate_er,setrate_er]=useState();
//quantity
const[quantity,setquantity]=useState();const updatequantity = (event) => { setquantity(event.target.value); calculate(); }; const[quantity_er,setquantity_er]=useState();
//units
const[units,setunits]=useState();const updateunits = (event) => { setunits(event.target.value);}; const[units_er,setunits_er]=useState();
//amount
const[amount,setamount]=useState();const updateamount = (event) => { setamount(event.target.value); calculate(); }; const[amount_er,setamount_er]=useState();
//particular
const[particular,setparticular]=useState();const updateparticular = (event) => { setparticular(event.target.value); }; const[particular_er,setparticular_er]=useState();

  //ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();

//Calculate Function
function calculate(){
  let rate = (document.getElementById("rate").value);if(rate==""){ rate=0;}else{ rate=parseFloat(rate);}
  let quantity = (document.getElementById("quantity").value);if(quantity==""){ quantity=0;}else{ quantity=parseFloat(quantity);}
  let amount = parseFloat(rate*quantity);setamount(amount.toFixed(2));
}

    //******************* GET PRODUCT DATA BY NAME FUNCTION START  ********************//
function getproductbyname(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((trno!=null)||(trno!=" "))){
 
     const cname = document.getElementById("product_name").value;
     const cnlength = cname.length;
     if((cdefault==0)&&(cnlength>=3)){
     
 
 axios.post("bt-get-productlist-spr", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Name:document.getElementById("product_name").value,
   Sn_start :0,
   Sn_end :0,
   Sn_total :0,
   Sn_limit :0
 })
 .then((response) => {

  //Transaction Limit Reached
  if(response.data.error==111){     seterror_msg(response.data.error_msg); return; }

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setproducts(response.data.productlist);
   document.getElementById("products-list-box").style.display="block";
  
  }
  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
}
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }

 }
  
 //******************* GET PRODUCT DATA BY NAME FUNCTION END  ********************//




  
//******************* ADD INVENTORY FUNCTION START ********************//
function addinventory(){

  //Setting Error Variables
setdate_er(" ");setproduct_name_er(" ");settype_er(" ");setproduct_id_er(" ");setproduct_code_er(" ");setproduct_hsn_er(" ");setrate_er(" ");setquantity_er(" ");setunits_er(" ");setamount_er(" ");setparticular_er(" ");
seterror_msg(" ");




  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))){
 
 
  axios.post("bt-create-inventory", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Date:moment(date).format('YYYY-MM-DD'),
   Type:type,
   Product_id:product_id,
   Product_name:product_name,
   Product_code : product_code,
   Product_hsn : product_hsn,
   Quantity:quantity,
   Rate:rate,
   Units:units,
   Particular:particular
 
 })
 .then((response) => {

  if(response.data.error==103){     seterror_msg(response.data.error_msg);  return; }

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");

  }

   //Validation Errors
 if(response.data.validatorerror){
  settype_er(response.data.validatorerror.Type);
  setproduct_name_er(response.data.validatorerror.Product_name);
  setproduct_id_er(response.data.validatorerror.Product_id);
  setproduct_code_er(response.data.validatorerror.Product_code);
  setproduct_hsn_er(response.data.validatorerror.Product_hsn);
  setdate_er(response.data.validatorerror.Date);
  setquantity_er(response.data.validatorerror.Quantity);
  setrate_er(response.data.validatorerror.Rate);
  setunits_er(response.data.validatorerror.Units);
  setparticular_er(response.data.validatorerror.Particular);

  
  return;

}
//Invalid Entries
if(response.data.error==102){ 
  
  setdate_er(response.data.date_er);
  settype_er(response.data.type_er);
  setproduct_name_er(response.data.product_name_er);
  setproduct_code_er(response.data.product_code_er);
  setproduct_id_er(response.data.product_id_er);
  setproduct_hsn_er(response.data.product_hsn_er);
  setrate_er(response.data.rate_er);
  setquantity_er(response.data.quantity_er);
  setunits_er(response.data.units_er);
  setparticular_er(response.data.particular_er);
 
  seterror_msg("Cannot Add Product to Inventory !");  return; }

   

   //Product Entered Successfully
   if(response.data.successvar==1){   
    document.getElementById("success-card").style.display="block";
   }
   //Cannot Add Product
   else{  seterror_msg("Cannot Add Product to Inventory !"); }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* ADD INVENTORY FUNCTION END ********************//








return(
    <>

         {/* PRODUCT LIST BOX START */}
         <div class="d-flex justify-content-center">
          <div class="col-md-4 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"1rem",display:"none"}} id="products-list-box">
                <div class="card">
                <div class="d-flex justify-content-between">
                    <div>
                    <h4 style={{padding:'1rem'}}>Products</h4>
                    </div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ setcdefault(1); document.getElementById("products-list-box").style.display="none"; }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                    
                    <table class="table table-hover">
                      <thead>
                        <tr>
                        {/*}  <th>Product Id</th> */}
                          <th>Product Name</th>
                         {/*} <th>Product HSN</th> 
                          <th>Product Code</th> */}
                          <th>Purchase Rate</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                       products.map((curElm7,ind)=>{

                         return(
                         <>
                        <tr key={curElm7.Product_id}>
                         {/*} <td>{curElm7.Product_id}</td> */}
                          <td>{curElm7.Product_name}</td>
                         {/*} <td>{curElm7.Product_hsn}</td> 
                          <td>{curElm7.Product_code}</td> */}
                          <td>{curElm7.Purchase_rate}</td>
                          <td><i class="mdi mdi-assistant text-warning" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                        //Setting Product Details for 
                        setproduct_code(curElm7.Product_code);setproduct_hsn(curElm7.Product_hsn);setproduct_name(curElm7.Product_name); setrate(curElm7.Purchase_rate);setunits(curElm7.Units);
                        document.getElementById("products-list-box").style.display="none";
                              }}></i></td>

                        </tr>
                        </>
                         )
                         }
					   
	                  		 )
                         }
                      </tbody>
                    </table>
              
                </div>
              </div>
              </div>
              {/* PRODUCT LIST BOX END */}

                <div class="card">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("create-form").style.display="none"; }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                  <div class="card-body">
                    <h4 class="card-title">Add Inventory</h4>
                    <div class="form-sample">
                      <p class="card-description">add your inventory details here</p>
                         <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" id="date" value={date} onChange={updatedate}/>
                              <code>{date_er}</code>
                            </div>
                          </div>
                        </div>  
                        </div>
                      <div class="row">    
                        <div class="col-md-6">
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product Name<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Red T-Shirt" id="product_name" value={product_name} onChange={updateproduct_name} />
                            <code>{product_name_er}</code>
                            </div>
                          </div>
                        </div>   
                           <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type<code>*</code></label>
                            <div class="col-sm-9">
                            <select class="form-control" style={{padding:"1rem"}} placeholder="Select Transaction" id="type" value={type} onChange={updatetype}>
                              <option value="">Select Transaction</option>
                              <option value="entry">Entry</option>
                              <option value="drawings">Drawings</option>
                              </select>
                              <code>{type_er}</code>
                            </div>
                          </div>
                        </div>
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product Code<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="214" id="product_code" value={product_code} onChange={updateproduct_code} />
                              <code>{product_code_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">HSN Code</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="755444" id="product_hsn" value={product_hsn} onChange={updateproduct_hsn} />
                              <code>{product_hsn_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                  
                    
                   
                 
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Quantity<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="150.00" id="quantity" value={quantity} onChange={updatequantity} />
                              <code>{quantity_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Units<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="pieces" id="units" value={units} onChange={updateunits} />
                              <code>{units_er}</code>
                            </div>
                          </div>
                        </div>                 
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Rate<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="50.00" id="rate" value={rate} onChange={updaterate} />
                              <code>{rate_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Value</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="7500.00" id="amount" value={amount} onChange={updateamount} />
                            </div>
                          </div>
                        </div>                     
                      </div>

                      <div class="form-group">
                        <label for="exampleTextarea1">Particular</label>
                        <textarea class="form-control" rows="2" id="particular" value={particular} onChange={updateparticular} ></textarea>
                        <code>{particular_er}</code>
                      </div>

                        
                      
                        <button class="btn btn-primary" style={{fontWeight:"bold"}} onClick={addinventory}>Submit</button>
                      
                       
    
                     
                   
                    </div>
                  </div>
                </div>
    </>

);
}
//}

export default Add_inventory;