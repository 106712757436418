import React from 'react'
import { Link } from 'react-router-dom'
import './Cards.css'
import AllFeatures from '../AllFeatures'


const Cards = (props) => {


 // console.log(props);
  return (
<>


    <div class="card h-100 text-center services-card">
      
  <div class="card-body">
     <h3 class="card-title">{props.title}</h3>
     <img src={props.src} class="card-img-top" style={{border:"0.1rem solid #4c5657"}}  alt="Card image cap" />

    <h4 class="card-text">{props.category}</h4>
    <p class="card-text">{props.description}</p>
    <div style={{padding:"0.1rem",backgroundColor:"#0a7184"}}>
    <p class="card-text mt-1" style={{fontSize:"1.4rem",color:"#d7e4e6"}}>Starting at just <span style={{fontWeight:"bold",color:"#ffffff"}}>{props.price}</span></p>
    <div class="text-center">
      <Link to={props.link} style={{width:"60%",padding:"0.5rem",marginBottom:"1rem",fontWeight:"bold",fontSize:"1.4rem",backgroundColor:"#c73b4f",border:"0.1rem solid #ffffff"}} className='col mt-2 card-link btn btn-primary'>Get Started</Link>
    </div>
    </div>
  </div>
</div> 

  {/*  <div class="card h-100 text-center">
      
      <div class="card-body">
        <h3 class="card-title">{props.title}</h3>
        <h4 class="card-text">{props.category}</h4>
        <p class="card-text">{props.description}</p>
        <p class="card-text">Starting at just {props.price}</p>
        <div class="text-center">
          <Link to={props.link} className='col mt-2 card-link btn btn-primary bg-dark'>Get Started</Link>
        </div>
      </div>
  </div> */}

  
</>
  )
}

export default Cards
