import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
//import axios from '../../axios.js';
import moment from 'moment/moment';


//import '../Resources/dashboard.css';



import Chart from "chart.js/auto";
import { Line, Bar } from "react-chartjs-2";
import Footer3 from './Supportingfiles/footer3';

function Aboutus(){

    return(
     <>

<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Startup Connect | About Us</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div>

   <div class="container-scroller">
   <div class="row d-flex justify-content-center" >
   <div>
    <img src="https://images.pexels.com/photos/599982/pexels-photo-599982.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" style={{height:"42vh",width:"100vw"}}></img>
   </div>
   </div>
   </div>
  {/* CONTENT SECTION START */}
   <div class="container mt-4">
   <h1 style={{fontWeight:"bold"}}>ABOUT US</h1>
   <h3 style={{color:"#811a99"}}><b>“ Don't Just Dream it, Live it ”</b></h3>
   <p style={{fontSize:"1rem", textAlign:"left", marginTop:"3rem"}}><b>The Startup Connect </b>, operating under the company name "Technomate Startup Services Private Limited," was founded in December 2022 in Jabalpur (Madhya Pradesh) by a group of visionary entrepreneurs with a passion for fostering innovation and supporting aspiring startups. The founders, who themselves had experienced the challenges and excitement of building their own startups, recognized the need for a platform that could bridge the gap between budding entrepreneurs and the resources required to transform their ideas into successful businesses.</p>
   <p style={{fontSize:"1rem", textAlign:"left"}}>At Startup Connect, innovation meets opportunity! In today's fast-paced and competitive business landscape, startups play a pivotal role in driving economic growth and fostering technological advancements. Startup Connect is a dynamic platform designed to empower aspiring entrepreneurs and connect them with the resources they need to turn their ideas into successful ventures.</p>
   <br/><br/>
   <p style={{fontSize:"1rem",fontWeight:"bold"}}>Startup Connect is more than just a platform; it's a catalyst for innovation, collaboration, and growth. By providing startups with the right tools, access to capital, and expert guidance, we aim to revolutionize the startup ecosystem.<br/><br/> Join us today, and let's embark on an exciting journey to shape the future of entrepreneurship together!</p>
   </div>

  {/* MISSION VISION SECTION START */}
  <div class="row d-flex justify-content-center" style={{marginTop:"5rem", padding:"1rem"}}>
    <div class="grid-margin col-md-4">
      <div class="card" style={{textAlign:"left", padding:"2rem"}}>
      <img src="https://cdn-icons-png.flaticon.com/128/4887/4887265.png" style={{height:"5rem",width:"5rem", marginBottom:"2rem"}}></img>
        <h2>Our Mission</h2>
        <p style={{fontSize:"1rem"}}>Our mission is to empower businesses with professional services, automation, and management tools while fostering an ecosystem that connects startups with the right talent and industry peers.</p>
      </div>
    </div>
    <div class="grid-margin col-md-4">
      <div class="card"   style={{textAlign:"left", padding:"2rem"}}>
      <img src="https://cdn-icons-png.flaticon.com/128/9539/9539805.png" style={{height:"5rem",width:"5rem", marginBottom:"2rem"}}></img>
        <h2>Our Vision</h2>
        <p style={{fontSize:"1rem"}}>We aim unhesitating towards a future vision to create a dynamic ecosystem where startups can seamlessly connect with the right resources, enabling them to thrive, innovate, and achieve their full potential.</p>
      </div>
    </div>
  </div>
  {/* MISSION VISION SECTION END */}

  <hr/>
  {/* TEAM SECTION START */}
  <h1>OUR LEADERS</h1>
  <h4 class="text-muted">“Teamwork is the secret that makes common people achieve uncommon results.” –Ifeanyi Enoch Onuoha</h4>


  <div class="row d-flex justify-content-center mt-5" style={{padding:"1rem"}}>
    
    
            {/* MENTOR PROFILE CARD START 1 */}
              <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="/about/mayur.png" alt="" style={{height:"40vh"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                  <div class="d-flex justify-content-between">
                  <div>
                      <h4 class="font-weight-bold" style={{textAlign:"left"}}>MAYUR TAMRAKAR</h4>   
                      <p class="text-muted mb-0" style={{fontSize:"1rem"}}> Head of Operations and Technology</p>
                  </div>
                     <a href="https://www.linkedin.com/in/mayur-tamrakar-2000/"><button type="button" class="btn btn-social-icon btn-linkedin">
                        <i class="mdi mdi-linkedin"></i>
                      </button></a> 
                    </div>
                    <div>
                   {/*} <p style={{fontSize:"1rem", marginTop:"1rem"}}>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                   */} </div>
               
                  </div>
                </div>
              </div>
              {/* MENTOR PROFILE CARD END 1 */}

               {/* MENTOR PROFILE CARD START 2 */}
               <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="/about/siddharth.png" alt="" style={{height:"40vh"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                  <div class="d-flex justify-content-between">
                  <div>
                      <h4 class="font-weight-bold" style={{textAlign:"left"}}>Siddharth Nayak Jain</h4>   
                      <p class="text-muted mb-0" style={{fontSize:"1rem"}}> Head of Marketing and Finance</p>
                  </div>
                  <a href="https://www.linkedin.com/in/ca-siddharth-nayak-jain-94a346140/"><button type="button" class="btn btn-social-icon btn-linkedin">
                        <i class="mdi mdi-linkedin"></i>
                      </button></a> 
                    </div>
                    <div>
                    {/* <p style={{fontSize:"1rem", marginTop:"1rem"}}>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    */}</div>
               
                  </div>
                </div>
              </div>
              {/* MENTOR PROFILE CARD END 2 */}

  </div>
  {/* TEAM SECTION END */}


    </div>
    <Footer3/>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
     </>
    );

}

export default Aboutus;