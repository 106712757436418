import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';



function Employerleftsidebar(){
    
    
    
 return(
    <>  
    
    
      {/* SIDEBAR LEFT START */}
      <div class="hire-left-sidebar" id="left-sidebar">
        <h3>Jobs</h3>
        <ul type="none">
        <a href="/employer-jobs" style={{textDecoration:"none",color:"black"}}><li>Jobs</li></a>
        <a href="/employer-dashboard" style={{textDecoration:"none",color:"black"}}><li>Candidates</li></a>
        <a href="/employer-invitations" style={{textDecoration:"none",color:"black"}}><li>Invitations</li></a>
        <a href="/employer-assignments" style={{textDecoration:"none",color:"black"}}><li>Assignments</li></a>
        <a href="/employer-companies" style={{textDecoration:"none",color:"black"}}><li>Manage Companies</li></a>
      {/*}  <a href="/candidate-applied" style={{textDecoration:"none",color:"black"}}><li>Hires</li></a>
        <a href="/candidate-applied" style={{textDecoration:"none",color:"black"}}><li>Offer Letters</li></a>
        <a href="/candidate-applied" style={{textDecoration:"none",color:"black"}}><li>Tools</li></a> 
        <a href="" style={{textDecoration:"none",color:"black"}}><li>Jobs History</li></a> */}
        </ul>
        </div>
        {/* SIDEBAR LEFT END */}

</>
 );

    }

    export default Employerleftsidebar;