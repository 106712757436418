import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
//import axios from '../../axios.js';
import moment from 'moment/moment';

import '../Resources/homebtcss.css';
import '../Resources/home2css.css';
import '../Resources/serviceshomecss.css';

import Sidebar from './sidebar';


import Chart from "chart.js/auto";
import { Line, Bar } from "react-chartjs-2";
import Footer3 from '../Supportingfiles/footer3';
import { Navbar } from 'flowbite-react';
import Navbar3 from '../Supportingfiles/navbar3';

function Pagedummy(){

   

    return(
     <>

<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>STARTUP CONNECT | PAGES</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>

   
    <div class="home2-container">


   <Navbar3/>

    {/* HEADER SECTION START */}
    <section /*class="sv-header-section" */>
    <div class="sv-header-container1">
    <div class="sv-header-left" style={{padding:"2rem"}}>
        <h1 style={{color:"#004463"}}>Private Limited Company Registration</h1>
        <p>Private Limited Company (PLC) is a type of business entity in which shareholders have limited liability and the company has a legal existence separate from its members. It requires registration with the government and is subject to various regulations and compliance requirements. TheStartupConnect provides assistance in registering and maintaining a PLC.</p>

        <div class="home2-services-features-list">
                    <div class="home2-services-fcard">
                       <div class="home2-services-fcard-left">
                        <img src="https://cdn-icons-png.flaticon.com/128/1087/1087840.png" id="home2-services-fcard-icon" />
                       </div>
                       <div class="home2-services-fcard-right">
                       <h3>500+</h3>
                       <h5 style={{fontWeight:"bold"}}>Projects</h5>
                       </div> 
                    </div>
                

                    <div class="home2-services-fcard">
                       <div class="home2-services-fcard-left">
                        <img src="https://cdn-icons-png.flaticon.com/128/12735/12735475.png" id="home2-services-fcard-icon" />
                       </div>
                       <div class="home2-services-fcard-right">
                       <h3>25+</h3>
                       <h5 style={{fontWeight:"bold"}}>Cities</h5>
                       </div> 
                    </div>
             

                    <div class="home2-services-fcard">
                       <div class="home2-services-fcard-left">
                        <img src="https://cdn-icons-png.flaticon.com/128/2991/2991235.png" id="home2-services-fcard-icon" />
                       </div>
                       <div class="home2-services-fcard-right">
                       <h3>4 <img src="https://cdn-icons-png.flaticon.com/128/7656/7656139.png" style={{height:"2rem",width:"2rem"}} />+</h3>
                       <h5 style={{fontWeight:"bold"}}>Google Ratings</h5>
                       </div> 
                    </div>

                </div>

        <div class="row d-flex justify-content-left">
        <div class="col-sm-6 stretch-card grid-margin">
        <a href="https://calendly.com/thestartupconnect/30min"><button id="home2-modules-section-btn" style={{marginLeft:"0"}}>Book Free Consultancy <i class="mdi mdi-arrow-right"></i></button></a>
        </div>
        <div class="col-sm-6 stretch-card grid-margin">
        <a href="/home1/startupbrochure1.pdf" download="Startup Connect Services Brochure"><button id="home2-modules-section-btn" style={{backgroundImage: "linear-gradient(to right, #208d17, #00ad11)"}}>Download Brochure <i class="mdi mdi-download"></i></button></a>
        </div>
        </div>
         
    </div>
    <div class="sv-header-right" /* style={{backgroundColor:"#96dfff",padding:"2rem",borderRadius:"15px",boxShadow:"2px 3px 3px 2px #cbcecf"}} */>
    <img src="https://img.freepik.com/free-vector/recruiting-agency-isometric-illustration-coworkers-candidate-employer-3d-cartoon-characters-headhunting-human-resources-department_575670-1091.jpg" style={{width:"90%",height:"100%"}} />
    </div>
    </div>
    </section>
    {/* HEADER SECTION END */}
    <hr />

{/* SIDEBAR AND MAIN PAGE START */}
    <div className="container-scroller">

{/*<Sidebar /> */}
<div className="container-fluid page-body-wrapper">

  {/*} partial */}
  <div className="main-panel">

  <hr/>

   {/* MORE INFORMATION SECTION START */}
   <div class="row" style={{padding:"2rem"}}>
   <div class="col-sm-9 stretch-card grid-margin" style={{textAlign:"left"}}>
   <div>
   <h2 style={{fontWeight:"bold"}}>Why choose a <span style={{color:"#9f23c2"}}>Private Limited Company</span></h2>
   <br/>
   <p style={{fontSize:"1rem"}}> private limited company is a business entity offering limited owner liability. It is apt for a small number of shareholders and allows up to 200 members along with flexibility in shares and shareholdings.
   </p>
   <p style={{fontSize:"1rem"}}>One of the most highly recommended methods for starting a business in India is to establish a private limited company, which provides its shareholders with limited liability while imposing certain ownership restrictions. When it is LLP, the partners will manage it. On the other hand, a private limited company registration allows for directors and shareholders to be separate entities.
   </p>
   <p style={{fontSize:"1rem"}}>As your dependable legal advisor, Vakilsearch offers a cost-efficient service for registering your company in India. We handle all legal procedures and ensure compliance with the regulations set forth by the Ministry of Corporate Affairs (MCA). Upon successful completion of the pvt Ltd company registration process, we provide you with an Incorporation certificate (CoI), as well as PAN and TAN documents. With these in hand, you can easily establish a current bank account and commence your business operations.</p>
   </div>
   </div>
   <div class="col-sm-3 stretch-card grid-margin">
    <img src="https://img.freepik.com/free-vector/business-deal-concept-illustration_114360-1535.jpg" style={{width:"90%",height:"100%"}} />
    </div>
   </div>
   {/* MORE INFORMATION SECTION END */}



   <hr/>
   <h2>Benefits of registering company as a private limited company</h2>
   {/* BENEFITS SECTION START */}
   <div class="row" style={{padding:"2rem"}}>

   <div class="col-sm-2 stretch-card grid-margin" style={{textAlign:"left"}}>
   <div class="card" style={{textAlign:"center",padding:"1rem"}}>
   <div class="d-flex justify-content-center">
   <img src="https://cdn-icons-png.flaticon.com/128/4318/4318295.png" style={{height:"6rem",width:"6rem"}} />
   </div>
   <h4 class="mt-3">Legal Recognition</h4>
   <p>A Private Limited Company offers legal recognition to the business, providing a separate legal entity from its owners.</p>
   </div>
   </div>


   <div class="col-sm-2 stretch-card grid-margin" style={{textAlign:"left"}}>
   <div class="card" style={{textAlign:"center",padding:"1rem"}}>
   <div class="d-flex justify-content-center">
   <img src="https://cdn-icons-png.flaticon.com/128/12729/12729967.png" style={{height:"6rem",width:"6rem"}} />
   </div>
   <h4 class="mt-3">Limited Liability</h4>
   <p>Owners' liability is limited to the amount invested in the company, protecting their personal assets from business liabilities.</p>
   </div>
   </div>


   <div class="col-sm-2 stretch-card grid-margin" style={{textAlign:"left"}}>
   <div class="card" style={{textAlign:"center",padding:"1rem"}}>
   <div class="d-flex justify-content-center">
   <img src="https://cdn-icons-png.flaticon.com/128/9601/9601651.png" style={{height:"6rem",width:"6rem"}} />
   </div>
   <h4 class="mt-3">Perpetual Existence</h4>
   <p>The company has perpetual existence, regardless of the owner's presence, ensuring continuity in business operations.</p>
   </div>
   </div>


   <div class="col-sm-2 stretch-card grid-margin" style={{textAlign:"left"}}>
   <div class="card" style={{textAlign:"center",padding:"1rem"}}>
   <div class="d-flex justify-content-center">
   <img src="https://cdn-icons-png.flaticon.com/128/3529/3529127.png" style={{height:"6rem",width:"6rem"}} />
   </div>
   <h4 class="mt-3">Access to Funding</h4>
   <p>Private Limited Companies can easily raise funds through the sale of shares, loans, and other means of raising capital..</p>
   </div>
   </div>


   <div class="col-sm-2 stretch-card grid-margin" style={{textAlign:"left"}}>
   <div class="card" style={{textAlign:"center",padding:"1rem"}}>
   <div class="d-flex justify-content-center">
   <img src="https://cdn-icons-png.flaticon.com/128/5347/5347858.png" style={{height:"6rem",width:"6rem"}} />
   </div>
   <h4 class="mt-3">Credibility</h4>
   <p>Being registered as a Private Limited Company lends credibility to the business and builds trust among stakeholders.</p>
   </div>
   </div>


   <div class="col-sm-2 stretch-card grid-margin" style={{textAlign:"left"}}>
   <div class="card" style={{textAlign:"center",padding:"1rem"}}>
   <div class="d-flex justify-content-center">
   <img src="https://cdn-icons-png.flaticon.com/128/6086/6086765.png" style={{height:"6rem",width:"6rem"}} />
   </div>
   <h4 class="mt-3">Tax Benefits</h4>
   <p>The company is eligible for tax benefits, such as deductions on expenses and exemptions on profits..</p>
   </div>
   </div>



   </div>
   {/* BENEFITS SECTION END */}


   <hr/>
   {/* REQUIREMENTS SECTION START */}
   <h2>Requirements for Registering Private Limited Company</h2>

   <div class="row" style={{padding:"2rem"}}>
   
   <div class="col-sm-4 stretch-card grid-margin">
    <img src="https://img.freepik.com/free-vector/business-deal-concept-illustration_114360-1103.jpg" style={{width:"90%",height:"100%"}} />
    </div>

   <div class="col-sm-8 stretch-card grid-margin" style={{textAlign:"left"}}>
   <div>

   <h4 style={{fontWeight:"bold"}}>1. Two Directors:</h4>
   <br/>
   <p style={{fontSize:"1rem"}}> A private limited company must have at least two directors, with a maximum of fifteen. A minimum of one of the company's directors must be a resident of India.
   </p>
   <br/>
   <h4 style={{fontWeight:"bold"}}>2. Unique Name</h4>
   <br/>
   <p style={{fontSize:"1rem"}}>The name of your pvt ltd company must be unique. The suggested name should not match with any existing companies or trademarks in India.
   </p>
  <br/>
   <h4 style={{fontWeight:"bold"}}>3. Minimum Capital Contribution</h4>
   <br/>
   <p style={{fontSize:"1rem"}}>There is no minimum capital amount for a Pvt ltd company. A Pvt limited company should have an authorised capital of at least ₹1 lakh.
   </p>
   <br/>
   <h4 style={{fontWeight:"bold"}}>4. Registered Office</h4>
   <br/>
   <p style={{fontSize:"1rem"}}>The registered office of a pvt ltd company does not have to be a commercial space. Even a rented home can be the registered office, so long as an NOC is obtained from the landlord.
   </p>
   </div>
   </div>
  

   </div>

   {/* REQUIREMENTS SECTION END */}
   <hr/>
   <h2>Private Limited Company Registration Process</h2>

  {/* PROCESS SECTION START */}
  <div class="row"> 


  <div class="col-sm-3 stretch-card grid-margin" >
                <div class="card">
                  <div class="card-body">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/messenger-concept-illustration_114360-879.jpg" alt="" />
                  </div>
                  <div class="card-body px-3 text-dark" >
                 
                    <h3 class="font-weight-bold"> Apply for DSC </h3>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Experts review your documents and information
                      <br/>Apply for Digital Signature Certificate
                    </p></div>
                    </div>
                </div>
                </div>

                
  <div class="col-sm-3 stretch-card grid-margin" >
                <div class="card">
                  <div class="card-body">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/messenger-concept-illustration_114360-879.jpg" alt="" />
                  </div>
                  <div class="card-body px-3 text-dark" >
                 
                    <h3 class="font-weight-bold"> Name Reservation </h3>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Name availability check on MCA and Trademark Portal.
                      <br/>Apply for Company Name Reservation                    </p></div>
                    </div>
                </div>
                </div>

                
  <div class="col-sm-3 stretch-card grid-margin" >
                <div class="card">
                  <div class="card-body">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/messenger-concept-illustration_114360-879.jpg" alt="" />
                  </div>
                  <div class="card-body px-3 text-dark" >
                 
                    <h3 class="font-weight-bold"> Filing for COI </h3>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      Form filling for Certificate of Incorporation (COI)
                      <br/>Submission of MOA & AOA                  </p></div>
                    
                    </div>
                </div>
                </div>

                
  <div class="col-sm-3 stretch-card grid-margin" >
                <div class="card">
                  <div class="card-body">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/messenger-concept-illustration_114360-879.jpg" alt="" />
                  </div>
                  <div class="card-body px-3 text-dark" >
                 
                    <h3 class="font-weight-bold"> Apply for PAN, TAN and DIN </h3>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0" style={{fontSize:"1rem"}}>
                      DIN Allotment Application

                     <br/>PAN and TAN Application
                    </p></div>
                    </div>
                </div>
                </div>


</div>
{/* PROCESS SECTION END */}


  <hr/>
  {/* COMPLIANCES SECTION START */}
  <h2>Compliances Required by a Private Limited Company</h2>
  <p style={{fontSize:"1rem"}}>Once a company is registered in India, various compliances must be maintained from time to time to avoid penalties and prosecution. The following are some of the compliances a company would be required to complete after company registration</p>
  <div class="row" style={{textAlign:"left",padding:"2rem"}}>
  <h3>a.) Appointment of Auditor</h3>
  <p style={{fontSize:"1rem"}}>All companies registered in
India must appoint a practicing
and licensed Chartered
Accountant registered with the
ICAI and needs to file form
ADT-1 within 30 days of
incorporation. If the company fails to appoint
an auditor, the company won't
be allowed to commence business. Also, there is a
penalty of Rs. 300 per month. </p>
  <br/><br/>

  <h3>b.) Commencement of Business (Form INC 20 A)</h3>
  <p style={{fontSize:"1rem"}}>The company must open a Bank
Current Account and the
shareholders must deposit the
subscription amount mentioned
in the MOA of the company.
Hence, if the company was to
be incorporated with a paid-up
capital of Rs. 1 lakh, then the
shareholders must deposit Rs. 1
lakh in the Company's bank
account and file the bank
statement with the MCA to
obtain a commencement of
business certificate.

+The commencement of business
certificate in form INC 20A
must be obtained within 180
days of incorporating a
Company.

«In case of failure to obtain this
certificate, there is a penalty of
Rs. 50,000 for the company Rs.
1000 per day for the directors
for each day of default</p>
  <br/><br/>

  <h3>c.) Director DIN KYC (Form DIR-3 KYC)</h3>
  <p style={{fontSize:"1rem"}}>All persons who hold a Director
Identification Number (DIN)
whose DIN is allotted on or
before 31 March must complete
DIN KYC within 30th
September every year to
validate the phone and email
address on record with the
Ministry of Corporate Affairs.
«There's a penalty of Rs. 5000 in
case of failure to file DIN
eKYC.  </p>
  <br/><br/>
 
 <h3>d.) MCA Annual Filings</h3>
 <p style={{fontSize:"1rem"}}>If companies registered in
India must file a copy of the
financial statements with the
Ministry of Corporate Affairs
each financial year. If a
company is incorporated
between January — March, the
company can choose to file the
first MCA annual return as a
part of the next financial year’s
annual filing.

MCA annual return consists of
Form AOC-4 which consists of
balance sheet and profit and
loss account and notes to
accounts thereon

Form MGT-7 which consist of
annual return of the company

and Both these forms must be
digitally signed by the
Directors and a practicing
professional </p>
  <br/><br/>

  <h3>e.) Statutory and Tax Audit</h3>
 <p style={{fontSize:"1rem"}}>Every Private Limited
Company registered under the
Companies Act irrespective of

its sales turnover or nature of
business or capital must have
its book of accounts audited
each financial year

Every Private Limited
Company requires to get the tax
audits under section 44AB
done. The Tax audit under
section 44AB aims to ascertain
the compliance of various
provisions of the Income-tax
Law and the fulfillment of
other requirements of the
Income-Tax Law. The tax audit
limit under section 44AB is
INR 1 Crores. (5 crores where
at least 95 percent turnover is
made on digital transactions.).</p>
  <br/><br/>

  <h3>f.) GST Registration</h3>
 <p style={{fontSize:"1rem"}}>very Private Limited
Company is required to get
their GST Registration within
30 days of Busines
Incorporation in India. GST
Registration is needed when the
tumover is more than INR 40
lakhs or INR 20 lakhs in
Special category states or If the
business supplies goods
intra-state or Provides goods
and services online.</p>
  <br/><br/>

  </div>
  {/* COMPLIANCES SECTION END */}


{/* COMPARISON TABLE START */}
<div>


</div>
{/* COMPARISON TABLE END */}

<hr/>
<h2>Frequently Asked Questions (FAQs)</h2>
{/* FAQ SECTION START */}
<div id="accordion" style={{textAlign:"left",marginTop:"3rem"}}>
  <div class="card">
    <div class="card-header d-flex justify-content-between" id="headingOne" style={{backgroundColor:"#003e73"}}>
      <h5 class="mb-0" style={{fontSize:"1.2rem",color:"#ffffff",padding:"1rem"}}>
      What is a Private Limited Company in india ?
      </h5>
      <div>
        <button id="ques-1-aicon" class="btn btn-icon" onClick={()=>{document.getElementById("ans-1").style.display="block"; document.getElementById("ques-1-aicon").style.display="none"; document.getElementById("ques-1-dicon").style.display="block"; }} >
        <i class="mdi mdi-briefcase-download" style={{color:"#ffffff", fontSize:"2rem", padding:"1rem"}}></i>
        </button>
        <button id="ques-1-dicon" style={{display:"none"}} class="btn btn-icon" onClick={()=>{document.getElementById("ans-1").style.display="none"; document.getElementById("ques-1-dicon").style.display="none"; document.getElementById("ques-1-aicon").style.display="block"; }} >
        <i class="mdi mdi-briefcase-upload" style={{color:"#ffffff", fontSize:"2rem", padding:"1rem"}}></i>
        </button>
        </div>
    </div>
    <div id="ans-1">
      <div class="card-body">
      In India, a private limited company is a type of company that is privately held and has limited liability. It is one of the country’s most popular types of business structures due to its various advantages, including limited liability protection, ease of formation and maintenance, and separate legal entity status.      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-header d-flex justify-content-between" id="headingOne" style={{backgroundColor:"#2379c2"}}>
      <h5 class="mb-0" style={{fontSize:"1.2rem",color:"#ffffff",padding:"1rem"}}>
      What is a Private Limited Company in india ?
      </h5>
      <div>
        <button id="ques-2-aicon" class="btn btn-icon" onClick={()=>{document.getElementById("ans-2").style.display="block"; document.getElementById("ques-2-aicon").style.display="none"; document.getElementById("ques-2-dicon").style.display="block"; }} >
        <i class="mdi mdi-briefcase-download" style={{color:"#ffffff", fontSize:"2rem", padding:"1rem"}}></i>
        </button>
        <button id="ques-2-dicon" class="btn btn-icon" style={{display:"none"}} onClick={()=>{document.getElementById("ans-2").style.display="none"; document.getElementById("ques-2-dicon").style.display="none"; document.getElementById("ques-2-aicon").style.display="block"; }} >
        <i class="mdi mdi-briefcase-upload" style={{color:"#ffffff", fontSize:"2rem", padding:"1rem"}}></i>
        </button>
        </div>
    </div>
    <div id="ans-2" style={{display:"none"}}>
      <div class="card-body">
      In India, a private limited company is a type of company that is privately held and has limited liability. It is one of the country’s most popular types of business structures due to its various advantages, including limited liability protection, ease of formation and maintenance, and separate legal entity status.      </div>
    </div>
  </div>

  
  <div class="card">
    <div class="card-header d-flex justify-content-between" id="headingOne" style={{backgroundColor:"#2379c2"}}>
      <h5 class="mb-0" style={{fontSize:"1.2rem",color:"#ffffff",padding:"1rem"}}>
      What is a Private Limited Company in india ?
      </h5>
      <div>
        <button id="ques-3-aicon" class="btn btn-icon" onClick={()=>{document.getElementById("ans-3").style.display="block"; document.getElementById("ques-3-aicon").style.display="none"; document.getElementById("ques-3-dicon").style.display="block"; }} >
        <i class="mdi mdi-briefcase-download" style={{color:"#ffffff", fontSize:"2rem", padding:"1rem"}}></i>
        </button>
        <button id="ques-3-dicon" class="btn btn-icon" style={{display:"none"}} onClick={()=>{document.getElementById("ans-3").style.display="none"; document.getElementById("ques-3-dicon").style.display="none"; document.getElementById("ques-3-aicon").style.display="block"; }} >
        <i class="mdi mdi-briefcase-upload" style={{color:"#ffffff", fontSize:"2rem", padding:"1rem"}}></i>
        </button>
        </div>
    </div>
    <div id="ans-3" style={{display:"none"}}>
      <div class="card-body">
      In India, a private limited company is a type of company that is privately held and has limited liability. It is one of the country’s most popular types of business structures due to its various advantages, including limited liability protection, ease of formation and maintenance, and separate legal entity status.      </div>
    </div>
  </div>

  
  <div class="card">
    <div class="card-header d-flex justify-content-between" id="headingOne" style={{backgroundColor:"#2379c2"}}>
      <h5 class="mb-0" style={{fontSize:"1.2rem",color:"#ffffff",padding:"1rem"}}>
      What is a Private Limited Company in india ?
      </h5>
      <div>
        <button id="ques-4-aicon" class="btn btn-icon" onClick={()=>{document.getElementById("ans-4").style.display="block"; document.getElementById("ques-4-aicon").style.display="none"; document.getElementById("ques-4-dicon").style.display="block"; }} >
        <i class="mdi mdi-briefcase-download" style={{color:"#ffffff", fontSize:"2rem", padding:"1rem"}}></i>
        </button>
        <button id="ques-4-dicon" class="btn btn-icon" style={{display:"none"}} onClick={()=>{document.getElementById("ans-4").style.display="none"; document.getElementById("ques-4-dicon").style.display="none"; document.getElementById("ques-4-aicon").style.display="block"; }} >
        <i class="mdi mdi-briefcase-upload" style={{color:"#ffffff", fontSize:"2rem", padding:"1rem"}}></i>
        </button>
        </div>
    </div>
    <div id="ans-4" style={{display:"none"}}>
      <div class="card-body">
      In India, a private limited company is a type of company that is privately held and has limited liability. It is one of the country’s most popular types of business structures due to its various advantages, including limited liability protection, ease of formation and maintenance, and separate legal entity status.      </div>
    </div>
  </div>

  
  <div class="card">
    <div class="card-header d-flex justify-content-between" id="headingOne" style={{backgroundColor:"#2379c2"}}>
      <h5 class="mb-0" style={{fontSize:"1.2rem",color:"#ffffff",padding:"1rem"}}>
      What is a Private Limited Company in india ?
      </h5>
      <div>
        <button id="ques-5-aicon" class="btn btn-icon" onClick={()=>{document.getElementById("ans-5").style.display="block"; document.getElementById("ques-5-aicon").style.display="none"; document.getElementById("ques-5-dicon").style.display="block"; }} >
        <i class="mdi mdi-briefcase-download" style={{color:"#ffffff", fontSize:"2rem", padding:"1rem"}}></i>
        </button>
        <button id="ques-5-dicon" class="btn btn-icon" style={{display:"none"}} onClick={()=>{document.getElementById("ans-5").style.display="none"; document.getElementById("ques-5-dicon").style.display="none"; document.getElementById("ques-5-aicon").style.display="block"; }} >
        <i class="mdi mdi-briefcase-upload" style={{color:"#ffffff", fontSize:"2rem", padding:"1rem"}}></i>
        </button>
        </div>
    </div>
    <div id="ans-5" style={{display:"none"}}>
      <div class="card-body">
      In India, a private limited company is a type of company that is privately held and has limited liability. It is one of the country’s most popular types of business structures due to its various advantages, including limited liability protection, ease of formation and maintenance, and separate legal entity status.      </div>
    </div>
  </div>

  
  <div class="card">
    <div class="card-header d-flex justify-content-between" id="headingOne" style={{backgroundColor:"#2379c2"}}>
      <h5 class="mb-0" style={{fontSize:"1.2rem",color:"#ffffff",padding:"1rem"}}>
      What is a Private Limited Company in india ?
      </h5>
      <div>
        <button id="ques-6-aicon" class="btn btn-icon" onClick={()=>{document.getElementById("ans-6").style.display="block"; document.getElementById("ques-6-aicon").style.display="none"; document.getElementById("ques-6-dicon").style.display="block"; }} >
        <i class="mdi mdi-briefcase-download" style={{color:"#ffffff", fontSize:"2rem", padding:"1rem"}}></i>
        </button>
        <button id="ques-6-dicon" class="btn btn-icon" style={{display:"none"}} onClick={()=>{document.getElementById("ans-6").style.display="none"; document.getElementById("ques-6-dicon").style.display="none"; document.getElementById("ques-6-aicon").style.display="block"; }} >
        <i class="mdi mdi-briefcase-upload" style={{color:"#ffffff", fontSize:"2rem", padding:"1rem"}}></i>
        </button>
        </div>
    </div>
    <div id="ans-6" style={{display:"none"}}>
      <div class="card-body">
      In India, a private limited company is a type of company that is privately held and has limited liability. It is one of the country’s most popular types of business structures due to its various advantages, including limited liability protection, ease of formation and maintenance, and separate legal entity status.      </div>
    </div>
  </div>


</div>
{/* FAQ SECTION END */}

<br/>
<br/>
<br/>
<br/>

</div></div></div>
{/* SIDEBAR AND MAIN PAGE END */}

<Footer3 />

    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
     </>
    );

}

export default Pagedummy;