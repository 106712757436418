import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import Navbar from './navbar';
import Leftsidebar from './leftsidebar';
import Rightsidebar from './rightsidebar.js';



//import '../hireportalcss.css';
//import '../Hireportalcss/hireportalcss.css';

//Importing Page css with screen sizes
import '../Hireportalcss/sdefault.css';
import '../Hireportalcss/s1200.css';
import '../Hireportalcss/s920.css';
import '../Hireportalcss/s768.css';
import '../Hireportalcss/s600.css';
import '../Hireportalcss/sanimation.css';







function Candidatecompanies(){

    return(

        <>
        <div class="default-view">
        <Navbar/>
        {/* MAIN SECTION START */}
        <div class="hire-main-section">
        <Leftsidebar/>
        {/* MAIN PANEL START */}
      <div class="hire-main-panel">
      <h2>Companies</h2>
        {/* SEARCH BAR START */}
        <div class="hire-search-bar">

        </div>
        {/* SEARCH BAR END */}
   
        {/* LIST PANEL START */}
        <div class="list-panel">
        <div style={{width:"100%",height:"2vh"}}></div>
       
        {/* HIRE LIST START */}  
        <div class="hire-list">
            
        {/* LIST CARD 1 START */}
        <div class="hire-list-card">
        <div class="hire-list-card-top">
        <div class="hire-list-card-top-l"><h3>ABC Enterprises</h3></div>
         <div class="hire-list-card-top-r"><p>ABC Enterprises Private Limited</p></div>
        </div>
        <div class="hire-list-card-area">
        <div class="hire-list-card-area-l">
        <div class="hire-list-card-salarycard">
          <p><b>Introduction</b></p>
          <p>Passionate Full Stack Web Developer with a proven track record of creating robust,
            user-friendly, and visually appealing web applications. Proficient in both front-end and
            back-end technologies, I thrive in collaborative environments to turn innovative ideas
            into functional, scalable, and efficient digital solutions.
            <br/>
            Passionate Full Stack Web Developer with a proven track record of creating robust,
            user-friendly, and visually appealing web applications.</p>
        </div>
        </div> 
        <div class="hire-list-card-area-r">

        <div class="hire-list-card-badge">
          <span><b>Sector : </b>Marketing</span>
        </div>

        <div class="hire-list-card-badge">
          <span><b>Industry: </b>IT/ Tech</span>
        </div>

        <div class="hire-list-card-badge">
          <span><b>Category : </b>Category</span>
        </div>

        <div class="hire-list-card-badge">
          <span><b>Sub Category: </b>IT/ Tech</span>
        </div>

        <div class="hire-list-card-badge" style={{backgroundColor:"#20ce8c",color:"#ffffff"}}>
          <span><b>Status : </b>active</span>
        </div>

        <div class="hire-list-card-badge" style={{backgroundColor:"#ffffff",boxShadow:"none"}}>
        <button style={{backgroundColor:"#ffffff",border:"none"}}><img src="https://cdn-icons-png.flaticon.com/128/9073/9073064.png" class="hire-card-icon" /></button>
        </div>

        </div>  
        </div>
        <div class="hire-list-card-btn-list">

        <div>
          <button class="hire-btn-default">View Jobs</button>
          <button class="hire-btn-default" style={{backgroundColor:"#7813d4"}} >View Full Profile</button>

        </div>

      
        
        <div class="hire-list-card-function">
        <div class="hire-list-card-function-component">
        <div class="hire-list-card-function-component-l">
        <button><img src="https://cdn-icons-png.flaticon.com/128/2107/2107845.png" /></button></div>
        <div class="hire-list-card-function-component-r"><span><b>45</b></span></div>
        </div>
        </div>

        <div class="hire-list-card-function">
        <div class="hire-list-card-function-component">
        <div class="hire-list-card-function-component-l">
        <button><img src="https://cdn-icons-png.flaticon.com/128/5186/5186087.png" /></button></div>
        <div class="hire-list-card-function-component-r"><span><b>45</b></span></div>
        </div>
        </div>

        

        </div>

        <div class="hire-list-card-bottom">
          <p><b>Date Added :</b> 23-01-2022</p>
        </div>

        <div style={{height:"0.5rem"}}></div>
        </div>

        {/* LIST CARD 1 END */}


        {/* LIST CARD 2 START */}
        <div class="hire-list-card">
        <div class="hire-list-card-top">
        <div class="hire-list-card-top-l"><h3>ABC Enterprises</h3></div>
         <div class="hire-list-card-top-r"><p>ABC Enterprises Private Limited</p></div>
        </div>
        <div class="hire-list-card-area">
        <div class="hire-list-card-area-l">
        <div class="hire-list-card-salarycard">
          <p><b>Introduction</b></p>
          <p>Passionate Full Stack Web Developer with a proven track record of creating robust,
            user-friendly, and visually appealing web applications. Proficient in both front-end and
            back-end technologies, I thrive in collaborative environments to turn innovative ideas
            into functional, scalable, and efficient digital solutions.
            <br/>
            Passionate Full Stack Web Developer with a proven track record of creating robust,
            user-friendly, and visually appealing web applications.</p>
        </div>
        </div> 
        <div class="hire-list-card-area-r">

        <div class="hire-list-card-badge">
          <span><b>Sector : </b>Marketing</span>
        </div>

        <div class="hire-list-card-badge">
          <span><b>Industry: </b>IT/ Tech</span>
        </div>

        <div class="hire-list-card-badge">
          <span><b>Category : </b>Category</span>
        </div>

        <div class="hire-list-card-badge">
          <span><b>Sub Category: </b>IT/ Tech</span>
        </div>

        <div class="hire-list-card-badge" style={{backgroundColor:"#20ce8c",color:"#ffffff"}}>
          <span><b>Status : </b>active</span>
        </div>

        <div class="hire-list-card-badge" style={{backgroundColor:"#ffffff",boxShadow:"none"}}>
        <button style={{backgroundColor:"#ffffff",border:"none"}}><img src="https://cdn-icons-png.flaticon.com/128/9073/9073064.png" class="hire-card-icon" /></button>
        </div>

        </div>  
        </div>
        <div class="hire-list-card-btn-list">

        <div>
          <button class="hire-btn-default">View Jobs</button>
          <button class="hire-btn-default" style={{backgroundColor:"#7813d4"}} >View Full Profile</button>

        </div>

      
        
        <div class="hire-list-card-function">
        <div class="hire-list-card-function-component">
        <div class="hire-list-card-function-component-l">
        <button><img src="https://cdn-icons-png.flaticon.com/128/2107/2107845.png" /></button></div>
        <div class="hire-list-card-function-component-r"><span><b>45</b></span></div>
        </div>
        </div>

        <div class="hire-list-card-function">
        <div class="hire-list-card-function-component">
        <div class="hire-list-card-function-component-l">
        <button><img src="https://cdn-icons-png.flaticon.com/128/5186/5186087.png" /></button></div>
        <div class="hire-list-card-function-component-r"><span><b>45</b></span></div>
        </div>
        </div>

        

        </div>

        <div class="hire-list-card-bottom">
          <p><b>Date Added :</b> 23-01-2022</p>
        </div>

        <div style={{height:"0.5rem"}}></div>
        </div>

        {/* LIST CARD 2 END */}

        {/* LIST CARD 3 START */}
        <div class="hire-list-card">
        <div class="hire-list-card-top">
        <div class="hire-list-card-top-l"><h3>ABC Enterprises</h3></div>
         <div class="hire-list-card-top-r"><p>ABC Enterprises Private Limited</p></div>
        </div>
        <div class="hire-list-card-area">
        <div class="hire-list-card-area-l">
        <div class="hire-list-card-salarycard">
          <p><b>Introduction</b></p>
          <p>Passionate Full Stack Web Developer with a proven track record of creating robust,
            user-friendly, and visually appealing web applications. Proficient in both front-end and
            back-end technologies, I thrive in collaborative environments to turn innovative ideas
            into functional, scalable, and efficient digital solutions.
            <br/>
            Passionate Full Stack Web Developer with a proven track record of creating robust,
            user-friendly, and visually appealing web applications.</p>
        </div>
        </div> 
        <div class="hire-list-card-area-r">

        <div class="hire-list-card-badge">
          <span><b>Sector : </b>Marketing</span>
        </div>

        <div class="hire-list-card-badge">
          <span><b>Industry: </b>IT/ Tech</span>
        </div>

        <div class="hire-list-card-badge">
          <span><b>Category : </b>Category</span>
        </div>

        <div class="hire-list-card-badge">
          <span><b>Sub Category: </b>IT/ Tech</span>
        </div>

        <div class="hire-list-card-badge" style={{backgroundColor:"#20ce8c",color:"#ffffff"}}>
          <span><b>Status : </b>active</span>
        </div>

        <div class="hire-list-card-badge" style={{backgroundColor:"#ffffff",boxShadow:"none"}}>
        <button style={{backgroundColor:"#ffffff",border:"none"}}><img src="https://cdn-icons-png.flaticon.com/128/9073/9073064.png" class="hire-card-icon" /></button>
        </div>

        </div>  
        </div>
        <div class="hire-list-card-btn-list">

        <div>
          <button class="hire-btn-default">View Jobs</button>
          <button class="hire-btn-default" style={{backgroundColor:"#7813d4"}} >View Full Profile</button>

        </div>

      
        
        <div class="hire-list-card-function">
        <div class="hire-list-card-function-component">
        <div class="hire-list-card-function-component-l">
        <button><img src="https://cdn-icons-png.flaticon.com/128/2107/2107845.png" /></button></div>
        <div class="hire-list-card-function-component-r"><span><b>45</b></span></div>
        </div>
        </div>

        <div class="hire-list-card-function">
        <div class="hire-list-card-function-component">
        <div class="hire-list-card-function-component-l">
        <button><img src="https://cdn-icons-png.flaticon.com/128/5186/5186087.png" /></button></div>
        <div class="hire-list-card-function-component-r"><span><b>45</b></span></div>
        </div>
        </div>

        

        </div>

        <div class="hire-list-card-bottom">
          <p><b>Date Added :</b> 23-01-2022</p>
        </div>

        <div style={{height:"0.5rem"}}></div>
        </div>

        {/* LIST CARD 3 END */}


        {/* LIST CARD 4 START */}
        <div class="hire-list-card">
        <div class="hire-list-card-top">
        <div class="hire-list-card-top-l"><h3>ABC Enterprises</h3></div>
         <div class="hire-list-card-top-r"><p>ABC Enterprises Private Limited</p></div>
        </div>
        <div class="hire-list-card-area">
        <div class="hire-list-card-area-l">
        <div class="hire-list-card-salarycard">
          <p><b>Introduction</b></p>
          <p>Passionate Full Stack Web Developer with a proven track record of creating robust,
            user-friendly, and visually appealing web applications. Proficient in both front-end and
            back-end technologies, I thrive in collaborative environments to turn innovative ideas
            into functional, scalable, and efficient digital solutions.
            <br/>
            Passionate Full Stack Web Developer with a proven track record of creating robust,
            user-friendly, and visually appealing web applications.</p>
        </div>
        </div> 
        <div class="hire-list-card-area-r">

        <div class="hire-list-card-badge">
          <span><b>Sector : </b>Marketing</span>
        </div>

        <div class="hire-list-card-badge">
          <span><b>Industry: </b>IT/ Tech</span>
        </div>

        <div class="hire-list-card-badge">
          <span><b>Category : </b>Category</span>
        </div>

        <div class="hire-list-card-badge">
          <span><b>Sub Category: </b>IT/ Tech</span>
        </div>

        <div class="hire-list-card-badge" style={{backgroundColor:"#20ce8c",color:"#ffffff"}}>
          <span><b>Status : </b>active</span>
        </div>

        <div class="hire-list-card-badge" style={{backgroundColor:"#ffffff",boxShadow:"none"}}>
        <button style={{backgroundColor:"#ffffff",border:"none"}}><img src="https://cdn-icons-png.flaticon.com/128/9073/9073064.png" class="hire-card-icon" /></button>
        </div>

        </div>  
        </div>
        <div class="hire-list-card-btn-list">

        <div>
          <button class="hire-btn-default">View Jobs</button>
          <button class="hire-btn-default" style={{backgroundColor:"#7813d4"}} >View Full Profile</button>

        </div>

      
        
        <div class="hire-list-card-function">
        <div class="hire-list-card-function-component">
        <div class="hire-list-card-function-component-l">
        <button><img src="https://cdn-icons-png.flaticon.com/128/2107/2107845.png" /></button></div>
        <div class="hire-list-card-function-component-r"><span><b>45</b></span></div>
        </div>
        </div>

        <div class="hire-list-card-function">
        <div class="hire-list-card-function-component">
        <div class="hire-list-card-function-component-l">
        <button><img src="https://cdn-icons-png.flaticon.com/128/5186/5186087.png" /></button></div>
        <div class="hire-list-card-function-component-r"><span><b>45</b></span></div>
        </div>
        </div>

        

        </div>

        <div class="hire-list-card-bottom">
          <p><b>Date Added :</b> 23-01-2022</p>
        </div>

        <div style={{height:"0.5rem"}}></div>
        </div>

        {/* LIST CARD 4 END */}

        {/* LIST CARD 5 START */}
        <div class="hire-list-card">
        <div class="hire-list-card-top">
        <div class="hire-list-card-top-l"><h3>ABC Enterprises</h3></div>
         <div class="hire-list-card-top-r"><p>ABC Enterprises Private Limited</p></div>
        </div>
        <div class="hire-list-card-area">
        <div class="hire-list-card-area-l">
        <div class="hire-list-card-salarycard">
          <p><b>Introduction</b></p>
          <p>Passionate Full Stack Web Developer with a proven track record of creating robust,
            user-friendly, and visually appealing web applications. Proficient in both front-end and
            back-end technologies, I thrive in collaborative environments to turn innovative ideas
            into functional, scalable, and efficient digital solutions.
            <br/>
            Passionate Full Stack Web Developer with a proven track record of creating robust,
            user-friendly, and visually appealing web applications.</p>
        </div>
        </div> 
        <div class="hire-list-card-area-r">

        <div class="hire-list-card-badge">
          <span><b>Sector : </b>Marketing</span>
        </div>

        <div class="hire-list-card-badge">
          <span><b>Industry: </b>IT/ Tech</span>
        </div>

        <div class="hire-list-card-badge">
          <span><b>Category : </b>Category</span>
        </div>

        <div class="hire-list-card-badge">
          <span><b>Sub Category: </b>IT/ Tech</span>
        </div>

        <div class="hire-list-card-badge" style={{backgroundColor:"#20ce8c",color:"#ffffff"}}>
          <span><b>Status : </b>active</span>
        </div>

        <div class="hire-list-card-badge" style={{backgroundColor:"#ffffff",boxShadow:"none"}}>
        <button style={{backgroundColor:"#ffffff",border:"none"}}><img src="https://cdn-icons-png.flaticon.com/128/9073/9073064.png" class="hire-card-icon" /></button>
        </div>

        </div>  
        </div>
        <div class="hire-list-card-btn-list">

        <div>
          <button class="hire-btn-default">View Jobs</button>
          <button class="hire-btn-default" style={{backgroundColor:"#7813d4"}} >View Full Profile</button>

        </div>

      
        
        <div class="hire-list-card-function">
        <div class="hire-list-card-function-component">
        <div class="hire-list-card-function-component-l">
        <button><img src="https://cdn-icons-png.flaticon.com/128/2107/2107845.png" /></button></div>
        <div class="hire-list-card-function-component-r"><span><b>45</b></span></div>
        </div>
        </div>

        <div class="hire-list-card-function">
        <div class="hire-list-card-function-component">
        <div class="hire-list-card-function-component-l">
        <button><img src="https://cdn-icons-png.flaticon.com/128/5186/5186087.png" /></button></div>
        <div class="hire-list-card-function-component-r"><span><b>45</b></span></div>
        </div>
        </div>

        

        </div>

        <div class="hire-list-card-bottom">
          <p><b>Date Added :</b> 23-01-2022</p>
        </div>

        <div style={{height:"0.5rem"}}></div>
        </div>

        {/* LIST CARD 5 END */}
       
        </div>
        {/* HIRE LIST END */}  
        </div>
        {/* LIST PANEL END */}

        </div>
        {/* MAIN PANEL END */} 

        <Rightsidebar/>

        </div>
        {/* MAIN SECTION END */}

        </div>

        </>
    );




}


export default Candidatecompanies;