import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import Navbar from './navbar';



//import '../hireportalcss.css';
//import '../Hireportalcss/hireportalcss.css';

//Importing Page css with screen sizes
import '../Hireportalcss/sdefault.css';
import '../Hireportalcss/s1200.css';
import '../Hireportalcss/s920.css';
import '../Hireportalcss/s768.css';
import '../Hireportalcss/s600.css';
import '../Hireportalcss/sanimation.css';



function Employerinvitations(){

    return(

    <>
        <div class="default-view">
        <Navbar/>
        {/* MAIN SECTION START */}
        <div class="hire-main-section">

        {/* FORM CONTAINER START */}
        <div class="hire-form-container">
        
        {/* FORM START */}
        <div class="hire-form-panel" style={{backgroundColor:"#ffffff"}}>
        <h3>Sent Invitations</h3>
      
          
        {/* TABLE CONTAINER START */}
        <div class="hire-table-container">
            <table class="hire-table-1">
                <thead>
                <tr>
                <th>Invitation Id</th>
                <th>Date Sent</th>
                <th>Candidate Name</th>
                <th>Position</th>
                <th>Status</th>
                <th>Delete</th>
                </tr> 
                </thead>
                <tbody>
                <tr>
                <td>INVT0921</td>
                <td>21-12-2022</td>
                <td>Suraj Kumar Sharma</td>
                <td>Project Manager</td>
                <td>Invitation Sent</td>
                <td><img src="https://cdn-icons-png.flaticon.com/128/9794/9794237.png" class="hire-card-icon" /></td>
                </tr> 
                <tr>
                <td>INVT0921</td>
                <td>21-12-2022</td>
                <td>Suraj Kumar Sharma</td>
                <td>Project Manager</td>
                <td>Invitation Sent</td>
                <td><img src="https://cdn-icons-png.flaticon.com/128/9794/9794237.png" class="hire-card-icon" /></td>
                </tr> 
                <tr>
                <td>INVT0921</td>
                <td>21-12-2022</td>
                <td>Suraj Kumar Sharma</td>
                <td>Project Manager</td>
                <td>Invitation Sent</td>
                <td><img src="https://cdn-icons-png.flaticon.com/128/9794/9794237.png" class="hire-card-icon" /></td>
                </tr> 
                <tr>
                <td>INVT0921</td>
                <td>21-12-2022</td>
                <td>Suraj Kumar Sharma</td>
                <td>Project Manager</td>
                <td>Invitation Sent</td>
                <td><img src="https://cdn-icons-png.flaticon.com/128/9794/9794237.png" class="hire-card-icon" /></td>
                </tr> 
                <tr>
                <td>INVT0921</td>
                <td>21-12-2022</td>
                <td>Suraj Kumar Sharma</td>
                <td>Project Manager</td>
                <td>Invitation Sent</td>
                <td><img src="https://cdn-icons-png.flaticon.com/128/9794/9794237.png" class="hire-card-icon" /></td>
                </tr> 
                <tr>
                <td>INVT0921</td>
                <td>21-12-2022</td>
                <td>Suraj Kumar Sharma</td>
                <td>Project Manager</td>
                <td>Invitation Sent</td>
                <td><img src="https://cdn-icons-png.flaticon.com/128/9794/9794237.png" class="hire-card-icon" /></td>
                </tr> 
                </tbody>
            </table>
        </div>
        {/* TABLE CONTAINER END */}

        <div class="hire-list-card-badge" style={{backgroundColor:"#ffffff",boxShadow:"none"}}>
        <img src="https://cdn-icons-png.flaticon.com/128/189/189253.png" class="hire-card-icon" style={{marginLeft:"100%"}} />
        </div>


        </div>
         {/* FORM END */}


        


        </div>
        {/* FORM CONTAINER END */}

        </div>
        {/* MAIN SECTION END */}

        </div>

        </>
    );




}


export default Employerinvitations;