import React from "react";
import Sidebar from "./sidebar";

function Team_projectdashboard(){
  return (
    <>
<html lang="en">
  <head>
    {/* Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Startup Connect | Services Portal</title>
    {/* plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/* endinject */}
    {/* Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/* End plugin css for this page */}
    {/* inject:css */}
    {/* endinject */}
    {/* Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/* End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
    </head>
    <body>
    {/* container-scroller */}
    <div class="container-scroller">

     

     <Sidebar /> 
      <div class="container-fluid page-body-wrapper">

 {/*} partial */}
 <div class="main-panel">



   <div class="content-wrapper pb-0">

      <div className="feedbacks main-panel float-end">
        <div className="content-wrapper d-flex flex-column">
          <div className="d-flex User align-center justify-content-center">
            <h1>Project Dashboard</h1>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <form className="form-sample" action="">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Project ID
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="42"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Project Title
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="E Commerce Website"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Start Date
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="2023-08-30"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            End Date
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="----/--/--"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Status
                          </label>
                          <div className="col-sm-9">
                            <label className="badge badge-danger">
                              Pending
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Progress
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="----/--/--"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                      <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Description
                          </label>
                          <div className="col-sm-9">
                            <textarea
                              className="form-control"
                              placeholder="Yes it works"
                              disabled
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="button mx-auto">
            <button className="btn-lg btn-primary"> + Create New Task</button>
          </div>
        </div>
          
          </div>
          </div>
          </div>

          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}

     

{/* plugins:js */}
<script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
{/* endinject */}
{/* Plugin js for this page */}
<script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
<script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
<script src="panel/assets/vendors/flot/jquery.flot.js"></script>
<script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
<script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
<script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
<script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
<script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
{/* End plugin js for this page */}
{/* inject:js */}
<script src="panel/assets/js/off-canvas.js"></script>
<script src="panel/assets/js/hoverable-collapse.js"></script>
<script src="panel/assets/js/misc.js"></script>
<script src="panel/assets/js/settings.js"></script>
<script src="panel/assets/js/todolist.js"></script>
{/* endinject */}
{/* Custom js for this page */}
<script src="panel/assets/js/dashboard.js"></script>
{/* End custom js for this page */}
</body>
</html>    

    </>
  );
};

export default Team_projectdashboard;
