import React from "react";
import Sidebar from "./sidebar";

function Team_feedbacks(){
  return (
    <>
    
    
<html lang="en">
  <head>
    {/* Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Startup Connect | Services Portal</title>
    {/* plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/* endinject */}
    {/* Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/* End plugin css for this page */}
    {/* inject:css */}
    {/* endinject */}
    {/* Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/* End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
    </head>
    <body>
    {/* container-scroller */}
    <div class="container-scroller">

    
    
    <Sidebar /> 
      <div class="container-fluid page-body-wrapper">

 {/*} partial */}
 <div class="main-panel">



   <div class="content-wrapper pb-0">

   <div className="feedbacks main-panel float-end">
        <div className="content-wrapper d-flex flex-column">
        <div className="d-flex User align-center justify-content-center"><h1>CRM Team Dashboard</h1></div>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title d-flex justify-content-center">Feedbacks</h4>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Feedback ID</th>
                          <th>Date</th>
                          <th>Heading</th>
                          <th>Feedback</th>
                          <th>Rating</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>ABC123</td>
                          <td>2023-09-04</td>
                          <td>Customer usage</td>
                          <td>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque, sit?</td>
                          <td>9</td>
                        </tr>
                        <tr>
                            <td>AFG342</td>
                            <td>2023-12-23</td>
                            <td>Identity issue</td>
                            <td>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque, sit?</td>
                            <td>7</td>
                        </tr>
                        
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      


      </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
      </div>

{/* plugins:js */}
<script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
{/* endinject */}
{/* Plugin js for this page */}
<script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
<script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
<script src="panel/assets/vendors/flot/jquery.flot.js"></script>
<script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
<script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
<script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
<script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
<script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
{/* End plugin js for this page */}
{/* inject:js */}
<script src="panel/assets/js/off-canvas.js"></script>
<script src="panel/assets/js/hoverable-collapse.js"></script>
<script src="panel/assets/js/misc.js"></script>
<script src="panel/assets/js/settings.js"></script>
<script src="panel/assets/js/todolist.js"></script>
{/* endinject */}
{/* Custom js for this page */}
<script src="panel/assets/js/dashboard.js"></script>
{/* End custom js for this page */}
</body>
</html>    
    </>
  );
};

export default Team_feedbacks;
