import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import '../Resources/dashboard.css';
import Navbar from '../navbar';
import Sidebar from '../Sidebar';
import Debtor_pay from './debtor_pay';

function Userdebtor(){

return(
    <>
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Business Tools|BookKeeping Solution</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div class="container-scroller">

      <Sidebar />
    <div class="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div class="main-panel">
          <div class="content-wrapper pb-0">
            <div class="page-header flex-wrap">
            <div class="header-left"></div>
              <div class="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div class="d-flex align-items-center">
                  <a href="#">
                    <p class="m-0 pe-3">Debtors</p>
                  </a>
                  <a class="ps-3 me-4" href="#">
                    <p class="m-0">ADE-00234</p>
                  </a>
                </div>
              </div>
            </div>

            {/*} chart row starts here */}
            <div class="row">
              <div class="col-sm-6 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      <div class="card-title">
                      </div>
                      <div class="d-flex text-muted font-20">
                        <i class="mdi mdi-printer mouse-pointer"></i>
                        <i class="mdi mdi-help-circle-outline ms-2 mouse-pointer"></i>
                      </div>
                    </div>
                    <h3 class="font-weight-bold mb-0"> 2,409 <span class="text-success h5">4,5%<i class="mdi mdi-arrow-up"></i></span>
                    </h3>
                    <span class="text-muted font-13">Debtor /Day</span>
                    <div class="line-chart-wrapper">
                      <canvas id="linechart" height="80"></canvas>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      <div class="card-title"> Conversions
                      </div>
                      <div class="d-flex text-muted font-20">
                        <i class="mdi mdi-printer mouse-pointer"></i>
                        <i class="mdi mdi-help-circle-outline ms-2 mouse-pointer"></i>
                      </div>
                    </div>
                    <h3 class="font-weight-bold mb-0"> 0.40% <span class="text-success h5">0.20%<i class="mdi mdi-arrow-up"></i></span>
                    </h3>
                    <span class="text-muted font-13">Debtor /Day</span>
                    <div class="bar-chart-wrapper">
                      <canvas id="barchart" height="80"></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 grid-margin create-form" id="create-form">
              <Debtor_pay /> 
              </div>
          </div>

                  
             <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Debtors</h4>
                    <p class="card-description">debtor details
                    </p>
                    <div class="table-responsive">
                      <table class="table table-hover">
                        <thead>
                          <tr >
                            <th  style={{fontWeight:"bold"}}>Sn</th>
                            <th  style={{fontWeight:"bold"}}>Debtor Id</th>
                            <th  style={{fontWeight:"bold"}}>Debtor Name</th>
                            <th  style={{fontWeight:"bold"}}>Transactions</th>
                            <th  style={{fontWeight:"bold"}}>Paid</th>
                            <th  style={{fontWeight:"bold"}}>Due</th>
                            <th  style={{fontWeight:"bold"}}>Balance</th>
                            <th  style={{fontWeight:"bold"}}>Interest</th>
                            <th  style={{fontWeight:"bold"}}>Discount</th>
                            <th  style={{fontWeight:"bold"}}>Status</th>
                            <th  style={{fontWeight:"bold"}}></th>
                          </tr>
                        </thead>
                        <tbody>
                       
                        <tr>
                            <td>24</td>
                            <td>D654</td>
                            <td>Suman Kumar Sharma</td>
                            <td>21</td>
                            <td>5400.00</td>
                            <td>3400.00</td>
                            <td>2000.00</td>
                            <td>400.00</td>
                            <td>300.00</td>
                            <td><label class="badge badge-danger">due</label></td>
                            <td><button class="btn btn-primary" onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>Pay</button></td>
                          </tr>
                          <tr>
                            <td>24</td>
                            <td>D654</td>
                            <td>Suman Kumar Sharma</td>
                            <td>21</td>
                            <td>5400.00</td>
                            <td>3400.00</td>
                            <td>2000.00</td>
                            <td>400.00</td>
                            <td>300.00</td>
                            <td><label class="badge badge-danger">due</label></td>
                            <td><button class="btn btn-primary" onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>Pay</button></td>
                          </tr>
                          <tr>
                            <td>24</td>
                            <td>D654</td>
                            <td>Suman Kumar Sharma</td>
                            <td>21</td>
                            <td>5400.00</td>
                            <td>3400.00</td>
                            <td>2000.00</td>
                            <td>400.00</td>
                            <td>300.00</td>
                            <td><label class="badge badge-danger">due</label></td>
                            <td><button class="btn btn-primary" onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>Pay</button></td>
                          </tr>
                          <tr>
                            <td>24</td>
                            <td>D654</td>
                            <td>Suman Kumar Sharma</td>
                            <td>21</td>
                            <td>5400.00</td>
                            <td>3400.00</td>
                            <td>2000.00</td>
                            <td>400.00</td>
                            <td>300.00</td>
                            <td><label class="badge badge-danger">due</label></td>
                            <td><button class="btn btn-primary" onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>Pay</button></td>
                          </tr>
                          <tr>
                            <td>24</td>
                            <td>D654</td>
                            <td>Suman Kumar Sharma</td>
                            <td>21</td>
                            <td>5400.00</td>
                            <td>3400.00</td>
                            <td>2000.00</td>
                            <td>400.00</td>
                            <td>300.00</td>
                            <td><label class="badge badge-danger">due</label></td>
                            <td><button class="btn btn-primary" onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>Pay</button></td>
                          </tr>
                          <tr>
                            <td>24</td>
                            <td>D654</td>
                            <td>Suman Kumar Sharma</td>
                            <td>21</td>
                            <td>5400.00</td>
                            <td>3400.00</td>
                            <td>2000.00</td>
                            <td>400.00</td>
                            <td>300.00</td>
                            <td><label class="badge badge-danger">due</label></td>
                            <td><button class="btn btn-primary" onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>Pay</button></td>
                          </tr>
                          <tr>
                            <td>24</td>
                            <td>D654</td>
                            <td>Suman Kumar Sharma</td>
                            <td>21</td>
                            <td>5400.00</td>
                            <td>3400.00</td>
                            <td>2000.00</td>
                            <td>400.00</td>
                            <td>300.00</td>
                            <td><label class="badge badge-danger">due</label></td>
                            <td><button class="btn btn-primary" onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>Pay</button></td>
                          </tr>
                          <tr>
                            <td>24</td>
                            <td>D654</td>
                            <td>Suman Kumar Sharma</td>
                            <td>21</td>
                            <td>5400.00</td>
                            <td>3400.00</td>
                            <td>2000.00</td>
                            <td>400.00</td>
                            <td>300.00</td>
                            <td><label class="badge badge-danger">due</label></td>
                            <td><button class="btn btn-primary" onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>Pay</button></td>
                          </tr>
                          
                         
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

            
            
              <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Debtors Transaction History</h4>
                    <p class="card-description">debtors transaction history
                    </p>
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr >
                            <th  style={{fontWeight:"bold"}}>Sn</th>
                            <th  style={{fontWeight:"bold"}}>Tr No.</th>
                            <th  style={{fontWeight:"bold"}}>Voucher No.</th>
                            <th  style={{fontWeight:"bold"}}>Date</th>
                            <th  style={{fontWeight:"bold"}}>Transaction</th>
                            <th style={{fontWeight:"bold"}}>Type</th>
                            <th  style={{fontWeight:"bold"}}>Particular</th>
                            <th  style={{fontWeight:"bold"}}>Debtor Name</th>
                            <th style={{fontWeight:"bold"}}>Amount</th>
                            <th  style={{fontWeight:"bold"}}>Interest</th>
                            <th  style={{fontWeight:"bold"}}>Discount</th>
                            <th  style={{fontWeight:"bold"}}>SGST</th>
                            <th  style={{fontWeight:"bold"}}>CGST</th>
                            <th  style={{fontWeight:"bold"}}>IGST</th>
                            <th  style={{fontWeight:"bold"}}>Tax</th>
                            <th style={{fontWeight:"bold"}}>Total</th>
                            <th  style={{fontWeight:"bold"}}>Updated By</th>
                            <th  style={{fontWeight:"bold"}}>Updated At</th>
                            <th  style={{fontWeight:"bold"}}></th>
                            <th  style={{fontWeight:"bold"}}></th>
                          </tr>
                        </thead>
                        <tbody>
                       
                        <tr>
                            <td>24</td>
                            <td>654</td>
                            <td>VN65544</td>
                            <td>23-08-2023</td>
                            <td><label class="badge badge-success">Debtor Payment</label></td>
                            <td>Bank (ICICI Bank)</td>
                            <td>this is debtor payment made</td>
                            <td>Suman Kumar Sharma</td>
                            <td>1600.00</td>
                            <td>500.00</td>
                            <td>400.00</td>
                            <td>00.00</td>
                            <td>00.00</td>
                            <td>00.00</td>
                            <td>00.00</td>
                            <td>2500.00</td>
                            <td>Sumit (Team Id : AA877766)</td>
                            <td>23-07-2023 (23:00pm)</td>
                            <td><i class="mdi mdi-pencil-box text-warning" style={{fontSize:"1.4rem"}}></i></td>
                            <td><i class="mdi mdi-delete-forever text-danger" style={{fontSize:"1.4rem"}}></i></td>
                          </tr>
                          <tr>
                            <td>24</td>
                            <td>654</td>
                            <td>VN65544</td>
                            <td>23-08-2023</td>
                            <td><label class="badge badge-warning">Debit</label></td>
                            <td>Debit</td>
                            <td>this is debtor payment made</td>
                            <td>Suman Kumar Sharma</td>
                            <td>1600.00</td>
                            <td>500.00</td>
                            <td>400.00</td>
                            <td>00.00</td>
                            <td>00.00</td>
                            <td>00.00</td>
                            <td>00.00</td>
                            <td>2500.00</td>
                            <td>Sumit (Team Id : AA877766)</td>
                            <td>23-07-2023 (23:00pm)</td>
                            <td><i class="mdi mdi-pencil-box text-warning" style={{fontSize:"1.4rem"}}></i></td>
                            <td><i class="mdi mdi-delete-forever text-danger" style={{fontSize:"1.4rem"}}></i></td>
                          </tr>
                          <tr>
                            <td>24</td>
                            <td>654</td>
                            <td>VN65544</td>
                            <td>23-08-2023</td>
                            <td><label class="badge badge-success">Debtor Payment</label></td>
                            <td>Bank (ICICI Bank)</td>
                            <td>this is debtor payment made</td>
                            <td>Suman Kumar Sharma</td>
                            <td>1600.00</td>
                            <td>500.00</td>
                            <td>400.00</td>
                            <td>00.00</td>
                            <td>00.00</td>
                            <td>00.00</td>
                            <td>00.00</td>
                            <td>2500.00</td>
                            <td>Sumit (Team Id : AA877766)</td>
                            <td>23-07-2023 (23:00pm)</td>
                            <td><i class="mdi mdi-pencil-box text-warning" style={{fontSize:"1.4rem"}}></i></td>
                            <td><i class="mdi mdi-delete-forever text-danger" style={{fontSize:"1.4rem"}}></i></td>
                          </tr>
                          <tr>
                            <td>24</td>
                            <td>654</td>
                            <td>VN65544</td>
                            <td>23-08-2023</td>
                            <td><label class="badge badge-success">Debtor Payment</label></td>
                            <td>Bank (ICICI Bank)</td>
                            <td>this is debtor payment made</td>
                            <td>Suman Kumar Sharma</td>
                            <td>1600.00</td>
                            <td>500.00</td>
                            <td>400.00</td>
                            <td>00.00</td>
                            <td>00.00</td>
                            <td>00.00</td>
                            <td>00.00</td>
                            <td>2500.00</td>
                            <td>Sumit (Team Id : AA877766)</td>
                            <td>23-07-2023 (23:00pm)</td>
                            <td><i class="mdi mdi-pencil-box text-warning" style={{fontSize:"1.4rem"}}></i></td>
                            <td><i class="mdi mdi-delete-forever text-danger" style={{fontSize:"1.4rem"}}></i></td>
                          </tr>
                          <tr>
                            <td>24</td>
                            <td>654</td>
                            <td>VN65544</td>
                            <td>23-08-2023</td>
                            <td><label class="badge badge-warning">Debit</label></td>
                            <td>Debit</td>
                            <td>this is debtor payment made</td>
                            <td>Suman Kumar Sharma</td>
                            <td>1600.00</td>
                            <td>500.00</td>
                            <td>400.00</td>
                            <td>00.00</td>
                            <td>00.00</td>
                            <td>00.00</td>
                            <td>00.00</td>
                            <td>2500.00</td>
                            <td>Sumit (Team Id : AA877766)</td>
                            <td>23-07-2023 (23:00pm)</td>
                            <td><i class="mdi mdi-pencil-box text-warning" style={{fontSize:"1.4rem"}}></i></td>
                            <td><i class="mdi mdi-delete-forever text-danger" style={{fontSize:"1.4rem"}}></i></td>
                          </tr>
                          <tr>
                            <td>24</td>
                            <td>654</td>
                            <td>VN65544</td>
                            <td>23-08-2023</td>
                            <td><label class="badge badge-success">Debtor Payment</label></td>
                            <td>Bank (ICICI Bank)</td>
                            <td>this is debtor payment made</td>
                            <td>Suman Kumar Sharma</td>
                            <td>1600.00</td>
                            <td>500.00</td>
                            <td>400.00</td>
                            <td>00.00</td>
                            <td>00.00</td>
                            <td>00.00</td>
                            <td>00.00</td>
                            <td>2500.00</td>
                            <td>Sumit (Team Id : AA877766)</td>
                            <td>23-07-2023 (23:00pm)</td>
                            <td><i class="mdi mdi-pencil-box text-warning" style={{fontSize:"1.4rem"}}></i></td>
                            <td><i class="mdi mdi-delete-forever text-danger" style={{fontSize:"1.4rem"}}></i></td>
                          </tr>
                          <tr>
                            <td>24</td>
                            <td>654</td>
                            <td>VN65544</td>
                            <td>23-08-2023</td>
                            <td><label class="badge badge-success">Debtor Payment</label></td>
                            <td>Bank (ICICI Bank)</td>
                            <td>this is debtor payment made</td>
                            <td>Suman Kumar Sharma</td>
                            <td>1600.00</td>
                            <td>500.00</td>
                            <td>400.00</td>
                            <td>00.00</td>
                            <td>00.00</td>
                            <td>00.00</td>
                            <td>00.00</td>
                            <td>2500.00</td>
                            <td>Sumit (Team Id : AA877766)</td>
                            <td>23-07-2023 (23:00pm)</td>
                            <td><i class="mdi mdi-pencil-box text-warning" style={{fontSize:"1.4rem"}}></i></td>
                            <td><i class="mdi mdi-delete-forever text-danger" style={{fontSize:"1.4rem"}}></i></td>
                          </tr>
                          <tr>
                            <td>24</td>
                            <td>654</td>
                            <td>VN65544</td>
                            <td>23-08-2023</td>
                            <td><label class="badge badge-success">Debtor Payment</label></td>
                            <td>Bank (ICICI Bank)</td>
                            <td>this is debtor payment made</td>
                            <td>Suman Kumar Sharma</td>
                            <td>1600.00</td>
                            <td>500.00</td>
                            <td>400.00</td>
                            <td>00.00</td>
                            <td>00.00</td>
                            <td>00.00</td>
                            <td>00.00</td>
                            <td>2500.00</td>
                            <td>Sumit (Team Id : AA877766)</td>
                            <td>23-07-2023 (23:00pm)</td>
                            <td><i class="mdi mdi-pencil-box text-warning" style={{fontSize:"1.4rem"}}></i></td>
                            <td><i class="mdi mdi-delete-forever text-danger" style={{fontSize:"1.4rem"}}></i></td>
                          </tr>
                         
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

            
          </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer class="footer">
            <div class="d-sm-flex justify-content-center justify-content-sm-between">
              <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
</>
);

}
export default Userdebtor;