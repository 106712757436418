import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from 'axios';


function CandidateprofileEducationaldetails(){

  

    return(
<>

        {/* EDUCATIONAL DETAILS SECTION START */}
        <div class="hire-form-panel" style={{backgroundColor:"#ffffff",display:"none"}} id="educational-details-section">
            <h3>Educational Details</h3>
            <p style={{color:"#acafb0"}}>Contains educational details and qualification information that will be displayed in your profile and detailed resume.</p>
           
           <div class="hire-form-list">
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Course Name</div>
           <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Bachelor's in Science" /></div>
           </div>
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Course Type</div>
           <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Full Time" /> 
           </div>
           </div>
           </div>

           <div class="hire-form-list">
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Industry</div>
           <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Computers" /></div>
           </div>
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Sector</div>
           <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Web Development" /> 
           </div>
           </div>
           </div>


           <div class="hire-form-list">
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Accredation</div>
           <div class="hire-form-group-right"><select class="hire-form-group-input">
           <option>Percentage</option>
           <option>C.G.P.A</option>
           <option>Marks</option>
            </select>
           </div>
           </div>
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Type Value</div>
           <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : 84" /> 
           </div>
           </div>
           </div>


           <div class="hire-form-list">
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Institute Name</div>
           <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : ABC Institute of Technology" />
           </div>
           </div>
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>City</div>
           <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Jabalpur" /> 
           </div>
           </div>
           </div>

           <div class="hire-form-list">
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>State</div>
           <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Madhya Pradesh" />
           </div>
           </div>
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Country</div>
           <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : India" /> 
           </div>
           </div>
           </div>


           <div class="hire-form-list">
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Course Start Date</div>
           <div class="hire-form-group-right"><input type="date" class="hire-form-group-input"  />
           </div>
           </div>
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Course End Date</div>
           <div class="hire-form-group-right"><input type="date" class="hire-form-group-input"  /> 
           </div>
           </div>
           </div>

           <div class="hire-form-btn">
           <button style={{backgroundColor:"#ffffff",border:"none", marginTop:"2rem"}}><img src="https://cdn-icons-png.flaticon.com/128/10337/10337579.png" class="hire-card-icon" style={{height:"4rem",width:"4rem",}} /></button>
           </div>

           <div class="hire-form-btn">
            <button class="hire-btn-default" style={{backgroundColor:"#12d571",marginRight:"2rem",marginTop:"2rem",marginBottom:"2rem"}}
            onClick={()=>{ document.getElementById("educational-details-section").style.display="none";
            document.getElementById("skills-section").style.display="block";
             }}
            >Save and Next</button>
            </div>
           
            </div>
            {/* EDUCATIONAL DETAILS SECTION END */}
          
              
</>
);
    }

export default CandidateprofileEducationaldetails;