import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios';
import Navbar from '../Extracomponents/navbar.js';
import Sidebar from '../Extracomponents/sidebar.js';
import {useRef as useref} from 'react';
import {useReactToPrint} from 'react-to-print';



/*import Amanbg from './amanbg';*/


function Adminquotation(){


      //Invoice Print Function
      const componentRef = useref();
      const handlePrint = useReactToPrint({
        content: () => componentRef.current,
      });
  
         // Setting Values fom Session Storage
 const logstatus=sessionStorage.getItem("loginstatus00");//loginstatus
 const loguser=sessionStorage.getItem("username00");//username
 const logtype=sessionStorage.getItem("logintype00");//logintype
 const logname=sessionStorage.getItem("loginname00");//loginname
 const logadminid=sessionStorage.getItem("loginadminid00");//loginadminid
 //const logteamid=sessionStorage.getItem("loginteamid00");//loginteamid
   // Setting Values fom Session Storage

 const load_val = sessionStorage.getItem("loadVal010");//loadVal
 
 //Variables
 const[data,setData] = useState([]);//SetData Variable
 const[data1,setData1] = useState([]);//SetData Variable
 //Error Variable
 const[errorvar,seterrorvar] = useState();
 //Error Variable
 const[errorvar1,seterrorvar1] = useState();


 //Basic Details
 const[quotation_id,setquotation_id] = useState(); //quotation_id 
 const[quotation_no,setquotation_no] = useState(); //quotation_no
 const[date,setdate] = useState(); //date

 //Client Details
 const[client_id,setclient_id] = useState(); //client_id
 const[client_name,setclient_name] = useState(); //client_name
 const[client_contact,setclient_contact] = useState(); //client_contact     
 const[client_email,setclient_email] = useState(); //client_email
 const[client_gstin,setclient_gstin] = useState(); //client_gstin
 const[client_address,setclient_address] = useState(); //client_address
 const[client_city,setclient_city] = useState(); //client_city
 const[client_state,setclient_state] = useState(); //client_state

 //Payment Details
 const[payment_id,setpayment_id] = useState(); //payment_id
 const[payee_name,setpayee_name] = useState(); //payee_name
 const[payment_mode,setpayment_mode] = useState(); //payment_mode
 const[transaction_no,settransaction_no] = useState(); //transaction_no
 const[payment_details,setpayment_details] = useState(); //payment_details
 const[payment_date,setpayment_date] = useState(); //payment_date
 const[payment_time,setpayment_time] = useState(); //client_state
 const[payment_status,setpayment_status] = useState(); //payment_status

 //Quotation Items
 const[quotation_items,setquotation_items] = useState([]); //quotation_items

 //Extra/ Final
 const[amount,setamount] = useState([]); //amount
 const[discount_per,setdiscount_per] = useState([]); //discount_per
 const[discount_amt,setdiscount_amt] = useState([]); //discount_amt
 const[gst_per,setgst_per] = useState([]); //gst_per
 const[gst_amt,setgst_amt] = useState([]); //gst_amt
 const[other_ded,setother_ded] = useState([]); //other_ded
 const[other_exp,setother_exp] = useState([]); //quotation_items
 const[total,settotal] = useState([]); //total
 const[amt_words,setamt_words] = useState([]); //amt_words
 const[items,setitems] = useState([]); //items
 const[created_by,setcreated_by] = useState([]); //created_by
 const[date_created,setdate_created] = useState([]); //date_created
 const[time_created,settime_created] = useState([]); //time_created
 const[member_id,setmember_id] = useState([]); //member_id


    //Sessions Not Set
  {/*} if(((logstatus=="false")||(logstatus==null))&&(loguser==null)&&(load_val>0)){
 
      sessionStorage.removeItem("username00");
      sessionStorage.removeItem("loginstatus00");
      sessionStorage.removeItem("logintype00");
      sessionStorage.removeItem("loginname00");
      sessionStorage.removeItem("loginadminid00");
     
      alert("Session Expired! Login Again.");
      window.location.replace("/");
    
    }
    //Sessions Set
  else{
  */}
     
      

  //Use Effect, Data Load
  useEffect(()=>{

    getData();

},[])

//Fetching Team Members
function getData(){
  

  //Checking if user loggedin
if(((logstatus=="true")||(logstatus!=null))&&(loguser!=null)&&(logadminid!=null)&&(load_val>0)){


 
 axios.post("services-view-quotation", {
    //Basic Details
    User:loguser,
    Logtype:logtype,
    Log_id:logadminid,
    Logstatus:"true",
    User_id:logadminid,
    Account_type:logtype,
    Load_val:load_val,
    Operation:"getdata",

 })
 .then((response) => {
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   //setData(response.data.payment_list);
  // console.log(response.data);
  //setdatacount(response.data.datacount);
   //setloadcount(response.data.loadcount);
   //setloadcount(1); 
 
   //Set Basic Details
   setquotation_id(response.data.quotation_id);
   setquotation_no(response.data.quotation_no);
   setdate(response.data.date);
   //Set Client Details
   setclient_id(response.data.client_id);
   setclient_name(response.data.client_name);
   setclient_email(response.data.client_email);
   setclient_contact(response.data.client_contact);
   setclient_gstin(response.data.client_gstin);
   setclient_address(response.data.client_address);
   setclient_city(response.data.client_city);
   setclient_state(response.data.client_state);
   //Set Payment Details
   setpayment_id(response.data.payment_id);
   setpayee_name(response.data.payee_name);
   setpayment_mode(response.data.payment_mode);
   settransaction_no(response.data.transaction_no);
   setpayment_details(response.data.payment_details);
   setpayment_date(response.data.payment_date);
   setpayment_time(response.data.payment_time);
   setpayment_status(response.data.payment_status);
   //Set Quotation Items
   setquotation_items(response.data.quotation_items);
   //Extra/ Final
   setitems(response.data.items);
   setamount(response.data.amount);
   setdiscount_per(response.data.discount_per);
   setdiscount_amt(response.data.discount_amt);
   setgst_per(response.data.gst_per);
   setgst_amt(response.data.gst_amt);
   setother_ded(response.data.other_ded);
   setother_exp(response.data.other_exp);
   setamt_words(response.data.amt_words);
   settotal(response.data.total);
   setmember_id(response.data.member_id);
   setcreated_by(response.data.created_by);
   setdate_created(response.data.date_created);
   settime_created(response.data.time_created);

  }
  //Cannot Fetch Data
  else{
   //seterrorvar1("Cannot Fetch Data !");
   alert("Cannot Fetch Data !");
  }
 

 });

 }
//User not Logedin
else{

 sessionStorage.removeItem("username00");
 sessionStorage.removeItem("loginstatus00");
 sessionStorage.removeItem("logintype00");
 sessionStorage.removeItem("loginname00");
 sessionStorage.removeItem("loginadminid00");

 alert("Session Expired! Login Again.");
 window.location.replace("/");

  }

}





    return(
<>
{/*<!DOCTYPE html> */}
<html lang="en">
  <head>
    {/* Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>theStartupConnect | Portal</title>
    {/* plugins:css */}
    <link rel="stylesheet" href="./assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="./assets/vendors/css/vendor.bundle.base.css" />
    {/* endinject */}
    {/* Plugin css for this page */}
    {/* End plugin css for this page */}
    {/* inject:css */}
    {/* endinject */}
    {/* Layout styles */}
    <link rel="stylesheet" href="./assets/css/style.css" />
    {/* End layout styles */}
   {/*} <link rel="shortcut icon" href="./assets/images/favicon.ico" /> */}
  </head>
  <body>

  


<Navbar/>




{/* MAIN PANEL START */}
<div class="main-panel" style={{marginTop:"5vh",width:"100vw"}}>


{/*  QUOTATION DETAILS SECTION START  */}
<div class="content-wrapper">







<h2 class="text-center mb-2">Quotation</h2>
{/*
<div class="col-12">
                <div class="card">
                  <div class="card-body">
               
               <div class="invoice" style={{padding:"2rem",width:"90%",marginLeft:"5%",marginTop:"5%", border:"0.1rem solid black"}}>
               <h3><u>Quotation</u></h3>
               <div style={{backgroundColor:"black",padding:"0.1rem",marginTop:"0.5rem",marginBottom:"0.5rem",width:"100%"}}></div>
                <div class="invoice-top" style={{width:"100%",padding:"1rem",display:"flex",flexDirection:"row"}}>
               <div class="invoice-top-left" style={{width:"30%"}}>
                <img src="assets/images/logo-a.jpeg" id="invoice-top-img" style={{}} />
               </div> 
               <div style={{width:"70%",marginTop:"1rem"}}>
               <h1>Technomate Startup Services Private Limited</h1>
               <div style={{display:"flex",flexDirection:"row"}}>
               <div style={{width:"50%"}}>
               <h4><b>Contact :</b>+91 9899999999</h4>
               </div>
               <div style={{width:"50%",textAlign:"left"}}>
               <h4><b>Email :</b> help.startupconnect@gmail.com</h4>
               </div>
               </div>
               </div>
               </div>
               </div> 
                  
                  </div>
                  </div>
                  </div>
*/}
                  
<div class="col-12">
                <div class="card">
                  <div class="card-body">
               <div class="invoice" style={{padding:"2rem",width:"90%",marginLeft:"5%",marginTop:"5%", border:"0.1rem solid black"}} id="invoice" ref={componentRef}> 
               <div style={{backgroundColor:"#e6eef1",padding:"0.1rem"}}><h6 style={{marginLeft:"1rem",marginTop:"0.5rem",textAlign:"left"}}>#Quotation Id : {quotation_id}</h6></div>     
 
               <h3 style={{textAlign:"center"}}><u>Quotation</u></h3>
               <div style={{backgroundColor:"black",padding:"0.1rem",marginTop:"0.5rem",marginBottom:"0.5rem",width:"100%"}}></div>
                <div class="invoice-top" style={{width:"100%",padding:"1rem",display:"flex",flexDirection:"row"}}>
                <div style={{width:"70%",marginTop:"1rem"}}>
               <h1 style={{textAlign:"left"}}>Technomate Startup Services Private Limited</h1>
               <div style={{display:"flex",flexDirection:"row"}}>
               <div style={{width:"50%",textAlign:"left"}}>
               <h4><b>Contact :</b>+91 9899999999</h4>
               </div>
              <div style={{width:"50%",textAlign:"left"}}>
               <h4><b>Email :</b> help.startupconnect@gmail.com</h4>
               </div> 
               </div>
               <h4 style={{textAlign:"left"}}><b>Email :</b> help.startupconnect@gmail.com</h4>
               <h4 style={{textAlign:"left"}}><b>Website :</b>https://www.thestartupconnect.com</h4>
               </div>
               <div class="invoice-top-left" style={{width:"30%"}}>
                <img src="assets/images/logo-a.jpeg" id="invoice-top-img" style={{height:"11rem",width:"11rem",marginLeft:"6rem"}} />
               </div> 
        
               </div>
               <div style={{marginTop:"1rem",width:"100%",backgroundColor:"#006992",padding:"0.1rem",marginBottom:"1rem"}}></div>

               <div class="quotation-header" style={{textAlign:"left"}}>
               <div style={{display:"flex",flexDirection:"row"}}>
               <div style={{width:"50%",textAlign:"left"}}>
                <h4><b>Quotation No. :</b> {quotation_no}</h4>
                </div>
                <div style={{width:"50%",textAlign:"left"}}>
                <h4 style={{textAlign:"right"}}><b>Date :</b> {date}</h4>
                </div>
                </div>
               
               <div style={{display:"flex",flexDirection:"row"}}>
               <div style={{width:"50%",textAlign:"left"}}>
               <h6 style={{marginTop:"1rem"}}><b>For</b></h6>
                <h6>{client_name}</h6>
                <h6>Contact : +91 {client_contact}</h6>
                <h6>Email : {client_email}</h6>
                <h6>Address : {client_address}</h6>
                <h6 style={{marginLeft:"5rem"}}>{client_city} ({client_state})</h6>
                <h6>GSTIN : {client_gstin}</h6>

               </div>
               <div style={{width:"50%",textAlign:"left"}}>
               <h6 style={{marginTop:"1rem"}}><b>Payment Details</b></h6>
                <h6>Payment Mode : {payment_mode}</h6>
                <h6>Payee Name : {payee_name}</h6>
                <h6>Transaction Number : {transaction_no}</h6>
                <h6>Bank Account/ UPI ID : {payment_details}</h6>
                <h6>Date & Time: {payment_date} - {payment_time}</h6>
                <h6>Payment Status : {payment_status} <span style={{color:"#c92a46"}}>{payment_status}</span>/ <span style={{color:"#04a15c"}}>{payment_status}</span></h6>
                
               </div>
               </div>


               </div>
               <div class="invoice-area" style={{borderTop:"0.2rem solid #006992",marginTop:"2rem",overflowX:"scroll"}}>
               <table class="table table-striped">
  <thead>
    <tr>
      <th >#</th>
      <th >Heading</th>
      <th >Quantity</th>
      <th >Rate</th>
      <th >Amount</th>
    </tr>
  </thead>
  <tbody >
  {
                       quotation_items.map((curElm,ind)=>{

return(
  <>
    <tr key={curElm.Item_id}>
      <th scope="row">{curElm.Sno}</th>
      <td>{curElm.Heading}</td>
      <td>{curElm.Quantity} {curElm.Details}</td>
      <td>{curElm.Rate}</td>
      <td>{curElm.Amount}</td>
    </tr>
             
    </>
      )
      }
					   
			)}
  </tbody>
</table>
               </div>
               <div class="total-area" style={{textAlign:"left",marginTop:"4rem"}}>
               <div style={{display:"flex",flexDirection:"row"}}>
               <div style={{width:"50%",textAlign:"left"}}>
                     <table class="table bordered">
                     <thead>
                     </thead>
                      <tbody>
                      <tr style={{padding:"1rem"}}>
                        <td><b>Sub Total</b></td>
                        <td></td>
                        <td><b>{amount}</b></td>
                        </tr>
                      <tr style={{padding:"1rem"}}>
                        <td >Discount @ {discount_per}%</td>
                        <td></td>
                        <td>{discount_amt}</td>
                        </tr>
                        <tr style={{padding:"1rem"}}>
                        <td >GST @ {gst_per}%</td>
                        <td></td>
                        <td>{gst_amt}</td>
                        </tr>
                        <tr style={{padding:"1rem"}}>
                        <td >Other Expenses</td>
                        <td></td>
                        <td>{other_exp}</td>
                        </tr>
                        <tr style={{padding:"1rem"}}>
                        <td >Other Deductions</td>
                        <td></td>
                        <td>{other_ded}</td>
                        </tr>
                        <tr style={{padding:"1rem",borderTop:"0.2rem solid #006992",marginTop:"2rem"}}>
                        <td><b>Net Total(Payable)</b></td>
                        <td></td>
                        <td><b>{total}</b></td>
                        </tr>
                      
                      </tbody>
                     </table>
                     </div>
                     <div style={{width:"50%",textAlign:"left"}}>
                    
                    <div style={{marginLeft:"7rem"}}><h6>Amount in Words</h6>
                     <p>{amt_words}</p>
                     </div> 
                     </div>
                     </div>
               </div>

               <div style={{marginTop:"3rem",textAlign:"right"}}>
               <h6><b>Generated By : </b>{created_by}</h6>  
               <h6 style={{marginLeft:"3rem"}}> Member Id : MEMB{member_id}</h6>  
               <p style={{marginLeft:"3rem"}}> {date_created}({time_created})</p>  
               </div>
               <div class="invoice-footer" style={{borderTop:"0.3rem solid #006992",marginTop:"1rem"}}>
                <h6 style={{marginTop:"1rem",textAlign:"center"}}>This is computer generated invoice with digitally sign and does not require signature and seal</h6>
                <h6 style={{textAlign:"left"}}><b>Note:- </b></h6>
                      <ol>
                        <li style={{textAlign:"left"}}>Please refer to our Terms and Conditions for further details.</li>
                        <li style={{textAlign:"left"}}>We don't authorize our Team Representatives to collect payments in cash, cheque or demand draft.</li>
                        <li style={{textAlign:"left"}}>We collect Payment only in our Company's Bank Account (Technomate Startup Services Private Limited) in form of UPI, RTGS, IMPS, NEFT, Debit/ Credit Cards and any other internet banking methods only.</li>
                        <li style={{textAlign:"left"}}><b>Company's Account Number :</b> 87750200001707, (<b>Bank of Baroda, IFSC code :</b> BARB0DBCJAB)</li>

                      </ol>
               </div>
               <div class="invoice-footer" style={{backgroundColor:"#006992",marginTop:"1rem",color:"#ffffff",padding:"1rem"}}>
                <h4 style={{textAlign:"center"}}>Thanks for Choosing us as your Service Partner!</h4>
               </div>
               </div> 
               <button type="button" style={{backgroundColor:"#04a15c",marginTop:"2rem"}} class="btn btn-secondary btn-icon-text"> <i class="mdi mdi-arrow-left-bold"></i>Back 
                          </button>
               <button type="button" style={{backgroundColor:"#196fb0",marginTop:"2rem",marginLeft:"2rem"}} class="btn btn-secondary btn-icon-text" onClick={handlePrint}> Print <i class="mdi mdi-file-document"></i>
                          </button>
                  </div>
               
                  </div>
                  </div>

</div>
{/* MAIN PANEL END */}

</div>
{/* MAIN PAGE COMPONENT END */}

    {/* container-scroller */}
    {/* plugins:js */}
    <script src="./assets/vendors/js/vendor.bundle.base.js"></script>
    {/* endinject */}
    {/* Plugin js for this page */}
    {/* End plugin js for this page */}
    {/* inject:js */}
    <script src="./assets/js/off-canvas.js"></script>
    <script src="./assets/js/hoverable-collapse.js"></script>
    <script src="./assets/js/misc.js"></script>

    {/*} plugins:js */}
    <script src="assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="assets/vendors/chart.js/Chart.min.js"></script>
    <script src="assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="assets/js/off-canvas.js"></script>
    <script src="assets/js/hoverable-collapse.js"></script>
    <script src="assets/js/misc.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="assets/js/dashboard.js"></script>
    <script src="assets/js/todolist.js"></script>
    {/*} End custom js for this page */}
    {/* endinject */}
  </body>
</html>
</>
    );
}
//}

export default Adminquotation;