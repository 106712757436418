import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import moment from 'moment/moment';




function Sidebar1(){


  const logname=sessionStorage.getItem("logName00");

return(  
    
    <>
    
    {/* partial:partials/_sidebar.html */}


      <nav class="sidebar sidebar-offcanvas" id="sidebar">
        <ul class="nav">
          <li class="nav-item nav-profile border-bottom">
            <a href="#" class="nav-link flex-column">
              <div class="nav-profile-image">
                <img src="../assets/images/faces/face1.jpg" alt="profile" />
                {/*change to offline or busy as needed*/}
              </div>
              <div class="nav-profile-text d-flex ms-0 mb-3 flex-column">
                <span class="font-weight-semibold mb-1 mt-2 text-center">{logname}</span>
              {/*  <span class="text-secondary icon-sm text-center">$3499.00</span> */}
              </div>
            </a>
          </li>
       {/*}   <li class="nav-item pt-3">
            <a class="nav-link d-block" href="index.html">
              <img class="sidebar-brand-logo" src="../assets/images/logo.svg" alt="" />
              <img class="sidebar-brand-logomini" src="../assets/images/logo-mini.svg" alt="" />
              <div class="small font-weight-light pt-1">Responsive Dashboard</div>
            </a>
         {/*}   <form class="d-flex align-items-center" action="#">
              <div class="input-group">
                <div class="input-group-prepend">
                  <i class="input-group-text border-0 mdi mdi-magnify"></i>
                </div>
                <input type="text" class="form-control border-0" placeholder="Search" />
              </div>
            </form>
          </li> 
          </ul>  </nav>
          <li class="pt-2 pb-1">
            <span class="nav-item-head">Template Pages</span>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="index.html">
              <i class="mdi mdi-compass-outline menu-icon"></i>
              <span class="menu-title">Dashboard</span>
            </a>
          </li>
       {/*}   <li class="nav-item">
            <a class="nav-link" data-bs-toggle="collapse" href="#ui-basic" aria-expanded="false" aria-controls="ui-basic">
              <i class="mdi mdi-crosshairs-gps menu-icon"></i>
              <span class="menu-title">UI Elements</span>
              <i class="menu-arrow"></i>
            </a>
            <div class="collapse" id="ui-basic">
              <ul class="nav flex-column sub-menu">
                <li class="nav-item">
                  <a class="nav-link" href="pages/ui-features/buttons.html">Buttons</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="pages/ui-features/dropdowns.html">Dropdowns</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="pages/ui-features/typography.html">Typography</a>
                </li>
              </ul>
            </div>
          </li> */}
        <h3 class="mt-4">Dashboard</h3>
        <li class="nav-item">
            <a class="nav-link" href="/mainadmin-redirectdashboard">
              <i class="mdi mdi-contacts menu-icon"></i>
              <span class="menu-title">Main Dashboard</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/mainadmin-servicesportal-teammanagement">
              <i class="mdi mdi-contacts menu-icon"></i>
              <span class="menu-title">Team</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/mainadmin-servicesportal-customers">
              <i class="mdi mdi-format-list-bulleted menu-icon"></i>
              <span class="menu-title">Customers</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/mainadmin-registrations">
              <i class="mdi mdi-chart-bar menu-icon"></i>
              <span class="menu-title">Registrations</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/mainadmin-loginhistory">
              <i class="mdi mdi-table-large menu-icon"></i>
              <span class="menu-title">Login History</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/mainadmin-salesenquiry">
              <i class="mdi mdi-file-document-box menu-icon"></i>
              <span class="menu-title">Sales Enquiry</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/mainadmin-feedbacks">
              <i class="mdi mdi-file-document-box menu-icon"></i>
              <span class="menu-title">Feedbacks</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/mainadmin-btsupport">
              <i class="mdi mdi-file-document-box menu-icon"></i>
              <span class="menu-title">Support Requests</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/mainadmin-ntwstartups">
              <i class="mdi mdi-file-document-box menu-icon"></i>
              <span class="menu-title">Startup Registrations</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/mainadmin-bttransactions" target="_blank">
              <i class="mdi mdi-file-document-box menu-icon"></i>
              <span class="menu-title">B.T. Transactions</span>
            </a>
          </li>
        </ul>
      </nav>
      {/* partial */}


      </>
);

//}
}
export default Sidebar1;
