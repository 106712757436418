import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from 'axios';


function CandidateprofilePersonalinfo(){

  

    return(
<>

           {/* PERSONAL DETAILS SECTION START */}
            <div class="hire-form-panel" style={{backgroundColor:"#ffffff"}} id="personal-info-section">
            <h3>Personal Information</h3>
            <p style={{color:"#acafb0"}}>Contains personal information that will be displayed in your profile and detailed resume.</p>

            <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>First Name</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Sohan" /></div>
            </div>
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Last Name</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Kumar Verma" /> 
            </div>
            </div>
            </div>

            <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Contact Number</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : +91 33332 33333" /></div>
            </div>
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Email</div>
            <div class="hire-form-group-right"><input type="email" class="hire-form-group-input" placeholder="For e.g. : sohankumar345@gmail.com" /> 
            </div>
            </div>
            </div>

            <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Whatsapp Number</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : +91 33332 33333" /></div>
            </div>
            <div class="hire-form-group-small">
            <input type="checkbox" style={{marginLeft:"1rem"}} /><label style={{marginLeft:"2rem",padding:"1rem"}}>Same as Contact Number</label>
            </div>
            </div>
            
            <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Mother's Name</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Rohini Kumar Verma" /></div>
            </div>
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Father's Name</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Rohan Kumar Verma" /> 
            </div>
            </div>
            </div>

            <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Gender</div>
            <div class="hire-form-group-right"><select class="hire-form-group-input">
            <option>Select Gender</option>
            <option>Male</option>
            <option>Female</option>
            <option>Rather not Say</option>
            </select>
            </div>
            </div>
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Date of Birth</div>
            <div class="hire-form-group-right"><input type="date" class="hire-form-group-input" /> 
            </div>
            </div>
            </div>

            <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Age</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : 27" /></div>
            </div>
            <div class="hire-form-group-small">
            <label style={{marginLeft:"2rem"}}>Any Sibling</label>
            <input type="radio" style={{marginLeft:"10rem"}} /><label style={{marginLeft:"2rem",padding:"1rem"}}>Yes</label>
            <input type="radio" style={{marginLeft:"1rem"}} /><label style={{marginLeft:"2rem",padding:"1rem"}}>No</label>
            </div>
            </div>

            <div class="hire-form-btn">
            <button class="hire-btn-default" style={{backgroundColor:"#12d571",marginRight:"2rem",marginTop:"2rem",marginBottom:"2rem"}}
            onClick={()=>{ document.getElementById("personal-info-section").style.display="none";
            document.getElementById("address-section").style.display="block";
             }}
            >Save and Next</button>
            </div>
         


            </div>
            {/* PERSONAL DETAILS SECTION END */}
          
              
</>
);
    }

export default CandidateprofilePersonalinfo;