import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import ServicesCards from './components/ServicesCards/ServicesCards'
import AllFeatures from './components/AllFeatures/AllFeatures';
import Contact from './homecontactform';

import './homeabh.css';
import Amanhd from './amanhead';



function Homeab(){

    //saving default values with usestate
  const state = useState();
  
    
  //name
  const[cname,setcname]=useState();const updatecname = (event) => { setcname(event.target.value);};
  //email
  const[email,setemail]=useState();const updateemail = (event) => { setemail(event.target.value);};
  //contact
  const[contact,setcontact]=useState();const updatecontact = (event) => { setcontact(event.target.value);};
  //cstate
  const[cstate,setcstate]=useState();const updatecstate = (event) => { setcstate(event.target.value);};
  
  
  const [searchString, setSearchString] = useState();
  
  function callfunction(){
  
  /*alert("Hello");*/
  }
      return(
      <>


<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />

    <meta name="theme-color" content="#000000" />
    <meta
      name="description"
      content="Get your startup services relating to website development, consultancy, taxation, business registration, software services, intern hiring, social media management, digital marketing and much more.  "></meta>
    
   {/* <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />*/}
        <link rel="icon" href="./images/startupconnect_icon.ico" />
    <link rel="apple-touch-icon" href="./images/startupconnect_icon.png" />

    {/*} Bootstrap CSS */}
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous" />
 

    <title>theStartupConnect | Services for Business and Startup</title>
  </head>
  <body>
  <div className="searchbar-dropdown"></div>
   {/* WHATSAPP ICON START */}
   <div className="whatsapp-section">
<div>
  <a href="https://api.whatsapp.com/send?phone=9399395256"><img src="https://cdn-icons-png.flaticon.com/512/2504/2504957.png" id="whatsapp-icon"></img></a>
</div>
</div>
   {/* WHATSAPP SECTION END */}
    <div className="home-container">

{/* HEADER */}



{/* HEADER SECTION START */}
<div className="header-section">

{/* HEADER CONTENT START */}
<div className="header-content">
{/* }
{/* Animation Circles Start */}


{/* FEATURES SECTION END */}

<div class="area">
<Amanhd></Amanhd>

                 </div>
</div>

{/* CONTACT FORM START 
<div className="contact-form">
<h5>Get Free Consultancy by Expert.</h5>
{/*<div id="contact-form-ln"></div>*
<input type="email" className="form-input" id="form-name" placeholder="Enter your Name" value={cname} onChange={(event) => { setcname(event.target.value);}} />
<input type="text" className="form-input" id="form-contact" value={contact} onChange={updatecontact} placeholder="Enter your Contact" />
<input type="text" className="form-input" id="form-email" value={email} onChange={updateemail} placeholder="Enter your Email" />
<input type="text" className="form-input" id="form-cstate" value={cstate} onChange={updatecstate} placeholder="Enter your State" />
<button type="button" id="contact-submit" onClick={callfunction()}>GET STARTED NOW</button>
</div>


{/* CONTACT FORM END */}

 <Contact></Contact> 
 
</div>


{/* HEADER SECTION END */}








{/* }footer end */}
{/*<script src="https://code.jquery.com/jquery-3.3.1.slim.min.js" integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo" crossorigin="anonymous"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js" integrity="sha384-UO2eT0CpHqdSJQ6hJty5KVphtPhzWj9WO1clHTMGa3JDZwrnQq4sF86dIHNDz0W1" crossorigin="anonymous"></script>
<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js" integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM" crossorigin="anonymous"></script>
  
  {/* } Font Awesome 5 links */}
  <script src="https://kit.fontawesome.com/fddf5c0916.js" crossorigin="anonymous"></script>

</div>



    {/*} Optional JavaScript; choose one of the two! */}

    {/*} Option 1: Bootstrap Bundle with Popper */}
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>

    {/*} Option 2: Separate Popper and Bootstrap JS */}
    {/*}
    <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js" integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p" crossorigin="anonymous"></script>
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.min.js" integrity="sha384-cVKIPhGWiC2Al4u+LWgxfKTRIcfu0JTxR+EQDz/bgldoEyl4H0zUF0QKbrJ0EcQF" crossorigin="anonymous"></script>
    */}
  </body>
</html>
</>
      );

}

export default Homeab;