import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from 'axios';


function CandidateprofileOtherlinks(){

  

    return(
<>

            {/* OTHER LINKS SECTION START */}
            <div class="hire-form-panel" style={{backgroundColor:"#ffffff",display:"none"}} id="other-links-section">
            <h3>Other Links</h3>
            <p style={{color:"#acafb0"}}>Add other links that will be displayed in your profile and detailed resume.</p>

           <div class="hire-form-list">
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Link Name</div>
           <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Linked In" /></div>
           </div>
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Link</div>
           <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : https://www.linkedin.com/profile-12345" /> 
           </div>
           </div>
           </div>

           <div class="hire-form-btn">
           <button style={{backgroundColor:"#ffffff",border:"none", marginTop:"2rem"}}><img src="https://cdn-icons-png.flaticon.com/128/10337/10337579.png" class="hire-card-icon" style={{height:"4rem",width:"4rem",}} /></button>
           </div>
          
            <div class="hire-form-btn">
            <button class="hire-btn-default" style={{backgroundColor:"#12d571",marginRight:"2rem",marginTop:"2rem",marginBottom:"2rem"}}
            onClick={()=>{ document.getElementById("achievements-section").style.display="none";
            alert("Form Completed Successfully !");
             }}
            >Save</button>
            </div>
           </div>

            {/* OTHER LINKS SECTION END */}
              
</>
);
    }

export default CandidateprofileOtherlinks;