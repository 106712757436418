import React from "react";
import { useState, useEffect, Component } from "react";
import { useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
//import axios from '../../axios.js';
import moment from "moment/moment";

import "../../Resources/homebtcss.css";
import "../../Resources/home2css.css";
import "../../Resources/serviceshomecss.css";
import "../ankitcss.css";
import '../../Resources/responsiveness.css';

//import Sidebar from "./sidebar";

//import Chart from "chart.js/auto";
//import { Line, Bar } from "react-chartjs-2";
import Footer3 from "../../Supportingfiles/footer3";
//import { Navbar } from "flowbite-react";
import Navbar3 from "../../Supportingfiles/navbar3";
import Whystartupconnect from "../whystartupconnect";
import Comparisontable from "./comparisontable";


function Publiclimitedregistration1() {
  return (
    <>
      <html lang="en">
        <head>
          {/*} Required meta tags */}
          <meta charset="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <title>STARTUP CONNECT | SERVICES | PUBLIC LIMITED REGISTRATION</title>
          {/*} plugins:css */}
          <link
            rel="stylesheet"
            href="panel/assets/vendors/mdi/css/materialdesignicons.min.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/css/vendor.bundle.base.css"
          />
          {/*} endinject */}
          {/*} Plugin css for this page */}
          <link
            rel="stylesheet"
            href="panel/assets/vendors/jquery-bar-rating/css-stars.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/font-awesome/css/font-awesome.min.css"
          />
          {/*} End plugin css for this page */}
          {/*} inject:css */}
          {/*} endinject */}
          {/*} Layout styles */}
          <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
          {/*} End layout styles */}
          <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
        </head>
        <body>

        
  {/* Whatsapp Chat Icon Start */}
  <div class="wtp-chat-container" /*style={{position:"fixed",marginTop:"92vh", marginLeft:"94vw"}}*/>
    <a aria-label="Chat on WhatsApp" href="https://wa.me/918480002413?text=I%20want%20to%20know%20more%20about%20service%20Public%20Limited%20Company%20Registration%20by%20the%20Startup%20Connect">   
    <img class="wtp-chat-icon" src="https://cdn-icons-png.flaticon.com/128/3938/3938058.png" /*style={{height:"3rem", width:"3rem"}}*/></img>
  </a>
  </div>
  {/* Whatsapp Chat Icon End */}

          <div class="home2-container">
            <Navbar3 />

            {/* HEADER SECTION START */}
            <section /*class="sv-header-section" */>
              <div class="sv-header-container1">
                <div class="sv-header-left home-header-pd">
                  <h1 style={{ color: "#004463" }}>
                  PUBLIC LIMITED COMPANY REGISTRATION
                  </h1>
                  <p>
                  <div style={{ marginBottom: "2px" }}>
                      <span style={{ paddingRight: "5px" }}><i class="mdi mdi-checkbox-marked" style={{fontSize:"2rem", color :"#0b5748"}}></i></span>
                      <span>Fast customer support </span>
                    </div>
                    <div style={{ marginBottom: "2px" }}>
                      <span style={{ paddingRight: "5px" }}><i class="mdi mdi-checkbox-marked" style={{fontSize:"2rem", color :"#0b5748"}}></i></span>
                      <span>Complete online process.</span>
                    </div>
                    <div style={{ marginBottom: "2px" }}>
                      <span style={{ paddingRight: "5px" }}><i class="mdi mdi-checkbox-marked" style={{fontSize:"2rem", color :"#0b5748"}}></i></span>
                      <span>Instant WhatsApp/email updates </span>
                    </div>
                  </p>

                  <div class="home2-services-features-list">
                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img loading = "lazy"

                          src="https://cdn-icons-png.flaticon.com/128/1087/1087840.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>500+</h3>
                        <h5 style={{ fontWeight: "bold" }}>Projects</h5>
                      </div>
                    </div>

                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img loading = "lazy"

                          src="https://cdn-icons-png.flaticon.com/128/12735/12735475.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>25+</h3>
                        <h5 style={{ fontWeight: "bold" }}>Cities</h5>
                      </div>
                    </div>

                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img loading = "lazy"

                          src="https://cdn-icons-png.flaticon.com/128/2991/2991235.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>
                          4{" "}
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/7656/7656139.png"
                            style={{ height: "2rem", width: "2rem" }}
                          />
                          +
                        </h3>
                        <h5 style={{ fontWeight: "bold" }}>Google Ratings</h5>
                      </div>
                    </div>
                  </div>

                  <div  class="serviceshome-btn-container">
             <div>
             <a href="https://calendly.com/thestartupconnect/30min"><button class="btn-lft-margin" id="home2-modules-section-btn" style={{marginLeft:"0"}}>Book Free Consultancy <i class="mdi mdi-arrow-right"></i></button></a>
             </div>
             <div style={{marginLeft:"0"}}>
             <a href="/home1/public_limited_registration.pdf" download="Startup Connect Services Brochure"><button class="btn-lft-margin" id="home2-modules-section-btn" style={{backgroundImage: "linear-gradient(to right, #208d17, #00ad11)", marginLeft:"0"}}>Download Brochure <i class="mdi mdi-download"></i></button></a>
             </div>
             </div>
             
                </div>
                <div
                  class="sv-header-right" /* style={{backgroundColor:"#96dfff",padding:"2rem",borderRadius:"15px",boxShadow:"2px 3px 3px 2px #cbcecf"}} */
                >
                  <img loading = "lazy"

                    src="https://img.freepik.com/free-vector/business-people_53876-25442.jpg"
                    style={{ width: "90%", height: "100%" }}
                  />
                </div>
              </div>
            </section>
            {/* HEADER SECTION END */}
            <hr />

            {/* SIDEBAR AND MAIN PAGE START */}
            <div className="container-scroller">
              {/*<Sidebar /> */}
              <div className="container-fluid page-body-wrapper">
                {/*} partial */}
                <div className="main-panel">

                  {/* MORE INFORMATION SECTION START */}
                  <div class="row" style={{ padding: "2rem" }}>
                    <div
                      class="col-sm-9 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div>
                        <h2 id="ip-h2" style={{ fontWeight: "bold" }}>
                          WHAT IS A {" "}
                          <span style={{ color: "#9f23c2" }}>
                          PUBLIC LIMITED COMPANY?
                          </span>
                        </h2>
                        <h4></h4>
                        <br />
                        <div style = {{fontSize:'1rem'}}>
                        <i class="mdi mdi-assistant text-warning" style={{fontSize:"1.4rem",padding:"1rem"}}></i>A Public Limited Company (PLC) is one of the most common types of legal entity in India.
</div>                   <br/>
                      
                        <p style={{ fontSize: "1rem" }}>
                          {" "}
                          <i class="mdi mdi-assistant text-warning" style={{fontSize:"1.4rem",padding:"1rem"}}></i>
                          The Public limited company in India is a voluntary association of members which has a separate legal existence and the liability of whose members is limited.
                        </p>
                        <p style={{ fontSize: "1rem" }}>
                        <i class="mdi mdi-assistant text-warning" style={{fontSize:"1.4rem",padding:"1rem"}}></i>
                        Public Limited Companies are registered with the Ministry of Corporate Affairs (MCA) under the Companies Act, 2013.
                        </p>
                        <p style={{ fontSize: "1rem" }}>
                        <i class="mdi mdi-assistant text-warning" style={{fontSize:"1.4rem",padding:"1rem"}}></i>
                        A Public Limited Company can either be an unlisted Company or listed Company on the Stock Exchange.{" "}
                        </p>
                        <p style={{ fontSize: "1rem" }}>
                        <i class="mdi mdi-assistant text-warning" style={{fontSize:"1.4rem",padding:"1rem"}}></i>
                        Registration of Public Limited Companies requires a minimum of 3 Directors and 7 Shareholders.A person can be both a director and shareholder in a Public Limited Company.5)	The liability of the members of a Public Limited Company (PLC) is limited to the number of shares held by them. A Public Limited Company in India can begin with operations after getting the Certificate of Incorporation. A Public limited company can be incorporated within 15 working days. {" "}
                        </p>
                      
                      </div>
                    </div>
                    <div class="col-sm-3 stretch-card grid-margin">
                      <img loading = "lazy"

                        src="https://img.freepik.com/free-vector/business-deal-concept-illustration_114360-1535.jpg"
                        style={{ width: "90%", height: "100%" }}
                      />
                    </div>
                  </div>
                  {/* MORE INFORMATION SECTION END */}

                  <hr />
                  <h2 id="ip-h2" style={{padding:"0.5rem"}}>
                  BENEFITS OF REGISTERING AS A PUBLIC LIMITED COMPANY
                  </h2>
                  {/* BENEFITS SECTION START */}
                  <div class="row d-flex justify-content-center" style={{ padding: "2rem" }}>
                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/2590/2590440.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">No Minimum Capital</h4>
                        <p>
                        10,000 as total Paid Up Share capital.

                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/2758/2758527.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Limited Liability</h4>
                        <p>
                        If the company undergoes financial distress because of whatsoever reasons, the personal assets of members will not be used to pay the debts of the Company as the Liability of the person is limited.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/11443/11443795.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Separate Legal Entity</h4>
                        <p>
                        A Public Limited Company offers legal recognition to the business, providing a separate legal entity from its owners.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/3529/3529127.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Easy Fund Raising</h4>
                        <p>
                        A Public Limited Company in India is the only form of business except Public Limited Companies that can raise funds from the Venture Capitalists or Angel investors by issuing equity shares {" "}
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/5347/5347858.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Credibility</h4>
                        <p>
                        Being registered as a Public Limited Company lends credibility to the business and builds trust among stakeholders.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/13163/13163215.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">FDI Allowed</h4>
                        <p>
                        100% Foreign Direct Investment is allowed that means any foreign entity or foreign person can directly invest in a Public Limited Company.Hence, most foreign subsidiaries are established in India as Public limited company.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/4435/4435123.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Eligible for Startup India </h4>
                        <p>
                        Public Limited company is eligible for Startup india Registration and can avail the benefits of Startup India {" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* BENEFITS SECTION END */}

                  <hr />
                  {/* REQUIREMENTS SECTION START */}
                  <h2 id="ip-h2">MINIMUM REQUIREMENTS FOR PUBLIC LIMITED COMPANY REGISTRATION</h2>
                  <h4 style = {{fontSize: "1rem"}}>As per company act, 2013 there are minimum requirement that need to be met for company incorporation online </h4>

                  <div class="row" style={{ padding: "2rem" }}>
                    <div class="col-sm-4 stretch-card grid-margin">
                      <img loading = "lazy"

                        src="https://img.freepik.com/free-vector/business-deal-concept-illustration_114360-1103.jpg"
                        style={{ width: "90%", height: "100%" }}
                      />
                    </div>

                    

                    <div
                      class="col-sm-8 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      
                      <div>
                        <h4 style={{ fontWeight: "bold" }}>
                          1. Minimum of seven members are required:
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                          {" "}
                          To register a Public Limited Company in India there should be a minimum of seven members and there is no limit on the maximum number of members/shareholders for starting a Public Limited Company.
                        </p>
                        <br />
                        <h4 style={{ fontWeight: "bold" }}>
                          2. Minimum of 5 lakh rupees is required for share capital
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                        According to the provisions of the Indian Companies Act, 2013, minimum paid up share capital in case of a public company is Rs. 5 lakhs.
                        </p>
                        <br />
                        <h4 style={{ fontWeight: "bold" }}>
                          3. At least three members must be the directors of the comapny
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                        The Companies Act, 2013 ('Act') prescribes the Minimum number of directors in a company is 3.
                        </p>
                        <br />
                       
                      
                        
                      </div>

                      
                    </div>
                  </div>

                  {/* REQUIREMENTS SECTION END */}
                  <hr />
                  <h2 id="ip-h2">PUBLIC LIMITED COMPANY REGISTRATION PROCESS</h2>

                  {/* PROCESS SECTION START */}
                  <div class="row d-flex justify-content-center" style={{padding:"1rem"}}>
                    <div class="col-sm-4 stretch-card grid-margin" id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img loading = "lazy"

                            class="img-fluid w-100"
                            src="https://img.freepik.com/free-vector/paying-bills-concept-illustration_114360-19357.jpg?w=740&t=st=1706779500~exp=1706780100~hmac=ad8e6bf9dc5da282c80e40c82c023c68f94bea72270aa35612471e5892f8985b"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold"> Step 1 </h4>
                          <div>
                            <p class="mb-0" style={{ fontSize: "1rem", textAlign:"center" }}>
                              Payment and Document Submission
                              <br />
                              Expert review your Documents
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-4 stretch-card grid-margin" id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img loading = "lazy"

                            class="img-fluid w-100"
                            src="https://img.freepik.com/free-vector/login-concept-illustration_114360-748.jpg?t=st=1706779615~exp=1706780215~hmac=9651a498d516590febe6779c62640331889c05ff48733ca96f9ca77ddcdec593"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold"> Step 2 </h4>
                          <div>
                            <p class="mb-0" style={{ fontSize: "1rem", textAlign:"center" }}>
                              Name availability check
                              <br />
                              Trademark Check and Name reservation Application{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-4 stretch-card grid-margin" id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img loading = "lazy"

                            class="img-fluid w-100"
                            src="https://img.freepik.com/premium-vector/businessman-shaking-hands-deal-symbol-with-fellow-lawyer-lawyers-discuss-with-clients_923732-776.jpg?w=740"
                            alt=""
                            
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold"> Step 3 </h4>
                          <div>
                            <p class="mb-0" style={{ fontSize: "1rem", textAlign:"center" }}>
                              Application for Digital Signature and
                              Incorporation application Submission{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-4 stretch-card grid-margin" id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img loading = "lazy"

                            class="img-fluid w-100"
                            src="https://img.freepik.com/free-vector/product-quality-concept-illustration_114360-7301.jpg?w=740&t=st=1706780098~exp=1706780698~hmac=4ed66236fdef104f05dab6b8d7176409171e304e19a3758c389bc28837bbe13b"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold"> Step 4 </h4>
                          <div>
                            <p class="mb-0" style={{ fontSize: "1rem", textAlign:"center" }}>
                              Get Certificate for Incorporation
                              <br />
                              PAN, TAN, EPF, ESIC Certificate
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-4 stretch-card grid-margin" id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img loading = "lazy"

                            class="img-fluid w-100"
                            src="https://img.freepik.com/premium-vector/boy-looking-tax-form-laptop_118167-9401.jpg?w=740"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold"> Step 5 </h4>
                          <div>
                            <p class="mb-0" style={{ fontSize: "1rem", textAlign:"center" }}>
                              Apply for GST
                              <br />
                              MSME and Trademark
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* PROCESS SECTION END */}
                  <hr/>
                  {/* PRICING SECTION START */}
                  <h2 id="ip-h2" style={{fontWeight:'bold'}}>PRICING FOR <span style={{color:"#006345"}}>PUBLIC LIMITED COMPANY REGISTRATION</span></h2>
                  <div class="row md-12 d-flex justify-content-center" style={{marginTop:"5rem", padding:"1rem"}}>

                  {/* PRICING CARD 1 START */}
                  <div
                      class="col-sm-4 stretch-card grid-margin" id="resp-2"
                      style={{ textAlign: "left" }}
                    >
                    <div class="card">
                    <div style={{backgroundColor:"#207a78",color:"#ffffff",fontWeight:"bold",textAlign:"center",borderRadius:"12px",padding:"1rem"}}>
                    <h3><u>BUSINESS</u></h3>
                    <div style={{textAlign:"center"}}><h2 style={{fontWeight:"bold"}}> Starts <span style={{color:"#ffffff"}}>₹ 29999*</span></h2></div>
                    </div>
                    <div style={{padding:"1rem",margingTop:"1rem"}}>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i> 7 Digital Signature Certificates</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i> 3 Director Identification Number(DIN)</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i> Name Approval</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i> Stamp duty on INR 15 Lakh Authorized Capital</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i> Company Incorporation using SPICe+</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i> Copy of e-MOA & e-AOA</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i> e-PAN</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i> e-TAN</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i> 7 e-copies of Share Certificates</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i> ESIC Registration through SPICe Plus</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i> PF Registration through SPICe Plus</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i> Open Bank Account (feature) via SPICe Plus</p>
                        <p style={{fontSize:"1rem", textDecoration:"line-through"}}><i class="mdi mdi-close-circle text-danger" style={{fontSize:"1.6rem",padding:"1rem"}}></i> MSME Registration<br/></p>
                        <p style={{fontSize:"1rem", textDecoration:"line-through"}}><i class="mdi mdi-close-circle text-danger" style={{fontSize:"1.6rem",padding:"1rem"}}></i>GST Registration</p>
                        <hr/>
                       <div class="d-flex justify-content-center">
                       <button class="btn btn-lg btn-success" style={{backgroundColor:"#207a78", fontSize:"1rem"}} onClick={()=>{
                        //Setting Values to redirect to Contact Sales Page
                        sessionStorage.setItem("Smodule00","Services");sessionStorage.setItem("Sservice00","Public Limited Registration"); window.location.replace("/businesstools-contactsales");
                       }}>Contact Sales</button>
                       </div>
                    </div>
                    </div>
                    </div>
                    {/* PRICING CARD 1 END */}

                      {/* PRICING CARD 2 START */}
                  <div
                      class="col-sm-4 stretch-card grid-margin" id="resp-2"
                      style={{ textAlign: "left" }}
                    >
                    <div class="card">
                    <div style={{backgroundColor:"#207a78",color:"#ffffff",fontWeight:"bold",textAlign:"center",borderRadius:"12px",padding:"1rem"}}>
                    <h3><u>PROFESSIONAL</u></h3>
                    <div style={{textAlign:"center"}}><h2 style={{fontWeight:"bold"}}> Starts <span style={{color:"#ffffff"}}>₹ 32999*</span></h2></div>
                    </div>
                    <div style={{padding:"1rem",margingTop:"1rem"}}>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i> 7 Digital Signature Certificates</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i> 3 Director Identification Number(DIN)</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i> Name Approval</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i> Stamp duty on INR 15 Lakh Authorized Capital</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i> Company Incorporation using SPICe+</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i> Copy of e-MOA & e-AOA</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i> e-PAN</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i> e-TAN</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i> 7 e-copies of Share Certificates</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i> ESIC Registration through SPICe Plus</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i> PF Registration through SPICe Plus</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i> Open Bank Account (feature) via SPICe Plus</p>
                   <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i> MSME Registration</p>
                    <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>GST Registration</p>
                        <hr/>
                       <div class="d-flex justify-content-center">
                       <button class="btn btn-lg btn-success" style={{backgroundColor:"#207a78", fontSize:"1rem"}} onClick={()=>{
                        //Setting Values to redirect to Contact Sales Page
                        sessionStorage.setItem("Smodule00","Services");sessionStorage.setItem("Sservice00","Public Limited Registration"); window.location.replace("/businesstools-contactsales");
                       }}>Contact Sales</button>
                       </div>
                    </div>
                    </div>
                    </div>
                    {/* PRICING CARD 2 END */}


                  </div>
               
                  <hr/>
                  {/* PRICING SECTION END */}

                  {/* Why StartUp Connect Starts */}
                  <Whystartupconnect/>
                  {/* Why StartUp Connect Ends */}

                  <hr />

                  {/* Documents SECTION Starts */}
                  <h2 id="ip-h2" style={{padding:"0.5rem"}}> DOCUMENTS REQUIRED FOR PUBLIC LIMITED COMPANY REGISTRATION</h2>
                  <div class="row d-flex justify-content-center" style={{ padding: "2rem" }}>
                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/3135/3135715.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>

                        <h4 class="mt-3">Photograph of all the Directors</h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/1726/1726620.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">
                          PAN Card and aadhar card of all the Directors
                        </h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/9068/9068642.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Email id/ Mobile no of Directors</h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/9875/9875524.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">
                          Bank statement/any other Utility bill for address
                          Proof of Directors
                        </h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/6108/6108273.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">
                          Electricity Bill or any other utility bill for the
                          address proof of the Registered Office
                        </h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/1584/1584961.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">
                          Proof of Registered Office : Consent letter/Rent
                          agreement{" "}
                        </h4>
                      </div>
                    </div>
                  </div>
                  {/* Documents SECTION Ends */}


            
                   <hr/>

                  {/* COMPLIANCES SECTION START */}
                  <h2 id="ip-h2" style={{padding:"0.5rem"}}>COMPLIANCES REQUIRED BY A PUBLIC LIMITED COMPANY</h2>
                  <p style={{ fontSize: "1rem" }}>
                  Once a company is registered in India, various compliances must be maintained from time to time to avoid penalties and prosecution. The following are some of the compliances a company would be required to complete after company registration:
                  </p>
                  <div
                    class="row"
                    style={{ textAlign: "left", padding: "2rem" }}
                  >
                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> Appointment of Auditor</h3>
                    <p style={{ fontSize: "1rem" }}>
                      All companies registered in India must appoint a
                      practicing and licensed Chartered Accountant registered
                      with the ICAI and needs to file form ADT-1 within 30 days
                      of incorporation. If the company fails to appoint an
                      auditor, the company won't be allowed to commence
                      business. Also, there is a penalty of Rs. 300 per month.{" "}
                    </p>
                    <br />
                    <br />

                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> Commencement of Business (Form INC 20 A)</h3>
                    <p style={{ fontSize: "1rem" }}>
                      The company must open a Bank Current Account and the
                      shareholders must deposit the subscription amount
                      mentioned in the MOA of the company. Hence, if the company
                      was to be incorporated with a paid-up capital of Rs. 1
                      lakh, then the shareholders must deposit Rs. 1 lakh in the
                      Company's bank account and file the bank statement with
                      the MCA to obtain a commencement of business certificate.
                      +The commencement of business certificate in form INC 20A
                      must be obtained within 180 days of incorporating a
                      Company. «In case of failure to obtain this certificate,
                      there is a penalty of Rs. 50,000 for the company Rs. 1000
                      per day for the directors for each day of default
                    </p>
                    <br />
                    <br />

                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> Director DIN KYC (Form DIR-3 KYC)</h3>
                    <p style={{ fontSize: "1rem" }}>
                      All persons who hold a Director Identification Number
                      (DIN) whose DIN is allotted on or before 31 March must
                      complete DIN KYC within 30th September every year to
                      validate the phone and email address on record with the
                      Ministry of Corporate Affairs. «There's a penalty of Rs.
                      5000 in case of failure to file DIN eKYC.{" "}
                    </p>
                    <br />
                    <br />

                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> MCA Annual Filings</h3>
                    <p style={{ fontSize: "1rem" }}>
                      If companies registered in India must file a copy of the
                      financial statements with the Ministry of Corporate
                      Affairs each financial year. If a company is incorporated
                      between January — March, the company can choose to file
                      the first MCA annual return as a part of the next
                      financial year’s annual filing. MCA annual return consists
                      of Form AOC-4 which consists of balance sheet and profit
                      and loss account and notes to accounts thereon Form MGT-7
                      which consist of annual return of the company and Both
                      these forms must be digitally signed by the Directors and
                      a practicing professional{" "}
                    </p>
                    <br />
                    <br />

                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> Statutory and Tax Audit</h3>
                    <p style={{ fontSize: "1rem" }}>
                      Every Private Limited Company registered under the
                      Companies Act irrespective of its sales turnover or nature
                      of business or capital must have its book of accounts
                      audited each financial year Every Private Limited Company
                      requires to get the tax audits under section 44AB done.
                      The Tax audit under section 44AB aims to ascertain the
                      compliance of various provisions of the Income-tax Law and
                      the fulfillment of other requirements of the Income-Tax
                      Law. The tax audit limit under section 44AB is INR 1
                      Crores. (5 crores where at least 95 percent turnover is
                      made on digital transactions.).
                    </p>
                    <br />
                    <br />

                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> GST Registration</h3>
                    <p style={{ fontSize: "1rem" }}>
                      very Private Limited Company is required to get their GST
                      Registration within 30 days of Busines Incorporation in
                      India. GST Registration is needed when the tumover is more
                      than INR 40 lakhs or INR 20 lakhs in Special category
                      states or If the business supplies goods intra-state or
                      Provides goods and services online.
                    </p>
                    <br />
                    <br />

                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> GST Return</h3>
                    <p style={{ fontSize: "1rem" }}>
                      Once a Private Limited company gets registered under. GST,
                      it becomes necessary to file GST returns for Private
                      Limited Company. As filing of GST returns is mandatory for
                      all the registered Taxpayers including companies. GST
                      returns can be filed monthly, quarterly or yearly
                      depending upon the types of gst returns form you are
                      filing
                    </p>
                    <br />
                    <br />

                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> IEC Registration</h3>
                    <p style={{ fontSize: "1rem" }}>
                      All the Private Limited Company which are engaged in
                      Import and Export of goods require to register the Import
                      Export Code. IE code has lifetime validity. Importers are
                      ot allowed to proceed without this code and exporters
                      can't take benefits of exports from DGFT, customs, Export
                      Promotion Council, if they don't have this code.
                    </p>
                    <br />
                    <br />
                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> Trademark Registration</h3>
                    <p style={{ fontSize: "1rem" }}>
                      Registering a Private Limited Company with a name does not
                      provide complete protection to the name or brand name.
                      Ultimate protection is secured only by Trademark
                      Registration of same. By trademark registration, you may
                      get the ownership of your Pvt Ltd Co. name or brand name
                      or logo etc
                    </p>
                  </div>
                  {/* COMPLIANCES SECTION END */}
                  <hr/>
                   {/* COMPARISON TABLE START */}
                  <Comparisontable/>
                  {/* COMPARISON TABLE END */}
                  <h2 id="ip-h2">Frequently Asked Questions (FAQs)</h2>
                  {/* FAQ SECTION START */}
                  <div
                    id="accordion"
                    style={{ textAlign: "left", marginTop: "3rem" }}
                  >
                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#003e73" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                          What is a Public Limited Company in india ?
                        </h5>
                        <div>
                          <button
                            id="ques-1-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-1").style.display =
                                "block";
                              document.getElementById(
                                "ques-1-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-1-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-1-dicon"
                            style={{ display: "none" }}
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-1").style.display =
                                "none";
                              document.getElementById(
                                "ques-1-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-1-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-1">
                        <div class="card-body">
                        A public limited company offers shares to the general public and has limited liability. Its stock can be acquired by anyone, either privately through Initial Public Offering (IPO) or via trades on the stock market. {" "}
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                          How many members are required to form a Public Limited Company?
                        </h5>
                        <div>
                          <button
                            id="ques-2-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-2").style.display =
                                "block";
                              document.getElementById(
                                "ques-2-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-2-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-2-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-2").style.display =
                                "none";
                              document.getElementById(
                                "ques-2-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-2-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-2" style={{ display: "none" }}>
                        <div class="card-body">
                        To register a Public Limited Company in India there should be a minimum of seven members and there is no limit on the maximum number of members/shareholders for starting a Public Limited Company.{" "}
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                          What are the advantages of a Public Limited Company?
                        </h5>
                        <div>
                          <button
                            id="ques-3-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-3").style.display =
                                "block";
                              document.getElementById(
                                "ques-3-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-3-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-3-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-3").style.display =
                                "none";
                              document.getElementById(
                                "ques-3-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-3-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-3" style={{ display: "none" }}>
                        <div class="card-body">
                        Public companies have the advantage of being able to borrow money from many sources. Public companies can issue debts (secured and unsecured) to raise money.{" "}
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                          What are the compliance requirements for a Public Limited Company?
                        </h5>
                        <div>
                          <button
                            id="ques-4-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-4").style.display =
                                "block";
                              document.getElementById(
                                "ques-4-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-4-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-4-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-4").style.display =
                                "none";
                              document.getElementById(
                                "ques-4-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-4-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-4" style={{ display: "none" }}>
                        <div class="card-body">
                          
Minimum of 3 directors is required to form a public limited company. A minimum authorized share capital of Rs. 1 lakh is required. Digital signature certificate (DSC) of one of the directors is needed while submitting self-attested copies of identity and address proof.{" "}
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                          How long does it take to register a Public Limited
                          Company?
                        </h5>
                        <div>
                          <button
                            id="ques-5-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-5").style.display =
                                "block";
                              document.getElementById(
                                "ques-5-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-5-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-5-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-5").style.display =
                                "none";
                              document.getElementById(
                                "ques-5-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-5-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-5" style={{ display: "none" }}>
                        <div class="card-body">
                        To register a company in India, the first step is to obtain name approval for the business from the Ministry of Corporate Affairs (MCA). This process takes about 24-48 hours.{" "}
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                          Can a Public Limited Company be converted into
                          another type of business entity?
                        </h5>
                        <div>
                          <button
                            id="ques-6-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-6").style.display =
                                "block";
                              document.getElementById(
                                "ques-6-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-6-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-6-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-6").style.display =
                                "none";
                              document.getElementById(
                                "ques-6-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-6-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-6" style={{ display: "none" }}>
                        <div class="card-body">
                        According to sections 13 and 14 of the Companies Act, 2013 read with Rule 33 and Rule 41 of the Companies (Incorporation) Rules, 2014, a public company can be converted into the private company only after obtaining its shareholders approval by way of passing a special resolution in general meeting.{" "}
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                          Can a foreign national or non-resident be a director or shareholder in a Public Limited Company in India?
                        </h5>
                        <div>
                          <button
                            id="ques-7-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-7").style.display =
                                "block";
                              document.getElementById(
                                "ques-7-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-7-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-7-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-7").style.display =
                                "none";
                              document.getElementById(
                                "ques-7-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-7-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-7" style={{ display: "none" }}>
                        <div class="card-body">
                        Yes, under the provisions of the Companies Act, foreign nationals can be appointed as directors in Indian companies, provided they're eligible and have a Director Identification Number (DIN).{" "}
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                          What are the key documents required for registering a Public Limited Company?
                        </h5>
                        <div>
                          <button
                            id="ques-8-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-8").style.display =
                                "block";
                              document.getElementById(
                                "ques-8-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-8-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-8-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-8").style.display =
                                "none";
                              document.getElementById(
                                "ques-8-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-8-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-8" style={{ display: "none" }}>
                        <div class="card-body">
                        Passport size photographs of the members,
Copy of PAN Card of the members,
Copy of Aadhar Card or Voter ID,
Bank statement (not older than two months),
Proof of registered place of business,
No Objection Certificate from the owner of the property.{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* FAQ SECTION END */}

                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
            {/* SIDEBAR AND MAIN PAGE END */}

            <Footer3 />
          </div>
          {/*} container-scroller */}
          {/*} plugins:js */}
          <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
          {/*} endinject */}
          {/*} Plugin js for this page */}
          <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
          <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
          <script
            src="panel/assets/js/jquery.cookie.js"
            type="text/javascript"
          ></script>
          {/*} End plugin js for this page */}
          {/*} inject:js */}
          <script src="panel/assets/js/off-canvas.js"></script>
          <script src="panel/assets/js/hoverable-collapse.js"></script>
          <script src="panel/assets/js/misc.js"></script>
          <script src="panel/assets/js/settings.js"></script>
          <script src="panel/assets/js/todolist.js"></script>
          {/*} endinject */}
          {/*} Custom js for this page */}
          <script src="panel/assets/js/dashboard.js"></script>
          {/*} End custom js for this page */}
        </body>
      </html>
    </>
  );
}

export default Publiclimitedregistration1;
