import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import moment from 'moment/moment';



import '../Resources/dashboard.css';
import axios from '../../axios.js';
import Navbar from '../navbar';



 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");



function Mentorregister(){

/*  const[file,setfile]=useState();

  const handleFileUpload = (event) => {
    setfile(event.target.files[0]);
  };

  let formData = new FormData();

  formData.append('image',file);
  formData.append('name','Image123')  */

  
//name
const[name,setname]=useState(logname);const updatename = (event) => { setname(event.target.value);}; const[name_er,setname_er]=useState();
//contact
const[contact,setcontact]=useState();const updatecontact = (event) => { setcontact(event.target.value);}; const[contact_er,setcontact_er]=useState();
//email
const[email,setemail]=useState();const updateemail = (event) => { setemail(event.target.value);};  const[email_er,setemail_er]=useState();
//current_company
const[current_company,setcurrent_company]=useState();const updatecurrent_company = (event) => { setcurrent_company(event.target.value);}; const[current_company_er,setcurrent_company_er]=useState();
//current_designation
const[current_designation,setcurrent_designation]=useState();const updatecurrent_designation = (event) => { setcurrent_designation(event.target.value);};  const[current_designation_er,setcurrent_designation_er]=useState();
//experience
const[experience,setexperience]=useState();const updateexperience = (event) => { setexperience(event.target.value);}; const[experience_er,setexperience_er]=useState();
//gender
const[gender,setgender]=useState();const updategender = (event) => { setgender(event.target.value);};  const[gender_er,setgender_er]=useState();
//date_of_birth
const[date_of_birth,setdate_of_birth]=useState();const updatedate_of_birth = (event) => { setdate_of_birth(event.target.value);}; const[date_of_birth_er,setdate_of_birth_er]=useState();
//introduction
const[introduction,setintroduction]=useState();const updateintroduction = (event) => { setintroduction(event.target.value);};  const[introduction_er,setintroduction_er]=useState();
//industry
const[industry,setindustry]=useState();const updateindustry = (event) => { setindustry(event.target.value);}; const[industry_er,setindustry_er]=useState();
//sector
const[sector,setsector]=useState();const updatesector = (event) => { setsector(event.target.value);};  const[sector_er,setsector_er]=useState();
//category
const[category,setcategory]=useState();const updatecategory = (event) => { setcategory(event.target.value);}; const[category_er,setcategory_er]=useState();
//sub_category
const[sub_category,setsub_category]=useState();const updatesub_category = (event) => { setsub_category(event.target.value);};  const[sub_category_er,setsub_category_er]=useState();
//address
const[address,setaddress]=useState();const updateaddress = (event) => { setaddress(event.target.value);}; const[address_er,setaddress_er]=useState();
//city
const[city,setcity]=useState();const updatecity = (event) => { setcity(event.target.value);};  const[city_er,setcity_er]=useState();
//state
const[state,setstate]=useState();const updatestate = (event) => { setstate(event.target.value);}; const[state_er,setstate_er]=useState();
//country
const[country,setcountry]=useState();const updatecountry = (event) => { setcountry(event.target.value);};  const[country_er,setcountry_er]=useState();
//pincode
const[pincode,setpincode]=useState();const updatepincode = (event) => { setpincode(event.target.value);}; const[pincode_er,setpincode_er]=useState();
//youtube
const[youtube,setyoutube]=useState();const updateyoutube = (event) => { setyoutube(event.target.value);};  const[youtube_er,setyoutube_er]=useState();
//website
const[website,setwebsite]=useState();const updatewebsite = (event) => { setwebsite(event.target.value);}; const[website_er,setwebsite_er]=useState();
//linkedin
const[linkedin,setlinkedin]=useState();const updatelinkedin = (event) => { setlinkedin(event.target.value);};  const[linkedin_er,setlinkedin_er]=useState();
//twitter
const[twitter,settwitter]=useState();const updatetwitter = (event) => { settwitter(event.target.value);}; const[twitter_er,settwitter_er]=useState();
//profile_link
const[profile_link,setprofile_link]=useState();const updateprofile_link = (event) => { setprofile_link(event.target.value[0]);};  const[profile_link_er,setprofile_link_er]=useState();

//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();


//image upload
 //File Upload
  const [selectedFile, setSelectedFile] = React.useState(null);
  const handleFileChange = (event) => {  const file = event.target.files[0];  setSelectedFile(file); };


/***************** REGISTER FUNCTION START  ******************/
function register(){

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))){


//Setting Error Variables
setname_er(" "); setemail_er(" ");setcontact_er(" ");setcurrent_company_er(" ");setcurrent_designation_er(" ");setexperience_er(" ");setgender_er();setdate_of_birth_er();setintroduction_er();
setindustry_er();setsector_er();setcategory_er();setsub_category_er();setaddress_er();setcity_er();setstate_er();setcountry_er();setpincode_er();setyoutube_er();setwebsite_er();setlinkedin_er();settwitter_er();
setprofile_link_er();seterror_msg(" ");


//Fetching Form Data
let noerror = 0;
const $name = document.getElementById("name").value; if($name==""){ setname_er("Name is required !");noerror = noerror+1; }//Fetching Name Value
const $email = document.getElementById("email").value; if($email==""){ setemail_er("Email is required !");noerror = noerror+1; }//Fetching Email Value
const $contact = document.getElementById("contact").value; if($contact==""){ setcontact_er("Contact is required !");noerror = noerror+1; }//Fetching contact Value
const $current_company = document.getElementById("current_company").value; if($current_company==""){ setcurrent_company_er("Current Company is required !");noerror = noerror+1; }//Fetching current_company Value
const $current_designation = document.getElementById("current_designation").value; if($current_designation==""){ setcurrent_designation_er("Current Designation is required !");noerror = noerror+1; }//Fetching Confirm Password Value
const $experience = document.getElementById("experience").value; if($experience==""){ setexperience_er("Experience is required !");noerror = noerror+1; }//Fetching experience Value
const $gender = document.getElementById("gender").value; if($gender==""){ setgender_er("Gender is required !");noerror = noerror+1; }//Fetching gender Value
const $date_of_birth = document.getElementById("date_of_birth").value; if($date_of_birth==""){ setdate_of_birth_er("Date of Birth is required !");noerror = noerror+1; }//Fetching date_of_birth Value
const $introduction = document.getElementById("introduction").value; if($introduction==""){ setintroduction_er("Introduction is required !");noerror = noerror+1; }//Fetching introduction Value
const $industry = document.getElementById("industry").value; if($industry==""){ setindustry_er("Industry is required !");noerror = noerror+1; }//Fetching industry Value
const $sector = document.getElementById("sector").value; if($sector==""){ setsector_er("Sector is required !");noerror = noerror+1; }//Fetching sector Value
const $category = document.getElementById("category").value; if($category==""){ setcategory_er("Category is required !");noerror = noerror+1; }//Fetching category Value
const $sub_category = document.getElementById("sub_category").value; if($sub_category==""){ setsub_category_er("Sub Category is required !");noerror = noerror+1; }//Fetching sub_category Value
const $address = document.getElementById("address").value; //Fetching address Value
const $city = document.getElementById("city").value; //Fetching city Value
const $state = document.getElementById("state").value; //Fetching state Value
const $country = document.getElementById("country").value; //Fetching country Value
const $pincode = document.getElementById("pincode").value; //Fetching pincode Value
const $youtube = document.getElementById("youtube").value; //Fetching youtube Value
const $website = document.getElementById("website").value; //Fetching website Value
const $linkedin = document.getElementById("linkedin").value; //Fetching linkedin Value
const $twitter = document.getElementById("twitter").value; //Fetching twitter Value
//const $profile_link = document.getElementById("profile_link").value; //Fetching profile_link Value


//No primary errors
if(noerror==0){


//Sending Data through axios
axios.post("network-register-mentor", { 
Log_id:logid,
Token:token,
Mentor_name:$name,
Email:$email,
Contact:$contact,
Current_company:$current_company,
Current_designation:$current_designation,
Experience:$experience,
Gender:$gender,
Date_of_birth:moment($date_of_birth).format('YYYY-MM-DD'),
Introduction:$introduction,
Industry:$industry,
Sector:$sector,
Category:$category,
Sub_category:$sub_category,
Address:$address,
City:$city,
State:$state,
Country:$country,
Pincode:$pincode,
Youtube:$youtube,
Website:$website,
Linkedin:$linkedin,
Twitter:$twitter,
Profile_link:selectedFile
},{
headers: { "Content-Type": "multipart/form-data" },
}
  )

.then((response) => {


//Validation Errors
if(response.data.validatorerror){
setname_er(response.data.validatorerror.Name);
setcontact_er(response.data.validatorerror.Contact);
setemail_er(response.data.validatorerror.Email);
setcurrent_company_er(response.data.validatorerror.Current_company);
setcurrent_designation_er(response.data.validatorerror.Current_designation);
setexperience_er(response.data.validatorerror.Experience);
setgender_er(response.data.validatorerror.Gender);
setdate_of_birth_er(response.data.validatorerror.Date_of_birth);
setintroduction_er(response.data.validatorerror.Introduction);
setindustry_er(response.data.validatorerror.Industry);
setsector_er(response.data.validatorerror.Sector);
setcategory_er(response.data.validatorerror.Category);
setsub_category_er(response.data.validatorerror.Sub_category);
setaddress_er(response.data.validatorerror.Address);
setcity_er(response.data.validatorerror.City);
setstate_er(response.data.validatorerror.State);
setcountry_er(response.data.validatorerror.Country);
setpincode_er(response.data.validatorerror.Pincode);
setyoutube_er(response.data.validatorerror.Youtube);
setwebsite_er(response.data.validatorerror.Website);
setlinkedin_er(response.data.validatorerror.Linkedin);
settwitter_er(response.data.validatorerror.Twitter);
setprofile_link_er(response.data.validatorerror.Profile_link);
seterror_msg("Cannot Create Mentor Account !");


}
//Invalid Entries
if(response.data.error==102){ 

setname_er(response.data.name_er);
setcontact_er(response.data.contact_er);
setemail_er(response.data.email_er);
setcurrent_company_er(response.data.current_company_er);
setcurrent_designation_er(response.data.current_designation_er);
setexperience_er(response.data.experience_er);
setgender_er(response.data.gender_er);
setdate_of_birth_er(response.data.date_of_birth_er);
setintroduction_er(response.data.introduction_er);
setindustry_er(response.data.industry_er);
setsector_er(response.data.sector_er);
setcategory_er(response.data.category_er);
setsub_category_er(response.data.sub_category_er);
setaddress_er(response.data.address_er);
setcity_er(response.data.city_er);
setstate_er(response.data.state_er);
setcountry_er(response.data.country_er);
setpincode_er(response.data.pincode_er);
setyoutube_er(response.data.youtube_er);
setwebsite_er(response.data.website_er);
setlinkedin_er(response.data.linkedin_er);
settwitter_er(response.data.twitter_er);
setprofile_link_er(response.data.profile_link_er);
seterror_msg("Cannot Create Mentor Account !");


}

//Mentor Already Exists
if(response.data.error==104){     seterror_msg(response.data.error_msg); return; }



//Success
if(response.data.successvar==1){


//window.location.replace("/businesstools-startworking");
document.getElementById("success-card").style.display="block";

}
//failure
if(response.data.successvar==2){
seterror_msg("Mentor Account Not Created!");
}
}    );

}

  }
 //User not Logedin
else{

//Remove Session Variables and redirect to login
sessionStorage.removeItem("logStatus00");
sessionStorage.removeItem("logId00");
sessionStorage.removeItem("logToken00");
sessionStorage.removeItem("logName00");

window.location.replace("/businesstools-login");


}




}
/***************** REGSITER FUNCTION END  ******************/

return(
    <>
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Networking|Mentor Register</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div class="container-scroller">

  {/*}  <Sidebar /> */}
    <div class="container-fluid page-body-wrapper">


       <Navbar /> 
        {/*} partial */}
        <div class="main-panel">
          <div class="content-wrapper pb-0">

       {/*  SUCCESS CARD START */}
       <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Mentor Account Created Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <a href="networking-mentor-dashboard"><button class="mt-3 btn btn-outline-danger">Close</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/*  SUCCESS CARD END */}



              <div class="col-12 grid-margin">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Mentor Registration</h4>
                    <div class="form-sample" style={{textAlign:"left"}}>
                      <p class="card-description">register me as mentor</p>
                      <code>{error_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="name" onChange={updatename} value={name} placeholder="John Doe" />
                              <code>{name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Current Designation</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="current_designation" onChange={updatecurrent_designation} value={current_designation} placeholder="Chief Executive Officer"/>
                              <code>{current_designation_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Current Company</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="current_company" onChange={updatecurrent_company} value={current_company} placeholder="ABC Private Limited" />
                              <code>{current_company_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Experience (in years)</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="experience" onChange={updateexperience} value={experience} placeholder="2" />
                              <code>{experience_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Email</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="email" onChange={updateemail} value={email} placeholder="johndoemyemail@gmail.com" />
                              <code>{email_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Contact</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="contact" onChange={updatecontact} value={contact} placeholder="91900000000" />
                              <code>{contact_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Gender</label>
                            <div class="col-sm-9">
                              <select class="form-control" style={{padding:"1rem"}} id="gender" onChange={updategender}>
                                <option>Select Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="other">Other</option>
                              </select>
                              <code>{gender_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date of Birth</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control"  id="date_of_birth" onChange={updatedate_of_birth} value={date_of_birth} placeholder="dd-mm-yyyy" />
                              <code>{date_of_birth_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group" style={{textAlign:"left"}}>
                        <label for="exampleTextarea1">Summary/ Introduction</label>
                        <textarea type="text" class="form-control"  id="introduction" onChange={updateintroduction} value={introduction}  placeholder="I am a ...."></textarea>
                        <code>{introduction_er}</code>
                      </div> 
                      <hr />
                      <p class="card-description">Interested In</p>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label"> Category 1</label>
                            <div class="col-sm-9">
                              <select style={{padding:"1rem"}} class="form-control" id="category" onChange={updatecategory} value={category} >
                                <option>Select Category 1</option>
                                <option value="AI and ML">AI and ML</option>
                                <option value="Fintech and BlockChain">Fintech and Blockchain</option>
                                <option value="Cyber Security and Data Privacy">Cyber Security and Data Privacy</option>
                                <option value="Internet of Things">Internet of Things</option>
                                <option value="Big Data and Analytics">Big Data and Analytics</option>
                                <option value="AR and VR">AR and VR</option>
                                <option value="Robotics and Automation">Robotics and Automation</option>
                                <option value="Space Exploration and Technology">Space Exploration and Technology</option>
                                <option value="Education and Skill Development">Education and Skill Development</option>
                                <option value="Healthcare and Medical Technology">Healthcare and Medical Technology</option>
                                <option value="Renewbale Energy and Clean Tech">Renewbale Energy and Clean Tech</option>
                                <option value="Circular Economy and Waste Management">Circular Economy and Waste Management</option>
                                <option value="Sustainable Agriculture and Food Tech">Sustainable Agriculture and Food Tech</option>
                                <option value="Urban Development and Smart Cities">Urban Development and Smart Cities</option>
                                <option value="Social Justice and Equality">Social Justice and Equality</option>
                                <option value="Disaster Relief and Preparedness">Disaster Relief and Preparedness</option>
                                <option value="Ecommerce and Retail Tech">Ecommerce and Retail Tech</option>
                                <option value="On Demand Services and Gig Economy">On Demand Services and Gig Economy</option>
                                <option value="Personalized Experiences">Personalized Experiences</option>
                                <option value="Consultancy and Services">Consultancy and Services</option>
                                <option value="Travel and Tourism">Travel and Tourism</option>
                                <option value="Animations and Gaming">Animations and Gaming</option>
                                <option value="Marketing Promotion and Public Relations">Marketing Promotion and Public Relations</option>
                                <option value="Social Groups">Social Groups</option>
                                <option value="Others">Others</option>
                              </select>
                              <code>{category_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label"> Category 2</label>
                            <div class="col-sm-9">
                              <select style={{padding:"1rem"}} class="form-control" id="sub_category" onChange={updatesub_category} value={sub_category} >
                                <option>Select Category 2</option>
                                <option value="AI and ML">AI and ML</option>
                                <option value="Fintech and BlockChain">Fintech and Blockchain</option>
                                <option value="Cyber Security and Data Privacy">Cyber Security and Data Privacy</option>
                                <option value="Internet of Things">Internet of Things</option>
                                <option value="Big Data and Analytics">Big Data and Analytics</option>
                                <option value="AR and VR">AR and VR</option>
                                <option value="Robotics and Automation">Robotics and Automation</option>
                                <option value="Space Exploration and Technology">Space Exploration and Technology</option>
                                <option value="Education and Skill Development">Education and Skill Development</option>
                                <option value="Healthcare and Medical Technology">Healthcare and Medical Technology</option>
                                <option value="Renewbale Energy and Clean Tech">Renewbale Energy and Clean Tech</option>
                                <option value="Circular Economy and Waste Management">Circular Economy and Waste Management</option>
                                <option value="Sustainable Agriculture and Food Tech">Sustainable Agriculture and Food Tech</option>
                                <option value="Urban Development and Smart Cities">Urban Development and Smart Cities</option>
                                <option value="Social Justice and Equality">Social Justice and Equality</option>
                                <option value="Disaster Relief and Preparedness">Disaster Relief and Preparedness</option>
                                <option value="Ecommerce and Retail Tech">Ecommerce and Retail Tech</option>
                                <option value="On Demand Services and Gig Economy">On Demand Services and Gig Economy</option>
                                <option value="Personalized Experiences">Personalized Experiences</option>
                                <option value="Consultancy and Services">Consultancy and Services</option>
                                <option value="Travel and Tourism">Travel and Tourism</option>
                                <option value="Animations and Gaming">Animations and Gaming</option>
                                <option value="Marketing Promotion and Public Relations">Marketing Promotion and Public Relations</option>
                                <option value="Social Groups">Social Groups</option>
                                <option value="Others">Others</option>
                              </select>
                              <code>{sub_category_er}</code>
                            </div>
                            </div>
                          </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label"> Industry</label>
                            <div class="col-sm-9">
                              <select class="form-control" style={{padding:"1rem"}} id="industry" onChange={updateindustry} value={industry}>
                                <option>Select Industry</option>
                                <option value="Technology">Technology</option>
                                <option value="Healthcare">Healthcare</option>
                                <option value="Finance">Finance</option>
                                <option value="Education">Education</option>
                                <option value="Manufacturing">Manufacturing</option>
                                <option value="Retail">Retail</option>
                                <option value="Agriculture">Agriculture</option>
                                <option value="Energy">Energy</option>
                                <option value="Transaportaion">Transaportation</option>
                                <option value="Construction">Construction</option>
                                <option value="Business Assistance">Business Assistance</option>
                                <option value="Other">Other</option>
                              </select>
                              <code>{industry_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label"> Sector</label>
                            <div class="col-sm-9">
                              <select class="form-control" style={{padding:"1rem"}} id="sector" onChange={updatesector} value={sector}>
                                <option>Select Sector</option>
                                <option value="B2B">B2B</option>
                                <option value="B2C">B2C</option>
                                <option value="B2B2C">B2B2C</option>
                              </select>
                              <code>{sector_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <p class="card-description">Address Information</p>
                      <div class="row">
                      <div class="form-group" style={{textAlign:"left"}}>
                        <label for="exampleTextarea1">Address</label>
                        <textarea type="text" class="form-control"  id="address" onChange={updateaddress} value={address}  placeholder="56 North Road"></textarea>
                        <code>{address_er}</code>
                      </div> 
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">City</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control"  id="city" onChange={updatecity} value={city}  placeholder="Mumbai" />
                              <code>{city_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">State</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control"  id="state" onChange={updatestate} value={state}  placeholder="State" />
                              <code>{state_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Country</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control"  id="country" onChange={updatecountry} value={country}  placeholder="India" />
                              <code>{country_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Pincode</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control"  id="pincode" onChange={updatepincode} value={pincode}  placeholder="400029" />
                              <code>{pincode_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <p class="card-description">Other Links</p>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Youtube</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control"  id="youtube" onChange={updateyoutube} value={youtube}  placeholder="https://www.youtube.com/......." />
                              <code>{youtube_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Website</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control"  id="website" onChange={updatewebsite} value={website}  placeholder="https://www.mywebsite.com/" />
                              <code>{website_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Linkedin</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control"  id="linkedin" onChange={updatelinkedin} value={linkedin}  placeholder="https://www.linkedin.com/......." />
                              <code>{linkedin_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Twitter</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control"  id="twitter" onChange={updatetwitter} value={twitter}  placeholder="https://twitter.com/........" />
                              <code>{twitter_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Attach Profile Image</label>
                            <div class="col-sm-3">
                              <input type="file" class="form-control"  id="profile_link" onChange={handleFileChange}  />
                              {selectedFile && <p>Selected file: {selectedFile.name}</p>}
                              <code>{profile_link_er}</code>
                            </div>
                          <div class="col-sm-5">
                          <p>Allowed File format (.png and .jpg), Max. file Size Allowed 1mb. </p>
                          </div>
                        </div>
                      </div>
                      </div>
                      <button class="btn btn-primary" onClick={register} >Register Me as Mentor</button>

                      <div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>

        </div>
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
</>
);

}
export default Mentorregister;