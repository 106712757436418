import React from "react";
import { useState, useEffect, Component } from "react";
import { useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
//import axios from '../../axios.js';
import moment from "moment/moment";

import "../../Resources/homebtcss.css";
import "../../Resources/home2css.css";
import "../../Resources/serviceshomecss.css";
import "../ankitcss.css";
import '../../Resources/responsiveness.css';

//import Sidebar from "./sidebar";

//import Chart from "chart.js/auto";
//import { Line, Bar } from "react-chartjs-2";
import Footer3 from "../../Supportingfiles/footer3";
//import { Navbar } from "flowbite-react";
import Navbar3 from "../../Supportingfiles/navbar3";
import Whystartupconnect from "../whystartupconnect";


function CMAData1() {

  

  /* Cashfree Button Style */

  const button_container = {
    border: "1px solid black",
    borderRadius: "5px",
    display: "flex",
    padding: "10px", 
    width: "fit-content",
    cursor: "pointer",
    background: "#0b6083",
    border:"none"
};

const text_container = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginLeft: "10px",
  justifyContent: "center",
  marginRight: "10px",
  fontWeight:"bold"
};
const logo_container = {
  width: "40px",
  height: "40px"
};
const secondary_logo_container = {
width: "16px",
height: "16px",
verticalAlign: "middle"
};


  return (
    <>
      <html lang="en">
        <head>
          {/*} Required meta tags */}
          <meta charset="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <title>STARTUP CONNECT |  SERVICES | CMA DATA</title>
          {/*} plugins:css */}
          <link
            rel="stylesheet"
            href="panel/assets/vendors/mdi/css/materialdesignicons.min.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/css/vendor.bundle.base.css"
          />
          {/*} endinject */}
          {/*} Plugin css for this page */}
          <link
            rel="stylesheet"
            href="panel/assets/vendors/jquery-bar-rating/css-stars.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/font-awesome/css/font-awesome.min.css"
          />
          {/*} End plugin css for this page */}
          {/*} inject:css */}
          {/*} endinject */}
          {/*} Layout styles */}
          <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
          {/*} End layout styles */}
          <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
        </head>
        <body>

        

  {/* Whatsapp Chat Icon Start */}
  <div class="wtp-chat-container" /*style={{position:"fixed",marginTop:"92vh", marginLeft:"94vw"}}*/>
    <a aria-label="Chat on WhatsApp" href="https://wa.me/918480002413?text=I%20want%20to%20know%20more%20about%20service%20CMA%20Data%20by%20the%20Startup%20Connect">   
    <img class="wtp-chat-icon" src="https://cdn-icons-png.flaticon.com/128/3938/3938058.png" /*style={{height:"3rem", width:"3rem"}}*/></img>
  </a>
  </div>
  {/* Whatsapp Chat Icon End */}

          <div class="home2-container">
            <Navbar3 />

            {/* HEADER SECTION START */}
            <section /*class="sv-header-section" */>
              <div class="sv-header-container1">
                <div class="sv-header-left home-header-pd">
                  <h1 style={{ color: "#004463" }}>
                  CMA REPORT PREPARATION

                  </h1>
                  <p>
                  <div style={{ marginBottom: "2px" }}>
                      <span style={{ paddingRight: "5px" }}><i class="mdi mdi-checkbox-marked" style={{fontSize:"2rem", color :"#0b5748"}}></i></span>
                      <span>Fast customer support </span>
                    </div>
                    <div style={{ marginBottom: "2px" }}>
                      <span style={{ paddingRight: "5px" }}><i class="mdi mdi-checkbox-marked" style={{fontSize:"2rem", color :"#0b5748"}}></i></span>
                      <span>Complete online process.</span>
                    </div>
                    <div style={{ marginBottom: "2px" }}>
                      <span style={{ paddingRight: "5px" }}><i class="mdi mdi-checkbox-marked" style={{fontSize:"2rem", color :"#0b5748"}}></i></span>
                      <span>Instant WhatsApp/email updates </span>
                    </div>
                  </p>

                  <div class="home2-services-features-list">
                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img
                          loading="lazy"
                          src="https://cdn-icons-png.flaticon.com/128/1087/1087840.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>500+</h3>
                        <h5 style={{ fontWeight: "bold" }}>Projects</h5>
                      </div>
                    </div>

                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img
                          loading="lazy"
                          src="https://cdn-icons-png.flaticon.com/128/12735/12735475.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>25+</h3>
                        <h5 style={{ fontWeight: "bold" }}>Cities</h5>
                      </div>
                    </div>

                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img
                          loading="lazy"
                          src="https://cdn-icons-png.flaticon.com/128/2991/2991235.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>
                          4{" "}
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/7656/7656139.png"
                            style={{ height: "2rem", width: "2rem" }}
                          />
                          +
                        </h3>
                        <h5 style={{ fontWeight: "bold" }}>Google Ratings</h5>
                      </div>
                    </div>
                  </div>

              <div  class="serviceshome-btn-container">
             <div>
             <a href="https://calendly.com/thestartupconnect/30min"><button class="btn-lft-margin" id="home2-modules-section-btn" style={{marginLeft:"0"}}>Book Free Consultancy <i class="mdi mdi-arrow-right"></i></button></a>
             </div>
             <div style={{marginLeft:"0"}}>
             <a href="/home1/startupbrochure1.pdf" download="Startup Connect Services Brochure"><button class="btn-lft-margin" id="home2-modules-section-btn" style={{backgroundImage: "linear-gradient(to right, #208d17, #00ad11)", marginLeft:"0"}}>Download Brochure <i class="mdi mdi-download"></i></button></a>
             </div>
             </div>

                </div>
                <div
                  class="sv-header-right" /* style={{backgroundColor:"#96dfff",padding:"2rem",borderRadius:"15px",boxShadow:"2px 3px 3px 2px #cbcecf"}} */
                >
                  <img
                    loading="lazy"
                    src="https://img.freepik.com/free-vector/businessman-laptop_603843-770.jpg"
                    style={{ width: "90%", height: "100%" }}
                  />
                </div>
              </div>
            </section>
            {/* HEADER SECTION END */}
            <hr />

            {/* SIDEBAR AND MAIN PAGE START */}
            <div className="container-scroller">
              {/*<Sidebar /> */}
              <div className="container-fluid page-body-wrapper">
                {/*} partial */}
                <div className="main-panel">
               

                  {/* MORE INFORMATION SECTION START */}
                  <div class="row" /*style={{ padding: "2rem" }}*/>
                    <div
                      class="col-sm-12 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div>
                        <h2 id="ip-h2" style={{ fontWeight: "bold" }}>
                          WHAT IS{" "}
                          <span style={{ color: "#9f23c2" }}>
                          CMA DATA?
                          </span>
                        </h2>
                        <br />
                        
                        <p style={{ fontSize: "1rem", padding:"1rem" }}>
                        Credit Monitoring Arrangement, commonly known as CMA Data is the financial report used by lending institutions to appraise and analyse the financial position of a company before lending. Under the present rules, the Reserve Bank of India has mandated all banks to prepare CMA for lending to large borrowers. Hence, CMA data is required for project loans, term loans and for working capital limits.
                        </p>
                        
                      </div>
                    </div>
               {/*}     <div class="col-sm-3 stretch-card grid-margin">
                      <img
                        loading="lazy"
                        src="https://img.freepik.com/free-vector/business-deal-concept-illustration_114360-1535.jpg"
                        style={{ width: "90%", height: "100%" }}
                      />
                    </div> */}
                  </div>
                  {/* MORE INFORMATION SECTION END */}

                  {/* KEY ASPECTS SECTION START */}
             

<h2 id="ip-h2" style={{textAlign:"left", padding:"1rem"}}>FORMAT AND CONTENTS OF CMA</h2>
<h5 style={{textAlign:"left", padding:"1rem"}}>The format of CMA Data is generally fixed, and it contains preceding two years actual performance, ongoing year estimated figures and next five years projections
<br/> <br/>It contains detailed
</h5>
<div class="row" style={{ padding: "2rem" }}>
  <div
    class="col-sm-2 stretch-card grid-margin" id="resp-3"
    style={{ textAlign: "left" }}
  >
    <div
      class="card"
      style={{ textAlign: "center", padding: "1rem" }}
    >
      <div class="d-flex justify-content-center">
        <img
          loading="lazy"
          src="https://cdn-icons-png.flaticon.com/128/1052/1052846.png"
          style={{ height: "6rem", width: "6rem" }}
        />
      </div>

      <h4 class="mt-3">Profit and Loss Account</h4>
    </div>
  </div>

  <div
    class="col-sm-2 stretch-card grid-margin" id="resp-3"
    style={{ textAlign: "left" }}
  >
    <div
      class="card"
      style={{ textAlign: "center", padding: "1rem" }}
    >
      <div class="d-flex justify-content-center">
        <img
          loading="lazy"
          src="https://cdn-icons-png.flaticon.com/128/2228/2228787.png"
          style={{ height: "6rem", width: "6rem" }}
        />
      </div>
      <h4 class="mt-3">
      Cash Flow Statement
      </h4>
    </div>
  </div>

  <div
    class="col-sm-2 stretch-card grid-margin" id="resp-3"
    style={{ textAlign: "left" }}
  >
    <div
      class="card"
      style={{ textAlign: "center", padding: "1rem" }}
    >
      <div class="d-flex justify-content-center">
        <img
          loading="lazy"
          src="https://cdn-icons-png.flaticon.com/128/9588/9588758.png"
          style={{ height: "6rem", width: "6rem" }}
        />
      </div>
      <h4 class="mt-3">Ratio Analysis</h4>
    </div>
  </div>

  <div
    class="col-sm-2 stretch-card grid-margin" id="resp-3"
    style={{ textAlign: "left" }}
  >
    <div
      class="card"
      style={{ textAlign: "center", padding: "1rem" }}
    >
      <div class="d-flex justify-content-center">
        <img
          loading="lazy"
          src="https://cdn-icons-png.flaticon.com/128/9875/9875524.png"
          style={{ height: "6rem", width: "6rem" }}
        />
      </div>
      <h4 class="mt-3">
      Balance Sheet
      </h4>
    </div>
  </div>

  <div
    class="col-sm-2 stretch-card grid-margin" id="resp-3"
    style={{ textAlign: "left" }}
  >
    <div
      class="card"
      style={{ textAlign: "center", padding: "1rem" }}
    >
      <div class="d-flex justify-content-center">
        <img
          loading="lazy"
          src="https://cdn-icons-png.flaticon.com/128/2228/2228787.png"
          style={{ height: "6rem", width: "6rem" }}
        />
      </div>
      <h4 class="mt-3">
      Cash Flow Statement
      </h4>
    </div>
  </div>

  <div
    class="col-sm-2 stretch-card grid-margin" id="resp-3"
    style={{ textAlign: "left" }}
  >
    <div
      class="card"
      style={{ textAlign: "center", padding: "1rem" }}
    >
      <div class="d-flex justify-content-center">
        <img
          loading="lazy"
          src="https://cdn-icons-png.flaticon.com/128/1584/1584961.png"
          style={{ height: "6rem", width: "6rem" }}
        />
      </div>
      <h4 class="mt-3">
      Analysis of Maximum Permissible Bank Finance{" "}
      </h4>
    </div>
  </div>
</div>
                  {/* KEY ASPECTS SECTION END */}

                  <hr />
                  <h2 id="ip-h2">
                  KEY ASPECTS FOR DRAFTING CMA DATA
                  </h2>
                  {/* BENEFITS SECTION START */}
                  <div class="row d-flex justify-content-center" style={{ padding: "2rem" }}>
                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center mb-3">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/3240/3240831.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        
                        <p>
                        All assumptions and estimates used in preparation of CMA should be mentioned separately
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center mb-3">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/14488/14488465.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        
                        <p>
                        Future projections should be realistic and not merely arithmetic multiples of current performance
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center mb-3">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/993/993857.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        
                        <p>
                        Fluctuations in performance should be strongly justifiable
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center mb-3">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/1907/1907675.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        
                        <p>
                        All fixed assets, depreciation and loan repayment schedules should be annexed and linked to CMA Data{" "}
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center mb-3">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/179/179385.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        
                        <p>
                        Past performance and actual numbers should be exactly as per Audited Financials
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center mb-3">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/2519/2519230.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        
                        <p>
                        The company should be able to justify the performance and numbers projected
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center mb-3">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/4435/4435123.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        
                        <p>
                        In case of multiple businesses activity or locations, detailed annexure should be attached showing breakup of how the projected numbers are arrived at{" "}
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center mb-3">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/11538/11538737.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        
                        <p>
                        CMA Data should represent a viable business performance - over borrowing is unfavourable and cannot be justified through financial ratios
{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* BENEFITS SECTION END */}

                  <hr />

     {/* PRICING SECTION START */}
     <h2 id="ip-h2" style={{fontWeight:'bold'}}>PRICING FOR <span style={{color:"#006345"}}>CMA REPORT PREPARATION</span></h2>
                  <div class="row md-12 d-flex justify-content-center" style={{marginTop:"5rem", padding:"1rem"}}>

                  {/* PRICING CARD 1 START */}
                  <div
                      class="col-sm-4 stretch-card grid-margin" id="resp-2"
                      style={{ textAlign: "left" }}
                    >
                    <div class="card">
                    <div style={{backgroundColor:"#207a78",color:"#ffffff",fontWeight:"bold",textAlign:"center",borderRadius:"12px",padding:"1rem"}}>
                    <h3><u>BUSINESS</u></h3>
                    <div style={{textAlign:"center"}}><h2 style={{fontWeight:"bold"}}> Starts <span style={{color:"#ffffff"}}>₹ 3999*</span></h2></div>
                    </div>
                    <div style={{padding:"1rem",margingTop:"1rem"}}>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-warning" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Preparation of CMA Report for working capital limit upto INR 25 Lakh</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-warning" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Two responses to bank queries</p>
                        <hr/>
                       <div class="d-flex justify-content-center">
                       <button class="btn btn-lg btn-success" style={{backgroundColor:"#207a78", fontSize:"1rem"}} onClick={()=>{
                        //Setting Values to redirect to Contact Sales Page
                        sessionStorage.setItem("Smodule00","Services");sessionStorage.setItem("Sservice00","CMA Data"); window.location.replace("/businesstools-contactsales");
                       }}>Contact Sales</button>
                          {/* CASHFREE CHECKOUT BUTTON START FOR PRICING CARD 1 */}
                          <div>
                        <a style={{textDecoration:"none"}} href="https://payments.cashfree.com/forms/startupconnect-cmadata-payment" target="_parent">
                        <div class="button-container" style={button_container}>
                        <div>
                        {/*}    <img src="https://cashfree-checkoutcartimages-prod.cashfree.com/startupconnectlogo1Y6cg7dgopcs0_prod.png" alt="logo" style={logo_container} class="logo-container" />
                        */} </div>
                        <div class="text-container" style={text_container}>
                        <div style={{fontFamily: "Arial", color: "#fff", marginBottom: "5px", fontSize: "14px"}}>
                        Check Out
                        </div>
                        <div style={{fontFamily: "Arial", color: "#fff", fontSize: "10px"}}>
                        <span>Powered By Cashfree</span>
                        <img src="https://cashfreelogo.cashfree.com/cashfreepayments/logosvgs/Group_4355.svg" alt="logo" style={secondary_logo_container} class="seconday-logo-container" />
                        </div>
                        </div>
                        </div>
                        </a>
                        </div>
                       {/* CASHFREE CHECKOUT BUTTON START FOR PRICING CARD 1 */}
                       </div>
                    </div>
                    </div>
                    </div>
                    {/* PRICING CARD 1 END */}

                      {/* PRICING CARD 2 START */}
                  <div
                      class="col-sm-4 stretch-card grid-margin" id="resp-2"
                      style={{ textAlign: "left" }}
                    >
                    <div class="card">
                    <div style={{backgroundColor:"#207a78",color:"#ffffff",fontWeight:"bold",textAlign:"center",borderRadius:"12px",padding:"1rem"}}>
                    <h3><u>PROFESSIONAL</u></h3>
                    <div style={{textAlign:"center"}}><h2 style={{fontWeight:"bold"}}> Starts <span style={{color:"#ffffff"}}>₹ 5999*</span></h2></div>
                    </div>
                    <div style={{padding:"1rem",margingTop:"1rem"}}>
                    <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-warning" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Preparation of CMA Report for working capital limit upto INR 200 Lakh</p>
                    <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Three responses to bank queries</p>
                      <hr/>
                       <div class="d-flex justify-content-center">
                       <button class="btn btn-lg btn-success" style={{backgroundColor:"#207a78", fontSize:"1rem"}} onClick={()=>{
                        //Setting Values to redirect to Contact Sales Page
                        sessionStorage.setItem("Smodule00","Services");sessionStorage.setItem("Sservice00","CMA Data"); window.location.replace("/businesstools-contactsales");
                       }}>Contact Sales</button>
                          {/* CASHFREE CHECKOUT BUTTON START FOR PRICING CARD 2 */}
                          <div>
                        <a style={{textDecoration:"none"}} href="https://payments.cashfree.com/forms/startupconnect-cmadata-payment-business" target="_parent">
                        <div class="button-container" style={button_container}>
                        <div>
                        {/*}    <img src="https://cashfree-checkoutcartimages-prod.cashfree.com/startupconnectlogo1Y6cg7dgopcs0_prod.png" alt="logo" style={logo_container} class="logo-container" />
                        */} </div>
                        <div class="text-container" style={text_container}>
                        <div style={{fontFamily: "Arial", color: "#fff", marginBottom: "5px", fontSize: "14px"}}>
                        Check Out
                        </div>
                        <div style={{fontFamily: "Arial", color: "#fff", fontSize: "10px"}}>
                        <span>Powered By Cashfree</span>
                        <img src="https://cashfreelogo.cashfree.com/cashfreepayments/logosvgs/Group_4355.svg" alt="logo" style={secondary_logo_container} class="seconday-logo-container" />
                        </div>
                        </div>
                        </div>
                        </a>
                        </div>
                       {/* CASHFREE CHECKOUT BUTTON START FOR PRICING CARD 2 */}
                       </div>
                    </div>
                    </div>
                    </div>
                    {/* PRICING CARD 2 END */}

                        {/* PRICING CARD 3 START */}
                  <div
                      class="col-sm-4 stretch-card grid-margin" id="resp-2"
                      style={{ textAlign: "left" }}
                    >
                    <div class="card">
                    <div style={{backgroundColor:"#207a78",color:"#ffffff",fontWeight:"bold",textAlign:"center",borderRadius:"12px",padding:"1rem"}}>
                    <h3><u>EXPERT</u></h3>
                    <div style={{textAlign:"center"}}><h2 style={{fontWeight:"bold"}}> Starts <span style={{color:"#ffffff"}}>₹ 9999*</span></h2></div>
                    </div>
                    <div style={{padding:"1rem",margingTop:"1rem"}}>
                    <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Preparation of CMA Report for working capital limit upto INR 500 Lakh</p>
                    <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Four responses to bank queries</p>
                      <hr/>
                       <div class="d-flex justify-content-center">
                       <button class="btn btn-lg btn-success" style={{backgroundColor:"#207a78", fontSize:"1rem"}} onClick={()=>{
                        //Setting Values to redirect to Contact Sales Page
                        sessionStorage.setItem("Smodule00","Services");sessionStorage.setItem("Sservice00","CMA Data"); window.location.replace("/businesstools-contactsales");
                       }}>Contact Sales</button>
                          {/* CASHFREE CHECKOUT BUTTON START FOR PRICING CARD 3 */}
                          <div>
                        <a style={{textDecoration:"none"}} href="https://payments.cashfree.com/forms/startupconnect-cmadata-payment-professional" target="_parent">
                        <div class="button-container" style={button_container}>
                        <div>
                        {/*}    <img src="https://cashfree-checkoutcartimages-prod.cashfree.com/startupconnectlogo1Y6cg7dgopcs0_prod.png" alt="logo" style={logo_container} class="logo-container" />
                        */} </div>
                        <div class="text-container" style={text_container}>
                        <div style={{fontFamily: "Arial", color: "#fff", marginBottom: "5px", fontSize: "14px"}}>
                        Check Out
                        </div>
                        <div style={{fontFamily: "Arial", color: "#fff", fontSize: "10px"}}>
                        <span>Powered By Cashfree</span>
                        <img src="https://cashfreelogo.cashfree.com/cashfreepayments/logosvgs/Group_4355.svg" alt="logo" style={secondary_logo_container} class="seconday-logo-container" />
                        </div>
                        </div>
                        </div>
                        </a>
                        </div>
                       {/* CASHFREE CHECKOUT BUTTON START FOR PRICING CARD 3 */}
                       </div>
                    </div>
                    </div>
                    </div>
                    {/* PRICING CARD 3 END */}


                  </div>
                  <hr/>
                  {/* PRICING SECTION END */}

                  {/* Why StartUp Connect Starts */}
                  <Whystartupconnect/>
                  {/* Why StartUp Connect Ends */}

                  {/* REQUIREMENTS SECTION START */}
                  
                  <hr />
                  <h2 id="ip-h2">CMA Report Process</h2>

                  {/* PROCESS SECTION START */}
                  <div class="row d-flex justify-content-center" style={{padding:"1rem"}}>
                    <div class="col-sm-3 stretch-card grid-margin" id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/free-vector/paying-bills-concept-illustration_114360-19357.jpg?w=740&t=st=1706779500~exp=1706780100~hmac=ad8e6bf9dc5da282c80e40c82c023c68f94bea72270aa35612471e5892f8985b"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold"> Step 1 </h4>
                          <div>
                            <p class="mb-0" style={{ fontSize: "1rem",textAlign:"center" }}>
                              Payment and Document Submission
                              <br />
                              Expert review your Documents
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-3 stretch-card grid-margin" id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/free-vector/audit-concept-illustration_114360-6397.jpg?w=996&t=st=1706980269~exp=1706980869~hmac=1eef66789c185c2b1f1caef37a1a79e50de09ff3eb9b7375c46e1327e60094fe"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold"> Step 2 </h4>
                          <div>
                            <p class="mb-0" style={{ fontSize: "1rem", textAlign:"center" }}>
                            Draft CMA report Preperation
                              
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-3 stretch-card grid-margin" id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/free-vector/audit-concept-illustration_114360-6397.jpg?w=996&t=st=1706980269~exp=1706980869~hmac=1eef66789c185c2b1f1caef37a1a79e50de09ff3eb9b7375c46e1327e60094fe"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold"> Step 3 </h4>
                          <div>
                            <p class="mb-0" style={{ fontSize: "1rem",textAlign:"center" }}>
                            Final CMA Report
{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                                    </div>
                  {/* PROCESS SECTION END */}

                  {/* Why StartUp Connect Starts */}

                  
                  {/* Why StartUp Connect Ends */}

               

                  {/* Documents SECTION Starts */}
                 
                  {/* Documents SECTION Ends */}
                  {/* COMPLIANCES SECTION START */}
                 
                
                  {/* COMPLIANCES SECTION END */}
               
                  {/* COMPARISON TABLE START */}
                 
                  

                  {/* COMPARISON TABLE END */}

                  <hr />
                 
                  {/* FAQ SECTION END */}

                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
            {/* SIDEBAR AND MAIN PAGE END */}

            <Footer3 />
          </div>
          {/*} container-scroller */}
          {/*} plugins:js */}
          <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
          {/*} endinject */}
          {/*} Plugin js for this page */}
          <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
          <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
          <script
            src="panel/assets/js/jquery.cookie.js"
            type="text/javascript"
          ></script>
          {/*} End plugin js for this page */}
          {/*} inject:js */}
          <script src="panel/assets/js/off-canvas.js"></script>
          <script src="panel/assets/js/hoverable-collapse.js"></script>
          <script src="panel/assets/js/misc.js"></script>
          <script src="panel/assets/js/settings.js"></script>
          <script src="panel/assets/js/todolist.js"></script>
          {/*} endinject */}
          {/*} Custom js for this page */}
          <script src="panel/assets/js/dashboard.js"></script>
          {/*} End custom js for this page */}
        </body>
      </html>
    </>
  );
}

export default CMAData1;
