import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';



function Mainpanel(){
    
    
    
 return(
    <>  
    
      {/* MAIN PANEL START */}
      <div class="hire-main-panel">
        <h2>View Candidates</h2>
        {/* SEARCH BAR START */}
        <div class="hire-search-bar">

        </div>
        {/* SEARCH BAR END */}

        {/* LIST PANEL START */}
        <div class="list-panel">
        <div style={{width:"100%",height:"2vh"}}></div>
        {/* HIRE LIST START */}  
        <div class="hire-list">
            
        {/* LIST CARD 1 START */}
        <div class="hire-list-card">
        <div class="hire-list-card-top">
        <div class="hire-list-card-top-l"><h3>Surya Pratap Singh</h3></div>
         <div class="hire-list-card-top-r"><p>Project Manager</p></div>
        </div>
        <div class="hire-list-card-area">
        <div class="hire-list-card-area-l">
        <div class="hire-list-card-salarycard">
          <p><b> Current Salary/CTC :</b> 12000-35000 per month</p>
          <p><b> Expected Salary/CTC :</b> 12000-35000 per month</p>
          <p><b>Introduction</b></p>
          <p>Passionate Full Stack Web Developer with a proven track record of creating robust,
            user-friendly, and visually appealing web applications. Proficient in both front-end and
            back-end technologies, I thrive in collaborative environments to turn innovative ideas
            into functional, scalable, and efficient digital solutions.
            <br/>
            Passionate Full Stack Web Developer with a proven track record of creating robust,
            user-friendly, and visually appealing web applications.</p>
        </div>
        </div> 
        <div class="hire-list-card-area-r">
        <div class="hire-list-card-badge" style={{color:"black"}}>
          <span>Full Time</span>
        </div>

        <div class="hire-list-card-badge" style={{color:"black"}}>
          <span>Day Shift</span>
        </div>

        <div class="hire-list-card-badge" style={{backgroundColor:"#ffffff",border:"0.1rem solid #013cb1",color:"#1a1b1d"}}>
        <div class="hire-list-card-badge-component">
        <div class="hire-list-card-badge-component-l"><img src="https://cdn-icons-png.flaticon.com/128/3281/3281289.png" /></div>
        <div class="hire-list-card-badge-component-r"><span>3 years</span></div>
        </div>
        </div>

        <div class="hire-list-card-badge" style={{backgroundColor:"#ffffff",border:"none",color:"black",boxShadow:"none"}}>
        <div class="hire-list-card-badge-component">
        <div class="hire-list-card-badge-component-l"><img src="https://cdn-icons-png.flaticon.com/128/616/616489.png" style={{height:"2.5rem",width:"2.5rem"}} /></div>
        <div class="hire-list-card-badge-component-r"><span style={{fontSize:"100%",fontWeight:"bold"}}>4.5</span></div>
        </div>
        </div>

        <div class="hire-list-card-badge" style={{color:"black"}}>
          <span><b>Score : </b>84/100</span>
        </div>

        <div class="hire-list-card-badge" style={{backgroundColor:"#49e38a",color:"#ffffff"}}>
          <span><b>looking for work</b></span>
        </div>

        <div class="hire-list-card-badge" style={{color:"black"}}>
          <span><b>Industry: </b>IT/ Tech</span>
        </div>

        <div class="hire-list-card-badge" style={{backgroundColor:"#ffffff",boxShadow:"none"}}>
        <img src="https://cdn-icons-png.flaticon.com/128/9977/9977292.png" class="hire-card-icon" style={{marginLeft:"70%"}} />
        </div>

        </div>  
        </div>
        <div class="hire-list-card-btn-list">
        <div style={{marginTop:"1rem"}}>
         <img src="https://cdn-icons-png.flaticon.com/128/724/724664.png" class="hire-card-icon" />
         <img src="https://cdn-icons-png.flaticon.com/128/6806/6806987.png" class="hire-card-icon" style={{marginLeft:"1rem"}} />
        </div>
        <div>
          <button class="hire-btn-default" style={{backgroundColor:"#7c077a"}} onClick={()=>{
            window.location.replace("/employer-view-resume");
          }}>View Detailed C.V.</button>
        </div>
        
        <div class="hire-list-card-function">
        <div class="hire-list-card-function-component">
        <div class="hire-list-card-function-component-l">
        <button><img src="https://cdn-icons-png.flaticon.com/128/2107/2107845.png" /></button></div>
        <div class="hire-list-card-function-component-r"><span><b>45</b></span></div>
        </div>
        </div>

        <div class="hire-list-card-function">
        <div class="hire-list-card-function-component">
        <div class="hire-list-card-function-component-l">
        <button><img src="https://cdn-icons-png.flaticon.com/128/5186/5186087.png" /></button></div>
        <div class="hire-list-card-function-component-r"><span><b>45</b></span></div>
        </div>
        </div>


        </div>

        <div class="hire-list-card-skillbar">
        <div class="hire-card-skill-badge">
        <span>MySql</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>Web Development</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>React Js</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>Laravel</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>HTML/CSS</span>
        </div>  
       
        </div>

        <div class="hire-list-card-bottom">
          <p><b>Last Seen : </b> 1 Day Ago</p>
        </div>

        <div style={{height:"0.5rem"}}></div>
        </div>

        {/* LIST CARD 1 START */}


        {/* LIST CARD 2 START */}
        <div class="hire-list-card">
        <div class="hire-list-card-top">
        <div class="hire-list-card-top-l"><h3>Surya Pratap Singh</h3></div>
         <div class="hire-list-card-top-r"><p>Project Manager</p></div>
        </div>
        <div class="hire-list-card-area">
        <div class="hire-list-card-area-l">
        <div class="hire-list-card-salarycard">
          <p><b> Current Salary/CTC :</b> 12000-35000 per month</p>
          <p><b> Expected Salary/CTC :</b> 12000-35000 per month</p>
          <p><b>Introduction</b></p>
          <p>Passionate Full Stack Web Developer with a proven track record of creating robust,
            user-friendly, and visually appealing web applications. Proficient in both front-end and
            back-end technologies, I thrive in collaborative environments to turn innovative ideas
            into functional, scalable, and efficient digital solutions.
            <br/>
            Passionate Full Stack Web Developer with a proven track record of creating robust,
            user-friendly, and visually appealing web applications.</p>
        </div>
        </div> 
        <div class="hire-list-card-area-r">
        <div class="hire-list-card-badge" style={{color:"black"}}>
          <span>Full Time</span>
        </div>

        <div class="hire-list-card-badge" style={{color:"black"}}>
          <span>Day Shift</span>
        </div>

        <div class="hire-list-card-badge" style={{backgroundColor:"#ffffff",border:"0.1rem solid #013cb1",color:"#1a1b1d"}}>
        <div class="hire-list-card-badge-component">
        <div class="hire-list-card-badge-component-l"><img src="https://cdn-icons-png.flaticon.com/128/3281/3281289.png" /></div>
        <div class="hire-list-card-badge-component-r"><span>3 years</span></div>
        </div>
        </div>

        <div class="hire-list-card-badge" style={{backgroundColor:"#ffffff",border:"none",color:"black",boxShadow:"none"}}>
        <div class="hire-list-card-badge-component">
        <div class="hire-list-card-badge-component-l"><img src="https://cdn-icons-png.flaticon.com/128/616/616489.png" style={{height:"2.5rem",width:"2.5rem"}} /></div>
        <div class="hire-list-card-badge-component-r"><span style={{fontSize:"100%",fontWeight:"bold"}}>4.5</span></div>
        </div>
        </div>

        <div class="hire-list-card-badge" style={{color:"black"}}>
          <span><b>Score : </b>84/100</span>
        </div>

        <div class="hire-list-card-badge" style={{backgroundColor:"#49e38a",color:"#ffffff"}}>
          <span><b>looking for work</b></span>
        </div>

        <div class="hire-list-card-badge" style={{color:"black"}}>
          <span><b>Industry: </b>IT/ Tech</span>
        </div>

        <div class="hire-list-card-badge" style={{backgroundColor:"#ffffff",boxShadow:"none"}}>
        <img src="https://cdn-icons-png.flaticon.com/128/9977/9977292.png" class="hire-card-icon" style={{marginLeft:"70%"}} />
        </div>

        </div>  
        </div>
        <div class="hire-list-card-btn-list">
        <div style={{marginTop:"1rem"}}>
         <img src="https://cdn-icons-png.flaticon.com/128/724/724664.png" class="hire-card-icon" />
         <img src="https://cdn-icons-png.flaticon.com/128/6806/6806987.png" class="hire-card-icon" style={{marginLeft:"1rem"}} />
        </div>
        <div>
          <button class="hire-btn-default" style={{backgroundColor:"#7c077a"}} onClick={()=>{
            window.location.replace("/employer-view-resume");
          }}>View Detailed C.V.</button>
        </div>
        
        <div class="hire-list-card-function">
        <div class="hire-list-card-function-component">
        <div class="hire-list-card-function-component-l">
        <button><img src="https://cdn-icons-png.flaticon.com/128/2107/2107845.png" /></button></div>
        <div class="hire-list-card-function-component-r"><span><b>45</b></span></div>
        </div>
        </div>

        <div class="hire-list-card-function">
        <div class="hire-list-card-function-component">
        <div class="hire-list-card-function-component-l">
        <button><img src="https://cdn-icons-png.flaticon.com/128/5186/5186087.png" /></button></div>
        <div class="hire-list-card-function-component-r"><span><b>45</b></span></div>
        </div>
        </div>

        

        </div>

        <div class="hire-list-card-skillbar">
        <div class="hire-card-skill-badge">
        <span>MySql</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>Web Development</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>React Js</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>Laravel</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>HTML/CSS</span>
        </div>  
       
        </div>

        <div class="hire-list-card-bottom">
          <p><b>Last Seen : </b> 1 Day Ago</p>
        </div>

        <div style={{height:"0.5rem"}}></div>
        </div>

        {/* LIST CARD 2 END */}


        {/* LIST CARD 3 START */}
        <div class="hire-list-card">
        <div class="hire-list-card-top">
        <div class="hire-list-card-top-l"><h3>Surya Pratap Singh</h3></div>
         <div class="hire-list-card-top-r"><p>Project Manager</p></div>
        </div>
        <div class="hire-list-card-area">
        <div class="hire-list-card-area-l">
        <div class="hire-list-card-salarycard">
          <p><b> Current Salary/CTC :</b> 12000-35000 per month</p>
          <p><b> Expected Salary/CTC :</b> 12000-35000 per month</p>
          <p><b>Introduction</b></p>
          <p>Passionate Full Stack Web Developer with a proven track record of creating robust,
            user-friendly, and visually appealing web applications. Proficient in both front-end and
            back-end technologies, I thrive in collaborative environments to turn innovative ideas
            into functional, scalable, and efficient digital solutions.
            <br/>
            Passionate Full Stack Web Developer with a proven track record of creating robust,
            user-friendly, and visually appealing web applications.</p>
        </div>
        </div> 
        <div class="hire-list-card-area-r">
        <div class="hire-list-card-badge" style={{color:"black"}}>
          <span>Full Time</span>
        </div>

        <div class="hire-list-card-badge" style={{color:"black"}}>
          <span>Day Shift</span>
        </div>

        <div class="hire-list-card-badge" style={{backgroundColor:"#ffffff",border:"0.1rem solid #013cb1",color:"#1a1b1d"}}>
        <div class="hire-list-card-badge-component">
        <div class="hire-list-card-badge-component-l"><img src="https://cdn-icons-png.flaticon.com/128/3281/3281289.png" /></div>
        <div class="hire-list-card-badge-component-r"><span>3 years</span></div>
        </div>
        </div>

        <div class="hire-list-card-badge" style={{backgroundColor:"#ffffff",border:"none",color:"black",boxShadow:"none"}}>
        <div class="hire-list-card-badge-component">
        <div class="hire-list-card-badge-component-l"><img src="https://cdn-icons-png.flaticon.com/128/616/616489.png" style={{height:"2.5rem",width:"2.5rem"}} /></div>
        <div class="hire-list-card-badge-component-r"><span style={{fontSize:"100%",fontWeight:"bold"}}>4.5</span></div>
        </div>
        </div>

        <div class="hire-list-card-badge" style={{color:"black"}}>
          <span><b>Score : </b>84/100</span>
        </div>

        <div class="hire-list-card-badge" style={{backgroundColor:"#49e38a",color:"#ffffff"}}>
          <span><b>looking for work</b></span>
        </div>

        <div class="hire-list-card-badge" style={{color:"black"}}>
          <span><b>Industry: </b>IT/ Tech</span>
        </div>

        <div class="hire-list-card-badge" style={{backgroundColor:"#ffffff",boxShadow:"none"}}>
        <img src="https://cdn-icons-png.flaticon.com/128/9977/9977292.png" class="hire-card-icon" style={{marginLeft:"70%"}} />
        </div>

        </div>  
        </div>
        <div class="hire-list-card-btn-list">
        <div style={{marginTop:"1rem"}}>
         <img src="https://cdn-icons-png.flaticon.com/128/724/724664.png" class="hire-card-icon" />
         <img src="https://cdn-icons-png.flaticon.com/128/6806/6806987.png" class="hire-card-icon" style={{marginLeft:"1rem"}} />
        </div>
        <div>
          <button class="hire-btn-default" style={{backgroundColor:"#7c077a"}} onClick={()=>{
            window.location.replace("/employer-view-resume");
          }}>View Detailed C.V.</button>
        </div>
        
        <div class="hire-list-card-function">
        <div class="hire-list-card-function-component">
        <div class="hire-list-card-function-component-l">
        <button><img src="https://cdn-icons-png.flaticon.com/128/2107/2107845.png" /></button></div>
        <div class="hire-list-card-function-component-r"><span><b>45</b></span></div>
        </div>
        </div>

        <div class="hire-list-card-function">
        <div class="hire-list-card-function-component">
        <div class="hire-list-card-function-component-l">
        <button><img src="https://cdn-icons-png.flaticon.com/128/5186/5186087.png" /></button></div>
        <div class="hire-list-card-function-component-r"><span><b>45</b></span></div>
        </div>
        </div>

        

        </div>

        <div class="hire-list-card-skillbar">
        <div class="hire-card-skill-badge">
        <span>MySql</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>Web Development</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>React Js</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>Laravel</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>HTML/CSS</span>
        </div>  
       
        </div>

        <div class="hire-list-card-bottom">
          <p><b>Last Seen : </b> 1 Day Ago</p>
        </div>

        <div style={{height:"0.5rem"}}></div>
        </div>

        {/* LIST CARD 3 END */}


        {/* LIST CARD 4 START */}
        <div class="hire-list-card">
        <div class="hire-list-card-top">
        <div class="hire-list-card-top-l"><h3>Surya Pratap Singh</h3></div>
         <div class="hire-list-card-top-r"><p>Project Manager</p></div>
        </div>
        <div class="hire-list-card-area">
        <div class="hire-list-card-area-l">
        <div class="hire-list-card-salarycard">
          <p><b> Current Salary/CTC :</b> 12000-35000 per month</p>
          <p><b> Expected Salary/CTC :</b> 12000-35000 per month</p>
          <p><b>Introduction</b></p>
          <p>Passionate Full Stack Web Developer with a proven track record of creating robust,
            user-friendly, and visually appealing web applications. Proficient in both front-end and
            back-end technologies, I thrive in collaborative environments to turn innovative ideas
            into functional, scalable, and efficient digital solutions.
            <br/>
            Passionate Full Stack Web Developer with a proven track record of creating robust,
            user-friendly, and visually appealing web applications.</p>
        </div>
        </div> 
        <div class="hire-list-card-area-r">
        <div class="hire-list-card-badge" style={{color:"black"}}>
          <span>Full Time</span>
        </div>

        <div class="hire-list-card-badge" style={{color:"black"}}>
          <span>Day Shift</span>
        </div>

        <div class="hire-list-card-badge" style={{backgroundColor:"#ffffff",border:"0.1rem solid #013cb1",color:"#1a1b1d"}}>
        <div class="hire-list-card-badge-component">
        <div class="hire-list-card-badge-component-l"><img src="https://cdn-icons-png.flaticon.com/128/3281/3281289.png" /></div>
        <div class="hire-list-card-badge-component-r"><span>3 years</span></div>
        </div>
        </div>

        <div class="hire-list-card-badge" style={{backgroundColor:"#ffffff",border:"none",color:"black",boxShadow:"none"}}>
        <div class="hire-list-card-badge-component">
        <div class="hire-list-card-badge-component-l"><img src="https://cdn-icons-png.flaticon.com/128/616/616489.png" style={{height:"2.5rem",width:"2.5rem"}} /></div>
        <div class="hire-list-card-badge-component-r"><span style={{fontSize:"100%",fontWeight:"bold"}}>4.5</span></div>
        </div>
        </div>

        <div class="hire-list-card-badge" style={{color:"black"}}>
          <span><b>Score : </b>84/100</span>
        </div>

        <div class="hire-list-card-badge" style={{backgroundColor:"#49e38a",color:"#ffffff"}}>
          <span><b>looking for work</b></span>
        </div>

        <div class="hire-list-card-badge" style={{color:"black"}}>
          <span><b>Industry: </b>IT/ Tech</span>
        </div>

        <div class="hire-list-card-badge" style={{backgroundColor:"#ffffff",boxShadow:"none"}}>
        <img src="https://cdn-icons-png.flaticon.com/128/9977/9977292.png" class="hire-card-icon" style={{marginLeft:"70%"}} />
        </div>

        </div>  
        </div>
        <div class="hire-list-card-btn-list">
        <div style={{marginTop:"1rem"}}>
         <img src="https://cdn-icons-png.flaticon.com/128/724/724664.png" class="hire-card-icon" />
         <img src="https://cdn-icons-png.flaticon.com/128/6806/6806987.png" class="hire-card-icon" style={{marginLeft:"1rem"}} />
        </div>
        <div>
          <button class="hire-btn-default" style={{backgroundColor:"#7c077a"}} onClick={()=>{
            window.location.replace("/employer-view-resume");
          }}>View Detailed C.V.</button>
        </div>
        
        <div class="hire-list-card-function">
        <div class="hire-list-card-function-component">
        <div class="hire-list-card-function-component-l">
        <button><img src="https://cdn-icons-png.flaticon.com/128/2107/2107845.png" /></button></div>
        <div class="hire-list-card-function-component-r"><span><b>45</b></span></div>
        </div>
        </div>

        <div class="hire-list-card-function">
        <div class="hire-list-card-function-component">
        <div class="hire-list-card-function-component-l">
        <button><img src="https://cdn-icons-png.flaticon.com/128/5186/5186087.png" /></button></div>
        <div class="hire-list-card-function-component-r"><span><b>45</b></span></div>
        </div>
        </div>

        

        </div>

        <div class="hire-list-card-skillbar">
        <div class="hire-card-skill-badge">
        <span>MySql</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>Web Development</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>React Js</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>Laravel</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>HTML/CSS</span>
        </div>  
       
        </div>

        <div class="hire-list-card-bottom">
          <p><b>Last Seen : </b> 1 Day Ago</p>
        </div>

        <div style={{height:"0.5rem"}}></div>
        </div>

        {/* LIST CARD 4 END */}

        {/* LIST CARD 5 START */}
        <div class="hire-list-card">
        <div class="hire-list-card-top">
        <div class="hire-list-card-top-l"><h3>Surya Pratap Singh</h3></div>
         <div class="hire-list-card-top-r"><p>Project Manager</p></div>
        </div>
        <div class="hire-list-card-area">
        <div class="hire-list-card-area-l">
        <div class="hire-list-card-salarycard">
          <p><b> Current Salary/CTC :</b> 12000-35000 per month</p>
          <p><b> Expected Salary/CTC :</b> 12000-35000 per month</p>
          <p><b>Introduction</b></p>
          <p>Passionate Full Stack Web Developer with a proven track record of creating robust,
            user-friendly, and visually appealing web applications. Proficient in both front-end and
            back-end technologies, I thrive in collaborative environments to turn innovative ideas
            into functional, scalable, and efficient digital solutions.
            <br/>
            Passionate Full Stack Web Developer with a proven track record of creating robust,
            user-friendly, and visually appealing web applications.</p>
        </div>
        </div> 
        <div class="hire-list-card-area-r">
        <div class="hire-list-card-badge" style={{color:"black"}}>
          <span>Full Time</span>
        </div>

        <div class="hire-list-card-badge" style={{color:"black"}}>
          <span>Day Shift</span>
        </div>

        <div class="hire-list-card-badge" style={{backgroundColor:"#ffffff",border:"0.1rem solid #013cb1",color:"#1a1b1d"}}>
        <div class="hire-list-card-badge-component">
        <div class="hire-list-card-badge-component-l"><img src="https://cdn-icons-png.flaticon.com/128/3281/3281289.png" /></div>
        <div class="hire-list-card-badge-component-r"><span>3 years</span></div>
        </div>
        </div>

        <div class="hire-list-card-badge" style={{backgroundColor:"#ffffff",border:"none",color:"black",boxShadow:"none"}}>
        <div class="hire-list-card-badge-component">
        <div class="hire-list-card-badge-component-l"><img src="https://cdn-icons-png.flaticon.com/128/616/616489.png" style={{height:"2.5rem",width:"2.5rem"}} /></div>
        <div class="hire-list-card-badge-component-r"><span style={{fontSize:"100%",fontWeight:"bold"}}>4.5</span></div>
        </div>
        </div>

        <div class="hire-list-card-badge" style={{color:"black"}}>
          <span><b>Score : </b>84/100</span>
        </div>

        <div class="hire-list-card-badge" style={{backgroundColor:"#49e38a",color:"#ffffff"}}>
          <span><b>looking for work</b></span>
        </div>

        <div class="hire-list-card-badge" style={{color:"black"}}>
          <span><b>Industry: </b>IT/ Tech</span>
        </div>

        <div class="hire-list-card-badge" style={{backgroundColor:"#ffffff",boxShadow:"none"}}>
        <img src="https://cdn-icons-png.flaticon.com/128/9977/9977292.png" class="hire-card-icon" style={{marginLeft:"70%"}} />
        </div>

        </div>  
        </div>
        <div class="hire-list-card-btn-list">
        <div style={{marginTop:"1rem"}}>
         <img src="https://cdn-icons-png.flaticon.com/128/724/724664.png" class="hire-card-icon" />
         <img src="https://cdn-icons-png.flaticon.com/128/6806/6806987.png" class="hire-card-icon" style={{marginLeft:"1rem"}} />
        </div>
        <div>
          <button class="hire-btn-default" style={{backgroundColor:"#7c077a"}} onClick={()=>{
            window.location.replace("/employer-view-resume");
          }}>View Detailed C.V.</button>
        </div>
        
        <div class="hire-list-card-function">
        <div class="hire-list-card-function-component">
        <div class="hire-list-card-function-component-l">
        <button><img src="https://cdn-icons-png.flaticon.com/128/2107/2107845.png" /></button></div>
        <div class="hire-list-card-function-component-r"><span><b>45</b></span></div>
        </div>
        </div>

        <div class="hire-list-card-function">
        <div class="hire-list-card-function-component">
        <div class="hire-list-card-function-component-l">
        <button><img src="https://cdn-icons-png.flaticon.com/128/5186/5186087.png" /></button></div>
        <div class="hire-list-card-function-component-r"><span><b>45</b></span></div>
        </div>
        </div>

        

        </div>

        <div class="hire-list-card-skillbar">
        <div class="hire-card-skill-badge">
        <span>MySql</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>Web Development</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>React Js</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>Laravel</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>HTML/CSS</span>
        </div>  
       
        </div>

        <div class="hire-list-card-bottom">
          <p><b>Last Seen : </b> 1 Day Ago</p>
        </div>

        <div style={{height:"0.5rem"}}></div>
        </div>

        {/* LIST CARD 5 END */}
       

        </div>
        {/* HIRE LIST END */}  
        </div>
        {/* LIST PANEL END */}

        </div>
        {/* MAIN PANEL END */} 

   </>
 );

    }

    export default Mainpanel;