import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import moment from 'moment/moment';


import '../Resources/dashboard.css';
import axios from '../../axios.js';
import Navbar from '../navbar.js';

 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");

  //Other Constatants of Sessions
  const startup_key=sessionStorage.getItem("StartupKey10");
  const startup_id=sessionStorage.getItem("StartupId10");

function Startupedit(){



//startup_name
const[startup_name,setstartup_name]=useState();const updatestartup_name = (event) => { setstartup_name(event.target.value);}; const[startup_name_er,setstartup_name_er]=useState();
//contact
const[contact,setcontact]=useState();const updatecontact = (event) => { setcontact(event.target.value);}; const[contact_er,setcontact_er]=useState();
//email
const[email,setemail]=useState();const updateemail = (event) => { setemail(event.target.value);};  const[email_er,setemail_er]=useState();
//company_name
const[company_name,setcompany_name]=useState();const updatecompany_name = (event) => { setcompany_name(event.target.value);}; const[company_name_er,setcompany_name_er]=useState();
//dpiit
const[dpiit,setdpiit]=useState();const updatedpiit = (event) => { setdpiit(event.target.value);};  const[dpiit_er,setdpiit_er]=useState();
//pan
const[pan,setpan]=useState();const updatepan = (event) => { setpan(event.target.value);}; const[pan_er,setpan_er]=useState();
//registration_number
const[registration_number,setregistration_number]=useState();const updateregistration_number = (event) => { setregistration_number(event.target.value);};  const[registration_number_er,setregistration_number_er]=useState();
//gstin
const[gstin,setgstin]=useState();const updategstin = (event) => { setgstin(event.target.value);}; const[gstin_er,setgstin_er]=useState();
//date_of_establishment
const[date_of_establishment,setdate_of_establishment]=useState();const updatedate_of_establishment = (event) => { setdate_of_establishment(event.target.value);}; const[date_of_establishment_er,setdate_of_establishment_er]=useState();
//introduction
const[introduction,setintroduction]=useState();const updateintroduction = (event) => { setintroduction(event.target.value);};  const[introduction_er,setintroduction_er]=useState();
//industry
const[industry,setindustry]=useState();const updateindustry = (event) => { setindustry(event.target.value);}; const[industry_er,setindustry_er]=useState();
//sector
const[sector,setsector]=useState();const updatesector = (event) => { setsector(event.target.value);};  const[sector_er,setsector_er]=useState();
//category
const[category,setcategory]=useState();const updatecategory = (event) => { setcategory(event.target.value);}; const[category_er,setcategory_er]=useState();
//sub_category
const[sub_category,setsub_category]=useState();const updatesub_category = (event) => { setsub_category(event.target.value);};  const[sub_category_er,setsub_category_er]=useState();
//type
const[type,settype]=useState();const updatetype = (event) => { settype(event.target.value);};  const[type_er,settype_er]=useState();
//address
const[address,setaddress]=useState();const updateaddress = (event) => { setaddress(event.target.value);}; const[address_er,setaddress_er]=useState();
//city
const[city,setcity]=useState();const updatecity = (event) => { setcity(event.target.value);};  const[city_er,setcity_er]=useState();
//state
const[state,setstate]=useState();const updatestate = (event) => { setstate(event.target.value);}; const[state_er,setstate_er]=useState();
//country
const[country,setcountry]=useState();const updatecountry = (event) => { setcountry(event.target.value);};  const[country_er,setcountry_er]=useState();
//pincode
const[pincode,setpincode]=useState();const updatepincode = (event) => { setpincode(event.target.value);}; const[pincode_er,setpincode_er]=useState();
//youtube
const[youtube,setyoutube]=useState();const updateyoutube = (event) => { setyoutube(event.target.value);};  const[youtube_er,setyoutube_er]=useState();
//website
const[website,setwebsite]=useState();const updatewebsite = (event) => { setwebsite(event.target.value);}; const[website_er,setwebsite_er]=useState();
//linkedin
const[linkedin,setlinkedin]=useState();const updatelinkedin = (event) => { setlinkedin(event.target.value);};  const[linkedin_er,setlinkedin_er]=useState();
//twitter
const[twitter,settwitter]=useState();const updatetwitter = (event) => { settwitter(event.target.value);}; const[twitter_er,settwitter_er]=useState();
//instagram
const[instagram,setinstagram]=useState();const updateinstagram = (event) => { setinstagram(event.target.value);}; const[instagram_er,setinstagram_er]=useState();
//facebook
const[facebook,setfacebook]=useState();const updatefacebook = (event) => { setfacebook(event.target.value);}; const[facebook_er,setfacebook_er]=useState();
//logo_link
const[logo_link,setlogo_link]=useState();const updatelogo_link = (event) => { setlogo_link(event.target.value[0]);};  const[logo_link_er,setlogo_link_er]=useState();



//image upload
 //File Upload
 const [selectedFile, setSelectedFile] = React.useState(null);
 const handleFileChange = (event) => {  const file = event.target.files[0];  setSelectedFile(file); };


 
//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();
const[errorvar,seterrorvar]=useState();


useEffect(()=>{
   
  getData();
  
  },[])
  
//******************* GET STARTUP DATA FUNCTION START  ********************//
function getData(){


  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((startup_id!=null)||(startup_id!=" "))&&((startup_key!=null)||(startup_key!=" "))){
 
 axios.post("network-get-startup-details-byId", {
   Log_id:logid,
   Token:token,
   Startup_id:startup_id,
   Startup_key:startup_key
   })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("NetworkVal00");
     sessionStorage.removeItem("StartupId10");
     sessionStorage.removeItem("StartupKey10");

     window.location.replace("/networking-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){

   //Setting Data
   
  setstartup_name(response.data.startup_name);//startup_name
  setcontact(response.data.contact);//contact
  setemail(response.data.email);//email
  setcompany_name(response.data.company_name);//company_name
  setdpiit(response.data.dpiit);//dpiit
  setpan(response.data.pan);//pan
  setregistration_number(response.data.registration_number);//registration_number
  setgstin(response.data.gstin);//gstin
  setdate_of_establishment(response.data.date_of_establishment);//date_of_establishment
  setintroduction(response.data.introduction);//introduction
  setindustry(response.data.industry);//industry
  setsector(response.data.sector);//startup_name
  setcategory(response.data.category);//category
  setsub_category(response.data.sub_category);//sub_category
  settype(response.data.type);//type
  setaddress(response.data.address);//address
  setcity(response.data.city);//city
  setstate(response.data.state);//state  
  setcountry(response.data.country);//country
  setpincode(response.data.pincode);//pincode
  setyoutube(response.data.youtube);//youtube
  setwebsite(response.data.website);//website
  setlinkedin(response.data.linkedin);//linkedin
  settwitter(response.data.twitter);//twitter
  setinstagram(response.data.instagram);//instagram
  setfacebook(response.data.facebook);//facebook





  }

  //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){
    seterrorvar("Cannot Fetch Data !");

  }

  //Data Violation
  if(response.data.error==102){ 
  
  seterrorvar("Cannot Fetch Data !");


  }


  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("logName00");
   sessionStorage.removeItem("StartupId00");
   sessionStorage.removeItem("StartupKey00");
   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* GET STARTUP DATA FUNCTION END  ********************//


/***************** EDIT FUNCTION START  ******************/
function editstartup(){

  
  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))){


    //Setting Error Variables
    setstartup_name_er(" "); setemail_er(" ");setcontact_er(" ");setcompany_name_er(" ");setdpiit_er(" ");setpan_er(" ");setregistration_number_er();setgstin_er();setdate_of_establishment_er();setintroduction_er();
    setindustry_er();setsector_er();setcategory_er();setsub_category_er();setaddress_er();setcity_er();setstate_er();setcountry_er();setpincode_er();setyoutube_er();setwebsite_er();setlinkedin_er();settwitter_er();
    setinstagram_er();setfacebook_er();setlogo_link_er();seterror_msg(" ");

    
//Fetching Form Data
let noerror = 0;
const $startup_name = document.getElementById("startup_name").value; if($startup_name==""){ setstartup_name_er("Startup Name is required !");noerror = noerror+1; }//Fetching startup_name Value
const $email = document.getElementById("email").value; if($email==""){ setemail_er("Email is required !");noerror = noerror+1; }//Fetching Email Value
const $contact = document.getElementById("contact").value; if($contact==""){ setcontact_er("Contact is required !");noerror = noerror+1; }//Fetching contact Value
const $company_name = document.getElementById("company_name").value; if($company_name==""){ setcompany_name_er("Company Name is required !");noerror = noerror+1; }//Fetching company_name Value
const $date_of_establishment = document.getElementById("date_of_establishment").value; if($date_of_establishment==""){ setdate_of_establishment_er("Date of Establishment is required !");noerror = noerror+1; }//Fetching date_of_establishment Value
const $introduction = document.getElementById("introduction").value; if($introduction==""){ setintroduction_er("Introduction is required !");noerror = noerror+1; }//Fetching introduction Value
const $industry = document.getElementById("industry").value; if($industry==""){ setindustry_er("Industry is required !");noerror = noerror+1; }//Fetching industry Value
const $sector = document.getElementById("sector").value; if($sector==""){ setsector_er("Sector is required !");noerror = noerror+1; }//Fetching sector Value
const $category = document.getElementById("category").value; if($category==""){ setcategory_er("Category is required !");noerror = noerror+1; }//Fetching category Value
const $sub_category = document.getElementById("sub_category").value; if($sub_category==""){ setsub_category_er("Sub Category is required !");noerror = noerror+1; }//Fetching sub_category Value
const $type = document.getElementById("type").value; if($type==""){ settype_er("Type is required !");noerror = noerror+1; }//Fetching type Value
const $dpiit = document.getElementById("dpiit").value; //Fetching dpiit Value
const $pan = document.getElementById("pan").value; //Fetching pan Value
const $registration_number = document.getElementById("registration_number").value; //Fetching registration_number Value
const $gstin = document.getElementById("gstin").value; //Fetching gstin Value
const $address = document.getElementById("address").value; //Fetching address Value
const $city = document.getElementById("city").value; //Fetching city Value
const $state = document.getElementById("state").value; //Fetching state Value
const $country = document.getElementById("country").value; //Fetching country Value
const $pincode = document.getElementById("pincode").value; //Fetching pincode Value
const $youtube = document.getElementById("youtube").value; //Fetching youtube Value
const $website = document.getElementById("website").value; //Fetching website Value
const $linkedin = document.getElementById("linkedin").value; //Fetching linkedin Value
const $twitter = document.getElementById("twitter").value; //Fetching twitter Value
const $facebook = document.getElementById("facebook").value; //Fetching facebook Value
const $instagram = document.getElementById("instagram").value; //Fetching instagram Value
//const $profile_link = document.getElementById("profile_link").value; //Fetching profile_link Value

//No primary errors
if(noerror==0){


  //Sending Data through axios
  axios.post("network-edit-startup", { 
  Log_id:logid,
  Token:token,
  Startup_id:startup_id,
  Startup_key:startup_key,
  Startup_name:$startup_name,
  Email:$email,
  Contact:$contact,
  Company_name:$company_name,
  Dpiit:$dpiit,
  Pan:$pan,
  Registration_number:$registration_number,
  Gstin:$gstin,
  Date_of_establishment:moment($date_of_establishment).format('YYYY-MM-DD'),
  Introduction:$introduction,
  Industry:$industry,
  Sector:$sector,
  Category:$category,
  Sub_category:$sub_category,
  Type:$type,
  Address:$address,
  City:$city,
  State:$state,
  Country:$country,
  Pincode:$pincode,
  Youtube:$youtube,
  Website:$website,
  Linkedin:$linkedin,
  Twitter:$twitter,
  Facebook:$facebook,
  Instagram:$instagram,
  Logo_link:selectedFile
  },{
  headers: { "Content-Type": "multipart/form-data" },
  }
    )
  
  .then((response) => {
  
  //Validation Errors
  if(response.data.validatorerror){
  setstartup_name_er(response.data.validatorerror.Startup_name);
  setcontact_er(response.data.validatorerror.Contact);
  setemail_er(response.data.validatorerror.Email);
  setcompany_name_er(response.data.validatorerror.Company_name);
  setdpiit_er(response.data.validatorerror.Dpiit);
  setpan_er(response.data.validatorerror.Pan);
  setregistration_number_er(response.data.validatorerror.Registration_number);
  setgstin_er(response.data.validatorerror.Gstin);
  setdate_of_establishment_er(response.data.validatorerror.Date_of_establishment);
  setintroduction_er(response.data.validatorerror.Introduction);
  setindustry_er(response.data.validatorerror.Industry);
  setsector_er(response.data.validatorerror.Sector);
  setcategory_er(response.data.validatorerror.Category);
  setsub_category_er(response.data.validatorerror.Sub_category);
  settype_er(response.data.validatorerror.Type);
  setaddress_er(response.data.validatorerror.Address);
  setcity_er(response.data.validatorerror.City);
  setstate_er(response.data.validatorerror.State);
  setcountry_er(response.data.validatorerror.Country);
  setpincode_er(response.data.validatorerror.Pincode);
  setyoutube_er(response.data.validatorerror.Youtube);
  setwebsite_er(response.data.validatorerror.Website);
  setlinkedin_er(response.data.validatorerror.Linkedin);
  setfacebook_er(response.data.validatorerror.Facebook);
  setinstagram_er(response.data.validatorerror.Instagram);
  settwitter_er(response.data.validatorerror.Twitter);
  setlogo_link_er(response.data.validatorerror.Logo_link);
  seterror_msg("Cannot Create Startup Account !");
  
  
  }
  //Invalid Entries
  if(response.data.error==102){ 
  
  setstartup_name_er(response.data.startup_name_er);
  setcontact_er(response.data.contact_er);
  setemail_er(response.data.email_er);
  setcompany_name_er(response.data.company_name_er);
  setdpiit_er(response.data.dpiit_er);
  setpan_er(response.data.pan_er);
  setregistration_number_er(response.data.registration_number_er);
  setgstin_er(response.data.gstin_er);
  setdate_of_establishment_er(response.data.date_of_establishment_er);
  setintroduction_er(response.data.introduction_er);
  setindustry_er(response.data.industry_er);
  setsector_er(response.data.sector_er);
  setcategory_er(response.data.category_er);
  setsub_category_er(response.data.sub_category_er);
  settype_er(response.data.type_er);
  setaddress_er(response.data.address_er);
  setcity_er(response.data.city_er);
  setstate_er(response.data.state_er);
  setcountry_er(response.data.country_er);
  setpincode_er(response.data.pincode_er);
  setyoutube_er(response.data.youtube_er);
  setwebsite_er(response.data.website_er);
  setlinkedin_er(response.data.linkedin_er);
  settwitter_er(response.data.twitter_er);
  setfacebook_er(response.data.facebook_er);
  setinstagram_er(response.data.instagram_er);
  setlogo_link_er(response.data.logo_link_er);
  seterror_msg("Cannot Create Startup Account !");
  
  
  }
  
  //Mentor Already Exists
  if(response.data.error==104){     seterror_msg(response.data.error_msg); return; }
  
  
  
  //Success
  if(response.data.successvar==1){
  
    sessionStorage.setItem("Answer0",response.data.Startup_id);
    sessionStorage.setItem("StartupKey00",response.data.Startup_key);
  //window.location.replace("/businesstools-startworking");
  document.getElementById("success-card").style.display="block";

  
  }
  //failure
  if(response.data.successvar==2){
  seterror_msg("Startup Account Not Created!");
  }
  }    );
  
  }
  

  }
  //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
 sessionStorage.removeItem("logStatus00");
 sessionStorage.removeItem("logId00");
 sessionStorage.removeItem("logToken00");
 sessionStorage.removeItem("logName00");
 sessionStorage.removeItem("StartupId10");
 sessionStorage.removeItem("StartupKey10");
 window.location.replace("/businesstools-login");
 
 
 }    

}



return(
    <>
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Networking|Startup Register</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div class="container-scroller">

  {/*}  <Sidebar /> */}
    <div class="container-fluid page-body-wrapper">


       <Navbar /> 
        {/*} partial */}
        <div class="main-panel">
          <div class="content-wrapper pb-0">

            {/*  SUCCESS CARD START */}
       <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Startup Data Updated Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue to Questions Page.</p>
                      <a href="networking-startup-answers-edit"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/*  SUCCESS CARD END */}



              <div class="col-12 grid-margin">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Register your Startup</h4>
                    <div class="form-sample" style={{textAlign:"left"}}>
                      <p class="card-description">add your startup details here</p>
                      <code>{error_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Startup Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="startup_name" onChange={updatestartup_name} value={startup_name} placeholder="My Startup Name"  />
                            <code>{startup_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Company Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="company_name" onChange={updatecompany_name} value={company_name} placeholder="My Startup Company Private Limited"  />
                              <code>{company_name_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type</label>
                            <div class="col-sm-9">
                              <select class="form-control" style={{padding:"1rem"}} id="type" onChange={updatetype} value={type} >
                                <option>Select Type</option>
                                <option value="Unregistered Sole Proprietorship">Unregistered Sole Proprietorship</option>
                                <option value="Registered Sole Proprietorship">Registered Sole Proprietorship</option>
                                <option value="Unregistered Partnership">Unregistered Partnership</option>
                                <option value="Registered Partnership">Registered Partnership</option>
                                <option value="Limited Liability Partnership">Limited Liability Partnership</option>
                                <option value="One Person Company">One Person Company</option>
                                <option value="Private Limited Company">Private Limited Company</option>
                                <option value="Section 8 Company">Section 8 Company</option>
                                <option value="Public Limited Company">Public Limited Company</option>
                                <option value="Other">Other</option>
                              </select>
                              <code>{type_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Registration Date</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control"  id="date_of_establishment" onChange={updatedate_of_establishment} value={date_of_establishment}  placeholder="dd/mm/yyyy" />
                              <code>{date_of_establishment_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Email</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="email" onChange={updateemail} value={email} placeholder="johndoemyemail@gmail.com" />
                              <code>{email_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Contact</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="contact" onChange={updatecontact} value={contact} placeholder="91900000000" />
                              <code>{contact_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group" style={{textAlign:"left"}}>
                        <label for="exampleTextarea1">Summary/ Introduction</label>
                        <textarea type="text" class="form-control"  id="introduction" onChange={updateintroduction} value={introduction}  placeholder="I am a ...."></textarea>
                        <code>{introduction_er}</code>
                      </div> 
                      <hr />
                      <p class="card-description">Additional Information</p>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label"> Category</label>
                            <div class="col-sm-9">
                              <select style={{padding:"1rem"}} class="form-control" id="category" onChange={updatecategory} >
                                <option>Select Category 1</option>
                                <option value="AI and ML">AI and ML</option>
                                <option value="Fintech and BlockChain">Fintech and Blockchain</option>
                                <option value="Cyber Security and Data Privacy">Cyber Security and Data Privacy</option>
                                <option value="Internet of Things">Internet of Things</option>
                                <option value="Big Data and Analytics">Big Data and Analytics</option>
                                <option value="AR and VR">AR and VR</option>
                                <option value="Robotics and Automation">Robotics and Automation</option>
                                <option value="Space Exploration and Technology">Space Exploration and Technology</option>
                                <option value="Education and Skill Development">Education and Skill Development</option>
                                <option value="Healthcare and Medical Technology">Healthcare and Medical Technology</option>
                                <option value="Renewbale Energy and Clean Tech">Renewbale Energy and Clean Tech</option>
                                <option value="Circular Economy and Waste Management">Circular Economy and Waste Management</option>
                                <option value="Sustainable Agriculture and Food Tech">Sustainable Agriculture and Food Tech</option>
                                <option value="Urban Development and Smart Cities">Urban Development and Smart Cities</option>
                                <option value="Social Justice and Equality">Social Justice and Equality</option>
                                <option value="Disaster Relief and Preparedness">Disaster Relief and Preparedness</option>
                                <option value="Ecommerce and Retail Tech">Ecommerce and Retail Tech</option>
                                <option value="On Demand Services and Gig Economy">On Demand Services and Gig Economy</option>
                                <option value="Personalized Experiences">Personalized Experiences</option>
                                <option value="Consultancy and Services">Consultancy and Services</option>
                                <option value="Travel and Tourism">Travel and Tourism</option>
                                <option value="Animations and Gaming">Animations and Gaming</option>
                                <option value="Marketing Promotion and Public Relations">Marketing Promotion and Public Relations</option>
                                <option value="Social Groups">Social Groups</option>
                                <option value="Others">Others</option>
                              </select>
                              <code>{category_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label"> Sub Category</label>
                            <div class="col-sm-9">
                              <select style={{padding:"1rem"}} class="form-control" id="sub_category" onChange={updatesub_category} >
                                <option>Select Category 2</option>
                                <option value="AI and ML">AI and ML</option>
                                <option value="Fintech and BlockChain">Fintech and Blockchain</option>
                                <option value="Cyber Security and Data Privacy">Cyber Security and Data Privacy</option>
                                <option value="Internet of Things">Internet of Things</option>
                                <option value="Big Data and Analytics">Big Data and Analytics</option>
                                <option value="AR and VR">AR and VR</option>
                                <option value="Robotics and Automation">Robotics and Automation</option>
                                <option value="Space Exploration and Technology">Space Exploration and Technology</option>
                                <option value="Education and Skill Development">Education and Skill Development</option>
                                <option value="Healthcare and Medical Technology">Healthcare and Medical Technology</option>
                                <option value="Renewbale Energy and Clean Tech">Renewbale Energy and Clean Tech</option>
                                <option value="Circular Economy and Waste Management">Circular Economy and Waste Management</option>
                                <option value="Sustainable Agriculture and Food Tech">Sustainable Agriculture and Food Tech</option>
                                <option value="Urban Development and Smart Cities">Urban Development and Smart Cities</option>
                                <option value="Social Justice and Equality">Social Justice and Equality</option>
                                <option value="Disaster Relief and Preparedness">Disaster Relief and Preparedness</option>
                                <option value="Ecommerce and Retail Tech">Ecommerce and Retail Tech</option>
                                <option value="On Demand Services and Gig Economy">On Demand Services and Gig Economy</option>
                                <option value="Personalized Experiences">Personalized Experiences</option>
                                <option value="Consultancy and Services">Consultancy and Services</option>
                                <option value="Travel and Tourism">Travel and Tourism</option>
                                <option value="Animations and Gaming">Animations and Gaming</option>
                                <option value="Marketing Promotion and Public Relations">Marketing Promotion and Public Relations</option>
                                <option value="Social Groups">Social Groups</option>
                                <option value="Others">Others</option>
                              </select>
                              <code>{sub_category_er}</code>
                            </div>
                            </div>
                          </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label"> Industry</label>
                            <div class="col-sm-9">
                              <select class="form-control" style={{padding:"1rem"}} id="industry" onChange={updateindustry} >
                                <option>Select Industry</option>
                                <option value="Technology">Technology</option>
                                <option value="Healthcare">Healthcare</option>
                                <option value="Finance">Finance</option>
                                <option value="Education">Education</option>
                                <option value="Manufacturing">Manufacturing</option>
                                <option value="Retail">Retail</option>
                                <option value="Agriculture">Agriculture</option>
                                <option value="Energy">Energy</option>
                                <option value="Transaportaion">Transaportation</option>
                                <option value="Construction">Construction</option>
                                <option value="Business Assistance">Business Assistance</option>
                                <option value="Other">Other</option>
                              </select>
                              <code>{industry_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label"> Sector</label>
                            <div class="col-sm-9">
                              <select class="form-control" style={{padding:"1rem"}} id="sector" onChange={updatesector} >
                                <option>Select Sector</option>
                                <option value="B2B">B2B</option>
                                <option value="B2C">B2C</option>
                                <option value="B2B2C">B2B2C</option>
                              </select>
                              <code>{sector_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Incorporation Number</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control"  id="registration_number" onChange={updateregistration_number} value={registration_number}  placeholder="Reg0000000001" />
                              <code>{registration_number_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">DPIIT Number</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control"  id="dpiit" onChange={updatedpiit} value={dpiit}  placeholder="DIPP00001" />
                              <code>{dpiit_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Company PAN</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control"  id="pan" onChange={updatepan} value={pan}  placeholder="PAN000000001" />
                              <code>{pan_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Company GSTIN</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control"  id="gstin" onChange={updategstin} value={gstin}  placeholder="GSTIN10199911" />
                              <code>{gstin_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <p class="card-description">Address Information</p>
                      <div class="row">
                      <div class="form-group" style={{textAlign:"left"}}>
                        <label for="exampleTextarea1">Address</label>
                        <textarea type="text" class="form-control"  id="address" onChange={updateaddress} value={address}  placeholder="56 North Road"></textarea>
                        <code>{address_er}</code>
                      </div> 
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">City</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control"  id="city" onChange={updatecity} value={city}  placeholder="Mumbai" />
                              <code>{city_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">State</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control"  id="state" onChange={updatestate} value={state}  placeholder="State" />
                              <code>{state_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Country</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control"  id="country" onChange={updatecountry} value={country}  placeholder="India" />
                              <code>{country_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Pincode</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control"  id="pincode" onChange={updatepincode} value={pincode}  placeholder="400029" />
                              <code>{pincode_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <p class="card-description">Other Links</p>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Youtube</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control"  id="youtube" onChange={updateyoutube} value={youtube}  placeholder="https://www.youtube.com/......." />
                              <code>{youtube_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Website</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control"  id="website" onChange={updatewebsite} value={website}  placeholder="https://www.mywebsite.com/" />
                              <code>{website_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Linkedin</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control"  id="linkedin" onChange={updatelinkedin} value={linkedin}  placeholder="https://www.linkedin.com/......." />
                              <code>{linkedin_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Twitter</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control"  id="twitter" onChange={updatetwitter} value={twitter}  placeholder="https://twitter.com/........" />
                              <code>{twitter_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Facebook</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control"  id="facebook" onChange={updatefacebook} value={facebook}  placeholder="https://www.facebook.com/......." />
                              <code>{facebook_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Instagram</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control"  id="instagram" onChange={updateinstagram} value={instagram}  placeholder="https://instagram.com/........" />
                              <code>{instagram_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Attach Logo Image</label>
                            <div class="col-sm-3">
                              <input type="file" class="form-control"  id="logo_link" onChange={handleFileChange}  />
                              {selectedFile && <p>Selected file: {selectedFile.name}</p>}
                              <code>{logo_link_er}</code>
                            </div>
                          <div class="col-sm-5">
                          <p>Allowed File format (.png and .jpg), Max. file Size Allowed 1mb. </p>
                          </div>
                        </div>
                      </div>
                      <button class="btn btn-primary" onClick={editstartup} >Edit Startup</button>

                    </div>
                  </div>
                </div>
              </div>

        </div>
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
</>
);

}
export default Startupedit;