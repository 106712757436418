import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from './../axios.js';




/*import Amanbg from './amanbg';*/


function Register(){

  //saving default values with usestate
const state = useState();


  
  //name
  const[cname,setcname]=useState();const updatecname = (event) => { setcname(event.target.value);};
  //email
  const[email,setemail]=useState();const updateemail = (event) => { setemail(event.target.value);};
  //contact
  const[contact,setcontact]=useState();const updatecontact = (event) => { setcontact(event.target.value);};
  //cstate
  const[cstate,setcstate]=useState("Select State");const updatecstate = (event) => { setcstate(event.target.value);};
  //password
  const[password,setpassword]=useState("");const updatepassword = (event) => { setpassword(event.target.value);};
  //cpassword
  const[cpassword,setcpassword]=useState("");const updatecpassword = (event) => { setcpassword(event.target.value);};

    //ERROR VARIABLES 
    const[error1,seterror1]=useState();
    const[error2,seterror2]=useState();
    const[error3,seterror3]=useState();
    const[error4,seterror4]=useState();
    const[error5,seterror5]=useState();
    const[error6,seterror6]=useState();
    const[error7,seterror7]=useState();



    function Registerme(){

       seterror1("");
       seterror2("");
       seterror3("");
       seterror4("");
       seterror5("");
       seterror6("");
       seterror7("");

       let noerror = 0;

       const $name = document.getElementById("name").value; if($name==""){ seterror2("Name is required !");noerror = noerror+1; }
       const $email = document.getElementById("email").value; if($email==""){ seterror3("Email is required !");noerror = noerror+1; }
       const $contact = document.getElementById("contact").value; if($contact==""){ seterror4("Contact is required !");noerror = noerror+1; }
       const $state = document.getElementById("state").value; if($state=="Select State"){ seterror5("State is required !");noerror = noerror+1; }
       const $password = document.getElementById("password").value; if($password==""){ seterror6("Password is required !");noerror = noerror+1; }
       const $cpassword = document.getElementById("cpassword").value; if($cpassword==""){ seterror7("Confirm Password is required !");noerror = noerror+1; }
       if($password!=$cpassword){ seterror7("Password & Confirm Password do not match!");noerror = noerror+1;}

     //No primary errors
if(noerror==0){
       //Sending Data through axios
       axios.post("register", { 
        Name:$name,
        Contact:$contact,
        Email:$email,
        State:$state,
        Password:$password,
        ConfirmPassword:$cpassword
      
  })
  
      .then((response) => {
  
        seterror1(" ");seterror2(" ");seterror3(" ");seterror4(" ");seterror5(" ");seterror6(" ");seterror7(" ");
  
        //Validation Errors
      if(response.data.validatorerror){
        seterror2(response.data.validatorerror.Name);
        seterror3(response.data.validatorerror.Email);
        seterror4(response.data.validatorerror.Contact);
        seterror5(response.data.validatorerror.State);
        seterror6(response.data.validatorerror.Password);
        seterror7(response.data.validatorerror.ConfirmPassword);
      }
      //Invalid Entries
      if(response.data.successvar==2){
        seterror2(response.data.name_er);
        seterror3(response.data.email_er);
        seterror4(response.data.contact_er);
        seterror5(response.data.state_er);
        seterror6(response.data.password_er);
        seterror7(response.data.cpassword_er);
      }
      //Email exists
      if(response.data.error==1){
        seterror1("Email Already Exists!");
      }
      //Password & Confirm Password do not match
      if(response.data.error==2){
        seterror7("Password & Confirm Password do not match!!");
      }
      //success
      if(response.data.successvar==1){
      window.location.replace("/login");
      }
      //failure
      if(response.data.successvar==0){
      seterror1("Cannot create an account, Try Again!");
      }
    }    );

  }
  //Return Errors
  else{
    return;
  }




    }

      //Checkbox label css
      const checkboxlabelcss ={
        fontSize:"1.6rem",marginLeft:"1rem",marginTop:"2rem",width:"9vw",display:"inline-block"
  
      }




    return(
<>
{/*</>}DOCTYPE html>*/}
<html lang="en">
  <head>
    {/*</>}-- Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>theStartupConnect | Services Portal</title>
    {/*</>}-- plugins:css */}
    <link rel="stylesheet" href="./assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="./assets/vendors/css/vendor.bundle.base.css" />
    {/*</>}-- endinject */}
    {/*</>}-- Plugin css for this page */}
    {/*</>}-- End plugin css for this page */}
    {/*</>}-- inject:css */}
    {/*</>}-- endinject */}
    {/*</>}-- Layout styles */}
    <link rel="stylesheet" href="./assets/css/style.css" />
    {/*</>}-- End layout styles */}
   {/*} <link rel="shortcut icon" href="./assets/images/favicon.ico" />*/}
  </head>
  <body>
  <div className="container-scroller"> 
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth">
          <div className="row flex-grow">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left p-5">
                <div className="brand-logo">
                  <img src="./assets/images/logo-a.jpeg" />
                </div>
                <h4>New here?</h4>
                <h6 className="font-weight-light">Signing up is easy. It only takes a few steps</h6>
                <small style={{fontSize:".8rem",color:"#f16083"}}>{error1}</small>
                <div className="pt-3">
                  <div className="form-group">
                    <input type="text" className="form-control form-control-lg" id="name"  value={cname} onChange={updatecname} placeholder="Full Name" />
                    <small style={{fontSize:".8rem",color:"#f16083"}}>{error2}</small>
                  </div>
                  <div className="form-group">
                    <input type="email" className="form-control form-control-lg" id="email" value={email} onChange={updateemail} placeholder="Email" />
                    <small style={{fontSize:".8rem",color:"#f16083"}}>{error3}</small>
                  </div>
                  <div className="form-group">
                    <input type="text" className="form-control form-control-lg" id="contact" value={contact} onChange={updatecontact} placeholder="Phone" />
                    <small style={{fontSize:".8rem",color:"#f16083"}}>{error4}</small>
                  </div>

                  <div className="form-group">
                    <select className="form-control form-control-lg" id="state" onChange={updatecstate}>
                    <option value={cstate}>{cstate}</option>
                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                    <option value="Assam">Assam</option>
                    <option value="Bihar">Bihar</option>
                    <option value="Chhattisgarh">Chhattisgarh</option>
                    <option value="Goa">Goa</option>
                    <option value="Gujarat">Gujarat</option>
                    <option value="Haryana">Haryana</option>
                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                    <option value="Jharkhand">Jharkhand</option>
                    <option value="Karnataka">Karnataka</option>
                    <option value="Kerala">Kerala</option>
                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                    <option value="Maharashtra">Maharashtra</option>
                    <option value="Manipur">Manipur</option>
                    <option value="Meghalaya">Meghalaya</option>
                    <option value="Mizoram">Mizoram</option>
                    <option value="Nagaland">Nagaland</option>
                    <option value="Odisha">Odisha</option>
                    <option value="Punjab">Punjab</option>
                    <option value="Rajasthan">Rajasthan</option>
                    <option value="Sikkim">Sikkim</option>
                    <option value="Tamil Nadu">Tamil Nadu</option>
                    <option value="Telangana">Telangana</option>
                    <option value="Tripura">Tripura</option>
                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                    <option value="Uttarakhand">Uttarakhand</option>
                    <option value="West Bengal">West Bengal</option>
                    <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                    <option value="Chandigarh">Chandigarh</option>
                    <option value="Dadra and Nagar Haveli and Daman and Diu">Dadra and Nagar Haveli and Daman and Diu</option>
                    <option value="Delhi">Delhi</option>
                    <option value="Lakshadweep">Lakshadweep</option>
                    <option value="Puducherry">Puducherry</option>
                    </select>
                    <small style={{fontSize:".8rem",color:"#f16083"}}>{error5}</small>
                  </div>
                  <div className="form-group">
                    <input type="password" className="form-control form-control-lg" id="password" value={password} onChange={updatepassword} placeholder="Password" />
                    <small style={{fontSize:".8rem",color:"#f16083"}}>{error6}</small>
                  </div>
                  <div className="form-group">
                    <input type="password" className="form-control form-control-lg" id="cpassword" value={cpassword} onChange={updatecpassword} placeholder="Confirm Password" />
                    <small style={{fontSize:".8rem",color:"#f16083"}}>{error7}</small>
                  </div>
                  <div className="mb-4">
                 {/*}   <div className="form-check">
                      <label className="form-check-label text-muted" >
                        <input type="checkbox" className="form-check-input" id="agreeterms" value="agree" style={checkboxlabelcss} /> I agree to all Terms & Conditions </label>
                    </div> */}
                  </div>
                  <div className="mt-3">
                    <a className="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" onClick={Registerme}>SIGN UP</a>
                  </div>
                  <div className="text-center mt-4 font-weight-light"> Already have an account? <a href="login.html" className="text-primary">Login</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> 
        {/*</>}-- content-wrapper ends */}
      </div>
      {/*</>}-- page-body-wrapper ends */}
    </div>
    {/*</>}-- container-scroller */}
    {/*</>}-- plugins:js */}
    <script src="./assets/vendors/js/vendor.bundle.base.js"></script>
    {/*</>}-- endinject */}
    {/*</>}-- Plugin js for this page */}
    {/*</>}-- End plugin js for this page */}
    {/*</>}-- inject:js */}
    <script src="./assets/js/off-canvas.js"></script>
    <script src="./assets/js/hoverable-collapse.js"></script>
    <script src="./assets/js/misc.js"></script>
    {/*</>}-- endinject */}
  </body>
</html>
</>
    );
}

export default Register;