import React from "react";
import Sidebar from "./sidebar";

function Client_projects(){
  return (
    <>

    
<html lang="en">
  <head>
    {/* Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Startup Connect | Services Portal</title>
    {/* plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/* endinject */}
    {/* Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/* End plugin css for this page */}
    {/* inject:css */}
    {/* endinject */}
    {/* Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/* End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
    </head>
    <body>
    {/* container-scroller */}
    <div class="container-scroller">

     

     <Sidebar /> 
      <div class="container-fluid page-body-wrapper">

 {/*} partial */}
 <div class="main-panel">



   <div class="content-wrapper pb-0">

      <div className="my-projects main-panel float-end">
        <div className="content-wrapper d-flex flex-column">
        <div className="d-flex User align-center justify-content-center"><h1>Client Dashboard</h1></div>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">My Projects</h4>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Project ID</th>
                          <th>Start Date</th>
                          <th>Title</th>
                          <th>Description</th>
                          <th>Status</th>
                          <th>Progress</th>
                          <th>Deadline</th>
                          <th>View</th>
                        </tr>
                      </thead>
                      <tbody>
                      {/*}  <tr>
                          <td>42</td>
                          <td>2023-08-30</td>
                          <td>E Commerce Website</td>
                          <td></td>
                          <td>
                            <label className="badge badge-danger">
                              Pending
                            </label>
                          </td>
                          <td>
                            <div className="progress">
                              <div
                                className="progress-bar bg-primary progress-bar"
                                role="progressbar"
                                style={{ width: "90%" }}
                                aria-valuenow="90"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </td>
                          <td>2023-09-04</td>
                          <td>
                            <label className="badge badge-info">View</label>
                          </td>
                        </tr>
                        <tr>
                          <td>41</td>
                          <td>1970-01-01</td>
                          <td>Updated Website Developmet Project</td>
                          <td>Yes it works</td>
                          <td>
                            <label className="badge badge-success">
                              Completed
                            </label>
                          </td>
                          <td>
                            <div className="progress">
                              <div
                                className="progress-bar bg-success progress-bar"
                                role="progressbar"
                                style={{ width: "100%" }}
                                aria-valuenow="100"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </td>
                          <td>2023-09-24</td>
                          <td>
                            <label className="badge badge-info">View</label>
                          </td>
                        </tr>
                        <tr>
                          <td>40</td>
                          <td>2023-11-30</td>
                          <td>Webs ite Dev for Me</td>
                          <td></td>
                          <td>
                            <label className="badge badge-warning">
                              In Progress
                            </label>
                          </td>
                          <td>
                            <div className="progress">
                              <div
                                className="progress-bar bg-warning progress-bar"
                                role="progressbar"
                                style={{ width: "70%" }}
                                aria-valuenow="70"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </td>
                          <td>2023-09-04</td>
                          <td>
                            <label className="badge badge-info">View</label>
                          </td>
                        </tr>
                        <tr>
                          <td>39</td>
                          <td>2023-09-30</td>
                          <td>Presentation</td>
                          <td></td>
                          <td>
                            <label className="badge badge-danger">
                              Pending
                            </label>
                          </td>
                          <td>
                            <div
                              className="progress"
                              style={{ width: "150px" }}
                            >
                              <div
                                className="progress-bar bg-danger progress-bar"
                                role="progressbar"
                                style={{ width: "50%" }}
                                aria-valuenow="50"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </td>
                          <td>2022-09-04</td>
                          <td>
                            <label className="badge badge-info">View</label>
                          </td>
                        </tr>*/}
                      </tbody>
                    </table>
                    <p class="mt-5">No projects to show</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      </div>
      </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}

     

{/* plugins:js */}
<script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
{/* endinject */}
{/* Plugin js for this page */}
<script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
<script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
<script src="panel/assets/vendors/flot/jquery.flot.js"></script>
<script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
<script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
<script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
<script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
<script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
{/* End plugin js for this page */}
{/* inject:js */}
<script src="panel/assets/js/off-canvas.js"></script>
<script src="panel/assets/js/hoverable-collapse.js"></script>
<script src="panel/assets/js/misc.js"></script>
<script src="panel/assets/js/settings.js"></script>
<script src="panel/assets/js/todolist.js"></script>
{/* endinject */}
{/* Custom js for this page */}
<script src="panel/assets/js/dashboard.js"></script>
{/* End custom js for this page */}
</body>
</html>    

    </>
  );
};

export default Client_projects;
