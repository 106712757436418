import React from "react";
import { useState, useEffect, Component } from "react";
import { useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
//import axios from '../../axios.js';
import moment from "moment/moment";

import "../Resources/homebtcss.css";
import "../Resources/home2css.css";
import "../Resources/serviceshomecss.css";
import "./ankitcss.css";

import Sidebar from "./sidebar";

import Chart from "chart.js/auto";
import { Line, Bar } from "react-chartjs-2";
import Footer3 from "../Supportingfiles/footer3";
import { Navbar } from "flowbite-react";
import Navbar3 from "../Supportingfiles/navbar3";

function Pagedummy1() {
  return (
    <>
 <html lang="en">
        <head>
          {/*} Required meta tags */}
          <meta charset="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <title>STARTUP CONNECT | PAGES</title>
          {/*} plugins:css */}
          <link
            rel="stylesheet"
            href="panel/assets/vendors/mdi/css/materialdesignicons.min.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/css/vendor.bundle.base.css"
          />
          {/*} endinject */}
          {/*} Plugin css for this page */}
          <link
            rel="stylesheet"
            href="panel/assets/vendors/jquery-bar-rating/css-stars.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/font-awesome/css/font-awesome.min.css"
          />
          {/*} End plugin css for this page */}
          {/*} inject:css */}
          {/*} endinject */}
          {/*} Layout styles */}
          <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
          {/*} End layout styles */}
          <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
        </head>
        <body>
          <div class="home2-container">
            <Navbar3 />

            {/* HEADER SECTION START */}
            <section /*class="sv-header-section" */>
              <div class="sv-header-container1">
                <div class="sv-header-left" style={{ padding: "2rem" }}>
                  <h1 style={{ color: "#004463" }}>
                    Private Limited Company Registration
                  </h1>
                  <p>
                    <h3 style={{ color: "red" }}>
                      Prices Starting from INR ………/- only
                    </h3>

                    <div style={{ marginBottom: "5px" }}>
                      <span style={{ paddingRight: "10px" }}>✅</span>
                      <span>Complete Online Process</span>
                    </div>
                    <div style={{ marginBottom: "5px" }}>
                      <span style={{ paddingRight: "10px" }}>💸</span>
                      <span>Lowest Price Gurantee</span>
                    </div>
                    <div style={{ marginBottom: "5px" }}>
                      <span style={{ paddingRight: "10px" }}>✅</span>
                      <span>Get Regular Whatsapp/Email Updates</span>
                    </div>
                    <div style={{ marginBottom: "5px" }}>
                      <span style={{ paddingRight: "10px" }}>✅</span>
                      <span>Free Expert Assistance</span>
                    </div>
                  </p>

                  <div class="home2-services-features-list">
                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img loading = "lazy"

                          src="https://cdn-icons-png.flaticon.com/128/1087/1087840.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>500+</h3>
                        <h5 style={{ fontWeight: "bold" }}>Projects</h5>
                      </div>
                    </div>

                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img loading = "lazy"

                          src="https://cdn-icons-png.flaticon.com/128/12735/12735475.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>25+</h3>
                        <h5 style={{ fontWeight: "bold" }}>Cities</h5>
                      </div>
                    </div>

                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img loading = "lazy"

                          src="https://cdn-icons-png.flaticon.com/128/2991/2991235.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>
                          4{" "}
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/7656/7656139.png"
                            style={{ height: "2rem", width: "2rem" }}
                          />
                          +
                        </h3>
                        <h5 style={{ fontWeight: "bold" }}>Google Ratings</h5>
                      </div>
                    </div>
                  </div>

                  <div class="row d-flex justify-content-left">
                    <div class="col-sm-6 stretch-card grid-margin">
                      <a href="https://calendly.com/thestartupconnect/30min">
                        <button
                          id="home2-modules-section-btn"
                          style={{ marginLeft: "0" }}
                        >
                          Book Free Consultancy{" "}
                          <i class="mdi mdi-arrow-right"></i>
                        </button>
                      </a>
                    </div>
                    <div class="col-sm-6 stretch-card grid-margin">
                      <a
                        href="/home1/startupbrochure1.pdf"
                        download="Startup Connect Services Brochure"
                      >
                        <button
                          id="home2-modules-section-btn"
                          style={{
                            backgroundImage:
                              "linear-gradient(to right, #208d17, #00ad11)",
                          }}
                        >
                          Download Brochure <i class="mdi mdi-download"></i>
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  class="sv-header-right" /* style={{backgroundColor:"#96dfff",padding:"2rem",borderRadius:"15px",boxShadow:"2px 3px 3px 2px #cbcecf"}} */
                >
                  <img loading = "lazy"

                    src="https://img.freepik.com/free-vector/recruiting-agency-isometric-illustration-coworkers-candidate-employer-3d-cartoon-characters-headhunting-human-resources-department_575670-1091.jpg"
                    style={{ width: "90%", height: "100%" }}
                  />
                </div>
              </div>
            </section>
            {/* HEADER SECTION END */}
            <hr />

            {/* SIDEBAR AND MAIN PAGE START */}
            <div className="container-scroller">
              {/*<Sidebar /> */}
              <div className="container-fluid page-body-wrapper">
                {/*} partial */}
                <div className="main-panel">
                  <hr />

                  {/* MORE INFORMATION SECTION START */}
                  <div class="row" style={{ padding: "2rem" }}>
                    <div
                      class="col-sm-9 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div>
                        <h2 style={{ fontWeight: "bold" }}>
                          Why choose a{" "}
                          <span style={{ color: "#9f23c2" }}>
                            Private Limited Company
                          </span>
                        </h2>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                          {" "}
                          A Private Limited Company offers limited liability,
                          better credibility and legal protection to its
                          shareholders due to its separate legal existance. A
                          Private Limited Company in India lies somewhere
                          between a partnership firm and a widely owned public
                          company.
                        </p>
                        <p style={{ fontSize: "1rem" }}>
                          Private Limited Companies are registered with the
                          Ministry of Corporate Affairs (MCA) under the
                          Companies Act, 2013.
                        </p>
                        <p style={{ fontSize: "1rem" }}>
                          Registration of Private Limited Companies require a
                          minimum of 2 Directors and 2 Shareholders with one of
                          the Directors being an Indian Resident and Indian
                          Citizen. A person can be both a director and
                          shareholder in a Private Limited Company.The liability
                          of the members of a Private Limited Company (PLC) is
                          limited to the number of shares held by them. A
                          Private Limited Company in India can begin with
                          operations after getting the Certificate of
                          Incorporation. A Private limited company can be
                          incorporated within 15 working days.{" "}
                        </p>
                      </div>
                    </div>
                    <div class="col-sm-3 stretch-card grid-margin">
                      <img loading = "lazy"

                        src="https://img.freepik.com/free-vector/business-deal-concept-illustration_114360-1535.jpg"
                        style={{ width: "90%", height: "100%" }}
                      />
                    </div>
                  </div>
                  {/* MORE INFORMATION SECTION END */}

                  <hr />
                  <h2>
                    Benefits of registering company as a private limited company
                  </h2>
                  {/* BENEFITS SECTION START */}
                  <div class="row" style={{ padding: "2rem" }}>
                    <div
                      class="col-sm-2 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/2590/2590440.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">No Minimum Capital</h4>
                        <p>
                          No minimum capital is required.A Private Limited
                          Company can be registered with a mere sum of Rs.
                          10,000 as total Paid Up Share capital.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/2758/2758527.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Limited Liability</h4>
                        <p>
                          Owners' liability is limited to the amount invested in
                          the company, protecting their personal assets from
                          business liabilities.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/11443/11443795.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Separate Legal Entity</h4>
                        <p>
                          A Private Limited Company offers legal recognition to
                          the business, providing a separate legal entity from
                          its owners.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/3529/3529127.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Easy Fund Raising</h4>
                        <p>
                          A Private Limited Company in India is the only form of
                          business except Public Limited Companies that can
                          raise funds from the Venture Capitalists or Angel
                          investors by issuing equity shares{" "}
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/5347/5347858.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Credibility</h4>
                        <p>
                          Being registered as a Private Limited Company lends
                          credibility to the business and builds trust among
                          stakeholders.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/13163/13163215.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">FDI Allowed</h4>
                        <p>
                          100% Foreign Direct Investment is allowed that means
                          any foreign entity or foreign person can directly
                          invest in a Private Limited Company.Hence, most
                          foreign subsidiaries are established in India as
                          private limited company.
                        </p>
                      </div>
                    </div>

                 {/*}   <div
                      class="col-sm-2 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/4435/4435123.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Eligible for Startup India </h4>
                        <p>
                          Private Limited company is eligible for Startup india
                          Registration and can avail the benefits of Startup
                          India{" "}
                        </p>
                      </div>
                    </div> */}
                  </div>
                  {/* BENEFITS SECTION END */}

                  <hr />
                  {/* REQUIREMENTS SECTION START */}
                  <h2>Requirements for Registering Private Limited Company</h2>

                  <div class="row" style={{ padding: "2rem" }}>
                    <div class="col-sm-4 stretch-card grid-margin">
                      <img loading = "lazy"

                        src="https://img.freepik.com/free-vector/business-deal-concept-illustration_114360-1103.jpg"
                        style={{ width: "90%", height: "100%" }}
                      />
                    </div>

                    <div
                      class="col-sm-8 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div>
                        <h4 style={{ fontWeight: "bold" }}>
                          1. Minimum 2 directors:
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                          {" "}
                          A private limited company must have at least two
                          directors, with a maximum of fifteen. A minimum of one
                          of the company's directors must be a resident of
                          India.
                        </p>
                        <br />
                        <h4 style={{ fontWeight: "bold" }}>
                          2. Unique business Name
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                          The name of your pvt ltd company must be unique. The
                          suggested name should not match with any existing
                          companies or trademarks in India.
                        </p>
                        <br />
                        <h4 style={{ fontWeight: "bold" }}>
                          3. At least one director should be the resident of
                          India
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                          Section 149(3) of the Companies Act, 2013 provides
                          that every Company shall have at least one director
                          who has stayed in India for a total period of at least
                          182 days during the financial year.
                        </p>
                        <br />
                        <h4 style={{ fontWeight: "bold" }}>
                          4. Registered Office Address
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                          The registered office of a pvt ltd company does not
                          have to be a commercial space. Even a rented home can
                          be the registered office, so long as an NOC is
                          obtained from the landlord.
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* REQUIREMENTS SECTION END */}
                  <hr />
                  <h2>Private Limited Company Registration Process</h2>

                  {/* PROCESS SECTION START */}
                  <div class="row">
                    <div class="col-sm-2 stretch-card grid-margin">
                      <div class="card">
                        <div class="card-body">
                          <img loading = "lazy"

                            class="img-fluid w-100"
                            src="https://img.freepik.com/free-vector/paying-bills-concept-illustration_114360-19357.jpg?w=740&t=st=1706779500~exp=1706780100~hmac=ad8e6bf9dc5da282c80e40c82c023c68f94bea72270aa35612471e5892f8985b"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h3 class="font-weight-bold"> Step 1 </h3>
                          <div class="d-flex justify-content-between">
                            <p class="mb-0" style={{ fontSize: "1rem" }}>
                              Payment and Document Submission
                              <br />
                              Expert review your Documents
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-2 stretch-card grid-margin">
                      <div class="card">
                        <div class="card-body">
                          <img loading = "lazy"

                            class="img-fluid w-100"
                            src="https://img.freepik.com/free-vector/login-concept-illustration_114360-748.jpg?t=st=1706779615~exp=1706780215~hmac=9651a498d516590febe6779c62640331889c05ff48733ca96f9ca77ddcdec593"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h3 class="font-weight-bold"> Step 2 </h3>
                          <div class="d-flex justify-content-between">
                            <p class="mb-0" style={{ fontSize: "1rem" }}>
                              Name availability check
                              <br />
                              Trademark Check and Name reservation Application{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-2 stretch-card grid-margin">
                      <div class="card">
                        <div class="card-body">
                          <img loading = "lazy"

                            class="img-fluid w-100"
                            src="https://img.freepik.com/premium-vector/businessman-shaking-hands-deal-symbol-with-fellow-lawyer-lawyers-discuss-with-clients_923732-776.jpg?w=740"
                            alt=""
                            
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h3 class="font-weight-bold"> Step 3 </h3>
                          <div class="d-flex justify-content-between">
                            <p class="mb-0" style={{ fontSize: "1rem" }}>
                              Application for Digital Signature and
                              Incorporation application Submission{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-2 stretch-card grid-margin">
                      <div class="card">
                        <div class="card-body">
                          <img loading = "lazy"

                            class="img-fluid w-100"
                            src="https://img.freepik.com/free-vector/product-quality-concept-illustration_114360-7301.jpg?w=740&t=st=1706780098~exp=1706780698~hmac=4ed66236fdef104f05dab6b8d7176409171e304e19a3758c389bc28837bbe13b"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h3 class="font-weight-bold"> Step 4 </h3>
                          <div class="d-flex justify-content-between">
                            <p class="mb-0" style={{ fontSize: "1rem" }}>
                              Get Certificate for Incorporation
                              <br />
                              PAN, TAN, EPF, ESIC Certificate
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-2 stretch-card grid-margin">
                      <div class="card">
                        <div class="card-body">
                          <img loading = "lazy"

                            class="img-fluid w-100"
                            src="https://img.freepik.com/premium-vector/boy-looking-tax-form-laptop_118167-9401.jpg?w=740"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h3 class="font-weight-bold"> Step 5 </h3>
                          <div class="d-flex justify-content-between">
                            <p class="mb-0" style={{ fontSize: "1rem" }}>
                              Apply for GST
                              <br />
                              MSME and Trademark
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* PROCESS SECTION END */}

                  {/* Why StartUp Connect Starts */}
                 <hr/>
                  <h2 class="fontweight-bold"> Why Choose Startup Connect ?</h2>
                  <div class="row" style={{ padding: "2rem",  }}>
                    <div
                      class="col-sm-2 stretch-card grid-margin"
                      style={{ textAlign: "left"}}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn1.iconfinder.com/data/icons/programming-blue-1/64/Process-512.png"
                            style={{ height: "6rem", width: "6rem", borderRadius: "9999px" }}
                          />
                        </div>

                        <h4 class="mt-3">Complete Digital Process</h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn4.iconfinder.com/data/icons/business-blue-series-set-4/64/a-26-512.png"
                            style={{ height: "6rem", width: "6rem", borderRadius: "9999px" }}
                          />
                        </div>
                        <h4 class="mt-3">
                          Free Expert Assistance
                        </h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn0.iconfinder.com/data/icons/offer-and-discount-labels-gradient/64/BEST_PRICE-sale-offer-shopping-discount-512.png"
                            style={{ height: "6rem", width: "6rem", borderRadius:"9999px"}}
                          />
                        </div>
                        <h4 class="mt-3">Best Price Guarantee</h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn0.iconfinder.com/data/icons/leto-survey-rating/64/__rate_rating_stars_evaluation-256.png"
                            style={{ height: "6rem", width: "6rem", borderRadius:"9999px" }}
                          />
                        </div>
                        <h4 class="mt-3" style={{ fontSize: "15px" }}>
                          4.9/5 Google Rating (350 Reviews)
                  
                        </h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn0.iconfinder.com/data/icons/ecommerce-594/24/refund_money_back_guarantee_cashback_money_back_return-64.png"
                            style={{ height: "6rem", width: "6rem", borderRadius:"9999px" }}
                          />
                        </div>
                        <h4 class="mt-3" style={{ fontSize: "15px" }}>
                          Money back Gurantee
                        </h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn1.iconfinder.com/data/icons/fitness-and-sport-4/256/Cycling-256.png"
                            style={{ height: "6rem", width: "6rem", borderRadius:"9999px" }}
                          />
                        </div>
                        <h4 class="mt-3">
                          Simple and fast Process{" "}
                        </h4>
                      </div>
                    </div>
                  </div>
                  {/* Why StartUp Connect Ends */}

                  <hr />

                  {/* Documents SECTION Starts */}
                  <h2> Documents required for Private Company Registration</h2>
                  <div class="row" style={{ padding: "2rem" }}>
                    <div
                      class="col-sm-2 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/3135/3135715.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>

                        <h4 class="mt-3">Photograph of all the Directors</h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/1726/1726620.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">
                          PAN Card and aadhar card of all the Directors
                        </h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/9068/9068642.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Email id/ Mobile no of Directors</h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/9875/9875524.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3" style={{ fontSize: "15px" }}>
                          Bank statement/any other Utility bill for address
                          Proof of Directors
                        </h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/6108/6108273.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3" style={{ fontSize: "15px" }}>
                          Electricity Bill or any other utility bill for the
                          address proof of the Registered Office
                        </h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/1584/1584961.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">
                          Proof of Registered Office : Consent letter/Rent
                          agreement{" "}
                        </h4>
                      </div>
                    </div>
                  </div>
                  {/* Documents SECTION Ends */}
                  {/* COMPLIANCES SECTION START */}
                  <h2>Compliances Required by a Private Limited Company</h2>
                  <p style={{ fontSize: "1rem" }}>
                    Once a company is registered in India, various compliances
                    must be maintained from time to time to avoid penalties and
                    prosecution. The following are some of the compliances a
                    company would be required to complete after company
                    registration
                  </p>
                  <div
                    class="row"
                    style={{ textAlign: "left", padding: "2rem" }}
                  >
                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> Appointment of Auditor</h3>
                    <p style={{ fontSize: "1rem" }}>
                      All companies registered in India must appoint a
                      practicing and licensed Chartered Accountant registered
                      with the ICAI and needs to file form ADT-1 within 30 days
                      of incorporation. If the company fails to appoint an
                      auditor, the company won't be allowed to commence
                      business. Also, there is a penalty of Rs. 300 per month.{" "}
                    </p>
                    <br />
                    <br />

                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> Commencement of Business (Form INC 20 A)</h3>
                    <p style={{ fontSize: "1rem" }}>
                      The company must open a Bank Current Account and the
                      shareholders must deposit the subscription amount
                      mentioned in the MOA of the company. Hence, if the company
                      was to be incorporated with a paid-up capital of Rs. 1
                      lakh, then the shareholders must deposit Rs. 1 lakh in the
                      Company's bank account and file the bank statement with
                      the MCA to obtain a commencement of business certificate.
                      +The commencement of business certificate in form INC 20A
                      must be obtained within 180 days of incorporating a
                      Company. «In case of failure to obtain this certificate,
                      there is a penalty of Rs. 50,000 for the company Rs. 1000
                      per day for the directors for each day of default
                    </p>
                    <br />
                    <br />

                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> Director DIN KYC (Form DIR-3 KYC)</h3>
                    <p style={{ fontSize: "1rem" }}>
                      All persons who hold a Director Identification Number
                      (DIN) whose DIN is allotted on or before 31 March must
                      complete DIN KYC within 30th September every year to
                      validate the phone and email address on record with the
                      Ministry of Corporate Affairs. «There's a penalty of Rs.
                      5000 in case of failure to file DIN eKYC.{" "}
                    </p>
                    <br />
                    <br />

                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> MCA Annual Filings</h3>
                    <p style={{ fontSize: "1rem" }}>
                      If companies registered in India must file a copy of the
                      financial statements with the Ministry of Corporate
                      Affairs each financial year. If a company is incorporated
                      between January — March, the company can choose to file
                      the first MCA annual return as a part of the next
                      financial year’s annual filing. MCA annual return consists
                      of Form AOC-4 which consists of balance sheet and profit
                      and loss account and notes to accounts thereon Form MGT-7
                      which consist of annual return of the company and Both
                      these forms must be digitally signed by the Directors and
                      a practicing professional{" "}
                    </p>
                    <br />
                    <br />

                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> Statutory and Tax Audit</h3>
                    <p style={{ fontSize: "1rem" }}>
                      Every Private Limited Company registered under the
                      Companies Act irrespective of its sales turnover or nature
                      of business or capital must have its book of accounts
                      audited each financial year Every Private Limited Company
                      requires to get the tax audits under section 44AB done.
                      The Tax audit under section 44AB aims to ascertain the
                      compliance of various provisions of the Income-tax Law and
                      the fulfillment of other requirements of the Income-Tax
                      Law. The tax audit limit under section 44AB is INR 1
                      Crores. (5 crores where at least 95 percent turnover is
                      made on digital transactions.).
                    </p>
                    <br />
                    <br />

                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> GST Registration</h3>
                    <p style={{ fontSize: "1rem" }}>
                      very Private Limited Company is required to get their GST
                      Registration within 30 days of Busines Incorporation in
                      India. GST Registration is needed when the tumover is more
                      than INR 40 lakhs or INR 20 lakhs in Special category
                      states or If the business supplies goods intra-state or
                      Provides goods and services online.
                    </p>
                    <br />
                    <br />

                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> GST Return</h3>
                    <p style={{ fontSize: "1rem" }}>
                      Once a Private Limited company gets registered under. GST,
                      it becomes necessary to file GST returns for Private
                      Limited Company. As filing of GST returns is mandatory for
                      all the registered Taxpayers including companies. GST
                      returns can be filed monthly, quarterly or yearly
                      depending upon the types of gst returns form you are
                      filing
                    </p>
                    <br />
                    <br />

                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> IEC Registration</h3>
                    <p style={{ fontSize: "1rem" }}>
                      All the Private Limited Company which are engaged in
                      Import and Export of goods require to register the Import
                      Export Code. IE code has lifetime validity. Importers are
                      ot allowed to proceed without this code and exporters
                      can't take benefits of exports from DGFT, customs, Export
                      Promotion Council, if they don't have this code.
                    </p>
                    <br />
                    <br />
                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> Trademark Registration</h3>
                    <p style={{ fontSize: "1rem" }}>
                      Registering a Private Limited Company with a name does not
                      provide complete protection to the name or brand name.
                      Ultimate protection is secured only by Trademark
                      Registration of same. By trademark registration, you may
                      get the ownership of your Pvt Ltd Co. name or brand name
                      or logo etc
                    </p>
                  </div>
                  {/* COMPLIANCES SECTION END */}
                  <hr/>
                  {/* COMPARISON TABLE START */}
                  <h2 style = {{marginBottom: "40px"}}> Comparison of Various Business Entities</h2>
                  <div className="container" style = {{overflow:"scroll"}}>
                    <div classame = "table-responsive">
                    <div className="row">
                      <div className="col" >
                        <table className="table table-striped table-bordered table-hover">
                          <thead className="thead-dark">
                            <tr>
                              <th scope="col">Features</th>
                              <th scope="col">Proprietorship</th>
                              <th scope="col">Partnership</th>
                              <th scope="col">LLP</th>
                              <th scope="col">company</th>
                              <th scope="col">One person Company</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">Definition</th>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>
                                Unregistered type of business entity managed by
                                one single person
                              </td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>
                                A formal agreement between two or more parties
                                to manage and operate a business
                              </td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>
                                A Limited Liability Partnership is a hybrid
                                combination having features similar to a
                                partnership firm and liabilities similar to a
                                company.
                              </td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>
                                Registered type of entity with limited liability
                                to the owners and shareholders
                              </td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>
                                Combination of Sole-Proprietorship and Corporate
                                form of business
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Applicable Law</th>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>No specified Act</td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>
                                Indian Partnership Act, 1932
                              </td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>
                                Limited Liability Partnership Act, 2008
                              </td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>Companies Act, 2013</td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>Companies Act, 2013</td>
                            </tr>
                            <tr>
                              <th scope="row" style = {{whiteSpace : "wrap"}}>Ownership/No of owners</th>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>Sole Ownership</td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>
                                Min 2 Partners Max 50 Partners
                              </td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>
                                Min 2 Partners Max Unlimited
                              </td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>
                                Min 2 Shareholders- Max 200 Shareholder
                              </td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>Only 1</td>
                            </tr>
                            <tr>
                              <th scope="row">Management</th>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>
                                Managed by proprietor
                              </td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>Managed by Partners</td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>Managed by Partners</td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>
                                Min 2 Directors Max 15 Directors
                              </td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>
                                1 Director 1 Nominee Director
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Promoter Liability</th>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>Unlimited Liability</td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>Unlimited Liability</td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>Limited Liability</td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>Limited Liability</td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>Limited Liability</td>
                            </tr>
                            <tr>
                              <th scope="row" style = {{whiteSpace : "wrap"}}>Separate Legal Entity</th>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>No</td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>No</td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>Yes</td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>Yes</td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>Yes</td>
                            </tr>
                            <tr>
                              <th scope="row">Documentation</th>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>
                                MSME, GST Registration
                              </td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>Partnership Deed</td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>
                                LLP Deed, Incorporation Certificate
                              </td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>
                                MOA, AOA, Incorporation Certificate
                              </td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>
                                MOA, AOA, Incorporation Certificate
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Transferability</th>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>Non Transferable</td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>
                                Transferable if registered under ROF
                              </td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>Transferable</td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>Transferable</td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>Transferable</td>
                            </tr>
                            <tr>
                              <th scope="row">Statutory Audit</th>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>Not Mandatory</td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>Not Mandatory</td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>
                                Applicable if Limit exceeds
                              </td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>Mandatory</td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>Mandatory</td>
                            </tr>
                            <tr>
                              <th scope="row">Taxation Liability</th>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>Low</td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>High</td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>High</td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>Moderate</td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>Moderate</td>
                            </tr>
                            <tr>
                              <th scope="row">Foreign Ownership</th>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>Not allowed</td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>Allowed</td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>Allowed</td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>Allowed</td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>Not Allowed</td>
                            </tr>
                            <tr>
                              <th scope="row" style = {{whiteSpace : "wrap"}}>Compliance Requirement</th>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>Low</td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>Low</td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>Moderate</td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>High</td>
                              <td style = {{whiteSpace : "wrap", padding: "20px", fontSize:"1rem"}}>High</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    </div>
                  </div>

                  {/* COMPARISON TABLE END */}

                  <hr />
                  <h2>Frequently Asked Questions (FAQs)</h2>
                  {/* FAQ SECTION START */}
                  <div
                    id="accordion"
                    style={{ textAlign: "left", marginTop: "3rem" }}
                  >
                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#003e73" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                          What is a Private Limited Company in india ?
                        </h5>
                        <div>
                          <button
                            id="ques-1-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-1").style.display =
                                "block";
                              document.getElementById(
                                "ques-1-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-1-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-1-dicon"
                            style={{ display: "none" }}
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-1").style.display =
                                "none";
                              document.getElementById(
                                "ques-1-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-1-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-1">
                        <div class="card-body">
                          In India, a private limited company is a type of
                          company that is privately held and has limited
                          liability. It is one of the country’s most popular
                          types of business structures due to its various
                          advantages, including limited liability protection,
                          ease of formation and maintenance, and separate legal
                          entity status.{" "}
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                          How many members are required to form a Private
                          Limited Company?
                        </h5>
                        <div>
                          <button
                            id="ques-2-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-2").style.display =
                                "block";
                              document.getElementById(
                                "ques-2-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-2-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-2-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-2").style.display =
                                "none";
                              document.getElementById(
                                "ques-2-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-2-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-2" style={{ display: "none" }}>
                        <div class="card-body">
                          The minimum number of members required to form a
                          Private Limited Company typically depends on the
                          jurisdiction where the company is being registered.
                          However, in many countries, including the UK and
                          India, the minimum requirement is usually two members.
                          These members can be individuals or corporate
                          entities. It's essential to check the specific
                          regulations and requirements of the jurisdiction where
                          you intend to register the Private Limited Company, as
                          they can vary.{" "}
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                          What are the advantages of a Private Limited Company?
                        </h5>
                        <div>
                          <button
                            id="ques-3-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-3").style.display =
                                "block";
                              document.getElementById(
                                "ques-3-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-3-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-3-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-3").style.display =
                                "none";
                              document.getElementById(
                                "ques-3-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-3-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-3" style={{ display: "none" }}>
                        <div class="card-body">
                          The advantages of a Private Limited Company include
                          limited liability, separate legal entity, perpetual
                          existence, ease of ownership transfer, access to
                          capital, credibility, tax benefits, employee benefits,
                          professional management, and limited compliance
                          requirements.{" "}
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                          What are the compliance requirements for a Private
                          Limited Company?
                        </h5>
                        <div>
                          <button
                            id="ques-4-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-4").style.display =
                                "block";
                              document.getElementById(
                                "ques-4-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-4-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-4-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-4").style.display =
                                "none";
                              document.getElementById(
                                "ques-4-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-4-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-4" style={{ display: "none" }}>
                        <div class="card-body">
                          The compliance requirements for a Private Limited
                          Company typically include annual filings, holding
                          annual general meetings, tax filings, maintaining
                          statutory registers, holding board meetings,
                          appointing a company secretary (where required),
                          adhering to corporate governance principles, complying
                          with regulations, record-keeping, and reporting
                          changes in company structure.{" "}
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                          How long does it take to register a Private Limited
                          Company?
                        </h5>
                        <div>
                          <button
                            id="ques-5-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-5").style.display =
                                "block";
                              document.getElementById(
                                "ques-5-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-5-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-5-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-5").style.display =
                                "none";
                              document.getElementById(
                                "ques-5-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-5-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-5" style={{ display: "none" }}>
                        <div class="card-body">
                          The duration to register a Private Limited Company
                          varies depending on the jurisdiction and the
                          efficiency of the registration process. In some
                          countries, it can be completed within a few days if
                          all the required documents are in order and submitted
                          promptly. However, in other jurisdictions, it may take
                          several weeks or even months due to administrative
                          procedures, document processing times, and any
                          additional regulatory requirements. It's advisable to
                          consult with local authorities or legal experts
                          familiar with the registration process in the specific
                          jurisdiction for a more accurate estimate of the
                          registration timeline.{" "}
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                          Can a Private Limited Company be converted into
                          another type of business entity?
                        </h5>
                        <div>
                          <button
                            id="ques-6-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-6").style.display =
                                "block";
                              document.getElementById(
                                "ques-6-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-6-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-6-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-6").style.display =
                                "none";
                              document.getElementById(
                                "ques-6-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-6-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-6" style={{ display: "none" }}>
                        <div class="card-body">
                          Yes, a Private Limited Company can typically be
                          converted into another type of business entity,
                          subject to the laws and regulations of the
                          jurisdiction where it operates.{" "}
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                          Can a foreign national or non-resident be a director
                          or shareholder in a Private Limited Company in India?
                        </h5>
                        <div>
                          <button
                            id="ques-7-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-7").style.display =
                                "block";
                              document.getElementById(
                                "ques-7-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-7-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-7-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-7").style.display =
                                "none";
                              document.getElementById(
                                "ques-7-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-7-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-7" style={{ display: "none" }}>
                        <div class="card-body">
                          Yes, a foreign national or non-resident can be a
                          director or shareholder in a Private Limited Company
                          in India. However, there are certain regulatory
                          requirements and restrictions that must be adhered to,
                          including obtaining a Director Identification Number
                          (DIN), obtaining a valid business visa, adhering to
                          Foreign Exchange Management Act (FEMA) regulations,
                          and complying with any other applicable laws and
                          regulations related to foreign investment and company
                          ownership in India. Additionally, at least one
                          director of the company must be a resident of India.
                          It's advisable to consult with legal and financial
                          experts familiar with Indian corporate laws and
                          regulations for guidance on specific requirements and
                          compliance procedures.{" "}
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                          What are the key documents required for registering a
                          Private Limited Company?
                        </h5>
                        <div>
                          <button
                            id="ques-8-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-8").style.display =
                                "block";
                              document.getElementById(
                                "ques-8-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-8-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-8-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-8").style.display =
                                "none";
                              document.getElementById(
                                "ques-8-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-8-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-8" style={{ display: "none" }}>
                        <div class="card-body">
                          The key documents required for registering a Private
                          Limited Company typically include Memorandum of
                          Association (MOA), Articles of Association (AOA),
                          Director Identification Number (DIN), Digital
                          Signature Certificate (DSC), proof of identity and
                          address, PAN card, address proof of registered office,
                          NOC from the property owner, consent and declaration
                          forms, and certificate of incorporation (if applicable
                          for foreign directors or shareholders).{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* FAQ SECTION END */}

                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
            {/* SIDEBAR AND MAIN PAGE END */}

            <Footer3 />
          </div>
          {/*} container-scroller */}
          {/*} plugins:js */}
          <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
          {/*} endinject */}
          {/*} Plugin js for this page */}
          <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
          <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
          <script
            src="panel/assets/js/jquery.cookie.js"
            type="text/javascript"
          ></script>
          {/*} End plugin js for this page */}
          {/*} inject:js */}
          <script src="panel/assets/js/off-canvas.js"></script>
          <script src="panel/assets/js/hoverable-collapse.js"></script>
          <script src="panel/assets/js/misc.js"></script>
          <script src="panel/assets/js/settings.js"></script>
          <script src="panel/assets/js/todolist.js"></script>
          {/*} endinject */}
          {/*} Custom js for this page */}
          <script src="panel/assets/js/dashboard.js"></script>
          {/*} End custom js for this page */}
        </body>
      </html>
    </>
  );
}


export default Pagedummy1;
