import React from 'react';
import {useState,useEffect,Component} from 'react';
import axios from './axios';


function Contact(){

    
  //saving default values with usestate
  const state = useState();

  
  //name
  const[cname,setcname]=useState();const updatecname = (event) => { setcname(event.target.value);};
  //email
  const[email,setemail]=useState();const updateemail = (event) => { setemail(event.target.value);};
  //contact
  const[contact,setcontact]=useState();const updatecontact = (event) => { setcontact(event.target.value);};
  //cstate
  const[cstate,setcstate]=useState();const updatecstate = (event) => { setcstate(event.target.value);};
  

return(
    <>
    {/* doctype html */}
<html lang="en">
  <head>
    {/* -- Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

    {/* -- Bootstrap CSS */}
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous" />

    <title>Contact Form</title>
  </head>
  <body>
    <h1>Contact Form</h1>
    <div style={{"width":"80vw","marginLeft":"10vw","marginLeft":"6vh"}}>
       
  <div class="form-group">
    <label for="exampleInputEmail1">Name</label>
    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" value={cname} onChange={updatecname} placeholder="Enter Name"  />
    <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
  </div>
  <div class="form-group">
    <label for="exampleInputPassword1">Contact</label>
    <input type="password" class="form-control" id="exampleInputPassword1" value={contact} onChange={updatecontact} placeholder="Password" />
  </div>
  <div class="form-group">
    <label for="exampleInputEmail1">Email</label>
    <input type="email" class="form-control" id="exampleInputEmail1" value={email} onChange={updateemail} aria-describedby="emailHelp" placeholder="Enter Name"  />
    <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
  </div>
  <div class="form-check">
    <input type="checkbox" class="form-check-input" id="exampleCheck1" value={cstate} onChange={updatecstate} />
    <label class="form-check-label" for="exampleCheck1">Check me out</label>
  </div>
  <button id="submit" class="btn btn-primary" onClick={()=>{/*callfunction()*/
   
   // axios.get("https://thestartupnetwork.000webhostapp.com/api/clients", {
    axios.post("http://127.0.0.1:8000/api/clients", {
    
      Name:cname,
      Contact:contact,
      Email:email

    
})
/*axios({
  method: 'get',
  url: "http://127.0.0.1:8000/api/clients",
  headers: {}, 
  data: {
    Name:"Abcde",//cname,
      Contact:"988777777",//contact,
      Email:"abcde@gmail.com",//email,

  }
})*/

    .then((response) => {
    console.log(response);
    alert(response.data.error);
    //alert(response.data.error+"<br/>"+response.data.validatorerror);
    console.log(response.data.validatorerror);
    console.log(response.data.Name);
  }    );

return;

}
}>Submit</button>

        </div>
    {/* -- Optional JavaScript */}
    {/* -- jQuery first, then Popper.js, then Bootstrap JS */}
    <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js" integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo" crossorigin="anonymous"></script>
    <script src="https://cdn.jsdelivr.net/npm/popper.js@1.14.7/dist/umd/popper.min.js" integrity="sha384-UO2eT0CpHqdSJQ6hJty5KVphtPhzWj9WO1clHTMGa3JDZwrnQq4sF86dIHNDz0W1" crossorigin="anonymous"></script>
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/js/bootstrap.min.js" integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM" crossorigin="anonymous"></script>
  </body>
</html>
      
    </>
);

}

export default Contact;