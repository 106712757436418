import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';




/*import Amanbg from './amanbg';*/


function Address(){

  

    return(
<>


{/* ADDRESS SECTION START */}
<div class="content-wrapper">

<div class="col-12">


                <div class="card">
                  <div class="card-body">
                    <h2 >ADDRESS DETAILS</h2>
                    <div class="form-sample" style={{textAlign:"left"}}>
                      <p class="card-description"> Personal information Form </p>
                      <p class="card-description">Permanent Address </p>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Street Address 1</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" style={{border:"0.1rem solid black"}} />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">State</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" style={{border:"0.1rem solid black"}} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Street Address 2</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" style={{border:"0.1rem solid black"}} />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Postcode</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" style={{border:"0.1rem solid black"}} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">City</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" style={{border:"0.1rem solid black"}} />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Country</label>
                            <div class="col-sm-9">
                              <select class="form-control" style={{border:"0.1rem solid black",padding:"1rem"}}>
                                <option>America</option>
                                <option>Italy</option>
                                <option>Russia</option>
                                <option>Britain</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                          <div class="form-check">
                              <label class="form-check-label">
                                <input type="checkbox" class="form-check-input" checked /> Same as Permanent Address </label>
                            </div>
                        </div>
                      </div>
                        </div>
                        <p class="card-description">Current Address </p>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Street Address 1</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" style={{border:"0.1rem solid black"}} />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">State</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" style={{border:"0.1rem solid black"}} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Street Address 2</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" style={{border:"0.1rem solid black"}} />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Postcode</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" style={{border:"0.1rem solid black"}} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">City</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" style={{border:"0.1rem solid black"}} />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Country</label>
                            <div class="col-sm-9">
                              <select class="form-control" style={{border:"0.1rem solid black", padding:"1rem"}}>
                                <option>America</option>
                                <option>Italy</option>
                                <option>Russia</option>
                                <option>Britain</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        </div>
                      </div>
                      <button type="button" class="btn btn-outline-danger btn-icon-text" style={{}}  onClick={()=>{        
                        //Back Button                  
                          document.getElementById("section-1").style.display="block";//Showing previous section
                          document.getElementById("section-2").style.display="none";//Hiding next section
                          sessionStorage.setItem("pval","10%");// Updating Progress Bar
                      }} > <i class="mdi mdi-chevron-double-left"></i>Back </button>
                     <button type="button" class="btn btn-outline-success btn-icon-text" style={{marginLeft:"3rem"}}  onClick={()=>{        
                        //Next Button                  
                          document.getElementById("section-3").style.display="block";//Showing next section
                          document.getElementById("section-2").style.display="none";//Hiding previous section
                          sessionStorage.setItem("pval","30%");// Updating Progress Bar
                      }} > Next <i class="mdi mdi-chevron-double-right"></i></button>
                     <button type="button" class="btn btn-gradient-dark float-end">Save</button>
                    </div>
                  </div>
                </div> 
              </div>

              </div>
              {/* ADDRESS SECTION END */}
              </>
);
    }

export default Address;