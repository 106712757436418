import React from 'react';
import {useState,useEffect,Component} from 'react';
//import axios from './axios';


function Footer(){

return(
<>
   
{/* footer start */}
<footer>
  <div class="footer-wrap" >
  <div class="container first_class" >
      <div class="row">
        <div class="col-md-4 col-sm-6">
          <h3>CONNECT TO US.</h3>
          <p>Get all the latest information on business and startups Sign up for our newsletter today.</p>
        </div>
        <div class="col-md-4 col-sm-6">
        <form class="newsletter">
           <input type="text" placeholder="Email Address" /> 
                                                        <button class="newsletter_submit_btn" type="submit"><i class="fa fa-paper-plane"></i></button>  
        </form>
        
        </div>
        <div class="col-md-4 col-sm-6">
        <div class="col-md-12">
          <div class="standard_social_links">
        <div>
    <li><a href="https://www.facebook.com/profile.php?id=100089210907604"><img class="round-btn btn-facebook" src="https://cdn-icons-png.flaticon.com/512/5968/5968764.png" id="footer-sm-icons"/></a></li>
    <li><a href="https://www.instagram.com/thestartupconnect/"><img class="round-btn btn-facebook" src="https://cdn-icons-png.flaticon.com/512/3955/3955024.png" id="footer-sm-icons"/></a></li>
    <li><a href="https://www.linkedin.com/company/startupconnect1/"><img class="round-btn btn-facebook" src="https://cdn-icons-png.flaticon.com/512/145/145807.png" id="footer-sm-icons"/></a></li>
    <li><a href="#"><img class="round-btn btn-facebook" src="https://cdn-icons-png.flaticon.com/512/3670/3670147.png" id="footer-sm-icons"/></a></li>
    <li><a href="#"><img class="round-btn btn-facebook" src="https://cdn-icons-png.flaticon.com/512/3670/3670127.png" id="footer-sm-icons"/></a></li>
    <li><a href="#"><img class="round-btn btn-facebook" src="https://cdn-icons-png.flaticon.com/512/2913/2913972.png" id="footer-sm-icons"/></a></li>
       
        </div>
      </div>  
        </div>
          <div class="clearfix"></div>
        <div class="col-md-12"><h3 style={{textAlign: "right"}}>Stay Connected</h3></div>
        </div>
      </div>
  </div>
    <div class="second_class">
      <div class="container second_class_bdr">
      <div class="row">
        <div class="col-md-4 col-sm-6">
          <a className="navbar-brand" href="#"><img style={{width:"70px",height:"70px"}} src="./images/logo-img.png" /><span style={{color:"#14C4ED",fontWeight: "bold"}}>the</span><span style={{color:"#7ED957",fontWeight:"bold"}}>Startup</span><span style={{color:"#FFBD1F",fontWeight:"bold"}}>Connect</span></a>

          <p style={{fontSize:"1.2rem",color:"#a7faeb"}}>Your one-stop solution to serve your business and startup needs.</p>
          <h5 style={{color:"#ffffff"}}>Head Office:</h5>
          <p style={{fontSize:"1rem",color:"#c8cccf"}}>LIG C-5 90 Quarter Sanjivani Nagar, Jabalpur <br/>District: Jabalpur (Madhya Pradesh) <br/>Pincode: 482001 (India)</p>
          <p style={{fontSize:"1rem",color:"#ffffff"}}><img src="https://cdn-icons-png.flaticon.com/128/732/732200.png" style={{height:"2rem",width:"2rem",borderRadius:"50%"}}></img> help.startupconnect@gmail.com</p>
         <p style={{fontSize:"1rem",color:"#ffffff"}}><img src="https://cdn-icons-png.flaticon.com/128/3178/3178284.png" style={{height:"2rem",width:"2rem",borderRadius:"50%"}}></img> +91 84800 02413</p>
        </div>
        <div class="col-md-2 col-sm-6">
          <h3>Most Popular</h3>
          <ul class="footer-links">
            <li><a href="/digitalmarketing">Digital Marketing</a>
            </li>
            <li><a href="/erpsoftware">Enterprise Resource Planners (ERPs)</a>
            </li>
            <li><a href="/saas">Software as a Service (SaaS)</a>
            </li>
            <li><a href="/staticdisplaywebsite">Static Display Website</a>
            </li>
            <li><a href="/ecommercewebsite" target="_blank">E-commerce Website</a>
            </li>
            <li><a href="/privatelimitedcompany" target="_blank">Private Limited Registration</a>
            </li>
            <li><a href="/llpregistration">Limited Liability Partnership</a>
            </li>
            <li><a href="/startupindiaregistration">Startup India Registration</a>
            </li>
            <li><a href="/gstregistration">GST Registration</a>
            </li>
          </ul>
        </div>
        <div class="col-md-3 col-sm-6">
          <h3>SERVICE CATEGORIES</h3>
          <ul class="footer-category">
            <li><a href="#">Marketing</a>
            </li>
            <li><a href="#">Software Solutions</a>
            </li>
            <li><a href="#">Website Development</a>
            </li>
            <li><a href="#">Consultancy</a>
            </li>
            <li><a href="#">Business Registration</a>
            </li>
            <li><a href="#">Taxation</a>
            </li>
            <li><a href="#">Legal</a>
            </li>
            <li><a href="#">Others</a>
            </li>
          </ul>
          <div class="clearfix"></div>
        </div>
        <div class="col-md-3 col-sm-6">
          <h3>Other Links</h3>
          <ul class="footer-links">
            <li><a href="/hiring-login">Job Portal</a>
            </li>

            <li><a href="#">Events & Quizzes</a>
            </li>

            <li><a href="/privacy-policy">Privacy Policy</a>
            </li>

            <li><a href="/termsandconditions">Terms of Service</a>
            </li>

            <li><a href="/refundandcancellation-policy">Refund Policy</a>
            </li>
          </ul>
        </div>
      </div>
      
    </div>
    </div>
    
    <div class="row">
      
      <div class="container-fluid" style={{color:"#d7fcff",textAlign:"center"}}>Copyright @2023 | All Rights Reserved by Technomate Startup Services Pvt. Ltd.</div>
      </div>
      
    </div>
    </footer>




{/* }footer end */}
</>
);


}


export default Footer;