
import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';


import './aman.css';



function Amanhd(){

return(
<ul class="circles">
<li></li>
 <li></li>
 <li></li>
 <li></li>
 <li></li>
 <li></li>
 <li></li>
 <li></li>
 <li></li>
 <li></li>  
</ul> 
);


}

export default Amanhd;