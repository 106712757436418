import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';




/*import Amanbg from './amanbg';*/


function Projectwork(){

  

    return(
<>

          
{/* PROJECTS & WORKS SECTION START */}
<div class="content-wrapper">

<div class="col-12">


                <div class="card">
                  <div class="card-body">
                    <h2 >PROJECTS & WORKS</h2>
                    <div class="form-sample" style={{textAlign:"left"}}>
                      <p class="card-description">Add your projects & works</p>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Project Title</label>
                            <div class="col-sm-9">
                           <input type="text" class="form-control" style={{border:"0.1rem solid black"}} />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Organisation Name</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" style={{border:"0.1rem solid black"}} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">In Project Role</label>
                            <div class="col-sm-9">
                           <input type="text" class="form-control" style={{border:"0.1rem solid black"}} />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Sector</label>
                            <div class="col-sm-9">
                            <select class="form-control" style={{padding:"1rem", border:"0.1rem solid black"}}>
                                <option>Part Type</option>
                                <option>Full Time</option>
                                <option>Internship</option>
                                <option>Contract</option>
                                <option>Other</option>
                              </select>                       </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Industry</label>
                            <div class="col-sm-9">
                            <select class="form-control" style={{padding:"1rem", border:"0.1rem solid black"}}>
                                <option>Part Type</option>
                                <option>Full Time</option>
                                <option>Internship</option>
                                <option>Contract</option>
                                <option>Other</option>
                              </select>         
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Category</label>
                            <div class="col-sm-9">
                            <select class="form-control" style={{padding:"1rem", border:"0.1rem solid black"}}>
                                <option>Part Type</option>
                                <option>Full Time</option>
                                <option>Internship</option>
                                <option>Contract</option>
                                <option>Other</option>
                              </select>                       </div>
                          </div>
                        </div>
                      </div>
                          <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Start Date</label>
                            <div class="col-sm-9">
                            <input type="date" class="form-control" id="firstname" style={{border:"0.1rem solid black"}}  />
                             </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">End Date</label>
                            <div class="col-sm-9">
                        <input type="date" class="form-control" id="firstname" style={{border:"0.1rem solid black"}}  />

                            </div>
                          </div>
                        </div>
                      </div>
                           <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Project Details</label>
                            <div class="col-sm-9">
                            <textarea class="form-control" id="firstname" style={{border:"0.1rem solid black"}}></textarea>
                             </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Skills Utilized</label>
                            <div class="col-sm-9">
                        <input type="text" class="form-control" id="firstname" style={{border:"0.1rem solid black"}}  />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Attach Link(if any)</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" id="firstname" style={{border:"0.1rem solid black"}}  />
                             </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Link Remarks</label>
                            <div class="col-sm-9">
                        <input type="text" class="form-control" id="firstname" style={{border:"0.1rem solid black"}}  />

                            </div>
                          </div>
                        </div>
                      </div>
                      <p class="card-description">Referenece Person(if any)</p>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Name</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" id="firstname" style={{border:"0.1rem solid black"}}  />
                             </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Contact</label>
                            <div class="col-sm-9">
                        <input type="text" class="form-control" id="firstname" style={{border:"0.1rem solid black"}}  />

                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Position</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" id="firstname" style={{border:"0.1rem solid black"}}  />
                             </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Email</label>
                            <div class="col-sm-9">
                        <input type="text" class="form-control" id="firstname" style={{border:"0.1rem solid black"}}  />

                            </div>
                          </div>
                        </div>
                      </div>
            
              </div>
                 <div class="d-flex justify-content-center">
                    <button type="button" class="btn btn-gradient-primary btn-rounded btn-icon" >
                            <i class="mdi mdi-plus-circle-outline" style={{fontSize:"2.8rem"}} ></i>
                          </button>
                          </div>
                          <br/>
                          <br/>
                          <br/>

                          <button type="button" class="btn btn-outline-danger btn-icon-text" style={{}}  onClick={()=>{        
                        //Back Button                  
                          document.getElementById("section-6").style.display="block";//Showing previous section
                          document.getElementById("section-7").style.display="none";//Hiding next section
                          sessionStorage.setItem("pval","60%");// Updating Progress Bar
                      }} > <i class="mdi mdi-chevron-double-left"></i>Back </button>
                     <button type="button" class="btn btn-outline-success btn-icon-text" style={{marginLeft:"3rem"}}  onClick={()=>{        
                        //Next Button                  
                          document.getElementById("section-8").style.display="block";//Showing next section
                          document.getElementById("section-7").style.display="none";//Hiding previous section
                          sessionStorage.setItem("pval","80%");// Updating Progress Bar
                      }} > Next <i class="mdi mdi-chevron-double-right"></i></button>            
                     <button type="button" class="btn btn-gradient-dark float-end">Save</button>

  
              </div>
                         </div>
                
              </div>
              </div>
              {/* PROJECTS & WORKS SECTION END */}

              </>
);
    }

export default Projectwork;