import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from 'axios';


function CandidateprofileProjectsandworks(){

  

    return(
<>

                {/* PROJECTS AND WORKS SECTION START */}
                <div class="hire-form-panel" style={{backgroundColor:"#ffffff",display:"none"}} id="projects-works-section">
            <h3>Projects and Works</h3>
            <p style={{color:"#acafb0"}}>Add your projects and works that will be displayed in your profile and detailed resume.</p>


            <div class="hire-form-list">
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Project Title</div>
           <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Assertion of Web Development Technologies" /></div>
           </div>
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Organization Name</div>
           <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : ABC Institute of Technology" /> 
           </div>
           </div>
           </div>

           <div class="hire-form-list">
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>In Project Role</div>
           <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Project Assitant" /></div>
           </div>
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Role Type</div>
           <div class="hire-form-group-right"><select class="hire-form-group-input">
           <option>Full Time</option>
           <option>Part Time</option>
           <option>Internship</option>
           <option>Contract</option>
           </select>  
           </div>
           </div>
           </div>

           <div class="hire-form-list">
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Start Date</div>
           <div class="hire-form-group-right"><input type="date" class="hire-form-group-input"  />
           </div>
           </div>
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>End Date</div>
           <div class="hire-form-group-right"><input type="date" class="hire-form-group-input"  /> 
           </div>
           </div>
           </div>


           <div class="hire-form-list">
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Industry</div>
           <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : IT/Tech"  />
           </div>
           </div>
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Sector</div>
           <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Web Development" /> 
           </div>
           </div>
           </div>

           <div class="hire-form-list">
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Category</div>
           <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Front End"  />
           </div>
           </div>
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label">Currently Working Here</label></div>
           <div class="hire-form-group-right">
           <input type="radio" style={{marginLeft:"10rem"}} /><label style={{marginLeft:"2rem",padding:"1rem"}}>Yes</label>
           <input type="radio" style={{marginLeft:"1rem"}} /><label style={{marginLeft:"2rem",padding:"1rem"}}>No</label>
           </div>
           </div>
           </div>

           <div class="hire-form-list">
            <div class="hire-form-group-large">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Project Details</div>
            <div class="hire-form-group-right"><textarea size="5" class="hire-form-group-input" placeholder="For e.g. : Managed Full Stack web development of a particular new site." ></textarea>
            </div>
            </div>
            </div>


            <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Skills Utilized</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : HTML" /></div>
            </div>
         
            </div>
            <div class="hire-list-card-skillbar">
            <div class="hire-card-skill-badge">
            <span>MySql</span>
            </div>  
            <div class="hire-card-skill-badge">
            <span>Web Development</span>
            </div>  
            <div class="hire-card-skill-badge">
            <span>React Js</span>
            </div>  
            <div class="hire-card-skill-badge">
            <span>Laravel</span>
            </div>  
            <div class="hire-card-skill-badge">
            <span>HTML/CSS</span>
            </div>  
       
           </div>

           
           <div class="hire-form-list">
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Link (if any)</div>
           <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : https://xyzinstitute.com/work-gfffff6666" /> 
           </div>
           </div>
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Link Remarks</div>
           <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Regular link" />
           </div>
           </div>
           </div>


           <div id="line-3"></div>

           <h4 style={{textAlign:"left",marginLeft:"4rem"}}>Reference Person</h4>

           <div class="hire-form-list">
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Name</div>
           <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Sohan Tiwari" /></div>
           </div>
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Contact</div>
           <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : +91 73663 37373" /> 
           </div>
           </div>
           </div>

           <div class="hire-form-list">
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Email</div>
           <div class="hire-form-group-right"><input type="email" class="hire-form-group-input" placeholder="For e.g. : sohantiwari21@gmail.com" /></div>
           </div>
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Position</div>
           <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Chief Project Manager" /> 
           </div>
           </div>
           </div>


           <div class="hire-form-btn">
           <button style={{backgroundColor:"#ffffff",border:"none", marginTop:"2rem"}}><img src="https://cdn-icons-png.flaticon.com/128/10337/10337579.png" class="hire-card-icon" style={{height:"4rem",width:"4rem",}} /></button>
           </div>
          
            <div class="hire-form-btn">
            <button class="hire-btn-default" style={{backgroundColor:"#12d571",marginRight:"2rem",marginTop:"2rem",marginBottom:"2rem"}}
            onClick={()=>{ document.getElementById("projects-works-section").style.display="none";
            document.getElementById("achievements-section").style.display="block";
             }}
            >Save and Next</button>
            </div>

            </div>
            {/* PROJECTS AND WORKS SECTION END */}
          
              
</>
);
    }

export default CandidateprofileProjectsandworks;