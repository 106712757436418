import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';
import moment from 'moment/moment';


import '../Resources/dashboard.css';


function Create_counter(){

  


 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const busyid=sessionStorage.getItem("BusyId00");
 const busyname=sessionStorage.getItem("BusyName00");




//saving default values with usestate
const state = useState();

//counter_name
const[counter_name,setcounter_name]=useState();const updatecounter_name = (event) => { setcounter_name(event.target.value);}; const[counter_name_er,setcounter_name_er]=useState();
//counter_type
const[counter_type,setcounter_type]=useState();const updatecounter_type = (event) => { setcounter_type(event.target.value);}; const[counter_type_er,setcounter_type_er]=useState();
//counter_category
const[counter_category,setcounter_category]=useState();const updatecounter_category = (event) => { setcounter_category(event.target.value);}; const[counter_category_er,setcounter_category_er]=useState();
//counter_details
const[counter_details,setcounter_details]=useState();const updatecounter_details = (event) => { setcounter_details(event.target.value);}; const[counter_details_er,setcounter_details_er]=useState();
//counter_limit
const[counter_limit,setcounter_limit]=useState();const updatecounter_limit = (event) => { setcounter_limit(event.target.value);}; const[counter_limit_er,setcounter_limit_er]=useState();



//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();






  
//******************* ADD NEW COUNTER FUNCTION START ********************//
function createcounter(){

    //Setting Error Variables
  setcounter_name_er(" ");setcounter_type_er(" ");setcounter_category_er(" ");setcounter_details_er(" ");setcounter_limit_er(" ");seterror_msg(" ");

 

    //Checking if user loggedin
    if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((busyname!=null)||(busyname!=" "))){
   
   
    axios.post("bt-create-counter", {
     Log_id:logid,
     Token:token,
     Busy_id:busyid,
     Business_name:busyname,
     Counter_name : counter_name,
     Details : counter_details,
     Type : counter_type,
     Category : counter_category,
     Counter_limit : counter_limit
   })
   .then((response) => {

    //Invalid Session
    if((response.data.error)==100){ 
        //Remove Session Variables and redirect to login
       sessionStorage.removeItem("logStatus00");
       sessionStorage.removeItem("logId00");
       sessionStorage.removeItem("logToken00");
       sessionStorage.removeItem("BusyId00");
       sessionStorage.removeItem("BusyName00");

       window.location.replace("businesstools-login");
    }

     //Validation Errors
   if(response.data.validatorerror){
    setcounter_name_er(response.data.validatorerror.Counter_name);
    setcounter_type_er(response.data.validatorerror.Type);
    setcounter_category_er(response.data.validatorerror.Category);
    setcounter_details_er(response.data.validatorerror.Details);
    setcounter_limit_er(response.data.validatorerror.Counter_limit);
  
  }
  //Invalid Entries
  if(response.data.error==102){ 
    
    setcounter_name_er(response.data.counter_name_er);
    setcounter_type_er(response.data.type_er);
    setcounter_category_er(response.data.category_er);
    setcounter_details_er(response.data.details_er);
    setcounter_limit_er(response.data.counter_limit_er);
  
    seterror_msg("Cannot Add Counter!");  }

       //User Login
       if(response.data.error==201){     seterror_msg(response.data.error_msg); return; }

    //Counter Name Already Exists
    if(response.data.error==103){     seterror_msg("Counter Name Already Exists!");  }

    //Counter Limit Reached
    if(response.data.error==111){     seterror_msg(response.data.error_msg); return; }

     //Cannot Create Counter
     if(response.data.successvar==1){        document.getElementById("success-card").style.display="block";  }
     else{  seterror_msg("Cannot Add Counter !"); }
    

});
}
   //User not Logedin
   else{
   
   //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");

     window.location.replace("businesstools-login");
   
    }
   
   }
    
   //******************* ADD NEW COUNTER FUNCTION END ********************//

  

return(
    <>
                <div class="card">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("create-form").style.display="none"; }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                  <div class="card-body">
                    <h4 class="card-title">Add New Counter</h4>
                    <div class="form-sample">
                      <p class="card-description">enter your counter details here</p>
                      <p><code>{error_msg}</code></p>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Counter Name<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Enter Counter Name" id="counter_name" value={counter_name} onChange={updatecounter_name} />
                            <code>{counter_name_er}</code>
                            </div>
                          </div>
                        </div>
                      {/*}  <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Counter Limit<code>*</code></label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="5000" id="counter_limit" value={counter_limit} onChange={updatecounter_limit} />
                              <code>{counter_limit_er}</code>
                            </div>
                          </div>
                        </div> */}
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type</label>
                            <div class="col-sm-9">
                              <input type="email" class="form-control" placeholder="Enter Counter Type"  id="counter_type" value={counter_type} onChange={updatecounter_type} />
                            {counter_type_er}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Category</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Enter Counter Category"  id="counter_category" value={counter_category} onChange={updatecounter_category} />
                            <code>{counter_category_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                   
                      <div class="form-group">
                        <label for="exampleTextarea1">Details</label>
                        <textarea  class="form-control" rows="2"  id="counter_details" value={counter_details} onChange={updatecounter_details} ></textarea>
                       <code>{counter_details_er}</code>
                      </div>
                   
                    
                      <button class="btn-lg btn-warning" style={{fontWeight:"bold"}} onClick={createcounter} >Create Counter</button>
                        
                       
    
                     
                   
                    </div>
                  </div>
                </div>
    </>

);

}

export default Create_counter;