import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import '../Resources/dashboard.css';


function Debtor_pay(){

return(
    <>
                <div class="card">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("create-form").style.display="none"; }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                  <div class="card-body">
                    <h4 class="card-title">Debtor Payment</h4>
                    <div class="form-sample">
                      <p class="card-description">record your debtor payment here</p>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-6 col-form-label">For Debtor Id</label>
                            <div class="col-sm-6">
                            <label class="col-sm-6 col-form-label">654</label>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-6 col-form-label">Debtor Name</label>
                            <div class="col-sm-6">
                            <label class="col-sm-6 col-form-label">Kumar Sanu Sharma</label>
                            </div>
                          </div>
                        </div>   
                        </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type</label>
                            <div class="col-sm-9">
                              <select class="form-control" >
                              <option>Cash</option>
                              <option>Bank</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Bank</label>
                            <div class="col-sm-9">
                            <select class="form-control" >
                              <option>Select Bank</option>
                              <option>ICICI Bank</option>
                              <option>SBI Bank</option>
                              <option>Axis Bank</option>
                              </select>
                            </div>
                          </div>
                        </div>

                      </div>
                    
                   
                 
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="1000.00" />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Total</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" style={{fontWeight:"bold"}} placeholder="1200.00" />
                            </div>
                          </div>
                        </div>                     
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Discount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="100.00" />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Interest</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="300.00" />
                            </div>
                          </div>
                        </div>                     
                      </div>

                      <div class="form-group">
                        <label for="exampleTextarea1">Particular</label>
                        <textarea
                          class="form-control"
                          id="exampleTextarea1"
                          rows="2"
                        ></textarea>
                      </div>

                        
                      
                        <button class="btn btn-primary" style={{fontWeight:"bold"}}>Submit</button>
                      
                       
    
                     
                   
                    </div>
                  </div>
                </div>
    </>

);

}

export default Debtor_pay;