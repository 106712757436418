import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './startuphomecss.css';
import axios from './axios.js';

function Contesthome(){

  
  //fcname
const[fcname,setfcname]=useState();const updatefcname = (event) => { setfcname(event.target.value);}; const[fcname_er,setfcname_er]=useState();
//fccontact
const[fccontact,setfccontact]=useState();const updatefccontact = (event) => { setfccontact(event.target.value);}; const[fccontact_er,setfccontact_er]=useState();
//fcemail
const[fcemail,setfcemail]=useState();const updatefcemail = (event) => { setfcemail(event.target.value);}; const[fcemail_er,setfcemail_er]=useState();
//fcmessage
const[fcmessage,setfcmessage]=useState();const updatefcmessage = (event) => { setfcmessage(event.target.value);}; const[fcmessage_er,setfcmessage_er]=useState();
const[error_msg,seterror_msg]=useState();//error_msg
const[success_msg,setsuccess_msg]=useState();//success_msg


/***************** CONTACT US FUNCTION START  ******************/
function contactus(){

alert("hello");

//Setting Error Variables
setfcname_er(" "); setfcemail_er(" ");setfccontact_er(" ");setfcmessage_er(" ");seterror_msg(" ");


//Fetching Form Data
let noerror = 0;
const $fcname = document.getElementById("fcname").value; if($fcname==""){ setfcname_er("Name is required !");noerror = noerror+1; }//Fetching fcname Value
const $fcemail = document.getElementById("fcemail").value; if($fcemail==""){ setfcemail_er("Email is required !");noerror = noerror+1; }//Fetching fcEmail Value
const $fccontact = document.getElementById("fccontact").value; if($fccontact==""){ setfccontact_er("Contact is required !");noerror = noerror+1; }//Fetching fccontact Value
const $fcmessage = document.getElementById("fcmessage").value; if($fcmessage==""){ setfcmessage_er("Message is required !");noerror = noerror+1; }//Fetching fcmessage Value

console.log(fcname_er);
//No primary errors
if(noerror==0){


//Sending Data through axios
axios.post("contact-us", { 
Name:$fcname,
Email:$fcemail,
Contact:$fccontact,
Message:$fcmessage,

})

.then((response) => {

//Validation Errors
if(response.data.validatorerror){
setfcname_er(response.data.validatorerror.Name);
setfccontact_er(response.data.validatorerror.Contact);
setfcemail_er(response.data.validatorerror.Email);
setfcmessage_er(response.data.validatorerror.Message);
seterror_msg("Cannot Send Message !");


}
//Invalid Entries
if(response.data.error==102){ 

setfcname_er(response.data.name_er);
setfccontact_er(response.data.contact_er);
setfcemail_er(response.data.email_er);
setfcmessage_er(response.data.message_er);
seterror_msg("Cannot Send Message !");


}

//Success
if(response.data.successvar==1){

//window.location.replace("/businesstools-startworking");
//document.getElementById("success-card").style.display="block";
setsuccess_msg("Message Sent to our Team. They will soon get back to you !!!");
setfcname("");setfccontact("");setfcemail("");setfcmessage("");

}
//failure
if(response.data.successvar==2){
seterror_msg("Cannot Send Message!");
return;
}
}    );

}




}
/***************** CONTACT US FUNCTION END  ******************/

return(
<>
<html lang="en">

<head>
  <meta charset="utf-8" />
  <title>TheEvent - Bootstrap Event Template</title>
  <meta content="width=device-width, initial-scale=1.0" name="viewport" />
  <meta content="" name="keywords" />
  <meta content="" name="description" />

  {/*} Favicons*/}
  <link href="contestcss/contestcss/img/favicon.png" rel="icon" />
  <link href="contestcss/contestcss/img/apple-touch-icon.png" rel="apple-touch-icon" />

  {/*} Google Fonts*/}
  <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,700,700i|Raleway:300,400,500,700,800" rel="stylesheet" />

  {/*} Bootstrap CSS File*/}
  <link href="contestcss/lib/bootstrap/css/bootstrap.min.css" rel="stylesheet" />

  {/*} Libraries CSS Files*/}
  <link href="contestcss/lib/font-awesome/css/font-awesome.min.css" rel="stylesheet" />
  <link href="contestcss/lib/animate/animate.min.css" rel="stylesheet" />
  <link href="contestcss/lib/venobox/venobox.css" rel="stylesheet" />
  <link href="contestcss/lib/owlcarousel/assets/owl.carousel.min.css" rel="stylesheet" />

  {/*} Main Stylesheet File*/}
  <link href="contestcss/css/style.css" rel="stylesheet" />

  {/*} =======================================================
    Theme Name: TheEvent
    Theme URL: https://bootstrapmade.com/theevent-conference-event-bootstrap-template/
    Author: BootstrapMade.com
    License: https://bootstrapmade.com/license/
  =======================================================*/}
</head>

<body>

  {/*}==========================
    Header
  ============================*/}
  <header id="header">
    <div class="container">

      <div id="logo" class="pull-left">
        {/*} Uncomment below if you prefer to use a text logo*/}
        {/*} <h1><a href="#main">C<span>o</span>nf</a></h1>*/}
        <a href="#intro" class="scrollto"><img src="contestcss/contestcss/img/logo.png" alt="" title="" /></a>
      </div>

    {/*}  <nav id="nav-menu-container">
        <ul class="nav-menu">
          <li class="menu-active"><a href="#intro">Home</a></li>
          <li><a href="#about">About</a></li>
          <li><a href="#speakers">Speakers</a></li>
          <li><a href="#schedule">Schedule</a></li>
          <li><a href="#venue">Venue</a></li>
          <li><a href="#hotels">Hotels</a></li>
          <li><a href="#gallery">Gallery</a></li>
          <li><a href="#supporters">Sponsors</a></li>
          <li><a href="#contact">Contact</a></li>
          <li class="buy-tickets"><a href="#buy-tickets">Buy Tickets</a></li>
        </ul>
      </nav> */}{/*} #nav-menu-container*/}
    </div>
  </header>{/*} #header*/}

  {/*}==========================
    Intro Section
  ============================*/}
  <section id="intro">
    <div class="intro-container" >
      <h1 class="mb-4 pb-0">Startup <span>Contests</span><br /><i style={{fontSize:"80%",color:"#23de5c"}}>Launching Soon</i></h1>
      <p class="mb-4 pb-0">Startupcontest is gearing up for an exciting launch! Stay tuned for updates, and be among the first to join our community of innovators.</p>
      <p>For further details on StartupContests and suggestions feel free to reach out to us at -</p>
      <p style={{color:"#5cbbec"}}>help.startupconnect@gmail.com</p>

   {/*}   <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" class="venobox play-btn mb-4" data-vbtype="video"
        data-autoplay="true"></a> */}
      <a href="#contact" class="about-btn scrollto">Join our Community</a>

        </div>
  </section>

  <main id="main">

    {/*}==========================
      About Section
    ============================*/}
    <section id="about">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <h2>Fuel Your Entrepreneurial Spirit</h2>
            <p>At Startupcontest, we curate a diverse range of events designed to inspire, educate, and connect entrepreneurs. From pitch competitions to industry-specific conferences, we have something for every aspiring innovator.</p>
          </div>
          <div class="col-lg-4">
            <h2>Stay In the Loop</h2>
            <p>Stay updated with our upcoming events, webinars, and quiz competitions. Don't miss out on the chance to network with industry leaders, investors, and fellow entrepreneurs.</p>
          </div>
          <div class="col-lg-4">
            <h2>Test Your Knowledge, Win Prizes</h2>
            <p>Challenge yourself and test your entrepreneurial acumen with our interactive quizzes. Not only can you expand your knowledge, but you can also win exciting prizes and recognition for your expertise.</p>
          </div>
        </div>
      </div>
    </section>


 {/*}==========================
      Contact Section
    ============================*/}
    <section id="contact" class="section-bg">

      <div class="container">

        <div class="section-header">
          <h2>Contact Us</h2>
          <p>Stay connected with us on social media as well to stay updated on our latest news, events, and quizzes.</p>
        </div>

        <div class="row contact-info">

          <div class="col-md-4">
            <div class="contact-address">
              <i class="ion-ios-location-outline"></i>
              <h3>Address</h3>
              <address>LIG C-5 90 Quarter Sanjivani Nagar, Jabalpur
                       <br/>District: Jabalpur (Madhya Pradesh)
                      <br/> Pincode: 482001 (India)</address>
            </div>
          </div>

          <div class="col-md-4">
            <div class="contact-phone">
              <i class="ion-ios-telephone-outline"></i>
              <h3>Phone Number</h3>
              <p><a href="tel: +91 84800 02413"> +91 84800 02413</a></p>
            </div>
          </div>

          <div class="col-md-4">
            <div class="contact-email">
              <i class="ion-ios-email-outline"></i>
              <h3>Email</h3>
              <p><a href="mailto:help.startupconnect@gmail.com">help.startupconnect@gmail.com</a></p>
            </div>
          </div>

        </div>

        <div class="form">
          <div id="sendmessage">Your message has been sent. Thank you!</div>
          <div id="errormessage"></div>
          <code>{error_msg}</code>
          <code style={{color:"#50ff9d"}}>{success_msg}</code>
          <div role="form" class="contactForm">
          <div class="form-group">
              <input type="text" class="form-control" name="name" value={fcname} onChange={updatefcname} id="fcname" placeholder="Your Name" data-rule="minlen:6" data-msg="Please enter at least 6 chars of name" />
              <div class="validation"></div>
              <code>{fcname_er}</code>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <input type="text" name="contact" class="form-control" value={fccontact} onChange={updatefccontact} id="fccontact" placeholder="Your Contact" data-rule="minlen:10" data-msg="Please enter valid contact" />
                <div class="validation"></div>
                <code>{fccontact_er}</code>
              </div>
              <div class="form-group col-md-6">
                <input type="email" class="form-control" name="email" value={fcemail} onChange={updatefcemail} id="fcemail" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" />
                <div class="validation"></div>
                <code>{fcemail_er}</code>
              </div>
            </div>
            <div class="form-group">
              <textarea class="form-control" name="message" id="fcmessage" value={fcmessage} onChange={updatefcmessage} rows="5" data-rule="required" data-msg="Please write something for us" placeholder="Message"></textarea>
              <div class="validation"></div>
              <code>{fcmessage_er}</code>
            </div>
            <div class="text-center"><button type="submit" onClick={contactus}>Send Message</button></div>
          </div>
        </div>

      </div>
    </section>{/*} #contact*/}



  
     
      {/*}==========================
    Footer
  ============================*/}
  <footer id="footer">
  <div>
  
      <div class="copyright">
        &copy; <strong>2023 Technomate Startup Services Pvt. Ltd.</strong> All Rights Reserved.
      </div>
      <div class="credits">
        {/*}
          All the links in the footer should remain intact.
          You can delete the links only if you purchased the pro version.
          Licensing information: https://bootstrapmade.com/license/
          Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/buy/?theme=TheEvent
       */}
        Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
      </div>
    </div>
  </footer>{/*} #footer*/}
  </main>

  <a href="#" class="back-to-top"><i class="fa fa-angle-up"></i></a>

  {/*} JavaScript Libraries*/}
  <script src="contestcss/lib/jquery/jquery.min.js"></script>
  <script src="contestcss/lib/jquery/jquery-migrate.min.js"></script>
  <script src="contestcss/lib/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="contestcss/lib/easing/easing.min.js"></script>
  <script src="contestcss/lib/superfish/hoverIntent.js"></script>
  <script src="contestcss/lib/superfish/superfish.min.js"></script>
  <script src="contestcss/lib/wow/wow.min.js"></script>
  <script src="contestcss/lib/venobox/venobox.min.js"></script>
  <script src="contestcss/lib/owlcarousel/owl.carousel.min.js"></script>

  {/*} Contact Form JavaScript File*/}
  <script src="contestcss/contactform/contactform.js"></script>

  {/*} Template Main Javascript File*/}
  <script src="contestcss/js/main.js"></script>
</body>

</html>


</>
);

}

export default Contesthome;