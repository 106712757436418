import React from "react";
import { useState, useEffect, Component } from "react";
import { useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
//import axios from '../../axios.js';
import moment from "moment/moment";

//import "./../Resources/homebtcss.css";
import "../../Resources/home2css.css";
import "../../Resources/serviceshomecss.css";

import "../../Resources/homebtcss.css";
import '../../Resources/responsiveness.css';
import '../../Resources/responsiveness1.css';
//import Sidebar from "./sidebar";

//import Chart from "chart.js/auto";
//import { Line, Bar } from "react-chartjs-2";
import Footer3 from "../../Supportingfiles/footer3";
//import { Navbar } from "flowbite-react";
import Navbar3 from "../../Supportingfiles/navbar3";
import Whystartupconnect from "../whystartupconnect";


function Institutionwebsite1() {
  return (
    <>
      <html lang="en">
        <head>
          {/*} Required meta tags */}
          <meta charset="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <title>STARTUP CONNECT | SERVICES | INSTITUTION WEBSITE</title>
          {/*} plugins:css */}
          <link
            rel="stylesheet"
            href="panel/assets/vendors/mdi/css/materialdesignicons.min.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/css/vendor.bundle.base.css"
          />
          {/*} endinject */}
          {/*} Plugin css for this page */}
          <link
            rel="stylesheet"
            href="panel/assets/vendors/jquery-bar-rating/css-stars.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/font-awesome/css/font-awesome.min.css"
          />
          {/*} End plugin css for this page */}
          {/*} inject:css */}
          {/*} endinject */}
          {/*} Layout styles */}
          <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
          {/*} End layout styles */}
          <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
        </head>
        <body>

        
  {/* Whatsapp Chat Icon Start */}
  <div class="wtp-chat-container" /*style={{position:"fixed",marginTop:"92vh", marginLeft:"94vw"}}*/>
    <a aria-label="Chat on WhatsApp" href="https://wa.me/918480002413?text=I%20want%20to%20know%20more%20about%20service%20Institution%20Website%20Development%20by%20the%20Startup%20Connect">   
    <img class="wtp-chat-icon" src="https://cdn-icons-png.flaticon.com/128/3938/3938058.png" /*style={{height:"3rem", width:"3rem"}}*/></img>
  </a>
  </div>
  {/* Whatsapp Chat Icon End */}


          <div class="home2-container">
            <Navbar3 />

            {/* HEADER SECTION START */}
            <section /*class="sv-header-section" */>
              <div class="sv-header-container1">
                <div class="sv-header-left home-header-pd">
                  <h1 style={{ color: "#004463" }}>
                    INSTITUTION WEBSITE
                  </h1>
                  <p><b>An institution website is a digital platform that provides information about an educational or non-profit institution, its services, and activities.</b></p>
                  <p>Institution Website is designed to showcase the services and activities of educational institutions, non-profit organizations, and other entities. It includes features like course listings, event calendars, faculty profiles, donation portals, and more. The website also serves as a tool for communication between the institution and its students, alumni, donors, and the general public.</p>

                  <div class="home2-services-features-list">
                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img loading = "lazy"

                          src="https://cdn-icons-png.flaticon.com/128/1087/1087840.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>500+</h3>
                        <h5 style={{ fontWeight: "bold" }}>Projects</h5>
                      </div>
                    </div>

                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img loading = "lazy"

                          src="https://cdn-icons-png.flaticon.com/128/12735/12735475.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>25+</h3>
                        <h5 style={{ fontWeight: "bold" }}>Cities</h5>
                      </div>
                    </div>

                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img loading = "lazy"

                          src="https://cdn-icons-png.flaticon.com/128/2991/2991235.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>
                          4{" "}
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/7656/7656139.png"
                            style={{ height: "2rem", width: "2rem" }}
                          />
                          +
                        </h3>
                        <h5 style={{ fontWeight: "bold" }}>Google Ratings</h5>
                      </div>
                    </div>
                  </div>

                  <div  class="serviceshome-btn-container">
             <div>
             <a href="https://calendly.com/thestartupconnect/30min"><button class="btn-lft-margin" id="home2-modules-section-btn" style={{marginLeft:"0"}}>Book Free Consultancy <i class="mdi mdi-arrow-right"></i></button></a>
             </div>
             <div style={{marginLeft:"0"}}>
             <a href="/home1/Website_and_Software_Development_Brochure.pdf" download="Startup Connect Services Brochure"><button class="btn-lft-margin" id="home2-modules-section-btn" style={{backgroundImage: "linear-gradient(to right, #208d17, #00ad11)", marginLeft:"0"}}>Download Brochure <i class="mdi mdi-download"></i></button></a>
             </div>
             </div>

             
                </div>
                <div
                  class="sv-header-right" /* style={{backgroundColor:"#96dfff",padding:"2rem",borderRadius:"15px",boxShadow:"2px 3px 3px 2px #cbcecf"}} */
                >
                  <img loading = "lazy"

                    src="https://img.freepik.com/free-vector/college-university-students-group-young-happy-people-standing-isolated-white-background_575670-66.jpg"
                    style={{ width: "90%", height: "100%" }}
                  />
                </div>
              </div>
            </section>
            {/* HEADER SECTION END */}
            <hr />

            {/* SIDEBAR AND MAIN PAGE START */}
            <div className="container-scroller">
              {/*<Sidebar /> */}
              <div className="container-fluid page-body-wrapper">
                {/*} partial */}
                <div className="main-panel">
                 

              

               
                  <h2 id="ip-h2" style={{fontWeight:"bold"}}>
                    WHY CHOOSE AN  <span style={{ color: "#9f23c2" }}>
                            INSTITUTION WEBSITE
                          </span>
                  </h2>
                  {/* BENEFITS SECTION START */}
                  <div class="row" style={{ padding: "2rem" }}>
                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/2997/2997592.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Course Listings</h4>
                        <p>
                        Displays courses offered by the institution with course descriptions.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/780/780677.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Event Calendar</h4>
                        <p>
                        Provides a calendar for upcoming events, lectures, and workshops.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/2997/2997279.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Faculty Profiles</h4>
                        <p>
                        Showcases faculty members and their areas of expertise.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/3815/3815861.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Donation Portal</h4>
                        <p>
                        Enables donations for the institution or related programs.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/7653/7653930.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">News and Announcements</h4>
                        <p>
                        Displays news and announcements related to the institution.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/3771/3771518.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Contact Form</h4>
                        <p>
                        Provides a form for contacting the institution directly.
                        </p>
                      </div>
                    </div>

                   
                  
                   
                  </div>
                  {/* BENEFITS SECTION END */}

                  <hr />
                  {/* REQUIREMENTS SECTION START */}
                  <h2 id="ip-h2" style={{fontWeight:"bold"}}>BENEFITS OF AN <span style={{color:"#140d83"}}>INSTITUTION WEBSITE</span></h2>
                  <p style={{fontSize:"1rem", padding:"0.5rem"}}>Selecting an institution website is a strategic decision that goes beyond establishing an online presence—it's about creating a dynamic hub that reflects the values and aspirations of your institution. Here's why opting for our institution website services is the key to unlocking a myriad of opportunities:</p>

                  <div class="row" style={{ padding: "2rem" }}>
                    <div class="col-sm-4 stretch-card grid-margin">
                      <img loading = "lazy"

                        src="https://img.freepik.com/free-vector/college-campus-concept-illustration_114360-13748.jpg"
                        style={{ width: "90%", height: "100%" }}
                      />
                    </div>

                    <div
                      class="col-sm-8 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div>
                        <h4 style={{ fontWeight: "bold" }}>
                        <i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> Professional Image:
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                          {" "}
                          An institution website serves as your digital identity, presenting a professional and credible image to students, parents, and stakeholders. It becomes a centralized platform to showcase achievements, academic programs, and the ethos that defines your institution.</p>
                        <br />
                        <h4 style={{ fontWeight: "bold" }}>
                        <i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> Enhanced Communication:
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                        Facilitate effective communication between the institution, students, parents, and faculty. Our websites are equipped with features like announcements, newsletters, and event calendars to keep everyone informed about important updates, events, and achievements.</p>
                        <br />
                        <h4 style={{ fontWeight: "bold" }}>
                        <i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> Accessibility and Convenience:
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                        With an institution website, vital information is just a click away. Students and parents can access academic resources, schedules, and important announcements from the convenience of their homes, fostering a culture of transparency and accessibility.</p>
                        </div>
                        </div>
                        </div>
                         
                        <div class="row" style={{ padding: "2rem" }}>
                  

                    <div
                      class="col-sm-8 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div>
                        <h4 style={{ fontWeight: "bold" }}>
                        <i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> Student Recruitment and Enrollment:
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                        Attract prospective students with a compelling online presence. Showcase academic programs, faculty profiles, and campus facilities to provide a comprehensive view of what your institution has to offer, ultimately encouraging prospective students to choose your institution. </p>
                        <br />
                        <h4 style={{ fontWeight: "bold" }}>
                        <i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> Alumni Engagement:
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                        Strengthen connections with alumni by creating an online space for alumni news, events, and networking opportunities. An institution website serves as a valuable tool to foster a sense of community and keep former students engaged with the institution's growth and achievements.</p>
                        <br />
                        <h4 style={{ fontWeight: "bold" }}>
                        <i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> Efficient Administration:
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                        Streamline administrative processes with online forms, admission portals, and secure document submissions. An institution website can significantly reduce paperwork, saving time and resources for both administrators and applicants.</p>
                        </div>
                    </div>
                    <div class="col-sm-4 stretch-card grid-margin">
                      <img loading = "lazy"

                        src="https://img.freepik.com/free-vector/college-students-concept-illustration_114360-13745.jpg"
                        style={{ width: "90%", height: "100%" }}
                      />
                    </div>
                  </div>

                  {/* REQUIREMENTS SECTION END */}
                  <hr />
               

                  {/* Why StartUp Connect Starts */}
                  <Whystartupconnect/>
                  {/* Why StartUp Connect Ends */}

                  <hr />

                                  {/* PRICING SECTION START */}
                                  <h2 id="ip-h2" style={{fontWeight:'bold'}}>PRICING FOR AN <span style={{color:"#006345"}}>INSTITUTION WEBSITE</span></h2>
                  <div class="row md-12 justify-content-center" style={{marginTop:"5rem", padding:"1rem"}}>

                  {/* PRICING CARD 1 START */}
                  <div
                      class="col-sm-4 stretch-card grid-margin" id="resp-2"
                      style={{ textAlign: "left" }}
                    >
                    <div class="card">
                    <div style={{backgroundColor:"#207a78",color:"#ffffff",fontWeight:"bold",textAlign:"center",borderRadius:"12px",padding:"1rem"}}>
                    <h3><u>BASIC</u></h3>
                    <div style={{textAlign:"center"}}><h2 style={{fontWeight:"bold"}}> Starts <span style={{color:"#ffffff"}}>₹ 9999*</span></h2></div>
                    </div>
                    <div style={{padding:"1rem",margingTop:"1rem"}}>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i> Home and About Page</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Courses Catalog</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Contact Form</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Notes Sharing</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Academic Calendar</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>News and Events</p>
                        <p style={{fontSize:"1rem", textDecoration:"line-through"}}><i class="mdi mdi-close-circle text-danger" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Class Schedules</p>
                        <p style={{fontSize:"1rem", textDecoration:"line-through"}}><i class="mdi mdi-close-circle text-danger" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Task Sheets</p>
                        <p style={{fontSize:"1rem", textDecoration:"line-through"}}><i class="mdi mdi-close-circle text-danger" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Notifications</p>
                        <p style={{fontSize:"1rem", textDecoration:"line-through"}}><i class="mdi mdi-close-circle text-danger" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Separate Teacher and Student Login</p>
                        <p style={{fontSize:"1rem", textDecoration:"line-through"}}><i class="mdi mdi-close-circle text-danger" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Team Logins and Members Management</p>
                        <p style={{fontSize:"1rem", textDecoration:"line-through"}}><i class="mdi mdi-close-circle text-danger" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Course Timelines and Tracking</p>
                        <p style={{fontSize:"1rem", textDecoration:"line-through"}}><i class="mdi mdi-close-circle text-danger" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Academic Calendar</p>
                        <p style={{fontSize:"1rem", textDecoration:"line-through"}}><i class="mdi mdi-close-circle text-danger" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Campus Facilities and Department Pages (maximum 5)</p>
                        <p style={{fontSize:"1rem", textDecoration:"line-through"}}><i class="mdi mdi-close-circle text-danger" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Alumni Network</p>
                        <p style={{fontSize:"1rem", textDecoration:"line-through"}}><i class="mdi mdi-close-circle text-danger" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Community and Open Interaction Groups</p>
                        <hr/>
                       <div class="d-flex justify-content-center">
                       <button class="btn btn-lg btn-success" style={{backgroundColor:"#207a78", fontSize:"1rem"}}  onClick={()=>{
                        //Setting Values to redirect to Contact Sales Page
                        sessionStorage.setItem("Smodule00","Services");sessionStorage.setItem("Sservice00","Institution Website Development"); window.location.replace("/businesstools-contactsales");
                       }}>Contact Sales</button>
                       </div>
                    </div>
                    </div>
                    </div>
                    {/* PRICING CARD 1 END */}

                      {/* PRICING CARD 2 START */}
                  <div
                      class="col-sm-4 stretch-card grid-margin" id="resp-2"
                      style={{ textAlign: "left" }}
                    >
                    <div class="card">
                    <div style={{backgroundColor:"#207a78",color:"#ffffff",fontWeight:"bold",textAlign:"center",borderRadius:"12px",padding:"1rem"}}>
                    <h3><u>STANDARD</u></h3>
                    <div style={{textAlign:"center"}}><h2 style={{fontWeight:"bold"}}> Starts <span style={{color:"#ffffff"}}>₹ 29999*</span></h2></div>
                    </div>
                    <div style={{padding:"1rem",margingTop:"1rem"}}>
                    <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i> Home and About Page</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Courses Catalog</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Contact Form</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Notes Sharing</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Academic Calendar</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>News and Events</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Class Schedules</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Task Sheets</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Notifications</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Separate Teacher and Student Login</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Team Logins and Members Management</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Course Timelines and Tracking</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Academic Calendar</p>
                        <p style={{fontSize:"1rem", textDecoration:"line-through"}}><i class="mdi mdi-close-circle text-danger" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Campus Facilities and Department Pages (maximum 5)</p>
                        <p style={{fontSize:"1rem", textDecoration:"line-through"}}><i class="mdi mdi-close-circle text-danger" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Alumni Network</p>
                        <p style={{fontSize:"1rem", textDecoration:"line-through"}}><i class="mdi mdi-close-circle text-danger" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Community and Open Interaction Groups</p>
                        <hr/>
                       <div class="d-flex justify-content-center">
                       <button class="btn btn-lg btn-success" style={{backgroundColor:"#207a78", fontSize:"1rem"}}  onClick={()=>{
                        //Setting Values to redirect to Contact Sales Page
                        sessionStorage.setItem("Smodule00","Services");sessionStorage.setItem("Sservice00","Institution Website Development"); window.location.replace("/businesstools-contactsales");
                       }}>Contact Sales</button>
                       </div>
                    </div>
                    </div>
                    </div>
                    {/* PRICING CARD 2 END */}

                      {/* PRICING CARD 3 START */}
                  <div
                      class="col-sm-4 stretch-card grid-margin" id="resp-2"
                      style={{ textAlign: "left" }}
                    >
                    <div class="card">
                    <div style={{backgroundColor:"#207a78",color:"#ffffff",fontWeight:"bold",textAlign:"center",borderRadius:"12px",padding:"1rem"}}>
                    <h3><u>PREMIUM</u></h3>
                    <div style={{textAlign:"center"}}><h2 style={{fontWeight:"bold"}}> Starts <span style={{color:"#ffffff"}}>₹ 49999*</span></h2></div>
                    </div>
                    <div style={{padding:"1rem",margingTop:"1rem"}}>
                    <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i> Home and About Page</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Courses Catalog</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Contact Form</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Notes Sharing</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Academic Calendar</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>News and Events</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Class Schedules</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Task Sheets</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Notifications</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Separate Teacher and Student Login</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Team Logins and Members Management</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Course Timelines and Tracking</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Academic Calendar</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Campus Facilities and Department Pages (maximum 5)</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Alumni Network</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Community and Open Interaction Groups</p>
                         <hr/>
                       <div class="d-flex justify-content-center">
                       <button class="btn btn-lg btn-success" style={{backgroundColor:"#207a78", fontSize:"1rem"}} onClick={()=>{
                        //Setting Values to redirect to Contact Sales Page
                        sessionStorage.setItem("Smodule00","Services");sessionStorage.setItem("Sservice00","Institution Website Development"); window.location.replace("/businesstools-contactsales");
                       }}>Contact Sales</button>
                       </div>
                    </div>
                    </div>
                    </div>
                    {/* PRICING CARD 3 END */}


                  </div>
                  <div>
                    <h3 style={{fontSize:"1rem",backgroundColor:"#207a78",color:"#ffffff",padding:"1rem"}}>CUSTOMIZED OPTIONS ALSO AVAILABLE</h3>
                  </div>
                  <hr/>
                  {/* PRICING SECTION END */}
              
              

             
                  <h2 id="ip-h2">Frequently Asked Questions (FAQs)</h2>
                  {/* FAQ SECTION START */}
                  <div
                    id="accordion"
                    style={{ textAlign: "left", marginTop: "3rem" }}
                  >
                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#003e73" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                        What is an institution website?
                        </h5>
                        <div>
                          <button
                            id="ques-1-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-1").style.display =
                                "block";
                              document.getElementById(
                                "ques-1-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-1-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-1-dicon"
                            style={{ display: "none" }}
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-1").style.display =
                                "none";
                              document.getElementById(
                                "ques-1-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-1-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-1">
                        <div class="card-body">
                        An institution website is a website that provides information about an institution such as a school, college, or university. </div>
                      </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                          What kind of content can be included on an institution website?
                        </h5>
                        <div>
                          <button
                            id="ques-2-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-2").style.display =
                                "block";
                              document.getElementById(
                                "ques-2-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-2-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-2-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-2").style.display =
                                "none";
                              document.getElementById(
                                "ques-2-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-2-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-2" style={{ display: "none" }}>
                        <div class="card-body">
                        An institution website can include information about the institution's history, academic programs, faculty, admission procedures, campus facilities, and student life.
                        </div>
                        </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                       How can an institution website help students?
                        </h5>
                        <div>
                          <button
                            id="ques-3-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-3").style.display =
                                "block";
                              document.getElementById(
                                "ques-3-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-3-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-3-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-3").style.display =
                                "none";
                              document.getElementById(
                                "ques-3-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-3-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-3" style={{ display: "none" }}>
                        <div class="card-body">
                        An institution website can provide students with easy access to information about academic programs, admission procedures, campus facilities, and student life, which can help them make informed decisions about their education. </div>
                      </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                         Can an institution website be customized to fit the institution's brand?
                        </h5>
                        <div>
                          <button
                            id="ques-4-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-4").style.display =
                                "block";
                              document.getElementById(
                                "ques-4-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-4-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-4-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-4").style.display =
                                "none";
                              document.getElementById(
                                "ques-4-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-4-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-4" style={{ display: "none" }}>
                        <div class="card-body">
                        Yes, an institution website can be customized to fit the institution's brand by using appropriate colors, fonts, logos, and images.
                         </div>
                      </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                      Can an institution website be updated easily?

                        </h5>
                        <div>
                          <button
                            id="ques-5-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-5").style.display =
                                "block";
                              document.getElementById(
                                "ques-5-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-5-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-5-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-5").style.display =
                                "none";
                              document.getElementById(
                                "ques-5-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-5-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-5" style={{ display: "none" }}>
                        <div class="card-body">
                        Yes, an institution website can be updated easily with new information, photos, or videos, making it easy to keep the website current and relevant.{" "}
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                       Can an institution website help attract more students?
                        </h5>
                        <div>
                          <button
                            id="ques-6-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-6").style.display =
                                "block";
                              document.getElementById(
                                "ques-6-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-6-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-6-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-6").style.display =
                                "none";
                              document.getElementById(
                                "ques-6-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-6-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-6" style={{ display: "none" }}>
                        <div class="card-body">
                        Yes, a well-designed and informative institution website can help attract more students by providing them with the information they need to make informed decisions about their education.   </div>
                      </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                         Can an institution website help improve communication between the institution and students?
                        </h5>
                        <div>
                          <button
                            id="ques-7-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-7").style.display =
                                "block";
                              document.getElementById(
                                "ques-7-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-7-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-7-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-7").style.display =
                                "none";
                              document.getElementById(
                                "ques-7-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-7-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-7" style={{ display: "none" }}>
                        <div class="card-body">
                        Yes, an institution website can help improve communication between the institution and students by providing them with easy access to important information and resources. </div>
                      </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                         Can an institution website be accessed on mobile devices?
                        </h5>
                        <div>
                          <button
                            id="ques-8-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-8").style.display =
                                "block";
                              document.getElementById(
                                "ques-8-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-8-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-8-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-8").style.display =
                                "none";
                              document.getElementById(
                                "ques-8-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-8-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-8" style={{ display: "none" }}>
                        <div class="card-body">
                        Yes, an institution website can be optimized for mobile devices, allowing students to access important information on-the-go{" "}
                        </div>
                      </div>
                    </div> 
                  </div>
                  {/* FAQ SECTION END */}

                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
            {/* SIDEBAR AND MAIN PAGE END */}

            <Footer3 />
          </div>
          {/*} container-scroller */}
          {/*} plugins:js */}
          <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
          {/*} endinject */}
          {/*} Plugin js for this page */}
          <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
          <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
          <script
            src="panel/assets/js/jquery.cookie.js"
            type="text/javascript"
          ></script>
          {/*} End plugin js for this page */}
          {/*} inject:js */}
          <script src="panel/assets/js/off-canvas.js"></script>
          <script src="panel/assets/js/hoverable-collapse.js"></script>
          <script src="panel/assets/js/misc.js"></script>
          <script src="panel/assets/js/settings.js"></script>
          <script src="panel/assets/js/todolist.js"></script>
          {/*} endinject */}
          {/*} Custom js for this page */}
          <script src="panel/assets/js/dashboard.js"></script>
          {/*} End custom js for this page */}
        </body>
      </html>
    </>
  );
}

export default Institutionwebsite1;
