import React from "react";
import { useState, useEffect, Component } from "react";
import { useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
//import axios from '../../axios.js';
import moment from "moment/moment";

import "../../Resources/homebtcss.css";
import "../../Resources/home2css.css";
import "../../Resources/serviceshomecss.css";
import "../ankitcss.css";
import '../../Resources/responsiveness.css';

//import Sidebar from "./sidebar";

//import Chart from "chart.js/auto";
//import { Line, Bar } from "react-chartjs-2";
import Footer3 from "../../Supportingfiles/footer3";
//import { Navbar } from "flowbite-react";
import Navbar3 from "../../Supportingfiles/navbar3";
import Whystartupconnect from "../whystartupconnect";

function Projectreportpreparation1() {

  
  /* Cashfree Button Style */

  const button_container = {
    border: "1px solid black",
    borderRadius: "5px",
    display: "flex",
    padding: "10px", 
    width: "fit-content",
    cursor: "pointer",
    background: "#0b6083",
    border:"none"
};

const text_container = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginLeft: "10px",
  justifyContent: "center",
  marginRight: "10px",
  fontWeight:"bold"
};
const logo_container = {
  width: "40px",
  height: "40px"
};
const secondary_logo_container = {
width: "16px",
height: "16px",
verticalAlign: "middle"
};


  return (
    <>
      <html lang="en">
        <head>
          {/*} Required meta tags */}
          <meta charset="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <title>STARTUP CONNECT | SERVICES | PROJECT REPORT PREPARATION</title>
          {/*} plugins:css */}
          <link
            rel="stylesheet"
            href="panel/assets/vendors/mdi/css/materialdesignicons.min.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/css/vendor.bundle.base.css"
          />
          {/*} endinject */}
          {/*} Plugin css for this page */}
          <link
            rel="stylesheet"
            href="panel/assets/vendors/jquery-bar-rating/css-stars.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/font-awesome/css/font-awesome.min.css"
          />
          {/*} End plugin css for this page */}
          {/*} inject:css */}
          {/*} endinject */}
          {/*} Layout styles */}
          <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
          {/*} End layout styles */}
          <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
        </head>
        <body>

           
  {/* Whatsapp Chat Icon Start */}
  <div class="wtp-chat-container" /*style={{position:"fixed",marginTop:"92vh", marginLeft:"94vw"}}*/>
    <a aria-label="Chat on WhatsApp" href="https://wa.me/918480002413?text=I%20want%20to%20know%20more%20about%20service%20Project%20Report%20Preparation%20by%20the%20Startup%20Connect">   
    <img class="wtp-chat-icon" src="https://cdn-icons-png.flaticon.com/128/3938/3938058.png" /*style={{height:"3rem", width:"3rem"}}*/></img>
  </a>
  </div>
  {/* Whatsapp Chat Icon End */}

          <div class="home2-container">
            <Navbar3 />

            {/* HEADER SECTION START */}
            <section /*class="sv-header-section" */>
              <div class="sv-header-container1">
                <div class="sv-header-left home-header-pd">
                  <h1 style={{ color: "#004463" }}>
                  PROJECT REPORT PREPARATION
                  </h1>
                  <p>
                  <div style={{ marginBottom: "2px" }}>
                      <span style={{ paddingRight: "5px" }}><i class="mdi mdi-checkbox-marked" style={{fontSize:"2rem", color :"#0b5748"}}></i></span>
                      <span>Fast customer support </span>
                    </div>
                    <div style={{ marginBottom: "2px" }}>
                      <span style={{ paddingRight: "5px" }}><i class="mdi mdi-checkbox-marked" style={{fontSize:"2rem", color :"#0b5748"}}></i></span>
                      <span>Complete online process.</span>
                    </div>
                    <div style={{ marginBottom: "2px" }}>
                      <span style={{ paddingRight: "5px" }}><i class="mdi mdi-checkbox-marked" style={{fontSize:"2rem", color :"#0b5748"}}></i></span>
                      <span>Instant WhatsApp/email updates </span>
                    </div>
                  </p>


                  <div class="home2-services-features-list">
                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img
                          loading="lazy"
                          src="https://cdn-icons-png.flaticon.com/128/1087/1087840.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>500+</h3>
                        <h5 style={{ fontWeight: "bold" }}>Projects</h5>
                      </div>
                    </div>

                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img
                          loading="lazy"
                          src="https://cdn-icons-png.flaticon.com/128/12735/12735475.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>25+</h3>
                        <h5 style={{ fontWeight: "bold" }}>Cities</h5>
                      </div>
                    </div>

                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img
                          loading="lazy"
                          src="https://cdn-icons-png.flaticon.com/128/2991/2991235.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>
                          4{" "}
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/7656/7656139.png"
                            style={{ height: "2rem", width: "2rem" }}
                          />
                          +
                        </h3>
                        <h5 style={{ fontWeight: "bold" }}>Google Ratings</h5>
                      </div>
                    </div>
                  </div>

                  <div  class="serviceshome-btn-container">
             <div>
             <a href="https://calendly.com/thestartupconnect/30min"><button class="btn-lft-margin" id="home2-modules-section-btn" style={{marginLeft:"0"}}>Book Free Consultancy <i class="mdi mdi-arrow-right"></i></button></a>
             </div>
             <div style={{marginLeft:"0"}}>
             <a href="/home1/startupbrochure1.pdf" download="Startup Connect Services Brochure"><button class="btn-lft-margin" id="home2-modules-section-btn" style={{backgroundImage: "linear-gradient(to right, #208d17, #00ad11)", marginLeft:"0"}}>Download Brochure <i class="mdi mdi-download"></i></button></a>
             </div>
             </div>

                </div>
                <div
                  class="sv-header-right" /* style={{backgroundColor:"#96dfff",padding:"2rem",borderRadius:"15px",boxShadow:"2px 3px 3px 2px #cbcecf"}} */
                >
                  <img
                    loading="lazy"
                    src="https://img.freepik.com/free-vector/data-inform-illustration-concept_114360-864.jpg"
                    style={{ width: "90%", height: "100%" }}
                  />
                </div>
              </div>
            </section>
            {/* HEADER SECTION END */}
            <hr />

            {/* SIDEBAR AND MAIN PAGE START */}
            <div className="container-scroller">
              {/*<Sidebar /> */}
              <div className="container-fluid page-body-wrapper">
                {/*} partial */}
                <div className="main-panel">
              
                  {/* MORE INFORMATION SECTION START */}
                  <div class="row" style={{ padding: "2rem" }}>
                    <div
                      class="col-sm-12 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div>
                        <h2 id="ip-h2" style={{ fontWeight: "bold" }}>
                          WHAT IS A{" "}
                          <span style={{ color: "#9f23c2" }}>
                            PROJECT REPORT?
                          </span>
                        </h2>
                        <br />
                     
                        <p style={{ fontSize: "1rem" }}>
                        In financial terms, a project report is a comprehensive document that provides detailed information about a specific project's financial aspects. It serves as a tool for planning, monitoring, and evaluating the financial performance of a project. Project reports are typically prepared before, during, and after the implementation of a project, and they are crucial for stakeholders, investors, and decision-makers.
                        </p>
                        <p style={{ fontSize: "1rem" }}>
                        Here are some key components and elements that are often included in a financial project report:{" "}
                        </p>
                        <p style={{ fontSize: "1rem" }}>
                          <ul>
                            <li style = {{fontSize : "1rem"}}><span style = {{fontWeight:"bolder"}}>Executive Summary:{"  "}</span>A brief overview of the project, including its objectives, scope, and key financial highlights.</li>
                            <li style = {{fontSize : "1rem"}}><span style = {{fontWeight:"bolder"}}>Project Description:{"  "}</span>Detailed information about the project, its goals, methodology, and the rationale behind it.</li>
                            <li style = {{fontSize : "1rem"}}><span style = {{fontWeight:"bolder"}}>Financial Projections:{"  "}</span>This is a critical section that includes estimates and forecasts of the project's financial performance. It may cover aspects such as revenue projections, cost estimates, cash flow analysis, and return on investment (ROI).</li>
                            <li style = {{fontSize : "1rem"}}><span style = {{fontWeight:"bolder"}}>Budget:{"  "}</span>A detailed breakdown of the project's budget, including capital expenditures, operating expenses, and any other financial requirements.</li>
                            <li style = {{fontSize : "1rem"}}><span style = {{fontWeight:"bolder"}}>Funding Sources:{"  "}</span>Information about where the funding for the project is coming from, whether it's from investors, loans, grants, or a combination of these.</li>
                            <li style = {{fontSize : "1rem"}}><span style = {{fontWeight:"bolder"}}>Risk Analysis:{"  "}</span>Identification and assessment of potential financial risks associated with the project, along with proposed risk mitigation strategies.</li>
                            <li style = {{fontSize : "1rem"}}><span style = {{fontWeight:"bolder"}}>Financial Statements:{"  "}</span>Detailed financial statements, including income statements, balance sheets, and cash flow statements, providing a snapshot of the project's financial health.</li>
                            <li style = {{fontSize : "1rem"}}><span style = {{fontWeight:"bolder"}}>Monitoring and Evaluation:{"  "}</span>A plan for monitoring and evaluating the financial performance of the project over time, including key performance indicators (KPIs).</li>
                            <li style = {{fontSize : "1rem"}}><span style = {{fontWeight:"bolder"}}>Conclusion and Recommendations:{"  "}</span>Summarizes the key findings of the financial analysis and provides recommendations for decision-makers.</li>
                            <li style = {{fontSize : "1rem"}}><span style = {{fontWeight:"bolder"}}>Appendix:{"  "}</span>Additional supporting documents or details, such as charts, graphs, and any supplementary information relevant to the financial aspects of the project.</li>
                         </ul>
                        </p>
                        <p style={{ fontSize: "1rem" }}>
                        Project reports are essential for stakeholders to make informed decisions, assess the viability of the project, and track its financial progress. They are often required by financial institutions, investors, and regulatory bodies as part of the project approval and monitoring process.

                        </p>
                      </div>
                    </div>
                 {/*}   <div class="col-sm-3 stretch-card grid-margin">
                      <img
                        loading="lazy"
                        src="https://img.freepik.com/free-vector/business-deal-concept-illustration_114360-1535.jpg"
                        style={{ width: "90%", height: "100%" }}
                      />
                    </div>*/}
                  </div>
                  {/* MORE INFORMATION SECTION END */}

                 
                  {/* BENEFITS SECTION END */}

                  <hr />

     {/* PRICING SECTION START */}
     <h2 id="ip-h2" style={{fontWeight:'bold'}}>PRICING FOR <span style={{color:"#006345"}}>PROJECT REPORT PREPARATION</span></h2>
                  <div class="row md-12 d-flex justify-content-center" style={{marginTop:"5rem", padding:"1rem"}}>

                  {/* PRICING CARD 1 START */}
                  <div
                      class="col-sm-4 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                    <div class="card">
                    <div style={{backgroundColor:"#207a78",color:"#ffffff",fontWeight:"bold",textAlign:"center",borderRadius:"12px",padding:"1rem"}}>
                    <h3><u>BUSINESS</u></h3>
                    <div style={{textAlign:"center"}}><h2 style={{fontWeight:"bold"}}> Starts <span style={{color:"#ffffff"}}>₹ 9999*</span></h2></div>
                    </div>
                    <div style={{padding:"1rem",margingTop:"1rem"}}>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-warning" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Business plan preparation upto INR 25 Lakh of funding</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Two iterations to queries</p>
                        <hr/>
                       <div class="d-flex justify-content-center">
                       <button class="btn btn-lg btn-success" style={{backgroundColor:"#207a78", fontSize:"1rem"}} onClick={()=>{
                        //Setting Values to redirect to Contact Sales Page
                        sessionStorage.setItem("Smodule00","Services");sessionStorage.setItem("Sservice00","Project Report Preparation"); window.location.replace("/businesstools-contactsales");
                       }}>Contact Sales</button>
                       </div>
                    </div>
                    </div>
                    </div>
                    {/* PRICING CARD 1 END */}

                      {/* PRICING CARD 2 START */}
                  <div
                      class="col-sm-4 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                    <div class="card">
                    <div style={{backgroundColor:"#207a78",color:"#ffffff",fontWeight:"bold",textAlign:"center",borderRadius:"12px",padding:"1rem"}}>
                    <h3><u>PROFESSIONAL</u></h3>
                    <div style={{textAlign:"center"}}><h2 style={{fontWeight:"bold"}}> Starts <span style={{color:"#ffffff"}}>₹ 19999*</span></h2></div>
                    </div>
                    <div style={{padding:"1rem",margingTop:"1rem"}}>
                    <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Business plan for working capital limit upto INR 2 Crore</p>
                    <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Two iterations to queries</p>
                      <hr/>
                       <div class="d-flex justify-content-center">
                       <button class="btn btn-lg btn-success" style={{backgroundColor:"#207a78"}} onClick={()=>{
                        //Setting Values to redirect to Contact Sales Page
                        sessionStorage.setItem("Smodule00","Services");sessionStorage.setItem("Sservice00","Project Report Preparation"); window.location.replace("/businesstools-contactsales");
                       }}>Contact Sales</button>
                       </div>
                    </div>
                    </div>
                    </div>
                    {/* PRICING CARD 2 END */}

                        {/* PRICING CARD 3 START */}
                  <div
                      class="col-sm-4 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                    <div class="card">
                    <div style={{backgroundColor:"#207a78",color:"#ffffff",fontWeight:"bold",textAlign:"center",borderRadius:"12px",padding:"1rem"}}>
                    <h3><u>EXPERT</u></h3>
                    <div style={{textAlign:"center"}}><h2 style={{fontWeight:"bold"}}> Starts <span style={{color:"#ffffff"}}>₹ 29999*</span></h2></div>
                    </div>
                    <div style={{padding:"1rem",margingTop:"1rem"}}>
                    <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Business plan for working capital limit upto INR 10 crore</p>
                    <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Two iterations to queries</p>
                      <hr/>
                       <div class="d-flex justify-content-center">
                       <button class="btn btn-lg btn-success" style={{backgroundColor:"#207a78"}} onClick={()=>{
                        //Setting Values to redirect to Contact Sales Page
                        sessionStorage.setItem("Smodule00","Services");sessionStorage.setItem("Sservice00","Project Report Preparation"); window.location.replace("/businesstools-contactsales");
                       }}>Contact Sales</button>
                       </div>
                    </div>
                    </div>
                    </div>
                    {/* PRICING CARD 3 END */}


                  </div>
                  <hr/>
                  {/* PRICING SECTION END */}

                  {/* REQUIREMENTS SECTION START */}
                  <h2 id="ip-h2">KEY ASPECTS FOR PREPARATION OF PROJECT REPORT </h2>

                  <div class="row" style={{ padding: "2rem" }}>
                    <div class="col-sm-4 stretch-card grid-margin">
                      <img
                        loading="lazy"
                        src="https://img.freepik.com/free-vector/business-deal-concept-illustration_114360-1103.jpg"
                        style={{ width: "90%", height: "100%" }}
                      />
                    </div>

                    <div
                      class="col-sm-8 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div>
                        
                        
                        <p style={{ fontSize: "1rem" }}>
                          {" "}
                          <i class="mdi mdi-assistant text-warning" style={{fontSize:"1.4rem",padding:"1rem"}}></i>
                          All assumptions and estimates used in preparation of Report should be mentioned separately.
                        </p>
                        
                       
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                        <i class="mdi mdi-assistant text-warning" style={{fontSize:"1.4rem",padding:"1rem"}}></i>
                        Future projections should be realistic and not merely arithmetic multiples of current performance.
                        </p>
                        
                       
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                        <i class="mdi mdi-assistant text-warning" style={{fontSize:"1.4rem",padding:"1rem"}}></i>
                        All fixed assets, depreciation and loan repayment schedules should be annexed and linked to Project report 
                        </p>
                        <br />
                        
                        <p style={{ fontSize: "1rem" }}>
                        <i class="mdi mdi-assistant text-warning" style={{fontSize:"1.4rem",padding:"1rem"}}></i>
                        The company should be able to justify the performance and numbers projected

                        </p>
                        
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                        <i class="mdi mdi-assistant text-warning" style={{fontSize:"1.4rem",padding:"1rem"}}></i>
                        In case of multiple businesses activity or locations, detailed annexure should be attached showing breakup of how the projected numbers are arrived at
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* REQUIREMENTS SECTION END */}
                  <hr />
                  <h2 id="ip-h2">PROJECT REPORT PREPARATION PROCESS</h2>

                  {/* PROCESS SECTION START */}
                  <div class="row d-flex justify-content-center" style={{padding:"1rem"}}>
                    <div class="col-sm-3 stretch-card grid-margin" id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/free-vector/paying-bills-concept-illustration_114360-19357.jpg?w=740&t=st=1706779500~exp=1706780100~hmac=ad8e6bf9dc5da282c80e40c82c023c68f94bea72270aa35612471e5892f8985b"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold"> Step 1 </h4>
                          <div>
                            <p class="mb-0" style={{ fontSize: "1rem", textAlign:"center" }}>
                              Payment and Document Submission
                              <br />
                              Expert review your Documents
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    

                    <div class="col-sm-3 stretch-card grid-margin" id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/premium-vector/businessman-shaking-hands-deal-symbol-with-fellow-lawyer-lawyers-discuss-with-clients_923732-776.jpg?w=740"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold"> Step 2 </h4>
                          <div>
                            <p class="mb-0" style={{ fontSize: "1rem", textAlign:"center" }}>
                            Draft project report Preperation{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-3 stretch-card grid-margin" id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/free-vector/product-quality-concept-illustration_114360-7301.jpg?w=740&t=st=1706780098~exp=1706780698~hmac=4ed66236fdef104f05dab6b8d7176409171e304e19a3758c389bc28837bbe13b"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold"> Step 3 </h4>
                          <div>
                            <p class="mb-0" style={{ fontSize: "1rem", textAlign:"center" }}>
                              Final Project Report
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    
                  </div>
                  {/* PROCESS SECTION END */}

                  {/* Why StartUp Connect Starts */}

                  
                  {/* Why StartUp Connect Ends */}

              
                  {/* Documents SECTION Starts */}
                  
                  {/* Documents SECTION Ends */}
                  {/* COMPLIANCES SECTION START */}
                  
                  {/* COMPLIANCES SECTION END */}
                 
                  {/* COMPARISON TABLE START */}
                 

                  {/* COMPARISON TABLE END */}

                 
                 
                  {/* FAQ SECTION END */}

                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
            {/* SIDEBAR AND MAIN PAGE END */}

            <Footer3 />
          </div>
          {/*} container-scroller */}
          {/*} plugins:js */}
          <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
          {/*} endinject */}
          {/*} Plugin js for this page */}
          <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
          <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
          <script
            src="panel/assets/js/jquery.cookie.js"
            type="text/javascript"
          ></script>
          {/*} End plugin js for this page */}
          {/*} inject:js */}
          <script src="panel/assets/js/off-canvas.js"></script>
          <script src="panel/assets/js/hoverable-collapse.js"></script>
          <script src="panel/assets/js/misc.js"></script>
          <script src="panel/assets/js/settings.js"></script>
          <script src="panel/assets/js/todolist.js"></script>
          {/*} endinject */}
          {/*} Custom js for this page */}
          <script src="panel/assets/js/dashboard.js"></script>
          {/*} End custom js for this page */}
        </body>
      </html>
    </>
  );
}

export default Projectreportpreparation1;
