import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './Resources/dashboard.css';

 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const logname=sessionStorage.getItem("logName00");


 

function Sidebar(){

return(
    <>
      {/*} partial:partials/_sidebar.html */}
      <nav class="sidebar sidebar-offcanvas" id="sidebar">
        <ul class="nav">
          <li class="nav-item nav-profile border-bottom">
            <a href="/businesstools-login" class="nav-link flex-column">
              <div class="nav-profile-image">
               <img src="https://cdn-icons-png.flaticon.com/128/9069/9069049.png" alt="profile" /> 
                {/*}change to offline or busy as needed */}
              </div> 
              <div class="nav-profile-text d-flex ms-0 mb-3 flex-column">
                <span class="font-weight-semibold mb-1 mt-2 text-center">{logname}</span>
                <span class="text-secondary icon-sm text-center mt-2">Session : SE{logid}098</span>
              </div>
            </a>
          </li>
        {/*}  <li class="nav-item pt-3">
            <a class="nav-link d-block" href="/home">
              <img class="sidebar-brand-logo" src="/home1/weblogo.png" alt="" height="100rem" width="100rem" />
              <img class="sidebar-brand-logomini" src="/home1/weblogo.png" alt="" height="50rem" width="50rem" />
            {/*}  <div class="small font-weight-light pt-1">Responsive Dashboard</div> */}
       {/*}     </a>
          </li>
        {/*}  <li class="pt-2 pb-1">
            <span class="nav-item-head">Dashboard</span>
          </li> */}
          <li class="nav-item">
            <a class="nav-link" href="/businesstools-user-customers">
              <i class="mdi mdi-contacts menu-icon"></i>
              <span class="menu-title">Customers</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/businesstools-user-suppliers">
              <i class="mdi mdi-account-multiple-outline menu-icon"></i>
              <span class="menu-title">Suppliers</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/businesstools-user-cash">
              <i class="mdi mdi-cash-multiple menu-icon"></i>
              <span class="menu-title">Cash</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/businesstools-user-bank">
              <i class="mdi mdi-bank menu-icon"></i>
              <span class="menu-title">Bank</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/businesstools-user-contra">
              <i class="mdi mdi-drawing-box menu-icon"></i>
              <span class="menu-title">Contra Entry</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/businesstools-user-catalog">
              <i class="mdi mdi-format-float-none menu-icon"></i>
              <span class="menu-title">Products/ Catalog</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/businesstools-user-inventory">
              <i class="mdi mdi-package-variant menu-icon"></i>
              <span class="menu-title">Inventory</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/businesstools-user-sales">
              <i class="mdi mdi-chart-areaspline menu-icon"></i>
              <span class="menu-title">Sales</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/businesstools-user-purchases">
              <i class="mdi mdi-clipboard-check menu-icon"></i>
              <span class="menu-title">Purchases</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-bs-toggle="collapse" href="#returns" aria-expanded="false" aria-controls="ui-basic">
              <i class="mdi mdi-truck-delivery menu-icon"></i>
              <span class="menu-title">Returns</span>
              <i class="menu-arrow"></i>
            </a>
            <div class="collapse" id="returns">
              <ul class="nav flex-column sub-menu">
                <li class="nav-item">
                  <a class="nav-link" href="/businesstools-user-salesreturn">Inwards (Sales Return)</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/businesstools-user-purchasereturn">Outwards (Purchases Return)</a>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/businesstools-user-incomes">
              <i class="mdi mdi-briefcase-upload menu-icon"></i>
              <span class="menu-title">Incomes</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/businesstools-user-expenses">
              <i class="mdi mdi-briefcase-download menu-icon"></i>
              <span class="menu-title">Expenses</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-bs-toggle="collapse" href="#outstanding" aria-expanded="false" aria-controls="ui-basic">
              <i class="mdi mdi-crosshairs-gps menu-icon"></i>
              <span class="menu-title">Outstanding</span>
              <i class="menu-arrow"></i>
            </a>
            <div class="collapse" id="outstanding">
              <ul class="nav flex-column sub-menu">
                <li class="nav-item">
                  <a class="nav-link" href="/businesstools-user-outstanding-incomes">Outstanding Incomes</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/businesstools-user-outstanding-expenses">Outstanding Expenses</a>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/businesstools-user-creditor">
              <i class="mdi mdi-account-network menu-icon"></i>
              <span class="menu-title">Creditors</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/businesstools-user-debtors">
              <i class="mdi mdi-account-card-details menu-icon"></i>
              <span class="menu-title">Debtors</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-bs-toggle="collapse" href="#taxes" aria-expanded="false" aria-controls="ui-basic">
              <i class="mdi mdi-chart-gantt menu-icon"></i>
              <span class="menu-title">GST and Taxes</span>
              <i class="menu-arrow"></i>
            </a>
            <div class="collapse" id="taxes">
              <ul class="nav flex-column sub-menu">
                <li class="nav-item">
                  <a class="nav-link" href="/businesstools-user-sgst">SGST</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/businesstools-user-cgst">CGST</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/businesstools-user-igst">IGST</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/businesstools-user-tax">Taxes</a>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item" >
            <a class="nav-link" href="/businesstools-user-quotations">
              <i class="mdi mdi-book-open-page-variant menu-icon"></i>
              <span class="menu-title">Quotations</span>
            </a>
          </li>
          <li class="nav-item" >
            <a class="nav-link" href="/businesstools-user-manufacturing">
              <i class="mdi mdi-cart-plus menu-icon"></i>
              <span class="menu-title">Manufacturing</span>
            </a>
          </li>
          <li class="nav-item" >
            <a class="nav-link" href="/businesstools-user-assets">
              <i class="mdi mdi-city menu-icon"></i>
              <span class="menu-title">Assets</span>
            </a>
          </li>
          <li class="nav-item" >
            <a class="nav-link" href="/businesstools-user-employees">
              <i class="mdi mdi-account-switch menu-icon"></i>
              <span class="menu-title">Employees</span>
            </a>
          </li>
          <li class="nav-item" >
            <a class="nav-link" href="/businesstools-user-salary">
              <i class="mdi mdi-book-open menu-icon"></i>
              <span class="menu-title">Salary</span>
            </a>
          </li>
          <li class="nav-item" hidden={logtype=="user"}  >
            <a class="nav-link" href="/businesstools-user-finalaccounts">
              <i class="mdi mdi-library-books menu-icon"></i>
              <span class="menu-title">Financial Analysis</span>
            </a>
          </li>
        {/*}  <li class="nav-item">
            <a class="nav-link" href="pages/tables/basic-table.html">
              <i class="mdi mdi-tag-multiple menu-icon"></i>
              <span class="menu-title">Offers</span>
            </a>
          </li> */}
         
        </ul>
      </nav>
    </>

);

}

export default Sidebar;