import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';



/*import Amanbg from './amanbg';*/


function Addteammember(){


  

   // Setting Values fom Session Storage
   const logstatus=sessionStorage.getItem("loginstatus00");//loginstatus
   const loguser=sessionStorage.getItem("username00");//username
   const logtype=sessionStorage.getItem("logintype00");//logintype
   const logname=sessionStorage.getItem("loginname00");//loginname
   const logadminid=sessionStorage.getItem("loginadminid00");//loginadminid

       


 //Checking if Sessions are Set

// alert(logstatus,loguser,logadminid);
 //Sessions Not Set
{/* if(((logstatus=="false")||(loguser==null))/*&&(loguser==null/&&(logadminid==null)){
  
  sessionStorage.removeItem("username00");
  sessionStorage.removeItem("loginstatus00");
  sessionStorage.removeItem("logintype00");
  sessionStorage.removeItem("loginname00");
  sessionStorage.removeItem("loginadminid00");
 
  alert("Session Expired! Login Again.");
  window.location.replace("/");
 }

 //Sessions Set
 else{
 */}
     


   //data
   const[data,setdata]=useState([],);const updatedata = (event) => { setdata(event.target.value);};
    //name
    const[name,setname]=useState();const updatename = (event) => { setname(event.target.value);};
    //username
    const[username,setusername]=useState();const updateusername = (event) => { setusername(event.target.value);};
     //email
    const[email,setemail]=useState();const updateemail = (event) => { setemail(event.target.value);};
    //contact
    const[contact,setcontact]=useState();const updatecontact = (event) => { setcontact(event.target.value);};
    //role
    const[role,setrole]=useState();const updaterole = (event) => { setrole(event.target.value);};
    //category
    const[category,setcategory]=useState();const updatecategory = (event) => { setcategory(event.target.value);};
    //password
    const[password,setpassword]=useState();const updatepassword = (event) => { setpassword(event.target.value);};
    //cpassword
    const[cpassword,setcpassword]=useState();const updatecpassword = (event) => { setcpassword(event.target.value);};
    //team
    const[team,setteam]=useState();const updateteam = (event) => { setteam(event.target.value);};
    //remarks
    const[remarks,setremarks]=useState();const updateremarks = (event) => { setremarks(event.target.value);};

   
      //ERROR VARIABLES 
      const[errorvar,seterrorvar]=useState();
      const[error1,seterror1]=useState();
      const[error2,seterror2]=useState();
      const[error3,seterror3]=useState();
      const[error4,seterror4]=useState();
      const[error5,seterror5]=useState();
      const[error6,seterror6]=useState();
      const[error7,seterror7]=useState();
      const[error8,seterror8]=useState();
      const[error9,seterror9]=useState();
      const[error10,seterror10]=useState();
      const[error11,seterror11]=useState();
      const[error12,seterror12]=useState();

         
//Use Effect, Data Load
useEffect(()=>{

  getData();

},[])



//Fetching Teams
function getData(){


//Checking if user loggedin
if(((logstatus=="true")||(logstatus!=null))&&(loguser!=null)&&(logadminid!=null)){



axios.post("team-details", {
 User: loguser,
 Logtype:logtype,
 Logstatus:logstatus,
 Log_id: logadminid,
})
.then((response) => {
//Success Data Fetched
if(response.data.successvar==1){
 //Setting Data
 setdata(response.data.teams);


/* setdatacount(response.data.datacount);
 setloadcount(response.data.loadcount);
 setloadcount(1); */
}
//Cannot Fetch Data
else{
 seterrorvar("Cannot Fetch Data !");
}


});

}
//User not Logedin
else{

sessionStorage.removeItem("username00");
sessionStorage.removeItem("loginstatus00");
sessionStorage.removeItem("logintype00");
sessionStorage.removeItem("loginname00");
sessionStorage.removeItem("loginadminid00");

alert("Session Expired! Login Again.");
window.location.replace("/");

}

}

  
      function create(){
  
           //Checking if user loggedin
   if((logstatus=="true")&&(loguser!=null)&&(logadminid!=null)){
         seterror1("");
         seterror2("");
         seterror3("");
         seterror4("");
         seterror5("");
         seterror6("");
         seterror7("");
         seterror8("");
         seterror9("");
         seterror10("");
         seterror11("");
         seterror12("");

         let noerror = 0;
  
         const $name = document.getElementById("name").value; if($name==""){ seterror2("Name is required !");noerror = noerror+1; }
         const $username = document.getElementById("username").value; if($username==""){ seterror3("Username is required !");noerror = noerror+1; }
         const $email = document.getElementById("email").value; if($email==""){ seterror4("Email is required !");noerror = noerror+1; }
         const $contact = document.getElementById("contact").value; if($contact==""){ seterror5("Contact is required !");noerror = noerror+1; }
         const $role = document.getElementById("role").value; if($role==""){ seterror6("Role is required !");noerror = noerror+1; }
         const $category = document.getElementById("category").value; if($category==""){ seterror7("Category is required !");noerror = noerror+1; }
         const $password = document.getElementById("password").value; if($password==""){ seterror8("Password is required !");noerror = noerror+1; }
         const $cpassword = document.getElementById("cpassword").value; if($cpassword==""){ seterror9("Confirm Password is required !");noerror = noerror+1; }
         const $team = document.getElementById("team").value; if($team==""){ seterror10("Team is required !");noerror = noerror+1; }
         const $remarks = document.getElementById("remarks").value; if($remarks==""){ seterror11("Remarks is required !");noerror = noerror+1; }

        if($password!=$cpassword){noerror = noerror+1; seterror1("Password and Confirm Password do not Match!"); }
         // const $status = document.getElementById("status").value;
  
       //No primary errors
  if(noerror==0){
         //Sending Data through axios
         axios.post("add-teammember", { 
          User:loguser,
          Logtype:logtype,
          Log_id:logadminid,
          Logstatus:"true",
          User_id:logadminid,
          Account_type:logtype,
          Operation:"create",
          Name:$name,
          Username:$username,
          Email:$email,
          Contact:$contact,
          Role:$role,
          Category:$category,
          Password:$password,
          Cpassword:$cpassword,
          Team_id:$team,
          Remarks:$remarks,
          Type:null,
          Status:"active",
          UpdateVal:null
         // ConfirmPassword:$cpassword
        
    })
    
        .then((response) => {


    
          seterror1(" ");seterror2(" ");seterror3(" ");seterror4(" ");seterror5(" ");seterror6(" ");seterror7(" ");seterror8(" ");seterror9(" ");seterror10(" ");seterror11(" ");seterror12(" ");
    
          //Validation Errors
        if(response.data.validatorerror){
          seterror2(response.data.validatorerror.Name);
          seterror3(response.data.validatorerror.Username);
          seterror4(response.data.validatorerror.Email);
          seterror5(response.data.validatorerror.Contact);
          seterror6(response.data.validatorerror.Role);
          seterror7(response.data.validatorerror.Category);
          seterror8(response.data.validatorerror.Password);
          seterror9(response.data.validatorerror.Cpassword);
          seterror10(response.data.validatorerror.Team);
          seterror11(response.data.validatorerror.Remarks);
          seterror12(response.data.validatorerror.Team);

          if(response.data.error=='101'){ seterror1("Cannot Add Team Member!"); }
          
           }
        //Invalid Entries
        if(response.data.successvar==2){
          seterror2(response.data.name_er);
          seterror3(response.data.username_er);
          seterror4(response.data.email_er);
          seterror5(response.data.contact_er);
          seterror6(response.data.role_er);
          seterror7(response.data.category_er);
          seterror8(response.data.password_er);
          seterror9(response.data.cpassword_er);
          seterror10(response.data.team_er);
          seterror11(response.data.remarks_er);
          seterror12(response.data.type_er);
          seterror1("Cannot Add Team Member!");
          
               }
      
        //success
        if(response.data.successvar==1){
        alert("New Member Added Successfully!");
        document.getElementById("addteammember-box").style.display="none";
        }
        else{
        if(response.data.successvar==0){
        seterror1("Cannot Add Team Member!");
        }
        
      }
      }    );
  
    }
    //Return Errors
    else{
      return;
    }
  
  
  }
  //User not Logedin
  else{
  
    sessionStorage.removeItem("username00");
    sessionStorage.removeItem("loginstatus00");
    sessionStorage.removeItem("logintype00");
    sessionStorage.removeItem("loginname00");
    sessionStorage.removeItem("loginadminid00");
   
    alert("Session Expired! Login Again.");
    window.location.replace("/");
  
     }
  
  
  
      }

    return(
<>

   
{/* Add Team Member Section Start */}

    <div>
    <div class="col-12" >     
             <div class="card">
             <div><button class="float-end" style={{padding:"1rem",backgroundColor:"transparent",border:"none"}} onClick={()=>{ document.getElementById("addteammember-box").style.display="none";}}><i class="mdi mdi-close-box" style={{color:"#f04f60",fontSize:"2rem"}}></i></button></div>
               <div class="card-body">
                 <h4 class="card-title">Add Team Member Details</h4>
                 <div class="form-sample">
                   <p class="card-description">New Team Member</p>
                   <small style={{fontSize:".8rem",color:"#f16083"}}>{error1}</small>       
                   <div class="row">
                     <div class="col-md-6">
                       <div class="form-group row">
                         <label class="col-sm-3 col-form-label">Member Name</label>
                         <div class="col-sm-9">
                           <input type="text" class="form-control"  id="name"  value={name} onChange={updatename} placeholder="Add Name" />
                           <small style={{fontSize:".8rem",color:"#f16083"}}>{error2}</small>       
                         </div>
                       </div>
                     </div>
                     <div class="col-md-6">
                       <div class="form-group row">
                         <label class="col-sm-3 col-form-label">Username</label>
                         <div class="col-sm-9">
                           <input type="text" class="form-control"  id="username"  value={username} onChange={updateusername} placeholder="Enter Username" />
                           <small style={{fontSize:".8rem",color:"#f16083"}}>{error3}</small>       
                         </div>
                       </div>
                     </div>
                   </div>
                   <div class="row">
                     <div class="col-md-6">
                       <div class="form-group row">
                         <label class="col-sm-3 col-form-label">Email</label>
                         <div class="col-sm-9">
                         <input type="email" class="form-control"  id="email"  value={email} onChange={updateemail} placeholder="Enter Email" />
                         <small style={{fontSize:".8rem",color:"#f16083"}}>{error4}</small>       
                         </div>
                       </div>
                     </div>
                     <div class="col-md-6">
                       <div class="form-group row">
                         <label class="col-sm-3 col-form-label">Contact</label>
                         <div class="col-sm-9">
                           <input class="form-control"  id="contact"  value={contact} onChange={updatecontact} placeholder="Enter Contact" />
                           <small style={{fontSize:".8rem",color:"#f16083"}}>{error5}</small>       
                         </div>
                       </div>
                     </div>
                   </div>
                   <div class="row">
                     <div class="col-md-6">
                       <div class="form-group row">
                         <label class="col-sm-3 col-form-label">Role</label>
                         <div class="col-sm-9">
                         <input type="text" class="form-control"  id="role"  value={role} onChange={updaterole}  placeholder="Enter Role" />
                         <small style={{fontSize:".8rem",color:"#f16083"}}>{error6}</small>       
                         </div>
                       </div>
                     </div>
                     <div class="col-md-6">
                       <div class="form-group row">
                         <label class="col-sm-3 col-form-label">Category</label>
                         <div class="col-sm-9">
                           <input class="form-control" id="category"  value={category} onChange={updatecategory}  placeholder="Enter Category" />
                           <small style={{fontSize:".8rem",color:"#f16083"}}>{error7}</small>       
                         </div>
                       </div>
                     </div>
                   </div>
                   <div class="row">
                     <div class="col-md-6">
                       <div class="form-group row">
                         <label class="col-sm-3 col-form-label">Password</label>
                         <div class="col-sm-9">
                         <input type="text" class="form-control"  id="password"  value={password} onChange={updatepassword} placeholder="Enter Password" />
                         <small style={{fontSize:".8rem",color:"#f16083"}}>{error8}</small>       
                         </div>
                       </div>
                     </div>
                     <div class="col-md-6">
                       <div class="form-group row">
                         <label class="col-sm-3 col-form-label">Confirm Password</label>
                         <div class="col-sm-9">
                           <input class="form-control"  id="cpassword"  value={cpassword} onChange={updatecpassword} placeholder="Confirm Password" />
                           <small style={{fontSize:".8rem",color:"#f16083"}}>{error9}</small>       
                         </div>
                       </div>
                     </div>
                   </div>
                   <div class="row">
                     <div class="col-md-6">
                       <div class="form-group row">
                         <label class="col-sm-3 col-form-label">Team</label>
                         <div class="col-sm-9">
                           <select class="form-control" id="team" style={{padding:"1rem"}} onChange={updateteam} >
                           <option value="0">Select Team</option>
                           {
                       data.map((curElm,ind)=>{

return(
  <>
                             <option value={curElm.Team_id}>{curElm.Name}</option>

                             </>
      )
      }
					   
			)}
                             
                           </select>
                           <small style={{fontSize:".8rem",color:"#f16083"}}>{error10}</small>       
                         </div>
                       </div>
                     </div>
                     <div class="col-md-6">
                       <div class="form-group row">
                         <label class="col-sm-3 col-form-label">Remarks</label>
                         <div class="col-sm-9">
                           <input class="form-control"  id="remarks"  value={remarks} onChange={updateremarks} placeholder="Remarks" />
                           <small style={{fontSize:".8rem",color:"#f16083"}}>{error11}</small>       
                         </div>
                       </div>
                     </div>
                   </div>
                   <button class="btn btn-block btn-lg btn-gradient-danger mt-4 mb-4" onClick={create}>Add To Team</button>
               
                 
                 </div>
               </div>
             </div>
           </div>

    
    </div>
    
{/* Add Team Member Section End */}
</>
    );
}
//}



export default Addteammember;