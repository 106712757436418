import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';


function Userlogin(){



 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");


 // Access value associated with the Username
//const User=sessionStorage.getItem("username00");

//saving default values with usestate
const state = useState();

  //user_username
  const[user_username,setuser_username]=useState();const updateuser_username = (event) => { setuser_username(event.target.value);}; const[user_username_er,setuser_username_er]=useState();
  //email
  //const[email,setemail]=useState();const updateemail = (event) => { setemail(event.target.value);};  const[email_er,setemail_er]=useState();
  //user_password
  const[user_password,setuser_password]=useState();const updateuser_password = (event) => { setuser_password(event.target.value);};  const[user_password_er,setuser_password_er]=useState();

 //ERROR VARIABLES 
 const[user_error_msg,setuser_error_msg]=useState();
    
  //Redirecting to Services Portal if LoggedIn or Sessions Set
  useEffect(() => { if((logstatus=="true")&&(logid>0)&&(token!=" ")){       window.location.replace("/businesstools-businesses");   } });



  /***************** USER LOGIN FUNCTION START  ******************/
  function userlogin(){


  //Setting Error Variables
  setuser_username_er(" ");
  setuser_password_er(" ");
  setuser_error_msg(" ");


  //Fetching Form Data
    let noerror = 0;
    const $user_username = document.getElementById("user_username").value; if($user_username==""){ setuser_username_er("Username is required !");noerror = noerror+1; }//Fetching Username Value
   // if($user_username==""){const $email = document.getElementById("email").value; if($email==""){ seterror2("Email is required !");noerror = noerror+1; }}//Fetching Email Value
    const $user_password = document.getElementById("user_password").value; if($user_password==""){ setuser_password_er("Password is required !");noerror = noerror+1; }//Fetching Password Value
   
  //No primary errors
if(noerror==0){
    //Sending Data through axios
    axios.post("bt-user-login", { 
     Username:$user_username,
     Password:$user_password
   
})

   .then((response) => {

     

     //Validation Errors
   if(response.data.validatorerror){
     setuser_username_er(response.data.validatorerror.Username);
     setuser_password_er(response.data.validatorerror.Password);
   
   }
   //Invalid Entries
   if(response.data.error==102){ setuser_error_msg("Invalid Username or Password!");  }
   if(response.data.error==106){ setuser_error_msg("Cannot Login, Try Again!"); }

   //Success
   if(response.data.successvar==1){

    //Setting Sessions 
    sessionStorage.setItem("logId00", response.data.log_id); //Assign value to log_id 
    sessionStorage.setItem("logToken00", response.data.token); //Assign value to token
    sessionStorage.setItem("logStatus00", "true"); //Assign value to logstatus
    sessionStorage.setItem("logType00", "user"); //Assign value to logtype
    sessionStorage.setItem("logName00", response.data.name); //Assign value to token

   //alert("User Logged In Successfully!");
   //  window.location.replace("/candidatedetails");
   //window.location.replace("/businesstools-startworking");
  // document.getElementById("success-card").style.display="block";
  window.location.replace("/businesstools-businesses");  
   
   }
   //failure
   else{
   setuser_error_msg("Invalid Username or Password!");
   }
 }    );

}
//Return Errors
else{
 return;
}

}
  /***************** USER LOGIN FUNCTION END  ******************/




return(
    <>
       <div class="card">
                  <div class="card-body">
                {/*}  <div style={{backgroundColor:"#de6707",textAlign:"center",borderRadius:"5px"}}>
                    <h2 class="card-title" style={{color:"#ffffff",padding:"1rem",fontSize:"1.2rem"}}>User Login Form</h2>
                    </div> */}
                    <div style={{borderBottom:"0.2rem solid #de6707",textAlign:"center",borderRadius:"5px"}}>
                    <h2 class="card-title" style={{padding:"1rem",fontSize:"1.2rem"}}>Business Tools Team Login</h2>
                    </div>
                    <p  style={{textAlign:"center"}}><code>{user_error_msg}</code></p>
                    <div class="forms-sample">
                      <div class="form-group">
                        <label for="exampleInputUsername1">Username</label>
                        <input type="text" class="form-control" placeholder="Username" id="user_username" value={user_username} onChange={updateuser_username}   />
                        <code>{user_username_er}</code>
                      </div>
                      <div class="form-group">
                        <label for="exampleInputPassword1">Password</label>
                        <input type="password" class="form-control" placeholder="Password" id="user_password" value={user_password} onChange={updateuser_password}  />
                      <code>{user_password_er}</code>
                      </div>
                      <div class="form-check form-check-flat form-check-primary">
                    {/*}    <label class="form-check-label">
                          <input type="checkbox" class="form-check-input" /> Remember me </label> */}
                      </div>
                      <button type="submit" class="btn-lg btn-warning me-2" onClick={userlogin}> Login </button>
                      <br/>
                      
                    </div>
                  </div>
                  <button class="btn btn-light" onClick={()=>{ window.location.replace("/businesstools-login"); }}><i class="mdi mdi-account-circle menu-icon" style={{fontSize:"1.5rem"}}></i> Login as Admin</button>
                </div>
    </>
);

}

export default Userlogin;

