import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../axios.js';
import Navbar from './navbar.js';
import Sidebar from './sidebar.js';
import moment from 'moment/moment';



/*import Amanbg from './amanbg';*/


function Adminaddnewtask(){

     

      // Setting Values fom Session Storage
      const userlogstatus=sessionStorage.getItem("loginstatus00");//loginstatus
      const userloguser=sessionStorage.getItem("username00");//username
      const userlogtype=sessionStorage.getItem("logintype00");//logintype
      const userlogname=sessionStorage.getItem("loginname00");//loginname
      const userlogadminid=sessionStorage.getItem("loginadminid00");//loginadminid
      const load_val=sessionStorage.getItem("editId00");//load_val
      //const logteamid=sessionStorage.getItem("loginteamid00");//loginteamid
  
      //Sessions Not Set
   {/*}  if(((userlogstatus=="false")||(userlogstatus==null))&&(userloguser==null)&&(userlogadminid==null)){
  
      sessionStorage.removeItem("username00");
      sessionStorage.removeItem("loginstatus00");
      sessionStorage.removeItem("logintype00");
      sessionStorage.removeItem("loginname00");
      sessionStorage.removeItem("loginadminid00");
     
      alert("Session Expired! Login Again.");
      window.location.replace("/");
    
    }
    //Sessions Set
    else{
    */}

        

  //Setting Variables
  const[due_date,setdue_date]=useState();const updatedue_date = (event) => { setdue_date(event.target.value);};    const[due_date_er,setdue_date_er]=useState();//due_date
  const[title,settitle]=useState();const updatetitle = (event) => { settitle(event.target.value);};    const[title_er,settitle_er]=useState();//title
  const[associated_function,setassociated_function]=useState();const updateassociated_function = (event) => { setassociated_function(event.target.value);};    const[associated_function_er,setassociated_function_er]=useState();//associated_function
  const[function_name,setfunction_name]=useState();const updatefunction_name = (event) => { setfunction_name(event.target.value);};    const[function_name_er,setfunction_name_er]=useState();//function_name
  const[pending_from,setpending_from]=useState();const updatepending_from = (event) => { setpending_from(event.target.value);};    const[pending_from_er,setpending_from_er]=useState();//pending_from
  const[status,setstatus]=useState();const updatestatus = (event) => { setstatus(event.target.value);};    const[status_er,setstatus_er]=useState();//status
  const[description,setdescription]=useState();const updatedescription = (event) => { setdescription(event.target.value);};    const[description_er,setdescription_er]=useState();//description

  
    //ERROR VARIABLES 
    const[error,seterror]=useState();
    //const[error1,seterror1]=useState();const[error2,seterror2]=useState();const[error3,seterror3]=useState();const[error4,seterror4]=useState();const[error5,seterror5]=useState();const[error6,seterror6]=useState();const[error7,seterror7]=useState();



    function create(){

         //Checking if user loggedin
 if((userlogstatus=="true")&&(userloguser!=null)&&(userlogadminid!=null)&&(userlogtype=="admin")){

     //  seterror1("");seterror2("");seterror3("");seterror4("");seterror5("");seterror6("");seterror7("");
     
     //Setting Error Variables
     setdue_date_er();setassociated_function_er();setfunction_name_er();setpending_from_er();settitle_er();setstatus_er();setdescription_er();

       let noerror = 0;

     //No primary errors
if(noerror==0){
       //Sending Data through axios
       axios.post("create-new-project-task", { 
        User:userloguser,
        Logtype:userlogtype,
        Log_id:userlogadminid,
        Logstatus:"true",
        User_id:userlogadminid,
        Account_type:userlogtype,
        Project_id:load_val,
        Associated_function:associated_function,
        Function_name:function_name,
        Operation:"create",
        Title:title,
        Description:description,
        Due_date:moment(due_date).format('MM-DD-YYYY'),
        Status:status,
        Pending_from:pending_from
      
  })
  
      .then((response) => {
  
        //seterror1(" ");seterror2(" ");seterror3(" ");seterror4(" ");seterror5(" ");seterror6(" ");seterror7(" ");
      
          //Setting Error Variables
          setdue_date_er(" ");setassociated_function_er(" ");setfunction_name_er(" ");setpending_from_er(" ");settitle_er(" ");setstatus_er(" ");setdescription_er(" ");

        //Validation Errors
      if(response.data.validatorerror){
        setdue_date_er(response.data.validatorerror.Client_id);
        setassociated_function_er(response.data.validatorerror.Client_name);
        setfunction_name_er(response.data.validatorerror.Member_id);
        setpending_from_er(response.data.validatorerror.Member_name);
        settitle_er(response.data.validatorerror.Title);
        setdescription_er(response.data.validatorerror.Description);
        setstatus_er(response.data.validatorerror.Status);
     
        if(response.data.error=='101'){ seterror("Cannot Create New Task!"); }
        
         }
      //Invalid Entries
      if(response.data.successvar==2){
        setdue_date_er(response.data.due_date_er);
        setassociated_function_er(response.data.associated_function_er);
        setfunction_name_er(response.data.function_name_er);
        setpending_from_er(response.data.pending_from_er);
        settitle_er(response.data.title_er);
        setdescription_er(response.data.description_er);
        setstatus_er(response.data.status_er);
        seterror("Cannot Create New Task!");
        
             }
    
      //success
      if(response.data.successvar==1){
      alert("New Task Created Successfully!");
      }
      else{
      if(response.data.successvar==0){
      seterror(response.data.error);
      }
      
    }
    }    );

  }
  //Return Errors
  else{
    return;
  }


}
//User not Logedin
else{

  sessionStorage.removeItem("username00");
  sessionStorage.removeItem("loginstatus00");
  sessionStorage.removeItem("logintype00");
  sessionStorage.removeItem("loginname00");
  sessionStorage.removeItem("loginadminid00");
 
  alert("Session Expired! Login Again.");
  window.location.replace("/");

   }



    }




    return(
<>
{/*<!DOCTYPE html> */}
<html lang="en">
  <head>
    {/* Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>theStartupConnect | Portal</title>
    {/* plugins:css */}
    <link rel="stylesheet" href="./assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="./assets/vendors/css/vendor.bundle.base.css" />
    {/* endinject */}
    {/* Plugin css for this page */}
    {/* End plugin css for this page */}
    {/* inject:css */}
    {/* endinject */}
    {/* Layout styles */}
    <link rel="stylesheet" href="./assets/css/style.css" />
    {/* End layout styles */}
   {/*} <link rel="shortcut icon" href="./assets/images/favicon.ico" /> */}
  </head>
  <body>

<Navbar/>

{/* MAIN PAGE COMPONENT START */}
 <div class="container-fluid page-body-wrapper">

 <Sidebar/>


{/* MAIN PANEL START */}
<div class="main-panel" style={{marginTop:"5vh"}}>
<div class="content-wrapper">

<h2>Create New Task</h2>

<div>
       <div class="col-12" >     
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Add Task Details</h4>
                    <div class="form-sample">
                      <p class="card-description">Create New Task </p>
                      <small style={{fontSize:".8rem",color:"#f16083"}}>{error}</small>    
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label" >Due Date</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" id="due_date" value={due_date} onChange={updatedue_date} placeholder="Due Date" />
                              <small style={{fontSize:".8rem",color:"#f16083"}}>{due_date_er}</small> 
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Title</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="title"  value={title} onChange={updatetitle} placeholder="Task Title" />
                              <small style={{fontSize:".8rem",color:"#f16083"}}>{title_er}</small> 
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Associated Function(link)</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" id="associated_function"  value={associated_function} onChange={updateassociated_function} placeholder="https://googlefroms.com/------" />
                            <small style={{fontSize:".8rem",color:"#f16083"}}>{associated_function_er}</small> 
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Function Name</label>
                            <div class="col-sm-9">
                              <input class="form-control"  id="function_name"  placeholder="Complete Form" value={function_name} onChange={updatefunction_name} />
                              <small style={{fontSize:".8rem",color:"#f16083"}}>{function_name_er}</small> 
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Pending From</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="To be  Done by" id="pending_from"  value={pending_from} onChange={updatepending_from} />
                            <small style={{fontSize:".8rem",color:"#f16083"}}>{pending_from_er}</small> 
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Status</label>
                            <div class="col-sm-9">
                              <select class="form-control" style={{padding:"1rem"}} placeholder="Status" id="status"  value={status} onChange={updatestatus}>
                              <option value="Pending">Pending</option>
                              <option value="Completed">Completed</option>
                              <option value="In Progress">In Progress</option>
                              </select>
                              <small style={{fontSize:".8rem",color:"#f16083"}}>{status_er}</small> 
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group row">
                            <label class="col-sm-2 col-form-label">Description</label>
                            <div class="col-sm-10">
                            <input type="text" class="form-control" placeholder="Type your task description here" id="description"  value={description} onChange={updatedescription}  />
                            <small style={{fontSize:".8rem",color:"#f16083"}}>{description_er}</small> 
                            </div>
                          </div>
                        </div>
                      </div>
                      <button class="btn btn-block btn-lg btn-gradient-info mt-4 mb-4" onClick={create}>Create Task</button>
                  
                    
                    </div>
                  </div>
                </div>
              </div>

       
       </div>
       </div>
    </div>
{/* MAIN PANEL END */}

</div>
{/* MAIN PAGE COMPONENT END */}

    {/* container-scroller */}
    {/* plugins:js */}
    <script src="./assets/vendors/js/vendor.bundle.base.js"></script>
    {/* endinject */}
    {/* Plugin js for this page */}
    {/* End plugin js for this page */}
    {/* inject:js */}
    <script src="./assets/js/off-canvas.js"></script>
    <script src="./assets/js/hoverable-collapse.js"></script>
    <script src="./assets/js/misc.js"></script>

    {/*} plugins:js */}
    <script src="assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="assets/vendors/chart.js/Chart.min.js"></script>
    <script src="assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="assets/js/off-canvas.js"></script>
    <script src="assets/js/hoverable-collapse.js"></script>
    <script src="assets/js/misc.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="assets/js/dashboard.js"></script>
    <script src="assets/js/todolist.js"></script>
    {/*} End custom js for this page */}
    {/* endinject */}
  </body>
</html>
            
{/* Create Team Section End */}
</>
    );
}

//}

export default Adminaddnewtask;