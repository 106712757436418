import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';
import Navbar from '../Extracomponents/navbar.js';
import Sidebar from '../Extracomponents/sidebar.js';
//import './customcss.css';

//import Addnewtask from './addnewtask.js';
//import './assets/css/custom.css';

/*import Amanbg from './amanbg';*/


function Clientprojectdashboard(){


   // Setting Values fom Session Storage
   const logstatus=sessionStorage.getItem("userloginstatus00");//loginstatus
   const loguser=sessionStorage.getItem("userusername00");//username
   const logtype=sessionStorage.getItem("userlogintype00");//logintype
   const logname=sessionStorage.getItem("userloginname00");//loginname
   const logadminid=sessionStorage.getItem("userloginadminid00");//loginadminid
   const logteamid=sessionStorage.getItem("userloginteamid00");//loginteamid
    const load_val=sessionStorage.getItem("editId00");//load_val
    //const logteamid=sessionStorage.getItem("loginteamid00");//loginteamid

    //Sessions Not Set
  {/*} if(((logstatus=="false")||(logstatus==null))&&(loguser==null)&&(logadminid==null)){

    sessionStorage.removeItem("username00");
    sessionStorage.removeItem("loginstatus00");
    sessionStorage.removeItem("logintype00");
    sessionStorage.removeItem("loginname00");
    sessionStorage.removeItem("loginadminid00");
   
    alert("Session Expired! Login Again.");
    window.location.replace("/");
  
  }
  //Sessions Set
  else{

  */}

    


  //Setting Variables
  const[project_id,setproject_id]=useState();//project_id
  const[client_id,setclient_id]=useState();//client_id
  const[client_name,setclient_name]=useState();//client_name
  const[member_id,setmember_id]=useState();//member_id
  const[member_name,setmember_name]=useState();//member_name
  const[team_id,setteam_id]=useState();//team_id
  const[start_date,setstart_date]=useState();//start_date
  const[end_date,setend_date]=useState();//end_date
  const[order_no,setorder_no]=useState();//order_no
  const[order_id,setorder_id]=useState();//order_id
  const[title,settitle]=useState();//title
  const[status,setstatus]=useState();//status
  const[projectstatus,setprojectstatus]=useState();//projectstatus
  const[description,setdescription]=useState();//description
  const[progress,setprogress]=useState();//progress
  const[team_type,setteam_type]=useState();//team_type


  const[Data,setData]=useState([], );//Data
  
    //ERROR VARIABLES 
    const[error,seterror]=useState();


    
  //Use Effect, Data Load
  useEffect(()=>{

    defaultload();

},[])

  //DEFAULT LOAD
    function defaultload(){

      //Checking if user loggedin
if((logstatus=="true")&&(loguser!=null)&&(logadminid!=null)){

  
    //Sending Data through axios
    axios.post("get-projectdetails-byid", { 
     User:loguser,
     Logtype:logtype,
     Log_id:logadminid,
     Logstatus:"true",
     User_id:logadminid,
     Account_type:logtype,
     Load_val:load_val,
     Operation:"get",
     
})

   .then((response) => {

   
   //success
   if(response.data.successvar==1){
   
    //Updating Values
    setproject_id(response.data.project_id);
    setclient_id(response.data.client_id);
    setclient_name(response.data.client_name);
    setmember_id(response.data.member_id);
    setmember_name(response.data.member_name);
    setteam_id(response.data.team_id);
    setstart_date(response.data.start_date);
    setorder_no(response.data.order_no);
    setorder_id(response.data.order_id);
    setend_date(response.data.end_date);
    settitle(response.data.title);
    setdescription(response.data.description);
    setstatus(response.data.status);
    setprojectstatus(response.data.project_status);
    setprogress(response.data.progress);
    setData(response.data.project_tasks);
    setteam_type(response.data.team_type)
   }
   else{
  alert("Cannot Fetch Data!");
  window.location.replace("/projects_list")
   
 }
 }    );

}

//User not Logedin
else{

sessionStorage.removeItem("username00");
sessionStorage.removeItem("loginstatus00");
sessionStorage.removeItem("logintype00");
sessionStorage.removeItem("loginname00");
sessionStorage.removeItem("loginadminid00");

alert("Session Expired! Login Again.");
window.location.replace("/");

}



 }









     
    return(
<>
{/*<!DOCTYPE html> */}
<html lang="en">
  <head>
    {/* Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>theStartupConnect | Portal</title>
    {/* plugins:css */}
    <link rel="stylesheet" href="./assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="./assets/vendors/css/vendor.bundle.base.css" />
    {/* endinject */}
    {/* Plugin css for this page */}
    {/* End plugin css for this page */}
    {/* inject:css */}
    {/* endinject */}
    {/* Layout styles */}
    <link rel="stylesheet" href="./assets/css/style.css" />
    {/* End layout styles */}
   {/*} <link rel="shortcut icon" href="./assets/images/favicon.ico" /> */}
  </head>
  <body>



<Navbar/>

{/* MAIN PAGE COMPONENT START */}
 <div class="container-fluid page-body-wrapper">

 <Sidebar/>


{/* MAIN PANEL START */}
<div class="main-panel" style={{marginTop:"5vh"}}>


{/* ADD TASK SECTION START */}
<div class="addtask-box" id="addtask-box"  style={{/*position:"fixed",marginLeft:"5%",marginTop:"0",width:"70%",backgroundColor:"#fdffff",display:"none",zIndex:"3",boxShadow:"0.1rem 0.1rem 0.1rem 0.1rem #c7d0ce"*/}}>
{/*<Addnewtask/>
*/}
</div>
{/* ADD TASK SECTION END */}
<div class="content-wrapper">

     {/*}    <Topanalytics/> */}

{/*<Pageheader/>
<Topanalytics/>*/}
<div class="col-lg-12 grid-margin stretch-card" >
                
                  <div class="card-body">
                    <h2 class="card-title">project Dashboard</h2>
<div class="card">

<div class="card-body" style={{textAlign:"left"}}>
<div class="card-group">
<div class="col-lg-4 grid-margin" >
<h4 class="card-title">Project Id -  <span style={{color:"#989aa2"}}>{project_id}</span></h4>
{/*<h4 class="card-title">Category -  <span style={{color:"#989aa2"}}>Business Registration</span></h4>*/}
<h4 class="card-title">Status -   <span class={projectstatus === "Pending"? "badge badge-danger"
                                             : projectstatus === "Completed"? "badge badge-success"
                                             : "badge badge-warning"}>{projectstatus}</span></h4>

</div>
<div class="col-lg-4 grid-margin" >
<h4 class="card-title">Project Title -  <span style={{color:"#989aa2"}}>{title}</span></h4>
{/*<h4 class="card-title">project Id -  <span style={{color:"#989aa2"}}>5001</span></h4>*/}
</div>
<div class="col-lg-4 grid-margin" >
<h4 class="card-title">Start Date-  <span style={{color:"#989aa2"}}>{start_date}</span></h4>
<h4 class="card-title">End Date -  <span style={{color:"#989aa2"}}>{end_date}</span></h4>

<h4 class="card-title">Progress    <div class="progress" style={{marginTop:"1rem"}}>
                             
{progress < 25 &&
                  <div class="progress-bar-bg-danger" role="progressbar" style={{width: `${progress}%`}} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>

                }
                {progress >= 25 && progress <= 50 &&
                  <div class="progress-bar bg-warning" role="progressbar" style={{width: `${progress}%`}} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>

                }
                {progress >= 51 && progress <= 80 &&
                  <div class="progress-bar bg-info" role="progressbar" style={{width: `${progress}%`}} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
                 
                }
                {progress > 80 &&
                  <div class="progress-bar bg-success" role="progressbar" style={{width: `${progress}%`}} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>

                }
                             
                     {/*}         <div class="progress-bar bg-success" role="progressbar" style={{width: "25%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                          */}  </div></h4>
</div>
</div>
<h4 class="card-title">Description -  <span style={{color:"#989aa2",padding:"0.5rem"}}>{description}</span></h4>

</div>

</div>
 {/*<button class="btn btn-block btn-lg btn mt-4" style={{backgroundColor:"#0e99b0",color:"#ffffff"}} onClick={()=>{
          window.location.replace("/admin-create-task");
          //document.getElementById("addtask-box").style.display="block";
         }}>+ Create New Task</button> */}
<br/><br/><br/>


               {/*   <div /*class="card-group custom-task-cards-group" /*style={{ display:"block", maxWidth:"100%", textAlign:"center", margin:"0 auto"}} > */

               }
                  {
                       Data.map((curElm,ind)=>{

                        const isButtonHidden = !curElm.Function_name;


return(
  <>
                    {/* TASK CARD START */}
  <div class="card mb-4" /*custom-task-card" /*style={{marginBottom:"1rem", display:"inline-block", width:"45%", margin:"0 25px 25px"}}*/ key={curElm.Task_id}>

    <div class="card-body" >
    <div class="card-group">
    <div class="col-lg-3 grid-margin" ><h4 style={{textAlign:"left"}}>Task Id :<span >{curElm.Task_id}</span></h4></div>
    <div class="col-lg-9 grid-margin" ><h2 style={{textAlign:"center"}}>{curElm.Title}</h2></div>
    <div style={{backgroundColor:"#989aa2",height:"0.1rem",width:"100%",marginTop:"0"}}></div>
    </div>
    <div class="col-lg-12 grid-margin mt-2" ><label style={{textAlign:"center"}}><b>Description:</b></label></div>
      <p class="card-description">{curElm.Description}</p>
      <a href={curElm.Associated_function} hidden={isButtonHidden}  ><button type="button" class="btn btn-gradient-info" >{curElm.Function_name}</button></a>
    </div>
    <div class="card-group">
    <div class="col-lg-4 grid-margin" ><label ><b>Pending from:</b></label><p>{curElm.Pending_from}</p></div>
    <div class="col-lg-4 grid-margin" ><label ><b>Status:</b></label><br/>
    <span style={{marginTop:"1rem"}} class={curElm.Task_status === "Pending"? "badge badge-danger"
                                             : curElm.Task_status === "Completed"? "badge badge-success"
                                             : "badge badge-warning"}>{curElm.Task_status}</span></div>
    <div class="col-lg-3 grid-margin" ><label ><b>Due Date:</b></label><p>{curElm.Due_date}</p></div>
    <div class="col-lg-1 grid-margin" hidden={(team_type != "operations")&&(team_type != "admin")}>
       {/* Edit Button Start 
    <button class="float-end" style={{backgroundColor:"transparent",border:"none"}} onClick={()=>{
                              //Redirecting to Edit
                             
                             sessionStorage.setItem("editId00",curElm.Project_id);
                            window.location.replace("/admin-edit-project");

                            }}><i class="mdi mdi-pencil-box" style={{color:"#dbc821",fontSize:"2rem"}}></i></button>

    {/* Edit Button End 
        {/* Delete Button Start
        <button class="float-end" style={{backgroundColor:"transparent",border:"none"}} onClick={()=>{
                              //Redirecting to Edit
                             
                             sessionStorage.setItem("editId00",curElm.Project_id);
                            window.location.replace("/admin-edit-project");

                            }}><i class="mdi mdi-close-box" style={{color:"#da4c4c",fontSize:"2rem"}}></i></button>

    {/* Delete Button End */}</div>
      </div>
    <div class="card-footer">
      <small class="text-muted">Last updated on {curElm.Date_updated} at {curElm.Time_updated} by {curElm.Updated_by}, {/*(Client Id: 90988)*/}</small>
    </div>
  </div>
 {/* TASK CARD END */}
 </>
      )
      }
					   
			)}
    
   {/*} </div>*/}
    </div>
    </div>
    </div>
    </div>

{/* MAIN PANEL END */}

</div>
{/* MAIN PAGE COMPONENT END */}

    {/* container-scroller */}
    {/* plugins:js */}
    <script src="./assets/vendors/js/vendor.bundle.base.js"></script>
    {/* endinject */}
    {/* Plugin js for this page */}
    {/* End plugin js for this page */}
    {/* inject:js */}
    <script src="./assets/js/off-canvas.js"></script>
    <script src="./assets/js/hoverable-collapse.js"></script>
    <script src="./assets/js/misc.js"></script>

    {/*} plugins:js */}
    <script src="assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="assets/vendors/chart.js/Chart.min.js"></script>
    <script src="assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="assets/js/off-canvas.js"></script>
    <script src="assets/js/hoverable-collapse.js"></script>
    <script src="assets/js/misc.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="assets/js/dashboard.js"></script>
    <script src="assets/js/todolist.js"></script>
    {/*} End custom js for this page */}
    {/* endinject */}
  </body>
</html>
</>
    );
}
//}

export default Clientprojectdashboard;