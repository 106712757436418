import React from 'react';
import {useState,useEffect,Component} from 'react';
//import axios from './axios';
import '../homeh.css';

import './navbar3.css';

function Navbar3(){

return(
<>
 



{/* NAVBAR START */}
{/* DEFAULT NAVBAR START */}
<div class="home2-navbar" id="home2-navbar-default">
<div style={{display:"flex",flexDirection:"row", justifyContent:"space-between"}}>
<div>
<ul style={{display:"flex"}}>
<li><img src="/home1/weblogo1.png" id="home2-navbar-logo" ></img></li>
<li class="lg-item" style={{ fontFamily: "Open Sans, sans-serif", fontWeight: "bold"}}><span style={{color:"#5d17eb"}}>Startup </span>Connect</li>
</ul>
</div>
<div>
   <ul class="li-item" style={{display:"flex",flexDirection:"row"}}>
   <li id="nav-select"><a href="/" style={{textDecoration:"none",color:"#000000"}}>Home</a></li>
   <li id="nav-select"><a href="/services-home" style={{textDecoration:"none",color:"#000000"}}>Services</a></li>
       <li id="nav-select"><a href="/book-keeping" style={{textDecoration:"none",color:"#000000"}}>Book-keeping</a></li>
       <li id="nav-select"><a href="/startups-network" style={{textDecoration:"none",color:"#000000"}}>Startups</a></li>
     {/*}  <li id="nav-select"><a href="/about-us" style={{textDecoration:"none",color:"#000000"}}>About Us</a></li> */}
       <li id="nav-select"><a href="https://www.startuphire.in" style={{textDecoration:"none",color:"#000000"}}>Hiring</a></li>
       <li id="nav-select"><a href="/contest-home" style={{textDecoration:"none",color:"#000000"}}>Contests</a></li>
       <li id="nav-select"><a href="/about-us" style={{textDecoration:"none",color:"#000000"}}>About Us</a></li>
       <li id="nav-select"><a href="/login" style={{textDecoration:"none",color:"#000000"}}><button id="home2-navbar-login">Login</button></a></li>
       <li id="nav-select"><a href="/businesstools-admin-register" style={{textDecoration:"none",color:"#000000"}}><button id="home2-navbar-register">Register</button></a></li>
   </ul>
</div>
</div>
</div>
{/* DEFAULT NAVBAR END */}

{/* DEFAULT NAVBAR START */}
<div class="home2-navbar" id="home2-navbar-changed">
<div style={{display:"flex",flexDirection:"row", justifyContent:"space-between"}}>

<div>
<ul style={{display:"flex", padding:"0"}}>
<li><img src="/home1/weblogo1.png" id="home2-navbar-logo" style={{marginLeft:"0"}} ></img></li>
<li class="text-logo-resp" /* style={{ fontFamily: "Open Sans, sans-serif", fontWeight: "bold",fontSize:"1.4rem", marginLeft:"0"}} */><span style={{color:"#5d17eb"}}>Startup </span>Connect</li>
</ul>
</div>

<div>

</div>
<div>
  <img src="https://cdn-icons-png.flaticon.com/128/9109/9109758.png" style={{width:"3rem",height:"3rem"}} onClick={()=>{   const createForm = document.getElementById('home2-display-menu');
          const currentDisplay = createForm.style.display;
          createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }} />
</div>
</div>

<div id="home2-display-menu">
<div style={{display:"flex",flexDirection:"row", justifyContent:"space-between"}}>

<div>
   <ul>
       <li id="nav-select"><a href="/" style={{textDecoration:"none",color:"#000000"}}>Home</a></li>
       <li id="nav-select"><a href="/services-home" style={{textDecoration:"none",color:"#000000"}}>Services</a></li>
       <li id="nav-select"><a href="/book-keeping" style={{textDecoration:"none",color:"#000000"}}>Book-keeping</a></li>
       <li id="nav-select"><a href="/startups-network" style={{textDecoration:"none",color:"#000000"}}>Startups</a></li>
     {/*}  <li id="nav-select"><a href="/about-us" style={{textDecoration:"none",color:"#000000"}}>About Us</a></li> */}
       <li id="nav-select"><a href="https://www.startuphire.in" style={{textDecoration:"none",color:"#000000"}}>Hiring</a></li>
       <li id="nav-select"><a href="/contest-home" style={{textDecoration:"none",color:"#000000"}}>Contests</a></li>
       <li id="nav-select"><a href="/about-us" style={{textDecoration:"none",color:"#000000"}}>About Us</a></li>
       <li id="nav-select"><a href="/login" style={{textDecoration:"none",color:"#000000"}}><button id="home2-navbar-login">Login</button></a></li>
       <li id="nav-select"><a href="/businesstools-admin-register" style={{textDecoration:"none",color:"#000000"}}><button id="home2-navbar-register">Register</button></a></li>

   </ul>
</div>
</div>
</div>
</div>
{/* DEFAULT NAVBAR END */}



{/* NAVBAR END */}
</>
);


}


export default Navbar3;