import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';

import moment from 'moment/moment';

import '../Resources/dashboard.css';


function Create_salary(){


  
 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const busyid=sessionStorage.getItem("BusyId00");
 const counterid=sessionStorage.getItem("CounterId00");


 
//saving default values with usestate
const state = useState();



//Variables
const[Tbanks,setTbanks] = useState([]);//SetData Variable
const[employees,setemployees] = useState([],);//SetData Variable
const[cdefault,setcdefault] = useState(0);

//Error Variable
const[errorvar,seterrorvar] = useState();


useEffect(()=>{
   
    getData();
    
    },[])
   



//******************* GET BANKS DATA FUNCTION START  ********************//
function getData(){
 

    //Checking if user loggedin
    if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))){
   
   
   axios.post("bt-get-banks", {
     Log_id:logid,
     Token:token,
     Busy_id:busyid,
     Counter_id:counterid,
     Sn_start :0,
     Sn_end :0,
     Sn_total :0,
     Sn_limit :0
   })
   .then((response) => {
  
    //Invalid Session
    if((response.data.error)==100){ 
        //Remove Session Variables and redirect to login
       sessionStorage.removeItem("logStatus00");
       sessionStorage.removeItem("logId00");
       sessionStorage.removeItem("logToken00");
       sessionStorage.removeItem("BusyId00");
       sessionStorage.removeItem("CounterId00");
  
       window.location.replace("businesstools-login");
    }
    //Success Data Fetched
    if(response.data.successvar==1){
     //Setting Data
     setTbanks(response.data.data);
    
    }
    //Cannot Fetch Data
    else{
     seterrorvar("Cannot Fetch Data !");
    }
   
   
   });
   
   }
   //User not Logedin
   else{
   
   //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");
  
     window.location.replace("businesstools-login");
   
    }
   
   }
    
   //******************* GET BANKS DATA FUNCTION END  ********************//
  
   
  
//******************* GET EMPLOYEE DATA BY NAME FUNCTION START  ********************//
function getemployeebyname(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))){
 
     const cname = document.getElementById("first_name").value;
     const cnlength = cname.length;
     if((cdefault==0)&&(cnlength>=3)){
     
 
 axios.post("bt-get-employee-details-by-name", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Name:document.getElementById("first_name").value,
   Sn_start :0,
   Sn_end :0,
   Sn_total :0,
   Sn_limit :0
 })
 .then((response) => {


  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setemployees(response.data.employeelist);
   document.getElementById("employees-list-box").style.display="block";
  
  }
  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
}
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }

 }
  
 //******************* GET EMPLOYEE DATA BY NAME FUNCTION END  ********************//

 



 //SETTING DATA VARIABLES
 const[employee_id,setemployee_id]=useState();const updateemployee_id = (event) => { setemployee_id(event.target.value);}; const[employee_id_er,setemployee_id_er]=useState();
 const[employee_code,setemployee_code]=useState();const updateemployee_code = (event) => { setemployee_code(event.target.value);}; const[employee_code_er,setemployee_code_er]=useState();
 const[first_name,setfirst_name]=useState();const updatefirst_name = (event) => { setfirst_name(event.target.value); getemployeebyname();}; const[first_name_er,setfirst_name_er]=useState();
 const[last_name,setlast_name]=useState();const updatelast_name = (event) => { setlast_name(event.target.value);}; const[last_name_er,setlast_name_er]=useState();
 const[email,setemail]=useState();const updateemail = (event) => { setemail(event.target.value);}; const[email_er,setemail_er]=useState();
 const[contact,setcontact]=useState();const updatecontact = (event) => { setcontact(event.target.value);}; const[contact_er,setcontact_er]=useState();
 const[date,setdate]=useState();const updatedate = (event) => { setdate(event.target.value);}; const[date_er,setdate_er]=useState();
 const[type,settype]=useState();const updatetype = (event) => { settype(event.target.value);}; const[type_er,settype_er]=useState();
 const[basic_salary,setbasic_salary]=useState();const updatebasic_salary = (event) => { setbasic_salary(event.target.value);}; const[basic_salary_er,setbasic_salary_er]=useState();
 const[incentives,setincentives]=useState();const updateincentives = (event) => { setincentives(event.target.value);}; const[incentives_er,setincentives_er]=useState();
 const[allowances,setallowances]=useState();const updateallowances = (event) => { setallowances(event.target.value);}; const[allowances_er,setallowances_er]=useState();
 const[provident_fund,setprovident_fund]=useState();const updateprovident_fund = (event) => { setprovident_fund(event.target.value);}; const[provident_fund_er,setprovident_fund_er]=useState();
 const[salary_deductions,setsalary_deductions]=useState();const updatesalary_deductions = (event) => { setsalary_deductions(event.target.value);}; const[salary_deductions_er,setsalary_deductions_er]=useState();
 const[amount,setamount]=useState();const updateamount = (event) => { setamount(event.target.value);}; const[amount_er,setamount_er]=useState();
 const[mode,setmode]=useState();const updatemode = (event) => { setmode(event.target.value);}; const[mode_er,setmode_er]=useState();
 const[bank_id,setbank_id]=useState();const updatebank_id = (event) => { setbank_id(event.target.value);}; const[bank_id_er,setbank_id_er]=useState();
 const[bank_name,setbank_name]=useState();const updatebank_name = (event) => { setbank_name(event.target.value);}; const[bank_name_er,setbank_name_er]=useState();
 const[salary_status,setsalary_status]=useState();const updatesalary_status = (event) => { setsalary_status(event.target.value);}; const[salary_status_er,setsalary_status_er]=useState();
 const[salary_remarks,setsalary_remarks]=useState();const updatesalary_remarks = (event) => { setsalary_remarks(event.target.value);}; const[salary_remarks_er,setsalary_remarks_er]=useState();


 
//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();




  
//******************* CREATE SALARY FUNCTION START ********************//
function addsalary(){

  //Setting Error Variables
  setemployee_id_er(" ");setemployee_code_er(" ");setfirst_name_er(" ");setlast_name_er(" ");setemail_er(" ");setcontact_er(" ");setdate_er(" ");setmode_er(" ");setbank_id_er(" ");settype_er(" ");
  setbasic_salary_er(" ");setincentives_er(" ");setallowances_er(" ");setprovident_fund_er(" ");setsalary_deductions_er(" ");setamount_er(" ");setsalary_status_er(" ");setsalary_remarks_er(" ");seterror_msg(" ");


  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))){
 
 
  axios.post("bt-create-salary", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Employee_id:employee_id,
   Employee_code:employee_code,
   First_name:first_name,
   Last_name:last_name,
   Email:email,
   Contact:contact,
   Type:type,
   Date:moment(date).format('YYYY-MM-DD'),
   Mode:mode,
   Bank_id:bank_id,
   Basic_salary:basic_salary,
   Incentives:incentives,
   Allowances:allowances,
   Provident_fund:provident_fund,
   Salary_deductions:salary_deductions,
   Amount:amount,
   Salary_status:salary_status,
   Salary_remarks:salary_remarks,

  })
  .then((response) => {

      
  //Customers Limit Reached
  if(response.data.error==111){     seterror_msg(response.data.error_msg); return; }

      
 else if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
  }

   //Validation Errors
  else if(response.data.validatorerror){
    setemployee_id_er(response.data.validatorerror.Employee_id);
    setemployee_code_er(response.data.validatorerror.Employee_code);
    setfirst_name_er(response.data.validatorerror.First_name);
    setlast_name_er(response.data.validatorerror.Last_name);
    setcontact_er(response.data.validatorerror.Contact);
    setemail_er(response.data.validatorerror.Email);
    settype_er(response.data.validatorerror.Type);
    setdate_er(response.data.validatorerror.Date);
    setbasic_salary_er(response.data.validatorerror.Basic_salary);
    setincentives_er(response.data.validatorerror.Incentives);
    setallowances_er(response.data.validatorerror.Allowances);
    setprovident_fund_er(response.data.validatorerror.Provident_fund);
    setsalary_deductions_er(response.data.validatorerror.Salary_deductions);
    setamount_er(response.data.validatorerror.Amount);
    setmode_er(response.data.validatorerror.Mode);
    setbank_id_er(response.data.validatorerror.Bank_id);
    setsalary_status_er(response.data.validatorerror.Salary_status);
    setsalary_remarks_er(response.data.validatorerror.Salary_remarks);
    seterror_msg("Cannot Create Transaction !");

}
//Invalid Entries
else if(response.data.error==102){ 
  
    setemployee_id_er(response.data.employee_id_er);
    setemployee_code_er(response.data.employee_code_er);
    setfirst_name_er(response.data.first_name_er);
    setlast_name_er(response.data.last_name_er);
    setcontact_er(response.data.contact_er);
    setemail_er(response.data.email_er);
    settype_er(response.data.type_er);
    setdate_er(response.data.date_er);
    setbasic_salary_er(response.data.basic_salary_er);
    setincentives_er(response.data.incentives_er);
    setallowances_er(response.data.allowances_er);
    setprovident_fund_er(response.data.provident_fund_er);
    setsalary_deductions_er(response.data.salary_deductions_er);
    setamount_er(response.data.amount_er);
    setmode_er(response.data.mode_er);
    setbank_id_er(response.data.bank_id_er);
    setsalary_status_er(response.data.salary_status_er);
    setsalary_remarks_er(response.data.salary_remarks_er);
    seterror_msg("Cannot Create Transaction !");  }

 //Email Already Exists
 else if(response.data.error==103){  seterror_msg(response.data.error_msg); } 

   //Transaction Entered Successfully
  else if(response.data.successvar==1){  document.getElementById("success-card").style.display="block";  }
   //Cannot Add Transaction
   else{  seterror_msg("Cannot Create Transaction !"); }


  


});

}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* CREATE SALARY FUNCTION END ********************//





return(
    <>


    
                 {/* EMPLOYEES LIST BOX START */}
                 <div class="d-flex justify-content-center">
          <div class="col-md-4 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"1rem",display:"none"}} id="employees-list-box">
                <div class="card">
                <div class="d-flex justify-content-between">
                    <div>
                    <h4 style={{padding:'1rem'}}>Employees</h4>
                    </div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ setcdefault(1); document.getElementById("employees-list-box").style.display="none"; }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                    
                    <table class="table table-hover">
                      <thead>
                        <tr>
                         {/*} <th>employee Id</th> */}
                          <th>Name</th>
                         {/*} <th>Email</th> */}
                          <th>Contact</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                       employees.map((curElm6,ind)=>{

                         return(
                         <>
                        <tr key={curElm6.Id}>
                          {/* <td>{curElm6.employee_id}</td> */}
                          <td>{curElm6.First_name} {curElm6.Last_name}</td>
                         {/*} <td>{curElm6.Email}</td> */}
                          <td>{curElm6.Contact}</td>
                          <td><i class="mdi mdi-assistant text-warning" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                        //Setting employee Details for 
                        setfirst_name(curElm6.First_name); setlast_name(curElm6.Last_name);setemployee_id(curElm6.Id); setemployee_code(curElm6.Employee_code);setcontact(curElm6.Contact);setemail(curElm6.Email);
                        setbasic_salary(curElm6.Basic_salary); setincentives(curElm6.Incentives); setallowances(curElm6.Allowances); setprovident_fund(curElm6.Provident_fund); setsalary_deductions(curElm6.Salary_deductions); setamount(curElm6.Monthly_salary);
                        document.getElementById("employees-list-box").style.display="none";
                              }}></i></td>

                        </tr>
                        </>
                         )
                         }
					   
	                  		 )
                         }
                      </tbody>
                    </table>
              
                </div>
              </div>
              </div>
              {/* EMPLOYEES LIST BOX END */}

                <div class="card" style={{textAlign:"left"}}>
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("create-form").style.display="none"; }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                  <div class="card-body">
                    <h4 class="card-title">Create New Transaction</h4>
                    <div class="form-sample">
                      <p class="card-description">add salary transaction</p>
                      <code>{error_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date</label>
                            <div class="col-sm-9">
                            <input type="date" class="form-control" placeholder="" id="date" value={date} onChange={updatedate} />
                            <code>{date_er}</code>
                            </div>
                          </div>
                        </div> 
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="Type" id="type" value={type} onChange={updatetype} />
                            <code>{type_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">First Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="First Name" id="first_name" value={first_name} onChange={updatefirst_name} />
                              <code>{first_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Last Name</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="Last Name" id="last_name" value={last_name} onChange={updatelast_name}  />
                            <code>{last_name_er}</code>
                            </div>
                          </div>
                        </div>   
                        </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Employee Id</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="0001" id="employee_id" value={employee_id} onChange={updateemployee_id} />
                              <code>{employee_id_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Employee_code</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="EMP0001" id="employee_code" value={employee_code} onChange={updateemployee_code} />
                              <code>{employee_code_er}</code>
                            </div>
                          </div>
                        </div>  
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Email</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="myemail@gmail.com"  id="email" value={email} onChange={updateemail} />
                              <code>{email_er}</code> 
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Contact</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="91 9000000000"  id="contact" value={contact} onChange={updatecontact} />
                              <code>{contact_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <hr/>
                      <h4>Salary Details</h4>
                   
                      <div class="row mt-3">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Salary Status</label>
                            <div class="col-sm-9">
                            <select class="form-control" style={{padding:"1rem"}} id="salary_status" value={salary_status} onChange={updatesalary_status}>
                            <option value=" ">select salary status</option>
                            <option value="payment">payment</option>
                            <option value="due">due</option>
                            <option value="advance">advance</option>
                            </select>
                            <code>{salary_status_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Basic Salary</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="10000.00" id="basic_salary" value={basic_salary} onChange={updatebasic_salary} />
                            <code>{basic_salary_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row" hidden={salary_status=="due"}>
                      <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Mode</label>
                            <div class="col-sm-9">
                            <select class="form-control" style={{padding:"1rem"}} id="mode" value={mode} onChange={updatemode} >
                            <option value=" ">select mode</option>
                            <option value="cash">cash</option>
                            <option value="bank">bank</option>
                            </select>
                            <code>{mode_er}</code>
                            </div>
                          </div>
                        </div>     
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Select Bank</label>
                            <div class="col-sm-9">
                              <select class="form-control" placeholder="Select Bank" style={{padding:"1rem"}} id="bank_id" value={bank_id} onChange={updatebank_id}>
                              <option value="">Select Bank</option>
                              {
                              Tbanks.map((curElm2,ind)=>{

                              return(
                              <>
                              <option value={curElm2.Bank_id}>{curElm2.Bank_name}</option>
                              </>
                              )
                              }
	 				   
	                         		)
                              }
                              </select>
                              <code>{bank_id_er}</code>
                            </div>
                          </div>
                        </div>                 
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Incentives</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="10000.00" id="incentives" value={incentives} onChange={updateincentives} />
                              <code>{incentives_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Allowances</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="5000.00" id="allowances" value={allowances} onChange={updateallowances} />
                            <code>{allowances_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Provident Fund</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="500.00" id="provident_fund" value={provident_fund} onChange={updateprovident_fund} />
                              <code>{provident_fund_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Salary Deductions</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="500.00" id="salary_deductions" value={salary_deductions} onChange={updatesalary_deductions} />
                            <code>{salary_deductions_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="1000.00" id="amount" value={amount} onChange={updateamount} />
                              <code>{amount_er}</code>
                            </div>
                          </div>
                        </div>
                                       
                      </div>
                   
                      <div class="form-group">
                        <label for="exampleTextarea1">Remarks</label>
                        <textarea  class="form-control" rows="2" placeholder="Add your Remarks here" id="salary_remarks" value={salary_remarks} onChange={updatesalary_remarks} />
                        <code>{salary_remarks_er}</code>
                      </div>

  
                        <button class="btn btn-primary" style={{fontWeight:"bold"}} onClick={addsalary}>Submit</button>
                      
                   
                    </div>
                  </div>
                </div>
    </>

);

}

export default Create_salary;