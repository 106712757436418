import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

//import '../Businesstools/Resources/dashboard.css';

import '../Resources/dashboard.css';

const logname=sessionStorage.getItem("logName00");

function Client_dashredirect(){

    
    


return(
    <>

<html lang="en">
  <head>
    {/* Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Startup Connect | Login</title>
    {/* plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/* endinject */}
    {/* Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/* End plugin css for this page */}
    {/* inject:css */}
    {/* endinject */}
    {/* Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/* End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
    </head>
    <body>
    {/* container-scroller */}
    <div class="container-scroller">

   {/* partial */}
   <div class="container-fluid page-body-wrapper">

       {/*}   <Navbar/> */}

          <div class="main-panel">
          <div class="d-flex justify-content-center">
          {/* SUCCESS CARD START */}
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Login Successful!!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <a href="businesstools-businesses"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              {/* SUCCESS CARD END */}

              </div>
          <div class="content-wrapper pb-0">
          <h1>Hi <span class="text-primary">{logname}!</span> Welcome to Startup Connect's Services Tracking Platform.</h1>
             <div class="row mt-5">
              <div class="col-sm-4 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/inbox-cleanup-illustration-concept_114360-963.jpg" alt="" />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-semibold"> QUOTATIONS </h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0">
                      manage and view your project quotations received from Startup Connect Associates.</p>
                      
                    </div>
                    <button class="btn btn-warning mt-3" onClick={()=>{ window.location.replace("/client-dashboard-quotations"); }}>Continue to Quotations Dashboard</button>
                  </div>
                </div>
              </div>
              <div class="col-sm-4 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/taking-orders-by-phone-store-contact-center-customers-support-easy-order-fast-delivery-trade-service-call-center-operator-cartoon-character_335657-2564.jpg" alt="" />
                  </div>
                  <div class="card-body px-3 text-dark">
                  
                    <h5 class="font-weight-semibold"> ORDERS </h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0">
                      manage and track your orders with Startup Connect Services from order managemenet dashboard.
                      </p>
                      
                    </div>
                    <button class="btn btn-primary mt-3" onClick={()=>{ window.location.replace("/client-dashboard-orders"); }}>Continue to Orders Dashboard</button>

                  </div>
                </div>
              </div>
              <div class="col-sm-4 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/organizing-projects-concept-illustration_114360-632.jpg" alt="" />
                  </div>
                  <div class="card-body px-3 text-dark">
                
                    <h5 class="font-weight-semibold">PROJECTS</h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0">
                       manage all your projects with Startup Connect Services from orders dashboard
                      </p> 
                      
                    </div>
                    <button class="btn btn-danger mt-3" onClick={()=>{ window.location.replace("/client-dashboard-projects"); }}>Continue to Projects Dashboard</button>
                  </div>
                </div>
              </div>
              <div class="col-sm-4 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/concept-landing-page-credit-card-payment_52683-25532.jpg" alt="" />
                  </div>
                  <div class="card-body px-3 text-dark">
                
                    <h5 class="font-weight-semibold">PAYMENTS</h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0">
                       manage and track all your payments with Startup Connect Services from payments dashboard
                      </p> 
                      
                    </div>
                    <button class="btn btn-success mt-3" onClick={()=>{ window.location.replace("/client-dashboard-payments"); }}>Continue to Payments Dashboard</button>
                  </div>
                </div>
              </div>
              <div class="col-sm-4 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/emoji-satisfaction-meter-flat_78370-4156.jpg" alt="" />
                  </div>
                  <div class="card-body px-3 text-dark">
                
                    <h5 class="font-weight-semibold">FEEDBACKS</h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0">
                       View all your feedbacks provided to Startup Connect Services from here.
                      </p> 
                      
                    </div>
                    <button class="btn btn-dark mt-3" onClick={()=>{ window.location.replace("/client-dashboard-feedbacks"); }}>Continue to Feedbacks Dashboard</button>
                  </div>
                </div>
              </div>
            </div>
             </div>
             </div>
             </div>
             </div>


    {/* plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/* endinject */}
    {/* Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/* End plugin js for this page */}
    {/* inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/* endinject */}
    {/* Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/* End custom js for this page */}
  </body>
  </html>    
    </>
);

}

export default Client_dashredirect;