import { toHaveErrorMessage } from '@testing-library/jest-dom/dist/matchers';
import React, { useState, useEffect } from 'react';
import { scryRenderedDOMComponentsWithTag } from 'react-dom/test-utils';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
//import Navbar from './navbar';
import axios from '../../axios.js';
import { counter } from '@fortawesome/fontawesome-svg-core';
import Footer3 from '../../Supportingfiles/footer3.js';
//import useScript from '../hooks/useScript';
{/*}
const MyComponent = props => {
  useScript('https://checkout.razorpay.com/v1/checkout.js');

  // rest of your component
}*/}
//import { Link } from "react-router-dom";
//import {Helmet} from "react-helmet";
//Checking Sessions




    // Access value associated with the PRICING PLAN
    const priceplan=sessionStorage.getItem("pricingplan00");
    const planname=sessionStorage.getItem("planname00");
    const logstatus=sessionStorage.getItem("logStatus00");
    const logid=sessionStorage.getItem("logId00");
    const token=sessionStorage.getItem("logToken00");
    const logname=sessionStorage.getItem("logName00");


function Bt_paidplanpage() {


     
    useEffect(()=>{
     
        defaultload();
      
      },[])
   
 
  //loadcount
  const [loadcount, setloadcount] = useState(0);
  //message
  const [message, setmessage] = useState();
  //codeId
  const [codeId, setcodeId] = useState();
  //details
  const [details, setdetails] = useState();
  //planvalue
  const [planvalue, setplanvalue] = useState(1);
  //transactions
  const [transactions, settransactions] = useState();
  //businesses
  const [businesses, setbusinesses] = useState();
  //counters
  const [counters, setcounters] = useState();
  //team
  const [team, setteam] = useState();
  //productlist
  const [productlist, setproductlist] = useState();
  //customers
  const [customers, setcustomers] = useState();
  //suppliers
  const [suppliers, setsuppliers] = useState();
  //days
  const [days, setdays] = useState();
  //orderid
  const [orderid, setorderid] = useState();
  //Error Messages
  //usernamemsg
  const [usernamemsg, setusernamemsg] = useState();
  //namemsg
  const [namemsg, setnamemsg] = useState();
  //couponcodemsg
  const [couponcodemsg, setcouponcodemsg] = useState();
  //daysmsg
  const [daysmsg, setdaysmsg] = useState();
  //amountmsg
  const [amountmsg, setamountmsg] = useState();
  //username
  //const [username, setusername] = useState(User); const updateusername = (event) => { setusername(event.target.value); };
  //name
  const [name, setname] = useState(); const updatename = (event) => { setname(event.target.value); };
  //duration
  const [duration, setduration] = useState(); const updateduration = (event) => { setduration(event.target.value); defaultload(); setcouponapplycount(0);setcouponcodemsg(); };
  //couponcode
  const [couponcode, setcouponcode] = useState(); const updatecouponcode = (event) => { setcouponcode(event.target.value);   };
  //gstin
  const[gstin,setgstin]=useState();const updategstin = (event) => { setgstin(event.target.value);}; const[gstin_er,setgstin_er]=useState();
  //amount
  const [amount, setamount] = useState();//const updateamount = (event) => {  setamount(event.target.value); };
  //gst_per
  const [gst_per, setgst_per] = useState();
  //gst_amount
  const [gst_amount, setgst_amount] = useState();
  //total
  const [total, settotal] = useState();
  //mrp
  const [mrp, setmrp] = useState();
  //couponapplycount
  const [couponapplycount, setcouponapplycount] = useState(0);


    
function defaultload(){
  
  

    
  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))){
 

    //check if user logged in
  
    if(priceplan!=null){
        
    
       //fetching login api
       axios.post("bt-get-pricingdetail", {
         "Plancode": priceplan,
       //  "User":User,
       //  "Logtype":logtype,
         "Log_id":logid,
         "Token":token,
         "Logstatus":logstatus
       })
         .then((response) => {

         

           const svar = response.data.successvar;
           const errorvar = response.data.error;
      
          if(errorvar=="100"||errorvar=="101"){
            
              //alert("Please Login to Continue!");
              //Remove Session Variables and redirect to login
              sessionStorage.removeItem("logStatus00");
              sessionStorage.removeItem("logId00");
              sessionStorage.removeItem("logToken00");
              sessionStorage.removeItem("logName00");
              sessionStorage.removeItem("pricingplan00");
              sessionStorage.removeItem("planname00");
              window.location.replace("/businesstools-login");

          }
          if(svar==1){

      
            
            const s_businesses =(response.data.businesses);setbusinesses(response.data.businesses);
            const s_team =(response.data.team);setteam(response.data.team);
            const s_customers =(response.data.customers);setcustomers(response.data.customers);
            const s_suppliers =(response.data.suppliers);setsuppliers(response.data.suppliers);
            const s_productlist =(response.data.productlist);setproductlist(response.data.productlist);
            const s_transactions =(response.data.transactions);//settransactions(response.data.transactions);
            const s_days =(response.data.days);//setdays(response.data.days);
            const s_amount =(response.data.amount);//setamount(response.data.amount);
            const s_counters =(response.data.counters);setcounters(response.data.counters);
            setgst_per(response.data.gst_per);
            setgst_amount(parseFloat(response.data.gst_amount).toFixed(2));
            settotal(response.data.total); 



            //First time load
            if(loadcount==0){

   
              const newtransactions = s_transactions;
              const newdays = s_days;
              const newamount = parseFloat(s_amount);
              settransactions(newtransactions);
              setdays(newdays);
              setamount(newamount.toFixed(2));
              setloadcount(1);
              setduration(newdays+"days");
              //setgst_per(response.data.gst_per);
              //setgst_amount(response.data.gst_amount);
              //settotal(response.data.total);
              
            }
       
          } 
         }); 
 }
 else{

  //alert("Please Login to Continue!");
   //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("logName00");
   sessionStorage.removeItem("pricingplan00");
   sessionStorage.removeItem("planname00");
   window.location.replace("/businesstools-login");
 }
}
//
else{
  //alert("Please Login to Continue!");
  //Remove Session Variables and redirect to login
  sessionStorage.removeItem("logStatus00");
  sessionStorage.removeItem("logId00");
  sessionStorage.removeItem("logToken00");
  sessionStorage.removeItem("logName00");
  sessionStorage.removeItem("pricingplan00");
  sessionStorage.removeItem("planname00");
  window.location.replace("/businesstools-login");
}

 

 }
 
   
     
   useEffect(()=>{
     
     defaultload();
   
   },[])

   



  //VALIDATE COUPON CODE
  function validatecoupon() {

    if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((priceplan!=null)||(priceplan!=" "))){
       

    setcouponcodemsg();

    if((couponcode==null)||(couponcode==" ")){  setcouponcodemsg(" Please Enter Coupon Code");  return; }

   if((couponapplycount>0)){  setcouponcodemsg(" Coupon Code Already Applied");  return; }

    if (((couponcode.length) > 3)&&(couponapplycount==0)) {

      //fetching login api
      axios.post("bt-check-couponcode", {
        "Log_id":logid,
        "Token":token,
        "Coupon": document.getElementById("couponcode").value,
        "Plan_code":priceplan
      })
        .then((response) => {

            

          const errorvar = response.data.errorvar;
          const svar = response.data.successvar;


          //success
          if (svar == 1) {

            const coupon_transactions = response.data.coupon_transactions;
            const coupon_team = response.data.coupon_team;
            const coupon_business = response.data.coupon_business;
            const coupon_counters = response.data.coupon_counters;
            const coupon_customers = response.data.coupon_customers;
            const coupon_suppliers = response.data.coupon_suppliers;
            const coupon_productlist = response.data.coupon_productlist;
            const coupon_discountper = response.data.coupon_discountper;
            const coupon_discountamt = response.data.coupon_discountamt;
            const coupon_days = response.data.coupon_days;

            if (coupon_discountper != null) { const discountamt = parseFloat(amount * coupon_discountper / 100); const newamount = parseFloat(amount - discountamt); setamount(newamount); }
            if (coupon_team != null) { const extrateam = team + coupon_team; setteam(extrateam); }// updating team
            if (coupon_transactions != null) { const extratrans = transactions + coupon_transactions; settransactions(extratrans); }//updating transactions
            if (coupon_business != null) { const extrabusiness = businesses + coupon_business; setbusinesses(extrabusiness); }//updating business
            if (coupon_counters != null) { const extracounters = counters + coupon_counters; setcounters(extracounters); }//updating business
            if (coupon_customers != null) { const extracustomers = customers + coupon_customers; setcustomers(extracustomers); }//updating customers
            if (coupon_suppliers != null) { const extrasuppliers = suppliers + coupon_suppliers; setsuppliers(extrasuppliers); }//updating suppliers
            if (coupon_productlist != null) { const extraproductlist = productlist + coupon_productlist; setproductlist(extraproductlist); }//updating productlist
            if (coupon_days != null) { const extradays = days + coupon_days; setdays(extradays); }//updating coupon days


            //GST and Total Calculation
            let new_amount = parseFloat(amount).toFixed(2);
            const $gst_per = 18;
            if(coupon_discountper>0){ new_amount = (amount-(parseFloat(amount*coupon_discountper/100))).toFixed(2); }
            else if(coupon_discountamt>0){ new_amount = parseFloat(amount - coupon_discountamt).toFixed(2); }
            else{ new_amount = amount; }

            const $gst_amount = parseFloat(new_amount*$gst_per/100);
            const totalAmount = parseFloat(new_amount)+parseFloat($gst_amount);
            //const $total = $amount+$gst_amount;
            setgst_amount($gst_amount.toFixed(2));
            settotal(totalAmount.toFixed(2));
          
           //document.getElementById("")

            setcouponcodemsg("Coupon Code Applied !");
            document.getElementById("coupon-msg").style.color="green";
            setcouponapplycount(1);
          }
          else {
            setcouponcodemsg(response.data.coupon_error);


          }

        });
    }
    

  }
  else{
    //Remove Session Variables and redirect to login
    sessionStorage.removeItem("logStatus00");
    sessionStorage.removeItem("logId00");
    sessionStorage.removeItem("logToken00");
    sessionStorage.removeItem("logName00");
    sessionStorage.removeItem("pricingplan00");
    sessionStorage.removeItem("planname00");
    window.location.replace("businesstools-login");
  }
  }  

/* CONFIRM ORDER FUNCTION START */

function confirmorder(){
  

   
   if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((priceplan!=null)||(priceplan!=" "))){
       
    let amtval = "000";
    if((amount!=null)||(amtval>0)){ amtval = amount; }
      axios.post("bt-confirm-cforder", {

        "Log_id":logid,
        "Token":token,
        "Logstatus":logstatus,
        "Plancode":priceplan,
         "Name":logname,
         "Days":days,
         "Coupon":couponcode,
         "Amount":amtval,
         "Log_id":logid,
         "Token":token,
       
      })
        .then((response) => {

         

         if(response.data.error==103){
            alert(response.data.error_msg);
          }
         else if(response.data.errorvar==1){
            alert("Invalid Coupon Code");
          }
        else if(response.data.successvar==1){
           
         
          setcodeId(response.data.codeId);
          document.getElementById("orderid").value=(response.data.codeId);
          setorderid(response.data.codeId);
          document.getElementById("gstin-info").style.display="block";
          document.getElementById("confirmorder-btn").style.display="none";
          document.getElementById("payment-btn").style.display="block";
          
         } 
         else if(response.data.error==107){
          alert(response.data.errormsg);
        }
        else{
          
         // alert("error1");
           //Remove Session Variables and redirect to login
           sessionStorage.removeItem("logStatus00");
           sessionStorage.removeItem("logId00");
           sessionStorage.removeItem("logToken00");
           sessionStorage.removeItem("logName00");
           sessionStorage.removeItem("pricingplan00");
           sessionStorage.removeItem("planname00");
           window.location.replace("businesstools-login"); 
        }
      
       //
        }); 
 }
else{

 // alert("error2");
          //Remove Session Variables and redirect to login
          sessionStorage.removeItem("logStatus00");
          sessionStorage.removeItem("logId00");
          sessionStorage.removeItem("logToken00");
          sessionStorage.removeItem("logName00");
          sessionStorage.removeItem("pricingplan00");
          sessionStorage.removeItem("planname00");
          window.location.replace("businesstools-login"); 
}
}

  



  return (
    <>
    

    
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Business Tools|BookKeeping Solution</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
       {/* container-scroller */}
       <div class="container-scroller">

{/* partial */}
<div class="container-fluid page-body-wrapper">

    {/*}   <Navbar/> */}

       <div class="main-panel">
       <div class="d-flex justify-content-center">
       {/* SUCCESS CARD START */}
       <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
             <div class="card">
               <div class="card-body">
                 <h4 class="card-title  text-success">Thanks for your Order Confirmation, your Order Id is <span style={{color:"#0070aa"}} >{codeId}</span> !!!</h4>
               
                 <blockquote class="blockquote">
                   <p class="mb-0"> Click the following button to continue.</p>
                   <a href="/redirect-dashboard"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                 </blockquote>
               </div>
           
             </div>
           </div>
           {/* SUCCESS CARD END */}

           </div>
       <div class="content-wrapper pb-0">
         <div class="row d-flex justify-content-center">
           <div class="col-md-6 grid-margin stretch-card" style={{textAlign:"left",display:"flex",flexDirection:"column"}}>
           <div>
          <h1 style={{textAlign:"left", color:"#0070aa"}}>Hi {logname}! </h1>
          <h3>Thanks for choosing us as your Book Keeping Partner</h3>
          <p style={{textAlign:"left"}}>Following are the details of your plan that you are continuing.</p>
          </div>

          <div class="card" style={{padding:"1rem"}}>
          <h3>{planname}</h3>
          <h5 class="text-muted">Plan Code : {priceplan}</h5>
          <hr />
          <p style={{textAlign:"right"}}>{days} Days Plan</p>
          <ul style={{textAlign:"left",fontSize:"105%", listStyleType:"none"}}>                    
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>{transactions} Transactions</li>
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>{businesses} Active Business</li>
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>{counters} Active Counters</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>{team} Team Accounts</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>{customers} Customers in List Access</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>{suppliers} Suppliers in List Access</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>{productlist} Products in List Access</li> 
                        <br/>           
                    {/*}    <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#f49938", fontSize:"110%",marginRight:"1rem"}}></i>{premium_plan_trans} Transactions</li>
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#f49938", fontSize:"110%",marginRight:"1rem"}}></i>{premium_plan_business} Active Business</li>
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#f49938", fontSize:"110%",marginRight:"1rem"}}></i>{premium_plan_counters} Active Counter</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#f49938", fontSize:"110%",marginRight:"1rem"}}></i>{premium_plan_team} Team Accounts</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#f49938", fontSize:"110%",marginRight:"1rem"}}></i>{premium_plan_customers} Customers in List Access</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#f49938", fontSize:"110%",marginRight:"1rem"}}></i>{premium_plan_suppliers} Suppliers in List Access</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#f49938", fontSize:"110%",marginRight:"1rem"}}></i>{premium_plan_products} Products in List Access</li> 
                       */} <li><button class="btn btn-light" id="premium-view-features-btn" style={{backgroundColor:"#ffffff"}} onClick={()=>{
                            document.getElementById("premium-view-features-btn").style.display="none";
                            document.getElementById("premium-hide-features-btn").style.display="block";
                            document.getElementById("premium-all-features").style.display="block";
                        }}><p class="text-primary"> view all features...</p></button></li>
                        <div id="premium-all-features"  style={{display:"none"}}>
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Create and Manage Invoices</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Cash Management</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Multiple Bank Ledger Management</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Contra Entries</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Incomes and Expenses Management</li>
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Record and Manage Outstanding Incomes and Expenses</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Debtors Management</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Creditors Management</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Record and Manage Sales, Purchases and Returns</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>GST and Tax Management</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>GSTIN wise Tax Summary</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Quotations Management</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Assets Management</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Manufacturing</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Employees and Salary Management</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Financial Analysis</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Product Groups in Catalog</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Interactive Charts for Data Visualization</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Multiple Filters in Reports</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Transactions and Updation Summaries</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Generate QR code for products in catalog</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Automated Double Entry</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Export Data as pdf or excel</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Manage transactions online</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Manage from any device any time connected over internet</li> 
                        <li><button class="btn btn-light" id="premium-hide-features-btn" style={{display:"none", backgroundColor:"#ffffff"}}  onClick={()=>{
                            document.getElementById("premium-view-features-btn").style.display="block";
                            document.getElementById("premium-hide-features-btn").style.display="none";
                            document.getElementById("premium-all-features").style.display="none";
                        }}><p class="text-primary"> hide features...</p></button></li>
                        </div>
                        </ul>
                        <hr />
                        <h4><b>₹ {parseFloat(amount).toFixed(2)}/-</b></h4>
                        <label>plan value</label>
                        <br/>
                        <p>GST @18% ({gst_amount})</p>
                        <br />

                        <h4 style={{color:"#18728b"}}><b>₹ {total}/-</b></h4>        
                        <label>payable amount</label>            
                        <hr/>

                        <div class="form-group">
                        <div class="row">
                        <div class="col-md-2"></div>
                        <div class="col-md-5">
                          <div class="form-group row">
                              <input type="text" class="form-control" placeholder="Enter Coupon Code"  id="couponcode"  value={couponcode} onChange={updatecouponcode}  />
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group row">
                           <button class="btn btn btn-primary" onClick={validatecoupon}>Apply</button>
                          </div>
                        </div>
                        </div>
                        <code id="coupon-msg">{couponcodemsg}</code>
                        </div>

                        <div class="form-group row" id="gstin-info" style={{display:"none"}}>
                            <label class="col-sm-3 col-form-label">Add GSTIN</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="23AAJCT8760E1Z7" id="gstin" value={gstin} onChange={updategstin} />
                              <p class="text-muted" style={{fontSize:"0.7rem"}}>fill this field if you want GSTIN to be included in invoice.</p>
                              <code>{gstin_er}</code> 
                            </div>
                          </div>
                     
                      <button class="btn btn-lg btn-success" id="confirmorder-btn" onClick={confirmorder}>Confirm Order</button>

                       <form action="https://www.thestartupconnect.com/api/payment/cfprocess" method="post">
                           {/*}   <form action="http://127.0.0.1:8000/payment-form" method="post">  */}
                                <input type="hidden"  id="orderid" value={orderid} name="OrderId" />
                                <input type="hidden" value={token} name="Token" />
                                <input type="hidden" value={logid} name="Log_id" />
                                <input type="hidden" value={total} name="Total" />
                                <input type="hidden" value={gstin} name="Gstin" /> 
                                <button class="btn btn-lg btn-primary" type="submit" style={{display:"none"}} id="payment-btn">Proceed to Payment</button>
                        </form>
                   
                     

          </div>
          <p class="mt-2">This payment is being made to Technomate Startup Services Pvt. Ltd. <br/> (<b>GSTIN: </b> 23AAJCT8760E1Z7)</p>
           </div>

         {/*}  <div class="col-md-6 grid-margin stretch-card" style={{textAlign:"left"}}>
           <img src="https://images.pexels.com/photos/6694543/pexels-photo-6694543.jpeg?auto=compress&cs=tinysrgb&w=600" style={{width:"100%",width:"100%"}}></img>
           </div> */}

          </div>
          </div>
          </div>
          </div>
          </div>
          <Footer3 />



      {/*}   <input type="text" value={orderid} id="orderid" /> */}


    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
    </>

  );

}

export default Bt_paidplanpage;