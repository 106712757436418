import React from "react";
import { useState, useEffect, Component } from "react";
import { useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
//import axios from '../../axios.js';
import moment from "moment/moment";

import "../../Resources/homebtcss.css";
import "../../Resources/home2css.css";
import "../../Resources/serviceshomecss.css";
import "../ankitcss.css";
import '../../Resources/responsiveness.css';

//import Sidebar from "./sidebar";

//import Chart from "chart.js/auto";
//import { Line, Bar } from "react-chartjs-2";
import Footer3 from "../../Supportingfiles/footer3";
//import { Navbar } from "flowbite-react";
import Navbar3 from "../../Supportingfiles/navbar3";
import Whystartupconnect from "../whystartupconnect";

function Valuation1() {
  return (
    <>
      <html lang="en">
        <head>
          {/*} Required meta tags */}
          <meta charset="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <title>STARTUP CONNECT | SERVICES | VALUATION</title>
          {/*} plugins:css */}
          <link
            rel="stylesheet"
            href="panel/assets/vendors/mdi/css/materialdesignicons.min.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/css/vendor.bundle.base.css"
          />
          {/*} endinject */}
          {/*} Plugin css for this page */}
          <link
            rel="stylesheet"
            href="panel/assets/vendors/jquery-bar-rating/css-stars.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/font-awesome/css/font-awesome.min.css"
          />
          {/*} End plugin css for this page */}
          {/*} inject:css */}
          {/*} endinject */}
          {/*} Layout styles */}
          <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
          {/*} End layout styles */}
          <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
        </head>
        <body>

           
  {/* Whatsapp Chat Icon Start */}
  <div class="wtp-chat-container" /*style={{position:"fixed",marginTop:"92vh", marginLeft:"94vw"}}*/>
    <a aria-label="Chat on WhatsApp" href="https://wa.me/918480002413?text=I%20want%20to%20know%20more%20about%20service%20Valuation%20by%20the%20Startup%20Connect">   
    <img class="wtp-chat-icon" src="https://cdn-icons-png.flaticon.com/128/3938/3938058.png" /*style={{height:"3rem", width:"3rem"}}*/></img>
  </a>
  </div>
  {/* Whatsapp Chat Icon End */}
          <div class="home2-container">
            <Navbar3 />

            {/* HEADER SECTION START */}
            <section /*class="sv-header-section" */>
              <div class="sv-header-container1">
                <div class="sv-header-left home-header-pd">
                  <h1 style={{ color: "#004463" }}>VALUATION SERVICE</h1>
                  <p>
                  <div style={{ marginBottom: "2px" }}>
                      <span style={{ paddingRight: "5px" }}><i class="mdi mdi-checkbox-marked" style={{fontSize:"2rem", color :"#0b5748"}}></i></span>
                      <span>Fast customer support </span>
                    </div>
                    <div style={{ marginBottom: "2px" }}>
                      <span style={{ paddingRight: "5px" }}><i class="mdi mdi-checkbox-marked" style={{fontSize:"2rem", color :"#0b5748"}}></i></span>
                      <span>Complete online process.</span>
                    </div>
                    <div style={{ marginBottom: "2px" }}>
                      <span style={{ paddingRight: "5px" }}><i class="mdi mdi-checkbox-marked" style={{fontSize:"2rem", color :"#0b5748"}}></i></span>
                      <span>Instant WhatsApp/email updates </span>
                    </div>
                  </p>


                  <div class="home2-services-features-list">
                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img
                          loading="lazy"
                          src="https://cdn-icons-png.flaticon.com/128/1087/1087840.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>500+</h3>
                        <h5 style={{ fontWeight: "bold" }}>Projects</h5>
                      </div>
                    </div>

                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img
                          loading="lazy"
                          src="https://cdn-icons-png.flaticon.com/128/12735/12735475.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>25+</h3>
                        <h5 style={{ fontWeight: "bold" }}>Cities</h5>
                      </div>
                    </div>

                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img
                          loading="lazy"
                          src="https://cdn-icons-png.flaticon.com/128/2991/2991235.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>
                          4{" "}
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/7656/7656139.png"
                            style={{ height: "2rem", width: "2rem" }}
                          />
                          +
                        </h3>
                        <h5 style={{ fontWeight: "bold" }}>Google Ratings</h5>
                      </div>
                    </div>
                  </div>

                  <div  class="serviceshome-btn-container">
             <div>
             <a href="https://calendly.com/thestartupconnect/30min"><button class="btn-lft-margin" id="home2-modules-section-btn" style={{marginLeft:"0"}}>Book Free Consultancy <i class="mdi mdi-arrow-right"></i></button></a>
             </div>
             <div style={{marginLeft:"0"}}>
             <a href="/home1/startupbrochure1.pdf" download="Startup Connect Services Brochure"><button class="btn-lft-margin" id="home2-modules-section-btn" style={{backgroundImage: "linear-gradient(to right, #208d17, #00ad11)", marginLeft:"0"}}>Download Brochure <i class="mdi mdi-download"></i></button></a>
             </div>
             </div>

                </div>
                <div
                  class="sv-header-right" /* style={{backgroundColor:"#96dfff",padding:"2rem",borderRadius:"15px",boxShadow:"2px 3px 3px 2px #cbcecf"}} */
                >
                  <img
                    loading="lazy"
                    src="https://img.freepik.com/free-vector/online-banking-concept-illustration_114360-17345.jpg"
                    style={{ width: "90%", height: "100%" }}
                  />
                </div>
              </div>
            </section>
            {/* HEADER SECTION END */}
            <hr />

            {/* SIDEBAR AND MAIN PAGE START */}
            <div className="container-scroller">
              {/*<Sidebar /> */}
              <div className="container-fluid page-body-wrapper">
                {/*} partial */}
                <div className="main-panel">
                

                  {/* MORE INFORMATION SECTION START */}
                  <div class="row" style={{ padding: "2rem" }}>
                    <div
                      class="col-sm-12 stretch-card grid-margin" 
                      style={{ textAlign: "left" }}
                    >
                      <div>
                        <h2 style={{ fontWeight: "bold", textAlign:"left" }}>
                          WHAT IS {" "}
                          <span style={{ color: "#9f23c2" }}>VALUATION?</span>
                        </h2>
                        <br />
                        <p style={{ fontSize: "1rem", textAlign:'left' }}>
                          {" "}
                          Valuation is the process of determining the economic
                          value of an asset, business, or investment. It plays a
                          crucial role in making strategic decisions, such as
                          mergers and acquisitions, financial reporting, tax
                          planning, and investment analysis. At [Your Company
                          Name], we leverage industry-leading methodologies and
                          expertise to provide you with a reliable and objective
                          assessment of your assets
                        </p>
                      </div>
                    </div>
                {/*}    <div class="col-sm-3 stretch-card grid-margin">
                      <img
                        loading="lazy"
                        src="https://img.freepik.com/free-vector/business-deal-concept-illustration_114360-1535.jpg"
                        style={{ width: "90%", height: "100%" }}
                      />
                    </div> */}
                  </div>
                  {/* MORE INFORMATION SECTION END */}


                  {/* TYPES SECTION START */}

                  {/* TYPE SECTION 1 START */}
                  <div class="row" style={{ padding: "2rem" }}>
                    <div
                      class="col-sm-8 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div>
                        <h2 style={{ fontWeight: "bold" }}>
                          TYPES OF ASSET VALUATION
                        </h2>
                        <br />
                        Here are some common asset classes in which valuations
                        are frequently performed:
                        <br />
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                          {" "}
                          <div style={{ fontWeight: "bolder" }}>
                            1. Real Estate Valuation:
                          </div>
                          <ul
                            style={{
                              listStyle: "initial",
                              fontSize: "1rem",
                              marginLeft: "10px",
                            }}
                          >
                            <li>
                              Residential Properties: Apartments, houses, and
                              individual residences.
                            </li>
                            <li>
                              Commercial Properties: Office spaces, retail
                              outlets, and industrial facilities.
                            </li>
                            <li>
                              Land Valuation: Assessing the value of vacant land
                              for development or investment purposes.
                            </li>
                          </ul>
                        </p>
                        <p style={{ fontSize: "1rem" }}>
                          {" "}
                          <div style={{ fontWeight: "bolder" }}>
                            2. Business Valuation:
                          </div>
                          <ul
                            style={{
                              listStyle: "initial",
                              fontSize: "1rem",
                              marginLeft: "10px",
                            }}
                          >
                            <li>
                              Private Companies: Determining the overall worth
                              of privately held businesses.
                            </li>
                            <li>
                              Public Companies: Assessing the market value of
                              publicly traded companies.
                            </li>
                            <li>
                              Startups: Valuations for early-stage companies,
                              often crucial for fundraising and investment.
                            </li>
                          </ul>
                        </p>
                        </div>
                        </div>
                        <div class="col-sm-4 stretch-card grid-margin">
                      <img
                        loading="lazy"
                        src="https://img.freepik.com/free-vector/analysis-concept-illustration_114360-1119.jpg"
                        style={{ width: "90%", height: "100%" }}
                      />
                    </div>

                        </div>

                        {/* TYPE SECTION 1 END */}

                   {/* TYPE SECTION 2 START */}
                  <div class="row" style={{ padding: "2rem" }}>
                  <div class="col-sm-4 stretch-card grid-margin">
                      <img
                        loading="lazy"
                        src="https://img.freepik.com/free-vector/credit-assessment-concept-illustration_114360-7282.jpg"
                        style={{ width: "90%", height: "100%" }}
                      />
                    </div>
                    <div
                      class="col-sm-8 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div>
                      <p style={{ fontSize: "1rem" }}>
                      {" "}
                          <div style={{ fontWeight: "bolder" }}>
                            3. Financial Instruments:
                          </div>
                          <ul
                            style={{
                              listStyle: "initial",
                              fontSize: "1rem",
                              marginLeft: "10px",
                            }}
                          >
                            <li>
                              Equities: Valuing shares and stocks in publicly
                              traded companies.
                            </li>
                            <li>
                              Public Companies: Assessing the market value of
                              publicly traded companies.
                            </li>
                          </ul>
                        </p>
                        <p style={{ fontSize: "1rem" }}>
                          {" "}
                          <div style={{ fontWeight: "bolder" }}>
                            4. Intellectual Property Valuation:
                          </div>
                          <ul
                            style={{
                              listStyle: "initial",
                              fontSize: "1rem",
                              marginLeft: "10px",
                            }}
                          >
                            <li>
                              Patents: Assessing the value of intellectual
                              property rights for inventions.
                            </li>
                            <li>
                              rademarks and Brands: Determining the worth of
                              brand names and logos.
                            </li>
                            <li>
                              Copyrights: Valuing original creative works, such
                              as literature or art.
                            </li>
                          </ul>
                        </p>
                        <p style={{ fontSize: "1rem" }}>
                          {" "}
                          <div style={{ fontWeight: "bolder" }}>
                            5. Plant and Machinery Valuation:
                          </div>
                          <ul
                            style={{
                              listStyle: "initial",
                              fontSize: "1rem",
                              marginLeft: "10px",
                            }}
                          >
                            <li>
                              Industrial Equipment: Valuing machinery and
                              equipment used in manufacturing.
                            </li>
                            <li>
                              Technology Assets: Assessing the worth of IT
                              infrastructure and technology assets.
                            </li>
                          </ul>
                        </p>
                        <p style={{ fontSize: "1rem" }}>
                          {" "}
                          <div style={{ fontWeight: "bolder" }}>
                            6. Jewelry and Art Valuation:
                          </div>
                          <ul
                            style={{
                              listStyle: "initial",
                              fontSize: "1rem",
                              marginLeft: "10px",
                            }}
                          >
                            <li>
                              Precious Metals and Gemstones: Determining the
                              market value of gold, silver, diamonds, etc.
                            </li>
                            <li>
                              Artwork: Valuation of paintings, sculptures, and
                              other artistic creations.
                            </li>
                          </ul>
                        </p>
                        </div>
                        </div>

                        </div>

                        {/* TYPE SECTION 2 END */}

               
                 
                    {/* TYPE SECTION 3 START */}
                  <div class="row" style={{ padding: "2rem" }}>
                    <div
                      class="col-sm-9 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div>
                     
                    
                    
                        <p style={{ fontSize: "1rem" }}>
                          {" "}
                          <div style={{ fontWeight: "bolder" }}>
                            7. Agricultural Assets:
                          </div>
                          <ul
                            style={{
                              listStyle: "initial",
                              fontSize: "1rem",
                              marginLeft: "10px",
                            }}
                          >
                            <li>
                              Farmland Valuation: Assessing the value of
                              agricultural land for cultivation.
                            </li>
                            <li>
                              Livestock and Crops: Valuations related to farming
                              and agribusiness.
                            </li>
                          </ul>
                        </p>
                        <p style={{ fontSize: "1rem" }}>
                          {" "}
                          <div style={{ fontWeight: "bolder" }}>
                            8. Infrastructure Projects:
                          </div>
                          <ul
                            style={{
                              listStyle: "initial",
                              fontSize: "1rem",
                              marginLeft: "10px",
                            }}
                          >
                            <li>
                              Transportation Infrastructure: Valuing roads,
                              bridges, and other transport-related assets.
                            </li>
                            <li>
                              Energy Infrastructure: Assessing the value of
                              power plants, pipelines, etc.
                            </li>
                          </ul>
                        </p>
                        <p style={{ fontSize: "1rem" }}>
                          {" "}
                          <div style={{ fontWeight: "bolder" }}>
                            9. Collectibles and Antiques:
                          </div>
                          <ul
                            style={{
                              listStyle: "initial",
                              fontSize: "1rem",
                              marginLeft: "10px",
                            }}
                          >
                            <li>
                              Numismatics: Valuing coins and currency notes.
                            </li>
                            <li>
                              Antiques: Assessing the worth of vintage items and
                              collectibles.
                            </li>
                          </ul>
                        </p>
                        <p style={{ fontSize: "1rem" }}>
                          {" "}
                          <div style={{ fontWeight: "bolder" }}>
                            10. Liabilities and Debts:
                          </div>
                          <ul
                            style={{
                              listStyle: "initial",
                              fontSize: "1rem",
                              marginLeft: "10px",
                            }}
                          >
                            <li>
                              Loan Portfolios: Evaluating the value of loan
                              portfolios held by financial institutions.
                            </li>
                            <li>
                              Corporate Debt: Valuing outstanding debts and
                              liabilities.
                            </li>
                          </ul>
                        </p>
                      </div>
                    </div>
                    <div class="col-sm-3 stretch-card grid-margin">
                      <img
                        loading="lazy"
                        src="https://img.freepik.com/free-vector/editorial-commission-concept-illustration_114360-7751.jpg"
                        style={{ width: "90%", height: "100%" }}
                      />
                    </div>
                  </div>
                   {/* TYPE SECTION 3 END */}
                   {/* TYPE SECTION 4 START */}
                   <div class="row" style={{ padding: "2rem" }}>
                   <div class="col-sm-3 stretch-card grid-margin">
                      <img
                        loading="lazy"
                        src="https://img.freepik.com/free-vector/strategic-consulting-concept-illustration_114360-8994.jpg"
                        style={{ width: "90%", height: "100%" }}
                      />
                    </div>
                    <div
                      class="col-sm-9 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div>
                        <p style={{ fontSize: "1rem" }}>
                          {" "}
                          <div style={{ fontWeight: "bolder" }}>
                            11. Human Capital:
                          </div>
                          <ul
                            style={{
                              listStyle: "initial",
                              fontSize: "1rem",
                              marginLeft: "10px",
                            }}
                          >
                            <li>
                              Employee Stock Options (ESOPs): Valuation of stock
                              options offered to employees.
                            </li>
                            <li>
                              Key Personnel: Assessing the value of skills and
                              expertise within an organization.
                            </li>
                          </ul>
                        </p>
                        <p style={{ fontSize: "1rem" }}>
                          {" "}
                          <div style={{ fontWeight: "bolder" }}>
                            12. Government Securities:
                          </div>
                          <ul
                            style={{
                              listStyle: "initial",
                              fontSize: "1rem",
                              marginLeft: "10px",
                            }}
                          >
                            <li>
                              Government Bonds: Valuing bonds issued by the
                              government.
                            </li>
                          </ul>
                        </p>
                        <p style={{ fontSize: "1rem" }}>
                          {" "}
                          <div style={{ fontWeight: "bolder" }}>
                            13. Insurance Valuation:
                          </div>
                          <ul
                            style={{
                              listStyle: "initial",
                              fontSize: "1rem",
                              marginLeft: "10px",
                            }}
                          >
                            <li>
                              Government Bonds: Valuing bonds issued by the
                              government.
                            </li>
                          </ul>
                        </p>
                        <p style={{ fontSize: "1rem" }}>
                          {" "}
                          <div style={{ fontWeight: "bolder" }}>
                            14. Environmental Assets:
                          </div>
                          <ul
                            style={{
                              listStyle: "initial",
                              fontSize: "1rem",
                              marginLeft: "10px",
                            }}
                          >
                            <li>
                              Carbon Credits: Valuation of tradable carbon
                              credits for environmental initiatives.
                            </li>
                          </ul>
                        </p>
                      </div>
                    </div>
                  </div>
                   {/* TYPE SECTION 4 END */}

                    {/* TYPES SECTION END */}




                  <hr />
                  <h2 id="ip-h2">WHAT ARE THE USES OF VALUATION REPORT</h2>
                  {/* BENEFITS SECTION START */}
                  <div class="row d-flex justify-content-center" style={{ padding: "2rem" }}>
                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/2590/2590464.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Private Equity and Venture Capital</h4>
                        <p>
                          Investors often require valuation reports to assess
                          the worth of companies they are considering investing
                          in.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/3398/3398099.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Taxation</h4>
                        <p>
                          Tax authorities may require valuation reports for
                          various purposes, such as calculating capital gains
                          tax or inheritance tax.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/4149/4149706.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Financial Reporting</h4>
                        <p>
                          Companies may need to value assets or businesses for
                          accounting purposes, such as impairment testing or
                          fair value accounting.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/3529/3529127.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Loan Security:</h4>
                        <p>
                          Lenders may ask for valuation reports on assets
                          offered as collateral for loans{" "}
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/4325/4325510.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Company Restructuring</h4>
                        <p>
                          When a company undergoes restructuring, a valuation
                          report can help determine the value of different
                          entities involved.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/12218/12218363.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Property Transactions:</h4>
                        <p>
                          Valuation reports are essential for determining the
                          fair market value of properties bought, sold, or
                          rented.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/10496/10496526.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Estate Planning</h4>
                        <p>
                          For inheritance purposes, accurate valuations of
                          properties are crucial{" "}
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/9934/9934734.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Intellectual Property</h4>
                        <p>
                          Intangible assets like patents, trademarks, and
                          copyrights require specialized valuation techniques
                          for licensing, disputes, or other purposes.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/3696/3696746.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Insurance Purpose</h4>
                        <p>
                          Valuation of machinery and equipment is needed for
                          insurance purposes, asset sales, or depreciation
                          calculations.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* BENEFITS SECTION END */}

                  <hr />
          
                   {/* Why choose startup connect section start */}
                   <Whystartupconnect/>
                   {/* Why choose startup connect section end */}
                  <hr />
                  <h2 id="ip-h2">VALUATION REPORT PROCESS</h2>

                  {/* PROCESS SECTION START */}
                  <div class="row justify-content-center" style={{padding:"1rem"}}>
                    <div class="col-sm-3 stretch-card grid-margin" id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/free-vector/paying-bills-concept-illustration_114360-19357.jpg?w=740&t=st=1706779500~exp=1706780100~hmac=ad8e6bf9dc5da282c80e40c82c023c68f94bea72270aa35612471e5892f8985b"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold"> Step 1 </h4>
                          <div>
                            <p class="mb-0" style={{ fontSize: "1rem", textAlign:"center" }}>
                              Payment and Document Submission
                              <br />
                              Expert review your Documents
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    
                    <div class="col-sm-3 stretch-card grid-margin" id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/premium-vector/businessman-shaking-hands-deal-symbol-with-fellow-lawyer-lawyers-discuss-with-clients_923732-776.jpg?w=740"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold"> Step 2 </h4>
                          <div>
                            <p class="mb-0" style={{ fontSize: "1rem", textAlign:"center" }}>
                            Consultantion with Valuation expert{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-3 stretch-card grid-margin" id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/free-vector/product-quality-concept-illustration_114360-7301.jpg?w=740&t=st=1706780098~exp=1706780698~hmac=4ed66236fdef104f05dab6b8d7176409171e304e19a3758c389bc28837bbe13b"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold"> Step 3 </h4>
                          <div>
                            <p class="mb-0" style={{ fontSize: "1rem", textAlign:"center" }}>
                            Draft report submission to client
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-3 stretch-card grid-margin" id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/premium-vector/boy-looking-tax-form-laptop_118167-9401.jpg?w=740"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold"> Step 4 </h4>
                          <div>
                            <p class="mb-0" style={{ fontSize: "1rem", textAlign:"center" }}>
                            Final Report Submission 
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* PROCESS SECTION END */}

                  {/* Why StartUp Connect Starts */}

                  
                  {/* Why StartUp Connect Ends */}

                  <hr />

                  {/* Documents SECTION Starts */}
                  
                  {/* Documents SECTION Ends */}
                  {/* COMPLIANCES SECTION START */}
                  <h2 id="ip-h2">VALUATION METHODS </h2>
                  <p style={{ fontSize: "1rem" }}>
                  Determining the fair value of an asset or business is crucial for various purposes in India, such as mergers and acquisitions, taxation, financial reporting, and investment decisions. Several valuation methods are employed in India, each with its strengths and limitations. Let's delve into the commonly used ones:
                  </p>
                  <div
                    class="row"
                    style={{ textAlign: "left", padding: "2rem" }}
                  >
                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> Income-Based Valuation:</h3>
                    <p style={{ fontSize: "1rem" }}>
                          {" "}
                          <div >
                          This method focuses on the income-generating potential of the asset or business. It assumes that the value is directly linked to its future cash flows. Common techniques include:
                          </div>
                          <ul
                            style={{
                              listStyle: "initial",
                              fontSize: "1rem",
                              marginLeft: "10px",
                            }}
                          >
                            <li>
                            <b>Discounted Cash Flow (DCF):</b> Estimates the present value of future cash flows using a discount rate reflecting the asset's risk and required return. It's popular for valuing businesses with predictable cash flows.
                            </li>
                            <li>
                            <b>Capitalization:</b> Estimates the value based on the current or potential income and a capitalization rate (market-derived rate reflecting risk and return expectations). Widely used for valuing income-producing properties like commercial buildings.
                            </li>
                          </ul>
                        </p>
                    <br />
                    <br />

                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> Market-Based Valuation:</h3>
                    <p style={{ fontSize: "1rem" }}>
                          {" "}
                          <div >
                          This method focuses on the income-generating potential of the asset or business. It assumes that the value is directly linked to its future cash flows. Common techniques include:
                          </div>
                          <ul
                            style={{
                              listStyle: "initial",
                              fontSize: "1rem",
                              marginLeft: "10px",
                            }}
                          >
                            <li>
                            <b>Comparable Company Analysis:</b> Values the subject company by comparing its financial metrics (e.g., price-to-earnings ratio) to publicly traded companies in the same industry. Suitable for mature businesses with listed peers.
                            </li>
                            <li>
                            <b>Transaction Analysis:</b> Analyzes recent arm's length transactions of similar assets to determine market value. Useful for valuing assets like real estate in specific locations.
                            </li>
                          </ul>
                        </p>
                    <br />
                    <br />

                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> Asset-Based Valuation:</h3>
                    <p style={{ fontSize: "1rem" }}>
                          {" "}
                          <div >
                          This method focuses on the net value of the asset's underlying assets and liabilities. It's primarily used when income streams are difficult to estimate or the asset's value is less dependent on future earnings. Common techniques include:
                          </div>
                          <ul
                            style={{
                              listStyle: "initial",
                              fontSize: "1rem",
                              marginLeft: "10px",
                            }}
                          >
                            <li>
                            <b>Net Asset Value (NAV):</b> Simply sums up the fair market value of all assets and subtracts the total liabilities. Useful for valuing companies with significant tangible assets like manufacturing firms.
                            </li>
                            <li>
                            <b>Liquidation Value:</b> Estimates the value if the asset were sold piecemeal in a forced sale scenario. Relevant for valuing distressed businesses or assets intended for immediate liquidation.
                            </li>
                          </ul>
                        </p>
                    <br />
                    <br />

                    <h3><i class="mdi mdi-check-decagram text-warning" style={{fontSize:"2rem",padding:"1rem"}}></i>  Additional Considerations:</h3>
                    <p style={{ fontSize: "1rem" }}>
                    <ul
                            style={{
                              listStyle: "initial",
                              fontSize: "1rem",
                              marginLeft: "10px",
                            }}
                          >
                            <li>
                            <b>Hybrid Valuation:</b> Often, a combination of these methods is used to provide a more comprehensive valuation, especially for complex assets or businesses.
                            </li>
                            <li>
                            <b>Intangible Assets:</b> Valuing intangible assets like brand value or intellectual property requires specialized techniques beyond traditional methods.
                            </li>
                            <li>
                            <b>Regulations:</b> Valuation reports in India must comply with various regulations and professional standards, including the SEBI Valuation Norms and the Institute of Chartered Accountants of India (ICAI) guidance.
                            </li>
                          </ul>{" "}
                    </p>

                    <p style ={{fontSize:"1rem"}}><b>Remember:</b> Choosing the appropriate valuation method depends on various factors, including the purpose of valuation, the nature of the asset or business, and the availability of reliable data. It's recommended to consult with a qualified professional appraiser experienced in Indian regulations and best practices for accurate and defensible valuations.</p>
                    <br />
                    <br />

                    
                  </div>
                  {/* COMPLIANCES SECTION END */}
                
                  {/* COMPARISON TABLE START */}
                  

                  {/* COMPARISON TABLE END */}

                  <hr />

                  {/* FAQ SECTION END */}

                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
            {/* SIDEBAR AND MAIN PAGE END */}

            <Footer3 />
          </div>
          {/*} container-scroller */}
          {/*} plugins:js */}
          <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
          {/*} endinject */}
          {/*} Plugin js for this page */}
          <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
          <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
          <script
            src="panel/assets/js/jquery.cookie.js"
            type="text/javascript"
          ></script>
          {/*} End plugin js for this page */}
          {/*} inject:js */}
          <script src="panel/assets/js/off-canvas.js"></script>
          <script src="panel/assets/js/hoverable-collapse.js"></script>
          <script src="panel/assets/js/misc.js"></script>
          <script src="panel/assets/js/settings.js"></script>
          <script src="panel/assets/js/todolist.js"></script>
          {/*} endinject */}
          {/*} Custom js for this page */}
          <script src="panel/assets/js/dashboard.js"></script>
          {/*} End custom js for this page */}
        </body>
      </html>
    </>
  );
}

export default Valuation1;
