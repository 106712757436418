import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';
import moment from 'moment/moment';


import '../Resources/dashboard.css';
import Navbar from '../navbar';
import Sidebar from '../Sidebar';
import Create_business from './create_business.js';
import Edit_business from './edit_business.js';

import Chart from "chart.js/auto";
import { Line, Bar } from "react-chartjs-2";

function Userteam(){

    
 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");

 //Checking if Sessions are Set

// alert(logstatus,loguser,logadminid);
 //Sessions Not Set
{/* if(((logstatus==null)||(logstatus==""))||((logid==null)||(logid==""))||((token==null)||(token==" "))){
  
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   
 
    window.location.replace("/businesstools-login");
   }
  
   //Sessions Set
  else{ */}
    
//Variables
const[data,setData] = useState([]);//SetData Variable
const[transaction_history,settransaction_history] = useState([]);//Settransaction_history Variable

//Error Variable
const[errorvar,seterrorvar] = useState();
    
   useEffect(()=>{
   
    getData();
    
    },[])
   

  
//******************* GET TEAM MEMBER DATA FUNCTION START  ********************//
function getData(){
 

    //Checking if user loggedin
    if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))){
   
   
   axios.post("bt-get-team", {
     Log_id:logid,
     Token:token,
     Sn_start :0,
     Sn_end :0,
     Sn_total :0,
     Sn_limit :0,
    
   })
   .then((response) => {

    //Invalid Session
    if((response.data.error)==100){ 
        //Remove Session Variables and redirect to login
       sessionStorage.removeItem("logStatus00");
       sessionStorage.removeItem("logId00");
       sessionStorage.removeItem("logToken00");

       window.location.replace("businesstools-login");
    }
    //Success Data Fetched
    if(response.data.successvar==1){
     //Setting Data
     setData(response.data.data);
   
    }
    //Cannot Fetch Data
    else{
     seterrorvar("Cannot Fetch Data !");
    }
   
   
   });
   
   }
   //User not Logedin
   else{
   
   //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");

     window.location.replace("businesstools-login");
   
    }
   
   }
    
   //******************* GET TEAM MEMBER DATA FUNCTION END  ********************//


  
//******************* GET TEAM MEMBER DATA BY ID FUNCTION START  ********************//


//member_name
const[member_name,setmember_name]=useState();const updatemember_name = (event) => { setmember_name(event.target.value);}; const[member_name_er,setmember_name_er]=useState();
//member_username
const[member_username,setmember_username]=useState();const updatemember_username = (event) => { setmember_username(event.target.value);}; const[member_username_er,setmember_username_er]=useState();
//member_email
const[member_email,setmember_email]=useState();const updatemember_email = (event) => { setmember_email(event.target.value);};  const[member_email_er,setmember_email_er]=useState();
//member_contact
const[member_contact,setmember_contact]=useState();const updatemember_contact = (event) => { setmember_contact(event.target.value);};  const[member_contact_er,setmember_contact_er]=useState();
//member_details
const[member_details,setmember_details]=useState();const updatemember_details = (event) => { setmember_details(event.target.value);};  const[member_details_er,setmember_details_er]=useState();
//member_password
const[member_password,setmember_password]=useState();const updatemember_password = (event) => { setmember_password(event.target.value);};  const[member_password_er,setmember_password_er]=useState();
//member_cpassword
const[member_cpassword,setmember_cpassword]=useState();const updatemember_cpassword = (event) => { setmember_cpassword(event.target.value);};  const[member_cpassword_er,setmember_cpassword_er]=useState();



//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();


function getmemberdatabyId(){
 
  const team_id=sessionStorage.getItem("MemberId00");

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&(team_id>0)){
 
 
 axios.post("bt-team-details-byid", {
   Log_id:logid,
   Token:token,
   Team_id:team_id,
 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");

     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){

   //Setting Data
   setmember_name(response.data.name);
   setmember_username(response.data.username);
   setmember_email(response.data.email);
   setmember_contact(response.data.contact);
   setmember_details(response.data.details);
 
  }
  //Cannot Fetch Data
  else{


      document.getElementById("edit-form").style.display="none";

     //Setting Variables to empty 
     setmember_name(" ");setmember_username(" ");setmember_email(" ");setmember_contact(" ");setmember_details(" ");seterror_msg(" ");
     setmember_name_er(" ");setmember_username_er(" ");setmember_email_er(" ");setmember_contact_er(" ");setmember_details_er(" ");seterror_msg(" ");

      document.getElementById("edit-error-card").style.display="block"; 
      sessionStorage.removeItem("MemberId00");
  }
 
 
 });
 
 }
 //User not Logedin
 else{

     //Checking if user loggedin
     if(((logstatus==null)||(logstatus==""))||((logid==null)||(logid==""))||((token==null)||(token==" "))){
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");

   window.location.replace("businesstools-login");
     }
 
  }
 
 }
  
 //******************* GET TEAM MEMBER DATA BY ID FUNCTION END  ********************//





//******************* EDIT TEAM MEMBER FUNCTION START ********************//
function editteammember(){

  const member_id=sessionStorage.getItem("MemberId00");

  //Setting Error Variables
  setmember_name_er(" ");setmember_username_er(" ");setmember_email_er(" ");setmember_contact_er(" ");setmember_details_er(" ");setmember_password_er(" ");setmember_cpassword_er(" ");seterror_msg(" ");

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((member_id!=null)||(member_id!=" "))){
  
 axios.post("bt-team-edit", {
   Team_id:member_id,
   Log_id:logid,
   Token:token,
   Name : member_name,
   Username : member_username,
   Email : member_email,
   Contact : member_contact,
   Details : member_details,
   Password : member_password,
   Confirm_password : member_cpassword,
  
 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");

     window.location.replace("businesstools-login");
  }

   //Validation Errors
 if(response.data.validatorerror){
  setmember_name_er(response.data.validatorerror.Name);
  setmember_username_er(response.data.validatorerror.Username);
  setmember_email_er(response.data.validatorerror.Email);
  setmember_contact_er(response.data.validatorerror.Contact);
  setmember_details_er(response.data.validatorerror.Details);
  setmember_password_er(response.data.validatorerror.Password);
  setmember_cpassword_er(response.data.validatorerror.Confirm_password);

}
//Invalid Entries
if(response.data.error==102){ 
  
  setmember_name_er(response.data._name_er);
  setmember_username_er(response.data._username_er);
  setmember_email_er(response.data.email_er);
  setmember_contact_er(response.data.contact_er);
  setmember_details_er(response.data.details_er);
  setmember_password_er(response.data.password_er);
  
  seterror_msg("Cannot Update Team Information!");  }


  //eBusiness Name Already Exists
//  if(response.data.error==103){     seterror_msg("Business Name Already Exists!");  }

  //Business Editted Successfully
   if(response.data.successvar==1){        

      document.getElementById("edit-form").style.display="none";
      document.getElementById("edit-success-card").style.display="block";  

      //Setting Variables to empty 
      setmember_name(" ");setmember_username(" ");setmember_email(" ");setmember_contact(" ");setmember_details(" ");setmember_password(" ");seterror_msg(" ");
      setmember_name_er(" ");setmember_username_er(" ");setmember_email_er(" ");setmember_contact_er(" ");setmember_details_er(" ");setmember_password_er(" ");seterror_msg(" ");
      sessionStorage.removeItem("MemberId00");

  }
  
      //Cannot Edit Business
   else{  seterror_msg("Cannot Update Team Information !"); }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* EDIT BUSINESS FUNCTION END ********************//



   
//******************* DELETE BUSINESS FUNCTION START ********************//
function deletebusiness(){

  const memberId=sessionStorage.getItem("DMemberId00")
  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((memberId!=null)||(memberId!=" "))){
 
 axios.post("bt-team-delete", {
   Team_id:memberId,
   Log_id:logid,
   Token:token,
 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");

     window.location.replace("businesstools-login");
  }

  //Business Deleted Successfully
   if(response.data.successvar==1){        

      document.getElementById("delete-card").style.display="none";
      document.getElementById("delete-success-card").style.display="block";  
      sessionStorage.removeItem("DMemberId00");

  }
  
      //Cannot Delete Business
    else{  
    document.getElementById("delete-card").style.display="none";
    document.getElementById("delete-error-card").style.display="block";   }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* DELETE BUSINESS FUNCTION END ********************//



return(
    <>
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Business Tools|BookKeeping Solution</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div class="container-scroller">

    <div class="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div class="main-panel">

     
                   {/* EDIT SUCCESS CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="edit-success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Team Member Information Updated Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("edit-success-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* EDIT SUCCESS CARD END */}
               {/* EDIT ERROR CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="edit-error-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-danger">Cannot Fetch Data !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("edit-error-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* EDIT ERROR CARD END */}
               {/* DELETE CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-danger">Are you sure you want to delete this team member profile?</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> This action cannot be reversed.  (Data added by by team member will not be deleted).</p>
                      <button class="mt-3 btn btn-outline-success" onClick={deletebusiness}>Yes, I Know! Delete Team Account.</button>
                      <button class="mt-3 btn btn-outline-danger" style={{marginLeft:"1rem"}} onClick={()=>{  
                      //Cancel Delete and remove Busy_id
                      sessionStorage.removeItem("DMemberId00");document.getElementById('delete-card').style.display = 'none'; }}>No, Don't Delete Account.</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* DELETE CARD END */}
                           {/* DELETE SUCCESS CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Team Account Deleted Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("delete-success-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* DELETE SUCCESS CARD END */}
               {/* EDIT ERROR CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-error-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-danger">Cannot Delete Team Account !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("delete-error-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* EDIT ERROR CARD END */}

          <div class="content-wrapper pb-0">


            <div class="page-header flex-wrap">
            <div class="header-left"></div>
              <div class="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div class="d-flex align-items-center">
                  <a href="#">
                    <p class="m-0 pe-3">Team Dashboard</p>
                  </a>
                  <a class="ps-3 me-4" href="#">
                   {/*} <p class="m-0">ADE-00234</p> */}
                  </a>
                </div>
                <a href="/businesstools-user-register"><button type="button" class="btn btn-primary mt-2 mt-sm-0 btn-icon-text">
                  <i class="mdi mdi-plus-circle"></i> Add New Team </button></a>
              </div>
            </div>


           {/* TEAM EDIT FORM START */}
            <div class="row">
              <div class="col-12 grid-margin create-form" id="edit-form" style={{display:"none"}}>
              <div class="card">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("edit-form").style.display="none"; }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                  <div class="card-body" style={{textAlign:"left"}}>
                    <h4 class="card-title">Edit Team Account</h4>
                    <div class="form-sample">
                      <p class="card-description">edit your team member details here</p>
                      <p><code>{error_msg}</code></p>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Name<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Rohan" id="member_name" value={member_name} onChange={updatemember_name} />
                            <code>{member_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Username<code>*</code></label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="Rohan12345" id="member_username" value={member_username} onChange={updatemember_username} />
                            <code>{member_username_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Email<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="email" class="form-control" placeholder="abccompany@company.com"  id="member_email" value={member_email} onChange={updatemember_email} />
                            {member_email_er}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Contact<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="9100000000000"  id="member_contact" value={member_contact} onChange={updatemember_contact} />
                            <code>{member_contact_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Password</label>
                            <div class="col-sm-9">
                              <input type="password" class="form-control" placeholder="Password"  id="member_password" value={member_password} onChange={updatemember_password} />
                            <code>{member_password_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Confirm Password</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Confirm Password"  id="member_cpassword" value={member_cpassword} onChange={updatemember_cpassword} />
                            <code>{member_cpassword_er}</code>
                            </div>
                          </div>
                        </div>
                       
                      </div>
                      <div class="form-group">
                        <label for="exampleTextarea1">Details</label>
                        <textarea  class="form-control" rows="2"  id="member_details" value={member_details} onChange={updatemember_details} ></textarea>
                       <code>{member_details_er}</code>
                      </div>

                      <button class="btn-lg btn-warning" style={{fontWeight:"bold"}} onClick={editteammember} >Edit Team</button>
      
                    </div>
                  </div>
                </div>
              </div>
          </div>
          {/* TEAM EDIT FORM END */}



                  
                
             <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">My Team</h4>
                    <p class="card-description">manage your team
                    </p>
                    <div class="table-responsive">
                      <table class="table table-hover">
                        <thead>
                          <tr >
                            <th  style={{fontWeight:"bold"}}>Member Id</th>
                            <th  style={{fontWeight:"bold"}}>Username</th>
                            <th  style={{fontWeight:"bold"}}>Name</th>
                            <th  style={{fontWeight:"bold"}}>Email</th>
                            <th  style={{fontWeight:"bold"}}>Contact</th>
                            <th  style={{fontWeight:"bold"}}>Details</th>
                            <th  style={{fontWeight:"bold"}}>Date_created</th>
                            <th  style={{fontWeight:"bold"}}>Time_created</th>
                            <th  style={{fontWeight:"bold"}}></th>
                            <th  style={{fontWeight:"bold"}}></th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                      data.map((curElm1,ind)=>{

return(
  <>
                        <tr key={curElm1.Member_id}>
                            <td>{curElm1.Member_id}</td>
                            <td>{curElm1.Username}</td>
                            <td>{curElm1.Name}</td>
                            <td>{curElm1.Email}</td>
                            <td>{curElm1.Contact}</td>
                            <td>{curElm1.Details}</td>
                            <td>{curElm1.Date_created}</td>
                            <td>{curElm1.Time_created}</td> 
                            <td id="cd_co13" ><i class="mdi mdi-pencil-box text-warning" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                            //Setting Member Id and Redirect to Edit
                            sessionStorage.setItem("MemberId00",curElm1.Member_id);getmemberdatabyId();document.getElementById('edit-form').style.display = 'block'; }}></i></td>
                            <td id="cd_oc14" ><i class="mdi mdi-delete-forever text-danger" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                            //Setting Member Id for Delete
                            sessionStorage.setItem("DMemberId00",curElm1.Member_id);  document.getElementById('delete-card').style.display = 'block'; }}></i></td>
      
                          </tr>
                          </>
      )
      }
					   
			)}
                       
                        </tbody>
                      </table>
                    </div>
                        {/* PAGINATION SECTION START */}
                     {/*}   <div>
                       <div class="col-12" style={{marginBottom:"2rem",marginTop:"1rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                       
                          <div class="dropdown">
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber-1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-left-drop-circle text-success"></i></button>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber+1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-right-drop-circle text-success"></i></button>
                          </div>
                          </div>
                          </div>
                    </div> */}
                    {/* PAGINATION SECTION END */}
                  </div>
                </div>
              </div>
            
          </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer class="footer">
            <div class="d-sm-flex justify-content-center justify-content-sm-between">
              <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
</>
);

   }

//}
export default Userteam;