import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import ServicesCards from './components/ServicesCards/ServicesCards'
import AllFeatures from './components/AllFeatures/AllFeatures';
import Contact from './homecontactform';

import './homeh.css';
import './Supportingfiles/Searchbar.css';

import HomeA from './abc';
import Navbar from './Supportingfiles/navbar';
import Footer from './Supportingfiles/footer';
import Contactform1 from './homecontactform';
import Footer3 from './Supportingfiles/footer3';
/*import Amanbg from './amanbg';*/


function Newhome(){

  //saving default values with usestate
const state = useState();

  
//name
const[cname,setcname]=useState();const updatecname = (event) => { setcname(event.target.value);};
//email
const[email,setemail]=useState();const updateemail = (event) => { setemail(event.target.value);};
//contact
const[contact,setcontact]=useState();const updatecontact = (event) => { setcontact(event.target.value);};
//cstate
const[cstate,setcstate]=useState();const updatecstate = (event) => { setcstate(event.target.value);};
//searchvalue
/*const [searchvalue, setsearchvalue] = useState(''); const onSearchChange = event => {   if(event.key === 'Enter'){
  event.preventDefault();
  onSearch();
}    setsearchvalue(event.target.value); }
*/

//searchtext
const[searchtext,setsearchtext]=useState();
const [searchInput, setSearchInput] = useState('');
const [filteredResults, setFilteredResults] = useState([]);
const [selectvalue, setselectvalue] = useState('');
const [searchid, setsearchid] = useState('');
const [APIData, setAPIData] = useState([]);




const [searchString, setSearchString] = useState();


/* SEARCH BAR FUNCTIONALITY START */


  /*****  SEARCH BAR  ******/
  
  //Fetching The CONTENT LIST
  function getData(){
    
    const frtdata=[
    

     {  id:1 , Name:"Social Media Management" , Link:"/socialmediamanagement" },
      {  id:2 , Name:"Digital Marketing" , Link:"/digitalmarketing" },
      {  id:3 , Name:"Data Consultancy" , Link:"/dataconsultancy" },
      {  id:4 , Name:"Design Branding Consultancy" , Link:"/designbrandingconsultancy" },
      {  id:5 , Name:"E-Commerce Consultancy" , Link:"/ecommerceconsultancy" },
      {  id:6 , Name:"GST Filing" , Link:"/gstfiling" },
      {  id:7 , Name:"GST Registration" , Link:"/gstregistration" },
      {  id:8 , Name:"HR Consultancy" , Link:"/hrconsultancy" },
      {  id:9 , Name:"IT & Tech Consultancy" , Link:"/ittechconsultancy" },
      {  id:10 , Name:"ITR Filing" , Link:"/itrfiling" },
      {  id:11 , Name:"LLP Registration" , Link:"/llpregistration" },
      {  id:12 , Name:"Marketing Consultancy" , Link:"/marketingconsultancy" },
      {  id:13 , Name:"Management Consultancy" , Link:"/managementconsultancy" },
      {  id:14 , Name:"MSME Registration" , Link:"/msmeregistration" },
      {  id:15 , Name:"OPC Registration" , Link:"/opcregistration" },
      {  id:16 , Name:"Pitch Deck Creation" , Link:"/pitchdeckcreation" },
      {  id:17 , Name:"Partnership Registration" , Link:"/partnershipregistration" },
      {  id:18 , Name:"Rent Agreement Drafting" , Link:"/rentagreementdrafting" },
      {  id:19 , Name:"Resume Building" , Link:"/resumebuilding" },
      {  id:20 , Name:"Section-8 Registration" , Link:"/section8registration" },
      {  id:21 , Name:"Sales Consultancy" , Link:"/salesconsultancy" },
      {  id:22 , Name:"Strategy Consultancy" , Link:"/strategyconsultancy" },
      {  id:23 , Name:"Startup India Registration" , Link:"/startupindiaregistration" },
      {  id:24 , Name:"Sole Proprietorship Registration" , Link:"/soleproprietorshipregistration" },
      {  id:25 , Name:"Accounting Software" , Link:"accountingsoftware" },
      {  id:27 , Name:"Billing Software" , Link:"/billingsoftware" },
      {  id:28 , Name:"Copywriting" , Link:"/copywriting" },
      {  id:29 , Name:"CRM Software" , Link:"/crmsoftware" },
      {  id:30 , Name:"Content Writing" , Link:"/contentwriting" },
      {  id:31 , Name:"Emailing & Messaging" , Link:"/emailingmessaging" },
      {  id:32 , Name:"E-Commerce Store Setup" , Link:"/e-commercestoresetup" },
      {  id:33 , Name:"ERP Software" , Link:"/erpsoftware" },
      {  id:31 , Name:"Graphics Designing" , Link:"/graphicsdesigning" },
      {  id:32 , Name:"Inventory Management Software" , Link:"/inventorymanagementsoftware" },
      {  id:33 , Name:"PoS Software" , Link:"/possoftware" },
      {  id:34 , Name:"Product Promotion" , Link:"/productpromotion" },
      {  id:35 , Name:"Search Engine Optimization (SEO)" , Link:"/seo" },
      {  id:36 , Name:"Customized Solutions" , Link:"/customizedsolutions" },
      {  id:37 , Name:"E-Commerce Website" , Link:"/ecommercewebsite" },
      {  id:38 , Name:"Institution Website" , Link:"/institutionwebsite" },
      {  id:39 , Name:"Members Management" , Link:"/membersmanagement" },
      {  id:40 , Name:"Platform as a Service(PaaS)" , Link:"/paas" },
      {  id:41 , Name:"Software as a Service" , Link:"/saas" },
      {  id:42 , Name:"Private Limited Company Registration" , Link:"/privatelimitedcompany" },
      {  id:43 , Name:"Static Display Website" , Link:"/staticdisplaywebsite" },
      {  id:44 , Name:"Web App" , Link:"/webapp" },
 
  ];
    
    const actualData = frtdata;
      setAPIData(actualData);
  }

  //Search Bar Search
  const searchItems = (searchValue) => {
  
    document.getElementById("myMenu").style.display="block";
    
    setSearchInput(searchValue)
  
    if (searchInput !== '') {
        const filteredData = APIData.filter((item) => {
            return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
        })
        setFilteredResults(filteredData)
    }
    else{
        setFilteredResults(APIData)
        document.getElementById("myMenu").style.display="none";
    }
  }
  
  //set search content
  function setsearchcontent(){
  
    setselectvalue(document.getElementById("myMenu").value);
    
    setsearchtext(selectvalue);
    
    }
    
    
    //Search Contents
    function searchContents(){
    
    window.location.replace(selectvalue);
    
    }
  
    
    useEffect(()=>{
    
      //fetchdefault();
      getData();
    
    },[])

    
/* SEARCH BAR FUNCTIONALITY END */



    

function callfunction(){

/*alert("Hello");*/
}
    return(
    <>

<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />

    <meta name="google-site-verification" content="6BF7NEnFOt-Szyse13Lbia8vk2BLKxK_kLNzqcJ7EbU" />
    
    <meta name="theme-color" content="#000000" />
    <meta
      name="description"
      content="Get your startup services relating to website development, consultancy, taxation, business registration, software services, intern hiring, social media management, digital marketing and much more.  "></meta>
    
   {/* <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />*/}
        <link rel="icon" href="./images/startupconnect_icon.ico" />
    <link rel="apple-touch-icon" href="./images/startupconnect_icon.png" />

    {/*} Bootstrap CSS */}
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous" />
 

    <title>theStartupConnect | Services for Business and Startup</title>
  </head>
  <body>
  <div className="searchbar-dropdown"></div>
  

  {/* CONTACT FORM EXTRA START */}
  <div id="ext-ct-form" style={{display:"none"}}>
<Contactform1></Contactform1>
  </div>
  {/* CONTACT FORM EXTRA START */}

   {/* WHATSAPP ICON START */}
   <div className="whatsapp-section">
<div>
  <a href="https://api.whatsapp.com/send?phone=9399395256"><img src="https://cdn-icons-png.flaticon.com/512/2504/2504957.png" id="whatsapp-icon"></img></a>
</div>
</div>
   {/* WHATSAPP SECTION END */}
    <div className="home-container">

{/* HEADER */}


<Navbar></Navbar>

{/* NAVBAR EXTRA START 
<div class="pos-f-t">
  <div class="collapse" id="navbarToggleExternalContent">
    <div class="bg-dark p-4">
      <h4 class="text-white">Collapsed content</h4>
      <span class="text-muted">Toggleable via the navbar brand.</span>
    </div>
  </div>
  <nav class="navbar navbar-dark bg-dark">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  </nav>
</div>
 NAVBAR EXTRA END */}
 {/* SUCCESS ALERT */}
 <div class="alert alert-success" id="contact-alert" role="alert" style={{width:"80vw",marginLeft:"10vw",display:"none"}}>
 <div>
  Thanks! for Contacting us. Your Contact Details have been Successfully sent to our team. Our team will soon reach out to you!
  <img src="https://cdn-icons-png.flaticon.com/512/399/399274.png" style={{height:"2rem",width:"2rem",marginLeft:"6rem"}} onClick={()=>{document.getElementById("contact-alert").style.display="none";}}/>
  </div>
</div>
{/* HEADER SECTION START */}
<div className="header-section">
{/* HEADER CONTENT START */}
<div className="header-content">

{/* Animation Circles Start */}
<div class="area">
{/*<ul class="circles">
                         <li></li>
                         <li></li>
                         <li></li>
                         <li></li>
                         <li></li>
                         <li></li>
                         <li></li>
                         <li></li>
                         <li></li>
                         <li></li>
                 </ul>*/}
{/* Animation Circles End */}
  <div className="header-content-section">
  <div className="main-header">
       
       <div className="main-header-sub">
       <h1>Professional Services for <span style={{color:"#f26e49"}}>Startup and Business</span></h1>
       </div>
       <div className="hd-para">
      
     </div>
  </div>
  {/* SEARCH BAR START */}
  <div className="search-bar">
  <div className="form-group">
                      <div className="input-group">
                        <input type="text" className="form-control" placeholder="Search Service that suites you" /*id="name"*/ aria-label="Recipient's username" aria-describedby="basic-addon2" /*value={searchvalue} onChange= { onSearchChange }*/ id="searchtext" onChange={(e) =>{ searchItems(e.target.value)}} value={searchtext}  />
                       
                      {/*  <button id="search-btn-btn"><img src="https://cdn-icons-png.flaticon.com/512/751/751381.png" id="search-btn" /></button> */}
                         {/*  <div className="input-group-append">
                          <button className="btn btn-sm btn-gradient-primary" id="search-btn" type="button">Search</button> 
                           </div>*/}
                           <div className="input-group-append">
                          <button className="btn btn-sm btn-gradient-primary" id="search-btn" type="button" /*onClick={()=> onSearch(searchvalue)}*/ onClick={searchContents}><img src="https://cdn-icons-png.flaticon.com/512/10070/10070371.png" style={{width:"2rem",height:"2rem"}} id="search-btn-icon" /></button> 
                           </div>
                      </div>
                      <div className="dropdown" >
                    <select className='dropdown-item mt-2' data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" size="5" style={{display:"none"}} /*id="dropdown"*/ id="myMenu">
                    { searchInput.length > 1 ? (
                    filteredResults.map((item) => {
                        return (
                            <option id="search-menu-content" key={item.Id} value={item.Link} onClick={setsearchcontent}>{item.Name}</option>
                        )
                    })
                ) : (
                    APIData.map((item) => {
                        return (
                          <option id="search-menu-content" key={item.Id} value={item.Link} >{item.Name}</option>
                        
                        )
                    })
                )}
                                       { /* data.filter(item => {

                            
                            const searchTerm = searchvalue.toLocaleLowerCase();
                            const fullName = item.full_name.toLowerCase();
                            return (searchTerm && fullName.startsWith(searchTerm) && fullName !== searchTerm);
                        })
                        .map((item)=> (
                            <div onClick={() => onSearch(item.full_name)} className='dropdown-row' key={item.full_name}>
                            {item.full_name} 
                            </div>
                            )) */}
                       
                        
                       </select>
                    
                
                </div>
                    </div>
                    </div>
               
                    <div class="searchbar-suggestions"></div>
                
  {/* SEARCH BAR END */}

  {/* SERVICES SUGGESTION BOX START */}
  <div className="suggestion-box-section">
  Popular:
  <span><a href="/privatelimitedcompany"><button className="btn btn-sm btn-gradient-primary" id="suggestion-box-1"  type="button">Private Limited Registration</button></a></span>
  <span><a href="/startupindiaregistration"><button className="btn btn-sm btn-gradient-primary" id="suggestion-box-3"  type="button">DPIIT Registration</button></a></span>
  <span><a href="/ecommercewebsite"><button className="btn btn-sm btn-gradient-primary" id="suggestion-box-3"  type="button">E-Commerce Website</button></a></span>
  <span><a href="/saas"><button className="btn btn-sm btn-gradient-primary" id="suggestion-box-2"  type="button">Software Development</button></a></span>
{/*<span id="v-all"><a href="/consultancy">View All</a></span>*/}
<span><a href=""><button className="btn btn-sm btn-gradient-primary" id="suggestion-box-4"  type="button">View All</button></a></span>

  </div>
  {/* SERVICES SUGGESTION BOX END */}

</div>
{/* FEATURES SECTION START */}
<div className="features-section">
  <div className="features-card-list">
  
  {/* Features Card 1 Start*/}
  <div className="feature-card" style={{border:"none"}}>
    <div class="fc-left">
    </div>
    <div class="fc-right">
    
      <div id="card-sub"><h3>500+ </h3><h5>Projects Done</h5></div>
    </div>
  </div>
    {/* Features Card 1 End */}

     {/* Features Card 2 Start*/}
  <div className="feature-card" style={{border:"none"}}>
    <div class="fc-left">
    </div>
    <div class="fc-right">
    
      <div id="card-sub"><h3>10+</h3><h5>Cities Served</h5></div>
    </div>
  </div>
    {/* Features Card 2 End */}

     {/* Features Card 3 Start*/}
     <div className="feature-card" style={{border:"none"}}>
    <div class="fc-left">

    </div>
    <div class="fc-right">
    
    <div id="card-sub"><h3>4<img src="https://cdn-icons-png.flaticon.com/512/1828/1828614.png" id="crd-str" alt="star image"></img>+  </h3><h5>Google Rating</h5></div>
    </div>
  </div>
    {/* Features Card 3 End */}
  </div>
</div>

{/* FEATURES SECTION END */}

</div>
<button id="gt-consult" onClick={()=>{document.getElementById("ext-ct-form").style.display="block";}}>Book Consultancy</button>
</div>
{/* CONTACT FORM START  */}

{/*<Contact></Contact>*/}
<div class="col-lg-6 order-1 order-lg-2 hero-img" id="ct-img">
          <img src="assets/images/hero-img.png" class="img-fluid animated" id="header-img" style={{width:"70vw",height:"70vh"}} alt="" />
        </div>

</div>


{/* HEADER SECTION END */}


{/* SERVICES SECTION START - GSJ */}
<AllFeatures/>
{/* SERVICES SECTION END - GSJ */}
{/* SERVICES LIST START */}
<div class="services-list">

<div class="services-list-row">


 </div> 
</div>


{/* SERVICES LIST END */}
{/*<div class="text-center"><button class="btn btn-link">View All</button></div>*/}
{/*<span id="v-all"><a href="/consultancy">View All</a></span>*/}
<span><a href=""><button className="btn btn-sm btn-gradient-primary" id="suggestion-box-4"  type="button">View All</button></a></span>

{/* VIDEO SECTION START */}
<div class=""></div>
{/* VIDEO SECTION END */}

{/* ABOUT SECTION START */}
<h2 style={{textAlign:"center",fontSize:"2.4rem",marginTop:"2rem"}}>Why choose us?</h2>
<div class="about-section">

  <div class="about-section-left">
 
  <ul>
    <li><img src="https://cdn-icons-png.flaticon.com/512/9690/9690019.png" id="about-list-icon"></img><span id="about-list-heading">Reliable</span><br/>
    <span id="about-list-content">At The Startup Connect, we prioritize reliability in all of our services to ensure consistent and trustworthy support for your business growth.</span></li>
    <li><img src="https://cdn-icons-png.flaticon.com/512/9321/9321632.png" id="about-list-icon"></img><span id="about-list-heading">Efficient</span><br/>
    <span id="about-list-content">Efficiency is key to success, and at The Startup Connect, we strive to optimize our services for maximum efficiency and productivity.</span></li>
    <li><img src="https://cdn-icons-png.flaticon.com/512/998/998412.png" id="about-list-icon"></img><span id="about-list-heading">Professional</span><br/>
    <span id="about-list-content">Our team at The Startup Connect is comprised of experienced professionals dedicated to providing top-notch services to help your business succeed.</span></li>

  </ul>
  </div>
  <div class="about-section-left">

  <ul>
    <li><img src="https://cdn-icons-png.flaticon.com/512/7601/7601227.png" id="about-list-icon"></img><span id="about-list-heading">Quality Served</span><br/>
    <span id="about-list-content">At The Startup Connect, we believe in delivering quality services that exceed expectations and drive long-term success for your business.</span></li>
    <li><img src="https://cdn-icons-png.flaticon.com/512/4954/4954479.png" id="about-list-icon"></img><span id="about-list-heading">Convenient</span><br/>
    <span id="about-list-content">We understand the value of time and offer convenient services to help you focus on growing your business while we take care of the rest at The Startup Connect.</span></li>
    <li><img src="https://cdn-icons-png.flaticon.com/512/4719/4719440.png" id="about-list-icon"></img><span id="about-list-heading">Budget Friendly</span><br/>
    <span id="about-list-content">At The Startup Connect, we offer budget-friendly services without compromising on quality to help your business achieve its goals within your budget.</span></li>

  </ul>
  </div>

</div>
{/* ABOUT SECTION END */}

{/* Clients Section Start */}
<div class="clients-section">
  <img src="" id="client-logo"></img>
</div>
{/* Clients Section End */}

<h2 style={{textAlign:"center",marginTop:"3rem"}}>OUR CLIENTS</h2>
{/* BRAND SECTION START */}
<div class="brand-line">
 {/*} <div class="brand-tag"> */}
    <img src="./images/clients/client-1.jpeg" id="brand-tag-img"></img>
    <img src="./images/clients/client-2.png" id="brand-tag-img"></img>
    <img src="./images/clients/client-3.jpeg" id="brand-tag-img"></img>
    <img src="./images/clients/client-6.png" id="brand-tag-img"></img>
    <img src="./images/clients/client-5.jpg" id="brand-tag-img"></img>
 {/*} </div> */}
</div>
{/* BRAND SECTION END */}

{/* Testimonials Section Start */}
<div class="testimonials-section">
<section class="home-testimonial">
    <div class="container-fluid">
        <div class="row d-flex justify-content-center testimonial-pos">
            <div class="col-md-12 pt-4 d-flex justify-content-center">
                <h3>Testimonials</h3>
            </div>
            <div class="col-md-12 d-flex justify-content-center">
                <h2>What our clients have to say about us.</h2>
            </div>
        </div>
        <section class="home-testimonial-bottom">
            <div class="container testimonial-inner">
                <div class="row d-flex justify-content-center">
                    <div class="col-md-4 style-3">
                        <div class="tour-item ">
                            <div class="tour-desc bg-white">
                                <div class="tour-text color-grey-3 text-center">&ldquo;We have used your service to made our company Private limited and yah I 100% satisfied with your service.&rdquo;</div>
                                <div class="d-flex justify-content-center pt-2 pb-2"><img class="tm-people" src="https://cdn-icons-png.flaticon.com/512/2202/2202112.png" alt="" /></div>
                                <div class="link-name d-flex justify-content-center">Rahul Rai</div>
                                <div class="link-position d-flex justify-content-center">Founder and CEO, Starial.in</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 style-3">
                        <div class="tour-item ">
                            <div class="tour-desc bg-white">
                                <div class="tour-text color-grey-3 text-center">&ldquo;I had a great experience working with Technomate.
From start to finish, they were professional, knowledgeable, and responsive to my needs. They helped me with my taxes and provided me with valuable advice on how to manage my finances. 
Thanks for all of your help!&rdquo;</div>
                                <div class="d-flex justify-content-center pt-2 pb-2"><img class="tm-people" src="https://cdn-icons-png.flaticon.com/512/2202/2202112.png" alt="" /></div>
                                <div class="link-name d-flex justify-content-center">Lucky Sharma</div>
                                <div class="link-position d-flex justify-content-center">Happy Customer</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 style-3">
                        <div class="tour-item ">
                            <div class="tour-desc bg-white">
                                <div class="tour-text color-grey-3 text-center">&ldquo;We used Startup Connect's services to get our Tech Consultancy and they are also our compliance partner for regular tax filings and compliances management &rdquo;</div>
                                <div class="d-flex justify-content-center pt-2 pb-2"><img class="tm-people" src="https://cdn-icons-png.flaticon.com/512/2202/2202112.png" alt="" /></div>
                                <div class="link-name d-flex justify-content-center">Rahul Roy</div>
                                <div class="link-position d-flex justify-content-center">Rojgar Bandhu</div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
        </section>
        </div>
</section>
</div>

{/* Testimonials Section End */}



<Footer></Footer> 

{/*<script src="https://code.jquery.com/jquery-3.3.1.slim.min.js" integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo" crossorigin="anonymous"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js" integrity="sha384-UO2eT0CpHqdSJQ6hJty5KVphtPhzWj9WO1clHTMGa3JDZwrnQq4sF86dIHNDz0W1" crossorigin="anonymous"></script>
<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js" integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM" crossorigin="anonymous"></script>
  
  {/* } Font Awesome 5 links */}
  <script src="https://kit.fontawesome.com/fddf5c0916.js" crossorigin="anonymous"></script>

</div>



    {/*} Optional JavaScript; choose one of the two! */}

    {/*} Option 1: Bootstrap Bundle with Popper */}
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>

    {/*} Option 2: Separate Popper and Bootstrap JS */}
    {/*}
    <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js" integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p" crossorigin="anonymous"></script>
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.min.js" integrity="sha384-cVKIPhGWiC2Al4u+LWgxfKTRIcfu0JTxR+EQDz/bgldoEyl4H0zUF0QKbrJ0EcQF" crossorigin="anonymous"></script>
    */}
  </body>
</html>
         
    </>
    );
}

export default Newhome;