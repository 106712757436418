import React from "react";
import { useState, useEffect, Component } from "react";
import { useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
//import axios from '../../axios.js';
import moment from "moment/moment";

//import "./../Resources/homebtcss.css";
import "../../Resources/home2css.css";
import "../../Resources/serviceshomecss.css";

import "../../Resources/homebtcss.css";
import '../../Resources/responsiveness.css';
//import Sidebar from "./sidebar";

//import Chart from "chart.js/auto";
//import { Line, Bar } from "react-chartjs-2";
import Footer3 from "../../Supportingfiles/footer3";
//import { Navbar } from "flowbite-react";
import Navbar3 from "../../Supportingfiles/navbar3";
import Whystartupconnect from "../whystartupconnect";


function Webapp1() {
  return (
    <>
      <html lang="en">
        <head>
          {/*} Required meta tags */}
          <meta charset="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <title>STARTUP CONNECT | SERVICES | WEBAPP</title>
          {/*} plugins:css */}
          <link
            rel="stylesheet"
            href="panel/assets/vendors/mdi/css/materialdesignicons.min.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/css/vendor.bundle.base.css"
          />
          {/*} endinject */}
          {/*} Plugin css for this page */}
          <link
            rel="stylesheet"
            href="panel/assets/vendors/jquery-bar-rating/css-stars.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/font-awesome/css/font-awesome.min.css"
          />
          {/*} End plugin css for this page */}
          {/*} inject:css */}
          {/*} endinject */}
          {/*} Layout styles */}
          <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
          {/*} End layout styles */}
          <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
        </head>
        <body>

        
  {/* Whatsapp Chat Icon Start */}
  <div class="wtp-chat-container" /*style={{position:"fixed",marginTop:"92vh", marginLeft:"94vw"}}*/>
    <a aria-label="Chat on WhatsApp" href="https://wa.me/918480002413?text=I%20want%20to%20know%20more%20about%20service%20Web App%20Website%20Development%20by%20the%20Startup%20Connect">   
    <img class="wtp-chat-icon" src="https://cdn-icons-png.flaticon.com/128/3938/3938058.png" /*style={{height:"3rem", width:"3rem"}}*/></img>
  </a>
  </div>
  {/* Whatsapp Chat Icon End */}


          <div class="home2-container">
            <Navbar3 />

            {/* HEADER SECTION START */}
            <section /*class="sv-header-section" */>
              <div class="sv-header-container1">
                <div class="sv-header-left home-header-pd">
                  <h1 style={{ color: "#004463" }}>
                    WEB APP
                  </h1>
                  <p><b>A web app is a website with interactive functionality to perform specific tasks.</b></p>
                  <p>Web App is a software application that is accessible through a web browser. It allows users to interact with dynamic content, perform specific tasks, and often includes a back-end database. Examples include online marketplaces, social media platforms, and productivity tools.</p>

                  <div class="home2-services-features-list">
                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img loading = "lazy"

                          src="https://cdn-icons-png.flaticon.com/128/1087/1087840.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>500+</h3>
                        <h5 style={{ fontWeight: "bold" }}>Projects</h5>
                      </div>
                    </div>

                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img loading = "lazy"

                          src="https://cdn-icons-png.flaticon.com/128/12735/12735475.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>25+</h3>
                        <h5 style={{ fontWeight: "bold" }}>Cities</h5>
                      </div>
                    </div>

                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img loading = "lazy"

                          src="https://cdn-icons-png.flaticon.com/128/2991/2991235.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>
                          4{" "}
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/7656/7656139.png"
                            style={{ height: "2rem", width: "2rem" }}
                          />
                          +
                        </h3>
                        <h5 style={{ fontWeight: "bold" }}>Google Ratings</h5>
                      </div>
                    </div>
                  </div>

                  <div  class="serviceshome-btn-container">
             <div>
             <a href="https://calendly.com/thestartupconnect/30min"><button class="btn-lft-margin" id="home2-modules-section-btn" style={{marginLeft:"0"}}>Book Free Consultancy <i class="mdi mdi-arrow-right"></i></button></a>
             </div>
             <div style={{marginLeft:"0"}}>
             <a href="/home1/Website_and_Software_Development_Brochure.pdf" download="Startup Connect Services Brochure"><button class="btn-lft-margin" id="home2-modules-section-btn" style={{backgroundImage: "linear-gradient(to right, #208d17, #00ad11)", marginLeft:"0"}}>Download Brochure <i class="mdi mdi-download"></i></button></a>
             </div>
             </div>

             
                </div>
                <div
                  class="sv-header-right" /* style={{backgroundColor:"#96dfff",padding:"2rem",borderRadius:"15px",boxShadow:"2px 3px 3px 2px #cbcecf"}} */
                >
                  <img loading = "lazy"

                    src="https://img.freepik.com/free-vector/web-development-programmer-engineering-coding-website-augmented-reality-interface-screens-developer-project-engineer-programming-software-application-design-cartoon-illustration_107791-3863.jpg"
                    style={{ width: "90%", height: "100%" }}
                  />
                </div>
              </div>
            </section>
            {/* HEADER SECTION END */}
            <hr />

            {/* SIDEBAR AND MAIN PAGE START */}
            <div className="container-scroller">
              {/*<Sidebar /> */}
              <div className="container-fluid page-body-wrapper">
                {/*} partial */}
                <div className="main-panel">
                 

              

               
                  <h2 id="ip-h2" style={{fontWeight:"bold"}}>
                    WHY CHOOSE A  <span style={{ color: "#9f23c2" }}>
                            WEB APP
                          </span>
                  </h2>
                  {/* BENEFITS SECTION START */}
                  <div class="row" style={{ padding: "2rem" }}>
                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/1791/1791961.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">User Authentication</h4>
                        <p>
                        User authentication ensures that only authorized users have access to the web app.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/1601/1601884.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Real Time Updates</h4>
                        <p>
                        Real-time updates allow users to receive immediate feedback on their actions.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/462/462706.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Scalability</h4>
                        <p>
                        Web apps are scalable, allowing them to handle increased traffic or data as the business grows.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/9521/9521756.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Customization</h4>
                        <p>
                        Web apps can be customized to meet the specific needs of the business and its users.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/1365/1365346.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Cost-Effective</h4>
                        <p>
                        Web apps are generally more cost-effective to develop than native
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/12287/12287403.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Cross Platform Compatibility</h4>
                        <p>
                        Web apps can run on multiple platforms, including desktop and mobile devices.
                        </p>
                      </div>
                    </div>

                   
                  
                   
                  </div>
                  {/* BENEFITS SECTION END */}

                  <hr />
                  {/* REQUIREMENTS SECTION START */}
                  <h2 id="ip-h2" style={{fontWeight:"bold"}}>BENEFITS OF A <span style={{color:"#140d83"}}>WEB APP</span></h2>
                  <p style={{fontSize:"1rem", padding:"0.5rem"}}>In an era defined by connectivity and dynamic user experiences, choosing a web app is a strategic decision that aligns with the evolving demands of the digital landscape. Here are compelling reasons why opting for a web app can be a game-changer for your online presence</p>

                  <div class="row" style={{ padding: "2rem" }}>
                    <div class="col-sm-4 stretch-card grid-margin">
                      <img loading = "lazy"

                        src="https://img.freepik.com/free-vector/app-development-concept-illustration_114360-5164.jpg"
                        style={{ width: "90%", height: "100%" }}
                      />
                    </div>

                    <div
                      class="col-sm-8 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div>
                        <h4 style={{ fontWeight: "bold" }}>
                        <i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> Universal Accessibility:
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                          {" "}
                          Web apps transcend device and operating system constraints, offering universal accessibility. Users can seamlessly engage with your application from desktops, laptops, tablets, or smartphones, ensuring a consistent experience across various platforms.
                        </p>
                        <br />
                        <h4 style={{ fontWeight: "bold" }}>
                        <i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> Seamless Integration:
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                        Integration with other web services and APIs is a hallmark of web apps. This fosters a seamless user experience by allowing your application to interact with third-party platforms, enhancing functionality and providing users with a more comprehensive solution.
                        </p>
                        </div>
                        </div>
                        </div>
                         
                        <div class="row" style={{ padding: "2rem" }}>
                  

                    <div
                      class="col-sm-8 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div>
                        <h4 style={{ fontWeight: "bold" }}>
                        <i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> Cross-Browser Compatibility:
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                        A web app operates uniformly across various web browsers, ensuring a consistent experience for all users. This eliminates compatibility issues that can arise with native applications and guarantees a smooth performance regardless of the browser choice.
                        </p>
                        <br />
                        <h4 style={{ fontWeight: "bold" }}>
                        <i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> Centralized Data Management:
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                        Centralized data storage and management in web apps facilitate efficient data handling and analysis. This enhances the performance of your application and enables you to glean valuable insights from user interactions.   </p>
                      </div>
                    </div>
                    <div class="col-sm-4 stretch-card grid-margin">
                      <img loading = "lazy"

                        src="https://img.freepik.com/free-vector/mobile-ux-concept-illustration_114360-4430.jpg"
                        style={{ width: "90%", height: "100%" }}
                      />
                    </div>
                  </div>

                  {/* REQUIREMENTS SECTION END */}
                  <hr />
               

                  {/* Why StartUp Connect Starts */}
                  <Whystartupconnect/>
                  {/* Why StartUp Connect Ends */}

                  <hr />

                  {/* PRICING SECTION START */}
                  <h2 id="ip-h2" style={{fontWeight:'bold'}}>PRICING FOR <span style={{color:"#006345"}}>WEB APP</span></h2>
                  <div class="row md-12" style={{marginTop:"5rem"}}>

                  {/* PRICING CARD 1 START */}
                  <div
                      class="col-sm-12 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                    <div class="card">
                    <div style={{backgroundColor:"#207a78",color:"#ffffff",fontWeight:"bold",textAlign:"center",borderRadius:"12px",padding:"1rem"}}>
                    <h3 class="pricing-ul-text"><u>GET CUSTOMIZED QUOTATIONS FOR WEB APP DEVELOPMENT IN YOUR BUDGET</u></h3>
                    <div style={{textAlign:"center"}}><h2 style={{fontWeight:"bold"}}> Starting at <span style={{color:"#ffffff"}}>₹ 9999*</span> only</h2></div>
                    </div>
                    <div style={{padding:"1rem",margingTop:"1rem"}}>
                       <div class="d-flex justify-content-center">
                       <button class="btn btn-lg btn-success" style={{backgroundColor:"#207a78", fontSize:"1rem"}} onClick={()=>{
                        //Setting Values to redirect to Contact Sales Page
                        sessionStorage.setItem("Smodule00","Services");sessionStorage.setItem("Sservice00","Web App Development"); window.location.replace("/businesstools-contactsales");
                       }}>Contact Sales</button>
                       </div>
                    </div>
                    </div>
                    </div>
                    {/* PRICING CARD 1 END */}

                  </div>
                  <hr/>
                  {/* PRICING SECTION END */}
              

             
                  <h2 id="ip-h2">Frequently Asked Questions (FAQs)</h2>
                  {/* FAQ SECTION START */}
                  <div
                    id="accordion"
                    style={{ textAlign: "left", marginTop: "3rem" }}
                  >
                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#003e73" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                         What is a web app?
                        </h5>
                        <div>
                          <button
                            id="ques-1-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-1").style.display =
                                "block";
                              document.getElementById(
                                "ques-1-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-1-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-1-dicon"
                            style={{ display: "none" }}
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-1").style.display =
                                "none";
                              document.getElementById(
                                "ques-1-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-1-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-1">
                        <div class="card-body">
                        A web app is a software application that runs in a web browser. </div>
                      </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                          What are the benefits of a web app over a traditional website?
                        </h5>
                        <div>
                          <button
                            id="ques-2-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-2").style.display =
                                "block";
                              document.getElementById(
                                "ques-2-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-2-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-2-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-2").style.display =
                                "none";
                              document.getElementById(
                                "ques-2-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-2-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-2" style={{ display: "none" }}>
                        <div class="card-body">
                        Web apps provide enhanced functionality, cross-platform compatibility, and better user engagement than traditional websites. </div>
                      </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                       Can a web app be accessed from any device?
                        </h5>
                        <div>
                          <button
                            id="ques-3-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-3").style.display =
                                "block";
                              document.getElementById(
                                "ques-3-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-3-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-3-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-3").style.display =
                                "none";
                              document.getElementById(
                                "ques-3-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-3-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-3" style={{ display: "none" }}>
                        <div class="card-body">
                        Yes, web apps can be accessed from any device with an internet connection.   </div>
                      </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                         How long does it take to develop a web app?
                        </h5>
                        <div>
                          <button
                            id="ques-4-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-4").style.display =
                                "block";
                              document.getElementById(
                                "ques-4-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-4-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-4-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-4").style.display =
                                "none";
                              document.getElementById(
                                "ques-4-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-4-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-4" style={{ display: "none" }}>
                        <div class="card-body">
                        The time to develop a web app varies depending on the complexity of the project. However, web apps can generally be developed more quickly than native apps.{" "}
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                       Can a web app be customized to meet the specific needs of my business?

                        </h5>
                        <div>
                          <button
                            id="ques-5-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-5").style.display =
                                "block";
                              document.getElementById(
                                "ques-5-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-5-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-5-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-5").style.display =
                                "none";
                              document.getElementById(
                                "ques-5-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-5-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-5" style={{ display: "none" }}>
                        <div class="card-body">
                        Yes, web apps can be customized to meet the specific needs of your business and its users.{" "}
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                        How do you ensure the security of user data in a web app?
                        </h5>
                        <div>
                          <button
                            id="ques-6-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-6").style.display =
                                "block";
                              document.getElementById(
                                "ques-6-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-6-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-6-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-6").style.display =
                                "none";
                              document.getElementById(
                                "ques-6-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-6-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-6" style={{ display: "none" }}>
                        <div class="card-body">
                        You can use encryption and other security measures to protect user data in your web apps.{" "}
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                         Can a web app be integrated with other services, such as payment gateways or social media platforms?
                        </h5>
                        <div>
                          <button
                            id="ques-7-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-7").style.display =
                                "block";
                              document.getElementById(
                                "ques-7-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-7-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-7-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-7").style.display =
                                "none";
                              document.getElementById(
                                "ques-7-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-7-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-7" style={{ display: "none" }}>
                        <div class="card-body">
                        Yes, web apps can be integrated with other services to provide advanced functionality.{" "}
                        </div>
                      </div>
                    </div>

                   <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                         What kind of analytics can I expect from a web app?
                        </h5>
                        <div>
                          <button
                            id="ques-8-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-8").style.display =
                                "block";
                              document.getElementById(
                                "ques-8-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-8-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-8-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-8").style.display =
                                "none";
                              document.getElementById(
                                "ques-8-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-8-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-8" style={{ display: "none" }}>
                        <div class="card-body">
                        Web apps can provide advanced analytics to track user behavior and identify areas for improvement.{" "}
                        </div>
                      </div>
                    </div> 
                  </div>
                  {/* FAQ SECTION END */}

                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
            {/* SIDEBAR AND MAIN PAGE END */}

            <Footer3 />
          </div>
          {/*} container-scroller */}
          {/*} plugins:js */}
          <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
          {/*} endinject */}
          {/*} Plugin js for this page */}
          <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
          <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
          <script
            src="panel/assets/js/jquery.cookie.js"
            type="text/javascript"
          ></script>
          {/*} End plugin js for this page */}
          {/*} inject:js */}
          <script src="panel/assets/js/off-canvas.js"></script>
          <script src="panel/assets/js/hoverable-collapse.js"></script>
          <script src="panel/assets/js/misc.js"></script>
          <script src="panel/assets/js/settings.js"></script>
          <script src="panel/assets/js/todolist.js"></script>
          {/*} endinject */}
          {/*} Custom js for this page */}
          <script src="panel/assets/js/dashboard.js"></script>
          {/*} End custom js for this page */}
        </body>
      </html>
    </>
  );
}

export default Webapp1;
