import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';
import moment from 'moment/moment';

import '../Resources/dashboard.css';
import Navbar from '../navbar';
import Sidebar from '../Sidebar';
import Add_product from './add_product';
import Add_productgroup from './add_productgroup';

import {useRef as useref} from 'react';
import {useReactToPrint} from 'react-to-print';

//EXCEL DOWNLOAD IMPORT
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

//FOR QR CODE GENERATION
import { QRCodeCanvas } from "qrcode.react";

function Usercatalog(){

  
 //Report Print Function
 const componentRef = useref();
 const handlePrint = useReactToPrint({
   content: () => componentRef.current,
 });

 //Report Print Function 1
 const componentRef1 = useref();
 const handlePrint1 = useReactToPrint({
   content: () => componentRef1.current,
 });

 
 //QR Print Function
 const componentRef2 = useref();
 const handlePrint2 = useReactToPrint({
   content: () => componentRef2.current,
 });


 //Saving Values for QR Code Generator
 {/*} const [product, setProduct] = useState({
    product_code: "", product_name: "", quantity: "", rate: ""
  });

  const handleChange = (e) => {
    setProduct({ ...product, [e.target.name]: e.target.value });
  }; */}

 //Variables
const[products,setproducts] = useState([]);//SetData Variable
const[groups,setgroups] = useState([]);//SetData Variable


  // Function to download the data as an Excel file
  const downloadExcel = () => {
    // Convert JSON data to Excel worksheet
    const worksheet = XLSX.utils.json_to_sheet(products);
    
    // Create a new workbook and add the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    
    // Convert the workbook to a binary array
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    
    // Create a Blob from the binary data and trigger a download
    const excel_data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(excel_data, "products_data.xlsx");
  };
  
   // Function to download the groups data as an Excel file
   const downloadExcel1 = () => {
    // Convert JSON data to Excel worksheet
    const worksheet1 = XLSX.utils.json_to_sheet(groups);
    
    // Create a new workbook and add the worksheet
    const workbook1 = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook1, worksheet1, "Sheet1");
    
    // Convert the workbook to a binary array
    const excelBuffer1 = XLSX.write(workbook1, { bookType: "xlsx", type: "array" });
    
    // Create a Blob from the binary data and trigger a download
    const excel_data1 = new Blob([excelBuffer1], { type: "application/octet-stream" });
    saveAs(excel_data1, "productgroups_data.xlsx");
  };
  
  

 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const busyid=sessionStorage.getItem("BusyId00");
 const counterid=sessionStorage.getItem("CounterId00");



 
 
{/* PRODUCTS TABLE COLUMNS MANAGEMENT VARIABLES START */}
const[cd_col1_val,setcd_col1_val] = useState(0);//column1
const[cd_col2_val,setcd_col2_val] = useState(0);//column2
const[cd_col3_val,setcd_col3_val] = useState(0);//column3
const[cd_col4_val,setcd_col4_val] = useState(0);//column4
const[cd_col5_val,setcd_col5_val] = useState(0);//column5
const[cd_col6_val,setcd_col6_val] = useState(0);//column6
const[cd_col7_val,setcd_col7_val] = useState(0);//column7
const[cd_col8_val,setcd_col8_val] = useState(0);//column8
const[cd_col9_val,setcd_col9_val] = useState(0);//column9
const[cd_col10_val,setcd_col10_val] = useState(0);//column10
const[cd_col11_val,setcd_col11_val] = useState(0);//column11
const[cd_col12_val,setcd_col12_val] = useState(0);//column12
const[cd_col13_val,setcd_col13_val] = useState(0);//column13
const[cd_col14_val,setcd_col14_val] = useState(0);//column14
const[cd_col15_val,setcd_col15_val] = useState(0);//column15
const[cd_col16_val,setcd_col16_val] = useState(0);//column16
const[cd_col17_val,setcd_col17_val] = useState(0);//column17
const[cd_col18_val,setcd_col18_val] = useState(0);//column18
const[cd_col19_val,setcd_col19_val] = useState(0);//column19
const[cd_col20_val,setcd_col20_val] = useState(0);//column20
const[cd_col21_val,setcd_col21_val] = useState(0);//column21
const[cd_col22_val,setcd_col22_val] = useState(0);//column22
const[cd_col23_val,setcd_col23_val] = useState(0);//column23
const[cd_col24_val,setcd_col24_val] = useState(1);//column23


{/* PRODUCTS TABLE COLUMNS MANAGEMENT VARIABLES END */}


 //Checking if Sessions are Set

// alert(logstatus,loguser,logadminid);
 //Sessions Not Set
 {/*if(((logstatus==null)||(logstatus==""))||((logid==null)||(logid==""))||((token==null)||(token==" "))||((busyid==null)||(busyid==" "))||((counterid==null)||(counterid==" "))){
  
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");
   
 
    window.location.replace("/businesstools-login");
   }
  
   //Sessions Set
  else*/}{

   

    
      


//Error Variable
const[errorvar,seterrorvar] = useState();



//FILTER VARIABLES
const[filter_val,setfilter_val] = useState(0);
const[filter_product_id,setfilter_product_id] = useState(); const updatefilter_product_id = (event) => { setfilter_product_id(event.target.product_id);}; const[filter_product_id_er,setfilter_product_id_er]=useState();
const[filter_product_code,setfilter_product_code] = useState(); const updatefilter_product_code = (event) => { setfilter_product_code(event.target.value);}; const[filter_product_code_er,setfilter_product_code_er]=useState();
const[filter_product_hsn,setfilter_product_hsn] = useState(); const updatefilter_product_hsn = (event) => { setfilter_product_hsn(event.target.value);}; const[filter_product_hsn_er,setfilter_product_hsn_er]=useState();
const[filter_product_name,setfilter_product_name] = useState(); const updatefilter_product_name = (event) => { setfilter_product_name(event.target.value);}; const[filter_product_name_er,setfilter_product_name_er]=useState();
const[filter_type,setfilter_type] = useState(); const updatefilter_type = (event) => { setfilter_type(event.target.value);}; const[filter_type_er,setfilter_type_er]=useState();
const[filter_category,setfilter_category] = useState(); const updatefilter_category = (event) => { setfilter_category(event.target.value);}; const[filter_category_er,setfilter_category_er]=useState();
const[filter_brand,setfilter_brand] = useState(); const updatefilter_brand = (event) => { setfilter_brand(event.target.value);}; const[filter_brand_er,setfilter_brand_er]=useState();
const[filter_group,setfilter_group] = useState(); const updatefilter_group = (event) => { setfilter_group(event.target.value);}; const[filter_group_er,setfilter_group_er]=useState();
const[filter_sales_rate,setfilter_sales_rate] = useState(); const updatefilter_sales_rate = (event) => { setfilter_sales_rate(event.target.value);}; const[filter_sales_rate_er,setfilter_sales_rate_er]=useState();
const[filter_purchase_rate,setfilter_purchase_rate] = useState(); const updatefilter_purchase_rate = (event) => { setfilter_purchase_rate(event.target.value);}; const[filter_purchase_rate_er,setfilter_purchase_rate_er]=useState();
const[filter_product_status,setfilter_product_status] = useState(); const updatefilter_product_status = (event) => { setfilter_product_status(event.target.value);}; const[filter_product_status_er,setfilter_product_status_er]=useState();

//PART 2 FILTERS
const[filter_val1,setfilter_val1] = useState(0);
const[filter_group_id,setfilter_group_id] = useState();const updatefilter_group_id = (event) => { setfilter_group_id(event.target.value);}; const[filter_group_id_er,setfilter_group_id_er]=useState();
const[filter_group_name,setfilter_group_name] = useState();const updatefilter_group_name = (event) => { setfilter_group_name(event.target.value);}; const[filter_group_name_er,setfilter_group_name_er]=useState();
const[filter_group_industry,setfilter_group_industry] = useState();const updatefilter_group_industry = (event) => { setfilter_group_industry(event.target.value);}; const[filter_group_industry_er,setfilter_group_industry_er]=useState();
const[filter_group_sector,setfilter_group_sector] = useState();const updatefilter_group_sector = (event) => { setfilter_group_sector(event.target.value);}; const[filter_group_sector_er,setfilter_group_sector_er]=useState();
const[filter_group_type,setfilter_group_type] = useState();const updatefilter_group_type = (event) => { setfilter_group_type(event.target.value);}; const[filter_group_type_er,setfilter_group_type_er]=useState();
const[filter_group_category,setfilter_group_category] = useState();const updatefilter_group_category = (event) => { setfilter_group_category(event.target.value);}; const[filter_group_category_er,setfilter_group_category_er]=useState();
const[filter_group_status,setfilter_group_status] = useState(); const updatefilter_group_status = (event) => { setfilter_group_status(event.target.value);}; const[filter_group_status_er,setfilter_group_status_er]=useState();




//Variables for pagination
const [currentPage, setCurrentPage] = useState(1);
const [pageNumber, setpageNumber] = useState(1);

useEffect(()=>{
   
  getData();
  
  },[])
 
    
  
//******************* GET PRODUCTS DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))){
 
    
    
  //Fetching Filter Variables
  const $filter_product_id = filter_product_id;const $filter_product_code = filter_product_code;const $filter_product_hsn = filter_product_hsn;const $filter_product_name = filter_product_name; const $filter_type = filter_type;const $filter_category = filter_category;
  const $filter_group = filter_group;const $filter_brand = filter_brand; const $filter_product_status = filter_product_status;const $filter_sales_rate = filter_sales_rate;const $filter_purchase_rate = filter_purchase_rate;
  const $filter_group_id = filter_group_id;const $filter_group_name = filter_group_name;const $filter_group_industry = filter_group_industry;const $filter_group_sector = filter_group_sector;const $filter_group_type = filter_group_type;const $filter_group_category = filter_group_category;const $filter_group_status = filter_group_status;

 axios.post("bt-get-products-default", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Sn_start :0,
   Sn_end :0,
   Sn_total :0,
   Sn_limit :0,
  //Filters
  Filter_val : filter_val,
  Filter_product_id : $filter_product_id,
  Filter_product_code : $filter_product_code,
  Filter_product_hsn : $filter_product_hsn,
  Filter_product_name : $filter_product_name,
  Filter_type : $filter_type,
  Filter_category : $filter_category,
  Filter_brand : $filter_brand,
  Filter_group : $filter_group,
  Filter_sales_rate : $filter_sales_rate,
  Filter_purchase_rate : $filter_purchase_rate,
  Filter_product_status : $filter_product_status, 
  Filter_val1 : filter_val1,
  Filter_group_id : $filter_group_id, 
  Filter_group_name : $filter_group_name, 
  Filter_group_type : $filter_group_type, 
  Filter_group_category : $filter_group_category, 
  Filter_group_sector : $filter_group_sector, 
  Filter_group_industry : $filter_group_industry, 
  Filter_group_status : $filter_group_status,
  
//Code Edits for pagination
'page' : pageNumber
 })
 .then((response) => {


  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setproducts(response.data.products.data);
   setgroups(response.data.groups.data);
  
  }
     //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){
    setfilter_product_id_er(response.data.validatorerror.Filter_product_id);
    setfilter_product_code_er(response.data.validatorerror.Filter_product_code);
    setfilter_product_hsn_er(response.data.validatorerror.Filter_product_hsn);
    setfilter_product_name_er(response.data.validatorerror.Filter_product_name);
    setfilter_type_er(response.data.validatorerror.Filter_type);
    setfilter_category_er(response.data.validatorerror.Filter_category);
    setfilter_brand_er(response.data.validatorerror.Filter_brand);
    setfilter_group_er(response.data.validatorerror.Filter_group);
    setfilter_sales_rate_er(response.data.validatorerror.Filter_sales_rate);
    setfilter_purchase_rate_er(response.data.validatorerror.Filter_purchase_rate);
    setfilter_product_status_er(response.data.validatorerror.Filter_product_status);
    setfilter_group_id_er(response.data.validatorerror.Filter_group_id);
    setfilter_group_name_er(response.data.validatorerror.Filter_group_name);
    setfilter_group_type_er(response.data.validatorerror.Filter_group_type);
    setfilter_group_category_er(response.data.validatorerror.Filter_group_category);
    setfilter_group_sector_er(response.data.validatorerror.Filter_group_sector);
    setfilter_group_industry_er(response.data.validatorerror.Filter_group_industry);
    setfilter_group_status_er(response.data.validatorerror.Filter_group_status);
  }

  //Data Violation
  if(response.data.error==102){ 
  
    setfilter_product_id_er(response.data.filter_product_id_er);
    setfilter_product_code_er(response.data.filter_product_code_er);
    setfilter_product_hsn_er(response.data.filter_product_hsn_er);
    setfilter_product_hsn_er(response.data.filter_product_hsn_er);
    setfilter_type_er(response.data.filter_type_er);
    setfilter_category_er(response.data.filter_category_er);
    setfilter_group_er(response.data.filter_group_er);
    setfilter_brand_er(response.data.filter_brand_er);
    setfilter_sales_rate_er(response.data.filter_sales_rate_er);
    setfilter_purchase_rate_er(response.data.filter_purchase_rate_er);
    setfilter_product_status_er(response.data.filter_product_status_er);
    setfilter_group_id_er(response.data.filter_group_id_er);
    setfilter_group_name_er(response.data.validatorerror.Filter_group_name);
    setfilter_group_type_er(response.data.validatorerror.Filter_group_type);
    setfilter_group_category_er(response.data.validatorerror.Filter_group_category);
    setfilter_group_industry_er(response.data.validatorerror.Filter_group_industry);
    setfilter_group_sector_er(response.data.validatorerror.Filter_group_sector);
    setfilter_group_status_er(response.data.validatorerror.Filter_group_status);

  }
  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* GET PRODUCTS DATA FUNCTION END  ********************//


 
 
//******************* GET PRODUCTS GROUP DATA BY ID FUNCTION START  ********************//



//egroup_name
const[egroup_name,setegroup_name]=useState();const updateegroup_name = (event) => { setegroup_name(event.target.value);}; const[egroup_name_er,setegroup_name_er]=useState();
//egroup_type
const[egroup_type,setegroup_type]=useState();const updateegroup_type = (event) => { setegroup_type(event.target.value);}; const[egroup_type_er,setegroup_type_er]=useState();
//egroup_industry
const[egroup_industry,setegroup_industry]=useState();const updateegroup_industry = (event) => { setegroup_industry(event.target.value);}; const[egroup_industry_er,setegroup_industry_er]=useState();
//egroup_category
const[egroup_category,setegroup_category]=useState();const updateegroup_category = (event) => { setegroup_category(event.target.value);}; const[egroup_category_er,setegroup_category_er]=useState();
//egroup_sector
const[egroup_sector,setegroup_sector]=useState();const updateegroup_sector = (event) => { setegroup_sector(event.target.value);}; const[egroup_sector_er,setegroup_sector_er]=useState();
//egroup_status
const[egroup_status,setegroup_status]=useState();const updateegroup_status = (event) => { setegroup_status(event.target.value);}; const[egroup_status_er,setegroup_status_er]=useState();


//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();




//Edit Function
function getproductsgroupdatabyId(){
 

  const groupId=sessionStorage.getItem("GroupId00");

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&(busyid>0)&&(counterid>0)&&(groupId>0)){
 
 
 axios.post("bt-productsgroup-details-byid", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Group_id:groupId
 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");
     sessionStorage.removeItem("GroupId00");


     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){

   //Setting Data
   setegroup_name(response.data.group_name);
   setegroup_type(response.data.type);
   setegroup_industry(response.data.industry);
   setegroup_sector(response.data.sector);
   setegroup_category(response.data.category);
   setegroup_status(response.data.status);

  
  }
  //Cannot Fetch Data
  else{


      document.getElementById("edit-form1").style.display="none";

     //Setting Variables to empty 
     setegroup_name_er(" ");setegroup_type_er(" ");setegroup_industry_er(" ");setegroup_sector_er(" ");setegroup_category_er();setegroup_status_er();seterror_msg(" ");
     setegroup_name(" ");setegroup_type(" ");setegroup_industry(" ");setegroup_sector(" ");setegroup_category();setegroup_status();


      document.getElementById("edit-error-card1").style.display="block"; 
      sessionStorage.removeItem("GroupId00");
  }
 
 
 });
 
 }
 //User not Logedin
 else{

     //Checking if user loggedin
     if(((logstatus==null)||(logstatus==""))||((logid==null)||(logid==""))||((token==null)||(token==" "))){
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("GroupId00");

   window.location.replace("businesstools-login");
     }
 
  }
 
 }
  
 //******************* GET PRODUCTS GROUP DATA BY ID FUNCTION END  ********************//



 
 
   
//******************* EDIT PRODUCT GROUP FUNCTION START ********************//
function editproductsgroup(){

  const groupId=sessionStorage.getItem("GroupId00");
  //Setting Error Variables
  setegroup_name_er(" ");setegroup_type_er(" ");setegroup_industry_er(" ");setegroup_sector_er(" ");setegroup_category_er();setegroup_status_er();seterror_msg(" ");



  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))&&((groupId!=null)||(groupId!=" "))){
 
 
  axios.post("bt-productsgroup-edit", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Group_name : egroup_name,
   Type:egroup_type,
   Industry : egroup_industry,
   Sector:egroup_sector,
   Category:egroup_category,
   Status:egroup_status,
   Group_id:groupId


  })
  .then((response) => {

  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");
     sessionStorage.removeItem("GroupId00");

     window.location.replace("businesstools-login");
  }

   //Validation Errors
 if(response.data.validatorerror){
  setegroup_name_er(response.data.validatorerror.Group_name);
  setegroup_type_er(response.data.validatorerror.Type);
  setegroup_industry_er(response.data.validatorerror.Industry);
  setegroup_sector_er(response.data.validatorerror.Sector);
  setegroup_category_er(response.data.validatorerror.Category);
  setegroup_status_er(response.data.validatorerror.Status);


}
//Invalid Entries
if(response.data.error==102){ 
  
  setegroup_name_er(response.data.group_name_er);
  setegroup_type_er(response.data.type_er);
  setegroup_industry_er(response.data.industry_er);
  setegroup_sector_er(response.data.sector_er);
  setegroup_category_er(response.data.category_er);
  setegroup_status_er(response.data.status_er);
 
  seterror_msg("Cannot Edit Product Group!");  }


  //Invalid Entries
  if(response.data.error==103){     seterror_msg(response.data.error_msg);  }

   //Bank Updated Successfully
   if(response.data.successvar==1){        

    document.getElementById("edit-form1").style.display="none";  
    setegroup_name_er(" ");setegroup_type_er(" ");setegroup_industry_er(" ");setegroup_sector_er(" ");setegroup_category_er();setegroup_status_er();seterror_msg(" ");
    setegroup_name(" ");setegroup_type(" ");setegroup_industry(" ");setegroup_sector(" ");setegroup_category();setegroup_status();

    
    document.getElementById("edit-success-card1").style.display="block";  }
   //Cannot AUpdate Bank
   else{  seterror_msg("Cannot Edit Product Group !"); }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* EDIT PRODUCT GROUP FUNCTION END ********************//



 
 //******************* GET PRODUCT ITEM DATA BY ID FUNCTION START  ********************//


//eproduct_name
const[eproduct_name,seteproduct_name]=useState();const updateeproduct_name = (event) => { seteproduct_name(event.target.value);}; const[eproduct_name_er,seteproduct_name_er]=useState();
//eproduct_code
const[eproduct_code,seteproduct_code]=useState();const updateeproduct_code = (event) => { seteproduct_code(event.target.value);}; const[eproduct_code_er,seteproduct_code_er]=useState();
//eproduct_hsn
const[eproduct_hsn,seteproduct_hsn]=useState();const updateeproduct_hsn = (event) => { seteproduct_hsn(event.target.value);}; const[eproduct_hsn_er,seteproduct_hsn_er]=useState();
//eproduct_type
const[eproduct_type,seteproduct_type]=useState();const updateeproduct_type = (event) => { seteproduct_type(event.target.value);}; const[eproduct_type_er,seteproduct_type_er]=useState();
//eproduct_category
const[eproduct_category,seteproduct_category]=useState();const updateeproduct_category = (event) => { seteproduct_category(event.target.value);}; const[eproduct_category_er,seteproduct_category_er]=useState();
//eproduct_brand
const[eproduct_brand,seteproduct_brand]=useState();const updateeproduct_brand = (event) => { seteproduct_brand(event.target.value);}; const[eproduct_brand_er,seteproduct_brand_er]=useState();
//eproduct_status
const[eproduct_status,seteproduct_status]=useState();const updateeproduct_status = (event) => { seteproduct_status(event.target.value);}; const[eproduct_status_er,seteproduct_status_er]=useState();
//edescription
const[edescription,setedescription]=useState();const updateedescription = (event) => { setedescription(event.target.value);}; const[edescription_er,setedescription_er]=useState();
//egroup_id
const[egroup_id,setegroup_id]=useState();const updateegroup_id = (event) => { setegroup_id(event.target.value);}; const[egroup_id_er,setegroup_id_er]=useState();
//egroup_name
const[epgroup_name,setepgroup_name]=useState();const updatepgroup_name = (event) => { setepgroup_name(event.target.value);}; const[epgroup_name_er,setepgroup_name_er]=useState();
//esales_rate
const[esales_rate,setesales_rate]=useState();const updateesales_rate = (event) => { setesales_rate(event.target.value);}; const[esales_rate_er,setesales_rate_er]=useState();
//epurchases_rate
const[epurchases_rate,setepurchases_rate]=useState();const updateepurchases_rate = (event) => { setepurchases_rate(event.target.value);}; const[epurchases_rate_er,setepurchases_rate_er]=useState();
//eunits
const[eunits,seteunits]=useState();const updateeunits = (event) => { seteunits(event.target.value);}; const[eunits_er,seteunits_er]=useState();
//ediscount_per
const[ediscount_per,setediscount_per]=useState();const updateediscount_per = (event) => { setediscount_per(event.target.value);}; const[ediscount_per_er,setediscount_per_er]=useState();
//esgst_per
const[esgst_per,setesgst_per]=useState();const updateesgst_per = (event) => { setesgst_per(event.target.value);}; const[esgst_per_er,setesgst_per_er]=useState();
//ecgst_per
const[ecgst_per,setecgst_per]=useState();const updateecgst_per = (event) => { setecgst_per(event.target.value);}; const[ecgst_per_er,setecgst_per_er]=useState();
//eigst_per
const[eigst_per,seteigst_per]=useState();const updateeigst_per = (event) => { seteigst_per(event.target.value);}; const[eigst_per_er,seteigst_per_er]=useState();
//etax_per
const[etax_per,setetax_per]=useState();const updateetax_per = (event) => { setetax_per(event.target.value);}; const[etax_per_er,setetax_per_er]=useState();


//Edit Function
function getproductdatabyId(){
 

  const productid=sessionStorage.getItem("Productid00");

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&(busyid>0)&&(counterid>0)&&(productid>0)){
 
 
 axios.post("bt-productitem-details-byid", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Product_id:productid,

 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");
     sessionStorage.removeItem("Productid00");


     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){

   //Setting Data
   seteproduct_name(response.data.product_name);
   seteproduct_code(response.data.product_code);
   seteproduct_hsn(response.data.product_hsn);
   seteproduct_type(response.data.type);
   seteproduct_brand(response.data.brand);
   seteproduct_category(response.data.category);
   seteproduct_status(response.data.status);
   setegroup_id(response.data.group_id);
   setepgroup_name(response.data.group_name);
   setedescription(response.data.description);
   setesales_rate(response.data.sales_rate);
   setepurchases_rate(response.data.purchases_rate);
   seteunits(response.data.units);
   setediscount_per(response.data.discount_per);
   setesgst_per(response.data.sgst_per);
   setecgst_per(response.data.cgst_per);
   seteigst_per(response.data.igst_per);
   setetax_per(response.data.tax_per);

    
  }
  //Cannot Fetch Data
  else{


      document.getElementById("edit-form").style.display="none";

     //Setting Variables to empty 
     seteproduct_name_er(" ");seteproduct_code_er(" ");seteproduct_hsn_er(" ");seteproduct_type_er(" ");seteproduct_brand_er(" ");seteproduct_category_er(" ");seteproduct_status_er();setedescription_er();seterror_msg(" ");
     setesales_rate_er(" ");setepurchases_rate_er(" ");seteunits_er(" ");setediscount_per_er(" ");setesgst_per_er(" ");setecgst_per_er(" ");seteigst_per_er(" ");setetax_per_er(" ");setegroup_id_er(" ");
     seteproduct_name(" ");seteproduct_code(" ");seteproduct_hsn(" ");seteproduct_type(" ");seteproduct_brand(" ");seteproduct_category(" ");seteproduct_status();setedescription();
     setesales_rate(" ");setepurchases_rate(" ");seteunits(" ");setediscount_per(" ");setesgst_per(" ");setecgst_per(" ");seteigst_per(" ");setetax_per(" ");setegroup_id(" ");setepgroup_name();

      document.getElementById("edit-error-card").style.display="block"; 
      sessionStorage.removeItem("Productid00");
  }
 
 
 });
 
 }
 //User not Logedin
 else{

     //Checking if user loggedin
     if(((logstatus==null)||(logstatus==""))||((logid==null)||(logid==""))||((token==null)||(token==" "))){
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
     }
 
  }
 
 }
  
 //******************* GET PRODUCT ITEM DATA BY ID FUNCTION END  ********************//


 

 //******************* EDIT PRODUCT ITEM FUNCTION START ********************//
function editproduct(){

  //Setting Error Variables
  seteproduct_name_er(" ");seteproduct_code_er(" ");seteproduct_hsn_er(" ");seteproduct_type_er(" ");seteproduct_brand_er(" ");seteproduct_category_er(" ");seteproduct_status_er();setedescription_er();seterror_msg(" ");
  setesales_rate_er(" ");setepurchases_rate_er(" ");seteunits_er(" ");setediscount_per_er(" ");setesgst_per_er(" ");setecgst_per_er(" ");seteigst_per_er(" ");setetax_per_er(" ");setegroup_id_er(" ");

  const productid = sessionStorage.getItem("Productid00");

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))&&((productid!=null)||(productid!=" "))){
 
 
  axios.post("bt-productitem-edit", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Product_id:productid,
   Product_name :eproduct_name,
   Product_code :eproduct_code,
   Product_hsn :eproduct_hsn,
   Type:eproduct_type,
   Category : eproduct_category,
   Brand:eproduct_brand,
   Status:eproduct_status,
   Description:edescription,
   Group_id:egroup_id,
   Units:eunits,
   Sales_rate:esales_rate,
   Purchases_rate:epurchases_rate,
   Discount_per:ediscount_per,
   Sgst_per:esgst_per,
   Cgst_per:ecgst_per,
   Igst_per:eigst_per,
   Tax_per:etax_per

 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");
     sessionStorage.removeItem("Productid00");

     window.location.replace("businesstools-login");
  }

   //Validation Errors
 if(response.data.validatorerror){
  seteproduct_name_er(response.data.validatorerror.Product_name);
  seteproduct_code_er(response.data.validatorerror.Product_code);
  seteproduct_hsn_er(response.data.validatorerror.Product_hsn);
  seteproduct_type_er(response.data.validatorerror.Type);
  seteproduct_category_er(response.data.validatorerror.Category);
  seteproduct_brand_er(response.data.validatorerror.Brand);
  seteproduct_status_er(response.data.validatorerror.Status);
  setedescription_er(response.data.validatorerror.Description);
  setegroup_id_er(response.data.validatorerror.Group_id);
  setesales_rate_er(response.data.validatorerror.Sales_rate);
  setepurchases_rate_er(response.data.validatorerror.Purchases_rate);
  seteunits_er(response.data.validatorerror.Units);
  setediscount_per_er(response.data.validatorerror.Discount_per);
  setesgst_per_er(response.data.validatorerror.Sgst_per);
  setecgst_per_er(response.data.validatorerror.Cgst_per);
  seteigst_per_er(response.data.validatorerror.Igst_per);
  setetax_per_er(response.data.validatorerror.Tax_per);


}
//Invalid Entries
if(response.data.error==102){ 
  
  seteproduct_name_er(response.data.product_name_er);
  seteproduct_code_er(response.data.validatorerror.Product_code);
  seteproduct_hsn_er(response.data.validatorerror.Product_hsn);
  seteproduct_type_er(response.data.type_er);
  seteproduct_category_er(response.data.category_er);
  seteproduct_brand_er(response.data.brand_er);
  seteproduct_status_er(response.data.status_er);
  setedescription_er(response.data.description_er);
  setegroup_id_er(response.data.group_id_er);
  setesales_rate_er(response.data.sales_rate_er);
  setepurchases_rate_er(response.data.purchases_rate_er);
  seteunits_er(response.data.units_er);
  setediscount_per_er(response.data.discount_per_er);
  setesgst_per_er(response.data.sgst_per_er);
  setecgst_per_er(response.data.cgst_per_er);
  seteigst_per_er(response.data.igst_per_er);
  setetax_per_er(response.data.tax_per_er);
  seterror_msg("Cannot Update Product Item!");  }


  //Invalid Entries
  if(response.data.error==103){     seterror_msg(response.data.error_msg);  }

   //Transaction Updated Successfully
   if(response.data.successvar==1){        
     //Setting Variables to empty 
     seteproduct_name_er(" ");seteproduct_code_er(" ");seteproduct_hsn_er(" ");seteproduct_type_er(" ");seteproduct_brand_er(" ");seteproduct_category_er(" ");seteproduct_status_er();setedescription_er();seterror_msg(" ");
     setesales_rate_er(" ");setepurchases_rate_er(" ");seteunits_er(" ");setediscount_per_er(" ");setesgst_per_er(" ");setecgst_per_er(" ");seteigst_per_er(" ");setetax_per_er(" ");setegroup_id_er(" ");
     seteproduct_name(" ");seteproduct_code(" ");seteproduct_hsn(" ");seteproduct_type(" ");seteproduct_brand(" ");seteproduct_category(" ");seteproduct_status();setedescription();
     setesales_rate(" ");setepurchases_rate(" ");seteunits(" ");setediscount_per(" ");setesgst_per(" ");setecgst_per(" ");seteigst_per(" ");setetax_per(" ");setegroup_id(" ");setepgroup_name();
     document.getElementById("edit-form").style.display="none"; 
    document.getElementById("edit-success-card").style.display="block";  }

   //Cannot Update Transaction
   else{  seterror_msg("Cannot Update Product Item !"); }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");
   sessionStorage.removeItem("Productid00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* EDIT PRODUCT ITEM FUNCTION END ********************//




  
   
//******************* DELETE PRODUCT ITEM FUNCTION START ********************//
function deleteproduct(){

  const productId=sessionStorage.getItem("DProductId00");

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))&&((productId!=null)||(productId!=" "))){
 
 axios.post("bt-productitem-delete", {
   Busy_id:busyid,
   Counter_id:counterid,
   Log_id:logid,
   Token:token,
   Product_id:productId
 })
 .then((response) => {


  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");
     sessionStorage.removeItem("DProductId00");


     window.location.replace("businesstools-login");
  }

  //Counter Deleted Successfully
   if(response.data.successvar==1){        

      document.getElementById("delete-card").style.display="none";
      document.getElementById("delete-success-card").style.display="block";  
      sessionStorage.removeItem("DProductId");

  }
  
      //Cannot Delete Counter
    else{  
    document.getElementById("delete-card").style.display="none";
    document.getElementById("delete-error-card").style.display="block";   }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");
   sessionStorage.removeItem("DProductId00");


   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* DELETE PRODUCT ITEM FUNCTION END ********************//



return(
    <>
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Business Tools|BookKeeping Solution</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div class="container-scroller">

      <Sidebar />
    <div class="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div class="main-panel">

           {/* ADD PRODUCT GROUP SUCCESS CARD START */}
           <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card1">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">New Product Group Added Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <a href="businesstools-user-catalog"><button class="mt-3 btn btn-outline-danger">Close</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* ADD PRODUCT GROUP SUCCESS CARD END */}

                {/* ADD PRODUCT GROUP EDIT SUCCESS CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="edit-success-card1">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Product Group Updated Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("edit-success-card1").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* ADD PRODUCT GROUP EDIT SUCCESS CARD END */}
               {/* ADD PRODUCT GROUP EDIT ERROR CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="edit-error-card1">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-danger">Cannot Fetch Data !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("edit-error-card1").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* ADD PRODUCT GROUP EDIT ERROR CARD END */}

                 {/* ADD PRODUCT SUCCESS CARD START */}
          <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">New Product Item Added Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <a href="businesstools-user-catalog"><button class="mt-3 btn btn-outline-danger">Close</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* ADD PRODUCT SUCCESS CARD END */}

                 {/* ADD PRODUCT EDIT SUCCESS CARD START */}
             <div class="d-flex justify-content-center">
             <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="edit-success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Product Item Updated Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("edit-success-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* ADD PRODUCT EDIT SUCCESS CARD END */}
               {/* ADD PRODUCT EDIT ERROR CARD START */}
            <div class="d-flex justify-content-center">
            <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="edit-error-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-danger">Cannot Fetch Data !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("edit-error-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* ADD PRODUCT EDIT ERROR CARD END */}

                    {/* ADD PRODUCT DELETE CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-danger">Are you sure you want to delete this transaction?</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> This action cannot be reversed. You will lose all your data related to this transaction if you wish to continue this action.</p>
                      <button class="mt-3 btn btn-outline-success" onClick={deleteproduct}>Yes, I Know! Delete Transaction.</button>
                      <button class="mt-3 btn btn-outline-danger" style={{marginLeft:"1rem"}} onClick={()=>{  
                      //Cancel Delete and remove Product_id
                      sessionStorage.removeItem("DProductId00"); document.getElementById('delete-card').style.display = 'none'; }}>No, Don't Delete Transaction.</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* ADD PRODUCT DELETE CARD END */}
              {/* ADD PRODUCT DELETE SUCCESS CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Product Item Deleted Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("delete-success-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* ADD PRODUCT DELETE SUCCESS CARD END */}
               {/*  ADD PRODUCT DELETE ERROR CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-error-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-danger">Cannot Delete Product Item !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("delete-error-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* ADD PRODUCT DELETE ERROR CARD END */}



          <div class="content-wrapper pb-0">

          <div class="row">
              <div class="col-12 grid-margin create-form" id="create-form">
              <Add_product /> 
              </div>
              <div class="col-12 grid-margin create-form" id="create-form1">
              <Add_productgroup /> 
              </div>
          </div>

            <div class="page-header flex-wrap">
            <div class="header-left"></div>
              <div class="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div class="d-flex align-items-center">
                  <a href="#">
                    <p class="m-0 pe-3">Products/ Catalog</p>
                  </a>
                  <a class="ps-3 me-4" href="#">
                  {/*}  <p class="m-0">ADE-00234</p> */}
                  </a>
                </div>
                <button type="button" style={{backgroundColor:"#006b6b", marginRight:"1rem"}} class="btn btn-primary mt-2 mt-sm-0 btn-icon-text" onClick={()=>{ 
               const createForm = document.getElementById('create-form1');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
                  <i class="mdi mdi-plus-circle" style={{backgroundColor:"#014f4e"}}></i>   New Group </button>
                <button type="button" class="btn btn-primary mt-2 mt-sm-0 btn-icon-text" onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
                  <i class="mdi mdi-plus-circle"></i> New Item </button>
              </div>
            </div>

            {/*} chart row starts here */}
         {/*}   <div class="row">
              <div class="col-sm-6 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      <div class="card-title">
                      </div>
                      <div class="d-flex text-muted font-20">
                        <i class="mdi mdi-printer mouse-pointer"></i>
                        <i class="mdi mdi-help-circle-outline ms-2 mouse-pointer"></i>
                      </div>
                    </div>
                    <h3 class="font-weight-bold mb-0"> 2,409 <span class="text-success h5">4,5%<i class="mdi mdi-arrow-up"></i></span>
                    </h3>
                    <span class="text-muted font-13">Catalog Items/Day</span>
                    <div class="line-chart-wrapper">
                      <canvas id="linechart" height="80"></canvas>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      <div class="card-title"> Conversions
                      </div>
                      <div class="d-flex text-muted font-20">
                        <i class="mdi mdi-printer mouse-pointer"></i>
                        <i class="mdi mdi-help-circle-outline ms-2 mouse-pointer"></i>
                      </div>
                    </div>
                    <h3 class="font-weight-bold mb-0"> 0.40% <span class="text-success h5">0.20%<i class="mdi mdi-arrow-up"></i></span>
                    </h3>
                    <span class="text-muted font-13">Catalog Groups/Day</span>
                    <div class="bar-chart-wrapper">
                      <canvas id="barchart" height="80"></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

             {/* PRODUCT ITEM EDIT FORM START */}
             <div class="row">
              <div class="col-12 grid-margin create-form" id="edit-form" style={{display:"none"}}>
              <div class="card">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ 
                         //close edit form button
                         document.getElementById("edit-form").style.display="none";
                        //Setting Variables to empty
                        //Setting Variables to empty 
                        seteproduct_name_er(" ");seteproduct_code_er(" ");seteproduct_hsn_er(" ");seteproduct_type_er(" ");seteproduct_brand_er(" ");seteproduct_category_er(" ");seteproduct_status_er();setedescription_er();seterror_msg(" ");
                        setesales_rate_er(" ");setepurchases_rate_er(" ");seteunits_er(" ");setediscount_per_er(" ");setesgst_per_er(" ");setecgst_per_er(" ");seteigst_per_er(" ");setetax_per_er(" ");setegroup_id_er(" ");
                        seteproduct_name(" ");seteproduct_code(" ");seteproduct_hsn(" ");seteproduct_type(" ");seteproduct_brand(" ");seteproduct_category(" ");seteproduct_status();setedescription();
                        setesales_rate(" ");setepurchases_rate(" ");seteunits(" ");setediscount_per(" ");setesgst_per(" ");setecgst_per(" ");seteigst_per(" ");setetax_per(" ");setegroup_id(" ");setepgroup_name();
    
                        sessionStorage.removeItem("Productid00");}}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                  <div class="card-body">
                    <h4 class="card-title">Edit Product</h4>
                    <div class="form-sample">
                      <p class="card-description">edit your product details here</p>
                      <code>{error_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product Name<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Red T-Shirt" id="eproduct_name" value={eproduct_name} />
                              <p class="text-muted" style={{fontSize:"0.7rem", textAlign:"left"}}>this field cannot be changed</p>
                            <code>{eproduct_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="Type Regular" id="eproduct_type" value={eproduct_type} onChange={updateeproduct_type}/>
                            <code>{eproduct_type_er}</code>
                            </div>
                          </div>
                        </div>   
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product Code</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="PC00001" id="eproduct_code" value={eproduct_code} onChange={updateeproduct_code} />
                            <code>{eproduct_code_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product HSN</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="214231" id="eproduct_hsn" value={eproduct_hsn} onChange={updateeproduct_hsn}/>
                            <code>{eproduct_hsn_er}</code>
                            </div>
                          </div>
                        </div>   
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Category</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Northern Style" id="eproduct_category" value={eproduct_category} onChange={updateeproduct_category} />
                            <code>{eproduct_category_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Brand</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Adidas" id="eproduct_brand" value={eproduct_brand} onChange={updateeproduct_brand} />
                            <code>{eproduct_brand_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Group</label>
                            <div class="col-sm-9">
                              <select class="form-control" id="egroup_id" style={{padding:"1rem"}} value={egroup_id} onChange={updateegroup_id} >
                              <option value={egroup_id}>{epgroup_name}</option>
                              {
                              groups.map((curElm,ind)=>{

                              return(
                              <>
                              <option value={curElm.Group_id}>{curElm.Group_name}</option>
                              </>
                              )
                              }
	 				   
	                         		)
                              }
                              </select>
                              <code>{egroup_id_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Status</label>
                            <div class="col-sm-9">
                            <select class="form-control" id="eproduct_status" style={{padding:"1rem"}} value={eproduct_status} onChange={updateeproduct_status} >
                              <option value={eproduct_status}>{eproduct_status}</option>
                              <option value="active">active</option>
                              <option value="inactive">inactive</option>
                              </select>
                              <code>{eproduct_status_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                    
                      <div class="form-group">
                        <label for="exampleTextarea1">Description</label>
                        <textarea class="form-control" rows="2" id="edescription" value={edescription} onChange={updateedescription} ></textarea>
                       <code>{edescription_er}</code>
                      </div>
                 
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Sales Rate</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="150.00" id="esales_rate" value={esales_rate} onChange={updateesales_rate}/>
                              <code>{esales_rate_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Purchases Rate</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="220.00" id="epurchases_rate" value={epurchases_rate} onChange={updateepurchases_rate} />
                            <code>{epurchases_rate_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Units</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="pieces" id="eunits" value={eunits} onChange={updateeunits} />
                              <code>{eunits_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Discount %</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="10.00" id="ediscount_per" value={ediscount_per} onChange={updateediscount_per} />
                              <code>{ediscount_per_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">SGST %</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="06.00" id="esgst_per" value={esgst_per} onChange={updateesgst_per} />
                              <code>{esgst_per_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">CGST %</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="06.00"  id="ecgst_per" value={ecgst_per} onChange={updateecgst_per}/>
                              <code>{ecgst_per_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">IGST %</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="06.00" id="eigst_per" value={eigst_per} onChange={updateeigst_per}/>
                              <code>{eigst_per_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Tax %</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="06.00" id="etax_per" value={etax_per} onChange={updateetax_per} />
                              <code>{etax_per_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>

                        
                      
                        <button class="btn btn-primary" style={{fontWeight:"bold"}} onClick={editproduct}>Edit Product</button>
                      
                       
    
                     
                   
                    </div>
                  </div>
                </div>
              </div>
              </div>
               {/* PRODUCT ITEM EDIT FORM END */}
                  
                  {/* PRODUCTS ITEM LIST START */}
             <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Products</h4>
                    <p class="card-description">view products here
                    </p>

                 {/* FILTER SECTION START */}
                 <hr/>
                            <div id="filter-top-card" class="row col-12" style={{textAlign:"left",display:"none"}}>
                            <div class="d-flex justify-content-between">
                            <div>
                            <h4 style={{padding:'1rem'}}>Filters</h4>
                           </div>
                           <div>
                    
                           <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("filter-top-card").style.display="none"; }}>
                           <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                           </button>
                           </div>
                           </div>
        
                        <div class="row">
                     {/*}  <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Product Id</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" id="filter_product_id" placeholder="457" value={filter_product_id} onChange={updatefilter_product_id}  />
                              <code>{filter_product_id_er}</code> 
                            </div>
                          </div>
                        </div> */}
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Product Code</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control"  id="filter_product_code" placeholder="PC123" value={filter_product_code} onChange={updatefilter_product_code}  />
                              <code>{filter_product_code_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Product HSN</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" placeholder="12345"   id="filter_product_hsn" value={filter_product_hsn} onChange={updatefilter_product_hsn}  />
                              <code>{filter_product_hsn_er}</code>
                            </div>
                          </div>
                        </div>
                     {/*} </div>

                      <div class="row">*/}
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Product Name</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" id="filter_product_name" placeholder="Product ABC" value={filter_product_name} onChange={updatefilter_product_name}  />
                              <code>{filter_product_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Type</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control"  id="filter_type" placeholder="Regular" value={filter_type} onChange={updatefilter_type}  />
                              <code>{filter_type_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Category</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" placeholder="Most Sold"   id="filter_category" value={filter_category} onChange={updatefilter_category}  />
                              <code>{filter_category_er}</code>
                            </div>
                          </div>
                        </div>
                    {/*}  </div>

                      <div class="row">*/}
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Brand</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" id="filter_brand" placeholder="Brand ABC" value={filter_brand} onChange={updatefilter_brand}  />
                              <code>{filter_brand_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Group</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control"  id="filter_group" placeholder="GROUP ABC" value={filter_group} onChange={updatefilter_group}  />
                              <code>{filter_group_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Sales Rate</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" placeholder="500.00"   id="filter_sales_rate" value={filter_sales_rate} onChange={updatefilter_sales_rate}  />
                              <code>{filter_sales_rate_er}</code>
                            </div>
                          </div>
                        </div>
                    {/*}  </div>

                      <div class="row">*/}
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Purchase Rate</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" placeholder="200.00"   id="filter_purchase_rate" value={filter_purchase_rate} onChange={updatefilter_purchase_rate}  />
                              <code>{filter_purchase_rate_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Product Status</label>
                            <div class="col-sm-8">
                              <select class="form-control" style={{padding:"1rem"}} placeholder="Highly Rated" id="filter_product_status" value={filter_product_status} onChange={updatefilter_product_status}>
                              <option value=" ">Select Status</option>
                              <option value="active">active</option>
                              <option value="inactive">inactive</option>
                              </select>
                              <code>{filter_product_status_er}</code>
                            </div>
                          </div>
                          </div>
                        <div class="col-md-4">
                          
                        <button class="btn btn-primary" onClick={()=>{ setfilter_val(1); getData(); }}>Apply Filters</button>
                         <button class="btn btn-danger" style={{marginLeft:"1rem"}} onClick={()=>{ setfilter_val(0); getData(); }}>Remove Filters</button>
                       
                        </div>
                      </div>

                      <div style={{marginBottom:"1rem"}}>
                  
                       
                      </div>
                            <hr/>                      
                            </div>
                            
                            {/* FILTER SECTION END */}

                    <div class="col-12" style={{marginBottom:"2rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                        {/* COLUMNS MANAGEMENT DROPDOWN START */}
                          <div class="dropdown">
                          <button class="btn btn-warning" onClick={()=>{ document.getElementById("filter-top-card").style.display="block"; }}><i class="mdi mdi-filter-outline"></i></button>
                            <button class="btn btn-primary dropdown-toggle" style={{marginLeft:"1rem"}} type="button" id="dropdownMenuIconButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="mdi mdi-table-large"></i>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuIconButton1">
                              <h6 class="dropdown-header">Columns</h6>
                              <hr/>
                              
                              {/* Column 1 */}
                              <li><div class="d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Sn</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c1_yes" onClick={()=>{setcd_col1_val(1); document.getElementById("cd_c1_yes").style.display="none"; document.getElementById("cd_c1_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c1_no" style={{ display:"none"}} onClick={()=>{ setcd_col1_val(0); document.getElementById("cd_c1_yes").style.display="block"; document.getElementById("cd_c1_no").style.display="none"; }}></i>
                              </div></div></li> 
                               {/* Column 2 
                               <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Product Id</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c2_yes"  onClick={()=>{setcd_col2_val(1); document.getElementById("cd_c2_yes").style.display="none"; document.getElementById("cd_c2_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c2_no" style={{ display:"none"}} onClick={()=>{ setcd_col2_val(0); document.getElementById("cd_c2_yes").style.display="block"; document.getElementById("cd_c2_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 3 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Product Code</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c3_yes"  onClick={()=>{setcd_col3_val(1); document.getElementById("cd_c3_yes").style.display="none"; document.getElementById("cd_c3_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c3_no" style={{ display:"none"}} onClick={()=>{ setcd_col3_val(0); document.getElementById("cd_c3_yes").style.display="block"; document.getElementById("cd_c3_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 4 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Product HSN</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c4_yes"  onClick={()=>{setcd_col4_val(1); document.getElementById("cd_c4_yes").style.display="none"; document.getElementById("cd_c4_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c4_no" style={{ display:"none"}} onClick={()=>{ setcd_col4_val(0); document.getElementById("cd_c4_yes").style.display="block"; document.getElementById("cd_c4_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 5 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Product Name</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c5_yes"  onClick={()=>{setcd_col5_val(1); document.getElementById("cd_c5_yes").style.display="none"; document.getElementById("cd_c5_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c5_no" style={{ display:"none"}} onClick={()=>{ setcd_col5_val(0); document.getElementById("cd_c5_yes").style.display="block"; document.getElementById("cd_c5_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 6 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Type</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c6_yes"  onClick={()=>{setcd_col6_val(1); document.getElementById("cd_c6_yes").style.display="none"; document.getElementById("cd_c6_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c6_no" style={{ display:"none"}} onClick={()=>{ setcd_col6_val(0); document.getElementById("cd_c6_yes").style.display="block"; document.getElementById("cd_c6_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 7 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Category</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c7_yes" onClick={()=>{setcd_col7_val(1); document.getElementById("cd_c7_yes").style.display="none"; document.getElementById("cd_c7_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c7_no" style={{ display:"none"}} onClick={()=>{ setcd_col7_val(0); document.getElementById("cd_c7_yes").style.display="block"; document.getElementById("cd_c7_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 8 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Brand</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c8_yes" onClick={()=>{setcd_col8_val(1); document.getElementById("cd_c8_yes").style.display="none"; document.getElementById("cd_c8_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c8_no" style={{ display:"none"}} onClick={()=>{ setcd_col8_val(0); document.getElementById("cd_c8_yes").style.display="block"; document.getElementById("cd_c8_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 9 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Group</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c9_yes" onClick={()=>{setcd_col9_val(1); document.getElementById("cd_c9_yes").style.display="none"; document.getElementById("cd_c9_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c9_no" style={{ display:"none"}} onClick={()=>{ setcd_col9_val(0); document.getElementById("cd_c9_yes").style.display="block"; document.getElementById("cd_c9_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 10 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Description</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c10_yes" onClick={()=>{setcd_col10_val(1); document.getElementById("cd_c10_yes").style.display="none"; document.getElementById("cd_c10_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c10_no" style={{ display:"none"}} onClick={()=>{ setcd_col10_val(0); document.getElementById("cd_c10_yes").style.display="block"; document.getElementById("cd_c10_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 11 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Sales Rate</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c11_yes" onClick={()=>{setcd_col11_val(1); document.getElementById("cd_c11_yes").style.display="none"; document.getElementById("cd_c11_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c11_no" style={{ display:"none"}} onClick={()=>{ setcd_col11_val(0); document.getElementById("cd_c11_yes").style.display="block"; document.getElementById("cd_c11_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 12 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Purchase Rate</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c12_yes" onClick={()=>{setcd_col12_val(1); document.getElementById("cd_c12_yes").style.display="none"; document.getElementById("cd_c12_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c12_no" style={{ display:"none"}} onClick={()=>{ setcd_col12_val(0); document.getElementById("cd_c12_yes").style.display="block"; document.getElementById("cd_c12_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 13 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Units</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c13_yes" onClick={()=>{setcd_col13_val(1); document.getElementById("cd_c13_yes").style.display="none"; document.getElementById("cd_c13_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c13_no" style={{ display:"none"}} onClick={()=>{ setcd_col13_val(0); document.getElementById("cd_c13_yes").style.display="block"; document.getElementById("cd_c13_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 14 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Discount %</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c14_yes" onClick={()=>{setcd_col14_val(1); document.getElementById("cd_c14_yes").style.display="none"; document.getElementById("cd_c14_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c14_no" style={{ display:"none"}} onClick={()=>{ setcd_col14_val(0); document.getElementById("cd_c14_yes").style.display="block"; document.getElementById("cd_c14_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 15 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Sgst %</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c15_yes" onClick={()=>{setcd_col15_val(1); document.getElementById("cd_c15_yes").style.display="none"; document.getElementById("cd_c15_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c15_no" style={{ display:"none"}} onClick={()=>{ setcd_col15_val(0); document.getElementById("cd_c15_yes").style.display="block"; document.getElementById("cd_c15_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 16 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Cgst %</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c16_yes" onClick={()=>{setcd_col16_val(1); document.getElementById("cd_c16_yes").style.display="none"; document.getElementById("cd_c16_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c16_no" style={{ display:"none"}} onClick={()=>{ setcd_col16_val(0); document.getElementById("cd_c16_yes").style.display="block"; document.getElementById("cd_c16_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 17 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Igst %</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c17_yes" onClick={()=>{setcd_col17_val(1); document.getElementById("cd_c17_yes").style.display="none"; document.getElementById("cd_c17_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c17_no" style={{ display:"none"}} onClick={()=>{ setcd_col17_val(0); document.getElementById("cd_c17_yes").style.display="block"; document.getElementById("cd_c17_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 18 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Tax %</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c18_yes" onClick={()=>{setcd_col18_val(1); document.getElementById("cd_c18_yes").style.display="none"; document.getElementById("cd_c18_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c18_no" style={{ display:"none"}} onClick={()=>{ setcd_col18_val(0); document.getElementById("cd_c18_yes").style.display="block"; document.getElementById("cd_c18_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 19 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Status</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c19_yes" onClick={()=>{setcd_col19_val(1); document.getElementById("cd_c19_yes").style.display="none"; document.getElementById("cd_c19_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c19_no" style={{ display:"none"}} onClick={()=>{ setcd_col19_val(0); document.getElementById("cd_c19_yes").style.display="block"; document.getElementById("cd_c19_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 20 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Updated By</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c20_yes" onClick={()=>{setcd_col20_val(1); document.getElementById("cd_c20_yes").style.display="none"; document.getElementById("cd_c20_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c20_no" style={{ display:"none"}} onClick={()=>{ setcd_col20_val(0); document.getElementById("cd_c20_yes").style.display="block"; document.getElementById("cd_c20_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 21 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Updated At</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c21_yes" onClick={()=>{setcd_col21_val(1); document.getElementById("cd_c21_yes").style.display="none"; document.getElementById("cd_c21_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c21_no" style={{ display:"none"}} onClick={()=>{ setcd_col21_val(0); document.getElementById("cd_c21_yes").style.display="block"; document.getElementById("cd_c21_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 24 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">QR Code</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c24_yes" style={{ display:"none"}} onClick={()=>{setcd_col24_val(1); document.getElementById("cd_c24_yes").style.display="none"; document.getElementById("cd_c24_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c24_no"  onClick={()=>{ setcd_col24_val(0); document.getElementById("cd_c24_yes").style.display="block"; document.getElementById("cd_c24_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 22 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10 text-warning">Edit</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c22_yes" onClick={()=>{setcd_col22_val(1); document.getElementById("cd_c22_yes").style.display="none"; document.getElementById("cd_c22_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c22_no" style={{ display:"none"}} onClick={()=>{ setcd_col22_val(0); document.getElementById("cd_c22_yes").style.display="block"; document.getElementById("cd_c22_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 23 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10 text-danger">Delete</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c23_yes"  onClick={()=>{setcd_col23_val(1); document.getElementById("cd_c23_yes").style.display="none"; document.getElementById("cd_c23_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c23_no" style={{ display:"none"}} onClick={()=>{ setcd_col23_val(0); document.getElementById("cd_c23_yes").style.display="block"; document.getElementById("cd_c23_no").style.display="none"; }}></i>
                              </div></div></li> 
                            {/*}  <div class="dropdown-divider"></div>
                              <button class="btn btn-danger">Close</button>*/}
                            </div> 
                          </div>
                          {/* COLUMNS MANAGEMENT DROPDOWN END */}

                          </div>
                          </div>


                    <div class="table-responsive" id="reports" ref={componentRef}>
                      <table class="table table-hover">
                        <thead>
                          <tr >
                            <th  style={{fontWeight:"bold"}} hidden={cd_col1_val==1} >Sn</th>
                         {/*}   <th  style={{fontWeight:"bold"}} hidden={cd_col2_val==1} >Product Id</th> */}
                            <th  style={{fontWeight:"bold"}} hidden={cd_col3_val==1} >Product Code</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col4_val==1} >Product HSN</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col5_val==1} >Product Name</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col6_val==1} >Type</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col7_val==1} >Category</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col8_val==1} >Brand</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col9_val==1} >Group</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col10_val==1} >Description</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col11_val==1} >Sales Rate</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col12_val==1} >Purchase Rate</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col13_val==1} >Units</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col14_val==1} >Discount %</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col15_val==1} >SGST %</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col16_val==1} >CGST %</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col17_val==1} >IGST %</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col18_val==1} >Tax %</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col19_val==1} >Status</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col20_val==1} >Updated By</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col21_val==1} >Updated At</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col24_val==1} ></th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col22_val==1} ></th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col23_val==1} ></th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                       products.map((curElm2,ind)=>{

                         return(
                         <>
                        <tr key={curElm2.Product_id}>
                            <td hidden={cd_col1_val==1} >{curElm2.Sn}</td>
                         {/*}   <td hidden={cd_col2_val==1} >{curElm2.Product_id}</td> */}
                            <td hidden={cd_col3_val==1} >{curElm2.Product_code}</td>
                            <td hidden={cd_col4_val==1} >{curElm2.Product_hsn}</td>
                            <td hidden={cd_col5_val==1} >{curElm2.Product_name}</td>
                            <td hidden={cd_col6_val==1} >{curElm2.Type}</td>
                            <td hidden={cd_col7_val==1} >{curElm2.Category}</td>
                            <td hidden={cd_col8_val==1} >{curElm2.Brand}</td>
                            <td hidden={cd_col9_val==1} >{curElm2.Group_name}</td>
                            <td hidden={cd_col10_val==1} >{curElm2.Description}</td>
                            <td hidden={cd_col11_val==1} >{curElm2.Sales_rate}</td>
                            <td hidden={cd_col12_val==1} >{curElm2.Purchase_rate}</td>
                            <td hidden={cd_col13_val==1} >{curElm2.Units}</td>
                            <td hidden={cd_col14_val==1} >{curElm2.Discount_per}</td>
                            <td hidden={cd_col15_val==1} >{curElm2.Sgst_per}</td>
                            <td hidden={cd_col16_val==1} >{curElm2.Cgst_per}</td>
                            <td hidden={cd_col17_val==1} >{curElm2.Igst_per}</td>
                            <td hidden={cd_col18_val==1} >{curElm2.Tax_per}</td>
                            <td hidden={cd_col19_val==1} ><label class="badge badge-success"  hidden={(curElm2.Product_status == "inactive")}>active</label><label class="badge badge-danger"  hidden={(curElm2.Product_status == "active")}>inactive</label></td>
                            <td hidden={cd_col20_val==1} >{curElm2.Updated_by}</td>
                            <td hidden={cd_col21_val==1} >{curElm2.Date_updated} ({curElm2.Time_updated})</td>
                            <td hidden={cd_col24_val==1} >  
                            {/* QR CODE */}
                            <div id="QR" ref={componentRef2}>
                            <QRCodeCanvas 
                            value={JSON.stringify({
                            Sn: curElm2.Sn,
                            Product_id: curElm2.Product_id,
                            Product_code: curElm2.Product_code,
                            Product_hsn: curElm2.Product_hsn,
                            Product_name: curElm2.Product_name,
                            Type: curElm2.Type,
                            Category: curElm2.Category,
                            Brand: curElm2.Brand,
                            Group_name: curElm2.Group_name,
                            Description: curElm2.Description,
                            Sales_rate: curElm2.Sales_rate,
                            Purchase_rate: curElm2.Purchase_rate,
                            Units: curElm2.Units,
                            Discount_per: curElm2.Discount_per,
                            Sgst_per: curElm2.Sgst_per,
                            Cgst_per: curElm2.Cgst_per,
                            Igst_per: curElm2.Igst_per,
                            Tax_per: curElm2.Tax_per,
                            Product_status: curElm2.Product_status,
                            Updated_by: curElm2.Updated_by,
                            Date_updated: curElm2.Date_updated,
                            Time_updated: curElm2.Time_updated
                            })} 
                            />
                            </div>
                             <br/><br/>
                             <button class="btn btn-light" onClick={handlePrint2}>download</button>
                            </td>


                            <td hidden={cd_col22_val==1} ><i class="mdi mdi-pencil-box text-warning" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                            //Setting Bank Id and Redirect to Edit
                            sessionStorage.setItem("Productid00",curElm2.Product_id);getproductdatabyId();document.getElementById('edit-form').style.display = 'block'; }}></i></td>
                            <td hidden={cd_col23_val==1} ><i class="mdi mdi-delete-forever text-danger" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                            //Setting Bank Id for Delete
                            sessionStorage.setItem("DProductId00",curElm2.Product_id); document.getElementById('delete-card').style.display = 'block'; }}></i></td>
                          </tr>
                          </>
                         )
                         }
					   
	                  		 )
                         }
                        
                        </tbody>
                      </table>
                    </div>
                       {/* PAGINATION SECTION START */}
                       <div>
                       <div class="col-12" style={{marginBottom:"2rem",marginTop:"1rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                       
                          <div class="dropdown">
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber-1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-left-drop-circle text-success"></i></button>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber+1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-right-drop-circle text-success"></i></button>
                          </div>
                          </div>
                          </div>
                    </div>
                    {/* PAGINATION SECTION END */}

                    <button class="btn btn-light" onClick={handlePrint}><i class="mdi mdi-cloud-print text-dark"></i></button>
                    <button class="btn btn-light" onClick={downloadExcel}><i class="mdi mdi-file-excel text-dark"></i></button>
                    
                  </div>
                </div>
              </div>
              {/* PRODUCTS ITEM LIST END */}

               {/* ADD PRODUCT GROUP EDIT FORM START */}
               <div class="row">
              <div class="col-12 grid-margin create-form" id="edit-form1" style={{display:"none"}}>
              <div class="card">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ 
                        //Removing Sessions and Closing Add Bank Edit
                        setegroup_name_er(" ");setegroup_type_er(" ");setegroup_industry_er(" ");setegroup_sector_er(" ");setegroup_category_er();setegroup_status_er();seterror_msg(" ");
                        setegroup_name(" ");setegroup_type(" ");setegroup_industry(" ");setegroup_sector(" ");setegroup_category();setegroup_status();
                        document.getElementById("edit-form1").style.display="none";
                        sessionStorage.removeItem("GroupId00");
                        
                         }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                  <div class="card-body">
                    <h4 class="card-title">Edit Product Group</h4>
                    <div class="form-sample">
                      <p class="card-description">edit your product group details here</p>
                      <code>{error_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Group Name<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Red T-Shirt" id="egroup_name" value={egroup_name} />
                              <p class="text-muted" style={{fontSize:"0.7rem", textAlign:"left"}}>this field cannot be changed</p>
                              <code>{egroup_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="Type Regular" id="egroup_type" value={egroup_type} onChange={updateegroup_type} />
                            <code>{egroup_type_er}</code>
                            </div>
                          </div>
                        </div>   
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Industry</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Clothing" id="egroup_industry" value={egroup_industry} onChange={updateegroup_industry} />
                            <code>{egroup_industry_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Sector</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Adidas" id="egroup_sector" value={egroup_sector} onChange={updateegroup_sector} />
                            <code>{egroup_sector_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                      <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Category</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="On Demand" id="egroup_category" value={egroup_category} onChange={updateegroup_category} />
                            <code>{egroup_category_er}</code>
                            </div>
                          </div>
                        </div> 
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Status</label>
                            <div class="col-sm-9">
                            <select class="form-control" id="group_status" style={{padding:"1rem"}} value={egroup_status} onChange={updateegroup_status} >
                            <option>Select Status</option>
                            <option value={egroup_status}>{egroup_status}</option>
                              <option value="active">active</option>
                              <option value="inactive">inactive</option>
                              </select>
                              <code>{egroup_status_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                    
                    

                        
                      
                        <button class="btn btn-primary" style={{fontWeight:"bold"}} onClick={editproductsgroup}>Edit Group</button>
                      
                       
    
                     
                   
                    </div>
                  </div>
                </div>
              </div>
              </div>
            {/* ADD PRODUCT GROUP EDIT FORM END */}

              {/* PRODUCTS GROUPS LIST START */}
              <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Product Groups</h4>
                    <p class="card-description">product groups and catalogs
                    </p>

                                        
                        {/* FILTER SECTION 1 START */}
                        <hr/>
                            <div id="filter-top-card1" class="row col-12" style={{textAlign:"left",display:"none"}}>
                            <div class="d-flex justify-content-between">
                            <div>
                            <h4 style={{padding:'1rem'}}>Filters</h4>
                           </div>
                           <div>
                    
                           <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("filter-top-card1").style.display="none"; }}>
                           <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                           </button>
                           </div>
                           </div>
        
                        <div class="row">         
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Group Id</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" placeholder="22456" id="filter_group_id" value={filter_group_id} onChange={updatefilter_group_id}  />
                              <code>{filter_group_id_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Group Name</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" placeholder="GROUP ABC" id="filter_group_name" value={filter_group_name} onChange={updatefilter_group_name}  />
                              <code>{filter_group_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Group Type</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" placeholder="Regular" id="filter_group_type" value={filter_group_type} onChange={updatefilter_group_type}  />
                              <code>{filter_group_type_er}</code>
                            </div>
                          </div>
                        </div>
                        </div>

                        <div class="row">         
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Group Category</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" placeholder="Highly Rated" id="filter_group_category" value={filter_group_category} onChange={updatefilter_group_category}  />
                              <code>{filter_group_category_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Group Industry</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" placeholder="Industry" id="filter_group_industry" value={filter_group_industry} onChange={updatefilter_group_industry}  />
                              <code>{filter_group_industry_er}</code>
                            </div>
                          </div>
                        </div>              
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Group Sector</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" placeholder="Sector" id="filter_group_sector" value={filter_group_sector} onChange={updatefilter_group_sector}  />
                              <code>{filter_group_sector_er}</code>
                            </div>
                          </div>
                        </div>
                       </div>
                     
                        
                       <div class="row">         
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Group Status</label>
                            <div class="col-sm-8">
                              <select class="form-control" style={{padding:"1rem"}} placeholder="Highly Rated" id="filter_group_status" value={filter_group_status} onChange={updatefilter_group_status}>
                              <option value=" ">Select Status</option>
                              <option value="active">active</option>
                              <option value="inactive">inactive</option>
                              </select>
                              <code>{filter_group_status_er}</code>
                            </div>
                          </div>
                        </div>
                    
                        <div class="col-md-4">
                        <button class="btn btn-primary" onClick={()=>{ setfilter_val1(1); getData(); }}>Apply Filters</button>
                        <button class="btn btn-danger" style={{marginLeft:"1rem"}} onClick={()=>{ setfilter_val1(0); getData(); }}>Remove Filters</button>
              
                        </div>

                      
                        
                        </div>


                            <hr/>                      
                            </div>
                            
                      {/* FILTER SECTION 1 END */}

                    <div class="col-12" style={{marginBottom:"2rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                       
                          <div class="dropdown">
                          <button class="btn btn-warning" onClick={()=>{ document.getElementById("filter-top-card1").style.display="block"; }}><i class="mdi mdi-filter-outline"></i></button>
                          </div>
                          </div>
                          </div>

                    <div class="table-responsive" id="reports" ref={componentRef1}>
                      <table class="table table-striped">
                        <thead>
                          <tr >
                            <th  style={{fontWeight:"bold"}}>Sn</th>
                            <th  style={{fontWeight:"bold"}}>Group Id</th>
                            <th  style={{fontWeight:"bold"}}>Group Name</th>
                          {/*  <th  style={{fontWeight:"bold"}}>Products</th> */}
                            <th  style={{fontWeight:"bold"}}>Industry</th>
                            <th  style={{fontWeight:"bold"}}>Sector</th>
                            <th  style={{fontWeight:"bold"}}>Type</th>
                            <th  style={{fontWeight:"bold"}}>Category</th>
                            <th  style={{fontWeight:"bold"}}>Status</th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                       groups.map((curElm1,ind)=>{

                         return(
                         <>
                        <tr key={curElm1.Group_id}>
                            <td>{curElm1.Sn}</td>
                            <td>{curElm1.Group_id}</td>
                            <td>{curElm1.Group_name}</td>
                            <td>{curElm1.Industry}</td>
                            <td>{curElm1.Sector}</td>
                            <td>{curElm1.Type}</td>
                            <td>{curElm1.Category}</td>
                            <td><label class="badge badge-success"  hidden={(curElm1.Group_status == "inactive")}>active</label><label class="badge badge-danger"  hidden={(curElm1.Group_status == "active")}>inactive</label></td>

                            <td><i class="mdi mdi-pencil-box text-warning" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                          //Setting Group Id and Redirect to Edit
                           sessionStorage.setItem("GroupId00",curElm1.Group_id);getproductsgroupdatabyId();document.getElementById('edit-form1').style.display = 'block'; }}></i></td>
                          {/*}  <td><i class="mdi mdi-delete-forever text-danger" style={{fontSize:"1.4rem"}}></i></td> */}
                          </tr>
                          </>
                         )
                         }
					   
	                  		 )
                         }
                     
                         
                        </tbody>
                      </table>
                    </div>
                       {/* PAGINATION SECTION START */}
                       <div>
                       <div class="col-12" style={{marginBottom:"2rem",marginTop:"1rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                       
                          <div class="dropdown">
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber-1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-left-drop-circle text-success"></i></button>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber+1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-right-drop-circle text-success"></i></button>
                          </div>
                          </div>
                          </div>
                    </div>
                    {/* PAGINATION SECTION END */}

                    <button class="btn btn-light" onClick={handlePrint1}><i class="mdi mdi-cloud-print text-dark"></i></button>
                    <button class="btn btn-light" onClick={downloadExcel1}><i class="mdi mdi-file-excel text-dark"></i></button>

                  </div>
                </div>
              </div>
              {/* PRODUCTS GROUPS LIST END */}

            
          </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
        {/*}  <footer class="footer">
            <div class="d-sm-flex justify-content-center justify-content-sm-between">
              <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
</>
);

}
}
export default Usercatalog;