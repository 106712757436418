import React from "react";
import { useState, useEffect, Component } from "react";
import { useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
//import axios from '../../axios.js';
import moment from "moment/moment";

import "../Resources/homebtcss.css";
import "../Resources/home2css.css";
import "../Resources/serviceshomecss.css";
import "./ankitcss.css";

import Footer3 from "../Supportingfiles/footer3";
//import { Navbar } from "flowbite-react";
import Navbar3 from "../Supportingfiles/navbar3";

//import "../App.css";

function Testfornow() {
  return (
    <>
   <html lang="en">
        <head>
          {/*} Required meta tags */}
          <meta charset="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <title>STARTUP CONNECT | PAGES</title>
          {/*} plugins:css */}
          <link
            rel="stylesheet"
            href="panel/assets/vendors/mdi/css/materialdesignicons.min.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/css/vendor.bundle.base.css"
          />
          {/*} endinject */}
          {/*} Plugin css for this page */}
          <link
            rel="stylesheet"
            href="panel/assets/vendors/jquery-bar-rating/css-stars.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/font-awesome/css/font-awesome.min.css"
          />
          {/*} End plugin css for this page */}
          {/*} inject:css */}
          {/*} endinject */}
          {/*} Layout styles */}
          <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
          {/*} End layout styles */}
          <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
        </head>
        <body>
          <div class="home2-container">
            <Navbar3 />

            {/* HEADER SECTION START */}
            <section /*class="sv-header-section" */>
              <div class="sv-header-container1">
                <div class="sv-header-left" style={{ padding: "2rem" }}>
                  <h1 style={{ color: "#004463" }}>
                  CMA Report Preparation

                  </h1>
                  <p>
                    <h3 style={{ color: "red" }}>
                      Prices Starting from INR ………/- only
                    </h3>

                    <div style={{ marginBottom: "5px" }}>
                      <span style={{ paddingRight: "10px" }}>✅</span>
                      <span>Complete Online Process</span>
                    </div>
                    <div style={{ marginBottom: "5px" }}>
                      <span style={{ paddingRight: "10px" }}>💸</span>
                      <span>Lowest Price Gurantee</span>
                    </div>
                    <div style={{ marginBottom: "5px" }}>
                      <span style={{ paddingRight: "10px" }}>✅</span>
                      <span>Get Regular Whatsapp/Email Updates</span>
                    </div>
                    <div style={{ marginBottom: "5px" }}>
                      <span style={{ paddingRight: "10px" }}>✅</span>
                      <span>Free Expert Assistance</span>
                    </div>
                  </p>

                  <div class="home2-services-features-list">
                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img
                          loading="lazy"
                          src="https://cdn-icons-png.flaticon.com/128/1087/1087840.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>500+</h3>
                        <h5 style={{ fontWeight: "bold" }}>Projects</h5>
                      </div>
                    </div>

                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img
                          loading="lazy"
                          src="https://cdn-icons-png.flaticon.com/128/12735/12735475.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>25+</h3>
                        <h5 style={{ fontWeight: "bold" }}>Cities</h5>
                      </div>
                    </div>

                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img
                          loading="lazy"
                          src="https://cdn-icons-png.flaticon.com/128/2991/2991235.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>
                          4{" "}
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/7656/7656139.png"
                            style={{ height: "2rem", width: "2rem" }}
                          />
                          +
                        </h3>
                        <h5 style={{ fontWeight: "bold" }}>Google Ratings</h5>
                      </div>
                    </div>
                  </div>

                  <div class="row d-flex justify-content-left">
                    <div class="col-sm-6 stretch-card grid-margin">
                      <a href="https://calendly.com/thestartupconnect/30min">
                        <button
                          id="home2-modules-section-btn"
                          style={{ marginLeft: "0" }}
                        >
                          Book Free Consultancy{" "}
                          <i class="mdi mdi-arrow-right"></i>
                        </button>
                      </a>
                    </div>
                    <div class="col-sm-6 stretch-card grid-margin">
                      <a
                        href="/home1/startupbrochure1.pdf"
                        download="Startup Connect Services Brochure"
                      >
                        <button
                          id="home2-modules-section-btn"
                          style={{
                            backgroundImage:
                              "linear-gradient(to right, #208d17, #00ad11)",
                          }}
                        >
                          Download Brochure <i class="mdi mdi-download"></i>
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  class="sv-header-right" /* style={{backgroundColor:"#96dfff",padding:"2rem",borderRadius:"15px",boxShadow:"2px 3px 3px 2px #cbcecf"}} */
                >
                  <img
                    loading="lazy"
                    src="https://img.freepik.com/free-vector/recruiting-agency-isometric-illustration-coworkers-candidate-employer-3d-cartoon-characters-headhunting-human-resources-department_575670-1091.jpg"
                    style={{ width: "90%", height: "100%" }}
                  />
                </div>
              </div>
            </section>
            {/* HEADER SECTION END */}
            <hr />

            {/* SIDEBAR AND MAIN PAGE START */}
            <div className="container-scroller">
              {/*<Sidebar /> */}
              <div className="container-fluid page-body-wrapper">
                {/*} partial */}
                <div className="main-panel">
                  <hr />

                  {/* MORE INFORMATION SECTION START */}
                  <div class="row" style={{ padding: "2rem" }}>
                    <div
                      class="col-sm-9 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div>
                        <h2 style={{ fontWeight: "bold" }}>
                          What is{" "}
                          <span style={{ color: "#9f23c2" }}>
                          CMA DATA??
                          </span>
                        </h2>
                        <br />
                        
                        <p style={{ fontSize: "1rem" }}>
                        Credit Monitoring Arrangement, commonly known as CMA Data is the financial report used by lending institutions to appraise and analyse the financial position of a company before lending. Under the present rules, the Reserve Bank of India has mandated all banks to prepare CMA for lending to large borrowers. Hence, CMA data is required for project loans, term loans and for working capital limits.
                        </p>
                        
                      </div>
                    </div>
                    <div class="col-sm-3 stretch-card grid-margin">
                      <img
                        loading="lazy"
                        src="https://img.freepik.com/free-vector/business-deal-concept-illustration_114360-1535.jpg"
                        style={{ width: "90%", height: "100%" }}
                      />
                    </div>
                  </div>
                  {/* MORE INFORMATION SECTION END */}

                  <hr />
                  <h2>
                  KEY ASPECTS FOR DRAFTING CMA DATA
                  </h2>
                  {/* BENEFITS SECTION START */}
                  <div class="row" style={{ padding: "2rem" }}>
                    <div
                      class="col-sm-2 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center mb-3">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/3240/3240831.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        
                        <p>
                        All assumptions and estimates used in preparation of CMA should be mentioned separately
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center mb-3">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/14488/14488465.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        
                        <p>
                        Future projections should be realistic and not merely arithmetic multiples of current performance
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center mb-3">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/993/993857.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        
                        <p>
                        Fluctuations in performance should be strongly justifiable
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center mb-3">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/1907/1907675.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        
                        <p>
                        All fixed assets, depreciation and loan repayment schedules should be annexed and linked to CMA Data{" "}
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center mb-3">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/179/179385.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        
                        <p>
                        Past performance and actual numbers should be exactly as per Audited Financials
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center mb-3">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/2519/2519230.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        
                        <p>
                        The company should be able to justify the performance and numbers projected
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center mb-3">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/4435/4435123.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        
                        <p>
                        In case of multiple businesses activity or locations, detailed annexure should be attached showing breakup of how the projected numbers are arrived at{" "}
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center mb-3">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/11538/11538737.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        
                        <p>
                        CMA Data should represent a viable business performance - over borrowing is unfavourable and cannot be justified through financial ratios
{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* BENEFITS SECTION END */}



                  <hr />

                  <h2>FORMAT AND CONTENTS OF CMA</h2>
                  <h5>The format of CMA Data is generally fixed, and it contains preceding two years actual performance, ongoing year estimated figures and next five years projections
      <br/> <br/>It contains detailed
</h5>
                  <div class="row" style={{ padding: "2rem" }}>
                    <div
                      class="col-sm-2 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/1052/1052846.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>

                        <h4 class="mt-3">Profit and Loss Account</h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/2228/2228787.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">
                        Cash Flow Statement
                        </h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/9588/9588758.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Ratio Analysis</h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/9875/9875524.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3" style={{ fontSize: "15px" }}>
                        Balance Sheet
                        </h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/2228/2228787.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3" style={{ fontSize: "15px" }}>
                        Cash Flow Statement
                        </h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/1584/1584961.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">
                        Analysis of Maximum Permissible Bank Finance{" "}
                        </h4>
                      </div>
                    </div>
                  </div>
                  {/* Pricing SECTION START */}
                  <section id="pricing" class="bg-white">
                    <div class="container">
                      <h2 class="text-center">PRICING</h2>
                      <div class="spacer spacer-line border-primary">
                        &nbsp;
                      </div>
                      <div class="spacer">&nbsp;</div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="pricing-table">
                            <div
                              class="pricing-table-title"
                              style={{ backgroundColor: "#2A3693" }}
                            >
                              <h5
                                class="pricing-title bg-info-hover text-white "
                                style={{ borderBottom: "1px solid white" }}
                              >
                                Business
                              </h5>
                            </div>
                            <div
                              class="pricing-table-price text-center "
                              style={{ backgroundColor: "#2A3693" }}
                            >
                              <p
                                class="title-font d-flex"
                                style={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <span
                                  class="pricing-currency"
                                  style={{ color: "#F15A27", fontSize: "3rem" }}
                                >
                                  ₹
                                </span>

                                <span
                                  class="pricing-price"
                                  style={{
                                    color: "#F15A27",
                                    marginLeft: "0.5rem",
                                  }}
                                >
                                  3999/-*
                                </span>
                              </p>
                            </div>
                            <div class="pricing-table-content">
                              <ul>
                                <li>
                                  <strong>Preparation of CMA Report for working capital limit upto INR 25 Lakh</strong>
                                </li>
                                <li>
                                  <strong>Two responses to bank queries</strong>
                                </li>
                                
                              </ul>
                              <div class="pricing-table-button" >
                                <a href="#x" class="btn btn-primary" style = {{backgroundColor:"#F15A27" }}>
                                  <span style = {{fontSize:'1.1rem'}}>Get Started</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="pricing-table bg-lightgrey">
                          <div
                              class="pricing-table-title"
                              style={{ backgroundColor: "#2A3693" }}
                            >
                              <h5
                                class="pricing-title bg-info-hover text-white "
                                style={{ borderBottom: "1px solid white" }}
                              >
                                Professional
                              </h5>
                            </div>
                            <div
                              class="pricing-table-price text-center "
                              style={{ backgroundColor: "#2A3693" }}
                            >
                              <p
                                class="title-font d-flex"
                                style={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <span
                                  class="pricing-currency"
                                  style={{ color: "#F15A27", fontSize: "3rem" }}
                                >
                                  ₹
                                </span>

                                <span
                                  class="pricing-price"
                                  style={{
                                    color: "#F15A27",
                                    marginLeft: "0.5rem",
                                  }}
                                >
                                  5999/-*
                                </span>
                              </p></div>
                            <div class="pricing-table-content">
                              <ul>
                                <li>
                                  <strong>Preparation of CMA Report for working capital limit upto INR  200 Lakh</strong>
                                </li>
                                <li>
                                  <strong>Three responses to bank queries</strong>
                                </li>
                                
                              </ul>
                              <div class="pricing-table-button" >
                                <a href="#x" class="btn btn-primary" style = {{backgroundColor:"#F15A27" }}>
                                  <span style = {{fontSize:'1.1rem'}}>Get Started</span>
                                </a>
                              </div>
                            </div>
                          </div>

                          
                        </div>

                        <div class="col-md-4">
                          <div class="pricing-table bg-lightgrey">
                          <div
                              class="pricing-table-title"
                              style={{ backgroundColor: "#2A3693" }}
                            >
                              <h5
                                class="pricing-title bg-info-hover text-white "
                                style={{ borderBottom: "1px solid white" }}
                              >
                                Expert
                              </h5>
                            </div>
                            <div
                              class="pricing-table-price text-center "
                              style={{ backgroundColor: "#2A3693" }}
                            >
                              <p
                                class="title-font d-flex"
                                style={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <span
                                  class="pricing-currency"
                                  style={{ color: "#F15A27", fontSize: "3rem" }}
                                >
                                  ₹
                                </span>

                                <span
                                  class="pricing-price"
                                  style={{
                                    color: "#F15A27",
                                    marginLeft: "0.5rem",
                                  }}
                                >
                                  9999/-*
                                </span>
                              </p></div>
                            <div class="pricing-table-content">
                              <ul>
                                <li>
                                  <strong>Preparation of CMA Report for working capital limit upto INR  500 Lakh</strong>
                                </li>
                                <li>
                                  <strong>Four responses to bank queries</strong>
                                </li>
                                
                              </ul>
                              <div class="pricing-table-button" >
                                <a href="#x" class="btn btn-primary" style = {{backgroundColor:"#F15A27" }}>
                                  <span style = {{fontSize:'1.1rem'}}>Get Started</span>
                                </a>
                              </div>
                            </div>
                          </div>

                          
                        </div>
                      </div>
                    </div>
                  </section>
                  {/* Pricing SECTION Ends */}
                  {/* REQUIREMENTS SECTION START */}
                  
                  <hr />
                  <h2>CMA Report Process</h2>

                  {/* PROCESS SECTION START */}
                  <div class="row">
                    <div class="col-sm-3 stretch-card grid-margin">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/free-vector/paying-bills-concept-illustration_114360-19357.jpg?w=740&t=st=1706779500~exp=1706780100~hmac=ad8e6bf9dc5da282c80e40c82c023c68f94bea72270aa35612471e5892f8985b"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h3 class="font-weight-bold"> Step 1 </h3>
                          <div class="d-flex justify-content-between">
                            <p class="mb-0" style={{ fontSize: "1rem" }}>
                              Payment and Document Submission
                              <br />
                              Expert review your Documents
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-3 stretch-card grid-margin">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/free-vector/audit-concept-illustration_114360-6397.jpg?w=996&t=st=1706980269~exp=1706980869~hmac=1eef66789c185c2b1f1caef37a1a79e50de09ff3eb9b7375c46e1327e60094fe"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h3 class="font-weight-bold"> Step 2 </h3>
                          <div class="d-flex justify-content-between">
                            <p class="mb-0" style={{ fontSize: "1rem" }}>
                            Draft CMA report Preperation
                              
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-3 stretch-card grid-margin">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/free-vector/audit-concept-illustration_114360-6397.jpg?w=996&t=st=1706980269~exp=1706980869~hmac=1eef66789c185c2b1f1caef37a1a79e50de09ff3eb9b7375c46e1327e60094fe"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h3 class="font-weight-bold"> Step 3 </h3>
                          <div class="d-flex justify-content-between">
                            <p class="mb-0" style={{ fontSize: "1rem" }}>
                            Final CMA Report
{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                                    </div>
                  {/* PROCESS SECTION END */}

                  {/* Why StartUp Connect Starts */}

                  
                  {/* Why StartUp Connect Ends */}

               

                  {/* Documents SECTION Starts */}
                 
                  {/* Documents SECTION Ends */}
                  {/* COMPLIANCES SECTION START */}
                 
                
                  {/* COMPLIANCES SECTION END */}
               
                  {/* COMPARISON TABLE START */}
                 
                  

                  {/* COMPARISON TABLE END */}

                  <hr />
                 
                  {/* FAQ SECTION END */}

                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
            {/* SIDEBAR AND MAIN PAGE END */}

            <Footer3 />
          </div>
          {/*} container-scroller */}
          {/*} plugins:js */}
          <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
          {/*} endinject */}
          {/*} Plugin js for this page */}
          <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
          <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
          <script
            src="panel/assets/js/jquery.cookie.js"
            type="text/javascript"
          ></script>
          {/*} End plugin js for this page */}
          {/*} inject:js */}
          <script src="panel/assets/js/off-canvas.js"></script>
          <script src="panel/assets/js/hoverable-collapse.js"></script>
          <script src="panel/assets/js/misc.js"></script>
          <script src="panel/assets/js/settings.js"></script>
          <script src="panel/assets/js/todolist.js"></script>
          {/*} endinject */}
          {/*} Custom js for this page */}
          <script src="panel/assets/js/dashboard.js"></script>
          {/*} End custom js for this page */}
        </body>
      </html>
    </>
  );
}

export default Testfornow;
