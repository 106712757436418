import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';





/* Import home from File */




import HomeH from './homehome';
import Contact from './homecontactform';
import Contacta from './contact.js';
import Homeab from './homeab.js';

import Intpg from './Internalpages/intpg';

//Internal Pages Follow Through
import Socialmediamanagement from './Internalpages/socialmediamanagement';
import Digitalmarketing from './Internalpages/digitalmarketing';
import Dataconsultancy from './Internalpages/dataconsultancy';
import Designbrandingconsultancy from './Internalpages/designbrandingconsultancy';
import Ecommerceconsultancy from './Internalpages/ecommerceconsultancy';
import Gstfiling from './Internalpages/gstfiling';
import Gstregistration from './Internalpages/gstregistration';
import HRconsultancy from './Internalpages/hrconsultancy';
import ITtechconsultancy from './Internalpages/ittechconsultancy';
import ITRfiling from './Internalpages/itrfiling';
import LLPregistration from './Internalpages/llpregistration';
import Marketingconsultancy from './Internalpages/marketinconsultancy';
import Managementconsultancy from './Internalpages/managementconsultancy';
import Msmeregistration from './Internalpages/msmeregistration';
import OPCregistration from './Internalpages/opcregistration';
import Pitchdeckcreation from './Internalpages/pitchdeckcreation';
import Partnershipregistration from './Internalpages/partnershipregistration';
import Rentagreementdrafting from './Internalpages/rentagreementdrafting';
import Resumebuilding from './Internalpages/resumebuilding';
import Section8registration from './Internalpages/section8registration';
import Salesconsultancy from './Internalpages/salesconsultancy';
import Strategyconsultancy from './Internalpages/strategyconsultancy';
import Startupindiaregistration from './Internalpages/startupindiaregistration';
import Soleproprietorshipregistration from './Internalpages/soleproprietorshipregistration';
import Accountingsoftware from './Internalpages/accountingsoftware';
import Billingsoftware from './Internalpages/billingsoftware';
import Copywriting from './Internalpages/copywriting';
import CRMsoftware from './Internalpages/crmsoftware';
import Contentwriting from './Internalpages/contentwriting';
import Emailingmessaging from './Internalpages/emailingmessaging';
import Ecomstoresetup from './Internalpages/ecomstoresetup';
import ERPsoftware from './Internalpages/erpsoftware';
import Graphicsdesigning from './Internalpages/graphicsdesigning';
import Inventorymanagementsoftware from './Internalpages/imssoftware';
import POSsoftware from './Internalpages/possoftware';
import Productpromotion from './Internalpages/productpromotion';
import SEO from './Internalpages/seo';
import Customizedsolutions from './Internalpages/customizedsolutions';
import Ecommercewebsite from './Internalpages/ecommercewebsite';
import Institutionwebsite from './Internalpages/institutionwebsite';
import Membersmanagement from './Internalpages/membersmanagement';
import Paas from './Internalpages/paas';
import Saas from './Internalpages/saas';
import Pvtltdcompany from './Internalpages/pvtltdcompany';
import Staticdisplaywebsite from './Internalpages/staticdisplaywebsite';
import Webapp from './Internalpages/webapp';


//SERVICES PORTAL
import Register from './Servicesportal/register';
import Login from './Servicesportal/login';

import Clientdashboard from './Servicesportal/clientdashboard';
//import Projectdashboard from './Servicesportal/projectdashboard';
import Projectcreate from './Servicesportal/createproject';
import Teamlogin from './Servicesportal/teamlogin';
import Team_projectcreate from './Servicesportal/team_createproject';

//SERVICES PORTAL - TEAM
import Quotation_page from './Servicesportal/Team_inside/quotation_page';
import Create_quotation from './Servicesportal/Team_inside/create_quotation';
import Quotation from './Servicesportal/Team_inside/quotation';
import Order_page from './Servicesportal/Team_inside/order_page';
import Orderinvoice from './Servicesportal/Team_inside/orderinvoice';
import Create_order from './Servicesportal/Team_inside/create_order';
import Payments_page from './Servicesportal/Team_inside/payments_page';
import Projects_page from './Servicesportal/Team_inside/projects_page';
import Projectdashboard from './Servicesportal/Team_inside/project_dashboard';
import Feedbacks_page from './Servicesportal/Team_inside/feedbacks_page';

//SERVICES PORTAL - CLIENTS
import Client_quotation_page from './Servicesportal/Client_inside/quotation_page';
import Client_order_page from './Servicesportal/Client_inside/order_page';
import Client_payments_page from './Servicesportal/Client_inside/payments_page';
import Client_projects_page from './Servicesportal/Client_inside/projects_page';
import Clientprojectdashboard from './Servicesportal/Client_inside/project_dashboard';
import Clientfeedbacks_page from './Servicesportal/Client_inside/feedbacks_page';


//ADMINISTRATION
import Adminregistration from './Administration/adminregistration';
import Adminlogin from './Administration/adminlogin';
//import Dashboard from './Administration/Extracomponents/dashboard';
import Admindashboard from './Administration/Adminmanager/dashboard';
import Adminpaneldashboard from './Administration/Adminmanager/Adminpanel/dashboard';
import Teampaneldashboard from './Administration/Adminmanager/TeamPanel/dashboard';
import Registrations_list from './Administration/Adminmanager/registrations_list';
import Login_history from './Administration/Adminmanager/login_history';
import Customers_list from './Administration/Adminmanager/customers_list';
import Clients_list from './Administration/Adminmanager/clients_list';
import Projects_list from './Administration/Adminmanager/projects_list';
import Admin_quotation_page from './Administration/Adminmanager/quotation_page';
import Adminquotation from './Administration/Adminmanager/quotation';
import Admin_order_page from './Administration/Adminmanager/order_page';
import Adminorderinvoice from './Administration/Adminmanager/orderinvoice';
import Admin_payments_page from './Administration/Adminmanager/payments_page';
import Admincreateproject from './Administration/Adminmanager/createproject';
import Admineditproject from './Administration/Adminmanager/editproject';
import Adminprojectdashboard from './Administration/Adminmanager/project_dashboard';
import Adminaddnewtask from './Administration/Adminmanager/addnewtask';
import Admin_feedbacks_page from './Administration/Adminmanager/feedbacks_page';

//General
import Customersdashboard from './Administration/General/customers';
import Insidedashboard from './Servicesportal/insidedash';



//HIRING PORTAL
import HiringLogin from './Hiringportal/login';
import Selection from './Hiringportal/selection';
import Candidateform from './Hiringportal/candidateform';
import Candidatedashboard from './Hiringportal/Candidate/dashboard';
import Candidatesaveddashboard from './Hiringportal/Candidate/saveddashboard';
import Candidateapplieddashboard from './Hiringportal/Candidate/applieddashboard';
import Candidateapply from './Hiringportal/Candidate/apply';
import Candidatecreateprofile from './Hiringportal/Candidate/Candidateprofile';


import Employerdashboard from './Hiringportal/Employer/dashboard';
import Employerjobsdashboard from './Hiringportal/Employer/jobs';
import Employercreatejob from './Hiringportal/Employer/createjob';
import Employerviewapplications from './Hiringportal/Employer/viewapplications';
import Employercreateapplicationquestions from './Hiringportal/Employer/createapplicationquestions';
import Employerinvitations from './Hiringportal/Employer/invitations';
import Employerassignments from './Hiringportal/Employer/assignments';
import Employercreateassignment from './Hiringportal/Employer/createassignment';
import Employerviewresume from './Hiringportal/Employer/resume';
import Employerviewapplication from './Hiringportal/Employer/viewapplication';
import Employerviewcandidateassignment from './Hiringportal/Employer/viewcandidateassignment';
import Employerviewassignment from './Hiringportal/Employer/viewassignment';
import Employercreatecompany from './Hiringportal/Employer/createcompany';
import Employercompanies from './Hiringportal/Employer/companies';
import Candidatecompanies from './Hiringportal/Candidate/companies';


//import Hiredashboard from './Hire/dashboard';
//import Login1 from './Hire/dashboard1';

//SUPPORTING DOCUMENTS
import Disclaimer from './Supportingfiles/disclaimer';
import Termsandconditions from './Supportingfiles/termsandconditions1.js';
import Refundpolicy from './Supportingfiles/refundpolicy1.js';
import Privacypolicy from './Supportingfiles/privacypolicy1.js';




//NEW HOME PAGES
//import Startuphome from './startuphome';
import Contesthome from './contesthome';
//import Startuptools from './startuptools-home';
import Startuphire from './hiringhome';



//BUSINESS TOOLS
import Businesstoolshome from './Businesstools/dashboard';
import Businesstoolslogin from './Businesstools/login';
import Poshome from './Businesstools/User/poshome';
import Businesstoolsentry from './Businesstools/entry';
import Startworking from './Businesstools/startworking';
import Usercustomers from './Businesstools/User/customers';
import Usersuppliers from './Businesstools/User/suppliers';
import Usercash from './Businesstools/User/cash';
import Userbank from './Businesstools/User/bank';
import Usercontra from './Businesstools/User/contra';
import Usercatalog from './Businesstools/User/catalog';
import Userinventory from './Businesstools/User/inventory';
import Userincomes from './Businesstools/User/incomes';
import Userexpenses from './Businesstools/User/expenses';
import Useroutstandingincome from './Businesstools/User/outstandingincome';
import Useroutstandingexpense from './Businesstools/User/outstandingexpense';
import Userdebtor from './Businesstools/User/debtor';
import Usercreditor from './Businesstools/User/creditors';
import UserSgst from './Businesstools/User/sgst';
import UserCgst from './Businesstools/User/cgst';
import UserIgst from './Businesstools/User/igst';
import UserTaxes from './Businesstools/User/taxes';
import UserSales from './Businesstools/User/sales';
import UserPurchases from './Businesstools/User/purchases';
import UserSalesreturn from './Businesstools/User/salesreturn';
import UserPurchasesreturn from './Businesstools/User/purchasereturn';
import Sales_invoice from './Businesstools/User/sales_invoice.js';
import Purchases_invoice from './Businesstools/User/purchases_invoice.js';
import Salesreturn_invoice from './Businesstools/User/salesreturn_invoice.js';
import Purchasereturns_invoice from './Businesstools/User/purchasereturns_invoice.js';
import UserSales_addproducts_edit from './Businesstools/User/sales_addproducts_edit.js';
import UserPurchases_addproducts_edit from './Businesstools/User/purchases_addproducts_edit.js';
import UserSalesreturn_addproducts_edit from './Businesstools/User/salesreturn_addproducts_edit.js';
import UserPurchasereturns_addproducts_edit from './Businesstools/User/purchasereturns_addproducts_edit.js';
import UserSales_addproducts from './Businesstools/User/sales_addproducts';
import UserPurchases_addproducts from './Businesstools/User/purchase_addproducts';
import UserSalesreturn_addproducts from './Businesstools/User/salesreturn_addproducts';
import UserPurchasereturn_addproducts from './Businesstools/User/purchasereturn_addproducts';


import Businesstools_adminregister from './Businesstools/User/admin_register';
import Businesshome from './Businesstools/User/businesshome';
import Businesstools_userregister from './Businesstools/User/user_register';
import Userdebtors from './Businesstools/User/debtors.js';
import Charthome from './Businesstools/components/Chartshome.js';
import Otp_verify from './Businesstools/User/otp_verify.js';
import Forget_pass from './Businesstools/User/forget_pass.js';
import Fg_otp_verify from './Businesstools/User/fg_otp_verify.js';
import Password_reset from './Businesstools/User/Password_reset.js';
import Homebt from './homebt.js';
import Pricingdummy from './Businesstools/User/pricingdummy.js';
import Bt_pricing from './Businesstools/User/bt_pricing.js';
import Pricing_page from './Businesstools/User/pricing_page.js';
import Home1 from './home1.js';
import Home2 from './home2.js';
import Mentorregister from './Networking/Mentor/mentorregister.js';
import Startupregister from './Networking/Startup/startupregister.js';
import Startupregister1 from './Networking/Startup/startupregister1.js';
import StartupRegister2 from './Networking/Startup/startupregister2.js';
import Homelogin from './login.js';
import Redirectdashboard from './redirectdashboard.js';
import Free_pricing from './Businesstools/User/freepricing.js';
import Freetrialpage from './Businesstools/User/freetrialpage.js';
import Userteam from './Businesstools/User/team.js';
import Bt_contactsales from './Businesstools/User/bt_contactsales.js';
import Bt_feedback from './Businesstools/User/bt_feedback.js';
import Bt_support from './Businesstools/User/bt_support.js';
import Mentordashboard from './Networking/Mentor/dashboard.js';
import Startupdashboard from './Networking/Startup/dashboard.js';
import Networkinglogin from './Networking/login.js';
import Mentoredit from './Networking/Mentor/mentoredit.js';
import Startupedit from './Networking/Startup/startupedit.js';
import Startupedit1 from './Networking/Startup/startupedit1.js';
import Startupedit2 from './Networking/Startup/startupedit2.js';
import Startupdetails from './Networking/Startup/startupdetails.js';
import Bthome from './bthome.js';
import Aboutus from './aboutus.js';
import Serviceslogin from './Servicesmgmt/Client/login.js';

//Services Portal
import Serviceshome from './serviceshome.js';
import Client_dashredirect from './Servicesmgmt/Client/redirectdashboard.js';
import Client_orders from './Servicesmgmt/Client/orders.js';
import Client_projects from './Servicesmgmt/Client/projects.js';
import Client_feedbacks from './Servicesmgmt/Client/feedbacks.js';
import Client_payments from './Servicesmgmt/Client/payments.js';
import Client_quotations from './Servicesmgmt/Client/quotations.js';
import Team_dashredirect from './Servicesmgmt/Team/redirectdashboard.js';
import Team_feedbacks from './Servicesmgmt/Team/feedbacks.js';
import Team_projects from './Servicesmgmt/Team/projects.js';
import Team_payments from './Servicesmgmt/Team/payments.js';
import Team_projectdashboard from './Servicesmgmt/Team/projectdashboard.js';
import Team_orders from './Servicesmgmt/Team/orders.js';
import Team_create_order from './Servicesmgmt/Team/create_order.js';
import Team_quotations from './Servicesmgmt/Team/quotations.js';
import Team_create_quoatation from './Servicesmgmt/Team/create_quoatation.js';

//New Supporting Documents
import New_termsandconditions from './Supportingfiles/termsandconditions.js';
import New_privacypolicy from './Supportingfiles/privacypolicy.js';
import New_refundandcancellation from './Supportingfiles/refundandcancellation.js';
import Pagedummy from './Insidepages/pagedummy.js';
import Serviceshome1 from './serviceshome1.js';
import Hometest from './hometest.js';
import Pagedummy1 from './Insidepages/pagedummy1.js';
import Staticdisplaywebsite1 from './Insidepages/Websitedev/staticdisplaywebsite.js';
import Webapp1 from './Insidepages/Websitedev/webapp.js';
import Ecommercewebsite1 from './Insidepages/Websitedev/ecommercewebsite.js';
import Institutionwebsite1 from './Insidepages/Websitedev/institutionwebsite.js';
import Enterpriseresourceplanner1 from './Insidepages/Softwaredev/erp.js';
import Customerrelationmanager1 from './Insidepages/Softwaredev/crm.js';
import Billingsoftware1 from './Insidepages/Softwaredev/billing.js';
import Pointofsale1 from './Insidepages/Softwaredev/pos.js';
import Inventoryandwarehouse1 from './Insidepages/Softwaredev/inventoryandwarehouse.js';
import Membersmanagement1 from './Insidepages/Softwaredev/membersmanagement.js';
import Saas1 from './Insidepages/Softwaredev/saas.js';
import Paas1 from './Insidepages/Softwaredev/paas.js';
import Socialmediamanagement1 from './Insidepages/Marketing/socialmediamanagement.js';
import Digitalmarketing1 from './Insidepages/Marketing/digitalmarketing.js';
import Copywriting1 from './Insidepages/Marketing/copywriting.js';
import Contentwriting1 from './Insidepages/Marketing/contentwriting.js';
import Graphicsdesigning1 from './Insidepages/Marketing/graphicsdesigning.js'
import Seo1 from './Insidepages/Marketing/seo.js';
import Ecommercestoresetup1 from './Insidepages/Marketing/ecomstoresetup.js';
import Marketingcampaigns1 from './Insidepages/Marketing/marketingcampaigns.js';
import Privatelimitedregistration1 from './Insidepages/Setup/privatelimitedregistration.js';
import LLPRegistration1 from './Insidepages/Setup/llpregistration.js';
import Soleproprietorshipregistration1 from './Insidepages/Setup/soleproprietorshipregistration.js';
import Startupindiaregistration1 from './Insidepages/Setup/startupindiaregistration.js';
import Partnershipregistration1 from './Insidepages/Setup/partnershipregistration.js';
import CMAData1 from './Insidepages/Compliances/cmadata.js';
import Testfornow from './Insidepages/testfornow.js';
import Publiclimitedregistration1 from './Insidepages/Setup/publiclimitedregistration.js';
import Projectreportpreparation1 from './Insidepages/Fundraising/projectreportpreparation.js';
import Valuation1 from './Insidepages/Fundraising/valuation.js';
import Pitchdeckpreparation1 from './Insidepages/Consultancy/pitchdeckpreparation.js';
import Businessplancreation1 from './Insidepages/Consultancy/businessplancreation.js';
import Startupindiaseedfundscheme1 from './Insidepages/Consultancy/startupindiaseedfundscheme.js';
import Startupconsultancy1 from './Insidepages/Consultancy/startupconsultancy.js';
import Superadminlogin from './Superadmin/superadminlogin.js';
import Superadmindashboard from './Superadmin/dashboard.js';
import Mainadminlogin from './Admin/login.js';
import Mainadminredirectdashboard from './Admin/mainadminredirectdashboard.js';
import Servicesportalmaindashboard from './Admin/servicesportalmaindashboard.js';
import Adminteammanagement from './Admin/teammanagement.js';
import Adminservicescustomers from './Admin/customers.js';
import Adminservicesquotations from './Admin/quotations.js';
import Adminfeedbacks from './Admin/feedbacks.js';
import Adminregistrations from './Admin/registrations.js';
import Adminloginhistory from './Admin/loginhistory.js';
import Adminsalesenquiry from './Admin/salesenquiry.js';
import Adminbtsupport from './Admin/btsupport.js';
import Adminbttransactions from './Admin/bttransactions.js';
import Adminntwstartups from './Admin/ntwstartups.js';
import Teamcard from './Team/teamcard.js';
import Bdtapr3021 from './Team/bdtapr3021.js';
import Bdtapr3022 from './Team/bdtapr3022.js';
import Bdtapr3023 from './Team/bdtapr3023.js';
import Bdtapr3024 from './Team/bdtapr3024.js';
import Bdtapr3025 from './Team/bdtapr3025.js';
import Bdtapr3026 from './Team/bdtapr3026.js';
import Userfinalaccounts from './Businesstools/User/finalaccounts.js';
import Businesstoolsteamlogin from './Businesstools/User/bt_team_login.js';
import Bt_paidplans from './Businesstools/User/bt_paidplans.js';
import Bt_paidplanpage from './Businesstools/User/bt_paidplanpage.js';
import Insideadmin_login from './Businesstools/InsideAdmin/insideadmin_login.js';
import Insideadmin_salesteam from './Businesstools/InsideAdmin/insideadmin_salesteam.js';
import Insideadmin_salescoupon from './Businesstools/InsideAdmin/Insideadmin_salescoupon.js';
import Insideadmin_salescouponhistory from './Businesstools/InsideAdmin/insideadmin_salescouponhistory.js';
import Insideadmin_registrations from './Businesstools/InsideAdmin/Insideadmin_registrations.js';
import Insideadmin_logins from './Businesstools/InsideAdmin/Insideadmin_logins.js';
import Insideadmin_cforders from './Businesstools/InsideAdmin/insideadmin_cforders.js';
import Insideadmin_cfpayments from './Businesstools/InsideAdmin/insideadmin_cfpayments.js';
import Insideteam_login from './Businesstools/InsideAdmin/insideteam_login.js';
import Insideteam_salescoupon from './Businesstools/InsideAdmin/insideteam_salescoupon.js';
import Insideteam_salescouponhistory from './Businesstools/InsideAdmin/insideteam_salescouponhistory.js';
import Insideteam_cforders from './Businesstools/InsideAdmin/insideteam_cforders.js';
import Insideteam_cfpayments from './Businesstools/InsideAdmin/insideteam_cfpayments.js';
import Insideteam_profile from './Businesstools/InsideAdmin/insideteam_profile.js';
import UserQuotations from './Businesstools/User/quotations.js';
import UserQuotation_addproducts from './Businesstools/User/quotation_addproducts.js';
import Quotation_invoice from './Businesstools/User/quotation_invoice.js';
import UserQuotation_addproducts_edit from './Businesstools/User/quotation_addproducts_edit.js';
import Usermanufacturing from './Businesstools/User/manufacturing.js';
import Create_manufacturingproduct from './Businesstools/User/create_manufacturingproduct.js';
import DriveFileUpload from './Extrasss/gdrive.js';
import Edit_manufacturingproduct from './Businesstools/User/edit_manufacturingproduct.js';
import Userassets from './Businesstools/User/assets.js';
import Userassets_history from './Businesstools/User/assets_history.js';
import Useremployees from './Businesstools/User/bt_employees.js';
import Usersalary from './Businesstools/User/salary.js';


//Test



function Routestosite(){

    return(
<>
<Router>
   <Routes>


 {/* MAIN DEFAULT ROUTES */}
   <Route exact path='/' element={<Hometest />} />
   <Route exact path='/home' element={<Hometest />} />
   <Route exact path='/services-home' element={<Serviceshome />} />
   <Route exact path='/book-keeping' element={<Bthome /> } />
   <Route exact path='/startups-network' element={<Networkinglogin />} />
   

   <Route exact path='/home1' element={<Home1 />} />
   <Route exact path='/home5' element={<Home2 />} />
   <Route exact path='/home' element={<HomeH />} />
   <Route exact path='/services1-home' element={<HomeH />} />
   <Route exact path='/services-home1' element={<HomeH />} />
   <Route exact path='/contact' element={<Contact />} />
   <Route exact path='/contact1' element={<Contacta />} />
   <Route exact path='/Homeab' element={<Homeab />} />
   <Route exact path='/ip' element={<Intpg />} /> 
   <Route exact path='/book-keeping' element={<Bthome /> } />
   <Route exact path='/services-home' element={<Serviceshome /> } />
   <Route exact path='/services-home1' element={<Serviceshome1 /> } />
   <Route exact path='/about-us' element={<Aboutus /> } />

   <Route exact path='/pagedummy1' element={<Pagedummy1 /> } />


/* INTERNAl PAGES FOLLOW LINKS */
// WEBSITE DEVELOPMENT
<Route exact path='/staticdisplaywebsite' element={<Staticdisplaywebsite1 /> } />
<Route exact path='/webapp' element={<Webapp1 /> } />
<Route exact path='/ecommercewebsite' element={<Ecommercewebsite1 /> } />
<Route exact path='/institutionwebsite' element={<Institutionwebsite1 /> } />
//SOFTWARE DEVELOPMENT
<Route exact path='/enterpriseresourceplanner' element={<Enterpriseresourceplanner1 /> } />
<Route exact path='/customerrelationmanager' element={<Customerrelationmanager1 /> } />
<Route exact path='/billingsoftware' element={<Billingsoftware1 /> } />
<Route exact path='/pointofsale' element={<Pointofsale1 /> } />
<Route exact path='/inventoryandwarehouse' element={<Inventoryandwarehouse1 /> } />
<Route exact path='/membersmanagement' element={<Membersmanagement1 /> } />
<Route exact path='/saas' element={<Saas1 /> } />
<Route exact path='/paas' element={<Paas1 /> } />
//MARKETING
<Route exact path='/socialmediamanagement' element={<Socialmediamanagement1 /> } />
<Route exact path='/digitalmarketing' element={<Digitalmarketing1 /> } />
<Route exact path='/copywriting' element={<Copywriting1 /> } />
<Route exact path='/contentwriting' element={<Contentwriting1 /> } />
<Route exact path='/graphicsdesigning' element={<Graphicsdesigning1 /> } />
<Route exact path='/seo' element={<Seo1 /> } />
<Route exact path='/ecommercestoresetup' element={<Ecommercestoresetup1 /> } />
<Route exact path='/marketingcampaigns' element={<Marketingcampaigns1 /> } />
//SETUP
<Route exact path='/privatelimitedregistration' element={<Privatelimitedregistration1 /> } />
<Route exact path='/llpregistration' element={<LLPRegistration1 /> } />
<Route exact path='/soleproprietorshipregistration' element={<Soleproprietorshipregistration1 /> } />
<Route exact path='/startupindiaregistration' element={<Startupindiaregistration1 /> } />
<Route exact path='/partnershipregistration' element={<Partnershipregistration1 /> } />
<Route exact path='/publiclimitedregistration' element={<Publiclimitedregistration1 /> } />
//COMPLIANCES
<Route exact path='/cmadata' element={<CMAData1 /> } />
//FUNDRAISING
<Route exact path='/projectreportpreparation' element={<Projectreportpreparation1 /> } />
<Route exact path='/valuation' element={<Valuation1 /> } />
//CONSULTANCY
<Route exact path='/pitchdeckpreparation' element={<Pitchdeckpreparation1 /> } />
<Route exact path='/businessplancreation' element={<Businessplancreation1 /> } />
<Route exact path='/startupindiaseedfundscheme' element={<Startupindiaseedfundscheme1 /> } />
<Route exact path='/startupconsultancy' element={<Startupconsultancy1 /> } />

<Route exact path='/testfornow' element={<Testfornow /> } />

{/* <Route exact path='/new-home' element={<Startuphome />} /> */}
  {/*  <Route exact path='/home1' element={<Home1 />} />
   <Route exact path='/home5' element={<Home2 />} />
   <Route exact path='/home' element={<HomeH />} />
   <Route exact path='/services1-home' element={<HomeH />} />
   <Route exact path='/' element={<HomeH />} />
   <Route exact path='/contact' element={<Contact />} />
   <Route exact path='/contact1' element={<Contacta />} />
   <Route exact path='/Homeab' element={<Homeab />} />
   <Route exact path='/ip' element={<Intpg />} /> 
   <Route exact path='/book-keeping' element={<Bthome /> } />
   <Route exact path='/services-home' element={<Serviceshome /> } />
   <Route exact path='/services-home1' element={<Serviceshome1 /> } />
   <Route exact path='/about-us' element={<Aboutus /> } /> */}

   <Route exact path='/home-test' element={<Hometest /> } />

   /* COMMON REDIRECTS */
   <Route exact path='/login' element={<Homelogin />} />
   <Route exact path='/redirect-dashboard' element={<Redirectdashboard />} />

    /* DIFFERENT PORTAL HOME PAGES REDIRECT */
    <Route exact path='contest-home' element={<Contesthome />} /> 
    {/*<Route exact path='startuptools-home' element={<Startuptools />} /> */}
    <Route exact path='startuphire-home' element={<Startuphire />} /> 

    /*    INTERNAL PAGES FOLLOW THROUGH    */
  {/*} <Route exact path='/socialmediamanagement' element={<Socialmediamanagement />} /> 
   <Route exact path='/digitalmarketing' element={<Digitalmarketing />} /> 
   <Route exact path='/dataconsultancy' element={<Dataconsultancy />} /> 
   <Route exact path='/designbrandingconsultancy' element={<Designbrandingconsultancy />} /> 
   <Route exact path='/ecommerceconsultancy' element={<Ecommerceconsultancy />} /> 
   <Route exact path='/gstfiling' element={<Gstfiling />} /> 
   <Route exact path='/gstregistration' element={<Gstregistration />} /> 
   <Route exact path='/hrconsultancy' element={<HRconsultancy />} /> 
   <Route exact path='/ittechconsultancy' element={<ITtechconsultancy />} />   
   <Route exact path='/itrfiling' element={<ITRfiling />} />   
   <Route exact path='/llpregistration' element={<LLPregistration />} />   
   <Route exact path='/marketingconsultancy' element={<Marketingconsultancy />} />  
   <Route exact path='/managementconsultancy' element={<Managementconsultancy />} />  
   <Route exact path='/msmeregistration' element={<Msmeregistration />} />  
   <Route exact path='/opcregistration' element={<OPCregistration />} />  
   <Route exact path='/pitchdeckcreation' element={<Pitchdeckcreation />} />  
   <Route exact path='/partnershipregistration' element={<Partnershipregistration />} />  
   <Route exact path='/rentagreementdrafting' element={<Rentagreementdrafting />} />  
   <Route exact path='/resumebuilding' element={<Resumebuilding />} />  
   <Route exact path='/section8registration' element={<Section8registration />} />  
   <Route exact path='/salesconsultancy' element={<Salesconsultancy />} />  
   <Route exact path='/strategyconsultancy' element={<Strategyconsultancy />} />  
   <Route exact path='/startupindiaregistration' element={<Startupindiaregistration />} />
   <Route exact path='/soleproprietorshipregistration' element={<Soleproprietorshipregistration />} />
   <Route exact path='/accountingsoftware' element={<Accountingsoftware />} />
   <Route exact path='/billingsoftware' element={<Billingsoftware />} />
   <Route exact path='/copywriting' element={<Copywriting />} />
   <Route exact path='/crmsoftware' element={<CRMsoftware />} />
   <Route exact path='/contentwriting' element={<Contentwriting />} />
   <Route exact path='/emailingmessaging' element={<Emailingmessaging />} />
   <Route exact path='/e-commercestoresetup' element={<Ecomstoresetup />} />
   <Route exact path='/erpsoftware' element={<ERPsoftware />} />
   <Route exact path='/graphicsdesigning' element={<Graphicsdesigning />} />
   <Route exact path='/inventorymanagementsoftware' element={<Inventorymanagementsoftware />} />
   <Route exact path='/possoftware' element={<POSsoftware />} />
   <Route exact path='/productpromotion' element={<Productpromotion />} />
   <Route exact path='/seo' element={<SEO />} />
   <Route exact path='/customizedsolutions' element={<Customizedsolutions />} />
   <Route exact path='/ecommercewebsite' element={<Ecommercewebsite />} />
   <Route exact path='/institutionwebsite' element={<Institutionwebsite />} />
   <Route exact path='/membersmanagement' element={<Membersmanagement />} />
   <Route exact path='/paas' element={<Paas />} />
   <Route exact path='/saas' element={<Saas />} />
   <Route exact path='/privatelimitedcompany' element={<Pvtltdcompany />} />
   <Route exact path='/staticdisplaywebsite' element={<Staticdisplaywebsite />} />
   <Route exact path='/webapp' element={<Webapp />} />
*/}





   /*    SERVICES PORTAL    */
   <Route exact path='/register' element={<Register />} />
   <Route exact path='/login-login' element={<Login />} />
   <Route exact path='/team-login' element={<Teamlogin />} />
   <Route exact path='/client-dashboard' element={<Clientdashboard />} />
   {/*<Route exact path='/project-dashboard' element={<Projectdashboard />} />*/}
   {/*<Route exact path='/project-create' element={<Projectcreate />} />*/}
   {/*<Route exact path='/team-project-create' element={<Team_projectcreate />} />*/}

   /* SERVICES PORTAL TEAM */
   <Route exact path='/team-quotations' element={<Quotation_page />} />
   <Route exact path='/create-quotation' element={<Create_quotation />} />
   <Route exact path='/view-quotation' element={<Quotation />} />
   <Route exact path='/team-orders' element={<Order_page />} />
   <Route exact path='/create-order' element={<Create_order/>} />
   <Route exact path='/view-order' element={<Orderinvoice />} />
   <Route exact path='/team-payments' element={<Payments_page />} />
   <Route exact path='/team-projects' element={<Projects_page />} />
   <Route exact path='/project-dashboard' element={<Projectdashboard />} />
   <Route exact path='/team-feedbacks' element={<Feedbacks_page />} />

   /* SERVICES PORTAL CLIENT */
   <Route exact path='/client-quotations' element={<Client_quotation_page />} />
   <Route exact path='/client-orders' element={<Client_order_page />} />
   <Route exact path='/client-payments' element={<Client_payments_page />} />
   <Route exact path='/client-projects' element={<Client_projects_page />} />
   <Route exact path='/client-project-dashboard' element={<Clientprojectdashboard />} />
   <Route exact path='/client-feedbacks' element={<Clientfeedbacks_page />} />

   /*    ADMINISTRATION    */
   <Route exact path='/adminstartupconnect-register' element={<Adminregistration />} />
   <Route exact path='/adminstartupconnect-login' element={<Adminlogin />} />
  {/*} <Route exact path='/dashboard' element={<Dashboard />} /> */}
   <Route exact path='/admin-dashboard' element={<Admindashboard />} />
   <Route exact path='/adminpanel-dashboard' element={<Adminpaneldashboard />} />
   <Route exact path='/teampanel-dashboard' element={<Teampaneldashboard />} />
   <Route exact path='/registrations-list' element={<Registrations_list />} />
   <Route exact path='/login-history' element={<Login_history />} />
   <Route exact path='/customers-list' element={<Customers_list />} />
   <Route exact path='/clients-list' element={<Clients_list />} />
   <Route exact path='/projects-list' element={<Projects_list />} />
   <Route exact path='/admin-quotations' element={<Admin_quotation_page />} />
   <Route exact path='/view-admin-quotation' element={<Adminquotation />} />
   <Route exact path='/admin-orders' element={<Admin_order_page />} />
   <Route exact path='/view-admin-order' element={<Adminorderinvoice />} />
   <Route exact path='/admin-payments' element={<Admin_payments_page />} />
   <Route exact path='/admin-create-project' element={<Admincreateproject />} />
   <Route exact path='/admin-edit-project' element={<Admineditproject />} />
   <Route exact path='/admin-project-dashboard' element={<Adminprojectdashboard />} />
   <Route exact path='/admin-create-task' element={<Adminaddnewtask/>} />
   <Route exact path='/admin-feedbacks' element={<Admin_feedbacks_page />} />


   /*  GENERAL */
   <Route exact path='/customers-dashboard' element={<Customersdashboard />} />
   <Route exact path='/dashboard' element={<Insidedashboard />} />



   /* HIRING PORTAL */
   <Route exact path='/hiring-login' element={<HiringLogin />} />
   <Route exact path='/selection' element={<Selection />} />
   <Route exact path='/candidatedetails' element={<Candidateform />} />

   <Route exact path='/candidate-dashboard' element={<Candidatedashboard />} />
   <Route exact path='/candidate-saved' element={<Candidatesaveddashboard />} />
   <Route exact path='/candidate-applied' element={<Candidateapplieddashboard />} />
   <Route exact path='/candidate-apply' element={<Candidateapply/>} />
   <Route exact path='/candidate-create-profile' element={<Candidatecreateprofile/>} />

   <Route exact path='/employer-dashboard' element={<Employerdashboard />} />
   <Route exact path='/employer-jobs' element={<Employerjobsdashboard />} />
   <Route exact path='/employer-create-job' element={<Employercreatejob />} />
   <Route exact path='/employer-view-applications' element={<Employerviewapplications />} />
   <Route exact path='/employer-create-application-questions' element={<Employercreateapplicationquestions />} />
   <Route exact path='/employer-invitations' element={<Employerinvitations />} />
   <Route exact path='/employer-assignments' element={<Employerassignments />} />
   <Route exact path='/employer-create-assignment' element={<Employercreateassignment />} />
   <Route exact path='/employer-view-resume' element={<Employerviewresume/>} />
   <Route exact path='/employer-view-application' element={<Employerviewapplication/>} />
   <Route exact path='/employer-view-candidate-assignment' element={<Employerviewcandidateassignment/>} />
   <Route exact path='/employer-view-assignment' element={<Employerviewassignment/>} />
   <Route exact path='/employer-create-company' element={<Employercreatecompany />} />
   <Route exact path='/employer-companies' element={<Employercompanies />} />
   <Route exact path='/candidate-companies' element={<Candidatecompanies />} />


   /* BUSINESS TOOLS */

   <Route exact path='/homebt' element={<Homebt />} />
   <Route exact path='/businesstools-contactsales' element={<Bt_contactsales />} />
   <Route exact path='/businesstools-feedback' element={<Bt_feedback />} />
   <Route exact path='/businesstools-support' element={<Bt_support />} />

   <Route exact path='/businesstools-home' element={<Businesstoolshome />} />
   <Route exact path='/businesstools-login' element={<Businesstoolslogin />} />
   <Route exact path='/businesstools-team-login' element={<Businesstoolsteamlogin />} />
   <Route exact path='/businesstools-admin-register' element={<Businesstools_adminregister />} />
   <Route exact path='/businesstools-user-register' element={<Businesstools_userregister />} />
   <Route exact path='/businesstools-user-team' element={<Userteam />} />
   <Route exact path='/businesstools-otpverify' element={<Otp_verify />} />
   <Route exact path='/businesstools-forgetpass' element={<Forget_pass />} />
   <Route exact path='/businesstools-fgotpverify' element={<Fg_otp_verify />} />
   <Route exact path='/businesstools-passreset' element={<Password_reset />} />
   <Route exact path='/businesstools-businesses' element={<Businesshome />} />
   <Route exact path='/businesstools-pos' element={<Poshome />} />
   <Route exact path='/businesstools-entry' element={<Businesstoolsentry />} />
   <Route exact path='/businesstools-startworking' element={<Startworking />} />
   <Route exact path='/businesstools-user-customers' element={<Usercustomers />} />
   <Route exact path='/businesstools-user-suppliers' element={<Usersuppliers />} />
   <Route exact path='/businesstools-user-cash' element={<Usercash />} />
   <Route exact path='/businesstools-user-bank' element={<Userbank />} />
   <Route exact path='/businesstools-user-contra' element={<Usercontra />} />
   <Route exact path='/businesstools-user-catalog' element={<Usercatalog />} />
   <Route exact path='/businesstools-user-inventory' element={<Userinventory />} />
   <Route exact path='/businesstools-user-sales' element={<UserSales />} />
   <Route exact path='/businesstools-user-sales-invoice' element={<Sales_invoice />} />
   <Route exact path='/businesstools-user-sales-addproducts' element={<UserSales_addproducts />} />
   <Route exact path='/businesstools-user-sales-addproducts-edit' element={<UserSales_addproducts_edit />} />
   <Route exact path='/businesstools-user-purchases' element={<UserPurchases />} />
   <Route exact path='/businesstools-user-purchases-invoice' element={<Purchases_invoice />} />
   <Route exact path='/businesstools-user-purchases-addproducts' element={<UserPurchases_addproducts />} />
   <Route exact path='/businesstools-user-purchases-addproducts-edit' element={<UserPurchases_addproducts_edit />} />
   <Route exact path='/businesstools-user-salesreturn' element={<UserSalesreturn />} />
   <Route exact path='/businesstools-user-salesreturn-invoice' element={<Salesreturn_invoice />} />
   <Route exact path='/businesstools-user-salesreturn-addproducts' element={<UserSalesreturn_addproducts />} />
   <Route exact path='/businesstools-user-salesreturn-addproducts-edit' element={<UserSalesreturn_addproducts_edit />} />
   <Route exact path='/businesstools-user-purchasereturn' element={<UserPurchasesreturn />} />
   <Route exact path='/businesstools-user-purchasereturns-invoice' element={<Purchasereturns_invoice />} />
   <Route exact path='/businesstools-user-purchasereturns-addproducts' element={<UserPurchasereturn_addproducts />} />
   <Route exact path='/businesstools-user-purchasereturns-addproducts-edit' element={<UserPurchasereturns_addproducts_edit />} />
   <Route exact path='/businesstools-user-incomes' element={<Userincomes />} />
   <Route exact path='/businesstools-user-expenses' element={<Userexpenses />} />
   <Route exact path='/businesstools-user-outstanding-incomes' element={<Useroutstandingincome />} />
   <Route exact path='/businesstools-user-outstanding-expenses' element={<Useroutstandingexpense />} />
   <Route exact path='/businesstools-user-debtor' element={<Userdebtor />} />
   <Route exact path='/businesstools-user-creditor' element={<Usercreditor />} />
   <Route exact path='/businesstools-user-sgst' element={<UserSgst />} />
   <Route exact path='/businesstools-user-cgst' element={<UserCgst />} />
   <Route exact path='/businesstools-user-igst' element={<UserIgst />} />
   <Route exact path='/businesstools-user-tax' element={<UserTaxes />} />
   <Route exact path='/businesstools-user-debtors' element={<Userdebtors />} />
   <Route exact path='/businesstools-user-quotations' element={<UserQuotations />} />
   <Route exact path='/businesstools-user-quotation-invoice' element={<Quotation_invoice />} />
   <Route exact path='/businesstools-user-quotation-addproducts' element={<UserQuotation_addproducts />} />
   <Route exact path='/businesstools-user-quotation-addproducts-edit' element={<UserQuotation_addproducts_edit />} />
   <Route exact path='/businesstools-user-finalaccounts' element={<Userfinalaccounts />} />
   <Route exact path='/businesstools-user-manufacturing' element={<Usermanufacturing />} />
   <Route exact path='/businesstools-user-manufacture-product' element={<Create_manufacturingproduct />} />
   <Route exact path='/businesstools-user-edit-manufacture-product' element={<Edit_manufacturingproduct />} />
   <Route exact path='/businesstools-user-assets' element={<Userassets />} />
   <Route exact path='/businesstools-user-assets-history' element={<Userassets_history />} />
   <Route exact path='/businesstools-user-employees' element={<Useremployees />} />
   <Route exact path='/businesstools-user-salary' element={<Usersalary />} />


   /* PAID PLANS FOR BOOK-KEEPING */
   <Route exact path='/businesstools-user-pricing' element={<Bt_paidplans />} />
   <Route exact path='/businesstools-user-paidplanpage' element={<Bt_paidplanpage />} />

   /* BOOK-KEEPING INSIDE ADMIN */
   /* ADMIN */
   <Route exact path='/businesstools-insideadminadminadmin-login' element={<Insideadmin_login />} />
   <Route exact path='/businesstools-insideadmin-salesteam' element={<Insideadmin_salesteam />} />
   <Route exact path='/businesstools-insideadmin-salescoupons' element={<Insideadmin_salescoupon />} />
   <Route exact path='/businesstools-insideadmin-salescoupons-history' element={<Insideadmin_salescouponhistory />} />
   <Route exact path='/businesstools-insideadmin-registrations' element={<Insideadmin_registrations />} />
   <Route exact path='/businesstools-insideadmin-loginhistory' element={<Insideadmin_logins />} />
   <Route exact path='/businesstools-insideadmin-orders' element={<Insideadmin_cforders />} />
   <Route exact path='/businesstools-insideadmin-payments' element={<Insideadmin_cfpayments />} />

   /* TEAM */
   <Route exact path='/businesstools-insideteam-login' element={<Insideteam_login />} />
   <Route exact path='/businesstools-insideteam-salescoupons' element={<Insideteam_salescoupon />} />
   <Route exact path='/businesstools-insideteam-salescoupon-history' element={<Insideteam_salescouponhistory />} />
 {/*}  <Route exact path='/businesstools-insideteam-orders' element={<Insideteam_cforders />} /> */}
   <Route exact path='/businesstools-insideteam-payments' element={<Insideteam_cfpayments />} />
   <Route exact path='/businesstools-insideteam-profile' element={<Insideteam_profile />} />

//NETWORKING 
//Login
<Route exact path='/networking-login' element={<Networkinglogin />} />
//Mentor Section
<Route exact path='/networking-mentor-register' element={<Mentorregister />} />
<Route exact path='/networking-mentor-dashboard' element={<Mentordashboard />} />
<Route exact path='/networking-mentor-edit' element={<Mentoredit />} />

//Startup Section
<Route exact path='/networking-startup-register' element={<Startupregister />} />
<Route exact path='/networking-startup-edit' element={<Startupedit />} />
<Route exact path='/networking-startup-answers' element={<Startupregister1 />} />
<Route exact path='/networking-startup-answers-edit' element={<Startupedit1 />} />
<Route exact path='/networking-startup-team' element={<StartupRegister2 />} />
<Route exact path='/networking-startup-team-edit' element={<Startupedit2 />} />
<Route exact path='/networking-startup-dashboard' element={<Startupdashboard />} />
<Route exact path='/networking-startup-details' element={<Startupdetails />} />


/* SERVICES PORTAL 2.0 */
//Login
<Route exact path='/services-login' element={<Serviceslogin />} />
//CLIENTS
<Route exact path='/client-dashboard-redirect' element={<Client_dashredirect />} />
<Route exact path='/client-dashboard-orders' element={<Client_orders />} />
<Route exact path='/client-dashboard-quotations' element={<Client_quotations />} />
<Route exact path='/client-dashboard-projects' element={<Client_projects />} />
<Route exact path='/client-dashboard-feedbacks' element={<Client_feedbacks />} />
<Route exact path='/client-dashboard-payments' element={<Client_payments />} />
//TEAM
<Route exact path='/team-dashboard-redirect' element={<Team_dashredirect />} />
<Route exact path='/team-dashboard-orders' element={<Team_orders />} />
<Route exact path='/team-dashboard-quotations' element={<Team_quotations />} /> 
<Route exact path='/team-dashboard-create-quotation' element={<Team_create_quoatation />} /> 
<Route exact path='/team-dashboard-projects' element={<Team_projects />} /> 
<Route exact path='/team-dashboard-feedbacks' element={<Team_feedbacks />} />
<Route exact path='/team-dashboard-payments' element={<Team_payments />} /> 
<Route exact path='/team-projectdashboard' element={<Team_projectdashboard />} /> 
<Route exact path='/team-dashboard-create-order' element={<Team_create_order />} /> 
//ADMIN


//Extra Test

<Route exact path='/businesstools-chart-test' element={<Charthome />} />

<Route exact path='/pricingdummy' element={<Pricingdummy />} />
<Route exact path='/bt-pricing-page' element={<Bt_pricing />} />
<Route exact path='/bt-pricing' element={<Pricing_page />} />
<Route exact path='/bt-freeplans' element={<Free_pricing />} />
<Route exact path='/bt-freetrial' element={<Freetrialpage />} />


   /* SUPPORTING DOCUMENTS */
   <Route exact path='/disclaimer' element={<Disclaimer />} />
   <Route exact path='/termsandconditions1' element={<Termsandconditions />} />
   <Route exact path='/refundandcancellation-policy' element={<Refundpolicy />} />
   <Route exact path='/privacy-policy1' element={<Privacypolicy />} />

   <Route exact path='/termsandconditions' element={<New_termsandconditions />} />
   <Route exact path='/privacy-policy' element={<New_privacypolicy />} />
   <Route exact path='/refundandcancellation' element={<New_refundandcancellation />} />

   //EXTRA Test
   <Route exact path='/pagedummy' element={<Pagedummy />} />


   //ADMIN PANEL
   
   /* SUPER ADMIN */
   <Route exact path='/superadmin-login' element={<Superadminlogin />} />
   <Route exact path='/superadmin-dashboard' element={<Superadmindashboard />} />

   /* MAIN ADMIN */
   <Route exact path='/mainadmin-login' element={<Mainadminlogin />} />
   <Route exact path='/mainadmin-redirectdashboard' element={<Mainadminredirectdashboard />} />
   <Route exact path='/mainadmin-servicesportal-dashboard' element={<Servicesportalmaindashboard />} />
   <Route exact path='/mainadmin-servicesportal-teammanagement' element={<Adminteammanagement />} />
   <Route exact path='/mainadmin-servicesportal-customers' element={<Adminservicescustomers />} />
   <Route exact path='/mainadmin-servicesportal-quotations' element={<Adminservicesquotations />} />
   <Route exact path='/mainadmin-feedbacks' element={<Adminfeedbacks />} />
   <Route exact path='/mainadmin-registrations' element={<Adminregistrations />} />
   <Route exact path='/mainadmin-loginhistory' element={<Adminloginhistory />} />
   <Route exact path='/mainadmin-salesenquiry' element={<Adminsalesenquiry />} />
   <Route exact path='/mainadmin-btsupport' element={<Adminbtsupport />} />
   <Route exact path='/mainadmin-bttransactions' element={<Adminbttransactions />} />
   <Route exact path='/mainadmin-ntwstartups' element={<Adminntwstartups />} />



   //TEAM

   /* TEAM TEST CARD */
  {/*} <Route exact path='/team-test-card' element={<Teamcard />} />
   <Route exact path='/bdtapr3021' element={<Bdtapr3021 />} />
   <Route exact path='/bdtapr3022' element={<Bdtapr3022 />} />
   <Route exact path='/bdtapr3023' element={<Bdtapr3023 />} />
   <Route exact path='/bdtapr3024' element={<Bdtapr3024 />} />
   <Route exact path='/bdtapr3025' element={<Bdtapr3025 />} />
   <Route exact path='/bdtapr3026' element={<Bdtapr3026 />} />*/}



   //TEST GOOGLE DRIVE UPLOAD


   </Routes>
   </Router>
</>
    );


}

export default Routestosite;