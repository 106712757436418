import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';
import Navbar from './navbar.js';
import Sidebar from './sidebar.js';
import Topanalytics from './topanalytics.js';
import Topchart from './topchart.js';





function Projects_list(){



  
  
  // Setting Values fom Session Storage
const logstatus=sessionStorage.getItem("loginstatus00");//loginstatus
const loguser=sessionStorage.getItem("username00");//username
const logtype=sessionStorage.getItem("logintype00");//logintype
const logname=sessionStorage.getItem("loginname00");//loginname
const logadminid=sessionStorage.getItem("loginadminid00");//loginadminid



//Variables
const[data,setData] = useState([]);//SetData Variable

//Error Variable
const[errorvar,seterrorvar] = useState();


   //Sessions Not Set
  {/*} if(((logstatus=="false")||(logstatus==null))&&(loguser==null)&&(logadminid==null)){

     sessionStorage.removeItem("username00");
     sessionStorage.removeItem("loginstatus00");
     sessionStorage.removeItem("logintype00");
     sessionStorage.removeItem("loginname00");
     sessionStorage.removeItem("loginadminid00");
    
     alert("Session Expired! Login Again.");
     window.location.replace("/");
   
   }
   //Sessions Set
   else{
   
   */}
     

 //Use Effect, Data Load
 useEffect(()=>{

   getData();

},[])



//Fetching Teams
function getData(){
 

 //Checking if user loggedin
if(((logstatus=="true")||(logstatus!=null))&&(loguser!=null)&&(logadminid!=null)){



axios.post("get-project-list", {
  User: loguser,
  Logtype:logtype,
  Logstatus:logstatus,
  Log_id: logadminid,
  User_id:logadminid,
  Account_type:logtype,
})
.then((response) => {

 //Success Data Fetched
 if(response.data.successvar==1){
  //Setting Data
  setData(response.data.projects);
 /* setdatacount(response.data.datacount);
  setloadcount(response.data.loadcount);
  setloadcount(1); */
 }
 //Cannot Fetch Data
 else{
  seterrorvar("Cannot Fetch Data !");
 }


});

}
//User not Logedin
else{

sessionStorage.removeItem("username00");
sessionStorage.removeItem("loginstatus00");
sessionStorage.removeItem("logintype00");
sessionStorage.removeItem("loginname00");
sessionStorage.removeItem("loginadminid00");

alert("Session Expired! Login Again.");
window.location.replace("/");

 }

}



/*  PROJECT LIST WITH MULTIPLE COLUMN FILTERS */


//Column Names
const[column1,setcolumn1]=useState("Client_id");//column 1
const[column2,setcolumn2]=useState("Member_id");//column 2


//Select List for Columns

//columnselect1
const[columnselect1,setcolumnselect1]=useState();const updatecolumnselect1 = (event) => { setcolumnselect1(event.target.value);};

     


/*import Amanbg from './amanbg';*/

     
    return(
<>
{/*<!DOCTYPE html> */}
<html lang="en">
  <head>
    {/* Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>theStartupConnect | Portal</title>
    {/* plugins:css */}
    <link rel="stylesheet" href="./assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="./assets/vendors/css/vendor.bundle.base.css" />
    {/* endinject */}
    {/* Plugin css for this page */}
    {/* End plugin css for this page */}
    {/* inject:css */}
    {/* endinject */}
    {/* Layout styles */}
    <link rel="stylesheet" href="./assets/css/style.css" />
    {/* End layout styles */}
   {/*} <link rel="shortcut icon" href="./assets/images/favicon.ico" /> */}
  </head>
  <body>

<Navbar/>

{/* MAIN PAGE COMPONENT START */}
  <div class="container-fluid page-body-wrapper">

 <Sidebar/>


{/* MAIN PANEL START */}
<div class="main-panel" style={{marginTop:"5vh"}}>
<div class="content-wrapper">
<h2 class="text-center mb-2">Projects</h2>
       
{/* Top Analytics Start */}

<div class="row">
              <div class="col-md-4 stretch-card grid-margin">
                <div class="card bg-gradient-danger card-img-holder text-white">
                  <div class="card-body">
                    <img src="assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
                    <h4 class="font-weight-normal mb-3">Total Projects <i class="mdi mdi-chart-line mdi-24px float-right"></i>
                    </h4>
                    <h2 >$ 15,0000</h2>
                    <h6 class="card-text mt-0">This week (+60%)</h6>
                   
                  </div>
                </div>
              </div>
              <div class="col-md-4 stretch-card grid-margin">
                <div class="card bg-gradient-info card-img-holder text-white">
                  <div class="card-body">
                    <img src="assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
                    <h4 class="font-weight-normal mb-3">Total Contacts <i class="mdi mdi-bookmark-outline mdi-24px float-right"></i>
                    </h4>
                    <h2 >$ 15,0000</h2>
                    <h6 class="card-text mt-0">This week (+60%)</h6>
                   
                  </div>
                </div>
              </div>
              <div class="col-md-4 stretch-card grid-margin">
                <div class="card bg-gradient-success card-img-holder text-white">
                  <div class="card-body">
                    <img src="assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
                    <h4 class="font-weight-normal mb-3">Total Logins <i class="mdi mdi-diamond mdi-24px float-right"></i>
                    </h4>
                    <h2 >$ 15,0000</h2>
                    <h6 class="card-text mt-0">This week (+60%)</h6>
                 
                  </div>
                </div>
              </div>
            </div>
            
                                 {/* Team Table Start */}
         <div class="row">
              <div class="col-12 grid-margin">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Projects</h4>
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th>Project Id </th>
                            <th>Client</th>
                            <th> Members</th>
                            <th> Team_id </th>
                            <th> Title </th> 
                          <th>Start Date</th>
                            <th>End Date </th>
                            <th>Description</th>
                            <th> Project Status </th>
                            <th>Progress</th>
                            <th>View</th>
                            <th>Status </th>
                            <th>Updation Details</th>
                        {/*}    <th> Edit </th>
                            <th> Delete </th> */}
                          </tr>
                        </thead>
                        <tbody>
                        {
                       data.map((curElm,ind)=>{

return(
  <>
                          <tr key={curElm.Project_id}>
                          
                          <td>{curElm.Project_id}</td>
                          <td>(Cid - <span style={{color:"#0a739c",fontWeight:"bold"}}>{curElm.Client_id}</span>){curElm.Client_name}</td>
                          <td>(Mid - <span style={{color:"#0a739c",fontWeight:"bold"}}>{curElm.Member_id}</span>){curElm.Member_name}</td>
                          <td>{curElm.Team_id}</td>
                          <td>{curElm.Title}</td>
                          <td>{curElm.Start_date}</td>
                          <td>{curElm.End_date}</td>
                          <td>{curElm.Description}</td>
                          
                          <td><span class={curElm.Project_status === "Pending"? "badge badge-danger"
                                             : curElm.Project_status === "Completed"? "badge badge-success"
                                             : "badge badge-warning"}>{curElm.Project_status}</span>
                           </td>
                        {/*}   <td>{curElm.Progress} %</td> */}
                           <td>
                        {/*}   <div class="progress"><div class="progress-bar-bg-warning" role="progressbar" style={{width: `${curElm.Progress}%`}} aria-valuenow={curElm.Progress} aria-valuemin="0" aria-valuemax="100"></div> </div></td>

*/}
                          <div class="progress">
                          
                {curElm.Progress < 25 &&
                  <div class="progress-bar-bg-danger" role="progressbar" style={{width: `${curElm.Progress}%`}} aria-valuenow={curElm.Progress} aria-valuemin="0" aria-valuemax="100"></div>

                }
                {curElm.Progress >= 25 && curElm.Progress <= 50 &&
                  <div class="progress-bar bg-warning" role="progressbar" style={{width: `${curElm.Progress}%`}} aria-valuenow={curElm.Progress} aria-valuemin="0" aria-valuemax="100"></div>

                }
                {curElm.Progress >= 51 && curElm.Progress <= 80 &&
                  <div class="progress-bar bg-info" role="progressbar" style={{width: `${curElm.Progress}%`}} aria-valuenow={curElm.Progress} aria-valuemin="0" aria-valuemax="100"></div>
                 
                }
                {curElm.Progress > 80 &&
                  <div class="progress-bar bg-success" role="progressbar" style={{width: `${curElm.Progress}%`}} aria-valuenow={curElm.Progress} aria-valuemin="0" aria-valuemax="100"></div>

                }
                           </div></td>
                      
                         {/*}   <td> <button style={{backgroundColor:"transparent",border:"none"}} onClick={()=>{
                     
                             setupteam_id(curElm.Team_id);
                             setname(curElm.Name);
                             setcategory(curElm.Category);
                             setremarks(curElm.Remarks);
                             settype(curElm.Type);
                             setstatus(curElm.Status);


                             document.getElementById("editteam-box").style.display="block";

                            }}><i class="mdi mdi-pencil-box" style={{color:"#dbc821",fontSize:"2rem"}}></i></button></td>
                            <td> <button style={{backgroundColor:"transparent",border:"none"}} onClick={()=>{
                              //Redirecting to Delete
                              setupteam_id(curElm.Team_id);
                           
                           //  defaultLoad();
                             document.getElementById("deleteteam-box").style.display="block";

                            }}><i class="mdi mdi-minus-box" style={{color:"#e1435d",fontSize:"2rem"}}></i></button></td>*/}
                            <td><button type="button" class="btn btn-gradient-dark" onClick={()=>{
                              //Redirecting to Edit
                             
                             sessionStorage.setItem("editId00",curElm.Project_id);
                            window.location.replace("/admin-project-dashboard");

                            }} >View</button></td>
                           
                            <td style={{color:"#04a15c"}} hidden={curElm.Status === "inactive"}>{curElm.Status}</td>              
                            <td style={{color:"#c92a46"}} hidden={curElm.Status === "active"}>{curElm.Status}</td>    
                            <td> <button style={{backgroundColor:"transparent",border:"none"}} onClick={()=>{
                              //Redirecting to Edit
                             
                             sessionStorage.setItem("editId00",curElm.Project_id);
                            window.location.replace("/admin-edit-project");

                            }}><i class="mdi mdi-pencil-box" style={{color:"#dbc821",fontSize:"2rem"}}></i></button></td>
                            <td style={{fontSize:"0.6rem"}}><label > Last Updated-{curElm.Date_created} at {(curElm.Time_created)}</label></td>
                    
                          </tr>
                            
                          </>
      )
      }
					   
			)}
                   
             
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Team Table End */}



{/* Top Analytics End */}
         <Topchart/>
{/*<Pageheader/>
<Topanalytics/>*/}
    
</div>
</div>
{/* MAIN PANEL END */}

</div>
{/* MAIN PAGE COMPONENT END */}

    {/* container-scroller */}
    {/* plugins:js */}
    <script src="./assets/vendors/js/vendor.bundle.base.js"></script>
    {/* endinject */}
    {/* Plugin js for this page */}
    {/* End plugin js for this page */}
    {/* inject:js */}
    <script src="./assets/js/off-canvas.js"></script>
    <script src="./assets/js/hoverable-collapse.js"></script>
    <script src="./assets/js/misc.js"></script>

    {/*} plugins:js */}
    <script src="assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="assets/vendors/chart.js/Chart.min.js"></script>
    <script src="assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="assets/js/off-canvas.js"></script>
    <script src="assets/js/hoverable-collapse.js"></script>
    <script src="assets/js/misc.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="assets/js/dashboard.js"></script>
    <script src="assets/js/todolist.js"></script>
    {/*} End custom js for this page */}
    {/* endinject */}
  </body>
</html>
</>
    );
}

//}

export default Projects_list;