import React from 'react';
import {useState,useEffect,Component} from 'react';
//import axios from './axios';


function Requirements(){

return(
<>
 {/* DOCUMENTS REQUIRED SECTION START */}
 <div class="documents-section" id="service-requirements">
  <h2 style={{textAlign: "center"}}>REQUIREMENTS FOR AVAILING THE SERVICE</h2>
 

<div class="doc-section">
    <h4 class="doc-section-heading">#1 Decide on the Partners:</h4>
      <p class="doc-section-content"> LLP requires a minimum of two designated partners who will be responsible for the legal compliances of the LLP.</p>
  </div>
  <div class="doc-section">
    <h4 class="doc-section-heading">#2 Decide on the Partners:</h4>
      <p class="doc-section-content"> LLP requires a minimum of two designated partners who will be responsible for the legal compliances of the LLP.</p>
  </div>
  <div class="doc-section">
    <h4 class="doc-section-heading">#3 Decide on the Partners:</h4>
      <p class="doc-section-content"> LLP requires a minimum of two designated partners who will be responsible for the legal compliances of the LLP.</p>
  </div>
  <div class="doc-section">
    <h4 class="doc-section-heading">#4 Decide on the Partners:</h4>
      <p class="doc-section-content"> LLP requires a minimum of two designated partners who will be responsible for the legal compliances of the LLP.</p>
  </div>
  <div class="doc-section">
    <h4 class="doc-section-heading">#5 Decide on the Partners:</h4>
      <p class="doc-section-content"> LLP requires a minimum of two designated partners who will be responsible for the legal compliances of the LLP.</p>
  </div>
  <div class="doc-section">
    <h4 class="doc-section-heading">#6 Decide on the Partners:</h4>
      <p class="doc-section-content"> LLP requires a minimum of two designated partners who will be responsible for the legal compliances of the LLP.</p>
  </div>
  <div class="doc-section">
    <h4 class="doc-section-heading">#7 Decide on the Partners:</h4>
      <p class="doc-section-content"> LLP requires a minimum of two designated partners who will be responsible for the legal compliances of the LLP.</p>
  </div> 
  </div>
{/* DOCUMENTS REQUIRED SECTION END */}  
</>
);
}

export default Requirements;