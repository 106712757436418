import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';
import moment from 'moment/moment';


import '../Resources/dashboard.css';


function Create_business(){


 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");




//saving default values with usestate
const state = useState();

//business_name
const[business_name,setbusiness_name]=useState();const updatebusiness_name = (event) => { setbusiness_name(event.target.value);}; const[business_name_er,setbusiness_name_er]=useState();
//business_type
const[business_type,setbusiness_type]=useState();const updatebusiness_type = (event) => { setbusiness_type(event.target.value);}; const[business_type_er,setbusiness_type_er]=useState();
//business_email
const[business_email,setbusiness_email]=useState();const updatebusiness_email = (event) => { setbusiness_email(event.target.value);};  const[business_email_er,setbusiness_email_er]=useState();
//business_contact
const[business_contact,setbusiness_contact]=useState();const updatebusiness_contact = (event) => { setbusiness_contact(event.target.value);};  const[business_contact_er,setbusiness_contact_er]=useState();
//business_whatsapp
const[business_whatsapp,setbusiness_whatsapp]=useState();const updatebusiness_whatsapp = (event) => { setbusiness_whatsapp(event.target.value);};  const[business_whatsapp_er,setbusiness_whatsapp_er]=useState();
//business_address
const[business_address,setbusiness_address]=useState();const updatebusiness_address = (event) => { setbusiness_address(event.target.value);}; const[business_address_er,setbusiness_address_er]=useState();
//business_city
const[business_city,setbusiness_city]=useState();const updatebusiness_city = (event) => { setbusiness_city(event.target.value);}; const[business_city_er,setbusiness_city_er]=useState();
//business_state
const[business_state,setbusiness_state]=useState();const updatebusiness_state = (event) => { setbusiness_state(event.target.value);}; const[business_state_er,setbusiness_state_er]=useState();
//business_country
const[business_country,setbusiness_country]=useState();const updatebusiness_country = (event) => { setbusiness_country(event.target.value);}; const[business_country_er,setbusiness_country_er]=useState();
//business_pincode
const[business_pincode,setbusiness_pincode]=useState();const updatebusiness_pincode = (event) => { setbusiness_pincode(event.target.value);}; const[business_pincode_er,setbusiness_pincode_er]=useState();
//business_doe
const[business_doe,setbusiness_doe]=useState();const updatebusiness_doe = (event) => { setbusiness_doe(event.target.value);}; const[business_doe_er,setbusiness_doe_er]=useState();
//business_website
const[business_website,setbusiness_website]=useState();const updatebusiness_website = (event) => { setbusiness_website(event.target.value);}; const[business_website_er,setbusiness_website_er]=useState();
//business_gstin
const[business_gstin,setbusiness_gstin]=useState();const updatebusiness_gstin = (event) => { setbusiness_gstin(event.target.value);}; const[business_gstin_er,setbusiness_gstin_er]=useState();
//business_licenseno
const[business_licenseno,setbusiness_licenseno]=useState();const updatebusiness_licenseno = (event) => { setbusiness_licenseno(event.target.value);}; const[business_licenseno_er,setbusiness_licenseno_er]=useState();
//business_sector
const[business_sector,setbusiness_sector]=useState();const updatebusiness_sector = (event) => { setbusiness_sector(event.target.value);}; const[business_sector_er,setbusiness_sector_er]=useState();
//business_industry
const[business_industry,setbusiness_industry]=useState();const updatebusiness_industry = (event) => { setbusiness_industry(event.target.value);}; const[business_industry_er,setbusiness_industry_er]=useState();
//business_category
const[business_category,setbusiness_category]=useState();const updatebusiness_category = (event) => { setbusiness_category(event.target.value);}; const[business_category_er,setbusiness_category_er]=useState();
//business_about
const[business_about,setbusiness_about]=useState();const updatebusiness_about = (event) => { setbusiness_about(event.target.value);}; const[business_about_er,setbusiness_about_er]=useState();
//business_details
const[business_details,setbusiness_details]=useState();const updatebusiness_details = (event) => { setbusiness_details(event.target.value);}; const[business_details_er,setbusiness_details_er]=useState();



//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();






  
//******************* ADD NEW BUSINESS FUNCTION START ********************//
function addbusiness(){

    //Setting Error Variables
  setbusiness_name_er(" ");setbusiness_type_er(" ");setbusiness_email_er(" ");setbusiness_contact_er(" ");setbusiness_address_er(" ");setbusiness_city_er(" ");setbusiness_state_er(" ");setbusiness_country_er(" ");setbusiness_pincode_er(" ");
  setbusiness_doe_er(" ");setbusiness_website_er(" ");setbusiness_sector_er(" ");setbusiness_industry_er(" ");setbusiness_category_er(" ");setbusiness_gstin_er(" ");setbusiness_licenseno_er(" ");setbusiness_about_er(" ");setbusiness_details_er(" ");
  seterror_msg(" ");

 

    //Checking if user loggedin
    if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))){
   
     //Adjusting empty variables for input
     if(business_address==" "){ setbusiness_address("-");}
    // if(business_website==" "){ setbusiness_website("-"); }
     if(business_about==" "){ setbusiness_about("-"); }if(business_details==" "){ setbusiness_details("-"); } 
   
   axios.post("bt-register-business", {
     Log_id:logid,
     Token:token,
     Business_name : business_name,
     Email : business_email,
     Contact : business_contact,
     Whatsapp : business_whatsapp,
     Address : business_address,
     City : business_city,
     State : business_state,
     Country : business_country, 
     Pincode : business_pincode,
     Google_profile : "-",
     Logo : "-",
     Gstin : business_gstin,
     License_no : business_licenseno,
     Website : business_website,
     Type : business_type,
     Category : business_category,
     Industry : business_industry,
     Sector : business_sector,
     About : business_about,
     Details : business_details,
     Doe : moment(business_doe).format('YYYY-MM-DD'),
   })
   .then((response) => {

      //Business Name Already Exists
      if(response.data.error==103){     seterror_msg("Business Name Already Exists!"); return; }

     //Business Limit Reached
     if(response.data.error==111){     seterror_msg(response.data.error_msg); return; }

       //User Login
       if(response.data.error==201){     seterror_msg(response.data.error_msg); return; }


    //Invalid Session
    if((response.data.error)==100){ 
        //Remove Session Variables and redirect to login
       sessionStorage.removeItem("logStatus00");
       sessionStorage.removeItem("logId00");
       sessionStorage.removeItem("logToken00");

       window.location.replace("businesstools-login");
    }

     //Validation Errors
   if(response.data.validatorerror){
    setbusiness_name_er(response.data.validatorerror.Business_name);
    setbusiness_email_er(response.data.validatorerror.Email);
    setbusiness_contact_er(response.data.validatorerror.Contact);
    setbusiness_whatsapp_er(response.data.validatorerror.Whatsapp);
    setbusiness_address_er(response.data.validatorerror.Address);
    setbusiness_city_er(response.data.validatorerror.City);
    setbusiness_state_er(response.data.validatorerror.State);
    setbusiness_country_er(response.data.validatorerror.Country);
    setbusiness_pincode_er(response.data.validatorerror.Pincode);
    setbusiness_gstin_er(response.data.validatorerror.Gstin);
    setbusiness_website_er(response.data.validatorerror.Website);
    setbusiness_licenseno_er(response.data.validatorerror.License_no);
    setbusiness_type_er(response.data.validatorerror.Type);
    setbusiness_category_er(response.data.validatorerror.Category);
    setbusiness_industry_er(response.data.validatorerror.Industry);
    setbusiness_sector_er(response.data.validatorerror.Sector);
    setbusiness_about_er(response.data.validatorerror.About);
    setbusiness_details_er(response.data.validatorerror.Details);
    setbusiness_doe_er(response.data.validatorerror.Doe);
  
  }
  //Invalid Entries
  if(response.data.error==102){ 
    
    setbusiness_name_er(response.data.business_name_er);
    setbusiness_email_er(response.data.email_er);
    setbusiness_contact_er(response.data.contact_er);
    setbusiness_whatsapp_er(response.data.whatsapp_er);
    setbusiness_address_er(response.data.address_er);
    setbusiness_city_er(response.data.city_er);
    setbusiness_state_er(response.data.state_er);
    setbusiness_country_er(response.data.country_er);
    setbusiness_pincode_er(response.data.pincode_er);
    setbusiness_gstin_er(response.data.gstin_er);
    setbusiness_website_er(response.data.website_er);
    setbusiness_licenseno_er(response.data.license_no_er);
    setbusiness_type_er(response.data.type_er);
    setbusiness_category_er(response.data.category_er);
    setbusiness_industry_er(response.data.industry_er);
    setbusiness_sector_er(response.data.sector_er);
    setbusiness_about_er(response.data.about_er);
    setbusiness_details_er(response.data.details_er);
    setbusiness_doe_er(response.data.doe_er);
    //console.log(response.data.google_profile_er);
    //console.log(response.data.logo_er);
    //console.log(response.data);
    seterror_msg("Cannot Add Business!");  }


  
     //Cannot Create Business
     if(response.data.successvar==1){        document.getElementById("success-card").style.display="block";  }
     else{  seterror_msg("Cannot Add Business !"); }
    

});
}
   //User not Logedin
   else{
   
   //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");

     window.location.replace("businesstools-login");
   
    }
   
   }
    
   //******************* ADD NEW BUSINESS FUNCTION END ********************//


return(
    <>
                <div class="card">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("create-form").style.display="none"; }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                  <div class="card-body">
                    <h4 class="card-title">Add New Business</h4>
                    <div class="form-sample">
                      <p class="card-description">enter your business details here</p>
                      <p><code>{error_msg}</code></p>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Business Name<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="ABC Private Limited" id="business_name" value={business_name} onChange={updatebusiness_name} />
                            <code>{business_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type<code>*</code></label>
                            <div class="col-sm-9">
                              <select class="form-control" placeholder="Select Business Type" style={{padding:"1rem"}}  id="business_type" value={business_type} onChange={updatebusiness_type}>
                              <option value="">Select Business Type</option>
                              <option value="Public Limited Company">Public Limited Company</option>
                              <option value="Private Limited Company">Private Limited Company</option>
                              <option value="One Person Company (OPC)">One Person Company (OPC)</option>
                              <option value="Limited Liability Partnership (LLP)">Limited Liability Partnership</option>
                              <option value="Partnership">Partnership</option>
                              <option value="Sole Proprietorship">Sole Proprietorship</option>
                              <option value="Hindu Undivided Family Business (HUFB)">Hindu Undivided Family Business (HUFB)</option>
                              <option value="Others">Others</option>
                              </select>
                              <code>{business_type_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Email<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="email" class="form-control" placeholder="abccompany@company.com"  id="business_email" value={business_email} onChange={updatebusiness_email} />
                            {business_email_er}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Contact<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="9100000000000"  id="business_contact" value={business_contact} onChange={updatebusiness_contact} />
                            <code>{business_contact_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Category</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Enter Category"  id="business_category" value={business_category} onChange={updatebusiness_category} />
                            <code>{business_category_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">WhatsApp</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Enter WhatsApp Number"  id="business_whatsapp" value={business_whatsapp} onChange={updatebusiness_whatsapp} />
                            <code>{business_whatsapp_er}</code>
                            </div>
                          </div>
                        </div>
                       
                      </div>
                      <div class="form-group">
                        <label for="exampleTextarea1">Address</label>
                        <textarea  class="form-control" rows="2"  id="business_address" value={business_address} onChange={updatebusiness_address} ></textarea>
                       <code>{business_address_er}</code>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">City</label>
                            <div class="col-sm-9">
                              <input type="email" class="form-control" placeholder="Enter City"  id="business_city" value={business_city} onChange={updatebusiness_city} />
                            <code>{business_city_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">State</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Enter State"  id="business_state" value={business_state} onChange={updatebusiness_state} />
                            <code>{business_state_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Country</label>
                            <div class="col-sm-9">
                              <input type="email" class="form-control" placeholder="Enter Country"  id="business_country" value={business_country} onChange={updatebusiness_country} />
                            <code>{business_country_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Pincode</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Enter Pincode"  id="business_pincode" value={business_pincode} onChange={updatebusiness_pincode} />
                            <code>{business_pincode_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">GSTIN</label>
                            <div class="col-sm-9">
                              <input type="email" class="form-control" placeholder="Enter GSTIN"  id="business_gstin" value={business_gstin} onChange={updatebusiness_gstin} />
                           <code>{business_gstin_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">License No.</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Enter License No."  id="business_licenseno" value={business_licenseno} onChange={updatebusiness_licenseno} />
                            <code>{business_licenseno_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Website</label>
                            <div class="col-sm-9">
                              <input type="email" class="form-control" placeholder="Enter Website"  id="business_website" value={business_website} onChange={updatebusiness_website} />
                            <code>{business_website_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date Established</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" placeholder=""  id="business_doe" value={business_doe} onChange={updatebusiness_doe} />
                            <code>{business_doe_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Industry</label>
                            <div class="col-sm-9">
                              <input type="email" class="form-control" placeholder="Enter Industry"  id="business_industry" value={business_industry} onChange={updatebusiness_industry} />
                            <code>{business_industry_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Sector</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Enter Sector"  id="business_sector" value={business_sector} onChange={updatebusiness_sector} />
                            <code>{business_sector_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="exampleTextarea1">About</label>
                        <textarea  class="form-control"  rows="2"   id="business_about" value={business_about} onChange={updatebusiness_about} ></textarea>
                      <code>{business_about_er}</code>
                      </div>
                      <div class="form-group">
                        <label for="exampleTextarea1">Details</label>
                        <textarea  class="form-control"  rows="2"  id="business_details" value={business_details} onChange={updatebusiness_details} ></textarea>
                      <code>{business_details_er}</code>
                      </div>
                    
                      <button class="btn-lg btn-warning" style={{fontWeight:"bold"}} onClick={addbusiness} >Add Business</button>
                        
                       
    
                     
                   
                    </div>
                  </div>
                </div>
    </>

);

}

export default Create_business;