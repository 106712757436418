
import React from 'react';
import {useState,useEffect,Component} from 'react';


function Refundpolicy(){

return(
<>
<html>
	<head>
		<title>Cancellation & Refund Policy</title>
	</head>
	<body>
	<div style={{fontSize:"1rem",textAlign:"left",padding:"2rem"}}>
        <h2 style={{textAlign: "center"}}><b>CANCELLATION & REFUND POLICY</b></h2>
        <br/>
        <br/>
        <p>Last updated : 2023-07-07</p>
        <br/>
		<p>
			<br/>
Refund and cancellation policy must be made available to customers on website and they have to agree to its terms during the order process. Customers have to click on an “Accept” or “Agree” for return and cancellation button before submitting their payment information. Then, once the order has been submitted, policy can be sent to customers with the order confirmation email. Refund and Cancellation Policy is available publicly on our website and the same is binding on every client placing an order with Startup Connect.
			<br/>
			<br/>
<b>CANCELLATION POLICY</b>
			<br/>
            <br/>
Startup Connect believes in helping its customers as far as possible, and has therefore a liberal cancellation policy. Under this policy:
			<br/>
Cancellations will be considered only if the request of cancellation is made within 24 hours of placing an order. In such cases we will levy Gateway Charges. However, the cancellation request will not be entertained if the orders have been communicated to us and we have initiated the process.
			<br/>
In case of complaints regarding services please refer the issue to us by dropping an email to help.StartupConnect@gmail.com
			<br/>
			<br/>
<b>REFUND POLICY</b>
<br/>
			<br/>
When a payment of fee is made to Startup Connect, the fees paid in advance are retained by Startup Connect in a client account. Startup Connect ,India will earn the fees upon working on a client’s matter. During an engagement, Startup Connect earns fee at different rates and different times depending on the completion of various milestones (e.g. providing client portal access, assigning relationship manager, obtaining DIN, Filing of forms, etc.). Refund cannot be provided for earned fee because resources and man hours spent on delivering the service are non-returnable in nature. Further, we can’t refund or credit any money paid to government entities, such as filing fees, registration fees or taxes, or to other third parties with a role in processing your order. Under any circumstance, Startup Connect shall be liable to refund only upto the fee amount paid by the client.
			<br/>
Store credit after deduction of charges of services and efforts put in, may be given based on the facts of the case as decided by the Feedback Manager and communicated to client.
			<br/>
            <br/>
<b>Refund will be liable to be paid by Startup Connect under following circumstances –</b>
			<br/>
            <br/>
•	Order cancelled within 24 hours of the payment and no execution has been started from our end subject to Cancellation policy.
			<br/>
•	Startup Connect is unable to render the committed scope of services.
			<br/>
            <br/>
            <b>Refund will not be processed by Startup Connect under following circumstances as Startup Connect dedicates its manpower, time, resources and technology to cater to the client’s requirements–</b>
            <br/>
			<br/>
•	Offers obtained under any discount or promotional campaigns
			<br/>
•	Execution work has been started
			<br/>
•	Delays/clarifications/re-submissions by the MCA, government bodies, other Govt authorities
			<br/>
•	Non availability of documents from client’s end
			<br/>
•	Towards any component/s of the bundled/customized package availed
			<br/>
•	In case of any untimely events such as earthquake, fire, flood, pandemic
			<br/>
•	Change of business plans by the client and not opting for the services chosen.
			<br/>
•	Payments made by you to any third parties directly for e.g. affiliates, vendors, employees
			<br/>
*This is not an exclusive list, the Feedback Manager will look into the case and decide accordingly.
			<br/>
			<br/>
<b>ORDER CANCELLATION AND REFUND PROCESS</b>
			<br/>
			<br/>
•	For availing refund the client has to write to us at help.startupconnect@gmail.com the name, phone number, email, order details, date of order, transaction or payment information along with any other important information to be conveyed. 	
			<br/>
•	The Feedback Manager will then be in touch with you to understand and verify the details provided.
			<br/>
•	Feedback Manager will then decide if the request is applicable for refund and cancellation or not. If your request is fair then our company will initiate the process for your refund as discussed and conveyed during your interaction with Feedback Manager.
			<br/>
The refund will be made to the party within 7 Days, post successful verification and completion of Order Cancellation or Refund Request from our Feedback Manager once received by the client.
			<br/>
			<br/>
			<b>Note:-</b> Any dispute arising from or in connection with the Refund and Cancellation Policy or Claim shall be settled through friendly negotiation. In case no settlement can be reached, the dispute shall be then submitted as per Dispute Resolution Clause mentioned under this agreement.*
			<br/>
			<br/>
<b>DISPUTE RESOLUTION/JURISDICTION</b>
			<br/>
            <br/>
Please note that any Service Agreement shall be governed by the laws of India and shall be construed in accordance with the laws thereof. The Parties agree that any and all disputes between the Parties under or relating to the terms and conditions of this Agreement shall be fully and completely adjudicated in the courts of Jabalpur, Madhya Pradesh.
			<br/>
            <br/>
            <br/>
<b>CONTACT US</b>
			<br/>
			<br/>
Please send your feedback, comments, requests for technical support by email: help.startupconnect@gmail.com.
			<br/>
		</p>
		</div>
	</body>
</html>
</>
);
}

export default Refundpolicy;