import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import '../Resources/dashboard.css';
import axios from '../../axios.js';

function Password_reset(){

  //console.log

        //Fetching Sessions set in Registartion
        const admin_sn = sessionStorage.getItem("admin_sn");
        const admin_id = sessionStorage.getItem("admin_id");
    

  //password
  const[password,setpassword]=useState();const updatepassword = (event) => { setpassword(event.target.value);}; const[password_er,setpassword_er]=useState();
  //cpassword
  const[cpassword,setcpassword]=useState();const updatecpassword = (event) => { setcpassword(event.target.value);};  const[cpassword_er,setcpassword_er]=useState();
  //contact
 // const[contact,setcontact]=useState();const updatecontact = (event) => { setcontact(event.target.value);};  const[contact_er,setcontact_er]=useState();

  //ERROR VARIABLES 
  const[error_msg,seterror_msg]=useState();

  function changepass(){

    
  //Checking if variables set
 if(((admin_sn!=null)||(admin_sn!=""))&&((admin_id!=null)||(admin_id!=" "))){

//Setting Error Variables
setpassword_er(" ");setcpassword_er(" "); seterror_msg(" ");


//Fetching Form Data
  let noerror = 0;
  const $password = document.getElementById("password").value; if($password==""){ setpassword_er("Password is required !");noerror = noerror+1; }//Fetching password Value
  const $cpassword = document.getElementById("cpassword").value; if($cpassword==""){ setcpassword_er("Confirm Password is required !");noerror = noerror+1; }//Fetching cpassword Value


//primary errors
if(noerror==0){

  //Sending Data through axios
  axios.post("bt-reset-password", { 
   Password:$password,
   Confirm_password:$cpassword,
   Admin_id:admin_id,
   Admin_sn:admin_sn,
 
})

 .then((response) => {
   


    //console.log(response);
    //console.log(admin_id);

   //Validation Errors
 if(response.data.validatorerror){
   setpassword_er(response.data.validatorerror.Password);
   setcpassword_er(response.data.validatorerror.Confirm_password);
   return;
 }
 //Invalid Entries
 if(response.data.error==102){ 
    
    setpassword_er(response.data.password_er); 
    setcpassword_er(response.data.cpassword_er); 
    return;

}

//Password and Confirm Password Don't Match
if(response.data.error==104){
  seterror_msg(response.data.error_msg);
}
 //Success
 if(response.data.successvar==1){
  
  //Removing Sessions 
  sessionStorage.removeItem("admin_sn");
  sessionStorage.removeItem("admin_id");

 document.getElementById("success-card").style.display="block";
 
 }
 //failure
 if(response.data.successvar==6){
 seterror_msg(response.data.error_msg);
 return
 }
 if(response.data.error==105){
    seterror_msg(response.data.error_msg);
    return;
 }
}    );

}
//Return Errors
else{
  return;
  }
 }
  //Variables Not Set
else{
    alert("Session Expired !");
    window.location.replace("businesstools-login");
   }


  }

return(
    <>

<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Business Tools|BookKeeping Solution</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div class="container-scroller">

    <div class="container-fluid page-body-wrapper">


    {/*}   <Navbar /> */}
        {/*} partial */}
        <div class="main-panel">

        <div class="d-flex justify-content-center">
             {/* SUCCESS CARD START */}
             <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Password Updated Successfully!!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                    {/*}  <a href="/businesstools-login"><button class="mt-3 btn btn-outline-success">Continue</button></a> */}
                      <a href="/businesstools-login"><button class="mt-3 btn btn-outline-success">Continue</button></a> 
                    </blockquote>
                  </div>
              
                </div>
              </div>
              {/* SUCCESS CARD END */}
              </div>

        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card">
        <div class="card" style={{textAlign:"left"}}>
                  <div class="card-body">
                 
                    <h2 class="card-title" style={{fontSize:"2rem"}}>Forget Password</h2>
                    <p>Reset in Just Few Steps</p>
                   
                    <p  style={{textAlign:"center"}}><code>{error_msg}</code></p>
                    <div class="forms-sample">
                      <div class="form-group">
                        <label for="exampleInputUsername1">Password</label>
                        <input type="password" class="form-control" id="password" placeholder="new password" value={password} onChange={updatepassword} />
                        <code>{password_er}</code>
                      </div>
                      <div class="form-group">
                        <label for="exampleInputEmail1">Confirm Password</label>
                        <input type="password" class="form-control" id="cpassword" placeholder="confirm password" value={cpassword} onChange={updatecpassword} />
                        <code>{cpassword_er}</code>
                      </div>
                 
                      <button type="submit" id="login-btn" class="btn-lg btn-primary me-2" onClick={changepass}>Change Password </button>
                      <br/>
                      {/*<div style={{textAlign:"right"}}><a href="">Forget contact</a></div>*/}
                    </div>
                  
                  </div>
                </div>
                </div>
                </div>
                  
             

                
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer class="footer">
            <div class="d-sm-flex justify-content-center justify-content-sm-between">
              <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
    </>

);

}

export default Password_reset;