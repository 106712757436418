import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import '../Resources/dashboard.css';
import Navbar from '../navbar.js';
import axios from '../../axios.js';
import moment from 'moment/moment';


function Create_manufacturingproduct(){


  
   //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const busyid=sessionStorage.getItem("BusyId00");
 const counterid=sessionStorage.getItem("CounterId00");
 const trno=sessionStorage.getItem("Trno00");
 const editid=sessionStorage.getItem("MId00");

  {/* ADD PRODUCTS TABLE COLUMNS MANAGEMENT VARIABLES START */}
  const[cd_col1_val,setcd_col1_val] = useState(0);//column1
  const[cd_col2_val,setcd_col2_val] = useState(0);//column2
  const[cd_col3_val,setcd_col3_val] = useState(0);//column3
  const[cd_col4_val,setcd_col4_val] = useState(0);//column4
  const[cd_col5_val,setcd_col5_val] = useState(0);//column5
  const[cd_col6_val,setcd_col6_val] = useState(0);//column6
  const[cd_col7_val,setcd_col7_val] = useState(0);//column7
  const[cd_col8_val,setcd_col8_val] = useState(0);//column8
  const[cd_col9_val,setcd_col9_val] = useState(0);//column9
  const[cd_col10_val,setcd_col10_val] = useState(0);//column10
  const[cd_col11_val,setcd_col11_val] = useState(0);//column11
  const[cd_col12_val,setcd_col12_val] = useState(0);//column12
  const[cd_col13_val,setcd_col13_val] = useState(0);//column13
  const[cd_col14_val,setcd_col14_val] = useState(0);//column14
  const[cd_col15_val,setcd_col15_val] = useState(0);//column15
  const[cd_col16_val,setcd_col16_val] = useState(0);//column16
  const[cd_col17_val,setcd_col17_val] = useState(0);//column17
  const[cd_col18_val,setcd_col18_val] = useState(0);//column18
  const[cd_col19_val,setcd_col19_val] = useState(0);//column19
  const[cd_col20_val,setcd_col20_val] = useState(0);//column20
  {/* ADD PRODUCTS TABLE COLUMNS MANAGEMENT VARIABLES END */}


 //Checking if Sessions are Set

// alert(logstatus,loguser,logadminid);
 //Sessions Not Set
 {/* if(((logstatus==null)||(logstatus==""))||((logid==null)||(logid==""))||((token==null)||(token==" "))||((busyid==null)||(busyid==" "))||((counterid==null)||(counterid==" "))||((trno==null)||(trno==" "))){
  
  alert(trno);
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");
   
 
    window.location.replace("/businesstools-login");
   }
  
   //Sessions Set
   else{
   */}



       
      
//Variables
const[inventory,setinventory] = useState([]);//SetData Variable
const[products,setproducts] = useState([],);//SetData Variable
const[cdefault,setcdefault] = useState(0);
const[ecdefault,setecdefault] = useState(0);

//Error Variable
const[errorvar,seterrorvar] = useState();



//EDIT FORM VARIABLES
//ivno
const[ivno,setivno]=useState();const updateivno = (event) => { setivno(event.target.value);geteproductbyname(); }; const[ivno_er,setivno_er]=useState();
//eproduct_name
const[eproduct_name,seteproduct_name]=useState();const updateeproduct_name = (event) => { seteproduct_name(event.target.value);geteproductbyname(); }; const[eproduct_name_er,seteproduct_name_er]=useState();
//eproduct_id
const[eproduct_id,seteproduct_id]=useState();const updateeproduct_id = (event) => { seteproduct_id(event.target.value);}; const[eproduct_id_er,seteproduct_id_er]=useState();
//eproduct_code
const[eproduct_code,seteproduct_code]=useState();const updateeproduct_code = (event) => { seteproduct_code(event.target.value);}; const[eproduct_code_er,seteproduct_code_er]=useState();
//eproduct_hsn
const[eproduct_hsn,seteproduct_hsn]=useState();const updateeproduct_hsn = (event) => { seteproduct_hsn(event.target.value);}; const[eproduct_hsn_er,seteproduct_hsn_er]=useState();
//etype
const[etype,setetype]=useState();const updateetype = (event) => { setetype(event.target.value);}; const[etype_er,setetype_er]=useState();
//erate
const[erate,seterate]=useState();const updateerate = (event) => { seterate(event.target.value); ecalculate(); }; const[erate_er,seterate_er]=useState();
//equantity
const[equantity,setequantity]=useState();const updateequantity = (event) => { setequantity(event.target.value); ecalculate(); }; const[equantity_er,setequantity_er]=useState();
//eunits
const[eunits,seteunits]=useState();const updateeunits = (event) => { seteunits(event.target.value);}; const[eunits_er,seteunits_er]=useState();
//eamount
const[eamount,seteamount]=useState();const updateeamount = (event) => { seteamount(event.target.value); ecalculate(); }; const[eamount_er,seteamount_er]=useState();
//etotal
const[etotal,setetotal]=useState();const updateetotal = (event) => { setetotal(event.target.value); ecalculate(); }; const[etotal_er,setetotal_er]=useState();




//CREATE FORM VARIABLES
//product_name
const[product_name,setproduct_name]=useState();const updateproduct_name = (event) => { setproduct_name(event.target.value);getproductbyname(); }; const[product_name_er,setproduct_name_er]=useState();
//product_id
const[product_id,setproduct_id]=useState();const updateproduct_id = (event) => { setproduct_id(event.target.value);}; const[product_id_er,setproduct_id_er]=useState();
//product_code
const[product_code,setproduct_code]=useState();const updateproduct_code = (event) => { setproduct_code(event.target.value);}; const[product_code_er,setproduct_code_er]=useState();
//product_hsn
const[product_hsn,setproduct_hsn]=useState();const updateproduct_hsn = (event) => { setproduct_hsn(event.target.value);}; const[product_hsn_er,setproduct_hsn_er]=useState();
//type
const[type,settype]=useState();const updatetype = (event) => { settype(event.target.value);}; const[type_er,settype_er]=useState();
//rate
const[rate,setrate]=useState();const updaterate = (event) => { setrate(event.target.value); calculate(); }; const[rate_er,setrate_er]=useState();
//quantity
const[quantity,setquantity]=useState();const updatequantity = (event) => { setquantity(event.target.value); calculate(); }; const[quantity_er,setquantity_er]=useState();
//units
const[units,setunits]=useState();const updateunits = (event) => { setunits(event.target.value);}; const[units_er,setunits_er]=useState();
//amount
const[amount,setamount]=useState();const updateamount = (event) => { setamount(event.target.value); calculate(); }; const[amount_er,setamount_er]=useState();
//total
const[total,settotal]=useState();const updatetotal = (event) => { settotal(event.target.value); calculate(); }; const[total_er,settotal_er]=useState();


//Setting Final Variables
//final_amt
const[final_amt,setfinal_amt]=useState();const updatefinal_amt = (event) => { setfinal_amt(event.target.value); }; const[final_amt_er,setfinal_amt_er]=useState();
//labour
const[labour,setlabour]=useState();const updatelabour = (event) => { setlabour(event.target.value); calculatetotal(); }; const[labour_er,setlabour_er]=useState();
//other_expenses
const[other_expenses,setother_expenses]=useState();const updateother_expenses = (event) => { setother_expenses(event.target.value); calculatetotal(); }; const[other_expenses_er,setother_expenses_er]=useState();
//power
const[power,setpower]=useState();const updatepower = (event) => { setpower(event.target.value); calculatetotal(); }; const[power_er,setpower_er]=useState();
//final_total
const[final_total,setfinal_total]=useState();const updatefinal_total = (event) => { setfinal_total(event.target.value); }; const[final_total_er,setfinal_total_er]=useState();
//quantities_produced
const[quantities_produced,setquantities_produced]=useState();const updatequantities_produced = (event) => { setquantities_produced(event.target.value);  unitsCalculation(); }; const[quantities_produced_er,setquantities_produced_er]=useState();
//unit_production_cost
const[unit_production_cost,setunit_production_cost]=useState();const updateunit_production_cost = (event) => { setunit_production_cost(event.target.value); }; const[unit_production_cost_er,setunit_production_cost_er]=useState();
//product_units
const[product_units,setproduct_units]=useState();const updateproduct_units = (event) => { setproduct_units(event.target.value); unitsCalculation(); }; const[product_units_er,setproduct_units_er]=useState();


//particular
const[particular,setparticular]=useState();const updateparticular = (event) => { setparticular(event.target.value); unitsCalculation(); }; const[particular_er,setparticular_er]=useState();


//SUCCESS VARIABLES
const[success_msg,setsuccess_msg]=useState();
const[esuccess_msg,setesuccess_msg]=useState();

//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();
const[eerror_msg,seteerror_msg]=useState();
const[e1error_msg,sete1error_msg]=useState();
const[error_msg1,seterror_msg1]=useState();

//Units Caollculation
function unitsCalculation(){

  const $final_total = final_total; const $quantities_produced = quantities_produced;
  const $unit_production_cost = parseFloat(($final_total/$quantities_produced),2);
  const upc = $unit_production_cost;
  document.getElementById("unit_production_cost").value=upc;

}




useEffect(()=>{
   
  getData();
  
  },[])



  
  
  
//******************* GET PRODUCTS LIST DATA FUNCTION START  ********************//
function getData(){
 


  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((trno!=null)||(trno!=" "))&&((editid!=null)||(editid!=" "))){
 
 
 axios.post("bt-add-manufacturing-inventory-list", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Tr_no:trno,
   Mid:editid,
   Sn_start :0,
   Sn_end :0,
   Sn_total :0,
   Sn_limit :0
 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setinventory(response.data.data);
   setfinal_amt((response.data.total).toFixed(2));
  /* setpower((response.data.power).toFixed(2));
   setother_expenses((response.data.other_expenses).toFixed(2));
   setshipping_carriages((response.data.shipping_carriages).toFixed(2));
   setfinal_total((response.data.total).toFixed(2));
   setparticular(response.data.particular); */

  
  }
  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* GET PRODUCTS LIST DATA FUNCTION END  ********************//


    //******************* GET PRODUCT DATA BY NAME FUNCTION START  ********************//
function getproductbyname(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((trno!=null)||(trno!=" "))){
 
     const cname = document.getElementById("product_name").value;
     const cnlength = cname.length;
     if((cdefault==0)&&(cnlength>=3)){
     
 
 axios.post("bt-get-productlist-spr", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Name:document.getElementById("product_name").value,
   Sn_start :0,
   Sn_end :0,
   Sn_total :0,
   Sn_limit :0
 })
 .then((response) => {

  //console.log(response);

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setproducts(response.data.productlist);
   document.getElementById("products-list-box").style.display="block";
  
  }
  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
}
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }

 }
  
 //******************* GET PRODUCT DATA BY NAME FUNCTION END  ********************//




 
    //******************* GET PRODUCT DATA BY NAME FOR EDIT FUNCTION START  ********************//
function geteproductbyname(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((trno!=null)||(trno!=" "))){
 
     const cname = document.getElementById("eproduct_name").value;
     const cnlength = cname.length;
     if((ecdefault==0)&&(cnlength>=3)){
     
 
 axios.post("bt-get-productlist-spr", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Name:document.getElementById("eproduct_name").value,
   Sn_start :0,
   Sn_end :0,
   Sn_total :0,
   Sn_limit :0
 })
 .then((response) => {

  //console.log(response);

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setproducts(response.data.productlist);
   document.getElementById("eproducts-list-box").style.display="block";
  
  }
  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
}
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }

 }
  
 //******************* GET PRODUCT DATA BY NAME FOR EDIT FUNCTION END  ********************//

 
  
//******************* ADD PRODUCT FUNCTION START ********************//
function addproduct(){

  //Setting Error Variables
setproduct_name_er(" ");settype_er(" ");setproduct_id_er(" ");setproduct_code_er(" ");setproduct_hsn_er(" ");setrate_er(" ");setquantity_er(" ");setunits_er(" ");setamount_er(" ");settotal_er(" ");
seterror_msg(" ");setsuccess_msg(" ");



  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))){
 
 
  axios.post("bt-add-manufacturing-inventory", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Mid:editid,
   Particular:"product manufacturing",
   Tr_no:trno,
   Product_id:product_id,
   Product_name:product_name,
   Product_code : product_code,
   Product_hsn : product_hsn,
   Quantity:quantity,
   Rate:rate,
   Units:units,
 })
 .then((response) => {


  if(response.data.error==103){     seterror_msg(response.data.error_msg);  return; }

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");

  }

   //Validation Errors
 if(response.data.validatorerror){
 // settype_er(response.data.validatorerror.Type);
  setproduct_name_er(response.data.validatorerror.Product_name);
  setproduct_id_er(response.data.validatorerror.Product_id);
  setproduct_code_er(response.data.validatorerror.Product_code);
  setproduct_hsn_er(response.data.validatorerror.Product_hsn);
  //settrno_er(response.data.validatorerror.Tr_no);
  setquantity_er(response.data.validatorerror.Quantity);
  setrate_er(response.data.validatorerror.Rate);
  setunits_er(response.data.validatorerror.Units);

  
  return;

}
//Invalid Entries
if(response.data.error==102){ 
  
  //settype_er(response.data.type_er);
  setproduct_name_er(response.data.product_name_er);
  setproduct_code_er(response.data.product_code_er);
  setproduct_id_er(response.data.product_id_er);
  setproduct_hsn_er(response.data.product_hsn_er);
  setrate_er(response.data.rate_er);
  setquantity_er(response.data.quantity_er);
  setunits_er(response.data.units_er);
 
  seterror_msg("Cannot Add Product!");  return; }

   

   //Product Entered Successfully
   if(response.data.successvar==1){   
   /*  document.getElementById("create-form").style.display="block";*/ setsuccess_msg("Product Added Successfully!")  

setproduct_name(" "); setproduct_id(" ");setproduct_code(" ");setproduct_hsn(" ");setrate(" ");setquantity(" ");setunits(" ");setamount(" ");settotal(" ");
seterror_msg(" ");

   //Calling Default Load Function
   getData();
   }
   //Cannot Add Product
   else{  seterror_msg("Cannot Add Product !"); }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* ADD PRODUCT FUNCTION END ********************//



  
//******************* COMPLETE MANUFACTURING FUNCTION START ********************//
function completemanufacturing(){

//Setting Error Variables
setpower_er(" ");setother_expenses_er(" ");setlabour_er(" ");setparticular_er(" ");seterror_msg1(" ");



  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))&&((trno!=null)||(trno!=" "))){
 
 
  axios.post("bt-complete-manufacturing", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Tr_no : trno,
   Mid:editid,
   Power:power,
   Other_expenses:other_expenses,
   Labour:labour,
   Particular : particular,
   Quantities_produced: quantities_produced,
   Units: product_units,

 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
  }

   //Validation Errors
 if(response.data.validatorerror){
  setother_expenses_er(response.data.validatorerror.Other_expenses);
  setpower_er(response.data.validatorerror.Power);
  setlabour_er(response.data.validatorerror.Labour);
  setparticular_er(response.data.validatorerror.Particular);



}
//Invalid Entries
if(response.data.error==102){ 
  
  setother_expenses_er(response.data.other_expenses_er);
  setpower_er(response.data.power_er);
  setlabour_er(response.data.labour_er);
  setparticular_er(response.data.particular_er);
 
  seterror_msg("Cannot Add Transaction!");  }


  //Invalid Entries
  if(response.data.error==103){     seterror_msg(response.data.error_msg);  }

   //Transaction Entered Successfully
   if(response.data.successvar==1){        document.getElementById("success-card").style.display="block";  }
   //Cannot Add Transaction
   else{  seterror_msg("Cannot Add Transaction !"); }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* COMPLETE PURCHASERETURNS FUNCTION END ********************//



 
 
 //******************* EDIT PRODUCT DETAILS FUNCTION START ********************//
function editproducts(){

  //Setting Error Variables
  seteproduct_name(" ");setetype(" ");seteproduct_id(" ");seteproduct_code(" ");seteproduct_hsn(" ");seterate(" ");setequantity(" ");seteunits(" ");seteamount(" ");setetotal(" ");
   seteerror_msg(" ");setesuccess_msg(" ");

 // const expenseid = sessionStorage.getItem("Expenseid00");
  

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))&&((ivno!=null)||(ivno!=" "))&&((trno!=null)||(trno!=" "))){
 
  

  axios.post("bt-edit-manufacturing-inventory", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Ivno:ivno,
   Type:'manufacturing',
   Tr_no:trno,
   Product_id:eproduct_id,
   Product_name:eproduct_name,
   Product_code : eproduct_code,
   Product_hsn : eproduct_hsn,
   Quantity:equantity,
   Rate:erate,
   Units:eunits,
 
 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");
     sessionStorage.removeItem("Trno00");

     window.location.replace("businesstools-login");
  }

   //Validation Errors
 if(response.data.validatorerror){
  setetype_er(response.data.validatorerror.Type);
  seteproduct_name_er(response.data.validatorerror.Product_name);
  seteproduct_id_er(response.data.validatorerror.Product_id);
  seteproduct_code_er(response.data.validatorerror.Product_code);
  seteproduct_hsn_er(response.data.validatorerror.Product_hsn);
  //settrno_er(response.data.validatorerror.Tr_no);
  setequantity_er(response.data.validatorerror.Quantity);
  seterate_er(response.data.validatorerror.Rate);
  seteunits_er(response.data.validatorerror.Units);
  


}
//Invalid Entries
if(response.data.error==102){ 
  
  setetype_er(response.data.type_er);
  seteproduct_name_er(response.data.product_name_er);
  seteproduct_code_er(response.data.product_code_er);
  seteproduct_id_er(response.data.product_id_er);
  seteproduct_hsn_er(response.data.product_hsn_er);
  seterate_er(response.data.rate_er);
  setequantity_er(response.data.quantity_er);
  seteunits_er(response.data.units_er);
 
  seterror_msg("Cannot Update Transaction!");  }



   //Transaction Updated Successfully
   if(response.data.successvar==1){        
     //Setting Variables to empty 
    
seteproduct_name(" ");seteproduct_id(" ");seteproduct_code(" ");seteproduct_hsn(" ");seterate(" ");setequantity(" ");seteunits(" ");seteamount(" ");setetotal(" ");
seteerror_msg(" ");setivno(" ");
setesuccess_msg("Product Updated Successfully !");

   //Calling Default Load Function
   getData();      document.getElementById("edit-form").style.display="none"; 
 }

   //Cannot Update Transaction
   else{  seterror_msg("Cannot Update Product !"); }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");
   sessionStorage.removeItem("Trno00");
   sessionStorage.removeItem("Expenseid00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* EDIT PRODUCT DETAILS FUNCTION END ********************//


 
 
 //******************* DELETE PRODUCTS FUNCTION START ********************//
function deleteproduct(){

  const divno=sessionStorage.getItem("DIvno00");
  const dtrno=sessionStorage.getItem("DTrno00");

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))&&((divno!=null)||(divno!=" "))&&((dtrno!=null)||(dtrno!=" "))){
 
 axios.post("bt-delete-manufacturing-inventory", {
   Busy_id:busyid,
   Counter_id:counterid,
   Log_id:logid,
   Token:token,
   Ivno:divno,
   Tr_no:dtrno
 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");
     sessionStorage.removeItem("DIvno00");
     sessionStorage.removeItem("DTrno00");

     window.location.replace("businesstools-login");
  }

  //Counter Deleted Successfully
   if(response.data.successvar==1){        

    //  document.getElementById("delete-card").style.display="none";
    //  document.getElementById("delete-success-card").style.display="block";  
      sessionStorage.removeItem("DIvno");
      sessionStorage.removeItem("DTrno00");
      setesuccess_msg("Product Deleted Successfully !");
      getData();

  }
  
      //Cannot Delete Counter
    else{  
      sessionStorage.removeItem("DIvno");
      sessionStorage.removeItem("DTrno00");
      sete1error_msg("Cannot Delete Product");
  
    }

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");
   sessionStorage.removeItem("DIvno00");
   sessionStorage.removeItem("DTrno00");


   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* DELETE ADD PRODUCTS FUNCTION END ********************//


 
  
function calculate(){
  
  //Fetching Values
  let rate = (document.getElementById("rate").value);if(rate==""){ rate=0;}else{ rate=parseFloat(rate);}
  let quantity = (document.getElementById("quantity").value);if(quantity==""){ quantity=0;}else{ quantity=parseFloat(quantity);}
  let amount = parseFloat(rate*quantity);
   
   //Setting Final Values to display
    //total = amount;
    setamount(amount);
    settotal(amount);

}



  
function ecalculate(){
  
  //Fetching Values
  let erate = (document.getElementById("erate").value);if(erate==""){ erate=0;}else{ erate=parseFloat(erate);}
  let equantity = (document.getElementById("equantity").value);if(equantity==""){ equantity=0;}else{ equantity=parseFloat(equantity);}
  let eamount = parseFloat(erate*equantity);
   
  seteamount(eamount);
  setetotal(eamount);
   //Setting Final Values to display
   // etotal = eamount;

}




  
function calculatetotal(){

  let final_amt = document.getElementById("final_amt").value;if(final_amt==""){ final_amt=0;}else{ final_amt=parseFloat(final_amt);}
  let power = document.getElementById("power").value;if(power==""){ power=0;}else{ power=parseFloat(power);}
  let other_expenses = document.getElementById("other_expenses").value;if(other_expenses==""){ other_expenses=0;}else{ other_expenses=parseFloat(other_expenses);}
  let labour = document.getElementById("labour").value;if(labour==""){ labour=0;}else{ labour=parseFloat(labour);}
  //Fetching and Calculating Values
  let final_total = parseFloat(final_amt+power+labour+other_expenses);
  setfinal_total(final_total.toFixed(2));


}

return(
    <>
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Business Tools|BookKeeping Solution</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div class="container-scroller">

  
    <div class="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div class="main-panel">

           {/* purchasereturns CREATED SUCCESS CARD START */}
           <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Product Manufactured Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <a href="/businesstools-user-manufacturing"><button class="mt-3 btn btn-outline-danger">Close</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* PURCHASERETURNS CREATED SUCCESS CARD END */}

          <div class="content-wrapper pb-0">

            <div class="page-header flex-wrap">
            <div class="header-left"></div>
              <div class="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div class="d-flex align-items-center">
                  <a href="#">
                    <p class="m-0 pe-3">Manufacture New Item</p>
                  </a>
                 {/*} <a class="ps-3 me-4" href="#">
                    <p class="m-0">Add Products</p>
                    
                  </a> */}
                </div>
            
              </div>
            </div>

               {/* EDIT PRODUCT LIST BOX START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-4 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"1rem",display:"none"}} id="eproducts-list-box">
                <div class="card">
                <div class="d-flex justify-content-between">
                    <div>
                    <h4 style={{padding:'1rem'}}>Products</h4>
                    </div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ setecdefault(1); document.getElementById("eproducts-list-box").style.display="none"; }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                    
                    <table class="table table-hover">
                      <thead>
                        <tr>
                       {/*}   <th>Product Id</th> */}
                          <th>Product Name</th>
                        {/*}  <th>Product HSN</th>
                          <th>Product Code</th> */}
                          <th>Purchase Rate</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                       products.map((curElm70,ind)=>{

                         return(
                         <>
                        <tr key={curElm70.Product_id}>
                         {/*} <td>{curElm70.Product_id}</td> */}
                          <td>{curElm70.Product_name}</td>
                        {/*}  <td>{curElm70.Product_hsn}</td>
                          <td>{curElm70.Product_code}</td> */}
                          <td>{curElm70.Purchase_rate}</td>
                          <td><i class="mdi mdi-assistant text-warning" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                         //Setting Product Details for 
                         seteproduct_code(curElm70.Product_code);seteproduct_hsn(curElm70.Product_hsn);seteproduct_name(curElm70.Product_name);seteunits(curElm70.Units); seterate(curElm70.Purchase_rate);
                         document.getElementById("eproducts-list-box").style.display="none";
                              }}></i></td>

                        </tr>
                        </>
                         )
                         }
					   
	                  		 )
                         }
                      </tbody>
                    </table>
              
                </div>
              </div>
              </div>
              {/* EDIT PRODUCT LIST BOX END */}


                     {/* EDIT PRODUCT DETAILS FORM START */}
                     <div class="row">
              <div class="col-12 grid-margin create-form" id="edit-form" style={{ display:"none",textAlign:"left" }}>
              <div class="card">
              <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ 
                        
                        seteproduct_name(" ");setetype(" ");seteproduct_id(" ");seteproduct_code(" ");seteproduct_hsn(" ");seterate(" ");setequantity(" ");seteunits(" ");seteamount(" ");setetotal(" ");
                        seteerror_msg(" ");setesuccess_msg(" ");setivno(" ");

                        document.getElementById("edit-form").style.display="none"; }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                  <div class="card-body">
                    <h4 class="card-title">Edit Products</h4>
                    <div class="form-sample">
                      <p class="card-description">edit product details</p>
                      <code>{eerror_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product Name<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Levis red T-Shirt " id="eproduct_name" value={eproduct_name} onChange={updateeproduct_name} />
                            <code>{eproduct_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product Code</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="87766" id="eproduct_code" value={eproduct_code} onChange={updateeproduct_code} />
                            <code>{eproduct_code_er}</code>
                            </div>
                          </div>
                        </div>   
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">HSN Code</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="H65S433" id="eproduct_hsn" value={eproduct_hsn} onChange={updateeproduct_hsn} />
                              <code>{eproduct_hsn_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Rate</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="500.00" id="erate" value={erate} onChange={updateerate}/>
                              <code>{erate_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Quantity</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="10.00" id="equantity" value={equantity} onChange={updateequantity} />
                              <code>{equantity_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Units</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="pieces" id="eunits" value={eunits} onChange={updateeunits} />
                              <code>{eunits_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="5000.00" id="eamount" value={eamount} onChange={updateeamount}/>
                              <code>{eamount_er}</code>
                            </div>
                          </div>
                        </div>
              {/*}          <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Total</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" style={{fontWeight:"bold"}} placeholder="5000.00" id="etotal" value={etotal} onChange={updateetotal} />
                              <code>{etotal_er}</code>
                            </div>
                          </div>
                        </div>      */}                
                      </div>
               
                  
                        <button class="btn btn-primary" style={{fontWeight:"bold"}} onClick={editproducts}>Submit</button>
                      
                       
    
                     
                   
                    </div>
                  </div>
                </div>
              </div>
          </div>
          {/* EDIT PRODUCT DETAILS FORM END */}

  
  
          {/* PRODUCT LIST START */}                
          <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Inputs</h4>
                    <p class="text-success" style={{textAlign:"center"}}>{esuccess_msg}</p>
                    <code>{e1error_msg}</code>
                    <p class="card-description">
                    </p>
                    <div class="table-responsive">
                      <table class="table table-hover">
                        <thead>
                          <tr >
                            <th  style={{fontWeight:"bold"}} >Sn</th>
                            <th  style={{fontWeight:"bold"}} >Product Code</th>
                            <th  style={{fontWeight:"bold"}} >HSN Code</th>
                            <th  style={{fontWeight:"bold"}} >Product Name</th>
                            <th  style={{fontWeight:"bold"}} >Quantity</th>
                            <th  style={{fontWeight:"bold"}} >Rate</th>
                            <th  style={{fontWeight:"bold"}} >Value</th>
                            <th  style={{fontWeight:"bold"}} ></th>
                            <th  style={{fontWeight:"bold"}} ></th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                       inventory.map((curElm1,ind)=>{

                         return(
                         <>
                        <tr key={curElm1.Ivno}>
                            <td hidden={cd_col1_val==1}>{curElm1.Sn}</td>
                            <td hidden={cd_col2_val==1}>{curElm1.Product_code}</td>
                            <td hidden={cd_col3_val==1}>{curElm1.Product_hsn}</td>
                            <td hidden={cd_col4_val==1}>{curElm1.Product_name}</td>
                            <td hidden={cd_col5_val==1}>{curElm1.Quantity}</td>
                            <td hidden={cd_col6_val==1}>{curElm1.Rate}</td>
                            <td hidden={cd_col7_val==1}>{curElm1.Value}</td>
                           {/*} <td hidden={cd_col18_val==1}>{curElm1.Value}</td>    */}  
                            <td hidden={cd_col19_val==1}  onClick={()=>{ 
               const createForm = document.getElementById('edit-form');const currentDisplay = createForm.style.display;  createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; 
                  //Setting Product Variables for Edit
                  setivno(curElm1.Mp_id);seteproduct_id(curElm1.Product_id);seteproduct_name(curElm1.Product_name);seteproduct_code(curElm1.Product_code);seteproduct_hsn(curElm1.Product_hsn);seterate(curElm1.Rate);setequantity(curElm1.Quantity);seteunits(curElm1.Units);seteamount(curElm1.Value);setetotal(curElm1.Value);

                       }}><i class="mdi mdi-pencil-box text-warning" style={{fontSize:"1.4rem"}}></i></td>
                            <td hidden={cd_col20_val==1}><i class="mdi mdi-delete-forever text-danger" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                        //Setting expense Id for Delete
                      sessionStorage.setItem("DIvno00",curElm1.Mp_id);      sessionStorage.setItem("DTrno00",curElm1.Tr_no); deleteproduct(); }}></i></td>

       
                            </tr>    
                            </>
                         )
                         }
					   
	                  		 )
                         }             
                             
                    
                         
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div><button class="btn btn-primary mb-2 btn-icon-text" onClick={()=>{ document.getElementById("create-form").style.display="block"; }}><i class="mdi mdi-plus-circle"></i> Add Input Product</button></div>
                </div>              
              </div>
             {/* PRODUCT LIST END */}  


            
        {/* PRODUCT LIST BOX START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-4 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"1rem",display:"none"}} id="products-list-box">
                <div class="card">
                <div class="d-flex justify-content-between">
                    <div>
                    <h4 style={{padding:'1rem'}}>Products</h4>
                    </div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ setcdefault(1); document.getElementById("products-list-box").style.display="none"; }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                    
                    <table class="table table-hover">
                      <thead>
                        <tr>
                        {/*}  <th>Product Id</th> */}
                          <th>Product Name</th>
                        {/*}  <th>Product HSN</th>
                          <th>Product Code</th> */}
                          <th>Purchase Rate</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                       products.map((curElm7,ind)=>{

                         return(
                         <>
                        <tr key={curElm7.Product_id}>
                          {/* <td>{curElm7.Product_id}</td> */}
                          <td>{curElm7.Product_name}</td>
                        {/*}  <td>{curElm7.Product_hsn}</td>
                          <td>{curElm7.Product_code}</td> */}
                          <td>{curElm7.Purchase_rate}</td>
                          <td><i class="mdi mdi-assistant text-warning" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                        //Setting Product Details for 
                        setproduct_code(curElm7.Product_code);setproduct_hsn(curElm7.Product_hsn);setproduct_name(curElm7.Product_name);setunits(curElm7.Units); setrate(curElm7.Purchase_rate);
                        document.getElementById("products-list-box").style.display="none";
                              }}></i></td>

                        </tr>
                        </>
                         )
                         }
					   
	                  		 )
                         }
                      </tbody>
                    </table>
              
                </div>
              </div>
              </div>
              {/* PRODUCT LIST BOX END */}

             {/* ADD PRODUCTS FORM START */}  
              <div class="row">
              <div class="col-12 grid-margin create-form" id="create-form" style={{textAlign:"left",display:"none"}}>
              <div class="card">
              <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ 
                        
                        setproduct_name(" ");settype(" ");setproduct_id(" ");setproduct_code(" ");setproduct_hsn(" ");setrate(" ");setquantity(" ");setunits(" ");setamount(" ");settotal(" ");
                        seterror_msg(" ");setsuccess_msg(" ");

                        document.getElementById("create-form").style.display="none"; }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                  <div class="card-body">
                    <h4 class="card-title">Add Products</h4>
                    
                    <div class="form-sample">
                      <p class="card-description">add products here</p>
                      <p class="text-success" style={{textAlign:"center"}}>{success_msg}</p>
                      <code>{error_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product Name<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Levis red T-Shirt " id="product_name" value={product_name} onChange={updateproduct_name} />
                            <code>{product_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product Code</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="87766" id="product_code" value={product_code} onChange={updateproduct_code} />
                            <code>{product_code_er}</code>
                            </div>
                          </div>
                        </div>   
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">HSN Code</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="H65S433" id="product_hsn" value={product_hsn} onChange={updateproduct_hsn} />
                              <code>{product_hsn_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Rate</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="500.00" id="rate" value={rate} onChange={updaterate}/>
                              <code>{rate_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Quantity</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="10.00" id="quantity" value={quantity} onChange={updatequantity} />
                              <code>{quantity_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Units</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="pieces" id="units" value={units} onChange={updateunits} />
                              <code>{units_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="5000.00" id="amount" value={amount} onChange={updateamount}/>
                              <code>{amount_er}</code>
                            </div>
                          </div>
                        </div>
                    {/*}    <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Total</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" style={{fontWeight:"bold"}} placeholder="5000.00" id="total" value={total} onChange={updatetotal} />
                              <code>{total_er}</code>
                            </div>
                          </div>
                        </div>             */}        
                      </div>
                  
                      
                        <button class="btn btn-primary" style={{fontWeight:"bold"}} onClick={addproduct}>Add Product</button>
                      
                       
    
                     
                   
                    </div>
                  </div>
                </div>
              </div>
          </div>
          {/* ADD PRODUCTS FORM END */}  

            {/* MAIN FORM SUBMIT START */}
            <div class="card" style={{textAlign:"left"}}>
                  <div class="card-body">
                  {/*}  <h4 class="card-title">Create purchasereturns</h4> */}
                    <div class="form-sample">
                      {/*<p class="card-description">add your purchasereturns details here</p>*/}
                      <code style={{textAlign:"center"}}>{error_msg1}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Inputs Total Amount</label>
                            <div class="col-sm-9">
                            <label>{final_amt}</label>
                            <input type="hidden" class="form-control" placeholder="final_amt" id="final_amt" value={final_amt} edittable="false" />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Power</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="5000.00"  id="power" value={power} onChange={updatepower} />
                            <code>{power_er}</code>
                            </div>
                          </div>
                        </div>   
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Labour</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="5000.00"  id="labour" value={labour} onChange={updatelabour} />
                            <code>{labour_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Other Expenses</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="5000.00"  id="other_expenses" value={other_expenses} onChange={updateother_expenses} />
                            <code>{other_expenses_er}</code> 
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Total Cost</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="25000.00"  id="final_total" value={final_total} onChange={updatefinal_total} />
                            <code>{final_total_er}</code>
                            </div>
                          </div>
                        </div>
                        </div>
                        <hr/>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Quantities Produced</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="5000.00"  id="quantities_produced" value={quantities_produced} onChange={updatequantities_produced} />
                            <code>{quantities_produced_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Final Product Units</label>
                            <div class="col-sm-9">
                             
                              <input type="text" class="form-control" placeholder="pcs"  id="product_units" value={product_units} onChange={updateproduct_units} />
                             

                            </div>
                          </div>
                        </div>     
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Unit Production Cost</label>
                            <div class="col-sm-9">
                             
                              <input type="text" class="form-control" placeholder="500.00"  id="unit_production_cost" value={unit_production_cost} />
                             

                            </div>
                          </div>
                        </div>                     
                      </div>

                        <div class="form-group">
                        <label for="exampleTextarea1">Product Remarks<code>*</code></label>
                        <textarea class="form-control" rows="2" id="particular" value={particular} onChange={updateparticular} ></textarea>
                        <code>{particular_er}</code>
                        </div>

                        <div class="row">
                        
                         <div class="col-md-4"><button class="btn-lg btn-warning mt-2" style={{fontWeight:"bold"}} onClick={completemanufacturing}>Manufacture</button></div> 
                        </div>
                      
        
                      
                      

           
                        
                      
                       
                       
    
                     
                   
                    </div>
                  </div>
                </div>

                {/* MAIN FORM SUBMIT END */}

    

            
          </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
        {/*}  <footer class="footer">
            <div class="d-sm-flex justify-content-center justify-content-sm-between">
              <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
</>
);

}
//}
export default Create_manufacturingproduct;