import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import Navbar from './navbar';
import Leftsidebar from './leftsidebar';
import Mainpanel from './mainpanel';
import Rightsidebar from './rightsidebar1';


//import '../hireportalcss.css';
//import '../Hireportalcss/hireportalcss.css';

//Importing Page css with screen sizes
import '../Hireportalcss/sdefault.css';
import '../Hireportalcss/s1200.css';
import '../Hireportalcss/s920.css';
import '../Hireportalcss/s768.css';
import '../Hireportalcss/s600.css';
import '../Hireportalcss/sanimation.css';







function Employerdashboard(){

    return(

        <>
        <div class="default-view">
        <Navbar/>
        {/* MAIN SECTION START */}
        <div class="hire-main-section">
        <Leftsidebar/>
      
        <Mainpanel />

        <Rightsidebar/>

        </div>
        {/* MAIN SECTION END */}

        </div>

        </>
    );




}


export default Employerdashboard;