import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import Navbar from './navbar';



//import '../hireportalcss.css';
//import '../Hireportalcss/hireportalcss.css';

//Importing Page css with screen sizes
import '../Hireportalcss/sdefault.css';
import '../Hireportalcss/s1200.css';
import '../Hireportalcss/s920.css';
import '../Hireportalcss/s768.css';
import '../Hireportalcss/s600.css';
import '../Hireportalcss/sanimation.css';



function Employercreatecompany(){

    return(

    <>
        <div class="default-view">
        <Navbar/>
        {/* MAIN SECTION START */}
        <div class="hire-main-section">

        {/* FORM CONTAINER START */}
        <div class="hire-form-container">
        
        {/* ADD NEW COMPANY FORM START */}
            <div class="hire-form-panel" style={{backgroundColor:"#ffffff"}}>
             {/* ADD NEW COMPANY FORM SECTION 1 START */}
             <div id="add-company-section">
            <h3>Add Company</h3>

            <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Your Name</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Sohan Kumar Verma" /></div>
            </div>
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Hiring for</div>
            <div class="hire-form-group-right"><select class="hire-form-group-input" placeholder="Select Company">
            <option value="">Individual</option>
            <option value="">Business</option>
            <option value="">NGO</option>
            <option value="">Government</option>
            </select>    
            </div>
            </div>
            </div>



            <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Hiring As</div>
            <div class="hire-form-group-right"><select class="hire-form-group-input" placeholder="Select Company">
            <option value="">Employee</option>
            <option value="">Owner</option>
            <option value="">Agency</option>
            </select>    </div>
            </div>
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Display Name</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : ABC Enterprises" />  
            </div>
            </div>
            </div>

            <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Select Company</div>
            <div class="hire-form-group-right"><select class="hire-form-group-input" placeholder="Select Company">
            <option value="">Company ABC</option>
            <option value="">Company XYZ</option>
            <option value="">Company EFG</option>
            </select>    
            </div>
            </div>
            <div class="hire-form-group-small">
            <div class="hire-form-group-right"><button class="hire-btn-default" style={{backgroundColor:"#12d571",marginRight:"2rem",marginTop:"2rem",marginBottom:"2rem"}} onClick={()=>{
                document.getElementById("add-company-section").style.display="none";
                document.getElementById("create-company-section").style.display="block";
            }}>Add Company</button></div>
            </div>

            </div>

            <div class="hire-form-btn">
            <button class="hire-btn-default" style={{backgroundColor:"#d49f13",marginRight:"2rem",marginTop:"2rem",marginBottom:"2rem"}}>Submit for Verification</button>

            </div>
            </div>
            {/* ADD NEW COMPANY FORM SECTION 1 END */}

{/* CREATE COMPANY SECTION START */}
            <div id="create-company-section" style={{display:"none"}}>
            <div id="line-3"></div>
            <h3>Create New Company</h3>

{/* IF CREATE COMPANY BUTTON CLICKED, SHOW BELOW SECTION */}
            <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Business Type</div>
            <div class="hire-form-group-right"><select class="hire-form-group-input" placeholder="Select Company">
            <option value="">Select Business Type</option>
            <option value="">Freelancer</option>
            <option value="">Sole Proprietorship</option>
            <option value="">Partnership</option>
            <option value="">Limited Liability Partnership</option>
            <option value="">One Person Company</option>
            <option value="">Private Limited Company</option>
            <option value="">Public Limited Company</option>
            </select>    </div>
            </div>
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Company Name</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : ABC Enterprises Pvt. Ltd." />  
            </div>
            </div>
            </div>

            <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Role/ Designation</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : HR Manager" />  
            </div>
            </div>
          {/*}  <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Upload Document</div>
            <div class="hire-form-group-right"><input type="file" class="hire-form-group-input" />  
            </div>
            </div> */}
            </div>

{/* Will be Shown Separately for each previous selection */}
{/* Hiring for Individual Ask for Aadhar Card Details etc. to Verified */}
            <h4 style={{marginLeft:"5rem",textAlign:"left"}}>For Employee</h4>
            <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Select Document</div>
            <div class="hire-form-group-right"><select class="hire-form-group-input" placeholder="Select Document">
            <option value="">Select Document</option>
            <option value="">Id Card</option>
            <option value="">Work Email</option>
            <option value="">Letter of Appointment</option>
            </select>   
            </div>
            </div>
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Upload Document</div>
            <div class="hire-form-group-right"><input type="file" class="hire-form-group-input" />  
            </div>
            </div>
            </div>
            <h4 style={{marginLeft:"5rem",textAlign:"left"}}>For Owner</h4>
            <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Select Document</div>
            <div class="hire-form-group-right"><select class="hire-form-group-input" placeholder="Select Document">
            <option value="">Select Document</option>
            <option value="">Company Incorporation Certificate</option>
            <option value="">Company PAN</option>
            <option value="">Company MSME</option>
            </select>    
            </div>
            </div>
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Upload Document</div>
            <div class="hire-form-group-right"><input type="file" class="hire-form-group-input" />  
            </div>
            </div>
            </div>

            <h4 style={{marginLeft:"5rem",textAlign:"left"}}>For Agency</h4>
            <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Select Document</div>
            <div class="hire-form-group-right"><select class="hire-form-group-input" placeholder="Select Document">
            <option value="">Select Document</option>
            <option value="">Letter of Appointment</option>
            <option value="">Work Authorization Letter</option>
            </select>   
            </div>            </div>
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Upload Document</div>
            <div class="hire-form-group-right"><input type="file" class="hire-form-group-input" />  
            </div>
            </div>
            </div>

            <h4 style={{marginLeft:"5rem",textAlign:"left"}}>For Individual/ Freelancer</h4>
            <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Select Document</div>
            <div class="hire-form-group-right"><select class="hire-form-group-input" placeholder="Select Company">
            <option value="">National Id</option>
            <option value="">Aadhar Card</option>
            <option value="">Pan Card</option>
            </select>    </div>
            </div>
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Upload Document</div>
            <div class="hire-form-group-right"><input type="file" class="hire-form-group-input" />  
            </div>
            </div>
            </div>

            <div id="line-3"></div>
            <h3>Add Company Introduction</h3>

            <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Mission</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Mission Statement" />  </div>
            </div>
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label">Vision</label></div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Mission Statement" />  
            </div>
            </div>
            </div>

            <div class="hire-form-list">
            <div class="hire-form-group-large">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Company Introduction</div>
            <div class="hire-form-group-right"><textarea size="5" class="hire-form-group-input" placeholder="For e.g. :" ></textarea>
            </div>
            </div>
            </div>

            <div class="hire-form-list">
            <div class="hire-form-group-large">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Why Join Us</div>
            <div class="hire-form-group-right"><textarea size="5" class="hire-form-group-input" placeholder="For e.g. :" ></textarea>
            </div>
            </div>
            </div>

            <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Website</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : https://www.mywebsite.com" />  </div>
            </div>
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Date of Incorporation</div>
            <div class="hire-form-group-right"><input type="date" class="hire-form-group-input"/>  
            </div>
            </div>
            </div>


            <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Industry</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Marketing" />  </div>
            </div>
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Sector</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Digital Marketing" />  
            </div>
            </div>
            </div>

            <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Category</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Marketing" />  </div>
            </div>
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Sub Category</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Digital Marketing" />  
            </div>
            </div>
            </div>

            <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Street Address 1</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : 24/A North Block" /></div>
            </div>
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Street Address 2</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Indira Market Road" /> 
            </div>
            </div>
            </div>

            <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>City</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Jabalpur" /></div>
            </div>
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>State</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Madhya Pradesh" /> 
            </div>
            </div>
            </div>

            <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Country</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : India" /></div>
            </div>
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Pincode</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : 482002" /> 
            </div>
            </div>
            </div>

            <div class="hire-form-btn">
            <button class="hire-btn-default" style={{backgroundColor:"#d49f13",marginRight:"2rem",marginTop:"2rem",marginBottom:"2rem"}}
            onClick={()=>{ 
                document.getElementById("add-company-section").style.display="block";
                document.getElementById("create-company-section").style.display="none";
             }}
            >Submit for Verification</button>

            </div>



{/* IF CREATE COMPANY BUTTON CLICKED, SHOW ABOVE SECTION */}
            </div>
            {/* CREATE COMPANY SECTION END */}


          
        </div>
        {/* ADD NEW COMPANY FORM END */}


        </div>
        {/* FORM CONTAINER END */}

        </div>
        {/* MAIN SECTION END */}

        </div>

        </>
    );




}


export default Employercreatecompany;