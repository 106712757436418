
import React from 'react';
import {useState,useEffect,Component} from 'react';


function Privacypolicy(){

return(
<>
<html>
	<head>
		<title>Privacy Policy</title>
	</head>
	<body>
        <div style={{fontSize:"1rem",textAlign:"left",padding:"2rem"}}>
        <h2 style={{textAlign: "center"}}><b>PRIVACY POLICY</b></h2>
			<br/>
			<br/>
<p>Last updated : 2023-07-07</p>
			<br/>
<b>``This Privacy Policy applies to the thestartupconnect.com</b>
<br/>
			<br/>
Thestartupconnect.com recognises the importance of maintaining your privacy. We value your privacy and appreciate your trust in us. This Policy describes how we treat user information we collect on our website and other offline sources. This Privacy Policy applies to current and former visitors to our website and to our online customers. By visiting and/or using our website, you agree to this Privacy Policy.
			<br/>
Thestartupconnect.com is a property of Technomate Startup Services Private Limited, an Indian Company registered under the Companies Act, 2013 having its registered office at LIG C-5, 90 quarter, Sanjeevani Nagar, Jabalpur (M.P) 482003
			<br/>
			<br/>
<b>INFORMATION WE COLLECT</b>
<br/>
			<br/>
For a better experience while using our Services or our website, we may require you to provide us with certain personally identifiable information, including but not limited to your name, phone number, and postal address. The information that we collect will be used to contact or identify you. However, you may be assured that it will only be used according to this privacy statement. 
			<br/>
			<br/>
<b>WHAT INFORMATION MAY WE COLLECT?</b>
<br/>
<br/>
			<br/>
•	Your name and job title.
			<br/>
•	Your contact information including, Phone Number and email address.
			<br/>
•	Your demographic information such as postcode, preferences and interests
			<br/>
			<br/>
<b>WE COLLECT INFORMATION IN DIFFERENT WAYS.</b>
<br/>
<br/>
			<br/>
<b>We collect information directly from you. </b>We collect information directly from you when you register for an event or buy tickets. We also collect information if you post a comment on our websites or ask us a question through phone or email.
			<br/><br/>
<b>We collect information from you passively.</b> We use tracking tools like Google Analytics, Google Webmaster, browser cookies and web beacons for collecting information about your usage of our website. 
			<br/><br/>
<b>We get information about you from third parties.</b> For example, if you use an integrated social media feature on our websites. The third-party social media site will give us certain information about you. This could include your name and email address.
			<br/>
			<br/>
			<br/>
<b>USE OF YOUR PERSONAL INFORMATION</b>
			<br/><br/>
<b>We use information to contact you:</b> We might use the information you provide to contact you for confirmation of a purchase on our website or for other promotional purposes.
			<br/>
            <br/>
<b>We use information to respond to your requests or questions.</b> We might use your information to confirm your registration for an event or contest. 
			<br/>
            <br/>
<b>We use information to improve our products and services.</b> We might use your information to customize your experience with us. This could include displaying content based upon your preferences.
			<br/>
            <br/>
<b>We use information to look at site trends and customer interests. </b>We may use your information to make our website and products better. We may combine information we get from you with information about you we get from third parties.
			<br/>
            <br/>
<b>We use information for security purposes.</b> We may use information to protect our company, our customers, or our websites.
			<br/>
            <br/>
<b>We use information for marketing purposes.</b> We might send you information about special promotions or offers. We might also tell you about new features or products. These might be our own offers or products, or third-party offers or products we think you might find interesting. Or, for example, if you buy tickets from us we'll enroll you in our newsletter. 
			<br/>
            <br/>
<b>We use information to send you transactional communications.</b> We might send you emails or SMS about your account or a ticket purchase. 
			<br/>
            <br/>
We use information as otherwise permitted by law.
			<br/>
			<br/>
<b>SHARING OF INFORMATION WITH THIRD PARTIES</b>
<br/>
			<br/>
<b>We will share information with third parties who perform services on our behalf.</b> We share information with vendors who help us manage our online registration process or payment processors or transactional message processors. Some vendors may be located outside of India.
			<br/>
            <br/>
<b>We will share information with the event organizers.</b> We share your information with event organizers and other parties responsible for fulfilling the purchase obligation. The event organizers and other parties may use the information we give them as described in their privacy policies. 
			<br/>
            <br/>
<b>We will share information with our business partners. </b>This includes a third party who provide or sponsor an event, or who operates a venue where we hold events. Our partners use the information we give them as described in their privacy policies. 
			<br/>
            <br/>
<b>We may share information if we think we have to in order to comply with the law or to protect ourselves.</b> We will share information to respond to a court order or subpoena. We may also share it if a government agency or investigatory body requests. Or, we might also share information when we are investigating potential fraud. 
			<br/>
            <br/>
<b>We may share information with any successor to all or part of our business.</b> For example, if part of our business is sold we may give our customer list as part of that transaction.
			<br/>
            <br/>
<b>We may share your information for reasons not described in this policy.</b> We will tell you before we do this.
			<br/>
			<br/>
            <br/>
<b>UPDATES TO THIS POLICY</b>
<br/>
			<br/>
We, at thestartupconnect.com may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately, after they are posted on this page.
			<br/>
			<br/>
            <br/>
<b>Note: -</b> Any dispute arising from or in connection with the Privacy Policy shall be settled through friendly negotiation. In case no settlement can be reached, the dispute shall be then submitted as per Dispute Resolution Clause mentioned under this agreement.*
			<br/>
			<br/>
            <br/>
<b>DISPUTE RESOLUTION/JURISDICTION</b>
<br/>
			<br/>
If you choose to visit the website, your visit and any dispute over privacy is subject to this Policy and the website's terms of use. The Parties agree that any and all disputes between the Parties under or relating to the terms and conditions of this Policy shall be fully and completely adjudicated in the courts of Jabalpur, Madhya Pradesh.
			<br/>
			<br/>
			<br/>
<b>CONTACT US</b>
			<br/>
			<br/>
Please send your feedback, comments, requests for technical support by email: help.startupconnect@gmail.com.
			<br/>
			<br/>
			<br/>
			<br/>
		
    </div>
	</body>
</html>
</>
);

}

export default Privacypolicy;