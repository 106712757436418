import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';
import moment from 'moment/moment';

import '../Resources/dashboard.css';
import Navbar from '../navbar';
import Sidebar from '../Sidebar';


import Chart from "chart.js/auto";
import { Line, Pie } from "react-chartjs-2";

import {useRef as useref} from 'react';
import {useReactToPrint} from 'react-to-print';

//EXCEL DOWNLOAD IMPORT
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

function UserSgst(){



 //Report Print Function
 const componentRef = useref();
 const handlePrint = useReactToPrint({
   content: () => componentRef.current,
 });

 //Report Print Function 1
 const componentRef1 = useref();
 const handlePrint1 = useReactToPrint({
   content: () => componentRef1.current,
 });

 
   // Function to download the data as an Excel file
   const downloadExcel = () => {
    // Convert JSON data to Excel worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);
    
    // Create a new workbook and add the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    
    // Convert the workbook to a binary array
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    
    // Create a Blob from the binary data and trigger a download
    const excel_data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(excel_data, "gstandtax_data.xlsx");
  };
  
  
  // Function to download the data as an Excel file
  const downloadExcel1 = () => {
    // Convert JSON data to Excel worksheet
    const worksheet1 = XLSX.utils.json_to_sheet(summary);
    
    // Create a new workbook and add the worksheet
    const workbook1 = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook1, worksheet1, "Sheet1");
    
    // Convert the workbook to a binary array
    const excelBuffer1 = XLSX.write(workbook1, { bookType: "xlsx", type: "array" });
    
    // Create a Blob from the binary data and trigger a download
    const excel_data1 = new Blob([excelBuffer1], { type: "application/octet-stream" });
    saveAs(excel_data1, "gstandtaxsummary_data.xlsx");
  };
  
 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const busyid=sessionStorage.getItem("BusyId00");
 const counterid=sessionStorage.getItem("CounterId00");

 

 
{/* SGST TRANSACTIONS TABLE COLUMNS MANAGEMENT VARIABLES START */}
const[cd_col1_val,setcd_col1_val] = useState(0);//column1
const[cd_col2_val,setcd_col2_val] = useState(0);//column2
const[cd_col3_val,setcd_col3_val] = useState(0);//column3
const[cd_col4_val,setcd_col4_val] = useState(0);//column4
const[cd_col5_val,setcd_col5_val] = useState(0);//column5
const[cd_col6_val,setcd_col6_val] = useState(0);//column6
const[cd_col7_val,setcd_col7_val] = useState(0);//column7
const[cd_col8_val,setcd_col8_val] = useState(0);//column8
const[cd_col9_val,setcd_col9_val] = useState(0);//column9
const[cd_col10_val,setcd_col10_val] = useState(0);//column10
const[cd_col11_val,setcd_col11_val] = useState(0);//column11
const[cd_col12_val,setcd_col12_val] = useState(0);//column12
const[cd_col13_val,setcd_col13_val] = useState(0);//column13

{/* SGST TRANSACTIONS TABLE COLUMNS MANAGEMENT VARIABLES END */}

 //Checking if Sessions are Set

// alert(logstatus,loguser,logadminid);
 //Sessions Not Set
/* if(((logstatus==null)||(logstatus==""))||((logid==null)||(logid==""))||((token==null)||(token==" "))||((busyid==null)||(busyid==" "))||((counterid==null)||(counterid==" "))){
  
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");
   
 
    window.location.replace("/businesstools-login");
   }
  
   //Sessions Set
   else{
*/


    
      
//Variables
const[summary,setsummary] = useState([]);//SetData Variable
const[data,setData] = useState([]);//SetData Variable

//Error Variable
const[errorvar,seterrorvar] = useState();



//FILTER VARIABLES
const[filter_val,setfilter_val] = useState(0);
const[filter_start_date,setfilter_start_date] = useState('1970-01-01'); const updatefilter_start_date = (event) => { setfilter_start_date(event.target.value);}; const[filter_start_date_er,setfilter_start_date_er]=useState();
const[filter_end_date,setfilter_end_date] = useState('2100-12-12'); const updatefilter_end_date = (event) => { setfilter_end_date(event.target.value);}; const[filter_end_date_er,setfilter_end_date_er]=useState();
const[filter_trno,setfilter_trno] = useState(); const updatefilter_trno = (event) => { setfilter_trno(event.target.value);}; const[filter_trno_er,setfilter_trno_er]=useState();
const[filter_transaction,setfilter_transaction] = useState(); const updatefilter_transaction = (event) => { setfilter_transaction(event.target.value);}; const[filter_transaction_er,setfilter_transaction_er]=useState();
const[filter_amount_sign,setfilter_amount_sign] = useState(); const updatefilter_amount_sign = (event) => { setfilter_amount_sign(event.target.value);}; const[filter_amount_sign_er,setfilter_amount_sign_er]=useState();
const[filter_amount,setfilter_amount] = useState(); const updatefilter_amount = (event) => { setfilter_amount(event.target.value);}; const[filter_amount_er,setfilter_amount_er]=useState();
const[filter_voucher_no,setfilter_voucher_no] = useState(); const updatefilter_voucher_no = (event) => { setfilter_voucher_no(event.target.value);}; const[filter_voucher_no_er,setfilter_voucher_no_er]=useState();
const[filter_gstin,setfilter_gstin] = useState(); const updatefilter_gstin = (event) => { setfilter_gstin(event.target.value);}; const[filter_gstin_er,setfilter_gstin_er]=useState();
const[filter_sgst_per,setfilter_sgst_per] = useState(); const updatefilter_sgst_per = (event) => { setfilter_sgst_per(event.target.value);}; const[filter_sgst_per_er,setfilter_sgst_per_er]=useState();
const[filter_sgst_in_sign,setfilter_sgst_in_sign] = useState(); const updatefilter_sgst_in_sign = (event) => { setfilter_sgst_in_sign(event.target.value);}; const[filter_sgst_in_sign_er,setfilter_sgst_in_sign_er]=useState();
const[filter_sgst_in,setfilter_sgst_in] = useState(); const updatefilter_sgst_in = (event) => { setfilter_sgst_in(event.target.value);}; const[filter_sgst_in_er,setfilter_sgst_in_er]=useState();
const[filter_sgst_out_sign,setfilter_sgst_out_sign] = useState(); const updatefilter_sgst_out_sign = (event) => { setfilter_sgst_out_sign(event.target.value);}; const[filter_sgst_out_sign_er,setfilter_sgst_out_sign_er]=useState();
const[filter_sgst_out,setfilter_sgst_out] = useState(); const updatefilter_sgst_out = (event) => { setfilter_sgst_out(event.target.value);}; const[filter_sgst_out_er,setfilter_sgst_out_er]=useState();
const[filter_cgst_per,setfilter_cgst_per] = useState(); const updatefilter_cgst_per = (event) => { setfilter_cgst_per(event.target.value);}; const[filter_cgst_per_er,setfilter_cgst_per_er]=useState();
const[filter_cgst_in_sign,setfilter_cgst_in_sign] = useState(); const updatefilter_cgst_in_sign = (event) => { setfilter_cgst_in_sign(event.target.value);}; const[filter_cgst_in_sign_er,setfilter_cgst_in_sign_er]=useState();
const[filter_cgst_in,setfilter_cgst_in] = useState(); const updatefilter_cgst_in = (event) => { setfilter_cgst_in(event.target.value);}; const[filter_cgst_in_er,setfilter_cgst_in_er]=useState();
const[filter_cgst_out_sign,setfilter_cgst_out_sign] = useState(); const updatefilter_cgst_out_sign = (event) => { setfilter_cgst_out_sign(event.target.value);}; const[filter_cgst_out_sign_er,setfilter_cgst_out_sign_er]=useState();
const[filter_cgst_out,setfilter_cgst_out] = useState(); const updatefilter_cgst_out = (event) => { setfilter_cgst_out(event.target.value);}; const[filter_cgst_out_er,setfilter_cgst_out_er]=useState();
const[filter_igst_per,setfilter_igst_per] = useState(); const updatefilter_igst_per = (event) => { setfilter_igst_per(event.target.value);}; const[filter_igst_per_er,setfilter_igst_per_er]=useState();
const[filter_igst_in_sign,setfilter_igst_in_sign] = useState(); const updatefilter_igst_in_sign = (event) => { setfilter_igst_in_sign(event.target.value);}; const[filter_igst_in_sign_er,setfilter_igst_in_sign_er]=useState();
const[filter_igst_in,setfilter_igst_in] = useState(); const updatefilter_igst_in = (event) => { setfilter_igst_in(event.target.value);}; const[filter_igst_in_er,setfilter_igst_in_er]=useState();
const[filter_igst_out_sign,setfilter_igst_out_sign] = useState(); const updatefilter_igst_out_sign = (event) => { setfilter_igst_out_sign(event.target.value);}; const[filter_igst_out_sign_er,setfilter_igst_out_sign_er]=useState();
const[filter_igst_out,setfilter_igst_out] = useState(); const updatefilter_igst_out = (event) => { setfilter_igst_out(event.target.value);}; const[filter_igst_out_er,setfilter_igst_out_er]=useState();
const[filter_tax_per,setfilter_tax_per] = useState(); const updatefilter_tax_per = (event) => { setfilter_tax_per(event.target.value);}; const[filter_tax_per_er,setfilter_tax_per_er]=useState();
const[filter_tax_in_sign,setfilter_tax_in_sign] = useState(); const updatefilter_tax_in_sign = (event) => { setfilter_tax_in_sign(event.target.value);}; const[filter_tax_in_sign_er,setfilter_tax_in_sign_er]=useState();
const[filter_tax_in,setfilter_tax_in] = useState(); const updatefilter_tax_in = (event) => { setfilter_tax_in(event.target.value);}; const[filter_tax_in_er,setfilter_tax_in_er]=useState();
const[filter_tax_out_sign,setfilter_tax_out_sign] = useState(); const updatefilter_tax_out_sign = (event) => { setfilter_tax_out_sign(event.target.value);}; const[filter_tax_out_sign_er,setfilter_tax_out_sign_er]=useState();
const[filter_tax_out,setfilter_tax_out] = useState(); const updatefilter_tax_out = (event) => { setfilter_tax_out(event.target.value);}; const[filter_tax_out_er,setfilter_tax_out_er]=useState();

//PART 2 FILTER VARIABLES
const[filter_val1,setfilter_val1] = useState(0);
const[filter_start_date1,setfilter_start_date1] = useState('1970-01-01'); const updatefilter_start_date1 = (event) => { setfilter_start_date1(event.target.value);}; const[filter_start_date1_er,setfilter_start_date1_er]=useState();
const[filter_end_date1,setfilter_end_date1] = useState('2100-12-12'); const updatefilter_end_date1 = (event) => { setfilter_end_date1(event.target.value);}; const[filter_end_date1_er,setfilter_end_date1_er]=useState();
const[filter_gstin1,setfilter_gstin1] = useState(); const updatefilter_gstin1 = (event) => { setfilter_gstin1(event.target.value);}; const[filter_gstin1_er,setfilter_gstin1_er]=useState();

//Variables for pagination
const [currentPage, setCurrentPage] = useState(1);
const [pageNumber, setpageNumber] = useState(1);
    

useEffect(()=>{
   
  getData();
  
  },[])


  
  
//SETTING DEFAULT STATE VARIABLES FOR CHART */
 
 //LINECHART VARIABLES
 const[lc_summary,setlc_summary] = useState([],);
 const[lc_inflow,setlc_inflow] = useState([],);
 const[lc_outflow,setlc_outflow] = useState([],);
 const[lc_balance,setlc_balance] = useState([],);

 //Line Chart
 const[lc_date,setlc_date] = useState();
 const[lc_default,setlc_default] = useState(0);
 const[lc_start_date,setlc_start_date] = useState(0);const updatelc_start_date = (event) => { setlc_start_date(event.target.value); }; 
 const[lc_end_date,setlc_end_date] = useState(0);const updatelc_end_date = (event) => { setlc_end_date(event.target.value); }; 
 const[lc_date_error,setlc_date_error] = useState();

 //Pie Chart
 const[pc_default,setpc_default] = useState(0);
 const[pc_start_date,setpc_start_date] = useState(0);const updatepc_start_date = (event) => { setpc_start_date(event.target.value); }; 
 const[pc_end_date,setpc_end_date] = useState(0);const updatepc_end_date = (event) => { setpc_end_date(event.target.value); }; 
 const[pc_date_error,setpc_date_error] = useState();
 const[pc_inflow,setpc_inflow] = useState();
 const[pc_outflow,setpc_outflow] = useState();
 const[pc_balance,setpc_balance] = useState();



 
    
  
//******************* GET GSTANDTAX DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))){
 
 

  //Fetching Filter Variables
  const $filter_start_date = filter_start_date;const $filter_end_date = filter_end_date;const $filter_trno = filter_trno;const $filter_transaction = filter_transaction;
  const $filter_amount_sign = filter_amount_sign;const $filter_amount = filter_amount; const $filter_voucher_no = filter_voucher_no;const $filter_gstin = filter_gstin;
  const $filter_sgst_per = filter_sgst_per;const $filter_sgst_in_sign = filter_sgst_in_sign;const $filter_sgst_in = filter_sgst_in; const $filter_sgst_out_sign = filter_sgst_out_sign;const $filter_sgst_out = filter_sgst_out;
  const $filter_cgst_per = filter_cgst_per;const $filter_cgst_in_sign = filter_cgst_in_sign;const $filter_cgst_in = filter_cgst_in; const $filter_cgst_out_sign = filter_cgst_out_sign;const $filter_cgst_out = filter_cgst_out;
  const $filter_igst_per = filter_igst_per;const $filter_igst_in_sign = filter_igst_in_sign;const $filter_igst_in = filter_igst_in; const $filter_igst_out_sign = filter_igst_out_sign;const $filter_igst_out = filter_igst_out;
  const $filter_tax_per = filter_tax_per;const $filter_tax_in_sign = filter_tax_in_sign;const $filter_tax_in = filter_tax_in; const $filter_tax_out_sign = filter_tax_out_sign;const $filter_tax_out = filter_tax_out;
  const $filter_gstin1 = filter_gstin1; const $filter_start_date1 = filter_start_date1;const $filter_end_date1 = filter_end_date1
   

 axios.post("bt-get-gstandtax-default", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Sn_start :0,
   Sn_end :0,
   Sn_total :0,
   Sn_limit :0,
  //Line Chart
  lc_default:lc_default,
  lc_start_date:moment(lc_start_date).format('YYYY-MM-DD'),
  lc_end_date:moment(lc_end_date).format('YYYY-MM-DD'),
  //Bar Chart
  pc_default:pc_default,
  pc_start_date:moment(pc_start_date).format('YYYY-MM-DD'),
  pc_end_date:moment(pc_end_date).format('YYYY-MM-DD'),
  //Filters
  Filter_val : filter_val,
  Filter_start_date : moment($filter_start_date).format('YYYY-MM-DD'),
  Filter_end_date : moment($filter_end_date).format('YYYY-MM-DD'),
  Filter_trno : $filter_trno,
  Filter_transaction : $filter_transaction,
  Filter_amount_sign : $filter_amount_sign,
  Filter_amount : $filter_amount,
  Filter_voucher_no : $filter_voucher_no,
  Filter_gstin : $filter_gstin,
  Filter_sgst_per : $filter_sgst_per,
  Filter_sgst_in_sign : $filter_sgst_in_sign,
  Filter_sgst_in : $filter_sgst_in,
  Filter_sgst_out_sign : $filter_sgst_out_sign,
  Filter_sgst_out : $filter_sgst_out,
  Filter_cgst_per : $filter_cgst_per,
  Filter_cgst_in_sign : $filter_cgst_in_sign,
  Filter_cgst_in : $filter_cgst_in,
  Filter_cgst_out_sign : $filter_cgst_out_sign,
  Filter_cgst_out : $filter_cgst_out,
  Filter_igst_per : $filter_igst_per,
  Filter_igst_in_sign : $filter_igst_in_sign,
  Filter_igst_in : $filter_igst_in,
  Filter_igst_out_sign : $filter_igst_out_sign,
  Filter_igst_out : $filter_igst_out,
  Filter_tax_per : $filter_tax_per,
  Filter_tax_in_sign : $filter_tax_in_sign,
  Filter_tax_in : $filter_tax_in,
  Filter_tax_out_sign : $filter_tax_out_sign,
  Filter_tax_out : $filter_tax_out,
  Filter_val1 : filter_val1,
  Filter_start_date1 : moment($filter_start_date1).format('YYYY-MM-DD'),
  Filter_end_date1 : moment($filter_end_date1).format('YYYY-MM-DD'),
  Filter_gstin1 : $filter_gstin1,
  
   //Code Edits for pagination
  'page' : pageNumber

 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setsummary(response.data.summary.data);
   setData(response.data.data.data);
   const lc_summary = response.data.lc_summary;
   // Extract data for line chart
   setlc_date(lc_summary.map((entry) => entry.Date));
   setlc_inflow(lc_summary.map((entry) => entry.Sgst_inflow));
   setlc_outflow(lc_summary.map((entry) => entry.Sgst_outflow)); 
   setlc_balance(lc_summary.map((entry) => entry.Sgst_balance)); 
   //extract data for bar chart
   setpc_inflow(response.data.pc_sgst_inflow);
   setpc_outflow(response.data.pc_sgst_outflow);
   setpc_balance(response.data.pc_sgst_balance);
  
  }
   //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){
    setfilter_start_date_er(response.data.validatorerror.Filter_start_date);
    setfilter_end_date_er(response.data.validatorerror.Filter_end_date);
    setfilter_trno_er(response.data.validatorerror.Filter_trno);
    setfilter_transaction_er(response.data.validatorerror.Filter_transaction);
    setfilter_voucher_no_er(response.data.validatorerror.Filter_voucher_no);
    setfilter_gstin_er(response.data.validatorerror.Filter_gstin);
    setfilter_amount_sign_er(response.data.validatorerror.Filter_amount_sign);
    setfilter_amount_er(response.data.validatorerror.Filter_amount);
    setfilter_sgst_per_er(response.data.validatorerror.Filter_sgst_per);
    setfilter_sgst_in_sign_er(response.data.validatorerror.Filter_sgst_in_sign);
    setfilter_sgst_in_er(response.data.validatorerror.Filter_sgst_in);
    setfilter_sgst_out_sign_er(response.data.validatorerror.Filter_sgst_out_sign);
    setfilter_sgst_out_er(response.data.validatorerror.Filter_sgst_out);
    setfilter_cgst_per_er(response.data.validatorerror.Filter_cgst_per);
    setfilter_cgst_in_sign_er(response.data.validatorerror.Filter_cgst_in_sign);
    setfilter_cgst_in_er(response.data.validatorerror.Filter_cgst_in);
    setfilter_cgst_out_sign_er(response.data.validatorerror.Filter_cgst_out_sign);
    setfilter_cgst_out_er(response.data.validatorerror.Filter_cgst_out);
    setfilter_igst_per_er(response.data.validatorerror.Filter_igst_per);
    setfilter_igst_in_sign_er(response.data.validatorerror.Filter_igst_in_sign);
    setfilter_igst_in_er(response.data.validatorerror.Filter_igst_in);
    setfilter_igst_out_sign_er(response.data.validatorerror.Filter_igst_out_sign);
    setfilter_igst_out_er(response.data.validatorerror.Filter_igst_out);
    setfilter_tax_per_er(response.data.validatorerror.Filter_tax_per);
    setfilter_tax_in_sign_er(response.data.validatorerror.Filter_tax_in_sign);
    setfilter_tax_in_er(response.data.validatorerror.Filter_tax_in);
    setfilter_tax_out_sign_er(response.data.validatorerror.Filter_tax_out_sign);
    setfilter_tax_out_er(response.data.validatorerror.Filter_tax_out);
    setfilter_start_date1_er(response.data.validatorerror.Filter_start_date1);
    setfilter_end_date1_er(response.data.validatorerror.Filter_end_date1);
    setfilter_gstin1_er(response.data.validatorerror.Filter_gstin1);
  }
  
  //Data Violation
  if(response.data.error==102){ 
  
    setfilter_start_date_er(response.data.filter_start_date_er);
    setfilter_end_date_er(response.data.filter_end_date_er);
    setfilter_trno_er(response.data.filter_trno_er);
    setfilter_transaction_er(response.data.filter_transaction_er);
    setfilter_voucher_no_er(response.data.filter_voucher_no_er);
    setfilter_gstin_er(response.data.filter_gstin_er);
    setfilter_amount_sign_er(response.data.filter_amount_sign_er);
    setfilter_amount_er(response.data.filter_amount_er);
    setfilter_sgst_per_er(response.data.filter_sgst_per_er);
    setfilter_sgst_in_sign_er(response.data.filter_sgst_in_sign_er);
    setfilter_sgst_in_er(response.data.filter_sgst_in_er);
    setfilter_sgst_out_sign_er(response.data.filter_sgst_out_sign_er);
    setfilter_sgst_out_er(response.data.filter_sgst_out_er);
    setfilter_cgst_per_er(response.data.filter_cgst_per_er);
    setfilter_cgst_in_sign_er(response.data.filter_cgst_in_sign_er);
    setfilter_cgst_in_er(response.data.filter_cgst_in_er);
    setfilter_cgst_out_sign_er(response.data.filter_cgst_out_sign_er);
    setfilter_cgst_out_er(response.data.filter_cgst_out_er);
    setfilter_igst_per_er(response.data.filter_igst_per_er);
    setfilter_igst_in_sign_er(response.data.filter_igst_in_sign_er);
    setfilter_igst_in_er(response.data.filter_igst_in_er);
    setfilter_igst_out_sign_er(response.data.filter_igst_out_sign_er);
    setfilter_igst_out_er(response.data.filter_igst_out_er);
    setfilter_tax_per_er(response.data.filter_tax_per_er);
    setfilter_tax_in_sign_er(response.data.filter_tax_in_sign_er);
    setfilter_tax_in_er(response.data.filter_tax_in_er);
    setfilter_tax_out_sign_er(response.data.filter_tax_out_sign_er);
    setfilter_tax_out_er(response.data.filter_tax_out_er);
    setfilter_start_date1_er(response.data.filter_start_date1_er);
    setfilter_end_date1_er(response.data.filter_end_date1_er);
    setfilter_gstin1_er(response.data.filter_gstin1_er);

  }
  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* GET GSTANDTAX DATA FUNCTION END  ********************//



 
 /* LINE CHART START */
 const data1 = {
  labels: lc_date,
  datasets: [
    {
      label: "Sgst Collected",
      backgroundColor: ["rgb(255, 99, 132)" ],
      borderColor: "rgb(255, 99, 132)",
      data: lc_inflow,
    },
    {
      label: "Sgst Paid",
      backgroundColor: ["rgb(54, 160, 255)"],
      borderColor: "rgb(54, 160, 255)",
      data: lc_outflow,
    },
    {
      label: "Sgst Balance",
      backgroundColor: ["#1da15b"],
      borderColor: "#1da15b",
      data: lc_balance,
    },
  ],
};
/* LINE CHART END */ 

  /* PIE CHART VARIABLES START */
const data2 = {
  labels: ["sgst collected", "sgst paid", "sgst balance"],
  datasets: [
    {
      label: "# transactions summary",
      data: [pc_inflow, pc_outflow, pc_balance],
      backgroundColor: [ "#3fe077", "#0b3190", "#d76721"],
      borderColor: [ "#ffffff", "#ffffff", "#ffffff"],
      borderWidth: 1,
    },
  ],
};
  /* PIE CHART VARIABLES END */


return(
    <>
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Business Tools|BookKeeping Solution</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div class="container-scroller">

      <Sidebar />
    <div class="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div class="main-panel">
          <div class="content-wrapper pb-0">
            <div class="page-header flex-wrap">
            <div class="header-left"></div>
              <div class="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div class="d-flex align-items-center">
                  <a href="#">
                    <p class="m-0 pe-3">SGST</p>
                  </a>
                  <a class="ps-3 me-4" href="#">
                  {/*}  <p class="m-0">ADE-00234</p> */}
                  </a>
                </div>
              </div>
            </div>

         
{/*} chart row starts here */}
<div class="row">
                       {/* LINECHART START */}
                       <div className="col-sm-8 stretch-card grid-margin">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                    <div className="card-title"> SGST Summary
                      </div>                  
                      <div className="d-flex text-muted font-20">
                        <i className="mdi mdi-arrow-down-drop-circle text-primary" onClick={()=>{ document.getElementById("lc-date-search-box").style.display="block"; }}></i>
                        <i className="mdi mdi-close-box text-danger"  onClick={()=>{ document.getElementById("lc-date-search-box").style.display="none"; }}></i>                     
                      </div>
                    </div>
                    {/* LINE CHART DATE FILTER START */}
                    <div id="lc-date-search-box" style={{display:"none"}}>
                    <hr />
                    <p style={{marginTop:"2rem"}}>Make sure start date and end date are not more than 10 days apart for best results</p>
                    <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label"> Start Date</label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control-sm"  id="lc_start_date" value={lc_start_date} onChange={updatelc_start_date} />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">End Date</label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control-sm" id="lc_end_date" value={lc_end_date} onChange={updatelc_end_date} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <button class="btn btn-primary mb-4" onClick={()=>{ setlc_default(1); getData(); }}>Update</button>
                      <hr/>
                      </div>
                      {/* LINE CHART DATE FILTER END */}
                    <div className="line-chart-wrapper">
                  {/* CASH INFLOW OUTFLOW LINE CHART START */}
                  <Line data={data1} /> 
                  {/* CASH INFLOW OUTFLOW LINE CHART END */}                   
                    </div>
                  </div>
                </div>
              </div>
               {/* LINE CHART END */}
               {/* PIE CHART START */}
              <div class="col-sm-4 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      <div class="card-title"> SGST Summary
                      </div>
                      <div class="d-flex text-muted font-20">
                        <i className="mdi mdi-arrow-down-drop-circle text-primary" onClick={()=>{ document.getElementById("pc-date-search-box").style.display="block"; }}></i>
                        <i className="mdi mdi-close-box text-danger"  onClick={()=>{ document.getElementById("pc-date-search-box").style.display="none"; }}></i>
                      
                      </div>
                    </div>
                        {/*BAR CHART DATE FILTER START */}
                        <div id="pc-date-search-box" style={{display:"none"}}>
                    <hr />
                    <div className="row">
                        <div className="col-md-12">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label"> Start Date</label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control-sm"  id="pc_start_date" value={pc_start_date} onChange={updatepc_start_date} />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">End Date</label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control-sm" id="pc_end_date" value={pc_end_date} onChange={updatepc_end_date} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <button class="btn btn-primary mb-4" onClick={()=>{ setpc_default(1); getData(); }}>Update</button>
                      <hr/>
                      </div>
                      {/* BAR CHART DATE FILTER END */}
                       
                    
                   
             
                    <div class="bar-chart-wrapper">
                    <Pie data={data2} style={{width:"600px",height:"400px"}} maintainAspectRatio={false} />
                    </div>
                  </div>
                </div>
              </div>
               {/* PIE CHART END */}
        
            </div>
                  
             <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">SGST Transactions</h4>
                    <p class="card-description">sgst past transaction details
                    </p>

                            {/* FILTER SECTION START */}
                            <hr/>
                            <div id="filter-top-card" class="row col-12" style={{textAlign:"left",display:"none"}}>
                            <div class="d-flex justify-content-between">
                            <div>
                            <h4 style={{padding:'1rem'}}>Filters</h4>
                           </div>
                           <div>
                    
                           <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("filter-top-card").style.display="none"; }}>
                           <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                           </button>
                           </div>
                           </div>
        
                        <div class="row">
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Start Date</label>
                            <div class="col-sm-8">
                              <input type="date" class="form-control" id="filter_start_date" value={filter_start_date} onChange={updatefilter_start_date}  />
                              <code>{filter_start_date_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">End Date</label>
                            <div class="col-sm-8">
                              <input type="date" class="form-control"  id="filter_end_date" value={filter_end_date} onChange={updatefilter_end_date}  />
                              <code>{filter_end_date_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Tr No.</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" placeholder="12345"   id="filter_trno" value={filter_trno} onChange={updatefilter_trno}  />
                              <code>{filter_trno_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Voucher No.</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" placeholder="VN123456" id="filter_voucher_no" value={filter_voucher_no} onChange={updatefilter_voucher_no}  />
                              <code>{filter_voucher_no_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">GSTIN</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" placeholder="GST838833333"  id="filter_gstin" value={filter_gstin} onChange={updatefilter_gstin}  />
                              <code>{filter_gstin_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">SGST %</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" placeholder="5.00"   id="filter_sgst_per" value={filter_sgst_per} onChange={updatefilter_sgst_per}  />
                              <code>{filter_sgst_per_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-8">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Taxable Amount</label>
                            <div class="col-sm-4">
                              <select class="form-control" style={{padding:"1rem"}} id="filter_amount_sign" value={filter_amount_sign} onChange={updatefilter_amount_sign}  >
                              <option className="dropdown-item" value=" ">All</option>
                              <option className="dropdown-item" value="equals">equals</option>
                              <option className="dropdown-item" value="more than" >more than</option>
                              <option className="dropdown-item" value="less than">less than</option>
                              <option className="dropdown-item" value="more than or equals" >more than or equals</option>
                              <option className="dropdown-item" value="less than or equals" >less than or equals</option>
                              </select>
                              <code>{filter_amount_sign_er}</code>
                            </div>
                            <div class="col-sm-4">
                              <input type="text" class="form-control" placeholder="5000.00" id="filter_amount" value={filter_amount} onChange={updatefilter_amount}   />
                              <code>{filter_amount_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Transaction</label>
                            <div class="col-sm-8">
                             <select class="form-control" style={{padding:"1rem"}} id="filter_transaction" value={filter_transaction} onChange={updatefilter_transaction} >
                             <option value=" ">Select Transaction</option>
                             <option value="income">income</option>
                             <option value="expense">expense</option>
                             <option value="sales">sales</option>
                             <option value="salesreturn">salesreturn</option>
                             <option value="purchases">purchases</option>
                             <option value="purchasereturns">purchasereturns</option>
                           
                             </select>                              
                             <code>{filter_transaction_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                      <div class="col-md-8">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Sgst Received</label>
                            <div class="col-sm-4">
                              <select class="form-control" style={{padding:"1rem"}} id="filter_sgst_in_sign" value={filter_sgst_in_sign} onChange={updatefilter_sgst_in_sign}  >
                              <option className="dropdown-item" value=" ">All</option>
                              <option className="dropdown-item" value="equals">equals</option>
                              <option className="dropdown-item" value="more than" >more than</option>
                              <option className="dropdown-item" value="less than">less than</option>
                              <option className="dropdown-item" value="more than or equals" >more than or equals</option>
                              <option className="dropdown-item" value="less than or equals" >less than or equals</option>
                              </select>
                              <code>{filter_sgst_in_sign_er}</code>
                            </div>
                            <div class="col-sm-4">
                              <input type="text" class="form-control" placeholder="5000.00" id="filter_sgst_in" value={filter_sgst_in} onChange={updatefilter_sgst_in}   />
                              <code>{filter_sgst_in_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                              
                     
                        </div>
                      </div>

                      <div class="row">
                      <div class="col-md-8">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Sgst Paid</label>
                            <div class="col-sm-4">
                              <select class="form-control" style={{padding:"1rem"}} id="filter_sgst_out_sign" value={filter_sgst_out_sign} onChange={updatefilter_sgst_out_sign}  >
                              <option className="dropdown-item" value=" ">All</option>
                              <option className="dropdown-item" value="equals">equals</option>
                              <option className="dropdown-item" value="more than" >more than</option>
                              <option className="dropdown-item" value="less than">less than</option>
                              <option className="dropdown-item" value="more than or equals" >more than or equals</option>
                              <option className="dropdown-item" value="less than or equals" >less than or equals</option>
                              </select>
                              <code>{filter_sgst_out_sign_er}</code>
                            </div>
                            <div class="col-sm-4">
                              <input type="text" class="form-control" placeholder="5000.00" id="filter_sgst_out" value={filter_sgst_out} onChange={updatefilter_sgst_out}   />
                              <code>{filter_sgst_out_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                         
                          <button class="btn btn-primary" onClick={()=>{ setfilter_val(1); getData(); }}>Apply Filters</button>
                         <button class="btn btn-danger" style={{marginLeft:"1rem"}} onClick={()=>{ setfilter_val(0); getData(); }}>Remove Filters</button>
                       
                     
                        </div>
                      </div>
                    
                      <div style={{marginBottom:"1rem"}}>
                  
                       
                      </div>
                            <hr/>                      
                            </div>
                            
                            {/* FILTER SECTION END */}

                    <div class="col-12" style={{marginBottom:"2rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                        {/* COLUMNS MANAGEMENT DROPDOWN START */}
                          <div class="dropdown">
                          <button class="btn btn-warning" onClick={()=>{ document.getElementById("filter-top-card").style.display="block"; }}><i class="mdi mdi-filter-outline"></i></button>
                            <button class="btn btn-primary dropdown-toggle" style={{marginLeft:"1rem"}} type="button" id="dropdownMenuIconButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="mdi mdi-table-large"></i>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuIconButton1">
                              <h6 class="dropdown-header">Columns</h6>
                              <hr/>
                              
                              {/* Column 1 */}
                              <li><div class="d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Sn</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c1_yes" onClick={()=>{setcd_col1_val(1); document.getElementById("cd_c1_yes").style.display="none"; document.getElementById("cd_c1_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c1_no" style={{ display:"none"}} onClick={()=>{ setcd_col1_val(0); document.getElementById("cd_c1_yes").style.display="block"; document.getElementById("cd_c1_no").style.display="none"; }}></i>
                              </div></div></li> 
                               {/* Column 2 */}
                               <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Tr No.</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c2_yes"  onClick={()=>{setcd_col2_val(1); document.getElementById("cd_c2_yes").style.display="none"; document.getElementById("cd_c2_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c2_no" style={{ display:"none"}} onClick={()=>{ setcd_col2_val(0); document.getElementById("cd_c2_yes").style.display="block"; document.getElementById("cd_c2_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 3 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Voucher No.</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c3_yes"  onClick={()=>{setcd_col3_val(1); document.getElementById("cd_c3_yes").style.display="none"; document.getElementById("cd_c3_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c3_no" style={{ display:"none"}} onClick={()=>{ setcd_col3_val(0); document.getElementById("cd_c3_yes").style.display="block"; document.getElementById("cd_c3_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 4 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Date</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c4_yes"  onClick={()=>{setcd_col4_val(1); document.getElementById("cd_c4_yes").style.display="none"; document.getElementById("cd_c4_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c4_no" style={{ display:"none"}} onClick={()=>{ setcd_col4_val(0); document.getElementById("cd_c4_yes").style.display="block"; document.getElementById("cd_c4_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 5 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Transaction</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c5_yes"  onClick={()=>{setcd_col5_val(1); document.getElementById("cd_c5_yes").style.display="none"; document.getElementById("cd_c5_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c5_no" style={{ display:"none"}} onClick={()=>{ setcd_col5_val(0); document.getElementById("cd_c5_yes").style.display="block"; document.getElementById("cd_c5_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 6 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">GSTIN</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c6_yes"  onClick={()=>{setcd_col6_val(1); document.getElementById("cd_c6_yes").style.display="none"; document.getElementById("cd_c6_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c6_no" style={{ display:"none"}} onClick={()=>{ setcd_col6_val(0); document.getElementById("cd_c6_yes").style.display="block"; document.getElementById("cd_c6_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 7 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Taxable Amount</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c7_yes" onClick={()=>{setcd_col7_val(1); document.getElementById("cd_c7_yes").style.display="none"; document.getElementById("cd_c7_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c7_no" style={{ display:"none"}} onClick={()=>{ setcd_col7_val(0); document.getElementById("cd_c7_yes").style.display="block"; document.getElementById("cd_c7_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 8 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Sgst %</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c8_yes" onClick={()=>{setcd_col8_val(1); document.getElementById("cd_c8_yes").style.display="none"; document.getElementById("cd_c8_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c8_no" style={{ display:"none"}} onClick={()=>{ setcd_col8_val(0); document.getElementById("cd_c8_yes").style.display="block"; document.getElementById("cd_c8_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 9 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Sgst Rec.</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c9_yes" onClick={()=>{setcd_col9_val(1); document.getElementById("cd_c9_yes").style.display="none"; document.getElementById("cd_c9_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c9_no" style={{ display:"none"}} onClick={()=>{ setcd_col9_val(0); document.getElementById("cd_c9_yes").style.display="block"; document.getElementById("cd_c9_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 10 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Sgst Paid</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c10_yes" onClick={()=>{setcd_col10_val(1); document.getElementById("cd_c10_yes").style.display="none"; document.getElementById("cd_c10_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c10_no" style={{ display:"none"}} onClick={()=>{ setcd_col10_val(0); document.getElementById("cd_c10_yes").style.display="block"; document.getElementById("cd_c10_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 11 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Updated By</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c11_yes" onClick={()=>{setcd_col11_val(1); document.getElementById("cd_c11_yes").style.display="none"; document.getElementById("cd_c11_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c11_no" style={{ display:"none"}} onClick={()=>{ setcd_col11_val(0); document.getElementById("cd_c11_yes").style.display="block"; document.getElementById("cd_c11_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 12 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Updated At</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c12_yes" onClick={()=>{setcd_col12_val(1); document.getElementById("cd_c12_yes").style.display="none"; document.getElementById("cd_c12_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c12_no" style={{ display:"none"}} onClick={()=>{ setcd_col12_val(0); document.getElementById("cd_c12_yes").style.display="block"; document.getElementById("cd_c12_no").style.display="none"; }}></i>
                              </div></div></li> 
                            {/*}  <div class="dropdown-divider"></div>
                              <button class="btn btn-danger">Close</button>*/}
                            </div> 
                          </div>
                          {/* COLUMNS MANAGEMENT DROPDOWN END */}

                          </div>
                          </div>


                    <div class="table-responsive" id="reports" ref={componentRef}>
                      <table class="table table-hover">
                        <thead>
                          <tr >
                            <th  style={{fontWeight:"bold"}} hidden={cd_col1_val==1} >Sn</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col2_val==1} >Tr No.</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col3_val==1} >Voucher No.</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col4_val==1} >Date</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col5_val==1} >Transaction</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col6_val==1} >GSTIN</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col7_val==1} >Taxable Amount</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col8_val==1} >Sgst %</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col9_val==1} >Sgst Rec.</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col10_val==1} >Sgst Paid</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col11_val==1} >Updated By</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col12_val==1} >Updated At</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                       data.map((curElm,ind)=>{

                         return(
                         <>
                        <tr>
                            <td hidden={(logtype == "user")||(cd_col1_val==1)}>{curElm.Admin_sn}</td>
                            <td hidden={(logtype == "admin")||(cd_col1_val==1)}>{curElm.User_sn}</td>
                            <td hidden={cd_col2_val==1} >{curElm.Tr_no}</td>
                            <td hidden={cd_col3_val==1} >{curElm.Voucher_no}</td>
                            <td hidden={cd_col4_val==1} >{curElm.Date}</td>
                            <td hidden={cd_col5_val==1} ><label class="badge badge-danger" hidden={(curElm.Transaction == "income")||(curElm.Transaction == "sales")||(curElm.Transaction == "salesreturn")||(curElm.Transaction == "purchases")||(curElm.Transaction == "purchasereturns")}>{curElm.Transaction}</label>
                            <label class="badge badge-success" hidden={(curElm.Transaction == "expense")||(curElm.Transaction== "sales")||(curElm.Transaction == "salesreturn")||(curElm.Transaction == "purchases")||(curElm.Transaction == "purchasereturns")}>{curElm.Transaction}</label>
                            <label class="badge badge-primary" hidden={(curElm.Transaction == "expense")||(curElm.Transaction== "sales")||(curElm.Transaction == "salesreturn")||(curElm.Transaction == "income")||(curElm.Transaction == "purchasereturns")}>{curElm.Transaction}</label>
                            <label class="badge badge-warning" hidden={(curElm.Transaction == "expense")||(curElm.Transaction== "purchases")||(curElm.Transaction == "salesreturn")||(curElm.Transaction == "income")||(curElm.Transaction == "purchasereturns")}>{curElm.Transaction}</label>
                            <label class="badge badge-info" hidden={(curElm.Transaction == "expense")||(curElm.Transaction== "purchases")||(curElm.Transaction == "sales")||(curElm.Transaction == "income")||(curElm.Transaction == "purchasereturns")}>{curElm.Transaction}</label>
                            <label class="badge badge-dark" hidden={(curElm.Transaction == "expense")||(curElm.Transaction== "purchases")||(curElm.Transaction == "sales")||(curElm.Transaction == "income")||(curElm.Transaction == "salesreturn")}>{curElm.Transaction}</label>
                            </td>
                            <td hidden={cd_col6_val==1} >{curElm.Gstin}</td>
                            <td hidden={cd_col7_val==1} >{curElm.Taxable_amt}</td>
                            <td hidden={(cd_col8_val==1)}><span hidden={(curElm.Sgst_in>0)||(curElm.Sgst_out==0)}>{curElm.Sgst_out}</span>
                            <span hidden={(curElm.Sgst_out>0)||(curElm.Sgst_in==0)}>{curElm.Sgst_in}</span></td>
                            <td hidden={cd_col9_val==1} >{curElm.Sgst_amt_in}</td>
                            <td hidden={cd_col10_val==1} >{curElm.Sgst_amt_out}</td>
                            <td hidden={cd_col11_val==1} >{curElm.Updated_by}</td>
                            <td hidden={cd_col12_val==1} >{curElm.Date_updated} ({curElm.Time_updated})</td>
                          </tr>
                          </>
                         )
                         }
					   
	                  		 )
                         }
                          
                       
                         
                        </tbody>
                      </table>
                    </div>
                      {/* PAGINATION SECTION START */}
                      <div>
                       <div class="col-12" style={{marginBottom:"2rem",marginTop:"1rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                       
                          <div class="dropdown">
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber-1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-left-drop-circle text-success"></i></button>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber+1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-right-drop-circle text-success"></i></button>
                          </div>
                          </div>
                          </div>
                    </div>
                    {/* PAGINATION SECTION END */}

                    <button class="btn btn-light" onClick={handlePrint}><i class="mdi mdi-cloud-print text-dark"></i></button>
                    <button class="btn btn-light" onClick={downloadExcel}><i class="mdi mdi-file-excel text-dark"></i></button>
            

                  </div>
                </div>
              </div>

            
            
              <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">SGST Summary</h4>
                    <p class="card-description">sgst summary
                    </p>

                        {/* FILTER SECTION 1 START */}
                            <hr/>
                            <div id="filter-top-card1" class="row col-12" style={{textAlign:"left",display:"none"}}>
                            <div class="d-flex justify-content-between">
                            <div>
                            <h4 style={{padding:'1rem'}}>Filters</h4>
                           </div>
                           <div>
                    
                           <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("filter-top-card1").style.display="none"; }}>
                           <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                           </button>
                           </div>
                           </div>
        
                        <div class="row">
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Start Date</label>
                            <div class="col-sm-8">
                              <input type="date" class="form-control" id="filter_start_date" value={filter_start_date1} onChange={updatefilter_start_date1}  />
                              <code>{filter_start_date1_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">End Date</label>
                            <div class="col-sm-8">
                              <input type="date" class="form-control"  id="filter_end_date" value={filter_end_date1} onChange={updatefilter_end_date1}  />
                              <code>{filter_end_date1_er}</code>
                            </div>
                          </div>
                        </div>
                      
                        <div class="col-md-4">
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">GSTIN</label>
                            <div class="col-sm-9">
                              <input class="form-control" placeholder="GST555EDDRDDD1" style={{padding:"1rem"}} id="filter_gstin1" value={filter_gstin1} onChange={updatefilter_gstin1} />
                              <code>{filter_gstin1_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                      <button class="btn btn-primary" onClick={()=>{ setfilter_val1(1); getData(); }}>Apply Filters</button>
                      <button class="btn btn-danger" style={{marginLeft:"1rem"}} onClick={()=>{ setfilter_val1(0); getData(); }}>Remove Filters</button>
                      </div>

                            <hr/>                      
                            </div>
                            
                            {/* FILTER SECTION 1 END */}

                            <div class="col-12" style={{marginBottom:"2rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                       
                          <div class="dropdown">
                          <button class="btn btn-warning" onClick={()=>{ document.getElementById("filter-top-card1").style.display="block"; }}><i class="mdi mdi-filter-outline"></i></button>
                          </div>
                          </div>
                          </div>

                      <div class="table-responsive" id="reports" ref={componentRef1}>
                      <table class="table table-striped">
                        <thead>
                          <tr >
                        {/*}    <th  style={{fontWeight:"bold"}}>Sn</th> */}
                            <th  style={{fontWeight:"bold"}}>GSTIN</th>
                            <th  style={{fontWeight:"bold"}}>SGST Rec.</th>
                            <th  style={{fontWeight:"bold"}}>SGST Paid</th>
                            <th  style={{fontWeight:"bold"}}>Balance</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                       summary.map((curElm1,ind)=>{

                         return(
                         <>
                        <tr>
                       {/*}     <td></td> */}
                            <td>{curElm1.Gstin}</td>
                            <td>{parseFloat(curElm1.Sgst_amt_in).toFixed(2)}</td>
                            <td>{parseFloat(curElm1.Sgst_amt_out).toFixed(2)}</td>
                            <td>{(parseFloat(curElm1.Sgst_amt_in)-(curElm1.Sgst_amt_out)).toFixed(2)}</td>
                          </tr>
                          </>
                         )
                         }
					   
	                  		 )
                         }
                     
                        </tbody>
                      </table>
                    </div>
                      {/* PAGINATION SECTION START */}
                      <div>
                       <div class="col-12" style={{marginBottom:"2rem",marginTop:"1rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                       
                          <div class="dropdown">
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber-1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-left-drop-circle text-success"></i></button>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber+1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-right-drop-circle text-success"></i></button>
                          </div>
                          </div>
                          </div>
                    </div>
                    {/* PAGINATION SECTION END */}

                    <button class="btn btn-light" onClick={handlePrint1}><i class="mdi mdi-cloud-print text-dark"></i></button> 
                    <button class="btn btn-light" onClick={downloadExcel1}><i class="mdi mdi-file-excel text-dark"></i></button>
            
                  </div>
                </div>
              </div>

            
          </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
        {/*}  <footer class="footer">
            <div class="d-sm-flex justify-content-center justify-content-sm-between">
              <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
</>
);

//}
}
export default UserSgst;