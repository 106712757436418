import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './Resources/dashboard.css';
import Navbar from './navbar';

function Businesstoolsentry(){

return(
    <>
    
<html lang="en">
  <head>
    {/* Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Business Tools|BookKeeping Solution</title>
    {/* plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/* endinject */}
    {/* Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/* End plugin css for this page */}
    {/* inject:css */}
    {/* endinject */}
    {/* Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/* End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
    </head>
    <body>
    {/* container-scroller */}
    <div class="container-scroller">

   {/* partial */}
   <div class="container-fluid page-body-wrapper">

          <Navbar/>

          <div class="main-panel">
          <div class="content-wrapper pb-0">
            <div class="page-header flex-wrap">

            <div class="row">
            <div class="col-sm-3 stretch-card grid-margin">
                
                {/* POS Card */}
                <div class="card">
                  <div class="card-body p-0 btools-card-header">
                  <i class="mdi mdi-store btools-card-icon"></i>
                  </div>
                  <div class="card-body px-3 text-dark">
                  <h3 class="font-weight-bold"> PoS Counters</h3>
                    <div class="d-flex justify-content-between font-weight-semibold">
                      <p class="text-muted mb-0">Manage your PoS counters with ease</p>
                     </div>
                    <button class="btn-lg btn-danger mt-4 font-weight-bold">Manage</button>
                  </div>
                </div>
                </div>

                {/* Team Card */}
                <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0 btools-card-header" >
                  <i class="mdi mdi-account-multiple-plus btools-card-icon" ></i>
                  </div>
                  <div class="card-body px-3 text-dark">
                  <h3 class="font-weight-bold"> Team</h3>
                    <div class="d-flex justify-content-between font-weight-semibold">
                      <p class="text-muted mb-0">Manage your team for your business</p>
                     </div>
                    <button class="btn-lg btn-danger mt-4 font-weight-bold">Manage</button>
                  </div>
                </div>
                </div>

               {/* Catalog Card */}
                <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0 btools-card-header" >
                  <i class="mdi mdi-cart-plus btools-card-icon" ></i>
                  </div>
                  <div class="card-body px-3 text-dark">
                  <h3 class="font-weight-bold"> Catalog</h3>
                    <div class="d-flex justify-content-between font-weight-semibold">
                      <p class="text-muted mb-0">Manage your catalog with ease</p>
                     </div>
                    <button class="btn-lg btn-danger mt-4 font-weight-bold">Manage</button>
                  </div>
                </div>
                </div>

                {/* Inventory Card */}
                <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0 btools-card-header" >
                  <i class="mdi mdi-package-variant-closed btools-card-icon" ></i>
                  </div>
                  <div class="card-body px-3 text-dark">
                  <h3 class="font-weight-bold"> Inventory</h3>
                    <div class="d-flex justify-content-between font-weight-semibold">
                      <p class="text-muted mb-0">Manage your inventory with ease</p>
                     </div>
                    <button class="btn-lg btn-danger mt-4 font-weight-bold">Manage</button>
                  </div>
                </div>
                </div>

                {/* Cash Card */}
                <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0 btools-card-header" style={{borderColor:"#23a54b"}} >
                  <i class="mdi mdi-cash btools-card-icon" style={{color:"#23a54b"}} ></i>
                  </div>
                  <div class="card-body px-3 text-dark">
                  <h3 class="font-weight-bold"> Cash</h3>
                    <div class="d-flex justify-content-between font-weight-semibold">
                      <p class="text-muted mb-0">Manage your cash with ease</p>
                     </div>
                    <button class="btn-lg btn-danger mt-4 font-weight-bold" >Manage</button>
                  </div>
                </div>
              </div>

              {/* Bank Card */} 
              <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                <div class="card-body p-0 btools-card-header" style={{borderColor:"#23a54b"}} >
                  <i class="mdi mdi-bank btools-card-icon" style={{color:"#23a54b"}} ></i>
                  </div>
                  <div class="card-body px-3 text-dark">
                  <h3 class="font-weight-bold"> Bank</h3>
                    <div class="d-flex justify-content-between font-weight-semibold">
                      <p class="text-muted mb-0">Manage your bank with ease</p>
                     </div>
                    <button class="btn-lg btn-danger mt-4 font-weight-bold">Manage</button>
                  </div>
                </div>
              </div>

              {/* Customers Card */}
              <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                <div class="card-body p-0 btools-card-header" style={{borderColor:"#23a54b"}} >
                  <i class="mdi mdi-account-box-outline btools-card-icon" style={{color:"#23a54b"}} ></i>
                  </div>
                  <div class="card-body px-3 text-dark">
                  <h3 class="font-weight-bold"> Customers</h3>
                    <div class="d-flex justify-content-between font-weight-semibold">
                      <p class="text-muted mb-0">Manage your customers with ease</p>
                     </div>
                    <button class="btn-lg btn-danger mt-4 font-weight-bold">Manage</button>
                  </div>
                </div>
              </div>

              {/* Incomes Card */}
              <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                <div class="card-body p-0 btools-card-header" style={{borderColor:"#23a54b"}} >
                  <i class="mdi mdi-wallet-giftcard btools-card-icon" style={{color:"#23a54b"}} ></i>
                  </div>
                  <div class="card-body px-3 text-dark">
                  <h3 class="font-weight-bold"> Incomes</h3>
                    <div class="d-flex justify-content-between font-weight-semibold">
                      <p class="text-muted mb-0">Manage your incomes with ease</p>
                     </div>
                    <button class="btn-lg btn-danger mt-4 font-weight-bold">Manage</button>
                  </div>
                </div>
              </div>

                {/* Expenses Card */}
                <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0 btools-card-header" >
                  <i class="mdi mdi-nutrition btools-card-icon" ></i>
                  </div>
                  <div class="card-body px-3 text-dark">
                  <h3 class="font-weight-bold"> Expenses</h3>
                    <div class="d-flex justify-content-between font-weight-semibold">
                      <p class="text-muted mb-0">Manage your expenses with ease</p>
                     </div>
                    <button class="btn-lg btn-danger mt-4 font-weight-bold">Manage</button>
                  </div>
                </div>
              </div>

                 {/* Sales Card */}
                 <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0 btools-card-header" >
                  <i class="mdi mdi-elevation-rise btools-card-icon" ></i>
                  </div>
                  <div class="card-body px-3 text-dark">
                  <h3 class="font-weight-bold"> Sales</h3>
                    <div class="d-flex justify-content-between font-weight-semibold">
                      <p class="text-muted mb-0">Manage your sales with ease</p>
                     </div>
                    <button class="btn-lg btn-danger mt-4 font-weight-bold">Manage</button>
                  </div>
                </div>
              </div>

               {/* Purchases Card */}
                <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0 btools-card-header" >
                  <i class="mdi mdi-truck-delivery btools-card-icon" ></i>
                  </div>
                  <div class="card-body px-3 text-dark">
                  <h3 class="font-weight-bold"> Purchases</h3>
                    <div class="d-flex justify-content-between font-weight-semibold">
                      <p class="text-muted mb-0">Manage your purchases with ease</p>
                     </div>
                    <button class="btn-lg btn-danger mt-4 font-weight-bold">Manage</button>
                  </div>
                </div>
              </div>
        
           {/* Taxes Card */}
           <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0 btools-card-header" >
                  <i class="mdi mdi-sale btools-card-icon" ></i>
                  </div>
                  <div class="card-body px-3 text-dark">
                  <h3 class="font-weight-bold"> Taxes</h3>
                    <div class="d-flex justify-content-between font-weight-semibold">
                      <p class="text-muted mb-0">Manage your taxes with ease</p>
                     </div>
                    <button class="btn-lg btn-danger mt-4 font-weight-bold">Manage</button>
                  </div>
                </div>
              </div>

            </div>
            {/* table row starts here */}

    </div>
    </div>
    </div>
    </div>
    </div>


    {/* plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/* endinject */}
    {/* Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/* End plugin js for this page */}
    {/* inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/* endinject */}
    {/* Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/* End custom js for this page */}
  </body>
  </html>    
    </>
);

}

export default Businesstoolsentry;   