import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import Navbar from './navbar';



//import '../hireportalcss.css';
//import '../Hireportalcss/hireportalcss.css';

//Importing Page css with screen sizes
import '../Hireportalcss/sdefault.css';
import '../Hireportalcss/s1200.css';
import '../Hireportalcss/s920.css';
import '../Hireportalcss/s768.css';
import '../Hireportalcss/s600.css';
import '../Hireportalcss/sanimation.css';



function Employercreateassignment(){

    return(

    <>
        <div class="default-view">
        <Navbar/>
        {/* MAIN SECTION START */}
        <div class="hire-main-section">

        {/* FORM CONTAINER START */}
        <div class="hire-form-container">
        
        {/* CREATE JOB FORM START */}
            <div class="hire-form-panel" style={{backgroundColor:"#ffffff"}}>

        {/* CREATE ASSIGNMENT SECTION START */}
            <div id="form-assignment-create-section">
            <h3>Create Assignment</h3>

            <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Assignment Name</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Normal Test for Full Stack Developer" /></div>
            </div>
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Assignment Type</div>
            <div class="hire-form-group-right"><input class="hire-form-group-input" placeholder="For e.g. : Skill Test" /> 
            </div>
            </div>
            </div>


            <div class="hire-form-list">
            <div class="hire-form-group-large">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Assignment Overview</div>
            <div class="hire-form-group-right"><textarea size="3" class="hire-form-group-input" placeholder="For e.g. : All Traits Test"></textarea></div>
            </div>
            </div>

            <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Industry</div>
            <div class="hire-form-group-right"><input class="hire-form-group-input"  placeholder="For e.g. : IT/ Tech" />
            </div>
            </div>
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>For</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Web Developer Role" /></div>
            </div>
            </div>
            <div class="hire-form-btn">
            <button class="hire-btn-default" style={{backgroundColor:"#060c5f",marginRight:"2rem",marginTop:"2rem",marginBottom:"2rem"}}
            onClick={()=>{ 
             document.getElementById("form-assignment-create-section").style.display="none";
             document.getElementById("form-assignment-questions-section").style.display="block";}}

            >Create Assignment</button>

            </div>
            </div>
            {/* CREATE ASSIGNMENT SECTION END */}

            {/* ASSIGNMENT QUESTIONS SECTION START */}
            <div id="form-assignment-questions-section" style={{display:"none"}}>
            <h3>Add Assignment Questions</h3>
            <p style={{textAlign:"right",marginRight:"1rem"}}><b>Total Marks:</b> 0</p>

<div class="hire-form-list">
<div class="hire-form-group-small">
<div class="hire-form-group-left"><label class="hire-form-group-label"></label>Question Number</div>
<div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : 1" /></div>
</div>
<div class="hire-form-group-small">
<div class="hire-form-group-left"><label class="hire-form-group-label"></label>Answer Type</div>
<div class="hire-form-group-right"><select class="hire-form-group-input" placeholder="Select Answer Type">
<option value="">Select Answer Type</option>
<option value="">Multiple Choice Answer</option>
<option value="">Short Format Answer</option>
<option value="">Long Format Answer</option>
</select>    
</div>
</div>
</div>


<div class="hire-form-list">
<div class="hire-form-group-large">
<div class="hire-form-group-left"><label class="hire-form-group-label"></label>Enter Question here</div>
<div class="hire-form-group-right"><textarea size="3" class="hire-form-group-input" placeholder="For e.g. : Why are you the best fit for this role?."></textarea></div>
</div>
</div>

<div class="hire-form-list">
<div class="hire-form-group-small">
<div class="hire-form-group-left"><label class="hire-form-group-label"></label>Required</div>
<div class="hire-form-group-right"><select class="hire-form-group-input"  placeholder="Select">
<option value="Yes">Yes</option>
<option value="No">No</option>
</select>
</div>
</div>
<div class="hire-form-group-small">
<div class="hire-form-group-left"><label class="hire-form-group-label"></label>Maximum Marks</div>
<div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : 6" /></div>
</div>
</div>

<div class="hire-form-list">
<div class="hire-form-group-small">
<div class="hire-form-group-left"><label class="hire-form-group-label"></label>Negative Marking</div>
<div class="hire-form-group-right"><select class="hire-form-group-input"  placeholder="Select">
<option value="Yes">Yes</option>
<option value="No">No</option>
</select>
</div>
</div>
<div class="hire-form-group-small">
<div class="hire-form-group-left"><label class="hire-form-group-label"></label>Negative Marks</div>
<div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : 3" /></div>
</div>
</div>


<div class="hire-form-btn">
<button class="hire-btn-default" style={{backgroundColor:"#060c5f",marginRight:"2rem",marginTop:"2rem",marginBottom:"2rem"}}>+</button>

</div>

<div id="line-1"></div>

<div class="hire-form-list">
<div class="hire-form-group-small">
<div class="hire-form-group-left"><label class="hire-form-group-label"></label>Filter Passing</div>
<div class="hire-form-group-right"><select class="hire-form-group-input"  placeholder="Select">
<option value="Yes">Yes</option>
<option value="No">No</option>
</select>
</div>
</div>
<div class="hire-form-group-small">
<div class="hire-form-group-left"><label class="hire-form-group-label"></label>Passing Marks</div>
<div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : 40" /></div>
</div>
</div>


<div class="hire-form-btn">
<button class="hire-btn-default" style={{backgroundColor:"#d48939",marginRight:"2rem",marginTop:"2rem",marginBottom:"2rem"}}>Submit Assignment for Review</button>

</div>


            </div>
        {/* ASSIGNMENT QUESTIONS SECTION END */}
        </div>
        {/* CREATE JOB FORM END */}


        </div>
        {/* FORM CONTAINER END */}

        </div>
        {/* MAIN SECTION END */}

        </div>

        </>
    );




}


export default Employercreateassignment;