import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from 'axios';


function CandidateprofileCoursescertification(){

  

    return(
<>

          {/* COURSES AND CERTIFICATION SECTION START */}
          <div class="hire-form-panel" style={{backgroundColor:"#ffffff",display:"none"}} id="courses-certifications-section">
            <h3>Courses and Certifications</h3>
            <p style={{color:"#acafb0"}}>Add your courses and certifications that will be displayed in your profile and detailed resume.</p>
          

           <div class="hire-form-list">
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Course Name</div>
           <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Fundamentals of Web Development" />
           </div>
           </div>
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Institute</div>
           <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : XYZ Institute of Technology" /> 
           </div>
           </div>
           </div>

           <div class="hire-form-list">
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Certification Number</div>
           <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : GHJUU43456" />
           </div>
           </div>
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Valid Till</div>
           <div class="hire-form-group-right"><input type="date" class="hire-form-group-input" /> 
           </div>
           </div>
           </div>

           <div class="hire-form-list">
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Start Date</div>
           <div class="hire-form-group-right"><input type="date" class="hire-form-group-input"  />
           </div>
           </div>
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>End Date</div>
           <div class="hire-form-group-right"><input type="date" class="hire-form-group-input"  /> 
           </div>
           </div>
           </div>

           
           <div class="hire-form-list">
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Details</div>
           <div class="hire-form-group-right"><textarea class="hire-form-group-input" size="3" placeholder="For e.g. : Course helps understand the basics and fundamentals of web development." />
           </div>
           </div>
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Link (if any)</div>
           <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : https://xyzinstitute.com/certificate-gfffff6666" /> 
           </div>
           </div>
           </div>



           <div class="hire-form-btn">
           <button style={{backgroundColor:"#ffffff",border:"none", marginTop:"2rem"}}><img src="https://cdn-icons-png.flaticon.com/128/10337/10337579.png" class="hire-card-icon" style={{height:"4rem",width:"4rem",}} /></button>
           </div>
          
            <div class="hire-form-btn">
            <button class="hire-btn-default" style={{backgroundColor:"#12d571",marginRight:"2rem",marginTop:"2rem",marginBottom:"2rem"}}
            onClick={()=>{ document.getElementById("courses-certifications-section").style.display="none";
            document.getElementById("work-experience-section").style.display="block";
             }}
            >Save and Next</button>
            </div>
          
            </div>
            {/* COURSES AND CERTIFICATION SECTION END */}
          
              
</>
);
    }

export default CandidateprofileCoursescertification;