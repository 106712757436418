import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import '../Resources/dashboard.css';
import axios from '../../axios.js';
import moment from 'moment/moment';


function Create_purchases(){

  

   //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const busyid=sessionStorage.getItem("BusyId00");
 const counterid=sessionStorage.getItem("CounterId00");


 //Checking if Sessions are Set

// alert(logstatus,loguser,logadminid);
 //Sessions Not Set
 {/* if(((logstatus==null)||(logstatus==""))||((logid==null)||(logid==""))||((token==null)||(token==" "))||((busyid==null)||(busyid==" "))||((counterid==null)||(counterid==" "))){
  
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");
   
 
    window.location.replace("/businesstools-login");
   }
  
   //Sessions Set
   else{

   */}

    
      
//Variables
const[Tbanks,setTbanks] = useState([]);//SetData Variable
const[suppliers,setsuppliers] = useState([],);//SetData Variable
const[cdefault,setcdefault] = useState(0);

//Error Variable
const[errorvar,seterrorvar] = useState();

//suppliers_count
const[suppliers_count,setsuppliers_count] = useState(1);
//csuccess
//const[ccsuccess,setccsuccess] = useState(0);
//date
const[date,setdate]=useState();const updatedate = (event) => { setdate(event.target.value);}; const[date_er,setdate_er]=useState();
//type
const[type,settype]=useState();const updatetype = (event) => { settype(event.target.value);}; const[type_er,settype_er]=useState();
//voucher_no
const[voucher_no,setvoucher_no]=useState();const updatevoucher_no = (event) => { setvoucher_no(event.target.value);}; const[voucher_no_er,setvoucher_no_er]=useState();
//gstin
const[gstin,setgstin]=useState();const updategstin = (event) => { setgstin(event.target.value);}; const[gstin_er,setgstin_er]=useState();
//license_no
const[license_no,setlicense_no]=useState();const updatelicense_no = (event) => { setlicense_no(event.target.value);}; const[license_no_er,setlicense_no_er]=useState();
//bank_id
const[bank_id,setbank_id]=useState();const updatebank_id = (event) => { setbank_id(event.target.value);}; const[bank_id_er,setbank_id_er]=useState();
//supplier_id
const[supplier_id,setsupplier_id]=useState();const updatesupplier_id = (event) => { setsupplier_id(event.target.value);}; const[supplier_id_er,setsupplier_id_er]=useState();
//supplier_name
const[supplier_name,setsupplier_name]=useState();const updatesupplier_name = (event) => { setsupplier_name(event.target.value);getsupplierbyname(); }; const[supplier_name_er,setsupplier_name_er]=useState();
//supplier_contact
const[supplier_contact,setsupplier_contact]=useState();const updatesupplier_contact = (event) => { setsupplier_contact(event.target.value);}; const[supplier_contact_er,setsupplier_contact_er]=useState();
//supplier_wtp
const[supplier_wtp,setsupplier_wtp]=useState();const updatesupplier_wtp = (event) => { setsupplier_wtp(event.target.value);}; const[supplier_wtp_er,setsupplier_wtp_er]=useState();
//supplier_email
const[supplier_email,setsupplier_email]=useState();const updatesupplier_email = (event) => { setsupplier_email(event.target.value);}; const[supplier_email_er,setsupplier_email_er]=useState();
//supplier_address
const[supplier_address,setsupplier_address]=useState();const updatesupplier_address = (event) => { setsupplier_address(event.target.value);}; const[supplier_address_er,setsupplier_address_er]=useState();
//supplier_city
const[supplier_city,setsupplier_city]=useState();const updatesupplier_city = (event) => { setsupplier_city(event.target.value);}; const[supplier_city_er,setsupplier_city_er]=useState();
//supplier_state
const[supplier_state,setsupplier_state]=useState();const updatesupplier_state = (event) => { setsupplier_state(event.target.value);}; const[supplier_state_er,setsupplier_state_er]=useState();
//supplier_country
const[supplier_country,setsupplier_country]=useState();const updatesupplier_country = (event) => { setsupplier_country(event.target.value);}; const[supplier_country_er,setsupplier_country_er]=useState();
//supplier_pincode
const[supplier_pincode,setsupplier_pincode]=useState();const updatesupplier_pincode = (event) => { setsupplier_pincode(event.target.value);}; const[supplier_pincode_er,setsupplier_pincode_er]=useState();




//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();




useEffect(()=>{
   
  getData();
  
  },[])
 

  
    
  
//******************* GET BANKS DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))){
 
 
 axios.post("bt-get-banks", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Sn_start :0,
   Sn_end :0,
   Sn_total :0,
   Sn_limit :0
 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setTbanks(response.data.data);
  
  }
  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* GET BANKS DATA FUNCTION END  ********************//



 
  
//******************* GET supplier DATA BY NAME FUNCTION START  ********************//
function getsupplierbyname(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))){
 
     const cname = document.getElementById("supplier_name").value;
     const cnlength = cname.length;
     if((cdefault==0)&&(cnlength>=3)){
     
 
 axios.post("bt-get-suppliers-spr", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Name:document.getElementById("supplier_name").value,
   Sn_start :0,
   Sn_end :0,
   Sn_total :0,
   Sn_limit :0
 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setsuppliers(response.data.suppliers);
   setsuppliers_count(response.data.suppliers_count);
   document.getElementById("suppliers-list-box").style.display="block";
  
  }
  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
}
 //set suppliers_count so that user can add new supplier
 else{ setsuppliers_count(1); }
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }

 }
  
 //******************* GET supplier DATA BY NAME FUNCTION END  ********************//

 
  
//******************* CREATE PURCHASES FUNCTION START ********************//
function createpurchases(){

  //Setting Error Variables
setdate_er(" ");settype_er(" ");setbank_id_er(" ");setgstin_er(" ");setvoucher_no_er(" ");setlicense_no_er(" ");setsupplier_id_er(" ");setsupplier_name_er(" ");setsupplier_email_er(" ");
setsupplier_contact_er(" ");setsupplier_wtp_er(" ");setsupplier_address_er();setsupplier_city_er();setsupplier_state_er();setsupplier_country_er();setsupplier_pincode_er();seterror_msg(" ");

  //Check if related fields selected right
  //Bank Transation
  if((type=="bank")&&((bank_id==" ")||(bank_id==null))){ seterror_msg("Select Bank for Bank Purchases !");return; }
  //Credit Transation
  if((type=="credit")&&((supplier_name==" ")||(supplier_name==null))){ seterror_msg("Enter Supplier Name for Credit Transaction !");return; }

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))){
 
 
  axios.post("bt-create-purchases", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Date : moment(date).format('YYYY-MM-DD'),
   Type:type,
   Bank_id:bank_id,
   Voucher_no : voucher_no,
   Gstin : gstin,
   License_no:license_no,
   Supplier_id:supplier_id,
   Supplier_name:supplier_name,
   Supplier_email:supplier_email,
   Supplier_contact:supplier_contact,
   Supplier_wtp:supplier_wtp,
   Supplier_address:supplier_address,
   Supplier_city:supplier_city,
   Supplier_state:supplier_state,
   Supplier_country:supplier_country,
   Supplier_pincode:supplier_pincode
 })
 .then((response) => {


  //Invalid Entries
  if(response.data.error==105){     seterror_msg("Invoice Number Already Exists ! ");  return; }
  if(response.data.error==104){     seterror_msg("Select Bank! "); return; }
  if(response.data.error==103){     seterror_msg(response.data.error_msg);  return; }
  if(response.data.error==111){     seterror_msg(response.data.error_msg); return; }

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");

  }

   //Validation Errors
 if(response.data.validatorerror){
  setdate_er(response.data.validatorerror.Date);
  settype_er(response.data.validatorerror.Type);
  setvoucher_no_er(response.data.validatorerror.Voucher_no);
  setgstin_er(response.data.validatorerror.Gstin);
  setlicense_no_er(response.data.validatorerror.License_no);
  setsupplier_id_er(response.data.validatorerror.Supplier_id);
  setsupplier_name_er(response.data.validatorerror.Supplier_name);
  setsupplier_email_er(response.data.validatorerror.Supplier_email);
  setsupplier_contact_er(response.data.validatorerror.Supplier_contact);
  setsupplier_wtp_er(response.data.validatorerror.Supplier_wtp);
  setsupplier_address_er(response.data.validatorerror.Supplier_address);
  setsupplier_city_er(response.data.validatorerror.Supplier_city);
  setsupplier_state_er(response.data.validatorerror.Supplier_state);
  setsupplier_country_er(response.data.validatorerror.Supplier_country);
  setsupplier_pincode_er(response.data.validatorerror.Supplier_pincode);
  return;

}
//Invalid Entries
if(response.data.error==102){ 
  
  setdate_er(response.data.date_er);
  settype_er(response.data.type_er);
  setvoucher_no_er(response.data.voucher_no_er);
  setgstin_er(response.data.gstin_er);
  setlicense_no_er(response.data.license_no_er);
  setsupplier_id_er(response.data.supplier_id_er);
  setsupplier_name_er(response.data.supplier_name_er);
  setsupplier_email_er(response.data.supplier_email_er);
  setsupplier_contact_er(response.data.supplier_contact_er);
  setsupplier_wtp_er(response.data.supplier_wtp_er);
  setsupplier_address_er(response.data.supplier_address_er);
  setsupplier_city_er(response.data.supplier_city_er);
  setsupplier_state_er(response.data.supplier_state_er);
  setsupplier_country_er(response.data.supplier_country_er);
  setsupplier_pincode_er(response.data.supplier_pincode_er);
 
  seterror_msg("Cannot Add Transaction!");  return; }

   

   //Transaction Entered Successfully
   if(response.data.successvar==1){        sessionStorage.setItem("Trno00",response.data.Tr_no); document.getElementById("success-card").style.display="block";  }
   //Cannot Add Transaction
   else{  seterror_msg("Cannot Add Transaction !"); }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* CREATE PURCHASES FUNCTION END ********************//


 
 
//******************* ADD SUPPLIER FUNCTION START ********************//
function addsupplier(){

  //Setting Error Variables
  setsupplier_name_er(" ");setsupplier_email_er(" ");setsupplier_contact_er(" ");setsupplier_wtp_er(" ");setsupplier_address_er();setsupplier_city_er();setsupplier_state_er();setsupplier_country_er();setsupplier_pincode_er();/*settype_er();setcategory_er();*/setgstin_er();setlicense_no_er();seterror_msg(" ");
  
  
  
    //Checking if user loggedin
    if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))){
   
   
    axios.post("bt-add-supplier", {
     Log_id:logid,
     Token:token,
     Busy_id:busyid,
     Counter_id:counterid,
     Name : supplier_name,
     Email:supplier_email,
     Contact : supplier_contact,
     Whatsapp:supplier_wtp,
     Address:supplier_address,
     City:supplier_city,
     State:supplier_state,
     Country:supplier_country,
     Pincode:supplier_pincode,
     /*Type:type,
     Category:category,*/
     Gstin:gstin,
     License_no:license_no
  
  
    })
    .then((response) => {
  
        //suppliers Limit Reached
        if(response.data.error==111){     seterror_msg(response.data.error_msg); return; }
  
        
    if((response.data.error)==100){ 
        //Remove Session Variables and redirect to login
       sessionStorage.removeItem("logStatus00");
       sessionStorage.removeItem("logId00");
       sessionStorage.removeItem("logToken00");
       sessionStorage.removeItem("BusyId00");
       sessionStorage.removeItem("BusyName00");
       sessionStorage.removeItem("CounterId00");
  
       window.location.replace("businesstools-login");
    }
  
     //Validation Errors
   if(response.data.validatorerror){
    setsupplier_name_er(response.data.validatorerror.Name);
    setsupplier_email_er(response.data.validatorerror.Email);
    setsupplier_contact_er(response.data.validatorerror.Contact);
    setsupplier_wtp_er(response.data.validatorerror.Whatsapp);
    setsupplier_address_er(response.data.validatorerror.Address);
    setsupplier_city_er(response.data.validatorerror.City);
    setsupplier_state_er(response.data.validatorerror.State);  
    setsupplier_country_er(response.data.validatorerror.Country);
    setsupplier_pincode_er(response.data.validatorerror.Pincode);  
   /* settype_er(response.data.validatorerror.Type);
    setcategory_er(response.data.validatorerror.Category); */
    setgstin_er(response.data.validatorerror.Gstin);
    setlicense_no_er(response.data.validatorerror.License_no);
  
  }
  //Invalid Entries
  if(response.data.error==102){ 
    
    setsupplier_name_er(response.data.name_er);
    setsupplier_email_er(response.data.email_er);
    setsupplier_contact_er(response.data.contact_er);
    setsupplier_wtp_er(response.data.whatsapp_er);
    setsupplier_address_er(response.data.address_er);
    setsupplier_city_er(response.data.city_er);
    setsupplier_state_er(response.data.state_er);
    setsupplier_country_er(response.data.country_er);
    setsupplier_pincode_er(response.data.pincode_er);
   /* settype_er(response.data.type_er);
    setcategory_er(response.data.category_er); */
    setgstin_er(response.data.gstin_er);
    setlicense_no_er(response.data.license_no_er);
    seterror_msg("Cannot Add Supplier!");  }
  
     //Transaction Entered Successfully
     if(response.data.successvar==1){     
     // setccsuccess(1);
      document.getElementById("cst-supplier").style.display="block";  }
     //Cannot Add Transaction
     else{  seterror_msg("Cannot Add Supplier !"); }
    
  
  });
  }
   //User not Logedin
   else{
   
   //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");
  
     window.location.replace("businesstools-login");
   
    }
   
   }
    
   //******************* ADD SUPPLIER FUNCTION END ********************//
  

return(
    <>

        {/* supplierS LIST BOX START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-4 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"1rem",display:"none"}} id="suppliers-list-box">
                <div class="card">
                <div class="d-flex justify-content-between">
                    <div>
                    <h4 style={{padding:'1rem'}}>Suppliers</h4>
                    </div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ setcdefault(1); document.getElementById("suppliers-list-box").style.display="none"; }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                    
                    <table class="table table-hover">
                      <thead>
                        <tr>
                          {/* <th>Supplier Id</th> */}
                          <th>Supplier Name</th>
                         {/*} <th>Email</th> */}
                          <th>Contact</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                       suppliers.map((curElm7,ind)=>{

                         return(
                         <>
                        <tr key={curElm7.Supplier_id}>
                          {/* <td>{curElm7.Supplier_id}</td> */}
                          <td>{curElm7.Supplier_name}</td>
                          {/* <td>{curElm7.Email}</td> */}
                          <td>{curElm7.Contact}</td>
                          <td><i class="mdi mdi-assistant text-warning" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                        //Setting supplier Details for 
                        setsupplier_name(curElm7.Supplier_name);setsupplier_id(curElm7.Supplier_id);setsupplier_contact(curElm7.Contact);setsupplier_wtp(curElm7.Whastapp);setsupplier_email(curElm7.Email);setgstin(curElm7.Gstin);setlicense_no(curElm7.License_no);
                        setsupplier_wtp(curElm7.Whatsapp);setsupplier_address(curElm7.Address);setsupplier_city(curElm7.City);setsupplier_state(curElm7.State);setsupplier_country(curElm7.Country);setsupplier_pincode(curElm7.Pincode);
                        document.getElementById("suppliers-list-box").style.display="none";
                              }}></i></td>

                        </tr>
                        </>
                         )
                         }
					   
	                  		 )
                         }
                      </tbody>
                    </table>
              
                </div>
              </div>
              </div>
              {/* supplierS LIST BOX END */}


                <div class="card">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("create-form").style.display="none"; }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                  <div class="card-body">
                    <h4 class="card-title">Create purchases</h4>
                    <div class="form-sample">
                      <p class="card-description">add your purchases details here</p>
                      <code>{error_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" id="date" value={date} onChange={updatedate} />
                              <code>{date_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Invoice No.<code>*</code></label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="VN87766" id="voucher_no" value={voucher_no} onChange={updatevoucher_no} />
                            <code>{voucher_no_er}</code>
                            </div>
                          </div>
                        </div>   
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type<code>*</code></label>
                            <div class="col-sm-9">
                              <select class="form-control" style={{padding:"1rem"}} id="type" value={type} onChange={updatetype} >
                              <option>Select Type</option>
                              <option value="cash">Cash</option>
                              <option value="bank">Bank</option>
                              <option value="credit">Credit</option>
                              </select>
                              <code>{type_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Bank</label>
                            <div class="col-sm-9">
                            <select class="form-control" style={{padding:"1rem"}} placeholder="Select Bank" id="bank_id" value={bank_id} onChange={updatebank_id}>
                              <option value="">Select Bank</option>
                              {
                              Tbanks.map((curElm2,ind)=>{

                              return(
                              <>
                              <option value={curElm2.Bank_id}>{curElm2.Bank_name}</option>
                              </>
                              )
                              }
	 				   
	                         		)
                              }
                              </select>
                              <code>{bank_id_er}</code>
                            </div>
                          </div>
                        </div>

                      </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Supplier Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Suman Soni Sharma" id="supplier_name" value={supplier_name} onChange={updatesupplier_name}/>
                              <code>{supplier_name_er}</code>
                              <button class="btn btn-light" hidden={suppliers_count>0} onClick={addsupplier}>Add this as new supplier ....</button>
                              <p class="text-success" style={{display:"none"}} id="cst-supplier"><b>New Supplier Added!</b></p>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Contact</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="91 8000000000" id="supplier_contact" value={supplier_contact} onChange={updatesupplier_contact} />
                            <code>{supplier_contact_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Email</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Sumansonisharma34@gmail.com" id="supplier_email" value={supplier_email} onChange={updatesupplier_email} />
                            <code>{supplier_email_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">WhatsApp</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="91 8000000000" id="supplier_wtp" value={supplier_wtp} onChange={updatesupplier_wtp} />
                            <code>{supplier_wtp_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">GSTIN</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="HGSSSFS55S4S4S4" id="gstin" value={gstin} onChange={updategstin} />
                              <code>{gstin_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">License No.</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="LIC9900000" id="license_no" value={license_no} onChange={updatelicense_no} />
                              <code>{license_no_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="form-group">
                        <label for="exampleTextarea1">Address</label>
                        <textarea class="form-control" rows="2"  id="supplier_address" value={supplier_address} onChange={updatesupplier_address} ></textarea>
                        <code>{supplier_address_er}</code>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">City</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Lucknow" id="supplier_city" value={supplier_city} onChange={updatesupplier_city} />
                              <code>{supplier_city_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">State</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Uttar Pradesh" id="supplier_state" value={supplier_state} onChange={updatesupplier_state} />
                              <code>{supplier_state_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Country</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="India"  id="supplier_country" value={supplier_country} onChange={updatesupplier_country}/>
                              <code>{supplier_country_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Pincode</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" pincode="420110" id="supplier_pincode" value={supplier_pincode} onChange={updatesupplier_pincode} />
                              <code>{supplier_pincode_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                    
                        
                      
                        <button class="btn btn-primary" style={{fontWeight:"bold"}} onClick={createpurchases}>Create</button>
                      
                       
    
                     
                   
                    </div>
                  </div>
                </div>
    </>

);

}
//}

export default Create_purchases;