import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';



/*import Amanbg from './amanbg';*/


function Sidebar(){

  
  const userlogtype=sessionStorage.getItem("userlogintype00");//logintype


  if(userlogtype=="team"){
     
    return(
<>

{/* SIDEBAR START */}

<nav class="sidebar sidebar-offcanvas" id="sidebar" style={{marginTop:"5vh"}}>
          <ul class="nav">
           {/*}  <li class="nav-item nav-profile">
              <a href="#" class="nav-link">
                <div class="nav-profile-image">
                  <img src="assets/images/faces/face1.jpg" alt="profile" />
                  <span class="login-status online"></span>
                  {change to offline or busy as needed}
                </div>
               <div class="nav-profile-text d-flex flex-column">
                  <span class="font-weight-bold mb-2">David Grey. H</span>
                  <span class="text-secondary text-small">Project Manager</span>
                </div> 

                <i class="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
              </a>
            </li> */}
            <li class="nav-item">
              <a class="nav-link" href="/team-quotations">
                <span class="menu-title">Quotations</span>
                <i class="mdi mdi-account-multiple-plus menu-icon"></i>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/team-orders">
                <span class="menu-title">Orders</span>
                <i class="mdi mdi-account-multiple-plus menu-icon"></i>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/team-projects">
                <span class="menu-title">My Projects</span>
                <i class="mdi mdi-access-point menu-icon"></i>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/team-payments">
                <span class="menu-title">Payments</span>
                <i class="mdi mdi-account-check menu-icon"></i>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/team-feedbacks">
                <span class="menu-title">Feedbacks</span>
                <i class="mdi mdi-account-multiple-plus menu-icon"></i>
              </a>
            </li>
        {/*}   <li>
            <a href="/team-project-create"><button class="btn btn-block btn-lg btn-gradient-primary mt-4">+ Add a project</button></a>
            </li>
             */}
   
          
          </ul>
        </nav>

{/* SIDEBAR END */}
</>
    );

          }
else{
  return(
    <>
    
    {/* SIDEBAR START */}
    
    <nav class="sidebar sidebar-offcanvas" id="sidebar" style={{marginTop:"5vh"}}>
              <ul class="nav">
               {/*}  <li class="nav-item nav-profile">
                  <a href="#" class="nav-link">
                    <div class="nav-profile-image">
                      <img src="assets/images/faces/face1.jpg" alt="profile" />
                      <span class="login-status online"></span>
                      {change to offline or busy as needed}
                    </div>
                   <div class="nav-profile-text d-flex flex-column">
                      <span class="font-weight-bold mb-2">David Grey. H</span>
                      <span class="text-secondary text-small">Project Manager</span>
                    </div> 
    
                    <i class="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
                  </a>
                </li> */}
                <li class="nav-item">
                 <a class="nav-link" href="/client-quotations">
                   <span class="menu-title">Quotations</span>
                   <i class="mdi mdi-account-multiple-plus menu-icon"></i>
                 </a>
                </li>
                <li class="nav-item">
                 <a class="nav-link" href="/client-orders">
                  <span class="menu-title">Orders</span>
                  <i class="mdi mdi-account-multiple-plus menu-icon"></i>
                 </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/client-projects">
                    <span class="menu-title">My Projects</span>
                    <i class="mdi mdi-access-point menu-icon"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/client-payments">
                    <span class="menu-title">Payments</span>
                    <i class="mdi mdi-account-check menu-icon"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/client-feedbacks">
                    <span class="menu-title">Feedbacks</span>
                    <i class="mdi mdi-account-multiple-plus menu-icon"></i>
                  </a>
                </li>
           {/*}     <li>
                <a href="/project-create"><button class="btn btn-block btn-lg btn-gradient-primary mt-4">+ Add a project</button></a>
                </li>
               */}  
       
              
              </ul>
            </nav>
    
    {/* SIDEBAR END */}
    </>
        );
}






}

export default Sidebar;