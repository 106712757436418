import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import Navbar from './navbar';
import Leftsidebar from './leftsidebar';
import Rightsidebar from './rightsidebar';



//import '../hireportalcss.css';
//import '../Hireportalcss/hireportalcss.css';

//Importing Page css with screen sizes
import '../Hireportalcss/sdefault.css';
import '../Hireportalcss/s1200.css';
import '../Hireportalcss/s920.css';
import '../Hireportalcss/s768.css';
import '../Hireportalcss/s600.css';
import '../Hireportalcss/sanimation.css';

//Location Api
import './locationapi.js';






function Candidatesaveddashboard(){

    return(

        <>
        <div class="default-view">
        <Navbar/>
        {/* MAIN SECTION START */}
        <div class="hire-main-section">
        <Leftsidebar/>
      
        {/* MAIN PANEL START */}
      <div class="hire-main-panel">
      <h2>Saved Jobs</h2>
        {/* SEARCH BAR START */}
        <div class="hire-search-bar">

        </div>
        {/* SEARCH BAR END */}

        {/* LIST PANEL START */}
        <div class="list-panel">
        <div style={{width:"100%",height:"2vh"}}></div>
        {/* HIRE LIST START */}  
        <div class="hire-list">
            
        {/* LIST CARD 1 START */}
        <div class="hire-list-card">
        <div class="hire-list-card-top">
        <div class="hire-list-card-top-l"><h3>Full Stack Developer</h3></div>
         <div class="hire-list-card-top-r"><p>Company ABC Private Limited</p></div>
        </div>
        <div class="hire-list-card-area">
        <div class="hire-list-card-area-l">
        <div class="hire-list-card-salarycard">
          <p><b>Salary/CTC :</b> 12000-35000 per month</p>
          <p><b>Summary</b></p>
          <p>Passionate Full Stack Web Developer with a proven track record of creating robust,
            user-friendly, and visually appealing web applications. Proficient in both front-end and
            back-end technologies, I thrive in collaborative environments to turn innovative ideas
            into functional, scalable, and efficient digital solutions.
            <br/>
            Passionate Full Stack Web Developer with a proven track record of creating robust,
            user-friendly, and visually appealing web applications.</p>
        </div>
        </div> 
        <div class="hire-list-card-area-r">

        <div class="hire-list-card-badge">
          <span>Full Time</span>
        </div>

        <div class="hire-list-card-badge">
          <span>Day Shift</span>
        </div>

        <div class="hire-list-card-badge" style={{backgroundColor:"#ffffff",border:"0.1rem solid #013cb1",color:"#1a1b1d"}}>
        <div class="hire-list-card-badge-component">
        <div class="hire-list-card-badge-component-l"><img src="https://cdn-icons-png.flaticon.com/128/3281/3281289.png" /></div>
        <div class="hire-list-card-badge-component-r"><span>3 years</span></div>
        </div>
        </div>

        <div class="hire-list-card-badge" style={{backgroundColor:"#e00f36",color:"#ffffff"}}>
          <span><b>Deadline : </b>24-05-2023</span>
        </div>

        <div class="hire-list-card-badge">
          <span><b>Openings : </b>24</span>
        </div>

        <div class="hire-list-card-badge">
          <span><b>Industry: </b>IT/ Tech</span>
        </div>
        </div>  
        </div>
        <div class="hire-list-card-btn-list">
        <div>
          <button class="hire-btn-default" onClick={()=>{ window.location.replace("/candidate-apply"); }} >Apply Now</button>
          <button class="hire-btn-default" style={{backgroundColor:"#7c077a"}}>View Job Description</button>
        </div>
        
        <div class="hire-list-card-function">
        <div class="hire-list-card-function-component">
        <div class="hire-list-card-function-component-l">
        <button><img src="https://cdn-icons-png.flaticon.com/128/2107/2107845.png" /></button></div>
        <div class="hire-list-card-function-component-r"><span><b>45</b></span></div>
        </div>
        </div>

        <div class="hire-list-card-function">
        <div class="hire-list-card-function-component">
        <div class="hire-list-card-function-component-l">
        <button><img src="https://cdn-icons-png.flaticon.com/128/942/942748.png" /></button></div>
        <div class="hire-list-card-function-component-r"><span><b>15000</b></span></div>
        </div>
        </div>

        <div class="hire-list-card-function">
        <div class="hire-list-card-function-component">
        <div class="hire-list-card-function-component-l">
        <button><img src="https://cdn-icons-png.flaticon.com/128/5186/5186087.png" /></button></div>
        <div class="hire-list-card-function-component-r"><span><b>45</b></span></div>
        </div>
        </div>

        

        </div>

        <div class="hire-list-card-skillbar">
        <div class="hire-card-skill-badge">
        <span>MySql</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>Web Development</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>React Js</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>Laravel</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>HTML/CSS</span>
        </div>  
       
        </div>

        <div class="hire-list-card-bottom">
          <p><b>Posted :</b> 23-01-2022   <b>Last Seen : </b> 1 Day Ago</p>
        </div>

        <div style={{height:"0.5rem"}}></div>
        </div>

        {/* LIST CARD 1 START */}

        {/* LIST CARD 2 START */}
        <div class="hire-list-card">
        <div class="hire-list-card-top">
        <div class="hire-list-card-top-l"><h3>Full Stack Developer</h3></div>
         <div class="hire-list-card-top-r"><p>Company ABC Private Limited</p></div>
        </div>
        <div class="hire-list-card-area">
        <div class="hire-list-card-area-l">
        <div class="hire-list-card-salarycard">
          <p><b>Salary/CTC :</b> 12000-35000 per month</p>
          <p><b>Summary</b></p>
          <p>Passionate Full Stack Web Developer with a proven track record of creating robust,
            user-friendly, and visually appealing web applications. Proficient in both front-end and
            back-end technologies, I thrive in collaborative environments to turn innovative ideas
            into functional, scalable, and efficient digital solutions.
            <br/>
            Passionate Full Stack Web Developer with a proven track record of creating robust,
            user-friendly, and visually appealing web applications.</p>
        </div>
        </div> 
        <div class="hire-list-card-area-r">

        <div class="hire-list-card-badge">
          <span>Full Time</span>
        </div>

        <div class="hire-list-card-badge">
          <span>Day Shift</span>
        </div>

        <div class="hire-list-card-badge" style={{backgroundColor:"#ffffff",border:"0.1rem solid #013cb1",color:"#1a1b1d"}}>
        <div class="hire-list-card-badge-component">
        <div class="hire-list-card-badge-component-l"><img src="https://cdn-icons-png.flaticon.com/128/3281/3281289.png" /></div>
        <div class="hire-list-card-badge-component-r"><span>3 years</span></div>
        </div>
        </div>

        <div class="hire-list-card-badge" style={{backgroundColor:"#e00f36",color:"#ffffff"}}>
          <span><b>Deadline : </b>24-05-2023</span>
        </div>

        <div class="hire-list-card-badge">
          <span><b>Openings : </b>24</span>
        </div>

        <div class="hire-list-card-badge">
          <span><b>Industry: </b>IT/ Tech</span>
        </div>
        </div>  
        </div>
        <div class="hire-list-card-btn-list">
        <div>
          <button class="hire-btn-default" onClick={()=>{ window.location.replace("/candidate-apply"); }} >Apply Now</button>
          <button class="hire-btn-default" style={{backgroundColor:"#7c077a"}}>View Job Description</button>
        </div>
        
        <div class="hire-list-card-function">
        <div class="hire-list-card-function-component">
        <div class="hire-list-card-function-component-l">
        <button><img src="https://cdn-icons-png.flaticon.com/128/2107/2107845.png" /></button></div>
        <div class="hire-list-card-function-component-r"><span><b>45</b></span></div>
        </div>
        </div>

        <div class="hire-list-card-function">
        <div class="hire-list-card-function-component">
        <div class="hire-list-card-function-component-l">
        <button><img src="https://cdn-icons-png.flaticon.com/128/942/942748.png" /></button></div>
        <div class="hire-list-card-function-component-r"><span><b>15000</b></span></div>
        </div>
        </div>

        <div class="hire-list-card-function">
        <div class="hire-list-card-function-component">
        <div class="hire-list-card-function-component-l">
        <button><img src="https://cdn-icons-png.flaticon.com/128/5186/5186087.png" /></button></div>
        <div class="hire-list-card-function-component-r"><span><b>45</b></span></div>
        </div>
        </div>

        

        </div>

        <div class="hire-list-card-skillbar">
        <div class="hire-card-skill-badge">
        <span>MySql</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>Web Development</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>React Js</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>Laravel</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>HTML/CSS</span>
        </div>  
       
        </div>

        <div class="hire-list-card-bottom">
          <p><b>Posted :</b> 23-01-2022   <b>Last Seen : </b> 1 Day Ago</p>
        </div>

        <div style={{height:"0.5rem"}}></div>
        </div>

        {/* LIST CARD 2 START */}
        {/* LIST CARD 3 START */}
        <div class="hire-list-card">
        <div class="hire-list-card-top">
        <div class="hire-list-card-top-l"><h3>Full Stack Developer</h3></div>
         <div class="hire-list-card-top-r"><p>Company ABC Private Limited</p></div>
        </div>
        <div class="hire-list-card-area">
        <div class="hire-list-card-area-l">
        <div class="hire-list-card-salarycard">
          <p><b>Salary/CTC :</b> 12000-35000 per month</p>
          <p><b>Summary</b></p>
          <p>Passionate Full Stack Web Developer with a proven track record of creating robust,
            user-friendly, and visually appealing web applications. Proficient in both front-end and
            back-end technologies, I thrive in collaborative environments to turn innovative ideas
            into functional, scalable, and efficient digital solutions.
            <br/>
            Passionate Full Stack Web Developer with a proven track record of creating robust,
            user-friendly, and visually appealing web applications.</p>
        </div>
        </div> 
        <div class="hire-list-card-area-r">

        <div class="hire-list-card-badge">
          <span>Full Time</span>
        </div>

        <div class="hire-list-card-badge">
          <span>Day Shift</span>
        </div>

        <div class="hire-list-card-badge" style={{backgroundColor:"#ffffff",border:"0.1rem solid #013cb1",color:"#1a1b1d"}}>
        <div class="hire-list-card-badge-component">
        <div class="hire-list-card-badge-component-l"><img src="https://cdn-icons-png.flaticon.com/128/3281/3281289.png" /></div>
        <div class="hire-list-card-badge-component-r"><span>3 years</span></div>
        </div>
        </div>

        <div class="hire-list-card-badge" style={{backgroundColor:"#e00f36",color:"#ffffff"}}>
          <span><b>Deadline : </b>24-05-2023</span>
        </div>

        <div class="hire-list-card-badge">
          <span><b>Openings : </b>24</span>
        </div>

        <div class="hire-list-card-badge">
          <span><b>Industry: </b>IT/ Tech</span>
        </div>
        </div>  
        </div>
        <div class="hire-list-card-btn-list">
        <div>
          <button class="hire-btn-default" onClick={()=>{ window.location.replace("/candidate-apply"); }} >Apply Now</button>
          <button class="hire-btn-default" style={{backgroundColor:"#7c077a"}}>View Job Description</button>
        </div>
        
        <div class="hire-list-card-function">
        <div class="hire-list-card-function-component">
        <div class="hire-list-card-function-component-l">
        <button><img src="https://cdn-icons-png.flaticon.com/128/2107/2107845.png" /></button></div>
        <div class="hire-list-card-function-component-r"><span><b>45</b></span></div>
        </div>
        </div>

        <div class="hire-list-card-function">
        <div class="hire-list-card-function-component">
        <div class="hire-list-card-function-component-l">
        <button><img src="https://cdn-icons-png.flaticon.com/128/942/942748.png" /></button></div>
        <div class="hire-list-card-function-component-r"><span><b>15000</b></span></div>
        </div>
        </div>

        <div class="hire-list-card-function">
        <div class="hire-list-card-function-component">
        <div class="hire-list-card-function-component-l">
        <button><img src="https://cdn-icons-png.flaticon.com/128/5186/5186087.png" /></button></div>
        <div class="hire-list-card-function-component-r"><span><b>45</b></span></div>
        </div>
        </div>

        

        </div>

        <div class="hire-list-card-skillbar">
        <div class="hire-card-skill-badge">
        <span>MySql</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>Web Development</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>React Js</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>Laravel</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>HTML/CSS</span>
        </div>  
       
        </div>

        <div class="hire-list-card-bottom">
          <p><b>Posted :</b> 23-01-2022   <b>Last Seen : </b> 1 Day Ago</p>
        </div>

        <div style={{height:"0.5rem"}}></div>
        </div>

        {/* LIST CARD 3 START */}
        {/* LIST CARD 4 START */}
        <div class="hire-list-card">
        <div class="hire-list-card-top">
        <div class="hire-list-card-top-l"><h3>Full Stack Developer</h3></div>
         <div class="hire-list-card-top-r"><p>Company ABC Private Limited</p></div>
        </div>
        <div class="hire-list-card-area">
        <div class="hire-list-card-area-l">
        <div class="hire-list-card-salarycard">
          <p><b>Salary/CTC :</b> 12000-35000 per month</p>
          <p><b>Summary</b></p>
          <p>Passionate Full Stack Web Developer with a proven track record of creating robust,
            user-friendly, and visually appealing web applications. Proficient in both front-end and
            back-end technologies, I thrive in collaborative environments to turn innovative ideas
            into functional, scalable, and efficient digital solutions.
            <br/>
            Passionate Full Stack Web Developer with a proven track record of creating robust,
            user-friendly, and visually appealing web applications.</p>
        </div>
        </div> 
        <div class="hire-list-card-area-r">

        <div class="hire-list-card-badge">
          <span>Full Time</span>
        </div>

        <div class="hire-list-card-badge">
          <span>Day Shift</span>
        </div>

        <div class="hire-list-card-badge" style={{backgroundColor:"#ffffff",border:"0.1rem solid #013cb1",color:"#1a1b1d"}}>
        <div class="hire-list-card-badge-component">
        <div class="hire-list-card-badge-component-l"><img src="https://cdn-icons-png.flaticon.com/128/3281/3281289.png" /></div>
        <div class="hire-list-card-badge-component-r"><span>3 years</span></div>
        </div>
        </div>

        <div class="hire-list-card-badge" style={{backgroundColor:"#e00f36",color:"#ffffff"}}>
          <span><b>Deadline : </b>24-05-2023</span>
        </div>

        <div class="hire-list-card-badge">
          <span><b>Openings : </b>24</span>
        </div>

        <div class="hire-list-card-badge">
          <span><b>Industry: </b>IT/ Tech</span>
        </div>
        </div>  
        </div>
        <div class="hire-list-card-btn-list">
        <div>
          <button class="hire-btn-default" onClick={()=>{ window.location.replace("/candidate-apply"); }} >Apply Now</button>
          <button class="hire-btn-default" style={{backgroundColor:"#7c077a"}}>View Job Description</button>
        </div>
        
        <div class="hire-list-card-function">
        <div class="hire-list-card-function-component">
        <div class="hire-list-card-function-component-l">
        <button><img src="https://cdn-icons-png.flaticon.com/128/2107/2107845.png" /></button></div>
        <div class="hire-list-card-function-component-r"><span><b>45</b></span></div>
        </div>
        </div>

        <div class="hire-list-card-function">
        <div class="hire-list-card-function-component">
        <div class="hire-list-card-function-component-l">
        <button><img src="https://cdn-icons-png.flaticon.com/128/942/942748.png" /></button></div>
        <div class="hire-list-card-function-component-r"><span><b>15000</b></span></div>
        </div>
        </div>

        <div class="hire-list-card-function">
        <div class="hire-list-card-function-component">
        <div class="hire-list-card-function-component-l">
        <button><img src="https://cdn-icons-png.flaticon.com/128/5186/5186087.png" /></button></div>
        <div class="hire-list-card-function-component-r"><span><b>45</b></span></div>
        </div>
        </div>

        

        </div>

        <div class="hire-list-card-skillbar">
        <div class="hire-card-skill-badge">
        <span>MySql</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>Web Development</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>React Js</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>Laravel</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>HTML/CSS</span>
        </div>  
       
        </div>

        <div class="hire-list-card-bottom">
          <p><b>Posted :</b> 23-01-2022   <b>Last Seen : </b> 1 Day Ago</p>
        </div>

        <div style={{height:"0.5rem"}}></div>
        </div>

        {/* LIST CARD 4 START */}
        {/* LIST CARD 5 START */}
        <div class="hire-list-card">
        <div class="hire-list-card-top">
        <div class="hire-list-card-top-l"><h3>Full Stack Developer</h3></div>
         <div class="hire-list-card-top-r"><p>Company ABC Private Limited</p></div>
        </div>
        <div class="hire-list-card-area">
        <div class="hire-list-card-area-l">
        <div class="hire-list-card-salarycard">
          <p><b>Salary/CTC :</b> 12000-35000 per month</p>
          <p><b>Summary</b></p>
          <p>Passionate Full Stack Web Developer with a proven track record of creating robust,
            user-friendly, and visually appealing web applications. Proficient in both front-end and
            back-end technologies, I thrive in collaborative environments to turn innovative ideas
            into functional, scalable, and efficient digital solutions.
            <br/>
            Passionate Full Stack Web Developer with a proven track record of creating robust,
            user-friendly, and visually appealing web applications.</p>
        </div>
        </div> 
        <div class="hire-list-card-area-r">

        <div class="hire-list-card-badge">
          <span>Full Time</span>
        </div>

        <div class="hire-list-card-badge">
          <span>Day Shift</span>
        </div>

        <div class="hire-list-card-badge" style={{backgroundColor:"#ffffff",border:"0.1rem solid #013cb1",color:"#1a1b1d"}}>
        <div class="hire-list-card-badge-component">
        <div class="hire-list-card-badge-component-l"><img src="https://cdn-icons-png.flaticon.com/128/3281/3281289.png" /></div>
        <div class="hire-list-card-badge-component-r"><span>3 years</span></div>
        </div>
        </div>

        <div class="hire-list-card-badge" style={{backgroundColor:"#e00f36",color:"#ffffff"}}>
          <span><b>Deadline : </b>24-05-2023</span>
        </div>

        <div class="hire-list-card-badge">
          <span><b>Openings : </b>24</span>
        </div>

        <div class="hire-list-card-badge">
          <span><b>Industry: </b>IT/ Tech</span>
        </div>
        </div>  
        </div>
        <div class="hire-list-card-btn-list">
        <div>
          <button class="hire-btn-default" onClick={()=>{ window.location.replace("/candidate-apply"); }} >Apply Now</button>
          <button class="hire-btn-default" style={{backgroundColor:"#7c077a"}}>View Job Description</button>
        </div>
        
        <div class="hire-list-card-function">
        <div class="hire-list-card-function-component">
        <div class="hire-list-card-function-component-l">
        <button><img src="https://cdn-icons-png.flaticon.com/128/2107/2107845.png" /></button></div>
        <div class="hire-list-card-function-component-r"><span><b>45</b></span></div>
        </div>
        </div>

        <div class="hire-list-card-function">
        <div class="hire-list-card-function-component">
        <div class="hire-list-card-function-component-l">
        <button><img src="https://cdn-icons-png.flaticon.com/128/942/942748.png" /></button></div>
        <div class="hire-list-card-function-component-r"><span><b>15000</b></span></div>
        </div>
        </div>

        <div class="hire-list-card-function">
        <div class="hire-list-card-function-component">
        <div class="hire-list-card-function-component-l">
        <button><img src="https://cdn-icons-png.flaticon.com/128/5186/5186087.png" /></button></div>
        <div class="hire-list-card-function-component-r"><span><b>45</b></span></div>
        </div>
        </div>

        

        </div>

        <div class="hire-list-card-skillbar">
        <div class="hire-card-skill-badge">
        <span>MySql</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>Web Development</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>React Js</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>Laravel</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>HTML/CSS</span>
        </div>  
       
        </div>

        <div class="hire-list-card-bottom">
          <p><b>Posted :</b> 23-01-2022   <b>Last Seen : </b> 1 Day Ago</p>
        </div>

        <div style={{height:"0.5rem"}}></div>
        </div>

        {/* LIST CARD 5 START */}

        </div>
        {/* HIRE LIST END */}  
        </div>
        {/* LIST PANEL END */}

        </div>
        {/* MAIN PANEL END */} 

        <Rightsidebar/>

        </div>
        {/* MAIN SECTION END */}

        </div>

        </>
    );




}


export default Candidatesaveddashboard;