import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './Resources/homebtcss.css';
import './Resources/home2css.css';
import './Resources/serviceshomecss.css';
import './Resources/hometestcss.css';
import './Resources/responsiveness.css';

import Navbar3 from './Supportingfiles/navbar3';
import Footer3 from './Supportingfiles/footer3';

//import './test123.css';

function Hometest(){


      return(
      <>


<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />

    <meta name="theme-color" content="#000000" />
    <meta
      name="description"
      content="Get your startup services relating to website development, consultancy, taxation, business registration, software services, intern hiring, social media management, digital marketing and much more.  "></meta>
    
   {/* <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />*/}
        <link rel="icon" href="./images/startupconnect_icon.ico" />
    <link rel="apple-touch-icon" href="./images/startupconnect_icon.png" />

      {/* plugins:css */}
      <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/* endinject */}
    {/* Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/* End plugin css for this page */}
    {/* inject:css */}
    {/* endinject */}
    {/* Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/* End layout styles */}
    <link rel="shortcut icon" href="./images/startupconnect_icon.png" />


    {/*} Bootstrap CSS */}
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous" />
 

    <title>Startup Connect | Services, Networks, Hiring, Fundraising, Softwares</title>
  </head>
  <body>


  {/* Whatsapp Chat Icon Start */}
  <div class="wtp-chat-container" /*style={{position:"fixed",marginTop:"92vh", marginLeft:"94vw"}}*/>
    <a aria-label="Chat on WhatsApp" href="https://wa.me/919399395256?text=I%20want%20to%20know%20more%20about%20the%20Startup%20Connect">   
    <img class="wtp-chat-icon" src="https://cdn-icons-png.flaticon.com/128/3938/3938058.png" /*style={{height:"3rem", width:"3rem"}}*/></img>
  </a>
  </div>
  {/* Whatsapp Chat Icon End */}

  
  
    
  <Navbar3/>

   {/* HEADER SECTION START */}
   <div class="homet-header" style={{backgroundColor:"#ffffff", width:"100%"}} >
 <div class="home-header-pd">
 <h1 class="home-header-h1" /*style={{fontWeight:"bold",fontSize:"3.2rem",color:"#0b6674"}}*/ >Shaping the Future of India's Startup Ecosystem!</h1>
 <p class="home-header-p1" /* style={{fontSize:"1.4rem",marginTop:"2rem"}} */>Embark on your startup journey with Startup Connect. Whether you're a seasoned entrepreneur or just starting, our platform provides the resources and support you need to turn your vision into reality. Join us in shaping the future of Central India's startup landscape.</p>
 <p class="home-header-p2" /*style={{fontSize:"1.4rem",fontWeight:"bold"}}*/>Let's connect, collaborate, and create success together.</p>

 {/*<button class="btn-lg btn-success" style={{backgroundColor:"#0c61a6",border:"#ffffff", marginTop:"1rem"}}>Services <i class="mdi mdi-arrow-right" style={{color:"#ffffff"}}></i></button>
 
 <button class="btn-lg btn-success" style={{backgroundColor:"#0b91a5",border:"#ffffff",marginLeft:"1rem", marginTop:"1rem"}}>Startup <i class="mdi mdi-arrow-right" style={{color:"#ffffff"}}></i></button>

 <button class="btn-lg btn-success" style={{backgroundColor:"#0b91a5",border:"#ffffff", marginLeft:"1rem", marginTop:"1rem"}}>Hiring <i class="mdi mdi-arrow-right" style={{color:"#ffffff"}}></i></button>

 <button class="btn-lg btn-success" style={{backgroundColor:"#0b91a5",border:"#ffffff", marginLeft:"1rem", marginTop:"1rem"}}>Book-Keeping <i class="mdi mdi-arrow-right" style={{color:"#ffffff"}}></i></button>

 <button class="btn-lg btn-success" style={{backgroundColor:"#0b91a5",border:"#ffffff", marginLeft:"1rem", marginTop:"1rem"}}>Contests <i class="mdi mdi-arrow-right" style={{color:"#ffffff"}}></i></button>
*/}


<a href="/services-home"><button id="home2-header-main-btn">Services <i class="mdi mdi-arrow-right"></i></button></a>
<a href="/startups-network"><button id="home2-header-main-btn" style={{backgroundImage: "linear-gradient(to right, #8d1777, #ad0082)"}}>Startups <i class="mdi mdi-arrow-right"></i></button></a>
<a href="https://www.startuphire.in"><button id="home2-header-main-btn" style={{backgroundImage: "linear-gradient(to right, #d4ba37, #fadc13)"}}>Hiring <i class="mdi mdi-arrow-right"></i></button></a>
<a href="/book-keeping"><button id="home2-header-main-btn" style={{backgroundImage: "linear-gradient(to right, #8d3717, #ad3900)"}}>Book Keeping<i class="mdi mdi-arrow-right"></i></button></a>
<a href="/contest-home"><button id="home2-header-main-btn" style={{backgroundImage: "linear-gradient(to right, #208d17, #00ad11)"}}>Contests <i class="mdi mdi-arrow-right"></i></button></a>

 
 
 </div>
 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0099ff" fill-opacity="1" d="M0,96L40,117.3C80,139,160,181,240,181.3C320,181,400,139,480,122.7C560,107,640,117,720,138.7C800,160,880,192,960,186.7C1040,181,1120,139,1200,117.3C1280,96,1360,96,1400,96L1440,96L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path></svg>

   </div>
   {/* HEADER SECTION END */}


   <div class="nwheader">
  <div>
     <div class="wave"></div>
     <div class="wave"></div>
     <div class="wave"></div>
  </div>
</div>


  <div class="home2-container">
 
   {/* HEADER 2 SECTION START */}
   <section /*class="sv-header-section" */>
    <div class="sv-header-container1">
    <div class="sv-header-left home-header-pd">
        <h1 style={{color:"#004463"}}>YOUR ONE STOP SOLUTION FOR ALL YOUR SERVICES NEEDS</h1>
      <p>best class services delivered by professionals in your budget. From business setup, compliances to monitoring, marketing, technology, growth, strategy and fundraising. Find all the services you need under one roof.</p>
      
        <p>Get Started with Services.</p>
        <div class="home2-services-features-list">
                    <div class="home2-services-fcard">
                       <div class="home2-services-fcard-left">
                        <img src="https://cdn-icons-png.flaticon.com/128/1087/1087840.png" id="home2-services-fcard-icon" />
                       </div>
                       <div class="home2-services-fcard-right">
                       <h3>500+</h3>
                       <h5 style={{fontWeight:"bold"}}>Projects</h5>
                       </div> 
                    </div>
                

                    <div class="home2-services-fcard">
                       <div class="home2-services-fcard-left">
                        <img src="https://cdn-icons-png.flaticon.com/128/12735/12735475.png" id="home2-services-fcard-icon" />
                       </div>
                       <div class="home2-services-fcard-right">
                       <h3>25+</h3>
                       <h5 style={{fontWeight:"bold"}}>Cities</h5>
                       </div> 
                    </div>
             

                    <div class="home2-services-fcard">
                       <div class="home2-services-fcard-left">
                        <img src="https://cdn-icons-png.flaticon.com/128/2991/2991235.png" id="home2-services-fcard-icon" />
                       </div>
                       <div class="home2-services-fcard-right">
                       <h3>4 <img src="https://cdn-icons-png.flaticon.com/128/7656/7656139.png" style={{height:"2rem",width:"2rem"}} />+</h3>
                       <h5 style={{fontWeight:"bold"}}>Google Ratings</h5>
                       </div> 
                    </div>

                </div>
              <div  class="serviceshome-btn-container">
             <div>
             <a href="/services-home"><button class="btn-lft-margin" id="home2-modules-section-btn" style={{marginLeft:"0"}}>Get Started <i class="mdi mdi-arrow-right"></i></button></a>
             </div>
             <div style={{marginLeft:"0"}}>
             <a href="/home1/startupbrochure1.pdf" download="Startup Connect Services Brochure"><button class="btn-lft-margin" id="home2-modules-section-btn" style={{backgroundImage: "linear-gradient(to right, #208d17, #00ad11)", marginLeft:"0"}}>Download Brochure <i class="mdi mdi-download"></i></button></a>
             </div>
             </div>

       
    </div>
    <div class="sv-header-right" /* style={{backgroundColor:"#96dfff",padding:"2rem",borderRadius:"15px",boxShadow:"2px 3px 3px 2px #cbcecf"}} */>
    <img src="/home1/setupgif1.gif" style={{width:"90%",height:"80%"/*,borderRadius:"15px",boxShadow:"2px 3px 3px 2px #cbcecf"*/}} />
    </div>
    </div>
    </section>
    {/* HEADER 2 SECTION END */}            

    <hr/>

    {/* HEADER 3 SECTION START */}
    <section /*class="sv-header-section" */>
    <div class="sv-header-container1">
    <div class="sv-header-right" /* style={{backgroundColor:"#96dfff",padding:"2rem",borderRadius:"15px",boxShadow:"2px 3px 3px 2px #cbcecf"}} */>
    <img src="/images/Btools/bt-view9.png" style={{width:"90%",height:"100%",borderRadius:"15px",boxShadow:"2px 3px 3px 2px #cbcecf"}} />
    </div>

    <div class="sv-header-left home-header-pd">
        <h1 style={{color:"#004463"}}>Start Your Book Keeping Journey <span id="change-txt-color" style={{color:"#e34802"}}></span></h1>
        <p>Rich in features with multiple businesses and inbuilt PoS and invoice managemenet. Want to get started with digitally managing your accounts. But, can't beacuse of hard to use and costly solutions.</p>
        <p>We have got you covered.</p>
        <div class="d-flex justify-content-left">
        <a href="/book-keeping"><button id="home2-modules-section-btn" style={{marginLeft:"0"}}>Get Started for Free <i class="mdi mdi-arrow-right"></i></button></a>
        </div>
        <p class="text-muted" style={{fontSize:"1rem",marginTop:"0.2rem"}}>No Credit Card Required*</p>
       
    </div>

    </div>
    </section>
    {/* HEADER 3 SECTION END */}

    <hr/>
    {/* STARTUP REGISTER SECTION */}
    <div class="new-home-startupreg-section">

    <div class="home-header-pd">
    <h2  class="home-header-h1" style={{/*marginTop:"2rem", fontSize:"3.2rem",*/ color:"#ffffff"/*,fontWeight:"bold"*/}}>REGISTER YOUR STARTUP WITH US!</h2>

     {/*}   <div style={{padding:"2rem",backgroundColor:"#000000",opacity:"0.8",height:"100%"}} >
        <div style={{marginTop:"2rem", zIndex:"inherit"}}>
     */}
        <p class="home-header-p1" style={{/*marginTop:"2rem", fontSize:"1.2rem", */color:"#ffffff"}}>Embark on a transformative journey with Startup Connect, where your startup dreams come to life. Ready to showcase your vision to a global audience, connect with experienced mentors, and explore funding opportunities? It's time to turn your ideas into reality. Join us and supercharge your entrepreneurial aspirations. Your breakthrough awaits—create your profile now and let's build the future together!</p>
        {/*} 
        </div> 
        </div> */}

        <button id="home2-modules-section-btn" style={{backgroundImage: "linear-gradient(to right, #8d1777, #ad0082)",float:"center"}} onClick={()=>{window.location.replace("/startups-network"); }}>Register my Startup<i class="mdi mdi-arrow-right"></i></button>


        </div>
    </div>
    {/* STARTUP REGISTER SECTION */}

     {/* FEATURES SECTION START */}
     <div class="bth-features-section"  style={{backgroundColor:"#e5ecf3"}}>
      <div class="bth-features-left">
      <p style={{color:"#ce30c1",fontWeight:"bold"}}>What's Special?</p>
      <h2>Startup Connect: <span style={{color:"#67179c"}}>Empowering Your Entrepreneurial Journey</span></h2>
      <p>
<b>Welcome to Startup Connect,</b> the all-in-one solution designed to empower startups in their entrepreneurial journey. Our platform is a dynamic ecosystem that integrates a wide array of features to address the diverse needs of emerging businesses. From an intuitive user-friendly dashboard to comprehensive tools covering bookkeeping, networking, fundraising, hiring, and beyond, Startup Connect serves as a central hub for startups to streamline their operations, foster collaborations, and achieve sustainable growth. 
<br/><br/>
Dive into a rich landscape of possibilities, where innovative features converge to create a seamless experience for every aspect of your startup venture. Explore the diverse functionalities that make Startup Connect the ultimate resource for startups seeking success in today's competitive business landscape.    </p>
      </div>
      <div class="bth-features-right">
      <div class="bth-features-elm-section">
        <div class="bth-features-elm-section-2">
        <div class="bth-features-elm-card" style={{backgroundColor:"#ffffff"}}>
          <h3>User-Friendly Interface</h3>
          <p style={{fontSize:"1rem"}}>Startup Connect boasts an intuitive dashboard designed for ease of use, offering users a comprehensive and customizable overview of key metrics and activities. Tailored widgets enable a personalized experience, allowing startups to efficiently manage their various operations and track essential data in real-time.</p>
        </div>

     
        <div class="bth-features-elm-card" style={{marginTop:"2rem",boxShadow:"2px 3px 3px 2px #d10bd1", backgroundColor:"#ffffff"}}>
          <h3>Contests and Challenges</h3>
          <p style={{fontSize:"1rem"}}>Engage with the startup ecosystem by participating in contests and challenges hosted on our platform. Startups can showcase their innovations, compete for prizes, and address industry-specific challenges, fostering creativity and problem-solving within the community.</p>
        </div>

        </div>
        <div class="bth-features-elm-section-2">
          
        <div class="bth-features-elm-card" style={{marginTop:"2rem",boxShadow:"2px 3px 3px 2px #ffba19", backgroundColor:"#ffffff"}}>
          <h3>Startup Networking Platform</h3>
          <p style={{fontSize:"1rem"}}>Fostering collaboration and idea-sharing, Startup Connect features a dedicated networking space where startups can connect with like-minded peers. User profiles with project showcases, industry-specific forums, and discussion groups create a vibrant community, promoting valuable connections and collaborations.</p>
        </div>
        <div class="bth-features-elm-card" style={{marginTop:"2rem",boxShadow:"2px 3px 3px 2px #049cb5", backgroundColor:"#ffffff"}}>
          <h3>Knowledge Base and Resources</h3>
          <p style={{fontSize:"1rem"}}>Empowering startups with knowledge, our platform features a comprehensive resource center. From articles and tutorials to educational webinars and workshops, startups can access a wealth of information to enhance their entrepreneurial skills. Legal and regulatory information ensures compliance with business requirements.</p>
        </div>
        </div>
      </div>

      </div>
    </div>
    {/* FEATURES SECTION END */}

    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>

  <Footer3/>
  </div>

     

    {/* plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/* endinject */}
    {/* Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/* End plugin js for this page */}
    {/* inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/* endinject */}
    {/* Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/* End custom js for this page */}

  </body>
</html>
</>
      );

}

export default Hometest;