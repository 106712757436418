import React from "react";
import { useState, useEffect, Component } from "react";
import { useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
//import axios from '../../axios.js';
import moment from "moment/moment";

import "../../Resources/homebtcss.css";
import "../../Resources/home2css.css";
import "../../Resources/serviceshomecss.css";
import "../ankitcss.css";
import '../../Resources/responsiveness.css';

//import Sidebar from "./sidebar";

//import Chart from "chart.js/auto";
//import { Line, Bar } from "react-chartjs-2";
import Footer3 from "../../Supportingfiles/footer3";
//import { Navbar } from "flowbite-react";
import Navbar3 from "../../Supportingfiles/navbar3";
import Whystartupconnect from "../whystartupconnect";
import Comparisontable from "./comparisontable";

function Partnershipregistration1() {

  

  /* Cashfree Button Style */

  const button_container = {
    border: "1px solid black",
    borderRadius: "5px",
    display: "flex",
    padding: "10px", 
    width: "fit-content",
    cursor: "pointer",
    background: "#0b6083",
    border:"none"
};

const text_container = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginLeft: "10px",
  justifyContent: "center",
  marginRight: "10px",
  fontWeight:"bold"
};
const logo_container = {
  width: "40px",
  height: "40px"
};
const secondary_logo_container = {
width: "16px",
height: "16px",
verticalAlign: "middle"
};

  return (
    <>
     <html lang="en">
        <head>
          {/*} Required meta tags */}
          <meta charset="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <title>STARTUP CONNECT | SERVICES | PARTNERSHIP REGISTRATION</title>
          {/*} plugins:css */}
          <link
            rel="stylesheet"
            href="panel/assets/vendors/mdi/css/materialdesignicons.min.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/css/vendor.bundle.base.css"
          />
          {/*} endinject */}
          {/*} Plugin css for this page */}
          <link
            rel="stylesheet"
            href="panel/assets/vendors/jquery-bar-rating/css-stars.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/font-awesome/css/font-awesome.min.css"
          />
          {/*} End plugin css for this page */}
          {/*} inject:css */}
          {/*} endinject */}
          {/*} Layout styles */}
          <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
          {/*} End layout styles */}
          <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
        </head>
        <body>
        
  {/* Whatsapp Chat Icon Start */}
  <div class="wtp-chat-container" /*style={{position:"fixed",marginTop:"92vh", marginLeft:"94vw"}}*/>
    <a aria-label="Chat on WhatsApp" href="https://wa.me/918480002413?text=I%20want%20to%20know%20more%20about%20service%20Partnership%20Registration%20by%20the%20Startup%20Connect">   
    <img class="wtp-chat-icon" src="https://cdn-icons-png.flaticon.com/128/3938/3938058.png" /*style={{height:"3rem", width:"3rem"}}*/></img>
  </a>
  </div>
  {/* Whatsapp Chat Icon End */}

          <div class="home2-container">
            <Navbar3 />

            {/* HEADER SECTION START */}
            <section /*class="sv-header-section" */>
              <div class="sv-header-container1">
                <div class="sv-header-left home-header-pd">
                  <h1 style={{ color: "#004463" }}>PARTNERSHIP REGISTRATION</h1>
                  <p>
               {/*}   <p style={{ fontWeight:"bold", marginBottom:"2rem" }}>
                     Begin your Journey with Startup Connect
                    </p>

                    <div style={{ marginBottom: "5px" }}>
                      <span style={{ paddingRight: "10px" }}><i class="mdi mdi-checkbox-marked" style={{fontSize:"2rem", color :"#0b5748"}}></i></span>
                      <span>Hassle-free documentation process.</span>
                    </div>
                    <div style={{ marginBottom: "5px" }}>
                      <span style={{ paddingRight: "10px" }}><i class="mdi mdi-checkbox-marked" style={{fontSize:"2rem", color :"#0b5748"}}></i></span>
                      <span>Comprehensive service packages to cater to all requirements.</span>
                    </div>
                    <div style={{ marginBottom: "5px" }}>
                      <span style={{ paddingRight: "10px" }}><i class="mdi mdi-checkbox-marked" style={{fontSize:"2rem", color :"#0b5748"}}></i></span>
                      <span>Quick response to queries and concerns.</span>
                    </div>
                    <div style={{ marginBottom: "5px" }}>
                      <span style={{ paddingRight: "10px" }}><i class="mdi mdi-checkbox-marked" style={{fontSize:"2rem", color :"#0b5748"}}></i></span>
                      <span>Transparent pricing with no hidden fees.</span>
                    </div>
                    <div style={{ marginBottom: "5px" }}>
                      <span style={{ paddingRight: "10px" }}><i class="mdi mdi-checkbox-marked" style={{fontSize:"2rem", color :"#0b5748"}}></i></span>
                      <span>User-friendly interface for a smooth experience.</span>
                    </div> */}
                    <div style={{ marginBottom: "2px" }}>
                      <span style={{ paddingRight: "5px" }}><i class="mdi mdi-checkbox-marked" style={{fontSize:"2rem", color :"#0b5748"}}></i></span>
                      <span>Fast customer support </span>
                    </div>
                    <div style={{ marginBottom: "2px" }}>
                      <span style={{ paddingRight: "5px" }}><i class="mdi mdi-checkbox-marked" style={{fontSize:"2rem", color :"#0b5748"}}></i></span>
                      <span>Complete online process.</span>
                    </div>
                    <div style={{ marginBottom: "2px" }}>
                      <span style={{ paddingRight: "5px" }}><i class="mdi mdi-checkbox-marked" style={{fontSize:"2rem", color :"#0b5748"}}></i></span>
                      <span>Instant WhatsApp/email updates </span>
                    </div>
                  </p>

                  <div class="home2-services-features-list">
                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img
                          loading="lazy"
                          src="https://cdn-icons-png.flaticon.com/128/1087/1087840.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>500+</h3>
                        <h5 style={{ fontWeight: "bold" }}>Projects</h5>
                      </div>
                    </div>

                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img
                          loading="lazy"
                          src="https://cdn-icons-png.flaticon.com/128/12735/12735475.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>25+</h3>
                        <h5 style={{ fontWeight: "bold" }}>Cities</h5>
                      </div>
                    </div>

                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img
                          loading="lazy"
                          src="https://cdn-icons-png.flaticon.com/128/2991/2991235.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>
                          4{" "}
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/7656/7656139.png"
                            style={{ height: "2rem", width: "2rem" }}
                          />
                          +
                        </h3>
                        <h5 style={{ fontWeight: "bold" }}>Google Ratings</h5>
                      </div>
                    </div>
                  </div>

                  <div  class="serviceshome-btn-container">
             <div>
             <a href="https://calendly.com/thestartupconnect/30min"><button class="btn-lft-margin" id="home2-modules-section-btn" style={{marginLeft:"0"}}>Book Free Consultancy <i class="mdi mdi-arrow-right"></i></button></a>
             </div>
             <div style={{marginLeft:"0"}}>
             <a href="/home1/partnership_registration.pdf" download="Startup Connect Services Brochure"><button class="btn-lft-margin" id="home2-modules-section-btn" style={{backgroundImage: "linear-gradient(to right, #208d17, #00ad11)", marginLeft:"0"}}>Download Brochure <i class="mdi mdi-download"></i></button></a>
             </div>
             </div>
             
                </div>
                <div
                  class="sv-header-right" /* style={{backgroundColor:"#96dfff",padding:"2rem",borderRadius:"15px",boxShadow:"2px 3px 3px 2px #cbcecf"}} */
                >
                  <img
                    loading="lazy"
                    src="https://img.freepik.com/free-vector/recruiting-agency-isometric-illustration-coworkers-candidate-employer-3d-cartoon-characters-headhunting-human-resources-department_575670-1091.jpg"
                    style={{ width: "90%", height: "100%" }}
                  />
                </div>
              </div>
            </section>
            {/* HEADER SECTION END */}
            <hr />

            {/* SIDEBAR AND MAIN PAGE START */}
            <div className="container-scroller">
              {/*<Sidebar /> */}
              <div className="container-fluid page-body-wrapper">
                {/*} partial */}
                <div className="main-panel">
           

                  {/* MORE INFORMATION SECTION START */}
                  <div class="row" style={{ padding: "2rem" }}>
                    <div
                      class="col-sm-9 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div>
                        <h2 style={{ fontWeight: "bold" }}>
                          WHAT IS{" "}
                          <span style={{ color: "#2da95c" }}>
                            PARTNERSHIP?
                          </span>
                        </h2>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                          {" "}
                          A partnership firm is one of the most important forms
                          of a business organization. It is a popular form of
                          business structure in India. A minimum of two persons
                          are required to establish a partnership firm. A
                          partnership firm is where two or more persons come
                          together to establish a business and divide its
                          profits amongst themselves in the agreed ratio. The
                          partnership business includes any kind of trade,
                          occupation and profession.
                        </p>

                        <p style={{ fontSize: "1rem" }}>
                          The Indian Partnership Act, 1932 governs and regulates
                          partnership firms in India. The persons who come
                          together to form the partnership firm are knowns as
                          partners. The partnership firm is constituted under a
                          contract between the partners. The contract between
                          the partners is known as a partnership deed which
                          regulates the relationship among the partners and also
                          between the partners and the partnership firm.{" "}
                        </p>
                      </div>
                    </div>
                    <div class="col-sm-3 stretch-card grid-margin">
                      <img
                        loading="lazy"
                        src="https://img.freepik.com/free-vector/business-deal-concept-illustration_114360-1535.jpg"
                        style={{ width: "90%", height: "100%" }}
                      />
                    </div>
                  </div>

                  <div class="row" style={{ padding: "2rem" }}>
                  <div class="col-sm-3 stretch-card grid-margin">
                      <img
                        loading="lazy"
                        src="https://img.freepik.com/free-vector/startup-partners-shaking-hands_74855-7874.jpg?w=740&t=st=1707211337~exp=1707211937~hmac=ad4cab1d2793afb2ad9afcef02a7878ea200e1357a17caddce7cc0c99ad95681"
                        style={{ width: "90%", height: "100%" }}
                      />
                    </div>
                    <div
                      class="col-sm-9 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div>
                        <h2 style={{ fontWeight: "bold" }}>
                         WHAT IS A {" "}
                          <span style={{ color: "#9f23c2" }}>
                            PARTNERSHIP DEED?
                          </span>
                        </h2>
                        <br />
                      
                          <p style={{ fontSize: "1rem" }}>
                          <i class="mdi mdi-assistant text-warning" style={{fontSize:"1.4rem",padding:"1rem"}}></i>
                            {" "}
                            A partnership deed is a legal document that outlines
                            the terms and conditions of a partnership. It
                            includes details such as the rights and duties of
                            partners, the distribution of profits, individual
                            capital contributions, and the partnership's
                            duration.
                          </p>

                          <p style={{ fontSize: "1rem" }}>
                          <i class="mdi mdi-assistant text-warning" style={{fontSize:"1.4rem",padding:"1rem"}}></i>
                            This document is significant as it helps prevent
                            misunderstandings and conflicts among partners by
                            clearly defining their roles and responsibilities.
                            Moreover, it serves as proof of the partnership's
                            existence and can be used in legal proceedings to
                            resolve disputes.{" "}
                          </p>

                          <p style={{ fontSize: "1rem" }}>
                          <i class="mdi mdi-assistant text-warning" style={{fontSize:"1.4rem",padding:"1rem"}}></i>
                          The terms of the Partnership Deed are often varied to suit the interests of the partners and may even be made contrary to the Indian Partnership Act, 1932 however in case, if the Partnership Deed is quiet on any point, then the arrangements of the Act would apply.{" "}
                          </p>

                      </div>
                    </div>
                   
                  </div>


                  <div class="row" style={{ padding: "2rem" }}>
                
                    <div
                      class="col-sm-6 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div>
                        <h2 style={{ fontWeight: "bold" }}>
                         CONTENTS OF A {" "}
                          <span style={{ color: "#0566ab" }}>
                            PARTNERSHIP DEED
                          </span>
                        </h2>
                        <br />
                              <ul style ={{fontSize:"1rem"}}>
                                <li>The name of the firm</li>
                                <li>Name and details of all partners</li>
                                <li>Date of commencement of business</li>
                                <li>Duration of the firm’s existence</li>
                                <li>Capital contributed by each partner</li>
                                <li>Profit/loss sharing ratio</li>
                                <li>Interest on capital payable to partners</li>
                                <li>The extent of borrowings each partner can draw</li>
                                <li>Salary payable to partners, if any</li>
                                <li>The procedure of admission or retirement of a partner</li>
                                <li>The method used for calculating goodwill</li>
                                <li>Preparation of accounts of the firm</li>
                                <li>Mode of settlement of dues with a deceased partner’s executors</li>
                                <li>The procedure to be followed in case disputes arise between partners</li>
                              </ul>{" "}
                      </div>
                    </div>
                    <div class="col-sm-6 stretch-card grid-margin">
                      <img
                        loading="lazy"
                        src="https://img.freepik.com/free-vector/signing-contract-concept-illustration_114360-4879.jpg"
                        style={{ width: "90%", height: "100%" }}
                      />
                    </div>
                  </div>
                  {/* MORE INFORMATION SECTION END */}

                  <hr />
                  <h2 id="ip-h2">MINIMUM REQUIREMENTS FOR A PARTNERSHIP REGISTRATION</h2>
                  {/* BENEFITS SECTION START */}
                  <div class="row" style={{ padding: "2rem" }}>
                    <div
                      class="col-sm-2 stretch-card grid-margin"  id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/2590/2590440.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">No Minimum Capital</h4>
                        <p>
                          There are no restrictions as such with respect to the
                          minimum capital requirement in case of partnership
                          firm. The Partnership firm can be registered even with
                          Rs. 10,000 as total capital.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin"  id="resp-3"
                      style={{ textAlign: "left" }} 
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/2758/2758527.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Easy To Form</h4>
                        <p>
                          Partnership Firm is very easy to form. It comes into
                          existence merely by a Partnership Deed. Its
                          registration is not mandatory. Even after formation,
                          there are no annual filings to be done except Income
                          tax returns.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin"  id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/11443/11443795.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Shared Financial Burden</h4>
                        <p>
                          Partners share the financial responsibilites and
                          risks, making it more manageable for each individual.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin"  id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/3529/3529127.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Tax Benefits</h4>
                        <p>
                          Partnership firm is a legal tool for better tax
                          planning. The partnership firm is a separate entity
                          and its tax is calculated separately so it can offer
                          remuneration and interest to working partners.{" "}
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin"  id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/5347/5347858.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Scalability</h4>
                        <p>
                          Scalability Its operations are scalable. Any new
                          partner can be introduced only by executing a
                          supplementary partnership deed. A partner can retire
                          or can be removed by executing a similar deed.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin"  id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/13163/13163215.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Sharing Risks</h4>
                        <p>
                          On account of its very nature, Partnership firm
                          enables sharing of risks by more than 1 person as the
                          profit and losses are shared by all the partners. This
                          ensures diversified financial risks.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* BENEFITS SECTION END */}

                  <hr />

                            {/* PRICING SECTION START */}
                            <h2 id="ip-h2" style={{fontWeight:'bold'}}>PRICING FOR <span style={{color:"#006345"}}>PARTNERSHIP REGISTRATION</span></h2>
                  <div class="row md-12 d-flex justify-content-center" style={{marginTop:"5rem", padding:"1rem"}}>

                  {/* PRICING CARD 1 START */}
                  <div
                      class="col-sm-4 stretch-card grid-margin"  id="resp-2"
                      style={{ textAlign: "left" }}
                    >
                    <div class="card">
                    <div style={{backgroundColor:"#207a78",color:"#ffffff",fontWeight:"bold",textAlign:"center",borderRadius:"12px",padding:"1rem"}}>
                    <h3><u>BUSINESS</u></h3>
                    <div style={{textAlign:"center"}}><h2 style={{fontWeight:"bold"}}> Starts <span style={{color:"#ffffff"}}>₹ 4999*</span></h2></div>
                    </div>
                    <div style={{padding:"1rem",margingTop:"1rem"}}>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i> Drafting of Deed</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Execution of Deed<br/></p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>PAN<br/></p>
                        <p style={{fontSize:"1rem", textDecoration:"line-through"}}><i class="mdi mdi-close-circle text-danger" style={{fontSize:"1.6rem",padding:"1rem"}}></i> MSME Registration<br/></p>
                        <p style={{fontSize:"1rem", textDecoration:"line-through"}}><i class="mdi mdi-close-circle text-danger" style={{fontSize:"1.6rem",padding:"1rem"}}></i>GST Registration</p>
                        <hr/>
                       <div class="d-flex justify-content-center">
                       <button class="btn btn-lg btn-success" style={{backgroundColor:"#207a78", fontSize:"1rem"}} onClick={()=>{
                        //Setting Values to redirect to Contact Sales Page
                        sessionStorage.setItem("Smodule00","Services");sessionStorage.setItem("Sservice00","Partnership Registration"); window.location.replace("/businesstools-contactsales");
                       }}>Contact Sales</button>
                       {/* CASHFREE CHECKOUT BUTTON START FOR PRICING CARD 1 */}
                        <div>
                        <a style={{textDecoration:"none"}} href="https://payments.cashfree.com/forms/startupconnect-partnershipregistration-payment" target="_parent">
                        <div class="button-container" style={button_container}>
                        <div>
                        {/*}    <img src="https://cashfree-checkoutcartimages-prod.cashfree.com/startupconnectlogo1Y6cg7dgopcs0_prod.png" alt="logo" style={logo_container} class="logo-container" />
                        */} </div>
                        <div class="text-container" style={text_container}>
                        <div style={{fontFamily: "Arial", color: "#fff", marginBottom: "5px", fontSize: "14px"}}>
                        Check Out
                        </div>
                        <div style={{fontFamily: "Arial", color: "#fff", fontSize: "10px"}}>
                        <span>Powered By Cashfree</span>
                        <img src="https://cashfreelogo.cashfree.com/cashfreepayments/logosvgs/Group_4355.svg" alt="logo" style={secondary_logo_container} class="seconday-logo-container" />
                        </div>
                        </div>
                        </div>
                        </a>
                        </div>
                       {/* CASHFREE CHECKOUT BUTTON START FOR PRICING CARD 1 */}
                       </div>
                    </div>
                    </div>
                    </div>
                    {/* PRICING CARD 1 END */}

                      {/* PRICING CARD 2 START */}
                  <div
                      class="col-sm-4 stretch-card grid-margin"  id="resp-2"
                      style={{ textAlign: "left" }}
                    >
                    <div class="card">
                    <div style={{backgroundColor:"#207a78",color:"#ffffff",fontWeight:"bold",textAlign:"center",borderRadius:"12px",padding:"1rem"}}>
                    <h3><u>PROFESSIONAL</u></h3>
                    <div style={{textAlign:"center"}}><h2 style={{fontWeight:"bold"}}> Starts <span style={{color:"#ffffff"}}>₹ 7999*</span></h2></div>
                    </div>
                    <div style={{padding:"1rem",margingTop:"1rem"}}>
                    <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i> Drafting of Deed</p>
                    <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Execution of Deed<br/></p>
                    <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>PAN<br/></p>
                    <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i> MSME Registration</p>
                    <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>GST Registration</p>
                        <hr/>
                       <div class="d-flex justify-content-center">
                       <button class="btn btn-lg btn-success" style={{backgroundColor:"#207a78", fontSize:"1rem"}} onClick={()=>{
                        //Setting Values to redirect to Contact Sales Page
                        sessionStorage.setItem("Smodule00","Services");sessionStorage.setItem("Sservice00","Partnership Registration"); window.location.replace("/businesstools-contactsales");
                       }}>Contact Sales</button>
                        {/* CASHFREE CHECKOUT BUTTON START FOR PRICING CARD 2 */}
                        <div>
                        <a style={{textDecoration:"none"}} href="https://payments.cashfree.com/forms/startupconnect-partnership-payment-professional" target="_parent">
                        <div class="button-container" style={button_container}>
                        <div>
                        {/*}    <img src="https://cashfree-checkoutcartimages-prod.cashfree.com/startupconnectlogo1Y6cg7dgopcs0_prod.png" alt="logo" style={logo_container} class="logo-container" />
                        */} </div>
                        <div class="text-container" style={text_container}>
                        <div style={{fontFamily: "Arial", color: "#fff", marginBottom: "5px", fontSize: "14px"}}>
                        Check Out
                        </div>
                        <div style={{fontFamily: "Arial", color: "#fff", fontSize: "10px"}}>
                        <span>Powered By Cashfree</span>
                        <img src="https://cashfreelogo.cashfree.com/cashfreepayments/logosvgs/Group_4355.svg" alt="logo" style={secondary_logo_container} class="seconday-logo-container" />
                        </div>
                        </div>
                        </div>
                        </a>
                        </div>
                       {/* CASHFREE CHECKOUT BUTTON START FOR PRICING CARD 2 */}
                       </div>
                    </div>
                    </div>
                    </div>
                    {/* PRICING CARD 2 END */}


                  </div>
                  <p style={{fontSize:"1rem"}}><b>*Note :</b>The above prices excludes the price of stamp duty to be paid on Partnership Agreement.Stamp charges has to be borne by the customer as actuals. Draft Partnership Agreement will be provided to client and the stamped agreement shall be provided by client to our team for filing.
</p>
                  <hr/>
                  {/* PRICING SECTION END */}
                
                  {/* REQUIREMENTS SECTION START */}
                  <h2 id="ip-h2" style={{padding:"0.5rem"}}>MINIMUM REQUIREMENTS FOR PARTNERSHIP REGISTRATION</h2>

                  <div class="row" style={{ padding: "2rem" }}>
                    <div class="col-sm-4 stretch-card grid-margin">
                      <img
                        loading="lazy"
                        src="https://img.freepik.com/free-vector/business-deal-concept-illustration_114360-1103.jpg"
                        style={{ width: "90%", height: "100%" }}
                      />
                    </div>

                    <div
                      class="col-sm-8 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div>
                        <h4 style={{ fontWeight: "bold" }}>
                          1. Minimum 2 Partners:
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                          {" "}
                          A minimum of two persons are required to establish a
                          partnership firm. A partnership firm is where two or
                          more persons come together to establish a business and
                          divide its profits amongst themselves in the agreed
                          ratio.
                        </p>
                        <br />
                        <h4 style={{ fontWeight: "bold" }}>
                          2. No fixed minimum Capital Requirement
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                          there is absolutely no requirement for minimum capital
                          (unlike in private limited company where it is 1
                          lakh). However, the contribution of any partner
                          towards the capital of the LLP may also include and
                          tangible or intangible, movable or immovable property.
                        </p>
                        <br />
                        <h4 style={{ fontWeight: "bold" }}>
                          3.Valid id proof of Partners
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                          PAN card of partners - All partners are required to
                          submit their PAN number as identity proof. Address
                          proof of partners - Partners can submit Aadhar Card,
                          Driving License, passport or Voter ID card as address
                          proof. Name and other details on address proof should
                          match PAN card details.
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* REQUIREMENTS SECTION END */}
                  <hr />
                  <h2 id="ip-h2" style={{padding:"0.5rem"}}>PARTNERSHIP REGISTRATION PROCESS</h2>

                  {/* PROCESS SECTION START */}
                  <div class="row" style={{padding:"1rem"}}>
                    <div class="col-sm-3 stretch-card grid-margin"  id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/free-vector/paying-bills-concept-illustration_114360-19357.jpg?w=740&t=st=1706779500~exp=1706780100~hmac=ad8e6bf9dc5da282c80e40c82c023c68f94bea72270aa35612471e5892f8985b"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold"> Step 1 </h4>
                          <div class="d-flex justify-content-between">
                            <p class="mb-0" style={{ fontSize: "1rem" }}>
                              Selection of Name and deciding terms and
                              conditions of partnership among the partners like
                              profit sharing, capital contribution
                              <br />
                              roles and responsibilities etc.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-3 stretch-card grid-margin"  id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/free-vector/login-concept-illustration_114360-748.jpg?t=st=1706779615~exp=1706780215~hmac=9651a498d516590febe6779c62640331889c05ff48733ca96f9ca77ddcdec593"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold"> Step 2 </h4>
                          <div>
                            <p class="mb-0" style={{ fontSize: "1rem", textAlign:"center" }}>
                              Drafting partnership deed{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-3 stretch-card grid-margin"  id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/premium-vector/businessman-shaking-hands-deal-symbol-with-fellow-lawyer-lawyers-discuss-with-clients_923732-776.jpg?w=740"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold"> Step 3 </h4>
                          <div>
                            <p class="mb-0" style={{ fontSize: "1rem", textAlign:"center" }}>
                              Incorporation of Firm{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-3 stretch-card grid-margin"  id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/free-vector/product-quality-concept-illustration_114360-7301.jpg?w=740&t=st=1706780098~exp=1706780698~hmac=4ed66236fdef104f05dab6b8d7176409171e304e19a3758c389bc28837bbe13b"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold"> Step 4 </h4>
                          <div>
                            <p class="mb-0" style={{ fontSize: "1rem",textAlign:"center" }}>
                              Application for PAN,GST, MSME and Trademark
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* PROCESS SECTION END */}

                  <hr />

                  {/* Why StartUp Connect Starts */}

                 <Whystartupconnect/>
                  {/* Why StartUp Connect Ends */}
                  <hr/>

                  {/* Documents SECTION Starts */}
                  <h2 id="ip-h2">DOCUMENTS REQUIRED FOR PARTNERSHIP REGISTRATION</h2>
                  <div class="row d-flex justify-content-center" style={{ padding: "2rem" }}>
                    <div
                      class="col-sm-3 stretch-card grid-margin"  id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/3135/3135715.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>

                        <h4 class="mt-3">Photograph of all the Partners</h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin"  id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/1726/1726620.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">
                          PAN Card and aadhar card of all the Partners
                        </h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin"  id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/9068/9068642.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Email id/ Mobile no of Partners</h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin"  id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/9875/9875524.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">
                          Bank statement/any other Utility bill for address
                          Proof of Partners
                        </h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin"  id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/6108/6108273.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3" >
                          Electricity Bill or any other utility bill for the
                          address proof of the Registered Office
                        </h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-3 stretch-card grid-margin"  id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/1584/1584961.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">
                          Proof of Registered Office : Consent letter/Rent
                          agreement{" "}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <hr/>
                  {/* Documents SECTION Ends */}

                  {/* COMPLIANCES SECTION START */}
                  <h2 id="ip-h2">COMPLIANCES REQUIRED BY PARTNERSHIP FIRM </h2>
                  <p style={{ fontSize: "1rem" }}>
                    Once a Partnership is registered in India, various
                    compliances must be maintained from time to time to avoid
                    penalties and prosecution. The following are some of the
                    compliances a Partnership would be required to complete
                    after registration:
                  </p>
                  <div
                    class="row"
                    style={{ textAlign: "left", padding: "2rem" }}
                  >
                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i>  GST Registration</h3>
                    <p style={{ fontSize: "1rem" }}>
                      Partnership firms in India requires GST registration. GST
                      Registration Process is 100% online and there is no
                      requirement of submission of physical documents to GST
                      Department. GST registration must be obtained within 30
                      days of business incorporation, otherwise, the business
                      will be subject to heavy penalties.{" "}
                    </p>
                    <br />
                    <br />

                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i>  GST Return</h3>
                    <p style={{ fontSize: "1rem" }}>
                      Under the Goods & Service Tax regime which apparently
                      rolled out in 2017, partnership firms having GST
                      registration would be required to file GST returns. GST
                      Returns can be filed monthly, quarterly and annual GST
                      returns. As filing of GST returns is mandatory for all the
                      registered Taxpayers including Partnership firms.
                    </p>
                    <br />
                    <br />

                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i>  Accounting</h3>
                    <p style={{ fontSize: "1rem" }}>
                      The accounting for a partnership firm is essential
                      compliance. Every Partnership firm shall maintain proper
                      books of accounts which shall represent an accurate and
                      fair view of the state of affairs of the Firm. In essence,
                      a separate account tracks each partner’s investment,
                      distributions etc.{" "}
                    </p>
                    <br />
                    <br />

                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i>  Tax Audit</h3>
                    <p style={{ fontSize: "1rem" }}>
                      The Income Tax audit would be needed for a partnership
                      firm if the total sales turnover is more than Rs.1 crore
                      during the financial year. In case of a professional firm,
                      the tax audit would be necessary if total gross receipts
                      exceed Rs.50 lakhs throughout the financial year under
                      assessment.{" "}
                    </p>
                    <br />
                    <br />

                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i>  IT Return</h3>
                    <p style={{ fontSize: "1rem" }}>
                      Income tax filing must be filed by all partnership firms.
                      The income tax return of a partnership firm that doesn’t
                      need an audit is due on the 31st of July. If the income
                      tax return of a partnership firm is to be audited
                      according to the Income Tax Act, then the return would be
                      unpaid on the 30th of September.
                    </p>
                    <br />
                    <br />

                    <h3><i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> TDS Return</h3>
                    <p style={{ fontSize: "1rem" }}>
                      Quarterly TDS returns must be filed by partnership firms
                      that have TAN and are required to deduct tax at source as
                      per TDS rules. A TDS Return is a quarterly statement which
                      has to be submitted to the IT Department of India. It
                      should contain all details of TDS deducted and deposited
                      by you for a particular quarter.
                    </p>
                    <br />
                    <br />
                  </div>
                  {/* COMPLIANCES SECTION END */}
                  <hr />
                   {/* COMPARISON TABLE START */}
                   <Comparisontable/>
                  {/* COMPARISON TABLE END */}

                  {/* FAQ SECTION END */}

                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
            {/* SIDEBAR AND MAIN PAGE END */}

            <Footer3 />
          </div>
          {/*} container-scroller */}
          {/*} plugins:js */}
          <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
          {/*} endinject */}
          {/*} Plugin js for this page */}
          <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
          <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
          <script
            src="panel/assets/js/jquery.cookie.js"
            type="text/javascript"
          ></script>
          {/*} End plugin js for this page */}
          {/*} inject:js */}
          <script src="panel/assets/js/off-canvas.js"></script>
          <script src="panel/assets/js/hoverable-collapse.js"></script>
          <script src="panel/assets/js/misc.js"></script>
          <script src="panel/assets/js/settings.js"></script>
          <script src="panel/assets/js/todolist.js"></script>
          {/*} endinject */}
          {/*} Custom js for this page */}
          <script src="panel/assets/js/dashboard.js"></script>
          {/*} End custom js for this page */}
        </body>
      </html>
    </>
  );
}

export default Partnershipregistration1;
