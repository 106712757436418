import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import '../Resources/dashboard.css';


 

function StartupDetailSidebar(){

return(
    <>

          {/*  SIDEBAR START */}
          <nav class="sidebar sidebar-offcanvas" id="sidebar">
        <ul class="nav">
          <li class="nav-item nav-profile border-bottom">
            <a href="/businesstools-login" class="nav-link flex-column">
              <div class="nav-profile-image">
                <img src="https://cdn-icons-png.flaticon.com/128/9069/9069049.png" alt="profile" />
                {/*}change to offline or busy as needed*/}
              </div>
              <div class="nav-profile-text d-flex ms-0 mb-3 flex-column">
                <span class="font-weight-semibold mb-1 mt-2 text-center">Aman</span>
                <span class="text-secondary icon-sm text-center mt-2">Session : SE0542098</span>
              </div>
            </a>
          </li>
     
          <li class="pt-2 pb-
          1">
            
          </li> 
          <li class="nav-item">
            <a class="nav-link" href="#startup-heading">
              <i class="mdi mdi-numeric-1-box menu-icon"></i>
              <span class="menu-title"> Heading</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#startup-introduction">
              <i class="mdi mdi-numeric-2-box menu-icon"></i>
              <span class="menu-title"> Introduction</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#startup-additional">
              <i class="mdi mdi-numeric-3-box menu-icon"></i>
              <span class="menu-title"> Additional Information</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#startup-team">
              <i class="mdi mdi-numeric-4-box menu-icon"></i>
              <span class="menu-title"> Team</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#startup-footer">
              <i class="mdi mdi-numeric-5-box menu-icon"></i>
              <span class="menu-title"> Footer</span>
            </a>
          </li>


        </ul>
      </nav>
     {/*  SIDEBAR END  */} 
    </>

);

}

export default StartupDetailSidebar;