import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import Navbar from './navbar';
import Leftsidebar from './leftsidebar';
import Rightsidebar from './rightsidebar.js';



//import '../hireportalcss.css';
//import '../Hireportalcss/hireportalcss.css';

//Importing Page css with screen sizes
import '../Hireportalcss/sdefault.css';
import '../Hireportalcss/s1200.css';
import '../Hireportalcss/s920.css';
import '../Hireportalcss/s768.css';
import '../Hireportalcss/s600.css';
import '../Hireportalcss/sanimation.css';







function Employerassignments(){

    return(

        <>
        <div class="default-view">
        <Navbar/>
        {/* MAIN SECTION START */}
        <div class="hire-main-section">
        <Leftsidebar/>
        {/* MAIN PANEL START */}
      <div class="hire-main-panel-ext">
      <h2>Assignments</h2>
        {/* SEARCH BAR START */}
        <div class="hire-search-bar">

        </div>
        {/* SEARCH BAR END */}
        <div style={{display:"flex",flexDirection:"row",justifyContent:"end"}}>
          <div>
            <button class="hire-btn-default" style={{backgroundColor:"#12d571",marginRight:"2rem"}} onClick={()=>{ window.location.replace("/employer-create-assignment"); }}>Create New Assignment</button>
          </div>
        </div>
        {/* LIST PANEL START */}
        <div class="list-panel">
        <div style={{width:"100%",height:"2vh"}}></div>
       
        {/* HIRE LIST START */}  
        <div class="hire-list">
            
        {/* LIST CARD 1 START */}
        <div class="hire-list-card">
        <div><span style={{marginLeft:"1rem",fontSize:"60%",color: "#b3b3b4"}}><b>Assignment Id :</b> HSA5444</span></div>
        <div class="hire-list-card-top">
        <div class="hire-list-card-top-l"><h3>Normal Test for Full Stack Developer</h3></div>
        <div class="hire-list-card-top-r"><p>Full Stack Developer Understanding Test</p></div>
        </div>

        <div class="hire-list-card-area">
        <div class="hire-list-card-area-l"> 
        <div class="hire-list-card-salarycard">
          <p><b>Time to complete :</b> 60 mins. <b>Maximum Marks :</b> 100</p>
          <p><b>Overview</b></p>
          <p>
A normal test for a full stack developer assesses their knowledge and skills in front-end and back-end development. Topics covered may include HTML, CSS, JavaScript, front-end frameworks, back-end languages, database technologies, API design, version control, testing, and deployment. Coding exercises may include building a web application, implementing a new feature, or debugging an existing web application.

Tips for taking the test include reviewing the job description, practicing coding skills, getting familiar with common question types, taking your time, and using a debugger.</p>
        </div>
       </div> 
       <div class="hire-list-card-area-r"> 

        <div class="hire-list-card-badge" style={{backgroundColor:"#ffffff",boxShadow:"none"}}>
        <button style={{marginLeft:"45%",backgroundColor:"#ffffff",border:"none"}}><img src="https://cdn-icons-png.flaticon.com/128/2891/2891918.png" class="hire-card-icon" /></button>
        <button style={{backgroundColor:"#ffffff",border:"none"}}><img src="https://cdn-icons-png.flaticon.com/128/4980/4980658.png" class="hire-card-icon" /></button>
        </div>

           <div class="hire-list-card-badge" style={{backgroundColor:"#ffffff",color:"#ffffff",boxShadow:"none"}}>
          <button class="hire-btn-default" style={{backgroundColor:"#154f9d"}} onClick={()=>{
            window.location.replace("/employer-view-assignment");
          }}>View Assignment</button>
        </div>
            

        </div>  
        </div>
        <div class="hire-list-card-btn-list">
        </div>

      

        <div class="hire-list-card-bottom">
          <p><b>Date Updated :</b> 23-01-2023</p>
        </div>

        <div style={{height:"0.5rem"}}></div>
        </div>

        {/* LIST CARD 1 END */}

          {/* LIST CARD 2 START */}
          <div class="hire-list-card">
        <div><span style={{marginLeft:"1rem",fontSize:"60%",color: "#b3b3b4"}}><b>Assignment Id :</b> HSA5444</span></div>
        <div class="hire-list-card-top">
        <div class="hire-list-card-top-l"><h3>Normal Test for Full Stack Developer</h3></div>
        <div class="hire-list-card-top-r"><p>Full Stack Developer Understanding Test</p></div>
        </div>

        <div class="hire-list-card-area">
        <div class="hire-list-card-area-l"> 
        <div class="hire-list-card-salarycard">
          <p><b>Time to complete :</b> 60 mins. <b>Maximum Marks :</b> 100</p>
          <p><b>Overview</b></p>
          <p>
A normal test for a full stack developer assesses their knowledge and skills in front-end and back-end development. Topics covered may include HTML, CSS, JavaScript, front-end frameworks, back-end languages, database technologies, API design, version control, testing, and deployment. Coding exercises may include building a web application, implementing a new feature, or debugging an existing web application.

Tips for taking the test include reviewing the job description, practicing coding skills, getting familiar with common question types, taking your time, and using a debugger.</p>
        </div>
       </div> 
       <div class="hire-list-card-area-r"> 

        <div class="hire-list-card-badge" style={{backgroundColor:"#ffffff",boxShadow:"none"}}>
        <button style={{marginLeft:"45%",backgroundColor:"#ffffff",border:"none"}}><img src="https://cdn-icons-png.flaticon.com/128/2891/2891918.png" class="hire-card-icon" /></button>
        <button style={{backgroundColor:"#ffffff",border:"none"}}><img src="https://cdn-icons-png.flaticon.com/128/4980/4980658.png" class="hire-card-icon" /></button>
        </div>

           <div class="hire-list-card-badge" style={{backgroundColor:"#ffffff",color:"#ffffff",boxShadow:"none"}}>
          <button class="hire-btn-default" style={{backgroundColor:"#154f9d"}} onClick={()=>{
            window.location.replace("/employer-view-assignment");
          }}>View Assignment</button>
        </div>
            

        </div>  
        </div>
        <div class="hire-list-card-btn-list">
        </div>

      

        <div class="hire-list-card-bottom">
          <p><b>Date Updated :</b> 23-01-2023</p>
        </div>

        <div style={{height:"0.5rem"}}></div>
        </div>

        {/* LIST CARD 2 END */}


  {/* LIST CARD 3 START */}
  <div class="hire-list-card">
        <div><span style={{marginLeft:"1rem",fontSize:"60%",color: "#b3b3b4"}}><b>Assignment Id :</b> HSA5444</span></div>
        <div class="hire-list-card-top">
        <div class="hire-list-card-top-l"><h3>Normal Test for Full Stack Developer</h3></div>
        <div class="hire-list-card-top-r"><p>Full Stack Developer Understanding Test</p></div>
        </div>

        <div class="hire-list-card-area">
        <div class="hire-list-card-area-l"> 
        <div class="hire-list-card-salarycard">
          <p><b>Time to complete :</b> 60 mins. <b>Maximum Marks :</b> 100</p>
          <p><b>Overview</b></p>
          <p>
A normal test for a full stack developer assesses their knowledge and skills in front-end and back-end development. Topics covered may include HTML, CSS, JavaScript, front-end frameworks, back-end languages, database technologies, API design, version control, testing, and deployment. Coding exercises may include building a web application, implementing a new feature, or debugging an existing web application.

Tips for taking the test include reviewing the job description, practicing coding skills, getting familiar with common question types, taking your time, and using a debugger.</p>
        </div>
       </div> 
       <div class="hire-list-card-area-r"> 

        <div class="hire-list-card-badge" style={{backgroundColor:"#ffffff",boxShadow:"none"}}>
        <button style={{marginLeft:"45%",backgroundColor:"#ffffff",border:"none"}}><img src="https://cdn-icons-png.flaticon.com/128/2891/2891918.png" class="hire-card-icon" /></button>
        <button style={{backgroundColor:"#ffffff",border:"none"}}><img src="https://cdn-icons-png.flaticon.com/128/4980/4980658.png" class="hire-card-icon" /></button>
        </div>

           <div class="hire-list-card-badge" style={{backgroundColor:"#ffffff",color:"#ffffff",boxShadow:"none"}}>
          <button class="hire-btn-default" style={{backgroundColor:"#154f9d"}} onClick={()=>{
            window.location.replace("/employer-view-assignment");
          }}>View Assignment</button>
        </div>
            

        </div>  
        </div>
        <div class="hire-list-card-btn-list">
        </div>

      

        <div class="hire-list-card-bottom">
          <p><b>Date Updated :</b> 23-01-2023</p>
        </div>

        <div style={{height:"0.5rem"}}></div>
        </div>

        {/* LIST CARD 3 END */}

       
         {/* LIST CARD 4 START */}
         <div class="hire-list-card">
        <div><span style={{marginLeft:"1rem",fontSize:"60%",color: "#b3b3b4"}}><b>Assignment Id :</b> HSA5444</span></div>
        <div class="hire-list-card-top">
        <div class="hire-list-card-top-l"><h3>Normal Test for Full Stack Developer</h3></div>
        <div class="hire-list-card-top-r"><p>Full Stack Developer Understanding Test</p></div>
        </div>

        <div class="hire-list-card-area">
        <div class="hire-list-card-area-l"> 
        <div class="hire-list-card-salarycard">
          <p><b>Time to complete :</b> 60 mins. <b>Maximum Marks :</b> 100</p>
          <p><b>Overview</b></p>
          <p>
A normal test for a full stack developer assesses their knowledge and skills in front-end and back-end development. Topics covered may include HTML, CSS, JavaScript, front-end frameworks, back-end languages, database technologies, API design, version control, testing, and deployment. Coding exercises may include building a web application, implementing a new feature, or debugging an existing web application.

Tips for taking the test include reviewing the job description, practicing coding skills, getting familiar with common question types, taking your time, and using a debugger.</p>
        </div>
       </div> 
       <div class="hire-list-card-area-r"> 

        <div class="hire-list-card-badge" style={{backgroundColor:"#ffffff",boxShadow:"none"}}>
        <button style={{marginLeft:"45%",backgroundColor:"#ffffff",border:"none"}}><img src="https://cdn-icons-png.flaticon.com/128/2891/2891918.png" class="hire-card-icon" /></button>
        <button style={{backgroundColor:"#ffffff",border:"none"}}><img src="https://cdn-icons-png.flaticon.com/128/4980/4980658.png" class="hire-card-icon" /></button>
        </div>

           <div class="hire-list-card-badge" style={{backgroundColor:"#ffffff",color:"#ffffff",boxShadow:"none"}}>
          <button class="hire-btn-default" style={{backgroundColor:"#154f9d"}} onClick={()=>{
            window.location.replace("/employer-view-assignment");
          }}>View Assignment</button>
        </div>
            

        </div>  
        </div>
        <div class="hire-list-card-btn-list">
        </div>

      

        <div class="hire-list-card-bottom">
          <p><b>Date Updated :</b> 23-01-2023</p>
        </div>

        <div style={{height:"0.5rem"}}></div>
        </div>

        {/* LIST CARD 4 END */}

          {/* LIST CARD 5 START */}
          <div class="hire-list-card">
        <div><span style={{marginLeft:"1rem",fontSize:"60%",color: "#b3b3b4"}}><b>Assignment Id :</b> HSA5444</span></div>
        <div class="hire-list-card-top">
        <div class="hire-list-card-top-l"><h3>Normal Test for Full Stack Developer</h3></div>
        <div class="hire-list-card-top-r"><p>Full Stack Developer Understanding Test</p></div>
        </div>

        <div class="hire-list-card-area">
        <div class="hire-list-card-area-l"> 
        <div class="hire-list-card-salarycard">
          <p><b>Time to complete :</b> 60 mins. <b>Maximum Marks :</b> 100</p>
          <p><b>Overview</b></p>
          <p>
A normal test for a full stack developer assesses their knowledge and skills in front-end and back-end development. Topics covered may include HTML, CSS, JavaScript, front-end frameworks, back-end languages, database technologies, API design, version control, testing, and deployment. Coding exercises may include building a web application, implementing a new feature, or debugging an existing web application.

Tips for taking the test include reviewing the job description, practicing coding skills, getting familiar with common question types, taking your time, and using a debugger.</p>
        </div>
       </div> 
       <div class="hire-list-card-area-r"> 

        <div class="hire-list-card-badge" style={{backgroundColor:"#ffffff",boxShadow:"none"}}>
        <button style={{marginLeft:"45%",backgroundColor:"#ffffff",border:"none"}}><img src="https://cdn-icons-png.flaticon.com/128/2891/2891918.png" class="hire-card-icon" /></button>
        <button style={{backgroundColor:"#ffffff",border:"none"}}><img src="https://cdn-icons-png.flaticon.com/128/4980/4980658.png" class="hire-card-icon" /></button>
        </div>

           <div class="hire-list-card-badge" style={{backgroundColor:"#ffffff",color:"#ffffff",boxShadow:"none"}}>
          <button class="hire-btn-default" style={{backgroundColor:"#154f9d"}} onClick={()=>{
            window.location.replace("/employer-view-assignment");
          }}>View Assignment</button>
        </div>
            

        </div>  
        </div>
        <div class="hire-list-card-btn-list">
        </div>

      

        <div class="hire-list-card-bottom">
          <p><b>Date Updated :</b> 23-01-2023</p>
        </div>

        <div style={{height:"0.5rem"}}></div>
        </div>

        {/* LIST CARD 5 END */}

        </div>
        {/* HIRE LIST END */}  
        </div>
        {/* LIST PANEL END */}

        </div>
        {/* MAIN PANEL END */} 



        </div>
        {/* MAIN SECTION END */}

        </div>

        </>
    );




}


export default Employerassignments;