import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../axios.js';
import Navbar from './navbar.js';
import Sidebar from './sidebar.js';
import moment from 'moment/moment';



/*import Amanbg from './amanbg';*/


function Admincreateproject(){


  

   // Setting Values fom Session Storage
  /* const userlogstatus=sessionStorage.getItem("userloginstatus00");//loginstatus
   const userloguser=sessionStorage.getItem("userusername00");//username
   const userlogtype=sessionStorage.getItem("userlogintype00");//logintype
   const userlogname=sessionStorage.getItem("userloginname00");//loginname
   const userlogadminid=sessionStorage.getItem("userloginadminid00");//loginadminid */

      // Setting Values fom Session Storage
      const userlogstatus=sessionStorage.getItem("loginstatus00");//loginstatus
      const userloguser=sessionStorage.getItem("username00");//username
      const userlogtype=sessionStorage.getItem("logintype00");//logintype
      const userlogname=sessionStorage.getItem("loginname00");//loginname
      const userlogadminid=sessionStorage.getItem("loginadminid00");//loginadminid
      //const logteamid=sessionStorage.getItem("loginteamid00");//loginteamid

 //Checking if Sessions are Set

// alert(logstatus,loguser,logadminid);
 //Sessions Not Set
{/*if(((userlogstatus=="false")||(userlogstatus==null))||(userlogtype!="admin")||(userlogadminid==null)){
  
  sessionStorage.removeItem("username00");
  sessionStorage.removeItem("loginstatus00");
  sessionStorage.removeItem("logintype00");
  sessionStorage.removeItem("loginname00");
  sessionStorage.removeItem("loginadminid00");
 
  alert("Session Expired! Login Again.");
  window.location.replace("/");
 }

 //Sessions Set
 else{
 */}

  
  


  //Setting Variables
  const[client_id,setclient_id]=useState();const updateclient_id = (event) => { setclient_id(event.target.value);};    const[client_id_er,setclient_id_er]=useState();//client_id
  const[client_name,setclient_name]=useState();const updateclient_name = (event) => { setclient_name(event.target.value);};    const[client_name_er,setclient_name_er]=useState();//client_name
  const[member_id,setmember_id]=useState();const updatemember_id = (event) => { setmember_id(event.target.value);};    const[member_id_er,setmember_id_er]=useState();//member_id
  const[member_name,setmember_name]=useState();const updatemember_name = (event) => { setmember_name(event.target.value);};    const[member_name_er,setmember_name_er]=useState();//member_name
  const[team_id,setteam_id]=useState();const updateteam_id = (event) => { setteam_id(event.target.value);};    const[team_id_er,setteam_id_er]=useState();//team_id
  const[start_date,setstart_date]=useState();const updatestart_date = (event) => { setstart_date(event.target.value);};    const[start_date_er,setstart_date_er]=useState();//start_date
  const[order_no,setorder_no]=useState();const updateorder_no = (event) => { setorder_no(event.target.value);};    const[order_no_er,setorder_no_er]=useState();//order_no
  const[order_id,setorder_id]=useState();const updateorder_id = (event) => { setorder_id(event.target.value);};    const[order_id_er,setorder_id_er]=useState();//order_id
  const[title,settitle]=useState();const updatetitle = (event) => { settitle(event.target.value);};    const[title_er,settitle_er]=useState();//title
  const[status,setstatus]=useState();const updatestatus = (event) => { setstatus(event.target.value);};    const[status_er,setstatus_er]=useState();//status
  const[description,setdescription]=useState();const updatedescription = (event) => { setdescription(event.target.value);};    const[description_er,setdescription_er]=useState();//description



    //ERROR VARIABLES 
    const[error,seterror]=useState();
    //const[error1,seterror1]=useState();const[error2,seterror2]=useState();const[error3,seterror3]=useState();const[error4,seterror4]=useState();const[error5,seterror5]=useState();const[error6,seterror6]=useState();const[error7,seterror7]=useState();



    function create(){

         //Checking if user loggedin
 if((userlogstatus=="true")&&(userloguser!=null)&&(userlogadminid!=null)&&(userlogtype=="admin")){

     //  seterror1("");seterror2("");seterror3("");seterror4("");seterror5("");seterror6("");seterror7("");
     
     //Setting Error Variables
     setclient_id_er();setclient_name_er();setmember_id_er();setmember_name_er();setteam_id_er();setstart_date_er();setorder_id_er();setorder_no_er();settitle_er();setstatus_er();setdescription_er();

       let noerror = 0;

      /* const $title = document.getElementById("title").value; if($title==""){ seterror1("Title is required !");noerror = noerror+1; }
       const $description = document.getElementById("description").value;
       const $start_date = document.getElementById("start_date").value;
       const $end_date = document.getElementById("end_date").value;
       const $status = document.getElementById("status").value;*/

     //No primary errors
if(noerror==0){
       //Sending Data through axios
       axios.post("admin-createnew-project", { 
        User:userloguser,
        Logtype:userlogtype,
        Log_id:userlogadminid,
        Logstatus:"true",
        User_id:userlogadminid,
        Account_type:userlogtype,
        Client_id:client_id,
        Client_name:client_name,     
        Team_id:team_id,
        Member_id:member_id,
        member_name:member_name,
        Operation:"create",
        Title:title,
        Description:description,
        Start_date:moment(start_date).format('MM-DD-YYYY'),
        Order_no:order_no,
        Order_id:order_id,
     //   End_date:moment($end_date).format('MM-DD-YYYY'),
        Status:status,
        Operation:"create"
      
  })
  
      .then((response) => {
  
        //seterror1(" ");seterror2(" ");seterror3(" ");seterror4(" ");seterror5(" ");seterror6(" ");seterror7(" ");
      
          //Setting Error Variables
          setclient_id_er(" ");setclient_name_er(" ");setmember_id_er(" ");setmember_name_er(" ");setteam_id_er(" ");setstart_date_er(" ");setorder_id_er(" ");setorder_no_er(" ");settitle_er(" ");setstatus_er(" ");setdescription_er(" ");

        //Validation Errors
      if(response.data.validatorerror){
        setclient_id_er(response.data.validatorerror.Client_id);
        setclient_name_er(response.data.validatorerror.Client_name);
        setmember_id_er(response.data.validatorerror.Member_id);
        setmember_name_er(response.data.validatorerror.Member_name);
        setteam_id_er(response.data.validatorerror.Team_id);
        settitle_er(response.data.validatorerror.Title);
        setdescription_er(response.data.validatorerror.Description);
        setstatus_er(response.data.validatorerror.Status);
        setstart_date_er(response.data.validatorerror.Start_date);
        setorder_id_er(response.data.validatorerror.Order_id);
        setorder_no_er(response.data.validatorerror.Order_no);
     /*   seterror2(response.data.validatorerror.Start_date);
        seterror3(response.data.validatorerror.End_date);
        seterror4(response.data.validatorerror.Status);
        seterror5(response.data.validatorerror.Description);*/
        if(response.data.error=='101'){ seterror("Cannot Create New Project!"); }
        
         }
      //Invalid Entries
      if(response.data.successvar==2){
        setclient_id_er(response.data.client_id_er);
        setclient_name_er(response.data.client_name_er);
        setmember_id_er(response.data.member_id_er);
        setmember_name_er(response.data.member_name_er);
        setteam_id_er(response.data.team_id_er);
        settitle_er(response.data.title_er);
        setdescription_er(response.data.description_er);
        setstatus_er(response.data.status_er);
        setstart_date_er(response.data.start_date_er);
        setorder_id_er(response.data.order_id_er);
        setorder_no_er(response.data.order_no_er);
        seterror("Cannot Create New Project!");
        
             }
    
      //success
      if(response.data.successvar==1){
      alert("New Project Created Successfully!");
      }
      else{
      if(response.data.successvar==0){
      seterror(response.data.error);
      }
      
    }
    }    );

  }
  //Return Errors
  else{
    return;
  }


}
//User not Logedin
else{

  sessionStorage.removeItem("username00");
  sessionStorage.removeItem("loginstatus00");
  sessionStorage.removeItem("logintype00");
  sessionStorage.removeItem("loginname00");
  sessionStorage.removeItem("loginadminid00");
 
  alert("Session Expired! Login Again.");
  window.location.replace("/");

   }



    }



    return(
<>
{/*<!DOCTYPE html> */}
<html lang="en">
  <head>
    {/* Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>theStartupConnect | Portal</title>
    {/* plugins:css */}
    <link rel="stylesheet" href="./assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="./assets/vendors/css/vendor.bundle.base.css" />
    {/* endinject */}
    {/* Plugin css for this page */}
    {/* End plugin css for this page */}
    {/* inject:css */}
    {/* endinject */}
    {/* Layout styles */}
    <link rel="stylesheet" href="./assets/css/style.css" />
    {/* End layout styles */}
   {/*} <link rel="shortcut icon" href="./assets/images/favicon.ico" /> */}
  </head>
  <body>

<Navbar/>

{/* MAIN PAGE COMPONENT START */}
 <div class="container-fluid page-body-wrapper">

 <Sidebar/>


{/* MAIN PANEL START */}
<div class="main-panel" style={{marginTop:"5vh"}}>
<div class="content-wrapper">

<h2>Create Project</h2>
<div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Create New Project</h4>
                    <div class="form-sample">
                      <p class="card-description"> Project Details </p>
                      <small style={{fontSize:".8rem",color:"#f16083"}}>{error}</small>    
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Client Id</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="client_id"  value={client_id} onChange={updateclient_id} placeholder="Client Id" />
                             <small style={{fontSize:".8rem",color:"#f16083"}}>{client_id_er}</small>                       
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Client Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="client_name"  value={client_name} onChange={updateclient_name} placeholder="Client Name" />
                             <small style={{fontSize:".8rem",color:"#f16083"}}>{client_name_er}</small> 
                            </div>
                          </div>
                        </div>
                      </div>  
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Member Id</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="member_id"  value={member_id} onChange={updatemember_id} placeholder="Member Id" />
                             <small style={{fontSize:".8rem",color:"#f16083"}}>{member_id_er}</small>                       
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Member Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="member_name"  value={member_name} onChange={updatemember_name} placeholder="Member Name"  />
                             <small style={{fontSize:".8rem",color:"#f16083"}}>{member_name_er}</small> 
                            </div>
                          </div>
                        </div>
                      </div> 
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Team Id</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="team_id"  value={team_id} onChange={updateteam_id} placeholder="Team Id" />
                             <small style={{fontSize:".8rem",color:"#f16083"}}>{team_id_er}</small>                       
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Start Date</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" id="start_date"  value={start_date} onChange={updatestart_date} placeholder="Start Date"  />
                            <small style={{fontSize:".8rem",color:"#f16083"}}>{start_date_er}</small>
                            </div>
                          </div>
                        </div>
                      </div>  
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Order Number</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="order_no"  value={order_no} onChange={updateorder_no} placeholder="Order Number" />
                             <small style={{fontSize:".8rem",color:"#f16083"}}>{order_no_er}</small>                       
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Order Id</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="order_id"  value={order_id} onChange={updateorder_id} placeholder="Order Id"  />
                            <small style={{fontSize:".8rem",color:"#f16083"}}>{order_id_er}</small> 
                            </div>
                          </div>
                        </div>
                      </div>      

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Title</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="title"  value={title} onChange={updatetitle} placeholder="Project Title" />
                             <small style={{fontSize:".8rem",color:"#f16083"}}>{title_er}</small>                       
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Status</label>
                            <div class="col-sm-9">
                              <input class="form-control" type="text" id="status"  value={status} onChange={updatestatus} placeholder="status"  />
                              <small style={{fontSize:".8rem",color:"#f16083"}}>{status_er}</small>                         
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                      <div class="col-md-12">
                          <div class="form-group row">
                            <label  class="col-sm-2 col-form-label">Description</label>
                            <div class="col-sm-10">
                              <input class="form-control" id="description"  value={description} onChange={updatedescription} placeholder="enter project description here." />
                              <small style={{fontSize:".8rem",color:"#f16083"}}>{description_er}</small>                        
                            </div>
                          </div>
                        </div>
                            
                            
                   
                       
                    </div>
                    </div>
                    <button class="btn btn-block btn-lg btn-gradient-danger mt-4" onClick={create}>Create New Project</button>
                    
                  </div>
                </div>
              </div>

              </div>
    </div>
{/* MAIN PANEL END */}

</div>
{/* MAIN PAGE COMPONENT END */}

    {/* container-scroller */}
    {/* plugins:js */}
    <script src="./assets/vendors/js/vendor.bundle.base.js"></script>
    {/* endinject */}
    {/* Plugin js for this page */}
    {/* End plugin js for this page */}
    {/* inject:js */}
    <script src="./assets/js/off-canvas.js"></script>
    <script src="./assets/js/hoverable-collapse.js"></script>
    <script src="./assets/js/misc.js"></script>

    {/*} plugins:js */}
    <script src="assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="assets/vendors/chart.js/Chart.min.js"></script>
    <script src="assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="assets/js/off-canvas.js"></script>
    <script src="assets/js/hoverable-collapse.js"></script>
    <script src="assets/js/misc.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="assets/js/dashboard.js"></script>
    <script src="assets/js/todolist.js"></script>
    {/*} End custom js for this page */}
    {/* endinject */}
  </body>
</html>
</>
    );
}
//}

export default Admincreateproject;