import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from './../axios.js';
import Navbar from './Extracomponents/navbar.js';
import Personalinfo from './Candidateformelements/personalinfo.js';
import Address from './Candidateformelements/address.js';
import Skills from './Candidateformelements/skills.js';
import Educationdetails from './Candidateformelements/educationdetails.js';
import Coursescertificate from './Candidateformelements/coursescertificate.js';
import Workexperience from './Candidateformelements/workexperience.js';
import Projectwork from './Candidateformelements/projectwork.js';
import Attachlinks from './Candidateformelements/attachlinks.js';
import Achievements from './Candidateformelements/achievements.js';

import './Extracomponents/hiringportalcss.css';
/*import Amanbg from './amanbg';*/


function Candidateform(){

  
   // Setting Values fom Session Storage
 const userlogstatus=sessionStorage.getItem("userloginstatus00");//loginstatus
 //const loguser=sessionStorage.getItem("userusername00");//username
 const userlogtype=sessionStorage.getItem("userlogintype00");//logintype
 const userlogname=sessionStorage.getItem("userloginname00");//loginname
 const userlogadminid=sessionStorage.getItem("userloginadminid00");//loginadminid
     

 {/*}
   //Sessions Not Set
   if(((userlogstatus=="false")||(userlogstatus==null))&&/*(loguser==null)&&(userlogadminid==null)){
    //if(((logstatus=="true")||(logstatus==null))&&(loguser==null)&&(logadminid==null)){

  /*  sessionStorage.removeItem("username00"); 
    sessionStorage.removeItem("userloginstatus00");
    sessionStorage.removeItem("userlogintype00");
    sessionStorage.removeItem("userloginname00");
    sessionStorage.removeItem("userloginadminid00");
   
    alert("Session Expired! Login Again.");
    window.location.replace("/");
  
  }
  //Sessions Set
  else{
  */}


/* PORTAL CODING START */


    //pvalue
    const[pvalue,setpvalue] = useState("");


   //Use Effect, Data Load
   useEffect(()=>{

  
showprogressbar();

})


//Showprogressbar
function showprogressbar(){
  //Getting Pvalue if set
  const pval = sessionStorage.getItem("pval");
  if((pval!=null)||(pval!="")){
   setpvalue(pval);
  }
  else{ setpvalue("10%"); }
}


    return(
<>
<html lang="en">
  <head>
    {/*meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>theStartupConnect | Services Portal</title>
    {/* plugins:css */}
    <link rel="stylesheet" href="./assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="./assets/vendors/css/vendor.bundle.base.css" />
    {/* endinject */}
    {/* Plugin css for this page */}
    {/* End plugin css for this page */}
    {/* inject:css */}
    {/* endinject */}
    {/* Layout styles */}
    <link rel="stylesheet" href="./assets/css/style.css" />
    {/* End layout styles */}
   {/*} <link rel="shortcut icon" href="./assets/images/favicon.ico" /> */}
  </head>
  <body>


  <Navbar/>

{/* MAIN PAGE COMPONENT START */}
  <div class="container-fluid page-body-wrapper">

 {/*<Sidebar/>*/}


{/* MAIN PANEL START */}
<div class="main-panel" style={{marginTop:"5vh",width:"100vw"}} >



{/* PROGRESS BAR SECTION START */}
<div class="content-wrapper">

<div class="col-12">


                <div class="card">
                <div class="card-body">
                <h3>Form Completion</h3>
                <div class="progress">
                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow={pvalue} aria-valuemin="0" aria-valuemax="100" style={{width: pvalue,backgroundColor:"#38a5c9"}}></div>
               </div>
                </div>
                </div>
</div>
</div>
{/* PROGRESS BAR SECTION END */}


{/* PERSONAL DETAILS SECTION START */}
<div id="section-1" class="section-1" >
<Personalinfo></Personalinfo>
</div>
{/* PERSONAL DETAILS SECTION END */}

{/* ADDRESS SECTION START */}
<div class="section-2" id="section-2">
 <Address></Address>                 
</div>
{/* ADDRESS SECTION END */}

{/* EDUCATIONAL DETAILS SECTION START */}
<div class="section-3" id="section-3">
<Educationdetails></Educationdetails>
</div>
{/* EDUCATIONAL DETAILS SECTION END */}

{/* ADD SKILLS SECTION START */}
<div class="section-4" id="section-4">
<Skills></Skills>
</div>
{/* ADD SKILLS SECTION END */}

{/* COURSES & CERTIFICATIONS SECTION START */}
<div class="section-5" id="section-5">
<Coursescertificate></Coursescertificate>
</div>
{/* COURSES & CERTIFICATIONS SECTION END */}

{/* WORK EXPERIENCE SECTION START */}
<div class="section-6" id="section-6">
<Workexperience></Workexperience>
</div>
{/* WORK EXPERIENCE SECTION END */}
          
{/* PROJECTS & WORKS SECTION START */}
<div class="section-7" id="section-7">
<Projectwork></Projectwork>
</div>
{/* PROJECTS & WORKS SECTION END */}

{/* ATTACH LINK SECTION START */}
<div class="section-8" id="section-8">
<Attachlinks></Attachlinks>
</div>
{/* ATTACH LINK SECTION END */}
              
{/* ACHIEVEMENTS SECTION START */}
<div class="section-9" id="section-9">
<Achievements></Achievements>
</div>
{/* ACHIEVEMENTS SECTION END */}


{/* SUCCESS SECTION START */}
<div class="section-10" id="section-10">
<div class="content-wrapper">

<div class="col-12">


                <div class="card">
                  <div class="card-body">
                    <h2 >Form Completed</h2>
                    <div class="form-sample" style={{textAlign:"left"}}>
                      <p class="card-description"> Congratulations!!! </p>
                      <button type="button" class="btn btn-gradient-info float-end" onClick={()=>{
                          document.getElementById("section-1").style.display="block";//Showing first section
                          document.getElementById("section-10").style.display="none";//Hiding last section
                         sessionStorage.removeItem("pval"); //Removing Session
                         }}>Return to First Page</button>
                      </div>
                      </div>
                      </div>
                      </div>

                   
                                               </div>
</div>
{/* SUCCESS SECTION END */}

              </div>
              {/* MAIN PANEL END */}
              </div>
              {/* MAIN PAGE COMPONENT END */}
              </body>
              </html>
</>
    );
}
//}

export default Candidateform;