import React from "react";
import { useState, useEffect, Component } from "react";
import { useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
//import axios from '../../axios.js';
import moment from "moment/moment";

//import "./../Resources/homebtcss.css";
import "../../Resources/home2css.css";
import "../../Resources/serviceshomecss.css";

import "../../Resources/homebtcss.css";
import '../../Resources/responsiveness.css';
//import Sidebar from "./sidebar";

//import Chart from "chart.js/auto";
//import { Line, Bar } from "react-chartjs-2";
import Footer3 from "../../Supportingfiles/footer3";
//import { Navbar } from "flowbite-react";
import Navbar3 from "../../Supportingfiles/navbar3";
import Whystartupconnect from "../whystartupconnect";


function Pointofsale1() {
  return (
    <>
      <html lang="en">
        <head>
          {/*} Required meta tags */}
          <meta charset="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <title>STARTUP CONNECT | SERVICES | POINT OF SALE (POS)</title>
          {/*} plugins:css */}
          <link
            rel="stylesheet"
            href="panel/assets/vendors/mdi/css/materialdesignicons.min.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/css/vendor.bundle.base.css"
          />
          {/*} endinject */}
          {/*} Plugin css for this page */}
          <link
            rel="stylesheet"
            href="panel/assets/vendors/jquery-bar-rating/css-stars.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/font-awesome/css/font-awesome.min.css"
          />
          {/*} End plugin css for this page */}
          {/*} inject:css */}
          {/*} endinject */}
          {/*} Layout styles */}
          <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
          {/*} End layout styles */}
          <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
        </head>
        <body>

        
         
  {/* Whatsapp Chat Icon Start */}
  <div class="wtp-chat-container" /*style={{position:"fixed",marginTop:"92vh", marginLeft:"94vw"}}*/>
    <a aria-label="Chat on WhatsApp" href="https://wa.me/918480002413?text=I%20want%20to%20know%20more%20about%20service%20PoS%20Software%20Development%20by%20the%20Startup%20Connect">   
    <img class="wtp-chat-icon" src="https://cdn-icons-png.flaticon.com/128/3938/3938058.png" /*style={{height:"3rem", width:"3rem"}}*/></img>
  </a>
  </div>
  {/* Whatsapp Chat Icon End */}

          <div class="home2-container">
            <Navbar3 />

            {/* HEADER SECTION START */}
            <section /*class="sv-header-section" */>
              <div class="sv-header-container1">
                <div class="sv-header-left home-header-pd">
                  <h1 style={{ color: "#004463" }}>
                    POINT OF SALE (POS)
                  </h1>
                  <p>POINT OF SALE (POS) is where you ring up customers. We can help you with hardwares such as barcode scanners, cash registers, and payment terminals, as well as software for managing transactions and generating reports, along with that our company can help your business improve efficiency, accuracy, and customer service by automating routine tasks and providing real-time insights into sales and inventory data.</p>
                  <div class="home2-services-features-list">
                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img loading = "lazy"

                          src="https://cdn-icons-png.flaticon.com/128/1087/1087840.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>500+</h3>
                        <h5 style={{ fontWeight: "bold" }}>Projects</h5>
                      </div>
                    </div>

                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img loading = "lazy"

                          src="https://cdn-icons-png.flaticon.com/128/12735/12735475.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>25+</h3>
                        <h5 style={{ fontWeight: "bold" }}>Cities</h5>
                      </div>
                    </div>

                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img loading = "lazy"

                          src="https://cdn-icons-png.flaticon.com/128/2991/2991235.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>
                          4{" "}
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/7656/7656139.png"
                            style={{ height: "2rem", width: "2rem" }}
                          />
                          +
                        </h3>
                        <h5 style={{ fontWeight: "bold" }}>Google Ratings</h5>
                      </div>
                    </div>
                  </div>

                  <div  class="serviceshome-btn-container">
             <div>
             <a href="https://calendly.com/thestartupconnect/30min"><button class="btn-lft-margin" id="home2-modules-section-btn" style={{marginLeft:"0"}}>Book Free Consultancy <i class="mdi mdi-arrow-right"></i></button></a>
             </div>
             <div style={{marginLeft:"0"}}>
             <a href="/home1/Website_and_Software_Development_Brochure.pdf" download="Startup Connect Services Brochure"><button class="btn-lft-margin" id="home2-modules-section-btn" style={{backgroundImage: "linear-gradient(to right, #208d17, #00ad11)", marginLeft:"0"}}>Download Brochure <i class="mdi mdi-download"></i></button></a>
             </div>
             </div>
             
                </div>
                <div
                  class="sv-header-right" /* style={{backgroundColor:"#96dfff",padding:"2rem",borderRadius:"15px",boxShadow:"2px 3px 3px 2px #cbcecf"}} */
                >
                  <img loading = "lazy"

                    src="https://img.freepik.com/free-vector/cashier-supermarket-isolated-person-smiling-woman-employee-uniform-standing-cash-desk-store-employee-working-grocery-shop-market-retail-commerce_575670-1280.jpg"
                    style={{ width: "90%", height: "100%" }}
                  />
                </div>
              </div>
            </section>
            {/* HEADER SECTION END */}
            <hr />

            {/* SIDEBAR AND MAIN PAGE START */}
            <div className="container-scroller">
              {/*<Sidebar /> */}
              <div className="container-fluid page-body-wrapper">
                {/*} partial */}
                <div className="main-panel">
                 

              

               
                  <h2 id="ip-h2" style={{fontWeight:"bold"}}>
                    WHY CHOOSE A  <span style={{ color: "#9f23c2" }}>
                           POINT OF SALE (POS)
                          </span>
                  </h2>
                  {/* BENEFITS SECTION START */}
                  <div class="row" style={{ padding: "2rem" }}>
                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/10691/10691773.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Sales Tracking</h4>
                        <p>
                        POS systems can track sales transactions for your company, including item details, prices, and discounts .</p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/8637/8637227.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Intgrations with Other Systems</h4>
                        <p>
                        POS systems can integrate with your business systems, such as accounting software and e-commerce platforms  </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/4866/4866986.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Inventory Management</h4>
                        <p>
                        POS systems can manage inventory levels, including stock counts, reorder alerts, and purchase order management of your business/company .
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/8984/8984290.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Payment Processing</h4>
                        <p>
                        Your payment types can be processed easily by POS system, such as credit and debit cards, mobile payments, and cash.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/8163/8163243.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Customer Management</h4>
                        <p>
                        POS systems can manage customer profiles and purchase history, allowing your businesses to provide personalized service and marketing. </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/2316/2316065.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Reporting and Analytics</h4>
                        <p>
                        POS systems can help you generate reports and analytics on sales, inventory, and customer data, providing your business with insights into their operations.</p>
                      </div>
                    </div>

                   
                  
                   
                  </div>
                  {/* BENEFITS SECTION END */}

                  <hr />
                  {/* REQUIREMENTS SECTION START */}
                  <h2 id="ip-h2" style={{fontWeight:"bold"}}>BENEFITS OF A <span style={{color:"#140d83"}}>POINT OF SALE (POS)</span></h2>
                  <p style={{fontSize:"1rem", padding:"0.5rem"}}>Transform your retail or service business with the seamless efficiency of our cutting-edge POS software. Opting for POS software is not just about upgrading your checkout system—it's a strategic move to enhance customer experiences, streamline operations, and boost overall business performance. Here are compelling reasons why choosing POS software is essential for your organization:</p>

                  <div class="row" style={{ padding: "2rem" }}>
                    <div class="col-sm-4 stretch-card grid-margin">
                      <img loading = "lazy"

                        src="https://img.freepik.com/free-vector/self-checkout-concept-illustration_114360-2331.jpg"
                        style={{ width: "90%", height: "100%" }}
                      />
                    </div>

                    <div
                      class="col-sm-8 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div>
                        <h4 style={{ fontWeight: "bold" }}>
                        <i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> Efficient and Swift Transactions:
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                          {" "}
                          Simplify and expedite the checkout process with our POS software. Enjoy faster transaction times, reducing wait times for customers and improving overall operational efficiency.</p>
                        <br />
                        <h4 style={{ fontWeight: "bold" }}>
                        <i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> Emhanced Customer Experience:
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                        Elevate customer satisfaction with a seamless and user-friendly checkout process. Our POS software enables quick and accurate transactions, minimizing errors and providing a positive experience for your customers.</p>
                        <br />
                     
                        </div>
                        </div>
                        </div>
                         
                        <div class="row" style={{ padding: "2rem" }}>
                  

                    <div
                      class="col-sm-8 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div>
                        <h4 style={{ fontWeight: "bold" }}>
                        <i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> Promotion and Discount Management:
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                        Run promotions and manage discounts effortlessly. Our POS software enables you to set up and manage promotions, discounts, and loyalty programs, enhancing your marketing efforts and driving sales.</p>
                        <br />
                        <h4 style={{ fontWeight: "bold" }}>
                        <i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> Cloud-Based Accessibility:
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                        Access your POS system from anywhere with cloud-based functionality. Our POS software allows you to manage your business remotely, check real-time sales data, and make informed decisions on the go.</p>
                      </div>
                    </div>
                    <div class="col-sm-4 stretch-card grid-margin">
                      <img loading = "lazy"

                        src="https://img.freepik.com/free-vector/group-illustrated-supermarket-workers_23-2148518530.jpg"
                        style={{ width: "90%", height: "100%" }}
                      />
                    </div>
                  </div>

                  {/* REQUIREMENTS SECTION END */}
                  <hr />
               

                  {/* Why StartUp Connect Starts */}
                  <Whystartupconnect/>
                  {/* Why StartUp Connect Ends */}

                  <hr />

                  {/* PRICING SECTION START */}
                  <h2 id="ip-h2" style={{fontWeight:'bold'}}>PRICING FOR <span style={{color:"#006345"}}>POINT OF SALE (POS)</span></h2>
                  <div class="row md-12" style={{marginTop:"5rem"}}>

                  {/* PRICING CARD 1 START */}
                  <div
                      class="col-sm-12 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                    <div class="card">
                    <div style={{backgroundColor:"#207a78",color:"#ffffff",fontWeight:"bold",textAlign:"center",borderRadius:"12px",padding:"1rem"}}>
                    <h3 class="pricing-ul-text"><u>GET CUSTOMIZED QUOTATIONS FOR POS DEVELOPMENT IN YOUR BUDGET</u></h3>
                    <div style={{textAlign:"center"}}><h2 style={{fontWeight:"bold"}}> Starting at <span style={{color:"#ffffff"}}>₹ 14999*</span> only</h2></div>
                    </div>
                    <div style={{padding:"1rem",margingTop:"1rem"}}>
                       <div class="d-flex justify-content-center">
                       <button class="btn btn-lg btn-success" style={{backgroundColor:"#207a78", fontSize:"1rem"}}  onClick={()=>{
                        //Setting Values to redirect to Contact Sales Page
                        sessionStorage.setItem("Smodule00","Services");sessionStorage.setItem("Sservice00","Point of Sale Development"); window.location.replace("/businesstools-contactsales");
                       }}>Contact Sales</button>
                       </div>
                    </div>
                    </div>
                    </div>
                    {/* PRICING CARD 1 END */}

                  </div>
                  <hr/>
                  {/* PRICING SECTION END */}
              

             
                  <h2 id="ip-h2">Frequently Asked Questions (FAQs)</h2>
                  {/* FAQ SECTION START */}
                  <div
                    id="accordion"
                    style={{ textAlign: "left", marginTop: "3rem" }}
                  >
                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#003e73" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                       How does a POS system improve business efficiency?
                        </h5>
                        <div>
                          <button
                            id="ques-1-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-1").style.display =
                                "block";
                              document.getElementById(
                                "ques-1-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-1-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-1-dicon"
                            style={{ display: "none" }}
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-1").style.display =
                                "none";
                              document.getElementById(
                                "ques-1-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-1-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-1">
                        <div class="card-body">
                        A POS system can improve business efficiency by automating sales transactions, managing inventory, providing real-time sales data, simplifying accounting processes, reducing errors, and streamlining operations.
                        </div>
                        </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                         Is POS system necessary for small businesses?
                        </h5>
                        <div>
                          <button
                            id="ques-2-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-2").style.display =
                                "block";
                              document.getElementById(
                                "ques-2-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-2-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-2-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-2").style.display =
                                "none";
                              document.getElementById(
                                "ques-2-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-2-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-2" style={{ display: "none" }}>
                        <div class="card-body">
                        A POS system may not be necessary for all small businesses, but it can help improve efficiency, accuracy, and customer experience, especially if the business handles a high volume of transactions or inventory.  </div>
                        </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                       Are point of sale systems secure?
                        </h5>
                        <div>
                          <button
                            id="ques-3-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-3").style.display =
                                "block";
                              document.getElementById(
                                "ques-3-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-3-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-3-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-3").style.display =
                                "none";
                              document.getElementById(
                                "ques-3-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-3-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-3" style={{ display: "none" }}>
                        <div class="card-body">
                        Yes, most POS systems are designed with security in mind. They use encryption to protect customer data during transactions, and many systems also have built-in fraud prevention tools.
                        </div>
                        </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                       Can I access my transaction history with a point of sale system?
                        </h5>
                        <div>
                          <button
                            id="ques-4-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-4").style.display =
                                "block";
                              document.getElementById(
                                "ques-4-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-4-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-4-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-4").style.display =
                                "none";
                              document.getElementById(
                                "ques-4-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-4-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-4" style={{ display: "none" }}>
                        <div class="card-body">
                        Most businesses keep a record of your transaction history, and you may be able to access this information through a customer account or loyalty program. You can usually request a receipt at the time of purchase as well.  </div>
                      </div>
                    </div>

               {/*}     <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                     How does billing software help businesses manage their billing processes?

                        </h5>
                        <div>
                          <button
                            id="ques-5-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-5").style.display =
                                "block";
                              document.getElementById(
                                "ques-5-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-5-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-5-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-5").style.display =
                                "none";
                              document.getElementById(
                                "ques-5-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-5-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-5" style={{ display: "none" }}>
                        <div class="card-body">
                        Billing software helps businesses manage their billing processes by automating routine tasks such as invoicing, payment reminders, and late fees. It also provides real-time visibility into billing data, allowing businesses to make informed decisions about their billing practices.
                        </div>
                         </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                       How can billing software help businesses provide transparency into billing history?
                        </h5>
                        <div>
                          <button
                            id="ques-6-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-6").style.display =
                                "block";
                              document.getElementById(
                                "ques-6-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-6-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-6-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-6").style.display =
                                "none";
                              document.getElementById(
                                "ques-6-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-6-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-6" style={{ display: "none" }}>
                        <div class="card-body">
                        Billing software can help businesses provide transparency into billing history by allowing customers to view their billing history online. This makes it easy for customers to track their payments and resolve any billing disputes.</div>
                      </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                         How can I ensure the security of my billing information when using billing software?
                        </h5>
                        <div>
                          <button
                            id="ques-7-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-7").style.display =
                                "block";
                              document.getElementById(
                                "ques-7-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-7-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-7-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-7").style.display =
                                "none";
                              document.getElementById(
                                "ques-7-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-7-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-7" style={{ display: "none" }}>
                        <div class="card-body">
                        To ensure the security of your billing information when using billing software, you should choose a reputable and reliable software provider that uses encryption and other security measures to protect your</div>
                      </div>
                    </div>

                  {/*} <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                         What kind of analytics can I expect from a web app?
                        </h5>
                        <div>
                          <button
                            id="ques-8-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-8").style.display =
                                "block";
                              document.getElementById(
                                "ques-8-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-8-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-8-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-8").style.display =
                                "none";
                              document.getElementById(
                                "ques-8-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-8-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-8" style={{ display: "none" }}>
                        <div class="card-body">
                        Web apps can provide advanced analytics to track user behavior and identify areas for improvement.{" "}
                        </div>
                      </div>
                    </div> */}
                  </div>
                  {/* FAQ SECTION END */}

                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
            {/* SIDEBAR AND MAIN PAGE END */}

            <Footer3 />
          </div>
          {/*} container-scroller */}
          {/*} plugins:js */}
          <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
          {/*} endinject */}
          {/*} Plugin js for this page */}
          <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
          <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
          <script
            src="panel/assets/js/jquery.cookie.js"
            type="text/javascript"
          ></script>
          {/*} End plugin js for this page */}
          {/*} inject:js */}
          <script src="panel/assets/js/off-canvas.js"></script>
          <script src="panel/assets/js/hoverable-collapse.js"></script>
          <script src="panel/assets/js/misc.js"></script>
          <script src="panel/assets/js/settings.js"></script>
          <script src="panel/assets/js/todolist.js"></script>
          {/*} endinject */}
          {/*} Custom js for this page */}
          <script src="panel/assets/js/dashboard.js"></script>
          {/*} End custom js for this page */}
        </body>
      </html>
    </>
  );
}

export default Pointofsale1;
