import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from 'axios';


function CandidateprofileAddskills(){

  

    return(
<>

        {/* ADD SKILLS SECTION START */}
        <div class="hire-form-panel" style={{backgroundColor:"#ffffff",display:"none"}} id="skills-section">
            <h3>Add Skills</h3>
            <p style={{color:"#acafb0"}}>Add your skills that will be displayed in your profile and detailed resume.</p>

            <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Add Skills</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : HTML" /></div>
            </div>
         
            </div>
            <div class="hire-list-card-skillbar">
            <div class="hire-card-skill-badge">
            <span>MySql</span>
            </div>  
            <div class="hire-card-skill-badge">
            <span>Web Development</span>
            </div>  
            <div class="hire-card-skill-badge">
            <span>React Js</span>
            </div>  
            <div class="hire-card-skill-badge">
            <span>Laravel</span>
            </div>  
            <div class="hire-card-skill-badge">
            <span>HTML/CSS</span>
            </div>  
       
        </div>
        <div class="hire-form-btn">
            <button class="hire-btn-default" style={{backgroundColor:"#12d571",marginRight:"2rem",marginTop:"2rem",marginBottom:"2rem"}}
            onClick={()=>{ document.getElementById("skills-section").style.display="none";
            document.getElementById("courses-certifications-section").style.display="block";
             }}
            >Save and Next</button>
            </div>

            </div>
            {/* ADD SKILLS SECTION END */}
          
              
</>
);
    }

export default CandidateprofileAddskills;