import React from "react";
import { useState, useEffect, Component } from "react";
import { useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
//import axios from '../../axios.js';
import moment from "moment/moment";

//import "./../Resources/homebtcss.css";
import "../../Resources/home2css.css";
import "../../Resources/serviceshomecss.css";

import "../../Resources/homebtcss.css";
import '../../Resources/responsiveness.css';
//import Sidebar from "./sidebar";

//import Chart from "chart.js/auto";
//import { Line, Bar } from "react-chartjs-2";
import Footer3 from "../../Supportingfiles/footer3";
//import { Navbar } from "flowbite-react";
import Navbar3 from "../../Supportingfiles/navbar3";
import Whystartupconnect from "../whystartupconnect";


function Staticdisplaywebsite1() {
  return (
    <>
      <html lang="en">
        <head>
          {/*} Required meta tags */}
          <meta charset="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <title>STARTUP CONNECT | SERVICES | STATIC DISPLAY WEBSITE</title>
          {/*} plugins:css */}
          <link
            rel="stylesheet"
            href="panel/assets/vendors/mdi/css/materialdesignicons.min.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/css/vendor.bundle.base.css"
          />
          {/*} endinject */}
          {/*} Plugin css for this page */}
          <link
            rel="stylesheet"
            href="panel/assets/vendors/jquery-bar-rating/css-stars.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/font-awesome/css/font-awesome.min.css"
          />
          {/*} End plugin css for this page */}
          {/*} inject:css */}
          {/*} endinject */}
          {/*} Layout styles */}
          <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
          {/*} End layout styles */}
          <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
        </head>
        <body>

        
  {/* Whatsapp Chat Icon Start */}
  <div class="wtp-chat-container" /*style={{position:"fixed",marginTop:"92vh", marginLeft:"94vw"}}*/>
    <a aria-label="Chat on WhatsApp" href="https://wa.me/918480002413?text=I%20want%20to%20know%20more%20about%20service%20Static%20Display%20Website%20Development%20by%20the%20Startup%20Connect">   
    <img class="wtp-chat-icon" src="https://cdn-icons-png.flaticon.com/128/3938/3938058.png" /*style={{height:"3rem", width:"3rem"}}*/></img>
  </a>
  </div>
  {/* Whatsapp Chat Icon End */}


          <div class="home2-container">
            <Navbar3 />

            {/* HEADER SECTION START */}
            <section /*class="sv-header-section" */>
              <div class="sv-header-container1">
                <div class="sv-header-left home-header-pd">
                  <h1 style={{ color: "#004463" }}>
                    STATIC DISPLAY WEBSITE
                  </h1>
                  <p><b>A basic, non-interactive website that provides information about your business or organization.</b></p>
                  <p>Static Display Website is a simple website that provides information about a business or organization. It typically consists of HTML and CSS files and is not interactive. TheStartupConnect offers static display website design and development services, ensuring businesses have a professional online presence.</p>

                  <div class="home2-services-features-list">
                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img loading = "lazy"

                          src="https://cdn-icons-png.flaticon.com/128/1087/1087840.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>500+</h3>
                        <h5 style={{ fontWeight: "bold" }}>Projects</h5>
                      </div>
                    </div>

                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img loading = "lazy"

                          src="https://cdn-icons-png.flaticon.com/128/12735/12735475.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>25+</h3>
                        <h5 style={{ fontWeight: "bold" }}>Cities</h5>
                      </div>
                    </div>

                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img loading = "lazy"

                          src="https://cdn-icons-png.flaticon.com/128/2991/2991235.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>
                          4{" "}
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/7656/7656139.png"
                            style={{ height: "2rem", width: "2rem" }}
                          />
                          +
                        </h3>
                        <h5 style={{ fontWeight: "bold" }}>Google Ratings</h5>
                      </div>
                    </div>
                  </div>

                  <div  class="serviceshome-btn-container">
             <div>
             <a href="https://calendly.com/thestartupconnect/30min"><button class="btn-lft-margin" id="home2-modules-section-btn" style={{marginLeft:"0"}}>Book Free Consultancy <i class="mdi mdi-arrow-right"></i></button></a>
             </div>
             <div style={{marginLeft:"0"}}>
             <a href="/home1/Website_and_Software_Development_Brochure.pdf" download="Startup Connect Services Brochure"><button class="btn-lft-margin" id="home2-modules-section-btn" style={{backgroundImage: "linear-gradient(to right, #208d17, #00ad11)", marginLeft:"0"}}>Download Brochure <i class="mdi mdi-download"></i></button></a>
             </div>
             </div>

             
                </div>
                <div
                  class="sv-header-right" /* style={{backgroundColor:"#96dfff",padding:"2rem",borderRadius:"15px",boxShadow:"2px 3px 3px 2px #cbcecf"}} */
                >
                  <img loading = "lazy"

                    src="https://img.freepik.com/free-vector/gradient-homepage-illustration_23-2149247166.jpg"
                    style={{ width: "90%", height: "100%" }}
                  />
                </div>
              </div>
            </section>
            {/* HEADER SECTION END */}
            <hr />

            {/* SIDEBAR AND MAIN PAGE START */}
            <div className="container-scroller">
              {/*<Sidebar /> */}
              <div className="container-fluid page-body-wrapper">
                {/*} partial */}
                <div className="main-panel">
                 

              

               
                  <h2 id="ip-h2" style={{fontWeight:"bold"}}>
                    WHY CHOOSE A  <span style={{ color: "#9f23c2" }}>
                            STATIC DISPLAY WEBSITE
                          </span>
                  </h2>
                  {/* BENEFITS SECTION START */}
                  <div class="row" style={{ padding: "2rem" }}>
                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/922/922699.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Simple Design</h4>
                        <p>
                        Static display websites have a straightforward design with minimal visual effects.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/2973/2973778.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Easy to Develop</h4>
                        <p>
                        Static websites are quick and easy to develop, reducing development costs.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/10165/10165363.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Low Hosting Costs</h4>
                        <p>
                        Static websites require less hosting resources, resulting in lower hosting costs.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/4285/4285622.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Fast Loading Times</h4>
                        <p>
                        Static websites load quickly due to their simple design and lack of dynamic content.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/3579/3579143.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">SEO Optimization</h4>
                        <p>
                        Static websites can be optimized for search engines, improving visibility online.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-2 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/12263/12263199.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Minimal Maintenance</h4>
                        <p>
                        Static websites require minimal maintenance since they don't have dynamic features.
                        </p>
                      </div>
                    </div>

                   
                  
                   
                  </div>
                  {/* BENEFITS SECTION END */}

                  <hr />
                  {/* REQUIREMENTS SECTION START */}
                  <h2 id="ip-h2" style={{fontWeight:"bold"}}>BENEFITS OF A <span style={{color:"#140d83"}}>STATIC DISPLAY WEBSITE</span></h2>
                  <p style={{fontSize:"1rem", padding:"0.5rem"}}>In a dynamic digital landscape, the choice of your website's architecture plays a crucial role in determining its performance, user experience, and overall efficiency. While dynamic websites offer real-time interactions and constant updates, static display websites have their own set of advantages that make them a preferred choice for many.</p>

                  <div class="row" style={{ padding: "2rem" }}>
                    <div class="col-sm-4 stretch-card grid-margin">
                      <img loading = "lazy"

                        src="https://img.freepik.com/free-vector/setup-concept-illustration_114360-242.jpg"
                        style={{ width: "90%", height: "100%" }}
                      />
                    </div>

                    <div
                      class="col-sm-8 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div>
                        <h4 style={{ fontWeight: "bold" }}>
                        <i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> Speed and Performance:
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                          {" "}
                          Static websites are known for their lightning-fast loading times. Since they consist of fixed, pre-built pages, there's no need to generate content dynamically, leading to quicker page loads. This results in a seamless and enjoyable browsing experience for your visitors.
                        </p>
                        <br />
                        <h4 style={{ fontWeight: "bold" }}>
                        <i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> Simplicity and Security:
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                        Static websites are inherently simpler, with a straightforward structure and minimal server-side processing. This simplicity not only makes them easier to develop and maintain but also reduces the surface area for potential security vulnerabilities. A static site can be a robust choice for those prioritizing security and ease of management.
                        </p>
                        <br />
                        <h4 style={{ fontWeight: "bold" }}>
                        <i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> Cost-Effectiveness:
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                        Hosting static websites is often more cost-effective compared to dynamic counterparts. With fewer server resources required, you can save on hosting expenses. This makes static websites an excellent choice for individuals, small businesses, or projects with budget constraints.
                        </p>
                        </div>
                        </div>
                        </div>
                         
                        <div class="row" style={{ padding: "2rem" }}>
                  

                    <div
                      class="col-sm-8 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div>
                        <h4 style={{ fontWeight: "bold" }}>
                        <i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> Reliability and Stability:
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                        Static websites are highly reliable because they don't rely on server-side processes or databases. This means they are less prone to issues like server crashes or database failures. Your content remains accessible to users, ensuring a stable online presence.
                        </p>
                        <br />
                        <h4 style={{ fontWeight: "bold" }}>
                        <i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> SEO Friendliness:
                        </h4>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                        Search engines appreciate the simplicity and speed of static websites. Faster load times and cleaner code can positively impact your site's search engine rankings. If search engine optimization (SEO) is a priority, a static display website can contribute to better visibility and discoverability.
                        </p>
                      </div>
                    </div>
                    <div class="col-sm-4 stretch-card grid-margin">
                      <img loading = "lazy"

                        src="https://img.freepik.com/free-vector/social-network-teamwork-vector-concept_81522-387.jpg"
                        style={{ width: "90%", height: "100%" }}
                      />
                    </div>
                  </div>

                  {/* REQUIREMENTS SECTION END */}
                  <hr />
               

                  {/* Why StartUp Connect Starts */}
                  <Whystartupconnect/>
                  {/* Why StartUp Connect Ends */}

                  <hr />

                  {/* PRICING SECTION START */}
                  <h2 id="ip-h2" style={{fontWeight:'bold'}}>PRICING FOR <span style={{color:"#006345"}}>STATIC DISPLAY WEBSITE</span></h2>
                  <div class="row md-12 justify-content-center" style={{marginTop:"5rem", padding:"1rem"}}>

                  {/* PRICING CARD 1 START */}
                  <div
                      class="col-sm-4 stretch-card grid-margin" id="resp-2"
                      style={{ textAlign: "left" }}
                    >
                    <div class="card">
                    <div style={{backgroundColor:"#207a78",color:"#ffffff",fontWeight:"bold",textAlign:"center",borderRadius:"12px",padding:"1rem"}}>
                    <h3><u>BASIC</u></h3>
                    <div style={{textAlign:"center"}}><h2 style={{fontWeight:"bold"}}> Starts <span style={{color:"#ffffff"}}>₹ 999*</span></h2></div>
                    </div>
                    <div style={{padding:"1rem",margingTop:"1rem"}}>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i> 1 Static Page Website</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Maximum 7 Sections</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-warning" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Basic SEO</p>
                        <p style={{fontSize:"1rem", textDecoration:"line-through"}}><i class="mdi mdi-close-circle text-danger" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Free Hosting*</p>
                        <hr/>
                       <div class="d-flex justify-content-center">
                       <button class="btn btn-lg btn-success" style={{backgroundColor:"#207a78", fontSize:"1rem"}} onClick={()=>{
                        //Setting Values to redirect to Contact Sales Page
                        sessionStorage.setItem("Smodule00","Services");sessionStorage.setItem("Sservice00","Static Display Website Development"); window.location.replace("/businesstools-contactsales");
                       }}>Contact Sales</button>
                       </div>
                    </div>
                    </div>
                    </div>
                    {/* PRICING CARD 1 END */}

                      {/* PRICING CARD 2 START */}
                  <div
                      class="col-sm-4 stretch-card grid-margin" id="resp-2"
                      style={{ textAlign: "left" }}
                    >
                    <div class="card">
                    <div style={{backgroundColor:"#207a78",color:"#ffffff",fontWeight:"bold",textAlign:"center",borderRadius:"12px",padding:"1rem"}}>
                    <h3><u>STANDARD</u></h3>
                    <div style={{textAlign:"center"}}><h2 style={{fontWeight:"bold"}}> Starts <span style={{color:"#ffffff"}}>₹ 4999*</span></h2></div>
                    </div>
                    <div style={{padding:"1rem",margingTop:"1rem"}}>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i> 5 Static Page Website</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Maximum 7 Sections per Page</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-warning" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Basic SEO</p>
                        <p style={{fontSize:"1rem", textDecoration:"line-through"}}><i class="mdi mdi-close-circle text-danger" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Free Hosting*</p>
                        <hr/>
                       <div class="d-flex justify-content-center">
                       <button class="btn btn-lg btn-success" style={{backgroundColor:"#207a78", fontSize:"1rem"}} onClick={()=>{
                        //Setting Values to redirect to Contact Sales Page
                        sessionStorage.setItem("Smodule00","Services");sessionStorage.setItem("Sservice00","Static Display Website Development"); window.location.replace("/businesstools-contactsales");
                       }}>Contact Sales</button>
                       </div>
                    </div>
                    </div>
                    </div>
                    {/* PRICING CARD 2 END */}

                      {/* PRICING CARD 3 START */}
                  <div
                      class="col-sm-4 stretch-card grid-margin" id="resp-2"
                      style={{ textAlign: "left" }}
                    >
                    <div class="card">
                    <div style={{backgroundColor:"#207a78",color:"#ffffff",fontWeight:"bold",textAlign:"center",borderRadius:"12px",padding:"1rem"}}>
                    <h3><u>PREMIUM</u></h3>
                    <div style={{textAlign:"center"}}><h2 style={{fontWeight:"bold"}}> Starts <span style={{color:"#ffffff"}}>₹ 8999*</span></h2></div>
                    </div>
                    <div style={{padding:"1rem",margingTop:"1rem"}}>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i> 10 Static Page Website</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Maximum 9 Sections per Page</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>SEO</p>
                        <p style={{fontSize:"1rem"}}><i class="mdi mdi-checkbox-marked-circle text-success" style={{fontSize:"1.6rem",padding:"1rem"}}></i>Free Hosting*</p>
                        <hr/>
                       <div class="d-flex justify-content-center">
                       <button class="btn btn-lg btn-success" style={{backgroundColor:"#207a78", fontSize:"1rem"}} onClick={()=>{
                        //Setting Values to redirect to Contact Sales Page
                        sessionStorage.setItem("Smodule00","Services");sessionStorage.setItem("Sservice00","Static Display Website Development"); window.location.replace("/businesstools-contactsales");
                       }}>Contact Sales</button>
                       </div>
                    </div>
                    </div>
                    </div>
                    {/* PRICING CARD 3 END */}


                  </div>
                  <hr/>
                  {/* PRICING SECTION END */}
              

             
                  <h2 id="ip-h2">Frequently Asked Questions (FAQs)</h2>
                  {/* FAQ SECTION START */}
                  <div
                    id="accordion"
                    style={{ textAlign: "left", marginTop: "3rem" }}
                  >
                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#003e73" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                         What is a static display website?
                        </h5>
                        <div>
                          <button
                            id="ques-1-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-1").style.display =
                                "block";
                              document.getElementById(
                                "ques-1-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-1-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-1-dicon"
                            style={{ display: "none" }}
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-1").style.display =
                                "none";
                              document.getElementById(
                                "ques-1-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-1-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-1">
                        <div class="card-body">
                        A static display website is a basic website that provides information about a business or organization, without any interactive features.
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                          How long does it take to develop a static website?
                        </h5>
                        <div>
                          <button
                            id="ques-2-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-2").style.display =
                                "block";
                              document.getElementById(
                                "ques-2-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-2-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-2-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-2").style.display =
                                "none";
                              document.getElementById(
                                "ques-2-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-2-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-2" style={{ display: "none" }}>
                        <div class="card-body">
                        The development time for a static website varies based on the project scope, but it generally takes less time than developing a dynamic website.{" "}
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                        How is a static website different from a dynamic website?
                        </h5>
                        <div>
                          <button
                            id="ques-3-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-3").style.display =
                                "block";
                              document.getElementById(
                                "ques-3-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-3-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-3-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-3").style.display =
                                "none";
                              document.getElementById(
                                "ques-3-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-3-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-3" style={{ display: "none" }}>
                        <div class="card-body">
                        Static websites have fixed content, whereas dynamic websites feature interactive content and are built using programming languages.{" "}
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                         Can a static website be updated easily?
                        </h5>
                        <div>
                          <button
                            id="ques-4-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-4").style.display =
                                "block";
                              document.getElementById(
                                "ques-4-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-4-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-4-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-4").style.display =
                                "none";
                              document.getElementById(
                                "ques-4-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-4-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-4" style={{ display: "none" }}>
                        <div class="card-body">
                        Yes, updates to a static website can be easily made by editing the HTML and CSS files.{" "}
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                       Can static websites be optimized for search engines?

                        </h5>
                        <div>
                          <button
                            id="ques-5-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-5").style.display =
                                "block";
                              document.getElementById(
                                "ques-5-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-5-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-5-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-5").style.display =
                                "none";
                              document.getElementById(
                                "ques-5-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-5-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-5" style={{ display: "none" }}>
                        <div class="card-body">
                        Yes, static websites can be optimized for search engines by using appropriate metadata, keywords, and other techniques.{" "}
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                         Is a static website mobile-friendly?
                        </h5>
                        <div>
                          <button
                            id="ques-6-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-6").style.display =
                                "block";
                              document.getElementById(
                                "ques-6-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-6-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-6-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-6").style.display =
                                "none";
                              document.getElementById(
                                "ques-6-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-6-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-6" style={{ display: "none" }}>
                        <div class="card-body">
                        Yes, a static website can be designed to be mobile-friendly and responsive to different screen sizes.{" "}
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                         What is the cost of developing a static website?
                        </h5>
                        <div>
                          <button
                            id="ques-7-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-7").style.display =
                                "block";
                              document.getElementById(
                                "ques-7-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-7-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-7-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-7").style.display =
                                "none";
                              document.getElementById(
                                "ques-7-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-7-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-7" style={{ display: "none" }}>
                        <div class="card-body">
                        The cost of developing a static website varies based on the project requirements and complexity, but it is generally more affordable than developing a dynamic website.{" "}
                        </div>
                      </div>
                    </div>

                {/*}    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                          What are the key documents required for registering a
                          Private Limited Company?
                        </h5>
                        <div>
                          <button
                            id="ques-8-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-8").style.display =
                                "block";
                              document.getElementById(
                                "ques-8-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-8-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-8-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-8").style.display =
                                "none";
                              document.getElementById(
                                "ques-8-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-8-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-8" style={{ display: "none" }}>
                        <div class="card-body">
                          The key documents required for registering a Private
                          Limited Company typically include Memorandum of
                          Association (MOA), Articles of Association (AOA),
                          Director Identification Number (DIN), Digital
                          Signature Certificate (DSC), proof of identity and
                          address, PAN card, address proof of registered office,
                          NOC from the property owner, consent and declaration
                          forms, and certificate of incorporation (if applicable
                          for foreign directors or shareholders).{" "}
                        </div>
                      </div>
                    </div> */}
                  </div>
                  {/* FAQ SECTION END */}

                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
            {/* SIDEBAR AND MAIN PAGE END */}

            <Footer3 />
          </div>
          {/*} container-scroller */}
          {/*} plugins:js */}
          <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
          {/*} endinject */}
          {/*} Plugin js for this page */}
          <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
          <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
          <script
            src="panel/assets/js/jquery.cookie.js"
            type="text/javascript"
          ></script>
          {/*} End plugin js for this page */}
          {/*} inject:js */}
          <script src="panel/assets/js/off-canvas.js"></script>
          <script src="panel/assets/js/hoverable-collapse.js"></script>
          <script src="panel/assets/js/misc.js"></script>
          <script src="panel/assets/js/settings.js"></script>
          <script src="panel/assets/js/todolist.js"></script>
          {/*} endinject */}
          {/*} Custom js for this page */}
          <script src="panel/assets/js/dashboard.js"></script>
          {/*} End custom js for this page */}
        </body>
      </html>
    </>
  );
}

export default Staticdisplaywebsite1;
