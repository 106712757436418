import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './Resources/home2css.css';
import './Resources/serviceshomecss.css';
import Footer1 from './Supportingfiles/footer1';
import Footer from './Supportingfiles/footer';

//import './test123.css';

function Serviceshome1(){


      return(
      <>


<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />

    <meta name="theme-color" content="#000000" />
    <meta
      name="description"
      content="Get your startup services relating to website development, consultancy, taxation, business registration, software services, intern hiring, social media management, digital marketing and much more.  "></meta>
    
   {/* <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />*/}
        <link rel="icon" href="./images/startupconnect_icon.ico" />
    <link rel="apple-touch-icon" href="./images/startupconnect_icon.png" />

      {/* plugins:css */}
      <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/* endinject */}
    {/* Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/* End plugin css for this page */}
    {/* inject:css */}
    {/* endinject */}
    {/* Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/* End layout styles */}
    <link rel="shortcut icon" href="./images/startupconnect_icon.png" />


    {/*} Bootstrap CSS */}
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous" />
 

    <title>Startup Connect | Business Tools, Services, Networks, Hiring</title>
  </head>
  <body>


             
               <a aria-label="Chat on WhatsApp" href="https://wa.me/918480002413?text=I%20want%20to%20know%20more%20about%20the%20Startup%20Connect" style={{border:"none",position:"fixed",marginTop:"84vh",marginLeft:"15vw",zIndex:"4"}}>
                <img src="https://cdn-icons-png.flaticon.com/128/3536/3536445.png" id="home2-wtp-icon"></img></a>
                


     <div class="home2-container">


     {/* NAVBAR START */}
     {/* DEFAULT NAVBAR START */}
     <div class="home2-navbar" id="home2-navbar-default">
     <div style={{display:"flex",flexDirection:"row", justifyContent:"space-between"}}>
     <div>
     <ul style={{display:"flex"}}>
     <li><img src="/home1/weblogo1.png" id="home2-navbar-logo" ></img></li>
     <li style={{ fontFamily: "Open Sans, sans-serif", fontWeight: "bold"}}><span style={{color:"#5d17eb"}}>Startup </span>Connect</li>
     </ul>
     </div>
     <div>
        <ul style={{display:"flex",flexDirection:"row"}}>
        <li><a href="#home2-services-section" style={{textDecoration:"none",color:"#000000"}}>Services</a></li>
            <li><a href="#home2-tools-section" style={{textDecoration:"none",color:"#000000"}}>Tools</a></li>
            <li><a href="#home2-networking-section" style={{textDecoration:"none",color:"#000000"}}>Network</a></li>
            <li><a href="/about-us" style={{textDecoration:"none",color:"#000000"}}>About Us</a></li>
            <li><a href="https://www.startuphire.in" style={{textDecoration:"none",color:"#000000"}}>Hiring</a></li>
            <li><a href="/contest-home" style={{textDecoration:"none",color:"#000000"}}>Contests</a></li>
            <li><a href="/login" style={{textDecoration:"none",color:"#000000"}}><button id="home2-navbar-login">Login</button></a></li>
            <li><a href="/businesstools-admin-register" style={{textDecoration:"none",color:"#000000"}}><button id="home2-navbar-register">Register</button></a></li>
        </ul>
     </div>
     </div>
     </div>
     {/* DEFAULT NAVBAR END */}

    {/* DEFAULT NAVBAR START */}
   <div class="home2-navbar" id="home2-navbar-changed">
   <div style={{display:"flex",flexDirection:"row", justifyContent:"space-between"}}>
     <div>
    
     </div>
     <div>
       <img src="https://cdn-icons-png.flaticon.com/128/9109/9109758.png" style={{width:"3rem",height:"3rem"}} onClick={()=>{   const createForm = document.getElementById('home2-display-menu');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }} />
     </div>
     </div>

     <div id="home2-display-menu">
     <div style={{display:"flex",flexDirection:"row", justifyContent:"space-between"}}>
     <div>
     <ul style={{display:"flex"}}>
     <li><img src="/home1/weblogo1.png" id="home2-navbar-logo" ></img></li>
     <li style={{ fontFamily: "Open Sans, sans-serif", fontWeight: "bold"}}><span style={{color:"#5d17eb"}}>Startup </span>Connect</li>
     </ul>
     </div>
     <div>
        <ul>
        <li><a href="/" style={{textDecoration:"none",color:"#000000"}}>Services</a></li>
            <li><a href="/book-keeping" style={{textDecoration:"none",color:"#000000"}}>Tools</a></li>
            <li><a href="home2" style={{textDecoration:"none",color:"#000000"}}>Network</a></li>
            <li><a href="/about-us" style={{textDecoration:"none",color:"#000000"}}>About Us</a></li>
            <li><a href="https://www.startuphire.in" style={{textDecoration:"none",color:"#000000"}}>Hiring</a></li>
            <li><a href="/contest-home" style={{textDecoration:"none",color:"#000000"}}>Contests</a></li>
            <li><a href="/login" style={{textDecoration:"none",color:"#000000"}}><button id="home2-navbar-login">Login</button></a></li>
            <li><a href="/businesstools-admin-register" style={{textDecoration:"none",color:"#000000"}}><button id="home2-navbar-register">Register</button></a></li>

        </ul>
     </div>
     </div>
     </div>
     </div>
     {/* DEFAULT NAVBAR END */}



     {/* NAVBAR END */}

    {/* HEADER SECTION START */}
    <section class="sv-header-section">
    <div class="sv-header-container">
    <div class="sv-header-left" style={{padding:"1rem"}}>
        <h1>YOUR ONE STOP SOLUTION FOR ALL YOUR SERVICES NEEDS</h1>
        <p>best class services delivered by professionals in your budget. From business setup, compliantce to monitoring, marketing, technology, growth, strategy and fundraising. Find all the services you need under one roof.</p>
        <p>Get Started with Services.</p>
        <div class="home2-services-features-list">
                    <div class="home2-services-fcard">
                       <div class="home2-services-fcard-left">
                        <img src="https://cdn-icons-png.flaticon.com/128/1087/1087840.png" id="home2-services-fcard-icon" />
                       </div>
                       <div class="home2-services-fcard-right">
                       <h3>500+</h3>
                       <h5 style={{fontWeight:"bold"}}>Projects</h5>
                       </div> 
                    </div>
                

                    <div class="home2-services-fcard">
                       <div class="home2-services-fcard-left">
                        <img src="https://cdn-icons-png.flaticon.com/128/12735/12735475.png" id="home2-services-fcard-icon" />
                       </div>
                       <div class="home2-services-fcard-right">
                       <h3>25+</h3>
                       <h5 style={{fontWeight:"bold"}}>Cities</h5>
                       </div> 
                    </div>
             

                    <div class="home2-services-fcard">
                       <div class="home2-services-fcard-left">
                        <img src="https://cdn-icons-png.flaticon.com/128/2991/2991235.png" id="home2-services-fcard-icon" />
                       </div>
                       <div class="home2-services-fcard-right">
                       <h3>4 <img src="https://cdn-icons-png.flaticon.com/128/7656/7656139.png" style={{height:"2rem",width:"2rem"}} />+</h3>
                       <h5 style={{fontWeight:"bold"}}>Google Ratings</h5>
                       </div> 
                    </div>

                </div>

                <div class="sv-searchbar">
                    <input type="text" placeholder="search your service"></input>
                    <button>Search</button>
                </div>
                <a href="/home1/startupbrochure1.pdf" download="Startup Connect Services Brochure"><button id="home2-modules-section-btn" style={{marginLeft:"0"}}>Download Brochure</button></a>
    </div>
    <div class="sv-header-right">
    <img src="https://img.freepik.com/free-vector/online-consulting-concept-illustration_114360-9658.jpg" style={{width:"96%",height:"60%"}} />
    </div>
    </div>

    </section>
    {/* HEADER SECTION END */}

    {/* SERVICES SECTION START */}
    <div class="sv-services-section">
        <h2>OUR SERVICES</h2>
  
        <div class="container-fluid py-2">
        <div class="row flex-row flex-nowrap overflow-auto mt-5" style={{scrollbar:"hidden"}}>
        {/* SERVICE CARD 1 START */}
              <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/iso-certification-concept_23-2148696815.jpg" alt="" style={{height:"40vh"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-semibold"> BUSINESS REGISTRATIONS </h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0">
                      Want to setup new business and want to get started. Business Setup Services are here to assist you.</p>
                      
                    </div>
                    <button class="btn btn-primary mt-3" onClick={()=>{ window.location.replace("/client-dashboard-quotations"); }}>View Services</button>
                  </div>
                </div>
              </div>
              {/* SERVICE CARD 1 END */}

               {/* SERVICE CARD 2 START */}
               <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/startup-construction-development-3d-thin-line-art-style-design-concept-isometric-illustration_1284-61110.jpg" alt="" style={{height:"40vh"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-semibold"> STARTUP SERVICES</h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0">
                      manage and view your project quotations received from Startup Connect Associates.</p>
                      
                    </div>
                    <button class="btn btn-warning mt-3" onClick={()=>{ window.location.replace("/client-dashboard-quotations"); }}>View Services</button>
                  </div>
                </div>
              </div>
              {/* SERVICE CARD 2 END */}

              {/* SERVICE CARD 3 START */}
              <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/banking-industry-concept-illustration_114360-13934.jpg" alt="" style={{height:"40vh"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-semibold"> DOCUMENTATAION AND FUNDRAISING</h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0">
                      manage and view your project quotations received from Startup Connect Associates.</p>
                      
                    </div>
                    <button class="btn btn-danger mt-3" onClick={()=>{ window.location.replace("/client-dashboard-quotations"); }}>View Services</button>
                  </div>
                </div>
              </div>
              {/* SERVICE CARD 3 END */}
              {/* SERVICE CARD 4 START */}
              <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/law-firm-concept-illustration_114360-8606.jpg" alt="" style={{height:"40vh"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-semibold">LEGAL SERVICES </h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0">
                      manage and view your project quotations received from Startup Connect Associates.</p>
                      
                    </div>
                    <button class="btn btn-success mt-3" onClick={()=>{ window.location.replace("/client-dashboard-quotations"); }}>View Services</button>
                  </div>
                </div>
              </div>
              {/* SERVICE CARD 4 END */}
               {/* SERVICE CARD 5 START */}
               <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/female-multitasking-work_23-2148390868.jpg" alt="" style={{height:"40vh"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-semibold"> MARKETING SERVICES </h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0">
                      manage and view your project quotations received from Startup Connect Associates.</p>
                      
                    </div>
                    <button class="btn btn-primary mt-3" onClick={()=>{ window.location.replace("/client-dashboard-quotations"); }}>View Services</button>
                  </div>
                </div>
              </div>
              {/* SERVICE CARD 5 END */}
               {/* SERVICE CARD 6 START */}
               <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/hand-drawn-flat-design-api-illustration_23-2149365021.jpg" alt="" style={{height:"40vh"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-semibold"> WEBSITE DEVELOPMENT </h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0">
                      manage and view your project quotations received from Startup Connect Associates.</p>
                      
                    </div>
                    <button class="btn btn-warning mt-3" onClick={()=>{ window.location.replace("/client-dashboard-quotations"); }}>View Services</button>
                  </div>
                </div>
              </div>
              {/* SERVICE CARD 6 END */}
               {/* SERVICE CARD 7 START */}
               <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/ui-ux-designers-isometric-composition-with-small-people-creating-custom-design-web-site-3d-vector-illustration_1284-68939.jpg" alt="" style={{height:"40vh"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-semibold"> SOFTWARE DEVELOPMENT </h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0">
                      Get quality softwares developed in effective time.</p>
                      
                    </div>
                    <button class="btn btn-danger mt-3" onClick={()=>{ window.location.replace("/client-dashboard-quotations"); }}>View Services</button>
                  </div>
                </div>
              </div>
              {/* SERVICE CARD 7 END */}
               {/* SERVICE CARD 8 START */}
                <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/managers-looking-chart-monitor_1262-19283.jpg" alt="" style={{height:"40vh"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h5 class="font-weight-semibold"> COMPLIANCES </h5>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0">
                      manage all your regulatory and compliances are being reviewed adequately.</p>
                      
                    </div>
                    <button class="btn btn-primary mt-3" onClick={()=>{ window.location.replace("/client-dashboard-quotations"); }}>View Services</button>
                  </div>
                </div>
              </div>
              {/* SERVICE CARD 8 END */}

              </div>
              </div>
    </div>
    {/* SERVICES SECTION END */}
     
     {/* FEATURES SECTION START */}
     <div class="sv-features-section">
     <h2>FEATURES OF OUR SERVICES</h2>
              <div class="row mt-5">
             {/* FEATURE CARD 1 START */}
              <div class="col-sm-6 stretch-card grid-margin">
                <div class="card">
                <div class="d-flex justify-content-between">
                <img style={{width:"4rem",height:"4rem",marginLeft:"1rem",marginTop:"1rem"}} src="https://cdn-icons-png.flaticon.com/128/3135/3135679.png" />
                  <div class="card-body text-dark">        
                    <h5 class="font-weight-semibold" style={{textAlign:"left"}}> BUSINESS REGISTRATIONS </h5>
                    <div class=""><p class="mb-0" style={{textAlign:"left"}}>
                      Want to setup new business and want to get started. Business Setup FEATUREs are here to assist you.</p>
                    </div>
                   </div>
                   </div>
                </div>
                </div>
              {/* FEATURE CARD 1 END */}
              {/* FEATURE CARD 2 START */}
             <div class="col-sm-6 stretch-card grid-margin">
                <div class="card">
                <div class="d-flex justify-content-between">
                <img style={{width:"4rem",height:"4rem",marginLeft:"1rem",marginTop:"1rem"}} src="https://cdn-icons-png.flaticon.com/128/3135/3135679.png" />
                  <div class="card-body text-dark">        
                    <h5 class="font-weight-semibold" style={{textAlign:"left"}}> BUSINESS REGISTRATIONS </h5>
                    <div class=""><p class="mb-0" style={{textAlign:"left"}}>
                      Want to setup new business and want to get started. Business Setup FEATUREs are here to assist you.</p>
                    </div>
                   </div>
                   </div>
                </div>
                </div>
              {/* FEATURE CARD 2 END */}
                {/* FEATURE CARD 3 START */}
                <div class="col-sm-6 stretch-card grid-margin">
                <div class="card">
                <div class="d-flex justify-content-between">
                <img style={{width:"4rem",height:"4rem",marginLeft:"1rem",marginTop:"1rem"}} src="https://cdn-icons-png.flaticon.com/128/3135/3135679.png" />
                  <div class="card-body text-dark">        
                    <h5 class="font-weight-semibold" style={{textAlign:"left"}}> BUSINESS REGISTRATIONS </h5>
                    <div class=""><p class="mb-0" style={{textAlign:"left"}}>
                      Want to setup new business and want to get started. Business Setup FEATUREs are here to assist you.</p>
                    </div>
                   </div>
                   </div>
                </div>
                </div>
              {/* FEATURE CARD 3 END */}
                {/* FEATURE CARD 4 START */}
                <div class="col-sm-6 stretch-card grid-margin">
                <div class="card">
                <div class="d-flex justify-content-between">
                <img style={{width:"4rem",height:"4rem",marginLeft:"1rem",marginTop:"1rem"}} src="https://cdn-icons-png.flaticon.com/128/3135/3135679.png" />
                  <div class="card-body text-dark">        
                    <h5 class="font-weight-semibold" style={{textAlign:"left"}}> BUSINESS REGISTRATIONS </h5>
                    <div class=""><p class="mb-0" style={{textAlign:"left"}}>
                      Want to setup new business and want to get started. Business Setup FEATUREs are here to assist you.</p>
                    </div>
                   </div>
                   </div>
                </div>
                </div>
              {/* FEATURE CARD 4 END */}
              {/* FEATURE CARD 5 START */}
              <div class="col-sm-6 stretch-card grid-margin">
                <div class="card">
                <div class="d-flex justify-content-between">
                <img style={{width:"4rem",height:"4rem",marginLeft:"1rem",marginTop:"1rem"}} src="https://cdn-icons-png.flaticon.com/128/3135/3135679.png" />
                  <div class="card-body text-dark">        
                    <h5 class="font-weight-semibold" style={{textAlign:"left"}}> BUSINESS REGISTRATIONS </h5>
                    <div class=""><p class="mb-0" style={{textAlign:"left"}}>
                      Want to setup new business and want to get started. Business Setup FEATUREs are here to assist you.</p>
                    </div>
                   </div>
                   </div>
                </div>
                </div>
              {/* FEATURE CARD 5 END */}
               {/* FEATURE CARD 6 START */}
               <div class="col-sm-6 stretch-card grid-margin">
                <div class="card">
                <div class="d-flex justify-content-between">
                <img style={{width:"4rem",height:"4rem",marginLeft:"1rem",marginTop:"1rem"}} src="https://cdn-icons-png.flaticon.com/128/3135/3135679.png" />
                  <div class="card-body text-dark">        
                    <h5 class="font-weight-semibold" style={{textAlign:"left"}}> BUSINESS REGISTRATIONS </h5>
                    <div class=""><p class="mb-0" style={{textAlign:"left"}}>
                      Want to setup new business and want to get started. Business Setup FEATUREs are here to assist you.</p>
                    </div>
                   </div>
                   </div>
                </div>
                </div>
              {/* FEATURE CARD 6 END */}


              </div>
              </div>
              {/* FEATURES SECTION END */}

              {/* CLIENTS SECTION START */}
              <div class="sv-services-section">
        <h2>OUR CLIENTS</h2>
  
        <div class="card">

             <div class="row mt-5 d-flex justify-content-between" style={{padding:"1rem"}}>
             {/* CLIENTS CARD 1 START */}
              <div class="col-sm-2 stretch-card grid-margin">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="/images/clients/client-1.jpeg" alt=""  />
                </div>
              </div>
              {/* CLIENTS CARD 1 END */}
               {/* CLIENTS CARD 2 START */}
               <div class="col-sm-2 stretch-card grid-margin">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="/images/clients/client-2.png" alt="" />
                </div>
              </div>
              {/* CLIENTS CARD 2 END */}
               {/* CLIENTS CARD 3 START */}
               <div class="col-sm-2 stretch-card grid-margin">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="/images/clients/client-3.jpeg" alt="" />
                </div>
              </div>
              {/* CLIENTS CARD 3 END */}
               {/* CLIENTS CARD 4 START */}
               <div class="col-sm-2 stretch-card grid-margin">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="/images/clients/client-4.jpg" alt="" />
                </div>
              </div>
              {/* CLIENTS CARD 4 END */}
               {/* CLIENTS CARD 5 START */}
               <div class="col-sm-2 stretch-card grid-margin">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="/images/clients/client-5.jpg" alt="" />
                </div>
              </div>
              {/* CLIENTS CARD 5 END */}
                 {/* CLIENTS CARD 6 START 
                 <div class="col-sm-2 stretch-card grid-margin">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="/images/clients/client-1.jpeg" alt="" />
                </div>
              </div>
              {/* CLIENTS CARD 6 END */}
              </div>
              </div>

              </div>
              {/* CLIENTS SECTION END */}

           {/* PARTNERS SECTION START */}
          <div class="sv-services-section">
        <h2>OUR PARTNERS</h2>
  
        <div class="card">

             <div class="row mt-5 d-flex justify-content-between" style={{padding:"1rem"}}>
             {/* PARTNERS CARD 1 START */}
              <div class="col-sm-2 stretch-card grid-margin">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="/images/clients/client-1.jpeg" alt=""  />
                </div>
              </div>
              {/* PARTNERS CARD 1 END */}
               {/* PARTNERS CARD 2 START */}
               <div class="col-sm-2 stretch-card grid-margin">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="/images/clients/client-2.png" alt="" />
                </div>
              </div>
              {/* PARTNERS CARD 2 END */}
               {/* PARTNERS CARD 3 START */}
               <div class="col-sm-2 stretch-card grid-margin">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="/images/clients/client-3.jpeg" alt="" />
                </div>
              </div>
              {/* PARTNERS CARD 3 END */}
               {/* PARTNERS CARD 4 START */}
               <div class="col-sm-2 stretch-card grid-margin">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="/images/clients/client-4.jpg" alt="" />
                </div>
              </div>
              {/* PARTNERS CARD 4 END */}
               {/* PARTNERS CARD 5 START */}
               <div class="col-sm-2 stretch-card grid-margin">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="/images/clients/client-5.jpg" alt="" />
                </div>
              </div>
              {/* PARTNERS CARD 5 END */}
                 {/* PARTNERS CARD 6 START 
                 <div class="col-sm-2 stretch-card grid-margin">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="/images/clients/client-1.jpeg" alt="" />
                </div>
              </div>
              {/* PARTNERS CARD 6 END */}
              </div>
              </div>

              </div>
              {/* PARTNERS SECTION END */}

              
    {/* TESTIMONIALS SECTION START */}
    <div class="sv-testimonials-section">
        <h2>OUR TESTIMONIALS</h2>
  
        <div class="row mt-5">
        {/* TESTIMONIAL CARD 1 START */}
              <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-90 " src="https://cdn-icons-png.flaticon.com/128/2202/2202112.png" alt="" style={{height:"11vh"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h3 class="font-weight-bold"> RAHUL RAY </h3>
                    <p class="font-weight-semibold text-muted">CEO, STARIAL</p>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0">
                      “ We have used your service to made our company Private limited and yah I 100% satisfied with your service. ”</p>
                      
                    </div>
                    </div>
                </div>
              </div>
              {/* TESTIMONIAL CARD 1 END */}
                 {/* TESTIMONIAL CARD 2 START */}
                 <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-90 " src="https://cdn-icons-png.flaticon.com/128/2202/2202112.png" alt="" style={{height:"11vh"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h3 class="font-weight-bold"> RAHUL RAY </h3>
                    <p class="font-weight-semibold text-muted">CEO, STARIAL</p>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0">
                      “ We have used your service to made our company Private limited and yah I 100% satisfied with your service. ”</p>
                      
                    </div>
                    </div>
                </div>
              </div>
              {/* TESTIMONIAL CARD 2 END */}
                 {/* TESTIMONIAL CARD 3 START */}
                 <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-90 " src="https://cdn-icons-png.flaticon.com/128/2202/2202112.png" alt="" style={{height:"11vh"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h3 class="font-weight-bold"> RAHUL RAY </h3>
                    <p class="font-weight-semibold text-muted">CEO, STARIAL</p>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0">
                      “ We have used your service to made our company Private limited and yah I 100% satisfied with your service. ”</p>
                      
                    </div>
                    </div>
                </div>
              </div>
              {/* TESTIMONIAL CARD 3 END */}
                 {/* TESTIMONIAL CARD 4 START */}
                 <div class="col-sm-3 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-90 " src="https://cdn-icons-png.flaticon.com/128/2202/2202112.png" alt="" style={{height:"11vh"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                 
                    <h3 class="font-weight-bold"> RAHUL RAY </h3>
                    <p class="font-weight-semibold text-muted">CEO, STARIAL</p>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0">
                      “ We have used your service to made our company Private limited and yah I 100% satisfied with your service. ”</p>
                      
                    </div>
                    </div>
                </div>
              </div>
              {/* TESTIMONIAL CARD 4 END */}

              </div>

              </div>
            {/* TESTIMONIALS SECTION END */}


       <Footer />
      
     </div>

      {/*} Optional JavaScript; choose one of the two! */}

    {/*} Option 1: Bootstrap Bundle with Popper */}
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>

    {/*} Option 2: Separate Popper and Bootstrap JS */}
    {/*}
    <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js" integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p" crossorigin="anonymous"></script>
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.min.js" integrity="sha384-cVKIPhGWiC2Al4u+LWgxfKTRIcfu0JTxR+EQDz/bgldoEyl4H0zUF0QKbrJ0EcQF" crossorigin="anonymous"></script>
    */}

    

    {/* plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/* endinject */}
    {/* Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/* End plugin js for this page */}
    {/* inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/* endinject */}
    {/* Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/* End custom js for this page */}

  </body>
</html>
</>
      );

}

export default Serviceshome1;