import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios';
import Navbar from '../Extracomponents/navbar.js';
import Sidebar from '../Extracomponents/sidebar.js';





/*import Amanbg from './amanbg';*/


function Customersdashboard(){

  
   // Setting Values fom Session Storage
 const logstatus=sessionStorage.getItem("loginstatus00");//loginstatus
 const loguser=sessionStorage.getItem("username00");//username
 const logtype=sessionStorage.getItem("logintype00");//logintype
 const logname=sessionStorage.getItem("loginname00");//loginname
 const logadminid=sessionStorage.getItem("loginadminid00");//loginadminid



     /*     TEAM LIST       */
     
   const[data,setData] = useState([]);//SetData Variable
    //Loading count
    const[loadcount,setloadcount] = useState(0);
    //Button Press
    const[buttonpress,setbuttonpress] = useState("No");
    //Error Variable
    const[errorvar,seterrorvar] = useState();
    //Data Count
    const[datacount,setdatacount] = useState();
    //User Count
    const[usercount,setusercount] = useState();
    //Filter Column
    const[filter,setfilter] = useState("All");const updatefilter = (event) => { setfilter(event.target.value);};
    //Filter Value
    const[filterval,setfilterval] = useState();const updatefilterval = (event) => { setfilterval(event.target.value);};

   //Sessions Not Set
 {/*if(((logstatus=="false")||(logstatus==null))&&(loguser==null)&&(logadminid==null)){
 
  sessionStorage.removeItem("username00");
  sessionStorage.removeItem("loginstatus00");
  sessionStorage.removeItem("logintype00");
  sessionStorage.removeItem("loginname00");
  sessionStorage.removeItem("loginadminid00");
 
  alert("Session Expired! Login Again.");
  window.location.replace("/");

}
//Sessions Set
else{
*/}

  //Use Effect, Data Load
  useEffect(()=>{

    getData();

},[])

function getData(){
  

   //Checking if user loggedin
 if(((logstatus=="true")||(logstatus!=null))&&(loguser!=null)&&(logadminid!=null)){


  
  axios.post("adminlist", {
    Loadcount:loadcount,
    User: loguser,
    Logtype:logtype,
    Logstatus:logstatus,
    Log_id: logadminid,
    Button_val: buttonpress,
    filter: filter,
   filterVal: filterval
  })
  .then((response) => {
   //Success Data Fetched
   if(response.data.successvar==1){
    //Setting Data
    setData(response.data.data);
    setdatacount(response.data.datacount);
    setloadcount(response.data.loadcount);
    setloadcount(1);
   }
   //Cannot Fetch Data
   else{
    seterrorvar("Cannot Fetch Data !");
   }
  

  });

  }
//User not Logedin
else{

  sessionStorage.removeItem("username00");
  sessionStorage.removeItem("loginstatus00");
  sessionStorage.removeItem("logintype00");
  sessionStorage.removeItem("loginname00");
  sessionStorage.removeItem("loginadminid00");
 
  alert("Session Expired! Login Again.");
  window.location.replace("/");

   }

}

//Filter Function
function getFunction(){
  getData();
}



    return(
<>
{/*<!DOCTYPE html> */}
<html lang="en">
  <head>
    {/* Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>theStartupConnect | Portal</title>
    {/* plugins:css */}
    <link rel="stylesheet" href="./assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="./assets/vendors/css/vendor.bundle.base.css" />
    {/* endinject */}
    {/* Plugin css for this page */}
    {/* End plugin css for this page */}
    {/* inject:css */}
    {/* endinject */}
    {/* Layout styles */}
    <link rel="stylesheet" href="./assets/css/style.css" />
    {/* End layout styles */}
   {/*} <link rel="shortcut icon" href="./assets/images/favicon.ico" /> */}
  </head>
  <body>

<Navbar/>

{/* MAIN PAGE COMPONENT START */}
  <div class="container-fluid page-body-wrapper">

 <Sidebar/>


{/* MAIN PANEL START */}
<div class="main-panel" style={{marginTop:"5vh"}}>
<div class="content-wrapper">



<h2 class="text-center mb-2">Manage Customers</h2>
       
{/* Top Analytics Start */}

<div class="row">
              <div class="col-md-4 stretch-card grid-margin">
                <div class="card bg-gradient-danger card-img-holder text-white">
                  <div class="card-body">
                    <img src="assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
                    <h4 class="font-weight-normal mb-3">Total Registrations <i class="mdi mdi-chart-line mdi-24px float-right"></i>
                    </h4>
                    <h2 >$ 15,0000</h2>
                    <h6 class="card-text mt-0">This week (+60%)</h6>
                    <h2 class="mt-2">$ 15,0000</h2>
                    <h6 class="card-text mt-0">Last 24 Hours (+60%)</h6>
                    <h2 class="mt-2">$ 15,0000</h2>
                    <h6 class="card-text mt-0">Last Hour (+60%)</h6>
                  </div>
                </div>
              </div>
              <div class="col-md-4 stretch-card grid-margin">
                <div class="card bg-gradient-info card-img-holder text-white">
                  <div class="card-body">
                    <img src="assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
                    <h4 class="font-weight-normal mb-3">Total Contacts <i class="mdi mdi-bookmark-outline mdi-24px float-right"></i>
                    </h4>
                    <h2 >$ 15,0000</h2>
                    <h6 class="card-text mt-0">This week (+60%)</h6>
                    <h2 class="mt-2">$ 15,0000</h2>
                    <h6 class="card-text mt-0">Last 24 Hours (+60%)</h6>
                    <h2 class="mt-2">$ 15,0000</h2>
                    <h6 class="card-text mt-0">Last Hour (+60%)</h6>
                  </div>
                </div>
              </div>
              <div class="col-md-4 stretch-card grid-margin">
                <div class="card bg-gradient-success card-img-holder text-white">
                  <div class="card-body">
                    <img src="assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
                    <h4 class="font-weight-normal mb-3">Total Logins <i class="mdi mdi-diamond mdi-24px float-right"></i>
                    </h4>
                    <h2 >$ 15,0000</h2>
                    <h6 class="card-text mt-0">This week (+60%)</h6>
                    <h2 class="mt-2">$ 15,0000</h2>
                    <h6 class="card-text mt-0">Last 24 Hours (+60%)</h6>
                    <h2 class="mt-2">$ 15,0000</h2>
                    <h6 class="card-text mt-0">Last Hour (+60%)</h6>
                  </div>
                </div>
              </div>
            </div>
            
{/* Top Analytics End */}
       
       {/*  MANAGE ADMIN SECTION START */}
     
         <div class="row">
         {/* FILTER SECTION START */}
        <div class="d-flex justify-content-end mb-4"><button style={{backgroundColor:"#1ea6ae",border:"none",boxShadow:"1px 1px 1px 1px #bec3c3",borderRadius:"5%"}}><i class="mdi mdi-filter-outline mb-3" style={{color:"#ffffff",fontSize:"1.6rem"}}
        onClick={()=>{ document.getElementById("filter-form").style.display="block"}}><span style={{fontSize:"1.2rem"}}>Filters</span></i></button></div>
        <div id="filter-form" style={{display:"none"}}>
        <div class="col-12">
                <div class="card">
                <div class="card-body">
                <div><button class="float-end" style={{padding:"1rem",backgroundColor:"transparent",border:"none"}} onClick={()=>{ document.getElementById("filter-form").style.display="none";}}><i class="mdi mdi-close-box" style={{color:"#f04f60",fontSize:"2rem"}}></i></button></div>
                    <h4 class="card-title"></h4>
                    <div class="form-sample">
                      <p class="card-description">Apply Filters</p>
                      <div class="row">
                      <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Select Column</label>
                            <div class="col-sm-9">
                              <select class="form-control" style={{border:"0.1rem solid #6d7576"}} onChange={updatefilter}>
                              <option value={filter}>{filter}</option>
                                <option value="AdminId">Admin Id</option>
                                <option value="Name">Name</option>
                                <option value="Username">Username</option>
                                <option value="Email">Email</option>
                                <option value="Contact">Contact</option>
                                <option value="Status">Status</option>
                                <option value="All">All</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Enter Value" style={{border:"0.1rem solid #6d7576"}} onChange={updatefilterval} value={filterval} />
                            </div>
                            <div class="col-sm-3">
                            <button type="submit" class="btn btn-gradient-info mb-2" onClick={getFunction}>Apply</button>
                            </div>
                          </div>
                        </div>     
                        <hr style={{color:"#7b8889"}}/>              
                      </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>
        {/* FILTER SECTION END */}
              <div class="col-12 grid-margin">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">List of Admins</h4>
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th>Image</th>
                            <th> Admin Id </th>
                            <th> Name </th>
                            <th> Username </th>
                            <th> Email </th>
                            <th> Contact </th>
                            <th> Status </th>
                            <th> Edit </th>
                            <th> Delete </th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                       data.map((curElm,ind)=>{

return(
  <>
                          <tr key={curElm.Admin_id}>
                            <td>
                              <img src="assets/images/faces/face1.jpg" class="me-2" alt="image" />
                            </td>
                            <td>{curElm.Admin_id}</td>
                            <td>{curElm.Name}</td>
                            <td>{curElm.Username}</td>
                            <td>{curElm.Email}</td>
                            <td>{curElm.Contact}</td>
                            <td>
                              <label class="badge badge-gradient-success">{curElm.Status}</label>
                            </td>
                      
                          </tr>
</>
      )
      }
					   
			)}
                          
                       
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      {/* MANAGE ADIN SECTION END */}
         
        {/*} <Topchart/> */}
{/*<Pageheader/>
<Topanalytics/>*/}
    
</div>
</div>
{/* MAIN PANEL END */}

</div>
{/* MAIN PAGE COMPONENT END */}

    {/* container-scroller */}
    {/* plugins:js */}
    <script src="./assets/vendors/js/vendor.bundle.base.js"></script>
    {/* endinject */}
    {/* Plugin js for this page */}
    {/* End plugin js for this page */}
    {/* inject:js */}
    <script src="./assets/js/off-canvas.js"></script>
    <script src="./assets/js/hoverable-collapse.js"></script>
    <script src="./assets/js/misc.js"></script>

    {/*} plugins:js */}
    <script src="assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="assets/vendors/chart.js/Chart.min.js"></script>
    <script src="assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="assets/js/off-canvas.js"></script>
    <script src="assets/js/hoverable-collapse.js"></script>
    <script src="assets/js/misc.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="assets/js/dashboard.js"></script>
    <script src="assets/js/todolist.js"></script>
    {/*} End custom js for this page */}
    {/* endinject */}
  </body>
</html>
</>
    );
}
//}

export default Customersdashboard;