import React from 'react';
import {useState,useEffect,Component} from 'react';
import axios from './axios';
import moment from 'moment/moment';

function Contactform1(){

  //saving default values with usestate
  const state = useState();

  
  //name
  const[cname,setcname]=useState();const updatecname = (event) => { setcname(event.target.value);};
  //email
  const[email,setemail]=useState();const updateemail = (event) => { setemail(event.target.value);};
  //contact
  const[contact,setcontact]=useState();const updatecontact = (event) => { setcontact(event.target.value);};
  //cstate
  const[cstate,setcstate]=useState("Select State");const updatecstate = (event) => { setcstate(event.target.value);};
  //date
  const[date,setdate]=useState();const updatedate = (event) => { setdate(event.target.value);};
  //time
  const[time,settime]=useState();const updatetime = (event) => { settime(event.target.value);};
  //query
  const[query,setquery]=useState();const updatequery = (event) => { setquery(event.target.value);};


  //ERROR VARIABLES 
  const[error1,seterror1]=useState();
  const[error2,seterror2]=useState();
  const[error3,seterror3]=useState();
  const[error4,seterror4]=useState();
  const[error5,seterror5]=useState();
  const[error6,seterror6]=useState();
  const[error7,seterror7]=useState();

  
    //name
    const[dname,setdname]=useState();const updatedname = (event) => { setdname(event.target.value);};
  
  function callfunction(){
 /*
 
    axios.get("https://thestartupnetwork.000webhostapp.com/api/clients", {
      Name: cname,
      Contact:contact,
      Email:email,


    })
    .then((response) => {
    console.log(response);
  }    );
 */
}
  
      return(
  <>

{/* CONTACT FORM START  */}
{/*<div className="contact-form" >
<h5>Get Free Consultancy by Expert.</h5>
<div id="contact-form-ln"></div>
{/*<input type="text" className="form-input" id="name" placeholder="Enter your Name" value={cname} onChange={updatecname} /> */}
{/*<input type="text" className="form-input" id="name" value={cname} onChange={updatecname} placeholder="Enter your Name" /><br/>
<small style={{fontSize:".8rem",color:"#f16083"}}>{error1}</small>
<input type="text" className="form-input" id="email" value={email} onChange={updateemail} placeholder="Enter your Email" /><br/>
<small style={{fontSize:".8rem",color:"#f16083"}}>{error2}</small>
<input type="text" className="form-input" id="contact" value={contact} onChange={updatecontact} placeholder="Enter your Contact" /><br/>
<small style={{fontSize:".8rem",color:"#f16083"}}>{error3}</small>
{/*<input type="text" className="form-input" id="cstate" value={cstate} onChange={updatecstate} placeholder="Enter your State" /><br/>*/}
{/*<select name="state" className="form-input" id="state" onChange={updatecstate} >
<option value={cstate}>{cstate}</option>
  <option value="Andhra Pradesh">Andhra Pradesh</option>
  <option value="Arunachal Pradesh">Arunachal Pradesh</option>
  <option value="Assam">Assam</option>
  <option value="Bihar">Bihar</option>
  <option value="Chhattisgarh">Chhattisgarh</option>
  <option value="Goa">Goa</option>
  <option value="Gujarat">Gujarat</option>
  <option value="Haryana">Haryana</option>
  <option value="Himachal Pradesh">Himachal Pradesh</option>
  <option value="Jharkhand">Jharkhand</option>
  <option value="Karnataka">Karnataka</option>
  <option value="Kerala">Kerala</option>
  <option value="Madhya Pradesh">Madhya Pradesh</option>
  <option value="Maharashtra">Maharashtra</option>
  <option value="Manipur">Manipur</option>
  <option value="Meghalaya">Meghalaya</option>
  <option value="Mizoram">Mizoram</option>
  <option value="Nagaland">Nagaland</option>
  <option value="Odisha">Odisha</option>
  <option value="Punjab">Punjab</option>
  <option value="Rajasthan">Rajasthan</option>
  <option value="Sikkim">Sikkim</option>
  <option value="Tamil Nadu">Tamil Nadu</option>
  <option value="Telangana">Telangana</option>
  <option value="Tripura">Tripura</option>
  <option value="Uttar Pradesh">Uttar Pradesh</option>
  <option value="Uttarakhand">Uttarakhand</option>
  <option value="West Bengal">West Bengal</option>
  <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
  <option value="Chandigarh">Chandigarh</option>
  <option value="Dadra and Nagar Haveli and Daman and Diu">Dadra and Nagar Haveli and Daman and Diu</option>
  <option value="Delhi">Delhi</option>
  <option value="Lakshadweep">Lakshadweep</option>
  <option value="Puducherry">Puducherry</option>
</select>
<small style={{fontSize:".8rem",color:"#f16083"}}>{error4}</small>

<button id="contact-submit" onClick={()=>{/*callfunction()*/
   
  /* axios.post("https://thestartupnetwork.000webhostapp.com/api/clients", {*/
   // axios.post("http://127.0.0.1:8000/api/clients",{ 
  /*   axios.post("clients", { 
      Name:cname,
      Contact:contact,
      Email:email,
      State:cstate
    
})

    .then((response) => {

      seterror1();seterror2();seterror3();seterror4();seterror5();

    if(response.data.validatorerror){
      seterror1(response.data.validatorerror.Name);
      seterror2(response.data.validatorerror.Email);
      seterror3(response.data.validatorerror.Contact);
      seterror4(response.data.validatorerror.State);
    }
    if(response.data.successvar==2){
      seterror1(response.data.name_er);
      seterror2(response.data.email_er);
      seterror3(response.data.contact_er);
      seterror4(response.data.state_er);
    }
    if(response.data.successvar==1){
    document.getElementById("contact-alert").style.display="block";
    setcname("");setcstate("");setcontact("");setemail("");
    }
    if(response.data.successvar==0){

    }
  }    );


}
}>GET CONSULTANCY</button>

</div>
{/* CONTACT FORM END */}













  {/* CONTACT FORM EXTRA START */}
  <div class="top-form" style={{backgroundColor:"#ffffff",border:"0.2rem solid #d1e0e0",boxShadow:"1px 2px 2px 1px #f0f5f5",borderRadius:"10px",width:"70vw",marginTop:"1.5rem",position:"fixed",marginLeft:"15vw",zIndex:"3",overflow:"scroll"}}>
  <div style={{padding:"3rem"}}>
  <div>
    <button class="float-end" onClick={()=>{document.getElementById("ext-ct-form").style.display="none"; }} style={{backgroundColor:"transparent",border:"none"}}><img src="https://cdn-icons-png.flaticon.com/128/5974/5974771.png" style={{width:"2rem",height:"2rem"}}></img></button>
  </div>
  <h3>Contact us for Free Consultancy!</h3>
  <div class="form-group"  style={{textAlign:"left",marginTop:"1rem"}}>
    <label>Name</label>
    <input type="text" class="form-control" placeholder="John Dere" id="name" value={cname} onChange={updatecname} />
    <small style={{fontSize:".8rem",color:"#f16083"}}>{error1}</small>
  </div>
  <div class="form-group"  style={{textAlign:"left",marginTop:"1rem"}}>
    <label>Email</label>
    <input type="email" class="form-control" placeholder="name@example.com"  id="email" value={email} onChange={updateemail} />
    <small style={{fontSize:".8rem",color:"#f16083"}}>{error2}</small>
  </div>
  <div class="form-group"  style={{textAlign:"left",marginTop:"1rem"}}>
    <label>Contact</label>
    <input type="text" class="form-control" placeholder="910000000000" id="contact" value={contact} onChange={updatecontact} />
    <small style={{fontSize:".8rem",color:"#f16083"}}>{error3}</small>
  </div>
  <div class="form-group"  style={{textAlign:"left",marginTop:"1rem"}}>
    <label>Select Preferred Date</label>
    <input type="date" class="form-control"  placeholder="name@example.com" id="date" value={date} onChange={updatedate} />
    <small style={{fontSize:".8rem",color:"#f16083"}}>{error5}</small>
  </div>
  <div class="form-group"  style={{textAlign:"left",marginTop:"1rem"}}>
    <label>Select Preferred Time</label>
    <input type="time" class="form-control"  placeholder="910000000000" id="time" value={time} onChange={updatetime} />
    <small style={{fontSize:".8rem",color:"#f16083"}}>{error6}</small>
  </div>
  <div class="form-group"  style={{textAlign:"left",marginTop:"1rem"}}>
    <label>State</label>
    <select name="state" className="form-control" id="state" onChange={updatecstate} >
<option value={cstate}>{cstate}</option>
  <option value="Andhra Pradesh">Andhra Pradesh</option>
  <option value="Arunachal Pradesh">Arunachal Pradesh</option>
  <option value="Assam">Assam</option>
  <option value="Bihar">Bihar</option>
  <option value="Chhattisgarh">Chhattisgarh</option>
  <option value="Goa">Goa</option>
  <option value="Gujarat">Gujarat</option>
  <option value="Haryana">Haryana</option>
  <option value="Himachal Pradesh">Himachal Pradesh</option>
  <option value="Jharkhand">Jharkhand</option>
  <option value="Karnataka">Karnataka</option>
  <option value="Kerala">Kerala</option>
  <option value="Madhya Pradesh">Madhya Pradesh</option>
  <option value="Maharashtra">Maharashtra</option>
  <option value="Manipur">Manipur</option>
  <option value="Meghalaya">Meghalaya</option>
  <option value="Mizoram">Mizoram</option>
  <option value="Nagaland">Nagaland</option>
  <option value="Odisha">Odisha</option>
  <option value="Punjab">Punjab</option>
  <option value="Rajasthan">Rajasthan</option>
  <option value="Sikkim">Sikkim</option>
  <option value="Tamil Nadu">Tamil Nadu</option>
  <option value="Telangana">Telangana</option>
  <option value="Tripura">Tripura</option>
  <option value="Uttar Pradesh">Uttar Pradesh</option>
  <option value="Uttarakhand">Uttarakhand</option>
  <option value="West Bengal">West Bengal</option>
  <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
  <option value="Chandigarh">Chandigarh</option>
  <option value="Dadra and Nagar Haveli and Daman and Diu">Dadra and Nagar Haveli and Daman and Diu</option>
  <option value="Delhi">Delhi</option>
  <option value="Lakshadweep">Lakshadweep</option>
  <option value="Puducherry">Puducherry</option>
</select>
<small style={{fontSize:".8rem",color:"#f16083"}}>{error4}</small>
  </div>
  <div class="form-group"  style={{textAlign:"left",marginTop:"1rem"}}>
    <label>Write your Question here.</label>
    <textarea class="form-control" rows="2" id="query" value={query} onChange={updatequery}></textarea>
    <small style={{fontSize:".8rem",color:"#f16083"}}>{error7}</small>
  </div>
  
<button id="contact-submit" onClick={()=>{/*callfunction()*/
   
   /* axios.post("https://thestartupnetwork.000webhostapp.com/api/clients",*/
    // axios.post("http://127.0.0.1:8000/api/clients",{ 
     axios.post("clients", { 
       Name:cname,
       Contact:contact,
       Email:email,
       State:cstate,
       Date:moment(date).format('MM-DD-YYYY'),
       Time:time,
       Query:query
     
 })
 
     .then((response) => {
 
       seterror1();seterror2();seterror3();seterror4();seterror5();seterror6();seterror7();
 
     if(response.data.validatorerror){
       seterror1(response.data.validatorerror.Name);
       seterror2(response.data.validatorerror.Email);
       seterror3(response.data.validatorerror.Contact);
       seterror4(response.data.validatorerror.State);
     }
     if(response.data.successvar==2){
       seterror1(response.data.name_er);
       seterror2(response.data.email_er);
       seterror3(response.data.contact_er);
       seterror4(response.data.state_er);
       seterror5(response.data.date_er);
       seterror6(response.data.time_er);
       seterror7(response.data.query_er);
     }
     if(response.data.successvar==1){
     document.getElementById("contact-alert").style.display="block";
     document.getElementById("ext-ct-form").style.display="none";
     setcname("");setcstate("");setcontact("");setemail("");
     }
     if(response.data.successvar==0){
      document.getElementById("contact-alert").style.display="none";
      alert("Cannot Save Data. Try gain!");
     }
   }    );
 
 
 }
 }>GET CONSULTANCY</button>
</div>
  </div>
  {/* CONTACT FORM EXTRA END*/}
  </>
      );

}

export default Contactform1;