import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import moment from 'moment/moment';


//import '../Resources/dashboard.css';

import Navbar from './navbar';
import Sidebar from './sidebar';

function Superadmindashboard(){


  

return(
    <>
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>SUPER ADMIN DASHBOARD</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">


     
 {/*}    <Sidebar/>  */}
    <div className="container-fluid page-body-wrapper">
    <Navbar />

        {/*} partial */}
        <div className="main-panel">
          
        
     
          <div class="content-wrapper pb-0">
            <div class="page-header flex-wrap">
              <div class="header-left">
            {/*}    <button class="btn btn-primary mb-2 mb-md-0 me-2"> Create new document </button>
                <button class="btn btn-outline-primary bg-white mb-2 mb-md-0"> Import documents </button> */}
              </div>
              <div class="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div class="d-flex align-items-center">
                  <a href="#">
                    <p class="m-0 pe-3">Super Admin Dashboard</p>
                  </a>
                  <a class="ps-3 me-4" href="#">
                    <p class="m-0">ADE-00234</p>
                  </a>
                </div>
                <button type="button" class="btn btn-primary mt-2 mt-sm-0 btn-icon-text" onClick={()=>{
                      const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; 
                }}>
                  <i class="mdi mdi-plus-circle"></i> Add New Admin </button>
              </div>
              </div>

            {/* ADD ADMIN START */}
             <div class="row">
              <div class="col-12 grid-margin create-form" id="create-form" style={{display:"none"}}>
              <div class="card">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ 
                         //close edit form button
                         document.getElementById("create-form").style.display="none";
                        //Setting Variables to empty
                        //Setting Variables to empty 
                    /*    setedate_er(" ");setetype_er(" ");seteparticular_er(" ");seteamount_er(" ");seterror_msg(" ");   
                        setedate(" ");setetype(" ");seteparticular(" ");seteamount(" ");                  
                        sessionStorage.removeItem("Bankid00");
                        sessionStorage.removeItem("Trno00");   */   }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                    <div class="card-body">
                    <h4 class="card-title">ADD ADMINS</h4>
                    <div class="form-sample">
                      <p class="card-description">add new admin</p>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Name<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" placeholder="Admin Name" class="form-control" id="" />
                              <code></code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Username<code>*</code></label>
                            <div class="col-sm-9">
                            <input type="text" placeholder="Username" class="form-control" id="" />
                              <code></code>
                            </div>
                          </div>
                          </div>
                          </div>
                          <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Contact<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" placeholder="Contact" class="form-control" id="" />
                              <code></code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Email<code>*</code></label>
                            <div class="col-sm-9">
                            <input type="text" placeholder="Email" class="form-control" id="" />
                              <code></code>
                            </div>
                          </div>
                          </div>
                          </div>
                          <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">City<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" placeholder="City" class="form-control" id="" />
                              <code></code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">State<code>*</code></label>
                            <div class="col-sm-9">
                            <input type="text" placeholder="State" class="form-control" id="" />
                              <code></code>
                            </div>
                          </div>
                          </div>
                          </div>
                          <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Country<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" placeholder="Country" class="form-control" id="" />
                              <code></code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Pincode<code>*</code></label>
                            <div class="col-sm-9">
                            <input type="text" placeholder="Pincode" class="form-control" id="" />
                              <code></code>
                            </div>
                          </div>
                          </div>
                          </div>
                          <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Password<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" placeholder="Password" class="form-control" id="" />
                              <code></code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Confirm Password<code>*</code></label>
                            <div class="col-sm-9">
                            <input type="text" placeholder="Confirm Password" class="form-control" id="" />
                              <code></code>
                            </div>
                          </div>
                          </div>
                          </div>

                          <button class="btn btn-lg btn-primary">Add Admin</button>
                        </div>
                    </div>
                    </div>
                  


                  </div>
                {/* ADD ADMIN END */}


                {/* EDIT ADMIN START */}
              <div class="row">
              <div class="col-12 grid-margin edit-form" id="edit-form" style={{display:"none"}}>
              <div class="card">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ 
                         //close edit form button
                         document.getElementById("edit-form").style.display="none";
                        //Setting Variables to empty
                        //Setting Variables to empty 
                    /*    setedate_er(" ");setetype_er(" ");seteparticular_er(" ");seteamount_er(" ");seterror_msg(" ");   
                        setedate(" ");setetype(" ");seteparticular(" ");seteamount(" ");                  
                        sessionStorage.removeItem("Bankid00");
                        sessionStorage.removeItem("Trno00");   */   }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                    <div class="card-body">
                    <h4 class="card-title">EDIT ADMINS</h4>
                    <div class="form-sample">
                      <p class="card-description">edit admins</p>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Name<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" placeholder="Admin Name" class="form-control" id="" />
                              <code></code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Username<code>*</code></label>
                            <div class="col-sm-9">
                            <input type="text" placeholder="Username" class="form-control" id="" />
                              <code></code>
                            </div>
                          </div>
                          </div>
                          </div>
                          <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Contact<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" placeholder="Contact" class="form-control" id="" />
                              <code></code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Email<code>*</code></label>
                            <div class="col-sm-9">
                            <input type="text" placeholder="Email" class="form-control" id="" />
                              <code></code>
                            </div>
                          </div>
                          </div>
                          </div>
                          <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">City<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" placeholder="City" class="form-control" id="" />
                              <code></code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">State<code>*</code></label>
                            <div class="col-sm-9">
                            <input type="text" placeholder="State" class="form-control" id="" />
                              <code></code>
                            </div>
                          </div>
                          </div>
                          </div>
                          <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Country<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" placeholder="Country" class="form-control" id="" />
                              <code></code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Pincode<code>*</code></label>
                            <div class="col-sm-9">
                            <input type="text" placeholder="Pincode" class="form-control" id="" />
                              <code></code>
                            </div>
                          </div>
                          </div>
                          </div>
                          <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Password<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" placeholder="Password" class="form-control" id="" />
                              <code></code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Confirm Password<code>*</code></label>
                            <div class="col-sm-9">
                            <input type="text" placeholder="Confirm Password" class="form-control" id="" />
                              <code></code>
                            </div>
                          </div>
                          </div>
                          </div>
                          <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">WhatsApp<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" placeholder="Password" class="form-control" id="" />
                              <code></code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Status<code>*</code></label>
                            <div class="col-sm-9">
                            <select class="form-control" style={{padding:"1rem"}}>
                            <option value="active">active</option>
                            <option value="active">inactive</option>
                            </select>
                              <code></code>
                            </div>
                          </div>
                          </div>
                          </div>

                          <button class="btn btn-lg btn-primary">Add Admin</button>
                        </div>
                    </div>
                    </div>
                  


                  </div>
                  </div>
                {/* EDIT ADMIN END */}


                {/* ADMIN TABLE START */}
                <div class="col-lg-12 grid-margin stretch-card">

               


                <div class="card">

                <div class="template-demo d-flex justify-content-between mt-3">
                        <div></div>

                        {/* COLUMNS MANAGEMENT DROPDOWN START */}
                          <div class="dropdown">
                          <button class="btn btn-warning" onClick={()=>{ document.getElementById("filter-top-card").style.display="block"; }}><i class="mdi mdi-filter-outline"></i></button>
                            <button class="btn btn-primary dropdown-toggle" style={{marginLeft:"1rem"}} type="button" id="dropdownMenuIconButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="mdi mdi-table-large"></i>
                            </button>
                         {/*}   <div class="dropdown-menu" aria-labelledby="dropdownMenuIconButton1">
                              <h6 class="dropdown-header">Columns</h6>
                              <hr/>

                              </div>*/}
                              </div>
                               </div>

                <div class="card-body">
                    <h4 class="card-title">Admins</h4>
                    <p class="card-description">admin details
                    </p>
                      <div class="table-responsive">
                      <table class="table table-hover">
                        <thead>
                          <tr >
                            <th  style={{fontWeight:"bold"}}>Admin Id</th>
                            <th  style={{fontWeight:"bold"}}>Admin Name</th>
                            <th  style={{fontWeight:"bold"}}>Username</th>
                            <th  style={{fontWeight:"bold"}}>Contact</th>
                            <th  style={{fontWeight:"bold"}}>Email</th>
                            <th  style={{fontWeight:"bold"}}>City</th>
                            <th  style={{fontWeight:"bold"}}>State</th>
                            <th  style={{fontWeight:"bold"}}>Country</th>
                            <th  style={{fontWeight:"bold"}}>Pincode</th>
                            <th  style={{fontWeight:"bold"}}>Status</th>
                            <th  style={{fontWeight:"bold"}}>Created At</th>
                            <th  style={{fontWeight:"bold"}}>Updated At</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>12345</td>
                            <td>Siddharth Nayak</td>
                            <td>Siddharth1</td>
                            <td>918338383838</td>
                            <td>siddharthnayak@gmail.com</td>
                            <td>Jabalpur</td>
                            <td>Madhya Pradesh</td>
                            <td>India</td>
                            <td>482003</td>
                            <td><label class="badge badge-success">active</label></td>
                            <td>2024-01-08 <br/> <span class="mt-2">(15:13pm)</span></td>
                            <td>2024-01-08 <br/>  <span class="mt-2">(15:13pm)</span></td>
                            <td><i class="mdi mdi-pencil-box text-warning" style={{fontSize:"1.4rem"}} onClick={()=>{
                                 document.getElementById("edit-form").style.display="block";
                            }} ></i></td>
                        </tr>
                        </tbody>
                        </table>
                        </div>
                        </div>
                        </div>
                        </div>
                    {/* ADMIN TABLE END */}

            </div>
            </div>


          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
</>
);

//}
}
export default Superadmindashboard;