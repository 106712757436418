import React from "react";
import { useState, useEffect, Component } from "react";
import { useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
//import axios from '../../axios.js';
import moment from "moment/moment";

import "../../Resources/homebtcss.css";
import "../../Resources/home2css.css";
import "../../Resources/serviceshomecss.css";
import "../ankitcss.css";
import '../../Resources/responsiveness.css';

//import Sidebar from "./sidebar";

//import Chart from "chart.js/auto";
//import { Line, Bar } from "react-chartjs-2";
import Footer3 from "../../Supportingfiles/footer3";
//import { Navbar } from "flowbite-react";
import Navbar3 from "../../Supportingfiles/navbar3";
import Whystartupconnect from "../whystartupconnect";


function Startupindiaseedfundscheme1() {

  
  /* Cashfree Button Style */

  const button_container = {
    border: "1px solid black",
    borderRadius: "5px",
    display: "flex",
    padding: "10px", 
    width: "fit-content",
    cursor: "pointer",
    background: "#0b6083",
    border:"none"
};

const text_container = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginLeft: "10px",
  justifyContent: "center",
  marginRight: "10px",
  fontWeight:"bold"
};
const logo_container = {
  width: "40px",
  height: "40px"
};
const secondary_logo_container = {
width: "16px",
height: "16px",
verticalAlign: "middle"
};


  return (
    <>
      <html lang="en">
        <head>
          {/*} Required meta tags */}
          <meta charset="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <title>STARTUP CONNECT |  SERVICES | STARTUP INDIA SEED FUND SCHEME (SISFS) APPLICATION</title>
          {/*} plugins:css */}
          <link
            rel="stylesheet"
            href="panel/assets/vendors/mdi/css/materialdesignicons.min.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/css/vendor.bundle.base.css"
          />
          {/*} endinject */}
          {/*} Plugin css for this page */}
          <link
            rel="stylesheet"
            href="panel/assets/vendors/jquery-bar-rating/css-stars.css"
          />
          <link
            rel="stylesheet"
            href="panel/assets/vendors/font-awesome/css/font-awesome.min.css"
          />
          {/*} End plugin css for this page */}
          {/*} inject:css */}
          {/*} endinject */}
          {/*} Layout styles */}
          <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
          {/*} End layout styles */}
          <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
        </head>
        <body>

        
  {/* Whatsapp Chat Icon Start */}
  <div class="wtp-chat-container" /*style={{position:"fixed",marginTop:"92vh", marginLeft:"94vw"}}*/>
    <a aria-label="Chat on WhatsApp" href="https://wa.me/918480002413?text=I%20want%20to%20know%20more%20about%20service%20Startup%20India%20SeedFund%20Application%20by%20the%20Startup%20Connect">   
    <img class="wtp-chat-icon" src="https://cdn-icons-png.flaticon.com/128/3938/3938058.png" /*style={{height:"3rem", width:"3rem"}}*/></img>
  </a>
  </div>
  {/* Whatsapp Chat Icon End */}

          <div class="home2-container">
            <Navbar3 />

            {/* HEADER SECTION START */}
            <section /*class="sv-header-section" */>
              <div class="sv-header-container1">
                <div class="sv-header-left home-header-pd">
                  <h1 style={{ color: "#004463" }}>
                  STARTUP INDIA SEED FUND SCHEME (SISFS) APPLICATION

                  </h1>
                  <p>
                  Are you an aspiring entrepreneur with a groundbreaking startup idea? Look no further – Startup Connect is here to guide you through the intricacies of the Startup India Seed Fund Scheme (SISFS) application process. Our experienced team offers personalized support to maximize your chances of securing the seed funding you need to kickstart your entrepreneurial journey. From refining your business plan to optimizing your financial projections, we're committed to helping you showcase the full potential of your startup to the selection committee. </p>
                  <p>
                  Ready to take the next step towards securing SISFS funding for your startup? Get in touch with us today and let Startup Connect be your trusted partner on the path to startup success!
                  </p>
                  <div class="home2-services-features-list">

                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img
                          loading="lazy"
                          src="https://cdn-icons-png.flaticon.com/128/1087/1087840.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>500+</h3>
                        <h5 style={{ fontWeight: "bold" }}>Projects</h5>
                      </div>
                    </div>

                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img
                          loading="lazy"
                          src="https://cdn-icons-png.flaticon.com/128/12735/12735475.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>25+</h3>
                        <h5 style={{ fontWeight: "bold" }}>Cities</h5>
                      </div>
                    </div>

                    <div class="home2-services-fcard">
                      <div class="home2-services-fcard-left">
                        <img
                          loading="lazy"
                          src="https://cdn-icons-png.flaticon.com/128/2991/2991235.png"
                          id="home2-services-fcard-icon"
                        />
                      </div>
                      <div class="home2-services-fcard-right">
                        <h3>
                          4{" "}
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/7656/7656139.png"
                            style={{ height: "2rem", width: "2rem" }}
                          />
                          +
                        </h3>
                        <h5 style={{ fontWeight: "bold" }}>Google Ratings</h5>
                      </div>
                    </div>
                  </div>

                  <div  class="serviceshome-btn-container">
             <div>
             <a href="https://calendly.com/thestartupconnect/30min"><button class="btn-lft-margin" id="home2-modules-section-btn" style={{marginLeft:"0"}}>Book Free Consultancy <i class="mdi mdi-arrow-right"></i></button></a>
             </div>
             <div style={{marginLeft:"0"}}>
             <a href="/home1/sisfs_brochure.pdf" download="Startup Connect Services Brochure"><button class="btn-lft-margin" id="home2-modules-section-btn" style={{backgroundImage: "linear-gradient(to right, #208d17, #00ad11)", marginLeft:"0"}}>Download Brochure <i class="mdi mdi-download"></i></button></a>
             </div>
             </div>

                </div>
                <div
                  class="sv-header-right" /* style={{backgroundColor:"#96dfff",padding:"2rem",borderRadius:"15px",boxShadow:"2px 3px 3px 2px #cbcecf"}} */
                >
                  <img
                    loading="lazy"
                    src="https://img.freepik.com/free-vector/people-meeting-concept-with-conference_1284-11235.jpg"
                    style={{ width: "90%", height: "100%" }}
                  />
                </div>
              </div>
            </section>
            {/* HEADER SECTION END */}
            <hr />

            {/* SIDEBAR AND MAIN PAGE START */}
            <div className="container-scroller">
              {/*<Sidebar /> */}
              <div className="container-fluid page-body-wrapper">
                {/*} partial */}
                <div className="main-panel">
                 

                 {/* MORE INFORMATION SECTION START */}
                 <div class="row" style={{ padding: "2rem" }}>
                    <div
                      class="col-sm-9 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div>
                        <h2 style={{ fontWeight: "bold" }}>
                         WHAT IS {" "}
                          <span style={{ color: "#2da95c" }}>
                            STARTUP INDIA?
                          </span>
                        </h2>
                        <br />
                        <p style={{ fontSize: "1rem" }}>
                          {" "}
                          The Startup India initiative of the Government of India
                           envisages building a robust Start-up ecosystem in the country
                           for nurturing innovation and providing opportunities to
                           budding entrepreneurs.
                        </p><p style={{ fontSize: "1rem" }}>
                           An Action Plan with 19 action points for the Startup India
                           initiative was unveiled by the Hon'ble Prime Minister on
                           January 16, 2016.
                        </p>
                        <p style={{ fontSize: "1rem" }}>
                           This Action Plan laid down a roadmap for the creation of a
                           conducive ecosystem for Startups in India. Subsequently,
                           many activities have been undertaken to encourage Startups.
                           Startup India Seed Fund Scheme (SISFS) is one such scheme
                           that provides financial assistance to early-stage startups.

                        </p>
                      </div>
                    </div>
                    <div class="col-sm-3 stretch-card grid-margin">
                      <img
                        loading="lazy"
                        src="https://img.freepik.com/free-vector/woman-man-teamwork-with-coins-leaves_24877-54738.jpg"
                        style={{ width: "90%", height: "100%" }}
                      />
                    </div>
                  </div>

                  <div class="row" style={{ padding: "2rem" }}>
                  <div class="col-sm-3 stretch-card grid-margin">
                      <img
                        loading="lazy"
                        src="https://img.freepik.com/free-vector/happy-tiny-people-growing-money-tree-isolated-flat-illustration_74855-16162.jpg"
                        style={{ width: "90%", height: "100%" }}
                      />
                    </div>
                    <div
                      class="col-sm-9 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div>
                        <h2 id="ip-h2" style={{ fontWeight: "bold" }}>
                         WHAT IS {" "}
                          <span style={{ color: "#9f23c2" }}>
                          STARTUP INDIA SEED FUND SCHEME (SISFS)
                          </span>
                        </h2>
                        <br />
                      
                          <p style={{ fontSize: "1rem" }}>
                          Easy availability of capital is essential for entrepreneurs at the early stages of growth of an
enterprise.
Funding from angel investors and venture capital firms becomes available to startups only
after the proof of concept has been provided. Similarly, banks provide loans only to asset-backed applicants. It is essential to provide seed funding to startups with an innovative idea
to conduct proof of concept trials.
</p><p style={{ fontSize: "1rem" }}>
DPIIT has created Startup India Seed Fund Scheme (SISFS) with an outlay of INR 945 Crore to
provide financial assistance to startups for Proof of Concept, prototype development,
product trials, market entry, and commercialization. It will support an estimated 3,600
entrepreneurs through 300 incubators in the next 4 years.
</p><p style={{ fontSize: "1rem" }}>
The Hon'ble Prime Minister of India announced the scheme in his Grand Plenary address of
Prarambh: Startup India International Summit on 16th January 2021. After approval of EFC
and Hon'ble Finance Minister, the scheme has been notified on 21.01.2021.
</p><p style={{ fontSize: "1rem" }}>
The Seed Fund will be disbursed to eligible startups through eligible incubators across India. </p>

                      </div>
                    </div>
                   
                  </div>


                  <div class="row" style={{ padding: "2rem" }}>
                
                    <div
                      class="col-sm-6 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div>

                      <h2 id="ip-h2" style={{ fontWeight: "bold" }}>
                         REQUIREMENTS FOR APPLYING IN {" "}
                          <span style={{ color: "#2da95c" }}>
                            STARTUP INDIA SEED FUND SCHEME (SISFS)
                          </span>
                        </h2>
                        <p  style={{ fontSize: "1rem" }}>To apply for the Startup India Seed Fund Scheme, the following requirements must be fulfilled:</p>
                        <br/>
                          <p style={{ fontSize: "1rem" }}>
                          <i class="mdi mdi-assistant text-warning" style={{fontSize:"1.4rem",padding:"1rem"}}></i>
                          Company incorporation Certificate</p>

                          <p style={{ fontSize: "1rem" }}>
                          <i class="mdi mdi-assistant text-warning" style={{fontSize:"1.4rem",padding:"1rem"}}></i>
                          Founders details (Name, Address, Date of birth, Mobile number, email id, Qualification, Experience, Residential address)</p>

                          <p style={{ fontSize: "1rem" }}>
                          <i class="mdi mdi-assistant text-warning" style={{fontSize:"1.4rem",padding:"1rem"}}></i>
                          Director details (Name, Address, Date of birth, Mobile number, email id, Qualification, Experience, Residential address)</p>

                          <p style={{ fontSize: "1rem" }}>
                          <i class="mdi mdi-assistant text-warning" style={{fontSize:"1.4rem",padding:"1rem"}}></i>
                          DPIIT Certificate</p>

                          <p style={{ fontSize: "1rem" }}>
                          <i class="mdi mdi-assistant text-warning" style={{fontSize:"1.4rem",padding:"1rem"}}></i>
                          Company Details</p>

                      </div>
                    </div>
                    <div class="col-sm-6 stretch-card grid-margin">
                      <img
                        loading="lazy"
                        src="https://img.freepik.com/free-vector/business-plan-concept-illustration_114360-1487.jpg"
                        style={{ width: "90%", height: "100%" }}
                      />
                    </div>
                  </div>
                  {/* MORE INFORMATION SECTION END */}

                  <hr/>
                  <h2 id="ip-h2" style={{fontWeight:"bold", padding:"0.5rem"}}>
                    BENEFITS OF <span style={{ color: "#9f23c2" }}>
                            STARTUP INDIA SEED FUND SCHEME (SISFS)
                          </span>
                  </h2>
                  {/* BENEFITS SECTION START */}
                  <div class="row d-flex justify-content-center" style={{ padding: "2rem" }}>
                    <div
                      class="col-sm-4 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/1464/1464393.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Financial Boost: </h4>
                        <p>
                        Receive vital financial support to kick-start your venture! The
Seed Fund India Scheme provides seed funding to early-stage startups, helping
you bridge the initial funding gap and bring your ideas to life.  </p>
                      </div>
                    </div>

                    <div
                      class="col-sm-4 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/7756/7756169.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Encouraging Innovation:</h4>
                        <p>
                        We believe in nurturing innovation and creativity! With
the Seed Fund India Scheme, you can explore uncharted territories, push
boundaries, and bring disruptive ideas to the forefront of the business landscape.
</p>
                      </div>
                    </div>

                    <div
                      class="col-sm-4 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/4334/4334580.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Mentorship & Guidance:</h4>
                        <p>
                        As a participant in the Seed Fund India Scheme, you'll
gain access to a network of experienced mentors and industry experts. Their
valuable insights, guidance, and support will pave the way</p>
                      </div>
                    </div>

                    <div
                      class="col-sm-4 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/8991/8991202.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Market Exposure:</h4>
                        <p>
                        The Seed Fund India Scheme not only provides financial aid but
also offers exposure to potential customers and investors. By leveraging our
extensive network, you'll have the opportunity to showcase your product or
service to a wider audience and secure potential partnerships.</p>
                      </div>
                    </div>

                

                    <div
                      class="col-sm-4 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img loading = "lazy"

                            src="https://cdn-icons-png.flaticon.com/128/1070/1070889.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">Entrepreneurial Ecosystem: </h4>
                        <p>
                        Joining the Seed Fund India Scheme means
becoming a part of a vibrant entrepreneurial ecosystem! Connect with like-minded individuals, collaborate with fellow innovators, and unlock a world of
opportunities to grow and scale your startup.</p>
                      </div>
                    </div>

                   
                  
                   
                  </div>
                  {/* BENEFITS SECTION END */}

                  <hr />
                    {/* REQUIREMENTS SECTION START */}
                    <h2 id="ip-h2">ELIGIBILITY CRITERIA :- WHO CAN APPLY?</h2>
                  <p style={{ fontSize: "1rem" }}>
                  To be eligible for the Startup India Seed Fund Scheme, the following criteria must be met:
                  </p>
                  <div
                    class="row"
                    style={{ textAlign: "left", padding: "2rem" }}
                  >
                    <p style={{ fontSize: "1rem" }}>
                    <i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> 
                    A startup, recognized by DPIIT, incorporated not more than 2 years ago at the time of application.{" "}</p>

                    <p style={{ fontSize: "1rem" }}>
                    <i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> 
                    The startup must have a business idea to develop a product or a service with a market fit, viable commercialization, and scope of scaling{" "}</p>
              
                    <p style={{ fontSize: "1rem" }}>
                    <i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> 
                    The startup should be using technology in its core product or service, or business model, or distribution model, or methodology to solve the problem being targeted.{" "}</p>

                    <p style={{ fontSize: "1rem" }}>
                    <i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> 
                    Preference would be given to startups creating innovative solutions in sectors
such as social impact, waste management, water management, financial
inclusion, education, agriculture, food processing, biotechnology, healthcare,
energy, mobility, defence, space, railways, oil and gas, textiles, etc.
{" "}</p>

                    <p style={{ fontSize: "1rem" }}>
                    <i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> 
                    Startup should not have received more than Rs 10 lakh of monetary support
under any other Central or State Government scheme. This does not include
prize money from competitions and grand challenges, subsidized working
space, founder monthly allowance, access to labs, or access to prototyping
facility{" "}</p>

                    <p style={{ fontSize: "1rem" }}>
                    <i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> 
                    Shareholding by Indian promoters in the startup should be at least 51% at the
time of application to the incubator for the scheme, as per Companies Act,
2013 and SEBI (ICDR) Regulations, 2018.{" "}</p>

                    <p style={{ fontSize: "1rem" }}>
                    <i class="mdi mdi-check-decagram text-primary" style={{fontSize:"2rem",padding:"1rem"}}></i> 
A startup applicant can avail seed support in the form of grant and
debt/convertible debentures each once as per the guidelines of the scheme.</p>
                    <br />
                    <br />
                    </div>
                  {/* REQUIREMENTS SECTION END */}
                    <hr/>

                   {/* PROCESS SECTION START */}
                   <h2 id="ip-h2" style={{padding:"0.5rem"}}>HOW WE CAN HELP YOU IN THE PROCESS?</h2>
                   <div class="row d-flex justify-content-center mt-3" style={{padding:"1rem"}}>
                    <div class="col-sm-3 stretch-card grid-margin" id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/free-vector/pitch-meeting-concept-illustration_114360-6000.jpg"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold"> Initial Consultation</h4>
                          <div class="d-flex justify-content-between">
                            <p class="mb-0" style={{ fontSize: "1rem" }}>
                            Offer an initial consultation to understand the startup's business
idea, goals, and funding requirements. Discuss the application process and the
documents required for filing.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-3 stretch-card grid-margin" id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/free-vector/team-goals-concept-illustration_114360-5146.jpg"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold"> Idea Refinement: </h4>
                          <div>
                            <p class="mb-0" style={{ fontSize: "1rem", textAlign:"center" }}>
                            Assist the startup in refining their business idea and value
proposition. Work closely with the founders to create a compelling pitch that highlights
the uniqueness of their product or service.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-3 stretch-card grid-margin" id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/premium-vector/businessman-shaking-hands-deal-symbol-with-fellow-lawyer-lawyers-discuss-with-clients_923732-776.jpg?w=740"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold"> Pitch Deck Preparation  </h4>
                          <div>
                            <p class="mb-0" style={{ fontSize: "1rem", textAlign:"center" }}>
                            Develop a professional and visually appealing pitch deck that
effectively communicates the startup's business model, market analysis, competitive
advantages, and financial projections.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-3 stretch-card grid-margin" id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/free-vector/process-optimization-concept-illustration_114360-21583.jpg"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold"> Points Creation: </h4>
                          <div>
                            <p class="mb-0" style={{ fontSize: "1rem",textAlign:"center" }}>
                            Help the startup create key points and compelling arguments that
address the requirements of the seed fund application. Highlight the startup's potential
for growth, scalability, and impact.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-3 stretch-card grid-margin" id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/free-vector/data-concept-illustration_114360-212.jpg"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold">Financial Projections : </h4>
                          <div>
                            <p class="mb-0" style={{ fontSize: "1rem",textAlign:"center" }}>
                            Aid in preparing detailed financial projections, including revenue
forecasts, cost estimates, and projected milestones. Ensure the financials are realistic,
based on market research and industry benchmarks.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-3 stretch-card grid-margin" id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/free-vector/investment-data-concept-illustration_114360-5159.jpg"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold">Funds Requirement and Deployment Plan: </h4>
                          <div>
                            <p class="mb-0" style={{ fontSize: "1rem",textAlign:"center" }}>
                            Work with the founders to determine the
exact funding requirements and how the funds will be deployed to achieve specific
business objectives.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-3 stretch-card grid-margin" id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/free-vector/product-quality-concept-illustration_114360-7301.jpg?w=740&t=st=1706780098~exp=1706780698~hmac=4ed66236fdef104f05dab6b8d7176409171e304e19a3758c389bc28837bbe13b"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold">Application Completion:</h4>
                          <div>
                            <p class="mb-0" style={{ fontSize: "1rem",textAlign:"center" }}>
                            Guide the startup in completing the seed fund India
application accurately and promptly. Review the application to ensure it is well-structured and includes all necessary information.

                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-3 stretch-card grid-margin" id="resp-3">
                      <div class="card">
                        <div class="card-body">
                          <img
                            loading="lazy"
                            class="img-fluid w-100"
                            src="https://img.freepik.com/free-vector/pitch-meeting-concept-illustration_114360-6300.jpg"
                            alt=""
                          />
                        </div>
                        <div class="card-body px-3 text-dark">
                          <h4 class="font-weight-bold">Presentation of Idea:</h4>
                          <div>
                            <p class="mb-0" style={{ fontSize: "1rem",textAlign:"center" }}>
                            Provide assistance in the pitch presentation. Help the founders
refine their public speaking skills and handle potential questions from investors.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <hr/>
                  {/* PROCESS SECTION END */}

                  {/* Why StartUp Connect Starts */}
                  <Whystartupconnect/>
                  {/* Why StartUp Connect Ends */}

                  <hr />
                  
                  {/* Documents SECTION Starts */}
                  <h2 id="ip-h2">DELIVERABLES OF THE STARTUP INDIA SEED FUND SCHEME (SISFS) CONSULTANCY</h2>
                  <div class="row d-flex justify-content-center" style={{ padding: "2rem" }}>
                    <div
                      class="col-sm-4 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/3135/3135715.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>

                        <h4 class="mt-3">FINANCIAL PROJECTIONS</h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-4 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/1726/1726620.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">
                          CONSULTATION POINTS
                        </h4>
                      </div>
                    </div>

                    <div
                      class="col-sm-4 stretch-card grid-margin" id="resp-3"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        class="card"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        <div class="d-flex justify-content-center">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/128/9068/9068642.png"
                            style={{ height: "6rem", width: "6rem" }}
                          />
                        </div>
                        <h4 class="mt-3">PITCH PRESENTATION</h4>
                      </div>
                    </div>

                  </div>
                  <hr/>
                  {/* Documents SECTION Ends */}

                  {/* PRICING SECTION START */}
                  <h2 id="ip-h2" style={{fontWeight:'bold', padding:"0.5rem"}}>PRICING FOR <span style={{color:"#006345"}}>STARTUP INDIA SEED FUND SCHEME (SISFS)</span> APPLICATION</h2>
                  <div class="row md-12" style={{marginTop:"5rem"}}>

                  {/* PRICING CARD 1 START */}
                  <div
                      class="col-sm-12 stretch-card grid-margin"
                      style={{ textAlign: "left" }}
                    >
                    <div class="card">
                    <div style={{backgroundColor:"#207a78",color:"#ffffff",fontWeight:"bold",textAlign:"center",borderRadius:"12px",padding:"1rem"}}>
                    <h3 class="pricing-ul-text"><u>GET CUSTOMIZED QUOTATIONS FOR STARTUP INDIA SEED FUND SCHEME (SISFS) APPLICATION IN YOUR BUDGET</u></h3>
                    <div style={{textAlign:"center"}}><h2 style={{fontWeight:"bold"}}> Starting at <span style={{color:"#ffffff"}}>₹ 9999*</span> only</h2></div>
                    </div>
                    <div style={{padding:"1rem",margingTop:"1rem"}}>
                       <div class="d-flex justify-content-center">
                       <button class="btn btn-lg btn-success" style={{backgroundColor:"#207a78", fontSize:"1rem"}} onClick={()=>{
                        //Setting Values to redirect to Contact Sales Page
                        sessionStorage.setItem("Smodule00","Services");sessionStorage.setItem("Sservice00","SISFS Application"); window.location.replace("/businesstools-contactsales");
                       }}>Contact Sales</button>
                        {/* CASHFREE CHECKOUT BUTTON START FOR PRICING CARD */}
                        <div>
                        <a style={{textDecoration:"none"}} href="https://payments.cashfree.com/forms/startupconnect-sisfs-payment" target="_parent">
                        <div class="button-container" style={button_container}>
                        <div>
                        {/*}    <img src="https://cashfree-checkoutcartimages-prod.cashfree.com/startupconnectlogo1Y6cg7dgopcs0_prod.png" alt="logo" style={logo_container} class="logo-container" />
                        */} </div>
                        <div class="text-container" style={text_container}>
                        <div style={{fontFamily: "Arial", color: "#fff", marginBottom: "5px", fontSize: "14px"}}>
                        Check Out
                        </div>
                        <div style={{fontFamily: "Arial", color: "#fff", fontSize: "10px"}}>
                        <span>Powered By Cashfree</span>
                        <img src="https://cashfreelogo.cashfree.com/cashfreepayments/logosvgs/Group_4355.svg" alt="logo" style={secondary_logo_container} class="seconday-logo-container" />
                        </div>
                        </div>
                        </div>
                        </a>
                        </div>
                       {/* CASHFREE CHECKOUT BUTTON START FOR PRICING CARD */}
                       </div>
                    </div>
                    </div>
                    </div>
                    {/* PRICING CARD 1 END */}

                  </div>
                  <hr/>
                  {/* PRICING SECTION END */}
              

             
                  <h2 id="ip-h2">Frequently Asked Questions (FAQs)</h2>
                  {/* FAQ SECTION START */}
                  <div
                    id="accordion"
                    style={{ textAlign: "left", marginTop: "3rem" }}
                  >
                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#003e73" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                         For what kind of activities does SISFS provide grant support?
                        </h5>
                        <div>
                          <button
                            id="ques-1-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-1").style.display =
                                "block";
                              document.getElementById(
                                "ques-1-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-1-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-1-dicon"
                            style={{ display: "none" }}
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-1").style.display =
                                "none";
                              document.getElementById(
                                "ques-1-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-1-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-1">
                        <div class="card-body">
                        SISFS provides grant funding for activities like proof of concept, prototype development, product trials, market entry, and commercialization. </div>
                        </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                        How long does it take to receive the funding?
                        </h5>
                        <div>
                          <button
                            id="ques-2-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-2").style.display =
                                "block";
                              document.getElementById(
                                "ques-2-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-2-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-2-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-2").style.display =
                                "none";
                              document.getElementById(
                                "ques-2-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-2-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-2" style={{ display: "none" }}>
                        <div class="card-body">
                        The timeline varies depending on the application process and due diligence. But, it typically takes anywhere between 60-180 days for process completion.
                        </div>  </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                       What is the maximum limit of funds set under SISFS for startups?
                        </h5>
                        <div>
                          <button
                            id="ques-3-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-3").style.display =
                                "block";
                              document.getElementById(
                                "ques-3-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-3-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-3-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-3").style.display =
                                "none";
                              document.getElementById(
                                "ques-3-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-3-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-3" style={{ display: "none" }}>
                        <div class="card-body">
                        Under this scheme startups can get Grant upto Rs. 20 Lakhs and Debt upto Rs. 50 Lakhs.*</div>
                        </div>
                    </div>

                    {/*<div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{

                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                    How does the business plan creation process work?
                        </h5>
                        <div>
                          <button
                            id="ques-4-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-4").style.display =
                                "block";
                              document.getElementById(
                                "ques-4-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-4-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-4-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-4").style.display =
                                "none";
                              document.getElementById(
                                "ques-4-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-4-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-4" style={{ display: "none" }}>
                        <div class="card-body">
                        The process typically begins with an initial consultation, where the service provider gathers information about your business, goals, and target market. From there, they conduct market research, develop strategic plans, create financial projections, and craft the business plan itself. Throughout the process, there is collaboration between you and the service provider to ensure that the plan accurately reflects your vision and objectives.</div>
                      </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                    How long does it take to create a business plan?

                        </h5>
                        <div>
                          <button
                            id="ques-5-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-5").style.display =
                                "block";
                              document.getElementById(
                                "ques-5-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-5-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-5-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-5").style.display =
                                "none";
                              document.getElementById(
                                "ques-5-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-5-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-5" style={{ display: "none" }}>
                        <div class="card-body">
                        The timeline for creating a business plan depends on various factors, including the complexity of your business, the level of detail required, and the availability of information. Generally, the process can take several weeks to a few months from initial consultation to final delivery. However, some service providers offer expedited options for clients with tight deadlines.
                        </div>
                         </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                       How can a business plan help me secure funding or investment?
                        </h5>
                        <div>
                          <button
                            id="ques-6-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-6").style.display =
                                "block";
                              document.getElementById(
                                "ques-6-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-6-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-6-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-6").style.display =
                                "none";
                              document.getElementById(
                                "ques-6-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-6-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-6" style={{ display: "none" }}>
                        <div class="card-body">
                        A well-crafted business plan is essential for attracting investors and lenders. It demonstrates your understanding of the market, the viability of your business model, and the potential for growth and profitability. Investors use business plans to evaluate the risks and opportunities associated with your venture and make informed decisions about providing funding or investment.</div>
                      </div>
                    </div>

                    <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                         Can I update or revise my business plan as my business evolves?
                        </h5>
                        <div>
                          <button
                            id="ques-7-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-7").style.display =
                                "block";
                              document.getElementById(
                                "ques-7-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-7-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-7-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-7").style.display =
                                "none";
                              document.getElementById(
                                "ques-7-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-7-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-7" style={{ display: "none" }}>
                        <div class="card-body">
                        Yes, it's important to regularly review and update your business plan to reflect changes in your business, market conditions, or strategic priorities. Many business plan creation services offer ongoing support and assistance with revisions to ensure that your plan remains current and relevant as your business evolves.</div>
                      </div>
                    </div>

                  {/*} <div class="card">
                      <div
                        class="card-header d-flex justify-content-between"
                        id="headingOne"
                        style={{ backgroundColor: "#2379c2" }}
                      >
                        <h5
                          class="mb-0"
                          style={{
                            fontSize: "1.2rem",
                            color: "#ffffff",
                            padding: "1rem",
                          }}
                        >
                         What kind of analytics can I expect from a web app?
                        </h5>
                        <div>
                          <button
                            id="ques-8-aicon"
                            class="btn btn-icon"
                            onClick={() => {
                              document.getElementById("ans-8").style.display =
                                "block";
                              document.getElementById(
                                "ques-8-aicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-8-dicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-download"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                          <button
                            id="ques-8-dicon"
                            class="btn btn-icon"
                            style={{ display: "none" }}
                            onClick={() => {
                              document.getElementById("ans-8").style.display =
                                "none";
                              document.getElementById(
                                "ques-8-dicon"
                              ).style.display = "none";
                              document.getElementById(
                                "ques-8-aicon"
                              ).style.display = "block";
                            }}
                          >
                            <i
                              class="mdi mdi-briefcase-upload"
                              style={{
                                color: "#ffffff",
                                fontSize: "2rem",
                                padding: "1rem",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div id="ans-8" style={{ display: "none" }}>
                        <div class="card-body">
                        Web apps can provide advanced analytics to track user behavior and identify areas for improvement.{" "}
                        </div>
                      </div>
                    </div> */}
                  </div>
                  {/* FAQ SECTION END */}

                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
            {/* SIDEBAR AND MAIN PAGE END */}

            <Footer3 />
          </div>
          {/*} container-scroller */}
          {/*} plugins:js */}
          <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
          {/*} endinject */}
          {/*} Plugin js for this page */}
          <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
          <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
          <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
          <script
            src="panel/assets/js/jquery.cookie.js"
            type="text/javascript"
          ></script>
          {/*} End plugin js for this page */}
          {/*} inject:js */}
          <script src="panel/assets/js/off-canvas.js"></script>
          <script src="panel/assets/js/hoverable-collapse.js"></script>
          <script src="panel/assets/js/misc.js"></script>
          <script src="panel/assets/js/settings.js"></script>
          <script src="panel/assets/js/todolist.js"></script>
          {/*} endinject */}
          {/*} Custom js for this page */}
          <script src="panel/assets/js/dashboard.js"></script>
          {/*} End custom js for this page */}
        </body>
      </html>
    </>
  );
}

export default Startupindiaseedfundscheme1;
