import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';



function Navbar(){
    
    
    
 return(
    <>  
    
    
    
    {/* NAVBAR START */}
  <div class="hire-navbar">
  <div class="hire-navbar-left">
  <div class="hire-navbar-logo">
  <img src="assets/images/logo-a.jpeg" alt="logo" id="hire-navbar-logo-default"/>
  </div> 
  {/* Left Side Bar Menu */}
  <div class="hire-navbar-icon" id="navbar-leftsidebar-menu"><img src="https://cdn-icons-png.flaticon.com/128/3388/3388797.png" id="navbar-menu-icon" onClick={()=>{ 
      const display = window.getComputedStyle(document.getElementById('left-sidebar')).getPropertyValue('display');
      if(display=="none"){  document.getElementById("left-sidebar").style.display="block"; }
      else{ document.getElementById("left-sidebar").style.display="none"; }
      
       }} /></div>
  </div>
  <div class="hire-navbar-right">
  {/* Right Sidebar Menu */}
  <div class="hire-navbar-icon"><img src="https://cdn-icons-png.flaticon.com/128/9073/9073396.png" id="navbar-menu-icon" onClick={()=>{ 
      const display = window.getComputedStyle(document.getElementById('right-sidebar')).getPropertyValue('display');
      if(display=="none"){  document.getElementById("right-sidebar").style.display="block"; }
      else{ document.getElementById("right-sidebar").style.display="none"; }
      
       }} /></div>     

  <div class="hire-navbar-icon"><img src="https://cdn-icons-png.flaticon.com/128/3237/3237472.png" id="navbar-menu-icon" onClick={()=>{ 
      const display = window.getComputedStyle(document.getElementById('hire-log-panel')).getPropertyValue('display');
      if(display=="none"){  document.getElementById("hire-log-panel").style.display="block"; }
      else{ document.getElementById("hire-log-panel").style.display="none"; }
      
       }} /></div>     
  </div>
</div>
{/* NAVBAR END */}


   {/* LOG PANEL START */}
   <div class="hire-log-panel" id="hire-log-panel" >
      <div class="hire-log-panel-top">
      <h3>Rajul Kumar Sharma</h3>
      <p>Candidate Id : HSSGG2221</p>
      </div>
      <div id="line-3"></div>
      <div class="hire-log-panel-list">
       <div class="hire-log-panel-list-item"><p>Manage Profile</p></div>
       <div class="hire-log-panel-list-item"><p>Switch Profile</p></div>
       <div class="hire-log-panel-list-item"><p>Purchases and Credits</p></div>
       <div class="hire-log-panel-list-item"><p>Log Out</p></div>
      </div>
    </div>
    {/* LOG PANEL END */}

</>
 );

    }

    export default Navbar;