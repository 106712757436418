// ./components/PieChart.js
import React from "react";
//import Chart from "chart.js/auto";// App.js

import BarChart from "./Barchart";
import LineChart from "./Linechart";
import PieChart from "./Piechart";

function Charthome() {
  return (
    <div>
    <div style={{width:"50%",height:"40vh"}}>
     <LineChart />
      <BarChart /> 
      <PieChart />  
      </div>
      <h1>hello</h1>
    </div>
  );
}
export default Charthome;