import React from 'react';
import {useState,useEffect,Component} from 'react';
import axios from '../axios';
import './insidepagecss.css';
import Navbar from './navbar';
import Footer from './footer';
import Process from './process';
import Requirements from './requirements';

//import 'https://unpkg.com/aos@2.3.1/dist/aos.js';

/* const useScript = url => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, ["https://unpkg.com/aos@2.3.1/dist/aos.js"]);
};
*/

function Intpg(){

  //useScript();

  const buttons = document.querySelectorAll(".faq-toggle");

  buttons.forEach((button) => {
    button.addEventListener("click", () =>
      button.parentElement.classList.toggle("active")
    );
  });

  //FAQs Button Onclick Action
  //faqclass1
const[faqclass1,setfaqclass1]=useState('faq');
  //faqclass2
  const[faqclass2,setfaqclass2]=useState('faq active');
    //faqclass3
const[faqclass3,setfaqclass3]=useState('faq');
  //faqclass4
  const[faqclass4,setfaqclass4]=useState('faq');
    //faqclass5
const[faqclass5,setfaqclass5]=useState('faq');
  //faqclass6
  const[faqclass6,setfaqclass6]=useState('faq');
    //faqclass7
const[faqclass7,setfaqclass7]=useState('faq');
  //faqclass8
  const[faqclass8,setfaqclass8]=useState('faq');

  //AOS.init();
/*

document.querySelector('#contact-form').addEventListener('submit', (e) => {
  e.preventDefault();
  e.target.elements.name.value = '';
  e.target.elements.email.value = '';
  e.target.elements.message.value = '';
});
*/

function showsidebar(){
document.getElementById("inside-sidebar").style.display="block";
}


return(
<>

<html lang="en">
  <head>
    {/* Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

    {/* Bootstrap CSS */}
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous" />
    <link rel="stylesheet" href="./insidepagecss.css" type="text/css" />
    <link rel="stylesheet" href="./aman.css" type="text/css" />
    <title>Startup Connect | Inside Page</title>
  </head>
  <body>

  <div className="home-container">

    {/* Navbar Start */}
   
      <Navbar></Navbar>
    {/* Navbar End */}
 {/* SUCCESS ALERT */}
 <div class="alert alert-success" id="contact-alert" role="alert" style={{width:"80vw",marginLeft:"10vw",display:"none"}}>
 <div>
  Thanks! for Contacting us. Your Contact Details have been Successfully sent to our team. Our team will soon reach out to you!
  <img src="https://cdn-icons-png.flaticon.com/512/399/399274.png" style={{height:"2rem",width:"2rem",marginLeft:"6rem"}} onClick={()=>{document.getElementById("contact-alert").style.display="none";}}/>
  </div>
</div>
    {/* HEADER BAR START */}
    <div class="header-section-top-pane" >
      {/* Animation Circles Start */}
<div class="area">
<ul class="circles">
                         <li></li>
                         <li></li>
                         <li></li>
                         <li></li>
                         <li></li>
                         <li></li>
                         <li></li>
                         <li></li>
                         <li></li>
                         <li></li>
</ul>
</div>
{/* Animation Circles End */}

      <div class="inside-header">
        <div class="inside-header-left">
        <h1>Social Media Management</h1>
        <h3>Social Media is a  service for all. To be delivered with peace.</h3>
        <p><b>Social media management </b>refers to the process of overseeing and executing a brand's social media strategy. We can help you in creating and scheduling content, analyzing performance metrics, engaging with followers, and building a community. Effective social media management can help you build brand awareness, drive website traffic, and increase customer engagement and loyalty. We have a deep understanding of the social media landscape, target audience, and industry trends.</p>
        <button id="ihl-getstarted">GET STARTED</button>
      </div>
        <div class="inside-header-right">

          {/* CONTACT FORM START  */}
          <div class="ip-contact-form">
          <h5 style={{padding: "1rem"}}>Get Free Consultancy by Expert.</h5>
          <div id="contact-form-ln"></div>
          <input type="text" class="ip-form-input" id="name" placeholder="Enter your Name" /><br/>
          <small style={{fontSize:".8rem",color:"#f16083"}}></small>
          <input type="text" class="ip-form-input" id="email" placeholder="Enter your Email" /><br/>
          <small style={{fontSize:".8rem",color:"#f16083"}}></small>
          <input type="text" class="ip-form-input" id="contact"  placeholder="Enter your Contact" /><br/>
          <small style={{fontSize:".8rem",color:"#f16083"}}></small>
          <input type="text" class="ip-form-input" id="cstate" placeholder="Enter your State" /><br/>
          <small style={{fontSize:".8rem",color:"#f16083"}}></small>
          
          <button id="ip-contact-submit" >CONNECT TO EXPERT</button>
          
          </div>
      
          {/* CONTACT FORM END */}
        </div>
      </div>
    <h1></h1>
    </div>
    {/* HEADER BAR END */}
<Process></Process>

{/* DIVERGION BLOCK START */}
    <div class="divergion-block">
      {/* DIVERGION BLOCK LEFT SECTION START */}
      <div class="divergion-block-left">

    {/* SIDEBAR START */}
    <div class="inside-sidebar make-me-sticky" id="inside-sidebar">
      <div class="sidebar-content make-me-sticky">
        <ul>
          <a href="#service-features"><li>Service Features</li></a>
          <a href="#service-benefits"><li>Service Benefits</li></a>
          <a href="#service-requirements"><li>Service Requirements</li></a>
          <a href="#service-faqs"><li>Frequently Asked Questions</li></a>
          <a href="#service-process"><li>Process</li></a>
          <a href="#service-documents"><li>Documents Required</li></a>
          <a href="#service-similar"><li>Similar Services</li></a>
        </ul>
      </div>
    </div>
  {/* SIDEBAR END */}
  </div>
        {/* DIVERGION BLOCK LEFT SECTION END */}

        {/* DIVERGION BLOCK RIGHT SECTION START */}
        <div class="divergion-block-right">
    
 


    {/* FEATURES SECTION START */}
    <div class="features-section">
        <h2 style={{textAlign: "center"}} id="service-features">SERVICE FEATURES</h2>
                {/* FEATURES CARD LIST 1 START */}
                <div class="feature-card-list">

                    {/* FEATURES CARD 1 START*/}
                    <div class="feature-card">
                        <div class="feature-card-top">           
                            <div class="feature-card-icon"><img id="feature-icon" src="https://cdn-icons-png.flaticon.com/512/6823/6823088.png" /></div>           
                            <div class="feature-card-content">
                                <h3>Two-way Interaction</h3>
                                <p>Social media provides a way for businesses to communicate  directly with your customers, receive feedback, and address concerns in real-time.</p>
                            </div>
                        </div>
                    </div>
                    {/* FEATURES CARD 1 END*/}
                    {/* FEATURES CARD 2 START*/}
                    <div class="feature-card">
                        <div class="feature-card-top">           
                            <div class="feature-card-icon"><img id="feature-icon" src="https://cdn-icons-png.flaticon.com/512/1875/1875523.png" /></div>           
                            <div class="feature-card-content">
                              <h3>Cost-effective</h3>
                                <p>Social media marketing can make it relatively inexpensive compared to traditional advertising methods, making it accessible for businesses of all sizes.</p>
                            </div>
                        </div>
                    </div>
                    {/* FEATURES CARD 2 END*/}
                      {/* FEATURES CARD 3 START*/}
                      <div class="feature-card">
                        <div class="feature-card-top">           
                            <div class="feature-card-icon"><img id="feature-icon" src="https://cdn-icons-png.flaticon.com/512/1933/1933975.png" /></div>           
                            <div class="feature-card-content">
                               <h3>Shareable content</h3>
                                <p>Social media platforms are designed for sharing and making it easier for your businesses to create content that is more likely to be shared and seen by a larger audience.</p>
                            </div>
                        </div>
                    </div>
                    {/* FEATURES CARD 3 END*/}
                </div>
                {/* FEATURES CARD LIST 1 END */}
        {/* FEATURES CARD LIST 2 START */}
        <div class="feature-card-list">

        {/* FEATURES CARD 1 START*/}
        <div class="feature-card">
            <div class="feature-card-top">           
                <div class="feature-card-icon"><img id="feature-icon" src="https://cdn-icons-png.flaticon.com/512/5431/5431282.png" /></div>           
                <div class="feature-card-content">
                   <h3>Analytics and tracking</h3>
                    <p>Social media platforms provide analytics and tracking tools that allow your businesses to measure the effectiveness of their campaigns and adjust their strategy accordingly.</p>
                </div>
            </div>
        </div>
        {/* FEATURES CARD 1 END*/}
        {/* FEATURES CARD 2 START*/}
        <div class="feature-card">
            <div class="feature-card-top">           
                <div class="feature-card-icon"><img id="feature-icon" src="https://cdn-icons-png.flaticon.com/512/2140/2140212.png" /></div>           
                <div class="feature-card-content">
                    <h3>Relationship building</h3>
                    <p>Social media marketing can help your businesses build stronger relationships with your customers by providing opportunities for engagement and interaction.</p>
                </div>
            </div>
        </div>
        {/* FEATURES CARD 2 END*/}
        
    </div>
    {/* FEATURES CARD LIST 2 END */}
    </div>
    {/* FEATURES SECTION END */}

    {/* BENEFIT SECTION START */}
<h2 style={{textAlign:"center",fontSize:"2.4rem",marginTop:"2rem"}}>BENEFITS OF TAKING THIS SERVICE</h2>
<div class="benefit-section" id="service-benefits">

  <div class="benefit-section-left">
 
  <ul>
    <li><img src="https://cdn-icons-png.flaticon.com/512/9690/9690019.png" id="benefit-list-icon"></img><span id="benefit-list-heading">Reliable</span><br/>
    <span id="benefit-list-content">At The Startup Connect, we prioritize reliability in all of our services to ensure consistent and trustworthy support for your business growth.</span></li>
    <li><img src="https://cdn-icons-png.flaticon.com/512/9321/9321632.png" id="benefit-list-icon"></img><span id="benefit-list-heading">Efficient</span><br/>
    <span id="benefit-list-content">Efficiency is key to success, and at The Startup Connect, we strive to optimize our services for maximum efficiency and productivity.</span></li>
    <li><img src="https://cdn-icons-png.flaticon.com/512/998/998412.png" id="benefit-list-icon"></img><span id="benefit-list-heading">Professional</span><br/>
    <span id="benefit-list-content">Our team at The Startup Connect is comprised of experienced professionals dedicated to providing top-notch services to help your business succeed.</span></li>

  </ul>
  </div>
  <div class="benefit-section-left">

  <ul>
    <li><img src="https://cdn-icons-png.flaticon.com/512/7601/7601227.png" id="benefit-list-icon"></img><span id="benefit-list-heading">Quality Served</span><br/>
    <span id="benefit-list-content">At The Startup Connect, we believe in delivering quality services that exceed expectations and drive long-term success for your business.</span></li>
    <li><img src="https://cdn-icons-png.flaticon.com/512/4954/4954479.png" id="benefit-list-icon"></img><span id="benefit-list-heading">Convenient</span><br/>
    <span id="benefit-list-content">We understand the value of time and offer convenient services to help you focus on growing your business while we take care of the rest at The Startup Connect.</span></li>
    <li><img src="https://cdn-icons-png.flaticon.com/512/4719/4719440.png" id="benefit-list-icon"></img><span id="benefit-list-heading">Budget Friendly</span><br/>
    <span id="benefit-list-content">At The Startup Connect, we offer budget-friendly services without compromising on quality to help your business achieve its goals within your budget.</span></li>

  </ul>
  </div>

</div>
{/* BENEFIT SECTION END */}

<Requirements></Requirements>  
    

    {/* SIMILAR SERVICES SECTION START */}
    {/* SIMILAR SERVICES SECTION END */}     
    

    {/* SERVICES STEPS START */}
    {/* SERVICES STEPS END */}    
    
{/* FREQUENTLY ASKED QUESTIONS SECTION START */}
<div class="faq-section" id="service-faqs">
  <main>
    <h1>Frequently Asked Questions</h1>
    
    <section class="faq-container" id="FAQs">
      <div data-aos="zoom-in">
     
      <div class={faqclass1}>
        <h3 class="faq-title">
          Which social media platforms should I use for my business?
  
        </h3>
        <p class="faq-text">Ans. Depending on your target audience and the type of your business, you can use different social media channels. Some of the most well-liked social media networks for businesses are Facebook, Instagram, Twitter, and LinkedIn.
        </p>
        <button class="faq-toggle">
         <img src="https://cdn-icons-png.flaticon.com/128/10009/10009582.png" style={{width: "2.5rem",height: "2.5rem"}} onClick={()=>{
 
 if(faqclass1=='faq'){ setfaqclass1('faq active'); }else { setfaqclass1('faq'); }
} 
}/>
        </button>
      </div>
    
      <div class={faqclass2}>
        <h3 class="faq-title">
          How do I create a social media marketing strategy?
  
        </h3>
        <p class="faq-text">Ans. To create a social media marketing strategy, you should define your goals, target audience, messaging, content plan, and metrics for success. It's also important to research your competition and stay up-to-date with social media trends.
        </p>
        <button class="faq-toggle">  
          <img src="https://cdn-icons-png.flaticon.com/128/10009/10009582.png" style={{width: "2.5rem",height: "2.5rem"}} onClick={()=>{
 
 if(faqclass2=='faq'){ setfaqclass2('faq active'); }else { setfaqclass2('faq'); }
} 
} />
        </button>
      </div>
    
      <div class={faqclass3}>
        <h3 class="faq-title">
          What types of content should I share on social media?
  
        </h3>
        <p class="faq-text">Ans. The type of content you should share on social media depends on your audience and goals. Some common types of content include images, videos, blog posts, infographics, and user-generated content.
        </p>
        <button class="faq-toggle">
         <img src="https://cdn-icons-png.flaticon.com/128/10009/10009582.png" style={{width: "2.5rem",height: "2.5rem"}} onClick={()=>{
 
 if(faqclass3=='faq'){ setfaqclass3('faq active'); }else { setfaqclass3('faq'); }
} 
} />
        </button>
      </div>
    
      <div class={faqclass4}>
        <h3 class="faq-title">
          How much should I spend on social media advertising?
  
        </h3>
        <p class="faq-text">Ans. The amount you should spend on social media advertising depends on your budget and advertising goals. You can start with a small budget and gradually increase it based on your performance and results.
        </p>
        <button class="faq-toggle">
        <img src="https://cdn-icons-png.flaticon.com/128/10009/10009582.png" style={{width: "2.5rem",height: "2.5rem"}} onClick={()=>{
 
 if(faqclass4=='faq'){ setfaqclass4('faq active'); }else { setfaqclass4('faq'); }
} 
} />
        </button>
      </div>
    
      <div class={faqclass5}>
        <h3 class="faq-title">
          How can your company help me engage with my followers on social media?
  
        </h3>
        <p class="faq-text">Ans. We can help you engage with your followers on social media by responding to comments and messages, hosting live Q&A sessions, running social media contests, and sharing user-generated content.
        </p>
        <button class="faq-toggle">
        <img src="https://cdn-icons-png.flaticon.com/128/10009/10009582.png" style={{width: "2.5rem",height: "2.5rem"}} onClick={()=>{
 
 if(faqclass5=='faq'){ setfaqclass5('faq active'); }else { setfaqclass5('faq'); }
} 
}/>
        </button>
      </div>
      <div class={faqclass6}>
        <h3 class="faq-title">
          Which social media channels do you recommend for our business and why?
  
  
        </h3>
        <p class="faq-text">Ans. Based on your business goals and target audience, We recommend using Instagram, Facebook, and LinkedIn as they have a large user base and offer different ways to engage with your audience through visual content, community building, and professional networking.
        </p>
        <button class="faq-toggle">
        <img src="https://cdn-icons-png.flaticon.com/128/10009/10009582.png" style={{width: "2.5rem",height: "2.5rem"}} onClick={()=>{
 
 if(faqclass6=='faq'){ setfaqclass6('faq active'); }else { setfaqclass6('faq'); }
} 
}

/>
        </button>
      </div>
      <div class={faqclass7} id="txt-a">
        <h3 class="faq-title">
          What goals should we set for each of our social media accounts, and what does success look like?
  
        </h3>
        <p className="faq-text">Ans. For Instagram, success may be measured by engagement rates and follower growth; for Facebook, it may be driving website traffic and increasing brand awareness; for LinkedIn, it may be generating leads and building professional relationships. Set specific goals and track metrics to measure success.
        </p>
        <button id="btn-a" className="faq-toggle">
       <img src="https://cdn-icons-png.flaticon.com/128/10009/10009582.png" style={{width: "2.5rem",height: "2.5rem"}} onClick={()=>{
 
         if(faqclass7=='faq'){ setfaqclass7('faq active'); }else { setfaqclass7('faq'); }
       } 
    }/>
        </button>
      </div>
      </div>
  </section>
    
</main>
</div>
{/* FREQUENTLY ASKED QUESTIONS SECTION END */}


</div>
{/* DIVERGION BLOCK RIGHT SECTION END */}
</div>
{/* DIVERGION BLOCK END */}
<Footer></Footer>
</div>

    {/* Optional JavaScript */}
    {/* jQuery first, then Popper.js, then Bootstrap JS */}
    <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js" integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo" crossorigin="anonymous"></script>
    <script src="https://cdn.jsdelivr.net/npm/popper.js@1.14.7/dist/umd/popper.min.js" integrity="sha384-UO2eT0CpHqdSJQ6hJty5KVphtPhzWj9WO1clHTMGa3JDZwrnQq4sF86dIHNDz0W1" crossorigin="anonymous"></script>
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/js/bootstrap.min.js" integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM" crossorigin="anonymous"></script>
  
  
  </body>
</html>




 
</>
);

}

export default Intpg;