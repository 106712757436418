import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import Navbar from './navbar';



//import '../hireportalcss.css';
//import '../Hireportalcss/hireportalcss.css';

//Importing Page css with screen sizes
import '../Hireportalcss/sdefault.css';
import '../Hireportalcss/s1200.css';
import '../Hireportalcss/s920.css';
import '../Hireportalcss/s768.css';
import '../Hireportalcss/s600.css';
import '../Hireportalcss/sanimation.css';



function Employercreatejob(){

    return(

    <>
        <div class="default-view">
        <Navbar/>
        {/* MAIN SECTION START */}
        <div class="hire-main-section">

        {/* FORM CONTAINER START */}
        <div class="hire-form-container">
        
        {/* CREATE JOB FORM START */}
            <div class="hire-form-panel" style={{backgroundColor:"#ffffff"}}>
            <h3>Create New Job</h3>

            <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Enter Job Role</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Full Stack Developer" /></div>
            </div>
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Company/ Business</div>
            <div class="hire-form-group-right"><select class="hire-form-group-input" placeholder="Select Company">
            <option value="">Select Company</option>
            <option value="">ABC Hiring Pvt. Ltd.</option>
            </select>    
            </div>
            </div>
            </div>


            <div class="hire-form-list">
            <div class="hire-form-group-large">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Enter Job Summary</div>
            <div class="hire-form-group-right"><textarea size="3" class="hire-form-group-input" placeholder="For e.g. : A full stack developer well versed with Reactjs and Laravel. Having working knowledge of Apache an Mysqql. 3+ Years experience working in real time projects."></textarea></div>
            </div>
            </div>

            <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Job Type</div>
            <div class="hire-form-group-right"><select class="hire-form-group-input"  placeholder="Select Job Type">
            <option value="">Select Job Type</option>
            <option value="Full Time">Full Time</option>
            <option value="Full Time">Part Time</option>
            <option value="Full Time">Contract</option>
            <option value="Full Time">Internship</option>
            </select>
            </div>
            </div>
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Job Shift</div>
            <div class="hire-form-group-right"><select class="hire-form-group-input"  placeholder="Select Job Type">
            <option value="">Select Shift</option>
            <option value="">Day</option>
            <option value="Full Time">Night</option>
            <option value="Full Time">Morning</option>
            </select>
            </div>
            </div>
            </div>

            <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Duration</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : 6" /></div>
            </div>
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Period</div>
            <div class="hire-form-group-right"><select class="hire-form-group-input"  placeholder="Select Period">
            <option value="">Select Period</option>
            <option value="">Days</option>
            <option value="Full Time">Months</option>
            <option value="Full Time">Years</option>
            </select></div>
            </div>
            </div>


            <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Country</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : India" /></div>
            </div>
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>State</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Madhya Pradesh" /></div>
            </div>
            </div>

            <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>City</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Jabalpur" /></div>
            </div>
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Pincode</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : 482001" /></div>
            </div>
            </div>

            <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Openings</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : 50" /></div>
            </div>
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Industry</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : IT/ Tech" /></div>
            </div>
            </div>

            <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Category</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Web Development" /></div>
            </div>
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Sector</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Full Stack" /></div>
            </div>
            </div>

            <div class="hire-form-list">
            <div class="hire-form-group-large">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Job Description</div>
            <div class="hire-form-group-right"><textarea size="5" class="hire-form-group-input" placeholder="For e.g. : 
Job Title: Full Stack Developer

Job Description:

We are looking for a talented and experienced Full Stack Developer to join our team. As a Full Stack Developer, you will be responsible for the full lifecycle of software development, from design and implementation to deployment and maintenance. You will work on both the front-end and back-end of our applications, and you will be responsible for ensuring that they are scalable, reliable, and secure.

Responsibilities:

Design, develop, and maintain web applications
Develop and manage databases
Write and maintain APIs
Work with front-end developers to create user-friendly interfaces
Work with back-end developers to build and maintain scalable and reliable servers
Troubleshoot and debug code
Deploy and maintain applications to production environments
Stay up-to-date on the latest technologies and trends
Qualifications:

Bachelor's degree in Computer Science or a related field
3+ years of experience in full stack web development
Strong knowledge of front-end and back-end technologies, including HTML, CSS, JavaScript, Node.js, React, and SQL
Experience with cloud computing platforms such as AWS or Azure
Experience with continuous integration and continuous delivery (CI/CD) pipelines
Excellent problem-solving and debugging skills
Ability to work independently and as part of a team
Benefits:

Competitive salary and benefits package
Opportunity to work on cutting-edge technologies
Collaborative and supportive work environment
Opportunities for professional development and growth
If you are a talented and experienced Full Stack Developer who is looking to join a fast-paced and growing company, we encourage you to apply."></textarea></div>
            </div>
            </div>



            <div class="hire-form-list">
            <div class="hire-form-group-large">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Who Can Apply</div>
            <div class="hire-form-group-right"><textarea size="5" class="hire-form-group-input" placeholder="For e.g. : 1. Candidates with a BMM, BMS, B.Com, or BBA

2. Minimum of 6 months of experience in digital marketing, preferably in a similar role

3. In-depth knowledge of various digital marketing channels, tools, and techniques

4. Familiarity with web analytics tools, such as Google Analytics (to track campaign performance and derive insights), Canva

5. Strong understanding of SEO principles and best practices to drive organic search traffic

6. Proficiency in social media platforms, content management systems (CMS), and email marketing tools

7. Excellent written and verbal communication skills to create compelling content and interact with internal and external stakeholders

8. Analytical mindset with the ability to interpret data, identify trends, and make data-driven decisions

9. Creativity and attention to detail to develop engaging digital content and campaigns" ></textarea>
            </div>
            </div>
            </div>



            <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Joining</div>
            <div class="hire-form-group-right"><select class="hire-form-group-input" placeholder="For e.g. : 50000">
            <option>Immediate</option>
            <option>1-7 Days</option>
            <option>7-15 Days</option>
            <option>15-30 Days</option>
            <option>1-3 Months</option>
            <option>3-6 Months</option>
            <option>6-12 Months</option>
            <option>More than 1 Year</option>
            </select>
            </div>
            </div>
            <div class="hire-form-group-small">
          {/*}  <div class="hire-form-group-left"> */}<label class="hire-form-group-label"></label>Disclose Salary/ CTC per month{/*</div>
            <div class="hire-form-group-right"> */}
            <input type="checkbox" style={{marginLeft:"2rem"}} /><label style={{marginRight:"1rem",marginLeft:"2rem",padding:"1rem"}}>Salary</label>
            <input type="checkbox" style={{marginLeft:"2rem"}} /><label style={{marginRight:"1rem",marginLeft:"2rem",padding:"1rem"}}>CTC</label></div>
          {/*}  </div> */}
            </div>


            <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Minimum Range</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : 50000" /></div>
            </div>
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Maximum Range</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : IT/ Tech" /></div>
            </div>
            </div>

            <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Add Skills</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : HTML" /></div>
            </div>
         
            </div>
            <div class="hire-list-card-skillbar">
        <div class="hire-card-skill-badge">
        <span>MySql</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>Web Development</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>React Js</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>Laravel</span>
        </div>  
        <div class="hire-card-skill-badge">
        <span>HTML/CSS</span>
        </div>  
       
        </div>

            <div class="hire-form-list">
            
            <label class="hire-form-group-label" style={{marginLeft:"4rem"}}>Select Perks</label>
 
            </div>

            <div class="hire-form-select-list">
            <div class="hire-form-list-selections" style={{marginLeft:"4rem"}}>
              <input type="checkbox" style={{marginLeft:"1rem"}} /><label style={{marginRight:"1rem"}}>Flexibe Working</label>
              <input type="checkbox" /><label style={{marginRight:"1rem"}}>Remote Working</label>
              <input type="checkbox" /><label style={{marginRight:"1rem"}}>Letter of Recommendation</label>
              <input type="checkbox" /><label style={{marginRight:"1rem"}}>Paid Leaves</label>
            </div>
            </div>
            


            <div class="hire-form-btn">
            <button class="hire-btn-default" style={{backgroundColor:"#12d571",marginRight:"2rem",marginTop:"2rem",marginBottom:"2rem"}}
            onClick={()=>{ window.location.replace("employer-create-application-questions"); }}
            >Create New Job</button>

            </div>
        </div>
        {/* CREATE JOB FORM END */}


        </div>
        {/* FORM CONTAINER END */}

        </div>
        {/* MAIN SECTION END */}

        </div>

        </>
    );




}


export default Employercreatejob;