import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import moment from 'moment/moment';


import '../Resources/dashboard.css';
import axios from '../../axios.js';
import Navbar from '../navbar';
import Sidebar from '../Sidebar';
import Create_asset from './create_asset';
import LineChart from '../components/Linechart.js';
import BarChart from '../components/Barchart.js';


import Chart from "chart.js/auto";
import { Line, Bar } from "react-chartjs-2";

import {useRef as useref} from 'react';
import {useReactToPrint} from 'react-to-print';

//EXCEL DOWNLOAD IMPORT
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

function Userassets_history(){

  

 //Report Print Function
 const componentRef = useref();
 const handlePrint = useReactToPrint({
   content: () => componentRef.current,
 });
  
 
 // Function to download the data as an Excel file
 const downloadExcel = () => {
  // Convert JSON data to Excel worksheet
  const worksheet = XLSX.utils.json_to_sheet(data);
  
  // Create a new workbook and add the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  
  // Convert the workbook to a binary array
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  
  // Create a Blob from the binary data and trigger a download
  const excel_data = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(excel_data, "assets_history.xlsx");
};


 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const busyid=sessionStorage.getItem("BusyId00");
 const counterid=sessionStorage.getItem("CounterId00");
 const asset=sessionStorage.getItem("Asset00");

 /* PAGE VARIABLES */


 
{/* TRANSACTIONS TABLE COLUMNS MANAGEMENT VARIABLES START */}
const[cd_col1_val,setcd_col1_val] = useState(0);//column1
const[cd_col2_val,setcd_col2_val] = useState(0);//column2
const[cd_col3_val,setcd_col3_val] = useState(0);//column3
const[cd_col4_val,setcd_col4_val] = useState(0);//column4
const[cd_col5_val,setcd_col5_val] = useState(0);//column5
const[cd_col6_val,setcd_col6_val] = useState(0);//column6
const[cd_col7_val,setcd_col7_val] = useState(0);//column7
const[cd_col8_val,setcd_col8_val] = useState(0);//column8
const[cd_col9_val,setcd_col9_val] = useState(0);//column9
const[cd_col10_val,setcd_col10_val] = useState(0);//column10
const[cd_col11_val,setcd_col11_val] = useState(0);//column11
const[cd_col12_val,setcd_col12_val] = useState(0);//column12
const[cd_col13_val,setcd_col13_val] = useState(0);//column13
const[cd_col14_val,setcd_col14_val] = useState(0);//column14 

{/* TRANSACTIONS TABLE COLUMNS MANAGEMENT VARIABLES END */}



 //Checking if Sessions are Set

// alert(logstatus,loguser,logadminid);
 //Sessions Not Set
 {/*if(((logstatus==null)||(logstatus==""))||((logid==null)||(logid==""))||((token==null)||(token==" "))||((busyid==null)||(busyid==" "))||((counterid==null)||(counterid==" "))){
  
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");
   
 
    window.location.replace("/businesstools-login");
   }
  
   //Sessions Set
   else{

   */}
  

 
 
//Variables
const[data,setData] = useState([]);//SetData Variable
const[Tbanks,setTbanks] = useState([]);//SetData Variable

//Error Variable
const[errorvar,seterrorvar] = useState();
    
 

//FILTER VARIABLES
const[filter_val,setfilter_val] = useState(0);
const[filter_start_date,setfilter_start_date] = useState('1970-01-01'); const updatefilter_start_date = (event) => { setfilter_start_date(event.target.value);}; const[filter_start_date_er,setfilter_start_date_er]=useState();
const[filter_end_date,setfilter_end_date] = useState('2100-12-12'); const updatefilter_end_date = (event) => { setfilter_end_date(event.target.value);}; const[filter_end_date_er,setfilter_end_date_er]=useState();
const[filter_trno,setfilter_trno] = useState(); const updatefilter_trno = (event) => { setfilter_trno(event.target.value);}; const[filter_trno_er,setfilter_trno_er]=useState();
const[filter_transaction,setfilter_transaction] = useState(); const updatefilter_transaction = (event) => { setfilter_transaction(event.target.value);}; const[filter_transaction_er,setfilter_transaction_er]=useState();
const[filter_amount_in_sign,setfilter_amount_in_sign] = useState(); const updatefilter_amount_in_sign = (event) => { setfilter_amount_in_sign(event.target.value);}; const[filter_amount_in_sign_er,setfilter_amount_in_sign_er]=useState();
const[filter_amount_in,setfilter_amount_in] = useState(); const updatefilter_amount_in = (event) => { setfilter_amount_in(event.target.value);}; const[filter_amount_in_er,setfilter_amount_in_er]=useState();
const[filter_amount_out_sign,setfilter_amount_out_sign] = useState(); const updatefilter_amount_out_sign = (event) => { setfilter_amount_out_sign(event.target.value);}; const[filter_amount_out_sign_er,setfilter_amount_out_sign_er]=useState();
const[filter_amount_out,setfilter_amount_out] = useState(); const updatefilter_amount_out = (event) => { setfilter_amount_out(event.target.value);}; const[filter_amount_out_er,setfilter_amount_out_er]=useState();
const[filter_part_name,setfilter_part_name] = useState(); const updatefilter_part_name = (event) => { setfilter_part_name(event.target.value);}; const[filter_part_name_er,setfilter_part_name_er]=useState();


//Variables for pagination
const [currentPage, setCurrentPage] = useState(1);
const [pageNumber, setpageNumber] = useState(1);
    
useEffect(()=>{
   
  getData();
  
  },[])

  

//changemode function
function changemode(){
    const mmode = document.getElementById("type").value;
    if((mmode=="sales")||(mmode=="purchase")){

        document.getElementById("mode-row").style.display="block";

    }
    else{
        document.getElementById("mode-row").style.display="none";
    }
}



//******************* GET CASHBOOK DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((asset!=null)||(asset!=" "))){
 
    //Fetching Filter Variables
    const $filter_start_date = filter_start_date;const $filter_end_date = filter_end_date;const $filter_trno = filter_trno;const $filter_transaction = filter_transaction;
    const $filter_amount_in_sign = filter_amount_in_sign;const $filter_amount_in = filter_amount_in;const $filter_amount_out_sign = filter_amount_out_sign;const $filter_amount_out = filter_amount_out;
    const $filter_part_name = filter_part_name;

 axios.post("bt-get-assets-history", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Sn_start :0,
   Sn_end :0,
   Sn_total :0,
   Sn_limit :0,
   Asset_name : asset,
   //Filters
   Filter_val : filter_val,
   Filter_start_date : moment($filter_start_date).format('YYYY-MM-DD'),
   Filter_end_date : moment($filter_end_date).format('YYYY-MM-DD'),
   Filter_trno : $filter_trno,
   Filter_transaction : $filter_transaction,
   Filter_amount_in_sign : $filter_amount_in_sign,
   Filter_amount_in : $filter_amount_in,
   Filter_amount_out_sign : $filter_amount_out_sign,
   Filter_amount_out : $filter_amount_out,
   Filter_part_name : $filter_part_name,

  
//Code Edits for pagination
'page' : pageNumber




 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
     return;
  }
  //Success Data Fetched
  if(response.data.successvar==1){

   

   //Setting Data
   setData(response.data.data.data);
   setTbanks(response.data.bank_data);
 

  }
   //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){
    setfilter_start_date_er(response.data.validatorerror.Filter_start_date);
    setfilter_end_date_er(response.data.validatorerror.Filter_end_date);
    setfilter_trno_er(response.data.validatorerror.Filter_trno);
    setfilter_transaction_er(response.data.validatorerror.Filter_transaction);
    setfilter_amount_in_sign_er(response.data.validatorerror.Filter_amount_in_sign);
    setfilter_amount_in_er(response.data.validatorerror.Filter_amount_in);
    setfilter_amount_out_sign_er(response.data.validatorerror.Filter_amount_out_sign);
    setfilter_amount_out_er(response.data.validatorerror.Filter_amount_out);
    setfilter_part_name_er(response.data.validatorerror.Filter_part_name);

  }

  //Data Violation
  if(response.data.error==102){ 
  
    setfilter_start_date_er(response.data.filter_start_date_er);
    setfilter_end_date_er(response.data.filter_end_date_er);
    setfilter_trno_er(response.data.filter_trno_er);
    setfilter_transaction_er(response.data.filter_transaction_er);
    setfilter_amount_in_sign_er(response.data.filter_amount_in_sign_er);
    setfilter_amount_in_er(response.data.filter_amount_in_er);
    setfilter_amount_out_er(response.data.filter_amount_out_er);
    setfilter_amount_out_sign_er(response.data.filter_amount_out_sign_er);
    setfilter_part_name_er(response.data.validatorerror.Filter_part_name);

  }
  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("CounterId00");
   sessionStorage.removeItem("Asset00");
   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* GET CASHBOOK DATA FUNCTION END  ********************//


 /* PAGINATION */
 /*const handlePageChange = (pageNumber) => {
  setCurrentPage(pageNumber);
};

const renderPaginationButtons = () => {
  const { current_page, last_page } = cashbookEntries.meta;
  const previousButtonDisabled = current_page === 1;
  const nextButtonDisabled = current_page === last_page;
}
*/

    

//******************* GET CASHBOOK DATA BY ID FUNCTION START  ********************//


//edate
const[edate,setedate]=useState();const updateedate = (event) => { setedate(event.target.value);}; const[edate_er,setedate_er]=useState();
//etype
const[etype,setetype]=useState();const updateetype = (event) => { setetype(event.target.value);}; const[etype_er,setetype_er]=useState();
//eparticular
const[eparticular,seteparticular]=useState();const updateeparticular = (event) => { seteparticular(event.target.value);}; const[eparticular_er,seteparticular_er]=useState();
//eamount
const[eamount,seteamount]=useState();const updateeamount = (event) => { seteamount(event.target.value);}; const[eamount_er,seteamount_er]=useState();

//date
const[date,setdate]=useState();const updatedate = (event) => { setdate(event.target.value);}; const[date_er,setdate_er]=useState();
//type
const[type,settype]=useState();const updatetype = (event) => { settype(event.target.value); }; const[type_er,settype_er]=useState();
//asset_name
const[asset_name,setasset_name]=useState();const updateasset_name = (event) => { setasset_name(event.target.value);}; const[asset_name_er,setasset_name_er]=useState();
//part_name
const[part_name,setpart_name]=useState();const updatepart_name = (event) => { setpart_name(event.target.value);}; const[part_name_er,setpart_name_er]=useState();
//mode
const[mode,setmode]=useState();const updatemode = (event) => { setmode(event.target.value);}; const[mode_er,setmode_er]=useState();
//bank_id
const[bank_id,setbank_id]=useState();const updatebank_id = (event) => { setbank_id(event.target.value);}; const[bank_id_er,setbank_id_er]=useState();
//particular
const[particular,setparticular]=useState();const updateparticular = (event) => { setparticular(event.target.value);}; const[particular_er,setparticular_er]=useState();
//value
const[value,setvalue]=useState();const updatevalue = (event) => { setvalue(event.target.value);}; const[value_er,setvalue_er]=useState();
//other_additions
const[other_additions,setother_additions]=useState();const updateother_additions = (event) => { setother_additions(event.target.value);}; const[other_additions_er,setother_additions_er]=useState();
//description
const[description,setdescription]=useState();const updatedescription = (event) => { setdescription(event.target.value);}; const[description_er,setdescription_er]=useState();


//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();



 //******************* EDIT ASSET FUNCTION START ********************//
function editasset(){

  //Setting Error Variables
  setdate_er(" ");settype_er(" ");setdescription_er(" ");seteparticular_er(" ");setasset_name_er(" ");setpart_name_er(" ");setmode_er(" ");setbank_id_er(" ");setvalue_er(" ");setother_additions_er(" ");seterror_msg(" ");   
                   
  const EditId = sessionStorage.getItem("EditId00");
  const trno = sessionStorage.getItem("Trno00");

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))&&((EditId!=null)||(EditId!=" "))&&((trno!=null)||(trno!=" "))){
 
 
  axios.post("bt-assets-edit", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Edit_id:EditId,
   Tr_no:trno,
   Date : moment(date).format('YYYY-MM-DD'),
   Type:type,
   Particular : particular,
   Asset_name : asset_name,
   Part_name : part_name,
   Description : description,
   Mode : mode,
   Bank_id : bank_id,
   Value : value,
   Other_additions : other_additions,
 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");
     sessionStorage.removeItem("Trno00");
     sessionStorage.removeItem("EditId00");

     window.location.replace("businesstools-login");
  }

   //Validation Errors
 if(response.data.validatorerror){
    setdate_er(response.data.validatorerror.Date);
    settype_er(response.data.validatorerror.Type);
    setparticular_er(response.data.validatorerror.Particular);
    setasset_name_er(response.data.validatorerror.Asset_name);
    setpart_name_er(response.data.validatorerror.Part_name);
    setdescription_er(response.data.validatorerror.Description);
    setmode_er(response.data.validatorerror.Mode);
    setbank_id_er(response.data.validatorerror.Bank_id);
    setvalue_er(response.data.validatorerror.Value);
    setother_additions_er(response.data.validatorerror.Other_additions);


}
//Invalid Entries
if(response.data.error==102){ 
  
    setdate_er(response.data.date_er);
    settype_er(response.data.type_er);
    setparticular_er(response.data.particular_er);
    setasset_name_er(response.data.asset_name_er);
    setpart_name_er(response.data.part_name_er);
    setdescription_er(response.data.description_er);
    setmode_er(response.data.mode_er);
    setbank_id_er(response.data.bank_id_er);
    setvalue_er(response.data.value_er);
    setother_additions_er(response.data.other_additions_er);
 
  seterror_msg("Cannot Update Transaction!");  }


  //Invalid Entries
  if(response.data.error==103){     seterror_msg(response.data.error_msg);  }

   //Transaction Updated Successfully
   if(response.data.successvar==1){        
     //Setting Variables to empty 
     setdate_er(" ");settype_er(" ");setdescription_er(" ");seteparticular_er(" ");setasset_name_er(" ");setpart_name_er(" ");setmode_er(" ");setbank_id_er(" ");setvalue_er(" ");setother_additions_er(" ");seterror_msg(" ");   
     setdate(" ");settype(" ");setdescription(" ");seteparticular(" ");setasset_name(" ");setpart_name(" ");setmode(" ");setbank_id(" ");setvalue(" ");setother_additions(" ");

    document.getElementById("edit-success-card").style.display="block"; 
    document.getElementById("edit-form").style.display="none"; 
  
  }

   //Cannot Update Transaction
   else{  seterror_msg("Cannot Update Transaction !"); }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");
   sessionStorage.removeItem("Trno00");
   sessionStorage.removeItem("EditId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* EDIT ASSET FUNCTION END ********************//


 
   
//******************* DELETE ASSET FUNCTION START ********************//
function deleteasset(){

  const EditId=sessionStorage.getItem("DEditId00");
  const dtrno=sessionStorage.getItem("DTrno00");

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))&&((EditId!=null)||(EditId!=" "))&&((dtrno!=null)||(dtrno!=" "))){
 
 axios.post("bt-assets-delete", {
   Busy_id:busyid,
   Counter_id:counterid,
   Log_id:logid,
   Token:token,
   Edit_id:EditId,
   Tr_no:dtrno
 })
 .then((response) => {


  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");
     sessionStorage.removeItem("DEditId00");
     sessionStorage.removeItem("DTrno00");

     window.location.replace("businesstools-login");
  }

  //Counter Deleted Successfully
   if(response.data.successvar==1){        

      document.getElementById("delete-card").style.display="none";
      document.getElementById("delete-success-card").style.display="block";  
      sessionStorage.removeItem("DEditId");
      sessionStorage.removeItem("DTrno00");

  }
  
      //Cannot Delete Counter
    else{  
    document.getElementById("delete-card").style.display="none";
    document.getElementById("delete-error-card").style.display="block";   }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");
   sessionStorage.removeItem("DEditId00");
   sessionStorage.removeItem("DTrno00");


   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* DELETE ASSET FUNCTION END ********************//



return(
    <>
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Business Tools|BookKeeping Solution</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">

      <Sidebar />
    <div className="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div className="main-panel">

     
                 {/* EDIT SUCCESS CARD START */}
        <div className="d-flex justify-content-center">
          <div className="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="edit-success-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title  text-success">Transaction Updated Successfully !!!</h4>
                  
                    <blockquote className="blockquote">
                      <p className="mb-0"> Click the following button to close.</p>
                      <button className="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("edit-success-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* EDIT SUCCESS CARD END */}
               {/* EDIT ERROR CARD START */}
        <div className="d-flex justify-content-center">
          <div className="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="edit-error-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title  text-danger">Cannot Fetch Data !!!</h4>
                  
                    <blockquote className="blockquote">
                      <p className="mb-0"> Click the following button to close.</p>
                      <button className="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("edit-error-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* EDIT ERROR CARD END */}
               {/* DELETE CARD START */}
        <div className="d-flex justify-content-center">
          <div className="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title  text-danger">Are you sure you want to delete this transaction?</h4>
                  
                    <blockquote className="blockquote">
                      <p className="mb-0"> This action cannot be reversed. You will lose all your data related to this transaction if you wish to continue this action.</p>
                      <button className="mt-3 btn btn-outline-success" onClick={deleteasset}>Yes, I Know! Delete Transaction.</button>
                      <button className="mt-3 btn btn-outline-danger" style={{marginLeft:"1rem"}} onClick={()=>{  
                      //Cancel Delete and remove Counter_id
                      sessionStorage.removeItem("DEditId00");     sessionStorage.removeItem("DTrno00");  document.getElementById('delete-card').style.display = 'none'; }}>No, Don't Delete Transaction.</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* DELETE CARD END */}
              {/* DELETE SUCCESS CARD START */}
        <div className="d-flex justify-content-center">
          <div className="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-success-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title  text-success">Transaction Deleted Successfully !!!</h4>
                  
                    <blockquote className="blockquote">
                      <p className="mb-0"> Click the following button to close.</p>
                      <button className="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("delete-success-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* DELETE SUCCESS CARD END */}
               {/*  DELETE ERROR CARD START */}
        <div className="d-flex justify-content-center">
          <div className="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-error-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title  text-danger">Cannot Delete Transaction !!!</h4>
                  
                    <blockquote className="blockquote">
                      <p className="mb-0"> Click the following button to close.</p>
                      <button className="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("delete-error-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* DELETE ERROR CARD END */}

          <div className="content-wrapper pb-0">

          <div className="row">
              <div className="col-12 grid-margin create-form" id="create-form">
              <Create_asset /> 
              </div>
          </div>


            <div className="page-header flex-wrap">
            <div className="header-left"></div>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <a href="#">
                    <p className="m-0 pe-3">ASSETS</p>
                  </a>
                  <a className="ps-3 me-4" href="#">
                  {/*}  <p className="m-0">ADE-00234</p> */}
                  </a>
                </div>
            {/*}    <button type="button" className="btn btn-primary mt-2 mt-sm-0 btn-icon-text"  onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
                  <i className="mdi mdi-plus-circle"></i> Manage Asset </button> */}
              </div>
            </div>


               {/* ASSET TRANSACTION EDIT FORM START */}
               <div className="row">
              <div className="col-12 grid-margin create-form" id="edit-form" style={{display:"none"}}>
              <div className="card">
                <div className="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" className="btn btn-social-icon" onClick={()=>{ 
                        //close edit form button
                        document.getElementById("edit-form").style.display="none";
                        //Setting Variables to empty
                        setdate_er(" ");settype_er(" ");setdescription_er(" ");seteparticular_er(" ");setasset_name_er(" ");setpart_name_er(" ");setmode_er(" ");setbank_id_er(" ");setvalue_er(" ");setother_additions_er(" ");seterror_msg(" ");   
                        setdate(" ");settype(" ");setdescription(" ");seteparticular(" ");setasset_name(" ");setpart_name(" ");setmode(" ");setbank_id(" ");setvalue(" ");setother_additions(" ");
               
                        sessionStorage.removeItem("EditId00");
                        sessionStorage.removeItem("Trno00");
                            }}>
                      <i className="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                  <div className="card-body" style={{textAlign:"left"}}>
                    <h4 className="card-title">Asset Transaction</h4>
                    <div className="form-sample">
                      <p className="card-description">edit your asset transactions here</p>
                      <code>{error_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control"  id="date" value={date} onChange={updatedate} />
                              <code>{date_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type<code>*</code></label>
                            <div class="col-sm-9">
                              <select class="form-control" placeholder="Select Transaction" style={{padding:"1rem"}} id="type" value={type} onChange={updatetype}>
                              <option value="">Select Type</option>
                              <option value="entry">Entry</option>
                              <option value="drawings">Drawings</option>
                              <option value="sales">Sales</option>
                              <option value="purchase">Purchase</option>
                              <option value="appreciation">Appreciation</option>
                              <option value="depreciation">Depreciation</option>
                              </select>
                              <code>{type_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Asset Name<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Machinery"  id="asset_name" value={asset_name} onChange={updateasset_name} />
                              <code>{asset_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Part Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Machinery Part" id="part_name" value={part_name} onChange={updatepart_name} />
                              <code>{part_name_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row" id="mode-row" hidden={((type=="entry")||(type=="drawings")||(type=="appreciation")||(type=="depreciation"))}>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Mode<code>*</code></label>
                            <div class="col-sm-9">
                              <select class="form-control" placeholder="Mode" style={{padding:"1rem"}} id="mode" value={mode} onChange={updatemode}>
                              <option value="">Select Mode</option>
                              <option value="cash">Cash</option>
                              <option value="bank">Bank</option>
                              </select>
                              <code>{mode_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Select Bank</label>
                            <div class="col-sm-9">
                              <select class="form-control" placeholder="Select Bank" style={{padding:"1rem"}} id="bank_id" value={bank_id} onChange={updatebank_id}>
                              <option value="">Select Bank</option>
                              {
                              Tbanks.map((curElm2,ind)=>{

                              return(
                              <>
                              <option value={curElm2.Bank_id}>{curElm2.Bank_name}</option>
                              </>
                              )
                              }
	 				   
	                         		)
                              }
                              </select>
                              <code>{bank_id_er}</code>
                            </div>
                          </div>
                        </div>     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Value<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="50000.00" id="value" value={value} onChange={updatevalue} />
                              <code>{value_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Other Additions<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="5000.00" id="other_additions" value={other_additions} onChange={updateother_additions} />
                              <code>{other_additions_er}</code>
                            </div>
                          </div>
                        </div>
                        </div>
                        <div class="form-group">
                        <label for="exampleTextarea1">Description</label>
                        <textarea  class="form-control"  rows="2" id="description" value={description} onChange={updatedescription}></textarea>
                        <code>{description_er}</code>
                      </div>
                      <div class="form-group">
                        <label for="exampleTextarea1">Particular<code>*</code></label>
                        <textarea  class="form-control"  rows="2" id="particular" value={particular} onChange={updateparticular}></textarea>
                        <code>{particular_er}</code>
                      </div>
                      <div class="row">
                        <div className="col-md-6">
                        <button className="btn btn-primary" style={{fontWeight:"bold"}} onClick={editasset}>Submit</button>
                        </div>
                       
                      </div>
                        
                       
    
                     
                   
                    </div>
                  </div>
                </div>
              </div>
          </div>
          {/* ASSET TRANSACTION EDIT FORM END */}

              {/* ASSET TRANSACTIONS TABLE SECTION START */}
                  
             <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Asset History</h4>
                    <p className="card-description">manage your asset's history from here
                    </p>

                           {/* FILTER SECTION START */}
                           <hr/>
                            <div id="filter-top-card" class="row col-12" style={{textAlign:"left",display:"none"}}>
                            <div class="d-flex justify-content-between">
                            <div>
                            <h4 style={{padding:'1rem'}}>Filters</h4>
                           </div>
                           <div>
                    
                           <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("filter-top-card").style.display="none"; }}>
                           <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                           </button>
                           </div>
                           </div>
        
                        <div class="row">
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Start Date</label>
                            <div class="col-sm-8">
                              <input type="date" class="form-control" id="filter_start_date" value={filter_start_date} onChange={updatefilter_start_date}  />
                              <code>{filter_start_date_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">End Date</label>
                            <div class="col-sm-8">
                              <input type="date" class="form-control"  id="filter_end_date" value={filter_end_date} onChange={updatefilter_end_date}  />
                              <code>{filter_end_date_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Tr No.</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" placeholder="12345"   id="filter_trno" value={filter_trno} onChange={updatefilter_trno}  />
                              <code>{filter_trno_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-8">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Value In</label>
                            <div class="col-sm-4">
                              <select class="form-control" style={{padding:"1rem"}} id="filter_amount_in_sign" value={filter_amount_in_sign} onChange={updatefilter_amount_in_sign}  >
                              <option className="dropdown-item" value=" ">All</option>
                              <option className="dropdown-item" value="equals">equals</option>
                              <option className="dropdown-item" value="more than" >more than</option>
                              <option className="dropdown-item" value="less than">less than</option>
                              <option className="dropdown-item" value="more than or equals" >more than or equals</option>
                              <option className="dropdown-item" value="less than or equals" >less than or equals</option>
                              </select>
                              <code>{filter_amount_in_sign_er}</code>
                            </div>
                            <div class="col-sm-4">
                              <input type="text" class="form-control" placeholder="5000.00" id="filter_amount_in" value={filter_amount_in} onChange={updatefilter_amount_in}   />
                              <code>{filter_amount_in_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Type</label>
                            <div class="col-sm-8">
                             <select class="form-control" style={{padding:"1rem"}} id="filter_transaction" value={filter_transaction} onChange={updatefilter_transaction} >
                             <option value=" ">Select Transaction</option>
                             <option value="entry">entry</option>
                             <option value="drawings">drawings</option>
                             <option value="appreciation">appreciation</option>
                             <option value="depreciation">depreciation</option>
                             <option value="sales">sales</option>
                             <option value="purchase">purchase</option>
                             </select>                              
                             <code>{filter_transaction_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                    
                      <div class="row">
                      <div class="col-md-8">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Value Out</label>
                            <div class="col-sm-4">
                              <select class="form-control" style={{padding:"1rem"}} id="filter_amount_out_sign" value={filter_amount_out_sign} onChange={updatefilter_amount_out_sign} >
                              <option className="dropdown-item" value=" ">All</option>
                              <option className="dropdown-item" value="equals">equals</option>
                              <option className="dropdown-item" value="more than" >more than</option>
                              <option className="dropdown-item" value="less than">less than</option>
                              <option className="dropdown-item" value="more than or equals" >more than or equals</option>
                              <option className="dropdown-item" value="less than or equals" >less than or equals</option>
                              </select>
                              <code>{filter_amount_out_sign_er}</code>
                            </div>
                            <div class="col-sm-4">
                              <input type="text" class="form-control" placeholder="5000.00" id="filter_amount_out" value={filter_amount_out} onChange={updatefilter_amount_out}   />
                              <code>{filter_amount_out_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Part Name</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" placeholder="Part Name"   id="filter_part_name" value={filter_part_name} onChange={updatefilter_part_name}  />
                              <code>{filter_part_name_er}</code>
                            </div>
                          </div>
                        </div>
                      
                      </div>
                      <div class="row">
                      <div class="col-md-4">
                         <button class="btn btn-primary" onClick={()=>{ setfilter_val(1); getData(); }}>Apply Filters</button>
                         <button class="btn btn-danger" style={{marginLeft:"1rem"}} onClick={()=>{ setfilter_val(0); getData(); }}>Remove Filters</button>
                        </div>
                      </div>
                      <br/><br/>
                            <hr/>                      
                            </div>
                            
                            {/* FILTER SECTION END */}

                    <div class="col-12" style={{marginBottom:"2rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                        {/* COLUMNS MANAGEMENT DROPDOWN START */}
                          <div class="dropdown">
                          <button class="btn btn-warning" onClick={()=>{ document.getElementById("filter-top-card").style.display="block"; }}><i class="mdi mdi-filter-outline"></i></button>
                          <button class="btn btn-primary dropdown-toggle" style={{marginLeft:"1rem"}} type="button" id="dropdownMenuIconButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="mdi mdi-table-large"></i>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuIconButton1">
                              <h6 class="dropdown-header">Columns</h6>
                              <hr/>
                              
                              {/* Column 1 */}
                              <li><div class="d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Sn</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c1_yes" onClick={()=>{setcd_col1_val(1); document.getElementById("cd_c1_yes").style.display="none"; document.getElementById("cd_c1_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c1_no" style={{ display:"none"}} onClick={()=>{ setcd_col1_val(0); document.getElementById("cd_c1_yes").style.display="block"; document.getElementById("cd_c1_no").style.display="none"; }}></i>
                              </div></div></li> 
                               {/* Column 2 */}
                               <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Tr No.</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c2_yes"  onClick={()=>{setcd_col2_val(1); document.getElementById("cd_c2_yes").style.display="none"; document.getElementById("cd_c2_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c2_no" style={{ display:"none"}} onClick={()=>{ setcd_col2_val(0); document.getElementById("cd_c2_yes").style.display="block"; document.getElementById("cd_c2_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 3 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Date</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c3_yes"  onClick={()=>{setcd_col3_val(1); document.getElementById("cd_c3_yes").style.display="none"; document.getElementById("cd_c3_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c3_no" style={{ display:"none"}} onClick={()=>{ setcd_col3_val(0); document.getElementById("cd_c3_yes").style.display="block"; document.getElementById("cd_c3_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 4 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Asset_name</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c4_yes"  onClick={()=>{setcd_col4_val(1); document.getElementById("cd_c4_yes").style.display="none"; document.getElementById("cd_c4_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c4_no" style={{ display:"none"}} onClick={()=>{ setcd_col4_val(0); document.getElementById("cd_c4_yes").style.display="block"; document.getElementById("cd_c4_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 5 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Part_name</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c5_yes"  onClick={()=>{setcd_col5_val(1); document.getElementById("cd_c5_yes").style.display="none"; document.getElementById("cd_c5_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c5_no" style={{ display:"none"}} onClick={()=>{ setcd_col5_val(0); document.getElementById("cd_c5_yes").style.display="block"; document.getElementById("cd_c5_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 6 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Type</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c6_yes"  onClick={()=>{setcd_col6_val(1); document.getElementById("cd_c6_yes").style.display="none"; document.getElementById("cd_c6_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c6_no" style={{ display:"none"}} onClick={()=>{ setcd_col6_val(0); document.getElementById("cd_c6_yes").style.display="block"; document.getElementById("cd_c6_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 7 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Mode</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c7_yes" onClick={()=>{setcd_col7_val(1); document.getElementById("cd_c7_yes").style.display="none"; document.getElementById("cd_c7_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c7_no" style={{ display:"none"}} onClick={()=>{ setcd_col7_val(0); document.getElementById("cd_c7_yes").style.display="block"; document.getElementById("cd_c7_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 8 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Description</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c8_yes" onClick={()=>{setcd_col8_val(1); document.getElementById("cd_c8_yes").style.display="none"; document.getElementById("cd_c8_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c8_no" style={{ display:"none"}} onClick={()=>{ setcd_col8_val(0); document.getElementById("cd_c8_yes").style.display="block"; document.getElementById("cd_c8_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 9 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Value In</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c9_yes" onClick={()=>{setcd_col9_val(1); document.getElementById("cd_c9_yes").style.display="none"; document.getElementById("cd_c9_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c9_no" style={{ display:"none"}} onClick={()=>{ setcd_col9_val(0); document.getElementById("cd_c9_yes").style.display="block"; document.getElementById("cd_c9_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 10 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} >Value Out</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c10_yes" onClick={()=>{setcd_col10_val(1); document.getElementById("cd_c10_yes").style.display="none"; document.getElementById("cd_c10_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c10_no" style={{ display:"none"}} onClick={()=>{ setcd_col10_val(0); document.getElementById("cd_c10_yes").style.display="block"; document.getElementById("cd_c10_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 11 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}}>Updated By</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c11_yes" onClick={()=>{setcd_col11_val(1); document.getElementById("cd_c11_yes").style.display="none"; document.getElementById("cd_c11_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c11_no" style={{ display:"none"}} onClick={()=>{ setcd_col11_val(0); document.getElementById("cd_c11_yes").style.display="block"; document.getElementById("cd_c11_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 12 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Updated At</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c12_yes" onClick={()=>{setcd_col12_val(1); document.getElementById("cd_c12_yes").style.display="none"; document.getElementById("cd_c12_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c12_no" style={{ display:"none"}} onClick={()=>{ setcd_col12_val(0); document.getElementById("cd_c12_yes").style.display="block"; document.getElementById("cd_c12_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 13 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10 text-warning">Edit</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c13_yes" onClick={()=>{setcd_col13_val(1); document.getElementById("cd_c13_yes").style.display="none"; document.getElementById("cd_c13_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c13_no" style={{ display:"none"}} onClick={()=>{ setcd_col13_val(0); document.getElementById("cd_c13_yes").style.display="block"; document.getElementById("cd_c13_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 14 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10 text-danger">Delete</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c14_yes" onClick={()=>{setcd_col14_val(1); document.getElementById("cd_c14_yes").style.display="none"; document.getElementById("cd_c14_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c14_no" style={{ display:"none"}} onClick={()=>{ setcd_col14_val(0); document.getElementById("cd_c14_yes").style.display="block"; document.getElementById("cd_c14_no").style.display="none"; }}></i>
                              </div></div></li> 
                              
                           
                            {/*}  <div class="dropdown-divider"></div>
                              <button class="btn btn-danger">Close</button>*/}
                            </div> 
                          </div>
                          {/* COLUMNS MANAGEMENT DROPDOWN END */}

                          </div>
                          </div>


                    <div className="table-responsive" id="reports" ref={componentRef}>
                      <table className="table table-hover">
                        <thead>
                          <tr >
                          <th style={{fontWeight:"bold"}} hidden={cd_col1_val==1} >Sn</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col2_val==1} >Tr No.</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col3_val==1} >Date</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col4_val==1} >Asset_name</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col5_val==1} >Part_name</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col6_val==1} >Type</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col7_val==1} >Mode</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col8_val==1} >Description</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col9_val==1} >Value In</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col10_val==1} >Value Out</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col11_val==1} >Updated By</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col12_val==1} >Updated At</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col13_val==1} ></th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col14_val==1} ></th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                       data.map((curElm,ind)=>{

                        return(
                        <>
                        <tr key={curElm.Id}>
                            <td hidden={(cd_col1_val==1)}>{curElm.Asn}</td>
                            <td hidden={cd_col2_val==1} >{curElm.Tr_no}</td>
                            <td hidden={cd_col3_val==1}>{curElm.Date}</td>
                            <td hidden={cd_col4_val==1}>{curElm.Asset_name}</td>
                            <td hidden={cd_col5_val==1}>{curElm.Part_name}</td>
                            <td hidden={cd_col6_val==1}>
                            <label className="badge badge-danger" hidden={(curElm.Type == "entry")||(curElm.Type == "appreciation")||(curElm.Type == "depreciation")||(curElm.Type == "sales")||(curElm.Type == "purchase")}>drawings</label>
                            <label className="badge badge-success" hidden={(curElm.Type == "drawings")||(curElm.Type == "appreciation")||(curElm.Type == "depreciation")||(curElm.Type == "sales")||(curElm.Type == "purchase")}>entry</label>
                            <label className="badge badge-primary" hidden={(curElm.Type == "drawings")||(curElm.Type == "entry")||(curElm.Type == "depreciation")||(curElm.Type == "sales")||(curElm.Type == "purchase")}>appreciation</label>
                            <label className="badge badge-warning" hidden={(curElm.Type == "drawings")||(curElm.Type == "entry")||(curElm.Type == "appreciation")||(curElm.Type == "sales")||(curElm.Type == "purchase")}>depreciation</label>
                            <label className="badge badge-primary" style={{backgroundColor:"#08a1b5",border:"none"}} hidden={(curElm.Type == "drawings")||(curElm.Type == "entry")||(curElm.Type == "depreciation")||(curElm.Type == "appreciation")||(curElm.Type == "purchase")}>sales</label>
                            <label className="badge badge-info" style={{backgroundColor:"#c723b0",border:"none"}} hidden={(curElm.Type == "drawings")||(curElm.Type == "entry")||(curElm.Type == "depreciation")||(curElm.Type == "appreciation")||(curElm.Type == "sales")}>purchase</label>
                            </td>
                            <td hidden={cd_col7_val==1}>{curElm.Mode} <span hidden={((curElm.Mode=="cash")||(curElm.Mode==" ")||curElm.Mode==null)}>({curElm.Bank_name})</span></td>
                            <td hidden={cd_col8_val==1}>{curElm.Description}</td>
                            <td hidden={cd_col9_val==1}>{curElm.Value_in}</td>
                            <td hidden={cd_col10_val==1}>{curElm.Value_out}</td>
                            <td hidden={cd_col11_val==1}>{curElm.Updated_by}</td>
                            <td hidden={cd_col12_val==1}>{curElm.Date_updated} ({curElm.Time_updated})</td>
                            <td hidden={cd_col13_val==1}><i  className="mdi mdi-pencil-box text-warning" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                        //Setting Cash Id and Redirect to Edit
                      sessionStorage.setItem("EditId00",curElm.Asset_id);sessionStorage.setItem("Trno00",curElm.Tr_no);
                      //Setting Data for Form
               /*       document.getElementById("date").value=(curElm.Date);
                      document.getElementById("type").value=(curElm.Type);
                      document.getElementById("asset_name").value=(curElm.Asset_name);
                      document.getElementById("part_name").value=(curElm.Part_name);
                      document.getElementById("value").value=(curElm.Value);
                      document.getElementById("other_additions").value=(curElm.Other_additions);
                      document.getElementById("mode").value=(curElm.Mode);
                      document.getElementById("bank_id").value=(curElm.Bank_id);
                      document.getElementById("description").value=(curElm.Description);
                      document.getElementById("particular").value=(curElm.Particular); */

                      setdate(curElm.Date);
                      settype(curElm.Type);
                      setasset_name(curElm.Asset_name);
                      setpart_name(curElm.Part_name);
                      setvalue(curElm.Value);
                      setother_additions(curElm.Other_additions);
                      setmode(curElm.Mode);
                      setbank_id(curElm.Bank_id);
                      setdescription(curElm.Description);
                      setparticular(curElm.Particular);

                      document.getElementById('edit-form').style.display = 'block'; }}></i></td>
                            <td hidden={cd_col14_val==1}><i className="mdi mdi-delete-forever text-danger" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                        //Setting Counter Id for Delete
                      sessionStorage.setItem("DEditId00",curElm.Asset_id);      sessionStorage.setItem("DTrno00",curElm.Tr_no); document.getElementById('delete-card').style.display = 'block'; }}></i></td>
                          </tr>
                          </>
                        )
                        }
					   
		                  	)
                        }
                          
                         
                        </tbody>
                      </table>
                    </div>
                    
                    {/* PAGINATION SECTION START */}
                    <div>
                       <div class="col-12" style={{marginBottom:"2rem",marginTop:"1rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                       
                          <div class="dropdown">
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber-1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-left-drop-circle text-success"></i></button>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber+1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-right-drop-circle text-success"></i></button>
                          </div>
                          </div>
                          </div>
                    </div>
                    {/* PAGINATION SECTION END */}

                    <button class="btn btn-light" onClick={handlePrint}><i class="mdi mdi-cloud-print text-dark"></i></button>
                    <button class="btn btn-light" onClick={downloadExcel}><i class="mdi mdi-file-excel text-dark"></i></button>
                    
                  </div>
                </div>
              </div>

            {/* ASSET TRANSACTIONS TABLE SECTION END */}

            
          </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
</>
);

//}
}
export default Userassets_history;