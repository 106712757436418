import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios';
import Navbar from '../navbar.js';
import Sidebar from '../sidebar.js';
import Topanalytics from './topanalytics.js';
import Topchart from './topchart.js';
import Createteam from './createteam.js';
import Editteam from './editteam';
import Addteammember from './addteammember.js';



/*import Amanbg from './amanbg';*/


function Teampaneldashboard(){


  
  
   // Setting Values fom Session Storage
 const logstatus=sessionStorage.getItem("loginstatus00");//loginstatus
 const loguser=sessionStorage.getItem("username00");//username
 const logtype=sessionStorage.getItem("logintype00");//logintype
 const logname=sessionStorage.getItem("loginname00");//loginname
 const logadminid=sessionStorage.getItem("loginadminid00");//loginadminid


 
 //Variables
 const[data,setData] = useState([]);//SetData Variable
 const[data1,setData1] = useState([]);//SetData Variable
 //Error Variable
 //const[errorvar,seterrorvar] = useState();
 //Error Variable
 const[errorvar1,seterrorvar1] = useState();


 
      

    //Sessions Not Set
   {/*} if(((logstatus=="false")||(logstatus==null))&&(loguser==null)&&(logadminid==null)){
 
      sessionStorage.removeItem("username00");
      sessionStorage.removeItem("loginstatus00");
      sessionStorage.removeItem("logintype00");
      sessionStorage.removeItem("loginname00");
      sessionStorage.removeItem("loginadminid00");
     
      alert("Session Expired! Login Again.");
      window.location.replace("/");
    
    }
    //Sessions Set
    else{
    */}
     
      

  //Use Effect, Data Load
  useEffect(()=>{

    getData();getData1();

},[])



//Fetching Teams
function getData(){
  

  //Checking if user loggedin
if(((logstatus=="true")||(logstatus!=null))&&(loguser!=null)&&(logadminid!=null)){


 
 axios.post("team-details", {
   User: loguser,
   Logtype:logtype,
   Logstatus:logstatus,
   Log_id: logadminid,
 })
 .then((response) => {
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setData(response.data.teams);
  /* setdatacount(response.data.datacount);
   setloadcount(response.data.loadcount);
   setloadcount(1); */
  }
  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 

 });

 }
//User not Logedin
else{

 sessionStorage.removeItem("username00");
 sessionStorage.removeItem("loginstatus00");
 sessionStorage.removeItem("logintype00");
 sessionStorage.removeItem("loginname00");
 sessionStorage.removeItem("loginadminid00");

 alert("Session Expired! Login Again.");
 window.location.replace("/");

  }

}



//Fetching Team Members
function getData1(){
  

  //Checking if user loggedin
if(((logstatus=="true")||(logstatus!=null))&&(loguser!=null)&&(logadminid!=null)){


 
 axios.post("teammember-details", {
   User: loguser,
   Logtype:logtype,
   Logstatus:logstatus,
   Log_id: logadminid,
 })
 .then((response) => {
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setData1(response.data.teammembers);
  // console.log(response.data);
  /* setdatacount(response.data.datacount);
   setloadcount(response.data.loadcount);
   setloadcount(1); */
  }
  //Cannot Fetch Data
  else{
   seterrorvar1("Cannot Fetch Data !");
  }
 

 });

 }
//User not Logedin
else{

 sessionStorage.removeItem("username00");
 sessionStorage.removeItem("loginstatus00");
 sessionStorage.removeItem("logintype00");
 sessionStorage.removeItem("loginname00");
 sessionStorage.removeItem("loginadminid00");

 alert("Session Expired! Login Again.");
 window.location.replace("/");

  }

}

/* EDIT TEAM MEMBER DETAILS FUNCTION START  */
 //upmember_id
 const[upmember_id,setupmember_id]=useState();const updateupmember_id = (event) => { setupmember_id(event.target.value);};
//mname
const[mname,setmname]=useState();const updatemname = (event) => { setmname(event.target.value);};
//musername
const[musername,setmusername]=useState();const updatemusername = (event) => { setmusername(event.target.value);};
 //memail
const[memail,setmemail]=useState();const updatememail = (event) => { setmemail(event.target.value);};
//mcontact
const[mcontact,setmcontact]=useState();const updatemcontact = (event) => { setmcontact(event.target.value);};
//mrole
const[mrole,setmrole]=useState();const updatemrole = (event) => { setmrole(event.target.value);};
//mcategory
const[mcategory,setmcategory]=useState();const updatemcategory = (event) => { setmcategory(event.target.value);};
//mpassword
const[mpassword,setmpassword]=useState();const updatempassword = (event) => { setmpassword(event.target.value);};
//mcpassword
const[mcpassword,setmcpassword]=useState();const updatemcpassword = (event) => { setmcpassword(event.target.value);};
//mteam
const[mteam,setmteam]=useState();const updatemteam = (event) => { setmteam(event.target.value);};
//mteam_name
const[mteam_name,setmteam_name]=useState();const updatemteam_name = (event) => { setmteam_name(event.target.value);};
//mremarks
const[mremarks,setmremarks]=useState();const updatemremarks = (event) => { setmremarks(event.target.value);};
//mtype
const[mtype,setmtype]=useState();const updatemtype = (event) => { setmtype(event.target.value);};
//mstatus
const[mstatus,setmstatus]=useState();const updatemstatus = (event) => { setmstatus(event.target.value);};

      //ERROR VARIABLES 
      const[errorvar,seterrorvar]=useState();
      const[error11,seterror11]=useState();
      const[error12,seterror12]=useState();
      const[error13,seterror13]=useState();
      const[error14,seterror14]=useState();
      const[error15,seterror15]=useState();
      const[error16,seterror16]=useState();
      const[error17,seterror17]=useState();
      const[error18,seterror18]=useState();
      const[error19,seterror19]=useState();
      const[error20,seterror20]=useState();
      const[error21,seterror21]=useState();
      const[error22,seterror22]=useState();




      function editteammember(){
  
        //Checking if user loggedin
if((logstatus=="true")&&(loguser!=null)&&(logadminid!=null)){

  //const Fetchadmin = sessionStorage.getItem("editId00");

//Member Id Set
if((upmember_id!=null)&&(upmember_id>0)){

      seterror11("");
      seterror12("");
      seterror13("");
      seterror14("");
      seterror15("");
      seterror16("");
      seterror17("");
      seterror18("");
      seterror19("");
      seterror20("");
      seterror21("");
      seterror22("");

      let noerror = 0;

      const $name = document.getElementById("mname").value; if($name==""){ seterror12("Name is required !");noerror = noerror+1; }
      const $username = document.getElementById("musername").value; if($username==""){ seterror13("Username is required !");noerror = noerror+1; }
      const $email = document.getElementById("memail").value; if($email==""){ seterror14("Email is required !");noerror = noerror+1; }
      const $contact = document.getElementById("mcontact").value; if($contact==""){ seterror15("Contact is required !");noerror = noerror+1; }
      const $role = document.getElementById("mrole").value; if($role==""){ seterror16("Role is required !");noerror = noerror+1; }
      const $password = document.getElementById("mpassword").value; //if($password==""){ seterror16("password is required !");noerror = noerror+1; }
      const $cpassword = document.getElementById("mcpassword").value; //if($cpassword==""){ seterror16("cpassword is required !");noerror = noerror+1; }
      const $category = document.getElementById("mcategory").value; if($category==""){ seterror17("Category is required !");noerror = noerror+1; }
      const $team = document.getElementById("mteam").value; if($team==""){ seterror20("Team is required !");noerror = noerror+1; }
      const $remarks = document.getElementById("mremarks").value; if($remarks==""){ seterror21("Remarks is required !");noerror = noerror+1; }
      const $type = document.getElementById("mtype").value; if($type==""){ seterror22("Type is required !");noerror = noerror+1; }

     if($password!=$cpassword){noerror = noerror+1; seterror1("Password and Confirm Password do not Match!"); }
      // const $status = document.getElementById("status").value;

    //No primary errors
if(noerror==0){
      //Sending Data through axios
      axios.post("add-teammember", { 
       User:loguser,
       Logtype:logtype,
       Log_id:logadminid,
       Logstatus:"true",
       User_id:logadminid,
       Account_type:logtype,
       Operation:"update",
       Name:$name,
       Username:$username,
       Email:$email,
       Contact:$contact,
       Role:$role,
       Category:$category,
       Password:$password,
       Cpassword:$cpassword,
       Team_id:$team,
       Remarks:$remarks,
       Type:$type,
       Status:"active",
       UpdateVal:upmember_id
      // ConfirmPassword:$cpassword
     
 })
 
     .then((response) => {


 
       seterror11(" ");seterror12(" ");seterror13(" ");seterror14(" ");seterror15(" ");seterror16(" ");seterror17(" ");seterror18(" ");seterror19(" ");seterror20(" ");seterror21(" ");seterror22(" ");
 
       //Validation Errors
     if(response.data.validatorerror){
       seterror12(response.data.validatorerror.Name);
       seterror13(response.data.validatorerror.Username);
       seterror14(response.data.validatorerror.Email);
       seterror15(response.data.validatorerror.Contact);
       seterror16(response.data.validatorerror.Role);
       seterror17(response.data.validatorerror.Category);
       seterror18(response.data.validatorerror.Password);
       seterror19(response.data.validatorerror.Cpassword);
       seterror20(response.data.validatorerror.Team);
       seterror21(response.data.validatorerror.Remarks);
       seterror22(response.data.validatorerror.Team);

       if(response.data.error=='101'){ seterror11("Cannot Add Team Member!"); }
       
        }
     //Invalid Entries
     if(response.data.successvar==2){
       seterror12(response.data.name_er);
       seterror13(response.data.username_er);
       seterror14(response.data.email_er);
       seterror15(response.data.contact_er);
       seterror16(response.data.role_er);
       seterror17(response.data.category_er);
       seterror18(response.data.password_er);
       seterror19(response.data.cpassword_er);
       seterror20(response.data.team_er);
       seterror21(response.data.remarks_er);
       seterror22(response.data.type_er);
       seterror11("Cannot Update Team Member Details!");
       
            }
   
     //success
     if(response.data.successvar==1){
   //  alert("Data Updated Successfully!");
     alert("Data Updated Successfully!"+"<br/>"+response.data.pass_update_msg);
     document.getElementById("editadmin-box").style.display="none";
     }
     else{
     if(response.data.successvar==0){
     seterror1("Cannot Update Data!");
     }
     
   }
   }    );

 }
 //Return Errors
 else{
   return;
 }


}
//User not Logedin
else{

 sessionStorage.removeItem("username00");
 sessionStorage.removeItem("loginstatus00");
 sessionStorage.removeItem("logintype00");
 sessionStorage.removeItem("loginname00");
 sessionStorage.removeItem("loginadminid00");

 alert("Session Expired! Login Again.");
 window.location.replace("/");

  }

}

   }


/*   DELETE TEAM MEMBER DETAILS FUNCTION START */

//Delete Function
function deleteteammemberdetails(){
  //Checking if user loggedin and Admin Id Set for Edit
if(((logstatus=="true")||(logstatus!=null))&&(loguser!=null)&&(logadminid!=null)){


//const Fetchadmin = sessionStorage.getItem("editId00");

//Team Id Set
if((upmember_id!=null)&&(upmember_id>0)){

//Sending Data through axios
axios.post("add-teammember", { 
User: loguser,
Logtype:logtype,
Logstatus:logstatus,
Log_id: logadminid,
User_id: logadminid,
Account_type:logtype,
//AdminId:Fetchadmin,
UpdateVal:upmember_id,
Operation:"delete"
})

.then((response) => {


  
  //Validation Errors
if(response.data.validatorerror){

  if(response.data.error=='101'){ alert("Data Not Deleted!"); }

   }
//Invalid Entries
if(response.data.successvar==2){

  alert("Data Not Updated!");


       }
if((response.data.successvar)==1){

  alert("Data Deleted Successfully!");
}
else{
  alert("Data Not Deleted!");
}



})

}else{
 
//Removing Edit Variables
document.getElementById("deleteteammember-box").style.display="none"; setupmember_id("");}
}
//User not Logedin
else{

sessionStorage.removeItem("username00");
sessionStorage.removeItem("loginstatus00");
sessionStorage.removeItem("logintype00");
sessionStorage.removeItem("loginname00");
sessionStorage.removeItem("loginadminid00");

alert("Session Expired! Login Again.");
window.location.replace("/");



}
document.getElementById("deleteteammember-box").style.display="none";
}

/*   DELETE TEAM MEMBER DETAILS FUNCTION END */


/* EDIT TEAM MEMBER DETAILS FUCTION END */

/*   EDIT TEAM DETAILS FUNCTION START */

    //upteam_id
    const[upteam_id,setupteam_id]=useState();const updateupteam_id = (event) => { setupteam_id(event.target.value);};
    //name
    const[name,setname]=useState();const updatename = (event) => { setname(event.target.value);};
    //category
    const[category,setcategory]=useState();const updatecategory = (event) => { setcategory(event.target.value);};
    //type
    const[type,settype]=useState();const updatetype = (event) => { settype(event.target.value);};
    //remarks
    const[remarks,setremarks]=useState();const updateremarks = (event) => { setremarks(event.target.value);};
    //status
    const[status,setstatus]=useState();const updatestatus = (event) => { setstatus(event.target.value);};
   
      //ERROR VARIABLES 
      const[error1,seterror1]=useState();
      const[error2,seterror2]=useState();
      const[error3,seterror3]=useState();
      const[error4,seterror4]=useState();
      const[error5,seterror5]=useState();
      const[error6,seterror6]=useState();
      const[error7,seterror7]=useState();

function editteam(){
  
  //Checking if user loggedin
if((logstatus=="true")&&(loguser!=null)&&(logadminid!=null)){
seterror1("");
seterror2("");
seterror3("");
seterror4("");
seterror5("");
seterror6("");
seterror7("");

let noerror = 0;

const $name = name; if($name==""){ seterror1("Name is required !");noerror = noerror+1; }
const $type = type; if($type==""){ seterror2("Type is required !");noerror = noerror+1; }
const $category = category; if($category==""){ seterror3("Category is required !");noerror = noerror+1; }
const $remarks = remarks; if($remarks==""){ seterror5("Remarks is required !");noerror = noerror+1; }
const $status = status;if($status==""){ seterror4("Status is required !");noerror = noerror+1; }

//No primary errors
if((noerror==0)&&(upteam_id>0)){
//Sending Data through axios
axios.post("add-team", { 
 User:loguser,
 Logtype:logtype,
 Log_id:logadminid,
 Logstatus:"true",
 User_id:logadminid,
 Account_type:logtype,
 Operation:"update",
 Name:$name,
 Type:$type,
 Category:$category,
 Remarks:$remarks,
 Status:$status,
 UpdateVal:upteam_id
// ConfirmPassword:$cpassword

})

.then((response) => {



 seterror1(" ");seterror2(" ");seterror3(" ");seterror4(" ");seterror5(" ");seterror6(" ");seterror7(" ");

 //Validation Errors
if(response.data.validatorerror){
 seterror1(response.data.validatorerror.Name);
 seterror2(response.data.validatorerror.Type);
 seterror3(response.data.validatorerror.Category);
 seterror4(response.data.validatorerror.Status);
 seterror5(response.data.validatorerror.Remarks);
 if(response.data.error=='101'){ seterror6("Cannot Edit Team!"); }
 
  }
//Invalid Entries
if(response.data.successvar==2){
 seterror1(response.data.name_er);
 seterror2(response.data.type_er);
 seterror3(response.data.category_er);
 seterror4(response.data.status_er);
 seterror5(response.data.remarks_er);
 seterror6("Cannot Edit Team!");
 
      }

//success
if(response.data.successvar==1){
alert("Data Updated Successfully!");
document.getElementById("editteam-box").style.display="none";
   // Removing Sessions
   sessionStorage.removeItem("editId00");
   sessionStorage.removeItem("editteamname");
   sessionStorage.removeItem("editteamcategory");
   sessionStorage.removeItem("editteamremarks");
   sessionStorage.removeItem("editteamtype");
   sessionStorage.removeItem("editteamstatus");

}
else{
if(response.data.successvar==0){
seterror6("Cannot Update Team!");
}

}
}    );

}
//Return Errors
else{
alert("er...");
return;
}


}
//User not Logedin
else{

sessionStorage.removeItem("username00");
sessionStorage.removeItem("loginstatus00");
sessionStorage.removeItem("logintype00");
sessionStorage.removeItem("loginname00");
sessionStorage.removeItem("loginadminid00");

alert("Session Expired! Login Again.");
window.location.replace("/");

}




}


/*   EDIT TEAM DETAILS FUNCTION END */

/*   DELETE TEAM DETAILS FUNCTION START */

//Delete Function
function deleteteamdetails(){
  //Checking if user loggedin and Admin Id Set for Edit
if(((logstatus=="true")||(logstatus!=null))&&(loguser!=null)&&(logadminid!=null)){

//const Fetchadmin = sessionStorage.getItem("editId00");

//Team Id Set
if((upteam_id!=null)&&(upteam_id>0)){

//Sending Data through axios
axios.post("add-team", { 
User: loguser,
Logtype:logtype,
Logstatus:logstatus,
Log_id: logadminid,
User_id: logadminid,
Account_type:logtype,
//AdminId:Fetchadmin,
UpdateVal:upteam_id,
Operation:"delete"
})

.then((response) => {


  
  //Validation Errors
if(response.data.validatorerror){

  if(response.data.error=='101'){ alert("Data Not Deleted!"); }

   }
//Invalid Entries
if(response.data.successvar==2){

  alert("Data Not Updated!");


       }
if((response.data.successvar)==1){

  alert("Data Deleted Successfully!");
}
else{
  alert("Data Not Deleted!");
}



})

}else{

//Removing Edit Variables
document.getElementById("deleteteam-box").style.display="none"; setupteam_id("");}
}
//User not Logedin
else{

sessionStorage.removeItem("username00");
sessionStorage.removeItem("loginstatus00");
sessionStorage.removeItem("logintype00");
sessionStorage.removeItem("loginname00");
sessionStorage.removeItem("loginadminid00");

alert("Session Expired! Login Again.");
window.location.replace("/");



}
document.getElementById("deleteteam-box").style.display="none";
}

/*   DELETE TEAM DETAILS FUNCTION END */

    return(
<>
{/*<!DOCTYPE html> */}
<html lang="en">
  <head>
    {/* Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>theStartupConnect | Portal</title>
    {/* plugins:css */}
    <link rel="stylesheet" href="./assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="./assets/vendors/css/vendor.bundle.base.css" />
    {/* endinject */}
    {/* Plugin css for this page */}
    {/* End plugin css for this page */}
    {/* inject:css */}
    {/* endinject */}
    {/* Layout styles */}
    <link rel="stylesheet" href="./assets/css/style.css" />
    {/* End layout styles */}
   {/*} <link rel="shortcut icon" href="./assets/images/favicon.ico" /> */}
  </head>
  <body>

  


<Navbar/>

{/* MAIN PAGE COMPONENT START */}
  <div class="container-fluid page-body-wrapper">

 <Sidebar/>


{/* MAIN PANEL START */}
<div class="main-panel" style={{marginTop:"5vh"}}>
<div class="content-wrapper">

{/* ADD NEW TEAM MEMBER SECTION START */}
<div class="addteammember-box" id="addteammember-box"  style={{position:"fixed",marginLeft:"2%",marginTop:"-2%",width:"70%",backgroundColor:"#fdffff",display:"none",zIndex:"3",boxShadow:"0.1rem 0.1rem 0.1rem 0.1rem #c7d0ce"}}>
<Addteammember/>

</div>
{/* ADD NEW TEAM MEMBER SECTION END */}
{/* EDIT TEAM MEMBER SECTION START */}
<div class="editadmin-box" id="editadmin-box"  style={{position:"fixed",marginLeft:"2%",marginTop:"-8%",width:"70%",backgroundColor:"#fdffff",display:"none",zIndex:"5",boxShadow:"0.1rem 0.1rem 0.1rem 0.1rem #c7d0ce"}}>
{/*<Editadmin/>*/}
{/* Edit Team Member Section Start */}

<div>
       <div class="col-12" >     
                <div class="card">
                <div><button class="float-end" style={{padding:"1rem",backgroundColor:"transparent",border:"none"}} onClick={()=>{ sessionStorage.removeItem("editId00"); document.getElementById("editadmin-box").style.display="none";document.getElementById("change-pwd").style.display="none";}}><i class="mdi mdi-close-box" style={{color:"#f04f60",fontSize:"2rem"}}></i></button></div>
                  <div class="card-body">
                    <h4 class="card-title">Edit Team Member Details</h4>
                    <div class="form-sample">
                      <p class="card-description"> </p>
                      
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Member Id</label>
                            <div class="col-sm-9">
                              <label class="col-form-label">{upmember_id}</label>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="mname" value={mname} onChange={updatemname} />
                              <small style={{fontSize:".8rem",color:"#f16083"}}>{error12}</small>  
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Username</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="musername" value={musername} onChange={updatemusername} />
                              <small style={{fontSize:".8rem",color:"#f16083"}}>{error13}</small>  
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Email</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="memail" value={memail} onChange={updatememail} />
                              <small style={{fontSize:".8rem",color:"#f16083"}}>{error14}</small>  
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Category</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="mcategory" value={mcategory} onChange={updatemcategory} />
                              <small style={{fontSize:".8rem",color:"#f16083"}}>{error17}</small>  
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Team</label>
                            <div class="col-sm-9">
                            <select class="form-control" id="mteam" style={{padding:"1rem"}} onChange={updatemteam} >
                           <option value={mteam}>{mteam_name}</option>
                           {
                       data.map((curElm,ind)=>{

return(
  <>
                             <option value={curElm.Team_id}>{curElm.Name}</option>

                             </>
      )
      }
					   
			)}
                             
                           </select>
                              <small style={{fontSize:".8rem",color:"#f16083"}}>{error20}</small>  
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="mtype" value={mtype} onChange={updatemtype} />
                              <small style={{fontSize:".8rem",color:"#f16083"}}>{error22}</small>  
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Remarks</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="mremarks" value={mremarks} onChange={updatemremarks} />
                              <small style={{fontSize:".8rem",color:"#f16083"}}>{error21}</small>  
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Contact</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" id="mcontact" value={mcontact} onChange={updatemcontact} />
                            <small style={{fontSize:".8rem",color:"#f16083"}}>{error15}</small>  
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Role</label>
                            <div class="col-sm-9">
                              <input class="form-control" id="mrole" value={mrole} onChange={updatemrole} />
                              <small style={{fontSize:".8rem",color:"#f16083"}}>{error16}</small>  
                            </div>
                          </div>
                        </div>
                        <div class="d-flex justify-content-end"><button class="btn btn-outline-info" onClick={()=>{ document.getElementById("change-pwd").style.display="block";}}>Change Password</button></div>
                      </div>
                      <div class="row mt-4" id="change-pwd" style={{display:"none"}}>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Password</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" id="mpassword" value={mpassword} onChange={updatempassword} />
                            <small style={{fontSize:".8rem",color:"#f16083"}}>{error4}</small>  
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Confirm Passwprd</label>
                            <div class="col-sm-9">
                              <input class="form-control" id="mcpassword" value={mcpassword} onChange={updatemcpassword} />
                              <small style={{fontSize:".8rem",color:"#f16083"}}>{error3}</small>  
                            </div>
                          </div>
                        </div>
                                         </div>
                      </div>
                      <button class="btn btn-block btn-lg btn-primary mt-4 mb-4" onClick={editteammember} >Edit Details</button>
                  
                    
                    </div>
                  </div>
                </div>
              </div>

       
       </div>
            
{/* Edit Team Member Section End */}
</div>
{/* EDIT TEAM MEMBER SECTION END */}


{/* ADD NEW TEAM SECTION START */}
<div class="addteam-box" id="addteam-box"  style={{position:"fixed",marginLeft:"2%",marginTop:"2%",width:"70%",backgroundColor:"#fdffff",display:"none",zIndex:"3",boxShadow:"0.1rem 0.1rem 0.1rem 0.1rem #c7d0ce"}}>
<Createteam/>

</div>


{/* DELETE TEAM ALERT BOX SECTION START */}
<div class="deleteteam-box" id="deleteteam-box"  style={{position:"fixed",marginLeft:"2%",marginTop:"-2%",width:"70%",backgroundColor:"#fdffff",display:"none",zIndex:"3",boxShadow:"0.1rem 0.1rem 0.1rem 0.1rem #c7d0ce"}}>
<h3 style={{marginTop:"3rem"}}> Are you sure you want to Delete this account?</h3>
<button class="btn btn-block btn-lg btn-success mt-4 mb-4" onClick={deleteteamdetails}>Yes</button>
<button class="btn btn-block btn-lg btn-danger mt-4 mb-4 " style={{marginLeft:"2rem"}} onClick={()=>{ document.getElementById("deleteteam-box").style.display="none"; }}>No</button>

</div>
{/* DELETE TEAM ALERT BOX SECTION END */}

{/* DELETE TEAM MEMBER ALERT BOX SECTION START */}
<div class="deleteteammember-box" id="deleteteammember-box"  style={{position:"fixed",marginLeft:"2%",marginTop:"-2%",width:"70%",backgroundColor:"#fdffff",display:"none",zIndex:"3",boxShadow:"0.1rem 0.1rem 0.1rem 0.1rem #c7d0ce"}}>
<h3 style={{marginTop:"3rem"}}> Are you sure you want to Delete this account?</h3>
<button class="btn btn-block btn-lg btn-success mt-4 mb-4" onClick={deleteteammemberdetails}>Yes</button>
<button class="btn btn-block btn-lg btn-danger mt-4 mb-4 " style={{marginLeft:"2rem"}} onClick={()=>{ document.getElementById("deleteteammember-box").style.display="none"; }}>No</button>

</div>
{/* DELETE TEAM  MEMBER ALERT BOX SECTION END */}
{/* ADD NEW TEAM SECTION END */}


{/* EDIT NEW TEAM SECTION START */}
<div class="editteam-box" id="editteam-box"  style={{position:"fixed",marginLeft:"2%",marginTop:"2%",width:"70%",backgroundColor:"#fdffff",display:"none",zIndex:"3",boxShadow:"0.1rem 0.1rem 0.1rem 0.1rem #c7d0ce"}}>

{/* Edit Team Section Start */}

<div>
       <div class="col-12" >     
                <div class="card">
                <div><button class="float-end" style={{padding:"1rem",backgroundColor:"transparent",border:"none"}} onClick={()=>{ 
                  
                      // Removing Sessions
          sessionStorage.removeItem("editId00");
                             setupteam_id("");
                             setname("");
                             setcategory("");
                             setremarks("");
                             settype("");
                             setstatus("");
         document.getElementById("editteam-box").style.display="none";}}>
     <i class="mdi mdi-close-box" style={{color:"#f04f60",fontSize:"2rem"}}></i></button></div>
                  <div class="card-body">
                    <h4 class="card-title">Edit Team Details</h4>
                    <div class="form-sample">
                      <p class="card-description">Edit Team Information</p>
                      <small style={{fontSize:".8rem",color:"#f16083"}}>{error6}</small>   
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Team Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="name"  value={name} onChange={updatename} placeholder="Enter Team Name" />
                              <small style={{fontSize:".8rem",color:"#f16083"}}>{error1}</small>       
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="type"  value={type} onChange={updatetype} placeholder="Enter Type"  />
                              <small style={{fontSize:".8rem",color:"#f16083"}}>{error2}</small>       
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Category</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" id="category"  value={category} onChange={updatecategory} placeholder="Category" />
                            <small style={{fontSize:".8rem",color:"#f16083"}}>{error3}</small>       
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Remarks</label>
                            <div class="col-sm-9">
                              <input class="form-control" id="remarks"  value={remarks} onChange={updateremarks} placeholder="Remarks" />
                              <small style={{fontSize:".8rem",color:"#f16083"}}>{error5}</small>       
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Status</label>
                            <div class="col-sm-9">
                              <input class="form-control" id="status"  value={status} onChange={updatestatus} placeholder="Status" />
                              <small style={{fontSize:".8rem",color:"#f16083"}}>{error4}</small>       
                            </div>
                          </div>
                        </div>
                      
                      </div>
                      <button class="btn btn-block btn-lg btn-gradient-danger mt-4 mb-4" onClick={editteam}>Edit Team</button>
                  
                    
                    </div>
                  </div>
                </div>
              </div>

       
       </div>
            
{/* Edit Team Section End */}


</div>
{/* EDIT NEW TEAM SECTION END */}


<h2 class="text-center mb-2">Team Panel</h2>
         <Topanalytics/>
       
       {/*  MANAGE TEAM SECTION START */}

       {/* Add Team Member Start */}

         <button class="btn btn-block btn-lg btn-gradient-primary mt-4 mb-4 mr-3" onClick={()=>{
         // window.location.replace("/teamstartupconnect-register");
         document.getElementById("addteammember-box").style.display="block";
         }}>+ Add New Member</button>
         {/* Add Team Member End */}
         <span style={{padding:"2rem"}}></span>
         {/* Create Team Start */}
              <button class="btn btn-block btn-lg btn-gradient-info mt-4 mb-4" onClick={()=>{
          //window.location.replace("/teamstartupconnect-register");
          document.getElementById("addteam-box").style.display="block";
         }}>+ Create New Team</button>
         {/* Create Team End */}


         {/* Team Members Table Start */}
         <div class="row">
              <div class="col-12 grid-margin">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Team Members</h4>
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th>Image</th>
                            <th> Member Id </th>
                            <th> Name </th>
                            <th> Username </th>
                            <th> Email </th>
                            <th> Contact </th>
                            <th> Role </th>
                            <th> Category </th>
                            <th> Status </th>
                            <th>Team Id</th>
                            <th> Edit </th>
                            <th> Delete </th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                       data1.map((curElm1,ind)=>{

return(
  <>
                          <tr key={curElm1.Member_id}>
                            <td style={{width:"6vw"}}>
                              <img src="assets/images/faces/face1.jpg" class="me-2" alt="image" />
                              </td>
                            <td>{curElm1.Member_id}</td>
                            <td> {curElm1.Name} </td>
                            <td> {curElm1.Username} </td>
                            <td>{curElm1.Email}</td>
                            <td>{curElm1.Contact}</td>
                            <td>{curElm1.Role}</td>
                            <td>{curElm1.Category}</td>
                            <td>
                              <label class="badge badge-gradient-success">{curElm1.Status}</label>
                            </td>
                            <td>{curElm1.Team_id}</td>
                            <td> <button style={{backgroundColor:"transparent",border:"none"}} onClick={()=>{
                     
                     setupmember_id(curElm1.Member_id);
                     setmname(curElm1.Name);
                     setmusername(curElm1.Username);
                     setmemail(curElm1.Email);
                     setmcontact(curElm1.Contact);
                     setmrole(curElm1.Role);
                     setmcategory(curElm1.Category);
                     setmteam(curElm1.Team_id);
                     setmteam_name(curElm1.Team_name);
                     setmremarks(curElm1.Remarks);
                     setmtype(curElm1.Type);
                     setstatus(curElm1.Status);


                     document.getElementById("editadmin-box").style.display="block";

                    }}><i class="mdi mdi-pencil-box" style={{color:"#dbc821",fontSize:"2rem"}}></i></button></td>
                            <td> <button style={{backgroundColor:"transparent",border:"none"}} onClick={()=>{
                              //Redirecting to Delete
                              setupmember_id(curElm1.Member_id);
                           
                           //  defaultLoad();
                             document.getElementById("deleteteammember-box").style.display="block";

                            }}><i class="mdi mdi-minus-box" style={{color:"#e1435d",fontSize:"2rem"}}></i></button></td>
                          </tr>
                          
                          </>
      )
      }
					   
			)}
                 
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Team Members Table End */}


                     {/* Team Table Start */}
         <div class="row">
              <div class="col-12 grid-margin">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Teams</h4>
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th> Team Id </th>
                            <th> Team Name </th>
                            <th> Category </th>
                            <th> Type </th>
                          {/*  <th> Members </th> */}
                          <th>Remarks</th>
                            <th> Date Created </th>
                            <th>Created By</th>
                            <th> Status </th>
                            <th> Edit </th>
                            <th> Delete </th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                       data.map((curElm,ind)=>{

return(
  <>
                          <tr key={curElm.Team_id}>
                          
                              <td>{curElm.Team_id}</td>
                            <td>{curElm.Name}</td>
                            <td>{curElm.Category}</td>
                            <td>{curElm.Type}</td>
                         {/*   <td>{curElm.Members}</td> */}
                         <td>{curElm.Remarks}</td>
                            <td>{curElm.Date_created}</td>
                            <td>{curElm.Created_by}</td>
                            <td>
                              <label class="badge badge-gradient-success">{curElm.Status}</label>
                            </td>
                            <td> <button style={{backgroundColor:"transparent",border:"none"}} onClick={()=>{
                     
                             setupteam_id(curElm.Team_id);
                             setname(curElm.Name);
                             setcategory(curElm.Category);
                             setremarks(curElm.Remarks);
                             settype(curElm.Type);
                             setstatus(curElm.Status);


                             document.getElementById("editteam-box").style.display="block";

                            }}><i class="mdi mdi-pencil-box" style={{color:"#dbc821",fontSize:"2rem"}}></i></button></td>
                            <td> <button style={{backgroundColor:"transparent",border:"none"}} onClick={()=>{
                              //Redirecting to Delete
                              setupteam_id(curElm.Team_id);
                           
                           //  defaultLoad();
                             document.getElementById("deleteteam-box").style.display="block";

                            }}><i class="mdi mdi-minus-box" style={{color:"#e1435d",fontSize:"2rem"}}></i></button></td>
                          </tr>
                          </>
      )
      }
					   
			)}
                   
             
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Team Table End */}
      {/* MANAGE TEAM SECTION END */}
         
         <Topchart/>
{/*<Pageheader/>
<Topanalytics/>*/}
    
</div>
</div>
{/* MAIN PANEL END */}

</div>
{/* MAIN PAGE COMPONENT END */}

    {/* container-scroller */}
    {/* plugins:js */}
    <script src="./assets/vendors/js/vendor.bundle.base.js"></script>
    {/* endinject */}
    {/* Plugin js for this page */}
    {/* End plugin js for this page */}
    {/* inject:js */}
    <script src="./assets/js/off-canvas.js"></script>
    <script src="./assets/js/hoverable-collapse.js"></script>
    <script src="./assets/js/misc.js"></script>

    {/*} plugins:js */}
    <script src="assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="assets/vendors/chart.js/Chart.min.js"></script>
    <script src="assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="assets/js/off-canvas.js"></script>
    <script src="assets/js/hoverable-collapse.js"></script>
    <script src="assets/js/misc.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="assets/js/dashboard.js"></script>
    <script src="assets/js/todolist.js"></script>
    {/*} End custom js for this page */}
    {/* endinject */}
  </body>
</html>
</>
    );
}
//}

export default Teampaneldashboard;