import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';



/*import Amanbg from './amanbg';*/


function Createteam(){

     


   // Setting Values fom Session Storage
   const logstatus=sessionStorage.getItem("loginstatus00");//loginstatus
   const loguser=sessionStorage.getItem("username00");//username
   const logtype=sessionStorage.getItem("logintype00");//logintype
   const logname=sessionStorage.getItem("loginname00");//loginname
   const logadminid=sessionStorage.getItem("loginadminid00");//loginadminid

 //Checking if Sessions are Set

// alert(logstatus,loguser,logadminid);
 //Sessions Not Set
{/*if(((logstatus=="false")||(loguser==null))/*&&(loguser==null)&&(logadminid==null)){
  
  sessionStorage.removeItem("username00");
  sessionStorage.removeItem("loginstatus00");
  sessionStorage.removeItem("logintype00");
  sessionStorage.removeItem("loginname00");
  sessionStorage.removeItem("loginadminid00");
 
  alert("Session Expired! Login Again.");
  window.location.replace("/");
 }

 //Sessions Set
 else{
 */}


    //name
    const[name,setname]=useState();const updatename = (event) => { setname(event.target.value);};
    //category
    const[category,setcategory]=useState();const updatecategory = (event) => { setcategory(event.target.value);};
    //type
    const[type,settype]=useState();const updatetype = (event) => { settype(event.target.value);};
    //remarks
    const[remarks,setremarks]=useState();const updateremarks = (event) => { setremarks(event.target.value);};
    //status
    const[status,setstatus]=useState();const updatestatus = (event) => { setstatus(event.target.value);};
   
      //ERROR VARIABLES 
      const[error1,seterror1]=useState();
      const[error2,seterror2]=useState();
      const[error3,seterror3]=useState();
      const[error4,seterror4]=useState();
      const[error5,seterror5]=useState();
      const[error6,seterror6]=useState();
      const[error7,seterror7]=useState();
  
  
  
      function create(){
  
           //Checking if user loggedin
   if((logstatus=="true")&&(loguser!=null)&&(logadminid!=null)){
         seterror1("");
         seterror2("");
         seterror3("");
         seterror4("");
         seterror5("");
         seterror6("");
         seterror7("");
  
         let noerror = 0;
  
         const $name = name;/*document.getElementById("name").value;*/ if($name==""){ seterror1("Name is required !");noerror = noerror+1; alert($name); }
         const $type = type;/*document.getElementById("type").value;*/ if($type==""){ seterror2("Type is required !");noerror = noerror+1; alert($type); }
         const $category = category; /*document.getElementById("category").value;*/ if($category==""){ seterror3("Category is required !");noerror = noerror+1; alert($category); }
         const $remarks = remarks;/*document.getElementById("remarks").value;*/ if($remarks==""){ seterror5("Remarks is required !");noerror = noerror+1; alert($remarks); }
        // const $status = document.getElementById("status").value;
     
        
       //No primary errors
  if(noerror==0){
         //Sending Data through axios
         axios.post("create-team", { 
          User:loguser,
          Logtype:logtype,
          Log_id:logadminid,
          Logstatus:"true",
          User_id:logadminid,
          Account_type:logtype,
          Operation:"create",
          Name:$name,
          Type:$type,
          Category:$category,
          Remarks:$remarks,
          Status:"active",
          //UpdateVal:null
         // ConfirmPassword:$cpassword
        
    })
    
        .then((response) => {


    
          seterror1(" ");seterror2(" ");seterror3(" ");seterror4(" ");seterror5(" ");seterror6(" ");seterror7(" ");
    
          //Validation Errors
        if(response.data.validatorerror){
          seterror1(response.data.validatorerror.Name);
          seterror2(response.data.validatorerror.Type);
          seterror3(response.data.validatorerror.Category);
          seterror4(response.data.validatorerror.Status);
          seterror5(response.data.validatorerror.Remarks);
          
          if(response.data.error=='101'){ seterror6("Cannot Create Team!"); }
          
           }
        //Invalid Entries
        if(response.data.successvar==2){
          seterror1(response.data.name_er);
          seterror2(response.data.type_er);
          seterror3(response.data.category_er);
          seterror4(response.data.status_er);
          seterror5(response.data.remarks_er);
          
          seterror6("Cannot Create Team!");
          
               }

               if(response.data.successvar==3){
              
                seterror6(response.data.error);

               }
      
        //success
        if(response.data.successvar==1){
        alert("New Team Created Successfully!");
        document.getElementById("addteam-box").style.display="none";
        }
        else{
        if(response.data.successvar==0){
          
        seterror6("Cannot Create Team!");
        }
        
      }
      }    );
  
    }
    //Return Errors
    else{
      return;
    }
  
  
  }
  //User not Logedin
  else{
  
    sessionStorage.removeItem("username00");
    sessionStorage.removeItem("loginstatus00");
    sessionStorage.removeItem("logintype00");
    sessionStorage.removeItem("loginname00");
    sessionStorage.removeItem("loginadminid00");
   
    alert("Session Expired! Login Again.");
    window.location.replace("/");
  
     }
  
  
  
      }
  
    return(
<>

   
{/* Create Team Section Start */}

<div>
       <div class="col-12" >     
                <div class="card">
                <div><button class="float-end" style={{padding:"1rem",backgroundColor:"transparent",border:"none"}} onClick={()=>{ document.getElementById("addteam-box").style.display="none";}}><i class="mdi mdi-close-box" style={{color:"#f04f60",fontSize:"2rem"}}></i></button></div>
                  <div class="card-body">
                    <h4 class="card-title">Add Team Details</h4>
                    <div class="form-sample">
                      <p class="card-description">Create New Team </p>
                      <small style={{fontSize:".8rem",color:"#f16083"}}>{error6}</small>   
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Team Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="name"  value={name} onChange={updatename} placeholder="Enter Team Name" />
                              <small style={{fontSize:".8rem",color:"#f16083"}}>{error1}</small>       
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="type"  value={type} onChange={updatetype} placeholder="Enter Type"  />
                              <small style={{fontSize:".8rem",color:"#f16083"}}>{error2}</small>       
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Category</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" id="category"  value={category} onChange={updatecategory} placeholder="Category" />
                            <small style={{fontSize:".8rem",color:"#f16083"}}>{error3}</small>       
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Remarks</label>
                            <div class="col-sm-9">
                              <input class="form-control" id="remarks"  value={remarks} onChange={updateremarks} placeholder="Remarks" />
                              <small style={{fontSize:".8rem",color:"#f16083"}}>{error5}</small>       
                            </div>
                          </div>
                        </div>
                      </div>
                      <button class="btn btn-block btn-lg btn-gradient-danger mt-4 mb-4" onClick={create}>Create Team</button>
                  
                    
                    </div>
                  </div>
                </div>
              </div>

       
       </div>
            
{/* Create Team Section End */}
</>
    );
//}
}

export default Createteam;