import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

//import './Resources/home1css.css';

function Home1(){


      return(
      <>


<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />

    <meta name="theme-color" content="#000000" />
    <meta
      name="description"
      content="Get your startup services relating to website development, consultancy, taxation, business registration, software services, intern hiring, social media management, digital marketing and much more.  "></meta>
    
   {/* <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />*/}
        <link rel="icon" href="./images/startupconnect_icon.ico" />
    <link rel="apple-touch-icon" href="./images/startupconnect_icon.png" />

      {/* plugins:css */}
      <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/* endinject */}
    {/* Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/* End plugin css for this page */}
    {/* inject:css */}
    {/* endinject */}
    {/* Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/* End layout styles */}
    <link rel="shortcut icon" href="./images/startupconnect_icon.png" />


    {/*} Bootstrap CSS */}
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous" />
 

    <title>theStartupConnect | Business Tools and Services</title>
  </head>
  <body>

     <div class="home1-container">

     {/* HEADER SECTION START */}
        <section class="home1-header-section">

        {/* NAVBAR START */}
            <div class="home1-navbar">
                <div class="home1-navbar-left">
                    <ul>
                    <li><img src="/home1/weblogo1.png" id="home1-navbar-logo" ></img></li>
                    <li><h3><span style={{color:"#5d17eb"}}>Startup </span>Connect</h3></li>
                    </ul>
                </div>
                <div class="home1-navbar-right">
                    <ul>
                        <li>Services</li>
                        <li>Tools</li>
                        <li>Network</li>
                        <li>Team</li>
                        <li>Contests</li>
                        <li>Hiring</li>
                        <li><button id="home1-navbar-login">Login</button></li>
                        <li><button id="home1-navbar-register">Register</button></li>
                    </ul>
                </div>
            </div>
            {/* NAVBAR END */}

            {/* MAIN HEADER SECTION START */}
            <div class="home1-header-main">
                <div class="home1-header-main-left">
                <h1>START BUILDING YOUR STARTUP WITH US</h1>
                <p>Start your entrepreneurship journey today with us. Start with whatever you wnat we are her to provide you with best class solutions, softwraes, support and anything else required for building your business. One extra line for feel.</p>
                <button id="home1-header-main-btn">Services <i class="mdi mdi-arrow-right"></i></button>
                <button id="home1-header-main-btn" style={{backgroundImage: "linear-gradient(to right, #8d1777, #ad0082)"}}>Network <i class="mdi mdi-arrow-right"></i></button>
                <button id="home1-header-main-btn" style={{backgroundImage: "linear-gradient(to right, #d4ba37, #fadc13)"}}>Hiring <i class="mdi mdi-arrow-right"></i></button>
                <br />
                <button id="home1-header-main-btn" style={{backgroundImage: "linear-gradient(to right, #8d3717, #ad3900)"}}>Tools <i class="mdi mdi-arrow-right"></i></button>
                <button id="home1-header-main-btn" style={{backgroundImage: "linear-gradient(to right, #208d17, #00ad11)"}}>Contests <i class="mdi mdi-arrow-right"></i></button>

                </div>
                <div class="home1-header-main-right">
                
                    </div>
            </div>
            {/* MAIN HEADER SECTION END */}

        </section>
        {/* HEADER SECTION END */}



        <hr />
        {/* MODULES SECTION START */}
        <div class="home1-modules-section">
        <h1>START YOUR JOURNEY!</h1>
        <p>Startup Connect has been regularly working to improve its efficeiency and provide best place to users to find all their resources.</p>

        {/* SERVICES SECTION START */}
        <div class="home1-services-section">
        <div class="home1-services-section-container">
            <div class="home1-services-section-left">
                <h2>Startup Connect Services</h2>
                <p>Startup Connect’s Services portal provides a complete new approach to services management. Grt all your services deliverd through single services portal at cost effective prices. Startup Connect’s Services portal provides a complete new approach to services management.</p>
                <p>Start your journey today!</p>
                <div class="home1-services-features-list">
                    <div class="home1-services-fcard">
                       <div class="home1-services-fcard-left">
                        <img src="https://cdn-icons-png.flaticon.com/128/1087/1087840.png" id="home1-services-fcard-icon" />
                       </div>
                       <div class="home1-services-fcard-right">
                       <h3>500+</h3>
                       <h5 style={{fontWeight:"bold"}}>Projects</h5>
                       </div> 
                    </div>
                

                    <div class="home1-services-fcard">
                       <div class="home1-services-fcard-left">
                        <img src="https://cdn-icons-png.flaticon.com/128/12735/12735475.png" id="home1-services-fcard-icon" />
                       </div>
                       <div class="home1-services-fcard-right">
                       <h3>25+</h3>
                       <h5 style={{fontWeight:"bold"}}>Cities</h5>
                       </div> 
                    </div>
             

                    <div class="home1-services-fcard">
                       <div class="home1-services-fcard-left">
                        <img src="https://cdn-icons-png.flaticon.com/128/2991/2991235.png" id="home1-services-fcard-icon" />
                       </div>
                       <div class="home1-services-fcard-right">
                       <h3>4 <img src="https://cdn-icons-png.flaticon.com/128/7656/7656139.png" style={{height:"2rem",width:"2rem"}} />+</h3>
                       <h5 style={{fontWeight:"bold"}}>Google Ratings</h5>
                       </div> 
                    </div>

                </div>
                <button id="home1-modules-section-btn">Get Started with Services <i class="mdi mdi-arrow-right"></i></button>
            </div>
            <div class="home1-services-section-right">
            <img src="https://media.istockphoto.com/id/1144645520/photo/busy-call-centre-in-operation.jpg?b=1&s=612x612&w=0&k=20&c=vib4nWV1c_PLKXZa6aqHDEU-fVBg6-WeIGfmik2zj2E=" style={{height:"60%",marginTop:"20%",width:"96%",marginRight:"4%"}} />
            </div>
        </div> 
        </div>
        {/* SERVICES SECTION END */}


       <hr />
       {/* TOOLS SECTION START */}
       <div class="home1-tools-section">
       <h2>Startup Connect Business Tools</h2>
       <p>Startup Connect’s Services portal provides a complete new approach to services management. </p>
       <p>Grt all your services deliverd through single services portal at cost effective prices.</p>
       <p>Start your journey today!</p>
            <div class="home1-tools-section-container">
            <div class="home1-tools-section-left">
            <img src="https://tse4.mm.bing.net/th?id=OIP.KFSjZvbRzYNRce5jkc6uBQHaEK&pid=Api&P=0&h=180=" style={{height:"70%",marginTop:"15%",width:"96%",marginLeft:"4%",boxShadow:"#b0b0b0 0.2rem 0.2rem 0.3rem 0.2rem",borderRadius:"15px"}} />
            </div>
            <div class="home1-tools-section-right">
            <h3>Accounting Software</h3>
            <p>Accounting Solution for all your accounting needs. Simplify your accounts</p>

            <div class="home1-tools-fcard">
                <div class="home1-tools-fcard-left">
                <img src="https://cdn-icons-png.flaticon.com/128/4954/4954479.png" style={{height:"5rem",width:"5rem", marginTop:"2.5rem", boxShadow:"#b0b0b0 0.2rem 0.2rem 0.3rem 0.2rem",borderRadius:"15px"}} />
                </div>
                <div class="home1-tools-fcard-right">
                    <h5>Easy Management:</h5>
                    <p>easily manage your accounts from anywhere. With maximum ease.</p>
                </div>
            </div>

            <div class="home1-tools-fcard">
                <div class="home1-tools-fcard-left">
                <img src="https://cdn-icons-png.flaticon.com/128/11331/11331591.png" style={{height:"5rem",width:"5rem", marginTop:"2.5rem", boxShadow:"#b0b0b0 0.2rem 0.2rem 0.3rem 0.2rem",borderRadius:"15px"}} />
                </div>
                <div class="home1-tools-fcard-right">
                    <h5>Cost Efficient:</h5>
                    <p>Startup Connect’s this solution is cost friendly ffor all the people who want to utilize this.</p>
                </div>
            </div>

            <button id="home1-header-main-btn" style={{backgroundImage: "linear-gradient(to right, #2c3ab4, #0300ad)"}}>Get Started<i class="mdi mdi-arrow-right"></i></button>

            </div>
           
            </div>
         
       </div>
       {/* TOOLS SECTION END */}
       <hr />
       {/* NETWORK SECTION START */}
       <div class="home1-network-section">
       <h2>Network with Startup Connect</h2>
       <p>Build your network with startup connect meet new people, new interactions, new insights, new everything to widen your perspective.</p>
       <p>Start earning with your expertise.</p>
       

       <div class="home1-network-container">

        <div class="home1-network-fcard">
            <div class="home1-network-fcard-top">
                <div class="home1-networrk-fcard-top-l">
                <img src="https://cdn-icons-png.flaticon.com/128/10010/10010599.png" style={{height:"5rem",width:"5rem", marginRight:"2rem" }} />
                </div>
                <div class="home1-network-fcard-top-r">
                    <h3>Mentor, Advisor and Investor</h3>
                </div>
            </div>
            <div class="home1-network-fcard-content">
                <p>Are you an investor, looking for any opportunity. This can be a perfect start to your journey.</p>
                <p>Are you an investor, looking for any opportunity. This can be a perfect start to your journey.</p>
                <p>Are you an investor, looking for any opportunity. This can be a perfect start to your journey.</p>

                <button id="home1-header-main-btn" style={{backgroundImage: "linear-gradient(to right, #8d1777, #ad0082)",float:"right"}}>I'm a Mentor<i class="mdi mdi-arrow-right"></i></button>

            </div>
           
        </div>


        <div class="home1-network-fcard" style={{marginTop:"1rem"}}>
            <div class="home1-network-fcard-top">
                <div class="home1-networrk-fcard-top-l">
                <img src="https://cdn-icons-png.flaticon.com/128/5064/5064971.png" style={{height:"5rem",width:"5rem", marginRight:"2rem" }} />
                </div>
                <div class="home1-network-fcard-top-r">
                    <h3>Startup or Business</h3>
                </div>
            </div>
            <div class="home1-network-fcard-content">
                <p>Are you a startup, looking for any opportunity. This can be a perfect start to your journey.</p>
                <p>Are you a startup, looking for any opportunity. This can be a perfect start to your journey..</p>
                <p>Are you a startup, looking for any opportunity. This can be a perfect start to your journey..</p>

                <button id="home1-header-main-btn" style={{backgroundImage: "linear-gradient(to right, #17888d, #004aad)",float:"right"}}>I'm a Startup<i class="mdi mdi-arrow-right"></i></button>

            </div>
           
        </div>



       </div>


       </div>
       {/* NETWORK SECTION END */}
       <hr/>

       {/* HIRING SECTION START */}
       <div class="home1-hiring-section">
       <h2>Startuphire.in by StartupConnect</h2>
       <p>your one stop solution for all hiring needs. Get quality talent hired with ease. No need to spend hours searching for right talent.</p>

        <div class="home1-hiring-container">
        <div class="home1-hiring-section-left">
        <img src="https://images.pexels.com/photos/1595385/pexels-photo-1595385.jpeg?auto=compress&cs=tinysrgb&w=600" style={{height:"40rem",marginTop:"10%",width:"96%",marginLeft:"4%"}} />
        </div>
        <div class="home1-hiring-section-right">
         
         <div class="home1-hiring-card">
            <div class="home1-hiring-card-left">
            <img src="https://cdn-icons-png.flaticon.com/128/5064/5064971.png" style={{height:"5rem",width:"5rem", marginRight:"2rem" }} />
            </div>
            <div class="home1-hiring-card-right">
                <h4>Fast Hiring</h4>
                <p>fast hiring process in the cart. To be continued as it is by the individuals</p>
            </div>
         </div>

         <div class="home1-hiring-card" style={{backgroundImage:"linear-gradient(to right, #cd8802, #d6c919)"}}>
         
            <div class="home1-hiring-card-right">
                <h4  style={{textAlign:"right"}}>Fast Hiring</h4>
                <p  style={{textAlign:"right"}}>fast hiring process in the cart. To be continued as it is by the individuals</p>
            </div>
            <div class="home1-hiring-card-left">
            <img src="https://cdn-icons-png.flaticon.com/128/5064/5064971.png" style={{height:"5rem",width:"5rem", marginRight:"2rem" }} />
            </div>
         </div>

         <div class="home1-hiring-card" style={{backgroundImage:"linear-gradient(to right, #17868d, #00adad)"}}>
            <div class="home1-hiring-card-left">
            <img src="https://cdn-icons-png.flaticon.com/128/5064/5064971.png" style={{height:"5rem",width:"5rem", marginRight:"2rem" }} />
            </div>
            <div class="home1-hiring-card-right">
                <h4>Fast Hiring</h4>
                <p>fast hiring process in the cart. To be continued as it is by the individuals</p>
            </div>
         </div>

       

        </div>

        </div>

        <button id="home1-header-main-btn" style={{backgroundImage: "linear-gradient(to right, #29b442, #18d85f)",width:"19rem"}}>Get Started as Employer<i class="mdi mdi-arrow-right"></i></button>
        <button id="home1-header-main-btn" style={{backgroundImage: "linear-gradient(to right, #8d1777, #ad0082)",width:"19rem"}}>Get Started as Candidate<i class="mdi mdi-arrow-right"></i></button>


       </div>
       {/* HIRING SECTION END */}
       <hr/>
       {/* CONTESTS SECTION START */}
       <div class="home1-contest-section">
       <h1>Why Choose Us ?</h1>
        <p>Startup Connect has been regularly working to improve its efficeiency and provide best place to users to find all their resources.</p>
        <div class="home1-contest-container" style={{marginTop:"5vh"}}>
       {/*}    <iframe width="900" height="560" src="https://www.youtube.com/embed/HrnrqYxYrbk?si=G9c5NEPXf3Tre9kp" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
       */} </div>
       

       <button id="home1-header-main-btn" style={{backgroundImage: "linear-gradient(to right, #d4ba37, #fadc13)",width:"12rem,marginTop:0"}}>Get Started<i class="mdi mdi-arrow-right"></i></button>
      </div>
       {/* CONTESTS SECTION END */}

        </div>
        {/* MODULES SECTION END */}




        <hr />
        {/* FEATURES SECTION START */}
        <div class="home1-features-section">
        <h1>Why Choose Us ?</h1>
        <p>Startup Connect has been regularly working to improve its efficeiency and provide</p>
        <p>best place to users to find all their resources.</p>
      
      {/* Features Row 1 Start */}
        <div class="home1-features-row">
        <div class="home1-features-card" style={{borderImage: "linear-gradient(to right, #17868d, #00adad) 1" }}>
                <div class="home1-features-card-top">
                <div class="home1-features-card-top-left" style={{backgroundImage: "linear-gradient(to right, #17868d, #00adad)"}}>
                <i class="mdi mdi-arrange-bring-to-front"></i>
                <img src="/home1/monitor.png" style={{height:"4rem",width:"3.5rem",padding:"0.5rem"}} />
                </div>
                <div class="home1-features-card-top-right">
                <h3 style={{color:"#1F8989"}}>Support</h3>
                </div>
                </div>
                <hr />
                <div class="home1-features-card-content">
                    <p>The scheduling of your transactions simplified with this.</p>
                </div>
            </div>


            <div class="home1-features-card" style={{borderImage: "linear-gradient(to right, #8d3717, #ad3900) 1" }}>
                <div class="home1-features-card-top">
                <div class="home1-features-card-top-left" style={{backgroundImage: "linear-gradient(to right, #8d3717, #ad3900)"}}>
                <i class="mdi mdi-arrange-bring-to-front"></i>
                <img src="/home1/monitor.png" style={{height:"4rem",width:"3.5rem",padding:"0.5rem"}} />
                </div>
                <div class="home1-features-card-top-right">
                <h3 style={{color:" #ad3900"}}>Efficiency</h3>
                </div>
                </div>
                <hr />
                <div class="home1-features-card-content">
                    <p>The scheduling of your transactions simplified with this.</p>
                </div>
            </div>


            <div class="home1-features-card" style={{borderImage: "linear-gradient(to right, #208d17, #00ad11) 1" }}>
                <div class="home1-features-card-top">
                <div class="home1-features-card-top-left" style={{backgroundImage: "linear-gradient(to right, #208d17, #00ad11)"}}>
                <i class="mdi mdi-arrange-bring-to-front"></i>
                <img src="/home1/monitor.png" style={{height:"4rem",width:"3.5rem",padding:"0.5rem"}} />
                </div>
                <div class="home1-features-card-top-right">
                <h3 style={{color:"#1BEB17"}}>Affordable</h3>
                </div>
                </div>
                <hr />
                <div class="home1-features-card-content">
                    <p>The scheduling of your transactions simplified with this.</p>
                </div>
            </div>

        </div>
        {/* Features Row 1 End */}

              {/* Features Row 2 Start */}
              <div class="home1-features-row">
            <div class="home1-features-card">
                <div class="home1-features-card-top">
                <div class="home1-features-card-top-left">
                <i class="mdi mdi-arrange-bring-to-front"></i>
                <img src="/home1/calendar.png" style={{height:"4rem",width:"3.5rem",padding:"0.5rem"}} />
                </div>
                <div class="home1-features-card-top-right">
                <h3>Scheduling</h3>
                </div>
                </div>
                <hr />
                <div class="home1-features-card-content">
                    <p>The scheduling of your transactions simplified with this.</p>
                </div>
            </div>


              <div class="home1-features-card" style={{borderImage: "linear-gradient(to right, #d4ba37, #fadc13) 1" }}>
                <div class="home1-features-card-top">
                <div class="home1-features-card-top-left" style={{backgroundImage: "linear-gradient(to right, #d4ba37, #fadc13)"}}>
                <i class="mdi mdi-arrange-bring-to-front"></i>
                <img src="/home1/monitor.png" style={{height:"4rem",width:"3.5rem",padding:"0.5rem"}} />
                </div>
                <div class="home1-features-card-top-right">
                <h3 style={{color:"#fadc13"}}>Monitoring</h3>
                </div>
                </div>
                <hr />
                <div class="home1-features-card-content">
                    <p>The scheduling of your transactions simplified with this.</p>
                </div>
            </div>


            <div class="home1-features-card" style={{borderImage: "linear-gradient(to right, #2c3ab4, #0300ad) 1" }}>
                <div class="home1-features-card-top">
                <div class="home1-features-card-top-left" style={{backgroundImage: "linear-gradient(to right, #2c3ab4, #0300ad)"}}>
                <i class="mdi mdi-arrange-bring-to-front"></i>
                <img src="/home1/monitor.png" style={{height:"4rem",width:"3.5rem",padding:"0.5rem"}} />
                </div>
                <div class="home1-features-card-top-right">
                <h3 style={{color:"#024BA9",marginLeft:"1rem"}}>Management</h3>
                </div>
                </div>
                <hr />
                <div class="home1-features-card-content">
                    <p>The scheduling of your transactions simplified with this.</p>
                </div>
            </div>


        </div>
        {/* Features Row 2 End */}

        </div>
        {/* FEATURES SECTION END */}



    
     </div>

      {/*} Optional JavaScript; choose one of the two! */}

    {/*} Option 1: Bootstrap Bundle with Popper */}
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>

    {/*} Option 2: Separate Popper and Bootstrap JS */}
    {/*}
    <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js" integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p" crossorigin="anonymous"></script>
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.min.js" integrity="sha384-cVKIPhGWiC2Al4u+LWgxfKTRIcfu0JTxR+EQDz/bgldoEyl4H0zUF0QKbrJ0EcQF" crossorigin="anonymous"></script>
    */}

    

    {/* plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/* endinject */}
    {/* Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/* End plugin js for this page */}
    {/* inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/* endinject */}
    {/* Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/* End custom js for this page */}

  </body>
</html>
</>
      );

}

export default Home1;