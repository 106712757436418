import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';



function Rightsidebar(){
    
    
    
 return(
    <>  

    {/* SIDEBAR RIGHT START */}
   <div class="hire-right-sidebar" id="right-sidebar">
   {/* Filter Section Start */}
   <h3>Filters</h3>
   <div id="line-1"></div> 
   {/* Location Filter Start */}
   <div class="filter-section">
   <h4>Filter by Location</h4>
  {/*} <div class="filter-form1">
       <select class="filter-form-input1" style={{width:"60%"}}>
           <option value="city">City</option>
           <option value="state">State</option>
           <option value="country">Country</option>
       </select>
   </div> */}


    {/* Country Filter Start */}
    <div class="filter-section-city-filter">
    <div class="filter-heading">
   <h5>Select Country</h5> 
   </div>
   <div class="filter-selection">
   <div class="filter-selection-text"><label>India</label></div>
   <div class="filter-selection-check"><input type="checkbox" value="Delhi" style={{borderColor:"blue"}} class="filter-selection-checkbox"></input></div>   
   </div>
   <div class="filter-selection">
   <div class="filter-selection-text"><label>Australia</label></div>
   <div class="filter-selection-check"><input type="checkbox" value="Delhi" style={{borderColor:"blue"}} class="filter-selection-checkbox"></input></div>   
   </div>
   <div class="filter-selection">
   <div class="filter-selection-text"><label>USA</label></div>
   <div class="filter-selection-check"><input type="checkbox" value="Delhi" style={{borderColor:"blue"}} class="filter-selection-checkbox"></input></div>   
   </div>
   <div class="filter-selection">
   <div class="filter-selection-text"><label>Switzerland</label></div>
   <div class="filter-selection-check"><input type="checkbox" value="Delhi" style={{borderColor:"blue"}} class="filter-selection-checkbox"></input></div>   
   </div> 
   <p class="view-al">View All</p>


   </div>
   {/* Country Filter End */}
    {/* State Filter Start */}
    <div class="filter-section-city-filter">
   <h5>Select State</h5> 
   <div class="filter-selection">
   <div class="filter-selection-text"><label>Madhya Pradesh</label></div>
   <div class="filter-selection-check"><input type="checkbox" value="Delhi" style={{borderColor:"blue"}} class="filter-selection-checkbox"></input></div>   
   </div>
   <div class="filter-selection">
   <div class="filter-selection-text"><label>Goa</label></div>
   <div class="filter-selection-check"><input type="checkbox" value="Delhi" style={{borderColor:"blue"}} class="filter-selection-checkbox"></input></div>   
   </div>
   <div class="filter-selection">
   <div class="filter-selection-text"><label>Maharashtra</label></div>
   <div class="filter-selection-check"><input type="checkbox" value="Delhi" style={{borderColor:"blue"}} class="filter-selection-checkbox"></input></div>   
   </div>
   <div class="filter-selection">
   <div class="filter-selection-text"><label>Gujarat</label></div>
   <div class="filter-selection-check"><input type="checkbox" value="Delhi" style={{borderColor:"blue"}} class="filter-selection-checkbox"></input></div>   
   </div>
   <p class="view-al">View All</p>
   </div>
  
    {/* State Filter End */}
   {/* City Filter  Start */}
   <div class="filter-section-city-filter">
   <h5>Select City</h5> 
   <div class="filter-selection">
   <div class="filter-selection-text"><label>Delhi</label></div>
   <div class="filter-selection-check"><input type="checkbox" value="Delhi" style={{borderColor:"blue"}} class="filter-selection-checkbox"></input></div>   
   </div>
   <div class="filter-selection">
   <div class="filter-selection-text"><label>Mumbai</label></div>
   <div class="filter-selection-check"><input type="checkbox" value="Delhi" style={{borderColor:"blue"}} class="filter-selection-checkbox"></input></div>   
   </div>
   <div class="filter-selection">
   <div class="filter-selection-text"><label>Bangalore</label></div>
   <div class="filter-selection-check"><input type="checkbox" value="Delhi" style={{borderColor:"blue"}} class="filter-selection-checkbox"></input></div>   
   </div>
   <div class="filter-selection">
   <div class="filter-selection-text"><label>Pune</label></div>
   <div class="filter-selection-check"><input type="checkbox" value="Delhi" style={{borderColor:"blue"}} class="filter-selection-checkbox"></input></div>   
   </div>
   <p class="view-al">View All</p>
   </div>
    {/* City Filter End */}
    </div>
   {/* Location Filter End */}

   <div id="line-1"></div> 
   {/* Job Role Filter Start */}
   <div class="filter-section">
   <h4>Filter by Job Role</h4>
    {/* Job Role Filter Start */}
    <div class="filter-section-city-filter">
   
   <div class="filter-selection">
   <div class="filter-selection-text"><label>Project Manager</label></div>
   <div class="filter-selection-check"><input type="checkbox" value="Delhi" style={{borderColor:"blue"}} class="filter-selection-checkbox"></input></div>   
   </div>
   <div class="filter-selection">
   <div class="filter-selection-text"><label>Digital Marketer</label></div>
   <div class="filter-selection-check"><input type="checkbox" value="Delhi" style={{borderColor:"blue"}} class="filter-selection-checkbox"></input></div>   
   </div>
   <div class="filter-selection">
   <div class="filter-selection-text"><label>Website Developer</label></div>
   <div class="filter-selection-check"><input type="checkbox" value="Delhi" style={{borderColor:"blue"}} class="filter-selection-checkbox"></input></div>   
   </div>
   <div class="filter-selection">
   <div class="filter-selection-text"><label>Social Media Manager</label></div>
   <div class="filter-selection-check"><input type="checkbox" value="Delhi" style={{borderColor:"blue"}} class="filter-selection-checkbox"></input></div>   
   </div> 
   <p class="view-al">View All</p>
   </div>
   {/* Job Role Filter End */}

    </div>
   {/* Job Role Filter End */}

   {/* Skills Filter Start */}
   
   <div id="line-1"></div> 
   <div class="filter-section">
   <h4>Filter by Skills</h4>
    {/* Skills Filter Start */}
    <div class="filter-section-city-filter">
   
   <div class="filter-selection">
   <div class="filter-selection-text"><label>HTML/ CSS</label></div>
   <div class="filter-selection-check"><input type="checkbox" value="Delhi" style={{borderColor:"blue"}} class="filter-selection-checkbox"></input></div>   
   </div>
   <div class="filter-selection">
   <div class="filter-selection-text"><label>React JS</label></div>
   <div class="filter-selection-check"><input type="checkbox" value="Delhi" style={{borderColor:"blue"}} class="filter-selection-checkbox"></input></div>   
   </div>
   <div class="filter-selection">
   <div class="filter-selection-text"><label>Python</label></div>
   <div class="filter-selection-check"><input type="checkbox" value="Delhi" style={{borderColor:"blue"}} class="filter-selection-checkbox"></input></div>   
   </div>
   <div class="filter-selection">
   <div class="filter-selection-text"><label>My Sql</label></div>
   <div class="filter-selection-check"><input type="checkbox" value="Delhi" style={{borderColor:"blue"}} class="filter-selection-checkbox"></input></div>   
   </div> 
   <p class="view-al">View All</p>
   </div>
   {/* Skills Filter End */}

    </div>
   {/* Skills Filter End */}

    {/* Industry Filter Start */}
   
    <div id="line-1"></div> 
   <div class="filter-section">
   <h4>Filter by Industry</h4>
    {/* Industry Filter Start */}
    <div class="filter-section-city-filter">
   
   <div class="filter-selection">
   <div class="filter-selection-text"><label>IT/ Tech</label></div>
   <div class="filter-selection-check"><input type="checkbox" value="Delhi" style={{borderColor:"blue"}} class="filter-selection-checkbox"></input></div>   
   </div>
   <div class="filter-selection">
   <div class="filter-selection-text"><label>Edu Tech</label></div>
   <div class="filter-selection-check"><input type="checkbox" value="Delhi" style={{borderColor:"blue"}} class="filter-selection-checkbox"></input></div>   
   </div>
   <div class="filter-selection">
   <div class="filter-selection-text"><label>Medical</label></div>
   <div class="filter-selection-check"><input type="checkbox" value="Delhi" style={{borderColor:"blue"}} class="filter-selection-checkbox"></input></div>   
   </div>
   <div class="filter-selection">
   <div class="filter-selection-text"><label>Marketing</label></div>
   <div class="filter-selection-check"><input type="checkbox" value="Delhi" style={{borderColor:"blue"}} class="filter-selection-checkbox"></input></div>   
   </div> 
   <p class="view-al">View All</p>
   </div>
   {/* Industry Filter End */}

    </div>
   {/* Company Filter End */}
       {/* Company Filter Start */}
   
       <div id="line-1"></div> 
   <div class="filter-section">
   <h4>Filter by Company</h4>
    {/* Company Filter Start */}
    <div class="filter-section-city-filter">
   
   <div class="filter-selection">
   <div class="filter-selection-text"><label>Byjus Private Limited</label></div>
   <div class="filter-selection-check"><input type="checkbox" value="Delhi" style={{borderColor:"blue"}} class="filter-selection-checkbox"></input></div>   
   </div>
   <div class="filter-selection">
   <div class="filter-selection-text"><label>Microsoft</label></div>
   <div class="filter-selection-check"><input type="checkbox" value="Delhi" style={{borderColor:"blue"}} class="filter-selection-checkbox"></input></div>   
   </div>
   <div class="filter-selection">
   <div class="filter-selection-text"><label>Amazon</label></div>
   <div class="filter-selection-check"><input type="checkbox" value="Delhi" style={{borderColor:"blue"}} class="filter-selection-checkbox"></input></div>   
   </div>
   <div class="filter-selection">
   <div class="filter-selection-text"><label>Accenture</label></div>
   <div class="filter-selection-check"><input type="checkbox" value="Delhi" style={{borderColor:"blue"}} class="filter-selection-checkbox"></input></div>   
   </div>
   <p class="view-al">View All</p> 
   </div>
   {/* Company Filter End */}

    </div>
   {/* Company Filter End */}

   <div id="line-1"></div> 
   {/* Experience Filter Start */}
   <div class="filter-section">
   <h4>Experience</h4>
   <p></p>
   
 <div class="filter-form-bar">
   <div class="filter-form-text">
   <label>Experience</label>
   </div>
   <div class="filter-form-input">
   <input type="text" placeholder="enter in years"></input>
   </div>
 </div>

    </div>

    {/* Job Type Filter End */}
       {/* Job Type Filter Start */}
   
       <div id="line-1"></div> 
   <div class="filter-section">
   <h4>Filter by Job Type</h4>
    {/* Job Type Filter Start */}
    <div class="filter-section-city-filter">
   
   <div class="filter-selection">
   <div class="filter-selection-text"><label>Full Time</label></div>
   <div class="filter-selection-check"><input type="checkbox" value="Delhi" style={{borderColor:"blue"}} class="filter-selection-checkbox"></input></div>   
   </div>
   <div class="filter-selection">
   <div class="filter-selection-text"><label>Part Time</label></div>
   <div class="filter-selection-check"><input type="checkbox" value="Delhi" style={{borderColor:"blue"}} class="filter-selection-checkbox"></input></div>   
   </div>
   <div class="filter-selection">
   <div class="filter-selection-text"><label>Contract</label></div>
   <div class="filter-selection-check"><input type="checkbox" value="Delhi" style={{borderColor:"blue"}} class="filter-selection-checkbox"></input></div>   
   </div>
   <div class="filter-selection">
   <div class="filter-selection-text"><label>Internship</label></div>
   <div class="filter-selection-check"><input type="checkbox" value="Delhi" style={{borderColor:"blue"}} class="filter-selection-checkbox"></input></div>   
   </div> 
   </div>
   {/* Job Type Filter End */}

    </div>
   {/* Job Type Filter End */}

       {/* Stipend Filter End */}
       {/* Stipend Filter Start */}
   
       <div id="line-1"></div> 
   <div class="filter-section">
   <h4>Filter by Stipend</h4>
    {/* Stipend Filter Start */}
    <div class="filter-section-city-filter">
   
   <div class="filter-selection">
   <div class="filter-selection-text"><label>Paid</label></div>
   <div class="filter-selection-check"><input type="checkbox" value="Delhi" style={{borderColor:"blue"}} class="filter-selection-checkbox"></input></div>   
   </div>
   <div class="filter-selection">
   <div class="filter-selection-text"><label>Unpaid</label></div>
   <div class="filter-selection-check"><input type="checkbox" value="Delhi" style={{borderColor:"blue"}} class="filter-selection-checkbox"></input></div>   
   </div>
 
   </div>
   {/* Stipend Filter End */}

    </div>
   {/* Stipend Filter End */}

   {/* Stipend Filter End */}


   <div id="line-1"></div> 
   {/* Duration Filter Start */}
   <div class="filter-section">
   <h4>Duration</h4>
   <p></p>
   
 <div class="filter-form-bar">
   <div class="filter-form-text">
   <label>Duration</label>
   </div>
   <div class="filter-form-input">
   <input type="text" placeholder="enter in months"></input>
   </div>
 </div>
 </div>

 <div id="line-1"></div> 
{/* Salary Filter Start */}
<div class="filter-section">
   <h4>Salary</h4>
   <p></p>
   
 <div class="filter-form-bar">
   <div class="filter-form-text">
   <label>Minimum</label>
   </div>
   <div class="filter-form-input">
   <input type="text" placeholder="enter in Rupees"></input>
   </div>
 </div>
 <div class="filter-form-bar">
   <div class="filter-form-text">
   <label>Maximum</label>
   </div>
   <div class="filter-form-input">
   <input type="text" placeholder="enter in Rupees"></input>
   </div>
 </div>
  {/* Salary Filter End */}

    </div>
 

   {/* Filter Section End */}
   </div>
   {/* SIDEBAR RIGHT END */}

   </>
 );

    }

    export default Rightsidebar;