import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';



function Leftsidebar(){
    
    
    
 return(
    <>  
    
    
      {/* SIDEBAR LEFT START */}
      <div class="hire-left-sidebar" id="left-sidebar">
        <h3>Jobs</h3>
        <ul type="none">
        <a href="/candidate-dashboard" style={{textDecoration:"none",color:"black"}}><li>Suggestions</li></a>
        <a href="/candidate-saved" style={{textDecoration:"none",color:"black"}}><li>Saved</li></a>
        <a href="/candidate-applied" style={{textDecoration:"none",color:"black"}}><li>Applied</li></a>
        <a href="/candidate-invitations" style={{textDecoration:"none",color:"black"}}><li>Invitations</li></a>
        <a href="/candidate-companies" style={{textDecoration:"none",color:"black"}}><li>Companies</li></a>
        </ul>
        </div>
        {/* SIDEBAR LEFT END */}

</>
 );

    }

    export default Leftsidebar;