import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../axios.js';
import Navbar from './Extracomponents/navbar.js';
import Sidebar from './Extracomponents/sidebar.js';
import Topanalytics from './Extracomponents/topanalytics.js';
import Topchart from './Extracomponents/topchart.js';



/*import Amanbg from './amanbg';*/


function Clientdashboard(){


  

   // Setting Values fom Session Storage
   const userlogstatus=sessionStorage.getItem("userloginstatus00");//loginstatus
   const loguser=sessionStorage.getItem("userusername00");//username
   const userlogtype=sessionStorage.getItem("userlogintype00");//logintype
   const userlogname=sessionStorage.getItem("userloginname00");//loginname
   const userlogadminid=sessionStorage.getItem("userloginadminid00");//loginadminid
   const userteamid=sessionStorage.getItem("userteamid00");//loginadminid


 //Checking if Sessions are Set

// alert(logstatus,loguser,logadminid);
 //Sessions Not Set
if(((userlogstatus=="false")||(userlogstatus==null))/*&&(userloguser==null)*/&&(userlogadminid==null)){
  
  sessionStorage.removeItem("username00");
  sessionStorage.removeItem("loginstatus00");
  sessionStorage.removeItem("logintype00");
  sessionStorage.removeItem("loginname00");
  sessionStorage.removeItem("loginadminid00");
 
  alert("Session Expired! Login Again.");
  window.location.replace("/");
 }

 //Sessions Set
 else{
     
    return(
<>
{/*<!DOCTYPE html> */}
<html lang="en">
  <head>
    {/* Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>theStartupConnect | Portal</title>
    {/* plugins:css */}
    <link rel="stylesheet" href="./assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="./assets/vendors/css/vendor.bundle.base.css" />
    {/* endinject */}
    {/* Plugin css for this page */}
    {/* End plugin css for this page */}
    {/* inject:css */}
    {/* endinject */}
    {/* Layout styles */}
    <link rel="stylesheet" href="./assets/css/style.css" />
    {/* End layout styles */}
   {/*} <link rel="shortcut icon" href="./assets/images/favicon.ico" /> */}
  </head>
  <body>

<Navbar/>

{/* MAIN PAGE COMPONENT START */}
  <div class="container-fluid page-body-wrapper">

 <Sidebar/>


{/* MAIN PANEL START */}
<div class="main-panel" style={{marginTop:"5vh"}}>
<div class="content-wrapper">

         <Topanalytics/>

{/*<Pageheader/>
<Topanalytics/>*/}
<div class="col-lg-12 grid-margin stretch-card" >
                <div class="card" style={{overflow:"auto",overflowX:"scroll",overflowY:"scroll"}}>
                  <div class="card-body">
                    <h4 class="card-title">My Projects</h4>

                    <table class="table table-bordered" >
                      <thead>
                        <tr>
                        <th> Project Id </th>
                          <th> Start Date </th>
                          <th>Title</th>
                          <th>Description</th>
                          <th>Status</th>
                          <th> Progress </th>
                          <th> Deadline </th>
                          <th> View </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td> 1 </td>
                          <td>23-06-2023</td>
                          <td> Website Development </td>
                          <td>This is my website development project</td>
                          <td><label class="badge badge-danger">Pending</label></td>
                          <td>
                            <div class="progress">
                              <div class="progress-bar bg-success" role="progressbar" style={{width: "25%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                          </td>
                          <td> May 15, 2015 </td>
                          <td><a href="/project-dashboard"><button type="button" class="btn btn-gradient-dark" >View</button></a></td>
                        </tr>
                        <tr>
                          <td> 2 </td>
                          <td>23-06-2023</td>
                          <td> Website Development </td>
                          <td>This is my website development project</td>
                          <td><label class="badge badge-success">Completed</label></td>
                          <td>
                            <div class="progress">
                              <div class="progress-bar bg-danger" role="progressbar" style={{width: "75%"}} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                          </td>
                          <td> July 1, 2015 </td>
                            <td><a href="/project-dashboard"><button type="button" class="btn btn-gradient-dark" >View</button></a></td>
                        </tr>
                        <tr>
                          <td> 3 </td>
                          <td>23-06-2023</td>
                          <td> Website Development </td>
                          <td>This is my website development project</td>
                          <td><label class="badge badge-warning">In Progress</label></td>
                          <td>
                            <div class="progress">
                              <div class="progress-bar bg-warning" role="progressbar" style={{width: "90%"}} aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                          </td>
                          <td> Apr 12, 2015 </td>
                            <td><a href="/project-dashboard"><button type="button" class="btn btn-gradient-dark" >View</button></a></td>
                        </tr>
                        <tr>
                          <td> 4 </td>
                          <td>23-06-2023</td>
                          <td> Website Development </td>
                          <td>This is my website development project</td>
                          <td><label class="badge badge-danger">Pending</label></td>
                          <td>
                            <div class="progress">
                              <div class="progress-bar bg-primary" role="progressbar" style={{width: "50%"}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                          </td>
                          <td> May 15, 2015 </td>
                            <td><a href="/project-dashboard"><button type="button" class="btn btn-gradient-dark" >View</button></a></td>
                        </tr>
                        <tr>
                          <td> 5 </td>
                          <td>23-06-2023</td>
                          <td> Website Development </td>
                          <td>This is my website development project</td>
                          <td><label class="badge badge-warning">In Progress</label></td>
                          <td>
                            <div class="progress">
                              <div class="progress-bar bg-danger" role="progressbar" style={{width: "35%"}} aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                          </td>
                          <td> May 03, 2015 </td>
                            <td><a href="/project-dashboard"><button type="button" class="btn btn-gradient-dark" >View</button></a></td>
                        </tr>
                        <tr>
                          <td> 6 </td>
                          <td>23-06-2023</td>
                          <td> Website Development </td>
                          <td>This is my website development project</td>
                          <td><label class="badge badge-warning">In Progress</label></td>
                          <td>
                            <div class="progress">
                              <div class="progress-bar bg-info" role="progressbar" style={{width: "65%"}} aria-valuenow="65" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                          </td>
                          <td> April 05, 2015 </td>
                            <td><a href="/project-dashboard"><button type="button" class="btn btn-gradient-dark" >View</button></a></td>
                        </tr>
                        <tr>
                        <td>7</td>
                        <td>23-06-2023</td>
                          <td> Website Development </td>
                          <td>This is my website development project</td>
                          <td><label class="badge badge-success">Completed</label></td>
                          <td>
                            <div class="progress">
                              <div class="progress-bar bg-warning" role="progressbar" style={{width: "20%"}} aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                          </td>
                          <td> June 16, 2015 </td>
                            <td><a href="/project-dashboard"><button type="button" class="btn btn-gradient-dark" >View</button></a></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
    
</div>
</div>
{/* MAIN PANEL END */}

</div>
{/* MAIN PAGE COMPONENT END */}

    {/* container-scroller */}
    {/* plugins:js */}
    <script src="./assets/vendors/js/vendor.bundle.base.js"></script>
    {/* endinject */}
    {/* Plugin js for this page */}
    {/* End plugin js for this page */}
    {/* inject:js */}
    <script src="./assets/js/off-canvas.js"></script>
    <script src="./assets/js/hoverable-collapse.js"></script>
    <script src="./assets/js/misc.js"></script>

    {/*} plugins:js */}
    <script src="assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="assets/vendors/chart.js/Chart.min.js"></script>
    <script src="assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="assets/js/off-canvas.js"></script>
    <script src="assets/js/hoverable-collapse.js"></script>
    <script src="assets/js/misc.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="assets/js/dashboard.js"></script>
    <script src="assets/js/todolist.js"></script>
    {/*} End custom js for this page */}
    {/* endinject */}
  </body>
</html>
</>
    );
}
}

export default Clientdashboard;