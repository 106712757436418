import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';
import moment from 'moment/moment';

import '../Resources/dashboard.css';
import Navbar from '../navbar';
import {useRef as useref} from 'react';
import {useReactToPrint} from 'react-to-print';


function Purchasereturns_invoice(){

 


    //Invoice Print Function
    const componentRef = useref();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });




 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const busyid=sessionStorage.getItem("BusyId00");
 const counterid=sessionStorage.getItem("CounterId00");
 const trno=sessionStorage.getItem("Trno00");

 //Checking if Sessions are Set

// alert(logstatus,loguser,logadminid);
 //Sessions Not Set
 {/*if(((logstatus==null)||(logstatus==""))||((logid==null)||(logid==""))||((token==null)||(token==" "))||((busyid==null)||(busyid==" "))||((counterid==null)||(counterid==" "))||((trno==null)||(trno==" "))){
  
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");
   sessionStorage.removeItem("Tr_no00");
 
    window.location.replace("/businesstools-login");
   }
  
   //Sessions Set
   else{
   */}

    
 
{/* TRANSACTIONS TABLE COLUMNS MANAGEMENT VARIABLES START */}
const[cd_col1_val,setcd_col1_val] = useState(0);//column1
const[cd_col2_val,setcd_col2_val] = useState(0);//column2
const[cd_col3_val,setcd_col3_val] = useState(0);//column3
const[cd_col4_val,setcd_col4_val] = useState(0);//column4
const[cd_col5_val,setcd_col5_val] = useState(0);//column5
const[cd_col6_val,setcd_col6_val] = useState(0);//column6
const[cd_col7_val,setcd_col7_val] = useState(0);//column7
const[cd_col8_val,setcd_col8_val] = useState(0);//column8
const[cd_col9_val,setcd_col9_val] = useState(0);//column9
const[cd_col10_val,setcd_col10_val] = useState(0);//column10
const[cd_col11_val,setcd_col11_val] = useState(0);//column11
const[cd_col12_val,setcd_col12_val] = useState(0);//column12
const[cd_col13_val,setcd_col13_val] = useState(0);//column13
const[cd_col14_val,setcd_col14_val] = useState(0);//column14 
const[cd_col15_val,setcd_col15_val] = useState(0);//column15 
const[cd_col16_val,setcd_col16_val] = useState(0);//column16 
const[cd_col17_val,setcd_col17_val] = useState(0);//column17 

{/* TRANSACTIONS TABLE COLUMNS MANAGEMENT VARIABLES END */}
    
      
//Variables


//Business Information
const[business_name,setbusiness_name] = useState();//business_name
const[business_email,setbusiness_email] = useState();//business_email
const[business_contact,setbusiness_contact] = useState();//business_contact
const[business_wtp,setbusiness_wtp] = useState();//business_wtp
const[business_gstin,setbusiness_gstin] = useState();//business_gstin
const[business_license_no,setbusiness_license_no] = useState();//business_license_no
const[business_address,setbusiness_address] = useState();//business_address
const[business_city,setbusiness_city] = useState();//business_city
const[business_state,setbusiness_state] = useState();//business_state
const[business_country,setbusiness_country] = useState();//business_country
const[business_pincode,setbusiness_pincode] = useState();//business_pincode

//supplier Information
const[supplier_name,setsupplier_name] = useState();//supplier_name
const[supplier_email,setsupplier_email] = useState();//supplier_email
const[supplier_contact,setsupplier_contact] = useState();//supplier_contact
const[supplier_wtp,setsupplier_wtp] = useState();//supplier_wtp
const[supplier_gstin,setsupplier_gstin] = useState();//supplier_gstin
const[supplier_license_no,setsupplier_license_no] = useState();//supplier_license_no
const[supplier_address,setsupplier_address] = useState();//supplier_address
const[supplier_city,setsupplier_city] = useState();//supplier_city
const[supplier_state,setsupplier_state] = useState();//supplier_state
const[supplier_country,setsupplier_country] = useState();//supplier_country
const[supplier_pincode,setsupplier_pincode] = useState();//supplier_pincode

//Invoice Information
const[date,setdate] = useState();//date
const[voucher_no,setvoucher_no] = useState();//voucher_no
const[type,settype] = useState();//type
const[bank_name,setbank_name] = useState();//bank_name
const[items_count,setitems_count] = useState();//items_count



const[inventorylist,setinventorylist] = useState([]);//SetData Variable


//Final Values
const[amount,setamount] = useState();//amount
const[other_expenses,setother_expenses] = useState();//other_expenses
const[other_deductions,setother_deductions] = useState();//other_deductions
const[shipping_carriages,setshipping_carriages] = useState();//shipping_carriages
const[total,settotal] = useState();//total


//Others
const[date_updated,setdate_updated] = useState();//date_updated
const[time_updated,settime_updated] = useState();//timeupdated



const[data,setData] = useState([]);//SetData Variable

//Error Variable
const[errorvar,seterrorvar] = useState();


useEffect(()=>{
   
  getData();
  
  },[])
 

    
//******************* GET PURCHASERETURNS DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&((trno!=null)||(trno!=" "))){
 
 
 axios.post("bt-view-purchasereturns-invoice", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Tr_no:trno,
   Sn_start :0,
   Sn_end :0,
   Sn_total :0,
   Sn_limit :0
 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");
     sessionStorage.removeItem("Tr_no00");

     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){

   //Setting Data


  //Business Information
  setbusiness_name(response.data.business_name);
  setbusiness_email(response.data.business_email);
  setbusiness_contact(response.data.business_contact);
  setbusiness_wtp(response.data.business_wtp);
  setbusiness_address(response.data.business_address);
  setbusiness_city(response.data.business_city);
  setbusiness_state(response.data.business_state);
  setbusiness_country(response.data.business_country);
  setbusiness_pincode(response.data.business_pincode);
  setbusiness_gstin(response.data.business_gstin);
  setbusiness_license_no(response.data.business_license_no);


  //supplier Information
  setsupplier_name(response.data.supplier_name);
  setsupplier_email(response.data.supplier_email);
  setsupplier_contact(response.data.supplier_contact);
  setsupplier_wtp(response.data.supplier_wtp);
  setsupplier_address(response.data.supplier_address);
  setsupplier_city(response.data.supplier_city);
  setsupplier_state(response.data.supplier_state);
  setsupplier_country(response.data.supplier_country);
  setsupplier_pincode(response.data.supplier_pincode);
  setsupplier_gstin(response.data.supplier_gstin);
  setsupplier_license_no(response.data.supplier_license_no);


  //Invoice Information
  setdate(response.data.date);
  settype(response.data.type);
  setbank_name(response.data.bank_name);
  setvoucher_no(response.data.voucher_no);
  setitems_count(response.data.items_count);


  //Inventory List
  setinventorylist(response.data.inventorylist);

  //Final Values
  setamount(response.data.amount);
  setother_expenses(response.data.other_expenses);
  setother_deductions(response.data.other_deductions);
  setshipping_carriages(response.data.shipping_carriages);
  settotal(response.data.total);

  //Others
  setdate_updated(response.data.date_updated);
  settime_updated(response.data.time_updated);

   //setData(response.data.data);
  
  }
  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("CounterId00");
   sessionStorage.removeItem("Tr_no00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* GET PURCHASERETURNS DATA FUNCTION END  ********************//




    return(
    <>
        <html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Business Tools|BookKeeping Solution</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div class="container-scroller">
    <div class="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div class="main-panel">
          <div class="content-wrapper pb-0">

                       {/* INVOICE COLUMNS MANAGEMENT START */}
                       <div class="col-12" style={{marginBottom:"2rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                        {/* COLUMNS MANAGEMENT DROPDOWN START */}
                          <div class="dropdown">
                            <button class="btn btn-primary dropdown-toggle" style={{marginLeft:"1rem"}} type="button" id="dropdownMenuIconButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="mdi mdi-table-large"></i>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuIconButton1">
                              <h6 class="dropdown-header">Columns</h6>
                              <hr/>
                              
                              {/* Column 1 */}
                              <li><div class="d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Sn</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c1_yes" onClick={()=>{setcd_col1_val(1); document.getElementById("cd_c1_yes").style.display="none"; document.getElementById("cd_c1_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c1_no" style={{ display:"none"}} onClick={()=>{ setcd_col1_val(0); document.getElementById("cd_c1_yes").style.display="block"; document.getElementById("cd_c1_no").style.display="none"; }}></i>
                              </div></div></li> 
                               {/* Column 2 */}
                               <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">P_code</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c2_yes"  onClick={()=>{setcd_col2_val(1); document.getElementById("cd_c2_yes").style.display="none"; document.getElementById("cd_c2_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c2_no" style={{ display:"none"}} onClick={()=>{ setcd_col2_val(0); document.getElementById("cd_c2_yes").style.display="block"; document.getElementById("cd_c2_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 3 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">HSN</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c3_yes"  onClick={()=>{setcd_col3_val(1); document.getElementById("cd_c3_yes").style.display="none"; document.getElementById("cd_c3_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c3_no" style={{ display:"none"}} onClick={()=>{ setcd_col3_val(0); document.getElementById("cd_c3_yes").style.display="block"; document.getElementById("cd_c3_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 4 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Product Name</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c4_yes"  onClick={()=>{setcd_col4_val(1); document.getElementById("cd_c4_yes").style.display="none"; document.getElementById("cd_c4_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c4_no" style={{ display:"none"}} onClick={()=>{ setcd_col4_val(0); document.getElementById("cd_c4_yes").style.display="block"; document.getElementById("cd_c4_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 5 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Quantity</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c5_yes"  onClick={()=>{setcd_col5_val(1); document.getElementById("cd_c5_yes").style.display="none"; document.getElementById("cd_c5_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c5_no" style={{ display:"none"}} onClick={()=>{ setcd_col5_val(0); document.getElementById("cd_c5_yes").style.display="block"; document.getElementById("cd_c5_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 6 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Rate</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c6_yes"  onClick={()=>{setcd_col6_val(1); document.getElementById("cd_c6_yes").style.display="none"; document.getElementById("cd_c6_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c6_no" style={{ display:"none"}} onClick={()=>{ setcd_col6_val(0); document.getElementById("cd_c6_yes").style.display="block"; document.getElementById("cd_c6_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 7 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Dis. %</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c7_yes" onClick={()=>{setcd_col7_val(1); document.getElementById("cd_c7_yes").style.display="none"; document.getElementById("cd_c7_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c7_no" style={{ display:"none"}} onClick={()=>{ setcd_col7_val(0); document.getElementById("cd_c7_yes").style.display="block"; document.getElementById("cd_c7_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 8 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Dis. Amt</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c8_yes" onClick={()=>{setcd_col8_val(1); document.getElementById("cd_c8_yes").style.display="none"; document.getElementById("cd_c8_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c8_no" style={{ display:"none"}} onClick={()=>{ setcd_col8_val(0); document.getElementById("cd_c8_yes").style.display="block"; document.getElementById("cd_c8_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 9 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Sgst %</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c9_yes" onClick={()=>{setcd_col9_val(1); document.getElementById("cd_c9_yes").style.display="none"; document.getElementById("cd_c9_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c9_no" style={{ display:"none"}} onClick={()=>{ setcd_col9_val(0); document.getElementById("cd_c9_yes").style.display="block"; document.getElementById("cd_c9_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 10 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Sgst Amt</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c10_yes" onClick={()=>{setcd_col10_val(1); document.getElementById("cd_c10_yes").style.display="none"; document.getElementById("cd_c10_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c10_no" style={{ display:"none"}} onClick={()=>{ setcd_col10_val(0); document.getElementById("cd_c10_yes").style.display="block"; document.getElementById("cd_c10_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 11 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Cgst %</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c11_yes" onClick={()=>{setcd_col11_val(1); document.getElementById("cd_c11_yes").style.display="none"; document.getElementById("cd_c11_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c11_no" style={{ display:"none"}} onClick={()=>{ setcd_col11_val(0); document.getElementById("cd_c11_yes").style.display="block"; document.getElementById("cd_c11_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 12 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Cgst Amt</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c12_yes" onClick={()=>{setcd_col12_val(1); document.getElementById("cd_c12_yes").style.display="none"; document.getElementById("cd_c12_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c12_no" style={{ display:"none"}} onClick={()=>{ setcd_col12_val(0); document.getElementById("cd_c12_yes").style.display="block"; document.getElementById("cd_c12_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 13 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Igst %</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c13_yes" onClick={()=>{setcd_col13_val(1); document.getElementById("cd_c13_yes").style.display="none"; document.getElementById("cd_c13_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c13_no" style={{ display:"none"}} onClick={()=>{ setcd_col13_val(0); document.getElementById("cd_c13_yes").style.display="block"; document.getElementById("cd_c13_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 14 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Igst Amt</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c14_yes" onClick={()=>{setcd_col14_val(1); document.getElementById("cd_c14_yes").style.display="none"; document.getElementById("cd_c14_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c14_no" style={{ display:"none"}} onClick={()=>{ setcd_col14_val(0); document.getElementById("cd_c14_yes").style.display="block"; document.getElementById("cd_c14_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 15 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Tax %</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c15_yes" onClick={()=>{setcd_col15_val(1); document.getElementById("cd_c15_yes").style.display="none"; document.getElementById("cd_c15_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c15_no" style={{ display:"none"}} onClick={()=>{ setcd_col15_val(0); document.getElementById("cd_c15_yes").style.display="block"; document.getElementById("cd_c15_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 16 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Tax Amt</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c16_yes" onClick={()=>{setcd_col16_val(1); document.getElementById("cd_c16_yes").style.display="none"; document.getElementById("cd_c16_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c16_no" style={{ display:"none"}} onClick={()=>{ setcd_col16_val(0); document.getElementById("cd_c16_yes").style.display="block"; document.getElementById("cd_c16_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 17 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Total</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c17_yes" onClick={()=>{setcd_col17_val(1); document.getElementById("cd_c17_yes").style.display="none"; document.getElementById("cd_c17_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c17_no" style={{ display:"none"}} onClick={()=>{ setcd_col17_val(0); document.getElementById("cd_c17_yes").style.display="block"; document.getElementById("cd_c17_no").style.display="none"; }}></i>
                              </div></div></li> 
                              
                           
                            {/*}  <div class="dropdown-divider"></div>
                              <button class="btn btn-danger">Close</button>*/}
                            </div> 
                          </div>
                          {/* COLUMNS MANAGEMENT DROPDOWN END */}

                          </div>
                          </div>
                          {/* INVOICE COLUMNS MANAGEMENT END */}

          <div class="card" id="invoice" ref={componentRef}>
          <code>{errorvar}</code>
              
                  <div class="card-body">
                    <div style={{backgroundColor:"#2d6cb5",borderRadius:"5px"}}><h2 class="card-title" style={{color:"#ffffff",padding:"0.5rem"}}>Purchase Return Invoice</h2></div>
                    <div class="form-sample">
                      <p class="card-description"></p>


                    {/*}    <div class="row" style={{textAlign:"left"}}>            


                      <div class="col-md-4">
                         <h4>From</h4>
                         <hr style={{color:"#2d6cb5",padding:"0.1rem"}}/>
                         <h3>{business_name}</h3>
                         <address><b><i class="mdi mdi-map-marker-radius text-warning"></i></b> {business_address}</address>
                         <address>{business_city}, {business_state} ({business_country})</address>
                         <address>Pincode : {business_pincode}</address>
                         <hr/>
                         <address><b>GSTIN :</b> {business_gstin}  <b>| License No. :</b> {business_license_no}</address>
                         <address></address>
                         <hr/>
                         <address><b><i class="mdi mdi-gmail icon text-danger"></i></b> {business_email}
                         <b><i class="mdi mdi-phone-in-talk icon text-primary" style={{marginLeft:"2rem"}}></i></b> {business_contact}</address>
                         <address></address>
                        </div>
                        <div class="col-md-4">
                        <h4>To</h4>
                        <hr style={{color:"#2d6cb5",padding:"0.1rem"}}/>
                        <h3>{supplier_name}</h3>
                         <address><b><i class="mdi mdi-map-marker-radius text-warning"></i></b> {supplier_address} </address>
                         <address>{supplier_city}, {supplier_state} ({supplier_country})</address>
                         <address>Pincode : {supplier_pincode}</address>
                         <hr/>
                         <address><b>GSTIN :</b> {supplier_gstin} <b>| License No. :</b> {supplier_license_no}</address>
                         <address></address>
                         <hr/>
                         <address><b><i class="mdi mdi-gmail icon text-danger"></i></b> {supplier_email}
                         <b><i class="mdi mdi-phone-in-talk icon text-primary" style={{marginLeft:"2rem"}}></i></b> {supplier_contact}</address>
                         <address></address>
                        </div>  
                        <div class="col-md-4">
                        <h4>Details</h4>
                        <hr style={{color:"#2d6cb5",padding:"0.1rem"}}/>
                        <address><b>Invoice No. :</b> {voucher_no}</address>
                        <address><b>Date :</b> {date}</address>
                        <address><b>Type :</b> {type} ({bank_name})</address>
                        <address><b>Created At :</b> {date_updated} ({time_updated})</address>
                        </div>   
                        </div> */}


                      
                        <div class="row" style={{display: "flex", justifyContent: "space-between", textAlign: "left", padding: "10px"}}>
    {/*} From Section */}
    <div class="column" style={{flex: 1, paddingRight: "20px"}}>
        <h6>From</h6>
        <hr style={{border: "1px solid #2d6cb5"}} />
        <h4>{business_name}</h4>
        <address><b><i class="mdi mdi-map-marker-radius text-warning"></i></b> {business_address}</address>
        <address>{business_city}, {business_state} ({business_country})</address>
        <address>Pincode: {business_pincode}</address>
        <hr/>
        <address><b>GSTIN:</b> {business_gstin} </address>
        <address><b>License No.:</b> {business_license_no}</address>
        <hr/>
        <address style={{textAlign:"left"}}><b><i class="mdi mdi-gmail icon text-danger"></i></b> {business_email}</address>
        <address style={{textAlign:"left"}}><b><i class="mdi mdi-phone-in-talk icon text-primary"></i></b> {business_contact}</address>
    </div>

    {/*} To Section */}
    <div class="column" style={{flex: 1, paddingRight: "20px"}}>
        <h6>To</h6>
        <hr style={{border: "1px solid #2d6cb5"}} />
        <h4>{supplier_name}</h4>
        <address><b><i class="mdi mdi-map-marker-radius text-warning"></i></b> {supplier_address}</address>
        <address>{supplier_city}, {supplier_state} ({supplier_country})</address>
        <address>Pincode: {supplier_pincode}</address>
        <hr/>
        <address style={{textAlign:"left"}}><b>GSTIN:</b> {supplier_gstin} </address>
        <address style={{textAlign:"left"}}><b>License No.:</b> {supplier_license_no}</address>
        <hr/>
        <address style={{textAlign:"left"}}><b><i class="mdi mdi-gmail icon text-danger"></i></b> {supplier_email}<br/></address>
        <address style={{textAlign:"left"}}><b><i class="mdi mdi-phone-in-talk icon text-primary"></i></b> {supplier_contact}</address>
    </div>

    {/*} Details Section */}
    <div class="column" style={{flex: 1}}>
        <h6>Details</h6>
        <hr style={{border: "1px solid #2d6cb5"}} />
        <address><b>Invoice No.:</b> {voucher_no}</address>
        <address><b>Date:</b> {date}</address>
        <address><b>Type:</b> {type} ({bank_name})</address>
        <address><b>Created At:</b> {date_updated} ({time_updated})</address>
    </div>
</div>



                        <hr style={{color:"#2d6cb5",padding:"0.1rem"}} />
                        <div class="row">
                        <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>

                            <tr>
                                <th hidden={cd_col1_val==1}>Sn</th>
                                <th hidden={cd_col2_val==1}>P_code</th>
                                <th hidden={cd_col3_val==1}>HSN</th>
                                <th hidden={cd_col4_val==1}>Product Name</th>
                                <th hidden={cd_col5_val==1}>Quantity</th>
                                <th hidden={cd_col6_val==1}>Rate</th>
                                <th hidden={cd_col7_val==1}>Dis. %</th>
                                <th hidden={cd_col8_val==1}>Dis. Amt</th>
                                <th hidden={cd_col9_val==1}>Sgst %</th>
                                <th hidden={cd_col10_val==1}>Sgst Amt</th>
                                <th hidden={cd_col11_val==1}>Cgst %</th>
                                <th hidden={cd_col12_val==1}>Cgst Amt</th>
                                <th hidden={cd_col13_val==1}>Igst %</th>
                                <th hidden={cd_col14_val==1}>Igst Amt</th>
                                <th hidden={cd_col15_val==1}>Tax %</th>
                                <th hidden={cd_col16_val==1}>Tax Amt</th>
                                <th hidden={cd_col17_val==1}>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                       inventorylist.map((curElm,ind)=>{

                         return(
                         <>
                                <tr>
                                    <td hidden={cd_col1_val==1}>{curElm.Sn}</td>
                                    <td hidden={cd_col2_val==1}>{curElm.Product_code}</td>
                                    <td hidden={cd_col3_val==1}>{curElm.Hsn_code}</td>
                                    <td hidden={cd_col4_val==1}>{curElm.Product_name}</td>
                                    <td hidden={cd_col5_val==1}>{curElm.Qty_out} {curElm.Units}</td>
                                    <td hidden={cd_col6_val==1}>{curElm.Rate_out}</td>
                                    <td hidden={cd_col7_val==1}>{curElm.Dis_out}</td>
                                    <td hidden={cd_col8_val==1}>{curElm.Dis_amt_out}</td>
                                    <td hidden={cd_col9_val==1}>{curElm.Sgst_out}</td>
                                    <td hidden={cd_col10_val==1}>{curElm.Sgst_amt_out}</td>
                                    <td hidden={cd_col11_val==1}>{curElm.Cgst_out}</td>
                                    <td hidden={cd_col12_val==1}>{curElm.Cgst_amt_out}</td>
                                    <td hidden={cd_col13_val==1}>{curElm.Igst_out}</td>
                                    <td hidden={cd_col14_val==1}>{curElm.Igst_amt_out}</td>
                                    <td hidden={cd_col15_val==1}>{curElm.Tax_out}</td>
                                    <td hidden={cd_col16_val==1}>{curElm.Tax_amt_out}</td>
                                    <td hidden={cd_col17_val==1}>{curElm.Total_out}</td>

                                </tr>
                                </>
                         )
                         }
					   
	                  		 )
                         }
                                

                            </tbody>
                        </table>
                        </div>
                        </div>

                        <hr style={{color:"#2d6cb5",padding:"0.1rem"}} />
                        <p style={{textAlign:"left"}}><b>Total :</b> {amount}</p>
                        <div class="row mt-4">
                        <div class="d-flex justify-content-between">
                            <div class="col-md-6"></div>
                            <div class="col-md-6">
                                <table class="table table-striped" style={{textAlign:'left'}}>
                                  
                                   
                                    <tr ><td style={{padding:"1rem"}}><b>Shiping and Carriages :</b></td><td style={{padding:"1rem"}}>{shipping_carriages}</td></tr>
                                    <tr><td style={{padding:"1rem"}}><b>Other Deductions :</b></td><td style={{padding:"1rem"}}>{other_deductions}</td></tr>
                                    <tr><td style={{padding:"1rem"}}><b>Other Expenses :</b></td><td style={{padding:"1rem"}}>{other_expenses}</td></tr>
                                    
                                    <tr><td style={{padding:"1rem"}}><b>Net Payable Amount :</b></td><td style={{padding:"1rem"}}><b>{total}</b></td></tr>
                                </table>
                            </div>
                        </div>
                        </div>
                        
                  
                        <div class="row mt-4" style={{textAlign:"left"}}>
                        <div class="col-md-3">
                        <h5>Net Payable Amount :</h5>
                        <p>{total}</p>    
                        </div>
                        </div>
                      
                       
                       
                          <div style={{backgroundColor:"#d5d1cf"}}><p style={{padding:"0.5rem",textAlign:"center"}}>This is a computer generated invoice and don't require any seal or sign.</p></div>
                     
                   
                    </div>
                  </div>
                </div>

                <button type="button" style={{backgroundColor:"#04a15c",marginTop:"2rem"}} class="btn btn-secondary btn-icon-text" onClick={()=>{ window.location.replace("/businesstools-user-purchasereturn"); sessionStorage.removeItem("Tr_no00"); }}> <i class="mdi mdi-arrow-left-bold"></i>Back 
                          </button>
               <button type="button" style={{backgroundColor:"#196fb0",marginTop:"2rem",marginLeft:"2rem"}} class="btn btn-secondary btn-icon-text" onClick={handlePrint}> Print <i class="mdi mdi-file-document"></i>
                          </button>
       
       

            
          </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
         {/*} <footer class="footer">
            <div class="d-sm-flex justify-content-center justify-content-sm-between">
              <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
    </>
    );


}

   //}
export default Purchasereturns_invoice;