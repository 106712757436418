import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../axios.js';
import Navbar from './Extracomponents/navbar.js';
//import Sidebar from './Extracomponents/sidebar.js';


   // Setting Values fom Session Storage
   const userlogstatus=sessionStorage.getItem("userloginstatus00");//loginstatus
   //const loguser=sessionStorage.getItem("userusername00");//username
   const userlogtype=sessionStorage.getItem("userlogintype00");//logintype
   const userlogname=sessionStorage.getItem("userloginname00");//loginname
   const userlogadminid=sessionStorage.getItem("userloginadminid00");//loginadminid

   
function Insidedashboard(){



   //Sessions Not Set
   if(((userlogstatus=="false")||(userlogstatus==null))&&/*(loguser==null)&&*/(userlogadminid==null)){
    //if(((logstatus=="true")||(logstatus==null))&&(loguser==null)&&(logadminid==null)){

  /*  sessionStorage.removeItem("username00"); */
    sessionStorage.removeItem("userloginstatus00");
    sessionStorage.removeItem("userlogintype00");
    sessionStorage.removeItem("userloginname00");
    sessionStorage.removeItem("userloginadminid00");
   
    alert("Session Expired! Login Again.");
    window.location.replace("/");
  
  }
  //Sessions Set
  else{


  
    if(userlogtype=="client"){
     
        return(
   
<>
<html lang="en">
  <head>
    {/* Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

    {/* Bootstrap CSS */}
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous" />

    <title>Dashboard</title>
      {/* plugins:css */}
      <link rel="stylesheet" href="./assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="./assets/vendors/css/vendor.bundle.base.css" />
    {/* endinject */}
    {/* Plugin css for this page */}
    {/* End plugin css for this page */}
    {/* inject:css */}
    {/* endinject */}
    {/* Layout styles */}
    <link rel="stylesheet" href="./assets/css/style.css" />
    {/* End layout styles */}
   {/*} <link rel="shortcut icon" href="./assets/images/favicon.ico" /> */}
  </head>
  <body>
<Navbar/>

{/* PERSONAL DETAILS SECTION START */}

<div class="content-wrapper">

<div class="col-12">


                <div class="card">
                  <div class="card-body">
                    <h2 >Continue to your Services Dashboard</h2>
                    <div class="form-sample" style={{textAlign:"left"}}></div>

                      <p class="card-description"> </p>
<br/><br/>


                   
<div class="card-deck">
<div class="card">
  <div>
  <h2 >Quotations</h2>
  </div>
    <img class="card-img-top" src="https://cdn-icons-png.flaticon.com/128/4388/4388554.png" style={{height:"24vh"}} alt="Card image cap" />
    <div class="card-body">
      <a href="client-quotations"><button type="button" class="btn btn-gradient-info" >Manage</button></a>
    </div>
    
  </div>

  <div class="card">
  <h2 >Orders</h2>
    <img class="card-img-top" src="https://cdn-icons-png.flaticon.com/128/11478/11478422.png" style={{height:"24vh"}} alt="Card image cap" />
    <div class="card-body">
      <a href="client-orders"><button type="button" class="btn btn-gradient-info" >Manage</button></a>
    </div>
  </div>
  <div class="card">
  <h2 >Projects</h2>
    <img class="card-img-top" src="https://cdn-icons-png.flaticon.com/128/5956/5956592.png" style={{height:"24vh"}} alt="Card image cap" />
    <div class="card-body">
      <a href="client-projects"><button type="button" class="btn btn-gradient-info" >Manage</button></a>
    </div>
  </div>
  <div class="card">
  <h2 >Payments</h2>
    <img class="card-img-top" src="https://cdn-icons-png.flaticon.com/128/4108/4108843.png" style={{height:"24vh"}} alt="Card image cap" />
    <div class="card-body">
      <a href="client-payments"><button type="button" class="btn btn-gradient-info" >Manage</button></a>
    </div>
  </div>
  <div class="card">
  <h2 >Feedbacks</h2>
    <img class="card-img-top" src="https://cdn-icons-png.flaticon.com/128/2931/2931312.png" style={{height:"24vh"}} alt="Card image cap" />
    <div class="card-body">
      <a href="client-feedbacks"><button type="button" class="btn btn-gradient-info" >Manage</button></a>
    </div>
  </div>
 
</div>
                    </div>
                  </div>
                </div>
              </div>

              
              {/* PERSONAL DETAILS SECTION END */}
              </body>
              </html>
</>
);
    }

    //FOR TEAM LOGIN
    else if(userlogtype=="team"){

        return(
   
            <>
            <html lang="en">
              <head>
                {/* Required meta tags */}
                <meta charset="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            
                {/* Bootstrap CSS */}
                <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous" />
            
                <title>Dashboard</title>
                  {/* plugins:css */}
                  <link rel="stylesheet" href="./assets/vendors/mdi/css/materialdesignicons.min.css" />
                <link rel="stylesheet" href="./assets/vendors/css/vendor.bundle.base.css" />
                {/* endinject */}
                {/* Plugin css for this page */}
                {/* End plugin css for this page */}
                {/* inject:css */}
                {/* endinject */}
                {/* Layout styles */}
                <link rel="stylesheet" href="./assets/css/style.css" />
                {/* End layout styles */}
               {/*} <link rel="shortcut icon" href="./assets/images/favicon.ico" /> */}
              </head>
              <body>
            <Navbar/>
            
            {/* PERSONAL DETAILS SECTION START */}
            
            <div class="content-wrapper">
            
            <div class="col-12">
            
            
                            <div class="card">
                              <div class="card-body">
                                <h2 >Continue to your Services Dashboard</h2>
                                <div class="form-sample" style={{textAlign:"left"}}></div>
            
                                  <p class="card-description"> </p>
            <br/><br/>
            
            
                               
            <div class="card-deck">
            <div class="card">
              <div>
              <h2 >Quotations</h2>
              </div>
                <img class="card-img-top" src="https://cdn-icons-png.flaticon.com/128/4388/4388554.png" style={{height:"24vh"}} alt="Card image cap" />
                <div class="card-body">
                  <a href="team-quotations"><button type="button" class="btn btn-gradient-info" >Manage</button></a>
                </div>
                
              </div>
            
              <div class="card">
              <h2 >Orders</h2>
                <img class="card-img-top" src="https://cdn-icons-png.flaticon.com/128/11478/11478422.png" style={{height:"24vh"}} alt="Card image cap" />
                <div class="card-body">
                  <a href="team-orders"><button type="button" class="btn btn-gradient-info" >Manage</button></a>
                </div>
              </div>
              <div class="card">
              <h2 >Projects</h2>
                <img class="card-img-top" src="https://cdn-icons-png.flaticon.com/128/5956/5956592.png" style={{height:"24vh"}} alt="Card image cap" />
                <div class="card-body">
                  <a href="team-projects"><button type="button" class="btn btn-gradient-info" >Manage</button></a>
                </div>
              </div>
              <div class="card">
              <h2 >Payments</h2>
                <img class="card-img-top" src="https://cdn-icons-png.flaticon.com/128/4108/4108843.png" style={{height:"24vh"}} alt="Card image cap" />
                <div class="card-body">
                  <a href="team-payments"><button type="button" class="btn btn-gradient-info" >Manage</button></a>
                </div>
              </div>
              <div class="card">
              <h2 >Feedbacks</h2>
                <img class="card-img-top" src="https://cdn-icons-png.flaticon.com/128/2931/2931312.png" style={{height:"24vh"}} alt="Card image cap" />
                <div class="card-body">
                  <a href="team-feedbacks"><button type="button" class="btn btn-gradient-info" >Manage</button></a>
                </div>
              </div>
             
            </div>
                                </div>
                              </div>
                            </div>
                          </div>
            
                          
                          {/* PERSONAL DETAILS SECTION END */}
                          </body>
                          </html>
            </>
            );

    }
    //PREVIOUS CONDITIONS NOT MET
    else{
 

    }
}



}

export default Insidedashboard;