import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from 'axios';


function CandidateprofileAddressinfo(){

  

    return(
<>

        {/* ADDRESS SECTION START */}
        <div class="hire-form-panel" style={{backgroundColor:"#ffffff",display:"none"}} id="address-section">
            <h3>Address Information</h3>
            <p style={{color:"#acafb0"}}>Contains current and permanent address information that will be displayed in your profile and detailed resume.</p>
           
           <h4 style={{textAlign:"left",marginLeft:"4rem"}}>Permanent Address</h4>

           <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Street Address 1</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : 24/A North Block" /></div>
            </div>
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Street Address 2</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Indira Market Road" /> 
            </div>
            </div>
            </div>

            <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>City</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Jabalpur" /></div>
            </div>
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>State</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Madhya Pradesh" /> 
            </div>
            </div>
            </div>

            <div class="hire-form-list">
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Country</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : India" /></div>
            </div>
            <div class="hire-form-group-small">
            <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Pincode</div>
            <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : 482002" /> 
            </div>
            </div>
            </div>


            <div id="line-3"></div>

           <h4 style={{textAlign:"left",marginLeft:"4rem"}}>Current Address</h4>

           <div class="hire-form-list">
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Street Address 1</div>
           <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : 24/A North Block" /></div>
           </div>
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Street Address 2</div>
           <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Indira Market Road" /> 
           </div>
           </div>
           </div>

           <div class="hire-form-list">
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>City</div>
           <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Jabalpur" /></div>
           </div>
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>State</div>
           <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : Madhya Pradesh" /> 
           </div>
           </div>
           </div>

           <div class="hire-form-list">
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Country</div>
           <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : India" /></div>
           </div>
           <div class="hire-form-group-small">
           <div class="hire-form-group-left"><label class="hire-form-group-label"></label>Pincode</div>
           <div class="hire-form-group-right"><input type="text" class="hire-form-group-input" placeholder="For e.g. : 482002" /> 
           </div>
           </div>
           </div>

           <div class="hire-form-btn">
            <button class="hire-btn-default" style={{backgroundColor:"#12d571",marginRight:"2rem",marginTop:"2rem",marginBottom:"2rem"}}
            onClick={()=>{ document.getElementById("address-section").style.display="none";
            document.getElementById("educational-details-section").style.display="block";
             }}
            >Save and Next</button>
            </div>


            </div>
          {/* ADDRESS SECTION END */}
          
              
</>
);
    }

export default CandidateprofileAddressinfo;