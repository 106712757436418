import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';
import moment from 'moment/moment';
import '../Resources/dashboard.css';


function Create_asset(){

  
  
 //Checking Sessions
 //Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const busyid=sessionStorage.getItem("BusyId00");
 const counterid=sessionStorage.getItem("CounterId00");



//saving default values with usestate
const state = useState();

//date
const[date,setdate]=useState();const updatedate = (event) => { setdate(event.target.value);}; const[date_er,setdate_er]=useState();
//type
const[type,settype]=useState();const updatetype = (event) => { settype(event.target.value); changemode(); }; const[type_er,settype_er]=useState();
//asset_name
const[asset_name,setasset_name]=useState();const updateasset_name = (event) => { setasset_name(event.target.value);}; const[asset_name_er,setasset_name_er]=useState();
//part_name
const[part_name,setpart_name]=useState();const updatepart_name = (event) => { setpart_name(event.target.value);}; const[part_name_er,setpart_name_er]=useState();
//mode
const[mode,setmode]=useState();const updatemode = (event) => { setmode(event.target.value);}; const[mode_er,setmode_er]=useState();
//bank_id
const[bank_id,setbank_id]=useState();const updatebank_id = (event) => { setbank_id(event.target.value);}; const[bank_id_er,setbank_id_er]=useState();
//particular
const[particular,setparticular]=useState();const updateparticular = (event) => { setparticular(event.target.value);}; const[particular_er,setparticular_er]=useState();
//value
const[value,setvalue]=useState();const updatevalue = (event) => { setvalue(event.target.value);}; const[value_er,setvalue_er]=useState();
//other_additions
const[other_additions,setother_additions]=useState();const updateother_additions = (event) => { setother_additions(event.target.value);}; const[other_additions_er,setother_additions_er]=useState();
//description
const[description,setdescription]=useState();const updatedescription = (event) => { setdescription(event.target.value);}; const[description_er,setdescription_er]=useState();



//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();
 
//Variables
const[Tbanks,setTbanks] = useState([]);//SetData Variable
//Error Variable
const[errorvar,seterrorvar] = useState();


useEffect(()=>{
   
    getData();
    
    },[])
   


//changemode function
function changemode(){
    const mmode = document.getElementById("type").value;
    if((mmode=="sales")||(mmode=="purchase")){

        document.getElementById("mode-row").style.display="block";

    }
    else{
        document.getElementById("mode-row").style.display="none";
    }
}


  
//******************* GET BANKS DATA FUNCTION START  ********************//
function getData(){
 

    //Checking if user loggedin
    if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))){
   
   
   axios.post("bt-get-banks", {
     Log_id:logid,
     Token:token,
     Busy_id:busyid,
     Counter_id:counterid,
     Sn_start :0,
     Sn_end :0,
     Sn_total :0,
     Sn_limit :0
   })
   .then((response) => {
  
    //Invalid Session
    if((response.data.error)==100){ 
        //Remove Session Variables and redirect to login
       sessionStorage.removeItem("logStatus00");
       sessionStorage.removeItem("logId00");
       sessionStorage.removeItem("logToken00");
       sessionStorage.removeItem("BusyId00");
       sessionStorage.removeItem("CounterId00");
  
       window.location.replace("businesstools-login");
    }
    //Success Data Fetched
    if(response.data.successvar==1){
     //Setting Data
     setTbanks(response.data.data);
    
    }
    //Cannot Fetch Data
    else{
     seterrorvar("Cannot Fetch Data !");
    }
   
   
   });
   
   }
   //User not Logedin
   else{
   
   //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");
  
     window.location.replace("businesstools-login");
   
    }
   
   }
    
   //******************* GET BANKS DATA FUNCTION END  ********************//
  
   



  
//******************* CREATE ASSET FUNCTION START ********************//
function createasset(){

    //Setting Error Variables
  setdate_er(" ");settype_er(" ");setparticular_er(" ");setasset_name_er(" ");setpart_name_er(" ");setdescription_er(" ");setmode_er(" ");setbank_id_er(" ");setvalue_er(" ");setother_additions_er(" ");seterror_msg(" ");

 

    //Checking if user loggedin
    if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))){
   
   
    axios.post("bt-create-asset", {
     Log_id:logid,
     Token:token,
     Busy_id:busyid,
     Counter_id:counterid,
     Date : moment(date).format('YYYY-MM-DD'),
     Type:type,
     Particular : particular,
     Asset_name : asset_name,
     Part_name : part_name,
     Description : description,
     Mode : mode,
     Bank_id : bank_id,
     Value : value,
     Other_additions : other_additions,

   })
   .then((response) => {

    //Transaction Limit Reached
    if(response.data.error==111){     seterror_msg(response.data.error_msg); return; }

    //Invalid Session
    if((response.data.error)==100){ 
        //Remove Session Variables and redirect to login
       sessionStorage.removeItem("logStatus00");
       sessionStorage.removeItem("logId00");
       sessionStorage.removeItem("logToken00");
       sessionStorage.removeItem("BusyId00");
       sessionStorage.removeItem("BusyName00");
       sessionStorage.removeItem("CounterId00");

       window.location.replace("businesstools-login");
    }

     //Validation Errors
   if(response.data.validatorerror){
    setdate_er(response.data.validatorerror.Date);
    settype_er(response.data.validatorerror.Type);
    setparticular_er(response.data.validatorerror.Particular);
    setasset_name_er(response.data.validatorerror.Asset_name);
    setpart_name_er(response.data.validatorerror.Part_name);
    setdescription_er(response.data.validatorerror.Description);
    setmode_er(response.data.validatorerror.Mode);
    setbank_id_er(response.data.validatorerror.Bank_id);
    setvalue_er(response.data.validatorerror.Value);
    setother_additions_er(response.data.validatorerror.Other_additions);
 
  
  }
  //Invalid Entries
  if(response.data.error==102){ 
    
    setdate_er(response.data.date_er);
    settype_er(response.data.type_er);
    setparticular_er(response.data.particular_er);
    setasset_name_er(response.data.asset_name_er);
    setpart_name_er(response.data.part_name_er);
    setdescription_er(response.data.description_er);
    setmode_er(response.data.mode_er);
    setbank_id_er(response.data.bank_id_er);
    setvalue_er(response.data.value_er);
    setother_additions_er(response.data.other_additions_er);
   
    seterror_msg("Cannot Complete Transaction!");  }


    //Invalid Entries
    if(response.data.error==103){     seterror_msg(response.data.error_msg);  }

     //Transaction Entered Successfully
     if(response.data.successvar==1){        document.getElementById("success-card").style.display="block";  }
     //Cannot Complete Transaction
     else{  seterror_msg("Cannot Complete Transaction !"); }
    

});
}
   //User not Logedin
   else{
   
   //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
   
    }
   
   }
    
   //******************* CREATE ASSET FUNCTION END ********************//


return(
    <>
                <div class="card">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("create-form").style.display="none"; }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                  <div class="card-body" style={{textAlign:"left"}}>
                    <h4 class="card-title">Asset Management</h4>
                    <div class="form-sample">
                      <p class="card-description">manage your asset from here</p>
                      <code>{error_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control"  id="date" value={date} onChange={updatedate} />
                              <code>{date_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type<code>*</code></label>
                            <div class="col-sm-9">
                              <select class="form-control" placeholder="Select Transaction" style={{padding:"1rem"}} id="type" value={type} onChange={updatetype}>
                              <option value="">Select Type</option>
                              <option value="entry">Entry</option>
                              <option value="drawings">Drawings</option>
                              <option value="sales">Sales</option>
                              <option value="purchase">Purchase</option>
                              <option value="appreciation">Appreciation</option>
                              <option value="depreciation">Depreciation</option>
                              </select>
                              <code>{type_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Asset Name<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Machinery"  id="asset_name" value={asset_name} onChange={updateasset_name} />
                              <code>{asset_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Part Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Machinery Part" id="part_name" value={part_name} onChange={updatepart_name} />
                              <code>{part_name_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row" id="mode-row" style={{display:"none"}}>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Mode<code>*</code></label>
                            <div class="col-sm-9">
                              <select class="form-control" placeholder="Mode" style={{padding:"1rem"}} id="mode" value={mode} onChange={updatemode}>
                              <option value="">Select Mode</option>
                              <option value="cash">Cash</option>
                              <option value="bank">Bank</option>
                              </select>
                              <code>{mode_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Select Bank</label>
                            <div class="col-sm-9">
                              <select class="form-control" placeholder="Select Bank" style={{padding:"1rem"}} id="bank_id" value={bank_id} onChange={updatebank_id}>
                              <option value="">Select Bank</option>
                              {
                              Tbanks.map((curElm2,ind)=>{

                              return(
                              <>
                              <option value={curElm2.Bank_id}>{curElm2.Bank_name}</option>
                              </>
                              )
                              }
	 				   
	                         		)
                              }
                              </select>
                              <code>{bank_id_er}</code>
                            </div>
                          </div>
                        </div>     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Value<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="50000.00" id="value" value={value} onChange={updatevalue} />
                              <code>{value_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Other Additions<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="5000.00" id="other_additions" value={other_additions} onChange={updateother_additions} />
                              <code>{other_additions_er}</code>
                            </div>
                          </div>
                        </div>
                        </div>
                        <div class="form-group">
                        <label for="exampleTextarea1">Description</label>
                        <textarea  class="form-control"  rows="2" id="description" value={description} onChange={updatedescription}></textarea>
                        <code>{description_er}</code>
                      </div>
                      <div class="form-group">
                        <label for="exampleTextarea1">Particular<code>*</code></label>
                        <textarea  class="form-control"  rows="2" id="particular" value={particular} onChange={updateparticular}></textarea>
                        <code>{particular_er}</code>
                      </div>
                    
                        <div class="row">
                        <div class="col-md-6">
                        <button class="btn btn-primary" style={{fontWeight:"bold"}} onClick={createasset}>Submit</button>
                        </div>
                       
                      </div>
                        
                       
    
                     
                   
                    </div>
                  </div>
                </div>
    </>

);

}

export default Create_asset;