import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
//import axios from '../../axios.js';
import moment from 'moment/moment';


import '../Resources/dashboard.css';
import Navbar from '../navbar.js';
import Sidebar from './sidebar';
import axios from '../../axios.js';


import Chart from "chart.js/auto";
import { Line, Bar } from "react-chartjs-2";
import StartupDetailSidebar from './sdetailssidebar.js';



 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");

 //Other Constatants of Sessions
 const startup_key=sessionStorage.getItem("StartupKey00");
 const startup_id=sessionStorage.getItem("StartupId00");

function Startupdetails(){

//STARTUP DETAILS
//const imgpath = 'http://127.0.0.1:8000/storage/';
const imgpath = 'https://thestartupconnect.com/apidata/storage/'; 

const[startup_name,setstartup_name]=useState();//startup_name
const[contact,setcontact]=useState();//contact
const[email,setemail]=useState();//email
const[company_name,setcompany_name]=useState();//company_name
const[dpiit,setdpiit]=useState();//dpiit
const[pan,setpan]=useState();//pan
const[registration_number,setregistration_number]=useState();//registration_number
const[gstin,setgstin]=useState();//gstin
const[date_of_establishment,setdate_of_establishment]=useState();//date_of_establishment
const[introduction,setintroduction]=useState();//introduction
const[industry,setindustry]=useState();//industry
const[sector,setsector]=useState();//sector
const[category,setcategory]=useState();//category
const[sub_category,setsub_category]=useState();//sub_category
const[type,settype]=useState();//type
const[address,setaddress]=useState();//address
const[city,setcity]=useState();//city
const[state,setstate]=useState();//state
const[country,setcountry]=useState();//country
const[pincode,setpincode]=useState();//pincode
const[youtube,setyoutube]=useState();//youtube
const[website,setwebsite]=useState();//website
const[linkedin,setlinkedin]=useState();//linkedin
const[twitter,settwitter]=useState();//twitter
const[instagram,setinstagram]=useState();//instagram
const[facebook,setfacebook]=useState();//facebook
const[logo_link,setlogo_link]=useState(null);//logo_link
const[views,setviews]=useState();//views
const[likes,setlikes]=useState();//likes
const[saves,setsaves]=useState();//saves
const[date_created,setdate_created]=useState();//date_created
const[time_created,settime_created]=useState();//time_created
const[date_updated,setdate_updated]=useState();//date_updated
const[time_updated,settime_updated]=useState();//time_updated
const[shares,setshares]=useState();//shares
const[reports,setreports]=useState();//reports
const[answer1,setanswer1]=useState();//answer1
const[answer2,setanswer2]=useState();//answer2
const[answer3,setanswer3]=useState();//answer3
const[answer4,setanswer4]=useState();//answer4
const[answer5,setanswer5]=useState();//answer5
const[answer6,setanswer6]=useState();//answer6
const[answer7,setanswer7]=useState();//answer7
const[answer8,setanswer8]=useState();//answer8
const[answer9,setanswer9]=useState();//answer9
const[answer10,setanswer10]=useState();//answer10
const[answer11,setanswer11]=useState();//answer11
const[team_data,setteam_data]=useState([],);//team_data

//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();
const[errorvar,seterrorvar]=useState();



useEffect(()=>{
   
  getData();
  
  },[])
  
//******************* GET STARTUP DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((startup_id!=null)||(startup_id!=" "))&&((startup_key!=null)||(startup_key!=" "))){
 
 axios.post("network-get-complete-startup-details-byId", {
   Log_id:logid,
   Token:token,
   Startup_id:startup_id,
   Startup_key:startup_key
   })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");

     window.location.replace("/networking-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){

   //Setting Data
   
  setstartup_name(response.data.startup_name);//startup_name
  setcontact(response.data.contact);//contact
  setemail(response.data.email);//email
  setcompany_name(response.data.company_name);//company_name
  setdpiit(response.data.dpiit);//dpiit
  setpan(response.data.pan);//pan
  setregistration_number(response.data.registration_number);//registration_number
  setgstin(response.data.gstin);//gstin
  setdate_of_establishment(response.data.date_of_establishment);//date_of_establishment
  setintroduction(response.data.introduction);//introduction
  setindustry(response.data.industry);//industry
  setsector(response.data.sector);//startup_name
  setcategory(response.data.category);//category
  setsub_category(response.data.sub_category);//sub_category
  settype(response.data.type);//type
  setaddress(response.data.address);//address
  setcity(response.data.city);//city
  setstate(response.data.state);//state  
  setcountry(response.data.country);//country
  setpincode(response.data.pincode);//pincode
  setyoutube(response.data.youtube);//youtube
  setwebsite(response.data.website);//website
  setlinkedin(response.data.linkedin);//linkedin
  settwitter(response.data.twitter);//twitter
  setinstagram(response.data.instagram);//instagram
  setfacebook(response.data.facebook);//facebook
  setlogo_link(response.data.logo_link);//logo_link
  setviews(response.data.views);//views
  setlikes(response.data.likes);//likes
  setsaves(response.data.saves);//saves
  setshares(response.data.shares);//shares
  setreports(response.data.reports);//reports
  const et_logo_link = (response.data.logo_link);//logo_link
  setlogo_link(et_logo_link.slice(7));
  setanswer1(response.data.answer1);//answer1
  setanswer2(response.data.answer2);//answer2
  setanswer3(response.data.answer3);//answer3
  setanswer4(response.data.answer4);//answer4
  setanswer5(response.data.answer5);//answer5
  setanswer6(response.data.answer6);//answer6
  setanswer7(response.data.answer7);//answer7
  setanswer8(response.data.answer8);//answer8
  setanswer9(response.data.answer9);//answer9
  setanswer10(response.data.answer10);//answer10
  setanswer11(response.data.answer11);//answer11
  setteam_data(response.data.team_data);//team_data




  }

  //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){
    seterrorvar("Cannot Fetch Data !");

  }

  //Data Violation
  if(response.data.error==102){ 
  
  seterrorvar("Cannot Fetch Data !");


  }


  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("logName00");
   sessionStorage.removeItem("StartupId00");
   sessionStorage.removeItem("StartupKey00");
   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* GET STARTUP DATA FUNCTION END  ********************//


return(
    <>
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Neworking | Startups</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div class="container-scroller">

     <StartupDetailSidebar />
    <div class="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div class="main-panel">
       
  

          <div class="content-wrapper pb-0">
        
          

            <div class="row">
        

            {/* PROFILE CARD START */}
              <div class="col-sm-12 stretch-card grid-margin">
               <div class="card" style={{padding:"1rem"}}>

               {/* HEADING SECTION START */}
               <div class="d-flex justify-content-between" id="startup-heading">
               <div class="col-sm-8 stretch-card grid-margin">
               <div style={{textAlign:"left"}}>
                <h2 class="font-weight-bold">{startup_name}</h2>
                <h4 class="font-weight-semibold text-muted">{company_name}</h4>
                <p>({type})</p>

               
                      <p class="text-muted font-13 mb-0"></p>
                      <div>
                      <p class="mb-0 font-weight-bold"> <i class="mdi mdi-heart text-danger"></i>{likes}
                       <i class="mdi mdi-poll-box text-primary" style={{marginLeft:"1rem"}}></i>{views}
                       <i class="mdi mdi-marker-check text-warning" style={{marginLeft:"1rem"}}></i> {saves}</p>
                      </div>
                    
                </div>
                </div>
                <div class="col-sm-4 stretch-card grid-margin">
                <img src={imgpath+logo_link}></img>
                </div>
              </div>
              {/* HEADING SECTION END */}
              <div style={{textAlign:"left"}} id="startup-introduction">
                <h3 class="font-weight-bold">INTRODUCTION</h3>
                <h5 class="font-weight-semibold text-muted">{answer1}</h5>
                <p>{introduction}</p>

                {/* CATEGORY SECTION START */}
                <div class="col-12 d-flex justify-content-between">
                <div class="col-sm-4">
                    <h4 class="font-weight-bold">Category</h4>
                    <p class="font-weight-semibold text-primary">#{category}</p>
                    <p class="font-weight-semibold text-primary">#{sub_category}</p>
                </div>
                <div class="col-sm-4">
                    <h4 class="font-weight-bold">Industry</h4>
                    <p class="font-weight-semibold text-primary">#{industry}</p>
                </div>
                <div class="col-sm-2">
                    <h4 class="font-weight-bold">Sector</h4>
                    <p class="font-weight-semibold text-primary">#{sector}</p>
                </div>
                <div class="col-sm-2">
                <button class="btn btn-icon"><i class="mdi mdi-information text-danger" ></i></button>
                </div>
                </div>
                {/* CATEGORY SECTION END */}

                {/* REGISTRATIONS SECTION START */}
                <div class="col-12 d-flex justify-content-between mt-5">
                <div class="col-sm-4">
                <h4 class="font-weight-bold">Date of Registration</h4>
                <p class="font-weight-semibold">{date_of_establishment}</p>
                </div>
                <div class="col-sm-4">
                <h4 class="font-weight-bold">Registration Number</h4>
                <p class="font-weight-semibold">{registration_number}</p>
                </div>
                <div class="col-sm-4">
                <h4 class="font-weight-bold">DPIIT</h4>
                <p class="font-weight-semibold">{dpiit}</p>
                </div>
                </div>
                <div class="col-12 d-flex justify-content-between mt-5">
                <div class="col-sm-4">
                <h4 class="font-weight-bold">GSTIN </h4>
                <p class="font-weight-semibold">{gstin}</p>
                </div>
                <div class="col-sm-4">
                <h4 class="font-weight-bold">PAN </h4>
                <p class="font-weight-semibold">{pan}</p>
                </div>
                <div class="col-sm-4">
                <h4 class="font-weight-bold">Contact</h4>
                <p class="font-weight-semibold">{contact}</p>
                </div>
                </div>
                {/* REGISTRATIONS SECTION END */}
  
                {/* ADDRESS SECTION START */}
                <div class="mt-5">
                    <h4 class="font-weight-bold">Address</h4>
                    <p class="font-weight-semibold">{address}. {city}, {state} ({country}). [{pincode}]</p>
                </div>
                {/* ADDRESS SECTION END */}
                <hr  class="mt-5" />

                {/* QUESTIONS SECTION START */}
                <div style={{textAlign:"left"}} id="startup-additional">
                <h3 class="font-weight-bold">MORE INFORMATION</h3>
                
                
                <h4 class="font-weight-bold mt-4">1.) The Problem</h4>
                <p class="font-weight-semibold"> {answer2}</p>

                <h4 class="font-weight-bold mt-4">2.) Current Stage and Future Plans</h4>
                <p class="font-weight-semibold"> {answer3}</p>
  
                <h4 class="font-weight-bold mt-4">3.) Target Audience</h4>
                <p class="font-weight-semibold"> {answer4}</p>

                <h4 class="font-weight-bold mt-4">4.) Traction</h4>
                <p class="font-weight-semibold"> {answer5}</p>

                <h4 class="font-weight-bold mt-4">5.) Marketing Strategy</h4>
                <p class="font-weight-semibold"> {answer6}</p>

                <h4 class="font-weight-bold mt-4">6.) Fundraising and Investments</h4>
                <p class="font-weight-semibold"> {answer7}</p>

                <h4 class="font-weight-bold mt-4">7.) Incubations and Acceleration Programmes Participated</h4>
                <p class="font-weight-semibold"> {answer8}</p>

                <h4 class="font-weight-bold mt-4">8.) Other Details</h4>
                <p class="font-weight-semibold"> {answer9}</p>

                <h4 class="font-weight-bold mt-4">9.) Other Links</h4>
                <p class="font-weight-semibold"> {answer10}</p>

                <h4 class="font-weight-bold mt-4">10.) Link to our Presentation</h4>
                <a href=""><p class="font-weight-semibold text-primary">{answer11}</p></a>

                </div>
                {/* QUESTIONS SECTION END */}
                  <hr/>
                  {/* TEAM SECTION START */}
                  <div class="mt-6" style={{textAlign:"left"}} id="startup-team">
                  <h3 class="font-weight-bold">OUR TEAM</h3>

                  <div class="row mt-7">

                  {
                       team_data.map((curElm,ind)=>{

                         return(
                         <>


                      {/* TEAM CARD START 1 */}
              <div class="col-sm-4 stretch-card grid-margin" key={curElm.Steam_id}>
                <div class="card">
                <div class="card-body p-0 d-flex justify-content-center">
                  <div>
                    <img class="img-fluid mt-3" src="https://cdn-icons-png.flaticon.com/128/3135/3135823.png" alt="" style={{height:"20vh"}} hidden={curElm.Gender=="male"} />
                    <img class="img-fluid mt-3" src="https://cdn-icons-png.flaticon.com/128/3135/3135768.png" alt="" style={{height:"20vh"}} hidden={curElm.Gender=="female"} />
                    </div>
                  </div>
                  <div class="card-body px-3 text-dark">
                    <div class="d-flex justify-content-between">
                    <h6 class="text-muted">{curElm.Role}</h6>
                      <p class="text-muted font-13 mb-0">{curElm.Type} ({curElm.Working})</p>
                    </div>
                    <h4 class="font-weight-semibold text-center"> {curElm.Name} </h4>
                    <p class="text-center">{curElm.Summary}</p>
                    <hr />
                    <div class="d-flex justify-content-between font-weight-semibold" style={{marginTop:"1rem"}}>
                    <p class="text-center"><i class="mdi mdi-certificate text-primary" style={{fontSize:"1.5rem"}}></i><br />{curElm.Experience} years</p>
                    <p class="text-center"><i class="mdi mdi-chart-areaspline text-warning" style={{fontSize:"1.5rem"}}></i> <br />{curElm.Duration} years</p>
                    <p class="text-center"><i class="mdi mdi-calendar-today text-info" style={{fontSize:"1.5rem"}}></i><br />{curElm.Date_of_birth}</p>
                    </div>

                    <div class="d-flex justify-content-center font-weight-semibold" style={{marginTop:"2rem"}}>
                    <div>
                    <a href={curElm.Email}><button type="button" class="btn btn-social-icon btn-linkedin btn-rounded" style={{marginLeft:".5rem", backgroundColor:"#dc0b8d"}}>
                        <i class="mdi mdi-gmail"></i>
                      </button></a>
                      <a href={curElm.Contact}><button type="button" class="btn btn-social-icon btn-linkedin btn-rounded" style={{marginLeft:".5rem",backgroundColor:"#25d169"}}>
                        <i class="mdi mdi-phone"></i>
                      </button></a>
                      <a href={curElm.Linkedin}><button type="button" class="btn btn-social-icon btn-linkedin btn-rounded" style={{marginLeft:".5rem"}}>
                        <i class="mdi mdi-linkedin"></i>
                      </button></a>
                   
                      </div>
                    </div>
                 
                  </div>
                </div>
              </div>
              {/* TEAM CARD END 1 */}

              </>
                         )
                         }
					   
	                  		 )
                         }

                      {/* TEAM CARD START 2 
                      <div class="col-sm-4 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0 d-flex justify-content-center">
                  <div>
                    <img class="img-fluid mt-3" src="https://cdn-icons-png.flaticon.com/128/3135/3135823.png" alt="" style={{height:"20vh"}} />
                    </div>
                  </div>
                  <div class="card-body px-3 text-dark">
                    <div class="d-flex justify-content-between">
                    <h6 class="text-muted">Founder</h6>
                      <p class="text-muted font-13 mb-0">Core Team (Full Time)</p>
                    </div>
                    <h4 class="font-weight-semibold text-center"> MRS. STUART MOMENT CHREW </h4>
                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    <hr />
                    <div class="d-flex justify-content-between font-weight-semibold" style={{marginTop:"1rem"}}>
                    <p class="text-center"><i class="mdi mdi-certificate text-primary" style={{fontSize:"1.5rem"}}></i><br />23 years</p>
                    <p class="text-center"><i class="mdi mdi-chart-areaspline text-warning" style={{fontSize:"1.5rem"}}></i> <br />23 years</p>
                    <p class="text-center"><i class="mdi mdi-calendar-today text-info" style={{fontSize:"1.5rem"}}></i><br />2000-09-23</p>
                    </div>

                    <div class="d-flex justify-content-center font-weight-semibold" style={{marginTop:"2rem"}}>
                    <div>
                    <a href=""><button type="button" class="btn btn-social-icon btn-linkedin btn-rounded" style={{marginLeft:".5rem", backgroundColor:"#dc0b8d"}}>
                        <i class="mdi mdi-gmail"></i>
                      </button></a>
                      <a href=""><button type="button" class="btn btn-social-icon btn-linkedin btn-rounded" style={{marginLeft:".5rem",backgroundColor:"#25d169"}}>
                        <i class="mdi mdi-phone"></i>
                      </button></a>
                      <a href=""><button type="button" class="btn btn-social-icon btn-linkedin btn-rounded" style={{marginLeft:".5rem"}}>
                        <i class="mdi mdi-linkedin"></i>
                      </button></a>
                   
                      </div>
                    </div>
                 
                  </div>
                </div>
              </div>
              {/* TEAM CARD END 2 */}

               {/* TEAM CARD START 3 
                <div class="col-sm-4 stretch-card grid-margin">
                <div class="card">
                <div class="card-body p-0 d-flex justify-content-center">
                  <div>
                    <img class="img-fluid mt-3" src="https://cdn-icons-png.flaticon.com/128/3135/3135823.png" alt="" style={{height:"20vh"}} />
                    </div>
                  </div>
                  <div class="card-body px-3 text-dark">
                    <div class="d-flex justify-content-between">
                    <h6 class="text-muted">Founder</h6>
                      <p class="text-muted font-13 mb-0">Core Team (Full Time)</p>
                    </div>
                    <h4 class="font-weight-semibold text-center"> MRS. STUART MOMENT CHREW </h4>
                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    <hr />
                    <div class="d-flex justify-content-between font-weight-semibold" style={{marginTop:"1rem"}}>
                    <p class="text-center"><i class="mdi mdi-certificate text-primary" style={{fontSize:"1.5rem"}}></i><br />23 years</p>
                    <p class="text-center"><i class="mdi mdi-chart-areaspline text-warning" style={{fontSize:"1.5rem"}}></i> <br />23 years</p>
                    <p class="text-center"><i class="mdi mdi-calendar-today text-info" style={{fontSize:"1.5rem"}}></i><br />2000-09-23</p>
                    </div>

                    <div class="d-flex justify-content-center font-weight-semibold" style={{marginTop:"2rem"}}>
                    <div>
                    <a href=""><button type="button" class="btn btn-social-icon btn-linkedin btn-rounded" style={{marginLeft:".5rem", backgroundColor:"#dc0b8d"}}>
                        <i class="mdi mdi-gmail"></i>
                      </button></a>
                      <a href=""><button type="button" class="btn btn-social-icon btn-linkedin btn-rounded" style={{marginLeft:".5rem",backgroundColor:"#25d169"}}>
                        <i class="mdi mdi-phone"></i>
                      </button></a>
                      <a href=""><button type="button" class="btn btn-social-icon btn-linkedin btn-rounded" style={{marginLeft:".5rem"}}>
                        <i class="mdi mdi-linkedin"></i>
                      </button></a>
                   
                      </div>
                    </div>
                 
                  </div>
                </div>
              </div>
              {/* TEAM CARD END 3 */}

               {/* TEAM CARD START 4 
               <div class="col-sm-4 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0 d-flex justify-content-center">
                  <div>
                    <img class="img-fluid mt-3" src="https://cdn-icons-png.flaticon.com/128/3135/3135768.png" alt="" style={{height:"20vh"}} />
                    </div>
                  </div>
                  <div class="card-body px-3 text-dark">
                    <div class="d-flex justify-content-between">
                    <h6 class="text-muted">Founder</h6>
                      <p class="text-muted font-13 mb-0">Core Team (Full Time)</p>
                    </div>
                    <h4 class="font-weight-semibold text-center"> MRS. STUART MOMENT CHREW </h4>
                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    <hr />
                    <div class="d-flex justify-content-between font-weight-semibold" style={{marginTop:"1rem"}}>
                    <p class="text-center"><i class="mdi mdi-certificate text-primary" style={{fontSize:"1.5rem"}}></i><br />23 years</p>
                    <p class="text-center"><i class="mdi mdi-chart-areaspline text-warning" style={{fontSize:"1.5rem"}}></i> <br />23 years</p>
                    <p class="text-center"><i class="mdi mdi-calendar-today text-info" style={{fontSize:"1.5rem"}}></i><br />2000-09-23</p>
                    </div>

                    <div class="d-flex justify-content-center font-weight-semibold" style={{marginTop:"2rem"}}>
                    <div>
                    <a href=""><button type="button" class="btn btn-social-icon btn-linkedin btn-rounded" style={{marginLeft:".5rem", backgroundColor:"#dc0b8d"}}>
                        <i class="mdi mdi-gmail"></i>
                      </button></a>
                      <a href=""><button type="button" class="btn btn-social-icon btn-linkedin btn-rounded" style={{marginLeft:".5rem",backgroundColor:"#25d169"}}>
                        <i class="mdi mdi-phone"></i>
                      </button></a>
                      <a href=""><button type="button" class="btn btn-social-icon btn-linkedin btn-rounded" style={{marginLeft:".5rem"}}>
                        <i class="mdi mdi-linkedin"></i>
                      </button></a>
                   
                      </div>
                    </div>
                 
                  </div>
                </div>
              </div>
              {/* TEAM CARD END 4 */}

              {/* TEAM CARD START 5 
               <div class="col-sm-4 stretch-card grid-margin">
                <div class="card">
                <div class="card-body p-0 d-flex justify-content-center">
                  <div>
                    <img class="img-fluid mt-3" src="https://cdn-icons-png.flaticon.com/128/3135/3135768.png" alt="" style={{height:"20vh"}} />
                    </div>
                  </div>
                  <div class="card-body px-3 text-dark">
                    <div class="d-flex justify-content-between">
                    <h6 class="text-muted">Founder</h6>
                      <p class="text-muted font-13 mb-0">Core Team (Full Time)</p>
                    </div>
                    <h4 class="font-weight-semibold text-center"> MRS. STUART MOMENT CHREW </h4>
                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    <hr />
                    <div class="d-flex justify-content-between font-weight-semibold" style={{marginTop:"1rem"}}>
                    <p class="text-center"><i class="mdi mdi-certificate text-primary" style={{fontSize:"1.5rem"}}></i><br />23 years</p>
                    <p class="text-center"><i class="mdi mdi-chart-areaspline text-warning" style={{fontSize:"1.5rem"}}></i> <br />23 years</p>
                    <p class="text-center"><i class="mdi mdi-calendar-today text-info" style={{fontSize:"1.5rem"}}></i><br />2000-09-23</p>
                    </div>

                    <div class="d-flex justify-content-center font-weight-semibold" style={{marginTop:"2rem"}}>
                    <div>
                    <a href=""><button type="button" class="btn btn-social-icon btn-linkedin btn-rounded" style={{marginLeft:".5rem", backgroundColor:"#dc0b8d"}}>
                        <i class="mdi mdi-gmail"></i>
                      </button></a>
                      <a href=""><button type="button" class="btn btn-social-icon btn-linkedin btn-rounded" style={{marginLeft:".5rem",backgroundColor:"#25d169"}}>
                        <i class="mdi mdi-phone"></i>
                      </button></a>
                      <a href=""><button type="button" class="btn btn-social-icon btn-linkedin btn-rounded" style={{marginLeft:".5rem"}}>
                        <i class="mdi mdi-linkedin"></i>
                      </button></a>
                   
                      </div>
                    </div>
                 
                  </div>
                </div>
              </div>
              {/* TEAM CARD END 5 */}
              {/* TEAM CARD START 6 
              <div class="col-sm-4 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0 d-flex justify-content-center">
                  <div>
                    <img class="img-fluid mt-3" src="https://cdn-icons-png.flaticon.com/128/3135/3135823.png" alt="" style={{height:"20vh"}} />
                    </div>
                  </div>
                  <div class="card-body px-3 text-dark">
                    <div class="d-flex justify-content-between">
                    <h6 class="text-muted">Founder</h6>
                      <p class="text-muted font-13 mb-0">Core Team (Full Time)</p>
                    </div>
                    <h4 class="font-weight-semibold text-center"> MR. STUART MOMENT CHREW </h4>
                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    <hr />
                    <div class="d-flex justify-content-between font-weight-semibold" style={{marginTop:"1rem"}}>
                    <p class="text-center"><i class="mdi mdi-certificate text-primary" style={{fontSize:"1.5rem"}}></i><br />23 years</p>
                    <p class="text-center"><i class="mdi mdi-chart-areaspline text-warning" style={{fontSize:"1.5rem"}}></i> <br />23 years</p>
                    <p class="text-center"><i class="mdi mdi-calendar-today text-info" style={{fontSize:"1.5rem"}}></i><br />2000-09-23</p>
                    </div>

                    <div class="d-flex justify-content-center font-weight-semibold" style={{marginTop:"2rem"}}>
                    <div>
                    <a href=""><button type="button" class="btn btn-social-icon btn-linkedin btn-rounded" style={{marginLeft:".5rem", backgroundColor:"#dc0b8d"}}>
                        <i class="mdi mdi-gmail"></i>
                      </button></a>
                      <a href=""><button type="button" class="btn btn-social-icon btn-linkedin btn-rounded" style={{marginLeft:".5rem",backgroundColor:"#25d169"}}>
                        <i class="mdi mdi-phone"></i>
                      </button></a>
                      <a href=""><button type="button" class="btn btn-social-icon btn-linkedin btn-rounded" style={{marginLeft:".5rem"}}>
                        <i class="mdi mdi-linkedin"></i>
                      </button></a>
                   
                      </div>
                    </div>
                 
                  </div>
                </div>
              </div>
              {/* TEAM CARD END 6 */}


                  </div>
                  </div>
                  {/* TEAM SECTION END */}
              
                <hr/>
                {/* FOOTER ICONS START */}
                <div class="d-flex justify-content-center font-weight-semibold" style={{marginTop:"2rem"}} id="startup-footer">
                    <div>
                      <a href={youtube}><button type="button" class="btn btn-social-icon btn-youtube btn-rounded" style={{fontSize:".5rem",padding:".5rem"}}>
                        <i class="mdi mdi-youtube"></i>
                      </button></a>
                      <a href={twitter}><button type="button" class="btn btn-social-icon btn-twitter btn-rounded" style={{marginLeft:".5rem"}}>
                        <i class="mdi mdi-twitter"></i>
                      </button></a>
                      <a href={linkedin}><button type="button" class="btn btn-social-icon btn-linkedin btn-rounded" style={{marginLeft:".5rem"}}>
                        <i class="mdi mdi-linkedin"></i>
                      </button></a>
                      <a href={website}><button type="button" class="btn btn-social-icon btn-linkedin btn-rounded" style={{marginLeft:".5rem", backgroundColor:"#2cdba0"}}>
                        <i class="mdi mdi-web"></i>
                      </button></a>
                      <a href={email}><button type="button" class="btn btn-social-icon btn-linkedin btn-rounded" style={{marginLeft:".5rem", backgroundColor:"#dc0b8d"}}>
                        <i class="mdi mdi-gmail"></i>
                      </button></a>
                      <a href={facebook}><button type="button" class="btn btn-social-icon btn-linkedin btn-rounded" style={{marginLeft:".5rem", backgroundColor:"#1100D1"}}>
                        <i class="mdi mdi-facebook"></i>
                      </button></a>
                      <a href={instagram}><button type="button" class="btn btn-social-icon btn-linkedin btn-rounded" style={{marginLeft:".5rem", backgroundColor:"#EA2F18"}}>
                        <i class="mdi mdi-instagram"></i>
                      </button></a>
                      

                      </div>
                    </div>
                    {/* FOOTER ICONS END */}

              </div>

              </div>
              </div>
              {/* PROFILE CARD END */}

            
            </div>
       


         
             
          </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer class="footer">
            <div class="d-sm-flex justify-content-center justify-content-sm-between">
              <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
</>
);

   }

//}
export default Startupdetails;