import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';
import moment from 'moment/moment';

import '../Resources/dashboard.css';
import Navbar from '../navbar';
import Sidebar from '../Sidebar';
import Add_product from './add_product';
import Add_productgroup from './add_productgroup';

import {useRef as useref} from 'react';
import {useReactToPrint} from 'react-to-print';

//EXCEL DOWNLOAD IMPORT
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';




function Usermanufacturing(){

  
 //Report Print Function
 const componentRef = useref();
 const handlePrint = useReactToPrint({
   content: () => componentRef.current,
 });


 


 //Variables
const[products,setproducts] = useState([]);//SetData Variable
//Data Variable
const[data,setdata] = useState([], );


  // Function to download the data as an Excel file
  const downloadExcel = () => {
    // Convert JSON data to Excel worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);
    
    // Create a new workbook and add the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    
    // Convert the workbook to a binary array
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    
    // Create a Blob from the binary data and trigger a download
    const excel_data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(excel_data, "manufacturing_data.xlsx");
  };
  
  

 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const busyid=sessionStorage.getItem("BusyId00");
 const counterid=sessionStorage.getItem("CounterId00");



 
 
{/* PRODUCTS TABLE COLUMNS MANAGEMENT VARIABLES START */}
const[cd_col1_val,setcd_col1_val] = useState(0);//column1
const[cd_col2_val,setcd_col2_val] = useState(0);//column2
const[cd_col3_val,setcd_col3_val] = useState(0);//column3
const[cd_col4_val,setcd_col4_val] = useState(0);//column4
const[cd_col5_val,setcd_col5_val] = useState(0);//column5
const[cd_col6_val,setcd_col6_val] = useState(0);//column6
const[cd_col7_val,setcd_col7_val] = useState(0);//column7
const[cd_col8_val,setcd_col8_val] = useState(0);//column8
const[cd_col9_val,setcd_col9_val] = useState(0);//column9
const[cd_col10_val,setcd_col10_val] = useState(0);//column10
const[cd_col11_val,setcd_col11_val] = useState(0);//column11
const[cd_col12_val,setcd_col12_val] = useState(0);//column12
const[cd_col13_val,setcd_col13_val] = useState(0);//column13
const[cd_col14_val,setcd_col14_val] = useState(0);//column14
const[cd_col15_val,setcd_col15_val] = useState(0);//column15
const[cd_col16_val,setcd_col16_val] = useState(0);//column16
const[cd_col17_val,setcd_col17_val] = useState(0);//column17
const[cd_col18_val,setcd_col18_val] = useState(0);//column18
const[cd_col19_val,setcd_col19_val] = useState(0);//column19
const[cd_col20_val,setcd_col20_val] = useState(0);//column20
const[cd_col21_val,setcd_col21_val] = useState(0);//column21
const[cd_col22_val,setcd_col22_val] = useState(0);//column22
const[cd_col23_val,setcd_col23_val] = useState(0);//column23
const[cd_col24_val,setcd_col24_val] = useState(1);//column23


{/* PRODUCTS TABLE COLUMNS MANAGEMENT VARIABLES END */}


 //Checking if Sessions are Set

// alert(logstatus,loguser,logadminid);
 //Sessions Not Set
 {/*if(((logstatus==null)||(logstatus==""))||((logid==null)||(logid==""))||((token==null)||(token==" "))||((busyid==null)||(busyid==" "))||((counterid==null)||(counterid==" "))){
  
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");
   
 
    window.location.replace("/businesstools-login");
   }
  
   //Sessions Set
  else*/}{

   

    
      


//Error Variable
const[errorvar,seterrorvar] = useState();




//FILTER VARIABLES
const[filter_val,setfilter_val] = useState(0);
const[filter_product_id,setfilter_product_id] = useState(); const updatefilter_product_id = (event) => { setfilter_product_id(event.target.product_id);}; const[filter_product_id_er,setfilter_product_id_er]=useState();
const[filter_product_code,setfilter_product_code] = useState(); const updatefilter_product_code = (event) => { setfilter_product_code(event.target.value);}; const[filter_product_code_er,setfilter_product_code_er]=useState();
const[filter_product_hsn,setfilter_product_hsn] = useState(); const updatefilter_product_hsn = (event) => { setfilter_product_hsn(event.target.value);}; const[filter_product_hsn_er,setfilter_product_hsn_er]=useState();
const[filter_product_name,setfilter_product_name] = useState(); const updatefilter_product_name = (event) => { setfilter_product_name(event.target.value);}; const[filter_product_name_er,setfilter_product_name_er]=useState();
const[filter_total_cost,setfilter_total_cost] = useState(); const updatefilter_total_cost = (event) => { setfilter_total_cost(event.target.value);}; const[filter_total_cost_er,setfilter_total_cost_er]=useState();
const[filter_individual_cost,setfilter_individual_cost] = useState(); const updatefilter_individual_cost = (event) => { setfilter_individual_cost(event.target.value);}; const[filter_individual_cost_er,setfilter_individual_cost_er]=useState();
const[filter_trno,setfilter_trno] = useState(); const updatefilter_trno = (event) => { setfilter_trno(event.target.value);}; const[filter_trno_er,setfilter_trno_er]=useState();


/*
const[filter_type,setfilter_type] = useState(); const updatefilter_type = (event) => { setfilter_type(event.target.value);}; const[filter_type_er,setfilter_type_er]=useState();
const[filter_category,setfilter_category] = useState(); const updatefilter_category = (event) => { setfilter_category(event.target.value);}; const[filter_category_er,setfilter_category_er]=useState();
const[filter_brand,setfilter_brand] = useState(); const updatefilter_brand = (event) => { setfilter_brand(event.target.value);}; const[filter_brand_er,setfilter_brand_er]=useState();
const[filter_group,setfilter_group] = useState(); const updatefilter_group = (event) => { setfilter_group(event.target.value);}; const[filter_group_er,setfilter_group_er]=useState();
const[filter_sales_rate,setfilter_sales_rate] = useState(); const updatefilter_sales_rate = (event) => { setfilter_sales_rate(event.target.value);}; const[filter_sales_rate_er,setfilter_sales_rate_er]=useState();
const[filter_purchase_rate,setfilter_purchase_rate] = useState(); const updatefilter_purchase_rate = (event) => { setfilter_purchase_rate(event.target.value);}; const[filter_purchase_rate_er,setfilter_purchase_rate_er]=useState();
const[filter_product_status,setfilter_product_status] = useState(); const updatefilter_product_status = (event) => { setfilter_product_status(event.target.value);}; const[filter_product_status_er,setfilter_product_status_er]=useState();

//PART 2 FILTERS
const[filter_val1,setfilter_val1] = useState(0);
const[filter_group_id,setfilter_group_id] = useState();const updatefilter_group_id = (event) => { setfilter_group_id(event.target.value);}; const[filter_group_id_er,setfilter_group_id_er]=useState();
const[filter_group_name,setfilter_group_name] = useState();const updatefilter_group_name = (event) => { setfilter_group_name(event.target.value);}; const[filter_group_name_er,setfilter_group_name_er]=useState();
const[filter_group_industry,setfilter_group_industry] = useState();const updatefilter_group_industry = (event) => { setfilter_group_industry(event.target.value);}; const[filter_group_industry_er,setfilter_group_industry_er]=useState();
const[filter_group_sector,setfilter_group_sector] = useState();const updatefilter_group_sector = (event) => { setfilter_group_sector(event.target.value);}; const[filter_group_sector_er,setfilter_group_sector_er]=useState();
const[filter_group_type,setfilter_group_type] = useState();const updatefilter_group_type = (event) => { setfilter_group_type(event.target.value);}; const[filter_group_type_er,setfilter_group_type_er]=useState();
const[filter_group_category,setfilter_group_category] = useState();const updatefilter_group_category = (event) => { setfilter_group_category(event.target.value);}; const[filter_group_category_er,setfilter_group_category_er]=useState();
const[filter_group_status,setfilter_group_status] = useState(); const updatefilter_group_status = (event) => { setfilter_group_status(event.target.value);}; const[filter_group_status_er,setfilter_group_status_er]=useState();

*/


//Variables for pagination
const [currentPage, setCurrentPage] = useState(1);
const [pageNumber, setpageNumber] = useState(1);

useEffect(()=>{
   
  getData();
  
  },[])
 
    
  
//******************* GET PRODUCTS DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))){
 
    
    
  //Fetching Filter Variables
  const $filter_product_id = filter_product_id;const $filter_product_code = filter_product_code;const $filter_product_hsn = filter_product_hsn;const $filter_product_name = filter_product_name; const $filter_individual_cost = filter_individual_cost; const $filter_total_cost = filter_total_cost; 
 const $filter_trno = filter_trno;  /*const $filter_category = filter_category;
  const $filter_group = filter_group;const $filter_brand = filter_brand; const $filter_product_status = filter_product_status;const $filter_sales_rate = filter_sales_rate;const $filter_purchase_rate = filter_purchase_rate;
  const $filter_group_id = filter_group_id;const $filter_group_name = filter_group_name;const $filter_group_industry = filter_group_industry;const $filter_group_sector = filter_group_sector;const $filter_group_type = filter_group_type;const $filter_group_category = filter_group_category;const $filter_group_status = filter_group_status;
  */

 axios.post("bt-get-manufacturing-default", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Sn_start :0,
   Sn_end :0,
   Sn_total :0,
   Sn_limit :0,
  //Filters
  Filter_val : filter_val,
  Filter_product_id : $filter_product_id,
  Filter_product_code : $filter_product_code,
  Filter_product_hsn : $filter_product_hsn,
  Filter_product_name : $filter_product_name,
  Filter_individual_cost : $filter_individual_cost,
  Filter_total_cost : $filter_total_cost,
  Filter_trno : $filter_trno,
  /*Filter_type : $filter_type,
  Filter_category : $filter_category,
  Filter_brand : $filter_brand,
  Filter_group : $filter_group,
  Filter_sales_rate : $filter_sales_rate,
  Filter_purchase_rate : $filter_purchase_rate,
  Filter_product_status : $filter_product_status, */
  Filter_val1 : 0,
 /* Filter_group_id : $filter_group_id, 
  Filter_group_name : $filter_group_name, 
  Filter_group_type : $filter_group_type, 
  Filter_group_category : $filter_group_category, 
  Filter_group_sector : $filter_group_sector, 
  Filter_group_industry : $filter_group_industry, 
  Filter_group_status : $filter_group_status,
  */
//Code Edits for pagination
'page' : pageNumber
 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setdata(response.data.data.data);

  
  }
     //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){
    setfilter_product_id_er(response.data.validatorerror.Filter_product_id);
    setfilter_product_code_er(response.data.validatorerror.Filter_product_code);
    setfilter_product_hsn_er(response.data.validatorerror.Filter_product_hsn);
    setfilter_product_name_er(response.data.validatorerror.Filter_product_name);
    setfilter_total_cost_er(response.data.validatorerror.Filter_total_cost);
    setfilter_individual_cost_er(response.data.validatorerror.Filter_individual_cost);
    setfilter_trno_er(response.data.validatorerror.Filter_trno);
  
  /*  setfilter_type_er(response.data.validatorerror.Filter_type);
    setfilter_category_er(response.data.validatorerror.Filter_category);
    setfilter_brand_er(response.data.validatorerror.Filter_brand);
    setfilter_group_er(response.data.validatorerror.Filter_group);
    setfilter_sales_rate_er(response.data.validatorerror.Filter_sales_rate);
    setfilter_purchase_rate_er(response.data.validatorerror.Filter_purchase_rate);
    setfilter_product_status_er(response.data.validatorerror.Filter_product_status);
    setfilter_group_id_er(response.data.validatorerror.Filter_group_id);
    setfilter_group_name_er(response.data.validatorerror.Filter_group_name);
    setfilter_group_type_er(response.data.validatorerror.Filter_group_type);
    setfilter_group_category_er(response.data.validatorerror.Filter_group_category);
    setfilter_group_sector_er(response.data.validatorerror.Filter_group_sector);
    setfilter_group_industry_er(response.data.validatorerror.Filter_group_industry);
    setfilter_group_status_er(response.data.validatorerror.Filter_group_status); */
  }

  //Data Violation
  if(response.data.error==102){ 
  
    setfilter_product_id_er(response.data.filter_product_id_er);
    setfilter_product_code_er(response.data.filter_product_code_er);
    setfilter_product_hsn_er(response.data.filter_product_hsn_er);
    setfilter_product_hsn_er(response.data.filter_product_hsn_er);
    setfilter_total_cost_er(response.data.filter_total_cost_er);
    setfilter_individual_cost_er(response.data.filter_individual_cost_er);
    setfilter_trno_er(response.data.filter_trno_er);
  
  /*  setfilter_type_er(response.data.filter_type_er);
    setfilter_category_er(response.data.filter_category_er);
    setfilter_group_er(response.data.filter_group_er);
    setfilter_brand_er(response.data.filter_brand_er);
    setfilter_sales_rate_er(response.data.filter_sales_rate_er);
    setfilter_purchase_rate_er(response.data.filter_purchase_rate_er);
    setfilter_product_status_er(response.data.filter_product_status_er);
    setfilter_group_id_er(response.data.filter_group_id_er);
    setfilter_group_name_er(response.data.validatorerror.Filter_group_name);
    setfilter_group_type_er(response.data.validatorerror.Filter_group_type);
    setfilter_group_category_er(response.data.validatorerror.Filter_group_category);
    setfilter_group_industry_er(response.data.validatorerror.Filter_group_industry);
    setfilter_group_sector_er(response.data.validatorerror.Filter_group_sector);
    setfilter_group_status_er(response.data.validatorerror.Filter_group_status); */

  }
  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* GET PRODUCTS DATA FUNCTION END  ********************//


 
 

//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();




 
 //******************* GET PRODUCT ITEM DATA BY ID FUNCTION START  ********************//


//eproduct_name
const[eproduct_name,seteproduct_name]=useState();const updateeproduct_name = (event) => { seteproduct_name(event.target.value);}; const[eproduct_name_er,seteproduct_name_er]=useState();
//eproduct_code
const[eproduct_code,seteproduct_code]=useState();const updateeproduct_code = (event) => { seteproduct_code(event.target.value);}; const[eproduct_code_er,seteproduct_code_er]=useState();
//eproduct_hsn
const[eproduct_hsn,seteproduct_hsn]=useState();const updateeproduct_hsn = (event) => { seteproduct_hsn(event.target.value);}; const[eproduct_hsn_er,seteproduct_hsn_er]=useState();
//edate
const[edate,setedate]=useState();const updateedate = (event) => { setedate(event.target.value);}; const[edate_er,setedate_er]=useState();


 //******************* EDIT PRODUCT ITEM FUNCTION START ********************//
function editproduct(){

  //Setting Error Variables
  seteproduct_name_er(" ");seteproduct_code_er(" ");seteproduct_hsn_er(" ");setedate_er(" ");
  const Mid = sessionStorage.getItem("Mid00");
  const trno = sessionStorage.getItem("Trno00");
  

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))&&((Mid!=null)||(Mid!=" "))){
 
 
  axios.post("bt-manufacturing-edit", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Mid:Mid,
   Date:document.getElementById("edate").value,
   Product_name :document.getElementById("eproduct_name").value,
   Product_code :document.getElementById("eproduct_code").value,
   Product_hsn :document.getElementById("eproduct_hsn").value,
   Tr_no: trno
  /* Type:eproduct_type,
   Category : eproduct_category,
   Brand:eproduct_brand,
   Status:eproduct_status,
   Description:edescription,
   Group_id:egroup_id,
   Units:eunits,
   Sales_rate:esales_rate,
   Purchases_rate:epurchases_rate,
   Discount_per:ediscount_per,
   Sgst_per:esgst_per,
   Cgst_per:ecgst_per,
   Igst_per:eigst_per,
   Tax_per:etax_per */

 })
 .then((response) => {

  console.log(response);

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");
     sessionStorage.removeItem("Mid00");
     sessionStorage.removeItem("Trno00");

     window.location.replace("businesstools-login");
  }

   //Validation Errors
 if(response.data.validatorerror){
  setedate_er(response.data.validatorerror.Date);
  seteproduct_name_er(response.data.validatorerror.Product_name);
  seteproduct_code_er(response.data.validatorerror.Product_code);
  seteproduct_hsn_er(response.data.validatorerror.Product_hsn);
 /* seteproduct_type_er(response.data.validatorerror.Type);
  seteproduct_category_er(response.data.validatorerror.Category);
  seteproduct_brand_er(response.data.validatorerror.Brand);
  seteproduct_status_er(response.data.validatorerror.Status);
  setedescription_er(response.data.validatorerror.Description);
  setegroup_id_er(response.data.validatorerror.Group_id);
  setesales_rate_er(response.data.validatorerror.Sales_rate);
  setepurchases_rate_er(response.data.validatorerror.Purchases_rate);
  seteunits_er(response.data.validatorerror.Units);
  setediscount_per_er(response.data.validatorerror.Discount_per);
  setesgst_per_er(response.data.validatorerror.Sgst_per);
  setecgst_per_er(response.data.validatorerror.Cgst_per);
  seteigst_per_er(response.data.validatorerror.Igst_per);
  setetax_per_er(response.data.validatorerror.Tax_per); */


}
//Invalid Entries
if(response.data.error==102){ 
  
  setedate_er(response.data.date_er);
  seteproduct_name_er(response.data.product_name_er);
  seteproduct_code_er(response.data.validatorerror.Product_code);
  seteproduct_hsn_er(response.data.validatorerror.Product_hsn);
 /* seteproduct_type_er(response.data.type_er);
  seteproduct_category_er(response.data.category_er);
  seteproduct_brand_er(response.data.brand_er);
  seteproduct_status_er(response.data.status_er);
  setedescription_er(response.data.description_er);
  setegroup_id_er(response.data.group_id_er);
  setesales_rate_er(response.data.sales_rate_er);
  setepurchases_rate_er(response.data.purchases_rate_er);
  seteunits_er(response.data.units_er);
  setediscount_per_er(response.data.discount_per_er);
  setesgst_per_er(response.data.sgst_per_er);
  setecgst_per_er(response.data.cgst_per_er);
  seteigst_per_er(response.data.igst_per_er);
  setetax_per_er(response.data.tax_per_er); */
  seterror_msg("Cannot Update Product Item!");  }


  //Invalid Entries
  if(response.data.error==103){     seterror_msg(response.data.error_msg);  }

   //Transaction Updated Successfully
   if(response.data.successvar==1){        
     //Setting Variables to empty 
     seteproduct_name_er(" ");seteproduct_code_er(" ");seteproduct_hsn_er(" ");
     /* seteproduct_type_er(" ");seteproduct_brand_er(" ");seteproduct_category_er(" ");seteproduct_status_er();setedescription_er();seterror_msg(" ");
     setesales_rate_er(" ");setepurchases_rate_er(" ");seteunits_er(" ");setediscount_per_er(" ");setesgst_per_er(" ");setecgst_per_er(" ");seteigst_per_er(" ");setetax_per_er(" ");setegroup_id_er(" ");
     seteproduct_name(" ");seteproduct_code(" ");seteproduct_hsn(" ");seteproduct_type(" ");seteproduct_brand(" ");seteproduct_category(" ");seteproduct_status();setedescription();
     setesales_rate(" ");setepurchases_rate(" ");seteunits(" ");setediscount_per(" ");setesgst_per(" ");setecgst_per(" ");seteigst_per(" ");setetax_per(" ");setegroup_id(" ");setepgroup_name();
    */ document.getElementById("edit-form").style.display="none"; 
    //document.getElementById("edit-success-card").style.display="block";  
  
    window.location.replace("/businesstools-user-edit-manufacture-product"); 
  
  }

   //Cannot Update Transaction
   else{  seterror_msg("Cannot Update Product Item !"); }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");
   sessionStorage.removeItem("Mid00");
   sessionStorage.removeItem("Trno00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* EDIT PRODUCT ITEM FUNCTION END ********************//




  
   
//******************* DELETE PRODUCT ITEM FUNCTION START ********************//
function deleteproduct(){

  const Mid=sessionStorage.getItem("DMid00");
  const Dtrno=sessionStorage.getItem("DTrno00");

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))&&((Mid!=null)||(Mid!=" "))){
 
 axios.post("bt-manufacturing-delete", {
   Busy_id:busyid,
   Counter_id:counterid,
   Log_id:logid,
   Token:token,
   Mid:Mid,
   Tr_no:Dtrno
  
 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");
     sessionStorage.removeItem("DMid00");


     window.location.replace("businesstools-login");
  }

  //Counter Deleted Successfully
   if(response.data.successvar==1){        

      document.getElementById("delete-card").style.display="none";
      document.getElementById("delete-success-card").style.display="block";  
      sessionStorage.removeItem("DMid");

  }
  
      //Cannot Delete Counter
    else{  
    document.getElementById("delete-card").style.display="none";
    document.getElementById("delete-error-card").style.display="block";   }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");
   sessionStorage.removeItem("DProductId00");


   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* DELETE PRODUCT ITEM FUNCTION END ********************//
















  
//product_name
const[product_name,setproduct_name]=useState();const updateproduct_name = (event) => { setproduct_name(event.target.value); getproductbyname();}; const[product_name_er,setproduct_name_er]=useState();
//product_code
const[product_code,setproduct_code]=useState();const updateproduct_code = (event) => { setproduct_code(event.target.value);}; const[product_code_er,setproduct_code_er]=useState();
//product_hsn
const[product_hsn,setproduct_hsn]=useState();const updateproduct_hsn = (event) => { setproduct_hsn(event.target.value);}; const[product_hsn_er,setproduct_hsn_er]=useState();
//date
const[date,setdate]=useState();const updatedate = (event) => { setdate(event.target.value);}; const[date_er,setdate_er]=useState();


 //******************* CREATE MANUFACTURING FUNCTION START ********************//
 function createmanufacturing(){

  //Setting Error Variables
  setproduct_name_er(" ");setproduct_code_er(" ");setproduct_hsn_er(" ");setdate_er(" "); seterror_msg(" ");

  const productid = sessionStorage.getItem("Productid00");

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))){
 
 
  axios.post("bt-create-manufacturing", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Date:moment(date).format('YYYY-MM-DD'),
   Product_name :product_name,
   Product_code :product_code,
   Product_hsn :product_hsn,

 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");
     window.location.replace("businesstools-login");
  }

   //Validation Errors
 if(response.data.validatorerror){
  setproduct_name_er(response.data.validatorerror.Product_name);
  setproduct_code_er(response.data.validatorerror.Product_code);
  setproduct_hsn_er(response.data.validatorerror.Product_hsn);
  setdate_er(response.data.validatorerror.Date);
  

}
//Invalid Entries
if(response.data.error==102){ 
  
  setproduct_name_er(response.data.product_name_er);
  setproduct_code_er(response.data.validatorerror.product_code_er);
  setproduct_hsn_er(response.data.validatorerror.product_hsn_er);
  setdate_er(response.data.date_er);
  seterror_msg("Cannot Create Transaction!");  }


  //Invalid Entries
  if(response.data.error==103){     seterror_msg(response.data.error_msg);  }

   //Transaction Updated Successfully
   if(response.data.successvar==1){        
     //Setting Variables to empty 
     setproduct_name_er(" ");setproduct_code_er(" ");setproduct_hsn_er(" ");setdate_er(" ");seterror_msg(" ");
   
    //redirecting to next page
    sessionStorage.setItem("MId00", response.data.mid);
    sessionStorage.setItem("Trno00", response.data.trno);
    window.location.replace("/businesstools-user-manufacture-product"); 
   }

   //Cannot Update Transaction
   else{  seterror_msg("Cannot Create Transaction !"); }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* CREATE MANUFACTURING FUNCTION END ********************//




 const[cdefault,setcdefault] = useState(0);
 const[ecdefault,setecdefault] = useState(0);
    //******************* GET PRODUCT DATA BY NAME FUNCTION START  ********************//
    function getproductbyname(){
 

      //Checking if user loggedin
      if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))){
     
         const cname = document.getElementById("product_name").value;
         const cnlength = cname.length;
         if((cdefault==0)&&(cnlength>=3)){
         
     
     axios.post("bt-get-productlist-spr", {
       Log_id:logid,
       Token:token,
       Busy_id:busyid,
       Counter_id:counterid,
       Name:document.getElementById("product_name").value,
       Sn_start :0,
       Sn_end :0,
       Sn_total :0,
       Sn_limit :0
     })
     .then((response) => {
    
      //console.log(response);
    
      //Invalid Session
      if((response.data.error)==100){ 
          //Remove Session Variables and redirect to login
         sessionStorage.removeItem("logStatus00");
         sessionStorage.removeItem("logId00");
         sessionStorage.removeItem("logToken00");
         sessionStorage.removeItem("BusyId00");
         sessionStorage.removeItem("CounterId00");
    
         window.location.replace("businesstools-login");
      }
      //Success Data Fetched
      if(response.data.successvar==1){
       //Setting Data
       setproducts(response.data.productlist);
       document.getElementById("products-list-box").style.display="block";
      
      }
      //Cannot Fetch Data
      else{
       seterrorvar("Cannot Fetch Data !");
      }
     
     
     });
    }
     }
     //User not Logedin
     else{
     
     //Remove Session Variables and redirect to login
       sessionStorage.removeItem("logStatus00");
       sessionStorage.removeItem("logId00");
       sessionStorage.removeItem("logToken00");
       sessionStorage.removeItem("BusyId00");
       sessionStorage.removeItem("CounterId00");
    
       window.location.replace("businesstools-login");
     
      }
    
     }
      
     //******************* GET PRODUCT DATA BY NAME FUNCTION END  ********************//
    
    
    







return(
    <>
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Business Tools|BookKeeping Solution</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div class="container-scroller">

      <Sidebar />
    <div class="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div class="main-panel">

         
               {/* ADD PRODUCT EDIT ERROR CARD START */}
            <div class="d-flex justify-content-center">
            <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="edit-error-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-danger">Cannot Fetch Data !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("edit-error-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* ADD PRODUCT EDIT ERROR CARD END */}

                    {/* ADD PRODUCT DELETE CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-danger">Are you sure you want to delete this transaction?</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> This action cannot be reversed. You will lose all your data related to this transaction if you wish to continue this action.</p>
                      <button class="mt-3 btn btn-outline-success" onClick={deleteproduct}>Yes, I Know! Delete Transaction.</button>
                      <button class="mt-3 btn btn-outline-danger" style={{marginLeft:"1rem"}} onClick={()=>{  
                      //Cancel Delete and remove Product_id
                      sessionStorage.removeItem("DMid00"); sessionStorage.removeItem("DTrno00"); document.getElementById('delete-card').style.display = 'none'; }}>No, Don't Delete Transaction.</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* ADD PRODUCT DELETE CARD END */}
              {/* ADD PRODUCT DELETE SUCCESS CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Product Deleted Successfully Manufacturing !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("delete-success-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* ADD PRODUCT DELETE SUCCESS CARD END */}
               {/*  ADD PRODUCT DELETE ERROR CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-error-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-danger">Cannot Delete Product from Manufacturing !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("delete-error-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* ADD PRODUCT DELETE ERROR CARD END */}



          <div class="content-wrapper pb-0">


            {/* PRODUCT LIST BOX START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-4 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"1rem",display:"none"}} id="products-list-box">
                <div class="card">
                <div class="d-flex justify-content-between">
                    <div>
                    <h4 style={{padding:'1rem'}}>Products</h4>
                    </div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ setcdefault(1); document.getElementById("products-list-box").style.display="none"; }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                    
                    <table class="table table-hover">
                      <thead>
                        <tr>
                        {/*}  <th>Product Id</th> */}
                          <th>Product Name</th>
                        {/*}  <th>Product HSN</th>
                          <th>Product Code</th> */}
                          <th>Purchase Rate</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                       products.map((curElm7,ind)=>{

                         return(
                         <>
                        <tr key={curElm7.Product_id}>
                          {/* <td>{curElm7.Product_id}</td> */}
                          <td>{curElm7.Product_name}</td>
                        {/*}  <td>{curElm7.Product_hsn}</td>
                          <td>{curElm7.Product_code}</td> */}
                          <td>{curElm7.Purchase_rate}</td>
                          <td><i class="mdi mdi-assistant text-warning" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                        //Setting Product Details for 
                          setproduct_code(curElm7.Product_code);setproduct_hsn(curElm7.Product_hsn);setproduct_name(curElm7.Product_name); 
                          document.getElementById("products-list-box").style.display="none";
                              }}></i></td>

                        </tr>
                        </>
                         )
                         }
					   
	                  		 )
                         }
                      </tbody>
                    </table>
              
                </div>
              </div>
              </div>
              {/* PRODUCT LIST BOX END */}


          <div class="row">
              <div class="col-12 grid-margin create-form" id="create-form">
                 {/* MANUFACTURE PRODUCT FORM START */}
              <div class="card">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ 
                         //close edit form button
                         document.getElementById("edit-form").style.display="none";
                        //Setting Variables to empty
                        //Setting Variables to empty 
                        seteproduct_name_er(" ");seteproduct_code_er(" ");seteproduct_hsn_er(" ");setdate_er(" ");seterror_msg(" ");
                       }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                  <div class="card-body">
                    <h4 class="card-title">Manufacture Product</h4>
                    <div class="form-sample">
                      <p class="card-description">enter product manufacturing details here</p>
                      <code>{error_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" id="date" value={date} onChange={updatedate} />
                            <code>{date_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product Name<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Red T-Shirt" id="product_name" value={product_name} onChange={updateproduct_name} />
                            <code>{product_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product Code<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="PC88888" id="product_code" value={product_code} onChange={updateproduct_code} />
                            <code>{product_code_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product HSN<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Red T-Shirt" id="product_hsn" value={product_hsn} onChange={updateproduct_hsn} />
                            <code>{product_hsn_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                        <button class="btn btn-primary" style={{fontWeight:"bold"}} onClick={createmanufacturing}>Manufacture Product</button>
                      
                        </div>                     
                      </div>           
                   
                    </div>
                  </div>
                </div>
               {/* MANUFACTURE PRODUCT FORM END */}
              </div>
         
          </div>

            <div class="page-header flex-wrap">
            <div class="header-left"></div>
              <div class="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div class="d-flex align-items-center">
                  <a href="#">
                    <p class="m-0 pe-3">Manufacturing</p>
                  </a>
                  <a class="ps-3 me-4" href="#">
                  {/*}  <p class="m-0">ADE-00234</p> */}
                  </a>
                </div>
                <button type="button" class="btn btn-primary mt-2 mt-sm-0 btn-icon-text" onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }} >
                  <i class="mdi mdi-plus-circle"></i> Manufacture Product </button>
              </div>
            </div>

          

             {/* PRODUCT ITEM EDIT FORM START */}
             <div class="row">
              <div class="col-12 grid-margin create-form" id="edit-form" style={{display:"none"}}>
              <div class="card">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ 
                         //close edit form button
                         document.getElementById("edit-form").style.display="none";
                        //Setting Variables to empty
                        //Setting Variables to empty 
                        seteproduct_name_er(" ");seteproduct_code_er(" ");seteproduct_hsn_er(" ");setedate_er(" ");
                        /*seteproduct_brand_er(" ");seteproduct_category_er(" ");seteproduct_status_er();setedescription_er();seterror_msg(" ");
                        setesales_rate_er(" ");setepurchases_rate_er(" ");seteunits_er(" ");setediscount_per_er(" ");setesgst_per_er(" ");setecgst_per_er(" ");seteigst_per_er(" ");setetax_per_er(" ");setegroup_id_er(" ");
                        seteproduct_name(" ");seteproduct_code(" ");seteproduct_hsn(" ");seteproduct_type(" ");seteproduct_brand(" ");seteproduct_category(" ");seteproduct_status();setedescription();
                        setesales_rate(" ");setepurchases_rate(" ");seteunits(" ");setediscount_per(" ");setesgst_per(" ");setecgst_per(" ");seteigst_per(" ");setetax_per(" ");setegroup_id(" ");setepgroup_name();
                        */
                        sessionStorage.removeItem("Mid00");}}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                  <div class="card-body">
                    <h4 class="card-title">Edit Manufactured Product</h4>
                    <div class="form-sample">
                      <p class="card-description">edit your product details here</p>
                      <code>{error_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" placeholder="" id="edate" onChange={updateedate} value={edate} />
                              <p class="text-muted" style={{fontSize:"0.7rem", textAlign:"left"}}></p>
                            <code>{edate_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product Name<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Red T-Shirt" id="eproduct_name" onChange={updateeproduct_name} value={eproduct_name} />
                            <code>{eproduct_name_er}</code>
                            </div>
                          </div>
                        </div>  
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product Code</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="PC00001" id="eproduct_code" value={eproduct_code} onChange={updateeproduct_code} />
                            <code>{eproduct_code_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product HSN</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="214231" id="eproduct_hsn" value={eproduct_hsn} onChange={updateeproduct_hsn}/>
                            <code>{eproduct_hsn_er}</code>
                            </div>
                          </div>
                        </div>   
                        </div>
                                       
                    

                        
                      
                        <button class="btn btn-primary" style={{fontWeight:"bold"}} onClick={editproduct}>Edit Product</button>
                      
                       
    
                     
                   
                    </div>
                  </div>
                </div>
              </div>
              </div>
               {/* PRODUCT ITEM EDIT FORM END */}
                  
                  {/* PRODUCTS ITEM LIST START */}
             <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Manufactured Products</h4>
                    <p class="card-description">view manufactured products here
                    </p>

                 {/* FILTER SECTION START */}
                 <hr/>
                            <div id="filter-top-card" class="row col-12" style={{textAlign:"left",display:"none"}}>
                            <div class="d-flex justify-content-between">
                            <div>
                            <h4 style={{padding:'1rem'}}>Filters</h4>
                           </div>
                           <div>
                    
                           <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("filter-top-card").style.display="none"; }}>
                           <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                           </button>
                           </div>
                           </div>
        
                        <div class="row">
                       <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Tr_no</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" id="filter_trno" placeholder="457" value={filter_trno} onChange={updatefilter_trno}  />
                              <code>{filter_trno_er}</code> 
                            </div>
                          </div>
                        </div> 
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Product Code</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control"  id="filter_product_code" placeholder="PC123" value={filter_product_code} onChange={updatefilter_product_code}  />
                              <code>{filter_product_code_er}</code>
                            </div>
                          </div>
                        </div>
                   
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Product Name</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" id="filter_product_name" placeholder="Product ABC" value={filter_product_name} onChange={updatefilter_product_name}  />
                              <code>{filter_product_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Total Cost</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control"  id="filter_total_cost" placeholder="15000.00" value={filter_total_cost} onChange={updatefilter_total_cost}  />
                              <code>{filter_total_cost_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Individual Cost</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" placeholder="200.00"   id="individual_cost" value={filter_individual_cost} onChange={updatefilter_individual_cost}  />
                              <code>{filter_individual_cost_er}</code>
                            </div>
                          </div>
                        </div>
                   
                        <div class="col-md-4">
                          
                        <button class="btn btn-primary" onClick={()=>{ setfilter_val(1); getData(); }}>Apply Filters</button>
                         <button class="btn btn-danger" style={{marginLeft:"1rem"}} onClick={()=>{ setfilter_val(0); getData(); }}>Remove Filters</button>
                       
                        </div>
                      </div>

                      <div style={{marginBottom:"1rem"}}>
                  
                       
                      </div>
                            <hr/>                      
                            </div>
                            
                            {/* FILTER SECTION END */}

                    <div class="col-12" style={{marginBottom:"2rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                        {/* COLUMNS MANAGEMENT DROPDOWN START */}
                          <div class="dropdown">
                          <button class="btn btn-warning" onClick={()=>{ document.getElementById("filter-top-card").style.display="block"; }}><i class="mdi mdi-filter-outline"></i></button>
                            <button class="btn btn-primary dropdown-toggle" style={{marginLeft:"1rem"}} type="button" id="dropdownMenuIconButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="mdi mdi-table-large"></i>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuIconButton1">
                              <h6 class="dropdown-header">Columns</h6>
                              <hr/>
                              
                              {/* Column 1 */}
                              <li><div class="d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Sn</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c1_yes" onClick={()=>{setcd_col1_val(1); document.getElementById("cd_c1_yes").style.display="none"; document.getElementById("cd_c1_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c1_no" style={{ display:"none"}} onClick={()=>{ setcd_col1_val(0); document.getElementById("cd_c1_yes").style.display="block"; document.getElementById("cd_c1_no").style.display="none"; }}></i>
                              </div></div></li> 
                                {/* Column 13 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Tr_no</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c13_yes" onClick={()=>{setcd_col13_val(1); document.getElementById("cd_c13_yes").style.display="none"; document.getElementById("cd_c13_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c13_no" style={{ display:"none"}} onClick={()=>{ setcd_col13_val(0); document.getElementById("cd_c13_yes").style.display="block"; document.getElementById("cd_c13_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/*} Column 2 */}
                               <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Date</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c2_yes"  onClick={()=>{setcd_col2_val(1); document.getElementById("cd_c2_yes").style.display="none"; document.getElementById("cd_c2_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c2_no" style={{ display:"none"}} onClick={()=>{ setcd_col2_val(0); document.getElementById("cd_c2_yes").style.display="block"; document.getElementById("cd_c2_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 3 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Product Code</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c3_yes"  onClick={()=>{setcd_col3_val(1); document.getElementById("cd_c3_yes").style.display="none"; document.getElementById("cd_c3_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c3_no" style={{ display:"none"}} onClick={()=>{ setcd_col3_val(0); document.getElementById("cd_c3_yes").style.display="block"; document.getElementById("cd_c3_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 4 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Product Name</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c4_yes"  onClick={()=>{setcd_col4_val(1); document.getElementById("cd_c4_yes").style.display="none"; document.getElementById("cd_c4_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c4_no" style={{ display:"none"}} onClick={()=>{ setcd_col4_val(0); document.getElementById("cd_c4_yes").style.display="block"; document.getElementById("cd_c4_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 5 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Inputs/ Value</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c5_yes"  onClick={()=>{setcd_col5_val(1); document.getElementById("cd_c5_yes").style.display="none"; document.getElementById("cd_c5_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c5_no" style={{ display:"none"}} onClick={()=>{ setcd_col5_val(0); document.getElementById("cd_c5_yes").style.display="block"; document.getElementById("cd_c5_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 6 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Quantity</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c6_yes"  onClick={()=>{setcd_col6_val(1); document.getElementById("cd_c6_yes").style.display="none"; document.getElementById("cd_c6_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c6_no" style={{ display:"none"}} onClick={()=>{ setcd_col6_val(0); document.getElementById("cd_c6_yes").style.display="block"; document.getElementById("cd_c6_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 7 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Labour</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c7_yes" onClick={()=>{setcd_col7_val(1); document.getElementById("cd_c7_yes").style.display="none"; document.getElementById("cd_c7_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c7_no" style={{ display:"none"}} onClick={()=>{ setcd_col7_val(0); document.getElementById("cd_c7_yes").style.display="block"; document.getElementById("cd_c7_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 8 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Power</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c8_yes" onClick={()=>{setcd_col8_val(1); document.getElementById("cd_c8_yes").style.display="none"; document.getElementById("cd_c8_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c8_no" style={{ display:"none"}} onClick={()=>{ setcd_col8_val(0); document.getElementById("cd_c8_yes").style.display="block"; document.getElementById("cd_c8_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 9 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Other Expenses</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c9_yes" onClick={()=>{setcd_col9_val(1); document.getElementById("cd_c9_yes").style.display="none"; document.getElementById("cd_c9_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c9_no" style={{ display:"none"}} onClick={()=>{ setcd_col9_val(0); document.getElementById("cd_c9_yes").style.display="block"; document.getElementById("cd_c9_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 10 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Total Cost</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c10_yes" onClick={()=>{setcd_col10_val(1); document.getElementById("cd_c10_yes").style.display="none"; document.getElementById("cd_c10_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c10_no" style={{ display:"none"}} onClick={()=>{ setcd_col10_val(0); document.getElementById("cd_c10_yes").style.display="block"; document.getElementById("cd_c10_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 11 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Individual Cost</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c11_yes" onClick={()=>{setcd_col11_val(1); document.getElementById("cd_c11_yes").style.display="none"; document.getElementById("cd_c11_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c11_no" style={{ display:"none"}} onClick={()=>{ setcd_col11_val(0); document.getElementById("cd_c11_yes").style.display="block"; document.getElementById("cd_c11_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 12 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Units</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c12_yes" onClick={()=>{setcd_col12_val(1); document.getElementById("cd_c12_yes").style.display="none"; document.getElementById("cd_c12_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c12_no" style={{ display:"none"}} onClick={()=>{ setcd_col12_val(0); document.getElementById("cd_c12_yes").style.display="block"; document.getElementById("cd_c12_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 13 *
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Units</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c13_yes" onClick={()=>{setcd_col13_val(1); document.getElementById("cd_c13_yes").style.display="none"; document.getElementById("cd_c13_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c13_no" style={{ display:"none"}} onClick={()=>{ setcd_col13_val(0); document.getElementById("cd_c13_yes").style.display="block"; document.getElementById("cd_c13_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 14 *
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Discount %</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c14_yes" onClick={()=>{setcd_col14_val(1); document.getElementById("cd_c14_yes").style.display="none"; document.getElementById("cd_c14_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c14_no" style={{ display:"none"}} onClick={()=>{ setcd_col14_val(0); document.getElementById("cd_c14_yes").style.display="block"; document.getElementById("cd_c14_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 15 *
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Sgst %</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c15_yes" onClick={()=>{setcd_col15_val(1); document.getElementById("cd_c15_yes").style.display="none"; document.getElementById("cd_c15_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c15_no" style={{ display:"none"}} onClick={()=>{ setcd_col15_val(0); document.getElementById("cd_c15_yes").style.display="block"; document.getElementById("cd_c15_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 16 *
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Cgst %</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c16_yes" onClick={()=>{setcd_col16_val(1); document.getElementById("cd_c16_yes").style.display="none"; document.getElementById("cd_c16_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c16_no" style={{ display:"none"}} onClick={()=>{ setcd_col16_val(0); document.getElementById("cd_c16_yes").style.display="block"; document.getElementById("cd_c16_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 17 *
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Igst %</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c17_yes" onClick={()=>{setcd_col17_val(1); document.getElementById("cd_c17_yes").style.display="none"; document.getElementById("cd_c17_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c17_no" style={{ display:"none"}} onClick={()=>{ setcd_col17_val(0); document.getElementById("cd_c17_yes").style.display="block"; document.getElementById("cd_c17_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 18 *
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Tax %</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c18_yes" onClick={()=>{setcd_col18_val(1); document.getElementById("cd_c18_yes").style.display="none"; document.getElementById("cd_c18_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c18_no" style={{ display:"none"}} onClick={()=>{ setcd_col18_val(0); document.getElementById("cd_c18_yes").style.display="block"; document.getElementById("cd_c18_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 19 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Status</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c19_yes" onClick={()=>{setcd_col19_val(1); document.getElementById("cd_c19_yes").style.display="none"; document.getElementById("cd_c19_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c19_no" style={{ display:"none"}} onClick={()=>{ setcd_col19_val(0); document.getElementById("cd_c19_yes").style.display="block"; document.getElementById("cd_c19_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 20 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Updated By</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c20_yes" onClick={()=>{setcd_col20_val(1); document.getElementById("cd_c20_yes").style.display="none"; document.getElementById("cd_c20_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c20_no" style={{ display:"none"}} onClick={()=>{ setcd_col20_val(0); document.getElementById("cd_c20_yes").style.display="block"; document.getElementById("cd_c20_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 21 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Updated At</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c21_yes" onClick={()=>{setcd_col21_val(1); document.getElementById("cd_c21_yes").style.display="none"; document.getElementById("cd_c21_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c21_no" style={{ display:"none"}} onClick={()=>{ setcd_col21_val(0); document.getElementById("cd_c21_yes").style.display="block"; document.getElementById("cd_c21_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 24 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">QR Code</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c24_yes" style={{ display:"none"}} onClick={()=>{setcd_col24_val(1); document.getElementById("cd_c24_yes").style.display="none"; document.getElementById("cd_c24_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c24_no"  onClick={()=>{ setcd_col24_val(0); document.getElementById("cd_c24_yes").style.display="block"; document.getElementById("cd_c24_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 22 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10 text-warning">Edit</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c22_yes" onClick={()=>{setcd_col22_val(1); document.getElementById("cd_c22_yes").style.display="none"; document.getElementById("cd_c22_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c22_no" style={{ display:"none"}} onClick={()=>{ setcd_col22_val(0); document.getElementById("cd_c22_yes").style.display="block"; document.getElementById("cd_c22_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 23 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10 text-danger">Delete</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c23_yes"  onClick={()=>{setcd_col23_val(1); document.getElementById("cd_c23_yes").style.display="none"; document.getElementById("cd_c23_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c23_no" style={{ display:"none"}} onClick={()=>{ setcd_col23_val(0); document.getElementById("cd_c23_yes").style.display="block"; document.getElementById("cd_c23_no").style.display="none"; }}></i>
                              </div></div></li> 
                            {/*}  <div class="dropdown-divider"></div>
                              <button class="btn btn-danger">Close</button>*/}
                            </div> 
                          </div>
                          {/* COLUMNS MANAGEMENT DROPDOWN END */}

                          </div>
                          </div>


                    <div class="table-responsive" id="reports" ref={componentRef}>
                      <table class="table table-hover">
                        <thead>
                          <tr >
                            <th  style={{fontWeight:"bold"}} hidden={cd_col1_val==1} >Sn</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col13_val==1} >Tr_no</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col2_val==1} >Date</th> 
                            <th  style={{fontWeight:"bold"}} hidden={cd_col3_val==1} >Product Code</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col4_val==1} >Product Name</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col5_val==1} >Inputs/ Value</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col6_val==1} >Quantity</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col7_val==1} >Labour</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col8_val==1} >Power</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col9_val==1} >Other Expenses</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col10_val==1} >Total Cost</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col11_val==1} >Individual Cost</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col12_val==1} >Units</th>
                          {/*}  <th  style={{fontWeight:"bold"}} hidden={cd_col14_val==1} >Discount %</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col15_val==1} >SGST %</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col16_val==1} >CGST %</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col17_val==1} >IGST %</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col18_val==1} >Tax %</th> */}
                            <th  style={{fontWeight:"bold"}} hidden={cd_col19_val==1} >Status</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col20_val==1} >Updated By</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col21_val==1} >Updated At</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col24_val==1} ></th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col22_val==1} ></th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col23_val==1} ></th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                       data.map((curElm2,ind)=>{

                         return(
                         <>
                        <tr key={curElm2.Mid}>
                            <td hidden={cd_col1_val==1} >{curElm2.Sn}</td>
                            <td hidden={cd_col13_val==1} >{curElm2.Tr_no}</td>
                            <td hidden={cd_col2_val==1} >{curElm2.Date}</td> 
                            <td hidden={cd_col3_val==1} >{curElm2.Product_code}</td>
                            <td hidden={cd_col4_val==1} >{curElm2.Product_name}</td>
                            <td hidden={cd_col5_val==1} >({curElm2.Inputs}) {curElm2.Input_value}</td>
                            <td hidden={cd_col6_val==1} >{curElm2.Quantities_produced}</td>
                            <td hidden={cd_col7_val==1} >{curElm2.Labour}</td>
                            <td hidden={cd_col8_val==1} >{curElm2.Power}</td>
                            <td hidden={cd_col9_val==1} >{curElm2.Other_expenses}</td>
                            <td hidden={cd_col10_val==1} >{curElm2.Total_cost}</td>
                            <td hidden={cd_col11_val==1} >{curElm2.Unit_production_cost}</td>
                            <td hidden={cd_col12_val==1} >{curElm2.Units}</td>
                          {/*}  <td hidden={cd_col13_val==1} >{curElm2.Units}</td>
                            <td hidden={cd_col14_val==1} >{curElm2.Discount_per}</td>
                            <td hidden={cd_col15_val==1} >{curElm2.Sgst_per}</td>
                            <td hidden={cd_col16_val==1} >{curElm2.Cgst_per}</td>
                            <td hidden={cd_col17_val==1} >{curElm2.Igst_per}</td>
                            <td hidden={cd_col18_val==1} >{curElm2.Tax_per}</td> */}
                            <td hidden={cd_col19_val==1} ><label class="badge badge-success"  hidden={(curElm2.Status == "inactive")}>active</label><label class="badge badge-danger"  hidden={(curElm2.Status == "active")}>inactive</label></td>
                            <td hidden={cd_col20_val==1} >{curElm2.Updated_by}</td>
                            <td hidden={cd_col21_val==1} >{curElm2.Date_updated} ({curElm2.Time_updated})</td>
                            <td hidden={cd_col24_val==1} >  
                            {/* QR CODE */}
                           
                             <br/><br/>
                             <button class="btn btn-light" onClick={handlePrint}>download</button>
                            </td>


                            <td hidden={cd_col22_val==1} ><i class="mdi mdi-pencil-box text-warning" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                            //Setting Bank Id and Redirect to Edit
                            sessionStorage.setItem("Mid00",curElm2.Mid);   
                            sessionStorage.setItem("Trno00",curElm2.Tr_no);   
                            document.getElementById("edate").value=curElm2.Date;
                            document.getElementById("eproduct_name").value=curElm2.Product_name;
                            document.getElementById("eproduct_code").value=curElm2.Product_code;
                            document.getElementById("eproduct_hsn").value=curElm2.Product_hsn;
                          //  document.getElementById("eproduct_name").value=curElm2.Product_name;
                            
                              document.getElementById('edit-form').style.display = 'block'; }}></i></td>
                            <td hidden={cd_col23_val==1} ><i class="mdi mdi-delete-forever text-danger" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                            //Setting Bank Id for Delete
                            sessionStorage.setItem("DMid00",curElm2.Mid); sessionStorage.setItem("DTrno00",curElm2.Tr_no); document.getElementById('delete-card').style.display = 'block'; }}></i></td>
                          </tr>
                          </>
                         )
                         }
					   
	                  		 )
                         }
                        
                        </tbody>
                      </table>
                    </div>
                       {/* PAGINATION SECTION START */}
                       <div>
                       <div class="col-12" style={{marginBottom:"2rem",marginTop:"1rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                       
                          <div class="dropdown">
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber-1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-left-drop-circle text-success"></i></button>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber+1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-right-drop-circle text-success"></i></button>
                          </div>
                          </div>
                          </div>
                    </div>
                    {/* PAGINATION SECTION END */}

                    <button class="btn btn-light" onClick={handlePrint}><i class="mdi mdi-cloud-print text-dark"></i></button>
                    <button class="btn btn-light" onClick={downloadExcel}><i class="mdi mdi-file-excel text-dark"></i></button>
                    
                  </div>
                </div>
              </div>
              {/* PRODUCTS ITEM LIST END */}

             

            
          </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
        {/*}  <footer class="footer">
            <div class="d-sm-flex justify-content-center justify-content-sm-between">
              <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
</>
);

}
}
export default Usermanufacturing;